import React, {useMemo, useState, useEffect} from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';


const PoOtherCharges = ({data}) => {
    const containerStyle = useMemo(() => ({ width: "100%", height: "20rem" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const rowStyle = { background: 'white' };
    const [rowData, setRowData] = useState(data || []);
    const [columnDefs, setColumnDefs] = useState([
        { headerName: 'S No', valueGetter: "node.rowIndex + 1", flex: 1},
        { headerName: 'Description', field: "desc" , flex: 3},
        { headerName: 'Remarks', field: "remarks", editable: true, flex: 5},
        { headerName: 'BOM Amount', field: "bomAmount", flex: 2 },
        { headerName: 'Actual Amount', field: "actualAmount", editable: true , flex: 2},
    ]);

    const defaultColDef = {
        // editable: true, 
        resizable: true
    }

    useEffect(()=>{
        let updated_data = data?.map(item => {
            return {...item, 'desc': item.description.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
        })
        setRowData(updated_data)
    }, [data])

    return(<>
        <div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowStyle ={rowStyle}
                    />
                </div>
            </div>
        </div>
    </>)
}

export default PoOtherCharges;