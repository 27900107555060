import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaFilter } from 'react-icons/fa';
import { LiaSortSolid } from "react-icons/lia";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import Select from "react-select";
import { tableListQuery } from '../../../GraphQLQueries/customfilterQueryandMutation';
import FilterModal from '../../../Page/reportes/FilterView';
import axiosInstance from '../../../api/axoiss';
import Can from "../../../components/authorizationComponents/Can";
import BallTriangleComponent from '../../../components/loading/BallTriangle';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import DataContext from '../../../context/ItemMasterContext';
import { formateGivenStringdate, ShowErrorNoties } from '../../../utils/Utils';
import TablePagination from '../../Custom/Pagination';
import {
    convertDateTimeToDateString,
    customSelectStyle,
    traverseArrayToFetchData,
    traverseObjectToFetchData
} from "../../Custom/TableUtils/TableUtils";
import CustomDatePicker from '../../DateSelector/CustomDatePicker';
import './ReporTableListView.css';

const ReporTableListView = ({ headers, url, loadComponent, titleName, reportTableViewValues, setReportTableViewValues, status, permission, addComponent }) => {
    const { userId } = useContext(DataContext)

    const [filterFieldOptions, setFilterFieldOptions] = useState(null)
    const [filtersData, setFiltersData] = useState(null);
    const [filterViewData, setfilterViewData] = useState({
        showFilterView: false, filterData: []
    })
    const [headerData, setHeaderData] = useState(headers)
    const [columnsEnabled, setcolumnsEnabled] = useState(reportTableViewValues?.columnsEnabled)
    const [fixedfilter, setFixedFilter] = useState({ fixedfilterData: {}, fixedselectedData: [], sortOrder: "", viewName: "View" })
    const [loading, setLoading] = useState(false)
    const [statusList, setStatusList] = useState([])
    const [paginationData, setPaginationData] = useState({ currentPageNumber: reportTableViewValues?.currentPageNumber, currentPagePageSize: reportTableViewValues?.currentPagePageSize })


    const splitPermission = permission?.split('_') || [];
    const [I, a, ...restParts] = splitPermission;
    const aAttribute = restParts.length ? `${a}_${restParts.join('_')}` : a;


    let numberFilters = [
        { key: 'fa-less-than', label: 'Less than', filter: "lt" },
        { key: 'fa-greater-than', label: 'Greater than', filter: "gt" },
        { key: 'fa-less-than-equal', label: 'Less than & Equal', filter: "lte" },
        { key: 'fa-greater-than-equal', label: 'Greater than & Equal', filter: "gte" },
        // { key: 'fa-sliders', label: 'Between', filter: "range" },
    ]
    useEffect(() => {
        if (url) {
            FetchData()
            // handleFetchData()
        }
    }, [])
    // returns nothing; Clear The Data
    const clearFilterData = (filters_data_) => {
        let clearedData = {}
        if (Object.keys(filters_data_).length > 0) {
            for (const [key, value] of Object.entries(filters_data_)) {
                if (typeof value === 'object') {
                    if (value?.start) {
                        let startsplitDate = (value?.start).split("-")
                        let endSplitDate = (value?.end).split("-")
                        if (value?.start === value?.end) {
                            if (value?.isdate_time) {
                                clearedData[`${key}`] = `${startsplitDate[2]}-${startsplitDate[1]}-${startsplitDate[0]}`
                            } else {
                                clearedData[`${key}__date`] = `${startsplitDate[2]}-${startsplitDate[1]}-${startsplitDate[0]}`
                            }
                        } else {
                            clearedData[`${key}__range`] = [`${startsplitDate[2]}-${startsplitDate[1]}-${startsplitDate[0]}`, `${endSplitDate[2]}-${endSplitDate[1]}-${Number(endSplitDate[0]) + 1}`]
                        }
                    } else {
                        clearedData[key] = value?.value
                    }
                } else {

                    clearedData[key] = value
                }
            }

            // for (const [key, value] of Object.entries(filters_data_)) {
            //     if (typeof value === 'object') {
            //         clearedData[key] = value[value.key]
            //     } else {
            //         clearedData[key] = value[value]
            //     }
            // }
        }
        return clearedData
    }
    //format the fixed filter data 
    const formatFixedFilterData = (fixedfilterDataFormat) => {
        const selectedData = fixedfilterDataFormat?.fixedselectedData?.map(column => column.field);
        const filterObject = {};
        fixedfilterDataFormat?.fixedfilterData.forEach(condition => {
            let filterKey
            let conditionApplied = condition.conditionApplied;
            if (condition?.type === "Date") {
                if (condition?.conditionApplied?.start === condition?.conditionApplied?.end) {
                    filterKey = `${condition.field}__date`;
                    // conditionApplied = formatDate(condition?.conditionApplied?.start); // Format the date
                    conditionApplied = condition?.conditionApplied?.start
                } else {
                    filterKey = `${condition.field}__range`;
                    // conditionApplied = [
                    //     formatDate(condition?.conditionApplied?.start), // Format the start date
                    //     formatDate(condition?.conditionApplied?.end)    // Format the end date
                    // ];
                    conditionApplied = [
                        condition?.conditionApplied?.start,
                        condition?.conditionApplied?.end
                    ];

                }
            }
            else if (condition?.type === "text") {
                if (condition?.conditionOption?.value === "equal") {
                    filterKey = `${condition.field}__icontains`
                } else if (condition?.conditionOption?.value === "notEqual") {
                    filterKey = `${condition.field}__noticontains`
                } else if (condition?.conditionOption?.value === "isEmpty") {
                    filterKey = `${condition.field}__isempty`
                }
                else if (condition?.conditionOption?.value === "isNotEmpty") {
                    filterKey = `${condition.field}__isnotempty`
                }
            } else if (condition?.type === "Bool") {
                if (condition?.conditionApplied?.value === 'false') {
                    filterKey = `${condition.field}`;
                    conditionApplied = false
                } else {
                    filterKey = `${condition.field}`;
                    conditionApplied = true
                }
            } else if (condition?.type === "number") {
                if (condition?.conditionOption?.value === "lessThan") {
                    filterKey = `${condition.field}__lt`
                } else if (condition?.conditionOption?.value === "greaterThan") {
                    filterKey = `${condition.field}__gt`
                } else if (condition?.conditionOption?.value === "lessThanEqual") {
                    filterKey = `${condition.field}__lte`
                } else if (condition?.conditionOption?.value === "greaterThanEqual") {
                    filterKey = `${condition.field}__gte`
                } else if (condition?.conditionOption?.value === "between") {
                    filterKey = `${condition.field}__range`
                    conditionApplied = [condition?.conditionApplied?.value, condition?.conditionApplied?.value]
                }
            }
            filterObject[filterKey] = conditionApplied
        });
        const result = {
            fixedfilterData: filterObject,
            fixedselectedData: selectedData.length > 0 ? selectedData : [],
            sortOrder: fixedfilterDataFormat?.sortOrder ? fixedfilterDataFormat?.sortOrder : null,
            viewName: fixedfilterDataFormat?.viewName ? fixedfilterDataFormat?.viewName : "View"
        };
        return result;
    }
    const FetchData = () => {
        setLoading(true)
        try {
            axiosInstance.put("/itemmaster/InitialDataForTable", {
                model_name: "SalesOrder_2",
                table_name: "SalesOrderReport",
                app_name: "itemmaster2",
                user_id: 9
            })
                .then((res) => {
                    if (res?.status === 200) {
                        let responseData = res?.data
                        let fixedfilterDataFormat = {
                            fixedselectedData: responseData?.editlistview?.coloumn_to_display ? responseData?.editlistview?.coloumn_to_display : [],
                            fixedfilterData: responseData?.editlistview?.filiter_conditions ? responseData?.editlistview?.filiter_conditions : {},
                            sortOrder: responseData?.editlistview?.default_sort_order ? responseData?.editlistview?.default_sort_order : [],
                            viewName: responseData?.editlistview?.view_name ? responseData?.editlistview?.view_name : "View"
                        }
                        const statusList = responseData?.status ? responseData?.status?.map(item => ({
                            value: item?.status__name,
                            label: item?.status__name
                        })) : []
                        setStatusList(statusList)
                        const updatedFixedFilter = formatFixedFilterData(fixedfilterDataFormat)
                        setFixedFilter(updatedFixedFilter)
                        handleFilterOptionsGet()
                        setReportTableViewValues(prev => ({
                            ...prev,
                            tableRowData: responseData?.results,
                            hasNextPage: responseData?.has_next,
                            hasPreviousPage: responseData?.has_previous,
                            totalItems: responseData?.count, totalPages: responseData?.num_pages
                        }))
                        const updatedColumnsEnabled = responseData?.editlistview?.coloumn_to_display ? responseData?.editlistview?.coloumn_to_display?.map(item => item?.field) : columnsEnabled
                        // Rearrange headerData based on updatedColumnsEnabled
                        const reorderedHeaderData = updatedColumnsEnabled.map((field) => {
                            const header = headerData.find((header) => header.field === field);

                            // Check if inputType is "number" and delete selectedCondition and selectedOption
                            if (header?.inputType === "number") {
                                const updatedHeader = { ...header }; // Clone the header object
                                delete updatedHeader.selectedCondition; // Delete selectedCondition key
                                delete updatedHeader.selectedOption;   // Delete selectedOption key
                                delete updatedHeader.rangeFrom;        // Delete rangeFrom key
                                delete updatedHeader.rangeTo;          // Delete rangeTo key
                                delete updatedHeader.error;            // Delete error key
                                return updatedHeader; // Return the updated header
                            }

                            return header; // Return the original header if inputType is not "number"
                        }).filter(Boolean); // Remove undefined if any field does not match
                        // Include remaining data (headers not in updatedColumnsEnabled)
                        const remainingHeaderData = headerData.filter(header =>
                            !updatedColumnsEnabled.includes(header.field)
                        );
                        // Combine reordered headers with remaining headers
                        const finalHeaderData = [...reorderedHeaderData, ...remainingHeaderData];
                        setHeaderData(finalHeaderData)
                        setcolumnsEnabled(updatedColumnsEnabled)
                    }
                    else {
                        let error = res?.error
                        let errorData = ShowErrorNoties(error)
                        showErrorToast(true, 'error', errorData)

                    }

                })
                .catch((error) => {
                    let errorData = ShowErrorNoties(error)
                    showErrorToast(true, 'error', errorData)

                })
        } catch (error) {
            let errorData = ShowErrorNoties(error)
            showErrorToast(true, 'error', errorData)
        }
        setLoading(false)
    }
    // returns nothing; fetch data and updates state
    const handleFetchData = (filters_data, filterViewData, sortData, pageSizeData, pageNumberData) => {
        setLoading(true)
        let fixedfilterData = filterViewData?.fixedfilterData ? filterViewData?.fixedfilterData : fixedfilter?.fixedfilterData
        let filterObjects = {}
        let filters_data_ = filters_data ? filters_data : {}
        if (Object.keys(filters_data_).length > 0) {
            filterObjects = clearFilterData(filters_data_)
        }
        let fixedselectedData = filterViewData?.fixedselectedData ? filterViewData?.fixedselectedData : fixedfilter?.fixedselectedData;

        if (status) {
            // If status exists, append it directly to fixedselectedData
            fixedselectedData = [...fixedselectedData, status];  // Add status__name directly
        }
        try {
            axiosInstance.put(url, {
                model_name: "SalesOrder_2",
                app_name: "itemmaster2",
                selectedData: reportTableViewValues?.columnsEnabled,
                pageNumber: pageNumberData ? pageNumberData : reportTableViewValues?.currentPageNumber,
                pageSize: pageSizeData ? pageSizeData : reportTableViewValues?.currentPagePageSize,
                filterData: Object.keys(filterObjects).length > 0 ? filterObjects : {},
                fixedfilterData: fixedfilterData,
                fixedselectedData: fixedselectedData,
                sort_by: sortData ? Array.isArray(sortData) ? sortData : [sortData] : fixedfilter?.sortOrder ? Array.isArray(fixedfilter?.sortOrder) ? fixedfilter?.sortOrder : [fixedfilter?.sortOrder] : null
            })
                .then((res) => {                    
                    if (res?.data?.errors.length > 0) {
                        let errorData = ShowErrorNoties(res?.errors)
                        showErrorToast(true, 'error', errorData)
                    }
                    let datas = res?.data
                    handleFilterOptionsGet()
                    setReportTableViewValues(prev => ({
                        ...prev, tableRowData: datas?.results,
                        hasNextPage: datas?.has_next, hasPreviousPage: datas?.has_previous, totalItems: datas?.count, totalPages: datas?.num_pages
                    }))
                })
                .catch((error) => {
                    let errorData = ShowErrorNoties(error)
                    showErrorToast(true, 'error', errorData)
                    setReportTableViewValues(prev => ({ ...prev, totalItems: 0, tableRowData: null }))
                })
        } catch (error) {
            let errorData = ShowErrorNoties(error)
            showErrorToast(true, 'error', errorData)

        }

        setLoading(false)

    }
    // returns nothing; fetch filter data and updates state
    const handleFetchFilterData = (e, header) => {
        let header_filter = header?.filter
        let header_field = header?.field
        if (e.trim() !== "") {
            let search_term = e.trim().replace(/"/g, '\\"');
            let options_data = []
            axiosInstance.put(url, {
                model_name: "SalesOrder_2",
                app_name: "itemmaster2",
                filterData: { [`${[header_filter]}`]: search_term },
                selectedData: [header_field], pageNumber: 1,
                pageSize: 10,
                distinct: header?.distinct ? [header_field] : [],
                fixedfilterData: fixedfilter?.fixedfilterData,
            })
                .then((res) => {
                    res?.data?.results?.map((item) => {
                        let option_item_obj = { label: item?.[header_field], value: item?.[header_field] }
                        options_data.push(option_item_obj)
                    })
                    let existing_filter_options = { ...filterFieldOptions }
                    existing_filter_options[header_field] = options_data
                    setFilterFieldOptions(existing_filter_options)
                })
                .catch((error) => {
                    let errorData = ShowErrorNoties(error)
                    showErrorToast(true, 'error', errorData)
                });
        } else {
            let existing_filter_options = { ...filterFieldOptions }
            existing_filter_options[header_field] = []
            setFilterFieldOptions(existing_filter_options)
        }

    }
    //returns nothing; updates state of filter
    const handleFilterOptionsGet = () => {
        // get column's field names which are boolean field
        let boolean_data = headerData?.filter(item => item?.inputType === "Bool").map(item => item.field)
        let filter_options = {}
        // updates boolean field filter dropdown options
        for (let key_name of boolean_data) {
            filter_options[key_name] = [{ value: true, label: 'Yes' }, { value: false, label: 'No' }]
        }
        setFilterFieldOptions(filter_options)
    }
    // returns nothing; update search term filter
    const handleFilterInputChange = (e, header) => {
        let filters_data = { ...filtersData }
        let header_filter = header?.filter
        if (!e) {
            delete filters_data[header_filter]
            setFiltersData(filters_data)
        }
        else {
            let { label, value } = e
            if (label === '') {
                delete filters_data[header_filter]
                setFiltersData(filters_data)
            } else {
                label = String(label).replace(/"/g, '\\"');
                filters_data[header_filter] = { label: label, value: value, key: "value" }
                setFiltersData(filters_data)
            }
        }
        handleFetchData(filters_data)
    }
    // returns "item" or <input /> element based on data
    const handleTdItemData = (item, header_item) => {
        /*
            checks input data for table row column
            if it is a string returns value in header key
            if it is a object returns value based on given label
            if it is a boolean returns input checkbox with checked true or false
            if it is a boolean returns yes or no
        */
        let item_value = item?.[header_item?.field];

        if (header_item?.inputType === "Bool") {
            item_value = item_value ? 'yes' : 'No'
        } else if (Array.isArray(item_value) && header_item?.label) {
            item_value = traverseArrayToFetchData(item_value, header_item?.label)
        } else if (typeof item_value === "object" && header_item?.label) {
            item_value = traverseObjectToFetchData(item_value, header_item?.label)
            if (header_item?.inputType === "Date") {
                item_value = convertDateTimeToDateString(item_value)
            }
        } else if (header_item?.inputType === "Date") {
            item_value = convertDateTimeToDateString(item_value)
        }
        return item_value;
    };
    // returns nothing; handles number filter popup
    const handleNumberFiltersPopup = (field) => {
        let number_filter_popup_element = document.getElementById(`number_${field}`)
        number_filter_popup_element.style.display = 'block';
    }

    // returns nothing; closes opened number filter popup
    const handleCloseNumberFilterPopup = (field) => {
        let number_filter_popup_element = document.getElementById(`number_${field}`)
        number_filter_popup_element.style.display = 'none';
    }
    const handleNumberRadioFieldChange = (e, headerValue, optionData) => {
        // Create a copy of the headerData to update
        const updatedHeaders = [...headerData];
        // Find the header item that corresponds to the current headerData
        const headerIndex = updatedHeaders.findIndex(item => item.field === headerValue);
        if (headerIndex === -1) return;
        const headerItem = updatedHeaders[headerIndex];
        // If optionData is provided (meaning the user selected a filter option like 'lt', 'gt', etc.)
        if (optionData) {
            // Update the selected filter option for this field (e.g., 'lt', 'gt', etc.)
            headerItem.selectedOption = optionData ? optionData : '';
        }
        // Handle range case (From and To)
        if (headerItem.selectedOption === "range") {
            // If the "From" or "To" input changes, update the respective range field
            if (e && e.includes(",")) {
                const [fromValue, toValue] = e.split(',');
                // Ensure no "undefined" value is set. Replace undefined with an empty string.
                headerItem.rangeFrom = fromValue || '';  // If fromValue is falsy (undefined, null, or empty), set it to ''
                headerItem.rangeTo = toValue || '';      // If toValue is falsy, set it to ''
                // Update selectedCondition to reflect both "From" and "To"
                headerItem.selectedCondition = `(${headerItem.rangeFrom},${headerItem.rangeTo})`;  // Format the condition
            } else {
                // If a single value is passed (not a range), update the respective "From" or "To"
                if (e) {
                    if (e.startsWith("from")) {
                        headerItem.rangeFrom = e.split(":")[1] ? e.split(":")[1] : '';  // e.g. from: 10
                    } else if (e.startsWith("to")) {
                        headerItem.rangeTo = e.split(":")[1] ? e.split(":")[1] : '';    // e.g. to: 20
                    }
                    // Ensure empty values are correctly formatted (empty strings instead of undefined)
                    headerItem.selectedCondition = `(${headerItem.rangeFrom || ''},${headerItem.rangeTo || ''})`;  // Format the condition
                }
            }
        } else {
            // If it's not a range, update selectedCondition normally
            headerItem.selectedCondition = e;
        }
        // Update the headerData state
        setHeaderData(updatedHeaders);
    };
    const handleNumberFilterApply = (headerValue) => {
        // Extract necessary data from headerValue using optional chaining
        const { field, selectedOption, rangeFrom, rangeTo, selectedCondition } = headerValue ?? {};
        // Initialize the error message state
        let errorMessage = "";
        // Create a copy of headerData to update the error key
        const updatedHeaders = [...headerData];
        // Find the header item that corresponds to the current headerValue
        const headerIndex = updatedHeaders.findIndex(item => item.field === field);
        if (headerIndex === -1) return;
        const headerItem = updatedHeaders[headerIndex];
        if (!selectedOption) {
            errorMessage = "Please select a filter option.";
            headerItem.error = errorMessage;
        } else if (selectedOption === "range") {
            // If selectedOption is "range", validate rangeFrom and rangeTo
            if (!rangeFrom || !rangeTo) {
                errorMessage = "Both 'From' and 'To' values are required for the range filter.";
                headerItem.error = errorMessage;
            }
            // Check if rangeFrom and rangeTo are valid numbers
            else if (isNaN(rangeFrom) || isNaN(rangeTo)) {
                errorMessage = "'From' and 'To' values must be valid numbers.";
                headerItem.error = errorMessage;
            }
            // Check if rangeFrom is greater than rangeTo
            else if (parseFloat(rangeFrom) > parseFloat(rangeTo)) {
                errorMessage = "'From' value cannot be greater than 'To' value.";
                headerItem.error = errorMessage;
            } else {
                // If all validation passes, construct the selectedCondition for range
                headerItem.selectedCondition = `(${rangeFrom},${rangeTo})`;
                headerItem.error = ""; // Clear any existing error message
            }
        } else {
            // For other selectedOption types (e.g., 'gt', 'lt', etc.), validate selectedCondition
            if (!selectedCondition) {
                errorMessage = "Please enter a valid condition for the filter.";
                headerItem.error = errorMessage;
            } else {
                headerItem.selectedCondition = selectedCondition ?? "";
                headerItem.error = ""; // Clear any existing error message
            }
        }
        // If validation passes, apply the filter
        const filterKey = `${field}__${selectedOption}`;
        const newFilter = {
            label: headerItem.selectedCondition,
            value: headerItem.selectedCondition,
            key: "value",
        };
        // Update the filtersData state with the new filter
        const updatedFilters = {
            ...filtersData,
            [filterKey]: newFilter,
        };
        // Update the header data (if needed)
        const updatedHeaderData = updatedHeaders.map((item) => {
            if (item.field === field) {
                return {
                    ...item,
                    selectedCondition: headerItem.selectedCondition,
                    selectedOption: selectedOption,
                };
            }
            return item;
        });
        // Apply the updated filter data
        setFiltersData(updatedFilters);
        setHeaderData(updatedHeaderData); // Update headerData state
        // Fetch the data with the updated filters
        if (!errorMessage) {
            handleFetchData(updatedFilters);
        }
    };

    const handleNumberFilterClear = (field) => {
        // Get the input field by its ID
        headers.forEach(header_item => {
            // Check if the input type is "number"
            if (header_item?.inputType === "number") {
                const filterFields = document.getElementsByName(`number_filter_${header_item.field}`);
                if (filterFields && filterFields.length) {
                    filterFields.forEach(radioButton => {
                        radioButton.checked = false; // Uncheck each radio button
                    });
                }
            }
        });
        const updatedHeaderData = headerData.map(header_item => {
            if (header_item.field === field) {
                const updatedItem = { ...header_item };
                delete updatedItem.selectedCondition;
                delete updatedItem.selectedOption;
                delete updatedItem.rangeFrom;
                delete updatedItem.rangeTo;
                delete updatedItem.error;
                return updatedItem; // Return the updated item with the keys deleted
            }
            return header_item; // Return the item unchanged if it doesn't match the field
        });
        // Update the headerData state with the new array
        setHeaderData(updatedHeaderData);
        const updatedFilters = { ...filtersData }; // Copy the current filtersData
        // Loop through and remove the filter for the specific field
        Object.keys(updatedFilters).forEach(key => {
            if (key.includes(field)) {
                delete updatedFilters[key]; // Remove the filter for the specific field
            }
        });
        // Now, update filtersData using the new updatedFilters object
        setFiltersData(updatedFilters);
        handleFetchData(updatedFilters)
    }
    // returns nothing; shows datepicker component
    const showDatePickerPopup = (field) => {
        let date_range_picker_element = document.getElementById(`date_${field}`)
        date_range_picker_element.style.display = 'block'
    }
    const closeDatePickerPopup = (field) => {
        let date_range_picker_element = document.getElementById(`date_${field}`)
        date_range_picker_element.style.display = 'none'
    }
    // returns nothig; updates state of date filter related to its field
    const handleGetSelectedDate = (data, field, header_item) => {
        let selected_start_date = formateGivenStringdate(data.startDate)
        let selected_end_date = formateGivenStringdate(data.endDate)
        let date_obj = {
            start: selected_start_date,
            end: selected_end_date,
            key: 'value',
            isdate_time: header_item?.isDate,
            value: `${selected_start_date} - ${selected_end_date}`
        }
        let filters_data = { ...filtersData }
        filters_data[field] = date_obj
        setFiltersData(filters_data)
        closeDatePickerPopup(field)
        handleFetchData(filters_data)
    }

    // returns nothing; clears value of date input field and state based on selected field using id
    const handleClearDateInputField = (field) => {
        document.getElementById(`date_input_${field}`).value = ''
        let date_range_picker_element = document.getElementById(`date_${field}`)
        date_range_picker_element.style.display = 'none'
        let filter_data = { ...filtersData }
        delete filter_data[field]
        setFiltersData(filter_data)
        handleFetchData(filter_data)
    }

    const handleFilterView = async () => {
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: tableListQuery(`table:"${titleName}" userID:${Number(userId)}`) });
            const responseData = response?.data?.data?.EditListViewType?.items
            if (responseData) {
                setfilterViewData(prev => ({
                    ...prev,
                    showFilterView: true,
                    filterData: responseData
                }));
            } else {
                showErrorToast(true, 'error', 'Error Fetching Filter !!!')
            }
        } catch {
            showErrorToast(true, 'error', 'Error Fetching Filter !!!')
        }
    }

    const handleClearFilter = () => {
        headers.forEach(header_item => {
            // Check if the input type is "number"
            if (header_item?.inputType === "number") {
                const inputField = document.getElementById(`number_input_${header_item.field}`);
                const filterFields = document.getElementsByName(`number_filter_${header_item.field}`);
                // Clear the value of the input field if it exists
                if (inputField) {
                    inputField.value = ""; // Clear the value
                }
                if (filterFields && filterFields.length) {
                    filterFields.forEach(radioButton => {
                        radioButton.checked = false; // Uncheck each radio button
                    });
                }
            }
        });
        const statusSelect = document.getElementById("status");
        if (statusSelect) {
            statusSelect.value = "All"; // Set the select value to "All"
        }
        const updatedHeaderData = headerData.map(header_item => {
            if (header_item?.inputType === "number") {
                const updatedItem = { ...header_item };
                delete updatedItem.selectedCondition;
                delete updatedItem.selectedOption;
                delete updatedItem.rangeFrom;
                delete updatedItem.rangeTo;
                delete updatedItem.error;
                return updatedItem; // Return the updated item with the keys deleted
            }
            return header_item; // Return the item unchanged if it doesn't match the field
        });
        // Update the headerData state with the new array
        setHeaderData(updatedHeaderData);
        FetchData();
        setFiltersData({})
    }
    const handleStatusChange = (status) => {
        const updatedFilters = { ...filtersData };
        if (status === "All") {
            // If status is "All", delete the status__name key
            delete updatedFilters["status__name__icontains"];
        } else {
            // Otherwise, update the status__name key with the new value
            updatedFilters["status__name__icontains"] = status;
        }
        setFiltersData(updatedFilters);
        handleFetchData(updatedFilters);
    }
    const handleSortClick = (data) => {
        const baseField = data.inputType === 'text' ? data.field.split('__')[0] : data.field;
        const currentSortState = data.sort;

        // Determine the new sort direction
        let newSortDirection;

        // If the column is already sorted, increment the click count
        const clickCount = (data.clickCount || 0) + 1;

        if (clickCount === 3) {
            newSortDirection = null; // Reset sorting on third click
            // Reset the click count
            data.clickCount = 0;
        } else {
            newSortDirection = currentSortState === 'asc' ? 'desc' : (currentSortState === 'desc' ? 'asc' : 'asc');
            data.clickCount = clickCount;
        }
        // Prepare the sortByArray
        const sortByArray = newSortDirection ? (newSortDirection === 'desc' ? `-${baseField}` : baseField) : fixedfilter?.sortOrder ? fixedfilter?.sortOrder : null;
        // Update headerData with the new sort direction for the clicked field
        setHeaderData(prevHeaderData =>
            prevHeaderData.map(item => {
                const updatedItem = { ...item };
                if (updatedItem.sort) {
                    delete updatedItem.sort; // Remove the 'sort' key completely on reset
                }

                // Add or update the 'sort' key only for the clicked column if it's not resetting
                if (item.field === data.field && newSortDirection) {
                    updatedItem.sort = newSortDirection;
                }

                return updatedItem;
            })
        );
        handleFetchData(null, fixedfilter, sortByArray);
    };
    const handlePageSizeChange = (data) => {
        setReportTableViewValues(prev => ({ ...prev, currentPagePageSize: data }))
        setPaginationData(prev => ({ ...prev, currentPagePageSize: data }))
        handleFetchData(null, fixedfilter, [], data, paginationData?.currentPageNumber);
    }
    const handlegetSelectedPageNumber = (data) => {
        setReportTableViewValues(prev => ({ ...prev, currentPageNumber: data }))
        setPaginationData(prev => ({ ...prev, currentPageNumber: data }))
        handleFetchData(null, fixedfilter, [], paginationData?.currentPagePageSize, data);
    }

    return (
        <>
            {<BallTriangleComponent isshow={loading} />}
            <div className='card ms-5 me-2 my-2 py-2 py-lg-3 shadow' >
                <div className='row align-items-center'>
                    <div className="col-6">
                        <b className="fs-4 ms-3" style={{ color: "#000000" }}>{reportTableViewValues?.titleName}</b>
                    </div>
                    <div className='col-6 d-flex justify-content-end pe-5'>
                        <Can I={I} a={aAttribute}>
                            <button
                                className="btn btn-sm btn-outline-success px-4 py-2 mr-2"
                                onClick={addComponent}
                            >
                                <i className="bx bxs-plus-circle me-2"></i>
                                New
                            </button>
                        </Can>
                        <div className='status-content'>
                            <span>
                                Status:
                            </span>
                            <select
                                id="status"
                                onChange={(e) => handleStatusChange(e.target.value)}
                            >
                                <option value="All" id={`status_All`}>All</option>
                                {statusList.map((status) => (
                                    <option key={status.value} value={status.value} id={`status_${status.value}`}>
                                        {status.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <Button
                            variant="light"
                            className="d-flex align-items-center view-button"
                            onClick={handleFilterView}
                        >
                            <FaFilter style={{ marginRight: '0.5rem' }} />
                            {fixedfilter?.viewName}
                        </Button>
                        <span title="Clear Filters" className="clear-filters" onClick={handleClearFilter}>
                            <i className="fa-solid fa-filter-circle-xmark fs-5" role="button"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div className='card ms-5 me-2 my-2'>
                <div className="custom-table-responsive">
                    <table className='table'>
                        <thead className='thead-fixed py-4 shadow-sm '>
                            <tr>
                                {
                                    headerData && headerData?.map((item, index) => {
                                        if (columnsEnabled?.includes(String(item?.field))) {
                                            return (
                                                <th
                                                    key={index}
                                                    className='table-header'
                                                    style={{ width: `${item.flex}rem` }}
                                                    onClick={() => handleSortClick(item)}
                                                >
                                                    <div
                                                        // style={{
                                                        //     display: 'flex',
                                                        //     alignItems: 'center',
                                                        //     justifyContent: 'center',
                                                        //     whiteSpace: 'nowrap',
                                                        //     overflow: 'hidden',
                                                        //     textOverflow: 'ellipsis',
                                                        // }}
                                                        className='table-header-content'
                                                    >
                                                        <span
                                                            // style={{
                                                            //     display: 'inline-block',
                                                            //     overflow: 'hidden',
                                                            //     textOverflow: 'ellipsis',
                                                            //     whiteSpace: 'nowrap',
                                                            // }}
                                                            className="table-header-text"

                                                        >
                                                            {item.header}
                                                        </span>
                                                        {item?.sort === 'asc' && (
                                                            <TiArrowSortedUp
                                                                // style={{
                                                                //     fontSize: '1rem',
                                                                //     marginLeft: '0.5rem',
                                                                //     verticalAlign: 'middle',
                                                                // }}
                                                                className='sort-icon'

                                                            />
                                                        )}
                                                        {item?.sort === 'desc' && (
                                                            <TiArrowSortedDown
                                                                // style={{
                                                                //     fontSize: '1rem',
                                                                //     marginLeft: '0.5rem',
                                                                //     verticalAlign: 'middle',
                                                                // }}
                                                                className='sort-icon'

                                                            />
                                                        )}
                                                        {(!item?.sort || item?.sort === null) && (
                                                            <LiaSortSolid
                                                                // style={{
                                                                //     fontSize: '1rem',
                                                                //     marginLeft: '0.5rem',
                                                                //     verticalAlign: 'middle',
                                                                // }}
                                                                className='sort-icon'
                                                            />
                                                        )}
                                                    </div>
                                                </th>
                                            );
                                        }
                                    })
                                }

                            </tr>
                            <tr>
                                {
                                    headerData && headerData?.map((header_item, index) => {
                                        if (columnsEnabled?.includes((header_item?.field))) {
                                            return <td key={index} className='table-cell'>
                                                {header_item.inputType === 'text' &&
                                                    <Select
                                                        onChange={(e) => { handleFilterInputChange(e, header_item) }}
                                                        name={header_item.field}
                                                        options={filterFieldOptions ? filterFieldOptions[header_item.field] : []}
                                                        onInputChange={(e) => { handleFetchFilterData(e, header_item) }}
                                                        styles={customSelectStyle}
                                                        isClearable
                                                        components={{ DropdownIndicator: () => null }}
                                                        value={filtersData?.[header_item?.filter] ? filtersData?.[header_item?.filter] : ''}
                                                    />}
                                                {
                                                    header_item.inputType === 'Bool' && <Select
                                                        name={header_item.field}
                                                        options={filterFieldOptions ? filterFieldOptions[header_item.field] : []}
                                                        styles={customSelectStyle}
                                                        onChange={(e) => { handleFilterInputChange(e, header_item) }}
                                                        isClearable
                                                        value={filtersData?.[header_item?.filter]}
                                                    />
                                                }
                                                {header_item.inputType === 'Date' &&
                                                    <>
                                                        <div className="form-group d-flex align-items-center date-input-group">
                                                            <input
                                                                type="text"
                                                                id={`date_input_${header_item.field}`}
                                                                className="form-control py-2"
                                                                style={{ fontSize: '.7rem', height: "2.5rem" }}
                                                                placeholder="Select..."
                                                                onFocus={() => { showDatePickerPopup(header_item.field) }}
                                                                defaultValue={filtersData?.[header_item.field] ? `${filtersData[header_item.field].start} - ${filtersData[header_item.field].end}` : ''}
                                                            // value = {filtersData?.[header_item.field] ? `${filtersData[header_item.field].start} - ${filtersData[header_item.field].end}`: ''}
                                                            />
                                                            {filtersData?.[header_item.field] && <i className="fa-solid fa-xmark fw-bold clear-date-icon"
                                                                onClick={() => { handleClearDateInputField(header_item.field) }}
                                                            ></i>}
                                                            <div
                                                                id={`date_${header_item.field}`}
                                                                className="border shadow-sm bg-white p-2 mt-2 date-picker-popup"
                                                            >
                                                                <div className="d-flex justify-content-end">
                                                                    <span className="mx-3" style={{ cursor: 'pointer' }} onClick={() => { closeDatePickerPopup(header_item.field) }}>
                                                                        <i className="fa-solid fa-xmark fs-6"></i>
                                                                    </span>
                                                                </div>
                                                                <CustomDatePicker
                                                                    headerField={header_item.field}
                                                                    handleGetSelectedDate={(date, field) => { handleGetSelectedDate(date, field, header_item) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>}
                                                {header_item.inputType === 'number' &&
                                                    <>
                                                        <div className='form-group d-flex justify-content-between align-items-center'>
                                                            <input
                                                                type="text"
                                                                className='form-control   number-field-custom-table-filter'
                                                                placeholder="Type..."
                                                                style={{ height: "2.6rem" }}
                                                                value={filtersData?.[header_item?.filter] ? filtersData?.[header_item?.filter]?.value : ""}
                                                                onChange={(e) => { handleFilterInputChange({ label: e.target.value, value: e.target.value }, header_item) }}
                                                            />
                                                            <i
                                                                title="Number Filters"
                                                                className="fa-solid fa-sort-down fs-4 ms-2"
                                                                role="button"
                                                                onClick={() => { handleNumberFiltersPopup(header_item.field) }}
                                                            ></i>
                                                        </div>
                                                        <div id={`number_${header_item.field}`} className="border shadow-sm bg-white p-2 mt-2 number-filters-popup">
                                                            <div>
                                                                <div className="d-flex justify-content-end">
                                                                    <span title="close" style={{ cursor: 'pointer' }}
                                                                        onClick={() => { handleCloseNumberFilterPopup(header_item.field) }}>
                                                                        <i className="fa-solid fa-x me-2"></i>
                                                                    </span>
                                                                </div>
                                                                {numberFilters?.map((number_filter_item, num_index) => {
                                                                    return <div className="form-check" key={num_index}>
                                                                        <input
                                                                            id={`number_filter_${header_item.field}_${number_filter_item.key}`}
                                                                            value={header_item?.selectedOption ? header_item?.selectedOption : ''}
                                                                            className="form-check-input" type="radio" name={`number_filter_${header_item.field}`}
                                                                            onChange={(e) => { handleNumberRadioFieldChange("", header_item?.field, number_filter_item?.filter) }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={number_filter_item.key}>
                                                                            <i className={`fa-solid ${number_filter_item.key} mx-2`}></i> - <span>{number_filter_item.label}</span>
                                                                        </label>

                                                                    </div>

                                                                })}
                                                                <div className='row' style={{ marginRight: "1rem" }}>
                                                                    {header_item?.selectedOption !== "range" && (
                                                                        <div className='col-12'>
                                                                            <input style={{ width: '100%' }}
                                                                                id={`number_input_${header_item.field}`}
                                                                                value={header_item?.selectedCondition ? header_item?.selectedCondition : ''}
                                                                                onChange={(e) => { handleNumberRadioFieldChange(e.target.value, header_item?.field) }}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {
                                                                        header_item?.selectedOption === "range" && (
                                                                            <>
                                                                                <div className='col-6'>
                                                                                    <input style={{ width: '100%' }}
                                                                                        id={`number_input_${header_item.field}`}
                                                                                        placeholder='From'
                                                                                        value={header_item?.rangeFrom ? header_item?.rangeFrom : ''}  // Value for "From"
                                                                                        onChange={(e) => {
                                                                                            handleNumberRadioFieldChange(`${e.target.value},${header_item?.rangeTo ? header_item?.rangeTo : ''}`, header_item?.field, "range");
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <input style={{ width: '100%' }}
                                                                                        placeholder='To'
                                                                                        id={`number_input_${header_item.field}`}
                                                                                        value={header_item?.rangeTo ? header_item?.rangeTo : ''}  // Value for "To"
                                                                                        onChange={(e) => {
                                                                                            handleNumberRadioFieldChange(`${header_item?.rangeFrom ? header_item?.rangeFrom : ''},${e.target.value}`, header_item?.field, "range");
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                                {header_item?.error && (<span className="text-danger">{header_item?.error}</span>)}
                                                                <div className='row mt-2'>
                                                                    <div className='col-6'>
                                                                        <div className='col-4'>
                                                                            <button style={{ height: '2rem' }} type="button" class="btn btn-outline-danger" onClick={() => { handleNumberFilterClear(header_item?.field) }}>Clear</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <div className='col-4'>
                                                                            <button style={{ height: '2rem' }} type="button" class="btn btn-outline-success" onClick={() => { handleNumberFilterApply(header_item) }}>Apply</button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>

                                                }
                                            </td>
                                        }
                                    })
                                }
                            </tr>
                        </thead>
                        {reportTableViewValues?.tableRowData && reportTableViewValues?.tableRowData?.length > 0 && <tbody>
                            {reportTableViewValues?.tableRowData && reportTableViewValues?.tableRowData?.map((item, index) => {
                                const isDraft = item?.status__name === "Canceled";
                                return <tr key={index} style={{
                                    backgroundColor: isDraft ? '#f0f0f0' : 'transparent',

                                }}>
                                    {headerData?.map((header_item, sub_index) => {
                                        if (columnsEnabled?.includes(header_item?.field)) {
                                            if (header_item?.renderComponent) {
                                                return <td className="py-2 row-cell"
                                                    key={sub_index}
                                                >
                                                    {handleTdItemData(item, header_item)}
                                                </td>
                                            } else {
                                                return <td className='py-2 row-cell-2' key={sub_index}>
                                                    {handleTdItemData(item, header_item)}
                                                </td>
                                            }
                                        }
                                    })}
                                </tr>
                            })}
                        </tbody>}
                    </table>
                </div>

                {reportTableViewValues?.tableRowData && <TablePagination
                    // handlePageSizeChange={(data) => { setReportTableViewValues(prev => ({ ...prev, currentPagePageSize: data })) }}
                    // getSelectedPageNumber={(data) => { setReportTableViewValues(prev => ({ ...prev, currentPageNumber: data })) }}
                    handlePageSizeChange={(data) => { handlePageSizeChange(data) }}
                    getSelectedPageNumber={(data) => { handlegetSelectedPageNumber(data) }}
                    pageNumber={reportTableViewValues?.currentPageNumber}
                    totalPages={reportTableViewValues?.totalPages}
                    totalItems={reportTableViewValues?.totalItems}
                    hasNextPage={reportTableViewValues?.hasNextPage}
                    hasPreviousPage={reportTableViewValues?.hasPreviousPage}
                    pageSize={reportTableViewValues?.totalPages}
                />}
            </div>
            {/* {filterViewData &&
                <FilterView
                    IsFilterShow={filterViewData?.showFilterView}
                    setIsFilterShow={() => { setfilterViewData(prev => ({ ...prev, showFilterView: false })) }}
                    headers={headers}
                    url={url}
                    tableName={titleName}
                />} */}
            {filterViewData &&
                <FilterModal
                    show={filterViewData?.showFilterView}
                    filterData={filterViewData?.filterData}
                    setfilterData={(value) => { setfilterViewData((prev) => ({ ...prev, filterData: value })) }}
                    handleClose={() => { setfilterViewData(prev => ({ ...prev, showFilterView: false })) }}
                    headers={headerData}
                    url={url}
                    tableName={titleName}
                    applyFilterFunction={handleFetchData}
                    applyFilterState={(values) => { setcolumnsEnabled(values) }}
                    setFixedFilter={setFixedFilter}
                />}
        </>
    )
}

export default ReporTableListView