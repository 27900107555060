import React from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { handlePdfReportDownload } from '../../PosDetails/Reports/PdfReportDownload';
const LeadExportModal = ({ show, onClose, exportDataList }) => {
    const handleExportExcel = () => {
        // Get the table element
        const table = document.getElementById('pos-table-day-report-data');
        // Create a workbook and a worksheet
        const wb = XLSX.utils.table_to_book(table);
        const excelFile = `lead.xlsx`;
        // Write the file
        XLSX.writeFile(wb, excelFile);
    };

    const handleExportPDF = () => {
        const fileName = `${new Date().toISOString().slice(0, 10)}_leads_report`;
    
        // Extract data from the table
        const table = document.getElementById('pos-table-day-report-data');
        const rows = Array.from(table.getElementsByTagName('tr')).slice(1); // Skip the header row
    
        const tableData = rows.map(row => {
            const cells = Array.from(row.getElementsByTagName('td'));
            return {
                leadNo: cells[0].innerText,
                leadName: cells[1].innerText,
                status: { name: cells[2].innerText },
                Enquiry: {
                    conferenceData: { name: cells[3].innerText },
                    district: { district: cells[4].innerText },
                    state: { stateName: cells[5].innerText }
                },
                salesPerson: { username: cells[6].innerText },
                rextFollowUp: cells[7].innerText
            };
        });
    
        handlePdfReportDownload(fileName, tableData); // Pass the extracted table data
    };
    

    return (
        <Modal show={show} size="xl" onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{ width: '100%', color: '#5CADA9' }}>Lead Export</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-end mb-3">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-excel">Download as Excel</Tooltip>}
                    >
                        <Button variant="success" onClick={handleExportExcel} className="me-2">
                            <FaFileExcel style={{ fontSize: '1.5em' }} />
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-pdf">Download as PDF</Tooltip>}
                    >
                        <Button variant="danger" onClick={handleExportPDF}>
                            <FaFilePdf style={{ fontSize: '1.5em' }} />
                        </Button>
                    </OverlayTrigger>
                </div>
                <div className="custom-table-responsive">
                    <table id="pos-table-day-report-data" className="table" >
                        <thead>
                            <tr>
                                <th>Lead Number</th>
                                <th>Lead Name</th>
                                <th>Status</th>
                                <th>Source</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Sales Person</th>
                                <th>Follow Up Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {exportDataList?.map((item) => (
                                <tr key={item?.id}>
                                    <td>{item?.leadNo}</td>
                                    <td>{item?.leadName}</td>
                                    <td>{item?.status?.name}</td>
                                    <td>{item?.Enquiry?.conferenceData?.name || 'N/A'}</td>
                                    <td>{item?.Enquiry?.district?.district || 'N/A'}</td>
                                    <td>{item?.Enquiry?.state?.stateName || 'N/A'}</td>
                                    <td>{item?.salesPerson?.username}</td>
                                    <td>{item?.rextFollowUp || 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LeadExportModal;
