import React, { useEffect } from 'react';
// import { BallTriangle } from 'react-loader-spinner'
import Modal from 'react-bootstrap/Modal';
import maxlogo from "../../img/MAX-Logo-Loader.gif";
import "./ballTriangle.css";

const BallTriangleComponent = ({ isshow }) => {
  useEffect(() => {
    console.log('isshow', isshow);

  }, [isshow])
  return (
    <>
      <Modal show={isshow} dialogClassName="transparent-modal" centered>
        <Modal.Body  >
          <div className='d-flex justify-content-center'   >
            {/* <BallTriangle 
                    height={100}
                    width={100}
                    radius={5}
                    color="#4fa94d"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    /> */}
            <img src={maxlogo} alt='Logo' />

          </div>
          <div className='d-flex justify-content-center'>
            <h6 className='m-3' style={{ color: "#1978BC", }}>Loading . . .</h6>
          </div>
        </Modal.Body>

      </Modal>
    </>
  )
}

export default BallTriangleComponent



