import React, { useContext, useEffect, useState } from 'react'
import axiosInstance from '../../../../api/axoiss'
import showErrorToast from '../../../../components/notifications/react-toastify/toast'
import DataContext from '../../../../context/ItemMasterContext'
import { noteCreateMutation } from '../../../../GraphQLQueries/ActivitiesQueryAndMutations/ActivitiesMutations'
import { removeEmptyValueInObject, ShowErrorNoties } from '../../../../utils/Utils'

const NoteActivitsForm = ({setShowForm,enquiry_id, setNavList, setLogList, setNoteEdit, noteEdit}) => {
    const {userId} = useContext(DataContext)
    const [initialValue, setInitialValue] = useState({
        id : "",
        note:"", 
        createdBy:""
    })
    useEffect(()=>{
       if (noteEdit !== null && noteEdit){
           setInitialValue({id: noteEdit?.id, note: noteEdit?.note, createdBy:noteEdit?.createdBy?.id})
          initialValue['id'] = noteEdit?.id
          initialValue['note'] = noteEdit?.note
          initialValue['createdBy'] = noteEdit?.createdBy?.id
       }
    },[noteEdit])

    const  handleSubmit = async(e)=>{
        e.preventDefault(); 
        if (initialValue?.note?.trim() !== "" && initialValue?.note !== null){
            let savedate = {
                id : initialValue?.id ? Number(initialValue?.id) : "",
                note: initialValue?.note,
                createdBy : initialValue?.id  ? Number(initialValue['createdBy']) : Number(userId) ,
                modifiedBy : initialValue?.id ? Number(userId) : ""
            }
            if (enquiry_id){
                savedate['enquiryId'] = Number(enquiry_id)
            }
            let ClearValue = removeEmptyValueInObject(savedate, ["id", "modifiedBy"])
            try {
                const response = await axiosInstance.post(`/itemmaster/graphql`, {
                    query: noteCreateMutation(ClearValue)
                });
                const responseData = response?.data?.data?.noteCreateMutation;
                if (responseData.success) {
                    setShowForm(false)
                    showErrorToast(true, "success", "", "Saved Successfully" )
                    // setMeetingEdit(null)
                    setNavList(prve=>prve.map(item=>({...item, isShow: false})))
                    if (enquiry_id) {
                        setLogList((prev) => {
                          let prev_ids = prev.map((item) => item?.note ? item.id : "").filter(item=> item !== "");
                          if (prev_ids.includes(responseData?.notesInstance?.id)) {
                              return prev.map((item) => {
                                  return  item?.note ? item.id === responseData?.notesInstance?.id
                                      ? responseData?.notesInstance
                                      : item : item ;
                              });
                          } else {
                              return [responseData?.notesInstance , ...prev];
                          }
                      });
                  }
                  
                    
                } else { 
                    let errorData = ShowErrorNoties(responseData.errors);
                    showErrorToast(true, "error", errorData,  )
                }
              } catch (error) {
                let errorData = ShowErrorNoties(error); 
                showErrorToast(true, "error", errorData,  )
              }

        } else{
            console.log("fill the name");
            
        }
        
        
        

    }
  return (
    <>
    <div>
       <form  >
            <div className="row">
                <div className="col-12 staan-input-group">
                    <input type='text' name='Note' value={initialValue?.note} onChange={(e)=>{setInitialValue(prve=>({...prve, note:e.target.value}))}}  className='w-100 staan-form-input ' required/>
                    <label htmlFor="Note" className='staan-form-input-label pt-0 px-1 mx-2'>Note <span className='text-danger'>*</span></label>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='text-end'>
                    <button type="button" className="btn btn-outline-danger btn-sm me-2"
                        onClick={()=>{  setShowForm(false); setNoteEdit(null);
                        setNavList(prve=>prve.map(item=>({...item, isShow: false})))
                        }} 
                        >Cancel</button>
                    <button type="submit" onClick={handleSubmit} className="btn btn-sm btn-outline-success shadow-sm" >Save</button>
                </div>
            </div>
            

       </form>


    </div>

    
    </>
  )
}

export default NoteActivitsForm