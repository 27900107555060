export const MRDetailsUpdateIssuedQtyById = (id, issued) => {
    let query = `mutation UpdateMRDetailById{
        materialRequestItemDetailsCreateMutation(id: ${id}, issuedQty: ${issued}){
            materialRequestItemDetail{
                id
                issuedQty
            }
        }
    }`
    return query;
}


export const FetchMRDetailsList = (id_list) => {
    let query = `query FetchMRItemDetails{
        materialRequestItemDetails(idList: [${id_list}]){
            items{
            id
            issuedQty
            qty
            partNumber{
                id
                itemName
                itemPartCode
                itemstockSet{
                    id
                    currentStock
                    store{
                    id
                    storeName
                    }
                }
            }
            uom{
                id
                name
            }
            hsnCode{
                id
                hsnCode
            }
            }
        }
    }`
    return query;
}