import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { AiOutlinePercentage } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { ItemMasterPurchaseOrderFetchQuery } from '../../../GraphQLQueries/ItemmasterQuery';
import { getItemComboDetails } from '../../../GraphQLQueries/QuatationqueryAndMutatiom/QuatationItemModalMutation';
import axiosInstance, { commanFetch } from '../../../api/axoiss';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import {
  checkNegativeNumber,
  DiscountAsValue,
  DiscountPercentage,
  ShowErrorNoties
} from '../../../utils/Utils'
import { parseAndGroupItemComboData } from './itemCombo'
import DataContext from '../../../context/ItemMasterContext'
import { quotationFormitemValidation } from '../../../validations/QuotationValidation'
import ItemCombo from './itemCombo'


const QuatationItemModel = ({
  stateValue,
  EditData,
  setEditData,
  onNewItem,
  QuotationItemDetailsShow,
  setQuotationItemDetailsShow,
  itemDetailsData,
  setItemDetailsData,
  isAfterOverallDiscount,
  currencyData,
  setIsSaveItemCombo
}) => {
  const { userId } = useContext(DataContext)
  const [initialItemQuatation, setInitialItemQuatation] = useState({
    partCode: '',
    partName: '',
    category: '',
    po_qty: 1,
    amount: '',
    uom: '',
    rate: '',
    gstRates: '',
    discount: '',
    afterDiscountValueForPerItem: '',
    description: '',
    partCodeLabel: '',
    partNameLabel: '',
    id: '',
    hsnId: '',
    index:
      itemDetailsData?.length > 0
        ? itemDetailsData[itemDetailsData.length - 1].index
        : 0,
    itemComboBool: '',
    itemComboItemDetails: [],
    createdBy: '',
    modifiedBy: ''
  })
  const [formKey, setFormKey] = useState(0)
  const [partCodeList, setPartCodeList] = useState([])
  const [partNameList, setPartNameList] = useState([])
  const [isPercentage, setIsPercentage] = useState(true)
  const [discount, setDiscount] = useState('0')
  const [showItemCombo, setShowItemCombo] = useState(false)
  const [lastUpdatedItemDetails, setlastUpdatedItemDetails] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectEditData, setSelectEditData] = useState({
    partCodeSelect: { value: '', label: '' },
    UomSelect: { value: '', label: '' },
    partNameSelect: { value: '', label: '' }
  })
  const [itemComboData, setItemComboData] = useState([])
  // Effect to initialize form state and select data when EditData changes; returns no value.
  useEffect(() => {
    if (EditData && EditData?.index) {
      setInitialItemQuatation({
        // Initialize state for item quotation with default values or existing data if editing
        partCode: EditData?.partCode?.value
          ? {
            value: EditData?.partCode?.value,
            label: EditData?.partCode?.label
          }
          : '',
        partName: EditData?.partName?.value
          ? {
            value: EditData?.partName?.value,
            label: EditData?.partName?.label
          }
          : '',
        category: EditData?.category,
        po_qty: EditData?.po_qty ? EditData?.po_qty : 1,
        amount: EditData?.amount ? EditData?.amount : 0,
        uom: EditData?.uom?.value
          ? { value: EditData?.uom?.value, label: EditData?.uom?.label }
          : '',
        rate: EditData?.rate ? EditData?.rate : '',
        gstRates: EditData?.gstRates ? EditData?.gstRates : '',
        discount: EditData?.discount
          ? (EditData?.discountPercentage
            ? `${EditData.discountPercentage}`
            : '') +
          (EditData?.discountValue
            ? `${currencyData?.currencySymbol}${EditData.discountValue}`
            : '')
          : '',
        discountPercentage: EditData?.discountPercentage
          ? EditData?.discountPercentage
          : '',
        discountValue: EditData?.discountValue ? EditData?.discountValue : '',
        // discount: EditData?.discount ? EditData?.discount.includes('%') ? `${EditData.discount}` : `${currencyData?.currencySymbol}${EditData.discount}` : '',
        afterDiscountValueForPerItem: EditData?.afterDiscountValueForPerItem
          ? EditData?.afterDiscountValueForPerItem
          : '',
        description: EditData?.description ? EditData?.description : '',
        hsnCode: EditData?.hsnCode ? EditData?.hsnCode : '',
        hsnId: EditData?.hsnId ? EditData?.hsnId : '',
        itemComboBool: EditData?.itemComboBool,
        itemMrp: EditData?.itemMrp ? EditData?.itemMrp : '',
        index: EditData?.index ? EditData?.index : '',
        id: EditData?.id ? EditData?.id : '',
        createdBy: EditData?.createdBy ? EditData?.createdBy : '',
        modifiedBy: EditData?.modifiedBy ? EditData?.modifiedBy : ''
      })
      setFormKey(prve => prve + 1)
      setSelectEditData({
        partCodeSelect: {
          value: EditData?.partCode?.value,
          label: EditData?.partCode?.label
        },
        UomSelect: { value: EditData?.uom?.value, label: EditData?.uom?.label },
        partNameSelect: {
          value: EditData?.partName?.value,
          label: EditData?.partName?.label
        }
      })
      setIsPercentage(
        EditData?.discountPercentage
          ? true
          : EditData?.discountValue
            ? false
            : true
      )
      // setDiscount(EditData?.discount ? (EditData?.discountPercentage ? `${EditData.discountPercentage}% ` : '') +
      //   (EditData?.discountValue ? `${currencyData?.currencySymbol}${EditData.discountValue}` : '')
      //   : '')
      setDiscount(
        EditData?.discountPercentage
          ? `${EditData.discountPercentage}`
          : EditData?.discountValue
            ? `${EditData.discountValue}`
            : '0'
      )
    }
  }, [EditData])
  // Function to calculate final value after discount based on the type of discount; returns the calculated final value.
  function FinalValue(discount, amount, isPer) {
    if (isPer) {
      return DiscountPercentage(
        Number(discount?.replace('%', '')),
        Number(amount)
      )
    } else {
      return DiscountAsValue(
        discount?.replace(currencyData?.currencySymbol, ''),
        amount
      )
    }
  }
  // Function to fetch options based on user input
  async function getOption(e, query, name) {
    if (query === 'ItemMasterPurchaseOrderFetchQuery') {
      if (name === 'PartNumber') {
        let perameter = `itemPartCode : "${e}",page:1, pageSize:100,  itemIndicatorsList:["Sell","Both"]`

        const response = await commanFetch(
          ItemMasterPurchaseOrderFetchQuery(perameter)
        )
        const responseData = response?.data?.items
        if (responseData.length > 0) {
          // Store detailed item data for later use
          setPartCodeList(
            responseData.map(item => ({
              value: item?.id,
              label: item?.itemPartCode,
              itemName: item?.itemName,
              uom: item?.itemUom,
              category: item?.category?.name,
              rate: item?.itemMrp,
              gstRate: item?.itemHsn?.gstRates?.rate,
              description: item?.description,
              hsnCode: item?.itemHsn?.hsnCode,
              hsnId: item?.itemHsn?.id,
              itemComboBool: item?.itemComboBool,
              itemMrp: item?.itemMrp,
              itemmaster: item?.id
            }))
          )
        }
      } else if (name === 'itemName') {
        let perameter = `itemName: "${e}" , page:1, pageSize:100,  itemIndicatorsList:["Sell","Both"]`
        const response = await commanFetch(
          ItemMasterPurchaseOrderFetchQuery(perameter)
        )
        const responseData = response?.data?.items
        if (responseData?.length > 0) {
          setPartNameList(
            responseData?.map(item => ({
              value: item?.id,
              label: item?.itemName,
              itemPartCode: item?.itemPartCode,
              uom: item?.itemUom,
              category: item?.category?.name,
              rate: item?.itemMrp,
              gstRate: item?.itemHsn?.gstRates?.rate,
              description: item?.description,
              hsnCode: item?.itemHsn?.hsnCode,
              hsnId: item?.itemHsn?.id,
              itemComboBool: item?.itemComboBool,
              itemMrp: item?.itemMrp,
              itemmaster: item?.id
            }))
          )
        }
      }
    }
  }
  // Function to handle quantity change and update amount accordingly
  const handleQtyChange = (e, setFieldValue, currentRate) => {
    const qty = e.target.value
    const rate = currentRate || 0 // Get the rate from the current form values
    const amount = qty * rate // Calculate amount
    setFieldValue('po_qty', qty) // Update Qty
    setFieldValue('amount', amount) // Update Amount
    setFieldValue('afterDiscountValueForPerItem', '')
    setFieldValue('discount', '')
    setFieldValue('discountPercentage', '')
    setDiscount('0')
  }
  // Function to handle form submission and validation; returns no value.
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const validationResponse = checkNegativeNumber(values, [
      'afterDiscountValueForPerItem',
      'amount',
      'rate',
      'po_qty',
      'discount',
      'discountPercentage',
      'discountValue'
    ])
    if (validationResponse) {
      showErrorToast(true, 'error', 'Value Should Not Be Negative', '')
      setSubmitting(false)
      return
    }
    values['index'] = EditData?.index ? values['index'] : values['index'] + 1
    values['createdBy'] = values?.createdBy ? values?.createdBy : Number(userId)
    values['modifiedBy'] = values?.id
      ? values?.modifiedBy
        ? values?.modifiedBy
        : Number(userId)
      : ''
    values['discount'] = discount
    if (stateValue === 'Tamil Nadu' || stateValue === 'Tamilnadu') {
      values['sgst'] = values['gstRates'] / 2
      values['cgst'] = values['gstRates'] / 2
      values['igst'] = 0
    } else {
      values['sgst'] = 0
      values['cgst'] = 0
      values['igst'] = values['gstRates']
    }
    if (isPercentage) {
      values['afterDiscountValueForPerItem'] =
        values?.afterDiscountValueForPerItem
          ? `${values?.afterDiscountValueForPerItem}`
          : ''
      values['discountPercentage'] = discount ? discount?.replace('%', '') : ''
      values['discountValue'] = ''
    } else {
      values['afterDiscountValueForPerItem'] =
        values?.afterDiscountValueForPerItem
          ? `${values?.afterDiscountValueForPerItem}`
          : ''
      values['discountPercentage'] = ''
      values['discountValue'] = discount
        ? discount?.replace(currencyData?.currencySymbol, '')
        : ''
    }
    // if (isAfterOverallDiscount) {
    //   values['discountPercentage'] = ''
    //   values['afterDiscountValueForPerItem'] = ''
    //   values['discountValue'] = ''
    //   values['discountPercentage'] = ''
    //   setAfterOverallDiscount(false)
    //   ClearDisCount(values,[])
    // }
    // else {
    //   onNewItem(values);
    // }
    onNewItem(values)
    if (values?.itemComboBool) {
      try {
        let reqBody = {
          itemmaster: values?.partCode?.value,
          amount: Number(values?.amount),
          qty: Number(values?.po_qty)
        }
        const response = await axiosInstance.post(`/itemmaster/graphql`, {
          query: getItemComboDetails(reqBody)
        })
        let responseData = response?.data?.data?.getItemComboItemDetails
        if (responseData.itemCombo) {
          const groupedData = parseAndGroupItemComboData(
            responseData?.itemComboData,
            values?.index
          )
          // values['itemComboItemDetails'] = groupedData
          setItemComboData(groupedData)

          setlastUpdatedItemDetails(values)
          setShowItemCombo(true)
        } else {
          let errorData = ShowErrorNoties(responseData.errors)
          showErrorToast(true, 'error', errorData)
        }
      } catch (error) {
        let errorData = ShowErrorNoties(error)
        console.log(errorData)
        showErrorToast(true, 'error', errorData)
      }
    } else {
      resetForm()
    }
    // values['index'] = values['index'] ? values['index'] + 1 : 1;
    // onNewItem(values);
    showErrorToast(true, 'success', '', 'Item Added Successfully....')
  }
  // Function to reset the form to its initial state; returns no value.
  const resetForm = () => {
    setInitialItemQuatation({
      partCode: '',
      partName: '',
      category: '',
      po_qty: '',
      amount: '',
      uom: '',
      rate: '',
      gstRates: '',
      discount: '',
      afterDiscountValueForPerItem: '',
      description: '',
      partCodeLabel: '',
      partNameLabel: '',
      itemMrp: '',
      id: '',
      index: '',
      createdBy: '',
      modifiedBy: ''
    })
    setSelectEditData({
      partCodeSelect: { value: '', label: '' },
      UomSelect: { value: '', label: '' },
      partNameSelect: { value: '', label: '' }
    })
    setPartCodeList([])
    setPartNameList([])
    setDiscount('0')
    setShowItemCombo(false)
    setlastUpdatedItemDetails('')
    setIsPercentage(true)
    setQuotationItemDetailsShow(false)
    setEditData(prev => ({
      ...prev,
      ItemEditData: []
    }))
  }
  return (
    <>
      {<BallTriangleLoading isshow={loading} />}
      <Modal
        show={QuotationItemDetailsShow}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header>
          <Modal.Title style={{ color: '#5CADA9' }}>
            <i
              className='fa-solid fa-arrow-left fa-sm me-2 cursor-pointer'
              onClick={resetForm}
            ></i>
            Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialItemQuatation}
            onSubmit={handleSubmit}
            validationSchema={quotationFormitemValidation}
            enableReinitialize
            key={formKey}
          >
            {({ errors, touched, setFieldValue, values, setValues }) => {
              return (
                <Form>
                  <div className='row '>
                    <div className='col-6 staan-input-group'>
                      <CustomSelect
                        setSelectEditValue={selectEditData?.partCodeSelect}
                        handleInputChange={e => {
                          getOption(
                            e,
                            'ItemMasterPurchaseOrderFetchQuery',
                            'PartNumber'
                          )
                        }}
                        options={partCodeList}
                        name='partCode'
                        handleOnChange={option => {
                          setValues(prev => ({
                            ...prev,
                            partCode: option ? option : null,
                            uom:
                              {
                                value: option?.uom?.id,
                                label: option?.uom?.name
                              } ?? null,
                            category: option?.category ?? null,
                            rate: option?.rate ?? null,
                            gstRates: option?.gstRate ?? null,
                            amount: values?.po_qty * option?.rate,
                            partName:
                              {
                                value: option?.value,
                                label: option?.itemName
                              } ?? null,
                            description: option?.description,
                            hsnCode: option?.hsnCode,
                            hsnId: option?.hsnId,
                            itemComboBool: option?.itemComboBool,
                            itemMrp: option?.itemMrp,
                            itemmaster: option?.itemmaster,
                            afterDiscountValueForPerItem: '',
                            discount: '',
                            discountPercentage: ''
                          }))
                          setSelectEditData(prev => ({
                            ...prev,
                            partCodeSelect: option ? option : null,
                            partNameSelect: {
                              value: option?.value,
                              label: option?.itemName
                            },
                            UomSelect: {
                              value: option?.uom?.id,
                              label: option?.uom?.name
                            }
                          }))
                          setDiscount('0')
                        }}
                        isClearable={true}
                      />
                      <label className='staan-form-input-label pt-1 px-1 mx-2'>
                        Part Code<span className='text-danger'>*</span>
                      </label>
                      {touched.partCode && errors.partCode && (
                        <small className='staan-error-msg'>
                          {errors.partCode}
                        </small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <CustomSelect
                        setSelectEditValue={selectEditData?.partNameSelect}
                        handleInputChange={e => {
                          getOption(
                            e,
                            'ItemMasterPurchaseOrderFetchQuery',
                            'itemName'
                          )
                        }}
                        options={partNameList}
                        name='partName'
                        handleOnChange={option => {
                          setValues(prev => ({
                            ...prev,
                            partName: option ? option : null,
                            uom:
                              {
                                value: option?.uom?.id,
                                label: option?.uom?.name
                              } ?? null,
                            category: option?.category ?? null,
                            rate: option?.rate ?? null,
                            gstRates: option?.gstRate ?? null,
                            amount: values?.po_qty * option?.rate,
                            partCode:
                              {
                                value: option?.value,
                                label: option?.itemPartCode
                              } ?? null,
                            description: option?.description,
                            hsnCode: option?.hsnCode,
                            hsnId: option?.hsnId,
                            itemComboBool: option?.itemComboBool,
                            itemMrp: option?.itemMrp,
                            itemmaster: option?.itemmaster,
                            afterDiscountValueForPerItem: '',
                            discount: '',
                            discountPercentage: ''
                          }))
                          setSelectEditData(prev => ({
                            ...prev,
                            partCodeSelect: {
                              value: option?.value,
                              label: option?.itemPartCode
                            },
                            partNameSelect: option ? option : null,
                            UomSelect: {
                              value: option?.uom?.id,
                              label: option?.uom?.name
                            }
                          }))
                          setDiscount('0')
                        }}
                      />
                      <label

                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Part Name<span className='text-danger'>*</span>
                      </label>
                      {touched.partName && errors.partName && (
                        <small className='staan-error-msg'>
                          {errors.partName}
                        </small>
                      )}
                    </div>
                    <div className='col-12 staan-input-group'>
                      <Field
                        type='textarea'
                        name='description'
                        id='description'
                        className='w-100 staan-form-input'
                        onChange={e =>
                          setFieldValue('description', e.target.value)
                        }
                      />
                      <label
                        htmlFor='description'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Description<span className='text-danger'>*</span>
                      </label>
                      {touched.description && errors.description && (
                        <small className='staan-error-msg'>
                          {errors.description}
                        </small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='category'
                        id='category'
                        disabled
                        className='w-100 staan-form-input'
                      />
                      <label
                        htmlFor='category'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Category<span className='text-danger'>*</span>
                      </label>
                      {touched.category && errors.category && (
                        <small className='staan-error-msg'>
                          {errors.category}
                        </small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='po_qty'
                        id='po_qty'
                        className='w-100 staan-form-input'
                        onChange={e =>
                          handleQtyChange(e, setFieldValue, values.rate)
                        }
                      />
                      <label
                        htmlFor='po_qty'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        {' '}
                        Qty<span className='text-danger'>*</span>
                      </label>
                      {touched.po_qty && errors.po_qty && (
                        <small className='staan-error-msg'>
                          {errors.po_qty}
                        </small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <CustomSelect
                        setSelectEditValue={selectEditData?.UomSelect}
                        name='uom'
                        id='uom'
                      />
                      <label className='staan-form-input-label pt-1 px-1 mx-2'>
                        UOM<span className='text-danger'>*</span>
                      </label>
                      {touched.uom && errors.uom && (
                        <small className='staan-error-msg'>{errors.uom}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='rate'
                        id='rate'
                        placeholder='Enter Rate'
                        className='w-100 staan-form-input'
                        onChange={e => {
                          setValues(prev => ({
                            ...prev,
                            rate: e?.target?.value,
                            amount:
                              Number(e?.target?.value) * Number(values?.po_qty),
                            afterDiscountValueForPerItem: 0,
                            discount: isPercentage ? `0` : `0`
                          }))
                          setDiscount(isPercentage ? `0` : `0`)
                        }}
                      />
                      <label
                        htmlFor='rate'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Rate<span className='text-danger'>*</span>
                      </label>
                      {touched.rate && errors.rate && (
                        <small className='staan-error-msg'>{errors.rate}</small>
                      )}
                    </div>
                    {/* <div className="col-3 staan-input-group text-center text-primary" style={{ display: 'flex', alignItems: 'center' }}>
                      <Field type='text' name='discount' id='discount' placeholder='Enter discount'
                        value={discount} className='w-100 staan-form-input'
                        onChange={(e) => {
                          setDiscount(e.target.value)
                          // setFieldValue('discount', e.target.value)
                          let discountAmount = FinalValue(e.target.value, Number(values.rate), isPercentage ? true : false);

                          if (discountAmount === undefined || discountAmount === "") {

                            setValues(prev => ({
                              ...prev,
                              amount: Number(values.rate * values.po_qty),
                              afterDiscountValueForPerItem: '',
                              rate: Number(values.rate).toFixed(2)
                            }))
                          } else {
                            setValues(prev => ({
                              ...prev, amount: Number(discountAmount) * Number(values.po_qty)?.toFixed(3),
                              afterDiscountValueForPerItem: discountAmount, rate: Number(values.rate)?.toFixed(2)
                            }))
                          }
                          setFieldValue('discount', `${e.target.value}`)
                        }}
                        disabled={isAfterOverallDiscount ? true : values?.rate === '' ? true : values.rate === 0 ? true : false}
                      // disabled={values?.rate === '' ? true : values.rate == 0 ? true : false}
                      />

                      <label htmlFor="discount" className='staan-form-input-label pt-1 px-1 mx-2' >Discount Per Qty
                      </label>
                      <RxCross1 className='cursor-pointer ms-2' style={{ top: "2rem", left: "9rem", position: "absolute", color: 'black' }}
                        onClick={() => {
                          setValues(prev => ({
                            ...prev,
                            amount: Number(values.rate * values.po_qty),
                            discount: "",
                            afterDiscountValueForPerItem: Number(values?.rate)
                          }))
                          setDiscount("")
                          setDiscount("")

                        }}
                      />
                      {
                        isPercentage ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="button-tooltip">
                                Click For Fixed Value Discount
                              </Tooltip>
                            }
                          >
                            <div style={{ marginLeft: "0.3rem", marginTop: '1rem' }}>
                              <AiOutlinePercentage
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1.5rem", // Adjust this for height/width
                                  top: '1rem'
                                }}
                                onClick={() => {
                                  setIsPercentage(false);
                                  const newValue = `${discount?.replace("₹", "")}`;
                                  setDiscount(newValue);
                                  let discountAmount = FinalValue(newValue, Number(values.rate * values.po_qty), false);
                                  setValues(prev => ({
                                    ...prev,
                                    amount: discountAmount.toFixed(3),
                                    afterDiscountValueForPerItem: Number(discountAmount / values.po_qty).toFixed(3),
                                  }));
                                }}
                              />
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="button-tooltip">
                                Click For Percentage Discount
                              </Tooltip>
                            }
                          >
                            <div style={{ marginLeft: "1rem", marginTop: '1rem' }}>
                              <p
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1.5rem", // Adjust this for height/width
                                  marginBottom:'0',
                                  marginLeft:'0'
                                  // marginLeft: "1rem",
                                  // marginTop: "1.5rem", // Optional: Adjust this for spacing
                                }}
                                onClick={() => {
                                  setIsPercentage(true);
                                  const newValue = `${discount?.replace("%", "")}`;
                                  setDiscount(newValue);
                                  let discountAmount = FinalValue(newValue, Number(values?.rate), true);
                                  setValues(prev => ({
                                    ...prev,
                                    amount: discountAmount.toFixed(3),
                                    afterDiscountValueForPerItem: Number(discountAmount / values.po_qty).toFixed(3),
                                    rate: String(values?.rate),
                                  }));
                                }}
                              >
                                {currencyData?.currencySymbol}
                              </p>
                            </div>
                          </OverlayTrigger>
                        )
                      }
                    </div> */}
                    <div
                      className='col-3 staan-input-group text-center text-primary'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Field
                        type='text'
                        name='discount'
                        id='discount'
                        placeholder='Enter discount'
                        value={discount}
                        className='w-100 staan-form-input'
                        onChange={e => {
                          setDiscount(e.target.value)
                          // setFieldValue('discount', e.target.value)
                          let discountAmount = FinalValue(
                            e.target.value,
                            Number(values.rate),
                            isPercentage ? true : false
                          )

                          if (
                            discountAmount === undefined ||
                            discountAmount === ''
                          ) {
                            setValues(prev => ({
                              ...prev,
                              amount: Number(values.rate * values.po_qty),
                              afterDiscountValueForPerItem: '',
                              rate: Number(values.rate).toFixed(2)
                            }))
                          } else {
                            setValues(prev => ({
                              ...prev,
                              amount:
                                Number(discountAmount) *
                                Number(values.po_qty)?.toFixed(3),
                              afterDiscountValueForPerItem: discountAmount,
                              rate: Number(values.rate)?.toFixed(2)
                            }))
                          }
                          setFieldValue('discount', `${e.target.value}`)
                        }}
                        disabled={
                          isAfterOverallDiscount
                            ? true
                            : values?.rate === ''
                              ? true
                              : values.rate === 0
                                ? true
                                : false
                        }
                      // disabled={values?.rate === '' ? true : values.rate == 0 ? true : false}
                      />

                      <label
                        htmlFor='discount'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Discount Per Qty
                      </label>
                      <RxCross1
                        className='cursor-pointer ms-2'
                        style={{
                          top: '2rem',
                          left: '9rem',
                          position: 'absolute',
                          color: 'black'
                        }}
                        onClick={() => {
                          setValues(prev => ({
                            ...prev,
                            amount: Number(values.rate * values.po_qty),
                            discount: '',
                            afterDiscountValueForPerItem: Number(values?.rate)
                          }))
                          setDiscount('')
                          setDiscount('')
                        }}
                      />
                      {isPercentage ? (
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip id='button-tooltip'>
                              Click For Fixed Value Discount
                            </Tooltip>
                          }
                        >
                          <div
                            style={{ marginLeft: '0.3rem', marginTop: '1rem' }}
                          >
                            <AiOutlinePercentage
                              style={{
                                cursor: 'pointer',
                                fontSize: '1.5rem', // Adjust this for height/width
                                top: '1rem'
                              }}
                              onClick={() => {
                                setIsPercentage(false)
                                const newValue = `${discount?.replace('₹', '')}`
                                setDiscount(newValue)
                                let discountAmount = FinalValue(
                                  newValue,
                                  Number(values.rate * values.po_qty),
                                  false
                                )
                                setValues(prev => ({
                                  ...prev,
                                  amount: discountAmount.toFixed(3),
                                  afterDiscountValueForPerItem: Number(
                                    discountAmount / values.po_qty
                                  ).toFixed(3)
                                }))
                              }}
                            />
                          </div>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip id='button-tooltip'>
                              Click For Percentage Discount
                            </Tooltip>
                          }
                        >
                          <div
                            style={{ marginLeft: '1rem', marginTop: '1rem' }}
                          >
                            <p
                              style={{
                                cursor: 'pointer',
                                fontSize: '1.5rem', // Adjust this for height/width
                                marginBottom: '0',
                                marginLeft: '0'
                                // marginLeft: "1rem",
                                // marginTop: "1.5rem", // Optional: Adjust this for spacing
                              }}
                              onClick={() => {
                                setIsPercentage(true)
                                const newValue = `${discount?.replace('%', '')}`
                                setDiscount(newValue)
                                let discountAmount = FinalValue(
                                  newValue,
                                  Number(values?.rate),
                                  true
                                )
                                setValues(prev => ({
                                  ...prev,
                                  amount: discountAmount.toFixed(3),
                                  afterDiscountValueForPerItem: Number(
                                    discountAmount / values.po_qty
                                  ).toFixed(3),
                                  rate: String(values?.rate)
                                }))
                              }}
                            >
                              {currencyData?.currencySymbol}
                            </p>
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                    {/* <div className='col-1 mt-3 text-center text-primary'>
                      {
                        isPercentage ?
                          <AiOutlinePercentage
                            style={{
                              cursor: "pointer",
                              fontSize: "1rem", // Adjust this for height/width
                            }}
                            onClick={() => {
                              setIsPercentage(false);
                              const newValue = `${discount?.replace("₹", "")}`;
                              setDiscount(newValue);
                              let discountAmount = FinalValue(newValue, Number(values.rate * values.po_qty), false);
                              setValues(prev => ({
                                ...prev,
                                amount: discountAmount.toFixed(3),
                                afterDiscountValueForPerItem: Number(discountAmount / values.po_qty).toFixed(3),
                              }))
                            }}
                          /> :
                          <p
                            style={{
                              cursor: "pointer",
                              fontSize: "1rem", // Adjust this for height/width
                              margin: "0", // Optional: Remove margin if needed
                            }}
                            onClick={() => {
                              setIsPercentage(true)
                              const newValue = `${discount?.replace("%", "")}`;
                              console.log("newValue", newValue);

                              setDiscount(newValue);
                              let discountAmount = FinalValue(newValue, Number(values?.rate), true);
                              console.log("discountAmount", discountAmount);
                              setValues(prev => ({
                                ...prev,
                                amount: discountAmount.toFixed(3),
                                afterDiscountValueForPerItem: Number(discountAmount / values.po_qty).toFixed(3),
                                rate: String(values?.rate)
                              }))
                            }
                            }>
                            {currencyData?.currencySymbol}
                          </p>
                      }
                    </div> */}

                    <div className='col-3 staan-input-group'>
                      <Field
                        type='text'
                        name='afterDiscountValueForPerItem'
                        id='afterDiscountValueForPerItem'
                        disabled
                        placeholder='Enter discount'
                        className='w-100 staan-form-input'
                      />
                      <label
                        htmlFor='discount'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Rate After Discount
                      </label>
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='gstRates'
                        id='gstRates'
                        disabled
                        className='w-100 staan-form-input'
                      />
                      <label
                        htmlFor='gstRates'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Tax<span className='text-danger'>*</span>
                      </label>
                      {touched.gstRates && errors.gstRates && (
                        <small className='staan-error-msg'>
                          {errors.gstRates}
                        </small>
                      )}
                    </div>
                    <div>
                      {touched.discount && errors.discount && (
                        <small>{errors.discount}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='amount'
                        id='amount'
                        disabled
                        className='w-100 staan-form-input'
                      />
                      <label
                        htmlFor='amount'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Amount<span className='text-danger'>*</span>
                      </label>
                      {touched.amount && errors.amount && (
                        <small className='staan-error-msg'>
                          {errors.amount}
                        </small>
                      )}
                    </div>
                    <div className='row mt-2'>
                      <div className='text-end'>
                        <button
                          type='button'
                          className='btn btn-outline-danger btn-sm me-2 '
                          onClick={() => {
                            if (setQuotationItemDetailsShow) {
                              setQuotationItemDetailsShow(false)
                            }
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-sm btn-outline-success shadow-sm px-3'
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <ItemCombo
                    IsshowItemCombo={showItemCombo}
                    setshowItemCombo={setShowItemCombo}
                    lastUpdatedItemDetails={lastUpdatedItemDetails}
                    itemComboData={itemComboData}
                    setItemComboData={setItemComboData}
                    setItemDetailsData={setItemDetailsData}
                    itemDetailsData={itemDetailsData}
                    setQuotationItemDetailsShow={setQuotationItemDetailsShow}
                    setIsSaveItemCombo={setIsSaveItemCombo}
                  />
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default QuatationItemModel
