import React from 'react';
import Modal from 'react-bootstrap/Modal';

const WaringModel = ({ state, setstate, title, text, Confirmtion }) => {
    const handleClose = () => {
        if (setstate) {
            setstate(false)
        }

    }

    return (
        <>
            <Modal
                show={state}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {text}.
                </Modal.Body>
                <Modal.Footer>
                    <div className='text-end'>
                        <button
                            type='button'
                            className='btn btn-outline-danger btn-sm me-2 px-3'
                            onClick={handleClose}
                        >
                            No
                        </button>
                        <button
                            type='submit'
                            className='btn btn-sm btn-outline-success shadow-sm px-3'
                            onClick={() => { Confirmtion(true); handleClose() }}
                        >
                            Yes
                        </button>
                    </div>
                    {/* <Button variant="outline-warning" size="sm" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="outline-success" size="sm" onClick={() => { Confirmtion(true); handleClose() }} >Yes</Button> */}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default WaringModel