import React, { useContext, useState } from 'react';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import DataContext from '../../context/ItemMasterContext';
import TableView from '../../Packages/Custom/DataTable';
import AddAccount from '../../Page/ItemMaster/model/AddAccount';
import AddAccountGroup from '../../Page/ItemMaster/model/AddAccountGroup';

const AccountMasterlist = () => {
    const { handlAccountShow, setAccountGroupType} =  useContext(DataContext)
    const [accountEditId , setAccountEditId ] = useState('')
    const [isLoad, setLoad] = useState(false)
    
 
  const CustomViewModalComponent = (data)=>{
    handlAccountShow(); 
    setAccountGroupType('all')
    setAccountEditId(data);
  }

 
    const headers = [
      {
        header: 'Accounts Name',
        field: 'accountsName', 
        flex: 10,
        query: 'accountsMasterOptionQueryForTable',
        inputType: 'text',
        renderComponent: CustomViewModalComponent,
      },
      {
        header: 'Accounts Group',
        field: 'accountsGroupName', 
        label : "accountsGroupName",
        flex: 10,
        query: 'accountGroupOptionforTable',
        inputType: 'text', 
      },
      {
        header: 'GST Applicable',
        field: 'gstApplicable', 
        flex: 10, 
        align: 'center', 
        option: true,
        bool: true,
        inputType: 'text',
      },
      {
        header: 'TDS',
        field: 'tds', 
        flex: 10, 
        align: 'center',
        option: true,
        bool: true,
        inputType: 'text',
      },
      {
        header: 'Active',
        field: 'accountsActive', 
        flex: 10,
        option: true,
        bool: true,
        inputType: 'text',
        
      }
    ]
    const CustomAddModalComponent = () => {
      handlAccountShow();  setAccountGroupType('all')
    }
  return (
     <>
        <div className=' index_page_aliment_warp'>
          <div className='index_page_aliment_sidebar'>
              <SideNavbar />
          </div>
          <div className='index_page_aliment_table'>
            <TableView
            headers={headers}
            query = {'AccountMasterTAbleQuery'}
            addComponent = {CustomAddModalComponent}
            loadComponent = {isLoad}
            titleName = {'Accounts Master'}
            heightAdjust = {'40rem'}
            permission={"Save_Accounts_Master"}
            customFilter={true}
            />
          </div>
          
        </div>
    
     <div className='container-lg'>
    
       
     <AddAccount
     accountEditId = {accountEditId}
     setAccountEditId= {setAccountEditId}
     setLoad = {()=>{setLoad(!isLoad)}} />
 
     <AddAccountGroup/>
     </div>
     
     </>
  )
}

export default AccountMasterlist