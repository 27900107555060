import React, { useEffect, useState } from 'react';
import { mixWithCalllogAndMeeting } from '../../../GraphQLQueries/ActivitiesQueryAndMutations/CallQueries';
import { commanFetch } from '../../../api/axoiss';
import ListOfActivites from './ListOfActivites';
import ActivitesFormManager from './form/ActivitesFormManager';


const Activities = ({enquiry_id, show, salesMan}) => { 

  const [navList, setNavList] = useState([ 
    {
        id:1,
        name:"Call Log",
        isShow:false,
    },{
        id:2,
        name:"Meeting",
        isShow:false,
    },{
        id:3,
        name:"Notes",
        isShow:false,
    },{
        id:4,
        name:"Emails",
        isShow:false,
    }])
  const [showForm , setShowForm] = useState(false)  
  const [callEdit, setCallEdit] = useState(null)
  const [meetingEdit, setMeetingEdit] =  useState(null) 
  const [noteEdit, setNoteEdit] =  useState(null) 
  const [logList , setLogList] = useState([])
  
  
  async function CallLog(params) {
    let queriesParams = ""
    if (enquiry_id){
        queriesParams = `isEnquiry: true, id: ${enquiry_id}`
    } 
    if (queriesParams){
        try{
            const response = await commanFetch(mixWithCalllogAndMeeting(queriesParams));
            const reponseData = response?.data 
            setLogList(reponseData) 
         } catch(error){
    
         }
         
    }
    
  }

 
  useEffect(()=>{  
     
     if (show){
        if (enquiry_id?.trim() !== ""&& enquiry_id?.trim() !== undefined && enquiry_id?.trim() !== null  ){
            CallLog(enquiry_id)
        } else{
            
        }
     }
    
  },[show,   ])
  return (
    <>
        <div className='Activites'>
           
                <>
                <ul className='nav nav-tabs' >
                    {navList.map(({id, name, isShow})=>(
                        <li className={isShow? "nav-link active": "nav-link"} 
                            onClick={() => {
                            setNavList(prev => 
                            prev.map(item => ({
                                ...item,
                                isShow: item.id === id ? true : false
                            })))
                            setShowForm(true)
                        }} key={id}>
                            <span role='button'  >{name}</span>
                        </li>
                    ))}
                </ul>
                { showForm  ?
                    <ActivitesFormManager
                    setShowForm ={setShowForm} 
                    enquiry_id={enquiry_id} 
                    callEdit={callEdit}
                    setCallEdit={setCallEdit}
                    meetingEdit = {meetingEdit}
                    setMeetingEdit = {setMeetingEdit}
                    navList={navList}
                    setNavList= {setNavList} 
                    setLogList = {setLogList}
                    setNoteEdit = {setNoteEdit}
                    noteEdit = {noteEdit}
                    salesMan = {salesMan}
                    /> : 
                    <div className='mt-2'>
                    <ListOfActivites
                    navList={navList}
                    setNavList = {setNavList}
                     
                    setCallEdit={setCallEdit}
                    setMeetingEdit = {setMeetingEdit}
                    setShowForm={setShowForm}
                    logList = {logList}
                    setNoteEdit = {setNoteEdit}
                   
                    />
                    </div>
                    
                }
            
              
                </>
                
                
             
            
          
            
            
        
        </div>
    
    </>
  )
}

export default Activities