import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';

const ShowUnsaveditemcombo = ({showComboUnSaved, setshowComboUnSaved,itemComboUnSavedList }) => {
  return (
     <> 
      <Offcanvas show={showComboUnSaved} onHide={()=>{setshowComboUnSaved(false)}} scroll = {true} backdrop ={true}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Pick Batch or Serial Number</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div>
          <table>
            <thead>
              <tr>
                <th className='p-1'>Part Code</th>
            
              </tr>
            </thead>
            <tbody>
              {itemComboUnSavedList.map((item, index) => (
                <>  
                  
                  <tr key={index}>
        
                    <td className='p-1'>{item.part}</td>
                     
                  </tr>

                </>
               
              ))}
            </tbody>
          </table>
        </div>  

        </Offcanvas.Body>
      </Offcanvas>
     
     </>
  )
}

export default ShowUnsaveditemcombo