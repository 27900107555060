import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { countries_list } from '../../../components/CountryWithStates'
import BallTriangleLoading from "../../../components/loading/BallTriangle"
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import CustomSelect from '../../../components/ReactSelect/reactSelect'
import WaringModel from '../../../components/Warings/WaringModel'
import DataContext from '../../../context/ItemMasterContext'
import { ShowErrorNoties } from '../../../utils/Utils'
import { AddressFromSchema } from '../../../validations/itemmaster'

const AddressFrom = ({
  addressadd,
  handleaddressFromAddClose,
  EditAddress,
  setEditAddress,
  disableCondition

}) => {
  const { AddressPost, setAddressPost, setAddressdata } = useContext(DataContext)
  const [stateOptions, setStateOptions] = useState([])
  const AddresstypeRef = useRef(null)
  const Address_Line_1Ref = useRef(null)
  const Address_Line_2Ref = useRef(null)
  const CityRef = useRef(null)
  const pincodeRef = useRef(null)
  const [isDeleteWarningShow, setIsDeleteWarningShow] = useState(false)
  const [formKey, setFormKey] = useState(0)
  const [loading, setLoading] = useState(false)



  //
  const [initialValues, setinitialValues] = useState({
    id: '',
    address_type: '',
    Address_Line_1: '',
    Address_Line_2: '',
    City: '',
    pincode: '',
    State: '',
    Country: '',
    Default: false
  })

  const address_types = useMemo(() => [
    { value: 'Billing Address', label: 'Billing Address' },
    { value: 'Shipping Address', label: 'Shipping Address' },
    { value: 'Others', label: 'Others' }
  ], []);

  const [addressTypeOption, setAddressTypeOption] = useState(address_types)
  // This useEffect updates the form based on EditAddress. 
  // It filters address types and sets form values if EditAddress is provided, otherwise, it resets the form. It doesn't return any value.
  useEffect(() => {
    let selected_address_types = AddressPost?.map(item => item.address_type);
    let filtered_address_type = address_types.filter(item => !selected_address_types.includes(item.value));
    setAddressTypeOption(filtered_address_type)
    if (EditAddress !== '' && EditAddress !== undefined) {
      console.log("EditAddress>>>>>>>", EditAddress);

      setinitialValues({
        id: EditAddress['id'] ? EditAddress['id'] : '',
        index: EditAddress['index'],
        address_type: EditAddress['address_type'],
        Address_Line_1: EditAddress['Address_Line_1'],
        Address_Line_2: EditAddress['Address_Line_2'],
        City: EditAddress['City'],
        pincode: EditAddress['pincode'],
        State: EditAddress['State'],
        Country: EditAddress['Country'],
        Default: EditAddress['Default'],
      })
      setFormKey(prev => prev + 1)
      setDefault(EditAddress['Default'])
      setAddresstype({
        value: EditAddress['address_type'],
        label: EditAddress['address_type']
      })
      setAddressLine1(EditAddress['Address_Line_1'])
      setAddressLine2(EditAddress['Address_Line_2'])
      setCity(EditAddress['City'])
      setpincode(EditAddress['pincode'])

      handleonChangeCountrylist({
        value: EditAddress['Country'],
        label: EditAddress['Country']
      })
      setSelectedState({
        value: EditAddress['State'],
        label: EditAddress['State']
      })
    } else {
      setinitialValues({
        id: '',
        address_type: '',
        Address_Line_1: '',
        Address_Line_2: '',
        City: '',
        pincode: '',
        State: '',
        Country: '',
        Default: false
      })
      setFormKey(prev => prev + 1)
      // initialValues.address_type =   ""
      // initialValues.Address_Line_1 =  ""
      // initialValues.Address_Line_2 =  ""
      // initialValues.City =  ""
      // initialValues.pincode =   ""
      // initialValues.State =  ""
    }
  }, [EditAddress])

  const [addresstype, setAddresstype] = useState('')
  // handleonChangeaddresstype: Updates the address type state when the user selects a new address type. 
  const handleonChangeaddresstype = value => {
    setAddresstype(value)
  }
  const [AddressLine1, setAddressLine1] = useState('')
  // handleonChangeAddressLine1: Updates the Address Line 1 state when the user enters a new value. 
  const handleonChangeAddressLine1 = value => {
    setAddressLine1(value)
  }
  const [AddressLine2, setAddressLine2] = useState('')
  // handleonChangeAddressLine2: Updates the Address Line 2 state when the user enters a new value. 
  const handleonChangeAddressLine2 = value => {
    setAddressLine2(value)
  }

  const [City, setCity] = useState('')
  // handleonChangeCity: Updates the City state when the user enters a new value. 
  const handleonChangeCity = value => {
    setCity(value)
  }
  const [pincode, setpincode] = useState('')
  // handleonChangepincode: Updates the Pincode state when the user enters a new value. 
  const handleonChangepincode = value => {
    setpincode(value)
  }

  const [Countrylist, setCountryList] = useState('')
  // handleonChangeCountrylist: Updates the Country list state and fetches regions based on the selected country. 
  const handleonChangeCountrylist = Option => {
    const regionsList = countries_list.find(
      Country => Country.countryName === Option.value
    )?.regions

    setStateOptions(regionsList)

    setCountryList({ value: Option.value, label: Option.label })
  }

  const [selectedState, setSelectedState] = useState('')
  // handleonChangeState: Updates the selected state when the user selects a new state. 
  const handleonChangeState = Option => {
    setSelectedState({ value: Option.value, label: Option.label })
  }

  const [isDefault, setDefault] = useState(false)

  const CountryOptions = countries_list.map(Country => Country.countryName)
// resetData: Resets all form states to their initial values. 
  function resetData() {
    setAddresstype('')
    setAddressLine1('')
    setAddressLine2('')
    setStateOptions([])
    setCity('')
    setpincode('')
    setCountryList('')
    setSelectedState('')
    setDefault(false)
    setEditAddress('')
  }
// handleSubmit: Handles form submission, validates the pincode, and either updates an existing address or adds a new address to the AddressPost state. It doesn't return any value. 
  const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
    setLoading(true)
    console.log("values--", values?.pincode.length);

    if (values?.pincode.length !== 6) {
      showErrorToast(true, "error", `Enter Valid Pin Code`);
      setSubmitting(false);
      setLoading(false)
      return;
    }
    console.log("AddressPost", AddressPost);
    if (AddressPost.length === 0) {
      // Set index for the first item to 0
      const newAddress = { ...values, index: 0 };
      setAddressPost([newAddress]);
      setAddressdata([newAddress])
    } else {
      // If AddressPost is not empty, check if index exists in the values
      const index = AddressPost.findIndex(address => address.index === values.index);

      if (index !== -1) {
        // If an existing address is found, update that address
        const updatedAddressPost = [...AddressPost];
        updatedAddressPost[index] = values;

        // If the current address is default, make sure other defaults are set to false
        if (values.Default) {
          updatedAddressPost.forEach((address, i) => {
            if (i !== index) {
              address.Default = false;
            }
          });
        }

        setAddressPost(updatedAddressPost);
        setAddressdata(updatedAddressPost)
      } else {
        // If no existing address with index is found, treat it as a new address
        const newIndex = AddressPost.length; // Create a new index
        const newAddress = { ...values, index: newIndex }; // Add the new address with the index

        if (values.Default) {
          // If this is the default address, ensure all others are marked false
          AddressPost.forEach(address => {
            address.Default = false;
          });
        }

        setAddressPost([...AddressPost, newAddress]);
        setAddressdata([...AddressPost, newAddress])
      }
    }
    resetForm()
    resetData()
    handleaddressFromAddClose()
    setEditAddress('')
    setLoading(false)
  }
// DeleteTheAddressForm: Deletes the address with the specified index from the AddressPost state. It doesn't return any value.
  const DeleteTheAddressForm = async () => {
    try {
      const updatedAddressPost = AddressPost?.filter(address => address.index !== EditAddress?.index);

      // Update the state with the filtered array
      setAddressPost(updatedAddressPost);
      handleaddressFromAddClose()
      resetData()
      // Optionally log the updated AddressPost
      console.log("Updated AddressPost:", updatedAddressPost);

    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, "error", errorFilter)
    }
  };
  return (
    <>
      {<BallTriangleLoading isshow={loading} />}
      <Modal show={addressadd} size='lg'
        onHide={() => {
          handleaddressFromAddClose()
          resetData()
        }}
      >
        <div className="row mx-1">
          <div className="col-5 m-2" style={{ color: "#5CADA9" }}>
            <Modal.Title>
              <i className="fa-solid fa-arrow-left fa-sm me-2" onClick={() => {
                handleaddressFromAddClose()
                resetData()
              }}></i>
              Address Form
            </Modal.Title>
          </div>
          <div className="col-6 mt-3 text-end" hidden={!EditAddress}>
            <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3" onClick={() => { setIsDeleteWarningShow(true) }} ></i>
          </div>
        </div>
        <hr className='split_border' />
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={AddressFromSchema}
            onSubmit={handleSubmit}
            enableReinitialize key={formKey}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                {/* New UI design starts */}
                <div>
                  <div className='row'>
                    <div className='col-6 staan-input-group'>
                      <CustomSelect
                        setSelectEditValue={addresstype}
                        ref={AddresstypeRef}
                        defaultValue={
                          EditAddress ? EditAddress['addressType'] : ''
                        }
                        options={addressTypeOption}
                        name='address_type'
                        handleOnChange={(selectedOption) => {
                          handleonChangeaddresstype(selectedOption)
                          setFieldValue('address_type', selectedOption.value)
                        }}
                        isDisabled={disableCondition}
                      />
                      <label
                        htmlFor='Addresstype' onClick={() => { console.log(AddressPost) }
                        }
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Address type
                      </label>
                      {touched.address_type && errors.address_type && (
                        <small>{errors.address_type}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='Address_Line_1'
                        id="Address_Line_1"
                        placeholder=''
                        ref={Address_Line_1Ref}
                        value={AddressLine1}
                        defaultValue={
                          EditAddress ? EditAddress['addressLine1'] : ''
                        }
                        className='w-100 staan-form-input'
                        onChange={e => {
                          handleonChangeAddressLine1(e.target.value)
                          setFieldValue('Address_Line_1', e.target.value)
                        }}
                        disabled={disableCondition}
                      />
                      <label
                        htmlFor='Address_Line_1' onClick={() => {
                          console.log("values", values);
                        }}
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Address Line 1
                      </label>
                      {touched.Address_Line_1 && errors.Address_Line_1 && (
                        <small>{errors.Address_Line_1}</small>
                      )}
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='Address_Line_2'
                        id="Address_Line_2"
                        ref={Address_Line_2Ref}
                        placeholder=''
                        defaultValue={
                          EditAddress ? EditAddress['addressLine2'] : ''
                        }
                        value={AddressLine2}
                        className='w-100 staan-form-input'
                        onChange={e => {
                          handleonChangeAddressLine2(e.target.value)
                          setFieldValue('Address_Line_2', e.target.value)
                        }}
                        disabled={disableCondition}
                      />
                      <label
                        htmlFor='Address_Line_2' onClick={() => {console.log("errors", errors);
                        }}
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Address Line 2
                      </label>
                      {touched.Address_Line_2 && errors.Address_Line_2 && (
                        <small>{errors.Address_Line_2}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='City'
                        id="City"
                        placeholder=''
                        ref={CityRef}
                        value={City}
                        defaultValue={EditAddress ? EditAddress['city'] : ''}
                        className='w-100 staan-form-input'
                        onChange={e => {
                          handleonChangeCity(e.target.value)
                          setFieldValue('City', e.target.value)
                        }}
                        disabled={disableCondition}
                      />
                      <label
                        htmlFor='City'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        City
                      </label>
                      {touched.City && errors.City && (
                        <small>{errors.City}</small>
                      )}
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='number'
                        name='pincode'
                        id="pincode"
                        defaultValue={EditAddress ? EditAddress['pincode'] : ''}
                        placeholder=''
                        ref={pincodeRef}
                        value={pincode}
                        className='w-100 staan-form-input'
                        onChange={e => {
                          handleonChangepincode(e.target.value)
                          setFieldValue('pincode', e.target.value)
                        }}
                        disabled={disableCondition}
                      />
                      <label
                        htmlFor='pincode'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Pincode
                      </label>
                      {touched.pincode && errors.pincode && (
                        <small>{errors.pincode}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <CustomSelect
                        defaultValue={EditAddress ? EditAddress['country'] : ''}
                        setSelectEditValue={Countrylist}
                        options={CountryOptions?.map(country => ({
                          value: country,
                          label: country
                        }))}
                        name='Country'
                        handleOnChange={(selectedOption) => {
                          handleonChangeCountrylist(selectedOption)
                          setFieldValue('Country', selectedOption.value)
                        }}
                        isDisabled={disableCondition}
                      />
                      <label
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Country
                      </label>
                      {touched.Country && errors.Country && (
                        <small>{errors.Country}</small>
                      )}
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-6 staan-input-group'>
                      <CustomSelect
                        setSelectEditValue={selectedState}
                        defaultValue={EditAddress ? EditAddress['state'] : ''}
                        options={stateOptions?.map(state => ({
                          value: state.name,
                          label: state.name
                        }))}
                        name='State'
                        handleOnChange={(selectedOption) => {
                          handleonChangeState(selectedOption)
                          setFieldValue('State', selectedOption.value)
                        }}
                        isDisabled={disableCondition}
                      />
                      <label
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        State
                      </label>
                      {touched.State && errors.State && (
                        <small>{errors.State}</small>
                      )}
                    </div>
                    <div className='col-3 staan-input-group d-flex align-items-center'>
                      <div>
                        <Field type="checkbox" name="Default" id="Default" className='me-2' checked={isDefault}
                          onChange={(e) => { setFieldValue('Default', e.target.checked); setDefault(e.target.checked) }}
                          disabled={disableCondition}
                        />
                        <label style={{ fontSize: '.8rem' }} className='pb-2'>Default</label>
                      </div>
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                      <div>
                        <button
                          type='submit'
                          disabled={disableCondition}
                          className='btn btn-sm btn-outline-success'
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {isDeleteWarningShow &&
                  <WaringModel
                    state={isDeleteWarningShow}
                    setstate={setIsDeleteWarningShow}
                    title={'Confirm'}
                    text={"Confirm to delete"}
                    Confirmtion={value => {
                      if (value) {
                        DeleteTheAddressForm(value)
                      }
                    }}
                  />}
                {/* New UI design ends */}
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddressFrom