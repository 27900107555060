import React, { useEffect } from 'react'
import { useState } from 'react';
import Select from 'react-select';
import CallActivities from './CallActivitiesform';
import MettingActivitiesform from './MettingActivitiesform';
import { customSelectStyle } from '../../../../utils/Utils';
import NoteActivitsForm from './NoteActivitsForm';

const ActivitesFormManager = ({navList,setNavList, setShowForm, enquiry_id,  
  callEdit, setCallEdit, meetingEdit, setMeetingEdit,   setLogList, setNoteEdit, noteEdit, salesMan}) => {
   
    
    const [subjectValue , setSubjectsValue]= useState({value: "Call Log", label: "Call Log"})
    useEffect(()=>{
      let currentNav = navList.filter(item=> item.isShow)
      setSubjectsValue({value: currentNav[0]?.name , label: currentNav[0]?.name})
    },[navList])

  return (
    <>
        <div  className="row callActivitiesFormHeader">
            
        </div>
    
        {subjectValue?.value  === "Call Log" 
         ? <CallActivities 
         setShowForm={setShowForm} 
         callEdit={callEdit}
         enquiry_id={enquiry_id}
         setCallEdit={setCallEdit}
         setNavList={setNavList}
         setLogList={setLogList}
         salesMan={salesMan}
         subjectValue = {subjectValue}/> :
         subjectValue?.value  === "Meeting"?
         <MettingActivitiesform
         setShowForm={setShowForm}
         enquiry_id={enquiry_id}
         meetingEdit ={meetingEdit}
         setMeetingEdit={setMeetingEdit} 
         setNavList={setNavList}
         setLogList={setLogList}
         salesMan={salesMan}
         subjectValue = {subjectValue}
         />: 
         subjectValue?.value  === "Notes"?
         <NoteActivitsForm
         setShowForm={setShowForm}
         enquiry_id={enquiry_id}
         setNavList={setNavList}
         setLogList={setLogList}
         setNoteEdit={setNoteEdit}
         noteEdit = {noteEdit}
         />
         : "" }

    </>
  )
}

export default ActivitesFormManager