import { Field, Form, Formik } from 'formik/dist';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { TbHistory } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import { createCurrencyExchange } from '../../../GraphQLQueries/ItemMastermutation';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { CurrencyExchangesSchema } from '../../../ItemMaster/validations/itemmaster';
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import axiosInstance from '../../../api/axoiss';
import Can from '../../../components/authorizationComponents/Can';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import DataContext from '../../../context/ItemMasterContext';
import {removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import HistoryData from '../../ItemMaster/model/HistoryData';
import CustomSelect from '../../../components/ReactSelect/reactSelect';

const CurrencyExchangesFrom = ({ editCurrencyExchange, setEditCurrencyExchange, setLoad }) => {
    const { CurrencyExchangeAdd, handleCurrencyExchangeClose, handltoastDeleteConfomationShow, userId,
        historyIds, setHistoryIds, prefetchValue } = useContext(DataContext)
    const [initialvalues, setInitialvalues] = useState(
        {
            "id": '',
            "Currency": "",
            "Rate": "",
            "date": "",
            "HistoryDetails": "",
            "modified_by": "",
            "createdby": ""
        }
    )
    const [formKey, setFormKey] = useState(0);
    let url = "/itemmaster/CurrencyExchange"
    const [edit, setEdit] = useState(true)
    const dispatch = useDispatch();
    const CurrencyEdit = useSelector(state => state.itemGroup.data);
    const [deleteData, setdeleteData] = useState({ Name: "", id: "" })
    const [currencyExchangeValue, setCurrencyExchangeValue] = useState({ currency: "", date: "", Rate: "" })

    // return null ; update the edit value
    useEffect(() => {
        if (CurrencyEdit.name === "CurrencyEdit") {
            let responceData = CurrencyEdit.value.items[0]
            setInitialvalues(
                {
                    "id": Number(responceData?.Currency?.id),
                    "Currency": Number(responceData?.Currency?.id),
                    "Rate": Number(responceData['rate']),
                    "date": responceData['date'],
                    "HistoryDetails": responceData['historyDetails'],
                    "modified_by": "",
                    "createdby": responceData?.createdBy?.id ? Number(responceData?.createdBy?.id) : ""
                }
            )
            setCurrencyExchangeValue({
                currency: { value: responceData?.Currency?.id, label: responceData?.Currency?.name },
                date: responceData['date'], Rate: Number(responceData['rate'])
            })
            setFormKey((prevKey) => prevKey + 1);
        }
    }, [CurrencyEdit])
    // return null ; use to call fetchData functions
    useEffect(() => {
        async function fetchData(id) {
            if (id) {
                dispatch(fetchOptionsData(QueryData.CurrencyEdit(id), "CurrencyEdit"))
            }
        }
        if (editCurrencyExchange) {
            fetchData(editCurrencyExchange.id)
            setEdit(true)
        } else {
            setEdit(false)
        }
    }, [editCurrencyExchange,dispatch])

    // return null; clear the value
    function reset() {
        setInitialvalues({
            "id": '',
            "Currency": "",
            "Rate": "",
            "date": "",
            "HistoryDetails": "",
            "modified_by": "",
            "createdby": ""
        })
        setEditCurrencyExchange('')
        setEdit(false)
        setCurrencyExchangeValue({ currency: "", date: "", Rate: "" })
    }
    // return null ; save the value
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const SavedValue = {
            id: editCurrencyExchange ? Number(editCurrencyExchange.id) : "",
            Currency: values?.Currency,
            rate: values?.Rate,
            date: values?.date,
            modifiedBy: editCurrencyExchange ? Number(userId) : "",
            createdBy: editCurrencyExchange ? Number(initialvalues.createdby) : Number(userId),
        }


        let variable = removeEmptyValueInObject(SavedValue, ['modifiedBy'])
        try {
            const respones = await axiosInstance.post('/itemmaster/graphql', { query: createCurrencyExchange(variable) });
            const responseData = respones.data.data.currencyExchangeCreateMutation
            if (responseData.success) {
                resetForm()
                handleCurrencyExchangeClose()
                setLoad()
                setSubmitting(false)
                showErrorToast(true, "success", "", "Saved Successfully")
            } else {
                let error = responseData.errors
                let errorData = ShowErrorNoties(error)
                showErrorToast(true, "error", errorData)
            }
        } catch (error) {
            let errorData = ShowErrorNoties(error)
            showErrorToast(true, "error", errorData)
        }
    }
    //return null ; delete call the delete waring
    const handledeleteConfomation = (data) => {
        handltoastDeleteConfomationShow();

        setdeleteData({
            Name: data?.Currency?.name,
            id: data?.id
        });
    };
    //return null ; Delete function
    const handleDeletedId = (deleted_id) => {
        if (deleted_id) {
            handleCurrencyExchangeClose()
            reset()
            setLoad()
            setEditCurrencyExchange('')
            setEdit(false)
        }
    }
    return (
        <>
            <HistoryData
                historyIds={historyIds}
                setHistoryIds={setHistoryIds} />
            <Modal show={CurrencyExchangeAdd} size="lg" backdrop="static"
                keyboard={false} onHide={() => {
                    handleCurrencyExchangeClose()
                    reset()
                }}>
                <div className="row">
                    <div className="col-5 m-3" style={{ color: "#5CADA9" }}> <Modal.Title>
                        <i className='fa-solid fa-arrow-left fa-sm me-2'
                            onClick={() => {
                                handleCurrencyExchangeClose()
                                reset()
                            }}
                        ></i>Currency Exchange </Modal.Title></div>
                    <div className="col-6 mt-4 text-end" >
                        <Can I={"Edit"} a={'Currency_Exchange'}>
                            <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={!(editCurrencyExchange && edit)} onClick={() => { setEdit(!edit) }}  ></i>
                        </Can>
                        <Can I={"Delete"} a={'Currency_Exchange'}>
                            <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={!(editCurrencyExchange && edit)} onClick={() => { handledeleteConfomation(editCurrencyExchange) }} ></i>
                        </Can>
                        <Can I={"Historys"} a={'Currency_Exchange'}>
                            <TbHistory type="button" className='fs-4 ms-3' data-bs-toggle="offcanvas" hidden={!(editCurrencyExchange && edit)} data-bs-target="#History" onClick={() => { setHistoryIds(initialvalues.HistoryDetails) }} />
                        </Can>
                    </div>
                </div>
                <hr className='split_border'/>
                <Modal.Body>
                    <Formik initialValues={initialvalues}
                        validationSchema={CurrencyExchangesSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize key={formKey}>
                        {({ errors, touched, setFieldValue, isSubmitting }) => (
                            <Form>
                                <div className="row ">
                                    <div className="col-6 staan-input-group">
                                        <CustomSelect
                                            setSelectEditValue={currencyExchangeValue?.currency}
                                            options={prefetchValue?.currencyMasterList}
                                            name='Currency'
                                            handleOnChange={(option) => {
                                                setCurrencyExchangeValue(prve => ({ ...prve, currency: option }))
                                                setFieldValue('Currency', Number(option?.value))
                                            }}
                                            isDisabled={editCurrencyExchange && edit}
                                        />
                                        <label className='staan-form-input-label pt-1  px-1 mx-2'>Currency</label>
                                        {touched.Currency && errors.Currency && <small>{errors.Currency}</small>}
                                    </div>
                                    <div className="col-6 staan-input-group">
                                        <Field type='number' id='Rate' name='Rate'
                                            value={currencyExchangeValue?.Rate}
                                            onChange={e => {
                                                setCurrencyExchangeValue(prve => ({ ...prve, Rate: e?.target?.value }))
                                                setFieldValue('Rate', e?.target?.value)
                                            }}
                                            disabled={editCurrencyExchange && edit}
                                            className='w-100 staan-form-input' />
                                        <label htmlFor="Rate" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                            console.log(prefetchValue);
                                        }}>Rate </label>
                                        {touched.Rate && errors.Rate && <small>{errors.Rate}</small>}
                                    </div>
                                    <div className='col-6  staan-input-group'>
                                        <input
                                            type='date'
                                            id='date'
                                            name='date'
                                            disabled={editCurrencyExchange && edit}
                                            className='w-100 staan-form-input'
                                            value={currencyExchangeValue?.date}
                                            onChange={e => {
                                                setCurrencyExchangeValue(prve => ({ ...prve, date: e?.target?.value }))
                                                setFieldValue('date', e?.target?.value)
                                            }}
                                        />
                                        <label htmlFor="date" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                            console.log(prefetchValue);
                                        }}>Date</label>
                                        {touched.date && errors.date && <small>{errors.date}</small>}
                                    </div>
                                </div>
                                <div className='row mt-4 mx-2'>
                                    <div className='text-end'>
                                        <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={() => {
                                            handleCurrencyExchangeClose()
                                            reset()
                                        }}>Cancel</button>
                                        <Can allow_list={["Save_Currency_Exchange", "Edit_Currency_Exchange"]}>
                                            <button type="submit" disabled={isSubmitting} className="px-3 btn btn-sm btn-outline-success shadow-sm">Save</button>
                                        </Can>
                                    </div>
                                </div>
                            </Form>
                        )}

                    </Formik>




                </Modal.Body>

            </Modal>
            {deleteData && <DeleteConformation
                handleDeletedId={(id_) => { handleDeletedId(id_) }}
                deleteData={deleteData}
                url={url}
            />}
        </>
    )
}

export default CurrencyExchangesFrom