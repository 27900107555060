import React, { useState } from 'react';
import ReporTableListView from './ReporTableListView';

const ReportTableView = ({ headers, url, loadComponent, titleName, status,permission,addComponent }) => {

    const [reportTableViewValues, setReportTableViewValues] = useState({
        tableRowData: null,
        titleName: titleName,
        currentPageNumber: "1", currentPagePageSize: 10,
        hasNextPage: false, hasPreviousPage: false, totalItems: 0, totalPages: 0,
        columnsEnabled: headers?.map(item => item?.field)
    })
    return (
        <>
            <ReporTableListView
                headers={headers}
                url={url}
                loadComponent={loadComponent}
                titleName={titleName}
                reportTableViewValues={reportTableViewValues}
                setReportTableViewValues={setReportTableViewValues}
                status={status}
                permission={permission}
                addComponent={addComponent}
            />
        </>
    )
}

export default ReportTableView