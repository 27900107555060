import { useEffect } from 'react';

const useShortcutFocus = (targetRef, keyCode, altKey) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.altKey === altKey && (event.key === keyCode || event.key === keyCode.toUpperCase())) {
        event.preventDefault();
        targetRef.current.focus();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [targetRef, keyCode, altKey]);
};

export default useShortcutFocus;
