import React, { useEffect, useState, useRef, useContext, useImperativeHandle } from 'react';
import { Modal } from 'react-bootstrap';
import { FetchPosSalesOrderItemCombo, FetchStockByPartCode, PosItemComboQuery } from '../../../GraphQLQueries/PosItemComboQuery';
import axiosInstance from '../../../api/axoiss';
import { AgGridReact } from 'ag-grid-react';
import {Formik , Field, Form} from 'formik';
import { PosItemSchema } from '../../../validations/itemmaster';
import DataContext from '../../../context/ItemMasterContext';
import Select from 'react-select';
import {   customSelectStyle,  } from '../../../utils/Utils';
// import { useDispatch } from 'react-redux'; 
import { ToastContainer,  toast, Bounce } from 'react-toastify';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery"
import { SaveBatchNumberPosItem, SaveNoBatchSerialNumberPosItem, 
    SaveSerialNumberPosItem, callOverallDiscountValue, 
    saveOverallDiscountPercentage, callFinalTotalDiscount } from '../../../utils/PosUtils';


export const PosItemComboDeleteComponent = ({show, close}) => {
    return (
        <>
            <Modal show={show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
            </Modal>
        </>
    )
}


export const PosItemComboEditComponent = ({show, close, data, store, itemDetailsData, setItemDetialData}) => {
    const {userId} =  useContext(DataContext);
    // const dispatch = useDispatch(); 
    const [serialOrBatch, setSerialOrBatch] = useState(null);
    const [seleteItempartnumber, setSeleteItempartnumber] = useState('')
    const [selectItemPartName, setselectItemPartName] = useState('')
    const [selectBatchNumber , setSelectBatchNumber] = useState([]);
    const [selectSerialNumber, setSelectSerialNumber] = useState([]);
    const [rateData, setRateData] = useState('');
    const [selectUom, setSelectUom] = useState('');
    const [qtydata, setqtydata] = useState('');
    const [displayName, setDisplayName] = useState("");
    const [descriptionText, setDescriptionText] = useState("");
    const [isQtyRateEdiTable, setQtyRateEditTable] = useState(false);
    const [selectedPartNo, setSelectedPartNo] = useState(null);
    const [isShowStockStatementModal, setShowStockStatementModal] = useState(false);
    const [selectedStockStatementDetails, setSelectedStockStatementDetails] = useState([]);
    const [batchNumberOptions, setBatchNumberOptions] = useState([]);
    const [serialNumberOptions, setSerialNumberOptions] = useState([]);
    const [isBatch, setBatch] = useState(false);
    const [isSerial, setSerial] = useState(true);
    const [gstRate, setGstRate] = useState(0);
    const [addedItems, setAddedItems] = useState({});
    const [initialValuePosItem, setInitialValuePosItem] = useState({
        partCode : data?.partNumber?.id,
        partName : data?.partNumber?.id,
        Description : data?.partNumber?.description,
        qty : data?.qty,
        rate : data?.rate, 
        uom : data?.partNumber?.itemUom?.id,
        Amount  : data?.amount,
        store : store?.value,
        BatchNo : "",
        Serialnum : "",
        getRate : gstRate,
        createdby : userId,
        display: data?.display,
    });

    const handleSelectBatchNumber = (option) => {
        if (option){
          setSelectBatchNumber({ value: option.value , label:  option.label })
        } else{
          setSelectBatchNumber({ value: "" , label:  "" })
        }
        
    }
   
    const handleSelectSerialNumber = (options) => {
        if (options){
            setSelectSerialNumber(options);
        } else{
            setSelectSerialNumber("");
        }
    };

    const handleShowStockStatementModal = () => {
        let query = QueryData.stockIdFetchQuery(seleteItempartnumber?.value, store.value)
        axiosInstance.post('/itemmaster/graphql', { query: query })
        .then((res) => {
            let stock_ids = []
            stock_ids = res?.data?.data?.stockIds?.items;
            let construct_stock_dict = {
              stockId: [],
              itemPartCode: '',
              itemName: '',
              description: '',
              display: "",
              batchNumber: '',
              serial: '',
            }
            if(stock_ids.length > 0){
              construct_stock_dict['partCode'] = stock_ids[0]?.partNumber?.['itemPartCode']
              construct_stock_dict['partName'] = stock_ids[0]?.partNumber?.['itemName']
              construct_stock_dict['description'] = stock_ids[0]?.partNumber?.['description']
              construct_stock_dict['display'] = stock_ids[0]?.partNumber?.['display']
              construct_stock_dict['isBatch'] = stock_ids[0]?.partNumber?.['batchNumber']
              construct_stock_dict['isSerial'] = stock_ids[0]?.partNumber?.['serial']
            }
            construct_stock_dict['stockId'] = stock_ids?.map(item => item.id)
            setSelectedStockStatementDetails(construct_stock_dict)
            setShowStockStatementModal(true)
        }).catch((error) => {
          // Handle errors here
          console.error('Error fetching data:', error);
        });
        setSelectedPartNo(seleteItempartnumber?.value)
    };

    const handleqtydata = (data) =>{
        setqtydata(data)
    }
     
    const handlerateData = (data) =>{
        setRateData(data)
    }

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors, setFieldError }) => {
        if(isBatch){ 
            if (values['BatchNo'] === "") {  
                setFieldError('BatchNo', 'Batch Number is required');
                return;
            } else { 
                setFieldError('BatchNo', null);
            } 
        }else if(isSerial){  
            if(values['Serialnum'] === ""){
                setFieldError ('Serialnum', 'Serial Number is required');
                return;
            }else{
                if(selectSerialNumber.length !== Number(qtydata)){
                    setFieldError ('Serialnum', 'Serial number and Qty values are mismatched!');
                    return;
                }
            }
        }   
        const savedata = {
            id : data?.id ? Number(data.id) : undefined,
            partCode : Number(values['partCode']),
            description : values['Description'],
            qty : Number(values['qty']),
            rate : Number(rateData),
            uom : Number(values['uom']),
            amount : Number((rateData) * (qtydata)), 
            batch : values['BatchNo'] ? Number(values['BatchNo']) : "",
            serial : values['Serialnum'] ? values['Serialnum'] : [] ,
            gstRate : gstRate,
            display: displayName,
            modifiedBy : data?.id ? Number(userId) : "",
        }   
        if(!data?.id){
            savedata['createdBy'] = Number(userId)
        }
        if(isBatch){ 
            SaveBatchNumberPosItem({data:savedata, 
                gstRate, posItemData: itemDetailsData,  
                setPosItemData: setItemDetialData,
                addedItems, setAddedItems, isCombo: true
            });
            close()
        }
        else if(isSerial){
            SaveSerialNumberPosItem({data:savedata, 
                gstRate, posItemData: itemDetailsData,  
                setPosItemData: setItemDetialData, isCombo: true
            });
            close()
        }
        else{  
            SaveNoBatchSerialNumberPosItem({data:savedata, 
                gstRate, posItemData: itemDetailsData,  
                setPosItemData: setItemDetialData, isCombo: true
            });
            close()
        }
    }

    const fetchStockDetails = (part_code, batch,  serial) => {
        let mutation_query = FetchStockByPartCode(part_code, store.value)
        axiosInstance.post('/itemmaster/graphql', {query: mutation_query})
        .then(res => {
            let res_data = res?.data?.data?.itemStock?.items
            if(batch){
                let batch_options = res_data.map(item => {
                    return {
                        value: item['batchNumber']['id'],
                        label: item['batchNumber']['batchNumberName'],
                        stockId: item.id
                    }
                })
                setBatchNumberOptions(batch_options)
                setSerialNumberOptions([])
            }else if(serial){
                let serial_options = res_data?.[0]?.['serialNumber']?.map(item => {
                    return {
                        value : item.id,
                        label : item.serialNumber,
                        stockId: res_data?.[0]?.id
                    }
                })
                setSerialNumberOptions(serial_options)
                setBatchNumberOptions([])
            }else{
                setBatchNumberOptions([])
                setSerialNumberOptions([])
            }
        })
        .catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        if(data){
            setSeleteItempartnumber({value: data?.partNumber?.id, label: data?.partNumber?.itemPartCode})
            setselectItemPartName({value: data?.partNumber?.id, label: data?.partNumber?.itemName})
            setSelectUom({value: data?.partNumber?.itemUom?.id, label: data?.partNumber?.itemUom?.name})
            setRateData(data?.rate);
            setqtydata(data?.qty);
            setDisplayName(data?.display);
            setDescriptionText(data?.partNumber?.description);
            setGstRate(data?.partNumber?.itemHsn?.gstRates?.rate || 0);
            if(data?.partNumber?.batchNumber){
                setSerialOrBatch('batch');
                setBatchNumberOptions([]);
                fetchStockDetails(data?.partNumber?.id, true, false);
                setBatch(true);
                setSerial(false);
                if(data.id){
                    setSelectBatchNumber(data?.batchNumber);
                }
            }else if(data?.partNumber?.serial){
                setSerialOrBatch('serial');
                setSerialNumberOptions([]);
                fetchStockDetails(data?.partNumber?.id, false, true);
                setBatch(false);
                setSerial(true);
                if(data.id){
                    let serial_num_option = data?.serialNumber?.map(item => ({label: item.serialNumber, value: item.id}))
                    setSelectSerialNumber(serial_num_option || []);
                }
            }else{
                setSerialOrBatch('')
                setBatch(false);
                setSerial(false);
            }
            if(data.isMandatory){
                setQtyRateEditTable(false);
            }else{
                setQtyRateEditTable(true);
            }
        }
    }, [itemDetailsData])

    return(
        <>
            <Modal show={show} onHide={close} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{data?.partNumber?.itemPartCode}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik 
                        initialValues={initialValuePosItem}
                        validationSchema = {PosItemSchema}
                        onSubmit={handleSubmit} 
                    >
                    {(formikProps)=>(
                        <Form>
                            <p hidden>{formikProps.values.createdby = userId}</p>
                            <div>
                                <div className='row mx-2'>
                                    <div className="col-6 staan-input-group">
                                        <Select name="partCode"  
                                            value={seleteItempartnumber}
                                            className='mt-3'
                                            styles={customSelectStyle}
                                            isDisabled
                                        />
                                        <label htmlFor="partCode" className='staan-form-input-label pt-1 px-1 mx-2 text-primary' 
                                        role="button" 
                                        onClick={handleShowStockStatementModal}
                                        >Part Code<i className="fa-solid fa-up-right-from-square ms-2"></i></label>
                                        {formikProps.touched.partCode && formikProps.errors.partCode && (
                                            <small>{formikProps.errors.partCode}</small>
                                        )}
                                    </div>
                                    <div className="col-6 staan-input-group">
                                        <Select name="PartName"  
                                            value={selectItemPartName}
                                            className='mt-3'
                                            styles={customSelectStyle}
                                            isDisabled
                                        />
                                        <label htmlFor="PartName" className='staan-form-input-label pt-1 px-1 mx-2'>Part Name</label>
                                        {formikProps.touched.partName && formikProps.errors.partName && (
                                            <small>{formikProps.errors.partName}</small>
                                        )}
                                    </div>
                                </div>
                                <div className='row mx-2 my-2'>
                                    <div className="col-8 staan-input-group">
                                        <Field type='text' name='Description' 
                                            value={descriptionText}  className='w-100 staan-form-input' 
                                            onChange = {(e) => {setDescriptionText(e.target.value)}}
                                        />
                                        <label htmlFor="Description" className='staan-form-input-label pt-2 px-1 mx-2'>Description</label>
                                        {formikProps.touched.Description && formikProps.errors.Description && (
                                            <small>{formikProps.errors.Description}</small>
                                        )}
                                    </div>
                                    <div className='col-4 staan-input-group'>
                                        <Field 
                                            type='text' 
                                            name='display' 
                                            value={displayName} 
                                            className='w-100 staan-form-input' 
                                            disabled={true}
                                        />
                                        <label htmlFor="display" className='staan-form-input-label pt-2 px-1 mx-2'>Display Name</label>
                                        {formikProps.touched.display && formikProps.errors.display && (
                                            <small>{formikProps.errors.display}</small>
                                        )}
                                    </div>
                                </div>
                                <div className='row mx-2 my-2'>
                                    <div className="col-2 staan-input-group">
                                        <Field type='text' name='qty' 
                                        value={qtydata} disabled={!isQtyRateEdiTable} 
                                        onChange={(e) => { 
                                            handleqtydata(e.target.value) 
                                            formikProps.setFieldValue('qty', e.target.value)
                                        }} 
                                        className='w-100 staan-form-input text-center' />
                                        <label htmlFor="Qty" className='staan-form-input-label pt-2 px-1 mx-2'>Qty</label>
                                        {formikProps.touched.qty && formikProps.errors.qty && (
                                            <small>{formikProps.errors.qty}</small>
                                        )}
                                    </div>
                                    <div className="col-3 staan-input-group">
                                        <Field type='text' 
                                            name='rate' value={rateData} 
                                            onChange={(e) => { 
                                                handlerateData(e.target.value);
                                                formikProps.setFieldValue('rate', e.target.value) 
                                            }}
                                            disabled = 'true'
                                             className='w-100 staan-form-input text-end px-2' />
                                        <label htmlFor="rate" className='staan-form-input-label pt-2 px-1 mx-2'>Rate</label>
                                        {formikProps.touched.rate && formikProps.errors.rate && (
                                                <small>{formikProps.errors.rate}</small>
                                            )}
                                    </div>
                                    <div className="col-3 staan-input-group">
                                        <Field type='text' name='Amount' value={(rateData * qtydata).toFixed(2)} disabled= {true}  className='w-100 staan-form-input text-end' />
                                        <label htmlFor="Amount" className='staan-form-input-label pt-2 px-1 mx-2 text-end px-2'>Amount</label>
                                        {formikProps.touched.Amount && formikProps.errors.Amount && (
                                            <small>{formikProps.errors.Amount}</small>
                                        )}
                                    </div>
                                    <div className="col-4 staan-input-group">
                                        <Select name="uom"  
                                            value={selectUom}
                                            className='mt-3'
                                            styles={customSelectStyle}
                                            isDisabled
                                        />
                                    <label htmlFor="UOM" className='staan-form-input-label pt-1 px-1 mx-2'>UOM</label>
                                    {formikProps.touched.uom && formikProps.errors.uom && (
                                        <small>{formikProps.errors.uom}</small>
                                    )}
                                    </div>
                                </div>
                                <div className='row mx-2 my-2'>
                                  <div className='col-6 staan-input-group'>
                                      <Select
                                          name="BatchNo" 
                                          options ={batchNumberOptions}
                                          value={selectBatchNumber}
                                          isDisabled ={serialOrBatch === 'batch' ? false : true}
                                          onChange={(option)=>{
                                              formikProps.setFieldValue('BatchNo', option ? option.value : null)
                                              handleSelectBatchNumber({ value: option.value , label:  option.label })
                                          }}
                                          className='mt-3'
                                          styles={customSelectStyle}
                                        /> 
                                        <label htmlFor="BatchNo" className='staan-form-input-label pt-1 px-1 mx-2'>Batch Number</label>
                                        {  formikProps.touched.BatchNo &&  formikProps.errors.BatchNo && <small>{formikProps.errors.BatchNo}</small>}
                                  </div> 
                                  <div className='col-6 staan-input-group'> 
                                        <Select
                                            name="Serialnum"  
                                            options ={serialNumberOptions}
                                            value={selectSerialNumber}
                                            isDisabled ={serialOrBatch === 'serial' ? false : true}
                                            isMulti
                                            isOptionDisabled= {() => selectSerialNumber?.length >= Number(qtydata) ? true : false } 
                                            onChange={(options) => { 
                                                formikProps.setFieldValue('Serialnum', options ? options.map(option => option.value)  : []);
                                                handleSelectSerialNumber(options);
                                            }}
                                            className='mt-3'
                                            styles={customSelectStyle}
                                        /> 
                                        <label htmlFor="Serialnum" className='staan-form-input-label pt-1 px-1 mx-2'>Serial Number  </label>
                                        {  formikProps.touched.Serialnum &&  formikProps.errors.Serialnum && <small>{formikProps.errors.Serialnum}</small>}
                                  </div>
                                </div>
                                <div className='mx-3 text-end pt-2'>
                                    <div>
                                        <button 
                                            type='submit'
                                            className='btn btn-sm btn-outline-success'
                                        >Save</button>
                                    </div>
                                </div>                
                            </div>
                        </Form>
                    )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}


const PosItemComboModal = ({ show, close, partCode, store, data, updateItemComboToMasterItem,
     TotalWithTaxValue, userId}) => {
    const gridApiRef = useRef(null);
    const [itemDetailsData, setItemDetialData] = useState([]);
    const [showEditRowModal, setShowEditRowModal] = useState(false);
    const [showDeleteRowModal, setShowDeleteRowModal] = useState(false);
    const [currentEditItem, setCurrentEditItem] = useState(false);

    const handleShowEditModal = () => {
        setShowEditRowModal(true);
    }
    const handleCloseEditModal = () => {
        setShowEditRowModal(false);
    }
    const handleShowDeleteModal = () => {
        setShowDeleteRowModal(true)
    }
    const handleCloseDeleteModal = () => {
        setShowDeleteRowModal(false)
    }
 
    const AmountCalculator = ({data}) => {
        return (
            <span>{data.FnAmount? Number(data.FnAmount) : Number(data.qty) * Number(data.rate)}</span>
        )
    }
    const ActionComponent = ({data}) => {
        return (
            <div>
                <span 
                    style={{cursor: 'pointer'}}
                    onClick={()=>{ 
                        if (data['stockReduce'] ){ 
                        } else{
                            setCurrentEditItem(data);
                            handleShowEditModal();
                        }
                    }}
                >
                    <i className='fa-solid fa-pen text-secondary'
                      ></i>
                </span>
                {/* <span 
                    className='ms-2' 
                    style={{cursor: 'pointer'}}
                    onClick={()=>{
                        handleShowDeleteModal()
                    }}
                > 
                    <i className='fa-solid fa-trash text-danger'></i>
                </span> */}
            </div>
        )
    }
    const RateCellComponent = params => {
        let discount_percentage = params.data?.discountPercentage !== undefined ? params.data.discountPercentage : 0
        let discount_value = params.data?.discountValue !== undefined ? params.data.discountValue : 0
        let final_value = params.data.finalValue !== undefined ? params.data.finalValue : 0
        return (
        <>
            {params.data.rate}
            {Number(discount_percentage) !== 0
            ? -params.data.discountPercentage +
                '  %  = ' +
                Number(params.data.discountValueForPerItem).toFixed(2)
            : Number(discount_value) !== 0
            ? '-' +
                Number(params.data.discountValue).toFixed(2) +
                ' = ' +
                Number(params.data.discountValueForPerItem).toFixed(2)
            : Number(final_value) !== 0
            ? ' - ' +
                Number(params.data.discountValueForPerItem).toFixed(2) +
                ' = ' +
                Number(params.data.finalValue).toFixed(2)
            : ''}
        </>
        )
    }

    const descriptionUpdate = params => {
        return (
          <>
          {console.log(params.data, "----->>>")}
            <div
              style={{
                color: params.data['stockReduce'] ? 'green' : 'black',
                fontWeight: params.data['stockReduce'] ? 'bold' : ''
              }}
            >
              {params.data.partCode}
            </div>
          </>
        )
      }
    const columns = [
        {
            headerName: 'ID',
            field: 'index',
            flex: 2,
        },
        {
            headerName: 'Part Code',
            field: 'partCode',
            cellRenderer : descriptionUpdate,
            flex: 4,
        },
        {
            headerName: 'Part Name',
            field: 'partName',
            flex: 3, 
        },
        {
            headerName: 'Display(Grouped)',
            field: 'display',
            flex: 3,
        },
        {
            headerName: 'Qty',
            field: 'qty',
            flex: 2, 
        },
        {
            headerName: 'Batch',  
            flex: 3, 
            field: "BatchNo",
        },
        { 
            headerName: 'Serial', 
            flex: 3,
            field: "Serialnum"
        },
        { 
            headerName: 'Rate', 
            flex: 3,
            field: 'rate',
            cellRenderer: RateCellComponent,
        },
        { 
            headerName: 'Amount', 
            flex: 3,
            cellRenderer: AmountCalculator
        },
        { 
            headerName: 'Action', 
            flex: 3,
            cellRenderer: ActionComponent
        },
    ]
    const defaultColDef = {
        sortable: true,
        editable: false,
        filter: true,
        floatingFilter: true,
        resizable: true
    }
    const handleSubmit = () => {
       console.log(itemDetailsData);
        let has_ids = itemDetailsData.map(item => item.id);
        if(has_ids.includes(undefined)){
            toast.error('Please Save All data!', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            })
        }else{
            updateItemComboToMasterItem(has_ids)
        }
    }
    
    useEffect(()=>{
        let posItemData = [] 
        let discount_percentage = data?.discountPercentage;
        let discount_value = data?.discountValue;
        let final_value = data?.finalValue;
        if(data?.itemCombo && data.itemCombo.length > 0){
                let sales_order_item_combo = data?.itemCombo?.map(item => {
                    if(typeof item === 'string'){
                        return item
                    }else{
                        return item.id
                    }
                });
                let fetch_query = FetchPosSalesOrderItemCombo(sales_order_item_combo);
                axiosInstance.post('/itemmaster/graphql', {query: fetch_query})
                .then(res => {
                    let res_data = res?.data?.data?.salesOrderItemCombo?.items;
                    if(res_data && res_data.length > 0){
                        let updated_data = res_data.map((item, index) => {
                            const serialNumbers = item?.['serial']?.map(item => item.serialNumber);
                            const joinedSerialNumbers = serialNumbers ? serialNumbers.join(', ') : '';
                            console.log(item);
                            let new_item = {
                                id: item?.id,
                                index: index + 1, 
                                partCode : item?.partCode?.itemPartCode,
                                partName : item?.partCode?.itemName,
                                Description : item?.partCode?.description,
                                qty :  Number(item['qty']) * Number(data['qty']),
                                rate : item?.rate, 
                                uom : item?.uom?.id,
                                Amount  : item?.amount,
                                store : store?.value,
                                BatchNo : item?.batch?.batchNumberName,
                                Serialnum : joinedSerialNumbers,
                                getRate : item?.gstRate,
                                createdby : "",
                                partNumber: item['partCode'],
                                display: item.display,
                                serialNumber: item?.['serial'],
                                stockReduce : item?.['stockReduce'],

                                batchNumber: {label: item?.['batch']?.['batchNumberName'], value: item?.['batch']?.['id']},
                            }
                            return new_item
                        })
                        posItemData = updated_data
                        if(Number(discount_percentage) > 0){
                            saveOverallDiscountPercentage(discount_percentage, posItemData, setItemDetialData, userId)
                        }else if(Number(discount_value) > 0){
                            callOverallDiscountValue(discount_value * data.qty, posItemData, setItemDetialData, userId)
                        }else if(Number(final_value) > 0){
                            callFinalTotalDiscount(final_value * data.qty, posItemData, setItemDetialData, userId, TotalWithTaxValue)
                        }else{
                            // console.log('ELSE')
                        }
                        setItemDetialData(updated_data)
                    }else{
                        setItemDetialData([])
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }else{
                let pos_item_query = PosItemComboQuery(partCode);
                axiosInstance.post('/itemmaster/graphql', {query: pos_item_query})
                .then(res => {
                    let res_data = res?.data?.data?.itemMaster?.items?.[0]?.itemComboData;
                    if(res_data && res_data.length > 0){
                        let updated_data = res_data.map((item, index) => {
                            let new_item = {
                                index: index + 1, 
                                partCode : item?.partNumber?.itemPartCode,
                                partName : item?.partNumber?.itemName,
                                Description : item?.partNumber?.description,
                                qty : Number(item['itemQty']) * Number(data['qty']),
                                rate : item?.partNumber?.itemMrp, 
                                uom : item?.partNumber?.itemUom?.id,
                                Amount  : "",
                                store : store?.value,
                                BatchNo : "",
                                Serialnum : "",
                                getRate : "",
                                createdby : "",
                                display: item?.itemDisplay?.display,
                                stockReduce : item?.['stockReduce'],
                                ...item
                            }
                            delete new_item['id']
                            return new_item
                        })
                        posItemData = updated_data
                        if(Number(discount_percentage) > 0){
                            saveOverallDiscountPercentage(discount_percentage, posItemData, setItemDetialData, userId)
                        }else if(Number(discount_value) > 0){
                            callOverallDiscountValue(discount_value, posItemData, setItemDetialData, userId)
                        }else if(Number(final_value) > 0){
                            callFinalTotalDiscount(final_value, posItemData, setItemDetialData, userId, TotalWithTaxValue)
                        }else{
                            // console.log('ELSE')
                        }
                        setItemDetialData(updated_data)
                    }else{
                        setItemDetialData([])
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [partCode])

    return ( <>
        <ToastContainer />
        <Modal show={show} onHide={close} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Item Combo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    className='ag-theme-alpine'
                    style={{ height: '40rem', width: '100%'}}
                >
                    <AgGridReact
                        ref={gridApiRef}
                        rowData={itemDetailsData}
                        columnDefs={columns}
                        defaultColDef={defaultColDef}
                    />
                </div>
                <div>
                    <div className='text-end mt-2'>
                        <button 
                            type='button' 
                            className='btn btn-sm btn-success'
                            onClick={()=>{handleSubmit()}}
                        >Save</button>
                    </div>
                </div>
                {showEditRowModal && 
                    <PosItemComboEditComponent 
                        show = {showEditRowModal}
                        close = {handleCloseEditModal}
                        data = {currentEditItem}
                        store = {store}
                        itemDetailsData = {itemDetailsData}
                        setItemDetialData = {setItemDetialData}
                    />
                }
                {showDeleteRowModal && <PosItemComboDeleteComponent />}
            </Modal.Body>
        </Modal>
    </> );
}
 
export default PosItemComboModal;