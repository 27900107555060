import React, { useContext, useEffect, useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable'
import DataContext from '../../context/ItemMasterContext'
import Grn from './models/Grn'

const GrnTable = () => {
  const currentURL = window.location.pathname;
  const {screenSize} = useContext(DataContext)
  const [isLoad, setIsLoad] = useState()
  const [isGrnShowing, setIsGrnShowing] = useState(false)
  const [editGrn, setEditGrn] = useState(null)
  const [isGin , setIsGin] = useState(true) 
  const [tableDetails, setTableDetails] = useState({name:"", qurey:""})
  const CustomViewModalComponent = (data)=>{
      setIsGrnShowing(true)
      setEditGrn(data) 
      if(currentURL === "/gin"){
        setIsGin(true) 
       } else if(currentURL === "/GRN"){ 
        setIsGin(false) 
       }
  }
  
 
  useEffect(()=>{
   if(currentURL === "/gin"){ 
    setIsGin(true) 
    setTableDetails({name:"Goods Inward Note",qurey:"GrnTableQuery"})
   } else if(currentURL === "/GRN"){
    setIsGin(false) 
    setTableDetails({name:"Goods Receipt Note",qurey:"onlyGrnTableQuery"})
   }
  },[])
  const headers = [
        {
          header: currentURL === "/gin" ? 'GIN' : "GRN",
          field: currentURL === "/gin" ? 'ginNo' :'grnNo',
          label : 'linkedModelId',
          flex: 10,
          inputType: 'text',
          // query: 'ItemMasterOptionQuery',
          renderComponent: CustomViewModalComponent,
        },
        {
          header: currentURL === "/gin" ? 'GIN Date' : "GRN Date",
          field: currentURL === "/gin" ?  'ginDate' : 'grnDate',
          flex: 10,
          // query: 'ItemMasterOptionQuery',
          inputType: 'text',
          isDateField: true
        },
        {
          header: 'Purchase Order No',
          field: 'purchaseOrderId',
          flex: 10,
          label : 'purchaseOrderNo.linkedModelId',
          query: currentURL === "/gin" ?  'GinPurchaseOrderFilter' : 'GrnPurchaseOrderFilter' ,
          inputType: 'text',
        },
        {
          header: 'Supplier No',
          field: 'purchaseOrderId',
          flex: 10,
          label : 'supplierId.supplierNo',
          // query: 'ItemMasterOptionQuery',
          inputType: 'text',
        },
        {
          header: 'Company Name',
          field: 'purchaseOrderId',
          customFilterField: 'companyName',
          flex: 20,
          label : 'supplierId.companyName',
          // query: 'ItemMasterOptionQuery',
          inputType: 'text',
        },
  ]
  
  return (
    <> 
        <SideNavbar/>
        <div id='custom-table-itemmaster'>
            <TableView
             headers={headers}
             query={currentURL === "/gin" ? "GrnTableQuery": "onlyGrnTableQuery"}
             loadComponent={isLoad}
             titleName={tableDetails?.name}
             heightAdjust = {`${(screenSize?.height/20)}rem`}
             customFilter={true}
            />

        </div>
        <Grn 
           isGin = {isGin} 
           setIsGin = {setIsGin}
           isGrnShowing = {isGrnShowing}
           setIsGrnShowing = {setIsGrnShowing}
           purchaseOrderDetails = {null}
           editGrn = {editGrn}
           setEditGrn= {setEditGrn}
           setLoad={() => {setIsLoad(!isLoad)}}
          //  modelKey = {model}
          //  handleCloseModelShow={handleCloseModelShow}
          //  handleDuplicateCurrentBom = {handleDuplicateCurrentBom}
          //  openTheClickData = {openTheClickData}
          //  key={model.id}
           />
          </>
 
         
  )
}

export default GrnTable