import React, { useState, useEffect, useContext } from 'react'
import "../Activities.css"
import { DateFormate,   TimeFormate } from '../../../utils/Utils'
import DataContext from '../../../context/ItemMasterContext'
 

const ListOfActivites = ({logList,navList,   setNavList,  setCallEdit, setShowForm, setMeetingEdit, setNoteEdit}) => {
  const {formattedDate, YESTERDAY_DATE_FORMAT} = useContext(DataContext)
  const [planed, setPlaned] = useState([])
  const [completed, setCompleted] = useState([])
  useEffect(()=>{
    setCompleted(logList?.map((item)=> item?.status ? item?.status?.name === "Completed" ? item  : "" : item).filter(item=>item!== ""))
    setPlaned(logList?.map((item)=>  item?.status ? item?.status?.name !== "Completed" && item?.status?.name !== "Canceled" ? item : "" : "").filter(item=>item!== ""))
  },[logList])
  return (
    <>  
        <div className="activities-container ">
            {
                planed?.length > 0 ? 
                <>
                    <h6 style={{color:"#5CADA9"}} >Planned. . .</h6>
                  <div role='list' className='Activites pt-2 activities-list '>
                    
                        <ul  className='planedList p-0'> 
                            {planed?.map((item, index)=>(
                                <li key={index} className='  rounded p-2 my-1' onClick={()=>{
                                    if (item?.status?.name){
                                        setMeetingEdit(item);  
                                        setShowForm(true)
                                        setNavList(prev => 
                                            prev.map(item => 
                                                item?.name === "Meeting"
                                                    ? { ...item, isShow: true } 
                                                    : { ...item, isShow: false }
                                            )
                                        );
                                    } else if (item?.subject){
                                        setCallEdit(item);
                                        setShowForm(true)
                                        setNavList(prev => 
                                            prev.map(item => 
                                                item?.name === "Call Log" 
                                                    ? { ...item, isShow: true } 
                                                    : { ...item, isShow: false }
                                            )
                                        );
                                    }
                                }}>
                                    <div>
                                        <p className='m-0 p-0 fw-bold'>{item?.status  ? `Meeting` : item?.subject ? "Call" : "Note"}</p>
                                        <p className='m-0 '>Subject : {item?.subject}</p>
                                        <p className=' text-wrap m-0 '>Outcome : {item?.outcome === "Empty" ?"" : item?.outcome}</p>
                                        <p className='m-0' style={{display:"flex" , justifyContent:"space-between"}}>
                                            <span><span className='fst-italic'>By</span>: {item?.salesPerson?.username}</span> 
                                          
                                            <span> 
                                                {formattedDate === DateFormate(item?.completedDate ? item?.completedDate :  item?.createdAt) ? "Today" : 
                                                YESTERDAY_DATE_FORMAT === DateFormate(item?.completedDate ? item?.completedDate :  item?.createdAt) ? "Yesterday" : DateFormate(item?.createdAt) } 
                                            </span>
                                        </p>
                                    </div>
                                </li>
                            ))} 
                        </ul> 

                  </div>
                  <hr/>
                </>: <div>
                    </div>
            }
            

            {
                completed?.length > 0 ?
                <>
                    <h6 style={{color:"#5CADA9"}}>Completed</h6>
                    <div role='list' className='Activites   activities-list-2 '>
                        
                        <ul  className='completedList p-0'> 
                            {completed?.map((item)=>(
                                <li key={item?.id} className=' rounded p-2 my-1' onClick={()=>{
                                    if (item?.status?.name){ 
                                        setMeetingEdit(item);  
                                        setShowForm(true)
                                        setNavList(prev => 
                                            prev.map(item => 
                                                item?.name === "Meeting" 
                                                    ? { ...item, isShow: true } 
                                                    : { ...item, isShow: false }
                                            )
                                        );
                                    } else if (item?.types){
                                        setCallEdit(item);
                                        setShowForm(true)
                                        setNavList(prev => 
                                            prev.map(item => 
                                                item?.name === "Call Log" 
                                                    ? { ...item, isShow: true } 
                                                    : { ...item, isShow: false }
                                            )
                                        );
                                    }  else {
                                        setNoteEdit(item)
                                        setShowForm(true)
                                        setNavList(prev => 
                                            prev.map(item => 
                                                item?.name === "Notes" 
                                                    ? { ...item, isShow: true } 
                                                    : { ...item, isShow: false }
                                            )
                                        );
                                    } 
                                }}>
                                    {item?.note ?
                                    <div>
                                        <p className='m-0 p-0 fw-bold'>Note</p>
                                        <p className='m-0'>Note : {item?.note}</p>
                                        <p className='m-0' style={{display:"flex" , justifyContent:"space-between"}}>
                                        <span><span className='fst-italic'>By</span> : {item?.createdBy?.username}</span> 
                                        <span>
                                        {formattedDate === DateFormate(item?.createdAt) ? "Today" :
                                            YESTERDAY_DATE_FORMAT === DateFormate(item?.createdAt) ? "Yesterday" : DateFormate(item?.createdAt) }  {TimeFormate(item?.createdAt)}
                                        </span>
                                        </p>
                                    </div> :
                                    <div> 
                                    <p className='m-0 p-0 fw-bold'>{item?.status  ? `Meeting` : item?.subject ? "Call" : "Note"}</p>
                                    <p className='m-0'>Subject : {item?.subject}</p>
                                    <p className='text-wrap m-0'>Outcome : {item?.outcome === "Empty" ?"" : item?.outcome}</p>
                                    <p className='m-0' style={{display:"flex" , justifyContent:"space-between"}}>
                                        <span><span className='fst-italic'>By</span> : {item?.salesPerson?.username}</span> 
                                        <span> 
                                           
                                           {formattedDate === DateFormate(item?.completedDate ? item?.completedDate :  item?.createdAt) ? "Today" : 
                                           YESTERDAY_DATE_FORMAT === DateFormate(item?.completedDate ? item?.completedDate :  item?.createdAt) ? "Yesterday" : DateFormate(item?.createdAt) } 
                                       </span>
                                    </p>
                                </div>
                                    }
                                    
                                </li>
                            ))} 
                        </ul>

                    </div>
                </>
                :""
            }
         
           
        </div>
      
       
      
       
    </>
  )
}

export default ListOfActivites