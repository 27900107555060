import * as yup from "yup";


export const salesOrderItemDetailsschema = yup.object().shape({
    item_master_part_code: yup.object().required('Part Code is required'),
    item_master_part_name: yup.object().required('Part Name is required'),
    qty: yup.number().required('Quantity is required'),
    uom: yup.object().required('Unit is required'),
    rate: yup.number().required('rate is required'),
    tax: yup.number().required('tax is required'),
    amount: yup.number(),
    // discount: yup.string().nullable()
    // .test(
    //     'is-valid-discount',
    //     'Discount must be a valid percentage or currency amount',
    //     value => { 

    //         if (value  === undefined || value.trim()  === ''){
    //         return true; // Allow empty string
    //         } 
    //         return /^₹\d+(\.\d+)?$/g.test(value) || /^\d+(\.\d+)?%$/g.test(value);
    //     }
    // )
    // .test(
    //     'is-valid-percentage-range',
    //     'Percentage must be between 1 and 100',
    //     value => { 
    //         if (/^\d+(\.\d+)?%$/g.test(value)) { 
    //             const percentageValue = parseInt(value.replace('%', ''), 10);
    //             return percentageValue >= 0 && percentageValue <= 100;
    //         }
    //         return true;
    //     }
    // )
    discount: yup.string().nullable()
        .test(
            'is-valid-discount',
            'Discount must be a valid percentage or currency amount',
            value => {
                if (value === undefined || value.trim() === '') {
                    return true; // Allow empty string
                }

                return /^(₹|€|\$|¥)?\d+(\.\d+)?$/.test(value) || /^\d+(\.\d+)?%$/.test(value.trim());
            }
        )
        .test(
            'is-valid-percentage-range',
            'Percentage must be between 1 and 100',
            value => {
                if (/^\d+(\.\d+)?%$/.test(value)) {
                    const percentageValue = parseInt(value.replace('%', ''), 10);
                    return percentageValue >= 0 && percentageValue <= 100;
                }
                return true;
            }
        )
})

export const SalesOrderOtherIncomeCharges = yup.object().shape({
    otherIncomeChargesId: yup.object().required('Account is required'),
    amount: yup.string().required('Amount is required'),
})


export const SalesOrder2schema = yup.object().shape({
    salesPerson: yup.number().required('Sales Person is required'),
    dueDate: yup.date().required('Due Date is required'),
    creditPeriod: yup.number().required('Credit Period is required'),
    paymentTerms: yup.string().required('Payment Terms is required'),
    customerPoNo: yup.string().required('Customer PO No is required'),
    customerPoDate: yup.date().required('Customer PO Date is required'),
    department: yup.number().required('Department is required'),
    buyer: yup.number().required('Buyer is required'),
    buyerAddress: yup.number().required('Buyer Address is required'),
    buyerContactPerson: yup.number().required('Buyer Contact Person is required'),
    buyerPlaceOfSupply: yup.string().required('Buyer Place Of Supply is required'),
    consignee: yup.number().required('Consignee is required'),
    consigneeAddress: yup.number().required('Consignee Address is required'),
    consigneeContactPerson: yup.number().required('Consignee Contact Person is required'),
    consigneePlaceOfSupply: yup.string().required('Consignee Place Of Supply is required'),
    termsConditions: yup.number().required('Terms Conditions is required'),
    termsConditionsText: yup.string().required('Terms Conditions Text is required'),
    salesOrderDate: yup.date().required('Sales Order Date is Required')
})