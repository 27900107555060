
// import AuthContext, { AuthProvider } from '../../context/AuthProviderContext';
 

const Can = ({ allow_list = [], I, a, children }) => {
  // Get permissions from localStorage
  let permissions = localStorage.getItem('permissionlist')
  // Define the action resource
  const actionResource = `${I}_${a}`;
  // Check if any item in allow_list or the actionResource is in permissions
  const isAllowed = allow_list?.some(item => permissions?.includes(item)) || permissions?.includes(actionResource);
  // Render children if allowed, otherwise render null
  return isAllowed ? <>{children}</> : null;
};

export default Can;
