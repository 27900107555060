import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineCloudUpload, AiTwotoneCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import DataContext from '../../../context/ItemMasterContext';
import { deleteDocument, deleteImage } from '../../../GraphQLQueries/ItemMastermutation';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { handleFileUploadToS3 } from '../../../utils/FileUploadToS3';
import { checkChangesInputs, OnchangeGetData, removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import { ItemMasterSchema } from '../../../validations/itemmaster';
import AddAccount from './AddAccount';
import AddAccountGroup from './AddAccountGroup';
import AddHsn from './AddHsn';
import AddItemGroup from './AddItemGroup';
import AddUom from "./AddUom";
import AlternateUnit from "./AlternateUnit";
import ItemCombo from './ItemCombo';
import PurchaseModal from "./PurchaseModal";
import PurchaseSellingModal from "./PurchaseSellingModal";
import SellingPrice from "./SellingPrice";
import Serviceable from "./Serviceable";


const ItemaMasterModal = ({ setLoad }) => {
  const { ItemUOMdata, Accountdata, itemaMasterModal, handleItemaMasterClose, handlitemGroupShow,
    handlUOMShow, handlAlternateUomShow, handlHSNShow, ItemGroupSelect, ItemUOMSelect,
    ItemHSNSelect, type, setType, tax, setTax,
    setToastSave, AccountGroupType, setAccountGroupType, handleItemComboShow, userId,
    dupRefData, screenSize, setDupRefData
  } = useContext(DataContext);
  const dispatch = useDispatch();
  const OptionsDataList = useSelector(state => state.itemGroup.data);
  const [error, setError] = useState('')
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState(null)
  const [docId, setDocId] = useState([])
  const [locationData, setlocationData] = useState(null)
  const [purchaseUomOptions, setPurchaseUomOptions] = useState([])
  const [selectedPurchaseuom, setSelectedPurchaseuom] = useState();
  const itemGroupRef = useRef(null);
  const ItemtypeRef = useRef(null);
  const ItemUomRef = useRef(null);
  const ItemcategoryRef = useRef(null);
  const ItemIndicatorRef = useRef(null);
  const ItemHsnRef = useRef(null);
  const DocInputRef = useRef(null);
  const ImageInputRef = useRef(null);


  const handleDocumentClick = () => {
    // Trigger a click event on the file input
    DocInputRef.current.click();
  };
  const handleImageClick = () => {
    // Trigger a click event on the file input
    ImageInputRef.current.click();
  };
  const handleDocSelect = async (e) => {
    let selectedFiles = e.target.files
    if (selectedFiles.length > 4) {
      alert("You can only upload up to 4 documents.");
      return;
    }
    setDocId([])
    const maxFileSizeMB = 5;
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
    let validFiles = [];

    for (let doc of selectedFiles) {
      if (doc.size > maxFileSizeBytes) {
        alert(`${doc.name} exceeds the 5MB size limit.`);
        continue;
      }
      validFiles.push(doc);
    }
    if (validFiles.length === selectedFiles.length) {
      for (let doc of Array.from(e.target.files)) {

        try {
          handleFileUploadToS3(doc, 'documents')
            .then(uploaded_file_url => {
              const mutation_query = ` 
              mutation MyMutation($document: String!) {
                documentCreateMutation(document: $document) { 
                  documentType{
                    id 
                  }
                  success
                  errors
                }
              }`
              const variables = {
                document: uploaded_file_url,
              };
              axiosInstance.post('/itemmaster/graphql', {
                query: mutation_query,
                variables: variables,
              })
                .then(response => {
                  const responseData = response?.data?.data?.documentCreateMutation;
                  if (responseData?.success) {
                    const document_id = responseData?.documentType?.id;
                    let extracted_data = doc
                    extracted_data['id'] = document_id

                    console.log("extracted_data", extracted_data);

                    setSelectedDoc(prev => [...prev, extracted_data]);
                    setDocId(prev => [...prev, document_id]);
                  } else {
                    const filteredError = ShowErrorNoties(responseData.errors);
                    toast.error(filteredError, {
                      position: "bottom-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: false,
                      pauseOnHover: true,
                      draggable: false,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                })
                .catch(error => {
                  console.error('Error uploading image:', error);
                  const filteredError = ShowErrorNoties(error);
                  toast.error(filteredError, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                  });
                });
            })
            .catch(err => {

            })
        } catch (error) {
          console.log(error);
        }

      }
    } else {
      alert("Some files were invalid and were not uploaded. Please check the file size and try again.");
    }

  }


  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSizeInMB = 2;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      // Check file size
      if (file.size > maxSizeInBytes) {
        toast.error('File size must be less than 2MB', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        return; // Stop execution if file size exceeds 2MB
      }

      // Check image dimensions
      const image = new Image();
      image.onload = () => {
        if (image.width !== 160 || image.height !== 160) {
          toast.error('Image dimensions must be 160x160 pixels', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          return; // Stop execution if dimensions are incorrect
        } else {
          // If size and dimensions are valid, proceed with the upload
          try {
            handleFileUploadToS3(file, 'Image_data')
              .then(uploaded_file_url => {
                const mutation_query = `
                    mutation MyMutation($image: String!) {
                      imageCreateMutation(image: $image) { 
                        imageType{
                          id
                          image
                        }
                        success
                        errors
                      }
                    }`;
                const variables = {
                  image: uploaded_file_url,
                };
                axiosInstance.post('/itemmaster/graphql', {
                  query: mutation_query,
                  variables: variables,
                }).then(response => {
                  let responceData = response?.data?.data?.imageCreateMutation;
                  if (responceData?.success) {
                    let image_id = responceData?.imageType?.id;
                    setImageId(image_id);
                    setSelectedImage(file);
                  } else {
                    let filiterError = ShowErrorNoties(responceData.errors);
                    toast.error(filiterError, {
                      position: "bottom-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: false,
                      pauseOnHover: true,
                      draggable: false,
                      progress: undefined,
                      theme: "light",
                    });
                    setSelectedImage('');
                  }
                }).catch(error => {
                  console.error('Error uploading image:', error);
                });
              }).catch(err => {
                alert('Failed to Upload Image. try again');
              });
          } catch (error) {
            let filiterError = ShowErrorNoties(error);
            alert(filiterError);
          }
        }
      };
      image.onerror = () => {
        toast.error('Invalid image file', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      };
      image.src = URL.createObjectURL(file);
    } else {
      setSelectedImage('');
    }
  };


  const [Purchase, setPurchase] = useState(false)

  // for select elemtnt
  const [keepStock, setKeepStock] = useState(false)
  const handleChangeKeepStock = (e) => {
    setKeepStock(e)
  }

  const [selectedItemGroup, setSelectedItemGroup] = useState();
  const handleSelectitemgroup = (option) => {
    setSelectedItemGroup({ value: option?.value, label: option?.label });
  };
  const [selectUOM, setSelectUOM] = useState()
  const handleSelectUOM = (option) => {
    if (option) {
      setSelectUOM({ value: option?.value, label: option?.label });
    } else {
      setSelectUOM({ value: "", label: "" });
    }


    // Your other logic here
  };
  const [isProduct, setProduct] = useState(true)
  const handleItemTypeChange = (item_type) => {
    if (item_type && item_type === 'Product') {
      setProduct(true)
    } else {
      setProduct(false)
    }
  }
  const [selectedtype, setSelectedtype] = useState({ value: "", label: '' });
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedItem_Indicator, setSelectedItem_Indicator] = useState({ value: "", label: '' });
  const [selectedAccount, setSelectedAccount] = useState();
  const [selectHSN, setSelectHSN] = useState()
  const [formKey, setFormKey] = useState(0);
  const [isItemComboPrint, setItemComboPrint] = useState(false);
  const [isAlternateUomFixed, setAlternateUomFixed] = useState(false);


  const handleSelectHSN = (option) => {
    setSelectHSN({ value: option?.value, label: option?.label });
  };
  useEffect(() => {
    const selectedItemID = ItemHSNSelect.id;
    const selectedItemlable = ItemHSNSelect.lable;
    setSelectHSN({ value: selectedItemID, label: selectedItemlable })
    if (selectedItemID) {
      ItemHsnRef.current.setValue({ value: selectedItemID, label: selectedItemlable })
    }


  }, [ItemHSNSelect]);
  useEffect(() => {
    const selectedItemID = ItemUOMSelect.id;
    const selectedItemlable = ItemUOMSelect.lable;
    if (selectedItemID) {
      setSelectUOM({ value: selectedItemID, label: selectedItemlable })
      // ItemUomRef.current.setValue({ value: selectedItemID , label:  selectedItemlable })
    }
  }, [ItemUOMSelect]);
  useEffect(() => {
    const selectedItemID = ItemGroupSelect.id;
    const selectedItemlable = ItemGroupSelect.lable;
    if (selectedItemID) {
      setSelectedItemGroup({ value: selectedItemID, label: selectedItemlable })
      itemGroupRef.current.setValue({ value: selectedItemID, label: selectedItemlable })
    }
  }, [ItemGroupSelect]);

  const handlePurchaseClose = () => {
    // setPurchaseUomOptions(prev => prev.filter(option => option?.value !== selectUOM?.['value'])); 
    setPurchase(false)

  }
  const handlePurchaseShow = () => {
    if (selectUOM) {
      setPurchaseUomOptions(prve => [...prve, selectUOM])
    }
    setPurchase(true)

  }
  const [Selling, setSelling] = useState(false)
  const handleSellingClose = () => {
    setSelling(false)

  }
  const handleSellingShow = () => {
    setSelling(true)
  }
  const [Both, setBoth] = useState(false)
  const handlebothClose = () => {
    setBoth(false)

  }
  const handlebothShow = () => {
    setBoth(true)
  }
  const [serviceable, setServiceable] = useState(false)
  const [serviceAbleBool, setServiceAbleBool] = useState(false)
  const handleserviceableClose = () => {
    setServiceable(false)
  }
  const handleserviceableShow = () => {
    setServiceable(true)
    setServiceAbleBool(true)
  }

  function reset_form() {
    setSelectedItemGroup({ value: "", label: "" });
    setSelectHSN({ value: "", label: "" });
    setSelectUOM({ value: "", label: "" })
    setSelectedItem_Indicator({ value: "", label: "" })
    setSelectedCategory({ value: "", label: "" })
    setSelectedItem_Indicator({ value: "", label: "" })
    setSelectedtype({ value: "", label: "" });
    setSafeStock(0.00)
    setOrderQty(0.00)
    setLeadTime(0.00)
    setTax()
    setitemQc(false)
    setServiceAbleBool(false)
    setComboBool(false)
    setlocationData()
    setSelectedImage("")
    setSelectedDoc([])
    setIsManufacture(false)
    setSelectedPurchaseuom({ value: "", label: "" })
    setDupRefData(null)
    initialmaster['Item_Cost'] = "0"
    initialmaster['Item_Mrp'] = "0"
    initialmaster['Item_min_price'] = "0"
    setPurchaseUomOptions([])
    setProduct(true)
  }


  const [initialmaster, setInitialMaster] = useState({
    Item_PartCode: "",
    Item_name: "",
    Description: "",
    Item_Group: null,
    Item_type: null,
    Item_UOM: null,
    Alternate_uom: [],
    category: null,
    Item_Indicator: null,
    Item_Cost: "0",
    Purchase_uom: "",
    product_image: "",
    Item_Safe_Stock: 0,
    Item_Order_Qty: 0,
    Item_Leadtime: 0,
    Item_Purchase_Account: "",
    supplierData: [],
    Item_Mrp: 0,
    Total_Stock: 0,
    Rejected_Stock: 0,
    Item_min_price: 0,
    Item_Sales_Account: "",
    Item_HSN: null,
    Keep_stock: false,
    serial: false,
    location: "",
    note: "",
    variation: "",
    is_manufacture: false,
    serial_auto_gentrate: false,
    Serial_format: "",
    Serial_starting: 0,
    Batch_number: false,
    Service: false,
    Item_Combo_bool: false,
    Item_Combo_data: [],
    Item_Barcode: false,
    Item_Active: true,
    item_qc: false,
  })

  const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {


    const save_itemmaster = {
      "itemPartCode": values['Item_PartCode'],
      "itemName": values['Item_name'],
      "description": values['Description'] ? values['Description'] : `${values['Item_PartCode']}-${values['Item_name']}`,
      "itemTypes": Number(values['Item_type']),
      "productImage": imageId !== null ? Number(imageId) : "",
      "productDocument": docId,
      "location": values['location'],
      "itemUom": Number(values['Item_UOM']),
      "alternateUom": values['Alternate_uom'] ? values['Alternate_uom'] : [],
      "itemIndicators": values['Item_Indicator'] ? Number(values['Item_Indicator']) : "",
      "itemCost": values['Item_Cost'] ? (values['Item_Cost']) : 0,
      'itemComboData': values['Item_Combo_data'].length > 0 ? (values['Item_Combo_data']) : [],
      "itemSafeStock": values['Item_Safe_Stock'] ? Number(values['Item_Safe_Stock']) : 0,
      "itemOrderQty": values['Item_Order_Qty'] ? Number(values['Item_Order_Qty']) : 0,
      "itemLeadTime": values['Item_Leadtime'] ? Number(values['Item_Leadtime']) : 0,
      "itemMrp": values['Item_Mrp'] ? Number(values['Item_Mrp']) : 0,
      "itemMinPrice": values['Item_min_price'] ? Number(values['Item_min_price']) : 0,
      "keepStock": values?.['Keep_stock'],
      "serial": values['serial'],
      "serialAutoGentrate": values['serial_auto_gentrate'],
      "serialFormat": values['Serial_format'] ? values['Serial_format'] : "",
      "serialStarting": values['Serial_starting'] ? Number(values['Serial_starting']) : 0,
      "batchNumber": values['Batch_number'],
      "sellOnMrp": values['sell_on_mrp'] !== undefined ? values['sell_on_mrp'] : false,
      "service": values['Service'],
      "totalStock": values['Total_Stock'] ? Number(values['Total_Stock']) : 0,
      "rejectedStock": values['Rejected_Stock'] ? Number(values['Rejected_Stock']) : 0,
      "itemWarrantyBased": values['Item_Warranty_based'] !== undefined ? values['Item_Warranty_based'] : "",
      "itemInstallation": values['Item_Installation'] !== undefined ? values['Item_Installation'] : false,
      "invoiceDate": values['Invoicedate'] !== undefined ? values['Invoicedate'] : "",
      "installationData": values['installation_data'] !== undefined ? values['installation_data'] : "",
      "itemComboBool": values['Item_Combo'] !== undefined ? values['Item_Combo'] : false,
      "itemBarcode": values['Item_Barcode'],
      "itemActive": values['Item_Active'],
      "itemQc": values['item_qc'],
      "notes": values['note'],
      "isManufacture": isManufacture,
      "variation": values['variation'],
      "itemGroup": Number(values['Item_Group']),
      "category": Number(values['category']),
      "purchaseUom": values['Purchase_uom'] ? Number(values['Purchase_uom']) : "",
      "itemSalesAccount": values['Item_Sales_Account'] ? Number(values['Item_Sales_Account']) : "",
      "itemPurchaseAccount": values['Item_Purchase_Account'] ? Number(values['Item_Purchase_Account']) : "",
      "itemHsn": Number(values['Item_HSN']),
      "supplierData": values['supplierData'].length > 0 ? values['supplierData'] : [],
      "createdBy": Number(userId),
      "itemComboPrint": isItemComboPrint,
      "alternateUomFixed": isAlternateUomFixed,
    }

    if (imageId) {
      save_itemmaster['productImage'] = Number(imageId)
    }
    if (docId && docId.length > 0) {
      save_itemmaster['productDocument'] = docId
    }
    let variable = removeEmptyValueInObject(save_itemmaster)
    let query = {
      "query": `mutation MyQuery { itemMasterCreateMutation(` + variable + `){ 
            itemMaster{
              id
              itemPartCode
              itemComboBool
              itemComboData {
                id
                itemDisplay {
                  id
                }
              }
            }
            errors
            success
          }
        }`
    }
    const response = await axiosInstance.post("/itemmaster/graphql", query)
    if (response.data.data.itemMasterCreateMutation.success) {
      const responseData = Array.isArray(response.data) ? response.data : [response.data];
      let part_number = Number(response.data.data.itemMasterCreateMutation.itemMaster?.id)
      if (part_number) {
        if (save_itemmaster['itemComboBool'] && save_itemmaster['itemComboData'].length > 0) {
          let matched_item_display_group = save_itemmaster['itemComboData']
          matched_item_display_group.map(item => {
            let update_query = `mutation myFirstCreateQuery{
                  displayGroupCreateMutation(partNumber: ${part_number}, id: ${item}){
                      displayGroupItem{
                        id
                        display
                        partNumber{
                          id
                        }
                      }
                      success
                      errors
                    }
                }`
            const response_1 = axiosInstance.post(`/itemmaster/graphql`, { query: update_query });
          })
        }

        let stock_serial_history_mutation = `mutation stockSerialHistoryCreation {
              stockSerialHistoryCreateMutation(partNo: ${part_number}, lastSerialHistory: ${values['Serial_starting']}){
                success
                  errors
                  stockSerialHistoryInstance{
                  id
                  lastSerialHistory
                  partNo{
                  id
                  itemPartCode
                  }
                }
              }
            }`
        await axiosInstance.post("/itemmaster/graphql", { query: stock_serial_history_mutation })
      }

      setToastSave(true);
      handleItemaMasterClose()
      setError("")
      setTimeout(() => { setToastSave(false); }, 3000);
      resetForm();
      reset_form();
      setLoad()
      setSubmitting(false);
    } else {
      let error = response.data.data.itemMasterCreateMutation.errors
      console.log(error);
      let errorData = ShowErrorNoties(error)
      console.log(errorData);
      toast.error(errorData, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

    }
  }

  const [aUOM, setAUOM] = useState([])
  const handleAlternateUomChanged = (data) => {
    setAUOM(data)
  }

  // clear confomations
  const [waringToCloseModel, setWaringToCloseModel] = useState(false)
  const handleCloseWaring = () => { setWaringToCloseModel(false) }
  const handleShowWaring = () => { setWaringToCloseModel(true) }
  let currentValues = {};
  const WaringToClose = () => {

    const cleaeTheData = () => {
      setError('');
      handleItemaMasterClose();
      setWaringToCloseModel()
      reset_form()
    }
    return (
      <>
        <Modal
          show={waringToCloseModel}
          onHide={handleCloseWaring}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you want to leave without saving?
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-outline-warning btn-sm" onClick={handleCloseWaring}>No</button>
            <button type="button" className="btn btn-outline-success btn-sm" onClick={cleaeTheData}>Yes</button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  function Close() {
    let isDuplicate = checkChangesInputs([initialmaster, currentValues])
    if (!isDuplicate) {
      setError('');
      handleItemaMasterClose();
      reset_form()
    } else {
      handleShowWaring()
    }
  }
  const [safeStock, setSafeStock] = useState()
  const [orderQty, setOrderQty] = useState()
  const [leadTime, setLeadTime] = useState()
  const [serialBool, setSerialBool] = useState()
  const [autoGentrateBool, setAutoGentrateBool] = useState()
  const [serialFormate, setSerialFormate] = useState()
  const [serialStarting, setSeriatStarting] = useState()
  const [comboBool, setComboBool] = useState(false)
  const [itemQc, setitemQc] = useState()
  const [isManufacture, setIsManufacture] = useState(false)

  useEffect(() => {
    if (dupRefData) {
      let dupData = dupRefData
      const newInitialValues = {
        ...initialmaster, // spread existing values to maintain form structure
        Item_type: dupRefData['itemTypes']?.['id'] ?? '',
        Item_Group: dupRefData['itemGroup']?.['id'] ?? '',
        Item_UOM: dupRefData['itemUom']?.['id'] ?? '',
        category: dupRefData['category']?.['id'] ?? '',
        Item_HSN: dupRefData['itemHsn']?.['id'] ?? '',
        Item_Indicator: dupRefData['itemIndicators']?.['id'] ?? '',
        Item_Mrp: dupData['itemMrp'] ? dupData['itemMrp'] : 0,
        Item_min_price: dupData['itemMinPrice'] ? dupData['itemMinPrice'] : 0,
        Item_Sales_Account: dupData['itemSalesAccount']?.['id'] ?? "",
        Item_Purchase_Account: dupData['itemPurchaseAccount']?.['id'] ?? "",
        Item_Cost: dupData['itemCost'] ? dupData['itemCost'] : 0,
        Purchase_uom: dupData['purchaseUom']?.['id'] ?? "",
        Item_Safe_Stock: dupRefData['itemSafeStock'] ?? '',
        Item_Order_Qty: dupRefData['itemOrderQty'] ?? '',
        Item_Leadtime: dupRefData['itemLeadTime'] ?? '',
        serial: dupRefData['serial'] ?? '',
        serial_auto_gentrate: dupRefData['serialAutoGentrate'] ?? '',
        Serial_format: dupRefData['serialFormat'] ?? '',
        Serial_starting: dupRefData['serialStarting'] ?? '',
        Service: dupRefData['service'] ?? '',
        Item_Combo_bool: dupRefData['itemComboBool'] ?? '',
        item_qc: dupRefData['itemQc'] ?? '',
        Item_Barcode: dupData['itemBarcode'],
        Batch_number: dupData['batchNumber'],
        note: dupData['notes'],
        location: dupData['location'],
        variation: dupData['variation']
        // Continue to update the rest of your fields as needed...
      };

      setInitialMaster(newInitialValues);
      if (dupData['location']) {
        setlocationData(dupData['location'])
      }
      if (dupData['itemTypes']) {
        setSelectedtype({ value: initialmaster.Item_type, label: dupData['itemTypes']['name'] });
      }
      if (dupData['itemGroup']) {
        setSelectedItemGroup({ value: initialmaster.Item_Group, label: dupData['itemGroup']['name'] })
      }
      if (dupData['itemUom']) {
        setSelectUOM({ value: initialmaster.Item_UOM, label: dupData['itemUom']['name'] })
      }
      if (dupData['category']) {
        setSelectedCategory({ value: initialmaster.category, label: dupData['category']['name'] })
      }
      if (dupData['itemHsn']) {
        setSelectHSN({ value: initialmaster.Item_HSN, label: dupData['itemHsn']['hsnCode'] })
        setTax(dupData['itemHsn']?.['gstRates']?.['rate'])
      }
      if (dupData['itemIndicators']) {
        setSelectedItem_Indicator({ value: initialmaster.Item_Indicator, label: dupData['itemIndicators']['name'] })
        if (dupData['itemIndicators']['name'] === 'Buy') {
          setType("Buyer")
        } else if (dupData['itemIndicators']['name'] === 'Sell') {
          setType("seller")
        } else if (dupData['itemIndicators']['name'] === 'Both') {
          setType("both")
        }
      }


      if (dupRefData['itemSafeStock']) {
        setSafeStock(dupRefData['itemSafeStock'])
      }
      if (dupRefData['itemOrderQty']) {
        setOrderQty(dupRefData['itemOrderQty'])
      }
      if (dupRefData['itemLeadTime']) {
        setLeadTime(dupRefData['itemLeadTime'])
      }
      if (dupRefData['itemQc']) {
        setitemQc(dupRefData['itemQc'])
      }
      if (dupRefData['itemComboBool']) {
        setComboBool(dupRefData['itemComboBool'])
      }
      if (dupRefData['serialStarting']) {
        setSeriatStarting(dupRefData['serialStarting'])
      }
      if (dupRefData['serialFormat']) {
        setSerialFormate(dupRefData['serialFormat'])
      }
      if (dupRefData['serialAutoGentrate']) {
        setAutoGentrateBool(dupRefData['serialAutoGentrate'])
      }
      if (dupRefData['serial']) {
        setSerialBool(dupRefData['serial'])
      }
      if (dupRefData['service']) {
        setServiceAbleBool(dupRefData['service'])
      }
      if (dupRefData['is_manufacture']) {
        setIsManufacture(dupRefData['is_manufacture'])
      }
    }
  }, [dupRefData])

  const [itemGroupNameOption, setItemGroupNameOptions] = useState([])
  const [categoryOprtion, setCategoryOption] = useState([])
  const [uomOptions, setUomOptions] = useState([])
  const [hsnOptions, setHsnOptions] = useState([])
  const [itemType, setItemType] = useState([])
  const [itemIndicator, setitemIndicator] = useState([])

  function PerFetchOptions() {
    dispatch(fetchOptionsData(QueryData.ItemTypeOptionQuery(), "ItemMasterType"))
    dispatch(fetchOptionsData(QueryData.ItemIndicatiorOptionQuery(), "ItemMasterIndicator"))
    dispatch(fetchOptionsData(QueryData.UOMOptionQuery(), "UOMOption"))
    dispatch(fetchOptionsData(QueryData.ItemCategoryOptionQuery(), "CategoryOption"))
    dispatch(fetchOptionsData(QueryData.ItemGroupOptionQueryWithFiliter(), "GroupOption"))
  }
  useEffect(() => {
    if (itemaMasterModal && itemIndicator.length > 0) {
      const defaultOption = itemIndicator.find(option => option.label === "Not Applicable");
      if (defaultOption) {
        setSelectedItem_Indicator(defaultOption);
      }

    }

  }, [itemaMasterModal])
  useEffect(() => {
    if (OptionsDataList.name === "ItemMasterType") {
      setItemType(OptionsDataList?.value?.items?.map((type) => ({ value: type.id, label: type.name })))
    }
    if (OptionsDataList.name === "ItemMasterIndicator") {
      setitemIndicator(OptionsDataList.value.items.map((type) => ({ value: type.id, label: type.name })))
    }
    if (OptionsDataList.name === "UOMOption") {
      setUomOptions(OptionsDataList.value.items.map((type) => ({ value: type.id, label: type.name })))
    }
    if (OptionsDataList.name === "CategoryOption") {
      setCategoryOption(OptionsDataList.value.items)
    } if (OptionsDataList.name === "GroupOption") {
      setItemGroupNameOptions(OptionsDataList.value.items)
    }
  }, [OptionsDataList])

  useEffect(() => {
    PerFetchOptions()
  }, [])

  // getting data for react select 
  async function getOptions(e, queryName, optionName) {

    let search_term = e.trim().replace(/"/g, '\\"');
    // if(queryName === "ItemGroupOptionQueryWithFiliter" && search_term){ 
    //   let responseData =  await  OnchangeGetData(QueryData[queryName](String(search_term)))
    //   if(responseData.success){ 
    //     setItemGroupNameOptions(responseData.data[optionName]['items'])
    //   }
    // } else{
    //   setItemGroupNameOptions([])
    // }
    if (queryName === "HsnOptionQueryWithFiliter" && search_term) {
      let hsn_query_argument = `hsnCode: ${search_term}, `
      if (selectedtype?.label === "Service") {
        hsn_query_argument += ` hsnTypes:"SAC"`
      }
      let responseData = await OnchangeGetData(QueryData[queryName](hsn_query_argument))
      if (responseData.success) {
        setHsnOptions(responseData.data[optionName]['items'])
      }
    } else {
      setHsnOptions([])
    }

  }

  const handleChangeItemComboPrint = (value) => {
    setItemComboPrint(value)
  }

  const handleChangeAlternateUomFixed = (value) => {
    setAlternateUomFixed(value)
  }

  const handleDeleteDocument = (document) => {
    if (document.id) {
      let delete_mutation_query = deleteDocument(document.id)
      axiosInstance.post("/itemmaster/graphql", { query: delete_mutation_query })
        .then(res => {
          if (res?.data?.data?.documentDeleteMutation?.success) {
            let document_list = [...selectedDoc]
            document_list = document_list.filter(item => item?.id !== document?.id)
            setSelectedDoc(document_list)
            toast.success('Document deleted successfully!.', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
              // transition: Bounce,
            })
          } else {
            toast.error('Failed to delete document!.', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
              // transition: Bounce,
            })
          }
        })
        .catch(err => {
          toast.error('Failed to delete document!.', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
            // transition: Bounce,
          })
        })
    }
  }

  const handleDeleteAllDocument = () => {
    selectedDoc.map(document => {
      let delete_mutation_query = deleteDocument(document.id)
      axiosInstance.post("/itemmaster/graphql", { query: delete_mutation_query })
        .then(res => {
          if (res?.data?.data?.documentDeleteMutation?.success) {
            let document_list = [...selectedDoc]
            document_list = document_list.filter(item => item?.id !== document?.id)
            setSelectedDoc(document_list)
            toast.success('Document deleted successfully!.', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
              // transition: Bounce,
            })
          } else {
            toast.error('Failed to delete document!.', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
              // transition: Bounce,
            })
          }
        })
        .catch(err => {
          toast.error('Failed to delete document!.', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
            // transition: Bounce,
          })
        })
    })
    setSelectedDoc([])
  }

  const handleDeleteImage = () => {
    if (selectedImage?.id) {
      let delete_mutation_query = deleteImage(selectedImage.id)
      axiosInstance.post("/itemmaster/graphql", { query: delete_mutation_query })
        .then(res => {
          if (res?.data?.data?.imageDeleteMutation?.success) {
            setSelectedImage(null)
            toast.success('Image deleted successfully!.', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
              // transition: Bounce,
            })
          } else {
            toast.error('Failed to delete Image!.', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
              // transition: Bounce,
            })
          }
        })
        .catch(err => {
          toast.error('Failed to delete Image!.', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
            // transition: Bounce,
          })
        })
    } else {
      setSelectedImage(null)
    }
  }

  return (
    <>
      <ToastContainer />
      {WaringToClose()}
      <Modal show={itemaMasterModal}
        size="xl" onHide={() => { Close() }} >
        <Modal.Header closeButton>
          <Modal.Title>Add Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='text-danger text-center' >{error ? error : ""}</div>
          <Formik
            enableReinitialize={true}
            key={formKey}
            initialValues={initialmaster}
            validationSchema={ItemMasterSchema}
            onSubmit={handleSubmit} >
            {({ errors, touched, isSubmitting, values, setFieldValue, Formik, formikProps }) => {
              currentValues = values;
              return (
                <>
                  <Form>
                    {/* new code starts */}
                    <div className='d-flex mb-1 justify-content-between'>
                      <div style={{ width: '53%' }}>
                        <div className='card px-1'>
                          <div className="pt-1 mx-1 border-bottom custom-bottom-shadow">
                            <h6 className='mx-3' >Basic Details</h6>
                          </div>
                          <div className='pt-3'>
                            <div className="row mx-2">
                              <div className="col-6 staan-input-group">
                                <Field type='text' id='Part Code' name='Item_PartCode' className='w-100 staan-form-input ' />
                                <label htmlFor="Part Code" className='staan-form-input-label pt-2 px-1 mx-2'>Part Code</label>
                                {touched.Item_PartCode && errors.Item_PartCode && <small className="staan-error-msg">{errors.Item_PartCode}</small>}
                              </div>
                              <div className="col-6 staan-input-group">
                                <Field type='text' id="Part Name" name='Item_name' className='w-100 staan-form-input' />
                                <label htmlFor="Part Name" className='staan-form-input-label pt-2 px-1 mx-2'>Item Name</label>
                                {touched.Item_name && errors.Item_name && <small className="staan-error-msg">{errors.Item_name}</small>}
                              </div>
                            </div>
                            <div className='row mx-2 mt-1'>
                              <div className="col-12 staan-input-group">
                                <Field type='text' id='Description' name='Description' className='w-100 staan-form-input' />
                                <label htmlFor="Description" className='staan-form-input-label pt-2 px-1 mx-2'>Description</label>
                                {touched.Description && errors.Description && <small className="staan-error-msg">{errors.Description}</small>}
                              </div>
                            </div>
                            <div className='row mx-2 mt-2'>
                              <div className="col-6 staan-input-group">
                                <CustomSelect
                                  ref={ItemtypeRef}
                                  setSelectEditValue={selectedtype}
                                  options={itemType}
                                  name='Item_type'
                                  id='Item_type'
                                  handleOnChange={(option) => {
                                    setFieldValue('Item_type', option ? option?.value : null)
                                    setSelectedtype({ value: option?.value, label: option?.label });
                                    handleItemTypeChange(option?.label)
                                    setFieldValue('Item_UOM', null);
                                    handleSelectUOM({ value: "", label: "" })
                                    if (option?.label === 'Product') {
                                      setFieldValue('Keep_stock', true)
                                    } else {
                                      setFieldValue('Keep_stock', false)
                                    }
                                  }}
                                />
                                <label htmlFor="pro_ser" className='staan-form-input-label pt-1 px-1 mx-2'>Item Type</label>
                                {touched.Item_type && errors.Item_type && <small className="staan-error-msg">{errors.Item_type}</small>}
                              </div>
                              <div className="col-6 staan-input-group">
                                <CustomSelect
                                  ref={itemGroupRef}
                                  setSelectEditValue={selectedItemGroup}
                                  handleInputChange={(e) => {
                                    getOptions(e, "ItemGroupOptionQueryWithFiliter", "itemGroupsName")
                                  }}
                                  options={itemGroupNameOption.map((item) => ({ value: item.id, label: item.name }))}
                                  name='Item_Group'
                                  id='Item_Group'
                                  handleOnChange={(option) => {
                                    setFieldValue('Item_Group', option ? option?.value : null);
                                    handleSelectitemgroup(option)
                                  }}
                                />
                                <label htmlFor="Item_Group_" className='staan-form-input-label pt-1 px-1 mx-2'>Item Group <i role="button" className='bx bx-plus-circle' onClick={handlitemGroupShow} ></i></label>

                                {touched.Item_Group && errors.Item_Group && <small className="staan-error-msg">{errors.Item_Group}</small>}
                              </div>
                            </div>
                            <div className='row mx-2'>
                              <div className="col-6 staan-input-group">
                                <CustomSelect
                                  ref={ItemcategoryRef}
                                  setSelectEditValue={selectedCategory}
                                  handleInputChange={(e) => {
                                    getOptions(e, "ItemCategoryOptionQuery", "categories")
                                  }}
                                  options={categoryOprtion.map((item) => ({ value: item.id, label: item.name }))}
                                  name='category'
                                  id='category'
                                  handleOnChange={(option) => {
                                    setFieldValue('category', option ? option?.value : null)
                                    setSelectedCategory({ value: option?.value, label: option?.label })
                                  }}
                                />
                                <label className='staan-form-input-label pt-1 px-1 mx-2'>Item Category</label>
                                {touched.category && errors.category && <small className="staan-error-msg">{errors.category}</small>}
                              </div>
                              <div className="col-6 staan-input-group">
                                <CustomSelect
                                  ref={ItemUomRef}
                                  setSelectEditValue={selectUOM}
                                  handleInputChange={(e) => {
                                    getOptions(e, "UOMOptionQuery", "uom")
                                  }}
                                  options={selectedtype?.label === "Service"
                                    ? uomOptions.filter(item => {
                                      return item?.label === "Nos";
                                    })
                                    : uomOptions}
                                  name='Item_UOM'
                                  id='Item_UOM'
                                  handleOnChange={(option) => {
                                    setFieldValue('Item_UOM', option ? option?.value : null);
                                    handleSelectUOM(option)
                                  }}
                                />
                                <label htmlFor="Item_UOM" className='staan-form-input-label pt-1 px-1 mx-2'>UOM <i role='button' className='bx bx-plus-circle' onClick={handlUOMShow} ></i></label>
                                <div className="d-flex justify-content-between">
                                  <small>{touched.Item_UOM && errors.Item_UOM && <small className="staan-error-msg">{errors.Item_UOM}</small>}</small>
                                  <div className="text-end">
                                    <button type="button" role='button' id='Item_Alternate_UOM' className="btn btn-sm btn-outline-secondary " disabled={values.Item_UOM ? false : true} onClick={() => { handlAlternateUomShow() }}>Alternate UOM</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row mx-2'>
                              <div className="col-6 staan-input-group">
                                <CustomSelect
                                  ref={ItemHsnRef}
                                  setSelectEditValue={selectHSN}
                                  handleInputChange={(e) => {
                                    getOptions(e, "HsnOptionQueryWithFiliter", "hsn")
                                  }}
                                  options={hsnOptions.map((item) => ({ value: item.id, label: item.hsnCode, gst_rate: item.gstRates ? item.gstRates.rate : "", }))}
                                  name='Item_HSN'
                                  id='accountItem_HSNName'
                                  handleOnChange={(option) => {
                                    setFieldValue('Item_HSN', option ? option?.value : null)
                                    handleSelectHSN(option);
                                    setTax(option ? option?.gst_rate : null)
                                  }}
                                />
                                <label htmlFor="Item_HSN" className='staan-form-input-label pt-1 px-1 mx-2'>HSN <i role="button" className='bx bx-plus-circle' onClick={handlHSNShow} ></i></label>
                                {touched.Item_HSN && errors.Item_HSN && <small className="staan-error-msg">{errors.Item_HSN}</small>}
                              </div>
                              <div className="col-6 staan-input-group">
                                <input type="number" id='Tax' className='staan-form-input' value={tax} disabled />
                                <label htmlFor="Tax" className='staan-form-input-label pt-2 px-1 mx-2'>Tax</label>
                              </div>
                            </div>
                            <div className="row mx-2 mb-3 mt-2">
                              <div className="col-5 staan-input-group">
                                <CustomSelect
                                  ref={ItemIndicatorRef}
                                  options={itemIndicator}
                                  name='Item_Indicator'
                                  id='Item_Indicator'
                                  handleOnChange={(option) => {
                                    setFieldValue('Item_Indicator', option ? option?.value : null)
                                    setSelectedItem_Indicator({ value: option?.value, label: option?.label })
                                    if (option && option?.label === 'Buy') {
                                      setType("Buyer")
                                      handlePurchaseShow()
                                    }
                                    if (option && option?.label === 'Sell') {
                                      setType("seller")
                                      handleSellingShow()
                                    } if (option && option?.label === 'Both') {
                                      setType("both")
                                      handlebothShow()
                                    }
                                  }}
                                />
                                <label htmlFor="Item_Indicator" className='staan-form-input-label pt-1 px-1 mx-2'>Buy/Sell</label>
                                {touched.Item_Indicator && errors.Item_Indicator && <small className="staan-error-msg">{errors.Item_Indicator}</small>}
                              </div>
                              <div className='col-7 d-flex justify-content-between align-items-center'>
                                <div className="col-6 text-center" style={!isProduct ? { display: "none" } : {}}>
                                  <Field type="checkbox" name="Keep_stock" checked={values.Keep_stock} onClick={(e) => {
                                    handleChangeKeepStock(values.Keep_stock)
                                  }} className='me-2' />
                                  <label style={{ fontSize: '.8rem' }} className='pb-1'> Keep Stock </label>
                                  <br />
                                  {errors.Keep_stock && <small className="staan-error-msg">{errors.Keep_stock}</small>}
                                </div>
                                <div className="col-6 text-end">
                                  <Field type="checkbox" name="Item_Active" className='me-2' />
                                  <label style={{ fontSize: '.8rem', top: '5px' }} className='pb-1'>Item Active</label>
                                  {errors.Item_Active && <small className="staan-error-msg">{errors.Item_Active}</small>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card px-1 mt-4" style={{ height: "36%" }} >
                          <div className="row m-3" >
                            <div className='d-flex justify-content-between'>
                              <div className="col-4 test-center p-2"  >
                                Document (.csv,.xlsx,.pdf,.doc)
                              </div>
                              <div className="col-4 text-end">
                                <AiTwotoneCloseCircle className='fs-5'
                                  onClick={(e) => {
                                    handleDeleteAllDocument()
                                  }} />
                              </div>
                            </div>
                            <div className='d-flex justify-content-between' >
                              <div style={{ width: '50%' }}>
                                <ul>
                                  {
                                    selectedDoc.map((file, index) => {
                                      return <>
                                        <li key={index} className='d-flex justify-content-between mb-1 text-start'>
                                          <span><i className="fa-regular fa-circle"></i></span>
                                          <a href={file.documentFile} download={file.documentFile} style={{ cursor: 'pointer' }}>{decodeURI(file.name)}</a>
                                          <span
                                            className='ms-2 me-4 text-danger'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => { handleDeleteDocument(file) }}
                                          >
                                            <i className="fa-regular fa-trash-can"></i>
                                          </span>
                                        </li>
                                      </>
                                    })
                                  }
                                </ul>
                              </div>
                              <div className="border rounded document"
                                onClick={handleDocumentClick}
                                style={{
                                  width: '40%',
                                  height: `${screenSize?.height / 110}rem`,
                                  overflow: "auto", position: "relative"
                                }}
                              >
                                <Field onChange={(e) => { handleDocSelect(e) }} innerRef={DocInputRef}
                                  multiple accept=".csv,.xlsx,.pdf,.doc,.docx"
                                  className="form-control form-control-sm" hidden id="formFileSm" type="file" name="fieldName" />
                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                  <div className='text-center'>
                                    <AiOutlineCloudUpload style={{ width: "5rem", height: "3rem" }} />
                                    <br />
                                    <p className='text-center'>Click Here To Select File</p>
                                    <p>  Size : 5MB</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '45%', position: "relative" }}>
                        <div className="card px-1 pb-2 my-3">
                          <div className="row" style={!isProduct ? { display: "none" } : { Height: '13rem' }}>
                            <div className="col-6 p-1 ms-4">
                              Image(.jpg,.png,.jpeg)
                            </div>
                            <div className="col-5 text-end">
                              <AiTwotoneCloseCircle className='fs-5'
                                onClick={(e) => {
                                  handleDeleteImage()
                                }} />
                            </div>
                            <div className="col-6">
                              <div className="border rounded p-0 mx-4" onClick={handleImageClick} style={{ minHeight: `10rem`, width: "10rem", position: "relative" }}>
                                <Field innerRef={ImageInputRef} onChange={(e) => {
                                  handleFileInputChange(e)
                                }}
                                  multiple
                                  accept=".jpg,.png,.jpeg"
                                  hidden
                                  className="form-control form-control-sm" id="formFileSm" type="file" name="fieldName" />
                                {selectedImage?.name ?
                                  selectedImage && selectedImage.type.startsWith('image/') && (
                                    <div className="" style={{ width: "10rem", height: "10rem" }}>
                                      <img src={URL.createObjectURL(selectedImage)} alt="Selected Image"
                                        style={{ maxWidth: "90%", maxHeight: "90%", objectFit: "contain", padding: "1px" }} />
                                    </div>
                                  )
                                  :
                                  <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                    <div className='text-center'>
                                      <AiOutlineCloudUpload style={{ width: "5rem", height: "5rem" }} />
                                      <br />
                                      <p className='text-center'>Click Here To Select Image</p>
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                            <div className="col-6 py-5">
                              Area : 160 X 160
                              <br />
                              Size : 2MB
                            </div>
                          </div>
                        </div>
                        <div className='card px-1 pb-2' style={!isProduct ? { display: "none", minHeight: '11rem' } : { minHeight: '11rem' }}>
                          <div className="pt-1 mx-1 border-bottom custom-bottom-shadow">
                            <h6 className='mx-3'>Stock Details</h6>
                          </div>
                          <div>
                            <div className='row mx-2 my-1'>
                              <div className="col-6 staan-input-group">
                                <Field type='text' id='total_stock' name='Total_Stock' disabled className='w-100 staan-form-input' />
                                <label htmlFor="total_stock" className='staan-form-input-label pt-2 px-1 mx-2'>Total Stock</label>
                                {touched.Total_Stock && errors.Total_Stock && <small className="staan-error-msg">{errors.Total_Stock}</small>}
                              </div>
                              <div className="col-6 staan-input-group">
                                <Field type='text' id='reject_stock' name='Rejected_Stock' disabled className='w-100 staan-form-input' />
                                <label htmlFor="reject_stock" className='staan-form-input-label pt-2 px-1 mx-2'>Reject Stock</label>
                                {touched.Rejected_Stock && errors.Rejected_Stock && <small className="staan-error-msg">{errors.Rejected_Stock}</small>}
                              </div>
                            </div>

                            <div className='row mx-2 my-1'>
                              <div className="col-4 staan-input-group">
                                <Field type='text' id='safety_stock' name='Item_Safe_Stock' value={safeStock} onChange={(e) => {
                                  setSafeStock(e.target.value);
                                  setFieldValue("Item_Safe_Stock", e.target.value)
                                }} disabled={selectedItem_Indicator['label'] !== "Buy" && selectedItem_Indicator['label'] !== "Both"} placeholder='' className='w-100 staan-form-input' />
                                <label htmlFor="safety_stock" className='staan-form-input-label pt-2 px-1 mx-2'>Safety Stock</label>
                                {touched.Item_Safe_Stock && errors.Item_Safe_Stock && <small className="staan-error-msg">{errors.Item_Safe_Stock}</small>}
                              </div>
                              <div className="col-4 staan-input-group">
                                <Field type='text' id='reject_stock' name='Item_Order_Qty' disabled={selectedItem_Indicator['label'] !== "Buy" && selectedItem_Indicator['label'] !== "Both"} value={orderQty} onChange={(e) => {
                                  setOrderQty(e.target.value);
                                  setFieldValue("Item_Order_Qty", e.target.value)
                                }} placeholder='' className='w-100 staan-form-input' />
                                <label htmlFor="reject_stock" className='staan-form-input-label pt-2 px-1 mx-2'>Min Order Qty</label>
                                {touched.Item_Order_Qty && errors.Item_Order_Qty && <small className="staan-error-msg">{errors.Item_Order_Qty}</small>}
                              </div>
                              <div className="col-4 staan-input-group">

                                <Field type='text' id='lead_time' name='Item_Leadtime' disabled={selectedItem_Indicator['label'] !== "Buy" && selectedItem_Indicator['label'] !== "Both"} placeholder='' value={leadTime} onChange={(e) => {
                                  setLeadTime(e.target.value);
                                  setFieldValue("Item_Leadtime", e.target.value)
                                }} className='w-100 staan-form-input' />
                                <label htmlFor="lead_time" className='staan-form-input-label pt-2 px-1 mx-2'>Lead Time in Days</label>
                                {touched.Item_Leadtime && errors.Item_Leadtime && <small className="staan-error-msg">{errors.Item_Leadtime}</small>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card px-1 my-3' style={!isProduct ? { display: "none", minHeight: '11rem' } : { minHeight: '11rem' }}>
                          <div className="pt-1 mx-1 border-bottom custom-bottom-shadow">
                            <h6 className='mx-3'>Item Tracking Details</h6>
                          </div>
                          <div>
                            <div className='row mx-2'>
                              <div className="col-4 pt-2">
                                <Field type="checkbox" name="serial" disabled={!!values.Batch_number}
                                  className='me-2' />
                                <label style={{ fontSize: '.8rem' }} className='pb-2'>
                                  Serial Number
                                </label>
                                <br />
                                {errors.serial && <small className="staan-error-msg">{errors.serial}</small>}
                              </div>
                              <div className="col-4 pt-2 text-center">
                                <Field type="checkbox" name="Batch_number" disabled={!!values.serial} className='me-2' />
                                <label style={{ fontSize: '.8rem' }} className='pb-2'>
                                  Batch Number
                                </label>
                                <br />
                                {errors.Batch_number && <small className="staan-error-msg">{errors.Batch_number}</small>}
                              </div>
                              <div className="col-4 pt-2 text-end">
                                <Field type="checkbox" name="Item_Barcode" className='me-2' />
                                <label style={{ fontSize: '.8rem' }} className='pb-2'>
                                  Barcode
                                </label>
                              </div>
                            </div>
                            <div className="row mx-2 my-1" hidden={!values.serial}>
                              <div className='col-12'>
                                <Field type="checkbox" name="serial_auto_gentrate" className='me-2' />
                                <label style={{ fontSize: '.8rem' }} className='pb-2'>
                                  Auto Generate
                                </label>
                              </div>
                              <div className="col-6 staan-input-group">
                                <Field type='text' id="Serial_format" name='Serial_format' placeholder='OT-#2'

                                  disabled={!values.serial_auto_gentrate} className='w-100 staan-form-input' />
                                <label htmlFor="Serial_format" className='staan-form-input-label pt-2 px-1 mx-2'>Serial Format</label>
                                {errors.Serial_format && <small className="staan-error-msg">{errors.Serial_format}</small>}
                              </div>
                              <div className="col-6 staan-input-group">
                                <Field type='text' id='Serial_starting' name='Serial_starting'
                                  value={serialStarting}
                                  onChange={(e) => { setSeriatStarting(e.target.value); setFieldValue("Serial_starting", e.target.value) }}
                                  placeholder='' disabled={!values.serial_auto_gentrate} className='w-100 staan-form-input' />
                                <label htmlFor="Serial_starting" className='staan-form-input-label pt-2 px-1 mx-2'>Serial Starting</label>
                                {errors.Serial_starting && <small className="staan-error-msg">{errors.Serial_starting}</small>}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 staan-input-group">
                                <Field type='text' id="location" name='location' className='w-100 staan-form-input'
                                  value={locationData}
                                  onChange={(e) => { setFieldValue("location", e.target.value); setlocationData(e.target.value); }}
                                />
                                <label htmlFor="location" className='staan-form-input-label pt-2 px-1 mx-2' onClick={() => {
                                  console.log(type === 'Sell', type === "Both");
                                  console.log(type);
                                }}>location</label>
                                {errors.location && <small className="staan-error-msg">{errors.location}</small>}
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="card px-1 mt-3" style={!isProduct ? { display: "none", minHeight: '11rem' } : { minHeight: '11rem' }}>
                          <div className="row mx-2">
                            <div className="col-4 pt-3">
                              <Field type="checkbox" name="Service" checked={serviceAbleBool} onClick={(e) => {

                                if (e.target.checked) {
                                  handleserviceableShow();
                                  setFieldValue("Service", e.target.checked)
                                } else {
                                  setServiceAbleBool(false)
                                  setFieldValue("Service", e.target.checked)
                                }
                              }} className='me-2' />
                              <label style={{ fontSize: '.8rem' }} className='pb-2' onClick={() => { handleserviceableShow() }}>
                                Item Serviceable
                              </label>
                              {errors.Service && <small className="staan-error-msg">{errors.Service}</small>}
                            </div>
                            <div className="col-4 pt-3 text-center">
                              <Field type="checkbox" name="Item_Combo" checked={comboBool} disabled={(type === 'seller' || type === "both") ? false : true} onClick={(e) => {
                                if (e.target.checked) {
                                  handleItemComboShow(); setComboBool(true);
                                  setFieldValue("Item_Combo", true)
                                } else { setComboBool(false); setFieldValue("Item_Combo", false) };
                              }} className='me-2' />

                              <label style={{ fontSize: '.8rem' }} className='pb-2' onClick={() => { handleItemComboShow() }}>
                                Item Combo
                              </label>
                              {errors.Item_Combo && <small className="staan-error-msg">{errors.Item_Combo}</small>}
                            </div>

                            <div className="col-4 pt-3 text-end">
                              <Field type="checkbox" name="item_qc" className='me-2' checked={itemQc} onClick={(e) => {
                                setitemQc(e.target.checked)
                                setFieldValue("item_qc", true)

                              }} />
                              <label style={{ fontSize: '.8rem' }} className='pb-2'>
                                Item QC
                              </label>
                              {errors.item_qc && <small className="staan-error-msg">{errors.item_qc}</small>}
                            </div>
                            <div className="col-4">
                              <Field type="checkbox" name="is_manufacture" className='me-2' checked={isManufacture} onClick={(e) => {
                                setIsManufacture(e.target.checked)
                                setFieldValue("is_manufacture", true)
                              }} />
                              <label style={{ fontSize: '.8rem' }} className='pb-2'>
                                Manufacture
                              </label>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="row">
                      <div className="card px-1 pb-2 my-3">
                        <div className="col-12 staan-input-group">
                          <Field type='text' as='textarea' style={{ height: "5rem" }} rows="3" name='note'
                            className='w-100 staan-form-input' />
                          <label htmlFor="note" className='staan-form-input-label pt-2 px-1 mx-2'>Note</label>
                          {touched.note && errors.note && <small className='text-danger' >{errors.note}</small>}
                        </div>
                        <div className='text-end px-3' >
                          <button type="submit"
                            disabled={isSubmitting}
                            className="btn btn-sm btn-outline-success fw-bold px-4  mt-2 shadow-sm">Save</button>
                        </div>
                      </div>
                    </div>
                    {/* new code ends */}
                    {/* selectedAccount,  */}
                  </Form>
                  <AddItemGroup />
                  <AddUom />
                  <PurchaseModal
                    touched={touched}
                    errors={errors}
                    handlePurchaseClose={handlePurchaseClose}
                    Purchase={Purchase}
                    dupData={dupRefData}
                    setFieldValue={setFieldValue}
                    values={values}
                    setSelectedItem_Indicator={setSelectedItem_Indicator}
                    purchaseUomOptions={purchaseUomOptions}
                    selectedPurchaseuom={selectedPurchaseuom}
                    setSelectedPurchaseuom={setSelectedPurchaseuom}
                    setType={setType}
                  />
                  <SellingPrice
                    touched={touched}
                    dupData={dupRefData}
                    errors={errors}
                    handleSellingClose={handleSellingClose}
                    Selling={Selling}
                    setFieldValue={setFieldValue}
                    setAccountGroupType={setAccountGroupType}
                    values={values}
                    tax={tax}
                    setSelectedItem_Indicator={setSelectedItem_Indicator}
                    setType={setType}
                  />
                  <PurchaseSellingModal
                    touched={touched}
                    errors={errors}
                    ItemUOMdata={ItemUOMdata}
                    Accountdata={Accountdata}
                    handlebothClose={handlebothClose}
                    Both={Both}
                    setFieldValue={setFieldValue}
                    setAccountGroupType={setAccountGroupType}
                    dupData={dupRefData}
                    values={values}
                    tax={tax}
                    setSelectedItem_Indicator={setSelectedItem_Indicator}
                    setType={setType}
                    purchaseUomOptions={purchaseUomOptions}
                  />
                  <AddAccount
                    AccountGroupType={AccountGroupType}
                    setAccountGroupType={setAccountGroupType}
                    setSelectedAccount={setSelectedAccount}
                  />
                  <AddAccountGroup
                    AccountGroupType={AccountGroupType}
                  />
                  <AddHsn />
                  <Serviceable
                    serviceable={serviceable}
                    handleserviceableClose={handleserviceableClose}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    values={values}
                    dupData={dupRefData}
                  />
                  <AlternateUnit
                    Fullvalue={values}
                    values={selectUOM}
                    isAlternateUomFixed={isAlternateUomFixed}
                    handleChangeAlternateUomFixed={(e) => { handleChangeAlternateUomFixed(e) }}
                    handleAlternateUomChanged={(d) => { handleAlternateUomChanged(d) }}
                    setPurchaseUomOptions={setPurchaseUomOptions}

                  />
                  <ItemCombo
                    values={values}
                    initialMasterData={{ 'Item_Combo_data': [] }}
                    handleChangeItemComboPrint={(e) => { handleChangeItemComboPrint(e) }}
                    isItemComboPrint={isItemComboPrint}
                  />
                </>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default ItemaMasterModal