export const employeeCreateMutation = (data)=>{
    // aadhaarNo: ""   altMobile: "" bankAccountNo: "" bankName: "" branch: ""
    // createdBy: 10 department: 10 designation: "" document: 10 educationQualification: ""
    // email: "" employeeName: "" id: "" ifscCode: "" modifiedBy: 10 panNo: "" permanentAddress: 10 presentAddress: 10
    // remark: "" userId: 10 userProfile: 10mobile: ""
    return  `mutation MyMutation {
  employeeCreateMutation(${data}) {
    errors
    success
  }
}`
    }

export const employeeDeleteMutation=(data)=>{
  // id: ""
  return ` mutation MyMutation {
      employeeDeleteMutation(id:${data}) {
        errors
        success
      }
    }`
}

export const employeeCategoriesCreateMutation=(data)=>{
  return  `
  mutation MyMutation { 
  expenseCategoriesCreateMutation(${data}) { 
    errors 
    success 
  } 
} 
  `
}


   