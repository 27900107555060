import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import { Formik, Field, Form } from 'formik';
import { customSelectStyle, removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import DataContext from '../../../context/ItemMasterContext';
import { roleSchema } from '../../../validations/UserValidations';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import { roleDeleteMutations, roleMutations } from '../../../GraphQLQueries/userQueryandMutations/UsersMutations';
import axiosInstance from '../../../api/axoiss';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import Can from '../../../components/authorizationComponents/Can'
import CustomSelect from '../../../components/ReactSelect/reactSelect';

const RoleForm = ({ isShow, setIsShow, setLoad, editData, setEditData }) => {
    const { CommanFetchForAllUser, allUsersList, userId, handltoastDeleteConfomationShow } = useContext(DataContext)
    const [initialValue, setInitialValue] = useState({
        id: "",
        roleName: "",
        reportTo: "",
        descriptions: "",
        shareDataWith: "",
        modifiedBy: "",
        createdBy: "",
    })
    const [deleteData, setdeleteData] = useState({ Name: "", id: "" })
    const [edit, setEdit] = useState(true)
    const [reportTo, setReportTo] = useState({ value: "", label: "" })
    const [shareDataWith, setShareDataWith] = useState([])

    // This useEffect hook initializes form values based on `editData` when `isShow` is true. return null
    useEffect(() => {
        if (editData !== null && isShow) {

            const selectedValues = editData?.['shareDataWith'] ? editData?.['shareDataWith'].map(option => Number(option.id)) : [];
            initialValue['id'] = Number(editData?.['id'])
            initialValue['roleName'] = editData?.['roleName']
            initialValue['descriptions'] = editData?.['descriptions']
            if (editData?.['reportTo']?.id) {
                initialValue['reportTo'] = Number(editData['reportTo'].id);
            } else {
                initialValue['reportTo'] = "";
            }
            initialValue['shareDataWith'] = selectedValues
            initialValue['createdBy'] = Number(editData?.createdBy?.['id'])
            if (selectedValues?.length > 0) {
                setShareDataWith(editData?.['shareDataWith']?.map((item) => ({ value: item?.id, label: item?.username })))
            }
            setEdit(true)
            setReportTo({ value: editData?.['reportTo']?.id, label: editData?.['reportTo']?.username })
        } else {
            setEdit(false)

        }
    }, [editData, isShow])

    // The `handleSubmit` function processes the form submission, validates input, This function does not return a value.
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {

        if (!initialValue['id']) {
            values['createdBy'] = Number(userId)
        }

        let ClearValue = removeEmptyValueInObject(values, ["id", "modifiedBy", "reportTo", "shareDataWith"])
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: roleMutations(ClearValue)
            });
            const responseData = response?.data?.data?.roleCreateMutation;
            if (responseData.success) {
                setIsShow(false)
                showErrorToast(true, "success", "", "Saved Successfully")
                resetForm()
                Close()
                setSubmitting(false)
                try {
                    setLoad()
                } catch (error) {
                    console.log(error);


                }

                setEditData(null)



            } else {
                let errorData = ShowErrorNoties(responseData.errors);
                showErrorToast(true, "error", errorData,)
            }
        } catch (error) {
            let errorData = ShowErrorNoties(error);
            showErrorToast(true, "error", errorData,)
        }

    }
    // The `Close` function resets the form and state variables to their initial values 
    function Close(params) {
        setIsShow(false)
        setShareDataWith([])
        setReportTo({ value: "", label: "" })
        setEditData(null)

        setInitialValue({
            id: "",
            roleName: "",
            descriptions: "",
            reportTo: "",
            shareDataWith: "",
            createdBy: ""
        })
    }
    // The `handledeleteConfomation` function prepares data for deletion confirmation 
    const handledeleteConfomation = (data) => {
        if (data) {
            handltoastDeleteConfomationShow();
            setdeleteData({
                Name: data?.roleName,
                id: data?.id,
            });
        }


    };
    // The `handleDeletedId` function processes the deletion of a role 
    const handleDeletedId = (deleted_id) => {
        if (deleted_id) {
            Close()
            setEdit(false)
            setLoad()
        }
    }

    return (
        <>
            <Modal show={isShow} backdrop='static' keyboard={false} size='lg'>
                <div className='row'>
                    <div className='col-5 m-3' style={{ color: '#5CADA9' }}>
                        {' '}
                        <Modal.Title>
                            <i className='fa-solid fa-arrow-left fa-sm me-2' onClick={() => { Close() }}></i>
                            Role
                        </Modal.Title>
                    </div>
                    <div className='col-6 mt-4 text-end'>
                        <Can I="Edit" a="Roles">
                            <i className="fa-solid fa-pen fs-5 text-primary pe-3"
                                hidden={!(editData && edit)}
                                onClick={() => { setEdit(!edit) }}  ></i>
                        </Can>
                        <Can I={"Delete"} a={"Roles"}>
                            <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={!(editData && edit)} onClick={() => { handledeleteConfomation(editData) }} ></i>
                        </Can>
                    </div>
                </div>
                <hr className='split_border'/>
                <Modal.Body>
                    <Formik initialValues={initialValue} validationSchema={roleSchema} onSubmit={handleSubmit} >
                        {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                            return (
                                <Form>
                                    <div className="row">
                                        <div className="col-6 staan-input-group">
                                            <Field type="text" name="roleName" id="roleName" placeholder="Name" disabled={editData && edit} className='w-100 staan-form-input' />
                                            <label htmlFor="roleName" className='staan-form-input-label   px-1 mx-2' onClick={() => {
                                                console.log(values, editData);
                                            }}>Role Name<span className='text-danger'>*</span></label>
                                            {touched.roleName && errors.roleName && <small className='text-danger' >{errors.roleName}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type="text" name="descriptions" id="descriptions" placeholder="Name" disabled={editData && edit} className='w-100 staan-form-input' />
                                            <label htmlFor="descriptions" className='staan-form-input-label pt-1 px-1 mx-2'   >Description</label>
                                            {touched.descriptions && errors.descriptions && <small className='text-danger' >{errors.descriptions}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <CustomSelect
                                                setSelectEditValue={reportTo}
                                                handleInputChange={(e) => CommanFetchForAllUser(e)}
                                                options={allUsersList}
                                                isClearable
                                                name='reportTo'
                                                handleOnChange={(options) => {
                                                    setFieldValue("reportTo", options ? Number(options?.value) : "")
                                                    setReportTo(options ? options : null)
                                                }}
                                                isDisabled={editData && edit}
                                            />
                                            <label className='staan-form-input-label pt-1 px-1 mx-2'>Report To</label>
                                            {touched.reportTo && errors.reportTo && <small>{errors.reportTo}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                name='shareDataWith'
                                                isMulti
                                                id='shareDataWith'  // This sets the outer div's id
                                                inputId='shareDataWith'  // This sets the input element's id inside the Select component
                                                value={shareDataWith}
                                                onInputChange={(e) => { CommanFetchForAllUser(e) }}
                                                options={allUsersList}
                                                isDisabled={editData && edit}
                                                className='my-3'
                                                styles={customSelectStyle}
                                                onChange={(options) => {
                                                    if (options) {
                                                        const selectedValues = options ? options.map(option => Number(option.value)) : [];
                                                        setFieldValue("shareDataWith", selectedValues)
                                                        setShareDataWith(options)
                                                    }
                                                }}
                                            />
                                            <label htmlFor="shareDataWith" className='staan-form-input-label pt-1 px-1 mx-2'>Share Data With</label>
                                            {touched.shareDataWith && errors.shareDataWith && <small>{errors.shareDataWith}</small>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='text-end'>
                                            <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={() => { Close() }}  >Cancel</button>
                                            <Can allow_list={["Save_Roles", "Edit_Roles"]}>
                                                <button type="submit" className="btn btn-sm btn-outline-success shadow-sm" disabled={editData && edit ? true : isSubmitting} >Save</button>
                                            </Can>

                                        </div>
                                    </div>
                                </Form>
                            )
                        }}

                    </Formik>

                </Modal.Body>
            </Modal>
            {
                deleteData && <DeleteConformation
                    handleDeletedId={(id) => { handleDeletedId(id) }}
                    deleteData={deleteData}
                    fullquery={roleDeleteMutations(deleteData?.id)}
                />
            }
        </>
    )
}

export default RoleForm
