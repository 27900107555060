import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { TiCancel } from 'react-icons/ti';
import { Formik, Field, Form } from 'formik';
import { advanceValidation, paymentVoucherValidation } from '../../../validations/ExpenceValidation';
import {removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import { employeeOptionQuery } from '../../../GraphQLQueries/EmployeeQueryAndMutations/EmployeeQuery';
import axiosInstance, { commanFetch } from '../../../api/axoiss';
import { accountsMasterForPosOptionQuery, SupplierOptionPerameter } from '../../../GraphQLQueries/ItemmasterQuery';
import DataContext from '../../../context/ItemMasterContext';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import { advanceTableCreateMutation, advanceTableDeleteMutation, voucherCancelMutation, voucherCreateMutation, voucherDeleteMutation } from '../../../GraphQLQueries/ExpenseQueryandMutation/expenseMutation';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import { voucherEditQuery } from '../../../GraphQLQueries/ExpenseQueryandMutation/expenseQuery';
import WaringModel from '../../../components/Warings/WaringModel';
import CustomSelect from '../../../components/ReactSelect/reactSelect';


const PaymentVoucherModal = ({ isVouchershow, setVouchershow, editData, setEditData, setLoad, requestFormData, setrequestFormData, handleSubmitRequestForm }) => {
    const [initialVoucherData, setinitialVoucherData] = useState({
        voucherNo: '',
        voucherDate: '',
        paymentTo: '',
        paymentFor: '',
        paymentMode: '',
        employeeDetailID: '',
        employeeDetailName: '',
        supplierNumber: '',
        supplierName: '',
        expenseRequestNo: '',
        empAmount: '',
        cusAmount: '',
        refNo: '',
        chqDate: '',
        transfer: '',
        bank: '',
        status: '',
        createdBy: '',
        modifiedBy: '',
        id: ''


    })
    const { userId, prefetchValue, handltoastDeleteConfomationShow } = useContext(DataContext)
    const [voucherOptionList, setvoucherOptionList] = useState({
        paymentTotList: [],
        paymentMode: [],
        payforList: [],
        supplierNoList: [],
        supplierNameList: [],
        bankList: [],
        transferList: []
    })
    const [employeeOptionList, setEmployeeOptionList] = useState({
        employeeIDList: [],
        employeeNameList: []
    })
    const [voucherSelectValues, setvoucherSelectValues] = useState({
        paymentToSelect: '',
        paymentModeSelect: '',
        paymentForSelect: '',
        employeeIdSelect: '',
        employeeNameSelect: '',
        supplierNoSelected: '',
        supplierNameSelected: '',
        AgainstInvoicechecked: false,
        Advancechecked: false,
        bankSelect: '',
        transferSelect: ''
    })
    const [advanceData, setAdvanceData] = useState([]);
    const [advanceValue, setadvanceValue] = useState({ editIndex: '', advRemarks: "", Amount: "", id: "", createdBy: '' })
    const [advinitialValue, setadvinitialValue] = useState({
        advRemarks: '',
        advAmount: ''
    })
    const [AdvanveformKey, setAdvanveformKey] = useState(0)
    const [formKey, setFormKey] = useState(0)
    const [onNewItemSelect, setonNewItemSelect] = useState(false)
    const [deleteData, setdeleteData] = useState({ Name: '', id: '' })
    const [isLoading, setLoading] = useState(false)
    const [editView, seteditView] = useState(false)
    const [isDeleteWarningShow, setIsDeleteWarningShow] = useState(false)
    const [IsCancelShow, setIsCancelShow] = useState(false)
    const [ispaytoChange, setpaytoChange] = useState({ isshow: false, ChangedData: "" })
    const [iseditViewMandatoryField, seteditViewMandatoryField] = useState(false)

    async function getOptions(e, name) {
        if (name === 'employeeID') {
            let peremeter = `employeeId:"${e}",employeeName:""`
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: employeeOptionQuery(peremeter) });
            const responseData = response?.data?.data?.allEmployee?.items
            console.log(response?.data);
            if (responseData?.length > 0) {
                setEmployeeOptionList(prevState => ({
                    ...prevState,
                    employeeIDList: responseData.map(item => ({
                        value: item?.id,
                        label: item?.employeeId,
                        employeeName: item?.employeeName,
                        image: item?.userProfile?.image
                    }))
                }));
            }
        }
        if (name === 'employeeName') {
            let peremeter = `employeeId:"",employeeName:"${e}"`
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: employeeOptionQuery(peremeter) });
            const responseData = response?.data?.data?.allEmployee?.items
            console.log(response?.data);
            if (responseData?.length > 0) {
                setEmployeeOptionList(prevState => ({
                    ...prevState,
                    employeeNameList: responseData.map(item => ({
                        value: item?.id,
                        label: item?.employeeName,
                        employeeId: item?.employeeId,
                        image: item?.userProfile?.image
                    }))
                }));
            }
        }
        if (name === 'supplierNumber') {
            let peremeter = `supplierNo: "${e}", customer:true `
            const response = await commanFetch(SupplierOptionPerameter(peremeter))
            const responseData = response?.data?.items
            if (responseData?.length > 0) {
                setvoucherOptionList(prevState => ({
                    ...prevState,
                    supplierNoList: responseData.map(item => ({
                        value: item?.id,
                        label: item?.supplierNo,
                        companyName: item?.companyName,
                    })),
                }));
            }
        }
        if (name === 'supplierName') {
            let peremeter = `companyName: "${e}", customer:true `
            const response = await commanFetch(SupplierOptionPerameter(peremeter))
            const responseData = response?.data?.items
            if (responseData?.length > 0) {
                setvoucherOptionList(prevState => ({
                    ...prevState,
                    supplierNameList: responseData.map(item => ({
                        value: item?.id,
                        label: item?.companyName,
                        supplierNo: item?.supplierNo,
                    })),
                }));
            }


        }
        if (name === 'PayFor') {
            let peremeter = `accountsName:"Employee"`
            const response = await commanFetch(accountsMasterForPosOptionQuery(peremeter))
            const responseData = response?.data?.items
            console.log(responseData);
            if (responseData?.length > 0) {
                setvoucherOptionList(prevState => ({
                    ...prevState,
                    payforList: responseData?.map(item => ({
                        value: item?.id,
                        label: item?.accountsName
                    }))
                }));
            }
        }
        if (name === 'ChooseBank') {
            let peremeter = `accountsTypeOnly:"Bank"`
            const response = await commanFetch(accountsMasterForPosOptionQuery(peremeter))
            const responseData = response?.data?.items
            console.log(responseData);
            if (responseData?.length > 0) {
                setvoucherOptionList(prevState => ({
                    ...prevState,
                    bankList: responseData?.map(item => ({
                        value: item?.id,
                        label: item?.accountsName
                    }))
                }));
            }
        }

    }
    useEffect(() => {
        if (requestFormData) {
            console.log("requestFormData----", requestFormData);
            setinitialVoucherData(prve => ({
                ...prve,
                expenseRequestId: requestFormData?.expenseRequestId,
                empAmount: requestFormData?.requestAmount,
                employeeDetailID: Number(requestFormData?.employeeData?.value),
                employeeDetailName: Number(requestFormData?.employeeData?.value),
                paymentTo: "Employee"
            }))
            setvoucherSelectValues(prev => ({
                ...prev,
                paymentToSelect: { value: '1', label: 'Employee' },
                employeeNameSelect: requestFormData?.employeeData,
                employeeIdSelect: { value: requestFormData?.employeeData?.id, label: requestFormData?.employeeData?.employeeId }
            }))
            seteditViewMandatoryField(true)
        }
    }, [requestFormData])
    useEffect(() => {
        async function fetchEditData(id) {
            setLoading(true)
            try {
                const response = await axiosInstance.post(`/itemmaster/graphql`, { query: voucherEditQuery(`id:${id}`) });
                const reponseData = response?.data?.data?.allPaymentVoucher
                updateTheStates(reponseData?.items[0])
                console.log("Edit reponseData", reponseData);
    
            } catch (error) {
    
            }
            setLoading(false)
        }
        if (isVouchershow && editData !== null && editData?.id) {
            fetchEditData(editData?.id)
            seteditViewMandatoryField(true)
            seteditView(true)
        }
        if (prefetchValue) {
            setvoucherOptionList(prevState => ({
                ...prevState,
                paymentTotList: prefetchValue?.allPaymentvoucherPayTo?.length > 0 ? prefetchValue?.allPaymentvoucherPayTo : prevState?.paymentTotList,
                paymentMode: prefetchValue?.allPaymentvoucherPaymode?.length > 0 ? prefetchValue?.allPaymentvoucherPaymode : prevState?.paymentMode,
                transferList: prefetchValue?.allPaymentvoucherTransfervia?.length > 0 ? prefetchValue?.allPaymentvoucherTransfervia : prevState?.transferList,
            }));
        }
    }, [isVouchershow, editData,prefetchValue]);
    async function DeleteTheVocher() {
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: voucherDeleteMutation(`id:${initialVoucherData?.id}`) })
            let reponseData = response?.data?.data?.paymentVoucherDeleteMutation
            if (reponseData?.success) {
                resetForm()
            } else {
                showErrorToast(true, "error", reponseData?.errors)
            }
        } catch (error) {
            let errorFilter = ShowErrorNoties(error)
            showErrorToast(true, "error", errorFilter)
        }

    }
    async function CancelTheVocher() {
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: voucherCancelMutation(`id:${initialVoucherData?.id},status:"Canceled"`) })
            let responseData = response?.data?.data?.paymentVoucherCreateMutation
            if (responseData?.success) {
                console.log("reponseData", responseData);
                const resData = responseData?.PaymentVoucherInstance
                updateTheStates(resData)
                seteditViewMandatoryField(true)
            } else {
                showErrorToast(true, "error", responseData?.errors)
            }
        } catch (error) {
            let errorFilter = ShowErrorNoties(error)
            showErrorToast(true, "error", errorFilter)
        }

    }
    async function handlePaytoChange(data, value) {
        if (value === "yes") {
            setvoucherSelectValues(prve => ({
                ...prve,
                paymentToSelect: data?.selectvalue,
                paymentForSelect: '',
                employeeIdSelect: '',
                employeeNameSelect: '',
                supplierNoSelected: '',
                supplierNameSelected: '',
                AgainstInvoicechecked: false,
                Advancechecked: false,
                bankSelect: '',
                transferSelect: '',
                paytoWarningShow: false
            }))
            setinitialVoucherData(prve => ({
                ...prve,
                voucherNo: data?.fieldValue?.voucherNo,
                voucherDate: data?.fieldValue?.voucherDate,
                paymentMode: data?.fieldValue?.paymentMode,
                paymentTo: data?.selectvalue?.label,
                employeeDetailID: '',
                employeeDetailName: '',
                supplierNumber: '',
                supplierName: '',
                empAmount: '',
                cusAmount: '',
                refNo: '',
                chqDate: '',
                transfer: '',
                bank: '',
                paymentFor: ''
            }))

            setFormKey(prve => prve + 1)
        }

    }
    const handleNewRow = () => {
        setonNewItemSelect(true)
        let indexValue = advanceData ? advanceData.length === 0 ? 1 : advanceData.length + 1 : 1
        const newRow = {
            id: '',
            index: indexValue,
            advRemarks: '',
            Amount: '',
            isEditing: true,
            createdBy: ''

        }
        setadvanceValue(prve => ({ ...prve, editIndex: indexValue }))
        setadvinitialValue({
            advRemarks: '',
            advAmount: ''
        })
        setAdvanceData([...advanceData, newRow])
    }
    const handleAdvanceDeletedId = deleted_id => {
        if (deleted_id) {
            const updatedData = advanceData
                .filter(item => item.id !== deleted_id)
                .map((item, rowIndex) => ({
                    ...item,
                    index: rowIndex + 1
                }));

            console.log("updatedData", updatedData);

            setAdvanceData(updatedData);
            setonNewItemSelect(false)
            setdeleteData({ Name: '', id: '' })
        }
    }
    const AdvanceTable = () => {
        const handleAdvSave = async (rowIndex, values) => {
            setLoading(true)
            let reqBody = {
                advRemark: values?.advRemarks ? values?.advRemarks : '',
                amount: values?.advAmount ? values?.advAmount : '',
                createdBy: values?.createdBy ? values?.createdBy : Number(userId),
                id: values?.id ? Number(values?.id) : '',
                modifiedBy: values?.id ? values?.modifiedBy : Number(userId)
            }
            let ClearValue = removeEmptyValueInObject(reqBody, ['id', 'modifiedBy'])
            try {

                const response = await axiosInstance.post(`/itemmaster/graphql`, {
                    query: advanceTableCreateMutation(ClearValue)
                })
                const responseData = response?.data?.data?.paymentVoucherAdvanceDetailsCreateMutation
                if (responseData?.success) {
                    setAdvanceData((prevData) => {
                        const updatedData = [...prevData];
                        updatedData[rowIndex] = {
                            ...updatedData[rowIndex],
                            advRemarks: responseData?.PaymentVoucherAdvanceDetailsInstance?.advRemark,
                            Amount: responseData?.PaymentVoucherAdvanceDetailsInstance?.amount,
                            id: responseData?.PaymentVoucherAdvanceDetailsInstance?.id,
                            createdBy: responseData?.PaymentVoucherAdvanceDetailsInstance?.createdBy?.id,
                            index: rowIndex + 1
                        };

                        return updatedData;
                    });
                    setadvinitialValue({
                        advRemarks: '',
                        advAmount: ''
                    })
                    setAdvanveformKey(prve => prve + 1)
                    setadvanceValue(prve => ({ ...prve, editIndex: '', advRemarks: "", Amount: "", id: "", createdBy: '' }));

                    setonNewItemSelect(false)
                }
                else {
                    let errors = ShowErrorNoties(responseData.errors)
                    showErrorToast(true, "error", errors)
                }

            } catch (error) {

                let errorData = ShowErrorNoties(error)
                showErrorToast(true, 'error', errorData)
            }

            setLoading(false)
        }
        const handledeleteConfomation = (item) => {
            setonNewItemSelect(false)
            if (item) {
                handltoastDeleteConfomationShow()
                setdeleteData({
                    Name: item?.advRemarks,
                    id: item?.id
                })
            }
        }


        return (
            <>
                <div className="row">
                    <div className="col-8">
                        <h6 className="commanModelTitleColor p-2">Advance</h6>
                    </div>
                    <div className="col-4 text-end">
                        <button
                            className="btn btn-sm btn-outline-primary p-1"
                            type="button"
                            style={{ borderRadius: '20px' }}
                            onClick={handleNewRow}
                            disabled={onNewItemSelect || editView}
                        >
                            <i className="fa-solid fa-plus"></i> New
                        </button>
                    </div>
                </div>

                <div id="staan-input-table" style={{ height: '12rem', overflowY: 'auto', width: '100%' }}>
                    <table className="StaanTable" style={{ width: '100%', tableLayout: 'fixed' }}>
                        <thead>
                            <tr style={{ fontSize: '.9rem', height: '2rem' }}>
                                <th style={{ width: '5%' }} onClick={() => {
                                    console.log("advanceData", advanceData);
                                }}>S No</th>
                                <th style={{ width: '30%' }} onClick={() => {
                                    console.log(advanceValue);
                                }}>Adv Remarks</th>
                                <th style={{ width: '15%' }} onClick={() => {
                                    console.log("advinitialValue", advinitialValue);
                                }}>Amount</th>
                                <th style={{ width: '15%' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {advanceData.map((item, rowIndex) => (
                                <Formik

                                    initialValues={advinitialValue}
                                    validationSchema={advanceValidation}
                                    enableReinitialize key={AdvanveformKey}

                                >
                                    {({ touched, errors, setFieldValue, values }) => (
                                        <tr>
                                            <td>{rowIndex + 1}</td>
                                            <td>
                                                {advanceValue?.editIndex === rowIndex + 1 ? (
                                                    <div className="row m-1">
                                                        <div className="staan-input-group">
                                                            <Field
                                                                type="text"
                                                                name="advRemarks"
                                                                id="advRemarks"
                                                                className="w-100 staan-form-input"
                                                            />
                                                            <label
                                                                htmlFor="advRemarks"
                                                                className="staan-form-input-label pt-1 px-1 mx-2"
                                                            >
                                                                Remark<span className="text-danger">*</span>
                                                            </label>
                                                            {touched.advRemarks && errors.advRemarks && <small>{errors.advRemarks}</small>}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    item?.advRemarks
                                                )}
                                            </td>
                                            <td>
                                                {advanceValue?.editIndex === rowIndex + 1 ? (
                                                    <div className="row m-1">
                                                        <div className="staan-input-group">
                                                            <Field
                                                                type="text"
                                                                name="advAmount"
                                                                id="advAmount"
                                                                className="w-100 staan-form-input"
                                                            />
                                                            <label
                                                                htmlFor="advAmount"
                                                                className="staan-form-input-label pt-1 px-1 mx-2"
                                                            >
                                                                Amount<span className="text-danger">*</span>
                                                            </label>
                                                            {touched.advAmount && errors.advAmount && <small>{errors.advAmount}</small>}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    item?.Amount
                                                )}
                                            </td>
                                            <td>
                                                <div style={{ display: 'flex' }}>
                                                    {advanceValue?.editIndex === rowIndex + 1 ? (
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="save-button"
                                                                onClick={() => handleAdvSave(rowIndex, values)}>
                                                                Save
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="cancel-button"
                                                                onClick={() => {
                                                                    setadvanceValue({ editIndex: '', advRemarks: "", Amount: "", id: "", createdBy: item?.createdBy });
                                                                    setonNewItemSelect(false)
                                                                    // && item?.index !== rowIndex
                                                                    setAdvanceData(prev => {
                                                                        return prev?.filter(item => item?.id !== "");
                                                                    });
                                                                }}

                                                            >
                                                                <i className="fa-solid fa-xmark"></i>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="edit-button"
                                                                disabled={editView}
                                                                onClick={() => {
                                                                    setadvinitialValue({
                                                                        id: item?.id,
                                                                        advRemarks: item?.advRemarks,
                                                                        advAmount: item?.Amount,
                                                                        createdBy: item?.createdBy ? Number(item?.createdBy) : ""
                                                                    })
                                                                    setadvanceValue(prve => ({ ...prve, editIndex: item?.index, advRemarks: item?.advRemarks, Amount: item?.Amount, createdBy: item?.createdBy }))

                                                                }}                                         >
                                                                <i className="fa-solid fa-pen-to-square"></i>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="delete-button"
                                                                disabled={editView}
                                                                onClick={() => handledeleteConfomation(item)}
                                                            >
                                                                <i className="fa-solid fa-trash"></i>
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </Formik>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };
    const AgainstInvoiceTable = () => {
        return (
            <>
                <div className="row">
                    <div className="col-8">
                        <h6 className="commanModelTitleColor p-2">Against Invoice Table</h6>
                    </div>
                    <div className="col-4 text-end">
                        <button
                            className="btn btn-sm btn-outline-primary p-1"
                            type="button"
                            style={{ borderRadius: '20px' }}
                        >
                            <i className="fa-solid fa-plus"></i> New
                        </button>
                    </div>
                </div>

                <div id="staan-input-table" style={{ height: '12rem', overflowY: 'auto', width: '100%' }}>
                    <table className="StaanTable" style={{ width: '100%', tableLayout: 'fixed' }}>
                        <thead>
                            <tr style={{ fontSize: '.9rem', height: '2rem' }}>
                                <th style={{ width: '10%' }}>Checkbox</th>
                                <th style={{ width: '15%' }}>Invoice No</th>
                                <th style={{ width: '10%' }}>Amount</th>
                                <th style={{ width: '10%' }}>Adjusted</th>
                                <th style={{ width: '25%' }}>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Sample data, replace with your actual data */}
                            <tr>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={true}
                                        className="me-2"
                                    />
                                </td>
                                <td>5124230</td>
                                <td>1000</td>
                                <td>
                                    1500
                                </td>
                                <td>
                                    Remark
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </>
        );
    };
    const resetForm = () => {
        setinitialVoucherData({
            voucherNo: '',
            paymentTo: '',
            paymentMode: '',
            employeeDetailID: '',
            employeeDetailName: '',
            supplierNumber: '',
            supplierName: '',
            empAmount: '',
            cusAmount: '',
            refNo: '',
            chqDate: '',
            transfer: '',
            bank: ''

        })
        setvoucherOptionList({
            paymentTotList: [],
            paymentMode: [],
            payforList: [],
            supplierNoList: [],
            supplierNameList: [],
            bankList: [],
            transferList: []
        })
        setEmployeeOptionList({
            employeeIDList: [],
            employeeNameList: []
        })
        setvoucherSelectValues({
            paymentToSelect: '',
            paymentModeSelect: '',
            paymentForSelect: '',
            employeeIdSelect: '',
            employeeNameSelect: '',
            supplierNoSelected: '',
            supplierNameSelected: '',
            AgainstInvoicechecked: false,
            Advancechecked: false,
            bankSelect: '',
            transferSelect: ''
        })
        setAdvanceData([])
        setadvanceValue({ editIndex: '', advRemarks: "", Amount: "", id: "", createdBy: '' })
        setadvinitialValue({
            advRemarks: '',
            advAmount: ''
        })
        setVouchershow(false)
        setEditData?.(null)
        setLoad?.()
        seteditViewMandatoryField(false)
        setrequestFormData?.([])
    }
    function updateTheStates(resData) {
        setinitialVoucherData(
            {
                voucherNo: resData?.paymentVoucherNo ? resData?.paymentVoucherNo?.linkedModelId : '',
                voucherDate: resData?.date ? resData?.date : '',
                paymentTo: resData?.payTo ? resData?.payTo?.name : '',
                paymentFor: resData?.payFor ? Number(resData?.payFor?.id) : '',
                paymentMode: resData?.payMode ? Number(resData?.payMode?.id) : '',
                employeeDetailID: resData?.employeeId ? resData?.employeeId?.id : '',
                employeeDetailName: resData?.employeeId ? resData?.employeeId?.id : '',
                supplierNumber: resData?.cusSupId ? resData?.cusSupId?.id : '',
                supplierName: resData?.cusSupId ? resData?.cusSupId?.id : '',
                empAmount: resData?.empAmount ? resData?.empAmount : '',
                cusAmount: resData?.cusSupAmount ? resData?.cusSupAmount : '',
                refNo: resData?.chqRefNo ? resData?.chqRefNo : '',
                chqDate: resData?.chqDate ? resData?.chqDate : '',
                transfer: resData?.transferVia ? Number(resData?.transferVia?.id) : '',
                bank: resData?.bank ? Number(resData?.bank?.id) : '',
                status: resData?.status?.name ? resData?.status?.name : '',
                createdBy: resData?.createdBy ? resData?.createdBy?.id : '',
                modifiedBy: resData?.modifiedBy ? resData?.modifiedBy?.id : '',
                id: resData?.id ? resData?.id : ''
            }
        )
        setvoucherSelectValues({
            paymentToSelect: resData?.payTo ? { value: resData?.payTo?.id, label: resData?.payTo?.name } : '',
            paymentModeSelect: resData?.payMode ? { value: resData?.payMode?.id, label: resData?.payMode?.name } : '',
            paymentForSelect: resData?.payFor ? { value: resData?.payFor?.id, label: resData?.payFor?.accountsName } : '',
            employeeIdSelect: resData?.employeeId ? { value: resData?.employeeId?.id, label: resData?.employeeId?.employeeId } : '',
            employeeNameSelect: resData?.employeeId ? { value: resData?.employeeId?.id, label: resData?.employeeId?.employeeName } : '',
            supplierNoSelected: resData?.cusSupId ? { value: resData?.cusSupId?.id, label: resData?.cusSupId?.supplierNo } : '',
            supplierNameSelected: resData?.cusSupId ? { value: resData?.cusSupId?.id, label: resData?.cusSupId?.companyName } : '',
            AgainstInvoicechecked: resData?.againstInvoice ? resData?.againstInvoice : false,
            Advancechecked: resData?.advance ? resData?.advance : false,
            bankSelect: resData?.bank ? { value: resData?.bank?.id, label: resData?.bank?.accountsName } : '',
            transferSelect: resData?.transferVia ? { value: resData?.transferVia?.id, label: resData?.transferVia?.name } : '',
        }

        )
        const updatedData = resData?.advanceDetails ? resData?.advanceDetails.map((item, rowIndex) => ({
            advRemarks: item?.advRemark,
            Amount: parseFloat(item?.amount),
            id: Number(item?.id),
            createdBy: Number(item?.createdBy?.id),
            index: rowIndex + 1
        })) : []

        console.log("AdvanceupdatedData", updatedData);
        seteditView(true)
        setAdvanceData(updatedData);
        setFormKey(prve => prve + 1)
    }
    const handleVocherSubmit = async (values, { setErrors }) => {
        setLoading(true)
        let isValid = true
        if (voucherSelectValues?.paymentToSelect?.label === "Supplier & Customer") {
            const isConditionMet = voucherSelectValues.AgainstInvoicechecked || voucherSelectValues.Advancechecked;
            const totalAmountInAdvance = advanceData?.length > 0 ? advanceData.reduce((sum, item) => sum + Number(item.Amount), 0) : 0;
            const isAmountValid = Number(values.cusAmount) === totalAmountInAdvance;
            if (!isConditionMet) {
                showErrorToast(true, "error", "Either Against Invoice or Advance must be selected");
                isValid = false
            }
            else if (!isAmountValid) {
                showErrorToast(true, "error", "Amount and Advance Amount mismatch");
                isValid = false
            }
            else {
                isValid = true
            }
        }

        if (isValid) {
            try {
                const reqBody = {
                    advance: voucherSelectValues?.Advancechecked,
                    advanceDetails: advanceData?.map(item => item.id),
                    againstInvoice: voucherSelectValues?.AgainstInvoicechecked,
                    bank: values?.bank ? Number(values?.bank) : '',
                    chqDate: values?.chqDate ? values?.chqDate : '',
                    chqRefNo: values?.refNo ? values?.refNo : '',
                    createdBy: values?.createdBy ? Number(values?.createdBy) : Number(userId),
                    cusSupAmount: values?.cusAmount ? values?.cusAmount : '',
                    cusSupId: values?.supplierNumber ? Number(values?.supplierNumber) : '',
                    date: values?.voucherDate ? values?.voucherDate : '',
                    empAmount: values?.empAmount ? values?.empAmount : '',
                    employeeId: values?.employeeDetailID ? Number(values?.employeeDetailID) : '',
                    id: values?.id ? values?.id : '',
                    modifiedBy: values?.modifiedBy ? Number(values?.modifiedBy) : Number(userId),
                    payFor: values?.paymentFor ? values?.paymentFor : '',
                    payMode: voucherSelectValues?.paymentModeSelect ? Number(voucherSelectValues?.paymentModeSelect?.value) : '',
                    payTo: voucherSelectValues?.paymentToSelect ? Number(voucherSelectValues?.paymentToSelect?.value) : '',
                    status: values?.status ? values?.status : 'Draft',
                    transferVia: values?.transfer ? values?.transfer : '',
                    expenseRequestId: values?.expenseRequestId ? values?.expenseRequestId : ''
                }
                let allow_null = ["id", "modifiedBy", "createdBy", "bank", "cusSupId", "transferVia", "chqDate", "cusSupAmount", "employeeId", "payFor", "empAmount", "payMode"]
                let clear_data = removeEmptyValueInObject(reqBody, allow_null)
                const respones = await axiosInstance.post(`/itemmaster/graphql`, {
                    query: voucherCreateMutation(clear_data, allow_null)
                })
                const responseData = respones?.data?.data?.paymentVoucherCreateMutation
                if (responseData?.success) {
                    const resData = responseData?.PaymentVoucherInstance
                    updateTheStates(resData)
                    seteditViewMandatoryField(true)
                    if (requestFormData) {
                        handleSubmitRequestForm()
                        setVouchershow(false)
                    }
                }
                else {
                    let errors = ShowErrorNoties(responseData.errors)
                    showErrorToast(true, "error", errors)
                }
            } catch (error) {
                console.log(error);
                showErrorToast(true, "error", ShowErrorNoties(error))
            }
        }
        else {
            console.log("Error");

        }
        setLoading(false)
    }
    return (
        <>
            {/* Modal */}
            {<BallTriangleLoading isshow={isLoading} />}
            <Modal show={isVouchershow} keyboard={false}
                size="xl"
                centered>
                <div className="row mx-1">
                    <div className="col-5 m-3" style={{ color: "#5CADA9" }}>
                        <Modal.Title>
                            <i className="fa-solid fa-arrow-left fa-sm me-2" onClick={resetForm}></i>
                            Payment Voucher
                            {initialVoucherData?.status ? <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'> {initialVoucherData?.status} </span> : ""}
                        </Modal.Title>
                    </div>
                    <div className="col-6 mt-4 text-end">
                        <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={initialVoucherData?.status === "Canceled" ? true : false || initialVoucherData?.status === "Submit" ? true : false || !editView} onClick={() => { seteditView(false) }}></i>
                        <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3" hidden={initialVoucherData?.status === "Canceled" ? false : true} onClick={() => { setIsDeleteWarningShow(true) }}></i>
                        <TiCancel
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Cancel"
                            className='fs-2 pb-1'
                            style={{ color: '#7f7f7f', cursor: "pointer" }}
                            hidden={(!editView) || initialVoucherData?.status === "Canceled" ? true : false}
                            onClick={() => { setIsCancelShow(true) }}
                        />
                    </div>
                </div>

                <hr />
                <Modal.Body>
                    <Formik
                        initialValues={initialVoucherData}
                        validationSchema={paymentVoucherValidation}
                        onSubmit={handleVocherSubmit}
                        enableReinitialize key={formKey}
                    >
                        {({ errors, touched, setFieldValue, submitForm, values, setValues }) => (
                            <>
                                <Form>
                                    <div className='row ms-1 mt-1' style={{ width: '100%' }}>
                                        <div className="row">
                                            <div className='col-6 staan-input-group'>
                                                <Field type='text' name='voucherNo' id='voucherNo' className='w-100 staan-form-input' disabled={true} />
                                                <label htmlFor="voucherNo" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                    console.log("voucherSelectValues", voucherSelectValues, "errors", errors);
                                                }}>Voucher No<span className='text-danger'>*</span></label>
                                                {touched.voucherNo && errors.voucherNo && <small>{errors.voucherNo}</small>}
                                            </div>
                                            <div className='col-6 col-6 staan-input-group'>
                                                <Field type='date' name='voucherDate' id='voucherDate' className='w-100 staan-form-input' disabled={editView} />
                                                <label htmlFor="voucherDate" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                    console.log("values", values);
                                                }}>Voucher Date<span className='text-danger'>*</span></label>
                                                {touched.voucherDate && errors.voucherDate && <small>{errors.voucherDate}</small>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-6 staan-input-group'>
                                                <CustomSelect
                                                    setSelectEditValue={voucherSelectValues?.paymentToSelect}
                                                    options={voucherOptionList?.paymentTotList}
                                                    name='paymentTo'
                                                    handleOnChange={(option) => {
                                                        if (voucherSelectValues?.paymentToSelect?.value) {
                                                            setpaytoChange({ isshow: true, ChangedData: { selectvalue: option ? option : null, fieldValue: values } })
                                                        } else {
                                                            setFieldValue('paymentTo', option ? option?.label : null)
                                                            setvoucherSelectValues(prve => ({
                                                                ...prve,
                                                                paymentToSelect: option ? option : null
                                                            }))
                                                        }
                                                    }}
                                                    isDisabled={editView || iseditViewMandatoryField}
                                                />
                                                <label className='staan-form-input-label  px-1 mx-2' onClick={() => {
                                                    console.log(values);
                                                }}>Pay To<span className='text-danger'>*</span></label>
                                                {touched.paymentTo && errors.paymentTo && <small>{errors.paymentTo}</small>}
                                            </div>
                                            <div className='col-6 staan-input-group'>
                                                <CustomSelect
                                                    setSelectEditValue={voucherSelectValues?.paymentModeSelect}
                                                    options={voucherOptionList?.paymentMode}
                                                    name='paymentMode'
                                                    handleOnChange={(option) => {
                                                        setFieldValue('paymentMode', option ? option?.label : null)
                                                        setvoucherSelectValues(prve => ({
                                                            ...prve,
                                                            paymentModeSelect: option ? option : null
                                                        }))
                                                    }}
                                                    isDisabled={editView}
                                                />
                                                <label className='staan-form-input-label  px-1 mx-2' onClick={() => {
                                                    console.log(values);
                                                }}>Pay Mode<span className='text-danger'>*</span></label>
                                                {touched.paymentMode && errors.paymentMode && <small>{errors.paymentMode}</small>}
                                            </div>
                                        </div>
                                        {voucherSelectValues?.paymentToSelect?.label === "Employee" ? <>
                                            <div className="row">
                                                <h6 className="commanModelTitleColor p-2">Employee</h6>
                                                <div className="col-6 staan-input-group">
                                                    <CustomSelect
                                                        setSelectEditValue={voucherSelectValues?.employeeIdSelect}
                                                        handleInputChange={(e) => { getOptions(e, "employeeID") }}
                                                        options={employeeOptionList.employeeIDList}
                                                        name='employeeDetailID'
                                                        handleOnChange={(option) => {
                                                            setFieldValue('employeeDetailID', option ? Number(option?.value) : null)
                                                            setFieldValue('employeeDetailName', option ? Number(option?.value) : null)
                                                            setvoucherSelectValues(prve => ({
                                                                ...prve,
                                                                employeeIdSelect: option ? option : null,
                                                                employeeNameSelect: option ? { value: option?.value, label: option?.employeeName } : null,

                                                            }))
                                                        }}
                                                        isDisabled={editView}
                                                    />
                                                    <label className='staan-form-input-label  px-1 mx-2' onClick={() => {
                                                        console.log(employeeOptionList.employeeIDList);
                                                    }}>Employee ID<span className='text-danger'>*</span></label>
                                                    {touched.employeeDetailID && errors.employeeDetailID && <small>{errors.employeeDetailID}</small>}
                                                </div>
                                                <div className="col-6 staan-input-group">
                                                    <CustomSelect
                                                        setSelectEditValue={voucherSelectValues?.employeeNameSelect}
                                                        handleInputChange={(e) => { getOptions(e, "employeeName") }}
                                                        options={employeeOptionList.employeeNameList}
                                                        name='employeeDetailName'
                                                        handleOnChange={(option) => {
                                                            setFieldValue('employeeDetailName', option ? Number(option?.value) : null)
                                                            setFieldValue('employeeDetailID', option ? Number(option?.value) : null)
                                                            setvoucherSelectValues(prve => ({
                                                                ...prve,
                                                                employeeNameSelect: option ? option : null,
                                                                employeeIdSelect: option ? { value: option?.value, label: option?.employeeId } : null,


                                                            }))
                                                        }}
                                                        isDisabled={editView}
                                                    />
                                                    <label className='staan-form-input-label  px-1 mx-2'>Employee Name <span className='text-danger'>*</span></label>
                                                    {touched.employeeDetailName && errors.employeeDetailName && <small>{errors.employeeDetailName}</small>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 staan-input-group">
                                                    <CustomSelect
                                                        setSelectEditValue={voucherSelectValues?.paymentForSelect}
                                                        handleInputChange={(e) => { getOptions(e, "PayFor") }}
                                                        options={voucherOptionList?.payforList}
                                                        name='paymentFor'
                                                        handleOnChange={(option) => {
                                                            setFieldValue('paymentFor', option ? Number(option?.value) : null)
                                                            setvoucherSelectValues(prve => ({
                                                                ...prve,
                                                                paymentForSelect: option ? option : null,
                                                            }))
                                                        }}
                                                        isDisabled={editView}
                                                    />
                                                    <label className='staan-form-input-label  px-1 mx-2'>Pay For<span className='text-danger'>*</span></label>
                                                    {touched.paymentFor && errors.paymentFor && <small>{errors.paymentFor}</small>}
                                                </div>
                                                <div className="col-6 staan-input-group">
                                                    <Field type='number' name='empAmount' id='empAmount' className='w-100 staan-form-input' disabled={editView} />
                                                    <label htmlFor="empAmount" className='staan-form-input-label pt-1 px-1 mx-2'>Amount<span className='text-danger'>*</span></label>
                                                    {touched.empAmount && errors.empAmount && <small>{errors.empAmount}</small>}
                                                </div>
                                            </div>
                                        </> : <></>}
                                        {voucherSelectValues?.paymentToSelect?.label === "Supplier & Customer" ? <>
                                            <div className="row">
                                                <h6 className="commanModelTitleColor p-2">Customer/Supplier</h6>
                                                <div className="col-4 staan-input-group">
                                                    <CustomSelect
                                                        setSelectEditValue={voucherSelectValues?.supplierNoSelected}
                                                        handleInputChange={(e) => getOptions(e, 'supplierNumber')}
                                                        options={voucherOptionList?.supplierNoList}
                                                        name='supplierNumber'
                                                        handleOnChange={(option) => {
                                                            setFieldValue('supplierNumber', option?.value ? Number(option?.value) : null);
                                                            setFieldValue(
                                                                'supplierName',
                                                                option?.value ? Number(option?.value) : null
                                                            );
                                                            setvoucherSelectValues(prve => ({
                                                                ...prve,
                                                                supplierNoSelected: option ? option : null,
                                                                supplierNameSelected: option ? { value: option?.id, label: option?.companyName } : null
                                                            }))
                                                        }}
                                                        isDisabled={editView}
                                                    />
                                                    <label className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                        console.log("voucherSelectValues?.paymentModeSelect", voucherSelectValues?.paymentModeSelect, "voucherSelectValues?.paymentToSelect", voucherSelectValues?.paymentToSelect);
                                                    }}>
                                                        Customer Code<span className='text-danger'>*</span>
                                                    </label>
                                                    {touched.supplierName && errors.supplierName && (
                                                        <small className='staan-error-msg'>
                                                            {errors.supplierName}
                                                        </small>
                                                    )}
                                                </div>
                                                <div className="col-4 staan-input-group">
                                                    <CustomSelect
                                                        setSelectEditValue={voucherSelectValues?.supplierNameSelected}
                                                        handleInputChange={(e) => { getOptions(e, 'supplierName'); }}
                                                        options={voucherOptionList?.supplierNameList}
                                                        name='supplierName'
                                                        handleOnChange={(option) => {
                                                            setFieldValue('supplierName', option?.value ? option?.value : null);
                                                            setFieldValue('supplierNumber', option?.value ? option?.value : null);
                                                            setvoucherSelectValues(prve => ({
                                                                ...prve,
                                                                supplierNoSelected: option ? { value: option?.value, label: option?.supplierNo } : null,
                                                                supplierNameSelected: option ? option : null
                                                            }))
                                                        }}
                                                        isDisabled={editView}
                                                    />
                                                    <label className='staan-form-input-label pt-1 px-1 mx-2' >
                                                        Customer Name<span className='text-danger'>*</span>
                                                    </label>
                                                    {touched.supplierNumber && errors.supplierNumber && (
                                                        <small className='staan-error-msg'>
                                                            {errors.supplierNumber}
                                                        </small>
                                                    )}
                                                </div>
                                                <div className='col-4 staan-input-group'>
                                                    <Field type='number' name='cusAmount' id='cusAmount' className='w-100 staan-form-input' disabled={editView} />
                                                    <label htmlFor="cusAmount" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                        console.log(errors);
                                                    }}>Amount<span className='text-danger'>*</span></label>
                                                    {touched.cusAmount && errors.cusAmount && <small>{errors.cusAmount}</small>}
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-6'>
                                                    <input
                                                        type="checkbox"
                                                        checked={voucherSelectValues?.AgainstInvoicechecked}
                                                        onChange={e => {
                                                            setvoucherSelectValues(prve => ({
                                                                ...prve,
                                                                AgainstInvoicechecked: !voucherSelectValues?.AgainstInvoicechecked,

                                                            }))
                                                        }}
                                                        disabled={voucherSelectValues?.Advancechecked || editView}
                                                        className="me-2"
                                                    />
                                                    <span >
                                                        Against Invoice
                                                    </span>
                                                </div>
                                                <div className='col-6'>
                                                    <input
                                                        type="checkbox"
                                                        checked={voucherSelectValues?.Advancechecked}
                                                        onChange={e => {
                                                            setvoucherSelectValues(prve => ({
                                                                ...prve,
                                                                Advancechecked: !voucherSelectValues?.Advancechecked

                                                            }))
                                                        }}
                                                        disabled={voucherSelectValues?.AgainstInvoicechecked || editView}
                                                        className="me-2"
                                                    />
                                                    <span onClick={() => {
                                                        console.log(voucherSelectValues?.AgainstInvoicechecked);
                                                    }}>
                                                        Advance
                                                    </span>
                                                </div>

                                            </div>
                                            {voucherSelectValues?.AgainstInvoicechecked &&
                                                <div className='row'>
                                                    <AgainstInvoiceTable />
                                                </div>
                                            }
                                            {voucherSelectValues?.Advancechecked &&
                                                <div className='row'>
                                                    <AdvanceTable />
                                                </div>
                                            }
                                            {voucherSelectValues?.paymentModeSelect?.label === 'Bank' ? <>
                                                <div className='row'>
                                                    <div className='col-6 staan-input-group'>
                                                        <CustomSelect
                                                            setSelectEditValue={voucherSelectValues?.bankSelect}
                                                            handleInputChange={(e) => { getOptions(e, "ChooseBank") }}
                                                            options={voucherOptionList?.bankList}
                                                            name='bank'
                                                            handleOnChange={(option) => {
                                                                setFieldValue('bank', option ? Number(option?.value) : null)
                                                                setvoucherSelectValues(prve => ({
                                                                    ...prve,
                                                                    bankSelect: option ? option : null
                                                                }))
                                                            }}
                                                            isDisabled={editView}
                                                        />
                                                        <label className='staan-form-input-label  px-1 mx-2'>Choose Bank<span className='text-danger'>*</span></label>
                                                        {touched.bank && errors.bank && <small>{errors.bank}</small>}
                                                    </div>
                                                    <div className='col-6 staan-input-group'>
                                                        <CustomSelect
                                                            setSelectEditValue={voucherSelectValues?.transferSelect}
                                                            options={voucherOptionList?.transferList}
                                                            name='transfer'
                                                            handleOnChange={(option) => {
                                                                setFieldValue('transfer', option ? Number(option?.value) : null)
                                                                setvoucherSelectValues(prve => ({
                                                                    ...prve,
                                                                    transferSelect: option ? option : null
                                                                }))
                                                            }}
                                                            isDisabled={editView}
                                                        />
                                                        <label className='staan-form-input-label  px-1 mx-2'>Transfer Via<span className='text-danger'>*</span></label>
                                                        {touched.transfer && errors.transfer && <small>{errors.transfer}</small>}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6 staan-input-group'>
                                                        <Field type='text' name='refNo' id='refNo' className='w-100 staan-form-input' disabled={editView} />
                                                        <label htmlFor="refNo" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                            console.log(errors);
                                                        }}>Chq/Ref No<span className='text-danger'>*</span></label>
                                                        {touched.refNo && errors.refNo && <small>{errors.refNo}</small>}
                                                    </div>
                                                    <div className='col-6 staan-input-group'>
                                                        <Field type='date' name='chqDate' id='chqDate' className='w-100 staan-form-input' disabled={editView} />
                                                        <label htmlFor="chqDate" className='staan-form-input-label pt-1 px-1 mx-2'>Chq Date<span className='text-danger'>*</span></label>
                                                        {touched.chqDate && errors.chqDate && <small>{errors.chqDate}</small>}
                                                    </div>
                                                </div></> : <></>}

                                        </> : <></>}
                                        <div className="row m-3">
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className="btn btn-outline-danger btn-sm me-2 px-4"
                                                    disabled={editView}
                                                    onClick={() => { setFieldValue('status', "Draft"); }}

                                                >
                                                    Draft
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-outline-success shadow-sm px-4"
                                                    disabled={initialVoucherData?.status === "Draft" ? false : true}
                                                    onClick={() => { setFieldValue('status', "Submit"); submitForm() }}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {ispaytoChange?.isshow && <WaringModel
                                        state={ispaytoChange?.isshow}
                                        setstate={setpaytoChange}
                                        title={'Confirm'}
                                        text={"Confirm to Change Payto"}
                                        Confirmtion={value => {
                                            if (value) {
                                                handlePaytoChange(ispaytoChange?.ChangedData, value)
                                            }
                                        }}
                                    />}
                                </Form>
                                {deleteData && (
                                    <DeleteConformation
                                        handleDeletedId={id => handleAdvanceDeletedId(id)}
                                        deleteData={deleteData}
                                        fullquery={advanceTableDeleteMutation(deleteData?.id)}
                                    />
                                )}
                                {isDeleteWarningShow &&
                                    <WaringModel
                                        state={isDeleteWarningShow}
                                        setstate={setIsDeleteWarningShow}
                                        title={'Confirm'}
                                        text={"Confirm to Delete"}
                                        Confirmtion={value => {
                                            if (value) {
                                                DeleteTheVocher(value)
                                            }
                                        }}
                                    />}
                                {
                                    IsCancelShow &&
                                    <WaringModel
                                        state={IsCancelShow}
                                        setstate={setIsCancelShow}
                                        title={'Confirm'}
                                        text={"Confirm to Cancel"}
                                        Confirmtion={value => {
                                            if (value) {
                                                CancelTheVocher(value)
                                            }
                                        }}
                                    />
                                }

                            </>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal >
        </>
    );
}

export default PaymentVoucherModal;
