import React, { useContext, useEffect, useState, useRef } from 'react'
import { Formik, Field, Form } from 'formik'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EnquirySchema } from '../../validations/Enquiryvalidation'
import staanlogo from '../../img/logo-final.png'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { ToastContainer, toast, } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { Pagination } from 'swiper/modules'
import Select from 'react-select'
import './Enquirystyle.css'
import axiosInstance, { commanFetch } from '../../api/axoiss'
import DataContext from '../../context/ItemMasterContext'
import TextEditer from '../../components/textEditer/TextEditer'
import Tesseract from 'tesseract.js'
import compromise from 'compromise'
import { useSelector, useDispatch } from 'react-redux';
import { fetchOptionsData } from '../../ReduxState/Action/itemMasterAction'
import { Area_data, districtQuery, Filiter_address_with_pincode_Query, pincodeQuery, StateQuery } from '../../GraphQLQueries/ItemmasterQuery'
import CreatableSelect from 'react-select/creatable';
import { MdInput } from "react-icons/md";
import { ShowErrorNoties } from '../../utils/Utils'
import showErrorToast from '../../components/notifications/react-toastify/toast'
import WaringModel from '../../components/Warings/WaringModel'
import CustomSelect from '../../components/ReactSelect/reactSelect'

const EnquiryForm = () => {
  const {
    currentConference, userName,
    setExtractData, Navigate, options, userId } = useContext(DataContext);
  const dispatch = useDispatch();
  const OptionsDataList = useSelector(state => state.itemGroup.data);
  const [textEditer_, settextEditer_] = useState(true)
  const [selectedFile, setSelectedFile] = useState(null)
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)
  const [pincodeList, setpincodeList] = useState([])
  const [linkedCostomerId, setlinkedCostomerId] = useState(null)
  const [isNew, setIsNew] = useState(false)
  const [allowEdit, setAllowEdit] = useState(false)
  const [contactList, setContactList] = useState([])
  const [waringModel, setWaringModel] = useState(false)

  function FetchData(e, optionName) {
    let search_term = e.trim().replace(/"/g, '\\"');
    if (optionName === "Area_data") {
      dispatch(fetchOptionsData(Area_data(search_term, 1), "Area_data"))
    } else if (optionName === "districtList") {
      dispatch(fetchOptionsData(districtQuery(search_term, 1), "districtList"))
    } else if (optionName === "pincodeList") {
      dispatch(fetchOptionsData(pincodeQuery(search_term, 1), "pincodeList"))
    } else if (optionName === "statesList") {
      dispatch(fetchOptionsData(StateQuery(search_term, 1), "statesList"))
    }
  }
  useEffect(() => {
    if (OptionsDataList.name === "pincodeList") {
      setpincodeList(OptionsDataList?.value?.items?.map((type) => ({ value: type.id, label: type.pincode })))
    }
    if (OptionsDataList.name === "distri_state") {
      let filiter_resuelt = OptionsDataList?.value?.items?.[0]

      setpincodeValue({ value: filiter_resuelt?.['pincode']?.id, label: filiter_resuelt?.['pincode']?.pincode })
      setDistrictValue(filiter_resuelt?.['district'])
      setstatesValue(filiter_resuelt?.['state'])
    }
  }, [OptionsDataList])
  const handleCollapse = () => {
    if (isCollapseOpen === 'show') {
    } else {
      setIsCollapseOpen('show')
    }
  }

  const toastId = useRef(null)

  const extractInfoFromImage = async image => {
    try {
      toastId.current = toast.loading('Loading...')
      const {
        data: { text }
      } = await Tesseract.recognize(
        selectedFile,
        'eng', // Language code for English
        { logger: info => console.log(info) } // Optional logger
      )

      setExtractData(text)
      const lines = text.split(/\n+/)
      const doc = compromise(text)
      toast.update(toastId.current, {
        render: `Text recognized`,
        type: 'success',
        isLoading: false,
        autoClose: 800
      })

      const extractedInfo = {
        name: '',
        phone: '',
        email: '',
        address: '',
        organizations: '',
        city: ''
      }

      // Define regular expressions for phone, email, and address
      // const phoneRegex = /(\+?\d{1,4}[-.\s]?)?\(?\d{1,}\)?[-.\s]?\d{1,}[-.\s]?\d{1,}[-.\s]?\d{1,}/;
      const phoneRegex_1 = /\+?9?1?\s?-?\d{5}\s?\d{5}/

      const emailRegex = /\S+@\S+\.\S+/
      const addressRegex = /\d+[-.\s]?\d+\s*,\s*\S+.*\d{5}/

      // Iterate through lines and extract information
      lines.forEach(line => {
        extractedInfo.name = doc.people().out('array').join(' ')
        // extractedInfo.email = doc.emails()
        // extractedInfo.phone =  doc.phoneNumbers()
        extractedInfo.organizations = doc.organizations().out('array').join(' ')

        if (!extractedInfo.phone) {
          const phoneMatch = line.match(phoneRegex_1)
          console.log(phoneMatch)
          if (phoneMatch) {
            extractedInfo.phone = phoneMatch[0].trim()
          }
        }
        options.forEach(keyword => {
          if (line.includes(keyword.value)) {
            extractedInfo.city = keyword.value
          }
        })

        if (!extractedInfo.email) {
          const emailMatch = line.match(emailRegex)
          if (emailMatch) {
            extractedInfo.email = emailMatch[0].trim()
          }
        }

        if (!extractedInfo.address) {
          const addressMatch = line.match(addressRegex)
          if (addressMatch) {
            extractedInfo.address = addressMatch[0].trim()
          }
        }
      })

      handlenameChange(extractedInfo.name)
      handlemailChange(extractedInfo.email)
      handlphoneChange(extractedInfo.phone)

      handlOrgChange(extractedInfo.organizations)

      initialValues.name = extractedInfo.name
      initialValues.Hospital = extractedInfo.organizations
      initialValues.email = extractedInfo.email
      initialValues.Mobile = extractedInfo.phone
      initialValues.locations = extractedInfo.city

      console.log('Extracted Information:', extractedInfo)
      console.log(text)
      handleClearFile()

      // }
    } catch (error) {
      toast.update(toastId.current, {
        render: 'Error during recognition',
        type: 'error',
        isLoading: false
      })
    }
  }

  const handletextedtier = () => {
    settextEditer_(!textEditer_)
  }
  const handleFileChange = event => {
    const file = event.target.files[0]
    setSelectedFile(file)
  }
  const handleClearFile = () => {
    setSelectedFile('')
    // Clear the input field visually by setting its value to an empty string
    const fileInput = document.getElementById('fileInput') // Assuming the id of the input is 'fileInput'
    if (fileInput) {
      fileInput.value = ''
    }
  }
  const [initialValues, setinitialValues] = useState({
    name: '',
    Hospital: '',
    email: '',
    alternateMobileNumber: '',
    Mobile: '',
    locations: '',
    District: "",
    state: "",
    Pincode: "",
    otherNumber: "",
    message: '',
    intrested: []
  })
  function reset_From() {
    setinitialValues({
      name: '',
      Hospital: '',
      email: '',
      alternateMobileNumber: '',
      otherNumber: '',
      Mobile: '',
      locations: '',
      message: '',
      intrested: []
    })
    handlenameChange('')
    handlemailChange('')
    handlphoneChange('')
    handlOrgChange('')
    setpincodeValue({ value: "", label: "" })
    setDistrictValue({ id: "", district: "" })
    setstatesValue({ id: "", stateName: "" })
  }


  const handlesubmit = async (values, { setSubmitting, resetForm }) => {
    if ((!isNew || values['Mobile'].length >= 10) && (values['Mobile'].length <= 15)) {

    } else {
      showErrorToast(true, "error", "Check The Mobile Number.")
      return ""
    }

    let save_enquiry_from = {
      name: values['name'],
      organizationName: values['Hospital'],
      email: values['email'],
      alternateMobileNumber: values['alternateMobileNumber'],
      status: 'NOT_CONTACTED',
      mobileNumber: !isNew ? phone?.label : values['Mobile'],
      location: values['locations'],
      message: values['message'],
      conferenceData: currentConference?.value ? currentConference.value : null,
      interests: values['intrested'],
      otherNumber: values['otherNumber'],
    }


    try {
      let interests = values['intrested'].map(item => `"${item}"`)
      let enquiry_form_create_mutations = `mutation enquiryDataCreateMutation{
        enquiryDataCreateMutation(
          name: "${values['name']}"
          organizationName: "${values['Hospital']}"
          email: "${values['email']}"
          status: "NOT_CONTACTED"
          mobileNumber: "${!isNew ? phone?.label : values['Mobile']}"
          location: "${values['locations']}"
          linkContactDetalis : ${linkedCostomerId ? linkedCostomerId : null}
          conferenceData: ${save_enquiry_from['conferenceData']}
          pincode: ${pincodeValue?.value}
          district: ${districtValue?.id}
          state: ${statesValue?.id}
          createdBy: ${userId ? Number(userId) : null}`


      if (interests.length > 0) {
        enquiry_form_create_mutations += `
        interests: [${interests}]`
      } else {
        enquiry_form_create_mutations += `
        interests: []`
      }

      if (values['message']?.trim() !== '') {
        enquiry_form_create_mutations += ` 
        message: "${values['message']}"`
      }
      if (values['alternateMobileNumber'] && values['alternateMobileNumber']?.trim() !== '') {
        enquiry_form_create_mutations += ` 
        alternateMobileNumber: "${values['alternateMobileNumber']}"`
      }

      if (values['otherNumber'] && values['otherNumber']) {
        enquiry_form_create_mutations += `
        otherNumber: "${values['otherNumber']}"`
      }
      enquiry_form_create_mutations += `){
          enquiryData{
            id
            name
            email
            mobileNumber
            salesPerson{
              id
              username
            }
            status
            remarks
            followUp
            message
            location
            alternateMobileNumber
            otherNumber
          }
          success
          errors
        }
      }`
      const response = await axiosInstance.post('/itemmaster/graphql', { query: enquiry_form_create_mutations })
      const responseData = response?.data?.data?.enquiryDataCreateMutation
      if (responseData?.success) {
        setlinkedCostomerId()
        setExtractData('')
        reset_From()
        resetForm()
        setSubmitting(false)
        setIsNew(false)
        setAllowEdit(false)
        toast(
          'Thank you so much for your enquiry. Our salesperson will be in touch with you shortly.',
          {
            position: 'top-center',
            autoClose: 800,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: 'light',
            className: 'custom-toast'
          }
        )
      } else {

        let errorData = ShowErrorNoties(responseData?.errors)
        toast.error(`${errorData}`, {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        })
      }
    } catch (error) {
      toast.error(`${error.message}`, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })
    }
  }

  const [name, setName] = useState('')
  const handlenameChange = value => {
    setName(value)
  }

  const [email, setEmail] = useState('')
  const handlemailChange = value => {
    setEmail(value)
  }

  const [phone, setPhone] = useState('')
  const handlphoneChange = value => {
    setPhone(value)
  }

  const [org, setOrg] = useState('')
  const handlOrgChange = value => {
    setOrg(value)
  }

  function confirmationToEdit(params) {
    if (params) {
      setAllowEdit(true)
    }

  }

  const [districtValue, setDistrictValue] = useState('')

  const [statesValue, setstatesValue] = useState('')
  const [pincodeValue, setpincodeValue] = useState('')
  const handleSelectpincode = options => {
    if (options) {
      let peremeter = `pincode:${options?.value}, pincodeDistinct:true`

      dispatch(fetchOptionsData(Filiter_address_with_pincode_Query(peremeter), "distri_state"))
      if (options.label) {
        setpincodeValue({ value: options.value, label: options.label })
      }
    } else {

      setpincodeValue({ value: "", label: "" })
      setDistrictValue({ id: "", district: "" })
      setstatesValue({ id: "", stateName: "" })
    }
  }

  async function fetchOptions(params) {


    let queryWithPerementer = ` 
        query MyQuery {
      contactDetalis(phoneNumber: "${params}"){
        items {
          contactPersonName
          email
          id
          otherNo
          phoneNumber 
          whatsappNo
          supplierformdataSet {
            companyName
            address {
              default
              pincode
            }
          }
        }
      }
    }`

    const response = await commanFetch(queryWithPerementer)


    const responseData = response?.data?.items
    if (response.success) {
      setContactList(responseData?.map((item) => ({
        value: item?.id,
        label: item?.phoneNumber,
        name: item?.contactPersonName,
        email: item?.email,
        whatsappNo: item?.whatsappNo,
        otherNo: item?.otherNo,
        pincode: item?.supplierformdataSet?.[0]?.address
          ?.filter((address) => address?.default)
          ?.map((address) => address.pincode)[0],
        company_name: item?.supplierformdataSet?.[0]?.companyName
      })));
    } else {
      console.error("Error fetching data:", response.error);
    }
  }

  //   const handleKeyDown = (event, setFieldValue) => { 
  //       console.log(event);

  //     if (event.key === 'Enter' && phone !== '') {  
  //       const newOption = { value: phone, label: phone }; 
  //       setFieldValue('Mobile',  newOption ? newOption?.value : null)
  //       handlphoneChange(newOption?.value)
  //       setIsNew(true)
  //       event.preventDefault();
  //     }
  // };
  const handleCreate = (e, setFieldValue) => {
    const newOption = { value: e, label: e };
    setFieldValue('Mobile', newOption ? newOption?.value : null)
    handlphoneChange(newOption?.value)
    setIsNew(true)
    setlinkedCostomerId()

  };

  return (
    <>
      <div className='wrap   container-fluid  '>
        <div className=' enquiryForm  shadow-lg px-4    container-sm container   bg-white '>
          <div className='header'>
            <img src={staanlogo} className='img-thumbnail' alt='staan logo' />
            <h3 className='title  '  >
              {currentConference?.['lable'] ? currentConference?.['lable'] : 'ENQUIRY FORM'}{' '}
            </h3>
            <i
              className='fa-solid fa-house'
              onClick={() => {
                Navigate('/EnquiryDetail')
              }}
            ></i>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={handlesubmit}
            validationSchema={EnquirySchema}>
            {({ errors, touched, isSubmitting, setFieldValue, setErrors, resetForm, values }) => (
              <Form className=''>

                <ToastContainer />
                <div className='row'>
                  {linkedCostomerId && !allowEdit ? <div className='text-end'>
                    <button
                      type='button'
                      className='btn btn-outline-success btn-sm px-3 mx-2'
                      onClick={() => { setWaringModel(true) }}

                    >
                      <i className='fa-solid fa-pen'></i>
                    </button>
                  </div> : ""}

                  <div className='col-12 col-md-6  mt-md-3 staan-input-group'>
                    <Field
                      type='text'
                      name='name'
                      value={name}
                      disabled={allowEdit ? false : !isNew}
                      placeholder=' '
                      autoComplete='off'
                      onChange={e => {
                        handlenameChange(e.target.value)
                        setFieldValue('name', e.target.value)
                      }}

                      className='w-100  staan-form-input'
                    />
                    <label htmlFor='Name' className='pt-1 px-1 mx-2 staan-form-input-label' onClick={() => {
                      console.log(values);
                    }}>Name<span className='text-danger'>*</span></label>
                    <br />
                    {touched.name && errors.name && (<small>{errors.name}</small>)}
                  </div>

                  <div className='col-12   col-md-6    staan-input-group   mt-md-3  '>
                    <Field
                      type='text'
                      name='Hospital'
                      autoComplete='off'
                      value={org}
                      onChange={e => {
                        handlOrgChange(e.target.value)
                        setFieldValue('Hospital', e.target.value)
                      }}
                      placeholder=''
                      className='w-100 staan-form-input'
                    />
                    <label htmlFor='addHospital' className='pt-1 px-1 mx-2 staan-form-input-label'> Hospital/Company<span className='text-danger'>*</span></label>
                    <br />
                    {(touched.Hospital || errors.Hospital) && (
                      <small>{errors.Hospital}</small>
                    )}
                  </div>

                  <div className='col-12  col-md-6  staan-input-group  mt-md-3  '>
                    <Field
                      type='email'
                      name='email'
                      autoComplete='off'
                      value={email}
                      disabled={allowEdit ? false : !isNew}
                      onChange={e => {
                        setFieldValue('email', e.target.value)
                        handlemailChange(e.target.value)
                      }}
                      placeholder=' '
                      className='w-100 staan-form-input'
                    />
                    <label htmlFor='email' className='pt-2 px-1 mx-2 staan-form-input-label'>Email</label>
                    <br />
                    {touched.email && errors.email && (
                      <small>{errors.email}</small>
                    )}
                  </div>

                  {isNew ?
                    <div className='col-12  col-md-6  staan-input-group mt-md-3 '>
                      <Field
                        type='text'
                        name='Mobile'
                        autoComplete='off'
                        value={phone}
                        disabled
                        onChange={e => {
                          setFieldValue('Mobile', e.target.value)
                          handlphoneChange(e.target.value)


                        }}
                        placeholder=' '
                        className='w-100 staan-form-input'
                      />
                      <label htmlFor='MobileNumber' className='pt-1 px-1 mx-2 staan-form-input-label cursor-pointer' >
                        Mobile Number<span className='text-danger'>*</span> <span style={!isNew ? { color: " " } : { color: "Highlight" }}>
                          {isNew ? <MdInput className='fs-5' onClick={
                            () => { setIsNew(!isNew); reset_From(); resetForm(); setlinkedCostomerId(); }} /> : ""}</span></label>
                      <br />
                      {touched.Mobile && errors.Mobile && (
                        <small>{errors.Mobile}</small>
                      )}
                    </div> :
                    <>
                      <div className='col-12  col-md-6  staan-input-group mt-md-3 '>
                        <CreatableSelect
                          onInputChange={(e) => { fetchOptions(e,) }}
                          name="Mobile"
                          isClearable
                          options={contactList}
                          isSearchable={true}
                          value={phone}

                          onCreateOption={(e) => { handleCreate(e, setFieldValue) }}
                          onChange={(option) => {
                            if (option) {
                              setlinkedCostomerId(option?.value)
                              handlenameChange(option?.name)
                              setFieldValue('name', option?.name)
                              setFieldValue("email", option?.email)
                              handlemailChange(option?.email)
                              setFieldValue("alternateMobileNumber", option?.whatsappNo)
                              handlOrgChange(option?.company_name)
                              console.log(option?.company_name);
                              setinitialValues(prev => ({
                                ...prev,
                                Hospital: option?.company_name
                              }));


                              setFieldValue('Hospital', option?.company_name)
                              if (option?.pincode) {
                                setFieldValue('Pincode', option?.pincode)
                                let peremeter = `pincodeString:"${option?.pincode}", pincodeDistinct:true`
                                console.log(peremeter);

                                dispatch(fetchOptionsData(Filiter_address_with_pincode_Query(peremeter), "distri_state"))
                              }
                              setFieldValue('Mobile', option ? option?.value : null)
                              handlphoneChange(option)
                            } else {
                              reset_From()
                              resetForm()
                              setFieldValue('Mobile', null)
                              handlphoneChange("")
                              setAllowEdit(false)
                            }
                          }}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              fontSize: '.8rem',
                              backgroundColor: state.isDisabled ? 'transparent' : provided.backgroundColor,
                              color: state.isDisabled ? '#000' : provided.color,
                              borderColor: "#65e6b4"
                            }),
                            option: (provided) => ({
                              ...provided,
                              fontSize: '.8rem !important',
                            }),
                          }}
                          placeholder="Search Mobile Number..."
                          className='my-3  '
                        />
                        <label htmlFor='MobileNumber' className='pt-1 px-1 mx-2 staan-form-input-label cursor-pointer' >
                          Mobile Number<span className='text-danger'>*</span></label>
                        <br />
                        {touched.Mobile && errors.Mobile && (
                          <small>{errors.Mobile}</small>
                        )}


                      </div>

                    </>
                  }

                  <div className='col-12 col-md-6   staan-input-group mt-md-3 '>
                    <Field
                      type='text'
                      autoComplete='off'
                      name='alternateMobileNumber'
                      placeholder=''
                      disabled={allowEdit ? false : !isNew}
                      className='w-100 mt-md-2  staan-form-input'
                    />
                    <label htmlFor='alternateMobileNumber' className=' px-1 mx-2 staan-form-input-label '>
                      Whatsapp Number
                    </label>
                    <span
                      style={{ fontSize: '.8rem', cursor: "pointer", color: "orange" }}
                      onClick={() => {
                        setFieldValue('alternateMobileNumber', phone)
                      }}>
                      <i className="fa-solid fa-copy me-2"></i>
                      same as mobile number</span>
                    <br />
                    {touched.alternateMobileNumber && errors.alternateMobileNumber && (
                      <small>{errors.alternateMobileNumber}</small>
                    )}
                  </div>

                  <div className='col-12 col-md-6 staan-input-group mt-md-3'>

                    <Field
                      type='text'
                      name='otherNumber'
                      placeholder=' '
                      disabled={allowEdit ? false : !isNew}
                      autoComplete='off'
                      className='w-100 mt-md-2  staan-form-input'
                    />
                    <label htmlFor='otherNumber' className='  px-1 mx-2 staan-form-input-label '>
                      Other Number
                    </label>
                    <br />

                    {touched.otherNumber && errors.otherNumber && (
                      <small>{errors.otherNumber}</small>
                    )}
                  </div>

                  <div className='col-12  col-md-6 staan-input-group'>
                    <CustomSelect
                      setSelectEditValue={pincodeValue}
                      handleInputChange={(e) => {
                        FetchData(e, "pincodeList")
                      }}
                      options={pincodeList}
                      name='Pincode'
                      id='Pincode'
                      handleOnChange={(option) => {
                        setFieldValue('Pincode', option ? option.value : null)
                        handleSelectpincode(option)
                      }}
                    />
                    <label
                      className='staan-form-input-label pt-1 px-1 mx-2'
                      style={{ zIndex: 10000 }}
                    >
                      Pincode<span className='text-danger' >*</span>
                    </label>
                    <br />

                    {touched.Pincode && errors.Pincode && (
                      <small>{errors.Pincode}</small>
                    )}
                  </div>
                  <div className='col-12  col-md-6 staan-input-group'>
                    <Field
                      type='text'
                      name='District'
                      value={districtValue?.district}

                      placeholder=' '
                      className='w-100 staan-form-input'
                      disabled
                    />
                    <label htmlFor='District' className='pt-2 px-1 mx-2 staan-form-input-label'> District</label>
                    <br />


                  </div>

                  <div className='col-12  col-md-6 staan-input-group   '>
                    <Field
                      type='text'
                      name='state'
                      value={statesValue?.stateName}
                      disabled
                      placeholder=' '
                      className='w-100 staan-form-input'
                    />
                    <label htmlFor='state' className='pt-2 px-1 mx-2 staan-form-input-label'> State</label>

                    <br />

                  </div>
                  <div className='col-12 col-md-6   staan-input-group mt-md-3 '>
                    <Field
                      type='text'
                      name='message'
                      placeholder=' '
                      className='w-100 mt-md-2  staan-form-input'
                    />
                    <label
                      htmlFor='Message'
                      className=' staan-form-input-label   px-1 mx-2'>
                      Requirements
                    </label>
                    <br />
                    {touched.message && errors.message && (
                      <small>{errors.message}</small>
                    )}
                  </div>
                  <div className='row icons_of_product  mt-1 '>
                    <Swiper
                      slidesPerView={3}
                      spaceBetween={30}
                      pagination={{
                        clickable: true
                      }}
                      modules={[Pagination]}
                      className='mySwiper custom-swiper'
                    >
                      <SwiperSlide className='SwiperSlideComman_circle'>
                        <label className={`comman_circle   shadow-lg img_1`}>
                          <Field
                            type='checkbox'
                            name='intrested'
                            value='1'
                            hidden
                          />
                          <p className={`product_name flex-nowrap`}>
                            OT<span className='invisible'>#</span>Table
                          </p>
                        </label>
                      </SwiperSlide>
                      <SwiperSlide className='SwiperSlideComman_circle'>
                        <label className={`comman_circle shadow-lg img_2  `}>
                          <Field
                            type='checkbox'
                            name='intrested'
                            value='2'
                            hidden
                          />
                          <p className={`product_name flex-nowrap  `}>
                            OT<span className='invisible'>#</span>Light
                          </p>
                        </label>
                      </SwiperSlide>
                      <SwiperSlide className='SwiperSlideComman_circle'>
                        <label className={`comman_circle shadow-lg img_3 `}>
                          <Field
                            type='checkbox'
                            name='intrested'
                            value='3'
                            hidden
                          />
                          <p className={`product_name flex-nowrap  `}>
                            Anesthesia
                          </p>
                        </label>
                      </SwiperSlide>

                      <SwiperSlide className='SwiperSlideComman_circle'>
                        <label className={`comman_circle shadow-lg img_4  `}>
                          <Field
                            type='checkbox'
                            name='intrested'
                            value='4'
                            hidden
                          />
                          <p className={`product_name flex-nowrap  `}>
                            Critical<span className='invisible'>#</span>Care
                          </p>
                        </label>
                      </SwiperSlide>

                      <SwiperSlide className='SwiperSlideComman_circle'>
                        <label className={`comman_circle shadow-lg img_6 `}>
                          <Field
                            type='checkbox'
                            name='intrested'
                            value='5'
                            hidden
                          />
                          <p className={`product_name flex-nowrap  `}>
                            Surgical{' '}
                          </p>
                        </label>
                      </SwiperSlide>

                      <SwiperSlide className='SwiperSlideComman_circle'>
                        <label className={`comman_circle shadow-lg img_7 `}>
                          <Field
                            type='checkbox'
                            name='intrested'
                            value='6'
                            hidden
                          />
                          <p className={`product_name flex-nowrap `}>
                            All Products
                          </p>
                        </label>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className='submit_button_wrao'>
                  <button
                    type='submit'
                    className='btn shadow-sm  enquiry_submit_button'
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </div>
                <div
                  className='text-end text-secondary fs-6 fw-bold  '
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseExample'
                  role='button'
                  aria-expanded={isCollapseOpen === 'show' ? true : false}
                  aria-controls='collapseExample'
                  onClick={() => {
                    handleCollapse()
                  }}
                >
                  <p className='pb-2 ps-2'> {userName} </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className={`mt-2 collapse ${isCollapseOpen}`} id='collapseExample'>
          <div className='card card-body'>
            <div className='row'>
              <div className='col-md-5 col-5'>
                <input
                  type='file'
                  className='form-control'
                  id='fileInput'
                  onChange={handleFileChange}
                />
              </div>
              <div className='col-md-3 col-4'>
                <button
                  className='btn btn-outline-success'
                  disabled={!selectedFile}
                  onClick={() => extractInfoFromImage()}
                >
                  Get Data
                </button>
              </div>
              <div className='col-3 text-end pe-1 col-md-2 mt-2 ps-0'>
                {/* <input
                                        type="file" 
                                        name='profile_image'
                                        onChange={handleImport}
                                        ref={hiddenFileInputImg}
                                        style={{ display: "none" }} // Make the file input element invisible
                          />
                          <i className="fa-solid fa-camera fa-xl pe-3" onClick={handleClick} ></i> */}
                <i
                  className='fa-regular fa-circle-xmark fa-xl'
                  onClick={() => {
                    setIsCollapseOpen('')
                  }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='textEdit ' hidden={textEditer_}>
        <div className='row text-end pe-3'>
          <i className='fa-solid fa-circle-xmark ' onClick={handletextedtier}></i>
        </div>
        <TextEditer />
      </div> */}
      {waringModel && (

        <WaringModel
          state={waringModel}
          setstate={setWaringModel}
          title={'Confirm'}
          text={'Do you want to alter contact?'}
          Confirmtion={value => {
            confirmationToEdit(value)
          }}
        />
      )}
    </>
  )
}

export default EnquiryForm
