import React, { useContext, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import DataContext from '../../context/ItemMasterContext';
import TableView from '../../Packages/Custom/DataTable';
import CurrencyExchangesFrom from './Model/CurrencyExchangesFrom';

const CurrencyExchangesList = () => { 
    const {   handleCurrencyExchangeShow} =   useContext(DataContext)
    const [isLoad, setLoad] = useState(false)
    const [editCurrencyExchange, setEditCurrencyExchange] = useState()
 
 
    const CustomAddModalComponent = () => {
      handleCurrencyExchangeShow()
    }
    
    const CustomViewModalComponent =(data)=>{
      handleCurrencyExchangeShow()
      setEditCurrencyExchange(data)
    }
 
    const headers = [
      {
        header: 'Currency',
        field: 'Currency',
        label: 'name',
        flex: 10,
        query: 'CurrencyExchangesOptionsForTable',
        inputType: 'text',
        renderComponent: CustomViewModalComponent,
      },
      {
        header: 'Rate',
        field: 'rate',
        flex: 10, 
        inputType: 'number',
      },
      {
        header: 'Date',
        field: 'date', 
        flex: 10,
        isDateField: true,
        inputType: 'text', 
      },

    ]
  
  return (
     <>
      <ToastContainer/>
      <div className=' index_page_aliment_warp'>
        <div className='index_page_aliment_sidebar'>
            <SideNavbar />
        </div>
        <div className='index_page_aliment_table'>
            <TableView
                headers={headers}
                query = {'CurrencyExchangeTableQuery'}
                addComponent = {CustomAddModalComponent}
                loadComponent = {isLoad}
                titleName = {'Currency Exchange'}
                permission={"Save_Currency_Exchange"}
                heightAdjust = {'40rem'} 
                customFilter={true}
            />
        </div>
      </div>
      

    <CurrencyExchangesFrom
    editCurrencyExchange={editCurrencyExchange}
    setEditCurrencyExchange ={setEditCurrencyExchange}
    setLoad = {()=>{setLoad(!isLoad)}}/>
    
    </>
  )
}

export default CurrencyExchangesList