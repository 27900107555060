import { AgGridReact } from 'ag-grid-react';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineCaretLeft, AiOutlineCaretRight, AiOutlineShoppingCart } from 'react-icons/ai';
import { TiCancel } from 'react-icons/ti';
import { Oval } from 'react-loader-spinner';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import Can from '../../../src/components/authorizationComponents/Can';
import { DepartmentQuery, ItemMasterPurchaseOrderFetchQuery, SupplierOptionPerameter, storeFetchQuery } from '../../GraphQLQueries/ItemmasterQuery';
import { OtherExpensesDeleteMutations, OtherExpensesPurchaseCreateMutations, PurchaseItemCreateMutations, PurchaseItemDeleteMutations, PurchaseOrderCancleMutations, PurchaseOrderCreateMutations, PurchaseOrderDeleteMutations, PurchaseOrderTimeLine, TermsConditionsFetchQuery } from '../../GraphQLQueries/PurchaseItemMutations';
import { itemmasterPruchase, otherExpensesAccountFetchQuery, purchasefetchdata } from '../../GraphQLQueries/PurchaseItemQueries';
import StaanTab from '../../Packages/StaanTables1.2';
import { fetchOptionsData } from '../../ReduxState/Action/itemMasterAction';
import axiosInstance from '../../api/axoiss';
import BallTriangleLoading from "../../components//loading/BallTriangle";
import { countries_list } from '../../components/CountryWithStates';
import WaringModel from '../../components/Warings/WaringModel';
import WaringModelWithCopy from '../../components/Warings/WaringModelWithCopy';
import DeleteConformation from '../../components/deleteConformations.js/DeleteConformation';
import showErrorToast from '../../components/notifications/react-toastify/toast';
import TreeComponent from '../../components/react-d3-tree/poHistory';
import DataContext from '../../context/ItemMasterContext';
import { DateFormate, ShowErrorNoties, TimeFormate, addSno, calculateTax, checkChangesInputs, gettax, onCellDoubleClicked, removeEmptyValueInObject, roundToNearest10, textEditormodules } from '../../utils/Utils';
import { PurchaseSchema } from '../../validations/PurchaseValidations';
import Grn from '../GRN/models/Grn';
import SupplierFrom from '../ItemMaster/model/SupplierFrom';
import PurchaseRetunForm from '../PurchaseReturnChallan/Models/PurchaseRetunForm';
import QIR from '../QualityInspectionReport/Model/QIR';
import PurchaseItemModel from './Models/PurchaseItemModel';
import CustomSelect from '../../components/ReactSelect/reactSelect';

const CostOutOfRangeModel = ({ showCostOutOfRangeModel, setShowCostOutOfRangeMode, varifiedFalselist }) => {
  return (
    <>
      <Modal show={showCostOutOfRangeModel} onHide={() => { setShowCostOutOfRangeMode(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Item Cost is out of Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {varifiedFalselist.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  )
}

const PurchaseOrderModel = ({ purchaseOderIsShowing, setpurchaseOderIsShowing, setLoad, purchaseOrderEdit, setPurchaseOrderEdit }) => {
  const dispatch = useDispatch();
  const [isGin, setIsGin] = useState(true)
  const OptionsDataList = useSelector(state => state?.itemGroup?.data);
  const [initialPurchase, setInitialPurchase] = useState({
    id: "",
    purchaseOrder_no: "",
    states: "",
    supplier_Number: "",
    supplier_name: "",
    credit_period: "",
    payment_terms: "",
    supplier_ref: "",
    due_date: "",
    amend: "",
    receiving_store_id: "",
    scrap_reject_store_id: "",
    remarks: "",
    department_id: "",
    gstin_type: "",
    gstin: "",
    contact: "",
    address: "",
    state: "",
    country: "",
    childCount: 0,
    place_of_supply: "",
    createdBy: "",
    TermsOptions: "",
    termsConditions: ""
  })
  const [editGrn, setEditGrn] = useState(null)
  const { userId, handltoastDeleteConfomationShow, handleSupplierShow, supplierSelect } = useContext(DataContext)
  const [states, setStates] = useState(null)
  const [purchaseItemShow, setPurchaseItemShow] = useState(false)
  const [supplierOptionList, setSupplierOptionList] = useState([])
  const [supplierSelected, setSupplierSelected] = useState(null)
  const [supplierOptionNoList, setSupplierOptionNoList] = useState([])
  const [supplierNoSelected, setSupplierNoSelected] = useState(null)
  const [billingAddressSelected, setBillingAddressSelected] = useState(null)
  const [contactSelected, setcontactSelected] = useState(null)
  const [storelist, setStorelist] = useState([])
  const [loadingModels, setLoadingModels] = useState(false);
  const [receivingStoreSelecte, setReceivingStoreSelecte] = useState(null)
  const [scrapRejectStoreSelecte, setscrapRejectStoreSelecte] = useState(null)
  const [departmentList, setDepartmentList] = useState([])
  const [departmentSelecte, setDepartmentSelecte] = useState(null)
  const [itemMasterList, setItemMasterList] = useState([])
  const [itemMasterNameList, setItemMasterNameList] = useState([])
  const [purchaseItemDetails, setPurchaseItemDetails] = useState([])
  const [purchaseItemDetailsEdit, setPurchaseItemDetailsEdit] = useState(null)
  const [deleteData, setdeleteData] = useState({ Name: "", id: "" })
  const [deleteOtherExpensesData, setdeleteOtherExpensesData] = useState({ Name: "", id: "" })
  const [tCList, setTCList] = useState([])
  const [termsOptions, setTermsOptions] = useState(null)
  const [termsConditions, setTermsConditions] = useState(null)
  const [otherExpenceAccountList, setOtherExpenceAccountList] = useState([])
  const [otherExpenceAccountSelected, setotherExpenceAccountSelected] = useState(null)
  const [otherExpenceList, setOtherExpenceList] = useState([]);
  const [totalAmountBeforeTax, setTotalAmountBeforeTax] = useState(0.00)
  const [fixedFinalAmount, setFinalAmount] = useState(0.00)
  const [totalTax, setTotalTax] = useState(0.00)
  const [roundof, setRoundof] = useState(0.00)
  const [sgstTax, setSgstTax] = useState([])
  const [cgstTax, setCgstTax] = useState([])
  const [igstTax, setIgstTax] = useState([])
  const [purchaseOrderEditData, setPurchaseOrderEditData] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)
  const [lastModified, setLastModified] = useState({ date: "", time: "" })
  const [purchaseOrderNo, setPurchaseOrderNo] = useState(null)
  const [statesList, setStatesList] = useState([])
  const [statesSelected, setStatesSelected] = useState(null)
  const [showTaxModel, setShowTaxModel] = useState(false)
  const [edit, setEdit] = useState(true)
  const [waringToCancle, setWaringToCancle] = useState(false)
  const [waringToDelete, setWaringToDelete] = useState(false)
  const [showCostOutOfRangeModel, setShowCostOutOfRangeModel] = useState(false)
  const [varifiedFalselist, setVarifiedFalselist] = useState([])
  const [amend, setAmend] = useState(false)
  const [parentOrder, setParentOrder] = useState(null)
  const [versionList, setVersionList] = useState([])
  const [currentVersionId, setCurrentVersionId] = useState(null)
  const [isFirst, setIsFirst] = useState(true);
  const [isLast, setIsLast] = useState(versionList.length <= 1);
  const [active, setActive] = useState(true)
  const [isGrnShowing, setIsGrnShowing] = useState(null)
  const [duplicate, setDuplicate] = useState()
  const [loading, setLoading] = useState(false)
  const [initialEditData, setInitialEditData] = useState({})
  const [allowPurchaseRetun, setAllowPurchaseRetun] = useState(false)
  const [purchaseRetun, setPurchaseRetun] = useState(false)
  let currentValues = {};
  let fieldvalue;

  // loading
  const LoadingModel = () => {
    return (
      <>
        <Modal show={loadingModels} backdrop='static' keyboard={false} centered>
          <Modal.Body className=''>
            <div className='d-flex justify-content-center   '>
              <Oval
                visible={true}
                height='80'
                width='80'
                color='#4fa94d'
                ariaLabel='oval-loading'
                wrapperStyle={{}}
                wrapperClass=''
              />
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }
  // Tax Model 
  const TaxModel = () => {

    return (
      <>
        <Modal show={showTaxModel} onHide={() => { setShowTaxModel(false) }}>
          <Modal.Header closeButton>
            <Modal.Title>Tax</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row mx-1 '>
              <div className=''>
                {igstTax
                  ? Object.entries(igstTax).map(([key, value]) => (
                    <div className='row'>
                      <div key={key} className='col-5'>
                        {`Igst @ ${key}%`}
                      </div>
                      <div className='col-5  '>{` : ₹  ${value}`}</div>
                    </div>
                  ))
                  : ''}
              </div>
              <div className=''>
                {sgstTax
                  ? Object.entries(sgstTax).map(([key, value]) => (
                    <div className='row  '>
                      <div key={key} className='col-5'>
                        {`sgst @ ${key}%`}
                      </div>
                      <div className='col-5 '>{` : ₹  ${value}`}</div>
                    </div>
                  ))
                  : ''}
              </div>
              <div className=''>
                {cgstTax
                  ? Object.entries(cgstTax).map(([key, value]) => (
                    <div className='row'>
                      <div key={key} className='col-5'>
                        {`cgst @ ${key}%`}
                      </div>
                      <div className='col-5 '>{` : ₹  ${value}`}</div>
                    </div>
                  ))
                  : ''}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    )
  }
  // to fetch edit data pass id
  useEffect(() => {
    if (purchaseOrderEdit?.id) {
      let peremeter = `id:${purchaseOrderEdit?.id}`
      dispatch(fetchOptionsData(purchasefetchdata(peremeter), "purchaseEdit"))
    }
  }, [purchaseOrderEdit])

  useEffect(() => {
    if (isGrnShowing === false && purchaseOrderEdit?.id) {
      setPurchaseOrderEdit({ id: purchaseOrderEdit?.id })
    }
  }, [isGrnShowing])

  // set edit data
  useEffect(() => {
    if (purchaseOrderEditData !== null) {

      setLoadingModels(true)
      let purchase_edit = purchaseOrderEditData?.[0]
      initialPurchase.purchaseOrder_no = purchase_edit?.purchaseOrderNo?.id
      setParentOrder(purchase_edit?.id)
      setCurrentVersionId(purchase_edit?.id)
      setActive(purchase_edit.active)
      setInitialEditData({
        "supplier_Number": purchase_edit?.supplierId?.id,
        "supplier_name": purchase_edit?.supplierId?.id,
        "credit_period": purchase_edit?.creditPeriod,
        "due_date": purchase_edit?.dueDate,
        "supplier_ref": purchase_edit?.supplierRef,
        "payment_terms": purchase_edit?.paymentTerms,
        "receiving_store_id": purchase_edit?.receivingStoreId?.id,
        "scrap_reject_store_id": purchase_edit?.scrapRejectStoreId?.id,
        "department_id": purchase_edit?.department?.id,
        "place_of_supply": purchase_edit?.placeOfSupply,
        "termsConditions": purchase_edit?.termsConditionsText,
        "netAmount": Number(purchase_edit?.netAmount)
      })
      initialPurchase.states = purchase_edit?.status
      initialPurchase.po_no = purchase_edit?.purchaseOrderNo?.linkedModelId
      initialPurchase.po_Date = purchase_edit?.createdAt
      initialPurchase.id = purchase_edit?.id
      initialPurchase.childCount = purchase_edit?.childCount ? purchase_edit?.childCount : 0
      initialPurchase.amend = purchase_edit?.amend
      initialPurchase.supplier_Number = purchase_edit?.supplierId?.id
      initialPurchase.supplier_name = purchase_edit?.supplierId?.id
      initialPurchase.Supplier_objects = purchase_edit?.supplierId
      initialPurchase.address = purchase_edit?.address?.id
      initialPurchase.address_objects = purchase_edit?.address
      initialPurchase.credit_period = purchase_edit?.creditPeriod
      initialPurchase.contact = purchase_edit?.contact?.id
      initialPurchase.country = purchase_edit?.country
      initialPurchase.due_date = purchase_edit?.dueDate
      initialPurchase.supplier_ref = purchase_edit?.supplierRef
      initialPurchase.payment_terms = purchase_edit?.paymentTerms
      initialPurchase.receiving_store_id = purchase_edit?.receivingStoreId?.id
      initialPurchase.scrap_reject_store_id = purchase_edit?.scrapRejectStoreId?.id
      initialPurchase.department_id = purchase_edit?.department?.id
      initialPurchase.remarks = purchase_edit?.remarks
      initialPurchase.gstin_type = purchase_edit?.gstinType
      initialPurchase.gstin = purchase_edit?.gstin
      initialPurchase.state = purchase_edit?.state
      initialPurchase.place_of_supply = purchase_edit?.placeOfSupply
      initialPurchase.createdBy = purchase_edit?.createdBy?.id
      initialPurchase.TermsOptions = purchase_edit?.termsConditions?.id
      initialPurchase.termsConditions = purchase_edit?.termsConditionsText
      initialPurchase.netAmount = purchase_edit?.netAmount
      initialPurchase.grnList = purchase_edit?.goodsreceiptnoteSet
      setPurchaseOrderNo(purchase_edit?.purchaseOrderNo?.linkedModelId)
      setStatesSelected({ values: initialPurchase.place_of_supply, label: initialPurchase.place_of_supply })
      initialPurchase.sgst = purchase_edit?.sgst
      initialPurchase.cgst = purchase_edit?.cgst
      initialPurchase.igst = purchase_edit?.igst
      setSgstTax(initialPurchase.sgst)
      setCgstTax(initialPurchase.cgst)
      setIgstTax(initialPurchase.igst)
      if (initialPurchase.createdBy) {
        setCreatedBy(purchase_edit?.createdBy?.username)
      }
      if (purchase_edit?.updatedAt) {
        setLastModified({ date: DateFormate(purchase_edit?.updatedAt), time: TimeFormate(purchase_edit?.updatedAt) })
      }
      if (initialPurchase?.TermsOptions) {
        setTermsOptions({ value: purchase_edit?.termsConditions?.id, label: purchase_edit?.termsConditions?.name })
      }
      if (initialPurchase.termsConditions) {
        setTermsConditions(initialPurchase?.termsConditions)
      }
      if (initialPurchase.address) {
        setSelectType({ value: purchase_edit?.address?.id, label: purchase_edit?.address?.addressType, objects: purchase_edit?.address })
        setBillingAddressSelected(purchase_edit?.address)
      }
      if (initialPurchase.supplier_Number) {
        setSupplierNoSelected({ value: purchase_edit?.supplierId?.id, label: purchase_edit?.supplierId?.supplierNo })
        setSupplierSelected({ value: purchase_edit?.supplierId?.id, label: purchase_edit?.supplierId?.companyName })
      }
      if (initialPurchase.contact) {
        setcontactSelected({
          value: purchase_edit?.contact?.id, label: purchase_edit?.contact?.contactPersonName,
          email: purchase_edit?.contact?.email, Phone: purchase_edit?.contact?.phoneNumber
        })
      }
      if (initialPurchase.receiving_store_id) {
        setReceivingStoreSelecte({ value: purchase_edit?.receivingStoreId?.id, label: purchase_edit?.receivingStoreId?.storeName })
      }
      if (initialPurchase.scrap_reject_store_id) {
        setscrapRejectStoreSelecte({ value: purchase_edit?.scrapRejectStoreId?.id, label: purchase_edit?.scrapRejectStoreId?.storeName })
      }
      if (initialPurchase.department_id) {
        setDepartmentSelecte({ value: purchase_edit?.department?.id, label: purchase_edit?.department?.name })
      }
      if (purchase_edit?.itemDetails?.length > 0) {
        let itemDetail = addSno(purchase_edit?.itemDetails)
        itemDetail.forEach((item) => {
          if (Number(item?.poQty) === Number(item?.acceptedQty)) {
            setAllowPurchaseRetun(true)
          } else {
            setAllowPurchaseRetun(false)
            return ""
          }
        })
        setPurchaseItemDetails(itemDetail)
      }
      purchase_edit?.otherExpenses.forEach((item => {
        let otherExpenceAccount = {
          id: item?.id,
          account: { value: item?.otherExpensesId?.id, label: item?.otherExpensesId?.account?.accountsName },
          tax: item?.otherExpensesId?.HSN?.gstRates?.rate,
          amount: item?.amount,
          createdBy: item?.createdBy?.id,
          igst: item?.igst,
          sgst: item?.sgst,
          cgst: item?.cgst,
        }

        setOtherExpenceList(prev => {
          // Check if the item exists in the previous list
          const index = prev.findIndex(exp => exp.id === otherExpenceAccount.id);
          if (index !== -1) {
            // Update the existing item
            return prev.map((exp, idx) => (idx === index ? otherExpenceAccount : exp));
          } else {
            // Add the new item to the list
            return [...prev, otherExpenceAccount];
          }
        });
      }))
      setStates(purchase_edit.status)
      setEdit(true)
      setLoadingModels(false)
    } else {
      setEdit(false)
    }
  }, [purchaseOrderEditData])

  useEffect(() => {
    if (supplierSelect) {
      setSupplierNoSelected({ value: supplierSelect.id, label: supplierSelect.supplier_no })
      setSupplierSelected({ value: supplierSelect.id, label: supplierSelect.lable })
      handlechangesuplier(supplierSelect,)
    }
  }, [supplierSelect])

  const forWord = () => {
    for (let x in versionList) {
      if (Number(currentVersionId) === Number(versionList[x])) {
        let nextIndex = Number(x) + 1;
        if (nextIndex < versionList.length) {
          // close()
          setOtherExpenceList([])
          setCurrentVersionId(versionList[nextIndex]);
          setPurchaseOrderEdit({ id: versionList[nextIndex] })
          setIsFirst(false);
          if (nextIndex === versionList.length - 1) {
            setIsLast(true);
          } else {
            setIsLast(false);
          }
          console.log(versionList[nextIndex]);
        }
        break;
      }
    }
  };

  const backWord = () => {
    for (let x in versionList) {
      if (Number(currentVersionId) === Number(versionList[x])) {
        let prevIndex = Number(x) - 1;
        if (prevIndex >= 0) {
          // close()
          setOtherExpenceList([])
          setCurrentVersionId(versionList[prevIndex]);
          setPurchaseOrderEdit({ id: versionList[prevIndex] })
          setIsLast(false);
          if (prevIndex === 0) {
            setIsFirst(true);
          } else {
            setIsFirst(false);
          }
          console.log(versionList[prevIndex]);
        }
        break;
      }
    }
  };

  // define the column for other Expenses
  const OtherExpensesColumnDefs = [
    {
      header: 'Account',
      fieldname: 'account',
      width: '31',
      object: "label",
    },
    {
      header: 'Tax',
      fieldname: 'tax',
      width: '11',

    },
    {
      header: "Amount",
      fieldname: 'amount',
      width: '12'
    }
  ]

  const costomButtom = ({ value, data }) => {
    // Assign the value to the higher-scoped itemId
    const openEditFrom = () => {
      setPurchaseItemDetailsEdit(data)
      setPurchaseItemShow(true)
    }
    const handledeleteConfomation = () => {
      if (data?.id) {
        setdeleteData({ Name: data?.itemMasterId?.itemName, id: data?.id })
        handltoastDeleteConfomationShow();
      } else {
        setPurchaseItemDetails(prev => {
          return prev.filter(item => item.itemMasterId?.itemName !== data?.itemMasterId?.itemName);
        });
      }

    }
    const showStock = () => {
      // handleShowStockStatementModal(data) 
    }
    return (
      <>
        <button
          type='button'
          className='btn btn-outline-success btn-sm px-3 mx-2'
          onClick={openEditFrom}

        >
          <i className='fa-solid fa-pen'></i>
        </button>
        <button
          type='button'
          className='btn btn-outline-danger btn-sm px-3 mx-2'
          onMouseDown={handledeleteConfomation}
        >
          <i className='fa-solid fa-trash '></i>
        </button>
        <AiOutlineShoppingCart
          style={{ cursor: "pointer" }}
          className='fs-3'
          onClick={() => {
            showStock()
          }}
        />
      </>
    )
  }
  // change color of column it is verifiy or not 
  // const partcodecellrender = params => { 
  //   return (
  //     <>
  //       <div style={{
  //         color: params.data.verifiedAmount ? "green": "red",
  //       }}>
  //           {params.data.itemMasterId.itemPartCode}
  //       </div> 
  //     </>
  //   )
  // }

  // define the column for item details
  const columns = [
    {
      headerName: 'S.No',
      field: 'sNo',
      flex: .7,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Part Code',
      field: 'itemMasterId.itemPartCode',
      flex: 2,
      // cellRenderer: partcodecellrender,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Part Name',
      field: 'itemMasterId.itemName',
      flex: 2,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'HSN',
      field: 'category',
      flex: 2,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'PO Qty',
      field: 'poQty',
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'UOM',
      field: 'uom.name',
      flex: 1.5,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Rate',
      field: 'rate',
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Discount',
      field: 'discount',
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Tax',
      field: 'tax',
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Inward',
      field: 'received',
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Accepted',
      field: 'acceptedQty',
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Action',
      hide: !active || (purchaseOrderEdit && edit),
      cellRenderer: costomButtom,
      flex: 2,
    },
  ]
  const defaultColDef = {
    filter: true, floatingFilter: true,
    resizable: true,
  }

  // return null ; call prefetch Options
  const prefetchOptions = useCallback(() => {
    dispatch(fetchOptionsData(storeFetchQuery(), "storeOptions"));
    dispatch(fetchOptionsData(otherExpensesAccountFetchQuery(), "otherExpensesOptions"))

  }, [dispatch]);

  useEffect(() => {
    prefetchOptions();
  }, [prefetchOptions])

  // handle submit other expenses
  const handleSubmitOtherExpenses = async (values, { setSubmitting, resetForm, setErrors, setFieldError }, toggle, setOpenRowIndex) => {

    const saveValue = {
      id: values.id ? Number(values.id) : "",
      otherExpensesId: Number(values['account']),
      amount: Number(values['amount']),
      modifiedBy: values.id ? Number(userId) : "",
      createdBy: values.id ? Number(values['createdBy']) : Number(userId)
    }
    let allow_null = ["id", "modifiedBy"]
    let cleanValue;
    if (values['tax']) {
      let save_value_with_tax = gettax(saveValue, values['tax'], billingAddressSelected?.state)
      cleanValue = removeEmptyValueInObject(save_value_with_tax, allow_null)
    } else {
      cleanValue = removeEmptyValueInObject(saveValue, allow_null)
    }
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: OtherExpensesPurchaseCreateMutations(cleanValue) });

      const responseData = response?.['data']?.['data']?.['otherExpensesPurchaseOrderCreateMutation']
      if (responseData.success) {
        let data = responseData['otherExpensesPurchaseOrderInstance']
        let otherExpenceAccount = {
          id: data.id,
          account: { value: data?.otherExpensesId?.id, label: data?.otherExpensesId?.account?.accountsName },
          tax: data?.otherExpensesId?.HSN?.gstRates?.rate,
          amount: data?.amount,
          createdBy: data?.createdBy?.id,
          igst: data?.igst,
          sgst: data?.sgst,
          cgst: data?.cgst,
        }
        setotherExpenceAccountSelected(null)
        if (saveValue?.id) {
          setOtherExpenceList((prevData) => {
            return prevData.map((item) => {
              return item.id === data.id ? otherExpenceAccount : item;
            });
          });
        } else {
          setOtherExpenceList(prev => [...prev, otherExpenceAccount])
        }
        resetForm()
        toggle()
        setOpenRowIndex()
      } else {
        let errorData = ShowErrorNoties(responseData.errors)
        toast.error(errorData, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error) {
      let errorData = ShowErrorNoties(error)
      toast.error(errorData, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

    }

  }

  // OtherExpenses input sections 
  const OtherExpensesInputFrom = (toggle, setOpenRowIndex, rowdata) => {
    const initialValues = {
      id: "",
      account: "",
      tax: "",
      amount: "",
      createdBy: ""
    }
    useEffect(() => {
      if (rowdata.account?.value !== undefined && rowdata.account?.value !== "") {
        initialValues.id = rowdata.id
        initialValues.amount = rowdata.amount
        initialValues.tax = rowdata.tax
        initialValues.account = rowdata?.account?.value
        initialValues.createdBy = rowdata.createdBy ? rowdata.createdBy : userId
        setotherExpenceAccountSelected(rowdata?.account)
      }
    }, [rowdata])
    return (
      <>
        <ToastContainer />
        <Formik initialValues={initialValues}
          // validationSchema={AlternateSchema}
          onSubmit={(values, formikProps) => handleSubmitOtherExpenses(values, formikProps, toggle, setOpenRowIndex)}
        >
          {({ errors, submitForm, touched, setFieldValue, values }) => (
            <>
              <Form>
                <div className="row">
                  <div className="col-6 staan-input-group">
                    <CustomSelect
                      setSelectEditValue={otherExpenceAccountSelected}
                      options={otherExpenceAccountList}
                      name='account'
                      id='account'
                      handleOnChange={(option) => {
                        setFieldValue("account", option?.value ? option?.value : null)
                        console.log(option);
                        setotherExpenceAccountSelected({ value: option?.value, label: option?.label },)
                        setFieldValue("tax", option?.tax ? option.tax : null)
                      }}
                    />
                    <label className='staan-form-input-label pt-1 px-1 mx-2'  >Account </label>
                    {touched.account && errors.account && <small>{errors.account}</small>}
                  </div>
                  <div className="col-2 staan-input-group">
                    <Field type='text' name='tax' disabled className='w-100 staan-form-input' />
                    <label htmlFor="tax" className='staan-form-input-label pt-1 px-1 mx-2'>Tax</label>
                    {touched.tax && errors.tax && <small>{errors.tax}</small>}
                  </div>
                  <div className="col-2 staan-input-group">
                    <Field type='text' name='amount' className='w-100 staan-form-input' />
                    <label htmlFor="amount" className='staan-form-input-label pt-1 px-1 mx-2'>Amount</label>
                    {touched.amount && errors.amount && <small>{errors.amount}</small>}
                  </div>
                  <div className='col-1 mt-3'>
                    <button type="button" className="save-button" onMouseDown={() => { submitForm() }}  >
                      <i className="fa-regular fa-floppy-disk" ></i>
                    </button>
                  </div>
                  <div className='col-1 mt-3'>
                    <button type="button" className="delete-button" onMouseDown={(event) => {
                      event.preventDefault();
                      getDeleteRowId(rowdata);
                    }}>
                      <i className="fa-solid fa-trash"></i>
                    </button >
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>


      </>
    )

  }

  function resetdata(params) {
    setAmend(false)
    setParentOrder(null)
    setdeleteData({ Name: "", id: "" })
    setSelectType("")
    initialPurchase['supplier_Number'] = ""
    initialPurchase['supplier_name'] = " "
    initialPurchase['credit_period'] = ""
    initialPurchase['payment_terms'] = " "
    initialPurchase["supplier_ref"] = ""
    initialPurchase['due_date'] = ""
    initialPurchase['receiving_store_id'] = ''
    initialPurchase["scrapRejectStoreSelecte"] = ""
    initialPurchase['remarks'] = ""
    initialPurchase['department_id'] = ""
    initialPurchase["gstin_type"] = ""
    initialPurchase["gstin"] = ""
    initialPurchase['contact'] = ""
    initialPurchase['address'] = ""
    initialPurchase['state'] = ""
    initialPurchase['country'] = ""
    initialPurchase['place_of_supply'] = ""
    initialPurchase['id'] = ""
    initialPurchase['TermsOptions'] = ""
    initialPurchase['termsConditions'] = ""
    initialPurchase['createdBy'] = ""
    initialPurchase['po_no'] = ""
    initialPurchase['po_Date'] = ""

    setCreatedBy(null)
    setSupplierSelected(null)
    setSupplierNoSelected(null)
    setcontactSelected(null)
    setBillingAddressSelected(null)
    setscrapRejectStoreSelecte(null)
    setReceivingStoreSelecte(null)
    setDepartmentSelecte(null)
    setPurchaseItemDetails(null)
    setPurchaseItemDetails([])
    setRoundof(0.00)
    setTotalTax(0.00)
    setFinalAmount(0.00)
    setTotalAmountBeforeTax(0.00)
    setOtherExpenceList([])
    setTermsConditions(null)
    setTermsOptions(null)
    setStates(null)
    setPurchaseOrderNo(null)
    setPurchaseOrderEditData(null)
    setPurchaseOrderEdit(null)
    setLastModified(null)

  }

  // return null; close model and clear the value
  const [waringToCloseModel, setWaringToCloseModel] = useState(false)
  function close() {
    let currentValues_ = {
      "credit_period": currentValues['credit_period'],
      "department_id": currentValues['department_id'],
      "due_date": currentValues['due_date'],
      "payment_terms": currentValues['payment_terms'],
      "receiving_store_id": currentValues['receiving_store_id'],
      "scrap_reject_store_id": currentValues['scrap_reject_store_id'],
      "supplier_Number": currentValues['supplier_Number'],
      "supplier_name": currentValues['supplier_name'],
      "supplier_ref": currentValues['supplier_ref'],
      "termsConditions": currentValues['termsConditions'],
      "place_of_supply": currentValues['place_of_supply'],
      "netAmount": Number(currentValues['netAmount'])
    }
    let isDuplicate = checkChangesInputs([initialEditData, currentValues_])


    if (!isDuplicate) {
      resetdata()
      setpurchaseOderIsShowing(false)
    } else {
      setWaringToCloseModel(true)
    }
  }
  function premisionToCloseData(params) {
    if (params) {
      resetdata()
      setpurchaseOderIsShowing(false)
    }


  }

  function getStates(Optionvalue) {
    const regionsList = countries_list.find(
      Country => Country.countryName === Optionvalue
    )?.regions
    setStatesList(regionsList?.map((item) => ({ values: item.name, label: item.name })))
  }

  // retun object ; save Purchase Item
  async function SavePurchaseitem(row) {
    let rate = row?.rate;
    let qty = row?.poQty;
    let save_data = {
      id: "",
      itemMasterId: row?.itemMasterId?.id ? Number(row?.itemMasterId?.id) : "",
      category: row?.category,
      poQty: row?.poQty ? (row?.poQty) : "",
      uom: row?.uom?.id ? Number(row?.uom?.id) : "",
      rate: row?.rate ? Number(row?.rate) : "",
      tax: row?.tax ? Number(row?.tax) : "",
      verifiedAmount: row?.verifiedAmount,
      received: row?.received,
      amount: rate * qty,
      discount: "0%",
      createdBy: Number(userId),
      modifiedBy: ""
    }
    let save_value_with_tax = gettax(save_data, save_data['tax'], billingAddressSelected?.state)
    let allow_null = ['id', "modifiedBy", "discount"]
    let clear_data = removeEmptyValueInObject(save_value_with_tax, allow_null)
    try {
      const respones = await axiosInstance.post("/itemmaster/graphql", { query: PurchaseItemCreateMutations(clear_data) })
      const responseData = respones.data.data.purchaseOrderItemDetailsCreateMutation
      if (responseData.success) {
        return responseData?.purchaseOrderItemDetailsInstance
      } else {
        let error = responseData.errors
        let errorData = ShowErrorNoties(error)
        toast.error(errorData, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error) {
      console.log(error);
    }

  }

  // retun object ; Save Other Expence'
  async function SaveOtherExpence(row) {
    const saveValue = {
      id: row.id ? Number(row.id) : "",
      otherExpensesId: Number(row?.['account']?.['value']),
      amount: Number(row?.['amount']),
      modifiedBy: row?.id ? Number(userId) : "",
      createdBy: Number(row?.['createdBy']) ? Number(row?.['createdBy']) : Number(userId)
    }
    let allow_null = ["id", "modifiedBy"]
    let cleanValue;
    if (row?.['tax']) {
      let save_value_with_tax = gettax(saveValue, row?.['tax'], billingAddressSelected?.state)
      cleanValue = removeEmptyValueInObject(save_value_with_tax, allow_null)
    } else {
      cleanValue = removeEmptyValueInObject(saveValue, allow_null)
    }
    const response = await axiosInstance.post(`/itemmaster/graphql`, { query: OtherExpensesPurchaseCreateMutations(cleanValue) });
    const responseData = response?.['data']?.['data']?.['otherExpensesPurchaseOrderCreateMutation']
    if (responseData.success) {
      let data = responseData['otherExpensesPurchaseOrderInstance']
      let otherExpenceAccount = {
        id: data.id,
        account: { value: data?.otherExpensesId?.id, label: data?.otherExpensesId?.account?.accountsName },
        tax: data?.otherExpensesId?.HSN?.gstRates?.rate,
        amount: data?.amount,
        createdBy: data?.createdBy?.id,
        igst: data?.igst,
        sgst: data?.sgst,
        cgst: data?.cgst,
      }
      setotherExpenceAccountSelected(null)
      return otherExpenceAccount
    } else {
      let errorData = ShowErrorNoties(responseData.errors)
      toast.error(errorData, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  }


  // return null; save 
  const handleSubmit = async (values, { resetForm, setErrors }) => {
    let itemDetails;
    let otherExpences;
    if (amend || duplicate) {
      const promises = purchaseItemDetails?.map(element => SavePurchaseitem(element));
      // Wait for all promises to resolve
      itemDetails = await Promise.all(promises)
      itemDetails = addSno(itemDetails)
      setPurchaseItemDetails(itemDetails)
      itemDetails = itemDetails?.map(item => item?.id)
    } else {
      itemDetails = purchaseItemDetails?.map(item => item?.id)
    }
    if (amend && (otherExpenceList?.length > 0 || duplicate)) {
      const promises = otherExpenceList?.map(element => SaveOtherExpence(element));
      otherExpences = await Promise.all(promises);
      setOtherExpenceList(otherExpences)
      otherExpences = otherExpences?.map(item => item?.id)
    } else {
      otherExpences = otherExpenceList?.map(item => item?.id)
    }
    // Process the results if needed 
    let save_value = {
      id: values?.id ? Number(values?.id) : "",
      purchaseOrderNo: values?.purchaseOrder_no ? Number(values?.purchaseOrder_no) : "",
      status: values?.states,
      supplierId: values?.supplier_name ? Number(values?.supplier_name) : "",
      creditPeriod: values?.credit_period ? Number(values?.credit_period) : "",
      paymentTerms: values?.payment_terms,
      supplierRef: values?.supplier_ref,
      receivingStoreId: values?.receiving_store_id ? Number(values?.receiving_store_id) : "",
      scrapRejectStoreId: values?.scrap_reject_store_id ? Number(values?.scrap_reject_store_id) : "",
      remarks: values?.remarks,
      itemDetails: itemDetails,
      otherExpenses: otherExpences,
      department: values?.department_id ? Number(values?.department_id) : "",
      gstinType: values?.gstin_type,
      termsConditions: values?.TermsOptions ? Number(values?.TermsOptions) : "",
      termsConditionsText: values?.termsConditions ? values?.termsConditions : "",
      gstin: values?.gstin,
      dueDate: values?.due_date,
      contact: values?.contact ? Number(values?.contact) : "",
      address: values?.address ? Number(values?.address) : "",
      state: values?.state,
      igst: Object.keys(igstTax).length > 0 ? igstTax : {},
      sgst: Object.keys(sgstTax).length > 0 ? sgstTax : {},
      cgst: Object.keys(cgstTax).length > 0 ? cgstTax : {},
      country: values?.country,
      placeOfSupply: values?.place_of_supply,
      beforTax: totalAmountBeforeTax ? `${totalAmountBeforeTax}` : "",
      taxTotal: totalTax ? `${totalTax}` : "0",
      roundOff: roundof ? `${roundof}` : "0",
      netAmount: fixedFinalAmount ? Number(fixedFinalAmount) : "",
      modifiedBy: initialPurchase?.id ? Number(userId) : "",
      createdBy: initialPurchase?.createdBy ? Number(initialPurchase?.createdBy) : Number(userId)
    }
    if (amend) {

      save_value['id'] = ""
      save_value['createdBy'] = Number(userId)
      save_value['modifiedBy'] = ""
      save_value['parentOrder'] = parentOrder ? Number(parentOrder) : ""
      save_value['childCount'] = initialPurchase?.childCount >= 0 ? Number(initialPurchase?.childCount) + 1 : ""
      save_value['status'] = "Submit"
    }
    let verifiedAmount_list = purchaseItemDetails.map(item => item.verifiedAmount);
    if (verifiedAmount_list.some(amount => amount === false)) {
      toast.error('Some Item Cost is out of Range', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })
      return ""
    }
    // purchaseItemDetails
    if (save_value['address'] === "") {
      toast.error('Address is required', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })
      return ""
    }
    if (itemDetails.length > 0) {
    } else {
      toast.error('item details is required', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })
      return ""
    }
    console.log("Save Value---------------->>>>>>>>>", save_value);

    let allow_null = ['id', "modifiedBy", "supplierRef", "paymentTerms", "remarks",
      "receivingStoreId", "scrapRejectStoreId", 'department', "purchaseOrderNo"]
    let clear_data = removeEmptyValueInObject(save_value, allow_null)

    try {
      const respones = await axiosInstance.post(`/itemmaster/graphql`, {
        query: PurchaseOrderCreateMutations(clear_data, allow_null)
      })
      const responceData = respones?.data?.data?.purchaseOrderCreateMutation
      if (responceData.success) {
        resetForm()
        setPurchaseOrderEdit({ id: responceData?.purchaseOrderInstance?.id })
        setOtherExpenceList([])
        setLoad()
        setEdit(false)
        if (amend) {
          setAmend(false)
        }
        if (duplicate) {
          setDuplicate(false)
        }
        toast.success(`${values?.states} Successfully.`, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        })
      }
    } catch (error) {

    }
  }

  // retun null; to fetch options
  const getOption = (e, query, name) => {
    let search_term = e.trim().replace(/"/g, '\\"');
    if (query === "SupplierOptionsOptionsQuery" && search_term && name === "Name") {
      let peremeter = `companyName: "${search_term}", supplier:true`
      dispatch(fetchOptionsData(SupplierOptionPerameter(peremeter), "SupplierOptionsName"))
    } else if (query === "SupplierOptionsOptionsQuery" && search_term && name === "No") {
      let peremeter = `supplierNo: "${search_term}", supplier:true `
      dispatch(fetchOptionsData(SupplierOptionPerameter(peremeter), "SupplierOptionsNo"))
    } else if (query === "DepartmentQuery" && search_term) {
      let peremeter = `name: "${search_term}", page:1, pageSize:200`
      dispatch(fetchOptionsData(DepartmentQuery(peremeter), "departmentOptionsNo"))
    } else if (query === "ItemMasterPurchaseOrderFetchQuery" && search_term && name === "itemPartCode") {
      let peremeter = `itemPartCode: "${search_term}", page:1, pageSize:200,  itemComboBool:false, itemIndicatorsList:["Buy","Both"] `
      dispatch(fetchOptionsData(ItemMasterPurchaseOrderFetchQuery(peremeter), "itemPartCodeOptions"))
    } else if (query === "ItemMasterPurchaseOrderFetchQuery" && search_term && name === "itemPartName") {
      let peremeter = `itemName: "${search_term}", page:1, pageSize:200,  itemComboBool:false, itemIndicatorsList:["Buy","Both"] `
      dispatch(fetchOptionsData(ItemMasterPurchaseOrderFetchQuery(peremeter), "itemPartNameOptions"))
    } else if (query === "TermsConditionsFetchQuery" && search_term) {
      let peremeter = `pageSize:200, name:"${search_term}", module:"Purchase"`
      dispatch(fetchOptionsData(TermsConditionsFetchQuery(peremeter), "TermsConditionOptions"))
    }
    else if (query === "otherExpensesAccountFetchQuery" && search_term) {

    }
  }

  const getDeleteRowId = async (data) => {
    if (data?.id) {
      handltoastDeleteConfomationShow();
      setdeleteOtherExpensesData({ Name: data?.account?.label, id: data?.id })
    } else {
      setOtherExpenceList(prev => {
        return prev.filter(item => item?.account?.label !== data?.account?.label);
      });
    }
  }

  // retun null; in options on type 
  // we pass the peremeter to getOption then here we receiving dats
  useEffect(() => {
    if (OptionsDataList.name === "SupplierOptionsName") {
      setSupplierOptionList(OptionsDataList?.value?.items?.map((item) => ({
        value: item?.id, label: item?.companyName,
        type: item?.address?.addressType, gstin: item?.gstin, gstinType: item?.gstinType?.gstType, supplierCreditedPeriod: item?.supplierCreditedPeriod,
        supplierNo: item?.supplierNo, address: item?.address, contact: item?.contact
      })))
    } else {
      setSupplierOptionList([])
    }
    if (OptionsDataList.name === "SupplierOptionsNo") {
      setSupplierOptionNoList(OptionsDataList?.value?.items?.map((item) => ({
        value: item?.id, label: item?.supplierNo,
        type: item?.address?.addressType, gstin: item?.gstin, gstinType: item?.gstinType?.gstType,
        supplierCreditedPeriod: item?.supplierCreditedPeriod, supplierNo: item?.supplierNo, address: item?.address,
        contact: item?.contact, companyName: item?.companyName
      })))
    } else {
      setSupplierOptionNoList([])
    }
    if (OptionsDataList.name === "storeOptions") {
      setStorelist(OptionsDataList?.value?.items?.map((item) => ({ value: item?.id, label: item?.storeName })))
    }
    if (OptionsDataList.name === "departmentOptionsNo") {
      setDepartmentList(OptionsDataList?.value?.items?.map((item) => ({ value: item?.id, label: item?.name })))
    }
    if (OptionsDataList.name === "itemPartCodeOptions") {
      setItemMasterList(OptionsDataList?.value?.items?.map((item) => ({
        value: item?.id, label: item?.itemPartCode,
        category: item?.category, itemName: item?.itemName, rate: item?.itemCost, uom: item?.itemUom,
        alternateUom: item?.alternateUom, tax: item?.itemHsn?.gstRates?.rate, Hsn: item?.itemHsn?.id, varition: item?.variation, purchaseUom: item?.purchaseUom
      })));
    }
    if (OptionsDataList.name === "itemPartNameOptions") {
      setItemMasterNameList(OptionsDataList?.value?.items?.map((item) => ({
        value: item?.id, label: item?.itemName,
        category: item?.category, itemPartCode: item?.itemPartCode, rate: item?.itemCost, uom: item?.itemUom,
        alternateUom: item?.alternateUom, tax: item?.itemHsn?.gstRates?.rate, Hsn: item?.itemHsn?.id, varition: item?.variation, purchaseUom: item?.purchaseUom
      })));
    }
    if (OptionsDataList.name === "TermsConditionOptions") {
      setTCList(OptionsDataList?.value?.items?.map((item) => (
        { value: item?.id, label: item?.name, tc: item?.tc }
      )))
    }
    if (OptionsDataList.name === "otherExpensesOptions") {
      setOtherExpenceAccountList(OptionsDataList?.value?.items?.map((item) => (
        { value: item?.id, label: item?.account?.accountsName, tax: item?.HSN?.gstRates?.rate }
      )))
    }
    if (OptionsDataList.name === "purchaseEdit") {
      setVersionList(OptionsDataList?.value?.version?.versionList)
      setPurchaseOrderEditData(OptionsDataList?.value?.items)
    }
  }, [OptionsDataList])
  const [addressList, setAddressList] = useState([])
  const [selectType, setSelectType] = useState()

  // retun null ; on select supplier change related datas
  const handlechangesuplier = (option, setFieldValue) => {
    if (option) {
      if (setFieldValue) {
        setFieldValue("credit_period", option?.supplierCreditedPeriod)
        setFieldValue("gstin", option?.gstin)
        setFieldValue("gstin_type", option?.gstinType)
      } else if (fieldvalue) {
        fieldvalue("credit_period", option?.CreditedPeriod)
        fieldvalue("gstin", option?.gstin)
        fieldvalue("gstin_type", option?.gstinType)
        fieldvalue("supplier_Number", supplierSelect.id)
        fieldvalue("supplier_name", supplierSelect.id)
      }
      setAddressList(option?.address?.map((item) => ({ value: item?.id, label: item?.addressType, objects: item })))
      let isBillingAddress = option?.address?.filter((item) => item?.addressType === "BILLING_ADDRESS")
      if (isBillingAddress?.length > 0) {
        setSelectType({ value: isBillingAddress?.[0]?.id, label: isBillingAddress?.[0]?.addressType, objects: isBillingAddress })
        if (setFieldValue) {
          setFieldValue("address", isBillingAddress?.[0]?.id)
          setFieldValue("country", isBillingAddress?.[0]?.country)
          setFieldValue("state", isBillingAddress?.[0]?.state)
        } else if (fieldvalue) {
          fieldvalue("address", isBillingAddress?.[0]?.id)
          fieldvalue("country", isBillingAddress?.[0]?.country)
          fieldvalue("state", isBillingAddress?.[0]?.state)
        }
        getStates(isBillingAddress?.[0]?.country)
        setBillingAddressSelected(isBillingAddress?.[0])
      }
      let isdefaultcontace = option?.contact?.filter((item) => item?.default === true)
      if (isdefaultcontace?.length > 0) {
        if (setFieldValue) {
          setFieldValue("contact", isdefaultcontace?.[0]?.id)
        } else {
          fieldvalue("contact", isdefaultcontace?.[0]?.id)
        }
        setcontactSelected({
          value: isdefaultcontace?.[0]?.id, label: isdefaultcontace?.[0]?.contactPersonName,
          email: isdefaultcontace?.[0]?.email, Phone: isdefaultcontace?.[0]?.phoneNumber
        })
      }

      if (purchaseItemDetails?.length > 0) {
        changeTaxValue(isBillingAddress?.[0]?.state)
      }
    } else {
      if (setFieldValue) {
        setFieldValue("credit_period", "")
        setFieldValue("gstin", "")
        setFieldValue("gstin_type", "")
        setFieldValue("address", "")
        setBillingAddressSelected("")
        setFieldValue("country", "")
        setFieldValue("state", "")
        setFieldValue("contact", "")
        setcontactSelected({ value: "", label: "", email: "", Phone: "" })
      }
      setAddressList([])
      setSelectType()
    }

  }

  const handleDeletedId = (deleted_id) => {
    if (deleted_id) {
      setPurchaseItemDetails(prev => {
        return prev.filter(item => item.id !== deleted_id);
      });

    }
  }

  const handleDeleteOtherExpensesId = (id) => {
    if (id) {
      setOtherExpenceList(prev => {
        return prev.filter(item => item.id !== id);
      });
      setdeleteOtherExpensesData({ Name: "", id: "" })
    }
  }

  // retun null; using for cal the total , total tax, finalvalue
  // Purchase order calculate
  useEffect(() => {

    if (purchaseItemDetails.length > 0) {
      let Final_value = 0
      const igstList = {}
      const cgstList = {}
      const sgstList = {}
      purchaseItemDetails.forEach(itemValue => {
        Final_value += Number(itemValue.amount)
        if (itemValue.igst) {
          if (igstList[itemValue.igst]) {
            igstList[itemValue.igst].push(itemValue.amount)
          } else {
            igstList[itemValue.igst] = [itemValue.amount]
          }
        }
        if (itemValue.cgst) {
          if (cgstList[itemValue.cgst]) {
            cgstList[itemValue.cgst].push(itemValue.amount)
          } else {
            cgstList[itemValue.cgst] = [itemValue.amount]
          }
        }
        if (itemValue.sgst) {
          if (sgstList[itemValue.sgst]) {
            sgstList[itemValue.sgst].push(itemValue.amount)
          } else {
            sgstList[itemValue.sgst] = [itemValue.amount]
          }
        }
      })

      if (otherExpenceList.length > 0) {
        otherExpenceList.forEach(itemValue => {
          console.log(itemValue.amount);
          Final_value += Number(itemValue.amount)
          if (itemValue.igst) {
            if (igstList[itemValue.igst]) {
              igstList[itemValue.igst].push(itemValue.amount)
            } else {
              igstList[itemValue.igst] = [itemValue.amount]
            }
          }
          if (itemValue.cgst) {
            if (cgstList[itemValue.cgst]) {
              cgstList[itemValue.cgst].push(itemValue.amount)
            } else {
              cgstList[itemValue.cgst] = [itemValue.amount]
            }
          }
          if (itemValue.sgst) {
            if (sgstList[itemValue.sgst]) {
              sgstList[itemValue.sgst].push(itemValue.amount)
            } else {
              sgstList[itemValue.sgst] = [itemValue.amount]
            }
          }
        })
      }
      const { taxResult: igstResult, taxValues: igstaxvalues } =
        calculateTax(igstList)
      const { taxResult: cgstResult, taxValues: cgstaxvalues } =
        calculateTax(cgstList)
      const { taxResult: sgstResult, taxValues: sgstaxvalues } =
        calculateTax(sgstList)
      setTotalTax((igstaxvalues + cgstaxvalues + sgstaxvalues).toFixed(2))
      setIgstTax(igstResult)
      setCgstTax(cgstResult)
      setSgstTax(sgstResult)
      setTotalAmountBeforeTax(Final_value.toFixed(2))
      setFinalAmount(roundToNearest10(igstaxvalues + cgstaxvalues + sgstaxvalues + Final_value).toFixed(2))
      if (purchaseOrderEdit?.id && typeof initialEditData === "object" && initialEditData !== null) {
        initialEditData.netAmount = Number(roundToNearest10(igstaxvalues + cgstaxvalues + sgstaxvalues + Final_value).toFixed(2))
      }
      setRoundof((roundToNearest10(igstaxvalues + cgstaxvalues + sgstaxvalues + Final_value).toFixed(2) - (igstaxvalues + cgstaxvalues + sgstaxvalues + Final_value).toFixed(2)).toFixed(2))
      console.log("totalAmountBeforeTax-------------------------", totalAmountBeforeTax);
      console.log("totalTax-------------------------", totalTax);
      console.log("roundof-------------------------", roundof);
      console.log("fixedFinalAmount-------------------------", fixedFinalAmount);
      console.log("igstResult-----------", igstResult)
    }
  }, [purchaseItemDetails, otherExpenceList])

  //  retun bool; check rate in  limit to variations  
  function checkAmountWithVariationsValue(variation, itemmasterRate, rate, finalAmount, qty, partcode) {
    if (variation && itemmasterRate) {
      let variationsValue, variationsAmount, minAmount, maxAmount, perUnitAmount;

      if (variation.includes('%')) {
        variationsValue = variation.replace('%', '');
        variationsAmount = rate / 100 * Number(variationsValue);
        minAmount = Number(itemmasterRate) - Number(variationsAmount);
        maxAmount = Number(itemmasterRate) + Number(variationsAmount);
      } else if (variation.includes('₹')) {
        variationsValue = variation.replace('₹', '');
        variationsAmount = Number(variationsValue);  // Convert to number
        minAmount = Number(itemmasterRate) - Number(variationsAmount);
        maxAmount = Number(itemmasterRate) + Number(variationsAmount);
      }

      // Ensure finalAmount and qty are numbers before performing calculations
      perUnitAmount = Number(finalAmount) / Number(qty)
      if (minAmount <= perUnitAmount && perUnitAmount <= maxAmount) {
        return true
      } else {
        setVarifiedFalselist(prev => [...prev, partcode])
        return false
      }
    } else {
      setVarifiedFalselist(prev => [...prev, partcode])
      return false
    }

  }

  async function checkValueOnSubmit(submitForm, setFieldValue) {
    setVarifiedFalselist([])
    if (purchaseItemDetails.length > 0) {
      for (const row of purchaseItemDetails) {
        let rate = row?.rate;
        let qty = row?.poQty;
        let finalAmount = Number(row?.rate) * Number(row?.poQty);
        let variation = '';
        let itemmasterRate = '';
        let partcode = row?.itemMasterId?.itemPartCode;
        try {
          let parameter = `id:${row?.itemMasterId?.id}`;
          const response = await axiosInstance.post('/itemmaster/graphql', { query: itemmasterPruchase(parameter) });
          const responseData = response?.data?.data?.itemMaster?.items?.[0];
          variation = responseData?.variation;
          itemmasterRate = responseData?.itemCost;
        } catch (error) {
          console.error("Error fetching item master data", error);
        }
        let variationIsValid = checkAmountWithVariationsValue(variation, itemmasterRate, rate, finalAmount, qty, partcode)
        if (!variationIsValid) {
          let save_data = {
            id: row?.id,
            verifiedAmount: false,
          }
          let clear_data = removeEmptyValueInObject(save_data)
          try {
            const respones = await axiosInstance.post("/itemmaster/graphql", { query: PurchaseItemCreateMutations(clear_data) })
            const responseData = respones.data.data.purchaseOrderItemDetailsCreateMutation
            if (responseData.success) {
              setPurchaseItemDetails((prve) => {
                return prve.map((item) => {
                  return item?.id === row?.id ? responseData?.purchaseOrderItemDetailsInstance : item
                })
              })
            } else {
              let error = responseData.errors
              let errorData = ShowErrorNoties(error)
              toast.error(errorData, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
        if (varifiedFalselist.length > 0) {
          setShowCostOutOfRangeModel(true)
        } else {
          return true
        }
      }
    }
  }

  // retun null ;  make duplicate the item details and other expence
  async function makeDuplicateSubTables() {
    setLoading(true)
    setVarifiedFalselist([])
    if (purchaseItemDetails.length > 0) {

      let verifiedAmount = false
      for (const row of purchaseItemDetails) {
        let rate = row?.rate;
        let qty = row?.poQty;
        let finalAmount = Number(row?.rate) * Number(row?.poQty);
        let variation = '';
        let itemmasterRate = '';
        let partcode = row?.itemMasterId?.itemPartCode;
        try {
          let parameter = `id:${row?.itemMasterId?.id}`;
          const response = await axiosInstance.post('/itemmaster/graphql', { query: itemmasterPruchase(parameter) });
          const responseData = response?.data?.data?.itemMaster?.items?.[0];
          variation = responseData?.variation;
          itemmasterRate = responseData?.itemCost;
        } catch (error) {
          console.error("Error fetching item master data", error);
        }

        if (variation && itemmasterRate) {
          let variationsValue, variationsAmount, minAmount, maxAmount, perUnitAmount;
          if (variation.includes('%')) {
            variationsValue = variation.replace('%', '');
            variationsAmount = rate / 100 * Number(variationsValue);
            minAmount = itemmasterRate - variationsAmount;
            maxAmount = itemmasterRate + variationsAmount;
          } else if (variation.includes('₹')) {
            variationsValue = variation.replace('₹', '');
            variationsAmount = Number(variationsValue);  // Convert to number
            minAmount = itemmasterRate - variationsAmount;
            maxAmount = itemmasterRate + variationsAmount;
          }

          // Ensure finalAmount and qty are numbers before performing calculations
          perUnitAmount = Number(finalAmount) / Number(qty)
          if (minAmount <= perUnitAmount && perUnitAmount <= maxAmount) {
            verifiedAmount = true
          } else {
            setVarifiedFalselist(prev => [...prev, partcode])
            toast.error(`${partcode} Item Master Cost is out of Range`, {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light'
            })
            verifiedAmount = false
          }
        } else {
          toast.error(`${partcode} check variation and Item cost`, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          });
          verifiedAmount = false
        }
        let clone_data = { ...row, id: "", createdBy: "", verifiedAmount: verifiedAmount }
        setPurchaseItemDetails(prve => [...prve, clone_data])
      }
    }
    if (otherExpenceList.length > 0) {
      for (const row of otherExpenceList) {
        let clone_other_expence_data = { ...row, id: "", createdBy: "" }
        setOtherExpenceList(prev => [...prev, clone_other_expence_data])
      }
    }
    if (varifiedFalselist.length > 0) {
      setShowCostOutOfRangeModel(true)
    }
    setLoading(false)
  }

  // retun null ; duplica the data
  function duplicaTheData() {
    setLoadingModels(true)
    setPurchaseItemDetails([])
    setOtherExpenceList([])
    initialPurchase.states = ""
    setStates(null)
    initialPurchase.id = ""
    initialPurchase.createdBy = ""
    setCreatedBy(null)
    setPurchaseOrderNo(null)
    setLastModified(null)
    setEdit(!edit)
    makeDuplicateSubTables()
    setLoadingModels(false)
  }
  const [waringToCopy, setWaringToCopy] = useState(false)
  const [waringContent, setWaringContent] = useState({ Title: "", content: "" })
  // retun null; set status to cancle
  async function makeStatesCancle(params) {
    if (params) {
      let perameter = `id:${Number(purchaseOrderEdit?.id)}`
      try {
        const respones = await axiosInstance.post("/itemmaster/graphql", { query: PurchaseOrderCancleMutations(perameter) })
        const responseData = respones.data.data.purchaseOrderCancleMutation
        if (responseData.success) {
          setStates("Canceled")
          toast.success('Canceled Successfully', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else if (responseData?.action) {
          setWaringToCopy(true)
          setWaringContent({ Title: "Waring", content: responseData?.action })

        } else {
          let error = responseData.errors
          let errorData = ShowErrorNoties(error)
          toast.error(errorData, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } catch (error) {
      }
    }
  }

  // retun null; delete 
  async function Deletepurchaseorder(params) {
    if (params) {
      try {
        const respones = await axiosInstance.post("/itemmaster/graphql", { query: PurchaseOrderDeleteMutations(Number(purchaseOrderEdit?.id)) })
        const responseData = respones.data.data.purchaseOrderDeleteMutation
        if (responseData.success) {
          close()
          setLoad()
          setpurchaseOderIsShowing(false)
          toast.success('Deleted Successfully', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else if (responseData?.actions) {
          setWaringToCopy(true)
          setWaringContent({ Title: "Waring", content: responseData?.actions })
        } else {
          let error = responseData.errors
          let errorData = ShowErrorNoties(error)
          toast.error(errorData, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } catch (error) {
        let errorData = ShowErrorNoties(error)
        toast.error(errorData, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    }


  }

  // change tax value 
  async function changeTaxValue(state) {
    const promises = purchaseItemDetails.map(async item => {
      let save_data = {
        sNo: item?.sNo ? Number(item?.sNo) : 0,
        id: item?.id ? Number(item?.id) : "",
        itemMasterId: item?.itemMasterId?.id ? Number(item?.itemMasterId?.id) : "",
        category: item?.category,
        poQty: item?.poQty ? (item?.poQty) : "",
        uom: item?.uom?.id ? Number(item?.uom?.id) : "",
        rate: item?.rate ? Number(item?.rate) : "",
        tax: item?.tax ? Number(item?.tax) : "",
        amount: item?.amount ? `${item?.amount}` : `${item.rate * item.po_qty}`,
        discount: item?.discount,
        verifiedAmount: true,
        createdBy: item?.created_by ? Number(item.created_by) : Number(userId),
        modifiedBy: item?.id ? Number(userId) : ""
      };
      let save_value_with_tax = gettax(save_data, save_data['tax'], state);
      let allow_null = ['id', "modifiedBy", "discount"];
      let clear_data = removeEmptyValueInObject(save_value_with_tax, allow_null);
      try {
        const response = await axiosInstance.post("/itemmaster/graphql", { query: PurchaseItemCreateMutations(clear_data) });
        const responseData = response.data.data.purchaseOrderItemDetailsCreateMutation;
        if (responseData.success) {
          return responseData.purchaseOrderItemDetailsInstance;
        } else {
          let error = responseData.errors;
          let errorData = ShowErrorNoties(error);
          toast.error(errorData, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          return null;
        }
      } catch (error) {
        console.error("Error updating item:", error);
        return null;
      }
    });

    try {
      const results = await Promise.all(promises);
      setPurchaseItemDetails(prev => {
        return prev.map(item => {
          const updatedItem = results.find(result => result && result.id === item.id);
          return updatedItem ? updatedItem : item;
        });
      });
    } catch (error) {
      console.error("Error processing items:", error);
    }

    if (otherExpenceList.length > 0) {
      for (const row of otherExpenceList) {
        const saveValue = {
          id: row.id ? Number(row.id) : "",
          otherExpensesId: Number(row?.['account']?.['value']),
          amount: Number(row?.['amount']),
          modifiedBy: row?.id ? Number(userId) : "",
          createdBy: Number(row?.['createdBy']) ? Number(row?.['createdBy']) : Number(userId)
        }
        let allow_null = ["id", "modifiedBy"]
        let cleanValue;
        if (row?.['tax']) {
          let save_value_with_tax = gettax(saveValue, row?.['tax'], state)
          cleanValue = removeEmptyValueInObject(save_value_with_tax, allow_null)
        } else {
          cleanValue = removeEmptyValueInObject(saveValue, allow_null)
        }
        const response = await axiosInstance.post(`/itemmaster/graphql`, { query: OtherExpensesPurchaseCreateMutations(cleanValue) });

        const responseData = response?.['data']?.['data']?.['otherExpensesPurchaseOrderCreateMutation']

        if (responseData.success) {
          let data = responseData['otherExpensesPurchaseOrderInstance']
          let otherExpenceAccount = {
            id: data.id,
            account: { value: data?.otherExpensesId?.id, label: data?.otherExpensesId?.account?.accountsName },
            tax: data?.otherExpensesId?.HSN?.gstRates?.rate,
            amount: data?.amount,
            createdBy: data?.createdBy?.id,
            igst: data?.igst,
            sgst: data?.sgst,
            cgst: data?.cgst,
          }
          setotherExpenceAccountSelected(null)
          if (saveValue?.id) {
            setOtherExpenceList((prevData) => {
              return prevData.map((item) => {
                return item.id === data.id ? otherExpenceAccount : item;
              });
            });
          } else {
            setOtherExpenceList(prev => [...prev, otherExpenceAccount])
          }
        } else {
          let errorData = ShowErrorNoties(responseData.errors)
          toast.error(errorData, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }

      }

    }



  }
  const [isShowTimeLine, setIsShowTimeLine] = useState(false)
  const [timeLineData, setTimeLineData] = useState([])
  async function fetchTimelineData(id) {
    setLoading(true)
    let queryperameter = `purchaseId: ${id}`
    try {
      const respones = await axiosInstance.post(`/itemmaster/graphql`, {
        query: PurchaseOrderTimeLine(queryperameter)
      })
      let timeLime = respones?.data?.data?.purchaseOrderTimeLine1
      setTimeLineData(timeLime)
      setIsShowTimeLine(true)
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  }
  const [qirIsShowing, setQirIsShowing] = useState()
  const [qirEdit, setQirEdit] = useState(null)
  function openTheClickData(params) {
    let modelName = params?.['name'];
    let id;

    if (params['attributes']) {
      id = params?.['attributes']?.['id']
    } else {
      id = params?.['id']
    }
    if (modelName === "GIN" && id !== "") {
      setIsGin(true)
      setIsGrnShowing(true)
      setEditGrn({ 'id': id })
    } else if (modelName === "QIR" && id !== "") {
      setQirIsShowing(true)
      setQirEdit(id)
    } else if (modelName === "GRN" && id !== "") {
      setIsGin(false)
      setIsGrnShowing(true)
      setEditGrn({ 'id': id })
    }

  }
  const gridApiRef = useRef(null);
  const gridColumnApiRef = useRef(null);
  const onGridReady = params => {
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;
  };

  // retun null ; Check pre purchase Return challan all submit
  function CheckPrcAllSubmited(params) {
    let purchaseData = purchaseOrderEditData[0]

    if (purchaseData?.['purchasereturnchallanSet']?.length > 0) {
      const lastIndex = purchaseData['purchasereturnchallanSet'].length - 1;
      if (purchaseData['purchasereturnchallanSet'][lastIndex]['status']['name'] === "Draft") {
        showErrorToast(true, "warn",
          `${purchaseData['purchasereturnchallanSet'][lastIndex]['purchaseReturnChallanNo']['linkedModelId']} Need to Submit.`
        )
      } else {
        setPurchaseRetun(true)
      }
    } else {
      setPurchaseRetun(true)
    }

  }

  return (
    <>
      {<TaxModel />}
      {<LoadingModel />}
      {<BallTriangleLoading isshow={loading} />}
      {<TreeComponent
        show={isShowTimeLine}
        setShow={setIsShowTimeLine}
        timeLineData={timeLineData}
        clickedData={value => {
          openTheClickData(value)
        }}
        currentID={initialPurchase?.id}
      />}
      {showCostOutOfRangeModel && <CostOutOfRangeModel
        showCostOutOfRangeModel={showCostOutOfRangeModel}
        setShowCostOutOfRangeMode={setShowCostOutOfRangeModel}
        varifiedFalselist={varifiedFalselist}
      />}
      <Modal show={purchaseOderIsShowing} fullscreen={true}>
        <Modal.Body>
          <div className='itemMaster_Top mx-3 mt-1 d-flex justify-content-center card' style={{ width: '98%' }}>
            <div className="row  align-items-center  justify-content-between ">
              {/* justify-content-between */}
              <div className="col-4 commanModelTitleColor"   >
                <h3 className='mx-2'>
                  <i className='fa-solid fa-arrow-left fa-sm me-2' onClick={() => {
                    close()
                  }}></i>
                  Purchase Order {states ? <><span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'> {states} </span>   <span className='link_color cursor-pointer' onClick={() => {
                    if (initialPurchase.id) {
                      fetchTimelineData(initialPurchase.id);
                    }
                  }}>Time Line</span></> : ""}
                </h3>
              </div>
              <div className="col-3 px-4  " hidden={versionList.length === 1 ? true : purchaseOrderEdit !== null ? false : true}>
                <p className='text-start  p-0 m-0 d-flex justify-content-center'><span className=''>Version</span></p>
                <div className='d-flex justify-content-center'>
                  <AiOutlineCaretLeft size={30} onClick={backWord} disabled={isFirst} />
                  <span className='fs-5 px-1 ms-1  '>{purchaseOrderNo}</span>
                  <AiOutlineCaretRight size={30} onClick={forWord} disabled={isLast} />
                </div>
              </div>
              <div className="col-5">
                <div className="row">
                  <div className="col-9">
                    <h6 className='text-end'  >
                      {createdBy ? `Created By : ${createdBy}` : ""}
                    </h6>
                    <h6 className='text-end '>
                      {lastModified?.date ? `Last Modified Date : ${lastModified?.date} (${lastModified?.time})` : ""}
                    </h6>
                  </div>
                  <div className="col-3 text-end">
                    <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={!active || !(purchaseOrderEdit && edit) || states === "Submit" ? true : states === "Canceled" ? true : false} onClick={() => { setEdit(!edit) }}  ></i>
                    <i className="fa-solid fa-copy  fs-5 pt-1 mx-3 text-success" onClick={() => { duplicaTheData(true); setDuplicate(true) }}
                      hidden={!active || !(purchaseOrderEdit && edit)} data-bs-toggle="tooltip" data-bs-placement="top" title="Duplicate" role="button" style={{ cursor: "pointer" }}></i>
                    <TiCancel hidden={!active || !(purchaseOrderEdit && edit) || states === "Canceled" ? true : false}
                      data-bs-toggle="tooltip" data-bs-placement="top" title="Cancle"
                      className='fs-2 pb-1'
                      style={{ color: '#7f7f7f', cursor: "pointer" }}
                      onClick={() => {
                        setWaringToCancle(true)
                      }}
                    />
                    <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={states === "Canceled" ? !active || !(purchaseOrderEdit && edit) : true} onClick={() => { setWaringToDelete(true) }} ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "98%" }}>
            <Formik
              initialValues={initialPurchase}
              validationSchema={PurchaseSchema}
              onSubmit={handleSubmit}>
              {({ errors, touched, setFieldValue, values, isSubmitting, submitForm }) => {
                fieldvalue = setFieldValue
                currentValues = values
                return (
                  <Form>
                    {/* Supplier Details */}
                    <div className="row ms-3 mt-2">
                      {/* left Side */}
                      <div className="col card  shadow  mx-2  ">
                        <div className="row">
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='PoNo' disabled value={initialPurchase.po_no} className='w-100 staan-form-input' />
                            <label htmlFor="due_date" className='staan-form-input-label pt-1 px-1 mx-2'>PO No</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='PoDate' value={DateFormate(initialPurchase.po_Date)} disabled className='w-100 staan-form-input' />
                            <label htmlFor="due_date" className='staan-form-input-label pt-1 px-1 mx-2'>PO Date</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={departmentSelecte}
                              handleInputChange={(e) => {
                                getOption(e, "DepartmentQuery")
                              }}
                              options={departmentList}
                              name='department_id'
                              id='department_id'
                              handleOnChange={(option) => {
                                setFieldValue("department_id", option ? option.value : null)
                                setDepartmentSelecte(option ? option : null)
                              }}
                              isDisabled={!active || (purchaseOrderEdit && edit)}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Department</label>
                            {touched.department_id && errors.department_id && <small>{errors.department_id}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='date' name='due_date' disabled={!active || (purchaseOrderEdit && edit)} className='w-100 staan-form-input' />
                            <label htmlFor="due_date" className='staan-form-input-label pt-1 px-1 mx-2'>Due Date<span className='text-danger'>*</span></label>
                            {touched.due_date && errors.due_date && <small>{errors.due_date}</small>}
                          </div>
                          <div className="col-6 staan-input-group  ">
                            <CustomSelect
                              setSelectEditValue={receivingStoreSelecte}
                              options={storelist}
                              name='receiving_store_id'
                              id='receiving_store_id'
                              handleOnChange={(option) => {
                                setFieldValue("receiving_store_id", option ? option.value : null)
                                setReceivingStoreSelecte(option ? option : null)
                              }}
                              isDisabled={!active || (purchaseOrderEdit && edit)}
                            />
                            <label htmlFor="receiving_store_id" className='staan-form-input-label pt-1 px-1 mx-2'>Receiving Store<span className='text-danger'>*</span></label>
                            {touched.receiving_store_id && errors.receiving_store_id && <small>{errors.receiving_store_id}</small>}
                          </div>
                          <div className="col-6 staan-input-group ">
                            <CustomSelect
                              setSelectEditValue={scrapRejectStoreSelecte}
                              options={storelist}
                              name='scrap_reject_store_id'
                              id='scrap_reject_store_id'
                              handleOnChange={(option) => {
                                setFieldValue("scrap_reject_store_id", option ? option.value : null)
                                setscrapRejectStoreSelecte(option ? option : null)
                              }}
                              isDisabled={!active || (purchaseOrderEdit && edit)}
                            />
                            <label className='staan-form-input-label pt-1 px-1 mx-2'>Scrap/Reject Store<span className='text-danger'>*</span></label>
                            {touched.scrap_reject_store_id && errors.scrap_reject_store_id && <small>{errors.scrap_reject_store_id}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='credit_period' disabled={!active || (purchaseOrderEdit && edit)} placeholder='Enter Credit Period' className='w-100 staan-form-input' />
                            <label htmlFor="credit_period" className='staan-form-input-label pt-1 px-1 mx-2'>Credit Period<span className='text-danger'>*</span></label>
                            {touched.credit_period && errors.credit_period && <small>{errors.credit_period}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='payment_terms' disabled={!active || (purchaseOrderEdit && edit)} placeholder='Enter Payment Terms' className='w-100 staan-form-input' />
                            <label htmlFor="payment_terms" className='staan-form-input-label pt-1 px-1 mx-2'>Payment Terms</label>
                            {touched.payment_terms && errors.payment_terms && <small>{errors.payment_terms}</small>}
                          </div>
                          <div className="col-6 staan-input-group my-1">
                            <Field type='text' name='supplier_ref' disabled={!active || (purchaseOrderEdit && edit)} placeholder='Enter Supplier Ref' className='w-100 staan-form-input' />
                            <label htmlFor="supplier_ref" className='staan-form-input-label pt-1 px-1 mx-2'>Supplier Ref</label>
                            {touched.supplier_ref && errors.supplier_ref && <small>{errors.supplier_ref}</small>}
                          </div>
                        </div>
                      </div>
                      {/* right Side */}
                      <div className="col  card  shadow mx-2 ">
                        <div className="row">
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={supplierNoSelected}
                              handleInputChange={(e) => {
                                getOption(e, "SupplierOptionsOptionsQuery", "No")
                              }}
                              options={supplierOptionNoList}
                              name='supplier_Number'
                              id='supplier_Number'
                              handleOnChange={(option) => {
                                setFieldValue("supplier_Number", option ? option.value : null)
                                setFieldValue("supplier_name", option ? option?.value : null)
                                setSupplierNoSelected(option)
                                setSupplierSelected({ value: option?.value, label: option?.companyName })
                                handlechangesuplier(option, setFieldValue)
                              }}
                              isDisabled={!active || (purchaseOrderEdit && edit)}
                            />
                            <label htmlFor="supplier_Number" className='staan-form-input-label  px-1 mx-2' >Supplier Number <span className='text-danger'>*</span> <i role="button" className='bx bx-plus-circle ms-2'
                              onClick={() => { handleSupplierShow() }} ></i></label>
                            {touched.supplier_Number && errors.supplier_Number && <small>{errors.supplier_Number}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={supplierSelected}
                              handleInputChange={(e) => {
                                getOption(e, "SupplierOptionsOptionsQuery", "Name")
                              }}
                              options={supplierOptionList}
                              name='supplier_name'
                              id='supplier_name'
                              handleOnChange={(option) => {
                                setFieldValue("supplier_name", option ? option?.value : null)
                                setFieldValue("supplier_Number", option ? option?.value : null)
                                setSupplierSelected(option)
                                setSupplierNoSelected({ value: option?.value, label: option?.supplierNo })
                                handlechangesuplier(option, setFieldValue)
                              }}
                              isDisabled={!active || (purchaseOrderEdit && edit)}
                            />
                            <label htmlFor="supplier_name" className='staan-form-input-label pt-1 px-1 mx-2'>Supplier Name<span className='text-danger'>*</span></label>
                            {touched.supplier_name && errors.supplier_name && <small>{errors.supplier_name}</small>}
                          </div>
                          <div className="col-6 mt-1" >
                            <div hidden={billingAddressSelected ? false : true}>
                              <p className='pt-2' style={{ marginBottom: ".5rem" }}>{billingAddressSelected?.addressLine1} {billingAddressSelected?.addressLine2},</p>
                              <p className='pb-0' style={{ marginBottom: ".5rem" }}> {billingAddressSelected?.city} -  {billingAddressSelected?.pincode},</p>
                              <p className='pb-0' style={{ marginBottom: ".5rem" }} >{billingAddressSelected?.state}  ,</p>
                              <p style={{ marginBottom: ".5rem" }}>Country : <span><Field type='text' name='country' disabled className='border border-0' /></span></p>
                            </ div>
                          </div>
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={selectType}
                              options={addressList}
                              name='addressList'
                              id='addressList'
                              handleOnChange={(option) => {
                                if (option) {
                                  setSelectType(option)
                                  setBillingAddressSelected(option?.objects)
                                  changeTaxValue(option?.objects?.state)
                                  setFieldValue("country", option?.objects?.country)
                                } else {
                                  setBillingAddressSelected("")
                                  setSelectType("")
                                }
                              }}
                              isDisabled={!active || (purchaseOrderEdit && edit)}
                            />
                            <label htmlFor="scrap_reject_store_id" className='staan-form-input-label pt-0 px-1 mx-2'>Address Type<span className='text-danger'>*</span></label>
                          </div>
                          <div className="col-12 staan-input-group mb-2">
                            <Field type='text' as="textarea" rows="3" style={{ resize: "vertical", height: "auto" }} name='remarks' disabled={!active || ((purchaseOrderEdit && edit))} placeholder="Remarks" className='w-100 staan-form-input' />
                            <label htmlFor="remarks" className='staan-form-input-label pt-1 px-1 mx-2'>Remark</label>
                            {touched.remarks && errors.remarks && <small>{errors.remarks}</small>}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* content */}
                    <div className="row ms-3 mt-3">
                      <div className="col card  shadow mx-2 pb-2">
                        <h6 className='commanModelTitleColor m-2'>Contact</h6>
                        <div className="row">
                          <div className="col-3 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={contactSelected}
                              options={[]}
                              name='contact'
                              id='contact'
                              isDisabled={!active || (purchaseOrderEdit && edit)}
                            />
                            <label htmlFor="contact" className='staan-form-input-label pt-1 px-1 mx-2'>Contact<span className='text-danger'>*</span></label>
                            {touched.contact && errors.contact && <small>{errors.contact}</small>}
                          </div>
                          <div className="col-3 staan-input-group my-1">
                            <Field type='text' name='Email' value={contactSelected?.email} disabled={!active || (purchaseOrderEdit && edit)} placeholder='Enter Email' className='w-100 staan-form-input' />
                            <label htmlFor="Email" className='staan-form-input-label pt-1 px-1 mx-2'>Email</label>
                          </div>
                          <div className="col-3 staan-input-group my-1">
                            <Field type='text' name='Phone' value={contactSelected?.Phone} disabled={!active || (purchaseOrderEdit && edit)} placeholder='Enter Phone' className='w-100 staan-form-input' />
                            <label htmlFor="Phone" className='staan-form-input-label pt-1 px-1 mx-2'>Phone</label>
                          </div>
                        </div>
                      </div>

                    </div>
                    {/* Gst Side */}
                    <div className="row ms-3 mt-3">

                      <div className="col-12 card  shadow">
                        <h6 className='commanModelTitleColor m-2'>GST Details</h6>
                        <div className="row my-1">
                          <div className="col-3 staan-input-group">
                            <Field type='text' name='gstin_type' disabled className='w-100 staan-form-input' />
                            <label htmlFor="gstin_type" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN Type<span className='text-danger'>*</span></label>
                            {touched.gstin_type && errors.gstin_type && <small>{errors.gstin_type}</small>}
                          </div>
                          <div className="col-3 staan-input-group">
                            <Field type='text' name='gstin' disabled className='w-100 staan-form-input' />
                            <label htmlFor="gstin" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN<span className='text-danger'>*</span></label>
                            {touched.gstin && errors.gstin && <small>{errors.gstin}</small>}
                          </div>
                          <div className="col-3 staan-input-group">
                            <Field type='text' name='state' disabled className='w-100 staan-form-input' />
                            <label htmlFor="state" className='staan-form-input-label pt-1 px-1 mx-2'>State<span className='text-danger'>*</span></label>
                            {touched.state && errors.state && <small>{errors.state}</small>}
                          </div>
                          <div className="col-3 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={statesSelected}
                              options={statesList}
                              name='place_of_supply'
                              id='place_of_supply'
                              handleOnChange={(option) => {
                                setStatesSelected(option)
                                setFieldValue("place_of_supply", option ? option?.label : null)
                              }}
                              isDisabled={!active || (purchaseOrderEdit && edit)}
                            />
                            <label htmlFor="place_of_supply" className='staan-form-input-label pt-1 px-1 mx-2'>Place Of Supply<span className='text-danger'>*</span></label>
                            {touched.place_of_supply && errors.place_of_supply && <small>{errors.place_of_supply}</small>}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Item Details */}
                    <div className="row ms-3 mt-4 card shadow-lg pb-3">
                      <div className="row justify-content-between align-items-center">
                        <div className="col-3">
                          <h6 className='commanModelTitleColor '>Item Details</h6>
                        </div>
                        <div className="col-3 text-end">
                          <button type="button" class="btn btn-outline-primary mt-4 px-4" disabled={!active || (purchaseOrderEdit && edit)} onClick={() => { setPurchaseItemShow(true) }}> <i className='bx bxs-plus-circle me-2'></i>New</button>
                        </div>
                      </div>
                      <div className="ag-theme-alpine mt-2" style={{ height: '40rem', width: '100%' }}>
                        <AgGridReact
                          rowData={purchaseItemDetails}
                          columnDefs={columns}
                          defaultColDef={defaultColDef}
                          pagination={false}
                          rowSelection="multiple"
                          onGridReady={onGridReady}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-between ms-3 mt-4">
                      {/* Terms & Conditions */}
                      <div className="col card shadow-lg">
                        <div className="row">
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={termsOptions}
                              handleInputChange={(e) => {
                                getOption(e, "TermsConditionsFetchQuery")
                              }}
                              options={tCList}
                              name='accountName'
                              id='accountName'
                              handleOnChange={(option) => {
                                setFieldValue("TermsOptions", option ? option?.value : "")
                                setFieldValue("termsConditions", option ? option?.tc : "")
                                setTermsOptions(option)
                                setTermsConditions(option?.tc)
                              }}
                              isDisabled={purchaseOrderEdit && edit}
                            />
                            <label htmlFor="supplier_Number" className='staan-form-input-label pt-1 px-1 mx-2'>Terms & Conditions</label>
                          </div>
                          <div className="col-12 staan-input-group"    >
                            <ReactQuill
                              value={termsConditions}
                              readOnly={purchaseOrderEdit && edit}
                              onChange={(e) => {
                                setFieldValue("termsConditions", e ? e : "")
                                setTermsConditions(e)
                              }}
                              modules={textEditormodules} />
                            {touched.termsConditions && errors.termsConditions && <small>{errors.termsConditions}</small>}
                          </div>
                        </div>
                      </div>
                      <div className="col ">
                        {/* Other Expenses */}
                        <div className="card shadow-lg  p-3">
                          <h6 className='commanModelTitleColor p-2'>Other Expenses</h6>
                          <StaanTab
                            row={otherExpenceList}
                            column={OtherExpensesColumnDefs}
                            inputSection={OtherExpensesInputFrom}
                            isdisabled={purchaseOrderEdit && edit}
                            getDeleteRowId={getDeleteRowId}
                          />
                        </div>
                        {/* Amount */}
                        <div className="card shadow-lg mt-3 mx-3">
                          <table class="table ">
                            <tbody>
                              <tr>
                                <td className='text-end'>Before Tax</td>
                                <td className='text-end'>{totalAmountBeforeTax ? totalAmountBeforeTax : "0.00"}</td>
                              </tr>
                              <tr>
                                <td className='text-end cursor-pointer text-primary' onClick={() => { setShowTaxModel(true) }}>Tax Total</td>
                                <td className='text-end'>{totalTax ? totalTax : "0.00"}</td>
                              </tr>
                              <tr>
                                <td className='text-end'>Round of</td>
                                <td className='text-end'>{roundof}</td>
                              </tr>
                              <tr>
                                <td className='text-end  fw-bold'>Nett Amoun</td>
                                <td className='text-end'>{fixedFinalAmount ? fixedFinalAmount : "0.00"}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                    {/* button */}
                    <div className="row mt-3 ms-3 justify-content-evenly" hidden={states === "Canceled" ? true : false}>
                      <div className="col-1">
                        <Can I={"Draft"} a={"Purchase_Order"}>
                          <button type="submit" disabled={states === "Submit" ? true : amend ? true : false} class="btn btn-outline-secondary  px-4" onClick={() => { setFieldValue("states", "Draft"); }}> Draft </button>
                        </Can>
                      </div>
                      <div className="col-1">
                        <Can I={"Submit"} a={"Purchase_Order"}>
                          <button type="button" disabled={states === "Draft" ? false : amend ? false : true} class="btn btn-outline-success px-3" onClick={() => {
                            let premision = checkValueOnSubmit(submitForm, setFieldValue)
                            if (premision) {
                              fieldvalue("states", "Submit")
                              submitForm()
                            }
                          }} >Submit</button>
                        </Can>
                      </div>
                      <div className="col-1">
                        <Can I={"Amend"} a={"Purchase_Order"}>
                          <button type="submit" disabled={!active || (states === "Submit" ? false : true)} class="btn btn-outline-primary px-3" onClick={() => { setAmend(true); duplicaTheData() }} >Amend</button>
                        </Can>
                      </div>
                      <div className="col-1">
                        <Can I={"Create"} a={"GIN"}>
                          <button type="button" class="btn btn-outline-info px-4" disabled={allowPurchaseRetun ? true : states === "Submit" ? false : true} onClick={() => { setIsGrnShowing(true) }}>GIN</button>
                        </Can>
                      </div>
                      <div className="col-1">
                        <button type="button" class="btn btn-outline-info px-4" disabled={!allowPurchaseRetun} onClick={CheckPrcAllSubmited}>PRC</button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
        {/* Title */}
      </Modal>
      <SupplierFrom />
      {allowPurchaseRetun && purchaseRetun &&
        <PurchaseRetunForm
          isShowing={purchaseRetun}
          setIsShowing={setPurchaseRetun}
          initialData={{ initialPurchase: initialPurchase, ItemDetails: purchaseItemDetails }}
          setPurchaseOrderEdit={setPurchaseOrderEdit}
        />
      }

      {waringToCancle &&
        <WaringModel
          state={waringToCancle}
          setstate={setWaringToCancle}
          title={"confirmation"}
          text={'Confirm to cancel'}
          Confirmtion={value => {
            makeStatesCancle(value)
          }}
        />}
      {waringToCopy && <WaringModelWithCopy
        state={waringToCopy}
        setState={setWaringToCopy}
        title={waringContent?.Title}
        text={waringContent?.content}
      />}
      {waringToDelete && <WaringModel
        state={waringToDelete}
        setstate={setWaringToDelete}
        title={"confirmation"}
        text={'Confirm to Delete'}
        Confirmtion={value => {
          Deletepurchaseorder(value)
        }}
      />}
      {waringToCloseModel && <WaringModel
        state={waringToCloseModel}
        setstate={setWaringToCloseModel}
        title={"confirmation"}
        text={' Do you want to leave without saving?'}
        Confirmtion={value => {
          premisionToCloseData(value)
        }}
      />}
      {purchaseItemShow &&
        <PurchaseItemModel
          amend={amend}
          duplicate={duplicate}
          purchaseItemShow={purchaseItemShow}
          setPurchaseItemShow={setPurchaseItemShow}
          getOption={(e, query, name) => { getOption(e, query, name) }}
          itemMasterList={itemMasterList}
          itemMasterNameList={itemMasterNameList}
          setItemMasterList={setItemMasterList}
          setItemMasterNameList={setItemMasterNameList}
          setPurchaseItemDetails={setPurchaseItemDetails}
          purchaseItemDetails={purchaseItemDetails}
          purchaseItemDetailsEdit={purchaseItemDetailsEdit}
          setPurchaseItemDetailsEdit={setPurchaseItemDetailsEdit}
          SNo={purchaseItemDetails?.length ? purchaseItemDetails.length + 1 : 1}
          states={billingAddressSelected?.state}
        />}
      {deleteData?.Name && <DeleteConformation
        handleDeletedId={(id) => { handleDeletedId(id) }}
        deleteData={deleteData}
        fullquery={PurchaseItemDeleteMutations(deleteData?.id)}
      />}
      {deleteOtherExpensesData?.Name && <DeleteConformation
        handleDeletedId={(id) => { handleDeleteOtherExpensesId(id) }}
        deleteData={deleteOtherExpensesData}
        fullquery={OtherExpensesDeleteMutations(deleteOtherExpensesData?.id)}
      />}
      <Grn
        isGin={isGin}
        setIsGin={setIsGin}
        isGrnShowing={isGrnShowing}
        setIsGrnShowing={setIsGrnShowing}
        purchaseOrderDetails={purchaseOrderEditData}
        editGrn={editGrn}
        setEditGrn={setEditGrn}
      />
      {qirIsShowing && <QIR
        isShowqir={qirIsShowing}
        setIsShowqir={setQirIsShowing}
        ginItemsList={{ "ginItemsList": [], "supplierDatas": "" }}
        editViewId={qirEdit}
        setQirEdit={setQirEdit}
      />}

    </>
  )
}

export default PurchaseOrderModel