import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { callLogCreateMutation } from '../../../../GraphQLQueries/ActivitiesQueryAndMutations/ActivitiesMutations';
import { userFiliterbypermission } from '../../../../GraphQLQueries/EnquiryQuery';
import axiosInstance, { commanFetch } from '../../../../api/axoiss';
import showErrorToast from '../../../../components/notifications/react-toastify/toast';
import DataContext from '../../../../context/ItemMasterContext';
import { customSelectStyle, removeEmptyValueInObject, ShowErrorNoties } from '../../../../utils/Utils';
import { call_activites_schema } from '../../../../validations/ActivitiesValidations/ActivitiesValidations';
 

const CallActivities = ({setLogList, setShowForm, enquiry_id,  callEdit, setCallEdit, 
  setNavList, salesMan, subjectValue}) => {
  const [initialValue, setInitialValue] = useState({
    id : "",
    subject:"Call",
    outcome:"",
    salesperson:"",
    createdBy:""
  })
  const [formKey, setFormKey] = useState(0)
  const {userId} = useContext(DataContext)
  const [salesPersonList, setSalesPersonlist] =  useState([])
  const [salesPersonValue, setSalesPersonValue] = useState({value:"", label: ""})
  
  useEffect(()=>{
    if (callEdit){
      console.log(callEdit);
      console.log("callEdit?.salesPerson?.id,", callEdit?.salesPerson?.id,);
      
      
      setInitialValue({
        id: callEdit?.id,
        subject: callEdit?.subject,
        outcome:callEdit?.outcome,
        salesperson: callEdit?.salesPerson?.id,
        createdBy : callEdit?.createdBy?.id
      })
      setSalesPersonValue({value:callEdit?.salesPerson?.id, label: callEdit?.salesPerson?.username})
      setFormKey(prev => prev + 1);

    } else{
     
      initialValue['salesperson'] = salesMan?.value
      setSalesPersonValue(salesMan)
      setFormKey(prev => prev + 1);
      
    }
  },[callEdit, subjectValue])
  // return null ; user to get options for selecte element
  async function fetchOptions(e){
    let search_term = e.trim().replace(/"/g, '\\"');
    try{
      const response = await commanFetch(userFiliterbypermission(`isSales:true, userName:"${search_term}"`));
      const reponseData = response?.data?.items
      if (response.success){
        setSalesPersonlist(reponseData.map((salesPerson)=>(
          {value:salesPerson?.userId?.id, label: salesPerson?.userId?.username})))
              
          
      } else {
          console.error("Error fetching data:", response.error);
      }
    } catch(error){

    }
    
  }
  // retun null ;  Submit functions
  const handleSubmit =async  (values, { setSubmitting, resetForm }) =>{ 
    const saveValue = {
      id: initialValue?.id ? Number(initialValue?.id) : "",
      subject: values?.subject,
      outcome: values?.outcome,
      salesPerson : values?.salesperson ? Number(values?.salesperson) : "", 
      createdBy :initialValue?.id ? Number(initialValue['createdBy']): Number(userId),
      modifiedBy : initialValue?.id ? Number(userId) : "", 
    }
    if(enquiry_id){
      saveValue['enquiryId'] = Number(enquiry_id)
    }
    let ClearValue = removeEmptyValueInObject(saveValue, ["id", "modifiedBy"], ['outcome'])
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
          query: callLogCreateMutation(ClearValue)
      });
      const responseData = response?.data?.data?.callLogCreateMutation;
      if (responseData.success) {
          setShowForm(false)
          showErrorToast(true, "success", "", "Saved Successfully" )
          resetForm()
          setSubmitting(false)
          setCallEdit(null)
          setNavList(prve=>prve.map(item=>({...item, isShow: false})))
          if (enquiry_id) { 
            setLogList((prev) => {
                let prev_ids = prev.map((item) => item?.types ? item.id : "").filter(item=> item !== "");
                 
                
                if (prev_ids.includes(responseData?.callLogInstance?.id)) {
                    return prev.map((item) => {
                        return item?.types ?  item.id === responseData?.callLogInstance?.id
                            ? responseData?.callLogInstance
                            : item : item;
                    });
                } else {
                    return [responseData?.callLogInstance , ...prev];
                }
            });
        }
        
          
      } else {
          let errorData = ShowErrorNoties(responseData.errors);
          showErrorToast(true, "error", errorData,  )
      }
  } catch (error) {
    let errorData = ShowErrorNoties(error); 
    showErrorToast(true, "error", errorData,  )
  }
      
  }

  return (
    <>
      <div className=''> 
        <Formik initialValues={initialValue}
        validationSchema={call_activites_schema}
        onSubmit={handleSubmit}
        key={formKey}>
          {({errors, touched, isSubmitting , setFieldValue, values})=>{
            return (
              <Form>
                <div className="row">
                  <div className="col-6 staan-input-group">
                    <Field
                      id="subject"
                      name="subject"
                      className='w-100 staan-form-input '
                    />
                    <label htmlFor="subject" className='staan-form-input-label pt-0 px-1 mx-2' onClick={()=>{console.log(values);
                    }}>Subject <span className='text-danger'>*</span></label>
                    <ErrorMessage name="subject" component="div" /> 
                  </div>
                  <div className="col-12 staan-input-group">
                    <Field
                      as="textarea"
                      id="outcome"
                      name="outcome"
                      className='w-100 staan-form-input '
                      style={{height:"8rem"}}
                      rows="4"
                      cols="50"
                    />
                    <label htmlFor="outcome" className='staan-form-input-label pt-0 px-1 mx-2' onClick={()=>{console.log(values);
                    }} >Outcome <span className='text-danger'>*</span></label>
                    <ErrorMessage name="outcome" component="div" /> 
                  </div>
                  <div className="col-6 staan-input-group">
                    <Select
                      onInputChange={(e)=>{fetchOptions(e)}}
                      name='salesperson'
                      isClearable
                      value={salesPersonValue}
                      onChange={(option)=>{
                        setFieldValue("salesperson", option? option?.value: null )
                        setSalesPersonValue(option)
                      }}
                      options={salesPersonList}
                      styles={customSelectStyle}  
                      className='my-3'
                    />
                    <ErrorMessage name="salesperson" component="div" />
                    <label htmlFor="salesperson" className='staan-form-input-label pt-0 px-1 mx-2' >Sales Person<span className='text-danger'>*</span></label>
                  </div>
                </div>
                <div className='row'>
                  <div className='text-end'>
                    <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={()=>{  setShowForm(false); setCallEdit(null);
                      setNavList(prve=>prve.map(item=>({...item, isShow: false})))
                     }} >Cancel</button>
                    <button type="submit" className="btn btn-sm btn-outline-success shadow-sm" disabled={isSubmitting} >Save</button>
                  </div>
                </div>
              </Form>

            )
          }}


        </Formik>
           
       

      </div>
    </>
  )
}

export default CallActivities