import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaFile, FaFileExcel, FaFilePdf, FaFileWord, FaImage } from 'react-icons/fa';
import { MdCheckCircle } from "react-icons/md";
import './commantable.css';

const CommanTableView = ({
  title,
  headers,
  rowData,
  inputSection,
  autoAdjust,
  handleautoAdjust,
  newRowAdd,
  getDeleteRowId,
  isOpenModal,
  handleopenModal,
  checkBox,
  handleChecked,
  cellRenderer,
  tooltipMessage,
  isDisabled
}) => {
  const [isOpen, setOpenState] = useState(false)
  const [openRowIndex, setOpenRowIndex] = useState(0)
  const [autoAdjustChecked, setautoAdjustChecked] = useState(false)
  // const [rowId, setRowId] = useState(1)
  const [tempRow, setTempRow] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [editingRow, setEditingRow] = useState(null)

  // const [filterStates, setFilterStates] = useState({});
  // const [activeDropdown, setActiveDropdown] = useState('');
  // const [filterCondition, setFilterCondition] = useState("");

  function toggle (params) {
    setOpenState(!isOpen)
  }
  const addRow = () => {
    let newRow = {}
    headers.map(col => {
      newRow[col?.field] = ''
    })
    let Index = tempRow.length > 0 ? tempRow[tempRow?.length - 1]?.index + 1 : 1
    newRow['index'] = Index
    console.log("Index",Index)

    console.log(tempRow[tempRow?.length - 1]?.index)

    console.log(
      'tempRow[tempRow?.length - 1]?.index + 1',
      tempRow[tempRow?.length - 1]?.index + 1
    )

    setTempRow([newRow, ...tempRow])
    toggle()
    setOpenRowIndex(Index)
  }
  const handleAddRow = () => {
    let current_actives = document.getElementsByClassName(
      'fa-regular fa-floppy-disk'
    )
    if (current_actives.length > 0) {
      alert('Please save any active row before proceeding')
    } else {
      addRow()
    }
  }
  useEffect(() => {
    setTempRow(rowData)
  }, [rowData])
  const handleCheckboxChange = (item, isChecked) => {
    if (isChecked) {
      // Add item to checkedItems
      const updatedCheckedItems = [...checkedItems, item]
      setCheckedItems(updatedCheckedItems)
      // Immediately notify the parent component with the updated checked items
      if (handleChecked) {
        handleChecked(updatedCheckedItems)
      }
    } else {
      // Remove item from checkedItems
      const updatedCheckedItems = checkedItems.filter(
        checkedItem => checkedItem.index !== item.index
      )
      setCheckedItems(updatedCheckedItems)
      // Immediately notify the parent component with the updated checked items
      if (handleChecked) {
        handleChecked(updatedCheckedItems)
      }
    }
  }
  const DocumentIcon = ({ value, data }) => {
    // Get the array of files from the pdfUrl field
    const fileUrls = data[value?.field]
    // Function to determine the icon and color for each file
    const getIcon = (fileUrl, fileId) => {
      const fileExtension = fileUrl.split('.').pop().toLowerCase()

      // Set a larger icon size (fontSize)
      const iconStyle = { fontSize: '1em', color: 'rgb(255, 255, 255)' }

      // Tooltip text will show the file URL
      const tooltip = (
        <Tooltip id={`tooltip-${fileId}`} style={{ fontSize: '12px' }}>
          {fileUrl}
        </Tooltip>
      )

      // Generate the download link
      const downloadLink = fileUrl

      // Render the appropriate button for each file type
      switch (fileExtension) {
        case 'pdf':
          return (
            <OverlayTrigger overlay={tooltip} placement='top'>
              <a href={downloadLink} download>
                <Button
                  variant='danger'
                  className='icon-delete'
                  style={{ padding: '5px', borderRadius: '4px' }}
                >
                  <FaFilePdf icon='fa-regular fa-file-pdf' style={iconStyle} />
                </Button>
              </a>
            </OverlayTrigger>
          )
        case 'doc':
        case 'docx':
          return (
            <OverlayTrigger overlay={tooltip} placement='top'>
              <a href={downloadLink} download>
                <Button
                  variant='primary'
                  style={{ padding: '5px', borderRadius: '4px' }}
                >
                  <FaFileWord style={iconStyle} title='Word Document' />
                </Button>
              </a>
            </OverlayTrigger>
          )
        case 'xls':
        case 'xlsx':
          return (
            <OverlayTrigger overlay={tooltip} placement='top'>
              <a href={downloadLink} download>
                <Button
                  variant='success'
                  style={{ padding: '5px', borderRadius: '4px' }}
                >
                  <FaFileExcel style={iconStyle} title='Excel Document' />
                </Button>
              </a>
            </OverlayTrigger>
          )
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          return (
            <OverlayTrigger overlay={tooltip} placement='top'>
              <a href={downloadLink} download>
                <Button
                  variant='secondary'
                  style={{ padding: '5px', borderRadius: '4px' }}
                >
                  <FaImage style={iconStyle} title='Image File' />
                </Button>
              </a>
            </OverlayTrigger>
          )
        default:
          return (
            <OverlayTrigger overlay={tooltip} placement='top'>
              <a href={downloadLink} download>
                <Button
                  variant='secondary'
                  style={{ padding: '5px', borderRadius: '4px' }}
                >
                  <FaFile style={iconStyle} title='Unknown File Type' />
                </Button>
              </a>
            </OverlayTrigger>
          )
      }
    }

    return (
      <div>
        {fileUrls?.map((file, index) => (
          <div
            key={index}
            style={{ display: 'inline-block', marginRight: '8px' }}
          >
            {value?.label
              ? getIcon(file[value?.label], file.id)
              : getIcon(file[value], file.id)}
            {/* {getIcon(file.documentFile, file.id)} */}
          </div>
        ))}
      </div>
    )
  }
  // const handleFilterChange = (colField, value, condition) => {
  //     setFilterStates((prev) => ({ ...prev, [colField]: { value, condition } }));

  //     if (!value.trim() && !condition) {
  //         setTempRow(rowData);
  //         return;
  //     }

  //     const filteredData = rowData.filter((row) => {
  //         const cellValue = row[colField]?.toString().toLowerCase() || "";

  //         if (condition) {
  //             const numericValue = parseFloat(cellValue);
  //             const inputValue = parseFloat(value);

  //             switch (condition) {
  //                 case "<":
  //                     return numericValue < inputValue;
  //                 case "<=":
  //                     return numericValue <= inputValue;
  //                 case ">":
  //                     return numericValue > inputValue;
  //                 case ">=":
  //                     return numericValue >= inputValue;
  //                 case "between":
  //                     const [min, max] = value.split("-").map(Number);
  //                     return numericValue >= min && numericValue <= max;
  //                 default:
  //                     return false;
  //             }
  //         } else {
  //             return cellValue.includes(value.toLowerCase());
  //         }
  //     });

  //     setTempRow(filteredData);
  // };
  // const renderFilterInput = (col) => {
  //     return (
  //         <div>
  //             <input
  //                 type="text"
  //                 placeholder="Search..."
  //                 value={filterStates[col.field]?.value || ""}
  //                 onChange={(e) => handleFilterChange(col.field, e.target.value, null)}
  //                 style={{ width: "80%", padding: "5px", border: "1px solid #ccc", borderRadius: "4px" }}
  //             />
  //             {col?.filterType && col?.filterType !== "text" && (
  //                 <>
  //                     <IoMdArrowDropdown
  //                         style={{ cursor: "pointer", height: '30px', width: '25px' }}
  //                         onClick={() => setActiveDropdown(col.field === activeDropdown ? null : col.field)}
  //                     /> {col.filterType === "amount" && (
  //                         <div>
  //                             {activeDropdown === col.field && (
  //                                 <div
  //                                     style={{
  //                                         background: "#fff",
  //                                         boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  //                                         padding: '10px',
  //                                         borderRadius: "4px",
  //                                         position: 'absolute',
  //                                         right: '1px',
  //                                         zIndex: 5000,
  //                                     }}
  //                                 >
  //                                     {col.field}
  //                                     <div style={{ textAlign: 'left', width: '100%' }}>
  //                                         <input style={{ textAlign: 'right' }} type="radio" id="lessthan" value="lessthan" />
  //                                         <label for="lessthan">- Less than</label><br />
  //                                         <input type="radio" id="greaterThan" value="greaterThan" />
  //                                         <label for="greaterThan">- Greater than</label><br />
  //                                         <input type="radio" id="lessThanEqual" value="lessThanEqual" />
  //                                         <label for="lessThanEqual">- Less than & Equal</label><br />
  //                                         <input type="radio" id="greaterThanEqual" value="greaterThanEqual" />
  //                                         <label for="greaterThanEqual">- Greater than & Equal</label><br />
  //                                         <input type="radio" id="between" value="between" />
  //                                         <label for="between">- Between</label><br />
  //                                         <input
  //                                             type="text"
  //                                             placeholder={filterCondition === "between" ? "min-max" : "Enter value"}
  //                                             onChange={(e) => handleFilterChange(col.field, e.target.value, filterCondition)}
  //                                             style={{ width: "50%", padding: "5px", border: "1px solid #ccc", borderRadius: "4px" }}
  //                                         />
  //                                         <button style={{ width: '40%' }} type="button" className="btn btn-outline-danger btn-sm ml-2" >
  //                                             Clear
  //                                         </button>
  //                                     </div>
  //                                 </div>

  //                             )}
  //                         </div>

  //                     )}
  //                 </>
  //             )}
  //         </div>
  //     )
  // }

  return (
    <>
      <div className='row'>
        {autoAdjust ? (
          <div className='col-4 text-end'>
            <div className='form-check form-switch pt-3 pb-1'>
              <input
                className='form-check-input'
                type='checkbox'
                id='flexSwitchCheckDefault'
                onChange={() => {
                  handleautoAdjust(!autoAdjustChecked)
                  setautoAdjustChecked(!autoAdjustChecked)
                }}
              />
              <label
                className='form-check-label'
                htmlFor='flexSwitchCheckDefault'
              >
                Auto Adjust
              </label>
            </div>
          </div>
        ) : null}
        {newRowAdd ? (
          <>
            <div className='col-2'>
              <h6
                className='pt-3 pb-1'
                style={{ color: '#5CADA9', fontWeight: 'bold' }}
              >
                {title}
              </h6>
            </div>
            <div className='col-10 text-end'>
              {tooltipMessage ? (
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip id='button-tooltip'>{tooltipMessage}</Tooltip>
                  }
                >
                  <button
                    type='button'
                    className='btn btn-outline-success px-4'
                    style={{ fontSize: '14px', fontWeight: 'bold' }}
                    onClick={() => {
                      handleAddRow()
                    }}
                    disabled={isDisabled}
                  >
                    <i className='bx bxs-plus-circle me-2'></i>New
                  </button>
                </OverlayTrigger>
              ) : (
                <button
                  type='button'
                  className='btn btn-outline-success px-4'
                  style={{ fontSize: '14px', fontWeight: 'bold' }}
                  onClick={() => {
                    handleAddRow()
                  }}
                  disabled={isDisabled}
                >
                  <i className='bx bxs-plus-circle me-2'></i>New
                </button>
              )}
            </div>
          </>
        ) : null}
      </div>
      <div id='comman-input-table'>
        <table className='CommanTable'>
          <thead style={{ backgroundColor: '#e0e0e0' }}>
            <tr>
              {headers
                ?.filter(col => col?.active !== false)
                ?.map((col, index) => (
                  <th
                    key={index}
                    style={{
                      height: '3rem',
                      width: col['flex'] ? `${col['flex']}rem` : 'auto',
                      display: col['hide'] ? 'none' : 'table-cell',
                      textAlign: 'center',
                      padding: '8px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: '#333'
                    }}
                  >
                    <div>{col['headerName']}</div>
                  </th>
                ))}
              {inputSection && (
                <th
                  style={{
                    height: '3rem',
                    width: headers[headers?.length - 1]?.flex
                      ? `${headers[headers?.length - 1]?.flex}rem`
                      : 'auto',
                    textAlign: 'center',
                    padding: '8px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#333'
                  }}
                  onClick={() => {
                    console.log(tempRow)
                  }}
                >
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {tempRow?.length > 0 ? (
              tempRow?.map((item, rowIndex) =>
                isOpen && Number(item?.index) === Number(openRowIndex) ? (
                  <tr
                    key={rowIndex}
                    
                  >
                    <td colSpan={12} style={{ padding: '10px' }}>
                      {React.createElement(inputSection, {
                        itemData: item,
                        setitemData: setTempRow,
                        editData: editingRow,
                        toggle,
                        setEditingRow
                      })}
                    </td>
                  </tr>
                ) : (
                  <tr
                    key={rowIndex}
                    style={{ backgroundColor: '#fff' }}
                    className='comman-table-row'
                  >
                    {headers
                      ?.filter(col => col?.active !== false)
                      ?.map((col, colIndex) => (
                        <td
                          key={colIndex}
                          style={{
                            maxWidth: col?.['flex']
                              ? `${col?.['flex']}rem`
                              : 'fit-content',
                            display: col?.['hide'] ? 'none' : 'table-cell',
                            padding: '8px',
                            textAlign: col?.['textAlign']
                              ? `${col?.['textAlign']}`
                              : 'center',
                            fontSize: '13px',
                            color: '#333',
                            whiteSpace: 'nowrap', // Prevent text from wrapping
                            overflow: 'hidden', // Hide overflowing content
                            textOverflow: 'ellipsis' // Add '...' to truncated text
                          }}
                        >
                          {col?.input === 'file' ? (
                            <DocumentIcon value={col} data={item} />
                          ) : col?.input === 'checked' ? (
                            <input
                              style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                              }}
                              className='form-check-input'
                              type='checkbox'
                              id={`checkbox-${item.index}`}
                              checked={checkedItems.some(
                                checkedItem => checkedItem.index === item.index
                              )}
                              onChange={e =>
                                handleCheckboxChange(item, e.target.checked)
                              }
                            />
                          ) : col?.input === 'bool' ? (
                            item[col?.['field']] ? (
                              <MdCheckCircle
                                style={{
                                  height: '20px',
                                  width: '20px',
                                  color: '#006600'
                                }}
                              />
                            ) : null
                          ) : col?.input === 'S No' ? (
                            rowIndex + 1
                          ) : col?.cellRenderer ? (
                            col?.cellRenderer(item)
                          ) : col?.label ? (
                            item[col?.['field']]?.[col?.['label']]
                          ) : (
                            item[col?.['field']]
                          )}
                        </td>
                      ))}
                    {inputSection && (
                      <td
                        style={{
                          height: '3rem',
                          width: headers[headers?.length - 1]?.flex
                            ? headers[headers?.length - 1]?.flex
                            : 'auto',
                          textAlign: 'center',
                          padding: '8px',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          color: '#333'
                        }}
                      >
                        <button
                          type='button'
                          className='btn btn-outline-info btn-sm px-3 mx-2  rounded-pill'
                          onClick={() => {
                            if (isOpenModal) {
                              if (handleopenModal) {
                                handleopenModal(item)
                              }
                            } else {
                              if (isOpen) {
                                alert(
                                  'Please save any active row before proceeding'
                                )
                              } else {
                                setOpenRowIndex(item?.index)
                                setEditingRow(item)
                                toggle()
                              }
                            }
                          }}
                          disabled={isDisabled}
                        >
                          <i className='fa-solid fa-pen-to-square'></i>
                        </button>
                        <button
                          type='button'
                          className='btn btn-outline-danger btn-sm px-3 mx-2  rounded-pill'
                          onClick={() => {
                            if (isOpen) {
                              alert(
                                'Please save any active row before proceeding'
                              )
                            } else {
                              getDeleteRowId(item)
                            }
                          }}
                          disabled={isDisabled}
                        >
                          <i className='fa-solid fa-trash'></i>
                        </button>
                      </td>
                    )}
                  </tr>
                )
              )
            ) : (
              <tr>
                <td
                  colSpan={headers?.length + (inputSection ? 1 : 0)}
                  style={{
                    textAlign: 'center',
                    height: '5rem',
                    fontSize: '14px',
                    color: '#666'
                  }}
                >
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default CommanTableView
