export const leadCurrencyList = () => {
  return `
      query MyQuery {
        currencyMaster {
          items {
            id
            name
          }
        }
      }
    `
}

export const leadFormEditQuery = (id) => {
  return `query MyQuery {
  allLead(id:${id}){
    items {
      expectedClosingDate
      createdBy{
        id
      }
      id
      status{
        id
        name
      }
      leadName
      leadNo
      leadValue
      requirement
      rextFollowUp
      quotationsSet {
        id
        active
        quotationNo {
          linkedModelId
        }
        status{
          id
          name
        }
          
           CreatedAt
        createdBy{
          username
        }
      }
      customer {
        id
        companyName
      }
        leadCurrency{
      id
      rate
        Currency{
          id
          name
          currencySymbol
        }

			}
        salesPerson{
        id
        username
      }
      Enquiry {
        id
         conferenceData{
          name
        }
        linkContactDetalis {
          contactPersonName
          email
          phoneNumber
          whatsappNo
        }
           state{
          stateName
        }
        district{
          district
        }
      }
    }
    
  }
}`
}

export const leadTimeLine = (id)=>{
  return `
  query MyQuery {
  leadTimeLine(leadId: ${id}) {
    id
    date
    isShow
    label
    status
    Number
    children {
      date
      id
      isShow
      label
      status
      Number
      children {
        Number
        date
        id
        isShow
        label
        status
      }
    }
  }
}
  `
}