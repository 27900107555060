import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CustomSelect from '../../components/ReactSelect/reactSelect';
import DataContext from '../../context/ItemMasterContext';
const ShowCurrencyConversion = ({isShow, setIshow, currencySymbols, currencyrate, netAmount}) => {
    const {prefetchValue} = useContext(DataContext)
    const [currencyConversionDatas, setCurrencyConversionDatas] = useState({selectedCurrency:{value : "",label: ""}, convertedValue:""})
    function close(params) {
        setIshow()
        setCurrencyConversionDatas({selectedCurrency:{value : "",label: ""}, convertedValue:""})
    }
    return (
        <>
        <Modal show={isShow} onHide={close} size='xl' centered backdrop>
            <Modal.Header closeButton>
            <Modal.Title>Currency Conversion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div className="row d-flex justify-content-around py-5" >
                        <div className="col-2 staan-input-group" >
                            <CustomSelect
                                setSelectEditValue={{label:currencySymbols, value:currencySymbols}}
                                options={[]}
                                isDisabled
                            />
                            <label
                            className='staan-form-input-label pt-0 px-1 mx-2'>
                            Currency
                            </label>
                        </div>
                        <div className="col-2 staan-input-group">
                        <input
                            type="text"
                            id='pay_amount'
                            disabled
                            value={currencySymbols+netAmount}
                            className='staan-form-input text-end pe-3 fw-bold'
                        />
                        <label
                            className='staan-form-input-label pt-0 px-1 mx-2'>
                            NetAmount <span className='text-danger'>*</span>
                        </label>
                        </div>
                        <div className="col-2 staan-input-group">
                            <CustomSelect
                            options={prefetchValue?.currencyExchangeList}
                            isClearable
                            handleOnChange={(option)=>{
                                if (option){
                                    let convertToRupes =  (netAmount*currencyrate)
                                    let convertToCurrent = (convertToRupes /option?.rate).toFixed(2)
                                    setCurrencyConversionDatas({selectedCurrency:option,
                                        convertedValue:option?.currencySymbol+convertToCurrent})
                                    
                                    
                                } else{
                                    setCurrencyConversionDatas({selectedCurrency:{value : "",label: ""}, convertedValue:""})
                                }
                            }}
                            />
                            <label
                            className='staan-form-input-label pt-0 px-1 mx-2'>
                            Currency
                            </label>
                        </div> 
                        <div className="col-2 staan-input-group">
                        <input
                            type="text"
                            id='pay_amount'
                            disabled
                            value={currencyConversionDatas?.convertedValue}
                            className='staan-form-input text-end pe-3 pos_pay_amount fw-bold'
                        />
                        <label
                            className='staan-form-input-label pt-0 px-1 mx-2'>
                            Conversion Rate
                            </label>
                        </div>
                    </div>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default ShowCurrencyConversion