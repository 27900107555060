import React, { useContext, useEffect, useState } from 'react';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
// import Select from 'react-select'; 
// import PrintComponent from './StockStatementPrint/PrintComponent';
import DataContext from '../../context/ItemMasterContext';
import TableView from '../../Packages/Custom/DataTable';
import StockStatementDetails from './Details/StockStatementDetails';

const StockStatement = () => {
  const { screenSize } = useContext(DataContext)
  const [isShowSelectedStockStatement, setShowSelectedStockStatement] = useState(false)
  const [selectedPartNo, setSelectedPartNo] = useState(null)
  const handleShowSelectedStockStatement = () => setShowSelectedStockStatement(true);
  const handleCloseSelectedStockStatement = () => setShowSelectedStockStatement(false);
  const [stockStatement, setStockStatement] = useState(null)
  // const [totalQuantity, setTotalQuantity] = useState(0)
  const [isLoad, setLoad] = useState(false);


  const CustomViewModalComponent = (data) => {
    setSelectedPartNo(data?.id)
    handleShowSelectedStockStatement()
    setStockStatement(data)
    setLoad(!isLoad);
  }

  const stock_statement_column_details = [
    {
      header: "Part Code",
      flex: 5,
      field: 'partCode',
      inputType: 'text',
      query: 'itemMasterCustomOptionFetchQuery',
      label: 'itemPartCode',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: "Part Name",
      field: "partName",
      flex: 5,
      inputType: 'text',
      query: 'itemMasterCustomOptionFetchQuery',
      label: 'itemName'
    },
    {
      header: "Qty",
      field: "currentStock",
      flex: 5,
      inputType: 'number',
    },
    {
      header: "Store",
      field: "stores",
      flex: 5,
      inputType: 'text',
      option: true,
      query: 'storeFetchQuery',
      label: 'storeName'
    },
    {
      header: "Stock Group",
      field: "itemGroup",
      label: "name",
      flex: 5,
      inputType: 'text',
      option: true,
      query: 'ItemGroupOptionQuery'
    },
  ]

  // const handleTotalQuantityCalculation = (stockStatement) => {
  //   let all_qty = stockStatement.map(item => item.qty)
  //     let total_qty = 0
  //     all_qty.forEach(function (number) {
  //       total_qty += number;
  //     });
  //   return total_qty
  // }

  useEffect(() => {
    localStorage.setItem('selected_stock_store', null)
    localStorage.setItem('selected_stock_group', null)
    localStorage.setItem('part_id', null)
  }, []);

  return (
    <>

      <div className="container-fluid index_page_aliment_warp" >
        {/* <div className="itemMaster_Top mx-3 mt-3" style={{   width: "100%", height:"fit-content" }}>
              <div className="row py-3 ps-3">
                <div className="col-5 my-auto">
                  <h3>Stock Statement</h3>
                </div>
              <div className='col-7'>
                    <div className='row mx-3'>
                      <div className='col-3'>
                        <label htmlFor="exampleFormControlInput1" className="form-label fw-bold" >Total Quantity</label>
                        <p>{totalQuantity}</p>
                      </div>
                      <div className='col-4'>
                          <label htmlFor="exampleFormControlInput1" className="form-label fw-bold" >Stock Group</label>
                          <Select options={ItemGroupdata.map(item => ({value: item.id, label: item.name}))} 
                          value={selectedStockGroup} 
                          isClearable
                          onChange={(option)=>{handleSelectStockGroup(option)}} />
                      </div>
                      <div className='col-4'>
                          <label htmlFor="exampleFormControlInput1" className="form-label fw-bold" >Store</label>
                          <Select options={storedata.map((item)=>({value: item.id, label: item.StoreName}))} 
                          value={storeSelect} 
                          isClearable
                          onChange={(option)=>{handleSelectStore(option)}} />
                      </div>
                      <div className='col-1 text-end d-flex align-items-center'>
                        <PrintComponent 
                          storeSelect = {storeSelect}
                          selectedStockGroup = {selectedStockGroup}
                          totalQuantity = {totalQuantity}
                          print_data = {post} />
                      </div>
                    </div>
              </div>
              </div>
          </div> */}
        <div className='index_page_aliment_sidebar'>
          <SideNavbar />
        </div>
        <div className='index_page_aliment_table'>

          <TableView
            headers={stock_statement_column_details}
            query={'StockStatementQuery'}
            loadComponent={isLoad}
            titleName={'Stock Statement'}
            heightAdjust={`${screenSize?.height / 20}rem`}
            customFilter={true}
          />

        </div>
        {isShowSelectedStockStatement && <StockStatementDetails
          isShowSelectedStockStatement={isShowSelectedStockStatement}
          handleCloseSelectedStockStatement={handleCloseSelectedStockStatement}
          stockStatement={stockStatement}
          part_id={selectedPartNo}
        />}
      </div>
    </>
  )
}

export default StockStatement