import { useEffect, useState } from "react";
import { defaultPageSize } from "../TableUtils/TableUtils";

const TablePagination = ({handlePageSizeChange, getSelectedPageNumber, pageNumber,  totalPages, pageSize, totalItems, hasPreviousPage, hasNextPage}) => {
    const [totalItemsPerPage, setTotalItemsPerPage] = useState(defaultPageSize);
    const [paginationNumbers, setPaginationNumbers] = useState([1, 2, 3])
    const [currentPageNumber, setCurrentPageNumber] = useState(pageNumber)

    // returns nothing; updates state of page number
    const handleSelectPage = (page_number) => {
        /**
         *  updating state of current page number when user clicks 
         */
        setCurrentPageNumber(page_number)
        getSelectedPageNumber(page_number)
    }

    // returns nothing; updates state of page number with previous page number
    const goToPreviousPage = () => {
        /**
         *  updating state of previous page number from the current page number when user clicks 
         */
        setCurrentPageNumber(currentPageNumber-1)
        getSelectedPageNumber(currentPageNumber-1)
    }

    // returns nothing; updates state of page number with next page number
    const goToNextPage = () => {
        /**
         *  updating state of next page number from the current page number when user clicks 
         */
        console.log('currentPageNumber', currentPageNumber, typeof currentPageNumber);
        
        setCurrentPageNumber(Number(currentPageNumber)+1)
        getSelectedPageNumber(Number(currentPageNumber)+1)
    }

    // returns nothing; updates state of page number with last page number
    const goToLastPage = () => {
        /**
         *  updating state of last page number when user clicks 
         */
        setCurrentPageNumber(totalPages)
        getSelectedPageNumber(totalPages)
    }

    // returns nothing; updates state of page number with first page number
    const goToFirstPage = () => {
        /**
         *  updating state of fisrt page number when user clicks 
         */
        setCurrentPageNumber(1)
        getSelectedPageNumber(1)
    }


    useEffect(()=>{
        if(totalPages === 0){
            setCurrentPageNumber(1)
        }else if(pageNumber > totalPages){
            setCurrentPageNumber(totalPages)
        }
        let pagination_numbers = [pageNumber];
        console.log(pageNumber);
        
        console.log(pagination_numbers);
        
        // if(currentPageNumber===1){
        //     pagination_numbers = [1, 2, 3]
        // }else if(currentPageNumber === totalPages){
        //     if(totalPages <= 3){
        //         console.log('else if if')
        //         pagination_numbers = customRange(totalPages)
        //     }else{
        //         console.log('else if else')
        //         pagination_numbers = [currentPageNumber-2, currentPageNumber-1, currentPageNumber]
        //     }
        // }else{
        //     if(totalPages <= 3){
        //         console.log('else if')
        //         pagination_numbers = customRange(totalPages)
        //     }else{
        //         console.log('else else')
        //         pagination_numbers = [currentPageNumber-1, currentPageNumber, currentPageNumber+1]
        //     }
        // }
        setPaginationNumbers(pagination_numbers)
        setTotalItemsPerPage(pageSize)
    }, [currentPageNumber, totalItemsPerPage,  totalPages, pageNumber])

    return(
        <>
            <div className="d-flex justify-content-between mx-3 my-3" >
                <div style={{width: '30%'}} className="d-flex justify-content-between">
                    <div className="d-flex">
                        <span>{totalItems} items</span>
                        <span className="mx-2">|</span>
                        <span>Rows Per Page </span>
                        <select 
                            className="mx-2" 
                            defaultValue='10'
                            onChange={(e)=>{setTotalItemsPerPage(e.target.value); handlePageSizeChange(e.target.value)}}>
                            <option value='10'>10</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='200'>200</option>
                            <option value='300'>300</option>
                            <option value='500'>500</option>
                        </select>
                    </div>
                </div>
                <div style={{width: '15%'}} className="d-flex justify-content-between">
                    <span>
                        {hasPreviousPage && <i className="fa-solid fa-angles-left" onClick={()=>{goToFirstPage()}} role="button"></i>}
                    </span>
                    <span>
                        {hasPreviousPage &&  <i className="fa-solid fa-angle-left" onClick={()=>{goToPreviousPage()}} role="button"></i>}
                    </span>
                    {paginationNumbers.map(item => {
                        return <span 
                                    role="button" 
                                    className={`px-1 mx-1 prevent-select ${currentPageNumber === item ? 'border border-primary border-1': ''}`}
                                    key={item}
                                    onClick={()=>{handleSelectPage(item)}}
                                >
                                    {item}
                                </span>
                    })}
                    <span>
                        {hasNextPage && <i className="fa-solid fa-angle-right" onClick={()=>{goToNextPage()}} role="button"></i> }
                    </span>
                    <span>
                        {hasNextPage && <i className="fa-solid fa-angles-right" onClick={()=>{goToLastPage()}} role="button"></i> }
                    </span> 
                    <span className="px-2">|</span>
                    <span>{totalPages} Pages</span>
                </div>
            </div>
        </>
    )
}


export default TablePagination;