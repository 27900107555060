import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {  Field, Form } from 'formik';
import { customSelectStyle } from '../../../utils/Utils';
import Button from 'react-bootstrap/Button';

const Serviceable = ({touched,errors ,serviceable,handleserviceableClose, setFieldValue
, values, dupData, initalData, ItemMasterEditDisabled}) => {
    const WarrantyBasedOptions = [
        { value: 'Invoice date', label: 'Invoice date' },
        { value: 'Installation date', label: 'Installation date' },
       
      ]

    const [warranty, setWarranty] = useState()
    const [installation, setInstallation] = useState()
    const [warrantyBased, setWarrantyBased] = useState()
    const [formKey, setFormKey] = useState(0);

    useEffect(()=>{
      if (dupData){ 
        setInstallation(dupData['itemInstallation'])
        setFieldValue("Item_Installation", dupData['itemInstallation'])
        setWarranty(dupData['itemWarrantyBased'] ? true : false)
      
        if (dupData['itemWarrantyBased'] === "INSTALLATION_DATE"){ 
          setWarrantyBased({ value: "Installation date", label: "Installation date"})
        } else if(dupData['itemWarrantyBased'] === "INVOICE_DATE"){  
          setWarrantyBased({ value: "Invoice date", label: "Invoice date"})
        } else{
          setWarrantyBased()
        }
        
      }else if(initalData){
        setInstallation(initalData['Item_Installation'])
        setFieldValue("Item_Installation", initalData['Item_Installation'])
        setWarranty(initalData['Warranty_Require'] ? true : false)
      
        if (initalData['Item_Warranty_based'] === "INSTALLATION_DATE"){ 
          setWarrantyBased({ value: "Installation date", label: "Installation date"})
        } else if(initalData['Item_Warranty_based'] === "INVOICE_DATE"){  
          setWarrantyBased({ value: "Invoice date", label: "Invoice date"})
        } else{
          setWarrantyBased()
        }
      }
      setFormKey((prevKey) => prevKey + 1); 
    },[dupData])

  return (
    <>
    <Form>
    <Modal show={serviceable} onHide={()=>{
            handleserviceableClose()
            // values.Service = false
          }}
    size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Item Serviceable</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
        <div className='row mx-2'>
            <div className="col-6 staan-input-group">
              <Field type="checkbox" name="Warranty_Require"  
              className='me-2' disabled = {ItemMasterEditDisabled} />
              <label style={{fontSize: '.8rem'}} className='pb-2'>Warranty Required</label>
            </div>
            <div className="col-6 staan-input-group text-end">
              <Field type="checkbox" name="Item_Installation" 
              disabled = {ItemMasterEditDisabled}
              className='me-2' />
              <label style={{fontSize: '.8rem'}} className='pb-2'>Item Installation</label>
            </div>
          </div>
          <div className="row mx-2 mt-3">
            <div className="col-6 staan-input-group"> 
                <Select
                    name="Item_Warranty_based"
                    options={WarrantyBasedOptions}
                    isSearchable={true}
                    value={warrantyBased}
                    isDisabled={!values.Warranty_Require && ItemMasterEditDisabled}
                    isClearable
                    onChange={(option) => {setFieldValue('Item_Warranty_based', option ? option.value : null); 
                    setWarrantyBased( { value: option?.value , label:  option?.label });}}
                    className='my-3 bg-white'
                    id="Item_Warranty_based" 
                    styles={customSelectStyle}
                />
                <label htmlFor="Item_Warranty_based" className='staan-form-input-label pt-1 px-1 mx-2'>Item Warranty based</label>
                {touched.Item_Warranty_based &&  errors.Item_Warranty_based && <small>{errors.Item_Warranty_based}</small>}
            </div>
            <div className='col-6 d-flex align-items-center justify-content-end'>
                <div className='text-end'>
                  {ItemMasterEditDisabled && <button type="submit" 
                   onClick={handleserviceableClose}
                  className="px-3 btn btn-sm btn-outline-success shadow-sm">Save</button> }
                </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </Form>
    </>
  )
}

export default Serviceable