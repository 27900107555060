import React from 'react'
import error from "../../img/error.gif"
import "./Unauthorized.css"

const Unauthorized = () => {
    // const {Navigate,  } = useContext(DataContext)
    // const goback = ()=> Navigate(-1)
  return (
     <>
        {/* 
        <div>
            <button >
                Go Back
            </button>
        </div> */}
         <div className="d-flex justify-content-center align-items-center " style={{height:`100vh`, backgroundColor:"#07080b"}}>
            <div className="container text-center  " style={{height:`50vh`}}>
                <div className="row">
                    <div className="col-12 col-md-6 mb-4 mb-md-0 align-self-center">
                        <p style={{fontSize:"8rem"}} className='text-white'>404</p>
                        <h1 className='text-white'>Unauthorized</h1>
                        <h5 className='text-white fw-normal' >You do not have access to the requested page.</h5>
                     
                        {/* <button type="button" class="UnauthorizedBackbutton  " onClick={()=>(goback())} role="button"><span class="text fs-5"> Back</span></button> */}
                    </div>
                    <div className="col-12 col-md-6">
                        <img 
                            src={error} 
                            alt="Error" 
                            style={{ maxWidth: "100vw", maxHeight: "20rem", width: "auto", height: "auto" }} 
                        />
                    </div>
                   
                </div>
            </div>
        </div>
     </>
  )
}

export default Unauthorized