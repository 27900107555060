import React, { useContext, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { IoMdAddCircleOutline } from "react-icons/io";
import Reactselect from 'react-select';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import WaringModel from '../../../components/Warings/WaringModel';
import DataContext from '../../../context/ItemMasterContext';
import { customSelectStyle, DateFormate } from '../../../utils/Utils';

const PosPaymentModel = ({isShow,handleCloseModal, posValues, handleCurrentReceivePay, paymentInputs, setpaymentInputs,
    posPamentValue,setPosPamentValue
}) => {

    const {AccountMasterData, userId} = useContext(DataContext)
    
    let NeedAccountType = ['Bank', 'Cash', "Swipe"]
    let initialPayByOptions = AccountMasterData?.filter(item => NeedAccountType.includes(item.accountType) && item.allowReceipt === true).map(item => ({label: item.accountsName, value: item.id}))
    useEffect(()=>{
        if (isShow){
            if (posValues?.isSales){
                setPosPamentValue(prev=>({...prev, payby_Options:initialPayByOptions}))
            }else{
                let updated_pay_options = AccountMasterData?.filter(item => item.accountType === 'Cash' && item.allowReceipt === true).map(item => ({label: item.accountsName, value: item.id}))
                setPosPamentValue(prev=>({...prev, payby_Options:updated_pay_options}))
            }

        }
    },[isShow])
    
    // return null; add new row
    const handeleAddRow =(index)=>{
        setpaymentInputs(prev=>([...prev, {
            index: paymentInputs.length,
            id: "",
            payby: {
                id: "",
                accountsName: ""
            },
            payAmount: "",
            balanceAmount: "",
        }] ))
        setPosPamentValue(prev=>({...prev, currentIndex: paymentInputs.length,}))
        
    }

    // return null ; on saverow update the payments
    const handlePaymentData = (data, index)=>{
        let pay_amount = data
        let blnc_amt = paymentInputs[index-1]?.balanceAmount ? paymentInputs[index-1]?.balanceAmount : posValues?.toPay
        let current_outstanding = Number(blnc_amt) - Number(pay_amount)
        if(String(data).trim() === ''){
                showErrorToast(true,"error", "Please Enter Pay Amount !","")
            return {success:false, error:"Please Enter Pay Amount !", blance:""};
        }else if(current_outstanding < 0){
            showErrorToast(true,"error", "Entered Amount Exceeds Pay Amount","")
            return {success:false, error:"Entered Amount Exceeds Pay Amount", blance:""};
        }else{
            if (current_outstanding > 0 || current_outstanding < 0) {
                setPosPamentValue(prev=>({...prev,paymentCompleted : false }))
            }else{
                setPosPamentValue(prev=>({...prev,paymentCompleted : true }))
            }
            return {success:true, error:"", blance:current_outstanding.toFixed(2)};
        }
    }
    // return null ; on save the rows
    const saveRow = (index)=>{
          // Assuming handlePaymentData returns the balance amount
        let balanceresponce = handlePaymentData(posPamentValue?.payAmount, index);
        if (balanceresponce?.success){
            // Creating the object to save
            let saveObjects = {
                index: index,
                id: "",
                payby: posPamentValue?.payby,
                payAmount: posPamentValue?.payAmount,
                balanceAmount: balanceresponce?.blance,
                createdBy: Number(userId)
            };
            // Updating the state correctly
            setpaymentInputs(prev => prev.map(item =>
                item?.index === index ? { ...item, ...saveObjects } : item
            ));
            
            setPosPamentValue(prev=>({...prev,payby:{label: "", value: ""},payAmount:"", blance:null, currentIndex:null  }))
        }
    }

    // return null ; on delete update the balance
    const handleAllPaymentsChange = (data) => {
        let blnc_amt = Number(posValues?.toPay)
        let final_data = data?.map((item, ind)=>{
            let current_outstanding = blnc_amt - item.payAmount
            blnc_amt = current_outstanding.toFixed(2)
            return {...item,index:ind,  balanceAmount: current_outstanding.toFixed(2)}
        })
        return final_data
    }

    // return  null; update the payment
    const handleDeletePayment=(params)=>{
        let changedData ;
        if (params){
            let removeDaleteData = paymentInputs?.filter(item => item?.index !== posPamentValue?.deleteData)
            if (removeDaleteData?.length > 0){
                changedData = handleAllPaymentsChange(removeDaleteData)
                let blance_pay = changedData[changedData?.length - 1]?.balanceAmount
                if (blance_pay > 0 ){
                    setPosPamentValue(prev=>({...prev,  paymentCompleted:false, currentIndex:null}))
                }
            } else{
                changedData=[{
                    index:0,
                    id: "",
                    payby: {
                        label: "",
                        value: ""
                    },
                    payAmount: "",
                    balanceAmount: "",
                }]
                setPosPamentValue(prev=>({...prev, currentIndex:0, paymentCompleted:false, blance:""}))
            }
            setpaymentInputs(changedData)
        }
    }
    // return null; close the model
    function closeTheModel() {
        if (posPamentValue?.currentIndex){
                if (paymentInputs?.length > 1){
                    showErrorToast(true,"error", "Clead The Unfield Input","")
                } else{
                    handleCloseModal()
                }
            
        } else{
            handleCloseModal()
            let TotalPayAmount = paymentInputs?.map(item => item?.payAmount).reduce((acc, curr) => acc + (parseFloat(curr) || 0), 0);
            handleCurrentReceivePay(TotalPayAmount)
        }
    }

    return (
        <Modal show={isShow}  size="xl"  onHide={closeTheModel}  backdrop="static"
        keyboard={false} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5 onClick={()=>{console.log(paymentInputs);
                    }}>POS Payment</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {paymentInputs.length > 0 &&
                paymentInputs?.map((item, index)=>(
                    <>
                        <div key={index} className="row payment-data-input">
                            <div className="col-2">
                                {DateFormate(item?.CreatedAt)}
                            </div>
                            <div className="col-3 staan-input-group">
                                <Reactselect
                                    name={`rows.${index}.payby`}
                                    options={posPamentValue?.payby_Options}
                                    className='mt-3 fw-bold'
                                    value={posPamentValue?.currentIndex === item?.index ? posPamentValue?.payby: item?.payby}
                                    isDisabled={item?.id? true : posPamentValue?.currentIndex === item?.index ? false: true}
                                    onChange={(options)=>{
                                        setPosPamentValue(prev=>({...prev, payby:options}))
                                    }}
                                    styles = {customSelectStyle}
                                />
                                <label  onClick={()=>{console.log(item, posPamentValue);
                                }} className='staan-form-input-label pt-1 px-1 mx-2'>Pay BY</label>
                            </div>
                            <div className="col-3 staan-input-group">
                                <input
                                    type="text"
                                    id='pay_amount'
                                    disabled={item?.id? true : posPamentValue?.currentIndex === item?.index ? false: true}
                                    name={`rows.${index}.pay_amount`}
                                    value={posPamentValue?.currentIndex === item?.index ? posPamentValue?.payAmount : item?.payAmount}
                                    onChange={(e)=>{
                                        setPosPamentValue(prev=>({...prev, payAmount:e?.target?.value?.trim()}))
                                    }}
                                    className='staan-form-input text-end pe-3 pos_pay_amount fw-bold'
                                />
                                <label htmlFor="pay_amount" className='staan-form-input-label pt-2 px-1 mx-2'>Pay Amount</label>
                            </div>
                            <div className="col-2 staan-input-group">
                                <input
                                    type="text"
                                    name={`rows.${index}.balance_amount`}
                                    id='balance_amount'
                                    disabled
                                    className='staan-form-input text-end pe-3 fw-bold'
                                    value = {posPamentValue?.currentIndex === item?.index ? posPamentValue?.blance : item?.balanceAmount}
                                />
                                <label htmlFor="balance_amount" className='staan-form-input-label pt-2 px-1 mx-2' onClick={()=>{console.log(paymentInputs?.length-1 ,  item?.index)
                                
                                
                                }}>Balance Amount</label>
                            </div>
                            <div className="col-2 d-flex align-items-end justify-content-center mb-1">
                                <button type='button' className='btn btn-sm btn-outline-success px-3 ms-3'
                                disabled={item?.id? true : posPamentValue?.currentIndex === item?.index ? false: true}
                                onClick={()=>{ saveRow(item?.index)}}>
                                    <i class="fa-solid fa-check"></i>
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-outline-danger ms-3'
                                    onClick={()=>{setPosPamentValue(prev=>({...prev, deleteWaring:true, deleteData:item?.index}))}}
                                >
                                <i class="fa-solid fa-trash px-1"  style={{afontSize:"1rem", margin:".2rem"}}></i>
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-outline-primary ms-3 px-3'
                                    disabled={posPamentValue?.paymentCompleted? true : posPamentValue?.currentIndex === item?.index ? true: paymentInputs?.length-1 === item?.index? false : true}
                                    onClick={()=>{handeleAddRow(item?.index)}}
                                >
                                <IoMdAddCircleOutline className=''/>
                                </button>
                            </div>
                        </div>
                    </>
                ))
            }
            </Modal.Body>
        
            {posPamentValue?.deleteWaring &&
                <WaringModel
                state={posPamentValue?.deleteWaring}
                setstate={()=>{
                    setPosPamentValue(prev=>({...prev, deleteWaring:false}))
                }}
                title={"Confirm"}
                text={"Confirm to delete"}
                Confirmtion={value => {
                    if (value) {
                        handleDeletePayment(value)
                    }
                }}
                />}
            <div  className='d-flex justify-content-center mt-3'>
                <div className="text-center">
                    <button
                    type="submit" 
                    className={`btn btn-sm btn-outline-success px-5 m-3`} 
                    onClick={closeTheModel}
                    >
                    Save
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default PosPaymentModel
