export const SalesOrderEditQuery = (data) => {
    // id: 6
    return `
    query MyQuery {
        allSalesorder2(${data}){
        items{
            id
            active
            CreatedAt
            UpdatedAt
            salesOrderDate
            currency{
            id
            rate
            Currency{
              name
              currencySymbol
            }
            }
            status{
            id
            name
            }
            quotations{
              id
              quotationNo{
                 linkedModelId
              }
            }
            leadNo{
            id
            leadNo
            }
             quotations{
            id
            quotationNo{
              linkedModelId
            }
            }
            salesOrderNo{
            id
            linkedModelId
            }
            salesPerson{
                id
                username
            }
                
            childCount
            dueDate
            creditPeriod
            paymentTerms
            customerPoNo
            customerPoDate
            department{
                id
                name
            }
            buyer{
                id
                companyName
                supplierNo
              	address{
                  id
                  addressType
                  addressLine1
                  addressLine2
                  city
                  state
                  country
                  pincode
                }
              	contact{
                  id
                  contactPersonName
                  phoneNumber
                  email
                }
            }
            buyerAddress{
                id
                addressType
                addressLine1
                addressLine2
                city
                state
                country
                pincode
            }
            buyerContactPerson{
                id
                contactPersonName
                phoneNumber
                email
            }
            buyerGstinType
            buyerGstin
            buyerState
            buyerPlaceOfSupply
            consignee{
                id
                companyName
                supplierNo
                address{
                    id
                    addressType
                    addressLine1
                    addressLine2
                    city
                    state
                    country
                    pincode
                }
                contact{
                    id
                    contactPersonName
                    phoneNumber
                    email
                }
            }
            consigneeAddress{
                id
                addressType
                addressLine1
                addressLine2
                city
                state
                country
                pincode
            }
            consigneeContactPerson{
                id
                contactPersonName
                phoneNumber
                email
            }
            consigneeGstinType
            consigneeGstin
            consigneeState
            consigneePlaceOfSupply
            itemDetails{
                afterDiscountValueForPerItem
                description
                amount
                cgst
                createdAt
                discountPercentage
                discountValue
                finalValue
                id
                itemmaster{
                id
                itemHsn{
                  gstRates{
                    rate
                    }
                  hsnCode
                  id
                }
                itemMrp
                itemPartCode
                itemName
                }
                qty
                rate
                igst
                sgst
                tax
                updatedAt
                uom{
                id
                name
                }
                createdBy{
                id
                username
                }
                modifiedBy{
                  id
                }
              	itemCombo
                itemComboItemDetails{
                  id
                createdBy{
                  id
                }
                qty
                uom{
                    id
                    name
                  }
                amount
                afterDiscountValueForPerItem
                isMandatory
                display
                rate
                }
            }
            otherIncomeCharge{
                otherIncomeChargesId{
                id
                name
                hsn{
                    id
                    hsnCode
                }
            }
                createdBy{
                id
            }
            afterDiscountValue
            discountValue
            tax
            amount
            createdAt
            igst
            cgst
            sgst
            id
            }
            termsConditions{
            id
            name
            }
            termsConditionsText
            CreatedAt
            createdBy{
            id
            username
            }
            itemTotalBeforTax
            otherChargesBeforTax
            taxTotal
            overallDiscountPercentage
            overallDiscountValue
            discountFinalTotal
            roundOff
            netAmount
            igst
            cgst
            sgst
            }
        version{
        versionList
        }
        }
    }
    `
}