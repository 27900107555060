import { useContext, useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import DataContext from '../../context/ItemMasterContext'
import TableView from '../../Packages/Custom/DataTable'
import LeadForm from './modals/LeadForm'
import LeadFormEdit from './modals/LeadFormEdit'
import LeadReport from './report/LeadReport'

const Lead = () => {
  const { screenSize,leadModels,openLeadModal} = useContext(DataContext)
  const [isLoad, setLoad] = useState(false);
  const [isshowLeadForm, setShowLeadForm] = useState(false)
  const [isShowLeadReport, setShowLeadReport] = useState(false)
  const [exportDataList, setexportDataList] = useState([])

  const CustomViewModalComponent = (data) => {
    openLeadModal(data?.id)
  }

 
  const headers = [
    {
      header: 'Lead Number',
      field: 'leadNo',
      flex: 5,
      inputType: 'text',
      align: 'center',
      renderComponent: CustomViewModalComponent,
    }, {
      header: 'Lead Name',
      field: 'leadName',
      flex: 7,
      inputType: 'text',
      align: 'start'
    }, {
      header: 'Customer',
      field: 'customer',
      label: 'companyName',
      option: true,
      inputType: 'text',
      flex: 7,
      align: 'start'
    }, {
      header: 'Lead Currency',
      field: 'leadCurrency?.Currency',
      label: 'name',
      option: true,
      flex: 7,
      inputType: '',
      align: 'center'
    }, {
      header: 'Lead Value',
      field: 'leadValue',
      flex: 7,
      inputType: '',
      align: 'center'
    }, {
      header: 'Expected Closure Date',
      field: 'expectedClosingDate',
      flex: 7,
      inputType: 'text',
      align: 'center',
      isDateField: true,
    }, {
      header: 'Lead Status',
      field: 'status',
      label: 'name',
      option: true,
      flex: 7,
      inputType: 'text',
      align: 'start'
    }, {
      header: 'Sales person',
      field: 'salesPerson',
      label: 'username',
      option: true,
      flex: 7,
      inputType: '',
      align: 'start'
    }, {
      header: 'Source',
      field: 'Enquiry',
      label: 'conferenceData.name',
      option: true,
      flex: 7,
      inputType: '',
      align: 'start'
    }
  ]
  let exportData = (data) => {
    setShowLeadReport(true)
    setexportDataList(data)

  }
  const CustomAddModalComponent = data => {
    setShowLeadForm(true)
    // setleadFormEditData(null)
    // setleadFormEditShow(true)
  }
  return (
    <>
      <div className='container-fluid index_page_aliment_warp'>
        <div className='index_page_aliment_sidebar'>
          <SideNavbar />
        </div>
        <div className='index_page_aliment_table'>
          <TableView
            headers={headers}
            query={'LeadTableQuery'}
            addComponent={CustomAddModalComponent}
            titleName={'Lead'}
            loadComponent={isLoad}
            heightAdjust={`${screenSize?.height / 20}rem`}
            exportData={exportData}
            permission={'Create_Lead'}
            customFilter={true}
          />
        </div>
      </div>

      {leadModels?.map((item)=>(
        <LeadFormEdit
        index={item?.index}
        editData={item?.id}
        setload={() => { setLoad(!isLoad) }}
        />
      ))}
      
      {isShowLeadReport &&
        <LeadReport
          show={isShowLeadReport}
          onClose={() => { setShowLeadReport(false) }}
          exportDataList={exportDataList}
        ></LeadReport>}
      {isshowLeadForm &&
        <LeadForm
          showLeadForm={isshowLeadForm}
          setShowLeadForm={setShowLeadForm}
          isNewLead={true}
        />
      }
    </>
  )
}

export default Lead
