import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ReactSelect from 'react-select'
import { ToastContainer } from 'react-toastify'
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import {
  getBatchOrSerial
} from '../../../GraphQLQueries/ItemmasterQuery'
import { posItemmasterfetch } from '../../../GraphQLQueries/posQueryAndMutations/posQuerys'
import { customSelectStyle } from '../../../Packages/Custom/TableUtils/TableUtils'
import { OnchangeGetData, ShowErrorNoties } from '../../../utils/Utils'
import { PosItemSchema } from '../../../validations/itemmaster'

const PosItemDetails2 = ({
  isShow,
  setIsShow,
  posList,
  setposList,
  posValues,
  posItemEdit,
  userId,
  saveLocalyBatchNumber,
  setPosItemEdit,
  setPosValues,
  saveLocalyNoneBS,
  saveSerialNumberinlocal
}) => {
  // initiate the Value
  const [initialValuePosItem, setInitialValuePosItem] = useState({
    partCode: '',
    partName: '',
    Description: '',
    qty: 1,
    rate: '',
    uom: '',
    itemComboBool: false,

    Amount: '',
    store: '',
    BatchNo: '',
    Serialnum: '',
    gstRate: '',
    createdby: ''
  })
  const [itemDetailsValues, setItemDetailsValues] = useState({
    partNumber: { value: '', label: '' },
    partName: { value: '', label: '' },
    batchNumber: { value: '', label: '' },
    serialNumber: [],
    qty: 1,
    serialorBatch: null,
    batchNumberOptions: [],
    serialNumberOptions: [],
    rate: 0,
    uom: { value: '', label: '' },
    hsn: null,
    hsnCode: null
  })
  const [formKey, setFormKey] = useState(0)

  // return null ; On change the posItemEdit not
  // be Null it will call the edit data
  useEffect(() => {
    if (posItemEdit) {
      setInitialValuePosItem({
        partCode: posItemEdit?.partCode?.id,
        partName: posItemEdit?.partCode?.id,
        Description: posItemEdit?.description,
        qty: posItemEdit?.qty,
        rate: Number(posItemEdit?.rate).toFixed(0),
        uom: posItemEdit?.uom?.id,
        Amount: posItemEdit?.amount,
        BatchNo: posItemEdit?.batch?.id,
        Serialnum: posItemEdit?.serial?.map(item => item?.id),
        gstRate: posItemEdit?.gstRate,
        createdby: posItemEdit?.createdBy?.id
          ? posItemEdit?.createdBy?.id
          : userId
      })

      setItemDetailsValues({
        partNumber: {
          value: posItemEdit?.partCode?.id,
          label: posItemEdit?.partCode?.itemPartCode,
          name: posItemEdit?.partCode?.itemName,
          batchNumber: posItemEdit?.partCode?.batchNumber ? true : false,
          serial: posItemEdit?.partCode?.serial ? true : ''
        },
        partName: {
          value: posItemEdit?.partCode?.id,
          label: posItemEdit?.partCode?.itemName
        },
        batchNumber: {
          value: posItemEdit?.batch?.id,
          label: posItemEdit?.batch?.batchNumberName
        },
        serialNumber: posItemEdit?.serial?.map(item => ({
          value: item?.id ? item?.id : item?.value,
          label: item?.serialNumber ? item?.serialNumber : item?.label
        })),
        qty: posItemEdit?.qty,
        serialorBatch: posItemEdit?.partCode?.batchNumber
          ? 'batch'
          : posItemEdit?.partCode?.serial
          ? 'serial'
          : '',
        rate: posItemEdit?.rate,
        uom: { value: posItemEdit?.uom?.id, label: posItemEdit?.uom?.name },
        hsn: posItemEdit?.hsn,
        hsnCode: posItemEdit?.hsnCode
      })
      filtertheData(
        posItemEdit?.partCode?.id,
        posItemEdit?.partCode?.batchNumber
          ? 'batch'
          : posItemEdit?.partCode?.serial
          ? 'serial'
          : ''
      )

      setFormKey(prev => prev + 1)
    }
  }, [posItemEdit])

  // return 0;  to get Options value
  async function getOptions (e, queryName, selectId) {
    let search_term = e.trim().replace(/"/g, '\\"')
    if (
      queryName === 'itemMasterOptionFetchQuery' &&
      search_term &&
      selectId === 'itemPartCode'
    ) {
      let responseData = await OnchangeGetData(
        posItemmasterfetch(`itemPartCode: "${search_term}" `)
      )
      if (responseData.success) {
        setposList(prev => ({
          ...prev,
          partCodeList: responseData.data.itemMaster.items
        }))
      }
    }
    if (
      queryName === 'itemMasterOptionFetchQuery' &&
      search_term &&
      selectId === 'itemName'
    ) {
      let responseData = await OnchangeGetData(
        posItemmasterfetch(`itemName: "${search_term}"`)
      )
      if (responseData.success) {
        setposList(prev => ({
          ...prev,
          partNameList: responseData.data.itemMaster.items
        }))
      }
    }
  }
  // retun Null; filter function for serial and batch number options
  const filtertheData = async (itemValue, serial_or_batch) => {
    /*
        get item id and store id send request to  get batch and serial number denpends  on serialOrBatch append the options
        to list
        */
    setItemDetailsValues(prev => ({
      ...prev,
      batchNumberOptions: [],
      serialNumberOptions: []
    }))
    let storeobj = posValues?.store?.value
    if (storeobj && itemValue) {
      let responseData = await OnchangeGetData(
        getBatchOrSerial(Number(itemValue), Number(storeobj))
      )
      if (
        responseData.success &&
        responseData?.data?.itemStock?.items?.length > 0
      ) {
        if (serial_or_batch === 'batch') {
          if (responseData.data.itemStock.items[0]['batchNumber'] !== null) {
            setItemDetailsValues(prev => ({
              ...prev,
              batchNumberOptions: responseData?.data?.itemStock?.items,
              serialNumberOptions: []
            }))
          } else {
            showErrorToast(true, 'error', 'Batch Number Not Available', '')
          }
        } else if (
          serial_or_batch === 'serial' &&
          responseData.data.itemStock.items.length > 0
        ) {
          setItemDetailsValues(prev => ({
            ...prev,
            batchNumberOptions: [],
            serialNumberOptions:
              responseData.data.itemStock.items[0]['serialNumber']
          }))
        }
      } else {
        let errorData = ShowErrorNoties(responseData.errors)
        showErrorToast(true, 'error', errorData, '')
      }
    }
  }

  // return Null; onChange partCode or part Name
  function selectdataonchange (option, identity = true) {
    /*
        partCode or PartName it set batch or Serial and
        pass the value to filtertheData then get options
        */

    if (option) {
      if (option.batchNumber) {
        setItemDetailsValues(prev => ({ ...prev, serialorBatch: 'batch' }))
        filtertheData(identity ? option?.value : option?.id, 'batch')
      } else if (option.serial) {
        setItemDetailsValues(prev => ({ ...prev, serialorBatch: 'serial' }))
        filtertheData(identity ? option?.value : option?.id, 'serial')
      } else {
        filtertheData(identity ? option?.value : option?.id, '')
        setItemDetailsValues(prev => ({ ...prev, serialorBatch: '' }))
      }
      setItemDetailsValues(prev => ({
        ...prev,
        rate: (Number(option.rate) * Number(posValues?.currencyRate)).toFixed(
          2
        ),
        uom: { value: option?.uom?.id, label: option?.uom?.name }
      }))
    } else {
      setItemDetailsValues(prev => ({
        ...prev,
        rate: 0,
        uom: { value: '', label: '' },
        serialorBatch: ''
      }))
    }
  }

  // handle submit
  let error = ''
  const handleSubmit = async (values, { setFieldError }) => {
    // setLoadingModels(true)
    if (itemDetailsValues?.serialorBatch === 'batch') {
      if (values['BatchNo'] === '') {
        setFieldError('BatchNo', 'Batch Number is required')
        error = 'Batch Number is required'
      } else {
        setFieldError('BatchNo', null)
        error = ''
      }
    }
    if (itemDetailsValues?.serialorBatch === 'serial') {
      if (values['Serialnum'] === '') {
        setFieldError('Serialnum', 'Serial Number is required')
        error = 'Serial Number is required'
      } else {
        if (values['Serialnum'].length === Number(values['qty'])) {
          error = ''
        } else {
          error = 'Serial Number and qty in missmatch'
          setFieldError('Serialnum', 'Serial Number and qty in missmatch')
        }
      }
    }

    let index
    if (posItemEdit?.index !== null && posItemEdit?.index !== undefined) {
      index = posItemEdit?.index
    } else {
      index = posValues?.posItemDetailsIndex + 1
      setPosValues(prev => ({ ...prev, posItemDetailsIndex: index }))
    }

    let savedObjects = {
      index: index,
      id: posItemEdit?.id ? Number(posItemEdit?.id) : '',
      partCode: {
        id: itemDetailsValues?.partNumber?.value,
        itemPartCode: itemDetailsValues?.partNumber?.label,
        itemName: itemDetailsValues?.partNumber?.name,
        batchNumber: itemDetailsValues?.partNumber?.batchNumber,
        serial: itemDetailsValues?.partNumber?.serial
      },
      hsn: itemDetailsValues.hsn ? Number(itemDetailsValues.hsn) : null,
      hsnCode: itemDetailsValues?.hsnCode ? itemDetailsValues?.hsnCode : null,
      description: values?.['Description'],
      qty: Number(values?.['qty']),
      rate: values?.['rate'],
      uom: {
        id: itemDetailsValues?.uom?.value,
        name: itemDetailsValues?.uom?.label
      },
      amount: Number(values?.['rate']) * Number(values['qty']),
      batch: values['BatchNo']
        ? {
            id: itemDetailsValues?.batchNumber?.value,
            batchNumberName: itemDetailsValues?.batchNumber?.label
          }
        : null,
      serial:
        values['Serialnum']?.length > 0 ? itemDetailsValues?.serialNumber : [],
      gstRate: values['gstRate'],
      modifiedBy: posItemEdit ? Number(userId) : null,
      createdBy: posItemEdit
        ? Number(initialValuePosItem?.createdby)
        : Number(userId),
      discountValueForPerItem: 0,
      discountPercentage: 0,
      finalValue: 0,
      discountValue: 0
    }

    if (error === '') {
      if (itemDetailsValues?.serialorBatch === 'batch') {
        let isSaved = await saveLocalyBatchNumber(
          savedObjects,
          values['gstRate']
        )
        if (isSaved) {
          showErrorToast(true, 'success', '', isSaved)
          handleClose()
        }
      } else if (itemDetailsValues?.serialorBatch === 'serial') {
        let isSaved = await saveSerialNumberinlocal(
          savedObjects,
          values['gstRate']
        )
        if (isSaved) {
          showErrorToast(true, 'success', '', isSaved)
          handleClose()
        }
      } else {
        let isSaved = await saveLocalyNoneBS(savedObjects, values['gstRate'])
        if (isSaved) {
          showErrorToast(true, 'success', '', isSaved)
          handleClose()
        }
      }
    } else {
      // setLoadingModels(false)
    }
  }

  const handleClose = () => {
    setIsShow(false)
    setInitialValuePosItem({
      partCode: '',
      partName: '',
      Description: '',
      qty: 1,
      rate: '',
      uom: '',
      itemComboBool: false,
      Amount: '',
      store: '',
      BatchNo: '',
      Serialnum: '',
      gstRate: '',
      createdby: ''
    })
    setItemDetailsValues({
      partNumber: { value: '', label: '' },
      partName: { value: '', label: '' },
      batchNumber: { value: '', label: '' },
      serialNumber: '',
      qty: 1,
      serialorBatch: null,
      batchNumberOptions: [],
      serialNumberOptions: [],
      rate: 0,
      uom: { value: '', label: '' }
    })
    setPosItemEdit(null)
  }
  return (
    <>
      <ToastContainer />
      <Modal
        show={isShow}
        onHide={handleClose}
        size='lg'
        centered
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Items Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mx-2'>
            <Formik
              key={formKey}
              enableReinitialize
              initialValues={initialValuePosItem}
              validationSchema={PosItemSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue, values, setValues }) => (
                <Form>
                  <div className='row mx-2'>
                    <div className='col-6 staan-input-group'>
                      <ReactSelect
                        name='partCode'
                        onInputChange={e => {
                          getOptions(
                            e,
                            'itemMasterOptionFetchQuery',
                            'itemPartCode'
                          )
                        }}
                        options={posList?.partCodeList.map(item => ({
                          value: item.id,
                          label: item.itemPartCode,
                          name: item.itemName,
                          dec: item.description,
                          uom: item.itemUom,
                          rate: item.itemMrp,
                          batchNumber: item.batchNumber,
                          serial: item.serial,
                          gstRate: item.itemHsn?.gstRates?.rate,
                          hsn: item?.itemHsn?.id,
                          hsnCode: item?.itemHsn?.hsnCode,
                          itemComboBool: item.itemComboBool
                        }))}
                        value={itemDetailsValues?.partNumber}
                        onChange={option => {
                          if (option) {
                            selectdataonchange(option)
                            setItemDetailsValues(prev => ({
                              ...prev,
                              partNumber: option,
                              partName: {
                                value: option.value,
                                label: option.name
                              },
                              batchNumber: { value: '', label: '' },
                              serialNumber: [],
                              hsn: option?.hsn,
                              hsnCode: option?.hsnCode
                            }))
                            setValues({
                              gstRate: option.gstRate,
                              Description: option.dec,
                              rate: (
                                (Number(option.rate) /
                                Number(posValues?.currencyRate))
                              ).toFixed(0),
                              uom: option?.uom?.id,
                              partCode: option.value,
                              partName: option.value,
                              store: posValues?.store?.value,
                              BatchNo: '',
                              Serialnum: [],
                              qty: 1
                            })
                          } else {
                            selectdataonchange()
                            setItemDetailsValues(prev => ({
                              ...prev,
                              partNumber: { value: '', label: '' },
                              partName: { value: '', label: '' },
                              batchNumber: { value: '', label: '' },
                              serialNumber: [],
                              hsn: null,
                              hsnCode: null
                            }))
                            setValues({
                              gstRate: '',
                              Description: '',
                              rate: 0,
                              uom: '',
                              partCode: '',
                              partName: '',
                              store: '',
                              BatchNo: '',
                              Serialnum: [],
                              qty: 1
                            })
                          }
                        }}
                        className='mt-3'
                        styles={customSelectStyle}
                      />
                      <label
                        className='staan-form-input-label pt-1 px-1 mx-2 text-primary'
                        role='button'
                        //   onClick={handleShowStockStatementModal}
                      >
                        Part Code
                        <i className='fa-solid fa-up-right-from-square ms-2'></i>
                      </label>
                      {touched.partCode && errors.partCode && (
                        <small>{errors.partCode}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <ReactSelect
                        name='PartName'
                        onInputChange={e => {
                          getOptions(
                            e,
                            'itemMasterOptionFetchQuery',
                            'itemName'
                          )
                        }}
                        options={posList?.partNameList.map(item => ({
                          value: item.id,
                          label: item.itemName,
                          partcode: item.itemPartCode,
                          dec: item.description,
                          uom: item.itemUom,
                          rate: item.itemMrp,
                          batchNumber: item.batchNumber,
                          serial: item.serial,
                          gstRate: item.itemHsn?.gstRates?.rate,
                          itemComboBool: item.itemComboBool,
                          hsn: item?.itemHsn?.id,
                          hsnCode: item?.itemHsn?.hsnCode
                        }))}
                        value={itemDetailsValues?.partName}
                        onChange={option => {
                          if (option) {
                            selectdataonchange(option)
                            setItemDetailsValues(prev => ({
                              ...prev,
                              partNumber: {
                                value: option.value,
                                label: option.partcode
                              },
                              partName: option,
                              batchNumber: { value: '', label: '' },
                              serialNumber: [],
                              hsn: option?.hsn,
                              hsnCode: option?.hsnCode
                            }))
                            setValues({
                              gstRate: option.gstRate,
                              Description: option.dec,
                              rate: (
                                (Number(option.rate) /
                                Number(posValues?.currencyRate))
                              ).toFixed(0),
                              uom: option?.uom?.id,
                              partCode: option.value,
                              partName: option.value,
                              store: posValues?.store?.value,
                              BatchNo: '',
                              Serialnum: [],
                              qty: 1
                            })
                          } else {
                            selectdataonchange('')
                            setItemDetailsValues(prev => ({
                              ...prev,
                              partNumber: { value: '', label: '' },
                              partName: { value: '', label: '' },
                              batchNumber: { value: '', label: '' },
                              serialNumber: [],
                              hsn: null,
                              hsnCode: option?.hsnCode
                            }))
                            setValues({
                              gstRate: '',
                              Description: '',
                              rate: 0,
                              uom: '',
                              partCode: '',
                              partName: '',
                              store: '',
                              BatchNo: '',
                              Serialnum: [],
                              qty: 1
                            })
                          }
                        }}
                        className='mt-3'
                        styles={customSelectStyle}
                      />
                      <label className='staan-form-input-label pt-1 px-1 mx-2'>
                        Part Name
                      </label>
                      {touched.partName && errors.partName && (
                        <small>{errors.partName}</small>
                      )}
                    </div>
                    <div className='col-12 staan-input-group'>
                      <Field
                        type='text'
                        name='Description'
                        id='Description'
                        className='w-100 staan-form-input  '
                      />
                      <label
                        htmlFor='Description'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Description
                      </label>
                      {touched.Description && errors.Description && (
                        <small>{errors.Description}</small>
                      )}
                    </div>
                    <div className='row   my-2'>
                      <div className='col-2 staan-input-group'>
                        <Field
                          type='text'
                          name='qty'
                          id='qty'
                          autoComplete='off'
                          onChange={e => {
                            const { value } = e.target
                            setFieldValue('qty', value) // Update Formik's internal state
                            setItemDetailsValues(prev => ({
                              ...prev,
                              qty: value
                            }))
                          }}
                          className='w-100 staan-form-input text-center'
                        />
                        <label
                          htmlFor='qty'
                          className='staan-form-input-label pt-2 px-1 mx-2'
                        >
                          Qty
                        </label>
                        {touched.qty && errors.qty && (
                          <small>{errors.qty}</small>
                        )}
                      </div>
                      <div className='col-3 staan-input-group'>
                        <Field
                          type='text'
                          name='rate'
                          id='rate'
                          className='w-100 staan-form-input text-end px-2'
                        />
                        <label
                          htmlFor='rate'
                          className='staan-form-input-label pt-2 px-1 mx-2'
                        >
                          Rate
                        </label>
                        {touched.rate && errors.rate && (
                          <small>{errors.rate}</small>
                        )}
                      </div>
                      <div className='col-3  staan-input-group'>
                        <Field
                          type='text'
                          name='Amount'
                          id='Amount'
                          disabled
                          value={(
                            Number(values?.rate) *
                            Number(itemDetailsValues?.qty)
                          ).toFixed(2)}
                          className='w-100 staan-form-input text-end'
                        />
                        <label
                          htmlFor='Amount'
                          className='staan-form-input-label pt-2 px-1 mx-2 text-end px-2'
                        >
                          Amount
                        </label>
                        {touched.Amount && errors.Amount && (
                          <small>{errors.Amount}</small>
                        )}
                      </div>
                      <div className='col-4 staan-input-group'>
                        <ReactSelect
                          name='uom'
                          value={itemDetailsValues?.uom}
                          className='mt-3'
                          styles={customSelectStyle}
                        />
                        <label className='staan-form-input-label pt-1 px-1 mx-2'>
                          UOM
                        </label>
                        {touched.uom && errors.uom && (
                          <small>{errors.uom}</small>
                        )}
                      </div>
                    </div>
                    <div className='col-6 staan-input-group'>
                      <ReactSelect
                        name='BatchNo'
                        options={itemDetailsValues?.batchNumberOptions?.map(
                          item => ({
                            value: item?.batchNumber?.id,
                            label: item?.batchNumber?.batchNumberName
                          })
                        )}
                        value={itemDetailsValues?.batchNumber}
                        isDisabled={
                          itemDetailsValues?.serialorBatch === 'batch'
                            ? false
                            : true
                        }
                        onChange={option => {
                          if (option) {
                            setFieldValue(
                              'BatchNo',
                              option ? option.value : null
                            )
                            setItemDetailsValues(prev => ({
                              ...prev,
                              batchNumber: option
                            }))
                          } else {
                            setFieldValue('BatchNo', null)
                            setItemDetailsValues(prev => ({
                              ...prev,
                              batchNumber: null
                            }))
                          }
                        }}
                        className='mt-3'
                        styles={customSelectStyle}
                        isClearable
                      />
                      <label className='staan-form-input-label pt-1 px-1 mx-2'>
                        Batch Number{' '}
                      </label>
                      {touched.BatchNo && errors.BatchNo && (
                        <small>{errors.BatchNo}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <ReactSelect
                        name='Serialnum'
                        options={itemDetailsValues?.serialNumberOptions?.map(
                          item => ({ value: item.id, label: item.serialNumber })
                        )}
                        value={itemDetailsValues?.serialNumber}
                        isDisabled={
                          itemDetailsValues?.serialorBatch === 'serial'
                            ? false
                            : true
                        }
                        isMulti
                        isOptionDisabled={() =>
                          itemDetailsValues?.serialNumber?.length >= values?.qty
                            ? true
                            : false
                        }
                        onChange={options => {
                          setFieldValue(
                            'Serialnum',
                            options ? options.map(option => option.value) : []
                          )
                          setItemDetailsValues(prev => ({
                            ...prev,
                            serialNumber: options
                          }))
                        }}
                        className='mt-3'
                        styles={customSelectStyle}
                      />
                      <label className='staan-form-input-label pt-1 px-1 mx-2'>
                        Serial Number{' '}
                      </label>
                      {touched.Serialnum && errors.Serialnum && (
                        <small>{errors.Serialnum}</small>
                      )}
                    </div>
                    <div className='row mx-2 mt-4 text-end'>
                      <div>
                        <button
                          type='submit'
                          className='btn btn-sm btn-outline-success px-3'
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  )
}

export default PosItemDetails2
