import * as yup from "yup";
export const quotationFormitemValidation = yup.object().shape({
    partCode: yup.object().required("Part Code is required"),
    partName: yup.object().required("Item Name is required"),
    category: yup.string().required("Category is required"),
    po_qty: yup.number().required("Qty is required"),
    uom: yup.object().required("UOM is required"),
    rate: yup.number().required("Rate is required"),
    gstRates: yup.number().required("Tax is required"),
    amount: yup.number().required("Amount is required"),
    discount: yup.string().nullable()
        .test(
            'is-valid-discount',
            'Discount must be a valid percentage or currency amount',
            value => {
                if (value === undefined || value.trim() === '') {
                    return true; // Allow empty string
                }
                
                return /^(₹|€|\$|¥)?\d+(\.\d+)?$/.test(value) || /^\d+(\.\d+)?%$/.test(value.trim());
            }
        )
        .test(
            'is-valid-percentage-range',
            'Percentage must be between 1 and 100',
            value => {
                if (/^\d+(\.\d+)?%$/.test(value)) { 
                    const percentageValue = parseInt(value.replace('%', ''), 10);
                    return percentageValue >= 0 && percentageValue <= 100;
                }
                return true;
            }
        )
});


export const quotationFormSchema=yup.object().shape({
    supplierNumber:yup.string().required("Supplier Number is required"),
    supplierName:yup.string().required("Supplier Name is required"),
    department_id:yup.string().required("Department is required"),
    remark:yup.string(),
    // QuatationNumber:yup.string().required("Quotation Number  is required"),
    // QuotationDate:yup.string().required("Quotation Date is required"),
    termsConditions:yup.string().required("Terms and Conditions is required"),
    TermsOptions:yup.string().required("Terms Option  is required"),
    buyerAddress : yup.number().required('Buyer Address is required'),
    buyerContactPerson : yup.number().required('Buyer Contact Person is required'),
})

export const QuotationOtherIncomeCharges =yup.object().shape({
    otherIncomeChargesId : yup.object().required('Account is required'),
    amount : yup.string().required('Amount is required'),
})