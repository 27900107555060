import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaSave } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { TiCancel } from 'react-icons/ti';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import DataContext from '../../../context/ItemMasterContext';
// Function to parse and group item combo data based on parent index; returns parsed data array.
export const parseAndGroupItemComboData = (itemComboData, parentIndex) => {
    const parsedData = itemComboData?.map((item, index) => {
        const data = typeof item === 'string' ? JSON.parse(item) : item;
        return {
            index: index,
            id: data?.id,
            itemmasterId: data?.itemmaster?.id,
            partCode: data?.itemmaster?.itemPartCode,
            partName: data?.itemmaster?.itemName,
            display: data?.display,
            isSelected: data?.isMandatory ? true : false,
            qty: data?.qty,
            rate: data?.rate,
            uom: {
                value: data?.uom?.id,
                label: data?.uom?.name
            },
            amount: Number(data?.amount).toFixed(2),
            afterDiscountValueForPerItem: data?.afterDiscountValueForPerItem,
            isMandatory: data?.isMandatory,
            parentIndex: parentIndex,
            createdBy: data?.createdBy?.id ?  data?.createdBy?.id : Number(data?.createdBy),
            modifiedBy: data?.modifiedBy?.id ?  data?.modifiedBy?.id : Number(data?.modifiedBy),
        };
    }

    );
    return parsedData
};
// Function to calculate and update amounts based on selected items in the combo; returns calculated item combo data array.
export const calculateItemCombo = (itemComboList_, amount, qty, setItemComboDataList) => {
    const itemComboData = [];
    const mandatoryItems = itemComboList_.filter(item => item?.isSelected);

    if (mandatoryItems.length > 0) {
        const totalValue = mandatoryItems.reduce((sum, item) => {
            return sum + (parseFloat(item.rate) * parseFloat(item.qty));
        }, 0);

        const roundedFinalTotal = Math.round(parseFloat(amount) / qty * 100) / 100;

        if (totalValue === 0) {
            console.log("Total checked value is zero; cannot calculate ratios.");
            return [itemComboData];
        }
        const totalDiscountNeeded = totalValue - roundedFinalTotal;
        const itemContributions = mandatoryItems?.map(item => {
            return parseFloat(item.rate) * parseFloat(item.qty);
        });

        const ratios = itemContributions?.map(contribution => contribution / totalValue);
        const discounts = ratios?.map(ratio => totalDiscountNeeded * ratio);
        itemComboList_?.forEach((item, index) => {
            const originalPrice = parseFloat(item.rate) * parseFloat(item.qty);
            const data = {
                index: index,
                id: item?.id ? item?.id : '',
                itemmasterId: item?.itemmasterId,
                partCode: item?.partCode,
                partName: item?.partName,
                display: item?.display || null,
                qty: item?.qty,
                rate: item?.rate,
                uom: {
                    value: item?.uom?.value,
                    label: item?.uom?.label
                },
                isMandatory: item?.isMandatory,
                parentIndex: item?.parentIndex,
                edit: item?.edit ? true : false,
                createdBy: item?.createdBy,
                modifiedBy: item?.modifiedBy,
            };
            if (item?.isSelected) {
                const discountIndex = mandatoryItems.findIndex(mItem => mItem.itemmasterId === item.itemmasterId);
                console.log(item.itemmasterId, originalPrice, discounts[discountIndex]);
                const discountedAmount = originalPrice - discounts[discountIndex];
                const finalDiscountedAmount = Math.max(discountedAmount, 0).toFixed(3);
                const afterDiscountValueForPerItem = (finalDiscountedAmount / parseFloat(item?.qty)).toFixed(3);
                data.afterDiscountValueForPerItem = afterDiscountValueForPerItem;
                data.amount = finalDiscountedAmount;
                data.isSelected = true
            } else {
                data.afterDiscountValueForPerItem = 0;
                data.amount = "0";
                data.isSelected = false
            }
            itemComboData.push(data);
        });
        console.log("itemComboData", itemComboData);

        const totalCalculatedAmount = itemComboData?.reduce((sum, data) => {
            return sum + parseFloat(data.amount);
        }, 0);

        if (totalCalculatedAmount !== roundedFinalTotal) {
            console.warn(`Warning: The total calculated amount ${totalCalculatedAmount} does not match the parameter amount ${amount}.`);
        }
        if (setItemComboDataList) {
            setItemComboDataList(itemComboData);
        }

    }

    return itemComboData;
};
const ItemCombo = ({ IsshowItemCombo, setshowItemCombo, lastUpdatedItemDetails, itemComboData, setItemComboData, setItemDetailsData, setQuotationItemDetailsShow, itemDetailsData, setIsSaveItemCombo }) => {
    const { userId } = useContext(DataContext)
    const [loading, setLoading] = useState(false)
    const [itemComboValue, setItemComboValue] = useState({ currentEditIndex: null })
    const [previousData, setPreviousData] = useState(null);
    let totalCalculatedAmount = 0
    if (itemComboData) {
        itemComboData?.map(item => {
            totalCalculatedAmount += Number(item?.amount)
        })
    }    
    // Function to handle the submission of item combo data; returns no value.
    const handleSubmitItemCombo = async () => {
        console.log("lastUpdatedItemDetails", lastUpdatedItemDetails);

        setLoading(true)
        let modifiedData = itemComboData?.map((item) => {
            if (item?.isSelected) {
                let modifiedValue = {
                    ...item,
                    createdBy: Number(userId),
                    id: null,
                    itemmaster: Number(item?.itemmasterId),
                    modifiedBy: null,
                    parentItemdetailId: Number(lastUpdatedItemDetails?.index),
                    uom: { value: Number(item?.uom?.value), label: item?.uom?.label },
                    afterDiscountValueForPerItem: item?.afterDiscountValueForPerItem,
                    amount: Number(item?.amount).toFixed(2),
                    qty: Number(item?.qty).toFixed(2),
                    rate: Number(item?.rate).toFixed(2),
                    edit: true,
                }
                // delete modifiedValue?.['isSelected']
                console.log(modifiedValue);

                return modifiedValue
            }
        }).filter(item => item !== undefined)
        // const itemsString = JSON.stringify(modifiedData)
        //     .replace(/"([^"]+)":/g, '$1:')
        //     .replace(/null/g, 'null');
        // console.log("modifiedData", modifiedData);
        console.log("parentIndex", modifiedData[0]?.parentIndex);
        
        setItemDetailsData((prev) =>
            prev?.map((item) =>
                item.index === modifiedData[0]?.parentIndex
                    ? { ...item, itemComboItemDetails: modifiedData }
                    : item
            )
        );

        setshowItemCombo(false)
        setQuotationItemDetailsShow(false)

        // try {
        //     const response = await axiosInstance.post("/itemmaster/graphql",
        //         { query: quotationsItemComboCreateMutations(itemsString) })
        //     const responseData = response?.data?.data?.quotationsItemComboItemDetailsCreateMutations;
        //     if (responseData?.success) {
        //         setItemDetailsData(prev => {
        //             const newItem = responseData?.parent;
        //             if (!newItem) return prev; // Exit early if there's no new item
        //             const existingIndex = prev.findIndex(item => item?.id === newItem.id);
        //             if (existingIndex !== -1) {
        //                 // Replace the existing item
        //                 const newList = [...prev];
        //                 newList[existingIndex] = newItem;
        //                 return newList;
        //             } else {
        //                 // Add the new item
        //                 return [...prev, newItem];
        //             }
        //         });
        //         setshowItemCombo(false)
        //         setQuotationItemDetailsShow(false)
        //     }

        // } catch (error) {
        //     console.log(error);
        // }
        setLoading(false)
    }
    // Function to handle selection of an item in the combo; returns no value.
    const handleSelectItem = (selectedItem) => {
        let modified = itemComboData?.map(item => {
            // Check if the item matches the selected item
            if (item.index === selectedItem.index) {
                // Toggle the isSelected property
                return { ...item, isSelected: !item.isSelected };
            }

            // Return the item unchanged if it doesn't match
            return item;
        })
        console.log("modified", modified);

        setItemComboData(modified);

        console.log("lastUpdatedItemDetails", lastUpdatedItemDetails);

        calculateItemCombo(modified,
            lastUpdatedItemDetails?.amount,
            lastUpdatedItemDetails?.po_qty, setItemComboData)


    };
    // Function to handle editing an item when clicked; returns no value.
    const handleEditClick = (item) => {
        if (item?.isSelected) {
            setPreviousData(item);
            setItemComboValue(prev => ({ ...prev, currentEditIndex: item?.index }))
        } else {
            showErrorToast(true, "warn", "Select The Check Box", "")
        }

    };
    // Function to save changes made to an edited item; returns no value.

    const handleSaveClick = (CurrentItem) => {
        setItemComboValue(prev => ({ ...prev, currentEditIndex: "" }));
        setPreviousData(null)
        calculateItemCombo(itemComboData,
            lastUpdatedItemDetails?.amount,
            lastUpdatedItemDetails?.po_qty, setItemComboData);
    };
    // Function to cancel editing and restore previous data; returns no value.
    const handleCancelClick = () => {
        setItemComboData(prev =>
            prev?.map(item => {
                // Check if the item matches the selected one
                if (item.index === previousData.index) {
                    // Restore the original data from previousData
                    return { ...previousData };
                }
                // Return the item unchanged if it doesn't match
                return item;
            })
        );
        setItemComboValue(prev => ({ ...prev, currentEditIndex: "" }));
    };
    // Parse the new quantity and ensure it's a number and Update the quantity in the item,
    // Calculate the new amount,If afterDiscountValueForPerItem has a value, use it,Otherwise, use the rate
    const handleQtyChange = (CurrentItem, e) => {
        let QTY_ = e.target.value;
        console.log("QTY_", QTY_);
        
         
        let updateQTy = itemComboData?.map(item => {
            if (item.index === CurrentItem.index) {
                // Return the item with the updated qty
                return { ...item, qty: QTY_ };  // This creates a new object with the updated qty
            }
            return item; // If no match, return the item unchanged
        });
        
        console.log("updateQTy", updateQTy);
        
        setItemComboData(updateQTy);
    };    
    const uniqueDisplayNames = Array.from(new Set(itemComboData?.map(item => item?.display)));
    return (
        <>
            {<BallTriangleLoading isshow={loading} />}
            <Modal show={IsshowItemCombo} size="xl">
                <Modal.Body style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className='row align-items-center justify-content-between' style={{ marginBottom: '10px' }}>
                        <div className='col-4 commanModelTitleColor'>
                            <h3 className='mx-2'>
                                <i
                                    className='fa-solid fa-arrow-left fa-sm me-2'
                                    onClick={() => {
                                        setshowItemCombo(false);
                                        setQuotationItemDetailsShow(false);
                                        if (setIsSaveItemCombo) {
                                            setIsSaveItemCombo(false);
                                        }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                ></i>
                                Item Combo
                            </h3>
                        </div>
                    </div>
                    <div style={{ overflowY: 'auto', height: '40rem', padding: '10px' }}>
                        <table className="table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead style={{ borderBottom: '2px solid #ddd' }}>
                                <tr>
                                    <th style={{ padding: '12px', textAlign: 'left', fontWeight: 'bold' }}>Mandatory</th>
                                    <th style={{ padding: '12px', textAlign: 'left', fontWeight: 'bold' }}>Part Code</th>
                                    <th style={{ padding: '12px', textAlign: 'left', fontWeight: 'bold' }}>Part Name</th>
                                    <th style={{ padding: '12px', textAlign: 'left', fontWeight: 'bold' }}>Rate</th>
                                    <th style={{ padding: '12px', textAlign: 'left', fontWeight: 'bold' }}>Item Combo Rate</th>
                                    <th style={{ padding: '12px', textAlign: 'left', fontWeight: 'bold' }}>Qty</th>
                                    <th style={{ padding: '12px', textAlign: 'left', fontWeight: 'bold' }}>UOM</th>
                                    <th style={{ padding: '12px', textAlign: 'left', fontWeight: 'bold' }}>Item Combo Amount</th>
                                    <th style={{ padding: '12px', textAlign: 'center', fontWeight: 'bold' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {uniqueDisplayNames?.map(displayName => (
                                    <React.Fragment key={displayName}> {/* Use displayName for key */}
                                        <tr>
                                            <td colSpan={12} style={{ fontWeight: 'bold', backgroundColor: '#e9ecef', padding: '10px 12px' }}>
                                                {displayName}
                                            </td>
                                        </tr>
                                        {itemComboData
                                            ?.filter(item => item.display === displayName)
                                            ?.map(item => {
                                                const { partCode, partName, rate, isMandatory, qty, uom, amount, isSelected, index, afterDiscountValueForPerItem } = item; // Destructure item
                                                return (
                                                    <React.Fragment key={partCode}>
                                                        <tr style={{ backgroundColor: '#fff', borderBottom: '1px solid #ddd' }}>
                                                            <td style={{ textAlign: 'center', padding: '10px' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isSelected}
                                                                    onChange={() => handleSelectItem(item)}
                                                                    disabled={isMandatory || item?.edit}
                                                                />
                                                            </td>
                                                            <td style={{ padding: '10px', textAlign: 'left' }}>{partCode}</td>
                                                            <td style={{ padding: '10px', textAlign: 'left' }}>{partName}</td>
                                                            <td style={{ padding: '10px', textAlign: 'right' }}>{rate}</td>
                                                            <td style={{ padding: '10px', textAlign: 'right' }}>{afterDiscountValueForPerItem}</td>
                                                            <td style={{ padding: '10px', textAlign: 'center' }}>
                                                                {isMandatory ? (
                                                                    qty
                                                                ) : (
                                                                    itemComboValue?.currentEditIndex === index ? (
                                                                        <input
                                                                            style={{
                                                                                width: '6rem',
                                                                                padding: '0.5rem',
                                                                                textAlign: 'center',
                                                                                border: '1px solid #ced4da',
                                                                                borderRadius: '4px'
                                                                            }}
                                                                            type="number"
                                                                            value={qty}
                                                                            onChange={(e) => handleQtyChange(item, e)}
                                                                        />
                                                                    ) : (
                                                                        qty
                                                                    )
                                                                )}
                                                            </td>
                                                            <td style={{ padding: '10px', textAlign: 'left' }}>{uom?.label}</td>
                                                            <td style={{ padding: '10px', textAlign: 'right' }}>{amount}</td>
                                                            <td style={{ textAlign: 'center', padding: '10px' }}>
                                                                {isMandatory || item?.edit ? (
                                                                    <TiCancel className="text-danger" style={{ cursor: 'pointer' }} />
                                                                ) : (
                                                                    itemComboValue?.currentEditIndex === index ? (
                                                                        <>
                                                                            <FaSave
                                                                                onClick={() => handleSaveClick(item)}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    color: 'green',
                                                                                    fontSize: '1.2rem',
                                                                                    marginRight: '10px'
                                                                                }}
                                                                            />
                                                                            <MdCancel
                                                                                onClick={handleCancelClick}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    color: 'black',
                                                                                    fontSize: '1.2rem'
                                                                                }}
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <i
                                                                            className='fa-solid fa-pen text-secondary'
                                                                            onClick={() => handleEditClick(item)}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                fontSize: '1.2rem'
                                                                            }}
                                                                        />
                                                                    )
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </React.Fragment>
                                ))}
                                <tr style={{ backgroundColor: '#f8f9fa', fontWeight: 'bold' }}>
                                    <td colSpan={7} style={{ padding: '12px', textAlign: 'right' }}>TOTAL</td>
                                    <td style={{ padding: '12px', textAlign: 'right' }}>{Number(totalCalculatedAmount).toFixed(2)}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="text-end mt-2" style={{ position: 'sticky', bottom: 0, background: 'white', padding: '10px' }}>
                        <button type="submit" className="btn btn-sm btn-success" disabled={itemComboData?.some(item => item.edit)}
                            onClick={handleSubmitItemCombo} style={{ fontSize: '1rem', padding: '0.5rem 1rem' }}>
                            Save
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );

};

export default ItemCombo;
