import React, { useMemo, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AddPO from './AddPO';
import { FetchRecursiveSubProductionOrders } from '../../../GraphQLQueries/ProductionOrderQuery';
import axiosInstance from '../../../api/axoiss';


const PoSubProductionOrder = ({ data, po_number, type, detail }) => {
    const containerStyle = useMemo(() => ({ width: '100%', height: '25rem' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const rowStyle = { background: 'white' };
    const [showAddPO, setShowAddPO] = useState(false);
    const [currentPoMaster, setCurrentPoMaster] = useState(null);
    const [poMasterData, setPoMasterData] = useState(null);
    const [rowData, setRowData] = useState([]);

    const getRowStyle = params => {
        if (params.data.isCombo === true) {
            return { background: '#E6E4E4' };
        }
    };
    
    const handleShowAddPO = () => {
        setShowAddPO(true);
    };

    const handleCloseAddPO = () => {
        setShowAddPO(false);
    };

    const ProductionOrderNumberRenderer = ({ data }) => {
        return (
            <p>
                <a className="text-primary" style={{ cursor: 'pointer' }} 
                    onClick={() => { 
                        handleShowAddPO(); 
                        setCurrentPoMaster(data?.poMaster?.id);
                        setPoMasterData(data?.poMaster)
                    }}
                >{data?.poMaster?.orderNo}</a>
            </p>
        );
    };

    let column_def = [
        {headerName : 'S.No', valueGetter: "node.rowIndex + 1", flex: 1},
        { headerName: 'MO No', cellRenderer: ProductionOrderNumberRenderer, flex: 2 },
        { headerName: 'Status', field: 'status.name', flex: 2 },
        { headerName: 'Part Code', field: 'partCode.itemPartCode', tooltipValueGetter: (p) => p.value, flex: 2},
        { headerName: 'Part Name', field: 'partCode.itemName', tooltipValueGetter: (p) => p.value, flex: 4 },
        { headerName: 'MO Qty', field: 'productionQty', flex: 2  },
        { headerName: 'UOM', field: 'unit.name', flex: 2  },
        { headerName: 'Completed', field: 'completedQty', flex: 2  },
        { headerName: 'Pending', field: 'pendingQty', flex: 2  },
    ]

    const [columnDefs, setColumnDefs] = useState(column_def)

    const defaultColDef = useMemo(() => ({
        // editable: true,
        resizable: true
    }), []);

    useEffect(() => {
        if(type === "SUBCONTRACT"){
            setColumnDefs([...column_def, 
                {
                    headerName: 'Supplier',
                    field: 'selectedSupplier',
                    editable: true,
                    cellEditor: "agSelectCellEditor",
                    cellEditorParams: params => {
                        return {
                            values: params.data.supplierOptions
                        };
                    }
                }
            ])
        }
        if(data){
            let sub_po_id = data?.map(item => item.id)
            if (sub_po_id.length > 0){
                axiosInstance.post("/itemmaster/graphql", {query: FetchRecursiveSubProductionOrders(detail, type)})
                .then(res => {
                    let data_list = res?.data?.data?.recursiveSubProductionOrder?.items || []
                    let updated_data_list = data_list?.map(rm_item => {
                        let new_data = {...rm_item}
                        let supplier_options = rm_item?.poMaster?.productionorderlinkingtableSet?.[0]?.poItem?.bom?.supplier || []
                        new_data['supplierOptions'] = supplier_options?.map(supplier => supplier?.companyName)
                        new_data['selectedSupplier'] = supplier_options?.length === 1 ? supplier_options[0]['companyName'] : '--Select Supplier--'
                        return new_data
                    })
                    setRowData(updated_data_list)
                })
                .catch(err =>{ 
                    console.log(err, "error")
                })
            }
        }
    }, [data, type, detail])

    return (
        <div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowStyle ={rowStyle}
                        getRowStyle = {getRowStyle}
                    />
                </div>
                {showAddPO && currentPoMaster && (
                    <AddPO 
                        show = {showAddPO}
                        close = {handleCloseAddPO}
                        data = {currentPoMaster}
                        po_master = {poMasterData}
                    />
                )}
            </div>
        </div>
    );
};

export default PoSubProductionOrder;
