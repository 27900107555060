import * as  yup from "yup";


export const po_master_schema = yup.object().shape({
    orderDate: yup.string(),
    department : yup.number().required('Department is required'),
    subProductionOrders : yup.array(),
    status: yup.number(),
})


// export const po_routing_time_schema = yup.object().shape({
//     startTime: yup.string().required('Start Time is required'),
//     endTime : yup.string().required('End Time is required'),
// })