import Modal from 'react-bootstrap/Modal'; 
import {Formik ,  Form  } from 'formik';
import React, { useState, useEffect} from 'react' 
import Select from 'react-select';
import { finished_goods_schema } from '../../../validations/bom';
import { customSelectStyle } from '../../../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import { ItemMasterProductsFetchQuery } from '../../../GraphQLQueries/ItemmasterQuery'
import { fetchItemMasterData, clearItemMasterData} from "../../../ReduxState/Action/itemMasterAction";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { createFgItem } from '../../../GraphQLQueries/BomMutation';
import axiosInstance from '../../../api/axoiss';


const AddFinishedGoods = ({data, isShowAddFinishedGoods, handleCloseAddFinishedGoods, handleAddedData, bomType}) => {
    const dispatch = useDispatch(); 
    const itemMasterDataList = useSelector(state => state.itemMasterData.data);

    let initialValue = {
        part_code: '',
        part_name: '',
        category: '',
        qty: 1,
        uom: '',
        cost_allocation: '',
        remarks: '',
        labourCharges: 0,
    }
    
    const [initialData, setInitialData] = useState(initialValue)
    const [uomSelectOptions, setUomSelectOptions] = useState([])
    const [selectpartCode, setSelectpartCode] = useState(null)
    const [selectpartName, setSelectpartName] = useState(null)
    const [qty, setQty] = useState(1)
    const [selectUom , setSelectUom] = useState(null) 
    const [selectCategory, setSelectCategory] = useState('');
    const [selectCostAllocation, setSelectCostAllocation] = useState('')
    const [selectRemarks, setSelectRemarks] = useState('');
    const [selectLabourCharges, setSelectLabourCharges] = useState(0)
    const handleSelectpartCode = (option)=>{
        setSelectpartCode({ value: option?.value , label:  option?.label })
    }
    const handleSelectpartName = (option)=>{ setSelectpartName({ value: option?.value , label:  option?.label })}
    const handleSelectQty = (value)=>{setQty(value)}
    const handleSelectUom = (option)=>{ setSelectUom({ value: option?.value , label:  option?.label })}
    const handleSelectCategory = (value)=>{ setSelectCategory(value)}
    const handleSelectCostAllocation = (value)=>{ setSelectCostAllocation(value)}
    const handleSelectRemarks = (value) => {setSelectRemarks(value)}

    const get_uom_options = (option) => {
        let item_master_data = itemMasterDataList?.items?.find(item => item.id === option?.value)
        let uom_options = []
        uom_options.push({'label': item_master_data?.itemUom?.name, 'value': item_master_data?.itemUom?.id})
        // if(item_master_data?.alternateUom){
        //     item_master_data?.alternateUom?.forEach(element => {
        //         uom_options.push({'label': element?.addtionalUnit?.name, 'value': element?.addtionalUnit?.id})
        //     });
        // }
        setUomSelectOptions(uom_options)
    }

    const handleCategoryValue = (option) => {
        let item_master_data = itemMasterDataList?.items?.find(item => item.id === option?.value)
        setSelectCategory(item_master_data?.category?.name)
    }

    const clearInputDatas = () =>{
        setSelectpartCode({ value: '' , label:  '' }) 
        setSelectpartName({ value: '' , label:  '' })
        setQty('')
        setSelectUom({ value: '' , label:  '' })
        setSelectCostAllocation('')
        setSelectRemarks('')
        setSelectCategory('')
    }

    const handleSubmit = (values, { setSubmitting, setTouched,  resetForm, setErrors, setFieldError }) => {
        if(selectCostAllocation){
            const fg_sum = Number(selectCostAllocation)
            if(fg_sum  !== 100){
                toast.error('Finished Goods Total Cost Allocation should be 100%', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                return;
            }
        }
        let submit_data = {
            serialNumber: 1, 
            qty: qty,
            costAllocation: selectCostAllocation,
            partNo: selectpartCode?.value,
            unit: selectUom?.value,
        }

        let form_data = {
            SerialNo: 1,
            part_code: selectpartCode,
            part_name: selectpartName,
            category: selectCategory,
            qty: qty,
            uom: selectUom,
            cost_allocation: selectCostAllocation,
            remarks: selectRemarks,
        }

        if(selectCategory && selectCategory !== ''){
            submit_data['category'] = selectCategory
        }
        if(selectRemarks && selectRemarks !== ''){
            submit_data['remarks'] = selectRemarks
        }
        if(data && data?.id){
            submit_data['id'] = data?.id
        }
        if(bomType === 'SUBCONTRACT'){
            submit_data['labourCharges'] = selectLabourCharges
        }
        let mutation_query = createFgItem(submit_data)
        axiosInstance.post("/itemmaster/graphql", {query: mutation_query})
        .then(response => {
            const keys = Object.keys(response.data.data);
            const firstKey = keys.length > 0 ? keys[0] : null;
            const data = response.data.data[firstKey]?.finishedGoods?.id
            form_data['id'] = data
            handleAddedData(form_data)
            clearInputDatas()
            handleCloseAddFinishedGoods()
            setSubmitting(false)
        })
        .catch(error => {
          console.log(error)
        });

    }

    const handleCloseModal = () => {
        dispatch(clearItemMasterData())
        clearInputDatas();
        handleCloseAddFinishedGoods();
    };

    const handleOnInputChangeTypeSearch = (value, field) => {
        if(value.trim() !== ''){
            let filter_obj = {
                key: field,
                value: value
            }
            dispatch(fetchItemMasterData(ItemMasterProductsFetchQuery('fg', filter_obj)));
        }
        else{
            dispatch(fetchItemMasterData(ItemMasterProductsFetchQuery('fg')))
        }
    }

    useEffect(()=>{
        if(data){
            setQty(data['qty'])
            setSelectpartCode(data['part_code'])
            setSelectpartName(data['part_name'])
            setSelectCostAllocation(data['cost_allocation'])
            setSelectCategory(data['category'])
            setSelectRemarks(data['remarks'])
            setSelectLabourCharges(data?.['labourCharges'] ?  data?.['labourCharges'] : 0)
            if(data['unit']){
                setSelectUom({label: data['uom']['label'], value: data['uom']['value']});
                let uom_options = [];
                uom_options.push({label: data['uom']['label'], value: data['uom']['value']})
                // if(data?.partNo?.alternateUom){
                //     data?.partNo?.alternateUom?.forEach(element => {
                //         uom_options.push({'label': element?.addtionalUnit?.name, 'value': element?.addtionalUnit?.id})
                //     });
                // }
                let uom_options_unique = uom_options.filter((obj, index, self) =>
                    index === self.findIndex((t) => (
                        t.value === obj.value
                    ))
                );
                setUomSelectOptions(uom_options_unique);
            }
            
        }
    }, [data, dispatch])

    const mapDataToInitialValues = (data) => {
        let mappedData = {...data}
        mappedData['part_code'] = data['part_code']?.value
        mappedData['part_name'] = data['part_name']?.value
        mappedData['uom'] = data['uom']?.value
        mappedData['labourCharges'] = 0
        return mappedData;
    };
    

    return(
        <>
        <ToastContainer />
             <Modal show={isShowAddFinishedGoods} onHide={handleCloseModal}  size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Finished Goods</Modal.Title>
                </Modal.Header>
                <Modal.Body>  
                    <Formik 
                        initialValues = {data? mapDataToInitialValues(data): initialData}
                        validationSchema={finished_goods_schema}
                        onSubmit={(values, formikProps) => handleSubmit(values, formikProps)}
                        >
                        {({errors, submitForm, touched, setFieldValue, setTouched })=>(
                            <>
                                <Form style={{width: "100%", fontSize: '.8rem' }}>
                                    <div className='row mx-auto pt-3'>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemPartCode')}}
                                                options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemPartCode, name:item.itemName  }))}
                                                name='part_code' style={{fontSize: '.7rem'}} 
                                                isSearchable={true}
                                                isClearable
                                                value={selectpartCode}
                                                onChange={(option) => {
                                                    setFieldValue('labourCharges', 0)
                                                    setFieldValue('part_code', option ? option.value : null);
                                                    setFieldValue('part_name', option ? option.value : null)
                                                    setSelectUom('')
                                                    setSelectCostAllocation('')
                                                    setSelectRemarks('')
                                                    setQty('')
                                                    handleSelectpartCode(option)
                                                    get_uom_options(option)
                                                    handleCategoryValue(option)
                                                    setSelectpartName({ value: option?.value , label:  option?.name })
                                                }}  
                                                className='mt-3'
                                                styles={customSelectStyle}           
                                            />
                                            <label htmlFor="part_code" className='staan-form-input-label pt-1 px-1 mx-2'>Part Code</label>
                                            <p>{  touched.part_code &&  errors.part_code && <small>{errors.part_code}</small>}</p>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemName')}}
                                                name='part_name' style={{fontSize: '.7rem'}} 
                                                options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemName , partCode: item.itemPartCode}))}
                                                isSearchable={true}
                                                value={selectpartName}
                                                isClearable
                                                onChange={(option) => {
                                                    setFieldValue('labourCharges', 0)
                                                    setFieldValue('part_name', option ? option.value : null);
                                                    setFieldValue('part_code', option ? option.value : null)
                                                    setSelectUom('')
                                                    setSelectCostAllocation('')
                                                    setSelectRemarks('')
                                                    setQty('')
                                                    handleSelectpartName(option) 
                                                    get_uom_options(option)
                                                    handleCategoryValue(option)
                                                    setSelectpartCode({ value: option?.value , label:  option?.partCode })}}  
                                                className='mt-3'
                                                styles={customSelectStyle}  
                                            />
                                            <label htmlFor="part_name" className='staan-form-input-label pt-1 px-1 mx-2'>Part Name</label>
                                            <p>{  touched.part_name &&  errors.part_name && <small>{errors.part_name}</small>}</p>
                                        </div>
                                    </div>
                                    <div className='row mx-auto my-3'>
                                        <div className="col-6 staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                disabled
                                                value={selectCategory}
                                                onChange={(e)=>{
                                                    setFieldValue('category', e.target.value);
                                                    handleSelectCategory(e.target.value)
                                                }}
                                                name='category' style={{fontSize: '.7rem'}} 
                                            />
                                            <label htmlFor="category" className='staan-form-input-label pt-2 px-1 mx-2'>Category</label>
                                            <p>{  touched.category &&  errors.category && <small>{errors.category}</small>}</p>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                onChange={(e)=>{
                                                    handleSelectQty(e.target.value); 
                                                    setFieldValue('qty', e.target.value);
                                                }}
                                                value={qty}
                                                name='qty' style={{fontSize: '.7rem'}} 
                                            />
                                            <label htmlFor="qty" className='staan-form-input-label pt-2 px-1 mx-2'>Quantity</label>
                                            <p>{ touched.qty &&  errors.qty && <small>{errors.qty}</small>}</p>
                                        </div>
                                    </div>
                                    <div className='row mx-auto my-3'>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                isClearable
                                                options={uomSelectOptions}
                                                onChange={(option)=>{
                                                    setFieldValue('uom', option ? option.value : null)
                                                    handleSelectUom({ value: option?.value , label:  option?.label})
                                                }}
                                                value={selectUom}
                                                name='uom' style={{fontSize: '.7rem'}} 
                                                className='mt-3'
                                                styles={customSelectStyle}
                                            />
                                            <label htmlFor="uom" className='staan-form-input-label pt-2 px-1 mx-2'>Unit</label>
                                            <p>{  touched.uom &&  errors.uom && <small>{errors.uom}</small>}</p>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                value={selectCostAllocation}
                                                onChange={(e)=>{
                                                    handleSelectCostAllocation(e.target.value) ; 
                                                    setFieldValue('cost_allocation', e.target.value) }}
                                                name='cost_allocation' style={{fontSize: '.7rem'}} 
                                            />
                                            <label htmlFor="cost_allocation" className='staan-form-input-label pt-2 px-1 mx-2'>Cost Allocation</label>
                                            <p>{  touched.cost_allocation &&  errors.cost_allocation && <small>{errors.cost_allocation}</small>}</p>
                                        </div>
                                    </div>
                                    <div className='row mx-auto my-3'>
                                        <div className="col-12 staan-input-group">
                                            <textarea className="w-100 staan-form-input-text-area" 
                                                value={selectRemarks}
                                                onChange={(e)=>{
                                                    handleSelectRemarks(e.target.value);
                                                    setFieldValue('remarks', e.target.value)
                                                }}
                                                name="remarks"  style={{fontSize: '.7rem'}} >
                                            </textarea>
                                            <label htmlFor="remarks" className='staan-form-input-label pt-2 px-1 mx-2'>Remarks</label>
                                            <p>{  touched.remarks &&  errors.remarks && <small>{errors.remarks}</small>}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mx-2'>
                                        {bomType && bomType === 'SUBCONTRACT' && <div className="col-6 staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                onChange={(e)=>{
                                                    setSelectLabourCharges(e.target.value); 
                                                    setFieldValue('labourCharges', e.target.value);
                                                }}
                                                value={selectLabourCharges}
                                                name='labourCharges' style={{fontSize: '.7rem'}} 
                                            />
                                            <label htmlFor="labourCharges" className='staan-form-input-label pt-2 px-1 mx-2'>Labour Charges</label>
                                            <p>{ touched.labourCharges &&  errors.labourCharges && <small>{errors.labourCharges}</small>}</p>
                                        </div>}
                                        <div></div>
                                        <div>
                                            <button type="submit" 
                                                className='btn btn-sm btn-outline-success px-3'
                                                onClick={()=>{console.log(errors)}}
                                            >Save</button>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default AddFinishedGoods;