import React from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import logo from "../../img/maxnik_Logo.png"
import "./Dashboard.css"


const Dashboard = () => {
  return (
     <>

        <div className='container-fluid' style={{width:"100%", position:"relative"}}>
            <div className='' style={{position:"fixed", top:"0", left:"0", width:"10%", zIndex:"20"}}>
                <SideNavbar/>
            </div>
            <div className='d-flex align-items-center' style={{height:"100vh",  }}>
                <div className="image-wrapper" style={{ position: "relative", width: "90%", height: "50vw" }}>
                    <img src={logo} alt="Staan" className="dynamic-image" />
                </div>
            </div>
        </div>
        
    </>
  )
}

export default Dashboard