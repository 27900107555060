import * as XLSX from 'xlsx';

const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
};

export const handleExcelReportDownload = ( data, fileName) => {
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push('Sheet 1');
    let wsData = data.map(row => Object.values(row));
    if(fileName === 'pos_detailed_report'|| fileName === "pos_stock_report"){
        const headers = Object.keys(data[0]);
        wsData = [headers].concat(data.map(row => Object.values(row)));
    }
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    wb.Sheets['Sheet 1'] = ws;
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = `${fileName}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};

