import { AgGridReact } from 'ag-grid-react';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import Can from "../../../components/authorizationComponents/Can";
import LoadingModelBallTriangle from '../../../components/loading/BallTriangle';
import DataContext from '../../../context/ItemMasterContext';
import { FetchDataItem } from '../../../CustomRequests/FetchData';
import { CreateStockHistory } from '../../../GraphQLQueries/InventoryMutation';
import {
    BatchNumberOptionFetchQuery, ItemMasterFetchSingleQuery,
    StockDataFetch, StockSerialHistoryDataFetch,
    TotalStockByPartCode,
    itemMasterOptionFetchQuery,
    storeFetchQuery
} from '../../../GraphQLQueries/ItemmasterQuery';
import { ConferenceFetchQuery } from '../../../GraphQLQueries/StoreQueries';
import { fetchItemMasterData } from "../../../ReduxState/Action/itemMasterAction";
import { fetchStockSerialHistoryData } from '../../../ReduxState/Action/stockAction';
import { fetchStoreData } from '../../../ReduxState/Action/storeActions';
import { customSelectStyle, onCellDoubleClicked } from '../../../utils/Utils';
import { StockSchema } from '../../../validations/itemmaster';


const StockAddition = ({show, close, setLoad, isLoad}) => {
    const { userId, screenSize } = useContext(DataContext)
    const [isStockAddtionsFormShow, setIsStockAddtionsFormShow] =  useState(false)
    const [formKey, setFormKey] = useState(0)
    const dispatch = useDispatch(); 
    const storeDataList = useSelector(state => state.storeData.data);
    const itemMasterDataList = useSelector(state => state.itemMasterData.data);
    const stockSerialHistoryDataList = useSelector(state =>  state.stockSerialHistory.data);
    const [conferenceDataList, setConferenceDataList] = useState([]);
    const [showConference, setShowConference] = useState(false);
    const [totalQty, setTotalQty] = useState(0);

    const [post, setPost] = useState([])
    const [UOMOptions, setUOMOptions] = useState([])

    const [storeSelect, setStoreSelete] = useState([]);
    const handleSelectStore = (options)=>{ 
        setShowConference(options.conference)
        setStoreSelete({ value: options.value , label: options.label }
    )}

    const [selectedConference, setSelectedConference] = useState({label: '', value: ''});
    const handleSelectConference = (options) => { setSelectedConference({ value: options.value, label: options.label })}
    
    const [selectpartCode, setSelectpartCode] = useState({label: '', value: ''})
    const handleSelectpartCode = async (option) =>{ 
        setSelectpartCode({ value: option?.value , label:  option?.label })
    }
    
    const [selectpartName, setSelectpartName] = useState({label: '', value: ''})
    const handleSelectpartName = async (option)=>{ 
       
        setSelectpartName({ value: option?.value , label:  option?.label })
    }

    const [qty, setQty] = useState('')
    const handleSelectQty = (value)=>{
        setQty(value);
    }

    const [selectUom , setSelectUom] = useState() 
    const handleSelectUom = (option)=>{ setSelectUom({ value: option?.value, label:  option?.label })}

    const [BatchNumber, setBatchNumber] = useState()
    const handleBatchNumber = (Value)=>{ setBatchNumber(Value)}

    const [ SerialNumber, setSerialNumber] = useState([]);
    const handleSerialNumber = (Value) => { setSerialNumber(Value)};
        
    const [SerialStart, setSerialStart] = useState("")
    const [SerialFormat, setSerialFormat] = useState("")

    // table currently editing or not
    const [isTableCurrentlyActive, setTableCurrentlyActive] = useState(false)
    
    const SerialNumberRenderer = (props) => {
        return <span>{props.rowIndex + 1}</span>;
      };
    
    const DeleteButton = ({value, data})=>{
            // handles single row delete in state level
        const getDeleteRowId=()=>{
            setDeleteData(data)
            handleShowDeleteModel()
        }
    return(
        <>
        <button
            type='button'
            className='btn btn-outline-danger btn-sm px-3 mx-2'
            onClick={getDeleteRowId}
          
            >
            <i className='fa-solid fa-trash '></i>
        </button>
        </>
    )
    }
    // initial Values to Formik input
    const [initialValues, setInitialValues] = useState({
        id : "",
        part_no : "",
        part_name : "",
        qty : "",
        UOM : "",
        BatchNumber: "",
        Serialnum : [] ,
        SerialNumberStart : "",
        SerialNumberFormat : "",
        isEdit:false
    })
    
    const COLUMNS = [
        {
            headerName: 'S.No',
            field: 'sNo',
            flex: .7,
            cellRenderer: 'serialNumberRenderer', // Use custom renderer
            onCellDoubleClicked: onCellDoubleClicked
        },
        {
            headerName: 'Part Code',
            field: 'part_no.label',
            flex: 2,
            cellRenderer:'EditView',
            onCellDoubleClicked: onCellDoubleClicked
        },
        {
            headerName: 'Part Name',
            field: 'part_name.label',
            flex: 2,
            onCellDoubleClicked: onCellDoubleClicked
        },
        {
            headerName: 'Qty',
            field: 'qty',
            flex: 1,
            onCellDoubleClicked: onCellDoubleClicked
        },
        {
            headerName: 'UOM',
            field: 'UOM.label',
            flex: 1,
            onCellDoubleClicked: onCellDoubleClicked
        },
        {
            headerName: 'Batch Number',
            field: 'BatchNumber',
            flex: 1,
            onCellDoubleClicked: onCellDoubleClicked
        },
        {
            headerName: 'Serial Number',
            field: 'Serialnum',
            flex: 1,
            onCellDoubleClicked: onCellDoubleClicked
        },
        {
            headerName: 'Serial Number Start',
            field: 'SerialNumberStart',
            flex: 1,
            onCellDoubleClicked: onCellDoubleClicked
        },
        {
            headerName: 'Serial Number Format',
            field: 'SerialNumberFormat',
            flex: 1,
            onCellDoubleClicked: onCellDoubleClicked
        },
        {
            headerName: 'Action',
            cellRenderer : DeleteButton,
            flex: 2,
        },
            
    ]
    const DEFAULT_COL_DEF ={
        filter:true, floatingFilter:true,
        resizable: true,
    }
    const EditView = params=>{
   
        const handleEdit=()=>{
            
            if(params?.data?.['part_no']?.['value']){
                setInitialValues({
                    id : params?.data?.id,
                    part_no : params?.data?.['part_no']?.['value'],
                    part_name : params?.data?.['part_name']?.['value'],
                    qty : params?.data?.['qty'],
                    UOM : params?.data?.['UOM']?.['value'],
                    BatchNumber: params?.data?.['BatchNumber'],
                    Serialnum : params?.data?.['Serialnum'] ,
                    SerialNumberStart : params?.data?.['SerialNumberStart'],
                    SerialNumberFormat :params?.data?.['SerialNumberFormat'],
                    isEdit:true,
                })
                handleSelectpartCode(params?.data?.['part_no'])
                handleSelectpartName(params?.data?.['part_name'])
                handleSelectQty(params?.data?.['qty'])
                handleSelectUom(params?.data?.['UOM'])
                setBatchNumber(params?.data?.['BatchNumber'])
                setSerialNumber(params?.data?.['Serialnum'])
                setSerialStart(params?.data?.['SerialNumberStart'])
                setSerialFormat(params?.data?.['SerialNumberFormat'])
                setFormKey(prve=> prve+1)
            }
            setIsStockAddtionsFormShow(true)
        }
        return(
            <>
                <p className='text-primary' onClick={handleEdit}>{params?.data?.part_no.label}</p>
            </>
        )

    }
    
    const COMPONENTS = {
        serialNumberRenderer: SerialNumberRenderer,
        EditView : EditView,
        DeleteButton : DeleteButton,
        // ratecellrender : ratecellrender
    };

    //Get Uom
    const  getUom =  async (value)  => {
        setUOMOptions([])
        let selectItemmasterdata = await FetchDataItem(ItemMasterFetchSingleQuery(value))
        selectItemmasterdata = selectItemmasterdata ? selectItemmasterdata['itemMaster']?.['items'] : []
        if(selectItemmasterdata.length > 0) {
            selectItemmasterdata = selectItemmasterdata[0]
        }else{
            selectItemmasterdata = {}
        }
        const item_uom_options = selectItemmasterdata?.['itemUom']
        if(item_uom_options){
            setUOMOptions([{label: item_uom_options?.name, value: item_uom_options?.id}])
        }
        // listUom.map((uomid)=>{
        //     let uomobj = ItemUOMdata.find(item =>item.id === uomid )
        //     setUOMOptions(prev=> [...prev, { value: uomobj?.id , label:  uomobj?.name }])
            
        // })
        // for (let AlternateUomId of ItemUom?.['Alternate_uom']) {
        //     try{
        //     const respones = await axiosInstance.get(`/itemmaster/Alternate/${AlternateUomId}`);
        //     let uomobj2 = ItemUOMdata.find(item =>item.id === respones.data.addtional_unit )
        //     setUOMOptions(prev=> [...prev, { value: uomobj2.id , label:  uomobj2.name, conversion_Factor : respones.data.conversion_Factor  }])
        //     } catch(error){
        //     console.log(error);
        //     }
        // }  
    }

    // to clear the input data
    function clearinput(params) {
        setSelectpartCode({ value: '' , label:  '' }) 
        setSelectpartName({ value: '' , label:  '' })
        setQty('')
        setSelectUom({ value: '' , label:  '' })
        setBatchNumber('')
        setSerialNumber('')
        setSerialFormat('')
        setSerialStart('')
        setIsStockAddtionsFormShow(false)
        setInitialValues({
            id : "",
            part_no : "",
            part_name : "",
            qty : "",
            UOM : "",
            BatchNumber: "",
            Serialnum : [] ,
            SerialNumberStart : "",
            SerialNumberFormat : "",
            isEdit:false
        })
        
    }

    // Convert the number to a string and pad it with leading zeros
    function generateZeros(totalDigits) {
        return ''.padStart(totalDigits, '0');
    }
    
    // generates auto serial number based on given input
    const handlesAutoGenerateSerialNumber = (serial_start, serial_number_format, stock_qty) => {
        let serial_starting_number = Number(serial_start)
        let created_last_stock_serial_number = Number(serial_starting_number)
        let starting_letter = serial_number_format.split('#')[0]
        let number_of_zeros = serial_number_format.split('#')[1]
        let generatedSerialNumber = []
        for(let i = 0; i < Number(stock_qty); i++){
            let serial_number_with_zeros = generateZeros(number_of_zeros);
            let temp_serial_number = `${starting_letter}${serial_number_with_zeros}${serial_starting_number+i}`
            generatedSerialNumber.push(temp_serial_number)
            created_last_stock_serial_number = serial_starting_number + i
        }
        return [generatedSerialNumber, Number(created_last_stock_serial_number) + 1];
    }

    // check duplicate row inserted
    const handleDuplicateRowInserted = (current_data, item_master_data, isScanned) => {
        if(item_master_data['serial'] && isScanned && item_master_data['serialAutoGentrate']){
            alert_custom_error('This is a Auto Generate Serial Item. Please Enter it Manually!')
            return true;
        }
        let current_list_items = [...post]
        console.log("current_list_items", current_list_items);
        
        console.log("current_data.id", current_data.id);
        
        let previous_table_data = current_list_items.find(table_item => table_item.part_no.value === current_data.part_no.value && table_item.id !== current_data.id)
        if(!previous_table_data){ 
            return false
        }else{
            let current_serial_number = Array.isArray(current_data['Serialnum']) ? current_data['Serialnum'][0] : current_data['Serialnum']
            let previous_serial_number = Array.isArray(previous_table_data['Serialnum']) ? previous_table_data['Serialnum'][0] : previous_table_data['Serialnum']
            let is_already_exists_i = previous_serial_number.includes(current_serial_number)
            if(item_master_data['serial'] && isScanned && is_already_exists_i){
                alert_custom_error('This item already Exists')
                return true;
            }else{
                current_list_items = current_list_items.filter(item => item.id !== previous_table_data.id)
            }
        }
        if(item_master_data['batchNumber']){
            if(previous_table_data['BatchNumber'].trim() === current_data['BatchNumber'].trim()){
                let existing_stock_count = Number(previous_table_data['qty']) + Number(current_data['qty'])
                previous_table_data['qty'] = existing_stock_count
                setPost((prevData)=>{
                    return prevData.map((item)=>{
                        return item.id === previous_table_data.id ? previous_table_data : item;
                    })
                })
                return true
            }
        }else if(item_master_data['serial'] && item_master_data['serialAutoGentrate']){
            let existing_stock_count = Number(previous_table_data['qty']) + Number(current_data['qty'])
            previous_table_data['qty'] = existing_stock_count

            let [serial_numbers_auto_generated, last_serial_number] = handlesAutoGenerateSerialNumber(SerialStart, SerialFormat, existing_stock_count);
            previous_table_data['Serialnum'] = serial_numbers_auto_generated.join(', ')
            previous_table_data['lastSerialHistory'] = last_serial_number
            setPost((prevData)=>{
                return prevData.map((item)=>{
                return item.id === previous_table_data.id ? previous_table_data : item ;
                })
            })
            return true
        }else if(item_master_data['serial'] && !item_master_data['serialAutoGentrate']){
            let existing_stock_count = Number(previous_table_data['qty']) + Number(current_data['qty'])
            previous_table_data['qty'] = existing_stock_count

            let existing_serial_number = previous_table_data['Serialnum']
            existing_serial_number = Array.isArray(existing_serial_number) ? existing_serial_number : existing_serial_number.replace(/,\s*$/, "").split(',')
            let current_serial_number = current_data['Serialnum']
            current_serial_number = Array.isArray(current_serial_number) ? current_serial_number : current_serial_number.replace(/,\s*$/, "").split(',')
            previous_table_data['Serialnum'] = existing_serial_number.concat(current_serial_number).join(', ')

            setPost((prevData)=>{
                return prevData.map((item)=>{
                return item.id === previous_table_data.id ? previous_table_data : item ;
                })
            })
            return true
        }else if(!item_master_data['serial'] && !item_master_data['batchNumber']){
            let existing_stock_count = Number(previous_table_data['qty']) + Number(current_data['qty'])
            previous_table_data['qty'] = existing_stock_count
            setPost((prevData)=>{
                return prevData.map((item)=>{
                    return item.id === previous_table_data.id ? previous_table_data : item ;
                })
            })
            return true
        }else{
            return false
        }
    }

    // data validation for batch number and serial number
    const handleDataValidation = async (raw_data, item_master_data) => {
        if(!item_master_data){
            return 'Please Enter Input!'
        }
        let has_batch_number = item_master_data['batchNumber']
        let has_serial_number = item_master_data['serial']
        let is_auto_generate_serial_number = item_master_data['serialAutoGentrate']
        let serial_num_by_user_input = raw_data['Serialnum']
        if(typeof(raw_data['Serialnum']) === 'string'){
            serial_num_by_user_input = serial_num_by_user_input.replace(/,\s*$/, "")
            serial_num_by_user_input = serial_num_by_user_input.replace(/\s\s+/g, ' ');
            serial_num_by_user_input = serial_num_by_user_input.split(',')
        }
        let qty_by_user_input = raw_data['qty']
        let serial_num_length = serial_num_by_user_input.length
        if(has_batch_number && raw_data['BatchNumber'].trim() === ''){
            return 'Batch Number is required!'
        }else if(has_batch_number){
            // let batch_number_exists = BatchNumberData.find(item => item.BatchNumberName.trim() === raw_data['BatchNumber'].trim())
            // if(batch_number_exists && !(batch_number_exists.part_no === Number(raw_data.part_no.value))){
            //   return `Batch Number ${raw_data['BatchNumber']} is already Exists!`
            // }
        }
        if(has_serial_number && is_auto_generate_serial_number){
            // pass
        }else if(has_serial_number){
            if(serial_num_by_user_input === ''){
                return 'Serial Number is required!'
            }else if(serial_num_length !== Number(qty_by_user_input)){
                return 'Serial Number length does not match with quantity!'
            }else{
                serial_num_by_user_input = serial_num_by_user_input.map(item => item.trim())
                const duplicates = serial_num_by_user_input.filter((value, index, array) => array.indexOf(value) !== index);
                if (duplicates.length > 0) {
                    return `Serial Number ${duplicates.join(', ')} is already Exists!`
                }
                let serial_num_by_user_input_joined = serial_num_by_user_input.map(serial =>`"${serial}"`)
                let serial_fetch_query = `query stockSerialNumber{
                    serialNumber(serialNumberList: [${serial_num_by_user_input_joined}]){
                        items{
                            id
                            serialNumber
                        }
                    }
                }`
                let selected_inventory_details = []
                let response = await axiosInstance.post("itemmaster/graphql", {query: serial_fetch_query})
                let serial_numbers = response?.data?.data?.serialNumber?.items;
                selected_inventory_details = serial_numbers.filter(serial_number_item => 
                    serial_num_by_user_input.includes(serial_number_item.serialNumber)
                ).map(item => item.serialNumber);
                if(selected_inventory_details.length > 0){
                    return `Serial Number ${selected_inventory_details.join(', ')} is already Exists!`
                }
            }
        }else{
            return NaN
        }
        return NaN
    }

    const [error, setError] = useState('');

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors, setFieldError },   rowdata) => {
         
        
        if(serialOrBatch === "batch"){ 
            if (values['BatchNumber'] === "") {
                setFieldError ('BatchNumber', 'Batch Number is required');
                setError('Batch Number is required')
            } else {
                setFieldError ('BatchNumber', null);
                setError('')
            }
        }
        if(serialOrBatch === 'serial'){ 
        if(values['Serialnum'].length<1){
            setFieldError ('Serialnum', 'Serial Number is required');
            setError('Serial Number is required')
        } else {  
            setFieldError ('Serialnum', null);
            setError('')
        } 
        } 
        let serial_numbers_auto_generated = false
        let last_serial_number = false
        if(serialOrBatch === 'autoGet'){
            [serial_numbers_auto_generated, last_serial_number] = handlesAutoGenerateSerialNumber(SerialStart, SerialFormat, qty)
            setSerialNumber(serial_numbers_auto_generated.join(', '))
            serial_numbers_auto_generated = serial_numbers_auto_generated.join(', ')
           
            
        } 
        let selectItemmasterdata = await FetchDataItem(ItemMasterFetchSingleQuery(selectpartCode?.value))
        let item_master_data = selectItemmasterdata ? selectItemmasterdata['itemMaster']?.['items'] : []
        if(item_master_data.length > 0) {
            item_master_data = item_master_data[0]
        }else{
            item_master_data = null
        } 
        if(error === ""){
            let savedValue = {
                id: rowdata.id,
                BatchNumber: BatchNumber,
                SerialNumberFormat: SerialFormat,
                SerialNumberStart: SerialStart,
                Serialnum: Array.isArray(serial_numbers_auto_generated) ? serial_numbers_auto_generated : SerialNumber,
                UOM: selectUom,
                part_name: selectpartName,
                part_no: selectpartCode,
                qty: Number(qty),
                lastSerialHistory: last_serial_number ? last_serial_number : SerialStart
            }
            console.log(savedValue);
            
            let batch_and_serial_validation = handleDataValidation(savedValue, item_master_data)
            if (batch_and_serial_validation instanceof Promise) {
                batch_and_serial_validation.then(validationResult => {
                    batch_and_serial_validation =  validationResult
                    if(batch_and_serial_validation){
                        alert_custom_error(batch_and_serial_validation)
                    }else{
                        let is_duplicated = handleDuplicateRowInserted(savedValue, item_master_data)
                        if(!is_duplicated){
                            if(rowdata['part_no']['value']){
                                setPost((prevData) => {
                                    return prevData.map((item) => {
                                        return item.id === savedValue.id ? savedValue : item;
                                    });
                                })
                            
                            } else{
                                setPost(previousData => {
                                    const index = previousData.findIndex(item => item.id === savedValue.id);
                                    if (index !== -1) {
                                        // If item with same id exists, update it
                                        return [
                                            ...previousData.slice(0, index),  // Keep items before the updated one
                                            savedValue,  // Add the updated item
                                            ...previousData.slice(index + 1)  // Keep items after the updated one
                                        ];
                                    } else {
                                        // If item with same id doesn't exist, add the new item
                                        return [...previousData, savedValue];
                                    }
                                });
                             
                            }
                        }
                        resetForm()
                        clearinput()
                    }
                }).catch(error => {
                    batch_and_serial_validation = null
                    alert_custom_error('Error while validating Serial Number!')
                });
            }else if(batch_and_serial_validation){
                alert_custom_error(batch_and_serial_validation)
            }else{
                let is_duplicated = handleDuplicateRowInserted(savedValue, item_master_data)
                if(!is_duplicated){
                    if(rowdata['part_no']['value']){
                        setPost((prevData) => {
                            return prevData.map((item) => {
                                return item.id === savedValue.id ? savedValue : item;
                            });
                        })
                      
                    } else{
                        setPost(previousData => {
                            const index = previousData.findIndex(item => item.id === savedValue.id);
                            if (index !== -1) {
                                // If item with same id exists, update it
                             
                                return [
                                    ...previousData.slice(0, index),  // Keep items before the updated one
                                    savedValue,  // Add the updated item
                                    ...previousData.slice(index + 1)  // Keep items after the updated one
                                ];
                                
                            } else {
                                  
                                // If item with same id doesn't exist, add the new item
                                return [...previousData, savedValue];
                            }
                        });
                    }
                }
                 
                resetForm()
                clearinput()
            }
        }
    }

    // check batch number or serial number
    const [serialOrBatch, setSerialOrBatch] = useState(null)

    const getSerial_batch = async (value) => {
        let selectItemmasterdata = await FetchDataItem(ItemMasterFetchSingleQuery(value))
        selectItemmasterdata = selectItemmasterdata ? selectItemmasterdata['itemMaster']?.['items'] : []
        if(selectItemmasterdata.length > 0) {
            selectItemmasterdata = selectItemmasterdata[0]
        }else{
            selectItemmasterdata = {}
        }
        if(selectItemmasterdata?.batchNumber){
            setSerialOrBatch('batch')
            setSerialFormat('')
            setSerialStart('')
        }else if(selectItemmasterdata?.serial){
            if(selectItemmasterdata?.serialAutoGentrate){
                setSerialFormat(selectItemmasterdata?.serialFormat)
                let serial_starting_number = stockSerialHistoryDataList?.items?.find(serial_id => value === serial_id.partNo.id)
                serial_starting_number = serial_starting_number?.lastSerialHistory
                if(serial_starting_number){
                    setSerialStart(parseInt(serial_starting_number))
                }
                setSerialOrBatch('autoGet')
            } else{
                setSerialOrBatch('serial')
                setSerialFormat('')
                setSerialStart('')
            }
        }else{
            setSerialOrBatch('')
            setSerialFormat('')
            setSerialStart('')
        }
    }


    const handleOnInputChangeTypeSearch = (value, field) => {
        let extra_filter = {
            itemTypes: 'Product',
            itemComboBool: false
        }
        if(value.trim() !== ''){
            let filter_obj = {
                key: field,
                value: value
            }
            dispatch(fetchItemMasterData(itemMasterOptionFetchQuery(filter_obj, extra_filter)));
        }else{
            dispatch(fetchItemMasterData(itemMasterOptionFetchQuery(null, extra_filter)))
        }
    }

    // input section
    const stockAddtionsFrom = ()=>{
    
        return(
        <>
            <Modal
            show={isStockAddtionsFormShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header  >
                <Modal.Title style={{color:"#5CADA9"}}>
                    <i className='fa-solid fa-arrow-left fa-sm me-2 cursor-pointer' onClick={()=>{setIsStockAddtionsFormShow(false);
                        clearinput()
                    }}></i>Add Item
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={StockSchema}
                        enableReinitialize 
                        key={formKey}
                        onSubmit={(values, formikProps) => handleSubmit(values, formikProps, values)}>
                        {({errors,  touched, setFieldValue, values})=>(
                                <>
                                <Form>
                                    <div className="row">
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemPartCode')}}
                                                name="part_no" 
                                                options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemPartCode, name:item.itemName  }))}
                                                isSearchable={true}
                                                isClearable
                                                value={selectpartCode}
                                                onChange={(option) => {
                                                    setFieldValue('part_no', option ? option?.value : null);
                                                    setFieldValue('part_name', option ? option?.value : null)
                                                    handleSelectpartCode(option)
                                                    getSerial_batch(option?.value)
                                                    getUom(option?.value)
                                                    setSelectUom('')
                                                    setBatchNumber('')
                                                    setSerialNumber('')
                                                    setSelectpartName({ value: option?.value , label:  option?.name })
                                                }}
                                                styles={customSelectStyle}
                                                className='mt-3'
                                            />
                                            <label htmlFor="part_no" className='staan-form-input-label pt-1 px-1 mx-2' onClick={()=>{console.log(initialValues);
                                            }}>Part Code</label>
                                            {  touched.part_no &&  errors.part_no && <small>{errors.part_no}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                name="part_name"
                                                onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemName')}}
                                                options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemName , partCode: item.itemPartCode}))}
                                                isSearchable={true}
                                                value={selectpartName}
                                                isClearable
                                                onChange={(option) => {
                                                    setFieldValue('part_name', option ? option?.value : null);
                                                    handleSelectpartName(option) 
                                                    getSerial_batch(option?.value)
                                                    getUom(option?.value)
                                                    setFieldValue('part_no', option ? option?.value : null)
                                                    setSelectUom('')
                                                    setBatchNumber('')
                                                    setSerialNumber('')
                                                    setSelectpartCode({ value: option?.value , label:  option?.partCode })}
                                                }
                                                styles={customSelectStyle}
                                                className='mt-3'
                                            />
                                            <label htmlFor="part_Name" className='staan-form-input-label pt-1 px-1 mx-2'>Part Name  </label>
                                            {  touched.part_name &&  errors.part_name && <small>{errors.part_name}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                                <Field type='text' id='qty' name='qty'  value={qty} 
                                                    onChange={(e) => {setFieldValue('qty', e.target.value);
                                                    handleSelectQty(e.target.value)}}
                                                    autocomplete="off"
                                                    style = {{backgroundColor: "#CCF2FF"}}
                                                    className='w-100 staan-form-input '/> 
                                                <label htmlFor="qty" className='staan-form-input-label pt-1 px-1 mx-2'>Qty  </label>
                                                {touched.qty &&  errors.qty && <small>{errors.qty}</small>}
                                        </div>
                                        <div className='col-6 staan-input-group'>
                                            <Select
                                                name="UOM" 
                                                options ={UOMOptions}
                                                value={selectUom}
                                                onChange={(option)=>{
                                                    setFieldValue('UOM', option ? option?.value : null)
                                                    handleSelectUom({ value: option?.value , label:  option?.label })
                                                }}
                                                styles={customSelectStyle}
                                                className='mt-3' 
                                            /> 
                                            <label htmlFor="UOM" className='staan-form-input-label pt-1 px-1 mx-2'>UOM</label>
                                        {  touched.UOM &&  errors.UOM && <small>{errors.UOM}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' id='BatchNumber' name='BatchNumber'  value={BatchNumber} 
                                                onChange={(e) => {setFieldValue('BatchNumber', e.target.value);
                                                    handleBatchNumber(e.target.value)}}
                                                    className='w-100 staan-form-input' disabled={serialOrBatch === 'batch' ? false : true}
                                                    autocomplete="off"
                                            />
                                            <label htmlFor="BatchNumber" className='staan-form-input-label pt-1 px-1 mx-2'>Batch Number</label> 
                                            {touched.BatchNumber &&  errors.BatchNumber && <small>{errors.BatchNumber}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' id='Serialnum' name='Serialnum'  value={SerialNumber} 
                                                onChange={(e) => {setFieldValue('Serialnum', e.target.value)
                                                handleSerialNumber(e.target.value)}}
                                                disabled={serialOrBatch === 'serial' ? false : true}
                                                className='w-100 staan-form-input'
                                                autocomplete="off"
                                            />
                                            <label htmlFor="Serialnum" className='staan-form-input-label pt-1 px-1 mx-2'>Serial Number</label> 
                                            {touched.Serialnum &&  errors.Serialnum && <small>{errors.Serialnum}</small>}
                                        </div>
                                        <div className='col-6 staan-input-group'>
                                            <input type='text' className='w-100 staan-form-input ' disabled value={SerialFormat} /> 
                                            <label className='staan-form-input-label pt-1 px-1 mx-2' >Serial format</label>
                                        </div>
                                        <div className='col-6 staan-input-group'>
                                            <input type='text' className='w-100 staan-form-input'  disabled value={SerialStart} />
                                            <label className='staan-form-input-label pt-1 px-1 mx-2' >Serial Start</label>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='text-end'>
                                                <button type="button" className="btn btn-outline-danger btn-sm me-2 " onClick={()=>{setIsStockAddtionsFormShow(false); clearinput()}}  >Cancel</button>
                                                <Can  allow_list={["Save_Stock_Addition","Edit_Stock_Addition"]}>
                                                    <button type="submit" className="btn btn-sm btn-outline-success shadow-sm px-3" onClick={()=>{
                                                        if (!values?.isEdit){
                                                            setFieldValue("id",  Number(post?.length))
                                                        }
                                                    }}  >Save</button>
                                                </Can>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
            </Modal.Body>
            
            </Modal>
        </>
        )
    }

    // check any rows current editing 
    const handleTableCurrentlyActive = () => {
        let current_actives = document.getElementsByClassName("fa-regular fa-floppy-disk");
        if(current_actives.length>0){
        setTableCurrentlyActive(true)
        }else{
        setTableCurrentlyActive(false)
        }
    }

    // handles serial number creation and return created ids
    let handleSerialNumberCreation = async (serial_numbers_by_user_input) => {
        let processed_serial_ids = []
        for(let serial_index=0; serial_index< serial_numbers_by_user_input.length; serial_index++){
            let serial_number_create_mutation = `mutation serialnumbermutation {
                serialNumberCreateMutation(createdAt: "", updatedAt: "", serialNumber: "${serial_numbers_by_user_input[serial_index]}"){
                success
                errors
                serialNumberItem{
                    id
                  }
              }
            }`
            let serial_number_response = await axiosInstance.post(`/itemmaster/graphql`, {'query': serial_number_create_mutation});
            let serial_number_response_data = serial_number_response?.data?.data?.serialNumberCreateMutation
            if(serial_number_response_data?.success){
                processed_serial_ids.push(serial_number_response_data?.serialNumberItem?.id)
            }else{
                console.log(serial_number_response_data?.errors)
            }
        }
        return processed_serial_ids
    }

    // handles stock addition 
    let handleStockAddition = async (row_data, method_type, data_field_type, item_master_data) => {
        let created_inventory_id = null;
        let stock_history_data  = null;
        let selected_store_id = storeSelect?.['value']
        let selected_con_id = selectedConference?.value;
        let part_code_id = row_data.part_no.value

        // create new stock data
        let stock_data_new = {
            "currentStock": row_data['qty'],
            "batchNumber": '',
            "lastSerialHistory": row_data['lastSerialHistory'] ? row_data['lastSerialHistory']: 0,
            "partNumber": part_code_id,
            "store": selected_store_id,
            "serialNumber": [],
            'unit': row_data?.UOM?.value,
        }

        // create new inventory_approval_data
        let inventory_approval_data = {
            "qty": row_data['current_qty_added'] ? row_data['current_qty_added'] : row_data['qty'],
            "batchNumber": '',
            "partNumber": part_code_id,
            "store": selected_store_id,
            "serialNumber": [],
            'unit': row_data?.UOM?.value
        }

        // console.log(row_data);
        let previous_state = 0
        axiosInstance.post("/itemmaster/graphql", {query: TotalStockByPartCode(part_code_id)})
        .then(res => {
            let total_stock_data = res?.data?.data?.itemStockByPartCode?.items
            total_stock_data = total_stock_data?.[0]?.totalCurrentStock
            if(total_stock_data){
                previous_state = total_stock_data
            }
        })
        .catch(err => {
            console.log(err)
        })
        
        // checks whether serial number auto generated is true or false
        let is_auto_generate = false;
        if(item_master_data){
            is_auto_generate = item_master_data['serialAutoGentrate']
        }  

        // updates based on data_field_type value - serial, batch or no_batch_and_no_serial
        if(data_field_type === 'serial'){
            let serial_number_list = row_data['Serialnum']?.filter(item => item !== undefined)
            let current_serial_number_list = row_data['current_Serialnum']?.filter(item => item !== undefined && !isNaN(item) && item !== null)
            stock_data_new["serialNumber"] = serial_number_list 
            inventory_approval_data["serialNumber"] = row_data['current_Serialnum'] ? current_serial_number_list : serial_number_list
            if(row_data['stock_unique_id']){

            }
            // updates last serial history if serial number auto generated
            if(is_auto_generate){
                stock_data_new['lastSerialHistory'] = row_data['lastSerialHistory']
            }
        }else if(data_field_type === 'batch'){
            stock_data_new["batchNumber"] = row_data['BatchNumber']
            inventory_approval_data["batchNumber"] = row_data['BatchNumber']
        }else{
            stock_data_new["batchNumber"] = ''
            inventory_approval_data["batchNumber"] = ''
            stock_data_new["serialNumber"] = []
            inventory_approval_data["serialNumber"] = []
        }

        try {
            let serial_number_ids = stock_data_new['serialNumber'].length > 0? stock_data_new['serialNumber'] : []
            serial_number_ids = serial_number_ids.filter(item => typeof item === 'string')
            // updating stock table depends on methods post or put
            let stock_mutation_query = `mutation itemstockmutation {
                itemStockCreateMutation(store: ${stock_data_new['store']}, currentStock: ${stock_data_new['currentStock']}, partNumber: ${stock_data_new['partNumber']},
                unit: ${stock_data_new['unit']}`
            if(showConference){
                stock_mutation_query += `, conference: ${selected_con_id}`
            }
            if(data_field_type === 'batch'){
                stock_mutation_query = `mutation itemstockmutation {
                    itemStockCreateMutation(store: ${stock_data_new['store']}, currentStock: ${stock_data_new['currentStock']}, partNumber: ${stock_data_new['partNumber']},
                    unit: ${stock_data_new['unit']}, batchNumber: ${stock_data_new["batchNumber"]}`
            }else if(data_field_type === 'serial'){
                stock_mutation_query = `mutation itemstockmutation {
                    itemStockCreateMutation(store: ${stock_data_new['store']}, currentStock: ${stock_data_new['currentStock']}, partNumber: ${stock_data_new['partNumber']},
                    unit: ${stock_data_new['unit']}, lastSerialHistory: ${stock_data_new['lastSerialHistory']}, serialNumber: [${serial_number_ids}]`
            }
            if(row_data['stock_unique_id']){
                stock_mutation_query += `, id: ${row_data['stock_unique_id']}`
            }
            stock_mutation_query += `){
                itemStock{
                    id
                }
                success
                errors
                }
            }`
            // console.log(stock_mutation_query)
            const stock_create_response = await axiosInstance.post(`/itemmaster/graphql`, {'query': stock_mutation_query});
            const stockResponseData = stock_create_response?.data?.data?.itemStockCreateMutation
            if(stockResponseData?.success){
                let updated_state = previous_state + Number(row_data['current_qty_added'] ? row_data['current_qty_added'] : row_data['qty'])
                stock_history_data = {
                    "action": "ADD",
                    "stockLink": stockResponseData.itemStock.id,
                    "storeLink": Number(selected_store_id),
                    "partNumber": part_code_id,
                    "previousState": previous_state,
                    "added": row_data['current_qty_added'] ? row_data['current_qty_added'] : row_data['qty'],
                    "reduced": 0,
                    "updatedState": updated_state,
                }
                
                if(stock_data_new['serialNumber'].length > 0){
                    let stock_serial_history_exists = stockSerialHistoryDataList?.items?.find(item => String(item.partNo.id) === String(part_code_id))
                    let stock_serial_history_create_mutation = `mutation stockSerialHistoryCreation {
                        stockSerialHistoryCreateMutation(partNo: ${part_code_id}, lastSerialHistory: ${stock_data_new['lastSerialHistory']}`
                    if(stock_serial_history_exists){
                        stock_serial_history_create_mutation += `, id: ${stock_serial_history_exists['id']}`
                    }
                    stock_serial_history_create_mutation +=  `){
                                success
                                errors
                                stockSerialHistoryInstance{
                              id
                              lastSerialHistory
                              partNo{
                                id
                                itemPartCode
                              }
                            }
                        }
                    }`
                    await axiosInstance.post(`/itemmaster/graphql`, {'query': stock_serial_history_create_mutation});
                }

                // updating inventory approval table
                try {
                    let batch_number_update = inventory_approval_data['batchNumber'] ? inventory_approval_data['batchNumber'] : ''
                    let inventory_approval_mutation_query = ''
                    if(batch_number_update !== ''){
                        inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
                            inventoryApprovalCreateMutation(partNumber: ${inventory_approval_data['partNumber']},
                             batchNumber: ${batch_number_update}, unit: ${inventory_approval_data['unit']}, qty: "${inventory_approval_data['qty']}",
                              store: ${inventory_approval_data['store']}){
                              inventoryApprovalItem {
                                id
                              }
                              success
                              errors
                            }
                          }`
                    }else{
                        inventory_approval_mutation_query = `mutation InventoryApprovalCreateQuery{
                            inventoryApprovalCreateMutation(partNumber: ${inventory_approval_data['partNumber']}, serialNumber: [${inventory_approval_data["serialNumber"]}],
                             unit: ${inventory_approval_data['unit']}, qty: "${inventory_approval_data['qty']}", store: ${inventory_approval_data['store']}){
                              inventoryApprovalItem {
                                id
                              }
                              success
                              errors
                            }
                          }`
                    }
                    
                    const inventory_approval_response = await axiosInstance.post(`/itemmaster/graphql`, {'query': inventory_approval_mutation_query});
                    created_inventory_id = inventory_approval_response?.data?.data?.inventoryApprovalCreateMutation?.inventoryApprovalItem?.id
                } catch (error) {
                    console.error('Error updating stock:', error);
                }
            }
        } catch (error) {
            console.error('Error updating stock:', error);
        }
        return [created_inventory_id, stock_history_data]
    }

    // alert message using toastify
    const alert_custom_error = (error_msg) => {
        toast.error(error_msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const handleBatchNumberCreation = async (batch_number, part_number) => {
        let batch_number_query = BatchNumberOptionFetchQuery(1, 200, {'batchNumber': batch_number,  "partNumber":part_number})
        let batch_number_data = await axiosInstance.post("/itemmaster/graphql", {query: batch_number_query})
        let batch_number_exists = batch_number_data?.data?.data?.batchNumber?.items?.find(item =>
            item.batchNumberName.trim() === batch_number.trim() && String(item.partNumber.id) === String(part_number))
        
        if(!batch_number_exists){
            let batch_mutate_query = `mutation batchNumberCreateMutation {
                batchNumberCreateMutation(partNumber: ${part_number}, batchNumberName: "${batch_number}"){
                    batchNumberItem{
                        id
                    }
                    success
                    errors
                    }
            }`
            let batch_number_response = await axiosInstance.post(`/itemmaster/graphql`, {'query': batch_mutate_query});
            let batch_number_response_data = batch_number_response?.data?.data?.batchNumberCreateMutation?.batchNumberItem?.id
            if(batch_number_response_data){
                return [batch_number_response_data, false]
            }else{ 
                return [false, false]
            }
        }else{
            if(batch_number_exists.partNumber.id === String(part_number)){
                return [batch_number_exists['id'], true]
            }else{
                return [batch_number_exists['id'], false]
            }
        }
    }
    const [loadingModels, setLoadingModels] = useState(false);
    // const LoadingModel = () => {
    //     return (
    //       <>
    //         <Modal show={loadingModels} backdrop='static' keyboard={false} centered>
    //           <Modal.Body className=''>
    //             <div className='d-flex justify-content-center   '>
    //               <Oval
    //                 visible={true}
    //                 height='80'
    //                 width='80'
    //                 color='#4fa94d'
    //                 ariaLabel='oval-loading'
    //                 wrapperStyle={{}}
    //                 wrapperClass=''
    //               />
    //             </div>
    //           </Modal.Body>
    //         </Modal>
    //       </>
    //     )
    //   }

    // submit stock addition table
    const handleStockAdditionSubmit = async () => {
        let selected_store_id = storeSelect?.['value']
        let selected_conf_id = selectedConference?.value === "" ? null : selectedConference.value
        if(isTableCurrentlyActive){
            alert_custom_error('Save all the changes!')
        }else if(post.length < 1){
            alert_custom_error('No data Added')
        }else if(post.length > 0 && !selected_store_id){
            alert_custom_error('Please select store!')
        }else if(post.length > 0 && showConference && !selected_conf_id){
            alert_custom_error('Please select Conference!')
        }else{
            setLoadingModels(true)
            let created_inventory_ids  = [];
            for(let i=0; i<post.length; i++){
                let row_data = post[i]
                let part_code_id = row_data?.part_no?.value
                let batch_number_query = BatchNumberOptionFetchQuery(1, 200, {'batchNumber': row_data?.BatchNumber, "partNumber":part_code_id})
                let batch_number_data = await axiosInstance.post("/itemmaster/graphql", {query: batch_number_query})
                let existed_batch_number = batch_number_data?.data?.data?.batchNumber?.items?.find(item => 
                    item.batchNumberName.trim() === row_data?.BatchNumber.trim() && String(item.partNumber.id) === String(part_code_id))
                
                let stock_fetch_query = StockDataFetch(1, 200, {partNumber: part_code_id, store: selected_store_id}, [])
                let stock_data = await axiosInstance.post("/itemmaster/graphql", {query: stock_fetch_query})
                let stock_data_exists = stock_data?.data?.data?.itemStock?.items?.[0]
                let stock_data_existing_list = stock_data?.data?.data?.itemStock?.items
                let selectItemmasterdata = await FetchDataItem(ItemMasterFetchSingleQuery(part_code_id))
                selectItemmasterdata = selectItemmasterdata ? selectItemmasterdata['itemMaster']?.['items'] : []
                if(selectItemmasterdata.length > 0) {
                    selectItemmasterdata = selectItemmasterdata[0]
                }else{
                    selectItemmasterdata = null
                }
                // check whether batch number is needed or not
                let batch_number = selectItemmasterdata?.['batchNumber']
                // check whether serial number is needed or not
                let serial_number = selectItemmasterdata?.['serial']
                // updates if data already exists in db else creates new one
                if(stock_data_existing_list && stock_data_existing_list.length > 0){
                    // based on batch number or serial number
                    if(batch_number){
                        let batch_data_exists = stock_data_existing_list?.find(item => item?.batchNumber?.id === existed_batch_number?.id);
                        let existing_stock_count = 0
                        if(batch_data_exists){
                            // concate current stock qty with stock qty
                            existing_stock_count = batch_data_exists['currentStock']
                            row_data['stock_unique_id'] = batch_data_exists['id']
                            existing_stock_count = Number(existing_stock_count)
                            row_data['current_qty_added'] = Number(row_data['qty'])
                            row_data['qty'] = existing_stock_count + Number(row_data['qty'])
                            let [batch_number_id, is_already_exists] = await handleBatchNumberCreation(row_data['BatchNumber'], row_data.part_no.value, storeSelect);
                            if(batch_number_id && is_already_exists){
                                row_data['BatchNumber'] = batch_number_id
                                 let created_inventory_id =  handleStockAddition(row_data, 'put', 'batch', selectItemmasterdata)
                                created_inventory_ids.push(created_inventory_id)
                            }else{
                                row_data['BatchNumber'] = batch_number_id
                                 let created_inventory_id =  handleStockAddition(row_data, 'post', 'batch', selectItemmasterdata)
                                created_inventory_ids.push(created_inventory_id)
                            }
                        }else{
                            let [batch_number_id, is_already_exists] = await handleBatchNumberCreation(row_data['BatchNumber'], row_data.part_no.value)
                            // if(batch_number_id && is_already_exists){
                            //     row_data['BatchNumber'] = batch_number_id
                            //     let created_inventory_id =  handleStockAddition(row_data, 'put', 'batch', selectItemmasterdata)
                            //     created_inventory_ids.push(created_inventory_id)
                            // }else{
                            row_data['BatchNumber'] = batch_number_id
                            let created_inventory_id =  handleStockAddition(row_data, 'post', 'batch', selectItemmasterdata)
                            created_inventory_ids.push(created_inventory_id)
                            // }
                        }
                    }else if(serial_number){
                        let serial_number_exists = stock_data_exists
                        // split serial number using comma - user input
                        let serial_numbers_by_user_input = row_data["Serialnum"] 
                        if(typeof(row_data['Serialnum']) === 'string'){
                            serial_numbers_by_user_input = row_data['Serialnum'].trim()
                            serial_numbers_by_user_input = serial_numbers_by_user_input.replace(/,\s*$/, "").split(',')
                        }
                        let existing_serial_number = serial_number_exists['serialNumber']

                        // add serial numbers to serialNumber table
                        let processed_serial_ids = await handleSerialNumberCreation(serial_numbers_by_user_input)
                        // row_data['Serialnum'] = processed_serial_ids
                        // let created_inventory_id =  handleStockAddition(row_data, 'post', 'serial', selectItemmasterdata)
                        // created_inventory_ids.push(created_inventory_id)
                        if(existing_serial_number.length > 0) {
                            row_data['stock_unique_id'] = serial_number_exists['id']
                            // concate current serial numbers with existing serial numbers
                            row_data['current_Serialnum'] = processed_serial_ids
                            let concatenated_serial_numbers = existing_serial_number?.map(item => item.id).concat(processed_serial_ids)
                            row_data['Serialnum'] = concatenated_serial_numbers 
                            // concate current stock qty with stock qty
                            let existing_stock_count = serial_number_exists['currentStock']
                            existing_stock_count = Number(existing_stock_count)
                            row_data['current_qty_added'] = Number(row_data['qty'])
                            row_data['qty'] = existing_stock_count + Number(row_data['qty'])

                             let created_inventory_id =  handleStockAddition(row_data, 'put', 'serial', selectItemmasterdata)
                            created_inventory_ids.push(created_inventory_id)
                        }else{
                            row_data['Serialnum'] = processed_serial_ids
                             let created_inventory_id =  handleStockAddition(row_data, 'post', 'serial', selectItemmasterdata)
                            created_inventory_ids.push(created_inventory_id)
                        }
                    }else{
                        // concate current stock qty with stock qty
                        row_data['stock_unique_id'] = stock_data_exists['id']
                        let existing_stock_count = stock_data_exists['currentStock']
                        existing_stock_count = Number(existing_stock_count)
                        row_data['current_qty_added'] = Number(row_data['qty'])
                        row_data['qty'] = existing_stock_count + Number(row_data['qty'])
                         let created_inventory_id =  handleStockAddition(row_data, 'put', 'nobatchserial', selectItemmasterdata)
                        created_inventory_ids.push(created_inventory_id)
                    }
                }else{
                    if(batch_number){
                        // new stock creation based on batch number
                        let [batch_number_id, is_already_exists] = await handleBatchNumberCreation(row_data['BatchNumber'], row_data.part_no.value)
                        // if(batch_number_id && is_already_exists){
                        //     row_data['BatchNumber'] = batch_number_id
                        //     let created_inventory_id =  handleStockAddition(row_data, 'post', 'batch', selectItemmasterdata)
                        //     created_inventory_ids.push(created_inventory_id)
                        // }else{
                        row_data['BatchNumber'] = batch_number_id
                        let created_inventory_id =  handleStockAddition(row_data, 'post', 'batch', selectItemmasterdata)
                        created_inventory_ids.push(created_inventory_id)
                        // }
                    }else if(serial_number){
                        // new stock creation based on serial number
                        let serial_numbers_by_user_input = row_data["Serialnum"] 
                        if(typeof(row_data['Serialnum']) === 'string'){
                            serial_numbers_by_user_input = row_data['Serialnum'].trim()
                            serial_numbers_by_user_input = serial_numbers_by_user_input.replace(/,\s*$/, "").split(',')
                        }

                        // add serial numbers to serialNumber table
                        let processed_serial_ids = await handleSerialNumberCreation(serial_numbers_by_user_input)

                        row_data['Serialnum'] = processed_serial_ids
                        let created_inventory_id =  handleStockAddition(row_data, 'post', 'serial', selectItemmasterdata)
                        created_inventory_ids.push(created_inventory_id)
                    }else{
                        let created_inventory_id = handleStockAddition(row_data, 'post', 'nobatchserial', selectItemmasterdata)
                        created_inventory_ids.push(created_inventory_id)
                    }
                }
            }

            // wait all inventory creation promise get success
            let created_inventory_ids_list = []
            let stock_history_data_list = []
            try {
                let created_inventory_ids_results = await Promise.all(created_inventory_ids);
                created_inventory_ids_results.forEach(item => {
                    let [inventory_id, stock_history] = item;
                    created_inventory_ids_list.push(inventory_id)
                    stock_history_data_list.push(stock_history)
                })
            } catch (error) {
                console.error('Error updating stock:', error);
            }
            if(created_inventory_ids_list.length > 0){
                // update inventory handler table 
                let inventory_handler_mutation_query = `mutation InventoryHandlerCreateQuery{
                    inventoryHandlerCreateMutation(inventoryId: [${created_inventory_ids_list}], 
                        store: ${selected_store_id}, actions: "Add", savedBy: ${userId}, conference:${selectedConference?.value ? Number(selectedConference?.value):null}){
                    inventoryHandlerItem {
                        id
                        inventoryHandlerId
                    }
                    success
                    errors
                    }
                }`
                try {
                    const inventory_handler_response = await axiosInstance.post("/itemmaster/graphql", {query: inventory_handler_mutation_query})
                    const inventory_handler_response_data = inventory_handler_response?.data?.data?.inventoryHandlerCreateMutation
                    if(inventory_handler_response_data?.success){
                        // stock_history_creation
                        stock_history_data_list.forEach(item => {
                            let current_history_item = {...item}
                            current_history_item['transactionModule'] = "InventoryHandler"
                            current_history_item['displayName'] = "Stock Addition"
                            current_history_item['savedBy'] = userId
                            current_history_item['displayId'] = inventory_handler_response_data?.inventoryHandlerItem?.inventoryHandlerId
                            current_history_item['transactionId'] = inventory_handler_response_data?.inventoryHandlerItem?.id
                            if (selectedConference?.value){
                                current_history_item['conference'] = Number(selectedConference?.value)
                            } 
                            let stock_history_mutation_query = CreateStockHistory(current_history_item)
                            axiosInstance.post("/itemmaster/graphql", {query: stock_history_mutation_query})
                            .then(res => {
                                console.log(res?.data?.data?.stockHistoryCreateMutation)
                            }).catch(err => {
                                console.log(err);
                            })
                        })
                        close()
                        setLoad(!isLoad)
                    }else{
                        alert_custom_error(inventory_handler_response_data?.errors[0])
                    }
                    
                } catch (error) {
                    console.error('Error updating stock:', error);
                }
            }
            close()
            setLoad(!isLoad)
            setLoadingModels(false)
        }
    }

    const handleShowDeleteModel = () => {
        setDeleteComfomation(true);
    };
    const handleCloseDeleteModel = () => {
        setDeleteComfomation(false);
    };

    const [deleteConfomation, setDeleteComfomation] = useState(false);
    const [deleteData, setDeleteData] = useState({})

    const RowDeleteConfomations = () => {
    
        const deleteTheData=()=>{
            console.log("deleteData.id", deleteData);
            
            const removed_delete_for_total_data = post.filter(data=> data.id !== deleteData.id)
            console.log("removed_delete_for_total_data", removed_delete_for_total_data);
            
            setPost(removed_delete_for_total_data)
            setDeleteData({})
            clearinput()
            handleCloseDeleteModel()
        }
        return (
        <>
            <Modal show={deleteConfomation} onHide={handleCloseDeleteModel}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Confirmations</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row fs-6 ms-2 text-danger'>
                Are you Confirm To Delete {deleteData['part_no']? deleteData['part_no']['label'] : ""}  
                </div>
                </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>{handleCloseDeleteModel();
                setDeleteData({})
                }}>
                No
                </Button>
                <Button variant="primary" onClick={deleteTheData}>
                Yes
                </Button>
            </Modal.Footer>
            </Modal>
        </>
        );
    };



    // scanner input
    const [scanerInput , setScanerInput] = useState('')
    let timeoutId;
    let updateValue = ''
    const handleChangeScannerInput = (e)=>{
        updateValue = updateValue + e.target.value
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            setScanerInput(updateValue);
            ScannerData(null, updateValue)
        }, 300)
    }


    const ScannerData = async (e, scanerInput) => {
        if (scanerInput.trim() !== "") {
          
            let [part_code, part_batch_or_serial] = scanerInput.split('|')
            part_code = part_code.replace(/"/g, '\\"');
            let selectItemmasterdata = await FetchDataItem(ItemMasterFetchSingleQuery(part_code, 'itemPartCode'))
            selectItemmasterdata = selectItemmasterdata ? selectItemmasterdata['itemMaster']?.['items'] : []
            if(selectItemmasterdata.length > 0) {
                selectItemmasterdata = selectItemmasterdata[0]
            }else{
                selectItemmasterdata = null
            }
            let row_id = 1
            if(post.length > 0){
                row_id = post[post.length-1]['id'] + 1
            }
            let savedValue = { 
                id: row_id,
                BatchNumber: '',
                SerialNumberFormat: '',
                SerialNumberStart: '',
                Serialnum: '',
                UOM: {label: selectItemmasterdata?.['itemUom']?.['name'], value: selectItemmasterdata?.['itemUom']?.['id']}, 
                part_name: {label: selectItemmasterdata?.['itemName'], value: selectItemmasterdata?.['id']},
                part_no: {label: selectItemmasterdata?.['itemPartCode'], value: selectItemmasterdata?.['id']}, 
                qty: 1,
            }
            if(part_batch_or_serial?.trim()){
                if(selectItemmasterdata?.batchNumber){
                    savedValue['BatchNumber'] = part_batch_or_serial
                }else if(selectItemmasterdata?.serial){
                    let serial_fetch_query = `query stockSerialNumber{
                        serialNumber(serialNumber: "${part_batch_or_serial}"){
                            items{
                                id
                                serialNumber
                            }
                        }
                    }`
                    let response = await axiosInstance.post("itemmaster/graphql", {query: serial_fetch_query})
                    let serial_numbers = response?.data?.data?.serialNumber?.items;
                    let is_exists = serial_numbers?.find(item => item.serialNumber === part_batch_or_serial.trim())
                    if(is_exists){
                        alert_custom_error('This Serial Number already Exists!')
                        setScanerInput('')
                        return ;
                    }else{
                        savedValue['Serialnum'] = [part_batch_or_serial]
                    }
                }
            }
            if(selectItemmasterdata){
                // setPost(prve=> [...prve , savedValue])
                let is_duplicated = handleDuplicateRowInserted(savedValue, selectItemmasterdata, true)
                if(!is_duplicated){
                    if(savedValue['part_no']['value']){
                        setPost(prve=> [...prve , savedValue]) 
                    } else{
                        // setPost(previous_data => [...previous_data , savedValue])
                        setPost(previousData => {
                            const index = previousData.findIndex(item => item.id === savedValue.id);
                            if (index !== -1) {
                                // If item with same id exists, update it
                                return [
                                    ...previousData.slice(0, index),  // Keep items before the updated one
                                    savedValue,  // Add the updated item
                                    ...previousData.slice(index + 1)  // Keep items after the updated one
                                ];
                            } else {
                                // If item with same id doesn't exist, add the new item
                                return [...previousData, savedValue];
                            }
                        });
                    }
                    setScanerInput('')
                }else{
                    setScanerInput('')
                }
            }
        }
    }

    useEffect(()=>{
        let total_qty = post?.reduce((total, item) => total + parseInt(item.qty, 10), 0);
        setTotalQty(total_qty)
    }, [post])

    useEffect(()=>{
        dispatch(fetchStoreData(storeFetchQuery()));
        dispatch(fetchStockSerialHistoryData(StockSerialHistoryDataFetch()));

        function fetchConferenceData(){
            axiosInstance.post("/itemmaster/graphql", {query: ConferenceFetchQuery()})
            .then(res => {
                let conference_list = res?.data?.data?.conferenceData?.items
                setConferenceDataList(conference_list);
            })
            .catch(err => {
                console.log(err)
            })
        }
        fetchConferenceData()
    }, [dispatch])
    const reversedData = [...post].reverse();
    return(
        <>
        {isStockAddtionsFormShow && stockAddtionsFrom()}
        {<LoadingModelBallTriangle
        isshow={loadingModels}/>}
            <Modal show={show} fullscreen={true}   backdrop="static">
                <Modal.Header  >
                    <Modal.Title className='commanModelTitleColor'><i className='fa-solid fa-arrow-left fa-sm me-2' onClick={close}></i>Stock Addition</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <ToastContainer/>
                        <RowDeleteConfomations />
                        <div className='container-fluid m-0 p-0 bg-white' style={{height:'100%'}}>
                            <div className="row mb-1 d-flex justify-content-start align-items-center">
                                <div className='col-3 staan-input-group'>
                                        <input type="text" className="w-100 staan-form-input" id="exampleFormControlInput1"
                                            value={scanerInput}
                                            // onKeyDown={(e)=>{ScannerData(e, scanerInput)}}
                                            autocomplete="off"
                                            onChange={(e)=>{handleChangeScannerInput(e)}} 
                                            style = {{backgroundColor: "#CCF2FF"}}
                                        />
                                        <label htmlFor="exampleFormControlInput1" className="staan-form-input-label pt-1 px-1 mx-2">Scanner Input</label>
                                    </div> 
                                <div className='col-2 pe-4 staan-input-group'>
                                    <Select 
                                        options={storeDataList?.items?.filter(item => item.matained === true).map((item) => ({ value: item.id, label: item.storeName, conference: item.conference}))} 
                                        value={storeSelect}
                                        onChange={(option)=>{handleSelectStore(option)}} 
                                        styles={customSelectStyle}
                                        className='mt-3'
                                    />
                                    <label htmlFor="exampleFormControlInput1" className="staan-form-input-label pt-1 px-1 mx-2" >Store</label>
                                </div> 
                                {showConference && <div className='col-2 pe-4 staan-input-group'>
                                    <Select 
                                        options={conferenceDataList?.map((item) => ({ value: item.id, label: item.name}))} 
                                        value={selectedConference}
                                        onChange={(option)=>{ handleSelectConference(option)}} 
                                        styles={customSelectStyle}
                                        className='mt-3'
                                    />
                                    <label htmlFor="exampleFormControlInput1" className="staan-form-input-label pt-1 px-1 mx-2" >Conference</label>
                                </div> }
                                <div className='col-3'>
                                    <h3>Total Qty: {totalQty}</h3>
                                </div>
                                <div className="col-2">
                                    <button 
                                    className="btn btn-sm btn-outline-primary px-5 py-2"
                                    onClick={()=>{setIsStockAddtionsFormShow(true);
                                    }}
                                    >
                                        <i className="fa-solid fa-plus me-2"></i>
                                        New
                                    </button>
                                </div>
                            </div>
                            <div className="ag-theme-alpine mt-2" style={{ height: `${screenSize?.height/20}rem`, width: '100%' }}>
                                <AgGridReact
                                rowData={reversedData}
                                columnDefs={COLUMNS}
                                defaultColDef={DEFAULT_COL_DEF}
                                components={COMPONENTS}
                                />
                            </div>
                            <div className='d-flex justify-content-center align-items-center mx-5 mt-1'>
                                <Can allow_list={["Save_Stock_Addition","Edit_Stock_Addition"]}>
                                <button className='btn btn-outline-success px-5 py-2' 
                                    onClick={()=>{handleTableCurrentlyActive(); handleStockAdditionSubmit()}}>Save</button>
                                </Can>
                               
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer/>
        </>
    )
}

export default StockAddition;