import React, { useContext, useState } from 'react';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import DataContext from '../../context/ItemMasterContext';
import TableView from '../../Packages/Custom/DataTable';
import AddItemGroup from '../ItemMaster/model/AddItemGroup';

const ItemGroup = () => {
    const  {handlitemGroupShow} = useContext(DataContext)
    
    const [itemGroupEditId , setitemGroupEditId] = useState()
 
    const [isLoad, setLoad] = useState(false)
  
    const CustomViewModalComponent = (data)=>{
      handlitemGroupShow(); 
      setitemGroupEditId(data);
    }
    
    const  headers = [
      {
        header: 'Groups Name',
        field: 'name', 
        flex: 10, 
        query: 'ItemGroupOptionQueryWithFiliterForTable',
        inputType: 'text',
        renderComponent: CustomViewModalComponent,
      },
      {
        header: 'Parent Group',
        field: 'parentGroup',
        label : "name",
        flex: 10,
        // query: 'ItemGroupOptionQueryWithFiliterForTable',
        inputType: 'text',
      },
      {
        header: 'HSN',
        field: 'hsn',
        label : "hsnCode",
        flex: 10,  
        // query: 'HsnOptionQueryWithFiliterForTable', 
        inputType: 'text',
      },
    ]

    const CustomAddModalComponent = () => {
      handlitemGroupShow()
    }

  return (
     <>
     <div className=' index_page_aliment_warp'>
        <div className='index_page_aliment_sidebar'>
            <SideNavbar />
        </div>
        <div className='index_page_aliment_table'>
            <TableView
                headers={headers}
                query = {'itemGroupTableQuery'}
                addComponent = {CustomAddModalComponent}
                loadComponent = {isLoad}
                titleName = {'Item Group'}
                heightAdjust = {'40rem'}
                permission={"Save_Item_Group"}
                customFilter={true}
                />

        </div>
     </div> 
       
     <AddItemGroup itemGroupEditId ={itemGroupEditId}
      setitemGroupEditId ={setitemGroupEditId}
      setLoad = {()=>{setLoad(!isLoad)}}
      />
     </>
  )
}

export default ItemGroup