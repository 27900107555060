import { Field, Form, Formik } from 'formik/dist';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { TbHistory } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import { createCurrencyMaster } from '../../../GraphQLQueries/ItemMastermutation';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { CurrencyMasterSchema } from '../../../ItemMaster/validations/itemmaster';
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import axiosInstance from '../../../api/axoiss';
import Can from '../../../components/authorizationComponents/Can';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import DataContext from '../../../context/ItemMasterContext';
import { ShowErrorNoties, removeEmptyValueInObject } from '../../../utils/Utils';
import HistoryData from '../../ItemMaster/model/HistoryData';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import showErrorToast from '../../../components/notifications/react-toastify/toast';

const CurrencyMasterFrom = ({ editCurrencyMaster, setEditCurrencyMaster, setLoad }) => {
  const { currencyMaster, handleCurrencyMasterClose, userId, handltoastDeleteConfomationShow,
    historyIds, setHistoryIds } = useContext(DataContext)
  const [edit, setEdit] = useState(false)
  const [deleteData, setdeleteData] = useState({ Name: "", id: "" })
  const [formateOptions, setFormateOptions] = useState([])
  let url = "/itemmaster/CurrencyMaster"
  const dispatch = useDispatch();
  const OptionsData = useSelector(state => state.itemGroup.data);
  const [formKey, setFormKey] = useState(0);
  const [initialValues, setInitialValues] = useState({
    id: "",
    Name: "",
    Currency_symbol: "",
    Formate: "",
    Active: true,
    modified_by: "",
    created_by: "",
    HistoryDetails: ""
  });
  const [selectFormat, setSelectFormat] = useState([])

  useEffect(() => {
    if (OptionsData.name === "currencyFormate") {
      setFormateOptions(OptionsData.value.items)
    }
    if (OptionsData.name === "CurrencyMasterEdit") {
      let responceData = OptionsData?.value?.items?.[0]
      console.log("responceData", responceData);

      setInitialValues({
        id: responceData?.['id'],
        Name: responceData?.['name'],
        Currency_symbol: responceData?.['currencySymbol'],
        Formate: responceData?.['formate']?.['id'],
        Active: responceData?.['active'],
        modified_by: "",
        created_by: responceData?.['createdBy']?.['id'],
        HistoryDetails: responceData?.['historyDetails']
      })
      setSelectFormat({ value: responceData['formate']['id'], label: responceData['formate']['formate'] })
      setFormKey(prev => prev + 1)
    }
  }, [OptionsData])

  useEffect(() => {
    if (editCurrencyMaster) {
      dispatch(fetchOptionsData(QueryData.CurrencyMasterEdit(editCurrencyMaster.id), "CurrencyMasterEdit"))
      setEdit(true)
    } else {
      dispatch(fetchOptionsData(QueryData.formateOption(), "currencyFormate"))
      setEdit(false)
    }

  }, [editCurrencyMaster, dispatch])

  const handlesubmit = async (values, { setSubmitting, resetForm }) => {
    const saveValue = {
      id: values?.id ? values?.id : "",
      name: values['Name'],
      currencySymbol: values['Currency_symbol'],
      formate: Number(values['Formate']),
      active: values['Active'],
      modifiedBy: editCurrencyMaster ? Number(userId) : "",
      createdBy: editCurrencyMaster ? Number(initialValues.created_by) : Number(userId),
    }
    let variables = removeEmptyValueInObject(saveValue)
    try {
      const respones = await axiosInstance.post('/itemmaster/graphql', { query: createCurrencyMaster(variables) });
      const responseData = respones?.data?.data?.currencyMasterCreateMutation
      if (responseData?.success) {
        resetForm()
        handleCurrencyMasterClose()
        showErrorToast(true, "success", "", "Updated")
        reset()
        setLoad()
        setSubmitting(false)
      } else {
        let error = responseData.errors
        let errorData = ShowErrorNoties(error)
        showErrorToast(errorData, "success", "", "Updated")
      }

    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(errorData, "success", "", "Updated")

    }
  }
  const handleFormatChange = (option) => {
    if (option) {
      setSelectFormat({ value: option.value, label: option.label })
    } else {
      setSelectFormat({ value: "", label: "" })
    }
  };
  function reset() {
    setInitialValues({
      id: "",
      Name: "",
      Currency_symbol: "",
      Formate: "",
      Active: true,
      modified_by: "",
      created_by: "",
      HistoryDetails: ""
    })
    setSelectFormat('')
    setEditCurrencyMaster('')
  }
  const handledeleteConfomation = (data) => {
    handltoastDeleteConfomationShow();
    setdeleteData({
      Name: data.name,
      id: data.id
    });
  };
  const handleDeletedId = (deleted_id) => {
    if (deleted_id) {
      handleCurrencyMasterClose()
      reset()
      setEdit(false)
      setLoad()
    }
  }

  return (
    <>
      <HistoryData
        historyIds={historyIds}
        setHistoryIds={setHistoryIds}
      />
      <Modal show={currencyMaster} onHide={() => {
        handleCurrencyMasterClose()
        reset()
      }} size='xl'>
        <div className="row">
          <div className="col-5 m-3" style={{ color: "#5CADA9" }}> <Modal.Title>
            <i className='fa-solid fa-arrow-left fa-sm me-2'
              onClick={() => {
                handleCurrencyMasterClose()
                reset()
              }}
            ></i>
            Currency Master </Modal.Title></div>
          <div className="col-6 mt-4 text-end" >
            <Can I={"Edit"} a={"Currency_Master"}>
              <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={!(editCurrencyMaster && edit)} onClick={() => { setEdit(!edit) }}  ></i>
            </Can>
            <Can I={"Delete"} a={"Currency_Master"}>
              <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={!(editCurrencyMaster && edit)} onClick={() => { handledeleteConfomation(editCurrencyMaster) }} ></i>
            </Can>
            <Can I={"Historys"} a={"Currency_Master"}>
              <TbHistory type="button" className='fs-4 ms-3' data-bs-toggle="offcanvas" hidden={!(editCurrencyMaster && edit)} data-bs-target="#History" onClick={() => { setHistoryIds(initialValues?.HistoryDetails) }} />
            </Can>
          </div>
          <hr />
        </div>
        <Modal.Body>
          <Formik initialValues={initialValues} validationSchema={CurrencyMasterSchema}
            onSubmit={handlesubmit} key={formKey}>
            {({ errors, touched, setFieldValue, values, isSubmitting }) => (
              <Form>
                <div>
                  <div className="row">
                    <div className="col-6  staan-input-group">
                      <Field type='text' id='Name' name='Name' disabled={editCurrencyMaster && edit} className='w-100 staan-form-input' />
                      <label htmlFor="Name" className='staan-form-input-label pt-2 px-1 mx-2'>Name </label>
                      {touched.Name && errors.Name && <small>{errors.Name}</small>}
                    </div>
                    <div className="col-6 staan-input-group">
                      <Field type='text' id='Name' name='Currency_symbol' disabled={editCurrencyMaster && edit} className='w-100 staan-form-input' />
                      <label htmlFor="Symbol" className='staan-form-input-label pt-2 px-1 mx-2'>Symbol </label>
                      {touched.Currency_symbol && errors.Currency_symbol && <small>{errors.Currency_symbol}</small>}
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-6 staan-input-group">
                      <CustomSelect
                        setSelectEditValue={selectFormat}
                        isClearable
                        options={formateOptions.map((item) => ({
                          value: item.id,
                          label: item.formate,
                        }))}
                        name='Formate'
                        handleOnChange={(option) => {
                          handleFormatChange(option);
                          setFieldValue('Formate', option?.value);
                        }}
                        isDisabled={editCurrencyMaster && edit}
                      />
                      <label htmlFor="Formate" className='staan-form-input-label pt-1 px-1 mx-2'>Format </label>
                      {touched.Formate && errors.Formate && <small>{errors.Formate}</small>}
                    </div>
                    <div className="col-6 staan-input-group d-flex align-items-center">
                      <div>
                        <Field type="checkbox" name="Active" disabled={editCurrencyMaster && edit} className='me-3' />
                        <label>Active</label>
                        {touched.Active && errors.Active && <small>{errors.Active}</small>}
                      </div>
                    </div>
                  </div>
                </div>
                <Modal.Footer>
                  <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={() => {
                    handleCurrencyMasterClose()
                    reset()
                  }}>Cancel</button>
                  <Can allow_list={["Save_Currency_Master", "Edit_Currency_Master"]}>
                    <button type='submit' disabled={isSubmitting} className="btn btn-sm btn-outline-success">Submit</button>
                  </Can>

                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {deleteData && <DeleteConformation
        handleDeletedId={(id_) => { handleDeletedId(id_) }}
        deleteData={deleteData}
        url={url}
      />}
    </>
  )
}

export default CurrencyMasterFrom