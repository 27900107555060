import * as yup from "yup";

export const ExpenceCategoryValidation = yup.object().shape({
    categoryName: yup.string().required('Category Name is required'),
    accountName: yup.string().required('Account Name is required')
})


export const ExpenceClaimValidation = yup.object().shape({
    employeeDetailID: yup.string().required('Employee ID is required'),
    employeeDetailName: yup.string().required('Employee Name is required'),
    claimDate: yup.string().required('Expense Claim Date is required'),
})

export const ClaimDetailsPopUpValidation = yup.object().shape({
    doe: yup.string().required('DOE is required'),
    category: yup.string().required('Category is required'),
    description: yup.string().required('Description is required'),
    claimAmount: yup.number().required('Claim Amount is required'),
    approvedAmount:yup.number().required('Claim Amount is required'),
})


export const ExpenceReconciliationValidation = yup.object().shape({
    employeeID: yup.string().required('Employee ID is required'),
    employeeName: yup.string().required('Employee Name is required'),
    advanceAmount: yup.number().required('Advance Due is required'),
    claimAmount: yup.number().required('Claim Due is required')
})


// export const  paymentVoucherValidation=yup.object().shape({
//     voucherDate:yup.string().required('Date is required'),
//     // paymentTo:yup.string().required('Payment To is required'),
//     paymentTo:yup.string().when('Supplier & Customer',{
//         is:true,
//         then:(schema)=>schema.required("Credited Period is required")
//     }),
//     supplierNumber:yup.string().required('Supplier Number is required')
//     // paymentMode:yup.string().required('Payment Mode is required'),
//     // paymentFor:yup.string().required('Payment For is required'),
//     // empAmount:yup.number().required('Amount For is required'),
//     // cusAmount:yup.number().required('Amount For is required'),
//     // bank:yup.string().required('Bank is required'),
//     // transfer:yup.string().required('Transfer Via is required'),
// })

export const paymentVoucherValidation = yup.object().shape({
    voucherDate: yup.string().required('Date is required'),
    paymentTo: yup.string().required('Payment To is required'),
    paymentMode: yup.string().required('Payment Mode is required'),
    supplierNumber: yup.string().when('paymentTo', {
        is: (val) => val === 'Supplier & Customer', // paymentTo is 2 (Supplier & Customer)
        then: (schema) => schema.required('Supplier Number is required'),
        otherwise: (schema) => schema.notRequired(),
    }),
    cusAmount: yup.number().when('paymentTo', {
        is: (val) => val === 'Supplier & Customer',
        then: (schema) => schema.required('Amount For is required'),
        otherwise: (schema) => schema.notRequired(),
    }),
    supplierName: yup.string().when('paymentTo', {
        is: (val) => val === 'Supplier & Customer',
        then: (schema) => schema.required('Supplier Name is required'),
        otherwise: (schema) => schema.notRequired(),
    }),
    employeeDetailID: yup.string().when('paymentTo', {
        is: (val) => val === 'Employee',
        then: (schema) => schema.required('Employee Number is required'),
        otherwise: (schema) => schema.notRequired(),
    }),
    employeeDetailName: yup.string().when('paymentTo', {
        is: (val) => val === 'Employee',
        then: (schema) => schema.required('Employee Name is required'),
        otherwise: (schema) => schema.notRequired(),
    }),
    paymentFor: yup.string().when('paymentTo', {
        is: (val) => val === 'Employee',
        then: (schema) => schema.required('Pay For is required'),
        otherwise: (schema) => schema.notRequired(),
    }),
    empAmount: yup.string().when('paymentTo', {
        is: (val) => val === 'Employee',
        then: (schema) => schema.required('Amount is required'),
        otherwise: (schema) => schema.notRequired(),
    }),
    bank: yup.string().when(['paymentMode', 'paymentTo'], {
        is: (paymentMode, paymentTo) => paymentMode === "Bank" && paymentTo === "Supplier & Customer",
        then: (schema) => schema.required('Bank is required'),
        otherwise: (schema) => schema.notRequired(),
    }),

    transfer: yup.string().when(['paymentMode', 'paymentTo'], {
        is: (paymentMode, paymentTo) => paymentMode === 'Bank' && paymentTo === 'Supplier & Customer',
        then: (schema) => schema.required('Transfer is required'),
        otherwise: (schema) => schema.notRequired(),
    }),

    refNo: yup.string().when(['paymentMode', 'paymentTo'], {
        is: (paymentMode, paymentTo) => paymentMode === 'Bank' && paymentTo === 'Supplier & Customer',
        then: (schema) => schema.required('Chq/Ref No is required'),
        otherwise: (schema) => schema.notRequired(),
    }),

    chqDate: yup.string().when(['paymentMode', 'paymentTo'], {
        is: (paymentMode, paymentTo) => paymentMode === 'Bank' && paymentTo === 'Supplier & Customer',
        then: (schema) => schema.required('Chq Date is required'),
        otherwise: (schema) => schema.notRequired(),
    }),
});


export const advanceValidation = yup.object().shape({
    advRemark: yup.string().required('Advance Remark is required'),
    advAmount: yup.number().required('Amount is required'),
})
export const reconvalidation = yup.object().shape({
    advAmount: yup.string().required('Advance Amount is required'),
    adjustedAmount: yup.number().required('Adjusted Amount is required'),
    advRcvdDate: yup.string().required('Advance  Received  Date is required'),
    expenseRequestno: yup.string().required('Expense Request No is required'),
})

