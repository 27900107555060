import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineCloudUpload, AiOutlinePercentage, AiTwotoneCloseCircle } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { TbHistory } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { ItemMasterHsnEffectiveDateCreateMutation, deleteDocument, deleteImage, getItemMasterWithId } from '../../../GraphQLQueries/ItemMastermutation';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import AlternateUnit from '../../../Page/ItemMaster/model/AlternateUnit';
import HistoryData from '../../../Page/ItemMaster/model/HistoryData';
import ItemCombo from '../../../Page/ItemMaster/model/ItemCombo';
import ItemaMasterModal from '../../../Page/ItemMaster/model/ItemaMasterModal';
import Serviceable from '../../../Page/ItemMaster/model/Serviceable';
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import axiosInstance from '../../../api/axoiss';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import Can from "../../../components/authorizationComponents/Can";
import DataContext from '../../../context/ItemMasterContext';
import { handleFileUploadToS3 } from '../../../utils/FileUploadToS3';
import { DateFormate, OnchangeGetData, ShowErrorNoties, calculateFinalPrice, checkChangesInputs, getCurrentDate, removeEmptyValueInObject } from '../../../utils/Utils';
import { ItemMasterSchema } from '../../validations/itemmaster';
import "./itemmasterEdit.css";


const ItemMasterEdit = ({ id }) => {
    const { ItemUOMdata, handlAlternateUomShow, ItemHsndata, itemaMasterEditModal, handleItemaMasterEditClose,
        Accountdata, userId, setTax, tax, setType, stock, historyIds, setHistoryIds,
        type, supplierdetails, handlItemaMastereShow, handleItemComboShow,
        editItemmasterData, setDupRefData, screenSize } = useContext(DataContext)

    const [ItemMasterEditDisabled, setItemMasterEditDisabled] = useState(true)
    const dispatch = useDispatch();
    const OptionsDataList = useSelector(state => state.itemGroup.data);
    const [formKey, setFormKey] = useState(0);
    
    const [initialMasterData, setInitialMasterData] = useState([]);
    const [isPercentage, setIsPercentage] = useState(true)
    const [variation, setvariation] = useState("0%")
    const [locationData, setlocationData] = useState(null)
    const [notes, setNotes] = useState(null)
    const [isManufacture, setIsManufacture] = useState(false);
    const [isItemComboPrint, setItemComboPrint] = useState(false);
    const [isAlternateUomFixed, setAlternateUomFixed] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageId, setImageId] = useState(null)
    const [purchaseUomOptions, setPurchaseUomOptions] = useState([])
    const [docId, setDocId] = useState([])
    const DocInputRef = useRef(null);
    const ImageInputRef = useRef(null);


    const handleDocumentClick = () => {
        if (!ItemMasterEditDisabled) {
            DocInputRef.current.click();
        }
    }
    const handleImageClick = () => {
        if (!ItemMasterEditDisabled) {
            ImageInputRef.current.click();
        }
    };

    const WarrantyBasedOptions = [
        { value: 'Invoice date', label: 'Invoice date' },
        { value: 'Installation date', label: 'Installation date' },
    ]
    // for select elemtnt
    const [selectedItemGroup, setSelectedItemGroup] = useState();
    const handleSelectitemgroup = (option) => {
        setSelectedItemGroup({ value: option?.value, label: option?.label });
    };

    const [isProduct, setProduct] = useState(true)
    const handleItemTypeChange = (item_type) => {
        if (item_type && item_type === 'Product') {
            setProduct(true)
        } else {
            setProduct(false)
        }
    }
    const [selectedtype, setSelectedtype] = useState({ value: "", label: "" });
    const handleSelectType = (option) => {
        setSelectedtype({ value: option?.value, label: option?.label });
    };
    const [selectedUOM, setSelectedUOM] = useState();
    const handleSelectUOM = (option) => {
        setSelectedUOM({ value: option?.value, label: option?.label });
    };
    const [selectedCategory, setSelectedCategory] = useState();
    const handleSelectCategory = (option) => {
        setSelectedCategory({ value: option?.value, label: option?.label });
    };
    const [selectedItem_Indicator, setSelectedItem_Indicator] = useState();
    const handleSelectItem_Indicator = (option) => {
        setSelectedItem_Indicator({ value: option?.value, label: option?.label });
    };
    const [selectedHSN, setSelectedHSN] = useState();
    const handleSelectHSN = (option) => {
        setSelectedHSN({ value: option?.value, label: option?.label });
    };
    const [selectedPurchaseuom, setSelectedPurchaseuom] = useState();
    const handleSelectPurchaseuom = (option) => {
        if (option) {
            setSelectedPurchaseuom({ value: option?.value, label: option?.label });
        } else {
            setSelectedPurchaseuom({ value: "", label: "" });
        }
    };
    const [selectedAccount, setSelectedAccount] = useState();
    const handleSelectAccount = (option) => {
        if (option) {
            setSelectedAccount({ value: option?.value, label: option?.label });
        } else {
            setSelectedAccount({ value: "", label: "" });
        }
    };
    const [selectedAccountSales, setSelectedAccountSales] = useState();
    const handleSelectAccountSales = (option) => {
        if (option) {
            setSelectedAccountSales({ value: option?.value, label: option?.label });
        } else {
            setSelectedAccountSales({ value: "", label: "" });
        }
    };
    const [selectedWarranty_based, setSelectedWarranty_based] = useState();
    const handleSelectWarranty_based = (option) => {
        setSelectedWarranty_based({ value: option?.value, label: option?.label });
    };
    const [selectSupplier, setSeletSupplier] = useState()
    const handleSelectSupplier = (options) => {
        if (options) {
            const selectedOptions = options.map(option => ({ value: option.value, label: option.label }));
            setSeletSupplier(selectedOptions);
        } else {
            setSeletSupplier([]);
        }
    }

    // total stock
    const [totalStockData, setTotalStockData] = useState(0);

    // for select elemtnt
    const [initialmaster, setInitialMaster] = useState(
        {
            Item_PartCode: "",
            Item_name: "",
            Description: "",
            Item_Group: null,
            Item_type: null,
            Item_UOM: null,
            Alternate_uom: [],
            notes: "",
            category: null,
            location: "",
            Item_Indicator: null,
            Item_Cost: "0",
            Purchase_uom: "",
            note: "",
            variation: "",
            isManufacture: "",
            Item_Safe_Stock: 0,
            Item_Order_Qty: 0,
            Item_Leadtime: 0,
            Item_Purchase_Account: "",
            sell_on_mrp: "",
            Item_Mrp: 0,
            Item_min_price: 0,
            Item_Sales_Account: "",
            Item_HSN: null,
            Keep_stock: false,
            serial: false,
            serial_auto_gentrate: false,
            Serial_format: "",
            Serial_starting: 0,
            Batch_number: false,
            Service: false,
            Warranty_Require: false,
            Item_Warranty_based: '',
            Item_Installation: false,
            Item_Combo: false,
            Item_Combo_data: [],
            Item_Barcode: false,
            Item_Active: true,
            item_qc: false,
            HistoryDetails: "",
            modified_by: "",
            createdby: ""
        }
    )
    const forter = ""
    if (forter === "forter") {
        setInitialMaster(
            {
                Item_PartCode: "",
                Item_name: "",
                Description: "",
                Item_Group: null,
                Item_type: null,
                Item_UOM: null,
                Alternate_uom: [],
                category: null,
                Item_Indicator: null,
                Item_Cost: "0",
                Purchase_uom: "",
                Item_Safe_Stock: 0,
                Item_Order_Qty: 0,
                Item_Leadtime: 0,
                Item_Purchase_Account: "",
                Item_Mrp: 0,
                Item_min_price: 0,
                Item_Sales_Account: "",
                Item_HSN: null,
                Keep_stock: false,
                serial: false,
                serial_auto_gentrate: false,
                Serial_format: "",
                Serial_starting: 0,
                Batch_number: false,
                Service: false,
                Warranty_Require: false,
                Item_Warranty_based: '',
                Item_Installation: false,
                Item_Combo: false,
                Item_Combo_data: [],
                Item_Barcode: false,
                Item_Active: true,
                supplierData: "",
                item_qc: false,
                location: ""
            }
        )
    }
    const [aUOM, setAUOM] = useState([])

    const handleAlternateUomChanged = (data) => {

        data.push(aUOM.find(item => item.id === ""))
        setAUOM(data)
    }

    const [itemType, setItemType] = useState([])
    const [itemIndicator, setitemIndicator] = useState([])

    function getBaseUnitconvertion(Alternate) {
        if (Alternate.length > 0) {
            for (let alteruom of Alternate) {
                try {
                    axiosInstance.get(`/itemmaster/graphql`, {
                        params: {
                            query: QueryData.alternateQuery(alteruom.id),
                        },
                    }).then(response => {
                        let data = response.data.data.alternateUnit[0]
                        let responseDataValue = {
                            id: Number(data?.id),
                            Addtional_Unit: ({ value: data?.['addtionalUnit']?.id, label: data['addtionalUnit']?.name }),
                            show_data: `1 ${data?.['addtionalUnit']?.name} =`,
                            Conversion_Factor: Number(data?.conversionFactor),
                        }
                        setPurchaseUomOptions(prev => [...prev, { value: data?.['addtionalUnit']?.id, label: data['addtionalUnit']?.name }])
                        setAUOM(prevState => {
                            const index = prevState.findIndex(item => item?.id === responseDataValue?.id);
                            if (index !== -1) {
                                return prevState.map((item, i) =>
                                    i === index ? responseDataValue : item
                                );
                            } else {
                                return [...prevState, responseDataValue];
                            }
                        });
                    })


                } catch (error) {
                    // Handle any errors here
                    console.error('Error fetching data: ', error);
                }
            }
        }

    }

    useEffect(() => {
        async function getitemmasterdata(id) {
            let response = await OnchangeGetData(getItemMasterWithId(id))
            let responseData = response.data.itemMaster.items[0]
            let product_documents = responseData?.productDocument || []
            product_documents = product_documents.map(item => {
                const splitPath = item.documentFile.split('/');
                const name = splitPath[splitPath.length - 1];
                return { ...item, name: name };
            });
            let product_doc_ids = product_documents.map(item => item.id)
            setSelectedDoc(product_documents)
            setDocId(product_doc_ids)
            if (responseData?.productImage) {
                let image_name_splitted = responseData?.productImage?.image.split('/')
                let image_name = image_name_splitted.length > 0 ? image_name_splitted[image_name_splitted.length - 1] : ''
                let constructed_image_obj = {
                    id: responseData?.productImage?.id,
                    name: image_name,
                    type: 'image/',
                    url: responseData?.productImage?.image
                }
                setSelectedImage(constructed_image_obj)
            }
            let Item_Combo_data = responseData.itemComboData
            responseData.itemComboData = []
            dispatch(fetchOptionsData(QueryData.ItemTypeOptionQuery(), "ItemMasterType"))
            dispatch(fetchOptionsData(QueryData.ItemIndicatiorOptionQuery(), "ItemMasterIndicator"))
            if (responseData?.itemTypes) {
                if (responseData?.itemTypes?.name === 'Product') {
                    setProduct(true)
                } else {
                    setProduct(false)
                }
            } else {
                setProduct(false)
            }
            if (Item_Combo_data) {
                Item_Combo_data.map((item) => {
                    responseData.itemComboData.push(item.id)
                })

                setInitialMasterData(responseData)
            }
            setItemComboPrint(responseData.itemComboPrint)
            setAlternateUomFixed(responseData.alternateUomFixed)
            axiosInstance.post("/itemmaster/graphql", { query: QueryData.TotalStockByPartCode(id) })
                .then(res => {
                    let total_stock_data = res?.data?.data?.itemStockByPartCode?.items
                    total_stock_data = total_stock_data?.[0]?.totalCurrentStock
                    if (total_stock_data) {
                        setTotalStockData(total_stock_data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            let mainUnit = {
                id: "",
                Addtional_Unit: { value: responseData['itemUom'].id, label: responseData['itemUom'].name },
                show_data: `1 ${responseData?.['itemUom']['name']} =`,
                Conversion_Factor: Number(1),
            }
            setAUOM([mainUnit])
            let Alternate = responseData.alternateUom ? responseData.alternateUom : []
            getBaseUnitconvertion(Alternate)
        }
        if (editItemmasterData?.id) {
            getitemmasterdata(editItemmasterData.id)
        }

    }, [id, editItemmasterData])

    //retun null; Set edit value
    useEffect(() => {
        // Only log and perform actions if initialMasterData has changed

        if (initialMasterData && initialMasterData['itemPartCode']) {
            setPurchaseUomOptions([])
            initialmaster.notes = initialMasterData['notes'] !== null ? initialMasterData['notes'] : ""
            if (initialmaster.notes) {
                setNotes(initialmaster.notes)
            }
            initialmaster.variation = initialMasterData["variation"] !== null ? initialMasterData['variation'] : ""
            if (initialmaster.variation) {
                setvariation(initialmaster.variation)
                const percentageRegex = /^\d+(\.\d+)?%$/;
                if (percentageRegex.test(initialmaster.variation)) {
                    setIsPercentage(true)
                } else {
                    setIsPercentage(false)
                }
            }
            initialmaster.isManufacture = initialMasterData['isManufacture']
            setIsManufacture(initialmaster.isManufacture)
            initialmaster.Item_PartCode = initialMasterData['itemPartCode'] !== null ? initialMasterData['itemPartCode'] : ""
            initialmaster.Item_name = initialMasterData['itemName'] !== null ? initialMasterData['itemName'] : ""
            initialmaster.Description = initialMasterData['description'] !== null ? initialMasterData['description'] : ""
            initialmaster.Item_Group = initialMasterData['itemGroup'] !== null ? initialMasterData['itemGroup']['id'] : ""
            if (initialmaster.Item_Group) {
                setSelectedItemGroup({ value: initialMasterData['itemGroup']['id'], label: initialMasterData['itemGroup']['name'] })
            }
            initialmaster.Item_type = initialMasterData['itemTypes']['id']
            setSelectedtype({ value: initialMasterData['itemTypes']['id'], label: initialMasterData['itemTypes']['name'] });
            initialmaster.Item_UOM = initialMasterData['itemUom']['id']
            if (initialmaster.Item_UOM) {
                setSelectedUOM({ value: initialMasterData?.['itemUom']?.['id'], label: initialMasterData?.['itemUom']?.['name'] })
                setPurchaseUomOptions((prev) => {
                    if (prev?.length > 0) {
                        return prev.map((item) => {
                            if (item.value !== initialMasterData.Item_UOM) {
                                return {
                                    value: initialMasterData?.itemUom?.id,
                                    label: initialMasterData?.itemUom?.name
                                };
                            }
                            return item; // Ensure you return the item if the condition is not met
                        });
                    } else {
                        return [{
                            value: initialMasterData?.itemUom?.id,
                            label: initialMasterData?.itemUom?.name
                        }];
                    }
                });
            }
            let Alternate_uom = initialMasterData['alternateUom'] !== null ? initialMasterData['alternateUom'] : ""
            initialmaster.Alternate_uom = []
            if (Alternate_uom) {
                Alternate_uom.map((item) => {
                    initialmaster.Alternate_uom.push(item.id)
                })
            }
            let location = initialMasterData['location'] !== null ? initialMasterData['location'] : ""
            if (location) {
                initialmaster.location = location
                setlocationData(location)
            }
            initialmaster.HistoryDetails = initialMasterData["historyDetails"]
            initialmaster.category = initialMasterData['category']?.['id']
            if (initialmaster.category) {
                setSelectedCategory({ value: initialMasterData['category']['id'], label: initialMasterData['category']['name'] });
            }
            initialmaster.Item_Indicator = initialMasterData?.['itemIndicators']?.['id']
            let Item_Indicator = initialMasterData['itemIndicators']
            if (Item_Indicator) {

                if (Item_Indicator['name'] === 'Buy') {
                    setType("Buy")
                }
                if (Item_Indicator['name'] === 'Sell') {
                    setType("Sell")
                }
                if (Item_Indicator['name'] === 'Both') {
                    setType("Both")
                }
                if (Item_Indicator['name'] === 'None') {
                    setType("None")
                }
                if (Item_Indicator) {
                    setSelectedItem_Indicator({ value: Item_Indicator['id'], label: Item_Indicator['name'] })
                }
            } else {
                setType("")
            }


            initialmaster.Item_HSN = initialMasterData['itemHsn']?.['id']
            if (initialmaster.Item_HSN) {
                setSelectedHSN({ value: initialMasterData['itemHsn']['id'], label: initialMasterData['itemHsn']['hsnCode'] })
                setTax(initialMasterData['itemHsn']?.['gstRates']?.['rate'])
            }
            initialmaster.Item_Cost = initialMasterData['itemCost'] !== null ? initialMasterData['itemCost'] : "";
            initialmaster.Purchase_uom = initialMasterData['purchaseUom'] !== null ? initialMasterData['purchaseUom']['id'] : "";
            let Purchase_uom = initialMasterData['purchaseUom'] !== null ? initialMasterData['purchaseUom'] : "";
            if (Purchase_uom) {
                setSelectedPurchaseuom({ value: Purchase_uom['id'], label: Purchase_uom['name'] })
            }
            initialmaster.Item_Purchase_Account = initialMasterData['itemPurchaseAccount'] !== null ? initialMasterData['itemPurchaseAccount']['id'] : ""
            let Item_Purchase_Account = initialMasterData['itemPurchaseAccount'] !== null ? initialMasterData['itemPurchaseAccount'] : ""
            if (Item_Purchase_Account) {
                setSelectedAccount({ value: Item_Purchase_Account['id'], label: Item_Purchase_Account['accountsName'] })
            }

            initialmaster.Item_Safe_Stock = initialMasterData['itemSafeStock'] !== null ? initialMasterData['itemSafeStock'] : ""
            initialmaster.Item_Order_Qty = initialMasterData['itemOrderQty'] !== null ? initialMasterData['itemOrderQty'] : ""
            initialmaster.Item_Leadtime = initialMasterData['itemLeadTime'] !== null ? initialMasterData['itemLeadTime'] : ""

            initialmaster.Item_Mrp = initialMasterData['itemMrp'] !== null ? initialMasterData['itemMrp'] : ""
            initialmaster.Item_min_price = initialMasterData['itemMinPrice'] !== null ? initialMasterData['itemMinPrice'] : ""
            initialmaster.Item_Sales_Account = initialMasterData['itemSalesAccount'] ? initialMasterData['itemSalesAccount']['id'] : ""
            let Item_Sales_Account = initialMasterData['itemSalesAccount'] ? initialMasterData['itemSalesAccount'] : ""
            if (Item_Sales_Account) {
                setSelectedAccountSales({ value: Item_Sales_Account['id'], label: Item_Sales_Account['accountsName'] })
            }
            if (initialMasterData['itemWarrantyBased']) {
                initialmaster.Warranty_Require = true
            }
            initialmaster.Item_Installation = initialMasterData['itemInstallation'] !== null ? initialMasterData['itemInstallation'] : ""
            if (initialMasterData['itemWarrantyBased'] === "INSTALLATION_DATE") {
                setSelectedWarranty_based({ value: "Installation date", label: "Installation date" })
            } else if (initialMasterData['itemWarrantyBased'] === "INVOICE_DATE") {
                setSelectedWarranty_based({ value: "Invoice date", label: "Invoice date" })
            }
            initialmaster.Keep_stock = initialMasterData['keepStock'] !== null ? initialMasterData['keepStock'] : ""
            initialmaster.Item_Active = initialMasterData['itemActive'] !== null ? initialMasterData['itemActive'] : ""
            initialmaster.serial = initialMasterData['serial']
            initialmaster.Batch_number = initialMasterData['batchNumber']
            initialmaster.serial_auto_gentrate = initialMasterData['serialAutoGentrate'] !== null ? initialMasterData['serialAutoGentrate'] : ""
            initialmaster.Serial_format = initialMasterData['serialFormat'] !== null ? initialMasterData['serialFormat'] : ""
            initialmaster.Serial_starting = initialMasterData['serialStarting'] !== null ? initialMasterData['serialStarting'] : ""
            initialmaster.Item_Combo = initialMasterData['itemComboBool'] !== null ? initialMasterData['itemComboBool'] : ""
            initialmaster.Item_Combo_data = initialMasterData['itemComboData'] !== null ? initialMasterData['itemComboData'] : ""

            // initialmaster.Item_Combo_data = []
            // if (Item_Combo_data){
            //     Item_Combo_data.map((item)=>{
            //         console.log(item);
            //         initialmaster.Item_Combo_data.push(item.id)
            //     })
            // }

            initialmaster.Item_Barcode = initialMasterData['itemBarcode'] !== null ? initialMasterData['itemBarcode'] : ""
            initialmaster.item_qc = initialMasterData['itemQc'] !== null ? initialMasterData['itemQc'] : ""
            initialmaster.Service = initialMasterData['service'] !== null ? initialMasterData['service'] : ""
            const stock_details = stock.find(item => Number(item.part_no) === Number(initialMasterData['id']));
            if (stock_details) {
                initialmaster.Total_Stock = Number(stock_details['currentStock'])
            } else {
                initialmaster.Total_Stock = 0
            }
            initialmaster.Rejected_Stock = initialMasterData['rejectedStock'] !== null ? initialMasterData['rejectedStock'] : ""
            initialmaster.supplierData = initialMasterData['supplierData']?.map(item => item.id);
            initialmaster.sell_on_mrp = initialMasterData['sellOnMrp'] !== null ? initialMasterData['sellOnMrp'] : ""
            initialmaster.modified_by = initialMasterData['modified_by'] !== null ? initialMasterData['modified_by'] : ""
            initialmaster.createdby = initialMasterData['createdBy'] !== null ? initialMasterData['createdBy']?.['id'] : ""
            if (initialMasterData?.['supplierData']?.length > 0) {
                const selectedValues = initialMasterData?.['supplierData'] ? initialMasterData['supplierData'].map(option => ({ value: option.id, label: option.companyName })) : [];
                handleSelectSupplier(selectedValues)
            }
            setFormKey(prev => prev + 1)

        }
    }, [initialMasterData, Accountdata, ItemHsndata, ItemUOMdata, setTax, setType,
    ]);

    // retun Bool;  Save Item Master Hsn Effective
    async function saveItemMasterHsnEffective(params) {

        try {
            const response = await axiosInstance.post("/itemmaster/graphql", { query: ItemMasterHsnEffectiveDateCreateMutation(params) })
            if (response.data.data.itemMasterHsnEffectiveDateMutation.success) {

                toast.success('Effective Date Saved Successfully', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                return true

            } else {
                let error = response.data.data.itemMasterHsnEffectiveDateMutation.errors
                let errorData = ShowErrorNoties(error)

                toast.error(errorData, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                return false
            }
        } catch (e) {
            let errorData = ShowErrorNoties(e)
            toast.error(errorData, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return false
        }


    }

    // retun null ; save item Master
    const handleSubmit = async (values, { setSubmitting, setErrors, setFieldError }) => {
        console.log(values);


        if (initialmaster['Item_HSN'] === values['Item_HSN']) {
            setEffectiveDate("")
        } else {
            if (!effectiveDate) {
                toast.error("Effective date is required ", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                return ""
            }
            let parameter;
            let resultItemMasterHsnEffective = false;
            if (DateFormate(effectiveDate) === DateFormate(new Date)) {
                parameter = `effectiveDate: "${effectiveDate}", hsnId:${values['Item_HSN']}
                     id:"", ItemMasterId: ${editItemmasterData?.id}  `
                console.log(parameter);
                resultItemMasterHsnEffective = await (saveItemMasterHsnEffective(parameter))
                if (!resultItemMasterHsnEffective) {
                    values['Item_HSN'] = initialmaster['Item_HSN']
                }
            } else {
                parameter = `effectiveDate: "${(effectiveDate)}", hsnId:${values['Item_HSN']}
                    id:"", ItemMasterId: ${editItemmasterData?.id}`
                console.log(parameter);
                resultItemMasterHsnEffective = await (saveItemMasterHsnEffective(parameter))
                values['Item_HSN'] = initialmaster['Item_HSN']
            }

            if (!resultItemMasterHsnEffective) {
                toast.error("Hsn Effective Not Updated Try again later", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }


        }
        if (selectedItem_Indicator?.label === "Buy") {
            if (values['Purchase_uom'] === null || values['Purchase_uom'] === undefined || values['Purchase_uom'] === "") {
                setFieldError("Purchase_uom", "Purchase uom is requery")
                return
            } else {
                setFieldError("Purchase_uom", "")
            }
            if (values['Item_Purchase_Account'] === null || values['Item_Purchase_Account'] === undefined || values['Item_Purchase_Account'] === "") {
                setFieldError("Item_Purchase_Account", "Purchase Account is requery")
                return
            } else {
                setFieldError("Item_Purchase_Account", "")
            }
            if (values['Item_Cost'] === null || values['Item_Cost'] === undefined || values['Item_Cost'] === "") {
                setFieldError("Item_Cost", "Item cost is requery")
                return
            } else {
                setFieldError("Item_Cost", "")
            }
            if (!(/^₹\d+(\.\d+)?$/.test(values['variation']) || /^\d+(\.\d+)?%$/.test(values['variation']))) {
                setFieldError("variation", "Variation must be a valid percentage or currency amount");
                return;
            }
            if (/^\d+%$/g.test(values['variation'])) {
                const percentageValue = parseFloat(values['variation'].replace('%', ''));

                if (!(percentageValue >= 0 && percentageValue <= 100)) {
                    console.log(!(percentageValue >= 0 && percentageValue <= 100));
                    setFieldError("variation", "Percentage must be between 1 and 100");
                    return;
                }
            }
        } else if (selectedItem_Indicator?.label === "Sell") {
            if (values['Item_Mrp'] == null || values['Item_Mrp'] === undefined || values['Item_Mrp'] === "") {
                setFieldError("Item_Mrp", "Max Selling Price is requery")
                return
            } else {
                setFieldError("Item_Mrp", "")
            }
            if (values['Item_min_price'] == null || values['Item_min_price'] === undefined || values['Item_min_price'] === "") {
                setFieldError("Item_min_price", "min Selling Price is requery")
                return
            } else {
                setFieldError("Item_min_price", "")
            }
            if (values['Item_Sales_Account'] == null || values['Item_Sales_Account'] === undefined || values['Item_Sales_Account'] === "") {
                setFieldError("Item_Sales_Account", "Sales account is requery")
                return
            } else {
                setFieldError("Item_Sales_Account", "")
            }
        } else if (selectedItem_Indicator?.label === "Both") {
            if (values['Item_Mrp'] == null || values['Item_Mrp'] === undefined || values['Item_Mrp'] === "") {
                setFieldError("Item_Mrp", "Max Selling Price is requery")
                return
            } else {
                setFieldError("Item_Mrp", "")
            }
            if (values['Item_min_price'] == null || values['Item_min_price'] === undefined || values['Item_min_price'] === "") {
                setFieldError("Item_min_price", "min Selling Price is requery")
                return
            } else {
                setFieldError("Item_min_price", "")
            }
            if (values['Item_Sales_Account'] == null || values['Item_Sales_Account'] === undefined || values['Item_Sales_Account'] === "") {
                setFieldError("Item_Sales_Account", "Sales account is requery")
                return
            } else {
                setFieldError("Item_Sales_Account", "")
            }
            if (values['Purchase_uom'] === null || values['Purchase_uom'] === undefined || values['Purchase_uom'] === "") {
                setFieldError("Purchase_uom", "Purchase uom is requery")
                return
            } else {
                setFieldError("Purchase_uom", "")
            }
            if (values['Item_Purchase_Account'] === null || values['Item_Purchase_Account'] === undefined || values['Item_Purchase_Account'] === "") {
                setFieldError("Item_Purchase_Account", "Purchase Account is requery")
                return
            } else {
                setFieldError("Item_Purchase_Account", "")
            }
            if (values['Item_Cost'] === null || values['Item_Cost'] === undefined || values['Item_Cost'] === "") {
                setFieldError("Item_Cost", "Item cost is requery")
                return
            } else {
                setFieldError("Item_Cost", "")
            }
        }
        const save_itemmaster = {
            "id": editItemmasterData?.id,
            "itemPartCode": values['Item_PartCode'],
            "itemName": values['Item_name'],
            "description": values['Description'] ? values['Description'] : `${values['Item_PartCode']}-${values['Item_name']}`,
            "itemTypes": Number(values['Item_type']),
            "itemUom": Number(values['Item_UOM']),
            "itemIndicators": Number(values['Item_Indicator']),
            "itemCost": values['Item_Cost'] ? (values['Item_Cost']) : 0,
            'itemComboData': values['Item_Combo_data'] !== undefined ? values['Item_Combo_data'].length > 0 ? (values['Item_Combo_data']) : "" : "",
            "alternateUom": values['Alternate_uom'] !== undefined ? values['Alternate_uom'].length > 0 ? (values['Alternate_uom']) : "" : "",
            "itemSafeStock": values['Item_Safe_Stock'] ? Number(values['Item_Safe_Stock']) : 0,
            "itemOrderQty": values['Item_Order_Qty'] ? Number(values['Item_Order_Qty']) : 0,
            "itemLeadTime": values['Item_Leadtime'] ? Number(values['Item_Leadtime']) : 0,
            "itemMrp": values['Item_Mrp'] ? Number(values['Item_Mrp']) : 0,
            "itemMinPrice": values['Item_min_price'] ? Number(values['Item_min_price']) : 0,
            "keepStock": values['Keep_stock'],
            "serial": values['serial'],
            "notes": values['note'],
            "variation": values['variation'],
            "isManufacture": isManufacture,
            "location": values['location'],
            "serialAutoGentrate": values['serial_auto_gentrate'],
            "serialFormat": values['Serial_format'] ? values['Serial_format'] : "",
            "serialStarting": values['Serial_starting'] ? Number(values['Serial_starting']) : 0,
            "batchNumber": values['Batch_number'],
            "sellOnMrp": values['sell_on_mrp'] !== undefined ? values['sell_on_mrp'] : false,
            "service": values['Service'],
            "totalStock": values['Total_Stock'] ? Number(values['Total_Stock']) : 0,
            "rejectedStock": values['Rejected_Stock'] ? Number(values['Rejected_Stock']) : 0,
            "itemWarrantyBased": values['Item_Warranty_based'] !== undefined ? values['Item_Warranty_based'] : "",
            "itemInstallation": values['Item_Installation'] !== undefined ? values['Item_Installation'] : false,
            "invoiceDate": values['Invoicedate'] !== undefined ? values['Invoicedate'] : "",
            "installationData": values['installation_data'] !== undefined ? values['installation_data'] : "",
            "itemComboBool": values['Item_Combo'] !== undefined ? values['Item_Combo'] : false,
            "itemBarcode": values['Item_Barcode'],
            "itemActive": values['Item_Active'],
            "itemQc": values['item_qc'],
            "itemGroup": Number(values['Item_Group']),
            "category": Number(values['category']),
            "purchaseUom": values['Purchase_uom'] ? Number(values['Purchase_uom']) : "",
            "itemSalesAccount": values['Item_Sales_Account'] ? Number(values['Item_Sales_Account']) : "",
            "itemPurchaseAccount": values['Item_Purchase_Account'] ? Number(values['Item_Purchase_Account']) : "",
            "itemHsn": Number(values['Item_HSN']),
            "supplierData": values['supplierData'].length > 0 ? values['supplierData'] : [],
            "modifiedBy": Number(userId),
            "createdBy": initialmaster.createdby ? Number(initialmaster.createdby) : Number(userId),
            "itemComboPrint": isItemComboPrint,
            "alternateUomFixed": isAlternateUomFixed,
        }
        save_itemmaster["description"] = save_itemmaster['description'].replace(/"/g, '\\"')
        save_itemmaster["itemName"] = save_itemmaster['itemName'].replace(/"/g, '\\"')
        if (imageId) {
            save_itemmaster['productImage'] = Number(imageId)
        } else {
            save_itemmaster['productImage'] = ""
        }
        if (docId && docId.length > 0) {
            save_itemmaster['productDocument'] = docId
        }
        let allow_null = ['productImage', 'itemSalesAccount', 'purchaseUom',
            'itemPurchaseAccount', "itemComboData", "alternateUom", "invoiceDate", "installationData"]
        let variable = removeEmptyValueInObject(save_itemmaster, allow_null)
        let query = {
            "query": `mutation MyQuery { itemMasterCreateMutation(` + variable + `){ itemMaster{id itemPartCode}  errors success } }`
        }

        try {
            const response = await axiosInstance.post("/itemmaster/graphql", query)
            if (response.data.data.itemMasterCreateMutation.success) {
                setSubmitting(false);
                toast.success('Updated Successfully', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setIsChangeHsn(false)
                handleItemaMasterEditClose()

            } else {
                let error = response.data.data.itemMasterCreateMutation.errors
                console.log(error)
                let errorData = ShowErrorNoties(error)
                toast.error(errorData, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    // clear confomations
    const [waringToCloseModel, setWaringToCloseModel] = useState(false)
    const handleCloseWaring = () => {
        setWaringToCloseModel(false)
    }

    const handleShowWaring = () => {
        setWaringToCloseModel(true)
    }

    let currentValues = {};
    const WaringToClose = () => {
        const cleaeTheData = () => {
            setItemMasterEditDisabled(true)
            setWaringToCloseModel()
            setType("")
        }
        return (
            <>
                <Modal
                    show={waringToCloseModel}
                    onHide={handleCloseWaring}
                    backdrop="static"
                    keyboard={false}


                >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to leave without saving?
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-outline-warning btn-sm" onClick={handleCloseWaring}>No</button>
                        <button type="button" className="btn btn-outline-success btn-sm" onClick={cleaeTheData}>Yes</button>
                    </Modal.Footer>
                </Modal>

            </>
        )
    }

    function Close() {
        let isDuplicate = checkChangesInputs([initialmaster, currentValues])
        if (!isDuplicate) {
            setItemMasterEditDisabled(true)
            setType("")
        } else {
            handleShowWaring()
        }

    }

    const handleDuplicateItem = () => {
        let temp_data = { ...initialMasterData }
        temp_data['Item_Purchase_Account'] = temp_data['Item_Purchase_Account'] === null ? '' : temp_data['Item_Purchase_Account']
        temp_data['Purchase_uom'] = temp_data['Purchase_uom'] === null ? '' : temp_data['Purchase_uom']
        temp_data['Item_PartCode'] = ''
        temp_data['Item_name'] = ''
        temp_data['Item_Combo'] = false
        temp_data['itemComboBool'] = false
        setDupRefData(temp_data)
    }

    const [ItemGroupOptions, setitemGroupOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [uomOptions, setUomOptions] = useState([])
    const [hstOptions, setHstOptions] = useState([])
    const [salesAccountOptions, setSalesAccountOptions] = useState([])
    const [purchaseAccountOptions, setPurchaseAccountOptions] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([])
    const [effectiveDate, setEffectiveDate] = useState()
    const [isChangeHsn, setIsChangeHsn] = useState(false)

    const getOption = (e, query, name) => {
        let search_term = e.trim().replace(/"/g, '\\"');
        // if (query=== "ItemGroupOptionQuery" && search_term){

        //     dispatch(fetchOptionsData(QueryData.ItemGroupOptionQuery(search_term), "ItemGroup"))
        // }  
        // if (query=== "ItemCategoryOptionQuery" && search_term){
        //     dispatch(fetchOptionsData(QueryData.ItemCategoryOptionQuery(search_term), "category"))
        // } else{
        //     setCategoryOptions([])
        // }

        if (query === "HsnOptionQueryWithFiliter" && search_term) {
            let hsn_query_argument = `hsnCode: ${search_term}, `
            if (selectedtype?.label === "Service") {
                hsn_query_argument += ` hsnTypes:"SAC"`
            }
            dispatch(fetchOptionsData(QueryData.HsnOptionQueryWithFiliter(hsn_query_argument), "Hsn"))
        } else {
            setHstOptions([])
        }
        if (query === "accountsMasterOptionQueryWithFiliter" && search_term && name === "SalesAccount") {
            dispatch(fetchOptionsData(QueryData.accountsMasterOptionQueryWithFiliter(search_term, "Asset", "Income"), "SalesAccount"))
        } else {
            setSalesAccountOptions([])
        }
        if (query === "accountsMasterOptionQueryWithFiliter" && search_term && name === "PurchaseAccount") {
            dispatch(fetchOptionsData(QueryData.accountsMasterOptionQueryWithFiliter(search_term, "Liabilities", "Expenses"), "PurchaseAccount"))
        } else {
            setPurchaseAccountOptions([])
        }

        if (query === "onlyForSupplierOption" && search_term) {
            dispatch(fetchOptionsData(QueryData.onlyForSupplierOption(search_term), "companyName"))
        } else {
            setSupplierOptions([])
        }
    }

    function PerFetchOptions() {

        dispatch(fetchOptionsData(QueryData.UOMOptionQuery(), "Uom"))
        dispatch(fetchOptionsData(QueryData.ItemGroupOptionQueryWithFiliter(), "GroupOption"))
        dispatch(fetchOptionsData(QueryData.ItemCategoryOptionQuery(), "CategoryOption"))
    }

    useEffect(() => { PerFetchOptions() }, [])

    useEffect(() => {
        if (OptionsDataList.name === "ItemMasterType") {
            setItemType(OptionsDataList.value.items)
        }
        if (OptionsDataList.name === "ItemMasterIndicator") {
            setitemIndicator(OptionsDataList.value.items)
        }
        if (OptionsDataList.name === "GroupOption") {
            setitemGroupOptions(OptionsDataList.value.items)
        }
        if (OptionsDataList.name === "CategoryOption") {
            setCategoryOptions(OptionsDataList.value.items)
        }
        if (OptionsDataList.name === "Uom") {
            setUomOptions(OptionsDataList.value.items)

        }
        if (OptionsDataList.name === "Hsn") {
            setHstOptions(OptionsDataList.value.items)
        } else {
            setHstOptions([])
        }
        if (OptionsDataList.name === "SalesAccount") {
            setSalesAccountOptions(OptionsDataList.value.items)
        } else {
            setSalesAccountOptions([])
        }
        if (OptionsDataList.name === "PurchaseAccount") {
            setPurchaseAccountOptions(OptionsDataList.value.items)
        } else {
            setPurchaseAccountOptions([])
        }

        if (OptionsDataList.name === "companyName") {
            setSupplierOptions(OptionsDataList.value.items)
        } else {
            setSupplierOptions([])
        }

    }, [OptionsDataList])

    const handleChangeItemComboPrint = (value) => {
        setItemComboPrint(value)
    }

    const handleChangeAlternateUomFixed = (value) => {
        setAlternateUomFixed(value)
    }

    const handleDocSelect = async (e) => {
        let selectedFiles = e.target.files
        const maxFileSizeMB = 5;
        const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
        let validFiles = [];
        if (selectedFiles.length > 4) {
            alert("You can only upload up to 4 documents.");
            return;
        }

        for (let doc of selectedFiles) {
            if (doc.size > maxFileSizeBytes) {
                alert(`${doc.name} exceeds the 5MB size limit.`);
                return;
            }
            validFiles.push(doc);
        }
        for (let doc of Array.from(e.target.files)) {
            try {
                handleFileUploadToS3(doc, 'documents')
                    .then(uploaded_file_url => {
                        const mutation_query = ` 
                      mutation MyMutation($document: String!) {
                        documentCreateMutation(document: $document) { 
                          documentType{
                            id 
                          }
                          success
                          errors
                        }
                      }`
                        const variables = {
                            document: uploaded_file_url,
                        };
                        axiosInstance.post('/itemmaster/graphql', {
                            query: mutation_query,
                            variables: variables,
                        }).then(response => {
                            let responceData = response?.data?.data?.documentCreateMutation
                            if (responceData?.success) {
                                let document_id = responceData?.documentType?.id
                                // let extracted_data = updated_file_list.find(item => item.name === doc.name)
                                // updated_file_list = updated_file_list.filter(item => item.name !== doc.name)
                                let extracted_data = doc
                                extracted_data['id'] = document_id
                                setSelectedDoc([...selectedDoc, extracted_data]);
                                // setSelectedDoc(updated_file_list);
                                setDocId(prev => [...prev, document_id])
                            } else {
                                let filiterError = ShowErrorNoties(responceData.errors)
                                toast.error(filiterError, {
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: false,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    theme: "light",
                                    // transition: Bounce,
                                });
                            }
                        }).catch(error => {
                            console.error('Error uploading image:', error);
                            let filiterError = ShowErrorNoties(error)
                            alert(filiterError)
                        });
                    })
                    .catch(err => {

                    })
            } catch (error) {
                console.log(error);
            }

        }
    }

    const handleFileInputChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const maxSizeInMB = 2;
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
            if (file.size > maxSizeInBytes) {
                toast.error('File size must be less than 2MB', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                return; // Stop execution if file size exceeds 2MB
            }
            try {
                handleFileUploadToS3(file, 'Image_data')
                    .then(uploaded_file_url => {
                        const mutation_query = `
                  mutation MyMutation($image: String!) {
                    imageCreateMutation(image: $image) { 
                      imageType{
                        id
                        image
                      }
                        success
                        errors
                      }
                  }`
                        const variables = {
                            image: uploaded_file_url,
                        };
                        axiosInstance.post('/itemmaster/graphql', {
                            query: mutation_query,
                            variables: variables,
                        }).then(response => {
                            let responceData = response?.data?.data?.imageCreateMutation
                            if (responceData?.success) {
                                let image_id = responceData?.imageType?.id
                                setImageId(image_id)
                                setSelectedImage(file)
                            } else {
                                let filiterError = ShowErrorNoties(responceData.errors)
                                toast.error(filiterError, {
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: false,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    theme: "light",
                                    // transition: Bounce,
                                });
                                setSelectedImage('')
                            }
                        }).catch(error => {
                            console.error('Error uploading image:', error);
                            // let filiterError = ShowErrorNoties(error)
                            // alert(filiterError)  
                        });
                    }).catch(err => {
                        alert('Failed to Upload Image. try again')
                    })
            } catch (error) {
                let filiterError = ShowErrorNoties(error)
                alert(filiterError)

            }
        } else {
            setSelectedImage("")
        }
        setSelectedImage('')
    };

    const handleDeleteDocument = (document) => {

        if (document.id) {
            let delete_mutation_query = deleteDocument(document.id)
            axiosInstance.post("/itemmaster/graphql", { query: delete_mutation_query })
                .then(res => {
                    if (res?.data?.data?.documentDeleteMutation?.success) {
                        let document_list = [...selectedDoc]
                        document_list = document_list.filter(item => item?.id !== document?.id)
                        setSelectedDoc(document_list)
                        toast.success('Document deleted successfully!.', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            // transition: Bounce,
                        })
                    } else {
                        toast.error('Failed to delete document!.', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            // transition: Bounce,
                        })
                    }
                })
                .catch(err => {
                    toast.error('Failed to delete document!.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        // transition: Bounce,
                    })
                })
        }
    }

    const handleDeleteAllDocument = () => {
        selectedDoc.map(document => {
            let delete_mutation_query = deleteDocument(document.id)
            axiosInstance.post("/itemmaster/graphql", { query: delete_mutation_query })
                .then(res => {
                    if (res?.data?.data?.documentDeleteMutation?.success) {
                        let document_list = [...selectedDoc]
                        document_list = document_list.filter(item => item?.id !== document?.id)
                        setSelectedDoc(document_list)
                        toast.success('Document deleted successfully!.', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            // transition: Bounce,
                        })
                    } else {
                        toast.error('Failed to delete document!.', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            // transition: Bounce,
                        })
                    }
                })
                .catch(err => {
                    toast.error('Failed to delete document!.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        // transition: Bounce,
                    })
                })
        })
        setSelectedDoc([])
    }

    const handleDeleteImage = () => {
        if (selectedImage?.id) {
            let delete_mutation_query = deleteImage(selectedImage.id)
            axiosInstance.post("/itemmaster/graphql", { query: delete_mutation_query })
                .then(res => {
                    if (res?.data?.data?.imageDeleteMutation?.success) {
                        setSelectedImage(null)
                        toast.success('Image deleted successfully!.', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            // transition: Bounce,
                        })
                    } else {
                        toast.error('Failed to delete Image!.', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            // transition: Bounce,
                        })
                    }
                })
                .catch(err => {
                    toast.error('Failed to delete Image!.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        // transition: Bounce,
                    })
                })
        } else {
            setSelectedImage(null)
        }
    }

    const [serviceable, setServiceable] = useState(false)
    const [serviceAbleBool, setServiceAbleBool] = useState(false)
    const handleserviceableClose = () => {
        setServiceable(false)
    }
    const handleserviceableShow = () => {
        setServiceable(true)
        setServiceAbleBool(true)
    }

    return (
        <Modal show={itemaMasterEditModal} >
            <>
                <ToastContainer />
                <HistoryData
                    historyIds={historyIds}
                    setHistoryIds={setHistoryIds}
                />
                {WaringToClose()}
                <div className='itemMaster_Wrap container-fluid  overflow-auto ps-0'>
                    <div className="container mt-5 itemMasterEditWrap bg-white rounded">
                        <Formik initialValues={initialmaster}
                            validationSchema={ItemMasterSchema}
                            onSubmit={handleSubmit} key={formKey}>
                            {({ errors, touched, isSubmitting, values, setFieldValue, }) => {
                                currentValues = values;
                                return (
                                    <>
                                        <Form>
                                            <div className="row ">

                                                <div className="col-6 fw-bold fs-4 my-2" style={{ color: "#5CADA9" }} >
                                                    <i
                                                        className='fa-solid fa-arrow-left fa-sm me-2'
                                                        onClick={() => { handleItemaMasterEditClose(); setIsChangeHsn(false) }}
                                                    ></i>
                                                    Item Master
                                                </div>
                                                <div className="col-6 text-end my-2">
                                                    <Can I={"Copy"} a={"Item_Master"}>
                                                        <i className="fa-solid fa-copy  fs-5 pt-1 mx-3 text-success"
                                                            hidden={!ItemMasterEditDisabled}
                                                            role="button"
                                                            onClick={() => {
                                                                handleItemaMasterEditClose()
                                                                handlItemaMastereShow(); handleDuplicateItem()
                                                            }}
                                                        ></i>
                                                    </Can>
                                                    <Can I={"Edit"} a={"Item_Master"}>
                                                        <i role="button"
                                                            hidden={!ItemMasterEditDisabled} onClick={() => { setItemMasterEditDisabled(false) }}
                                                            className="fa-solid fa-pen mx-3 text-primary"></i>
                                                    </Can>

                                                    <button type="button"
                                                        className="btn btn-sm px-2 shadow-sm btn-outline-danger me-1"
                                                        hidden={ItemMasterEditDisabled}
                                                        onClick={() => { setItemMasterEditDisabled(true); Close() }} >Cancel</button>
                                                    <Can allow_list={["Save_Item_Master", "Edit_Item_Master"]}>
                                                        <button type="submit"
                                                            className="btn btn-sm shadow-sm btn-outline-success ms-3" disabled={isSubmitting} hidden={ItemMasterEditDisabled}>Save</button>
                                                    </Can>

                                                </div>
                                                <hr className='split_border' />
                                                {/* item master edit new ui code starts */}
                                                <div className='overflow-auto' style={{ height: '78vh' }}>
                                                    <div className='d-flex mb-1 justify-content-between'>
                                                        <div style={{ width: '53%' }}>
                                                            <div className='card px-1'>
                                                                <div className="pt-1 mx-1 border-bottom custom-bottom-shadow">
                                                                    <h6 className='mx-3'  >Basic Details</h6>
                                                                </div>
                                                                <div className='pt-3'>
                                                                    <div className="row mx-2">
                                                                        <div className="col-6 staan-input-group">
                                                                            <Field type='text' id='Part Code' name='Item_PartCode' disabled={ItemMasterEditDisabled} placeholder='' className='w-100 staan-form-input' />
                                                                            <label htmlFor="Part Code" className='staan-form-input-label pt-2 px-1 mx-2'>Part Code  </label>
                                                                            {touched.Item_PartCode && errors.Item_PartCode && <small className="staan-error-msg">{errors.Item_PartCode}</small>}
                                                                        </div>
                                                                        <div className="col-6 staan-input-group">
                                                                            <Field type='text' id="Part Name" name='Item_name' disabled={ItemMasterEditDisabled} placeholder='' className='w-100 staan-form-input' />
                                                                            <label htmlFor="Part Name" className='staan-form-input-label pt-2 px-1 mx-2'>Part Name  </label>
                                                                            {touched.Item_name && errors.Item_name && <small className="staan-error-msg">{errors.Item_name}</small>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mx-2 mt-1">
                                                                        <div className="col-12 staan-input-group">
                                                                            <Field type='text' id='Description' name='Description' disabled={ItemMasterEditDisabled} placeholder='' className='w-100 staan-form-input' />
                                                                            <label htmlFor="Description" className='staan-form-input-label pt-2 px-1 mx-2'>Description</label>
                                                                            {touched.Description && errors.Description && <small className="staan-error-msg">{errors.Description}</small>}
                                                                        </div>
                                                                    </div>
                                                                    <div className='row mx-2 mt-2'>
                                                                        <div className="col-6  staan-input-group">
                                                                            <CustomSelect
                                                                                isDisabled
                                                                                setSelectEditValue={selectedtype}
                                                                                handleInputChange={(e) => {
                                                                                    dispatch(fetchOptionsData(QueryData.accountsMasterForPosOptionQuery(`accountsName :"${e}"`), "accountsMaster"))
                                                                                }}
                                                                                options={itemType?.map((types) => ({ value: types.id, label: types.name }))}
                                                                                name='pro_ser'
                                                                                id='pro_ser'
                                                                                handleOnChange={(option) => {
                                                                                    setFieldValue('Item_type', option ? option?.value : null);
                                                                                    handleItemTypeChange(option?.label)
                                                                                    handleSelectType(option)
                                                                                    setFieldValue('Item_UOM', null);
                                                                                    handleSelectUOM({ value: "", label: "" })
                                                                                }}
                                                                            />
                                                                            <label htmlFor="pro_ser" className='staan-form-input-label pt-1 px-1 mx-2'>Product/Service</label>
                                                                            {touched.Item_type && errors.Item_type && <small className="staan-error-msg">{errors.Item_type}</small>}
                                                                        </div>
                                                                        <div className="col-6 staan-input-group">
                                                                            <CustomSelect
                                                                                setSelectEditValue={selectedItemGroup}
                                                                                options={ItemGroupOptions?.map((item) => ({ value: item.id, label: item.name }))}
                                                                                name='Item_Group'
                                                                                id='Item_Group'
                                                                                handleOnChange={(option) => {
                                                                                    setFieldValue('Item_Group', option ? option?.value : null)
                                                                                    handleSelectitemgroup(option)
                                                                                }}
                                                                                isDisabled={ItemMasterEditDisabled}
                                                                            />
                                                                            <label htmlFor="Item_Group_" className='staan-form-input-label pt-1 px-1 mx-2'>Item Group</label>
                                                                            {touched.Item_Group && errors.Item_Group && <small className="staan-error-msg">{errors.Item_Group}</small>}
                                                                        </div>
                                                                    </div>
                                                                    <div className='row mx-2'>
                                                                        <div className="col-6  staan-input-group">
                                                                            <CustomSelect
                                                                                setSelectEditValue={selectedCategory}
                                                                                handleInputChange={(e) => {
                                                                                    dispatch(fetchOptionsData(QueryData.accountsMasterForPosOptionQuery(`accountsName :"${e}"`), "accountsMaster"))
                                                                                }}
                                                                                options={categoryOptions.map((item) => ({ value: item.id, label: item.name }))}
                                                                                name='category'
                                                                                id='category'
                                                                                handleOnChange={(option) => {
                                                                                    setFieldValue('category', option ? option?.value : null);
                                                                                    handleSelectCategory(option)
                                                                                }}
                                                                                isDisabled={ItemMasterEditDisabled}
                                                                            />
                                                                            <label className='staan-form-input-label pt-1 px-1 mx-2'>Category</label>
                                                                            {touched.category && errors.category && <small className="staan-error-msg">{errors.category}</small>}
                                                                        </div>
                                                                        <div className="col-6 staan-input-group">
                                                                            <CustomSelect
                                                                                setSelectEditValue={selectedUOM}
                                                                                name='Item_UOM'
                                                                                id='Item_UOM'
                                                                                handleOnChange={(option) => {
                                                                                    setFieldValue('Item_UOM', option ? option?.value : null);
                                                                                    handleSelectUOM(option);
                                                                                }}
                                                                                isDisabled
                                                                            />
                                                                            <label htmlFor="Item_UOM" className='staan-form-input-label pt-1 px-1 mx-2'>UOM  </label>
                                                                            <div className="d-flex justify-content-between">
                                                                                <small>{touched.Item_UOM && errors.Item_UOM && <small>{errors.Item_UOM}</small>}</small>
                                                                                <div className="text-end">
                                                                                    <button type="button"
                                                                                        id='Item_Alternate_UOM'
                                                                                        className="btn btn-sm btn-outline-secondary mt-2 text-primary "

                                                                                        onClick={() => { handlAlternateUomShow() }}>Alternate UOM</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row mx-2'>
                                                                        <div className="col-3 staan-input-group">
                                                                            <CustomSelect
                                                                                setSelectEditValue={selectedHSN}
                                                                                handleInputChange={(e) => {
                                                                                    getOption(e, "HsnOptionQueryWithFiliter")
                                                                                }}
                                                                                options={hstOptions?.map((item) => ({ value: item.id, label: item.hsnCode, gst_rate: item.gstRates?.rate }))}
                                                                                name='Item_HSN'
                                                                                id='Item_HSN'
                                                                                handleOnChange={(option) => {
                                                                                    setFieldValue('Item_HSN', option ? option?.value : null)
                                                                                    if (option) {
                                                                                        setIsChangeHsn(true)
                                                                                    }
                                                                                    handleSelectHSN(option)
                                                                                    setTax(option ? option?.gst_rate : null)
                                                                                }}
                                                                                isDisabled={ItemMasterEditDisabled}
                                                                            />
                                                                            <label htmlFor="Item_HSN" className='staan-form-input-label pt-1 px-1 mx-2'>HSN </label>
                                                                            {touched.Item_HSN && errors.Item_HSN && <small className="staan-error-msg">{errors.Item_HSN}</small>}
                                                                        </div>
                                                                        <div className="col-3 staan-input-group" >
                                                                            <input
                                                                                hidden={!isChangeHsn}
                                                                                type='date'
                                                                                className=' staan-form-input form-control w-100 fs-6'
                                                                                style={{ border: "1px solid rgb(206, 201, 201)", fontSize: '.8rem' }}
                                                                                value={effectiveDate}
                                                                                min={getCurrentDate()}
                                                                                onChange={e => {
                                                                                    if (e.target.value) {

                                                                                        setEffectiveDate(e.target.value)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <label className='staan-form-input-label pt-1 px-1 mx-2' hidden={!isChangeHsn}>Effective Date</label>

                                                                        </div>
                                                                        <div className="col-6 staan-input-group">
                                                                            <input type="number" id='Tax' className='staan-form-input' value={tax} disabled />
                                                                            <label htmlFor="Tax" className='staan-form-input-label pt-2 px-1 mx-2'>Tax</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mx-2 mb-3 mt-2">
                                                                        <div className="col-5 staan-input-group">
                                                                            <CustomSelect
                                                                                setSelectEditValue={selectedItem_Indicator}
                                                                                handleInputChange={(e) => {
                                                                                    dispatch(fetchOptionsData(QueryData.accountsMasterForPosOptionQuery(`accountsName :"${e}"`), "accountsMaster"))
                                                                                }}
                                                                                options={itemIndicator.map((Indicator) => ({ value: Indicator.id, label: Indicator.name }))}
                                                                                name='Item_Indicator'
                                                                                id='Item_Indicator'
                                                                                handleOnChange={(option) => {
                                                                                    setFieldValue('Item_Indicator', option ? option?.value : null)
                                                                                    if (option && option?.label === 'Buy') {
                                                                                        setType("Buy")
                                                                                    } if (option && option?.label === 'Sell') {
                                                                                        setType("Sell")
                                                                                    } if (option && option?.label === 'Both') {
                                                                                        setType("Both")
                                                                                    } if (option && option?.label === 'None') {
                                                                                        setType("None")
                                                                                    }
                                                                                    handleSelectItem_Indicator(option)
                                                                                }}
                                                                                isDisabled={ItemMasterEditDisabled}
                                                                            />
                                                                            <label htmlFor="Item_Indicator" className='staan-form-input-label pt-1 px-1 mx-2'>Buy/Sell</label>
                                                                            {touched.Item_Indicator && errors.Item_Indicator && <small className="staan-error-msg">{errors.Item_Indicator}</small>}
                                                                        </div>
                                                                        <div className='col-7 d-flex justify-content-between align-items-center'>
                                                                            <div className="col-6 text-center" style={!isProduct ? { display: "none" } : {}}>
                                                                                <Field type="checkbox" name="Keep_stock" disabled={ItemMasterEditDisabled} className='me-2' />
                                                                                <label style={{ fontSize: '.8rem' }} className='pb-1'>Keep Stock</label>
                                                                                <br />
                                                                                {errors.Keep_stock && <small className="staan-error-msg">{errors.Keep_stock}</small>}
                                                                            </div>
                                                                            <div className="col-6 text-end">
                                                                                <Field type="checkbox" name="Item_Active" disabled={ItemMasterEditDisabled} className='me-2' />
                                                                                <label style={{ fontSize: '.8rem', top: '5px' }} className='pb-1'>Item Active</label>
                                                                                <br />
                                                                                {errors.Item_Active && <small className="staan-error-msg">{errors.Item_Active}</small>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='card px-1 my-3' style={type === "" || type === "None" ? { display: "none" } : { height: '18rem' }}>
                                                                <div className="pt-1 mx-1 border-bottom custom-bottom-shadow">
                                                                    <h6 className='mx-3 pt-2'  >Price & Procurement Details</h6>
                                                                </div>
                                                                <div hidden={type === "Buy"}>
                                                                    <div className='row mx-2'>
                                                                        <div className="col-6 staan-input-group">
                                                                            <Field type='text' name='Item_Mrp' disabled={ItemMasterEditDisabled} className='w-100 staan-form-input' />
                                                                            <label htmlFor="Item_Mrp" className='staan-form-input-label pt-2 px-1 mx-2'>Max Selling Price</label>
                                                                            {touched.Item_Mrp && errors.Item_Mrp && <small>{errors.Item_Mrp}</small>}
                                                                        </div>
                                                                        <div className="col-6 staan-input-group">
                                                                            <Field type='text' name='Item_min_price' disabled={ItemMasterEditDisabled} className='w-100 staan-form-input' />
                                                                            <label htmlFor="Item_min_price" className='staan-form-input-label pt-2 px-1 mx-2'>Min Selling Price</label>
                                                                            {touched.Item_min_price && errors.Item_min_price && <small>{errors.Item_min_price}</small>}
                                                                        </div>
                                                                    </div>
                                                                    <div className='row mx-2 my-2'>
                                                                        <div className="col-6 staan-input-group">
                                                                            <CustomSelect
                                                                                isClearable
                                                                                setSelectEditValue={selectedAccountSales}
                                                                                handleInputChange={(e) => {
                                                                                    getOption(e, "accountsMasterOptionQueryWithFiliter", "SalesAccount")
                                                                                }}
                                                                                options={salesAccountOptions.map((item) => ({ value: item.id, label: item.accountsName }))}
                                                                                name='Item_Sales_Account'
                                                                                id='Item_Sales_Account'
                                                                                handleOnChange={(option) => {
                                                                                    setFieldValue('Item_Sales_Account', option ? option?.value : "");
                                                                                    handleSelectAccountSales(option)
                                                                                }}
                                                                                isDisabled={ItemMasterEditDisabled} />
                                                                            <label htmlFor="Item_Sales_Account" className='staan-form-input-label pt-1 px-1 mx-2'>Item Sales Account</label>
                                                                            {touched.Item_Sales_Account && errors.Item_Sales_Account && <small>{errors.Item_Sales_Account}</small>}
                                                                        </div>
                                                                        <div className="col-3 mt-4 text-start">
                                                                            <span style={{ fontSize: '.8rem' }} name="random_text">{values.sell_on_mrp && 'MRP: ' + calculateFinalPrice(values.Item_Mrp, tax)}</span>
                                                                        </div>
                                                                        <div className='col-3 text-end mt-3'>
                                                                            <label style={{ fontSize: '.8rem', top: '5px' }} className='pb-1'>Sell on MRP</label>
                                                                            <Field type="checkbox" name="sell_on_mrp" disabled={ItemMasterEditDisabled} checked={values.sell_on_mrp} className='ms-3' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div hidden={type === "Sell"}>
                                                                    <div className='row mx-2'>
                                                                        <div className="col-3 staan-input-group">
                                                                            <Field type='text' name='Item_Cost' disabled={ItemMasterEditDisabled} className='w-100 staan-form-input' />
                                                                            <label htmlFor="Item_Cost" className='staan-form-input-label pt-2 px-1 mx-2'>Item Cost </label>
                                                                            {touched.Item_Cost && errors.Item_Cost && <small>{errors.Item_Cost}</small>}
                                                                        </div>
                                                                        <div className="col-2 staan-input-group">
                                                                            <Field type='text' name='variation' value={variation} onChange={(e) => {
                                                                                setvariation(e.target.value)
                                                                                setFieldValue('variation', e.target.value)
                                                                            }} className='w-100 staan-form-input ' />
                                                                            <label htmlFor="variation" className='staan-form-input-label pt-2 px-1 mx-2'>variation </label>
                                                                            {touched.variation && errors.variation && <small>{errors.variation}</small>}
                                                                        </div>
                                                                        <div className="col-1 mt-4 text-center">
                                                                            {isPercentage ?
                                                                                <BsCurrencyRupee onClick={() => {
                                                                                    setIsPercentage(false);
                                                                                    const newValue = `₹${variation?.replace("%", "")}`;
                                                                                    setvariation(newValue !== undefined ? newValue : "");
                                                                                    setFieldValue('variation', newValue);
                                                                                }} />
                                                                                :
                                                                                <AiOutlinePercentage onClick={() => {
                                                                                    setIsPercentage(true);
                                                                                    const newValue = `${variation?.replace("₹", "")}%`;
                                                                                    setvariation(newValue !== undefined ? newValue : "");
                                                                                    setFieldValue('variation', newValue);
                                                                                }} />
                                                                            }
                                                                        </div>
                                                                        <div className="col-6 staan-input-group">
                                                                            <CustomSelect
                                                                                setSelectEditValue={selectedPurchaseuom}
                                                                                handleInputChange={(e) => {
                                                                                    getOption(e, "UOMOptionQuery")
                                                                                }}
                                                                                options={purchaseUomOptions}
                                                                                name='Purchase_uom'
                                                                                id='Purchase_uom'
                                                                                handleOnChange={(option) => {
                                                                                    setFieldValue('Purchase_uom', option ? option?.value : "");
                                                                                    handleSelectPurchaseuom(option)
                                                                                }}
                                                                                isDisabled={ItemMasterEditDisabled}
                                                                            />
                                                                            <label htmlFor="Purchase_uom" className='staan-form-input-label pt-1 px-1 mx-2'>Purchase UOM</label>
                                                                            {touched.Purchase_uom && errors.Purchase_uom && <small>{errors.Purchase_uom}</small>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mx-2">
                                                                        <div className="col-6 staan-input-group">
                                                                            <CustomSelect
                                                                                setSelectEditValue={selectedAccount}
                                                                                handleInputChange={(e) => {
                                                                                    getOption(e, "accountsMasterOptionQueryWithFiliter", "PurchaseAccount")
                                                                                }}
                                                                                options={purchaseAccountOptions.map((item) => ({ value: item.id, label: item.accountsName }))}
                                                                                name='accountName'
                                                                                id='accountName'
                                                                                handleOnChange={(option) => {
                                                                                    setFieldValue('Item_Purchase_Account', option ? option?.value : "");
                                                                                    handleSelectAccount(option)
                                                                                }}
                                                                                isDisabled={ItemMasterEditDisabled}
                                                                            />
                                                                            <label htmlFor="Item_Purchase_Account" className='staan-form-input-label pt-1 px-1 mx-2'>Item Purchase Account  </label>
                                                                            {touched.Item_Purchase_Account && errors.Item_Purchase_Account && <small>{errors.Item_Purchase_Account}</small>}
                                                                        </div>
                                                                        <div className="col-6 staan-input-group">
                                                                            <CustomSelect
                                                                                isClearable
                                                                                isMulti
                                                                                setSelectEditValue={selectSupplier}
                                                                                handleInputChange={(e) => {
                                                                                    getOption(e, "onlyForSupplierOption")
                                                                                }}
                                                                                options={supplierOptions.map((supplier) => ({ value: supplier.id, label: supplier.companyName }))}
                                                                                name='accountName'
                                                                                id='accountName'
                                                                                handleOnChange={(options) => {
                                                                                    const selectedValues = options ? options.map(option => option.value) : [];
                                                                                    setFieldValue('supplierData', selectedValues);
                                                                                    handleSelectSupplier(options)
                                                                                }}
                                                                                isDisabled={ItemMasterEditDisabled}
                                                                            />
                                                                            <label htmlFor="supplierData"
                                                                                className='staan-form-input-label pt-2 px-1 mx-2'>Supplier
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card px-1 mt-4" style={type === "" ? { height: "40%" } : { height: "25%" }} >
                                                                <div className="row m-3">
                                                                    <div className='d-flex justify-content-between'>
                                                                        <div className="col-4 test-center p-2">Document (.csv,.xlsx,.pdf,.doc)</div>

                                                                    </div>
                                                                    <div className='d-flex justify-content-between' >
                                                                        <div style={{ width: '50%' }}>
                                                                            <ul>
                                                                                {
                                                                                    selectedDoc.map((file, index) => {
                                                                                        return <>

                                                                                            <li key={index} className='d-flex justify-content-between mb-1 text-start'>
                                                                                                <span><i className="fa-regular fa-circle"></i></span>
                                                                                                <a href={file.documentFile} className='text-end' download={file.documentFile} style={{ cursor: 'pointer', }}>{decodeURI(file.name)}</a>
                                                                                                <span
                                                                                                    className={`ms-2 me-4 text-danger ${ItemMasterEditDisabled ? 'disabled' : ''}`}
                                                                                                    style={{ cursor: ItemMasterEditDisabled ? 'default' : 'pointer' }}
                                                                                                    onClick={() => {
                                                                                                        if (!ItemMasterEditDisabled) {
                                                                                                            handleDeleteDocument(file);
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="fa-regular fa-trash-can "></i>
                                                                                                </span>
                                                                                            </li>
                                                                                        </>
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                        <div className="border rounded document"
                                                                            onClick={handleDocumentClick}
                                                                            style={{
                                                                                width: '40%',
                                                                                height: `${screenSize?.height / 110}rem`,
                                                                                overflow: "auto", position: "relative"
                                                                            }}
                                                                        >
                                                                            <Field onChange={(e) => { handleDocSelect(e) }} innerRef={DocInputRef}
                                                                                multiple accept=".csv,.xlsx,.pdf,.doc,.docx"
                                                                                className="form-control form-control-sm" hidden id="formFileSm" type="file" name="fieldName" />
                                                                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                                                <div className='text-center'>
                                                                                    <AiOutlineCloudUpload style={{ width: "5rem", height: "3rem" }} />
                                                                                    <br />
                                                                                    <p className='text-center'>Click Here To Select File</p>
                                                                                    <p>  Size : 5MB</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '45%' }}>
                                                            <div className="card px-1 pb-2 my-3">
                                                                <div className="row" style={!isProduct ? { display: "none" } : { Height: '15rem' }}>
                                                                    <div className="col-6 p-1 ms-4">
                                                                        Image(.jpg,.png,.jpeg)
                                                                    </div>
                                                                    <div className="col-5 text-end"  >
                                                                        <AiTwotoneCloseCircle className='fs-5'
                                                                            onClick={(e) => {
                                                                                if (!ItemMasterEditDisabled) {
                                                                                    handleDeleteImage()
                                                                                }

                                                                                // setSelectedImage(null)
                                                                            }} />
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <div className="border rounded p-0 mx-4"
                                                                            onClick={handleImageClick}
                                                                            disabled={ItemMasterEditDisabled}
                                                                            style={{ minHeight: `10rem`, width: "10rem", position: "relative" }}>
                                                                            <Field
                                                                                innerRef={ImageInputRef} onChange={(e) => { handleFileInputChange(e) }}
                                                                                multiple
                                                                                accept=".jpg,.png,.jpeg"
                                                                                hidden
                                                                                className="form-control form-control-sm" id="formFileSm" type="file" name="fieldName"
                                                                            />
                                                                            {selectedImage?.name ?
                                                                                selectedImage && selectedImage.type.startsWith('image/') && (
                                                                                    <div className=" " style={{ width: "10rem", height: "10rem" }}>
                                                                                        <img src={selectedImage.id ? selectedImage.url : URL.createObjectURL(selectedImage)} alt="Selected Image"
                                                                                            style={{ maxWidth: "90%", maxHeight: "90%", objectFit: "contain", padding: "1px" }} />
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                                                    <div className='text-center'>
                                                                                        <AiOutlineCloudUpload style={{ width: "5rem", height: "5rem" }} />
                                                                                        <br />
                                                                                        <p className='text-center'>Click Here To Select Image</p>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 py-5">
                                                                        Area : 160 X 160
                                                                        <br />
                                                                        Size : 2MB
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='  card px-1 pb-2 ' style={!isProduct ? { display: "none" } : {}}>

                                                                <div className="pt-1 mx-1 border-bottom custom-bottom-shadow row">
                                                                    <div className="col-6">
                                                                        <h6 className='mx-3'>Stock Details</h6>
                                                                    </div>
                                                                    <div className="col-6 text-end">
                                                                        <TbHistory type="button" data-bs-toggle="offcanvas" data-bs-target="#History" onClick={() => { setHistoryIds(initialmaster.HistoryDetails) }} />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className='row mx-2 my-1'>
                                                                        <div className="col-6 staan-input-group">
                                                                            <Field type='text' id='total_stock' name='Total_Stock' value={totalStockData} disabled className='w-100 staan-form-input' />
                                                                            <label htmlFor="total_stock" className='staan-form-input-label pt-2 px-1 mx-2'>Total Stock</label>
                                                                            {touched.Total_Stock && errors.Total_Stock && <small className="staan-error-msg">{errors.Total_Stock}</small>}
                                                                        </div>
                                                                        <div className="col-6 staan-input-group">
                                                                            <Field type='text' id='reject_stock' name='Rejected_Stock' disabled={ItemMasterEditDisabled} className='w-100 staan-form-input' />
                                                                            <label htmlFor="reject_stock" className='staan-form-input-label pt-2 px-1 mx-2'>Reject Stock</label>
                                                                            {touched.Rejected_Stock && errors.Rejected_Stock && <small className="staan-error-msg">{errors.Rejected_Stock}</small>}
                                                                        </div>
                                                                    </div>
                                                                    <div className='row mx-2 my-1'>
                                                                        <div className="col-4 staan-input-group">
                                                                            <Field type='text' id='safety_stock' name='Item_Safe_Stock' disabled={values.Item_Indicator !== "Buyer" && values.Item_Indicator !== "both"} placeholder='' className='w-100 staan-form-input' />
                                                                            <label htmlFor="safety_stock" className='staan-form-input-label pt-2 px-1 mx-2'>Safety Stock</label>

                                                                            {touched.Item_Safe_Stock && errors.Item_Safe_Stock && <small className="staan-error-msg">{errors.Item_Safe_Stock}</small>}
                                                                        </div>

                                                                        <div className="col-4 staan-input-group">
                                                                            <Field type='text' id='reject_stock' name='Item_Order_Qty' disabled={values.Item_Indicator !== "Buyer" && values.Item_Indicator !== "both"} placeholder='' className='w-100 staan-form-input' />
                                                                            <label htmlFor="reject_stock" className='staan-form-input-label pt-2 px-1 mx-2'>Min Order Qty</label>
                                                                            {touched.Item_Order_Qty && errors.Item_Order_Qty && <small className="staan-error-msg">{errors.Item_Order_Qty}</small>}
                                                                        </div>
                                                                        <div className="col-4 staan-input-group">
                                                                            <Field type='text' id='lead_time' name='Item_Leadtime' disabled={values.Item_Indicator !== "Buyer" && values.Item_Indicator !== "both"} placeholder='' className='w-100 staan-form-input' />
                                                                            <label htmlFor="lead_time" className='staan-form-input-label pt-2 px-1 mx-2'>Lead Time in Days</label>
                                                                            {touched.Item_Leadtime && errors.Item_Leadtime && <small className="staan-error-msg">{errors.Item_Leadtime}</small>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='card px-1 pb-2 my-4'>
                                                                <div className="pt-1 mx-1">
                                                                    <h6 className='pt-1 mx-3'>Base Unit Conversion</h6>
                                                                </div>
                                                                <div style={{ height: '7rem', overflow: 'scroll' }}>
                                                                    <table style={{ fontSize: '.7rem', width: '90%' }} className='mx-auto table table-striped'>
                                                                        <thead className='thead-dark row text-center border'>
                                                                            <th scope="col" className='col-2 pt-1 border'>#</th>
                                                                            <th scope="col" className='col-4 pt-1 border'>Unit</th>
                                                                            <th scope="col" className='col-2 pt-1 border'>Stock</th>
                                                                            <th scope="col" className='col-4 pt-1 border'>Price per Unit</th>
                                                                        </thead>
                                                                        <tbody>
                                                                            {aUOM && aUOM.map((alternate_unit_item, index) => (
                                                                                <tr className='row text-center' key={index}>
                                                                                    <td className='col-2'>{index + 1}</td>
                                                                                    <td className='col-4'>{alternate_unit_item?.['Addtional_Unit']?.['label']}</td>
                                                                                    <td className='col-2'>{
                                                                                        Number(totalStockData / Number(alternate_unit_item['Conversion_Factor'])).toFixed(2)
                                                                                    }</td>
                                                                                    <td className='col-4'>{(values.Item_Cost / Number(alternate_unit_item['Conversion_Factor'])).toFixed(2)}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className='card px-1 my-3' style={!isProduct ? { display: "none", minHeight: '11rem' } : { minHeight: '11rem' }}>
                                                                <div className="pt-1 mx-1 border-bottom custom-bottom-shadow">
                                                                    <h6 className='pt-1 mx-3'>Item Tracking Details</h6>
                                                                </div>
                                                                <div>
                                                                    <div className='row mx-2'>
                                                                        <div className="col-4 pt-2">
                                                                            <Field type="checkbox" name="serial" checked={values.serial} disabled className='me-2' />
                                                                            <label style={{ fontSize: '.8rem', top: '5px' }} className='pb-1'>Serial Number</label>
                                                                            <br />
                                                                            {errors.serial && <small className="staan-error-msg">{errors.serial}</small>}
                                                                        </div>
                                                                        <div className="col-4 pt-2 text-center">
                                                                            <Field type="checkbox" name="Batch_number" disabled className='me-2' />
                                                                            <label style={{ fontSize: '.8rem', top: '5px' }} className='pb-1'>Batch Number</label>
                                                                            <br />
                                                                            {errors.Batch_number && <small>{errors.Batch_number}</small>}
                                                                        </div>
                                                                        <div className="col-4 pt-2 text-end">
                                                                            <Field type="checkbox" name="Item_Barcode" disabled={ItemMasterEditDisabled} className='me-2' />
                                                                            <label style={{ fontSize: '.8rem', top: '5px' }} className='pb-1'>Barcode</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mx-2 my-1" hidden={!values.serial}>
                                                                        <div className='col-12'>
                                                                            <Field type="checkbox" checked={values.serial_auto_gentrate} name="serial_auto_gentrate" disabled className='me-2' />
                                                                            <label style={{ fontSize: '.8rem' }} className='pb-2'>Auto Generate</label>
                                                                        </div>
                                                                        <div className="col-6 staan-input-group">
                                                                            <Field type='text' id="Serial_format" name='Serial_format' disabled className='w-100 staan-form-input' />
                                                                            <label htmlFor="Serial_format" className='staan-form-input-label pt-2 px-1 mx-2'>Serial Format</label>
                                                                            {errors.Serial_format && <small>{errors.Serial_format}</small>}
                                                                        </div>
                                                                        <div className="col-6  staan-input-group">
                                                                            <Field type='text' id='Serial_starting' name='Serial_starting' disabled className='w-100 staan-form-input' />
                                                                            <label htmlFor="Serial_starting" className='staan-form-input-label pt-2 px-1 mx-2'>Serial Starting</label>
                                                                            {errors.Serial_starting && <small>{errors.Serial_starting}</small>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 staan-input-group">
                                                                            <Field type='text' id="location" name='location' disabled={ItemMasterEditDisabled} className='w-100 staan-form-input'
                                                                                value={locationData}
                                                                                onChange={(e) => { setFieldValue("location", e.target.value); setlocationData(e.target.value); }}
                                                                            />
                                                                            <label htmlFor="location" className='staan-form-input-label pt-2 px-1 mx-2'>location</label>
                                                                            {errors.location && <small className="staan-error-msg">{errors.location}</small>}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='card px-1 mt-3' style={!isProduct ? { display: "none", minHeight: '11rem' } : { minHeight: '11rem' }}>
                                                                <div className="row mx-2">
                                                                    <div className="col-4 pt-3">
                                                                        <Field type="checkbox"
                                                                            name="Service" checked={values.Service} disabled className='me-2' />
                                                                        <label
                                                                            style={{ fontSize: '.8rem', cursor: 'pointer' }}
                                                                            className='pb-2 text-primary'
                                                                            onClick={() => { handleserviceableShow() }}
                                                                        >Item Serviceable</label>
                                                                        {errors.Service && <small>{errors.Service}</small>}
                                                                    </div>
                                                                    <div className="col-4 pt-3">
                                                                        <Field type="checkbox" name="Item_Combo" checked={values.Item_Combo}
                                                                            onClick={() => { handleItemComboShow() }} disabled
                                                                        // ={ItemMasterEditDisabled ? true : (type === 'Sell' || type ==="Both")? false : true } className='me-2'
                                                                        />
                                                                        <label style={{ fontSize: '.8rem', cursor: 'pointer' }}
                                                                            onClick={() => { handleItemComboShow() }} className='pb-2 text-primary'>Item Combo</label>
                                                                        {errors.Item_Combo && <small>{errors.Item_Combo}</small>}
                                                                    </div>

                                                                </div>
                                                                <div className="row mx-2">
                                                                    <div className="col-4 pt-3  ">
                                                                        <Field type="checkbox"
                                                                            name="is_manufacture" className='me-2' checked={isManufacture}
                                                                            disabled={ItemMasterEditDisabled}
                                                                            onClick={(e) => {
                                                                                setIsManufacture(e.target.checked)
                                                                                if (e.target.checked) {
                                                                                    setFieldValue("is_manufacture", true)
                                                                                } else {
                                                                                    setFieldValue("is_manufacture", false)
                                                                                }
                                                                            }} />
                                                                        <label style={{ fontSize: '.8rem' }} className='pb-2'>
                                                                            Manufacture
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-4 pt-3   ">
                                                                        <Field type="checkbox" name="item_qc" disabled={ItemMasterEditDisabled} className='me-2' />
                                                                        <label style={{ fontSize: '.8rem' }} className='pb-2'>Item QC</label>
                                                                        {errors.item_qc && <small>{errors.item_qc}</small>}
                                                                    </div>
                                                                </div>
                                                                <div className="row mx-2 my-2" hidden={!values.Service}>
                                                                    <div className="col-4 mt-4">
                                                                        <Field type="checkbox" name="Warranty_Require" className='me-1' disabled={ItemMasterEditDisabled} />
                                                                        <label style={{ fontSize: '.8rem' }} className='pb-2'>Warranty Required</label>
                                                                    </div>
                                                                    <div className="col-4 text-center mt-4">
                                                                        <Field type="checkbox" name="Item_Installation" className='me-1' disabled={ItemMasterEditDisabled} />
                                                                        <label style={{ fontSize: '.8rem' }} className='pb-2'>Item Installation</label>
                                                                    </div>
                                                                    <div className="col-4 staan-input-group">
                                                                        <CustomSelect
                                                                            setSelectEditValue={selectedWarranty_based}
                                                                            options={WarrantyBasedOptions}
                                                                            name='Item_Warranty_based'
                                                                            id='Item_Warranty_based'
                                                                            handleOnChange={(option) => {
                                                                                setFieldValue('Item_Warranty_based', option ? option?.value : null)
                                                                                handleSelectWarranty_based(option)
                                                                            }}
                                                                            isDisabled={ItemMasterEditDisabled}
                                                                        />
                                                                        <label htmlFor="Item_Warranty_based" className='staan-form-input-label pt-1 px-1 mx-2' >Item Warranty based</label>
                                                                        {touched.Item_Warranty_based && errors.Item_Warranty_based && <small>{errors.Item_Warranty_based}</small>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card px-1 pb-2 my-3">
                                                                <div className="col-12 staan-input-group">
                                                                    <Field type='text' as='textarea' style={{ height: "5rem" }} rows="3" name='note'
                                                                        value={notes}
                                                                        onChange={(e) => {
                                                                            setFieldValue("note", e.target.value);
                                                                            setNotes(e.target.value)
                                                                        }}
                                                                        className='w-100 staan-form-input'
                                                                    />
                                                                    <label htmlFor="note" className='staan-form-input-label pt-2 px-1 mx-2'>Note</label>
                                                                    {touched.note && errors.note && <small className='text-danger' >{errors.note}</small>}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                                {/* item master edit new ui code ends */}
                                                <AlternateUnit
                                                    isDisabled={ItemMasterEditDisabled}
                                                    deleteIsdisabled={true}
                                                    Fullvalue={values}
                                                    values={selectedUOM}
                                                    isAlternateUomFixed={isAlternateUomFixed}
                                                    handleChangeAlternateUomFixed={(e) => { handleChangeAlternateUomFixed(e) }}
                                                    handleAlternateUomChanged={(d) => { handleAlternateUomChanged(d) }}
                                                    setPurchaseUomOptions={setPurchaseUomOptions}
                                                />
                                                <ItemCombo
                                                    handleChangeItemComboPrint={(e) => { handleChangeItemComboPrint(e) }}
                                                    values={initialmaster}
                                                    isItemComboPrint={isItemComboPrint}
                                                    initialMasterData={initialMasterData}
                                                    ItemMasterEditDisabled={ItemMasterEditDisabled}
                                                />
                                                <Serviceable
                                                    serviceable={serviceable}
                                                    handleserviceableClose={handleserviceableClose}
                                                    touched={touched}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue}
                                                    values={values}
                                                    initalData={initialmaster}
                                                    ItemMasterEditDisabled={ItemMasterEditDisabled}
                                                />

                                            </div>
                                        </Form>
                                    </>
                                )
                            }}

                        </Formik>
                        <ItemaMasterModal />
                    </div>
                </div>
            </>


        </Modal>
    )
}

export default ItemMasterEdit