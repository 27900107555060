import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { TbHistory } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { storeCreate, storeDelete, storeEdit } from '../../../GraphQLQueries/ItemMastermutation';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import axiosInstance from '../../../api/axoiss';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import WaringModel from '../../../components/Warings/WaringModel';
import Can from "../../../components/authorizationComponents/Can";
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import DataContext from '../../../context/ItemMasterContext';
import "../../../style.css";
import { ShowErrorNoties, checkChangesInputs, removeEmptyValueInObject } from '../../../utils/Utils';
import { StoreSchema } from '../../../validations/itemmaster';
import HistoryData from '../../ItemMaster/model/HistoryData';

const StoreAdd = ({ storeEditId, setstoreEditId, setLoad }) => {
  const { storeAdd, handleStoreAddClose, handltoastDeleteConfomationShow,
    userId, historyIds, setHistoryIds } = useContext(DataContext)
  const [edit, setEdit] = useState(true)
  const [deleteData, setdeleteData] = useState({ Name: "", id: "" })
  const [selectAccount, setSelectAccount] = useState()
  const dispatch = useDispatch();
  const OptionsDataList = useSelector(state => state.itemGroup.data);
  const [selectInCharge, setSelectInCharge] = useState()
  const [initialStore, setinitialStore] = useState({
    "id": "",
    "StoreName": "",
    "matained": true,
    "Action": true,
    "StoreAccount": '',
    "StoreInCharge": '',
    "createdby": null,
    "modifiedBy": null,
    "HistoryDetails": "",
    "conference": false,
  })
  const [storeAddValues, setstoreAddValues] = useState({
    accountOptions: [],
    userOptions: [],
  })
  const [isWarningShow, setisWarningShow] = useState(false)
  const [formKey, setFormKey] = useState(0);
  const [loading, setLoading] = useState(false)


  const handledeleteConfomation = (data) => {
    // This function prepares and shows a confirmation dialog for deleting an item.
    // It takes the store data as input, displays a confirmation toast, 
    // and sets the deleteData state with the store's name and ID.
    handltoastDeleteConfomationShow();
    setdeleteData({
      Name: data?.StoreName,
      id: data?.id
    });

  }
  function reset_form() {
    // The reset_form function clears the form state by resetting selection values for account and in-charge,
    // clearing the storeEditId, closing the store addition modal, and setting edit mode to false.
    setSelectAccount({ value: "", label: "" });
    setSelectInCharge({ value: "", label: "" });
    setstoreEditId('');
    handleStoreAddClose();
    setEdit(false)

  }
  async function fetchData(id) {
    // The fetchData function retrieves store details by sending a GraphQL query with the given ID. 
    // It updates the initial store state and selects options based on the response data. 
    // The formKey is used to force a re-render of the Formik component whenever it changes, 
    setLoading(true)
    try {
      const response = await axiosInstance.post("/itemmaster/graphql", { query: storeEdit(id) });
      const responseData = response.data.data.Store.items[0];
      if (responseData) {
        setinitialStore(
          {
            "id": responseData['id'] ? responseData['id'] : '',
            "StoreName": responseData['storeName'] ? responseData['storeName'] : '',
            "matained": responseData['matained'],
            "Action": responseData['action'],
            "StoreAccount": responseData['storeAccount']?.['id'],
            "StoreInCharge": responseData['storeIncharge']?.['id'],
            "createdby": responseData['createdBy']?.['id'],
            "modifiedBy": responseData['modifiedBy']?.['id'],
            "HistoryDetails": responseData['historyDetails'],
            "conference": responseData['conference'],
          }
        )
        setSelectAccount({ value: responseData?.['storeAccount']?.['id'], label: responseData?.['storeAccount']?.['accountsName'] });
        setSelectInCharge({ value: responseData?.['storeIncharge']?.['id'], label: responseData?.['storeIncharge']?.['username'] });
        setFormKey((prevKey) => prevKey + 1);
      }
    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
    }
    setLoading(false)
  }

  useEffect(() => {
    // This useEffect triggered when storeEditId updates . 
    // If a storeEditId is present, it fetches the corresponding store data and sets the edit mode to true. 
    // If storeEditId is absent, it resets the initial store state and sets edit mode to false.
    if (storeEditId) {
      fetchData(storeEditId);
      setEdit(true)
    }
    else {
      setinitialStore({
        "StoreName": "",
        "matained": true,
        "Action": true,
        "StoreAccount": '',
        "StoreInCharge": '',
        "conference": false,
      })
      setEdit(false)
    }
  }, [storeEditId]);

  const handlesubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
    // The handlesubmit function processes the form submission using Formik values. 
    // removes any empty values and sends a GraphQL mutation to create or update the store. 
    // If successful, it resets the form and shows a success message; otherwise, it displays error messages.
    setLoading(true)
    const reqBody = {
      "id": values?.id ? values?.id : "",
      "storeName": values['StoreName'],
      "storeAccount": Number(values['StoreAccount']),
      "storeIncharge": values['StoreInCharge'] ? Number(values['StoreInCharge']) : "",
      "matained": values['matained'],
      "action": values['Action'],
      "modifiedBy": storeEditId ? Number(userId) : "",
      "createdBy": storeEditId ? Number(initialStore.createdby) : Number(userId),
      "conference": values['conference']
    }
    try {
      let allow_null = ['id', "modifiedBy", "storeIncharge"]
      let clearValue = removeEmptyValueInObject(reqBody, allow_null)
      const response = await axiosInstance.post("/itemmaster/graphql", { query: storeCreate(clearValue) })
      if (response?.data?.data?.storeCreateMutations?.success) {
        reset_form()
        setLoad()
        showErrorToast(true, 'success', "", "Successfully Updated")

        setFormKey(prev => prev + 1)
      } else {
        let errorData = ShowErrorNoties(response?.data?.data?.storeCreateMutations?.errors)
        showErrorToast(true, 'error', errorData)

      }
    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
    }
    setLoading(false)
  }

  let currentValues = {};
  function Close() {
    // The Close function checks for duplicate inputs by comparing initialStore and currentValues. 
    // If no duplicates are found, it resets the form; otherwise, it shows a warning. 
    // This function is triggered by clicking the back arrow in the modal title.
    let isDuplicate = checkChangesInputs([initialStore, currentValues])
    if (!isDuplicate) {
      reset_form()
    } else {
      setisWarningShow(true)
    }
  }

  const handleDeletedId = (deleted_id) => {
    // This function handles actions after an item has been deleted.
    // If a deleted ID is provided, it resets the form and triggers the Form to re render.
    if (deleted_id) {
      reset_form()
      setLoad()
    }
  }
  useEffect(() => {
    // This useEffect updates storeAddValues based on the name property of OptionsDataList. 
    // It creates accountOptions or userOptions from the items for use in CustomSelect components. 
    // OptionsDataList is retrieved from the Redux store, containing data for item groups.
    // The function does not return any value
    if (OptionsDataList.name === "Account") {
      setstoreAddValues(prev => ({
        ...prev,
        accountOptions: OptionsDataList?.value?.items?.map((item) => ({ value: item?.id, label: item?.accountsName }))
      }))
    }
    if (OptionsDataList.name === "userdata") {
      setstoreAddValues(prev => ({
        ...prev,
        userOptions: OptionsDataList.value.items.map((item) => ({ value: item.id, label: item.username }))
      }))
    }
  }, [OptionsDataList])
  return (
    <>
     {<BallTriangleLoading isshow={loading} />}
      <HistoryData
        historyIds={historyIds}
        setHistoryIds={setHistoryIds}
      />
      <ToastContainer />
      <Modal size="lg" show={storeAdd}
        backdrop="static"
        keyboard={false}>
        <div className="row">
          <div className="col-5 m-3" style={{ color: "#5CADA9" }}>
            <Modal.Title>
              <i className='fa-solid fa-arrow-left fa-sm me-2'
                onClick={() => {
                  Close()
                }}
              ></i>Store Add</Modal.Title></div>
          <div className="col-6 mt-4 text-end" >
            <Can I={"Edit"} a={"Store"}>
              <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={!(storeEditId && edit)} onClick={() => { setEdit(!edit) }}  ></i>
            </Can>
            <Can I={"Delete"} a={"Store"}>
              <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={!(storeEditId && edit)} onClick={() => { handledeleteConfomation(initialStore) }} ></i>
            </Can>
            <Can I={"Historys"} a={"Store"}>
              <TbHistory type="button" className='fs-4 ms-3' data-bs-toggle="offcanvas" hidden={!(storeEditId && edit)} data-bs-target="#History" onClick={() => { setHistoryIds(initialStore.HistoryDetails) }} />
            </Can>

          </div>
        </div>
        <hr />
        <Modal.Body>
          <Formik initialValues={initialStore}
            key={formKey}
            onSubmit={handlesubmit}
            validationSchema={StoreSchema}>
            {({ errors, touched, isSubmitting, values, setFieldValue, Formik, formikProps }) => {
              currentValues = values;
              return (
                <Form>
                  <div>
                    <div className='row mx-2'>
                      <div className="col-6 staan-input-group">
                        <Field type='text' id='StoreName' name='StoreName' disabled={storeEditId && edit}
                          className='w-100 staan-form-input' />
                        <label htmlFor="StoreName" className='staan-form-input-label pt-2 px-1 mx-2' onClick={() => {
                          console.log(values);
                        }}>Name</label>
                        {touched.StoreName && errors.StoreName && <small>{errors.StoreName}</small>}
                      </div>
                      <div className="col-6 staan-input-group">
                        <CustomSelect
                          setSelectEditValue={selectAccount}
                          handleInputChange={(e) => { dispatch(fetchOptionsData(QueryData.accountsMasterOptionQuery(e), "Account"), "PurchaseAccount") }}
                          options={storeAddValues?.accountOptions}
                          name='accountName'
                          handleOnChange={(option) => {
                            setFieldValue('StoreAccount', option ? option.value : null);
                          }}
                          isDisabled={storeEditId && edit}
                        />
                        <label className='staan-form-input-label pt-1 px-1 mx-2'>Account</label>
                        {touched.StoreAccount && errors.StoreAccount && <small>{errors.StoreAccount}</small>}
                      </div>
                    </div>
                    <div className='row mx-2'>
                      <div className="col-6 staan-input-group">
                        <CustomSelect
                          setSelectEditValue={selectInCharge}
                          handleInputChange={(e) => { dispatch(fetchOptionsData(QueryData.userdataOptionsQuery(e), "userdata")) }}
                          options={storeAddValues?.userOptions}
                          name='accountName'
                          handleOnChange={(option) => {
                            setFieldValue('StoreInCharge', option ? option.value : null);
                          }}
                          isDisabled={storeEditId && edit}
                        />
                        <label className='staan-form-input-label pt-1 px-1 mx-2'>Store InCharge</label>
                        {touched.StoreInCharge && errors.StoreInCharge && <small>{errors.StoreInCharge}</small>}
                      </div>
                      <div className='col-6 mt-3 d-flex align-items-center'>
                        <div className="col-6">
                          <Field type="checkbox" name="matained" id='matained' disabled={storeEditId ? true : false} className='me-2' />
                          <label htmlFor='matained' className='staan-form-input-label pt-1 px-1 mx-2'>Keep Stock</label>
                        </div>
                        <div className="col-6">
                          <Field type="checkbox" name="conference" id='conference' disabled={storeEditId && edit} className='me-2' />
                          <label htmlFor='conference' className='staan-form-input-label pt-1 px-1 mx-2'>Conference</label>
                        </div>
                      </div>
                    </div>
                    <div className='row m-2'>
                      <div className="col-4">
                        <Field type="checkbox" name="Action" id='Action' disabled={storeEditId && edit} className='me-2' />
                        <label htmlFor='Action' className='staan-form-input-label pt-1 px-1 mx-2'>Active</label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="text-end">
                      <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={() => { reset_form() }}>
                        Cancel
                      </button>
                      <Can allow_list={["Save_Store", "Edit_Store"]}>
                        <button type="submit" disabled={isSubmitting} className="btn btn-sm btn-outline-success shadow-sm px-3">
                          Save
                        </button>
                      </Can>

                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {isWarningShow &&
        <WaringModel
          state={isWarningShow}
          setstate={setisWarningShow}
          title={'Confirm'}
          text={"Do you want to leave without saving?"}
          Confirmtion={value => {
            if (value) {
              reset_form()
            }
          }}
        />}
      {deleteData && <DeleteConformation
        handleDeletedId={(id_) => { handleDeletedId(id_) }}
        deleteData={deleteData}
        fullquery={storeDelete(`id:${deleteData?.id}`)}
      />}
    </>
  )
}

export default StoreAdd