export const ItemMasterCreate = (data) => {
  return`mutation MyQuery { itemMasterCreateMutation(` + data + `){
            errors
            success
          }
        }`
}



export const getItemMasterWithId = (data) => {
  return `query myquery{
    itemMaster(id: ${data}, any:${true}){
      items{
        id
        itemPartCode
        itemName
        description
        itemTypes{
          id
          name
        }
        itemUom{
          id
          name
        }
        itemGroup{
          id
          name
        }
        itemIndicators{
          id
          name
        }
        category{
          id
          name
        }
        purchaseUom{
          id
          name
        }
        itemHsn{
          id
          hsnCode
          gstRates{
            rate
          }
        }
        itemCost
        purchaseUom{
          id
          name
        }
        itemPurchaseAccount{
          id
          accountsName
        } 
        supplierData{
          id
          companyName
        }
        location
        notes
        variation
        isManufacture
        productImage{
          id
          image
        }
        productDocument{
          id
          documentFile
        }
        itemMinPrice
        itemMrp
        sellOnMrp
        keepStock
        itemActive
        serial
        batchNumber
        itemBarcode
        service
        itemComboBool
        itemComboPrint
        itemComboData{
          id
        }
        itemQc
        itemSalesAccount{
          id
          accountsName
        }
        alternateUom{
          id
        }
        historyDetails{
          id
          Action
          ColumnName
          PreviousState
          UpdatedState
          modifiedDate
          SavedBy {
            id
            username
          }
        }
        serialAutoGentrate
        serialFormat
        serialStarting
        itemWarrantyBased
        itemInstallation
        totalStock
        rejectedStock
        itemSafeStock
        itemOrderQty
        itemLeadTime
        alternateUomFixed
        createdBy{
          id
          username
        }
        productDocument {
          id
          documentFile
        }
        productImage {
          id
          image
        }
      }
    }
  }`
}

export const itemComboMutations = (data) => {
  const mutations = `
    mutation mutate{
        itemComboCreateMutatio(`+ data + `){
           
          ItemCombo{
            id
            sNo 
            partNumber{
              id
              itemPartCode
              itemName
            }
            itemQty
            itemDisplay{
              id
              display
            }
            isMandatory
          }
          success
          errors
        }
      }
    `
  return mutations

}
export const itemComboDelete = (data) => {
  const mutations = `
  mutation itemComboDelete{
    itemComboDeleteMutation(id:${data}){
      errors
      success
    }
  }`
  return mutations
}


export const storeCreate = (data) => {
  const mutations = `
  mutation mystoreCreste{
    storeCreateMutations(`+ data + `){
    Store{
      id
      storeName
    }
    success
    errors
    }
  }`
  return mutations
}

export const storeDelete = (data) => {
  const mutations = `
  mutation MyMutation {
  storeDeleteMutations(${data}) {
    errors
    success
  }
}`
  return mutations
}

export const storeEdit = (data) => {
  const mutations = `
  query storeEdit{
    Store(id:${data}, any:${true}    ){
      items{
        id
        storeName
        conference
        storeAccount{
          id
          accountsName
        }
        storeIncharge{
          id
          username
        }
        matained
        action
        createdBy{
          id
          username
        }
        modifiedBy{
          id
          username
        }
        historyDetails{
          id
          Action
          ColumnName
          PreviousState
          UpdatedState
          modifiedDate
          SavedBy{
            id
            username
          }
        }

      }
    }
  }
  `
  return mutations
}


export const createItemDisplayGroup = (part_code, display) => {
  return `mutation myFirstCreateQuery{
    displayGroupCreateMutation(partNumber: ${part_code}, display: "${display}"){
        displayGroupItem{
          id
          display
          partNumber{
            id
          }
        }
        success
        errors
      }
    }`
}

export const createCurrencyExchange = (data) => {
  let mutations = ` mutation currencyExchangeCreateMutation{
    currencyExchangeCreateMutation(`+ data + `){
      success
      errors
    }
  }`
  return mutations
}

export const createCurrencyMaster = (data) => {
  let mutations = `mutation currencyMaster{
    currencyMasterCreateMutation(${data}){
      currencyMasterInstance{
        id
        name
        }
      success
      errors
      }
    }`
  return mutations
}

export const createNumberingSeries = (data) => {
  let mutations = `
  mutation NumberingSeries{
    numberingSeriesCreateMutation(${data}){
     numberingSeriesInstance{
       id
       numberingSeriesName
     }
     success
     errors
   }
 }`
  return mutations
}

export const createPosOrderItem = (data) => {
  let mutations = `
  mutation salesordersitemMutations{
    salesOrderCreateMutation(${data}){
      SalesoderInstance{
        id
        partCode{
          id
          itemPartCode
          itemName
        }
        posItemComboBool
        gstRate
        description
        qty
        rate
        amount
        stockReduce
        uom{
          id
          name
        }
        batch{
          id
          batchNumberName
        }
        serial{
          id
          serialNumber
        }
        createdBy{
          id
          username
        }
        itemCombo{
          id
        }
        discountPercentage
        discountValue
        finalValue
        discountValueForPerItem
        igst
        cgst
        sgst 
      }
      success
      errors
    }
      
  }`
  return mutations
}
export const salesOrderBulkCreateMutation = (data) => {
  let mutations = `
   mutation salesOrderBulkCreateMutation($salesOrders: [SalesOrderInput]) {
        salesOrderBulkCreateMutation(salesOrders: $salesOrders) {
          errors
          success
          createdItems {
            id
            partCode {
              id
              itemPartCode
              itemName
            }
            posItemComboBool
            gstRate
            description
            qty
            rate
            amount
            stockReduce
            uom {
              id
              name
            }
            batch {
              id
              batchNumberName
            }
            serial {
              id
              serialNumber
            }
            createdBy {
              id
              username
            }
            itemCombo {
              id
            }
            discountPercentage
            discountValue
            finalValue
            discountValueForPerItem
            igst
            cgst
            sgst
          }
        }
      }`
  return mutations
}

export const salesOrderBulkUpdatetionsMutation = (data) => {
  let mutations = `
    mutation salesOrderBulkCreateMutation($salesOrders: [SalesOrderInput]) {
      salesOrderBulkCreateMutation(salesOrders: $salesOrders) {
        errors
        success
        updatedItems {
          id
          partCode {
            id
            itemPartCode
            itemName
          }
          posItemComboBool
          gstRate
          description
          qty
          rate
          amount
          stockReduce
          uom {
            id
            name
          }
          batch {
            id
            batchNumberName
          }
          serial {
            id
            serialNumber
          }
          createdBy {
            id
            username
          }
          itemCombo {
            id
          }
          discountPercentage
          discountValue
          finalValue
          discountValueForPerItem
          igst
          cgst
          sgst
        }
      }
    }`
  return mutations
}

export const salesOrderItemOverallDiscountPercentage = (idList, percentage) => {
  let mutations = `
  query MyQuery {
    salesOrderItemOverallDiscountPercentage(idList: [${idList}], percentage:${percentage}) {
     items {
       
        id
        partCode {
          id
          itemPartCode
          itemName
        }
        posItemComboBool
        gstRate
        description
        qty
        rate
        amount
        stockReduce
        uom {
          id
          name
        }
        batch {
          id
          batchNumberName
        }
        serial {
          id
          serialNumber
        }
        createdBy {
          id
          username
        }
        itemCombo {
          id
        }
        discountPercentage
        discountValue
        finalValue
        discountValueForPerItem
        igst
        cgst
        sgst
      
    }
  }
}
`
  return mutations
}

export const salesOrderItemoverallDiscountValue = (idList, totalToSubtract) => {
  let mutations = `
    query MyQuery {
  salesOrderItemoverallDiscountValue(idList: [${idList}], totalToSubtract: ${totalToSubtract}) {
     items {
       
        id
        partCode {
          id
          itemPartCode
          itemName
        }
        posItemComboBool
        gstRate
        description
        qty
        rate
        amount
        stockReduce
        uom {
          id
          name
        }
        batch {
          id
          batchNumberName
        }
        serial {
          id
          serialNumber
        }
        createdBy {
          id
          username
        }
        itemCombo {
          id
        }
        discountPercentage
        discountValue
        finalValue
        discountValueForPerItem
        igst
        cgst
        sgst
      
    }
  }
}
`
  return mutations
}

export const salesOrderItemFinalTotalDiscount = (idList, finalTotal, TotalWithTaxValue) => {
  let TotalWithTaxValuePerameter = TotalWithTaxValue ? TotalWithTaxValue : null
  let mutations = `
      query MyQuery {
  salesOrderItemFinalTotalDiscount(
     idList: [${idList}]
     finalTotal: ${finalTotal}
     TotalWithTaxValue: ${TotalWithTaxValuePerameter}
  ) {
     items {
        id
        partCode {
          id
          itemPartCode
          itemName
        }
        posItemComboBool
        gstRate
        description
        qty
        rate
        amount
        stockReduce
        uom {
          id
          name
        }
        batch {
          id
          batchNumberName
        }
        serial {
          id
          serialNumber
        }
        createdBy {
          id
          username
        }
        itemCombo {
          id
        }
        discountPercentage
        discountValue
        finalValue
        discountValueForPerItem
        igst
        cgst
        sgst
      
    }
  }
}
`
  return mutations
}

export const salesOrderItemClearDiscount = (idList) => {

  let mutations = `
      query MyQuery {
  salesOrderItemClearDiscount(
     idList: [${idList}]
  ) {
      items {
        id
        partCode {
          id
          itemPartCode
          itemName
        }
        posItemComboBool
        gstRate
        description
        qty
        rate
        amount
        stockReduce
        uom {
          id
          name
        }
        batch {
          id
          batchNumberName
        }
        serial {
          id
          serialNumber
        }
        createdBy {
          id
          username
        }
        itemCombo {
          id
        }
        discountPercentage
        discountValue
        finalValue
        discountValueForPerItem
        igst
        cgst
        sgst
      
    }
  }
}
`
  return mutations
}



export const deletePosOrderItem = (data) => {
  let mutations = `
  mutation mymutations{
    salesOrderDeleteMutation(id:${data}){
      errors
      success
    }
  }`
  return mutations
}

export const createPaymentMode = (data) => {
  let mutation = `
  mutation paymentmutations{
    paymentModeCreateMutation(${data}){
    paymentModeInstance{
      id
      payAmount
      balanceAmount
    }
    success
    errors
    
  }
}
  `
  return mutation
}

export const deleteposPayment = (data) => {
  let mutation = `
  mutation deletepospayment {
    paymentModeDeleteMutation(id:${data}){
     success
     errors
   }
 }`
  return mutation
}


export const createPOSMode = (data) => {
  let mutation = `
  mutation enquiryDataCreateMutation {
    salesOrderPosCreateMutation(`+ data + `) {
      errors
      success
      salesOrderPosInstance {
        id
        district
        pincode{
          id
          pincode
        }
        CosName
        CreatedAt
        DiscountFinalTotal
        sampleContactLink{
          id
          contactPersonName
        }
        Email
        FinalTotalValue
        IsPOS
        Mobile
        OrderDate
        OverallDiscountPercentage
        OverallDiscountPercentageValue
        OverallDiscountValue
        balanceAmount
        receivedAmount
        POSId{
          linkedModelId
        }
        Pending
        Remarks
        customerName{
          id
          companyName
          legalName
        }
        marketingEvent {
          id
          name
          currency{
          rate
          Currency{
            currencySymbol
          }
        }
        }
        State
        TotalAmount
        UpdatedAt
        WhatsappNumber
        balanceAmount
        cgst
        id
        igst
        allStockReduced
        isDelivered
        itemDetails {
          id
          partCode {
            id
            itemPartCode
            itemName
            serial
            batchNumber
          }
          hsn{
            id
            hsnCode
          }
          posItemComboBool
          description
          stockReduce
          isCanceled
          uom {
            id
            name
          }
          sgst
          cgst
          igst
          qty
          itemCombo{
            id
          }
          serial {
            id
            serialNumber
          }
          batch {
            id
            batchNumberName
          }
          createdBy{
            id
            username
          }
          rate
          gstRate
          amount
          discountPercentage
          discountValue
          discountValueForPerItem
          finalValue
        }
        posType
        receivedAmount
        sgst
        status
        store {
          storeName
          id
        }
        payment {
          id
          payby{
            id
            accountsName
          }
          balanceAmount
          payAmount
          createdBy{
            id
            username
          }
        }
        BillingAddress {
          addressLine1
          addressLine2
          addressType
          city
          country
          default
          id
          pincode
          state
        }
        DeliverAddress {
          addressLine1
          addressLine2
          addressType
          city
          country
          default
          id
          pincode
          state
        }
        SalesPerson{
          id
          username
        }
        contactPerson{
          id
          contactPersonName
          phoneNumber
        }
        createdby{
          id
          username
        }
        UpdatedAt
         otherIncomeCharge {
         otherIncomeChargesId {
           name
            id
        }
            hsn{id
            hsnCode}
        afterDiscountValue
        amount
        cgst
        discountValue
        id
        igst
        tax
        sgst
       createdBy{
        id
        username
      }
      }
      }
    }
  } 
  `
  return mutation
}

export const SubmitPOSQuery = (data) => {
  // posId: "304", submitBy: 3
  let mutation = `
   mutation MyMutation {
  salesOrderSubmitMutation(${data}) {
    errors
    success
    salesOrderPosInstance {
        id
        district
        pincode{
          id
          pincode
        }
        CosName
        CreatedAt
        DiscountFinalTotal
        sampleContactLink{
          id
          contactPersonName
        }
        Email
        FinalTotalValue
        IsPOS
        Mobile
        OrderDate
        OverallDiscountPercentage
        OverallDiscountPercentageValue
        OverallDiscountValue
        balanceAmount
        receivedAmount
        POSId{
          linkedModelId
        }
        Pending
        Remarks
        customerName{
          id
          companyName
          legalName
        }
        marketingEvent {
          id
          name
          currency{
          rate
          Currency{
            currencySymbol
          }
        }
        }
        State
        TotalAmount
        UpdatedAt
        WhatsappNumber
        balanceAmount
        cgst
        id
        igst
        allStockReduced
        isDelivered
        itemDetails {
          id
          partCode {
            id
            itemPartCode
            itemName
            serial
            batchNumber
          }
          posItemComboBool
          description
          stockReduce
          isCanceled
          uom {
            id
            name
          }
          sgst
          cgst
          igst
          qty
          itemCombo{
            id
          }
          serial {
            id
            serialNumber
          }
          batch {
            id
            batchNumberName
          }
          createdBy{
            id
            username
          }
          rate
          gstRate
          amount
          discountPercentage
          discountValue
          discountValueForPerItem
          finalValue
        }
        posType
        receivedAmount
        sgst
        status
        store {
          storeName
          id
        }
        payment {
          id
          payby{
            id
            accountsName
          }
          balanceAmount
          payAmount
          createdBy{
            id
            username
          }
        }
        BillingAddress {
          addressLine1
          addressLine2
          addressType
          city
          country
          default
          id
          pincode
          state
        }
        DeliverAddress {
          addressLine1
          addressLine2
          addressType
          city
          country
          default
          id
          pincode
          state
        }
        SalesPerson{
          id
          username
        }
        contactPerson{
          id
          contactPersonName
          phoneNumber
        }
        createdby{
          id
          username
        }
        UpdatedAt
      }
  }
}
  `
  return mutation
}

export const CancelPOSQuery = (data) => {
  // posID: "304",
  let mutation = `
  mutation MyMutation {
  salesOrderCancleMutation(${data}) {
    errors
    success
    salesOrderPosInstance {
        id
        district
        pincode{
          id
          pincode
        }
        CosName
        CreatedAt
        DiscountFinalTotal
        sampleContactLink{
          id
          contactPersonName
        }
        Email
        FinalTotalValue
        IsPOS
        Mobile
        OrderDate
        OverallDiscountPercentage
        OverallDiscountPercentageValue
        OverallDiscountValue
        balanceAmount
        receivedAmount
        POSId{
          linkedModelId
        }
        Pending
        Remarks
        customerName{
          id
          companyName
          legalName
        }
        marketingEvent {
          id
          name
          currency{
          rate
          Currency{
            currencySymbol
          }
        }
        }
        State
        TotalAmount
        UpdatedAt
        WhatsappNumber
        balanceAmount
        cgst
        id
        igst
        allStockReduced
        isDelivered
        itemDetails {
          id
          partCode {
            id
            itemPartCode
            itemName
            serial
            batchNumber
          }
          posItemComboBool
          description
          stockReduce
          isCanceled
          uom {
            id
            name
          }
          sgst
          cgst
          igst
          qty
          itemCombo{
            id
          }
          serial {
            id
            serialNumber
          }
          batch {
            id
            batchNumberName
          }
          createdBy{
            id
            username
          }
          rate
          gstRate
          amount
          discountPercentage
          discountValue
          discountValueForPerItem
          finalValue
        }
        posType
        receivedAmount
        sgst
        status
        store {
          storeName
          id
        }
        payment {
          id
          payby{
            id
            accountsName
          }
          balanceAmount
          payAmount
          createdBy{
            id
            username
          }
        }
        BillingAddress {
          addressLine1
          addressLine2
          addressType
          city
          country
          default
          id
          pincode
          state
        }
        DeliverAddress {
          addressLine1
          addressLine2
          addressType
          city
          country
          default
          id
          pincode
          state
        }
        SalesPerson{
          id
          username
        }
        contactPerson{
          id
          contactPersonName
          phoneNumber
        }
        createdby{
          id
          username
        }
        UpdatedAt
      }
  }
}
  `
  return mutation
}




export const stockHistoryDeleteMutation = (id) => {
  let mutation = `
  mutation MyMutation {
    stockHistoryDeleteMutation(id: ${id}) {
      errors
      success
    }
  }
  `
  return mutation
}


export const deleteDocument = (id) => {
  let query = `mutation deleteProductDocument{
    documentDeleteMutation(id: ${id}){
      success
      errors
    }
  }`
  return query;
}

export const deleteImage = (id) => {
  let query = `mutation deleteProductImage{
    imageDeleteMutation(id: ${id}){
      success
      errors
    }
  }`
  return query;
}


export const HsnEffectiveDateCreateMutation = (data) => {
  let query = `mutation MyMutation {
  hsnEffectiveDateCreateMutation(${data}) {
    errors
    success
  }
}`
  return query;
}


export const AddHsnCreateMutation = (data) => {
 return `
 mutation hsnCreate{
        hsnCreateMutation(${data}) {
          hsn{
            id
            hsnCode
            gstRates{
              rate
            }
          }
          success
          errors
        }
      }`
}

export const ItemMasterHsnEffectiveDateCreateMutation = (data) => {
  // effectiveDate: "",  hsnId: 10 , id: "", ItemMasterId: 10
  let query = `mutation MyMutation2 {
  itemMasterHsnEffectiveDateMutation(
   ${data}
  ) {
    errors
    success
  }
}`
  console.log(
    query
  );
  return query;
}

export const supplierFormGstEffectiveDateCreateMutation = (data) => {
  //  supplierFormDataId: 107 , modifiedBy: 1 ,id: "" , gstinType: 2, 
  // gstin: "87494947sdvksvd", effectiveDate: "2024-02-01", createdBy: 1
  let query = `mutation MyMutation {
      supplierFormGstEffectiveDateCreateMutation(${data}){
        errors
        success
      }
    }
    `
  console.log(
    query
  );
  return query;
}




