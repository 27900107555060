import React, { useState,   useContext, useEffect,useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import {  Field, Form, Formik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Select from 'react-select'; 
import DataContext from '../../../context/ItemMasterContext';
import axiosInstance from '../../../api/axoiss';
import StaanTables from "../../../Packages/StaanTables1.2"
import { AlternateSchema } from '../../../validations/itemmaster';
import { ShowErrorNoties, customSelectStyle, removeEmptyValueInObject } from '../../../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';  
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { UOMOptionQuery, alternateQuery } from '../../../GraphQLQueries/ItemmasterQuery';
import {ToastContainer, toast , Bounce} from 'react-toastify';

const AlternateUnit = ({setPurchaseUomOptions, deleteIsdisabled, isDisabled, values, Fullvalue, handleAlternateUomChanged,  isAlternateUomFixed, 
   
}) => {
  
  const {AlternateUomAdd, handleAlternateUomClose, userId } = useContext(DataContext)
  const [unitSelectListed , setUnitSelectListed] = useState([values?.value])
  const dispatch = useDispatch();     
  const OptionsDataList = useSelector(state => state.itemGroup.data);
  const [isFixed, setFixed] = useState(false);

  // remove the base unit from Bom select element
  useEffect(()=>{
    setUnitSelectListed(prevList => [...prevList, values?.value])  
  },[AlternateUomAdd]) 
    // initial Values to Formik input
  const initialValues = {
    id : "",
    uom : "",
    Conversion : "",
    fixed : false
  }
 
  const [post, setPost] = useState([]);
  const columnDefs = [
    {
      header: 'Id', 
      fieldname: 'id', 
      hide: true,
      width: 'col-1'
      
    },
    { 
      header: 'Addtional Unit',
      fieldname: 'Addtional_Unit',
      width: 'col-3',
      object : "label",
    },
    { 
      header: 'Measurement', 
      fieldname: 'show_data', 
      width: 'col-2'
    
    },
    {
      header : "fixed",
      fieldname: 'fixed', 
      width: 'col'
    },
    { 
      header: 'Conversion Factor', 
      fieldname: 'Conversion_Factor', 
      width: 'col-3'
    },
     
  ]; 

  const [ConversionUnit, setConversionUnit] = useState('')
  const handleChangeUom = (options)=>{
    setConversionUnit({ value: options.value , label:  options.label })
  }
  const [ConversionFactor, setConversionFactor] = useState('')
  const handleChangeConversinfactor = (value) => {
    setConversionFactor(value)
  }
 
  const [measurement, setMeasurement] = useState("")
  const handleChangeMeasurement = (value)=>{
    setMeasurement(`1 ${value} =`)
  }
  
  // handle submit 
  
  let error = ""
  const handleSubmit = async (values, { setSubmitting, resetForm, setErrors, setFieldError }, toggle, setOpenRowIndex) => {
    let alternate_uom_list = [...post]
    const saveValue =  { 
      id : values.id ? Number(values.id) : "",
      conversionFactor:  String(values['Conversion']) ,
      addtionalUnit : Number(values['uom']),
      fixed : values['fixed'],
      modifiedBy : values.id ? Number(userId) : "",
      createdBy : values.id?  Number(values['createdBy']?['id'] : Number(userId))  : Number(userId)
    } 
    let variable = removeEmptyValueInObject(saveValue) 
    let mutations = `mutation alterUnit{
      alternateUnitCreateMutation(`+variable+`){
      alternateUnit{
        id
        addtionalUnit{
          name
          id
        }
        fixed
        conversionFactor
        createdBy{
          id
          username
        }
        modifiedBy{
          id
          username
        }
      }
      success
      errors
    }
  }`
    if(values.id){ 
        const response = await axiosInstance.post(`/itemmaster/graphql`, {query: mutations });
        const responseData = response['data']['data']['alternateUnitCreateMutation']
        if(responseData.success){
          let data = responseData['alternateUnit']
          let responseDataValue =  {
            id : Number(data.id),
            Addtional_Unit: ( {value: data['addtionalUnit'].id , label:  data['addtionalUnit'].name } ), 
            show_data : `1 ${data['addtionalUnit'].name} =`,
            Conversion_Factor: Number(data.conversionFactor),
            fixed: data['fixed'],
            createdBy : data['createdBy']?.id
          }
          alternate_uom_list.push(responseDataValue)
          // addtionalUnit
          setPost((prevData) => {
            return prevData.map((item) => {
              return item.id === responseDataValue.id ? responseDataValue : item;
            });
          });
          if(handleAlternateUomChanged){
            handleAlternateUomChanged(alternate_uom_list)
          }
          ResetValue()
          toggle()
          setOpenRowIndex() 
      } else{ 
        let errorData = ShowErrorNoties(responseData.errors)
        toast.error( errorData , {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          });
      }  
    } else{ 
        const response = await axiosInstance.post(`/itemmaster/graphql`, {query: mutations });
        const responseData = response['data']['data']['alternateUnitCreateMutation']
        if(responseData.success){
            let data = responseData['alternateUnit']
            // addtionalUnit
          let new_data = {
            id : Number(data.id),
            Addtional_Unit: ( {value: data['addtionalUnit'].id , label:  data['addtionalUnit'].name } ), 
            show_data : `1 ${data['addtionalUnit'].name} =`,
            fixed: data['fixed'],
            Conversion_Factor: Number(data.conversionFactor),
          }
          setPurchaseUomOptions(prev => [...prev, {value: data['addtionalUnit'].id , label:  data['addtionalUnit'].name }])
          // console.log(new_data);
          alternate_uom_list.push(new_data)
          setPost(prevState => [
            ...prevState,
            new_data
          ])
          if(handleAlternateUomChanged){
            handleAlternateUomChanged(alternate_uom_list)
          }
          ResetValue()
          toggle()
          setOpenRowIndex() 
        } else{
          let errorData = ShowErrorNoties(responseData.errors)
          toast.error( errorData , {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } 
       
    } 
  }
 
  const ResetValue = ()=>{
    setConversionUnit('')
    setConversionFactor("")
    setMeasurement('')
    setUnitSelectListed([])
  }

 

// edit functions
  useEffect(() => {
    const fetchData = async () => {
      if (Fullvalue.Alternate_uom.length > 0 && AlternateUomAdd === true) { 
      
        for (let alteruom of Fullvalue.Alternate_uom) {
          try {
            const response = await axiosInstance.get(`/itemmaster/graphql`, {
              params: {
                query: alternateQuery(alteruom),
              },
            });
            let data = response.data.data.alternateUnit[0] 
            
            let responseDataValue =  {
              id : Number(data.id),
              Addtional_Unit: ( {value: data['addtionalUnit'].id , label:  data['addtionalUnit'].name } ), 
              show_data : `1 ${data['addtionalUnit'].name} =`,
              fixed : data['fixed'],
              Conversion_Factor: Number(data.conversionFactor),
            }
              setPost((prevState) => [
            ...prevState,  responseDataValue]);
          } catch (error) {
            // Handle any errors here
            console.error('Error fetching data: ', error);
          }
        }
      }
    };
    if(Fullvalue !== undefined){ 
      fetchData()
      setPost([])
      // console.log(Fullvalue);
    }
    setFixed(isAlternateUomFixed);
  }, [AlternateUomAdd,  Fullvalue ]);
  const [edit, setEdit] = useState(false)

  // input sections 
  const InputFrom =(toggle, setOpenRowIndex, rowdata)=>{
     
    useEffect(()=>{
      if(rowdata['Addtional_Unit']['value']){
        initialValues['id'] = rowdata.id
        initialValues['uom'] = rowdata['Addtional_Unit']['value']
        initialValues['Conversion'] = rowdata['Conversion_Factor']
        initialValues['fixed']= rowdata['fixed'] 
        setEdit(true)
        handleChangeUom(rowdata['Addtional_Unit'])
        handleChangeConversinfactor(rowdata['Conversion_Factor'])
        setMeasurement( `1 ${rowdata['Addtional_Unit']['label']} =`)
      } else{
        setEdit(false)
      }
    },[])
    const [uomOptions, setUomOptions]=useState([])

    const getOption =(e, query, name)=>{
      let search_term = e.trim().replace(/"/g, '\\"');
   
      if (query=== "UOMOptionQuery" && search_term){
          dispatch(fetchOptionsData(UOMOptionQuery(search_term), "Uom"))
      } else{
          setUomOptions([])
      } 
    }
    
    useEffect(()=>{  
     
      if (OptionsDataList.name === "Uom"){   
          setUomOptions(OptionsDataList.value.items)
      } else{
          setUomOptions([])
      } 
      
     
   },[OptionsDataList.value])

    return(
      <>
      <ToastContainer/>
        <Formik initialValues={initialValues}
        validationSchema={AlternateSchema}
        onSubmit={(values, formikProps) => handleSubmit(values, formikProps, toggle, setOpenRowIndex)}
        >
              {({errors, submitForm, touched, setFieldValue})=>(
          <>
           <Form>
            <div className=" row  ">
              <div className="col-3">
                <label htmlFor="uom" className='form-label    pt-2 ps-1'>UOM  </label>
                <Select
                      name="UOM" 
                      onInputChange={(e)=>{getOption(e,"UOMOptionQuery")}}
                      options={uomOptions.filter(uomname => !unitSelectListed.includes(uomname.id))
                        .map((item) => ({ value: item.id, label: item.name }))}  
                      isDisabled={edit}
                      isSearchable={true}
                      value={ConversionUnit}
                      onChange={(option) => {setFieldValue('uom', option ? option.value : null)
                      handleChangeUom(option)
                      handleChangeMeasurement(option.label)
                      }}       
                    styles={customSelectStyle}  
                  /> 
                {  touched.uom &&  errors.uom && <small>{errors.uom}</small>}
              </div> 
              <div className="col-2">
                <label htmlFor="Measurement" className='form-label    pt-2 ps-1'>Measurement  </label>
                <Field type='text' id='Measurement' name='Measurement' disabled  value={measurement} 
              
                  className='  form-control '/> 
               
              </div>
              <div className="col my-4 mx-3">
              <label htmlFor="Measurement" className=' pt-2 ps-1'>Fixed</label>
              <Field  type="checkbox" name="fixed"  
              className='mt-3 mx-3'/> 
              </div>
              <div className="col-2">
                <label htmlFor="Conversion" className='form-label    pt-2 ps-1'>Conversion  </label>
                <Field type='text' id='Conversion' name='ConversionFactor'  value={ConversionFactor} 
                onChange={(e) => {
                setFieldValue('Conversion', e.target.value);
                handleChangeConversinfactor(e.target.value)}}
                  className='w-100 form-control '/> 
                {  touched.Conversion &&  errors.Conversion && <small>{errors.Conversion}</small>}
              </div>
              <div className='col-3 mt-3'>
              <label htmlFor="BatchNumber" className='form-label'  > </label>
              <br/> 
              <div className='row'>
                <div className='col-6 text-end'>
                  <button type="button"  className="save-button"    onMouseDown={()=>{submitForm( )}}>
                  <i className="fa-regular fa-floppy-disk"  ></i>
                  </button>
                
                </div>
                <div className='col-6'>
                  <button type='button' className="delete-button"  onClick={()=>{getDeleteRowId(rowdata)}}>
                  <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
                </div>
              </div>
            </div>
           </Form>
          </>
          )}
        </Formik>
      
      
      </>
    )

  }
  
  const getDeleteRowId= async(data)=>{
    let uom_id = data?.Addtional_Unit?.value
    try{
      const response = await  axiosInstance.delete(`/itemmaster/Alternate/${data.id}`);
 
      if(response.data === ''){
        const remove_delete_data = post.filter(item => item.id !== data.id)
        setPurchaseUomOptions(prev => prev.filter(option => option.value !== uom_id)); 
        setPost(remove_delete_data);
      } 
   } catch(error){
     console.log(error);
   }
    
  }
 

  return (
    <>
       <Modal show={AlternateUomAdd} onHide={() => {
            setUnitSelectListed([])
            // Deselect all rows using the grid API
            setPost([]) 
            // Close the modal or perform other actions as needed
            handleAlternateUomClose();
        }} 
        size="lg"
 >
        <Modal.Header closeButton> 
        </Modal.Header>
        <Modal.Body> 
        <div className="row mb-3">
             <div className="col-6">
              Alternate Unit
             </div>
            
             <div className="col-4">
                  <input
                      type="text" className="form-control"
                      value={values?.label} disabled 
                  /> 
             </div>
          </div>
          <div className="row">
                <StaanTables
                  row = {post}
                  column = {columnDefs}  
                  inputSection = {InputFrom}
                  getDeleteRowId = {getDeleteRowId}
                  isdisabled  =  {isDisabled}
                  deleteIsdisabled = {deleteIsdisabled}
                />
          </div>
          <div className='d-flex justify-content-end' >
            <div className='mx-2 mt-3'>
              <Button variant="outline-secondary"   onClick={() => {
                  // Deselect all rows using the grid API
                  setPost([])
                  // Close the modal or perform other actions as needed
                  handleAlternateUomClose();
                }}>
                  Close
              </Button>
            </div>
            <div className='mt-3'>
              <Button variant="primary" onClick={() => {
                    // Select all rows using the grid API
                
                    const Alternate_uomData = post.map(row => row.id);
                
                    Fullvalue.Alternate_uom = Alternate_uomData; 
                    setPost([])
                    // Close the modal or perform other actions as needed
                    handleAlternateUomClose();
                }}>
              Save  
            </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AlternateUnit;