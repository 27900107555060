 import * as yup from "yup"

export const departmentSchema = yup.object().shape({
    name : yup.string().required('Name is required'),
    department_head_user_id : yup.string().required('department head is required'),
})

export const termsConditionsSchema = yup.object().shape({
    name : yup.string().required('Name is required'),
    tc : yup.string().required('Tc is required'),
    module: yup.string().required('Module is required'),
})


export const otherExpensesSchema = yup.object().shape({
    name : yup.string().required('Name is required'),
    account : yup.number().required('Account is required'),
    HSN: yup.number() 
})