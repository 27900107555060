import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import WaringModel from '../../../components/Warings/WaringModel';
import DataContext from '../../../context/ItemMasterContext';
import { contactValidation } from '../../../GraphQLQueries/SupplierAndCustomerMutation/suplpliercustomermutation';
import { convertToTitleCase, removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import { ContectSchema } from '../../../validations/itemmaster';

const ContactFrom = ({ newContactNumber, setNewContactNumber, setEditContact, EditContact, ContactFromAdd, setcontactFromAdd, setLoad, condition }) => {
  const { setcontactdata, setContanctPost, contanctPost } = useContext(DataContext)
  const [initialValue, setInitialValue] = useState({
    Contact_Person_Name: '',
    Salutation: '',
    Email: '',
    Phone_number: '',
    WhatsappNo: "",
    OtherNo: ""
  })
  const [waringModel, setWaringModel] = useState(false)
  const [edit, setEdit] = useState(true)
  const [isDefault, setDefault] = useState(false)
  const [formKey, setFormKey] = useState(0)

  // useEffect: Initializes or resets the form fields based on the presence of EditContact. If EditContact exists, it pre-fills the form with the contact data, otherwise, it sets initial empty values and disables edit mode. It doesn't return any value. 
  useEffect(() => {
    if (EditContact) {
      setEdit(true)
      setInitialValue(
        {
          index: EditContact['index'],
          Contact_Person_Name: EditContact['Contact_Person_Name'],
          Salutation: EditContact['Salutation'],
          Email: EditContact['Email'],
          Phone_number: EditContact['Phone_number'],
          WhatsappNo: EditContact['WhatsappNo'],
          OtherNo: EditContact['OtherNo'],
          Default: EditContact['Default'],
          id: EditContact['id'],
        }
      )
      console.log("EditContact", EditContact);

      setPersonName(EditContact['Contact_Person_Name'])
      if (EditContact['Salutation']) {
        setSalutation({ value: EditContact['Salutation'], label: convertToTitleCase(EditContact['Salutation']) })
      }

      setEmail(EditContact['Email'])
      setmobileNo(EditContact['Phone_number'])
      setotherNo(EditContact['OtherNo'])
      setWhatsappNo(EditContact['WhatsappNo'])
      setDefault(EditContact['Default'])
      setFormKey(prev => prev + 1)

    } else {
      setInitialValue(
        {
          Contact_Person_Name: '',
          Salutation: '',
          Email: '',
          Phone_number: newContactNumber,
          WhatsappNo: "",
          OtherNo: "",
          Default: false,
          id: ''
        })
      setmobileNo(newContactNumber)
      setFormKey(prev => prev + 1)
      setEdit(false)
    }
  }, [EditContact, newContactNumber])

  // handleSubmit: Submits the form data, validates the contact details, and either updates an existing contact or adds a new one to the contact list. It doesn't return any value.
  const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
    console.log("values", values);
    try {
      let reqBody = {
        id: values?.id ? values?.id : '',
        Number: values?.Phone_number ? values?.Phone_number : ''
      }
      let allow_null = ["id"]
      let clearedValue = removeEmptyValueInObject(reqBody, allow_null)
      const response = await axiosInstance.post("/itemmaster/graphql", { query: contactValidation(clearedValue, allow_null) })
      const responseData = response?.data?.data?.contactValidation
      if (responseData.success) {
        console.log("values--", values);
        console.log("contanctPost", contanctPost);
        // Update contanctPost
        setContanctPost((prevContanctPost) => {
          const existingIndex = prevContanctPost.findIndex(item => item.index === values.index);

          // If Default is true, set other entries' Default to false
          const updatedContanctPost = prevContanctPost.map(item => ({
            ...item,
            Default: values.Default && item.index !== values.index ? false : item.Default,
          }));

          if (existingIndex !== -1) {
            // Update the existing entry
            updatedContanctPost[existingIndex] = { ...updatedContanctPost[existingIndex], ...values };
            return updatedContanctPost;
          } else {
            // Calculate the new index
            const newIndex = prevContanctPost.length > 0
              ? Math.max(...prevContanctPost.map(item => item.index)) + 1
              : 0;

            // Add the new entry with the calculated index
            return [...updatedContanctPost, { ...values, index: newIndex }];
          }
        });

        // Update contactdata
        setcontactdata((prevData) => {
          const updatedData = prevData.map(item => ({
            ...item,
            Default: values.Default && item.id !== values.id ? false : item.Default,
          }));

          const index = updatedData.findIndex(item => item.id === values.id);
          if (index !== -1) {
            // Update the existing entry
            updatedData[index] = values;
            return updatedData;
          } else {
            // Add the new entry at the beginning
            return [values, ...updatedData];
          }
        });

        setcontactFromAdd(false)
        resetForm()
        if (setLoad) {
          setLoad()
        }
        showErrorToast(true, "success", "", `Saved`)

        reset_Form()


      }
    } catch (errors) {
      let DetailsError = ShowErrorNoties(errors)
      showErrorToast(true, "error", `${DetailsError}`)
    }

    // try {
    //   if (values?.Default) {
    //     let address_ids = contanctPost?.map(item => item?.id)
    //     try {
    //       if (EditContact !== "" && EditContact !== undefined) {
    //         address_ids = address_ids.filter(id => id !== EditContact?.['id'])
    //       }
    //       address_ids = address_ids?.map(id => parseInt(id))
    //       let contact_bulk_update_query = `mutation contactFormBulkUpdate{
    //           supplierSubFormDefaultUpdateMutation(name: "ContactDetalis", idList: [${address_ids}]){
    //             success
    //             errors
    //           }
    //         }
    //         `
    //       await axiosInstance.post("/itemmaster/graphql", { query: contact_bulk_update_query })
    //       setContanctPost((prevData) => { return prevData.map((item) => ({ ...item, 'default': false, 'Default': false })) });
    //     } catch (e) {
    //       console.log(e)
    //     }

    //   }

    //   const reqBody = {
    //     contactPersonName: values?.Contact_Person_Name ? values?.Contact_Person_Name : '',
    //     salutation: values?.Salutation === undefined ? '' : values?.Salutation ? values?.Salutation.toUpperCase() : '',
    //     email: values?.Email ? values?.Email : '',
    //     phoneNumber: values?.Phone_number ? values?.Phone_number : '',
    //     whatsappNo: values?.WhatsappNo ? values?.WhatsappNo : '',
    //     otherNo: values?.OtherNo ? values?.OtherNo : '',
    //     default: values?.Default,
    //     id: values.id ? values?.id : ''
    //   }
    //   console.log("reqBody", reqBody);

    //   let allow_null = ["id", "createdBy", "salutation"]
    //   let clearedValue = removeEmptyValueInObject(reqBody, allow_null)
    //   let update_mutate_query = `mutation createContactData{
    //             contactDetailsCreateMutation(${clearedValue}){
    //               success
    //               errors
    //               contactDetailsItem{
    //                 id
    //                 contactPersonName
    //                 email
    //                 default
    //                 phoneNumber
    //                 salutation
    //                 otherNo
    //                 whatsappNo
    //               }
    //             }
    //           }`
    //   const response = await axiosInstance.post("/itemmaster/graphql", { query: update_mutate_query })
    //   const responseData = response?.data?.data?.contactDetailsCreateMutation
    //   if (responseData.success) {
    //     let data = response?.data?.data?.contactDetailsCreateMutation?.contactDetailsItem
    //     console.log("data--", data);
    //     let updated_res_data = { ...data }
    //     updated_res_data['Contact_Person_Name'] = updated_res_data['contactPersonName']
    //     updated_res_data['Email'] = updated_res_data['email']
    //     updated_res_data['Default'] = updated_res_data['default']
    //     updated_res_data['Phone_number'] = updated_res_data['phoneNumber']
    //     updated_res_data['Salutation'] = convertToTitleCase(updated_res_data['salutation'])
    //     updated_res_data['OtherNo'] = updated_res_data['otherNo']
    //     updated_res_data['WhatsappNo'] = updated_res_data['whatsappNo']
    //     setContanctPost(prevData => {
    //       const index = prevData.findIndex(item => item.id === updated_res_data.id); // Find index of object with same ID
    //       if (index !== -1) {
    //         const updatedData = [...prevData];
    //         updatedData[index] = updated_res_data;  // Replace the existing object with the updated one
    //         return updatedData;
    //       } else {
    //         // ID doesn't exist, add the new object
    //         return [updated_res_data, ...prevData];
    //       }
    //     });

    //     setcontactdata(prevData => {
    //       const index = prevData.findIndex(item => item.id === updated_res_data.id); // Find index of object with same ID
    //       if (index !== -1) {
    //         // ID exists, update the object
    //         const updatedData = [...prevData];
    //         updatedData[index] = updated_res_data;  // Replace the existing object with the updated one
    //         return updatedData;
    //       } else {
    //         // ID doesn't exist, add the new object
    //         return [updated_res_data, ...prevData];
    //       }
    //     });
    //     setcontactFromAdd(false)
    //     resetForm()
    //     if (setLoad) {
    //       setLoad()
    //     }
    //     showErrorToast(true, "success", "", `Saved`)

    //     reset_Form()
    //   } else {
    //     let errorData = ShowErrorNoties(responseData.errors)
    //     showErrorToast(true, 'error', errorData)
    //   }
    // }
    // catch (errors) {
    //   let DetailsError = ShowErrorNoties(errors)
    //   showErrorToast(true, "error", `${DetailsError}`)
    // }
  }
  // Salutationoption: Provides a list of options for selecting a salutation (Mr, Ms, Mrs, Dr). It doesn't return any value.
  const Salutationoption = [
    { value: "MR", label: "Mr" },
    { value: "MS", label: "Ms" },
    { value: "MRS", label: "Mrs" },
    { value: "DR", label: "Dr" },
  ]
  // reset_Form: Resets the contact form to initial empty values and clears any previously selected values. It doesn't return any value.
  function reset_Form() {

    setInitialValue(
      {
        Contact_Person_Name: '',
        Salutation: '',
        Email: '',
        Phone_number: '',
      }
    )
    setPersonName('')
    setSalutation('')
    setEmail('')
    setmobileNo('')
    setEditContact('')
    setotherNo('')
    setWhatsappNo('')

    if (setNewContactNumber) {
      setNewContactNumber()
    }
  }

  const [personName, setPersonName] = useState('')
  // handleChangePersonName: Updates the person name state when the user enters a new value. It doesn't return any value.
  const handleChangePersonName = (value) => {
    setPersonName(value)
  }

  const [salutation, setSalutation] = useState('')
  // handleChangeSalutation: Updates the salutation state based on the selected option from the dropdown. It doesn't return any value.
  const handleChangeSalutation = (selectedOption) => {

    setSalutation({ value: selectedOption.value, label: convertToTitleCase(selectedOption.label) })
  }

  const [email, setEmail] = useState('')
  // handleChangeEmail: Updates the email state when the user enters a new value. It doesn't return any value.
  const handleChangeEmail = (value) => {
    setEmail(value)
  }

  const [mobileNo, setmobileNo] = useState('')
  // handleChangemobileno: Updates the mobile number state when the user enters a new value. It doesn't return any value.
  const handleChangemobileno = (value) => {
    setmobileNo(value)
  }

  const [whatsappNo, setWhatsappNo] = useState('')
  // handleChangeWhatsappNo: Updates the Whatsapp number state when the user enters a new value. It doesn't return any value.
  const handleChangeWhatsappNo = (value) => {
    setWhatsappNo(value)
  }

  const [otherNo, setotherNo] = useState('')
  // handleChangeOtherNo: Updates the other number state when the user enters a new value. It doesn't return any value.
  const handleChangeOtherNo = (value) => {
    setotherNo(value)
  }
  // DeleteContact: Deletes the contact entry from the contact list if the user confirms deletion. It doesn't return any value.
  async function DeleteContact(params) {
    if (params === 'yes') {
      console.log("params", EditContact?.index);
      try {
        const updatedContactPost = contanctPost?.filter(contact => contact?.index !== EditContact?.index);
        console.log("Delected Contact", updatedContactPost);

        setContanctPost(updatedContactPost)
        setcontactFromAdd(false)
        reset_Form();

      } catch (error) {
        let errorFilter = ShowErrorNoties(error)
        showErrorToast(true, "error", errorFilter)
      }

    }

  }

  return (
    <>
      <ToastContainer />
      <Modal show={ContactFromAdd} >
        <div className='row mx-1'>
          <div className='col-5 m-3' style={{ color: "#5CADA9" }}>
            <Modal.Title>
              <i className='fa-solid fa-arrow-left fa-sm me-2' onClick={() => {
                setcontactFromAdd(false)
                reset_Form(); setEdit(false)
              }}>
              </i>Contact Form
            </Modal.Title>
          </div>
          <div className="col-6 mt-4 text-end" hidden={!EditContact}>
            <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  "
              onClick={() => { setWaringModel(true) }}
            ></i>
          </div>
        </div>
        <hr className='split_border' />
        <Modal.Body>
          <Formik
            initialValues={initialValue}
            validationSchema={ContectSchema}
            onSubmit={handleSubmit}
            enableReinitialize key={formKey}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                {/* UI Change Starts */}
                <div>
                  <div className='row'>
                    <div className="col-6 staan-input-group">
                      <CustomSelect
                        setSelectEditValue={salutation}
                        options={Salutationoption}
                        name='Salutation'
                        id='Salutation'
                        handleOnChange={(selectedOption) => {
                          console.log();

                          handleChangeSalutation(selectedOption)
                          setFieldValue("Salutation", selectedOption?.value)
                        }}
                        isDisabled={condition ? true : false}
                      />
                      <label className="staan-form-input-label pt-1 px-1 mx-2">Salutation</label>
                    </div>
                    <div className="col-6 staan-input-group">
                      <Field
                        type="text"
                        name="Contact_Person_Name"
                        id="Contact_Person_Name"
                        placeholder=""
                        disabled={condition ? true : false}
                        value={personName}
                        onChange={(e) => {
                          setFieldValue("Contact_Person_Name", e.target.value)
                          handleChangePersonName(e.target.value)
                        }}
                        className="w-100 staan-form-input"
                      />
                      <label htmlFor="Contact_Person_Name" className="staan-form-input-label pt-2 px-1 mx-2" onClick={() => {
                        console.log("values", values);
                      }}>Person Name</label>
                      {touched.Contact_Person_Name && errors.Contact_Person_Name && (
                        <small>{errors.Contact_Person_Name}</small>
                      )}
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className="col-6  staan-input-group">
                      <Field
                        type="email"
                        name="Email"
                        id="Email"
                        placeholder=""
                        disabled={condition ? true : false}
                        value={email}
                        className="w-100 staan-form-input"
                        onChange={(e) => {
                          handleChangeEmail(e.target.value)
                          setFieldValue("Email", e.target.value)
                        }}

                      />
                      <label htmlFor="Email" className="staan-form-input-label pt-2 px-1 mx-2">Email</label>
                      {touched.Email && errors.Email && (
                        <small>{errors.Email}</small>
                      )}
                    </div>
                    <div className="col-6  staan-input-group">
                      <label htmlFor="Phone_number" className="staan-form-input-label pt-2 px-1 mx-2">
                        Mobile Number
                      </label>
                      <Field
                        type="number"
                        name="Phone_number"
                        id="Phone_number"
                        // disabled={condition? true : false}
                        disabled
                        placeholder=""
                        className="w-100 staan-form-input"
                        value={mobileNo}
                        onChange={(e) => {
                          handleChangemobileno(e.target.value)
                          setFieldValue("Phone_number", e.target.value)
                        }}
                      />
                      {touched.Phone_number && errors.Phone_number && (
                        <small>{errors.Phone_number}</small>
                      )}
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className="col-6  staan-input-group">
                      <label htmlFor="Phone_number" className="staan-form-input-label pt-2 px-1 mx-2">
                        Whatsapp Number
                      </label>
                      <Field
                        type="number"
                        name="whatsappNo"
                        id="whatsappNo"
                        disabled={condition ? true : false}
                        placeholder=""
                        className="w-100 staan-form-input"
                        value={whatsappNo}
                        onChange={(e) => {
                          handleChangeWhatsappNo(e.target.value)
                          setFieldValue("WhatsappNo", e.target.value)
                        }}
                      />
                      {touched.WhatsappNo && errors.WhatsappNo && (
                        <small>{errors.WhatsappNo}</small>
                      )}
                      {!edit ? <p style={{ cursor: "pointer", fontSize: '.7rem' }} onClick={() => { handleChangeWhatsappNo(mobileNo); setFieldValue("WhatsappNo", mobileNo) }} className='text-success p-2'><i className="fa-solid fa-clone"></i> Same as Mobile Number</p> : ""}

                    </div>
                    <div className="col-6 staan-input-group">
                      <Field
                        type="number"
                        name="OtherNo"
                        id="OtherNo"
                        placeholder=""
                        disabled={condition ? true : false}
                        className="w-100 staan-form-input"
                        value={otherNo}
                        onChange={(e) => {
                          handleChangeOtherNo(e.target.value)
                          setFieldValue("OtherNo", e.target.value)
                        }}
                      />
                      <label htmlFor="otherNo" className="staan-form-input-label pt-2 px-1 mx-2">Other Number</label>
                      {touched.OtherNo && errors.OtherNo && (
                        <small>{errors.OtherNo}</small>
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className='col-3 staan-input-group d-flex align-items-center'>
                      <div>
                        <Field type="checkbox" name="Default" id="Default" disabled={condition ? true : false} className='me-2' checked={isDefault} onChange={(e) => { setFieldValue('Default', e.target.checked); setDefault(e.target.checked) }} />
                        <label style={{ fontSize: '.8rem' }} className='pb-2'>
                          Default
                        </label>
                      </div>
                    </div>
                    <div className='col-6'></div>
                    <div className="text-end col-2 ms-4">
                      <button type="submit" disabled={condition ? true : false} className="px-3 btn btn-sm shadow-sm btn-outline-success">Save</button>
                    </div>
                  </div>
                </div>
                {/* UI Change ends */}
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {waringModel && (
        <WaringModel
          state={waringModel}
          setstate={setWaringModel}
          title={"Confirm"}
          text={'Confirm To Delete Data?'}
          Confirmtion={value => {
            DeleteContact(value)
          }}

        />

      )}
    </>
  );
};

export default ContactFrom;
