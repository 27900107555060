import html2pdf from 'html2pdf.js';


import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Make sure to import jsPDF and autotable
import logofinal from '../../../img/logo-final.png';

export const handlePdfReportDownload = (fileName, data) => {
  const rowsPerPage = 20; // Set to 10 rows per PDF page
  const totalPages = Math.ceil(data.length / rowsPerPage); // Calculate total pages needed

  const pdf = new jsPDF('portrait', 'pt', 'a4');
  const margin = 20;

  // Define image properties
  const logoWidth = 100; // Adjust width as needed
  const logoHeight = 50; // Adjust height as needed

  for (let page = 0; page < totalPages; page++) {
      const startRow = page * rowsPerPage;
      const endRow = Math.min(startRow + rowsPerPage, data.length);
      
      const tableData = data.slice(startRow, endRow).map(item => [
          item.leadNo,
          item.leadName,
          item.status?.name,
          item.Enquiry?.conferenceData?.name || 'N/A',
          item.Enquiry?.district?.district || 'N/A',
          item.Enquiry?.state?.stateName || 'N/A',
          item.salesPerson?.username,
          item.rextFollowUp || 'N/A',
      ]);

      // Add a new page if it's not the first one
      if (page > 0) {
          pdf.addPage();
      }

      // Add the logo image at the top
      pdf.addImage(logofinal, 'PNG', margin, margin, logoWidth, logoHeight); // Add logo

      // Add the table to the PDF
      pdf.autoTable({
          head: [['Lead Number', 'Lead Name', 'Status', 'Source', 'City', 'State', 'Sales Person', 'Follow Up Date']],
          body: tableData,
          startY: margin + logoHeight + 10, // Position the table below the logo
          margin: { top: margin },
          theme: 'grid',
      });
  }

  pdf.save(`${fileName}.pdf`);
};



export const handlePdfDetailedReportDownload = (fileName) => {
    const table = document.getElementById('pos-table-detailed-report-data');
    table.setAttribute('style', 'fontSize: 10px')
    const opt = {
      margin: 1,
      filename: `${fileName}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'landscape' }
    };
    html2pdf().set(opt).from(table).save();
};

