import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import 'react-datepicker/dist/react-datepicker.css';
import { TbHistory } from "react-icons/tb";
import { Bounce, toast } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import Can from '../../../components/authorizationComponents/Can';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import WaringModel from '../../../components/Warings/WaringModel';
import DataContext from '../../../context/ItemMasterContext';
import { HsnEffectiveDateCreateMutation } from '../../../GraphQLQueries/ItemMastermutation';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { OnchangeGetData, ShowErrorNoties, checkChangesInputs, currentPath, getCurrentDate, removeEmptyValueInObject } from '../../../utils/Utils';
import { itemHsnSchema } from '../../../validations/itemmaster';
import HistoryData from './HistoryData';



const AddHsn = ({ HSNedit, setHsnEdit, setLoad }) => {

  const { ItemHSNAdd, handltoastDeleteConfomationShow, handleHSNClose, userId, prefetchValue, setToastSave, setItemHSNSelect,
    setTax, historyIds, setHistoryIds } = useContext(DataContext)
  const [initialHsn, setInitialHsn] = useState({
    id: "",
    hsn_type: "",
    hsn_code: '',
    Description: "",
    gst_rate: undefined,
    cess_rate: 0,
    rcm: false,
    itc: false,
    modified_by: "",
    createdby: "",
    HistoryDetails: "",
    effectiveDate: ""
  })
  const [hsnTypeOptions, setHsnTypeOptions] = useState([])
  const [formKey, setFormKey] = useState(0);
  const currentURL = currentPath(window.location.href)
  const [edit, setEdit] = useState(true)
  const [deleteData, setdeleteData] = useState({ Name: "", id: "" })
  const url = "/itemmaster/hsn"
  const [selectHsnType, setselectHsnType] = useState()
  const [effectiveDate, setEffectiveDate] = useState();
  const [selectGstRate, setSelectGstRate] = useState()
  const [loading, setLoading] = useState(false)
useEffect(()=>{
console.log(getCurrentDate(),"---->>");
      
      setEffectiveDate(getCurrentDate())
      setInitialHsn(prev=>({...prev,effectiveDate:getCurrentDate()}))
      setFormKey((prevKey) => prevKey + 1);
},[])

  useEffect(() => {
    // return null;  fetch data update input
    async function fetchData(id) {
      if (id) {
        try {
          const response = await axiosInstance.post(`/itemmaster/graphql`, { query: QueryData.HsnEdit(id) });
          const responseData = response?.data?.data?.hsn?.items?.[0]
         
          setInitialHsn({
            id: responseData?.["id"],
            hsn_type: responseData?.['hsnTypes']?.['id'],
            hsn_code: responseData?.['hsnCode'],
            Description: responseData?.['description'],
            gst_rate: responseData?.['gstRates']?.['id'],
            cess_rate: responseData?.['cessRate'] ? responseData?.['cessRate'] : 0,
            rcm: responseData?.['rcm'],
            itc: responseData?.['itc'],
            modified_by: "",
            createdby: responseData?.['createdBy']?.['id'],
            HistoryDetails: responseData?.['historyDetails'],
            effectiveDate: responseData?.['effectiveDate']
          })
          setselectHsnType({ value: responseData?.['hsnTypes']?.['id'], label: responseData?.['hsnTypes']?.['name'] });
          setSelectGstRate({ value: responseData?.['gstRates']?.['id'], label: String(responseData?.['gstRates']?.['rate']) });
          setEffectiveDate("")
          setFormKey((prevKey) => prevKey + 1);
        } catch (error) {
          let errorData = ShowErrorNoties(error)
          showErrorToast(true, "error", errorData, "")
        }

      }
    }
    if (HSNedit !== undefined && HSNedit?.id) {
      setEdit(true)
      fetchData(HSNedit.id)
    } else{
      
    }
  }, [HSNedit])

  // return null; reset the initial value
  function reset_form() {
    if (HSNedit) {
      setHsnEdit('')
    }
    setInitialHsn({
      id: "",
      hsn_type: "",
      hsn_code: '',
      Description: "",
      gst_rate: undefined,
      cess_rate: 0,
      rcm: false,
      itc: false,
      modified_by: "",
      createdby: "",
      HistoryDetails: "",
      effectiveDate: ""
    })

    setselectHsnType({ value: "", label: "" });
    setSelectGstRate({ value: "", label: "" });
    setEffectiveDate(getCurrentDate())
  }

  // return null; Submit the value
  const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
    setLoading(true)
    const save_hsn = {
      id: initialHsn ? initialHsn.id : "",
      hsnTypes: values['hsn_type'] ? Number(values['hsn_type']) : "",
      hsnCode: values['hsn_code'] ? Number(values['hsn_code']) : "",
      description: values['Description'],
      gstRates: values['gst_rate'] ? Number(values['gst_rate']) : "",
      cessRate: values['cess_rate'] ? Number(values['cess_rate']) : 0,
      rcm: Boolean(values['rcm']),
      itc: Boolean(values['itc']),
      modifiedBy: HSNedit ? Number(userId) : "",
      createdBy: HSNedit ? initialHsn.createdby ? Number(initialHsn.createdby) : Number(userId) : Number(userId)
    }
    if (initialHsn?.id) {
      save_hsn['hsnId'] = save_hsn['id']
      save_hsn['id'] = ""
      save_hsn['modifiedBy'] = ""
      save_hsn['hsnTypes'] = ""
      save_hsn['effectiveDate'] = effectiveDate
    }
    let variables = removeEmptyValueInObject(save_hsn)
    const mutation = `
      mutation hsnCreate{
        hsnCreateMutation(`+ variables + `) {
          hsn{
            id
            hsnCode
            gstRates{
              rate
            }
          }
          success
          errors
        }
      }
      `;
    if (HSNedit) {
      try {
        const respones = await axiosInstance.post('/itemmaster/graphql',
          { query: HsnEffectiveDateCreateMutation(variables) });
        if (respones.data.data.hsnEffectiveDateCreateMutation.success) {
          resetForm()
          reset_form()
          handleHSNClose()
          setSubmitting(false)
          if (currentURL === "hsn") {
            setLoad()
          }
        } else {
          let error = respones.data.data.hsnCreateMutation.errors
          let errorData = ShowErrorNoties(error)
          toast.error(errorData, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } catch (errors) {
        let errorData = ShowErrorNoties(errors)
        toast.error(errorData, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } else {
      try {
        const respones = await axiosInstance.post('/itemmaster/graphql',
          { query: mutation });
        if (respones.data.data.hsnCreateMutation.success) {
          const responseData = respones.data.data.hsnCreateMutation.hsn
          setTax(responseData["gstRates"]['rate'])
          setItemHSNSelect({
            id: responseData["id"],
            lable: responseData["hsnCode"]
          })
          setToastSave(true);
          resetForm();
          reset_form()
          setSubmitting(false)
          handleHSNClose()
          setTimeout(() => {
            setToastSave(false);
          }, 3000);

          if (currentURL === "hsn") {
            setLoad()
          }
        } else {
          let error = respones.data.data.hsnCreateMutation.errors

          let errorData = ShowErrorNoties(error)
          toast.error(errorData, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } catch (error) {
        let errorData = ShowErrorNoties(error)
        toast.error(errorData, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }



    }
    setLoading(false)
  }
  // const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
  //   setLoading(true)
  //   console.log("values", values);
  //   const save_hsn = {
  //     id: values?.id ? values?.id : '',
  //     hsnTypes: values['hsn_type'] ? Number(values['hsn_type']) : "",
  //     hsnCode: values['hsn_code'] ? Number(values['hsn_code']) : "",
  //     description: values['Description'],
  //     gstRates: values['gst_rate'] ? Number(values['gst_rate']) : "",
  //     cessRate: values['cess_rate'] ? Number(values['cess_rate']) : 0,
  //     rcm: Boolean(values['rcm']),
  //     itc: Boolean(values['itc']),
  //     modifiedBy: values?.modified_by ? Number(values?.modified_by) : '',
  //     createdBy: values?.createdby ? Number(values?.createdby) : ''
  //   }
  //   let allow_null = ["id", "createdBy", "modifiedBy"]
  //   let clearedValue = removeEmptyValueInObject(save_hsn, allow_null)
  //   if (HSNedit) {
  //     try {
  //       const respones = await axiosInstance.post('/itemmaster/graphql',
  //         { query: HsnEffectiveDateCreateMutation(variables) });
  //       if (respones.data.data.hsnEffectiveDateCreateMutation.success) {
  //         resetForm()
  //         reset_form()
  //         handleHSNClose()
  //         setSubmitting(false)
  //         if (currentURL === "hsn") {
  //           setLoad()
  //         }
  //       } else {
  //         let error = respones.data.data.hsnCreateMutation.errors
  //         let errorData = ShowErrorNoties(error)
  //         showErrorToast(true, 'error', errorData)
  //       }
  //     } catch (errors) {
  //       let errorData = ShowErrorNoties(errors)
  //       showErrorToast(true, 'error', errorData)
  //     }
  //   }
  //   else {
  //     try {
  //       const response = await axiosInstance.post(`/itemmaster/graphql`, {
  //         query: AddHsnCreateMutation(clearedValue, allow_null)
  //       })
  //       const responseData = response?.data?.data?.hsnCreateMutation
  //       console.log("responseData", responseData);

  //       if (responseData.success) {
  //         showErrorToast(true, "success", "", "Expense Categories Created Successfully...")
  //         setLoad()
  //         setCategoriesShow(false)
  //       }
  //       close()

  //     }
  //     catch (error) {
  //       let errorData = ShowErrorNoties(error)
  //       showErrorToast(true, 'error', errorData)
  //     }
  //   }
  //   setLoading(false)
  // }
  const [waringToCloseModel, setWaringToCloseModel] = useState(false)
  let currentValues = {};
  // Confirmation befor close
  const cleaeTheData = () => {
    handleHSNClose()

    reset_form()
    setWaringToCloseModel(false)
  }
  // before close any changes in form it call the Confirmation
  function Close() {
    let isDuplicate = checkChangesInputs([initialHsn, currentValues])
    if (!isDuplicate) {
      handleHSNClose()
      reset_form()
    } else {
      setWaringToCloseModel(true)
    }

  }
  // return null; befor delete call the Confirmation to delete
  const handledeleteConfomation = (data) => {
    handltoastDeleteConfomationShow();
    setdeleteData({
      Name: data?.hsnCode,
      id: data?.id,
      test: "hsn"
    });
  };
  // return null ; after delete close the Form
  const handleDeletedId = (deleted_id) => {
    if (deleted_id) {
      handleHSNClose()
      reset_form()
      setEdit(false)
      setLoad()
    }
  }
  // getting data for react select
  async function getOptions(e, queryName, optionName) {
    let search_term = e.trim().replace(/"/g, '\\"');
    if (queryName === "HsnTypeOptionQueryWithFiliter" && search_term) {
      let responseData = await OnchangeGetData(QueryData[queryName]())
      if (responseData.success) {
        setHsnTypeOptions(responseData.data[optionName]['items'])
      }
    } else {
      setHsnTypeOptions([])
    }

  }

  return (
    <>
      {<BallTriangleLoading isshow={loading} />}
      <HistoryData
        historyIds={historyIds}
        setHistoryIds={setHistoryIds} />
      <Modal show={ItemHSNAdd} onHide={Close}
        backdrop="static"
        keyboard={false}
        size="lg">
        <div className="row">
          <div className="col-5 m-3" style={{ color: "#5CADA9" }}> <Modal.Title>

            <i className='fa-solid fa-arrow-left fa-sm me-2'
              onClick={() => { Close() }}
            ></i>HSN/SAC </Modal.Title></div>
          <div className="col-6 mt-4 text-end" >
            <Can I={"Edit"} a={'HSN'}>
              <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={!(HSNedit && edit)} onClick={() => { setEdit(!edit) }}  ></i>
            </Can>
            <Can I={"Delete"} a={'HSN'}>
              <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={!(HSNedit && edit)} onClick={() => { handledeleteConfomation(HSNedit) }} ></i>
            </Can>
            <Can I={"Historys"} a={'HSN'}>
              <TbHistory type="button" className='fs-4 ms-3' data-bs-toggle="offcanvas" hidden={!(HSNedit && edit)} data-bs-target="#History" onClick={() => { setHistoryIds(initialHsn.HistoryDetails) }} />
            </Can>
          </div>

        </div>
        <hr className='split_border' />
        <Modal.Body>
          <Formik
            initialValues={initialHsn}
            validationSchema={itemHsnSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            key={formKey}>
            {({ errors, touched, setFieldValue, values, isSubmitting }) => {
              currentValues = values;
              return (
                <Form>
                  <div className="row mx-2">
                    <div className="col-6 staan-input-group">
                      <CustomSelect
                        setSelectEditValue={selectHsnType}
                        handleInputChange={(e) => { getOptions(e, "HsnTypeOptionQueryWithFiliter", "hsnType") }}
                        options={hsnTypeOptions?.map((item) => ({ value: item.id, label: item.name }))}
                        name='hsn_type'
                        handleOnChange={(option) => {
                          setFieldValue('hsn_type', option ? option.value : null)
                          setselectHsnType({ value: option?.value, label: option?.label })
                        }}
                        isDisabled={HSNedit}
                      />
                      <label htmlFor="hsn_type" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                        console.log(values);
                      }}>HSN TYPE </label>
                      {touched.hsn_type && errors.hsn_type && <small>{errors.hsn_type}</small>}
                    </div>
                    <div className="col-6  staan-input-group">
                      <Field type='text' name='hsn_code' disabled={HSNedit} className='w-100 staan-form-input' />
                      <label htmlFor="hsn_code" className='staan-form-input-label pt-2 px-1 mx-2'>HSN Code  </label>
                      {touched.hsn_code && errors.hsn_code && <small>{errors.hsn_code}</small>}
                    </div>
                  </div>
                  <div className='row mx-2'>
                    <div className="col-6  staan-input-group">
                      <CustomSelect
                        setSelectEditValue={selectGstRate}
                        handleInputChange={(e) => { getOptions(e, "gstRateOptionQueryWithFiliter", "gstRate") }}
                        options={prefetchValue?.GstRateOptions}
                        name='gst_rate'
                        handleOnChange={(option) => {
                          setFieldValue('gst_rate', option ? option.value : null)
                          setSelectGstRate({ value: option?.value, label: option?.label });
                        }}
                        isDisabled={HSNedit && edit}
                      />
                      <label htmlFor="Gst_rate" className='staan-form-input-label pt-1 px-1 mx-2'>GST RATE </label>
                      {touched.gst_rate && errors.gst_rate && <small>{errors.gst_rate}</small>}
                    </div>
                    <div className="col-6 staan-input-group">
                      <Field type='text' name='cess_rate' disabled={HSNedit && edit} className='w-100 staan-form-input' />
                      <label htmlFor="cess_rate" className='staan-form-input-label pt-2 px-1 mx-2'>CESS RATE</label>
                      {touched.cess_rate && errors.cess_rate && <small>{errors.cess_rate}</small>}
                    </div>
                  </div>
                  <div className="row mx-2">
                    <div className="col-3 mt-4 staan-input-group" data-bs-toggle="tooltip" data-bs-placement="top" title="Reverse Charge Mechanism">
                      <Field type="checkbox" name="rcm" disabled={HSNedit && edit} className='me-2' />
                      <label>RCM</label>
                    </div>
                    <div className="col-3 mt-4 ">
                      <Field type="checkbox" name="itc" disabled={HSNedit && edit} className='me-2' />
                      <label>No ITC</label>
                    </div>
                    <div className="col-6 staan-input-group ">
                      <input
                        type='date'
                        className=' staan-form-input form-control w-100 fs-6'
                        style={{ border: "1px solid rgb(206, 201, 201)", fontSize: '.8rem' }}
                        value={effectiveDate}
                        min={getCurrentDate()}
                        disabled={HSNedit && edit}
                        onChange={e => {
                          setFieldValue('effectiveDate', e.target.value)
                          setEffectiveDate(e.target.value)
                        }}
                      />
                      <label className='staan-form-input-label pt-1 px-1 mx-2'>Effective Date</label>
                      {touched.effectiveDate && errors.effectiveDate && <small>{errors.effectiveDate}</small>}
                    </div>
                  </div>
                  <div className="row mx-2">
                    <div className="col-12  staan-input-group">
                      <Field type='text' as='textarea' style={{ height: "5rem" }} name='Description' disabled={HSNedit && edit} className='w-100 staan-form-input' />
                      <label htmlFor="Description" className='staan-form-input-label pt-1 px-1 mx-2'>Description</label>
                      {touched.Description && errors.Description && <small>{errors.Description}</small>}
                    </div>
                  </div>
                  <div className='row mt-2 mx-2'>
                    <div className='text-end'>
                      <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={() => { Close() }}>Cancel</button>
                      <Can allow_list={["Save_HSN", "Edit_HSN"]}>
                        <button type="submit" disabled={HSNedit && edit ? true : isSubmitting} className="px-3 btn btn-sm btn-outline-success shadow-sm">Save</button>
                      </Can>

                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>

      </Modal>
      {deleteData && <DeleteConformation
        handleDeletedId={(id_) => { handleDeletedId(id_) }}
        deleteData={deleteData}
        url={url}
      />}
      {waringToCloseModel && <WaringModel
        state={waringToCloseModel}
        setstate={setWaringToCloseModel}
        title={'Confirm'}
        text={"Do you want to leave without saving?"}
        Confirmtion={value => {
          if (value) {
            cleaeTheData()
          }
        }}
      />}
    </>
  )
}

export default AddHsn