import * as yup from "yup"

export const EmployeeSchema = yup.object().shape({
    employeeName: yup.string().required("Employee Name is required"),
    department:  yup.number().required("Department is required"),
    email :  yup.string().email("Please Enter Valid Email"),
    present_Address_Line_1 : yup.string().nullable(),
    present_Address_Line_2 : yup.string().nullable(),
    present_City :yup.string().when('present_Address_Line_1', {
        is: (val) => val && val.length > 0, // checks if permanent_Address_Line_1 has a value
        then: (schema) => schema.required("City is required."),
        otherwise: (schema) => schema.notRequired(),
    }),
    present_pincode :yup.string().when('present_Address_Line_1', {
        is: (val) => val && val.length > 0,
        then: (schema) => schema.required("Pincode is required."),
        otherwise: (schema) => schema.notRequired(),
    }),
    present_Country : yup.string().when('present_Address_Line_1', {
        is: (val) => val && val.length > 0,
        then: (schema) => schema.required("Country is required."),
        otherwise: (schema) => schema.notRequired(),
    }),
    present_State :yup.string().when('present_Address_Line_1', {
        is: (val) => val && val.length > 0,
        then: (schema) => schema.required("State is required."),
        otherwise: (schema) => schema.notRequired(),
    }),
    permanent_Address_Line_1 : yup.string().nullable(),
    permanent_Address_Line_2 : yup.string().nullable(),
    permanent_City: yup.string().when('permanent_Address_Line_1', {
        is: (val) => val && val.length > 0, // checks if permanent_Address_Line_1 has a value
        then: (schema) => schema.required("City is required."),
        otherwise: (schema) => schema.notRequired(),
    }),
    permanent_pincode: yup.string().when('permanent_Address_Line_1', {
        is: (val) => val && val.length > 0,
        then: (schema) => schema.required("Pincode is required."),
        otherwise: (schema) => schema.notRequired(),
    }),
    permanent_Country: yup.string().when('permanent_Address_Line_1', {
        is: (val) => val && val.length > 0,
        then: (schema) => schema.required("Country is required."),
        otherwise: (schema) => schema.notRequired(),
    }),
    permanent_State: yup.string().when('permanent_Address_Line_1', {
        is: (val) => val && val.length > 0,
        then: (schema) => schema.required("State is required."),
        otherwise: (schema) => schema.notRequired(),
    }),
 
})

