import { QueryFilter } from "./ItemmasterQuery"

export const CreateStockHistory = (data) => {
    let boolean_fields = [];
    let no_string_fields = ["transactionId", "stockLink", "storeLink", "partNumber", "savedBy", "conference"];
    let mutation_query = `mutation CreateStockHistory{
        stockHistoryCreateMutation(
            `
    mutation_query += QueryFilter(data, boolean_fields, no_string_fields)
    mutation_query +=`
        ){
          success
          errors
        }
    }`
    console.log(mutation_query);
    return mutation_query
}