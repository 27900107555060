import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axiosInstance from '../../api/axoiss'; 
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const DeleteConfirmModal = ({deleteData, isShowDeleteConfirmationModal, handleCloseDeleteConfirmationModal, url, handleDeletedData}) => {

    const handleDeleteData = () => {
        axiosInstance.delete(`${url}/${deleteData['id']}`).then(data => { 
            handleDeletedData(true)
            handleCloseDeleteConfirmationModal()
        }).catch(err => {
            handleDeletedData(false) 
        })
    } 

    return (
     <>
      <ToastContainer/>
        <Modal
        show={isShowDeleteConfirmationModal} 
        backdrop="static"
        keyboard={false}
      > 
        <Modal.Header >
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header >
        <Modal.Body>
          <div className="row fs-5 ms-2 ">
            <div className="col-12">
            <span>Are you sure you want to delete </span> <span className='text-danger'>"{deleteData?.Name}"?.</span> 
            </div>
            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteConfirmationModal}>No</Button>
          <Button variant="primary" onClick={handleDeleteData}>Yes</Button>
        </Modal.Footer>
      </Modal>
      
     </>
  )
}

export default DeleteConfirmModal