import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineCloudUpload, AiOutlinePercentage } from "react-icons/ai";
import { BsArrowUpLeftCircleFill, BsCurrencyRupee } from "react-icons/bs";
import { TbHistory } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { ToastContainer } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import DataContext from '../../../context/ItemMasterContext';
import PreFetchContext from '../../../context/PreFetchContext';
import { getItemMasterWithId, ItemMasterCreate } from '../../../GraphQLQueries/ItemMastermutation';
import { accountsMasterOptionQueryWithFiliter, HsnOptionQueryWithFiliter, onlyForSupplierOption } from '../../../GraphQLQueries/ItemmasterQuery';
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { calculateFinalPrice, customSelectStyle, removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import { ItemMasterSchema } from '../../../validations/itemmaster';
import AddHsn from '../model/AddHsn';
import AddItemGroup from '../model/AddItemGroup';
import AddUom from '../model/AddUom';
import AlternateUnit2 from './AlternateUnit';
import "./itemMaster.css";

const ItemMasterDUP = ({isShow, setIsShow, setLoad, itemMasterBasicData, setItemMasterBasicData}) => {
    const {preFetchData, setPreFetchData} = useContext(PreFetchContext)
    const {  ItemHSNAdd, userId, handlitemGroupShow, ItemGroupSelect, setItemGroupSelect, handlUOMShow, ItemUOMSelect,
        setItemUOMSelect,handlHSNShow, ItemHSNSelect,AccountGroupType, setAccountGroupType} =  useContext(DataContext)
    const [formKey, setFormKey] = useState(0);
    const [initialmaster, setInitialMaster] = useState(
        {
            id:null,
            Item_PartCode: "",
            Item_name: "",
            Description: "",
            Item_Group: null,
            Item_type: null,
            Item_UOM: null,
            Alternate_uom: [],
            category: null,
            location: "",
            Item_Indicator: null,
            Item_Cost: 0,
            Purchase_uom: "",
            note: "",
            variation: "",
            isManufacture: false,
            Item_Safe_Stock: 0,
            Item_Order_Qty: 0,
            Item_Leadtime: 0,
            Item_Purchase_Account: "",
            supplierData:[],
            sell_on_mrp: false,
            Item_Mrp: 0,
            Item_min_price: 0,
            Item_Sales_Account: "",
            Item_HSN: null,
            Keep_stock: false,
            serial: false,
            serial_auto_gentrate: false,
            Serial_format: "",
            Serial_starting: 0,
            Batch_number: false,
            Service: false,
            Warranty_Require: false,
            Item_Warranty_based: '',
            Item_Installation: false,
            Item_Combo: false,
            Item_Combo_data: [],
            Item_Barcode: false,
            Item_Active: true,
            item_qc: false,
            HistoryDetails: "",
            modified_by: "",
            createdby: ""
        })
    const dispatch = useDispatch();
    let [alternateUnit, setAlternateUnit] = useState([])
    const OptionsDataList = useSelector(state => state.itemGroup.data);
    const [itemmasterList, setItemmasterList] = useState({hsnOptions:[],
        salesAccountOptions:[], purchaseUomOptions:[], purchaseAccountOptions:[], companyNameOptions:[]})
    const [itemmasterValues, setItemmasterValues] =  useState({edit:false,
        isProduct:true, type:null, isPercentage : true, ser_pro:{ value: "", label: "" }, Item_Group:{value: "", label: ""}
    ,category:{value: "", label: ""}, uom:{value: "", label: ""}, Item_HSN:{value: "", label: ""}, Tax:"", itemIndicators:
    {value: "", label: ""}, SalesAccount:{value: "", label: ""},Purchaseuom:{value: "", label: ""} ,
    PurchaseAccount:{value: "", label: ""},suppliers:[], Warranty_based:{value: "", label: ""}, isShowAlternateUnit:false})
    const WARRANTY_BASED_OPTIONS = [
        { value: 'Invoice date', label: 'Invoice date' },
        { value: 'Installation date', label: 'Installation date' },
       
    ]
    // from item Master we open other form  after created new data update the in usestate
    useEffect(()=>{
        let itemGroupId = ItemGroupSelect?.id
        let itemGroupLable = ItemGroupSelect?.lable
        if (itemGroupId){
            setItemmasterValues(prev=>({...prev,Item_Group:{value: itemGroupId, label:itemGroupLable}}))
            setInitialMaster(prev=>({...prev, Item_Group:Number(itemGroupId)}))
            let newItemGroupList = [...preFetchData?.itemGroups ,{value: itemGroupId, label:itemGroupLable} ]
            setPreFetchData(prev=>({...prev, itemGroups:newItemGroupList}))
            setItemGroupSelect({value: null, label:null})
            setFormKey(prev=>prev+1)
        }
    },[ItemGroupSelect])

    useEffect(()=>{
        let uomId = ItemUOMSelect?.id
        let uomLable = ItemUOMSelect?.lable
        if (uomId){
            let purchaseUom = itemmasterList?.purchaseUomOptions?.filter(
                (prev) => prev?.value !== itemmasterValues?.uom?.value
            );
            let purchaseUomList = [...purchaseUom, {value: uomId, label:uomLable}];
            let newUOMList = [...preFetchData?.uom,{value: uomId, label:uomLable} ]
            setPreFetchData(prev=>({...prev, uom:newUOMList}))
            setItemmasterList(prev=>({...prev, purchaseUomOptions:purchaseUomList}))
            setItemmasterValues(prev=>({...prev,uom:{value: uomId, label:uomLable},
                Purchaseuom:{value:"", label:""}}))
            setInitialMaster(prev=>({...prev, Item_UOM:Number(uomId), Purchase_uom:null}))
            setItemUOMSelect({value: "", label:""})
            setFormKey(prev=>prev+1)
        }
 

    },[ItemUOMSelect])

    useEffect(()=>{
        let hsnId = ItemHSNSelect?.id
        let hsnLable = ItemHSNSelect?.lable
        if (hsnId){
            setItemmasterValues(prev=>({...prev,Item_HSN:{value:hsnId, label:hsnLable}}))
            setInitialMaster(prev=>({...prev, Item_HSN:Number(hsnId)}))
            setFormKey(prev=>prev+1)
        }

    },[ItemHSNSelect])

    // return null; update all states
    function UpdateStatus(params) {
        
        setItemmasterValues({
        isProduct: params?.itemTypes?.name === "Product" ? true: false,
        ser_pro:{ value: params?.itemTypes?.id, label: params?.itemTypes?.name},
        Item_Group:{value: params?.itemGroup?.id, label:params?.itemGroup?.name},
        category:{value: params?.category?.id, label:params?.category?.name},
        uom:{value: params?.itemUom?.id, label:params?.itemUom?.name},
        Item_HSN:{value: params?.itemHsn?.id, label: params?.itemHsn?.hsnCode},
        Tax:params?.itemHsn?.gstRates?.rate,
        itemIndicators:{value: params?.itemIndicators?.id, label:params?.itemIndicators?.name},
        Purchaseuom:{value: params.purchaseUom?.id, label: params.purchaseUom?.name},
        PurchaseAccount:{value: params.itemPurchaseAccount?.id, label: params.itemPurchaseAccount?.accountsName},
        SalesAccount:{value: params.itemSalesAccount?.id, label: params.itemSalesAccount?.accountsName},
        suppliers:params?.supplierData?.length > 0 ? params?.supplierData?.map((prev)=>({value:prev?.id , label:prev?.companyName})):[],
        Warranty_based: params?.itemWarrantyBased === "INSTALLATION_DATE" ?
        { value: "Installation date", label: "Installation date" }:
        { value: "Invoice date", label: "Invoice date" }
        })
        let isWarrantyRequire = false
        if (params?.['itemWarrantyBased']) {
            isWarrantyRequire = true
        }
        let supplier_id;
        if (Array.isArray(params?.supplierData)) {
            supplier_id = params?.supplierData.map((prev) => prev?.id);
        } else{
            supplier_id = []
        }
        setInitialMaster({
            id: Number(params?.id),
            Item_PartCode: params?.itemPartCode,
            Item_name: params?.itemName,
            Description: params?.description,
            Item_type: params?.itemTypes?.id ? Number(params?.itemTypes?.id): null,
            Item_Group: params?.itemGroup?.id ? Number(params?.itemGroup?.id) : null,
            category: params?.category?.id ? Number(params?.category?.id): null ,
            Item_UOM: params?.itemUom?.id ? Number(params?.itemUom?.id) : null,
            Alternate_uom: [],
            Item_HSN: params?.itemHsn?.id ? Number(params?.itemHsn?.id) : null,
            Item_Indicator: params?.itemIndicators?.id ? Number(params?.itemIndicators?.id) : null,
            Keep_stock: params?.keepStock,
            Item_Active: params?.itemActive,
            Item_Cost: params?.itemCost,
            variation: params?.variation,
            Purchase_uom: params.purchaseUom?.id,
            Item_Purchase_Account: params.itemPurchaseAccount?.id,
            supplierData:supplier_id,
            sell_on_mrp: params?.sellOnMrp,
            Item_Mrp: params?.itemMrp,
            Item_min_price:params?.itemMinPrice,
            Item_Sales_Account: params?.itemSalesAccount?.id,
            serial: params?.serial,
            serial_auto_gentrate: params?.serialAutoGentrate,
            Serial_format: params?.serialFormat,
            Serial_starting: params?.serialStarting,
            Batch_number: params?.batchNumber,
            Item_Barcode: params?.itemBarcode,
            location: params?.location,
            Item_Safe_Stock: params?.itemSafeStock,
            Item_Order_Qty: params?.itemOrderQty,
            Item_Leadtime: params?.itemLeadTime,
            Service: params?.service,
            Warranty_Require: isWarrantyRequire,
            Item_Warranty_based: params?.itemWarrantyBased === "INSTALLATION_DATE" ? "Installation date":"Invoice date",
            Item_Installation: params?.itemInstallation,
            isManufacture: params?.isManufacture,
            item_qc: params?.itemQc,
            note: params?.notes,
            createdby: params?.createdBy?.id ? Number(params?.createdBy?.id) : null,
            
            
            Item_Combo: false,
            Item_Combo_data: [],
            
          
            HistoryDetails: "", 
            
        })
        setFormKey(prev=>prev+1)
        // setItemmasterValues
    }
    // return null; onload id not null it get data pass to UpdateStatus
    useEffect(()=>{
        async function fetchEditData(id){
            try {
                const response = await axiosInstance.post(`/itemmaster/graphql`, { query: getItemMasterWithId(id) });
                let responseData = response?.data?.data?.itemMaster?.items?.[0]
                UpdateStatus(responseData)
            } catch (error) {
                console.log(error);
                
                
            }
           
            
            

        }
        if (isShow && itemMasterBasicData?.editData){
            fetchEditData(itemMasterBasicData?.editData)
        }
        // itemMasterBasicData
    },[isShow])

    //return null; update the list options
    useEffect(()=>{
        if(OptionsDataList?.name === "Hsn"){
            setItemmasterList(prev=>({...prev, hsnOptions:OptionsDataList?.value?.items?.map((item) => ({ value: item.id, label: String(item.hsnCode), gst_rate: item.gstRates?.rate }))}))
        } else if(OptionsDataList?.name === "SalesAccount"){
            setItemmasterList(prev=>({...prev, salesAccountOptions:OptionsDataList?.value?.items?.map((item) => ({ value: item.id, label:(item.accountsName)}))}))
        } else if(OptionsDataList?.name === "PurchaseAccount"){
            setItemmasterList(prev=>({...prev, purchaseAccountOptions:OptionsDataList?.value?.items?.map((item) => ({ value: item.id, label:(item.accountsName)}))}))
        } else if(OptionsDataList?.name === "companyName"){
            setItemmasterList(prev=>({...prev, companyNameOptions:OptionsDataList?.value?.items?.map((item) => ({ value: item.id, label:(item.companyName)}))}))
        }
        // 
    },[OptionsDataList])

    //return null; close functions
    function Close(params) {
        setItemMasterBasicData(null)
        setIsShow(false)
        setItemmasterValues({edit:false,
            isProduct:true, type:null, isPercentage : true, ser_pro:{ value: "", label: "" }, Item_Group:{value: "", label: ""}
        ,category:{value: "", label: ""}, uom:{value: "", label: ""}, Item_HSN:{value: "", label: ""}, Tax:"", itemIndicators:
        {value: "", label: ""}, SalesAccount:{value: "", label: ""},Purchaseuom:{value: "", label: ""} , PurchaseAccount:{value: "", label: ""},
        suppliers:[], Warranty_based:{value: "", label: ""}})
        setInitialMaster({
            Item_PartCode: "",
            Item_name: "",
            Description: "",
            Item_Group: null,
            Item_type: null,
            Item_UOM: null,
            Alternate_uom: [],
            category: null,
            location: "",
            Item_Indicator: null,
            Item_Cost: 0,
            Purchase_uom: "",
            note: "",
            variation: "",
            isManufacture: false,
            Item_Safe_Stock: 0,
            Item_Order_Qty: 0,
            Item_Leadtime: 0,
            Item_Purchase_Account: "",
            sell_on_mrp: false,
            Item_Mrp: 0,
            Item_min_price: 0,
            Item_Sales_Account: "",
            Item_HSN: null,
            Keep_stock: false,
            serial: false,
            serial_auto_gentrate: false,
            Serial_format: "",
            Serial_starting: 0,
            Batch_number: false,
            Service: false,
            Warranty_Require: false,
            Item_Warranty_based: '',
            Item_Installation: false,
            Item_Combo: false,
            Item_Combo_data: [],
            Item_Barcode: false,
            Item_Active: true,
            item_qc: false,
            HistoryDetails: "",
            modified_by: "",
            createdby: ""
        })
    }

    // return NULL;submit functions
    const handleSubmit= async(values, { setSubmitting, resetForm })=>{
        let errorlist = []
        const formateRegex = /#\d+$/;
    if (values?.['serial'] && !formateRegex.test(values?.['Serial_format'])) {
      showErrorToast(true, 'error', `Incorrect Format. It should end with #<number>.`, '');
      return "";
    }
    if (itemmasterValues?.itemIndicators?.label === "Sell" || itemmasterValues?.itemIndicators?.label ==="Both"){
        if( Number(values?.['Item_Mrp']) <= 0){
            errorlist.push("Max Selling Price is required") 
        }
        if ( Number(values?.['Item_min_price']) <= 0){
            errorlist.push("Min Selling Price is required")
        }
        if (values?.['Item_Sales_Account'] === null || values?.['Item_Sales_Account'] === ""){
            errorlist.push("Item Sales Account is required")
        }
    }
    if (itemmasterValues?.itemIndicators?.label === "Buy"|| itemmasterValues?.itemIndicators?.label ==="Both"){
        if( Number(values?.['Item_Cost']) <= 0){
            errorlist.push("Item Cost is required")
        } 
        if (values?.['Purchase_uom'] == null || values?.['Purchase_uom']?.trim() === ""){
            errorlist.push("Purchase UOM is required")
        } 
        if (values?.['Item_Purchase_Account'] == null || values?.['Item_Purchase_Account']?.trim() === ""){
            errorlist.push("Item Purchase Account is required")
        } 
        if (values?.['variation'] == null || values?.['variation']?.trim() === ""){
            errorlist.push("Variation is required")
        }
    }
    
    if (errorlist?.length > 0){
        console.log("errorlist", errorlist);
        showErrorToast(true, "error",  errorlist?.toString())
        return ""
    } else{
        const save_itemmaster = {
            "id":values?.id ?  values?.id : null,
            "itemPartCode": values['Item_PartCode'],
            "itemName": values['Item_name'],
            "description": values['Description'] ? values['Description'] : `${values['Item_PartCode']}-${values['Item_name']}`,
            "itemTypes": Number(values['Item_type']),
            "itemGroup": Number(values['Item_Group']),
            "category": Number(values['category']),
            "itemUom": Number(values['Item_UOM']),
            "itemHsn": Number(values['Item_HSN']),
            "alternateUom": values['Alternate_uom']?.length > 0 ? values['Alternate_uom'] : [],
            "itemIndicators": values['Item_Indicator'] ? Number(values['Item_Indicator']) : "",
            "keepStock": values?.['Keep_stock'],
            "itemActive": values['Item_Active'],
            "itemCost": values['Item_Cost'] ? (values['Item_Cost']) : 0,
            "variation": values['variation'],
            "purchaseUom": values['Purchase_uom'] ? Number(values['Purchase_uom']) : "",
            "itemPurchaseAccount": values['Item_Purchase_Account'] ? Number(values['Item_Purchase_Account']) : "",
            "supplierData": values['supplierData']?.length > 0 ? values['supplierData'] : [],
            "itemMrp": values['Item_Mrp'] ? Number(values['Item_Mrp']) : 0,
            "itemMinPrice": values['Item_min_price'] ? Number(values['Item_min_price']) : 0,
            "itemSalesAccount": values['Item_Sales_Account'] ? Number(values['Item_Sales_Account']) : "",
            "sellOnMrp": values['sell_on_mrp'] !== undefined ? values['sell_on_mrp'] : false,
            "serial": values['serial'],
            "serialAutoGentrate": values['serial_auto_gentrate'],
            "serialFormat": values['Serial_format'] ? values['Serial_format'] : "",
            "serialStarting": values['Serial_starting'] ? Number(values['Serial_starting']) : 0,
            "batchNumber": values['Batch_number'],
            "itemBarcode": values['Item_Barcode'],
            "location": values['location'],
            // "productImage": imageId !== null ? Number(imageId) : "",
            // "productDocument": docId,
            // "totalStock": values['Total_Stock'] ? Number(values['Total_Stock']) : 0,
            // "rejectedStock": values['Rejected_Stock'] ? Number(values['Rejected_Stock']) : 0,
            "itemSafeStock": values['Item_Safe_Stock'] ? Number(values['Item_Safe_Stock']) : 0,
            "itemOrderQty": values['Item_Order_Qty'] ? Number(values['Item_Order_Qty']) : 0,
            "itemLeadTime": values['Item_Leadtime'] ? Number(values['Item_Leadtime']) : 0,
            "service": values['Service'],
            "itemWarrantyBased": values['Item_Warranty_based'] !== undefined ? values['Item_Warranty_based'] : "",
            "itemInstallation": values['Item_Installation'] !== undefined ? values['Item_Installation'] : false,
            // "invoiceDate": values['Invoicedate'] !== undefined ? values['Invoicedate'] : "",
            // "installationData": values['installation_data'] !== undefined ? values['installation_data'] : "",
            "itemComboBool": values['Item_Combo'] !== undefined ? values['Item_Combo'] : false,
            'itemComboData': values['Item_Combo_data'].length > 0 ? (values['Item_Combo_data']) : [],
            "isManufacture": values['isManufacture'],
            "itemQc": values['item_qc'],
            "notes": values['note'],
            "createdBy": values?.id ? Number(values?.createdby) :  Number(userId),
            // "itemComboPrint": isItemComboPrint,
            // "alternateUomFixed": isAlternateUomFixed,
            
        } 
        
        let allow_null = ["id","itemCost","variation","purchaseUom","itemPurchaseAccount", "supplierData",
            "itemMrp","itemMinPrice","itemSalesAccount","serialFormat","serialStarting","location",
            "totalStock","serialStarting", "itemSafeStock", "itemOrderQty","itemLeadTime","itemWarrantyBased",
            "notes",
        ]
        try{
            let clearedValue = removeEmptyValueInObject(save_itemmaster,allow_null,["notes"] )
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: ItemMasterCreate(clearedValue) });
            const responseData = response?.['data']?.['data']?.['itemMasterCreateMutation']
            if (responseData.success){
                Close()
                setLoad()
                showErrorToast(true, "success", "saved successfully.")
            } else{
                let errors = ShowErrorNoties(responseData.errors)
                showErrorToast(true, "error", errors)
            }
        } catch(error){
            let errors = ShowErrorNoties(error)
            showErrorToast(true, "error", errors)
            
        }
    }
    }
    

    return (
        <>
        <ToastContainer/>
            <Modal show={isShow} fullscreen  onHide={Close}>
                <div className=''>
                    <Formik 
                    enableReinitialize={true}
                    key={formKey}
                    initialValues={initialmaster}
                    validationSchema={ItemMasterSchema}
                    onSubmit={handleSubmit}>
                        {({errors,touched,   values, setFieldValue, setValues})=>{
                            return (
                            <>
                                <Form>
                                    <div className="itemMasterContantWarp bg-light ">
                                        <div className='d-flex justify-content-center'>
                                            <head className="row card" >
                                                <div className="col-12 fw-bold fs-4"  style={{ color: "#5CADA9" }} >
                                                    <i
                                                        className='fa-solid fa-arrow-left fa-sm me-2'
                                                        onClick={Close}
                                                    ></i>
                                                    Item Master
                                                </div>
                                            </head>
                                        </div>
                                        {/* start item Master Inputs */}
                                        
                                        <div className="row itemMasterInputWarp">
                                            <div className=" col-md-12 col-lg mx-1">
                                                <div className='card px-3 py-2'>
                                                    <div className="row " >
                                                        <div className="col-12 p-1">
                                                            <div className='border-bottom' onClick={()=>{console.log(itemmasterValues, values);
                                                        }}>
                                                                <h6 className='mx-3'  >Basic Details</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 staan-input-group">
                                                            <Field type='text' id='Part Code' name='Item_PartCode' disabled={itemmasterValues?.edit}   placeholder='' className='w-100 staan-form-input' />
                                                            <label htmlFor="Part Code" className='staan-form-input-label pt-0 px-1 mx-2' >Part Code<span className='text-danger'>*</span></label>
                                                            {touched?.Item_PartCode && errors?.Item_PartCode && <small className="staan-error-msg">{errors?.Item_PartCode}</small>}
                                                        </div>
                                                        <div className="col-6 staan-input-group">
                                                            <Field type='text' id="Part Name" name='Item_name' disabled={itemmasterValues?.edit}  placeholder='' className='w-100 staan-form-input' />
                                                            <label htmlFor="Part Name" className='staan-form-input-label pt-0 px-1 mx-2'>Part Name <span className='text-danger'>*</span></label>
                                                            {touched?.Item_name && errors?.Item_name && <small className="staan-error-msg">{errors?.Item_name}</small>}
                                                        </div>
                                                        <div className="col-12 staan-input-group">
                                                            <Field type='text' id='Description' name='Description' disabled={itemmasterValues?.edit} placeholder='' className='w-100 staan-form-input' />
                                                            <label htmlFor="Description" className='staan-form-input-label pt-0 px-1 mx-2'>Description</label>
                                                            {touched?.Description && errors?.Description && <small className="staan-error-msg">{errors?.Description}</small>}
                                                        </div>
                                                        <div className="col-6  staan-input-group">
                                                            <CustomSelect
                                                                setSelectEditValue={itemmasterValues?.ser_pro}
                                                                options={preFetchData?.itemTypes}
                                                                name='pro_ser'
                                                                id='pro_ser'
                                                                handleOnChange={(option) => {
                                                                    let pro_ser = option?.label === "Product"
                                                                    setItemmasterValues(prev=>({...prev, ser_pro:option? option : null, isProduct:pro_ser }))
                                                                    setFieldValue('Item_type', option ? Number(option?.value) : null);
                                                                    setFieldValue('Keep_stock', option?.label === 'Product' ?true : false);
                                                                }}
                                                            />
                                                            <label htmlFor="pro_ser" className='staan-form-input-label pt-0 px-1 mx-2'>Product/Service<span className='text-danger'>*</span></label>
                                                            {touched?.Item_type && errors?.Item_type && <small className="staan-error-msg">{errors?.Item_type}</small>}
                                                        </div>
                                                        <div className="col-6 staan-input-group">
                                                            <CustomSelect
                                                                setSelectEditValue={itemmasterValues?.Item_Group}
                                                                options={preFetchData?.itemGroups}
                                                                name='Item_Group'
                                                                id='Item_Group'
                                                                handleOnChange={(option) => {
                                                                    setFieldValue('Item_Group', option ? Number(option?.value) : null)
                                                                    setItemmasterValues(prev=>({...prev, Item_Group:option ? option: null}))
                                                                }}
                                                                isDisabled={itemmasterValues?.edit}
                                                            />
                                                            <label htmlFor="Item_Group_" className='staan-form-input-label pt-0 px-1 mx-2'>Item Group<span className='text-danger'>*</span>
                                                            <i role="button" className='bx bx-plus-circle' onClick={handlitemGroupShow} ></i></label>
                                                            {touched.Item_Group && errors.Item_Group && <small className="staan-error-msg">{errors.Item_Group}</small>}
                                                        </div>
                                                        <div className="col-6  staan-input-group">
                                                            <CustomSelect
                                                                setSelectEditValue={itemmasterValues?.category}
                                                                options={preFetchData?.categories}
                                                                name='category'
                                                                id='category'
                                                                handleOnChange={(option) => {
                                                                    setFieldValue('category', option ? Number(option?.value) : null);
                                                                    setItemmasterValues(prev=>({...prev,category:option ? option : null}))
                                                                }}
                                                                isDisabled={itemmasterValues?.edit}
                                                            />
                                                            <label className='staan-form-input-label pt-0 px-1 mx-2'>Category<span className='text-danger'>*</span></label>
                                                            {touched?.category && errors?.category && <small className="staan-error-msg">{errors?.category}</small>}
                                                        </div>
                                                        <div className="col-6 staan-input-group">
                                                            <CustomSelect
                                                                setSelectEditValue={itemmasterValues?.uom}
                                                                options={preFetchData?.uom}
                                                                name='Item_UOM'
                                                                id='Item_UOM'
                                                                handleOnChange={(option) => {
                                                                    if (option){
                                                                        let purchaseUom = itemmasterList?.purchaseUomOptions?.filter(
                                                                            (prev) => prev?.value !== itemmasterValues?.uom?.value
                                                                        );
                                                                        let purchaseUomList = [...purchaseUom, option];
                                                                        setItemmasterList(prev=>({...prev, purchaseUomOptions:purchaseUomList}))
                                                                    }
                                                                    setValues(prev=>({...prev,"Item_UOM":option ? Number(option?.value) : null , 
                                                                        Purchase_uom:null}))
                                                                    setItemmasterValues(prev=>({...prev,uom:option ? option : null,
                                                                        Purchaseuom:{value:"", label:""}}))
                                                                }}
                                                            />
                                                            <label htmlFor="Item_UOM" className='staan-form-input-label pt-0 px-1 mx-2'>UOM <span className='text-danger'>*</span>
                                                            <i role='button' className='bx bx-plus-circle' onClick={handlUOMShow} ></i> </label>
                                                            <div className="d-flex justify-content-between">
                                                                <small>{touched.Item_UOM && errors.Item_UOM && <small>{errors.Item_UOM}</small>}</small>
                                                                <div className="text-end">
                                                                    <button type="button"
                                                                        id='Item_Alternate_UOM'
                                                                        className="btn btn-sm btn-outline-secondary mt-2 text-primary "
                                                                        disabled={!itemmasterValues?.uom?.value}
                                                                        onClick={() => {setItemmasterValues(prev=>({...prev, isShowAlternateUnit:true}))}}
                                                                        >Alternate UOM</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 staan-input-group">
                                                            <CustomSelect
                                                                setSelectEditValue={itemmasterValues?.Item_HSN}
                                                                handleInputChange={(e) => {
                                                                    let search_term =  (e)?.trim()
                                                                        if (search_term){
                                                                            let hsn_query_argument = `hsnCode: ${search_term}, `
                                                                            if (itemmasterValues?.type?.label === "Service") {
                                                                                hsn_query_argument += ` hsnTypes:"SAC"`
                                                                            }
                                                                            dispatch(fetchOptionsData(HsnOptionQueryWithFiliter(hsn_query_argument),"Hsn"))
                                                                        }
                                                                }}
                                                                options={itemmasterList?.hsnOptions}
                                                                name='Item_HSN'
                                                                id='Item_HSN'
                                                                handleOnChange={(option) => {
                                                                    setFieldValue('Item_HSN', option ? Number(option?.value) : null)
                                                                    // if (option) {
                                                                    //     setIsChangeHsn(true)
                                                                    // }
                                                                    setItemmasterValues(prev=>({...prev, Item_HSN:option?option : null, Tax:option ? option?.gst_rate : null}))
                                                                }}
                                                                isDisabled={itemmasterValues?.edit}
                                                            />
                                                            <label htmlFor="Item_HSN" className='staan-form-input-label pt-1 px-1 mx-2'>HSN <span className='text-danger'>*</span>
                                                            <i role="button" className='bx bx-plus-circle' onClick={handlHSNShow} ></i>
                                                            </label>
                                                            {touched?.Item_HSN && errors?.Item_HSN && <small className="staan-error-msg">{errors?.Item_HSN}</small>}
                                                        </div>
                                                        <div className="col-3 staan-input-group" >
                                                            {/* <input
                                                                // hidden={!isChangeHsn}
                                                                type='date'
                                                                className=' staan-form-input form-control w-100 fs-6'
                                                                style={{ border: "1px solid rgb(206, 201, 201)", fontSize: '.8rem' }}
                                                                // value={effectiveDate}
                                                                // min={getCurrentDate()}
                                                                // onChange={e => {
                                                                //     if (e.target.value) {

                                                                //         setEffectiveDate(e.target.value)
                                                                //     }
                                                                // }}
                                                            />
                                                            <label className='staan-form-input-label pt-1 px-1 mx-2' 
                                                            // hidden={!isChangeHsn}
                                                            >Effective Date</label> */}

                                                        </div>
                                                        <div className="col-6 staan-input-group">
                                                            <input type="number" id='Tax' className='staan-form-input' 
                                                            value={itemmasterValues?.Tax} 
                                                            disabled />
                                                            <label htmlFor="Tax" className='staan-form-input-label pt-2 px-1 mx-2'>Tax</label>
                                                        </div>
                                                        <div className="col-5 staan-input-group">
                                                            <CustomSelect
                                                                setSelectEditValue={itemmasterValues?.itemIndicators}
                                                                options={preFetchData?.itemIndicators}
                                                                name='Item_Indicator'
                                                                id='Item_Indicator'
                                                                handleOnChange={(option) => {
                                                                    setFieldValue('Item_Indicator', option ? Number(option?.value) : null)
                                                                    if (option && option?.label === 'Buy') { 
                                                                        setItemmasterValues(prev=>({...prev, type:option, itemIndicators:option}))
                                                                    } else if (option && option?.label === 'Sell') {
                                                                        setItemmasterValues(prev=>({...prev, type:"Sell", itemIndicators:option}))
                                                                    } else if (option && option?.label === 'Both') {
                                                                        setItemmasterValues(prev=>({...prev, type:"Both", itemIndicators:option}))
                                                                    } else if (option && option?.label === 'None') {
                                                                        setItemmasterValues(prev=>({...prev, type:null, itemIndicators:option?option:null}))
                                                                    } else{
                                                                        setItemmasterValues(prev=>({...prev, type:null, itemIndicators:option?option:null}))
                                                                    }
                                                                }}
                                                                isDisabled={itemmasterValues?.edit}
                                                            />
                                                            <label htmlFor="Item_Indicator" className='staan-form-input-label pt-1 px-1 mx-2' onClick={()=>{console.log(itemmasterValues?.type);
                                                            }}>Buy/Sell<span className='text-danger'>*</span></label>
                                                            {touched.Item_Indicator && errors.Item_Indicator && <small className="staan-error-msg">{errors.Item_Indicator}</small>}
                                                        </div>
                                                        <div className='col-7 d-flex justify-content-between align-items-center'>
                                                            <div className="col-6 text-center" style={!itemmasterValues?.isProduct ? { display: "none" } : {}}>
                                                                <Field type="checkbox" name="Keep_stock" disabled={itemmasterValues?.edit} className='me-2' />
                                                                <label style={{ fontSize: '.8rem' }} className='pb-1'>Keep Stock</label>
                                                                <br />
                                                                {errors.Keep_stock && <small className="staan-error-msg">{errors.Keep_stock}</small>}
                                                            </div>
                                                            <div className="col-6 text-end">
                                                                <Field type="checkbox" name="Item_Active" disabled={itemmasterValues?.edit} className='me-2' />
                                                                <label style={{ fontSize: '.8rem', top: '5px' }} className='pb-1'>Item Active</label>
                                                                <br />
                                                                {errors.Item_Active && <small className="staan-error-msg">{errors.Item_Active}</small>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card px-1 py-2 my-3' style={itemmasterValues?.itemIndicators?.label === "Not Applicable" || itemmasterValues?.type ===null  ? { display: "none" } : { height:"auto", display: "block"}}>
                                                    <div className="pt-1 mx-1 border-bottom custom-bottom-shadow">
                                                        <h6 className='mx-3 pt-2'  >Price & Procurement Details</h6>
                                                    </div>
                                                    <div hidden={itemmasterValues?.itemIndicators?.label === "Buy"}>
                                                        <div className='row mx-2'>
                                                            <div className="col-6 staan-input-group">
                                                                <Field type='text' name='Item_Mrp' disabled={itemmasterValues?.edit} className='w-100 staan-form-input' />
                                                                <label htmlFor="Item_Mrp" className='staan-form-input-label pt-0 px-1 mx-2'>Max Selling Price<span className='text-danger'>*</span></label>
                                                                {touched.Item_Mrp && errors.Item_Mrp && <small>{errors.Item_Mrp}</small>}
                                                            </div>
                                                            <div className="col-6 staan-input-group">
                                                                <Field type='text' name='Item_min_price' disabled={itemmasterValues?.edit} className='w-100 staan-form-input' />
                                                                <label htmlFor="Item_min_price" className='staan-form-input-label pt-0 px-1 mx-2'>Min Selling Price<span className='text-danger'>*</span></label>
                                                                {touched.Item_min_price && errors.Item_min_price && <small>{errors.Item_min_price}</small>}
                                                            </div>
                                                        </div>
                                                        <div className='row mx-2 my-2'>
                                                            <div className="col-6 staan-input-group">
                                                                <CustomSelect
                                                                    isClearable
                                                                    setSelectEditValue={itemmasterValues?.SalesAccount}
                                                                    handleInputChange={(e) => {
                                                                        let search_term = e.trim()
                                                                        if (search_term){
                                                                            dispatch(fetchOptionsData(accountsMasterOptionQueryWithFiliter(search_term, "Asset", "Income"),  "SalesAccount"))
                                                                        } else{
                                                                            setItemmasterList(prev=>({...prev, salesAccountOptions:[]}))
                                                                        }
                                                                    }}
                                                                    options={itemmasterList?.salesAccountOptions}
                                                                    name='Item_Sales_Account'
                                                                    id='Item_Sales_Account'
                                                                    handleOnChange={(option) => {
                                                                        setFieldValue('Item_Sales_Account', option ? Number(option?.value) :null)
                                                                        setItemmasterValues(prev=>({...prev, SalesAccount:option?option:null}))
                                                                    }}
                                                                    isDisabled={itemmasterValues?.edit} />
                                                                <label htmlFor="Item_Sales_Account" className='staan-form-input-label pt-0 px-1 mx-2'>Item Sales Account<span className='text-danger'>*</span></label>
                                                                {touched.Item_Sales_Account && errors.Item_Sales_Account && <small>{errors.Item_Sales_Account}</small>}
                                                            </div>
                                                            <div className="col-3 mt-4 text-start">
                                                                <span style={{ fontSize: '.8rem' }} name="random_text">
                                                                    {values.sell_on_mrp && 'MRP: ' + calculateFinalPrice(values.Item_Mrp, itemmasterValues?.Tax)}
                                                                    </span>
                                                            </div>
                                                            <div className='col-3 text-end mt-3'>
                                                                <label style={{ fontSize: '.8rem', top: '5px' }} className='pb-1'>Sell on MRP</label>
                                                                <Field type="checkbox" name="sell_on_mrp" disabled={itemmasterValues?.edit} checked={values.sell_on_mrp} className='ms-3' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div hidden={itemmasterValues?.itemIndicators?.label=== "Sell"}>
                                                        <div className='row mx-2'>
                                                            <div className="col-3 staan-input-group">
                                                                <Field type='text' name='Item_Cost' disabled={itemmasterValues?.edit} className='w-100 staan-form-input' />
                                                                <label htmlFor="Item_Cost" className='staan-form-input-label pt-0 px-1 mx-2'>Item Cost<span className='text-danger'>*</span> </label>
                                                                {touched.Item_Cost && errors.Item_Cost && <small>{errors.Item_Cost}</small>}
                                                            </div>
                                                            <div className="col-2 staan-input-group">
                                                                <Field type='text' name='variation'
                                                                className='w-100 staan-form-input ' />
                                                                <label htmlFor="variation" className='staan-form-input-label pt-0 px-1 mx-2'>variation<span className='text-danger'>*</span> </label>
                                                                {touched.variation && errors.variation && <small>{errors.variation}</small>}
                                                            </div>
                                                            <div className="col-1 mt-4 text-center">
                                                                {itemmasterValues?.isPercentage ?
                                                                    <BsCurrencyRupee onClick={() => {
                                                                        setItemmasterValues(prev=>({...prev, isPercentage:false}))
                                                                    }} />
                                                                    :
                                                                    <AiOutlinePercentage onClick={() => {
                                                                        setItemmasterValues(prev=>({...prev, isPercentage:true}))
                                                                    }} />
                                                                }
                                                            </div>
                                                            <div className="col-6 staan-input-group">
                                                                <CustomSelect
                                                                    setSelectEditValue={itemmasterValues?.Purchaseuom}
                                                                    options={itemmasterList?.purchaseUomOptions}
                                                                    name='Purchase_uom'
                                                                    id='Purchase_uom'
                                                                    handleOnChange={(option) => {
                                                                        setFieldValue('Purchase_uom', option ? option?.value : null);
                                                                        setItemmasterValues(prev=>({...prev, Purchaseuom:option ? option : null}))

                                                                    }}
                                                                    isDisabled={itemmasterValues?.edit}
                                                                />
                                                                <label htmlFor="Purchase_uom" className='staan-form-input-label pt-0 px-1 mx-2' onClick={()=>{console.log(itemmasterList?.purchaseUomOptions)
                                                                }}>Purchase UOM<span className='text-danger'>*</span></label>
                                                                {touched.Purchase_uom && errors.Purchase_uom && <small>{errors.Purchase_uom}</small>}
                                                            </div>
                                                        </div>
                                                        <div className="row mx-2 ">
                                                            <div className="col-6 staan-input-group">
                                                                <CustomSelect
                                                                    setSelectEditValue={itemmasterValues?.PurchaseAccount}
                                                                    handleInputChange={(e) => {
                                                                        let search_term = e.trim()
                                                                        if (search_term){
                                                                            dispatch(fetchOptionsData(accountsMasterOptionQueryWithFiliter(search_term, "Liabilities", "Expenses"),  "PurchaseAccount"))
                                                                        } else{
                                                                            setItemmasterList(prev=>({...prev, purchaseAccountOptions:[]}))
                                                                        }
                                                                    }}
                                                                    options={itemmasterList?.purchaseAccountOptions}
                                                                    name='accountName'
                                                                    id='accountName'
                                                                    handleOnChange={(option) => {
                                                                        setFieldValue('Item_Purchase_Account', option ? option?.value : null);
                                                                        setItemmasterValues(prev=>({...prev, PurchaseAccount:option ? option : null}))
                                                                    }}
                                                                    isDisabled={itemmasterValues?.edit}
                                                                />
                                                                <label htmlFor="Item_Purchase_Account" className='staan-form-input-label pt-0 px-1 mx-2'>Item Purchase Account  <span className='text-danger'>*</span></label>
                                                                {touched.Item_Purchase_Account && errors.Item_Purchase_Account && <small>{errors.Item_Purchase_Account}</small>}
                                                            </div>
                                                            <div className="col-6 staan-input-group">
                                                                <Select
                                                                onInputChange={(e) => {
                                                                    let search_term = e.trim()
                                                                    if (search_term){
                                                                        dispatch(fetchOptionsData(onlyForSupplierOption(search_term),"companyName"))
                                                                    }
                                                                }}
                                                                name='companyName'
                                                                className='mt-3'
                                                                id='companyName'
                                                                isClearable
                                                                isMulti
                                                                value={itemmasterValues?.suppliers}
                                                                options={itemmasterList?.companyNameOptions}
                                                                isDisabled={itemmasterValues?.edit}
                                                                styles={customSelectStyle}
                                                                onChange={(options) => {
                                                                    const selectedValues = options ? options.map(option => option.value) : [];
                                                                    console.log(selectedValues);
                                                                    
                                                                    setFieldValue('supplierData', selectedValues);
                                                                    setItemmasterValues(prev=>({...prev, suppliers: options?.length  ? options : null}))
                                                                }}
                                                                />
                                                                <label htmlFor="supplierData"
                                                                    className='staan-form-input-label pt-0 px-1 mx-2'>Supplier
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card px-1 py-2 my-3' style={!itemmasterValues?.isProduct ? { display: "none", minHeight: '11rem' } : { minHeight: '11rem' }}>
                                                    <div className="pt-1 mx-1 border-bottom custom-bottom-shadow">
                                                        <h6 className='pt-1 mx-3'>Item Tracking Details</h6>
                                                    </div>
                                                    <div>
                                                    
                                                        <div className='row mx-2'>
                                                            <div className="col-4 pt-2">
                                                                <Field type="checkbox" name="serial" checked={values.serial} disabled={!!values.Batch_number}   className='me-2' />
                                                                <label style={{ fontSize: '.8rem', top: '5px' }} className='pb-1'>Serial Number</label>
                                                                <br />
                                                                {errors.serial && <small className="staan-error-msg">{errors.serial}</small>}
                                                            </div>
                                                            <div className="col-4 pt-2 text-center">
                                                                <Field type="checkbox" name="Batch_number"   className='me-2' disabled={!!values.serial} />
                                                                <label style={{ fontSize: '.8rem', top: '5px' }} className='pb-1'>Batch Number</label>
                                                                <br />
                                                                {errors.Batch_number && <small>{errors.Batch_number}</small>}
                                                            </div>
                                                            <div className="col-4 pt-2 text-end">
                                                                <Field type="checkbox" name="Item_Barcode" disabled={!itemmasterValues?.isProduct} className='me-2' />
                                                                <label style={{ fontSize: '.8rem', top: '5px' }} className='pb-1'>Barcode</label>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-2 my-1" hidden={!values.serial}>
                                                            <div className='col-12'>
                                                                <Field type="checkbox" checked={values.serial_auto_gentrate}    name="serial_auto_gentrate"   className='me-2' />
                                                                <label style={{ fontSize: '.8rem' }} className='pb-2'>Auto Generate</label>
                                                            </div>
                                                            <div className="col-6 staan-input-group">
                                                                <Field type='text' id="Serial_format" name='Serial_format' disabled={!values.serial_auto_gentrate} className='w-100 staan-form-input' />
                                                                <label htmlFor="Serial_format" className='staan-form-input-label pt-2 px-1 mx-2'>Serial Format</label>
                                                                {errors.Serial_format && <small>{errors.Serial_format}</small>}
                                                            </div>
                                                            <div className="col-6  staan-input-group">
                                                                <Field type='text' id='Serial_starting' name='Serial_starting' disabled={!values.serial_auto_gentrate} className='w-100 staan-form-input' />
                                                                <label htmlFor="Serial_starting" className='staan-form-input-label pt-2 px-1 mx-2'>Serial Starting</label>
                                                                {errors.Serial_starting && <small>{errors.Serial_starting}</small>}
                                                            </div>
                                                        </div>
                                                        <div className="row px-3 ">
                                                            <div className="col-6 staan-input-group">
                                                                <Field type='text' id="location" name='location'   className='w-100 staan-form-input'
                                                                    
                                                                />
                                                                <label htmlFor="location" className='staan-form-input-label pt-1 px-1 mx-2'>location</label>
                                                                {errors.location && <small className="staan-error-msg">{errors.location}</small>}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            {/*  End left side inputs */}
                                            {/*  start right side inputs */}
                                            <div className=" col-md-12 col-lg">
                                                <div className="" style={!itemmasterValues?.isProduct ? { display: "none" } : { Height: '15rem' }}>
                                                    <div  className='ItemMasterDocWrap' >
                                                        <span className=" card  itemMasterImageWarp   border-md-end   border-secondary">
                                                            <p> Image(.jpg,.png,.jpeg)</p>
                                                            <div className="border rounded p-0  "
                                                                // onClick={handleImageClick}
                                                                // disabled={ItemMasterEditDisabled}
                                                                style={{ minHeight: `10rem`, width: "10rem", position: "relative" }}>
                                                                <Field
                                                                    // innerRef={ImageInputRef} onChange={(e) => { handleFileInputChange(e) }}
                                                                    multiple
                                                                    accept=".jpg,.png,.jpeg"
                                                                    hidden
                                                                    className="form-control form-control-sm" id="formFileSm" type="file" name="fieldName"
                                                                />
                                                                {/* {selectedImage?.name ?
                                                                    selectedImage && selectedImage.type.startsWith('image/') && (
                                                                        <div className=" " style={{ width: "10rem", height: "10rem" }}>
                                                                            <img src={selectedImage.id ? selectedImage.url : URL.createObjectURL(selectedImage)} alt="Selected Image"
                                                                                style={{ maxWidth: "90%", maxHeight: "90%", objectFit: "contain", padding: "1px" }} />
                                                                        </div>
                                                                    )
                                                                    :
                                                                    
                                                                } */}
                                                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                                        <div className='text-center'>
                                                                            <AiOutlineCloudUpload style={{ width: "5rem", height: "5rem" }} />
                                                                            <br />
                                                                            <p className='text-center'>Area : 160 X 160
                                                                            <br />
                                                                            Size : 2MB</p>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </span>
                                                        <span className="card  p-2 itemMasterDocWarp "   >
                                                            <p>Document (.csv,.xlsx,.pdf,.doc)</p>
                                                            <div style={{ width: '50%' }}>
                                                                {/* <ul>
                                                                    {
                                                                        selectedDoc.map((file, index) => {
                                                                            return <>

                                                                                <li key={index} className='d-flex justify-content-between mb-1 text-start'>
                                                                                    <span><i className="fa-regular fa-circle"></i></span>
                                                                                    <a href={file.documentFile} className='text-end' download={file.documentFile} style={{ cursor: 'pointer', }}>{decodeURI(file.name)}</a>
                                                                                    <span
                                                                                        className={`ms-2 me-4 text-danger ${ItemMasterEditDisabled ? 'disabled' : ''}`}
                                                                                        style={{ cursor: ItemMasterEditDisabled ? 'default' : 'pointer' }}
                                                                                        onClick={() => {
                                                                                            if (!ItemMasterEditDisabled) {
                                                                                                handleDeleteDocument(file);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa-regular fa-trash-can "></i>
                                                                                    </span>
                                                                                </li>
                                                                            </>
                                                                        })
                                                                    }
                                                                </ul> */}
                                                            </div>
                                                            <div className="border rounded document"
                                                                // onClick={handleDocumentClick}
                                                                style={{
                                                                    width: '10rem',
                                                                    minHeight: `10rem`,
                                                                    overflow: "auto", position: "relative",
                                                                }} 
                                                            >
                                                                {/* <Field onChange={(e) => { handleDocSelect(e) }} innerRef={DocInputRef}
                                                                    multiple accept=".csv,.xlsx,.pdf,.doc,.docx"
                                                                    className="form-control form-control-sm" hidden id="formFileSm" type="file" name="fieldName" /> */}
                                                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                                    <div className='text-center'>
                                                                        <AiOutlineCloudUpload style={{ width: "5rem", height: "3rem" }} />
                                                                        <br />
                                                                        <p className='text-center'>Click Here To Select File</p>
                                                                        <p>  Size : 5MB</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                        

                                                    </div>
                                                </div>
                                                 <div className='  card px-1 pb-2 mt-2' style={!itemmasterValues?.isProduct ? { display: "none" } : {}}>
                                                    <div className="pt-1 mx-1 border-bottom custom-bottom-shadow row">
                                                        <div className="col-6">
                                                            <h6 className='mx-3'>Stock Details</h6>
                                                        </div>
                                                        <div className="col-6 text-end">
                                                            <TbHistory type="button" data-bs-toggle="offcanvas" data-bs-target="#History"
                                                            //  onClick={() => { setHistoryIds(initialmaster.HistoryDetails) }} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='row mx-2 my-1'>
                                                            <div className="col-6 staan-input-group">
                                                                <Field type='text' id='total_stock' name='Total_Stock'
                                                                disabled className='w-100 staan-form-input' />
                                                                <label htmlFor="total_stock" className='staan-form-input-label pt-2 px-1 mx-2'>Total Stock</label>
                                                                {touched.Total_Stock && errors.Total_Stock && <small className="staan-error-msg">{errors.Total_Stock}</small>}
                                                            </div>
                                                            <div className="col-6 staan-input-group">
                                                                <Field type='text' id='reject_stock' name='Rejected_Stock' disabled  className='w-100 staan-form-input' />
                                                                <label htmlFor="reject_stock" className='staan-form-input-label pt-2 px-1 mx-2'>Reject Stock</label>
                                                                {touched.Rejected_Stock && errors.Rejected_Stock && <small className="staan-error-msg">{errors.Rejected_Stock}</small>}
                                                            </div>
                                                        </div>
                                                        <div className='row mx-2 my-1'>
                                                            <div className="col-4 staan-input-group">
                                                                <Field type='text' id='safety_stock' name='Item_Safe_Stock' disabled={itemmasterValues?.itemIndicators?.label !== "Buy" && itemmasterValues?.itemIndicators?.label !== "Both"}
                                                                placeholder='' className='w-100 staan-form-input' />
                                                                <label htmlFor="safety_stock" className='staan-form-input-label pt-2 px-1 mx-2'>Safety Stock</label>

                                                                {touched.Item_Safe_Stock && errors.Item_Safe_Stock && <small className="staan-error-msg">{errors.Item_Safe_Stock}</small>}
                                                            </div>

                                                            <div className="col-4 staan-input-group">
                                                                <Field type='text' id='reject_stock' name='Item_Order_Qty' disabled={itemmasterValues?.itemIndicators?.label !== "Buy" && itemmasterValues?.itemIndicators?.label !== "Both"} placeholder='' className='w-100 staan-form-input' />
                                                                <label htmlFor="reject_stock" className='staan-form-input-label pt-2 px-1 mx-2'>Min Order Qty</label>
                                                                {touched.Item_Order_Qty && errors.Item_Order_Qty && <small className="staan-error-msg">{errors.Item_Order_Qty}</small>}
                                                            </div>
                                                            <div className="col-4 staan-input-group">
                                                                <Field type='text' id='lead_time' name='Item_Leadtime' disabled={itemmasterValues?.itemIndicators?.label !== "Buy" && itemmasterValues?.itemIndicators?.label !== "Both"} placeholder='' className='w-100 staan-form-input' />
                                                                <label htmlFor="lead_time" className='staan-form-input-label pt-2 px-1 mx-2'>Lead Time in Days</label>
                                                                {touched.Item_Leadtime && errors.Item_Leadtime && <small className="staan-error-msg">{errors.Item_Leadtime}</small>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card px-1 pb-2 my-4'>
                                                    <div className="pt-1 mx-1">
                                                        <h6 className='pt-1 mx-3'>Base Unit Conversion</h6>
                                                    </div>
                                                    <div style={{ height: '7rem', overflow: 'scroll' }}>
                                                        <table style={{ fontSize: '.7rem', width: '90%' }} className='mx-auto table table-striped'>
                                                            <thead className='thead-dark row text-center border'>
                                                                <th scope="col" className='col-2 pt-1 border'>#</th>
                                                                <th scope="col" className='col-4 pt-1 border'>Unit</th>
                                                                <th scope="col" className='col-2 pt-1 border'>Stock</th>
                                                                <th scope="col" className='col-4 pt-1 border'>Price per Unit</th>
                                                            </thead>
                                                            <tbody>
                                                                {/* {aUOM && aUOM.map((alternate_unit_item, index) => (
                                                                    <tr className='row text-center' key={index}>
                                                                        <td className='col-2'>{index + 1}</td>
                                                                        <td className='col-4'>{alternate_unit_item?.['Addtional_Unit']?.['label']}</td>
                                                                        <td className='col-2'>{
                                                                            Number(totalStockData / Number(alternate_unit_item['Conversion_Factor'])).toFixed(2)
                                                                        }</td>
                                                                        <td className='col-4'>{(values.Item_Cost / Number(alternate_unit_item['Conversion_Factor'])).toFixed(2)}</td>
                                                                    </tr>
                                                                ))} */}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='card px-1 mt-3' style={!itemmasterValues?.isProduct ? { display: "none", minHeight: '11rem' } : { minHeight: '11rem' }}>
                                                    <div className="row mx-2">
                                                        <div className="col-3 pt-3">
                                                        
                                                            <Field type="checkbox"
                                                                name="Service" checked={values.Service}  className='me-2' />
                                                            <label
                                                                style={{ fontSize: '.8rem', cursor: 'pointer' }}
                                                                className='pb-2 text-primary text-wrap' 
                                                            >Item Serviceable</label>
                                                            {errors.Service && <small>{errors.Service}</small>}
                                                        </div>
                                                        <div className="col-3 pt-3 text-wrap">
                                                            <Field type="checkbox"   name="Item_Combo"  id="Item_Combo" checked={values.Item_Combo}
                                                                disabled = {itemmasterValues?.itemIndicators?.label === "Sell" || itemmasterValues?.itemIndicators?.label === "Both"? false: true}
                                                            />
                                                            <label  name="Item_Combo" style={{ fontSize: '.8rem', cursor: 'pointer' }}
                                                                
                                                                className='pb-2 '>Item Combo <BsArrowUpLeftCircleFill className='text-primary fs-5' /></label>
                                                            {errors.Item_Combo && <small>{errors.Item_Combo}</small>}
                                                        </div>
                                                        <div className="col-3 pt-3  ">
                                                            <Field type="checkbox"
                                                                name="is_manufacture" className='me-2'
                                                                    />
                                                            <label style={{ fontSize: '.8rem' }} className='pb-2'>
                                                                Manufacture
                                                            </label>
                                                        </div>
                                                        <div className="col-3 pt-3   ">
                                                            <Field type="checkbox" name="item_qc" disabled={itemmasterValues?.edit} className='me-2' />
                                                            <label style={{ fontSize: '.8rem' }} className='pb-2'>Item QC</label>
                                                            {errors.item_qc && <small>{errors.item_qc}</small>}
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="row mx-2 my-2" 
                                                    hidden={!values.Service}
                                                    >
                                                        <div className="col-3 mt-4 ">
                                                            <Field type="checkbox" name="Warranty_Require" className='me-1' disabled={itemmasterValues?.edit} />
                                                            <label style={{ fontSize: '.8rem' }} className='pb-2'>Warranty Required</label>
                                                        </div>
                                                        <div className="col-3 mt-4">
                                                            <Field type="checkbox" name="Item_Installation" className='me-1' disabled={itemmasterValues?.edit} />
                                                            <label style={{ fontSize: '.8rem' }} className='pb-2'>Item Installation</label>
                                                        </div>
                                                        <div className="col-4 staan-input-group">
                                                            <CustomSelect
                                                                setSelectEditValue={itemmasterValues?.Warranty_based}
                                                                options={WARRANTY_BASED_OPTIONS}
                                                                name='Item_Warranty_based'
                                                                id='Item_Warranty_based'
                                                                handleOnChange={(option) => {
                                                                    setFieldValue('Item_Warranty_based', option ? option?.value : null)
                                                                    setItemmasterValues(prev=>({...prev, Warranty_based:option? option : null}))
                                                                }}
                                                                isDisabled={itemmasterValues?.edit}
                                                            />
                                                            <label htmlFor="Item_Warranty_based" className='staan-form-input-label pt-1 px-1 mx-2' >Item Warranty based</label>
                                                            {touched.Item_Warranty_based && errors.Item_Warranty_based && <small>{errors.Item_Warranty_based}</small>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 staan-input-group">
                                                        <Field type='text' as='textarea' style={{ height: "5rem" }} rows="3" name='note'
                                                            className='w-100 staan-form-input'
                                                        />
                                                        <label htmlFor="note" className='staan-form-input-label pt-1 px-1 mx-2'>Note</label>
                                                        {touched.note && errors.note && <small className='text-danger' >{errors.note}</small>}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            {/*  End right side inputs */}
                                            <div className='text-end'>
                                            <button type="submit" class="btn btn-outline-success ">Save</button>

                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </>)
                        }}
                    </Formik>
                </div>
            </Modal>
            <AddItemGroup/>
            <AddUom/>
            {ItemHSNAdd && 
            <AddHsn/>
            }
            {itemmasterValues?.isShowAlternateUnit && itemmasterValues?.uom &&
                <AlternateUnit2
                alternateUnit={alternateUnit}
                setAlternateUnit={setAlternateUnit}
                isShow={itemmasterValues?.isShowAlternateUnit}
                setIshow={()=>{setItemmasterValues(prev=>({...prev, isShowAlternateUnit:false}))}}
                optionsList ={preFetchData?.uom}
                SelectedUOM = {itemmasterValues?.uom}
                user_id={userId}
                />
            }
            
            

        </>
    )
}

export default ItemMasterDUP