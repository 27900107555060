import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import {
  AiOutlineCaretLeft,
  AiOutlineCaretRight,
  AiOutlineShoppingCart
} from 'react-icons/ai'
import { LuGift } from 'react-icons/lu'
import { PiPrinterLight } from 'react-icons/pi'
import { TiCancel } from 'react-icons/ti'
import ReactQuill from 'react-quill'
import axiosInstance, { commanFetch } from '../../../api/axoiss'
import HierarchicalTreeView from '../../../components/customTreeViewComponent/HierarchicalTreeView'
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation'
import DiscountModel from '../../../components/DiscountModel/DiscountModel'
import BallTriangleLoading from '../../../components/loading/BallTriangle'
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import CustomSelect from '../../../components/ReactSelect/reactSelect'
import TaxModel from '../../../components/Tax/TaxModel'
import WaringModel from '../../../components/Warings/WaringModel'
import WaringModelWithCopy from '../../../components/Warings/WaringModelWithCopy'
import DataContext from '../../../context/ItemMasterContext'
import {
  DepartmentQuery,
  stockIdFetchQuery,
  SupplierOptionPerameter,
  TermsConditionsQuotation,
  userPermissionOptions
} from '../../../GraphQLQueries/ItemmasterQuery'
import { leadTimeLine } from '../../../GraphQLQueries/LeadQueryAndMutations/LeadQuery'
import { otherIncomeChargesDeleteMutation } from '../../../GraphQLQueries/OtherIncomeChargesQuery/OtherIncomingChargesMutation'
import {
  getItemComboDetails,
  quotationCreateMutation,
  QuotationsCancel,
  quotationsDeleteMutation,
  quotationsgeneratePDF
} from '../../../GraphQLQueries/QuatationqueryAndMutatiom/QuatationItemModalMutation'
import {
  quotationAmandCancel,
  quotationEditQuery
} from '../../../GraphQLQueries/QuatationqueryAndMutatiom/quatationQuery'
import CommanTable from '../../../Packages/Custom/CommanTable'
import {
  calculateTax,
  checkNegativeNumber,
  CommanFinalDiscountValue,
  commanOverallDiscountValue,
  commanPercentageDiscount,
  displayRupeeFormat,
  formateGivenStringdate,
  getCurrentDate,
  getStates,
  removeEmptyValueInObject,
  roundToNearest10,
  ShowErrorNoties,
  textEditormodules,
  TimeFormate
} from '../../../utils/Utils'
import {
  quotationFormSchema,
  QuotationOtherIncomeCharges
} from '../../../validations/QuotationValidation'
import SalesOrderForm from '../../SalesOrder2/model/SalesOrderForm'
import StockStatementDetails from '../../StockStatement/Details/StockStatementDetails'
import ItemCombo, {
  calculateItemCombo,
  parseAndGroupItemComboData
} from '../Popup/itemCombo'
import QuatationItemModel from '../Popup/itemDetailsPopup'

const QuotationFrom = ({
  index,
  quotationId,
  setQuotationsList,
  leadData,
  setLoad
}) => {
  const {
    userId,
    prefetchValue,
    openQuotationModal,
    closeQuotationModal,
    openSalesOrderModal,
    openLeadModal,
    otherIncomeChargelist,
    salesOrderModels
  } = useContext(DataContext)
  // State to hold the initial form values for a quotation
  const currentDateType = getCurrentDate()
  const [initialQuotation, setInitialQuotation] = useState({
    id: '',
    supplierNumber: '',
    supplierName: '',
    department_id: '',
    remark: '',
    QuatationNumber: '',
    QuotationDate: currentDateType,
    buyerAddress: '',
    buyerContactPerson: '',
    termsConditions: '',
    TermsOptions: '',
    childCount: '',
    createdBy: {
      id: '',
      name: ''
    },
    createdAt: '',
    createdTime: '',
    modifiedBy: '',
    status: '',
    isAmend: false,
    isDuplicate: '',
    parentOrder: '',
    LeadNumber: '',
    salesPerson: '',
    Currency: '',
    currencyExchangeRate: '',
    active: ''
  })
  const [customerDetailsLists, setCustomerDetailsLists] = useState({
    buyerAddressList: [],
    buyerContactList: [],
    buyerStatesList: []
  })
  const [currencyData, setcurrencyData] = useState('')
  const [timeLineValue, setTimeLineValue] = useState({
    treeData: [],
    isShow: false
  })
  const [editView, seteditView] = useState(false)
  const [isSaveOtherIncomeCharges, setisSaveOtherIncomeCharges] =
    useState(false)
  const [IswarningShow, setwarningShow] = useState(false)
  const [IswarningAmandCancelShow, setwarningAmandCancelShow] = useState(false)
  const [isCancelWarningShow, setIsCancelWarningShow] = useState(false)
  const [isDeleteWarningShow, setIsDeleteWarningShow] = useState(false)
  const [IsshowWarningModalWithCopy, setShowWarningModalWithCopy] =
    useState(false)
  const [
    errorMessagesWarningModalWithCopy,
    setErrorMessagesWarningModalWithCopy
  ] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [versionList, setVersionList] = useState([])
  const [isFirst, setIsFirst] = useState(true)
  const [isLast, setIsLast] = useState(
    versionList ? versionList.length <= 1 : 0
  )
  const [currentVersionId, setCurrentVersionId] = useState(null)
  const [salesPersonList, setSalesPersonList] = useState([])
  const [salesPersonSelect, setsalesPersonSelect] = useState([])
  const [currencySelect, setcurrencySelect] = useState('')
  const [supplierNoList, setSupplierNoList] = useState([])
  const [supplierNoSelected, setSupplierNoSelected] = useState(null)
  const [supplierNameSelected, setSupplierNameSelected] = useState(null)
  const [supplierNameList, setSupplierNameList] = useState([])
  const [departmentSelect, setDepartmentSelect] = useState([])
  const [departmentList, setdepartmentList] = useState([])
  const [leadSelect, setleadSelect] = useState('')
  const [quotationInputValue, setQuotationInputValue] = useState({
    address: '',
    remark: ''
  })
  const [salesOrderData, setSalesOrderData] = useState({
    Quotation: '',
    Department: '',
    SalesPerson: '',
    CustomerCode: '',
    lead: '',
    value: ''
  })
  const [quotationAddressContactData, setquotationAddressContactData] =
    useState({
      buyerAddress: '',
      buyerContactPerson: ''
    })
  const [tCList, setTCList] = useState([])
  const [showTaxModel, setShowTaxModel] = useState(false)
  const [termsOptions, setTermsOptions] = useState()
  const [termsConditions, setTermsConditions] = useState(null)
  const [isShowStockStatementModal, setShowStockStatementModal] =
    useState(false)
  const [calculatedDiscount, setCalculatedDiscount] = useState(0)
  const [formKey, setFormKey] = useState(0)
  const [isLeadPage, setIsLeadPage] = useState(false)

  useEffect(() => {
    // Check if the current URL path is '/Lead'
    if (window.location.pathname === '/Lead') {
      setIsLeadPage(true)
    } else {
      setIsLeadPage(false)
    }
  }, [])

  // const [IsEnableShowDiscount, setIsEnableShowDiscount] = useState(false)
  // Effect to fetch data when quotationId changes
  const [otherIncomeChargesvalues, setotherIncomeChargesvalues] = useState({
    accountList: [],
    errorMessages: [],
    onNewItemSelect: false,
    deleteData: { Name: '', id: '' },
    showDiscount: false,
    otherIncomeValue: {
      editIndex: '',
      account: '',
      tax: '',
      amount: '',
      id: '',
      createdBy: ''
    }
  })
  const [itemDetailsValues, setitemDetailsValues] = useState({
    ItemEditData: [],
    deleteData: { Name: '', id: '' },
    deleteIndex: '',
    lastUpdatedItemDetails: [],
    selectedStockStatementDetails: [],
    selectedPartNo: '',
    itemComboData: []
  })
  const [isItemDeleteWarningShow, setisItemDeleteWarningShow] = useState(false)
  const [showItemCombo, setShowItemCombo] = useState(false)
  const [QuotationItemDetailsShow, setQuotationItemDetailsShow] =
    useState(false)
  const [ItemEditShow, setItemEditShow] = useState(false)
  const [otherIncomeChargesData, setOtherIncomeChargesData] = useState([])
  const [itemDetailsData, setItemDetailsData] = useState([])
  const [toSaveData, setToSaveData] = useState({
    beforeTax: '',
    otherIncomeChagersBeforeTax: '',
    roundOff: '',
    netAmount: '',
    itemDatalist: [],
    totaltax: '',
    cgst: {},
    sgst: {},
    igst: {},
    fixedAmount: '',
    actualNetAmount: '',
    actualTaxAmount: '',
    otherIncomeChargesId: []
  })
  const [checkDiscount, setCheckedDiscount] = useState(false)
  const [showDiscount, setShowDiscount] = useState(false)
  const [loading, setLoading] = useState(false)
  const [IsDeleteOtherIncomeCharges, setIsDeleteOtherIncomeCharges] =
    useState(false)
  const [deleteOtherIncomeData, setDeleteOtherIncomeData] = useState('')
  const [discountValue, setDiscountValue] = useState({
    discountError: '',
    discountPercentage: '',
    discountValue: '',
    discountTotalValue: '',
    isDiscountApplied: false
  })
  // fetchData function: Fetches and processes quotation data based on the provided quotationId
  const fetchData = async data => {
    setLoading(true)
    try {
      const response = await commanFetch(quotationEditQuery(data))
      if (!response.success) return
      const responseData = response?.data?.items
      const versionData = response?.data?.version
      if (responseData.length === 0) return
      setVersionList(versionData?.versionList)
      const {
        quotationNo,
        quotationDate,
        leadNo,
        CreatedAt,
        remarks,
        customerAddress,
        salesPerson,
        customerContactPerson,
        customerId,
        department,
        itemDetails,
        otherIncomeCharge,
        termsConditions,
        termsConditionsText,
        createdBy,
        // createdAt,
        status,
        discountFinalTotal,
        overallDiscountValue,
        overallDiscountPercentage,
        childCount,
        currency,
        active,
        id
      } = responseData[0]
      setleadSelect(leadNo?.leadNo)
      seteditView(true)
      setCurrentVersionId(id)
      const createdAtFormat = formateGivenStringdate(CreatedAt)
      const linkedModelId = quotationNo?.linkedModelId || ''
      const address = {
        id: customerAddress?.id,
        addressLine1: customerAddress?.addressLine1 || '',
        addressLine2: customerAddress?.addressLine2 || '',
        city: customerAddress?.city || '',
        state: customerAddress?.state || '',
        pincode: customerAddress?.pincode || ''
      }

      // Update the state with fetched data
      setInitialQuotation(prevState => ({
        ...prevState,
        id: id,
        QuatationNumber: linkedModelId,
        QuotationDate: quotationDate,
        remark: remarks || '',
        supplierNumber: customerId?.supplierNo,
        supplierName: customerId?.companyName,
        department_id: department?.id,
        TermsOptions: termsConditions?.id,
        termsConditions: termsConditionsText,
        childCount: childCount,
        status: status?.name,
        createdBy: {
          id: createdBy?.id,
          name: createdBy?.username
        },
        LeadNumber: leadNo?.id ? Number(leadNo?.id) : '',
        createdAt: createdAtFormat,
        createdTime: TimeFormate(CreatedAt),
        salesPerson: salesPerson ? Number(salesPerson?.id) : '',
        buyerAddress: Number(customerAddress?.id),
        buyerContactPerson: Number(customerContactPerson?.id),
        Currency: currency?.id ? currency?.id : '',
        currencyExchangeRate: currency?.rate ? currency?.rate : '',
        active: active
      }))

      setTermsOptions({
        value: termsConditions?.id,
        label: termsConditions?.name
      })
      setTermsConditions(termsConditionsText)

      setQuotationInputValue(prevState => ({
        ...prevState,
        address: address
      }))
      setSupplierNameSelected({
        value: customerId?.id,
        label: customerId?.companyName
      })
      setSupplierNoSelected({
        value: customerId?.id,
        label: customerId?.supplierNo
      })
      setDepartmentSelect({
        value: department?.id,
        label: department?.name
      })
      setsalesPersonSelect({
        value: salesPerson?.id,
        label: salesPerson?.username
      })
      setquotationAddressContactData({
        buyerAddress: {
          value: customerAddress?.id,
          label: customerAddress?.addressType,
          fullAddredd: customerAddress
        },
        buyerContactPerson: {
          value: customerContactPerson?.id,
          label: customerContactPerson?.contactPersonName,
          mobile: customerContactPerson?.phoneNumber,
          Email: customerContactPerson?.email
        }
      })
      setCustomerDetailsLists({
        buyerContactList: customerId?.contact?.map(contact_ => ({
          value: contact_?.id,
          label: contact_?.contactPersonName,
          mobile: contact_?.phoneNumber,
          Email: contact_?.email
        })),
        buyerAddressList: customerId?.address?.map(address_ => ({
          value: address_?.id,
          label: address_?.addressType,
          fullAddredd: address_
        }))
      })
      setDiscountValue(prev => ({
        ...prev,
        discountPercentage: overallDiscountPercentage
          ? overallDiscountPercentage
          : '',
        discountValue: overallDiscountValue ? overallDiscountValue : '',
        discountTotalValue: discountFinalTotal ? discountFinalTotal : ''
      }))
      // setSalesPersonList({ value: salesPerson?.id, label: salesPerson?.username })
      if (
        overallDiscountPercentage ||
        overallDiscountValue ||
        discountFinalTotal
      ) {
        setCheckedDiscount(true)
        setDiscountValue(prev => ({ ...prev, isDiscountApplied: true }))
      }
      // setIsEnableShowDiscount(true)
      // Set the item details
      let formattedItemDetails = []
      let transformedOtherIncomeChargesData = []
      if (itemDetails && Array.isArray(itemDetails)) {
        formattedItemDetails = itemDetails.map((item, rowIndex) => ({
          afterDiscountValueForPerItem:
            item?.afterDiscountValueForPerItem || null,
          amount: item?.amount ? Number(item?.amount).toFixed(2) : 0,
          category: item?.itemmaster?.category?.name || null,
          description: item?.description || null,
          discount: item?.discountPercentage
            ? Number(item?.discountPercentage).toFixed(2)
            : item?.discountValue, // Assuming percentage as string
          discountPercentage: item?.discountPercentage
            ? Number(item?.discountPercentage).toFixed(2)
            : null,
          discountValue: item?.discountValue
            ? Number(item?.discountValue).toFixed(2)
            : null,
          finalValue: item?.finalValue
            ? Number(item?.finalValue).toFixed(2)
            : null,
          gstRates: item?.itemmaster?.itemHsn?.gstRates?.rate || 0,
          hsnCode: item?.itemmaster?.itemHsn?.hsnCode || null,
          hsnId: item?.itemmaster?.itemHsn?.id || null,
          id: item?.id || null,
          index: rowIndex + 1,
          itemComboBool: item?.itemCombo || false,
          itemMrp: item?.itemmaster?.itemMrp || null,
          partCode:
            {
              value: item?.itemmaster?.id,
              label: item?.itemmaster?.itemPartCode
            } || '',
          partName:
            {
              value: item?.itemmaster?.id,
              label: item?.itemmaster?.itemName
            } || '',
          rate: item?.rate || null,
          uom: {
            value: item?.uom?.id || '',
            label: item?.uom?.name || ''
          },
          itemComboItemDetails: item?.itemCombo
            ? parseAndGroupItemComboData(
                item?.itemComboItemDetails,
                rowIndex + 1
              )
            : [],
          po_qty: item?.qty ? Number(item?.qty) : 0, // Default to 0 if qty is not provided,
          igst: item?.igst ? item?.igst : null,
          cgst: item?.cgst ? item?.cgst : null,
          sgst: item?.sgst ? item?.sgst : null,
          createdBy: item?.createdBy?.id ? Number(item?.createdBy?.id) : '',
          modifiedBy: item?.modifiedBy?.id ? Number(item?.modifiedBy?.id) : ''
        }))

        setItemDetailsData(formattedItemDetails)
      }
      if (otherIncomeCharge && Array.isArray(otherIncomeCharge)) {
        transformedOtherIncomeChargesData = otherIncomeCharge.map(
          (item, index) => ({
            index: index + 1,
            id: item?.id || '',
            amount: item?.amount ? Number(item?.amount).toFixed(2) : null,
            afterDiscountValue: item?.afterDiscountValue
              ? Number(item?.afterDiscountValue).toFixed(2)
              : null,
            discountValue: item?.discountValue
              ? Number(item?.discountValue).toFixed(2)
              : '',
            otherIncomeChargesId: {
              value: item?.otherIncomeChargesId?.id || '',
              label: item?.otherIncomeChargesId?.name || '',
              tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate || 0
            },
            igst: item?.igst ? item?.igst : null,
            cgst: item?.cgst ? item?.cgst : null,
            sgst: item?.sgst ? item?.sgst : null,
            tax: item?.otherIncomeChargesId?.hsn?.rate || 0,
            hsnCode: item?.otherIncomeChargesId?.hsn?.hsnCode,
            createdBy: item?.createdBy?.id || '',
            modifiedBy: item.modifiedBy?.id || ''
          })
        )
        setOtherIncomeChargesData(transformedOtherIncomeChargesData)
      }

      // for sales Order
      setSalesOrderData({
        Quotation: { id: id, QuatationNumber: linkedModelId },
        Department: { value: department?.id, label: department?.name },
        SalesPerson: { value: salesPerson?.id, label: salesPerson?.username },
        CustomerCode: { value: customerId?.id, label: customerId?.supplierNo },
        lead: leadNo,
        overallDiscountPercentage: overallDiscountPercentage,
        overallDiscountValue: overallDiscountValue,
        discountFinalTotal: discountFinalTotal,
        currency: {
          value: currency?.id,
          label: currency?.Currency?.name,
          Symbol: currency?.Currency?.currencySymbol
        },
        itemData: formattedItemDetails,
        otherIncomeData: transformedOtherIncomeChargesData
      })

      setcurrencyData({
        currencySymbol: currency?.Currency?.currencySymbol,
        label: currency?.Currency?.name,
        rate: currency?.rate,
        value: currency?.Currency?.id
      })
      setcurrencySelect({
        value: currency?.id,
        label: currency?.Currency?.name,
        rate: currency?.rate,
        currencySymbol: currency?.Currency?.currencySymbol
      })
    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
    }
    setLoading(false)
  }
  //Triggers the fetchData function if a valid quotationId is provided, or sets edit view to false otherwise. It does not return any value.
  useEffect(() => {
    if (quotationId) {
      fetchData(quotationId)
    } else {
      seteditView(false)
    }
    if (prefetchValue?.currencyExchangeList?.length > 0 && quotationId) {
      let currencyDefaultValue = prefetchValue?.currencyExchangeList?.filter(
        currency => currency?.label === 'Rupee'
      )
      if (currencyDefaultValue) {
        setInitialQuotation(prev => ({
          ...prev,
          Currency: Number(currencyDefaultValue?.[0]?.value),
          currencyExchangeRate: currencyDefaultValue?.[0]?.rate
        }))
        setcurrencyData(currencyDefaultValue?.[0])
        setcurrencySelect({
          value: currencyDefaultValue?.[0]?.value,
          label: currencyDefaultValue?.[0]?.label,
          rate: currencyDefaultValue?.[0]?.rate,
          currencySymbol: currencyDefaultValue?.[0]?.currencySymbol
        })
      }
    }
  }, [quotationId])
  //Fetches lead data if `leadData` exists and `quotationId` is null, updates supplier, address, contact, and other details based on the lead data. It does not return any value.
  useEffect(() => {
    const leadfetchData = async () => {
      if (leadData && quotationId === null) {
        let peremeter = `id: "${Number(
          leadData?.customer?.id
        )}", customer:true , isLead:null `
        const response = await commanFetch(SupplierOptionPerameter(peremeter))
        const reponseData = response?.data?.items
        if (reponseData.length > 0) {
          setSupplierNoSelected({
            value: reponseData[0]?.id,
            label: reponseData[0]?.supplierNo
          })
          setSupplierNameSelected({
            value: reponseData[0]?.id,
            label: reponseData[0]?.companyName
          })
          setquotationAddressContactData({
            buyerAddress: {
              value:
                reponseData[0]?.address?.find(address_ => address_.default)
                  ?.id || '',
              label:
                reponseData[0]?.address?.find(address_ => address_.default)
                  ?.addressType || '',
              fullAddredd:
                reponseData[0]?.address?.find(address_ => address_.default) ||
                ''
            },
            buyerContactPerson: {
              value:
                reponseData[0]?.contact?.find(contact_ => contact_.default)
                  ?.id || '',
              label:
                reponseData[0]?.contact?.find(contact_ => contact_.default)
                  ?.contactPersonName || '',
              mobile:
                reponseData[0]?.contact?.find(contact_ => contact_.default)
                  ?.phoneNumber || '',
              Email:
                reponseData[0]?.contact?.find(contact_ => contact_.default)
                  ?.email || ''
            }
          })
          setCustomerDetailsLists({
            buyerContactList: reponseData[0]?.contact?.map(contact_ => ({
              value: contact_?.id,
              label: contact_?.contactPersonName,
              mobile: contact_?.phoneNumber,
              Email: contact_?.email
            })),
            buyerAddressList: reponseData[0]?.address?.map(address_ => ({
              value: address_?.id,
              label: address_?.addressType,
              fullAddredd: address_
            }))
          })
          setsalesPersonSelect({
            value: leadData ? leadData?.salesPerson?.id : '',
            label: leadData ? leadData?.salesPerson?.username : ''
          })

          setleadSelect(leadData?.leadNo)
          setInitialQuotation(prevState => ({
            ...prevState,
            supplierNumber: reponseData[0]?.supplierNo,
            supplierName: reponseData[0]?.companyName,
            salesPerson: leadData ? Number(leadData?.salesPerson?.id) : '',
            buyerAddress: reponseData[0]?.address?.find(addr => addr.default)
              ?.id
              ? Number(reponseData[0].address.find(addr => addr.default).id)
              : '',
            buyerContactPerson: leadData
              ? Number(
                  reponseData[0]?.contact?.find(contact_ => contact_.default)
                    ?.id
                )
              : '',
            LeadNumber: leadData?.id ? leadData?.id : '',
            Currency: Number(leadData?.leadCurrency?.id),
            currencyExchangeRate: leadData?.leadCurrency?.[0]?.rate
          }))
          setcurrencyData({
            currencySymbol: leadData?.leadCurrency?.Currency?.currencySymbol,
            label: leadData?.leadCurrency?.Currency?.name,
            rate: leadData?.leadCurrency?.rate,
            value: leadData?.leadCurrency?.id
          })
          setcurrencySelect({
            value: leadData?.leadCurrency?.id,
            label: leadData?.leadCurrency?.Currency.name,
            rate: leadData?.leadCurrency?.rate,
            currencySymbol: leadData?.leadCurrency?.Currency?.currencySymbol
          })
        }
      }
    }
    if (leadData?.customer?.id) {
      leadfetchData()
    }
  }, [leadData])
  // handleWarningConfirmationAmandCancel function: Handles the confirmation or cancellation of a warning, sends a request to amend or cancel the quotation, processes the response, and resets the form if successful. It does not return any value.
  const handleWarningConfirmationAmandCancel = async data => {
    if (data === 'yes') {
      const reqBody = {
        itemDetails: itemDetailsData?.map(item => item?.id),
        otherIncomeCharge: otherIncomeChargesData?.map(item => item?.id)
      }
      try {
        const respones = await axiosInstance.post(`/itemmaster/graphql`, {
          query: quotationAmandCancel(reqBody)
        })
        const responseData = respones?.data?.data?.quotationsAmandCancel
        if (responseData?.success) {
          resetForm()
        } else {
          let errorFilter = ShowErrorNoties(responseData?.errors)
          showErrorToast(true, 'error', errorFilter)
        }
        closeQuotationModal(index)
      } catch (error) {
        let errorData = ShowErrorNoties(error)
        showErrorToast(true, 'error', errorData)
      }
    } else {
      setwarningAmandCancelShow(false)
    }
  }
  // handleDeleteOtherIncomeFunction function: Handles the deletion of an other income item from the `otherIncomeChargesData` state and clears associated discount data if a discount is applied. It does not return any value.
  const handleDeleteOtherIncomeFunction = data => {
    if (data === true) {
      const updatedOtherincome = otherIncomeChargesData?.filter(
        (item, index) => item?.index !== deleteOtherIncomeData?.deleteIndex
      )
      setOtherIncomeChargesData(updatedOtherincome)
      setDeleteOtherIncomeData('')
      callClearDiscount(null, updatedOtherincome)
    }
  }
  // handleNewItem function: Adds or updates an item in the `itemDetailsData` list based on the provided `newItem`. It does not return any value.
  const handleNewItem = newItem => {
    // Map through the itemDetailsData to create a new list with necessary changes
    const updatedItemDetails = itemDetailsData?.map(item => {
      if (item?.index === newItem?.index) {
        // Update the existing item if it matches the newItem index
        return newItem
      }
      return item // Return the item unchanged if it doesn't match
    })

    // If the newItem is not already in the list (not found in updatedItemDetails), add it
    if (!updatedItemDetails.some(item => item?.index === newItem?.index)) {
      updatedItemDetails.push(newItem) // Add new item to the end
    }

    // Now update the state using the updated list
    setItemDetailsData(updatedItemDetails)
    callClearDiscount(updatedItemDetails, null)
  }
  // Effect to calculate final amounts and taxes based on item details and other income charges
  useEffect(() => {
    if (itemDetailsData?.length >= 0) {
      let item_value = 0
      let other_income_value = 0
      let actual_item_value = 0
      let actual_other_income_value = 0

      const igstList = {}
      const cgstList = {}
      const sgstList = {}

      // const actualIgstList = {}
      // const actualCgstList = {}
      // const actualSgstList = {}

      // Group the tax to display it in Tax Modal
      let groupedData = {}
      let totalDiscount = 0
      itemDetailsData?.forEach(itemValue => {
        item_value += Number(itemValue?.amount)
        actual_item_value += Number(itemValue.rate * itemValue?.po_qty)
        const hsnCode = itemValue?.hsnCode
        // group the data based on hsn code to display in tax modal
        if (!groupedData[hsnCode]) {
          groupedData[hsnCode] = {
            hsn: hsnCode,
            taxableValue: 0,
            cgst: { percent: 0, amount: 0 },
            sgst: { percent: 0, amount: 0 },
            igst: { percent: 0, amount: 0 }
          }
        }
        // const discountAmount = itemValue.discountPercentage ? (itemValue?.discountPercentage / 100) * Number(itemValue?.amount) : itemValue?.discountValue || 0;
        // const finalTaxableValue = Number(itemValue?.amount) - discountAmount;
        const finalTaxableValue = Number(itemValue?.amount)
        groupedData[hsnCode].taxableValue += finalTaxableValue
        if (itemValue.igst) {
          igstList[itemValue?.igst] = (igstList[itemValue?.igst] || []).concat(
            itemValue?.amount
          )
          // actualIgstList[itemValue.igst] = (actualIgstList[itemValue.igst] || []).concat((itemValue.rate) * (itemValue?.po_qty));
          groupedData[hsnCode].igst.percent = Number(itemValue.igst)
          groupedData[hsnCode].igst.amount +=
            (finalTaxableValue * Number(itemValue.igst)) / 100
        }
        if (itemValue.cgst) {
          cgstList[itemValue?.cgst] = (cgstList[itemValue?.cgst] || []).concat(
            itemValue?.amount
          )
          // actualCgstList[itemValue.cgst] = (actualCgstList[itemValue.cgst] || []).concat((itemValue.rate) * (itemValue?.po_qty));
          groupedData[hsnCode].cgst.percent = Number(itemValue.cgst)
          groupedData[hsnCode].cgst.amount +=
            (finalTaxableValue * Number(itemValue.cgst)) / 100
        }
        if (itemValue.sgst) {
          sgstList[itemValue?.sgst] = (sgstList[itemValue?.sgst] || []).concat(
            itemValue?.amount
          )
          // actualSgstList[itemValue.sgst] = (actualSgstList[itemValue.sgst] || []).concat((itemValue?.rate) * (itemValue?.po_qty));
          groupedData[hsnCode].sgst.percent = Number(itemValue.sgst)
          groupedData[hsnCode].sgst.amount +=
            (finalTaxableValue * Number(itemValue.sgst)) / 100
        }
        // calculate individual  discount to display
        // if (discountValue?.isDiscountApplied === false) {
        //   if (itemValue?.discountPercentage > 0) {
        //     totalDiscount += (itemValue?.rate * itemValue?.po_qty) * (Number(itemValue?.discountPercentage) / 100);
        //   } else if (itemValue?.discountValue > 0) {
        //     totalDiscount += Number(itemValue?.discountValue * itemValue?.po_qty);
        //   }
        // } else {
        //   if (itemValue?.discountPercentage > 0) {
        //     totalDiscount += (itemValue?.rate * itemValue?.po_qty) * (Number(itemValue?.discountPercentage) / 100);
        //   } else if (itemValue?.discountValue > 0) {
        //     totalDiscount += Number(itemValue?.discountValue * itemValue?.po_qty);
        //   } else {
        //     totalDiscount += Number(itemValue?.finalValue);
        //   }
        // }
        if (itemValue?.discountPercentage > 0) {
          totalDiscount +=
            itemValue?.rate *
            itemValue?.po_qty *
            (Number(itemValue?.discountPercentage) / 100)
        } else if (itemValue?.discountValue > 0) {
          totalDiscount += Number(itemValue?.discountValue * itemValue?.po_qty)
        } else {
          totalDiscount += Number(itemValue?.finalValue)
        }
        // console.log("totalDiscount", totalDiscount);
      })

      setCalculatedDiscount(totalDiscount)
      otherIncomeChargesData?.forEach(itemValue => {
        let other_income_amount = itemValue?.afterDiscountValue
          ? Number(itemValue?.afterDiscountValue)
          : Number(itemValue.amount)
        other_income_value += other_income_amount
        actual_other_income_value += Number(itemValue.amount).toFixed(2)
        const hsnCode = itemValue?.hsnCode
        if (!groupedData[hsnCode]) {
          groupedData[hsnCode] = {
            hsn: hsnCode,
            taxableValue: 0,
            cgst: { percent: 0, amount: 0 },
            sgst: { percent: 0, amount: 0 },
            igst: { percent: 0, amount: 0 }
          }
        }
        const finalTaxableValue = itemValue?.afterDiscountValue
          ? Number(itemValue?.afterDiscountValue)
          : Number(itemValue.amount)
        groupedData[hsnCode].taxableValue += finalTaxableValue
        if (itemValue.igst) {
          igstList[itemValue.igst] = (igstList[itemValue.igst] || []).concat(
            other_income_amount
          )
          // actualIgstList[itemValue.igst] = (actualIgstList[itemValue.igst] || []).concat(other_income_amount);
          groupedData[hsnCode].igst.percent = Number(itemValue?.igst)
          groupedData[hsnCode].igst.amount +=
            (finalTaxableValue * Number(itemValue?.igst)) / 100
        }
        if (itemValue.cgst) {
          cgstList[itemValue.cgst] = (cgstList[itemValue.cgst] || []).concat(
            other_income_amount
          )
          // actualCgstList[itemValue.cgst] = (actualCgstList[itemValue.cgst] || []).concat(other_income_amount);
          groupedData[hsnCode].cgst.percent = Number(itemValue.cgst)
          groupedData[hsnCode].cgst.amount +=
            (finalTaxableValue * Number(itemValue?.cgst)) / 100
        }
        if (itemValue.sgst) {
          sgstList[itemValue.sgst] = (sgstList[itemValue.sgst] || []).concat(
            other_income_amount
          )
          // actualSgstList[itemValue.sgst] = (actualSgstList[itemValue.sgst] || []).concat(other_income_amount);
          groupedData[hsnCode].sgst.percent = Number(itemValue?.sgst)
          groupedData[hsnCode].sgst.amount +=
            (finalTaxableValue * Number(itemValue?.sgst)) / 100
        }
      })
      // Calculate taxes
      const { taxResult: igstResult, taxValues: igstaxvalues } =
        calculateTax(igstList)
      const { taxResult: cgstResult, taxValues: cgstaxvalues } =
        calculateTax(cgstList)
      const { taxResult: sgstResult, taxValues: sgstaxvalues } =
        calculateTax(sgstList)

      // // // actual taxes
      // const { taxResult: actualigstResult, taxValues: actualigstaxvalues } = calculateTax(actualIgstList);
      // const { taxResult: actualcgstResult, taxValues: actualcgstaxvalues } = calculateTax(actualCgstList);
      // const { taxResult: actualsgstResult, taxValues: actualgstaxvalues } = calculateTax(actualSgstList);
      let actualNetAmount =
        Number(actual_item_value) + Number(actual_other_income_value)
      // const fixedTaxAmount = roundToNearest10(actualigstaxvalues + actualcgstaxvalues + actualgstaxvalues);
      const newFinalAmount = roundToNearest10(
        igstaxvalues +
          cgstaxvalues +
          sgstaxvalues +
          item_value +
          other_income_value
      ).toFixed(2)

      // Update state only if values have changed
      let totalTax = (igstaxvalues + cgstaxvalues + sgstaxvalues).toFixed(2)
      let roundOff = (
        newFinalAmount -
        (igstaxvalues +
          cgstaxvalues +
          sgstaxvalues +
          item_value +
          other_income_value)
      ).toFixed(2)
      const itemDetailsds = itemDetailsData?.map(item => parseInt(item.id, 10))
      const otherIncomeIds = otherIncomeChargesData?.map(item =>
        parseInt(item.id, 10)
      )
      setToSaveData(prve => ({
        ...prve,
        totaltax: totalTax,
        beforeTax: item_value.toFixed(2),
        fixedAmount: Number(actualNetAmount).toFixed(2),
        otherIncomeChagersBeforeTax: other_income_value.toFixed(2),
        netAmount: newFinalAmount,
        roundOff: roundOff,
        sgst: sgstResult,
        cgst: cgstResult,
        igst: igstResult,
        itemDatalist: itemDetailsds,
        otherIncomeChargesId: otherIncomeIds,
        actualNetAmount: roundToNearest10(actualNetAmount),
        taxData: Object.values(groupedData)
      }))
      // if (discountValue?.isDiscountApplied === false) {
      //   let discoutnCal = individualDiscountCalculation(discountValue, itemDetailsData)
      //   setCalculatedDiscount(discoutnCal)
      // }
      setisSaveOtherIncomeCharges(false)
    }
  }, [itemDetailsData, isSaveOtherIncomeCharges, otherIncomeChargesData])
  // Handles moving forward to the next version in the version list
  const forWord = () => {
    for (let x in versionList) {
      if (Number(currentVersionId) === Number(versionList[x])) {
        let nextIndex = Number(x) + 1
        if (nextIndex < versionList.length) {
          // close()

          setIsFirst(false)
          fetchData(versionList[nextIndex])
          setCurrentVersionId(versionList[nextIndex])
          if (nextIndex === versionList.length - 1) {
            setIsLast(true)
          } else {
            setIsLast(false)
          }
        }
        break
      }
    }
  }
  // Handles moving backward to the previous version in the version list
  const backWord = () => {
    for (let x in versionList) {
      if (Number(currentVersionId) === Number(versionList[x])) {
        let prevIndex = Number(x) - 1

        if (prevIndex >= 0) {
          setIsLast(false)
          setLoading(true)
          fetchData(versionList[prevIndex])

          setCurrentVersionId(versionList[prevIndex])
          if (prevIndex === 0) {
            setIsFirst(true)
          } else {
            setIsFirst(false)
          }
        }
        break
      }
    }
  }
  // function to display the overall discount calculation
  const overAlldiscountCalculationToDisplay = (
    commanDiscount,
    netAmount,
    itemDetailsData,
    otherincomedata
  ) => {
    if (commanDiscount?.discountPercentage > 0) {
      // Apply percentage discount
      return (netAmount * commanDiscount?.discountPercentage) / 100
    } else if (commanDiscount?.discountValue > 0) {
      let totalDiscount = 0
      if (itemDetailsData) {
        itemDetailsData?.forEach(item => {
          let finalValue = Number(item?.discountValue)
          let totalfinalValue = Number(finalValue) * Number(item?.po_qty)
          totalDiscount += totalfinalValue
        })
      }
      if (otherincomedata) {
        otherincomedata?.forEach(item => {
          let finalValue = Number(item?.discountValue)
          let totalfinalValue = Number(finalValue)
          totalDiscount += totalfinalValue
        })
      }
      return totalDiscount.toFixed(2)
    } else if (commanDiscount?.discountTotalValue > 0) {
      let totalDiscount = 0
      itemDetailsData?.forEach(item => {
        let finalValue = item?.finalValue
        let totalfinalValue = Number(finalValue) * Number(item?.po_qty)
        totalDiscount += totalfinalValue
      })
      if (otherincomedata) {
        otherincomedata?.forEach(item => {
          let finalValue = Number(item?.discountValue)
          let totalfinalValue = Number(finalValue)
          totalDiscount += totalfinalValue
        })
      }
      return totalDiscount.toFixed(2)
    }
    return 0.0
  }

  // Function to apply a common percentage discount to item and other income charges; returns no value.
  const commanPercentageDiscountPOS = Precentage => {
    let preparitemData = itemDetailsData?.map(item => {
      return { index: item?.index, rate: item?.rate }
    })
    let prepareOtherIncome = otherIncomeChargesData?.map(item => {
      return { index: item?.index, rate: item?.amount }
    })
    let result = commanPercentageDiscount(
      preparitemData,
      prepareOtherIncome,
      Precentage
    )
    let updateDiscountItemDetails = itemDetailsData?.map(item => {
      // Create a hash map of itemDetails by their index
      const itemDetailsMap = result?.itemDetails?.reduce(
        (acc, item_with_dc) => {
          acc[item_with_dc?.index] = item_with_dc
          return acc
        },
        {}
      )

      // Find the corresponding item in itemDetails using the index
      const item_with_discount = itemDetailsMap[item?.index]
      if (item?.itemComboBool && item?.itemComboItemDetails) {
        let amount = Number(item_with_discount?.discountRate * item?.po_qty)
        item.itemComboItemDetails = calculateItemCombo(
          item?.itemComboItemDetails,
          amount,
          item.po_qty
        )
      }
      if (item_with_discount) {
        // If a matching item is found, calculate the amount and return the updated item
        let amount = item_with_discount?.discountRate * item?.po_qty
        return {
          ...item,
          amount: amount,
          afterDiscountValueForPerItem: Number(
            item_with_discount?.discountRate
          ).toFixed(2),
          discountPercentage: Precentage,
          discountValue: ''
        }
      }

      // If no matching item is found, return the original item
      return item
    })
    let updateDiscountOtherIncome = otherIncomeChargesData?.map(item => {
      // Create a hash map of itemDetails by their index
      const otherDetailsDetailsMap = result?.otherIncomeCharge?.reduce(
        (acc, item_with_dc) => {
          acc[item_with_dc?.index] = item_with_dc
          return acc
        },
        {}
      )
      // console.log("otherDetailsDetailsMap", otherDetailsDetailsMap);

      // Find the corresponding item in itemDetails using the index
      const item_with_discount = otherDetailsDetailsMap[item?.index]

      if (item_with_discount) {
        // If a matching item is found, calculate the amount and return the updated item
        return {
          ...item,
          afterDiscountValue: Number(item_with_discount?.discountRate).toFixed(
            2
          ),
          discountValue: Number(item_with_discount?.discountValue).toFixed(2)
        }
      }

      // If no matching item is found, return the original item
      return item
    })
    let discoutnCal = overAlldiscountCalculationToDisplay(
      discountValue,
      Number(toSaveData?.netAmount),
      null,
      null
    )
    setCalculatedDiscount(discoutnCal)
    setItemDetailsData(updateDiscountItemDetails)
    setOtherIncomeChargesData(updateDiscountOtherIncome)
    setDiscountValue(prev => ({ ...prev, isDiscountApplied: true }))
    setCheckedDiscount(true)
    showErrorToast(true, 'success', '', 'Discount Applied Successfully')
  }
  // Function to apply an overall discount to items and other income charges; returns no value.
  const commanOverAllDiscountPOS = discountValues => {
    let preparitemData = itemDetailsData?.map(item => {
      return { index: item?.index, rate: item?.rate }
    })
    let prepareOtherIncome = otherIncomeChargesData?.map(item => {
      return { index: item?.index, rate: item?.amount }
    })
    let result = commanOverallDiscountValue(
      preparitemData,
      prepareOtherIncome,
      discountValues,
      toSaveData?.netAmount
    )
    let updateDiscount = itemDetailsData?.map(item => {
      // Create a hash map of itemDetails by their index
      const itemDetailsMap = result?.itemDetails?.reduce(
        (acc, item_with_dc) => {
          acc[item_with_dc?.index] = item_with_dc
          return acc
        },
        {}
      )

      // Find the corresponding item in itemDetails using the index
      const item_with_discount = itemDetailsMap[item?.index]
      if (item_with_discount) {
        if (item?.itemComboBool && item?.itemComboItemDetails?.length > 0) {
          let amount =
            Number(item_with_discount?.discountRate) * Number(item?.po_qty)
          item.itemComboItemDetails = calculateItemCombo(
            item?.itemComboItemDetails,
            amount,
            item.po_qty
          )
        }
      }
      if (item_with_discount) {
        // If a matching item is found, calculate the amount and return the updated item
        let amount =
          Number(item_with_discount?.discountRate) * Number(item?.po_qty)
        return {
          ...item,
          amount: Number(amount).toFixed(2),
          afterDiscountValueForPerItem: Number(
            item_with_discount?.discountRate
          ).toFixed(2),
          discountValue: Number(item_with_discount?.discountValue).toFixed(2),
          discountPercentage: ''
        }
      }

      // If no matching item is found, return the original item
      return item
    })
    let updateDiscountOtherIncome = otherIncomeChargesData?.map(item => {
      // Create a hash map of itemDetails by their index
      const otherDetailsDetailsMap = result?.otherIncomeCharge?.reduce(
        (acc, item_with_dc) => {
          acc[item_with_dc?.index] = item_with_dc
          return acc
        },
        {}
      )
      const item_with_discount = otherDetailsDetailsMap[item?.index]
      if (item_with_discount) {
        return {
          ...item,
          afterDiscountValue: Number(item_with_discount?.discountRate).toFixed(
            2
          ),
          discountValue: Number(item_with_discount?.discountValue).toFixed(2)
        }
      }

      // If no matching item is found, return the original item
      return item
    })
    setItemDetailsData(updateDiscount)
    setOtherIncomeChargesData(updateDiscountOtherIncome)
    let discoutnCal = overAlldiscountCalculationToDisplay(
      discountValue,
      Number(toSaveData?.fixedAmount),
      updateDiscount,
      updateDiscountOtherIncome
    )
    setCalculatedDiscount(discoutnCal)
    setDiscountValue(prev => ({ ...prev, isDiscountApplied: true }))
    setCheckedDiscount(true)
    showErrorToast(true, 'success', '', 'Discount Applied Successfully')
  }
  // Function to apply a final discount to items and other income charges; returns no value.
  const commanFinalDiscountPOS = async finalValue => {
    let preparitemData = itemDetailsData?.map(item => {
      return { index: item?.index, rate: item?.rate }
    })
    let prepareOtherIncome = otherIncomeChargesData?.map(item => {
      return { index: item?.index, rate: item?.amount }
    })
    let result = CommanFinalDiscountValue(
      preparitemData,
      prepareOtherIncome,
      finalValue,
      Number(toSaveData?.netAmount)
    )
    let updateDiscount = itemDetailsData?.map(item => {
      // Create a hash map of itemDetails by their index
      const itemDetailsMap = result?.itemDetails?.reduce(
        (acc, item_with_dc) => {
          acc[item_with_dc?.index] = item_with_dc
          return acc
        },
        {}
      )

      // Find the corresponding item in itemDetails using the index
      const item_with_discount = itemDetailsMap[item?.index]
      if (item_with_discount) {
        if (item?.itemComboBool && item?.itemComboItemDetails) {
          let amount =
            Number(item_with_discount?.discountRate) * Number(item?.po_qty)
          item.itemComboItemDetails = calculateItemCombo(
            item?.itemComboItemDetails,
            amount,
            item.po_qty
          )
        }
      }
      if (item_with_discount) {
        // If a matching item is found, calculate the amount and return the updated item
        let amount =
          Number(item_with_discount?.discountRate) * Number(item?.po_qty)
        return {
          ...item,
          amount: Number(amount).toFixed(2),
          afterDiscountValueForPerItem: Number(
            item_with_discount?.discountRate
          ).toFixed(2),
          discountPercentage: '',
          discountValue: '',
          finalValue: Number(item_with_discount?.discountValue).toFixed(2)
        }
      }
      // If no matching item is found, return the original item
      return item
    })
    let updateDiscountOtherIncome = otherIncomeChargesData?.map(item => {
      // Create a hash map of itemDetails by their index
      const otherDetailsDetailsMap = result?.otherIncomeCharge?.reduce(
        (acc, item_with_dc) => {
          acc[item_with_dc?.index] = item_with_dc
          return acc
        },
        {}
      )
      // console.log("otherDetailsDetailsMap", otherDetailsDetailsMap);

      // Find the corresponding item in itemDetails using the index
      const item_with_discount = otherDetailsDetailsMap[item?.index]

      if (item_with_discount) {
        // If a matching item is found, calculate the amount and return the updated item
        return {
          ...item,
          afterDiscountValue: Number(item_with_discount?.discountRate).toFixed(
            2
          ),
          discountValue: Number(item_with_discount?.discountValue).toFixed(2)
        }
      }

      // If no matching item is found, return the original item
      return item
    })
    let discoutnCal = await overAlldiscountCalculationToDisplay(
      discountValue,
      Number(toSaveData?.fixedAmount),
      updateDiscount,
      updateDiscountOtherIncome
    )
    setCalculatedDiscount(discoutnCal)
    setOtherIncomeChargesData(updateDiscountOtherIncome)
    setItemDetailsData(updateDiscount)
    setDiscountValue(prev => ({ ...prev, isDiscountApplied: true }))
    setCheckedDiscount(true)
    showErrorToast(true, 'success', '', 'Discount Applied Successfully')
  }
  // Function to apply discounts based on existing values; returns no value.
  const applyDisCount = () => {
    setLoading(true)
    if (discountValue?.discountPercentage) {
      commanPercentageDiscountPOS(discountValue?.discountPercentage)
    } else if (discountValue?.discountValue) {
      commanOverAllDiscountPOS(discountValue?.discountValue)
    } else if (discountValue?.discountTotalValue) {
      commanFinalDiscountPOS(discountValue?.discountTotalValue)
    }
    setLoading(false)
  }
  const callClearDiscount = (itemDetails = null, otherincome = null) => {
    if (
      discountValue?.discountPercentage ||
      discountValue?.discountValue ||
      discountValue?.discountTotalValue
    ) {
      clearDiscount(itemDetails, otherincome)
      setCheckedDiscount(false)
    }
  }
  // Function to clear all discounts from items and other income charges; returns no value.
  // function clearDiscount(params, otherincome, isIndividualClear) {
  //   let Discountclear = itemDetailsData?.map((item) => {
  //     let amount = item?.rate * item?.po_qty
  //     if (item?.itemComboBool && item?.itemComboItemDetails) {
  //       item.itemComboItemDetails = calculateItemCombo(item.itemComboItemDetails, amount, item.po_qty);
  //     }
  //     return {
  //       ...item,
  //       afterDiscountValueForPerItem: '',
  //       discountValue: '',
  //       amount: Number(amount).toFixed(2),
  //       discountPercentage: ''
  //     }
  //   })
  //   let DiscountclearOtherIncome = otherIncomeChargesData?.map((item) => {
  //     return {
  //       ...item,
  //       afterDiscountValue: '',
  //       discountValue: ''
  //     }
  //   })
  //   setDiscountValue({
  //     discountError: "", discountPercentage: "", discountValue: "",
  //     discountTotalValue: "", isDiscountApplied: false
  //   })
  //   if (params) {
  //     setItemDetailsData((prevItems, rowIndex) => {
  //       const itemIndex = prevItems.findIndex(item => item?.index === params?.index);
  //       if (itemIndex !== -1) {
  //         const updatedItems = [...Discountclear];
  //         updatedItems[itemIndex] = params;
  //         return updatedItems;
  //       } else {
  //         return [...Discountclear, params];
  //       }
  //     });
  //   } else {
  //     setItemDetailsData(Discountclear)
  //   }
  //   if (otherincome) {
  //     console.log("otherincome in clear ", otherincome);

  //     setOtherIncomeChargesData(otherincome)
  //   } else {
  //     setOtherIncomeChargesData(DiscountclearOtherIncome)
  //   }
  //   setCalculatedDiscount(0.00)
  //   if (!isIndividualClear) {
  //     setCheckedDiscount(false)

  //   }
  //   showErrorToast(true, 'success', "", "Discount Clear Successfully")

  // }
  function clearDiscount (itemdetails, otherincome) {
    const item = itemdetails ? itemdetails : itemDetailsData
    const otherIncome = otherincome ? otherincome : otherIncomeChargesData
    let Discountclear = item?.map(item => {
      let amount = Number(item?.rate) * Number(item?.po_qty)
      if (item?.itemComboBool && item?.itemComboItemDetails) {
        item.itemComboItemDetails = calculateItemCombo(
          item?.itemComboItemDetails,
          amount,
          item.po_qty
        )
      }
      return {
        ...item,
        afterDiscountValueForPerItem: '',
        discountValue: '',
        amount: Number(amount).toFixed(2),
        discountPercentage: '',
        finalValue: ''
      }
    })
    setItemDetailsData(Discountclear)
    let DiscountclearOtherIncome = otherIncome?.map(item => {
      return {
        ...item,
        afterDiscountValue: '',
        discountValue: ''
      }
    })
    setOtherIncomeChargesData(DiscountclearOtherIncome)
    setDiscountValue({
      discountError: '',
      discountPercentage: '',
      discountValue: '',
      discountTotalValue: '',
      isDiscountApplied: false
    })

    showErrorToast(true, 'success', '', 'Discount Cleared Successfully')
  }
  // Fetches options based on user input for different queries like Supplier, Department, Terms, and Sales Person
  async function getOptions (e, query, name) {
    // Function to fetch options based on user input
    if (query === 'SupplierOptionsOptionsQuery') {
      if (name === 'supplierNumber') {
        let peremeter = `supplierNo: "${e}", customer:true `
        const response = await commanFetch(SupplierOptionPerameter(peremeter))
        const reponseData = response?.data?.items
        if (reponseData.length > 0) {
          setSupplierNoList(
            reponseData?.map(item => ({
              value: item?.id,
              label: item?.supplierNo,
              address: item?.address || [],
              companyName: item?.companyName,
              contact: item?.contact
            }))
          )
        }
      } else if (name === 'supplierName') {
        let peremeter = `companyName: "${e}", customer:true `
        const response = await commanFetch(SupplierOptionPerameter(peremeter))
        const reponseData = response?.data?.items
        if (reponseData?.length > 0) {
          setSupplierNameList(
            reponseData?.map(item => ({
              value: item?.id,
              label: item?.companyName,
              address: item?.address || [],
              supplierNo: item?.supplierNo,
              contact: item?.contact
            }))
          )
        }
      }
    }
    if (query === 'DepartmentQuery') {
      let peremeter = `name: "${e}" `
      const response = await commanFetch(DepartmentQuery(peremeter))
      const responseData = response?.data?.items
      if (responseData?.length > 0) {
        setdepartmentList(
          responseData?.map(item => ({ value: item?.id, label: item?.name }))
        )
      }
    }
    if (query === 'TermsConditionsFetchQuery') {
      const response = await commanFetch(TermsConditionsQuotation(e))
      const responseData = response?.data?.items
      setTCList(
        responseData.map(item => ({
          value: item?.id,
          label: item?.name,
          tc: item?.tc
        }))
      )
    }
    if (query === 'salesPerson') {
      let peremeter = `userName:"${e}", isSales: true`
      const response = await commanFetch(userPermissionOptions(peremeter))
      const responseData = response?.data?.items
      setSalesPersonList(
        responseData?.map(user => ({
          value: user?.userId?.id,
          label: user?.userId?.username
        }))
      )
    }
  }
  // Resets the form to its initial state and clears all relevant states
  const resetForm = async () => {
    // Function to reset the form to its initial state

    closeQuotationModal(index)
    setInitialQuotation({
      id: '',
      supplierNumber: '',
      supplierName: '',
      department_id: '',
      remark: '',
      QuatationNumber: '',
      QuotationDate: '',
      termsConditions: '',
      TermsOptions: '',
      childCount: '',
      modifiedBy: '',
      status: '',
      createdBy: {
        id: '',
        name: ''
      },
      createdAt: '',
      createdTime: '',
      isAmend: false,
      isDuplicate: '',
      currencyExchangeRate: '',
      active: ''
    })
    setleadSelect('')
    setOtherIncomeChargesData([])
    seteditView(false)
    setSupplierNoList([])
    setSupplierNoSelected(null)
    setSupplierNameSelected(null)
    setSupplierNameList([])
    setDepartmentSelect([])
    setItemDetailsData([])
    setQuotationInputValue({ address: '', remark: '' })
    setTCList([])
    setTermsOptions(null)
    setTermsConditions(null)
    setDiscountValue({
      discountError: '',
      discountPercentage: '',
      discountValue: '',
      discountTotalValue: '',
      isDiscountApplied: false
    })
    setCheckedDiscount(false)
    setVersionList([])
    setErrorMessage('')
    setquotationAddressContactData({
      buyerAddress: '',
      buyerContactPerson: ''
    })
    setsalesPersonSelect('')
    setSalesPersonList([])
    setCustomerDetailsLists({
      buyerAddressList: [],
      buyerContactList: [],
      buyerStatesList: []
    })
    setcurrencyData('')
    if (setLoad) {
      setLoad()
    }

    // setIsEnableShowDiscount(false)
  }
  // Creates duplicate data with default values for items and other charges
  async function CreateDuplicateData () {
    setItemDetailsData(
      itemDetailsData?.map(item => ({
        ...item,
        id: '',
        createdBy: '',
        modifiedBy: '',
        amount: item?.rate * item?.po_qty,
        afterDiscountValueForPerItem: '',
        discount: '',
        discountPercentage: '',
        discountValue: '',
        finalValue: ''
      }))
    )
    setOtherIncomeChargesData(
      otherIncomeChargesData?.map(item => ({
        ...item,
        id: '',
        createdBy: '',
        modifiedBy: '',
        afterDiscountValue: '',
        discountValue: ''
      }))
    )
    setDiscountValue({
      discountError: '',
      discountPercentage: '',
      discountValue: '',
      discountTotalValue: '',
      isDiscountApplied: false
    })
    seteditView(false)
    setCheckedDiscount(false)
  }
  // Function to duplicate the data for a quotation, handling duplicate flags and related actions
  function duplicaTheData (isDup) {
    setLoading(true)
    setInitialQuotation(prev => ({
      ...prev,
      status: '',
      createdBy: '',
      createdAt: '',
      isDuplicate: isDup,
      parentOrder: initialQuotation?.id,
      QuatationNumber: isDup ? '' : prev?.QuatationNumber,
      QuotationDate: isDup ? currentDateType : prev?.QuotationDate,
      id: ''
    }))
    CreateDuplicateData()
    if (isDup === false) {
      showErrorToast(true, 'success', '', 'Amend Successfully...')
    } else {
      showErrorToast(true, 'success', '', 'Duplicate Successfully...')
    }
    setLoading(false)
  }
  // Handles the form submission for creating or updating a quotation, including item details and other income charges; it doesn't return any value.
  const handleQuotationFromSubmit = async (
    values,
    { resetForm, setErrors }
  ) => {
    setLoading(true)
    // Function to handle the form submission for the quotation
    if (values?.status === 'Draft' || values?.status === 'Submit') {
      if (itemDetailsData.length > 0) {
        // let updatedItemDetails = itemDetailsData.map(item => ({
        //   id: item?.id ? Number(item?.id) : "",
        //   itemmaster: item?.partCode?.value ? Number(item?.partCode?.value) : "",
        //   qty: item?.po_qty,
        //   rate: item?.rate ? item?.rate : "",
        //   description: item?.description ? item?.description : "",
        //   uom: item?.uom?.value ? Number(item?.uom?.value) : "",
        //   tax: item?.gstRates ? item?.gstRates : "",
        //   states: quotationAddressContactData?.buyerAddress?.fullAddredd?.state ? quotationAddressContactData?.buyerAddress?.fullAddredd?.state : "",
        //   amount: item?.amount ? String(item?.amount) : "",
        //   afterDiscountValueForPerItem: item?.afterDiscountValueForPerItem ? String(item?.afterDiscountValueForPerItem) : null,
        //   discountPercentage: item?.discountPercentage ? String(item?.discountPercentage) : null,
        //   discountValue: item?.discountValue ? String(item?.discountValue) : null,
        //   finalValue: item?.finalValue ? String(item?.finalValue) : null,
        //   itemCombo: item?.itemComboBool ? item?.itemComboBool : false,
        //   itemComboItemDetails: item?.itemComboItemDetails ? item?.itemComboItemDetails : null,
        //   itemComboTotalAmount: "0.00",
        //   createdBy: item?.createdBy ? Number(item?.createdBy) : Number(userId),
        //   modifiedBy: item?.modifiedBy ? Number(item?.modifiedBy) : Number(userId),
        //   igst: item?.igst ? String(item?.igst) : null,
        //   cgst: item?.cgst ? String(item?.cgst) : null,
        //   sgst: item?.sgst ? String(item?.sgst) : null,
        // }));
        let updatedItemDetails = itemDetailsData.map(item => {
          const itemComboItemDetails =
            item?.itemComboBool && item?.itemComboItemDetails?.length > 0
              ? item?.itemComboItemDetails?.map(detail => ({
                  id: detail?.id ? detail?.id : null,
                  itemmaster: detail?.itemmasterId
                    ? Number(detail?.itemmasterId)
                    : null,
                  uom: detail?.uom?.value ? Number(detail?.uom?.value) : null,
                  qty: detail?.qty ? String(detail?.qty) : null,
                  rate: detail?.rate ? String(detail?.rate) : null,
                  display: detail?.display ? detail?.display : '',
                  isMandatory: detail?.isMandatory
                    ? detail?.isMandatory
                    : false,
                  afterDiscountValueForPerItem:
                    detail?.afterDiscountValueForPerItem
                      ? String(detail?.afterDiscountValueForPerItem)
                      : null,
                  amount: detail?.amount ? String(detail?.amount) : null,
                  modifiedBy: detail?.modifiedBy
                    ? Number(detail?.modifiedBy)
                    : Number(userId),
                  createdBy: detail?.createdBy
                    ? Number(detail?.createdBy)
                    : Number(userId)
                }))
              : []
          const itemComboTotalAmount = itemComboItemDetails
            ? itemComboItemDetails
                ?.reduce(
                  (sum, detail) => sum + parseFloat(detail?.amount || 0),
                  0
                )
                .toFixed(2)
            : null
          return {
            id: item?.id ? Number(item?.id) : '',
            itemmaster: item?.partCode?.value
              ? Number(item?.partCode?.value)
              : '',
            qty: item?.po_qty,
            rate: item?.rate || '',
            description: item?.description || '',
            uom: item?.uom?.value ? Number(item?.uom?.value) : '',
            tax: item?.gstRates || '',
            states:
              quotationAddressContactData?.buyerAddress?.fullAddredd?.state ||
              '',
            amount: item?.amount ? String(item?.amount) : '',
            afterDiscountValueForPerItem: item?.afterDiscountValueForPerItem
              ? String(item?.afterDiscountValueForPerItem)
              : null,
            discountPercentage: item?.discountPercentage
              ? String(item?.discountPercentage)
              : null,
            discountValue: item?.discountValue
              ? String(item?.discountValue)
              : null,
            finalValue: item?.finalValue ? String(item?.finalValue) : null,
            itemCombo: item?.itemComboBool || false,
            itemComboItemDetails: itemComboItemDetails,
            itemComboTotalAmount: itemComboTotalAmount,
            hsn: Number(item?.hsnId),
            createdBy: item?.createdBy
              ? Number(item?.createdBy)
              : Number(userId),
            modifiedBy: item?.modifiedBy
              ? Number(item?.modifiedBy)
              : Number(userId),
            igst: item?.igst ? String(item?.igst) : null,
            cgst: item?.cgst ? String(item?.cgst) : null,
            sgst: item?.sgst ? String(item?.sgst) : null
          }
        })
        let updatedOtherincome = otherIncomeChargesData?.map(item => ({
          id: item?.id ? Number(item?.id) : '',
          otherIncomeChargesId: item?.otherIncomeChargesId?.value
            ? Number(item?.otherIncomeChargesId?.value)
            : '',
          tax: item?.otherIncomeChargesId?.tax
            ? item?.otherIncomeChargesId?.tax
            : '',
          amount: item?.amount ? item?.amount?.toString() : null,
          states: quotationAddressContactData?.buyerAddress?.fullAddredd?.state
            ? quotationAddressContactData?.buyerAddress?.fullAddredd?.state
            : '',
          afterDiscountValue: item?.afterDiscountValue
            ? String(item?.afterDiscountValue)
            : null,
          discountValue: item?.discountValue
            ? String(item?.discountValue)
            : null,
          igst: item?.igst ? String(item?.igst) : null,
          cgst: item?.cgst ? String(item?.cgst) : null,
          sgst: item?.sgst ? String(item?.sgst) : null,
          createdBy: item?.createdBy ? Number(item?.createdBy) : Number(userId),
          modifiedBy: item?.modifiedBy
            ? Number(item?.modifiedBy)
            : Number(userId)
        }))
        let requestBody = {
          id: values?.id ? Number(values?.id) : '',
          quotationDate: values?.QuotationDate ? values?.QuotationDate : '',
          itemTotalBeforTax: toSaveData?.beforeTax,
          cgst: toSaveData.cgst,
          createdBy: values?.createdBy?.id
            ? Number(values?.createdBy?.id)
            : Number(userId),
          customerAddress: Number(values?.buyerAddress),
          customerContactPerson: Number(values?.buyerContactPerson),
          salesPerson: values?.salesPerson ? values?.salesPerson : '',
          customerId: Number(supplierNoSelected?.value),
          leadNo: initialQuotation?.LeadNumber
            ? Number(initialQuotation?.LeadNumber)
            : '',
          department: Number(values?.department_id),
          roundOff: toSaveData?.roundOff,
          discountFinalTotal: discountValue?.discountTotalValue
            ? discountValue?.discountTotalValue
            : '',
          igst: toSaveData.igst,
          parentOrder: '',
          childCount: '',
          itemDetails: updatedItemDetails,
          netAmount: toSaveData?.netAmount,
          otherIncomeCharge: updatedOtherincome,
          overallDiscountPercentage: discountValue?.discountPercentage
            ? discountValue?.discountPercentage
            : '',
          overallDiscountValue: discountValue?.discountValue
            ? discountValue?.discountValue
            : '',
          remarks: values?.remark,
          sgst: toSaveData?.sgst,
          status: values?.status ? values?.status : 'Draft',
          taxTotal: toSaveData?.totaltax,
          otherChargesBeforTax: toSaveData?.otherIncomeChagersBeforeTax,
          termsConditions: Number(values?.TermsOptions),
          termsConditionsText: values?.termsConditions,
          currency: Number(values?.Currency)
        }
        if (values?.isAmend) {
          requestBody.id = ''
          requestBody.createdBy = Number(userId)
          requestBody.parentOrder = initialQuotation?.parentOrder
            ? Number(initialQuotation?.parentOrder)
            : ''
          requestBody.childCount =
            initialQuotation.childCount > 0
              ? Number(initialQuotation?.childCount) + 1
              : 1
          requestBody.modifiedBy = ''
          requestBody.status = 'Submit'
        }
        let allow_null = [
          'id',
          'modifiedBy',
          'overallDiscountPercentage',
          'overallDiscountValue',
          'discountFinalTotal',
          'remarks',
          'parentOrder',
          'childCount',
          'leadNo',
          'itemComboTotalAmount',
          'quotationDate'
        ]
        let clear_data = removeEmptyValueInObject(
          requestBody,
          allow_null,
          [],
          false,
          ['itemDetails', 'otherIncomeCharge']
        )
        try {
          const respones = await axiosInstance.post(`/itemmaster/graphql`, {
            query: quotationCreateMutation(clear_data, allow_null)
          })
          const responseData = respones?.data?.data?.quotationsCreateMutation
          const versionData = responseData?.version
            ? responseData?.version?.versionList
            : []
          setVersionList(versionData)
          if (responseData?.success === true) {
            const {
              id,
              quotationNo,
              quotationDate,
              CreatedAt,
              createdBy,
              remarks,
              customerAddress,
              customerId,
              department,
              itemDetails,
              otherIncomeCharge,
              status,
              salesPerson,
              customerContactPerson,
              termsConditions,
              termsConditionsText,
              discountFinalTotal,
              overallDiscountValue,
              overallDiscountPercentage,
              active,
              currency,
              leadNo,
              childCount
            } = responseData?.quotations
            const linkedModelId = quotationNo?.linkedModelId || ''
            const createdAt = formateGivenStringdate(CreatedAt)
            const address = {
              addressLine1: customerAddress?.addressLine1 || '',
              addressLine2: customerAddress?.addressLine2 || '',
              city: customerAddress?.city || '',
              state: customerAddress?.state || '',
              pincode: customerAddress?.pincode || '',
              id: customerAddress?.id || ''
            }
            setCurrentVersionId(id)
            setInitialQuotation(prevState => ({
              ...prevState,
              id: id,
              QuatationNumber: linkedModelId,
              QuotationDate: quotationDate,
              remark: remarks || '',
              department_id: department?.id,
              TermsOptions: termsConditions?.id,
              termsConditions: termsConditionsText,
              status: status?.name,
              supplierNumber: customerId?.supplierNo,
              supplierName: customerId?.companyName,
              createdBy: {
                id: createdBy?.id,
                name: createdBy?.username
              },
              createdAt: createdAt,
              createdTime: TimeFormate(CreatedAt),
              childCount: childCount,
              isAmend: false,
              isDuplicate: false,
              salesPerson: salesPerson ? Number(salesPerson?.id) : '',
              buyerAddress: customerAddress ? Number(customerAddress?.id) : '',
              buyerContactPerson: customerContactPerson
                ? Number(customerContactPerson?.id)
                : '',
              active: active,
              LeadNumber: leadNo ? leadNo?.id : '',
              Currency: currency?.id ? currency?.id : '',
              currencyExchangeRate: currency?.rate
            }))
            setQuotationInputValue(prevState => ({
              ...prevState,
              address: address
            }))

            setSupplierNameSelected({
              value: customerId?.id,
              label: customerId?.companyName
            })

            setSupplierNoSelected({
              value: customerId?.id,
              label: customerId?.supplierNo
            })

            setDepartmentSelect({
              value: department?.id,
              label: department?.name
            })
            setTermsOptions({
              value: termsConditions?.id,
              label: termsConditions?.name
            })
            setleadSelect(leadNo?.leadNo)
            setsalesPersonSelect({
              value: salesPerson?.id,
              label: salesPerson?.username
            })
            setquotationAddressContactData({
              buyerAddress: {
                value: customerAddress?.id,
                label: customerAddress?.addressType,
                fullAddredd: customerAddress
              },
              buyerContactPerson: {
                value: customerContactPerson?.id,
                label: customerContactPerson?.contactPersonName,
                mobile: customerContactPerson?.phoneNumber,
                Email: customerContactPerson?.email
              }
            })
            setDiscountValue(prev => ({
              ...prev,
              discountPercentage: overallDiscountPercentage
                ? overallDiscountPercentage
                : '',
              discountValue: overallDiscountValue ? overallDiscountValue : '',
              discountTotalValue: discountFinalTotal ? discountFinalTotal : ''
            }))
            // setSalesPersonList({ value: salesPerson?.id, label: salesPerson?.username })
            if (
              overallDiscountPercentage ||
              overallDiscountValue ||
              discountFinalTotal
            ) {
              setCheckedDiscount(true)
              setDiscountValue(prev => ({ ...prev, isDiscountApplied: true }))
            }
            setTermsConditions(termsConditionsText)
            setcurrencyData({
              currencySymbol: currency?.Currency?.currencySymbol,
              label: currency?.Currency?.name,
              rate: currency?.rate,
              value: currency?.Currency?.id
            })
            setcurrencySelect({
              value: currency?.id,
              label: currency?.Currency?.name,
              rate: currency?.Currency?.rate,
              currencySymbol: currency?.Currency?.currencySymbol
            })
            let formattedItemDetails = []
            let transformedOtherIncomeChargesData = []
            if (itemDetails && Array.isArray(itemDetails)) {
              formattedItemDetails = itemDetails.map((item, rowIndex) => ({
                afterDiscountValueForPerItem:
                  item?.afterDiscountValueForPerItem || '',
                amount: item?.amount ? Number(item?.amount).toFixed(2) : '',
                category: item?.itemmaster?.category?.name || '',
                description: item?.description || '',
                discount: item?.discountPercentage
                  ? Number(item?.discountPercentage).toFixed(2)
                  : Number(item?.discountValue).toFixed(2),
                discountPercentage: item?.discountPercentage
                  ? Number(item?.discountPercentage).toFixed(2)
                  : '',
                discountValue: item?.discountValue
                  ? Number(item?.discountValue).toFixed(2)
                  : '',
                finalValue: item?.finalValue
                  ? Number(item?.finalValue).toFixed(2)
                  : '',
                gstRates: item?.itemmaster?.itemHsn?.gstRates?.rate || '',
                hsnCode: item?.itemmaster?.itemHsn?.hsnCode || '',
                hsnId: item?.itemmaster?.itemHsn?.id || '',
                id: item?.id || '',
                index: rowIndex + 1,
                itemComboBool: item?.itemCombo || false,
                itemComboItemDetails:
                  parseAndGroupItemComboData(
                    item?.itemComboItemDetails,
                    rowIndex + 1
                  ) || [],
                itemMrp: item?.itemmaster?.itemMrp || null,
                partCode:
                  {
                    value: item?.itemmaster?.id,
                    label: item?.itemmaster?.itemPartCode
                  } || '',
                partName:
                  {
                    value: item?.itemmaster?.id,
                    label: item?.itemmaster?.itemName
                  } || '',
                rate: item?.rate || null,
                uom: {
                  value: item?.uom?.id || '',
                  label: item?.uom?.name || ''
                },
                po_qty: Number(item?.qty) || 0,
                igst: item?.igst ? item?.igst : null,
                cgst: item?.cgst ? item?.cgst : null,
                sgst: item?.sgst ? item?.sgst : null,
                createdBy: item?.createdBy?.id
                  ? Number(item?.createdBy?.id)
                  : '',
                modifiedBy: item?.modifiedBy?.id
                  ? Number(item?.modifiedBy?.id)
                  : ''
              }))

              setItemDetailsData(formattedItemDetails)
            }
            if (otherIncomeCharge && Array.isArray(otherIncomeCharge)) {
              transformedOtherIncomeChargesData = otherIncomeCharge.map(
                (item, index) => ({
                  index: index + 1,
                  id: item?.id || '',
                  amount: item?.amount ? Number(item?.amount).toFixed(2) : null,
                  afterDiscountValue: item?.afterDiscountValue
                    ? Number(item?.afterDiscountValue).toFixed(2)
                    : null,
                  discountValue: item?.discountValue
                    ? Number(item?.discountValue).toFixed(2)
                    : null,
                  otherIncomeChargesId: {
                    value: item?.otherIncomeChargesId?.id || '',
                    label: item?.otherIncomeChargesId?.name || '',
                    tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate || 0
                  },
                  igst: item?.igst ? item?.igst : null,
                  cgst: item?.cgst ? item?.cgst : null,
                  sgst: item?.sgst ? item?.sgst : null,
                  tax: item?.otherIncomeChargesId?.hsn?.rate || 0,
                  hsnCode: item?.otherIncomeChargesId?.hsn?.hsnCode,
                  createdBy: item?.createdBy?.id
                    ? Number(item?.createdBy?.id)
                    : '',
                  modifiedBy: item?.modifiedBy?.id
                    ? Number(item?.modifiedBy?.id)
                    : ''
                })
              )
              setOtherIncomeChargesData(transformedOtherIncomeChargesData)
            }
            setSalesOrderData({
              Quotation: { id: id, QuatationNumber: linkedModelId },
              Department: { value: department?.id, label: department?.name },
              SalesPerson: {
                value: salesPerson?.id,
                label: salesPerson?.username
              },
              CustomerCode: {
                value: customerId?.id,
                label: customerId?.supplierNo
              },
              lead: leadNo,
              overallDiscountPercentage: overallDiscountPercentage,
              overallDiscountValue: overallDiscountValue,
              discountFinalTotal: discountFinalTotal,
              currency: {
                value: currency?.id,
                label: currency?.Currency?.name,
                Symbol: currency?.Currency?.currencySymbol
              },
              itemData: formattedItemDetails,
              otherIncomeData: transformedOtherIncomeChargesData
            })
            seteditView(
              status?.name === 'Submit' || status?.name === 'Cancel'
                ? true
                : false
            )
            setFormKey(prve => prve + 1)
            showErrorToast(
              true,
              'success',
              '',
              'Quotation Saved Successfully...'
            )

            if (setQuotationsList) {
              if (setQuotationsList) {
                setQuotationsList(prev => {
                  if (!Array.isArray(prev)) {
                    return [responseData?.quotations] // Initialize with the new quotation if `prev` is not an array
                  }

                  const updatedList = prev.map(quotation =>
                    quotation.id === responseData?.quotations.id
                      ? responseData?.quotations
                      : quotation
                  )

                  const isUpdated = updatedList.some(
                    quotation => quotation.id === responseData?.quotations.id
                  )

                  return isUpdated
                    ? updatedList
                    : [...updatedList, responseData?.quotations]
                })
              }
            }
            resetForm()
          } else {
            if (responseData?.outOfRangeErrors?.length > 0) {
              const errorMessages = responseData?.outOfRangeErrors.join(', \n')
              setShowWarningModalWithCopy(true)
              setErrorMessagesWarningModalWithCopy(errorMessages)
            } else {
              let errorData = ShowErrorNoties(responseData?.errors)
              showErrorToast(true, 'error', errorData)
            }
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          let errorData = ShowErrorNoties(error)
          showErrorToast(true, 'error', errorData)
        }
      } else {
        showErrorToast(true, 'error', 'Select Atleast One Item Details ...')
      }
    }
    setLoading(false)
  }
  // Function to check data before closing and reset the form.
  function BeforeCloseCheckData (params) {
    // if (initialQuotation?.id === "" && (itemDetailsData?.length > 0 || otherIncomeChargesData?.length > 0)) {
    //   setwarningAmandCancelShow(true)
    //   setErrorMessage("Are you sure you want to leave?")
    // } else {
    //   resetForm()
    // }
    resetForm()
  }
  // Function to update item and other income charge data based on the state for tax calculations; returns no value.
  async function QuotationBulkUpdateForTaxmutations (state) {
    const updatedItem = itemDetailsData?.map(item => {
      if (state === 'Tamil Nadu' || state === 'Tamilnadu') {
        item['sgst'] = item?.gstRates / 2
        item['cgst'] = item?.gstRates / 2
        item['igst'] = 0
      } else {
        item['sgst'] = 0
        item['cgst'] = 0
        item['igst'] = item?.gstRates
      }
      return item
    })
    setItemDetailsData(updatedItem)
    const updatedOtherIncomeCharge = otherIncomeChargesData?.map(item => {
      if (state === 'Tamil Nadu' || state === 'Tamilnadu') {
        item['sgst'] = item?.otherIncomeChargesId?.tax / 2
        item['cgst'] = item?.otherIncomeChargesId?.tax / 2
        item['igst'] = 0
      } else {
        item['sgst'] = 0
        item['cgst'] = 0
        item['igst'] = item?.otherIncomeChargesId?.tax
      }
      return item
    })
    setOtherIncomeChargesData(updatedOtherIncomeCharge)
    showErrorToast(true, 'success', '', 'Updated Successfully....')
  }
  // return null; convertToSalesOrder
  function CreateSalesOrder (params) {
    openSalesOrderModal()
  }
  // return null; Cancel the Quotations
  async function CancelTheQuotations () {
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query: QuotationsCancel(`id:${initialQuotation?.id}`)
      })
      let reponseData =
        response?.['data']?.['data']?.['quotationsCanceledMutation']
      if (reponseData?.success) {
        setInitialQuotation(prev => ({ ...prev, status: 'Canceled' }))
      } else {
        showErrorToast(true, 'error', reponseData?.errors)
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter)
    }
  }
  // return null; Delete the Quotations
  async function DeleteTheQuotations () {
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query: quotationsDeleteMutation(`id:${initialQuotation?.id}`)
      })
      let reponseData =
        response?.['data']?.['data']?.['quotationsDeleteMutation']
      if (reponseData?.success) {
        if (setQuotationsList) {
          if (setQuotationsList) {
            setQuotationsList(prev => {
              return prev.filter(
                quotation => quotation?.id !== initialQuotation?.id
              )
            })
          }
        }
        resetForm()
      } else {
        showErrorToast(true, 'error', reponseData?.errors)
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter)
    }
  }
  //Generate PDF
  async function genPDF () {
    setLoading(true)
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query: quotationsgeneratePDF(`id:${initialQuotation?.id}`)
      })
      const reponseData = response?.data?.data
      if (reponseData?.generatePDF?.success) {
        const base64Data = reponseData.generatePDF.pdfData

        // Convert base64 string to a Blob
        const byteCharacters = atob(base64Data)
        const byteNumbers = new Uint8Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const blob = new Blob([byteNumbers], { type: 'application/pdf' })
        // vnd.openxmlformats-officedocument.wordprocessingml.document

        // Create a link element for downloading
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.target = '_blank'
        link.click() // Trigger the download

        // Clean up
        window.URL.revokeObjectURL(link.href)
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter, '')
      return false
    }
    setLoading(false)
  }
  // fetch time line Datas
  async function fetchTimelineData (id) {
    // setLoading(true)
    try {
      const respones = await axiosInstance.post(`/itemmaster/graphql`, {
        query: leadTimeLine(id)
      })
      let timeLime = respones?.data?.data?.leadTimeLine
      setTimeLineValue(prev => ({
        ...prev,
        treeData: [timeLime],
        isShow: true
      }))
    } catch (error) {
      console.log(error)
    }
    // setLoading(false)
  }
  // Function to open the appropriate modal based on the provided ID and model type; returns no value.
  function getOpenmodelId (id, model) {
    if (id && model === 'Quotation') {
      openQuotationModal(id)
    } else if (id && model === 'Sales Order') {
      openSalesOrderModal(id)
    } else if (id && model === 'Lead') {
      openLeadModal(id)
    }
  }
  // Function to handle the deletion of an item from other income charges; returns no value.
  const handleDeletedIdotherincome = deleted_id => {
    if (deleted_id) {
      setOtherIncomeChargesData(prevRowData =>
        prevRowData.filter(row => row.id !== deleted_id)
      )
      setotherIncomeChargesvalues(prev => ({
        ...prev,
        onNewItemSelect: false,
        deleteData: { Name: '', id: '' }
      }))
      setisSaveOtherIncomeCharges(true)
    }
  }
  // Function to convert quotation amounts based on currency rates; returns a boolean indicating success.
  async function quotationCurrencyConvertion (CurrencyId, previousCurrencyId) {
    setLoading(true)

    const currentRate = Number(CurrencyId?.rate)
    const previousRate = Number(previousCurrencyId?.rate)

    // Conversion helper function to round values to nearest 10
    // function roundToNearestTen(value) {
    //   return Math.round(value / 10) * 10;
    // }

    // Update itemDetailsData based on the currency conversion
    const updatedItemDetailsData = itemDetailsData?.map(item => {
      const actualRate = item?.amount ? Number(item?.amount).toFixed(2) : 0
      let convertedRate
      if (CurrencyId.label === 'Rupee') {
        const tempInRupee = actualRate * previousRate
        convertedRate = tempInRupee
      } else if (
        CurrencyId.label !== 'Rupee' &&
        previousCurrencyId?.label !== 'Rupee'
      ) {
        const tempInRupee = actualRate * previousRate
        convertedRate = tempInRupee / currentRate
      } else {
        const tempInRupee = actualRate / currentRate
        convertedRate = tempInRupee
      }
      const finalRate = convertedRate
      return {
        ...item,
        discount: '',
        discountPercentage: '',
        discountValue: '',
        afterDiscountValueForPerItem: '',
        rate: finalRate.toFixed(2),
        amount: (finalRate * Number(item.po_qty)).toFixed(2)
      }
    })
    const updatedOtherIncomeChargesData = otherIncomeChargesData?.map(item => {
      let valuesInRupees = item?.amount
      let resultAmount
      if (CurrencyId.label === 'Rupee') {
        const tempInRupee = valuesInRupees * previousRate
        resultAmount = tempInRupee
      } else if (
        CurrencyId.label !== 'Rupee' &&
        previousCurrencyId?.label !== 'Rupee'
      ) {
        const tempInRupee = valuesInRupees * previousRate
        resultAmount = tempInRupee / currentRate
      } else {
        const tempInRupee = valuesInRupees / currentRate
        resultAmount = tempInRupee
      }
      const finalAmount = resultAmount
      return {
        ...item,
        afterDiscountValue: '',
        discountValue: '',
        amount: Number(finalAmount).toFixed(2) // Update the amount
      }
    })
    setOtherIncomeChargesData(updatedOtherIncomeChargesData)
    setItemDetailsData(updatedItemDetailsData)

    // Reset discount-related state
    setDiscountValue({
      discountError: '',
      discountPercentage: '',
      discountValue: '',
      discountTotalValue: '',
      isDiscountApplied: false
    })

    setCheckedDiscount(false)
    setLoading(false)
    showErrorToast(true, 'success', '', 'Currency Converted Successfully....')
    return true
  }
  // Function to render the rate cell for items with discounts applied; returns a JSX element.
  const ratecellrender = params => {
    let discountPercentage =
      params?.discountPercentage !== undefined ? params.discountPercentage : 0
    let discountValue =
      params?.discountValue !== undefined && params?.discountValue !== ''
        ? params.discountValue
        : ''
    let afterDiscountValue =
      params?.afterDiscountValueForPerItem !== undefined
        ? params.afterDiscountValueForPerItem
        : 0
    let finalValue = params?.finalValue !== undefined ? params.finalValue : 0
    let rate = params?.rate !== undefined ? params.rate : 0
    let currencyLabel = currencyData?.label
    return (
      <>
        {displayRupeeFormat(rate, currencyLabel)}
        {Number(discountPercentage) !== 0
          ? ` - ${discountPercentage}% = ${displayRupeeFormat(
              afterDiscountValue,
              currencyLabel
            )}`
          : Number(discountValue) !== 0
          ? ` - ${displayRupeeFormat(
              discountValue,
              currencyLabel
            )} = ${displayRupeeFormat(afterDiscountValue, currencyLabel)}`
          : Number(finalValue) !== 0
          ? `- ${displayRupeeFormat(
              finalValue,
              currencyLabel
            )} =${displayRupeeFormat(afterDiscountValue, currencyLabel)} `
          : ''}
      </>
    )
  }
  // Function to render the rate cell for other income charges with discounts applied; returns a JSX element.
  const ratecellrenderOtherIncome = params => {
    let discountValue = params?.discountValue ? params?.discountValue : 0
    let afterDiscountValue =
      params?.afterDiscountValue !== undefined ? params?.afterDiscountValue : 0
    let amount = params?.amount !== undefined ? params?.amount : 0
    let currencyLabel = currencyData?.label // Default to "Rupee" if no currency label is provided.

    return (
      <>
        {discountValue
          ? `${displayRupeeFormat(
              amount,
              currencyLabel
            )} - ${displayRupeeFormat(
              discountValue,
              currencyLabel
            )} = ${displayRupeeFormat(afterDiscountValue, currencyLabel)}`
          : displayRupeeFormat(amount, currencyLabel)}
      </>
    )
  }
  // Function to handle item combo logic based on provided data; returns no value.
  const itemDetailsAction = data => {
    const handleItemCombo = async () => {
      try {
        if (
          Array.isArray(data?.itemComboItemDetails) &&
          data?.itemComboItemDetails?.length === 0
        ) {
          const reqBody = {
            itemmaster: Number(data?.itemmaster),
            amount: String(data?.amount),
            qty: String(data?.po_qty)
          }
          const response = await axiosInstance.post(`/itemmaster/graphql`, {
            query: getItemComboDetails(reqBody)
          })
          const responseData =
            response?.data?.data?.getItemComboItemDetails?.itemComboData
          const groupedData = parseAndGroupItemComboData(
            responseData,
            data?.index
          )
          setitemDetailsValues(prev => ({
            ...prev,
            itemComboData: groupedData,
            lastUpdatedItemDetails: data
          }))
          setShowItemCombo(true)
        } else {
          setitemDetailsValues(prev => ({
            ...prev,
            itemComboData: data?.itemComboItemDetails,
            lastUpdatedItemDetails: data
          }))
        }
        setShowItemCombo(true)
      } catch (error) {
        console.log(error)
        const errorData = ShowErrorNoties(error)
        showErrorToast(true, 'error', errorData)
      }
    }
    // const handleItemCombo = async () => {
    //   try {
    //     if (Array.isArray(data?.itemComboItemDetails) && data?.itemComboItemDetails.length === 0) {
    //       const reqBody = {
    //         itemmaster: data?.itemmaster?.id,
    //         amount: String(data?.amount),
    //         qty: String(data?.qty)
    //       }
    //       const response = await axiosInstance.post(`/itemmaster/graphql`, {
    //         query: getItemComboDetails(reqBody)
    //       });
    //       console.log("getItemComboDetails", response);

    //       const responseData = response?.data?.data?.getItemComboItemDetails?.itemComboData;
    //       console.log("responseData", responseData);
    //       const groupedData = parseAndGroupItemComboData(responseData);
    //       setitemDetailsValues(prev => ({
    //         ...prev,
    //         itemComboData: groupedData,
    //         lastUpdatedItemDetails: data
    //       }))
    //       setShowItemCombo(true)
    //     }
    //     else {
    //       setItemComboData(data?.itemComboItemDetails?.map((item) => ({
    //         edit: true,
    //         isSelected: true,
    //         afterDiscountValueForPerItem: item?.afterDiscountValueForPerItem,
    //         amount: item?.amount,
    //         id: item?.id,
    //         display: item?.display,
    //         isMandatory: item?.isMandatory,
    //         qty: item?.qty,
    //         rate: item?.rate,
    //         itemmasterId: item?.itemmaster?.id,
    //         partCode: item?.itemmaster?.itemPartCode,
    //         partName: item?.itemmaster?.itemName,
    //         uom: {
    //           label: item?.uom?.name,
    //           value: item?.uom?.id
    //         }
    //       })))
    //       setIsSaveItemCombo(true)
    //       setShowItemCombo(true)
    //     }

    //   }

    //   catch (error) {
    //     console.log(error);
    //     const errorData = ShowErrorNoties(error);
    //     showErrorToast(true, 'error', errorData);
    //   }

    const showStock = () => {
      handleShowStockStatementModal(data)
    }
    return (
      <div style={{ alignItems: 'left' }}>
        {/* {data?.itemComboBool === false &&
            <button
              type='button'
              className='btn btn-outline-success btn-sm px-3 mx-2'
              onClick={() => {
                setitemDetailsValues(prev => ({
                  ...prev,
                  ItemEditData: data,

                }))
                setItemEditShow(true)
                setQuotationItemDetailsShow(true)
              }}
              disabled={editView}
            >
              <i className='fa-solid fa-pen'></i>
            </button>
          } */}
        <button
          type='button'
          className='btn btn-outline-danger btn-sm px-3 mx-2'
          onClick={() => {
            setitemDetailsValues(prev => ({
              ...prev,
              deleteData: {
                Name: data?.itemmaster?.itemPartCode,
                id: data.id
              },
              deleteIndex: data?.index
            }))
            setisItemDeleteWarningShow(true)
          }}
          disabled={editView}
        >
          <i className='fa-solid fa-trash '></i>
        </button>
        <AiOutlineShoppingCart
          style={{ cursor: 'pointer' }}
          className='fs-3'
          onClick={() => {
            showStock()
          }}
        />
        {/* {data?.itemComboBool === true &&
          <span className='ms-4 px-3 mx-2'
            onClick={handleItemCombo}>
            <LuGift
              style={{
                cursor: 'pointer',
                color: data?.itemComboItemDetails?.length === 0 ? 'brown' : 'green'
              }}
            />
          </span>
        } */}
        {data?.itemComboBool && data?.itemComboItemDetails?.length > 0 ? (
          <button
            type='button'
            className='btn btn-outline-info btn-sm px-3 mx-2'
            onClick={handleItemCombo}
            disabled={editView}
          >
            <LuGift />
          </button>
        ) : data?.itemComboBool ? (
          <button
            type='button'
            className='btn btn-outline-warning btn-sm px-3 mx-2'
            onClick={handleItemCombo}
            disabled={editView}
          >
            <LuGift />
          </button>
        ) : (
          ''
        )}
        {/* {data?.itemComboBool === false &&
            <span className='ms-4 px-3 mx-2'>
              <AiOutlineShoppingCart
                style={{ cursor: 'pointer' }}
                className='fs-3'
                onClick={() => {
                  showStock()
                }}
              />
            </span>
          } */}
      </div>
    )
  }
  const itemDetailsEdit = params => {    
    const renderTooltip = props => {
      // Conditional rendering within the function
      if (params?.itemComboBool === true) {
        return (
          <Tooltip id="button-tooltip" {...props}>
            For Changes In Combo Item "Delete & Add" !
          </Tooltip>
        )
      }
      return !editView ? (
        <Tooltip id='button-tooltip' {...props}>
          Click here to edit
        </Tooltip>
      ) : (
        <Tooltip id='button-tooltip' {...props}>
          Click Edit Button To enable Editing
        </Tooltip>
      )
    }
    return (
      <>
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <p
            className='text-primary'
            style={{ cursor: 'pointer', marginTop: '1rem' }}
            onClick={() => {
              if (params?.itemComboBool === true || editView) {
                return
              }
              setitemDetailsValues(prev => ({
                ...prev,
                ItemEditData: params
              }))
              setItemEditShow(true)
              setQuotationItemDetailsShow(true)
            }}
          >
            {params?.description}
          </p>
        </OverlayTrigger>
      </>
    )
  }
  const columns = [
    {
      headerName: 'S.No',
      flex: 8,
      textAlign: 'center',
      input: 'S No'
    },
    {
      headerName: 'Description',
      // field: 'description',
      flex: 35,
      textAlign: 'left',
      cellRenderer: itemDetailsEdit
    },
    {
      headerName: 'HSN',
      field: 'hsnCode',
      textAlign: 'center',
      flex: 15
    },
    {
      headerName: ' Qty',
      field: 'po_qty',
      textAlign: 'center',
      flex: 10
    },
    {
      headerName: 'UOM',
      field: 'uom',
      textAlign: 'center',
      label: 'label',
      flex: 15
    },
    {
      headerName: 'Rate',
      textAlign: 'right',
      cellRenderer: ratecellrender,
      field: 'rate',
      flex: 30
    },
    {
      headerName: 'Tax',
      field: 'gstRates',
      textAlign: 'center',
      flex: 10
    },
    {
      headerName: 'Amount',
      flex: 20,
      textAlign: 'right',
      cellRenderer: params =>
        displayRupeeFormat(params?.amount, currencyData?.label)
    },
    {
      headerName: 'Action',
      flex: 15,
      textAlign: 'left',
      cellRenderer: itemDetailsAction
    }
  ]
  const otherIncomeChargesColumns = [
    {
      headerName: 'Account',
      flex: 5,
      field: 'otherIncomeChargesId',
      label: 'label'
    },
    {
      headerName: 'Tax',
      flex: 5,
      field: 'otherIncomeChargesId',
      label: 'tax'
    },
    {
      headerName: 'Amount',
      flex: 10,
      textAlign: 'right',
      cellRenderer: ratecellrenderOtherIncome
    },
    {
      headerName: 'Actions',
      flex: 5,
      active: false
    }
  ]
  // Function to fetch stock statement details for a given item; returns no value.
  const handleShowStockStatementModal = async data => {
    try {
      // Set loading state to true if needed
      setLoading(true)

      // Perform the API call
      const response = await axiosInstance.post('/itemmaster/graphql', {
        query: stockIdFetchQuery(data?.['itemmaster']?.['id'])
      })

      let stock_ids = []
      stock_ids = response?.data?.data?.stockIds?.items || []

      // Construct the stock details dictionary
      let construct_stock_dict = {
        stockId: [],
        itemPartCode: data?.partCode?.itemPartCode,
        itemName: data?.partCode?.itemName,
        description: data?.description,
        batchNumber: '',
        serial: ''
      }

      // If stock ids are available, extract values
      if (stock_ids.length > 0) {
        construct_stock_dict['partCode'] =
          stock_ids[0]?.partNumber?.['itemPartCode']
        construct_stock_dict['partName'] =
          stock_ids[0]?.partNumber?.['itemName']
        construct_stock_dict['description'] =
          stock_ids[0]?.partNumber?.['description']
        construct_stock_dict['isBatch'] =
          stock_ids[0]?.partNumber?.['batchNumber']
        construct_stock_dict['isSerial'] = stock_ids[0]?.partNumber?.['serial']
      }

      // Map stock ids and update the state
      construct_stock_dict['stockId'] = stock_ids.map(item => item.id)

      setitemDetailsValues(prev => ({
        ...prev,
        selectedStockStatementDetails: construct_stock_dict
      }))

      // Show the modal
      setShowStockStatementModal(true)

      // Update selected part number
      setitemDetailsValues(prev => ({
        ...prev,
        selectedPartNo: data?.['itemmaster']?.['id']
      }))
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error)
    } finally {
      // Ensure loading state is reset regardless of success or failure
      setLoading(false)
    }
  }

  // Function to close the stock statement modal; returns no value.
  const handleCloseStockStatementModal = () => {
    setShowStockStatementModal(false)
  }
  // Function to delete an item from the item details; returns no value.
  async function DeleteTheItem () {
    // Store the filtered data in a variable
    const item = itemDetailsData.filter(
      item => item.index !== itemDetailsValues?.deleteIndex
    )
    // Now update the state using the filtered data
    setItemDetailsData(item)
    callClearDiscount(item, null)
    // Reset deleteData and deleteIndex in the state
    setitemDetailsValues(prev => ({
      ...prev,
      deleteData: { Name: '', id: '' },
      deleteIndex: ''
    }))
  }
  // Function to set up deletion confirmation for other income charges; returns no value.
  const handleDeleteOtherIncome = data => {
    setDeleteOtherIncomeData({
      deleteIndex: data?.index,
      deleteMessage: `Are You Sure Want To Delete ${data?.otherIncomeChargesId?.label}`
    })
    setIsDeleteOtherIncomeCharges(true)
  }
  // Component for rendering the input form for other income charges; returns a JSX element.
  const OtherIncomeChargesInputForm = ({
    itemData,
    setitemData,
    editData,
    toggle,
    setEditingRow
  }) => {
    const initialValues = {
      index: itemData?.index,
      id: editData?.id ? Number(editData?.id) : '',
      otherIncomeChargesId: editData?.otherIncomeChargesId?.value
        ? {
            value: editData?.otherIncomeChargesId?.value,
            label: editData?.otherIncomeChargesId?.label,
            tax: editData?.otherIncomeChargesId?.tax,
            hsn: editData?.otherIncomeChargesId?.hsn,
            hsnCode: editData?.otherIncomeChargesId?.hsnCode
          }
        : '',
      amount: editData?.amount ? Number(editData?.amount).toFixed(2) : '',
      tax: editData?.otherIncomeChargesId?.tax
        ? Number(editData?.otherIncomeChargesId?.tax)
        : '',
      afterDiscountValue: editData?.afterDiscountValue
        ? editData?.afterDiscountValue
        : '',
      discountValue: editData?.discountValue ? editData?.discountValue : '',
      createdBy: editData?.createdBy?.id
        ? Number(editData?.createdBy?.id)
        : Number(userId),
      hsnCode: editData?.otherIncomeChargesId?.hsnCode,
      modifiedBy: editData?.id
        ? editData?.modifiedBy?.id
          ? Number(editData?.modifiedBy?.id)
          : Number(userId)
        : ''
    }
    const [otherIncomeChargesValues, setOtherIncomeChargesValues] = useState(
      editData?.otherIncomeChargesId?.value
        ? {
            value: editData?.otherIncomeChargesId?.value,
            label: editData?.otherIncomeChargesId?.label,
            tax: editData?.otherIncomeChargesId?.tax
          }
        : { value: '', label: '' }
    )
    const handleSubmitOtherExpenses = values => {
      const validationResponse = checkNegativeNumber(values, [
        'amount',
        'tax',
        'afterDiscountValue',
        'discountValue'
      ])
      if (validationResponse) {
        showErrorToast(true, 'error', 'Value Should Not Be Negative', '')
        return
      }
      if (
        quotationAddressContactData?.buyerAddress?.fullAddredd?.state ===
          'Tamil Nadu' ||
        quotationAddressContactData?.buyerAddress?.fullAddredd?.state ===
          'Tamilnadu'
      ) {
        values['sgst'] = values['otherIncomeChargesId']['tax'] / 2
        values['cgst'] = values['otherIncomeChargesId']['tax'] / 2
        values['igst'] = 0
      } else {
        values['sgst'] = 0
        values['cgst'] = 0
        values['igst'] = values['otherIncomeChargesId']['tax']
      }
      // Store the updated list in a variable first
      const updatedItems = otherIncomeChargesData?.map(item => {
        if (item?.index === values?.index) {
          // If the item matches the index, update it
          return values
        }
        return item // Otherwise, return the item as it is
      })
      // If the item does not exist, add the new item
      if (!updatedItems.some(item => item.index === values.index)) {
        updatedItems.push(values) // Add the new item if it was not found
      }
      setOtherIncomeChargesData(updatedItems)
      callClearDiscount(null, updatedItems)
      setEditingRow('')
      toggle()
    }
    const handleCancel = () => {
      setitemData(prevData =>
        prevData.filter(
          item => item.index !== itemData.index || item.hasOwnProperty('id')
        )
      )
      setEditingRow('')
      toggle()
    }
    return (
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={QuotationOtherIncomeCharges}
          onSubmit={(values, formikProps) => handleSubmitOtherExpenses(values)}
        >
          {({
            errors,
            submitForm,
            touched,
            setFieldValue,
            values,
            resetForm
          }) => (
            <>
              <Form>
                <div className='row'>
                  <div className='col-6 staan-input-group'>
                    <CustomSelect
                      setSelectEditValue={otherIncomeChargesValues}
                      options={otherIncomeChargelist}
                      name='otherIncomeChargesId'
                      id='otherIncomeChargesId'
                      handleOnChange={option => {
                        setFieldValue(
                          'otherIncomeChargesId',
                          option?.value ? option : null
                        )
                        setFieldValue('hsnCode', option?.hsnCode)
                        setOtherIncomeChargesValues(option ? option : null)
                        setFieldValue('tax', option?.tax ? option.tax : null)
                      }}
                    />
                    <label className='staan-form-input-label pt-1 px-1 mx-2'>
                      Account{' '}
                    </label>
                    {touched.otherIncomeChargesId &&
                      errors.otherIncomeChargesId && (
                        <small>{errors.otherIncomeChargesId}</small>
                      )}
                  </div>
                  <div className='col-2 staan-input-group'>
                    <Field
                      type='text'
                      name='tax'
                      disabled
                      className='w-100 staan-form-input'
                    />
                    <label
                      htmlFor='tax'
                      className='staan-form-input-label pt-1 px-1 mx-2'
                    >
                      Tax
                    </label>
                    {touched.tax && errors.tax && <small>{errors.tax}</small>}
                  </div>
                  <div className='col-2 staan-input-group'>
                    <Field
                      type='text'
                      name='amount'
                      className='w-100 staan-form-input'
                    />
                    <label
                      htmlFor='amount'
                      className='staan-form-input-label pt-1 px-1 mx-2'
                    >
                      Amount
                    </label>
                    {touched.amount && errors.amount && (
                      <small>{errors.amount}</small>
                    )}
                  </div>
                  <div className='col-1 mt-3'>
                    <button
                      type='button'
                      className='btn btn-outline-success btn-sm px-3 mx-2  rounded-pill'
                      onMouseDown={() => {
                        submitForm()
                      }}
                    >
                      <i className='fa-regular fa-floppy-disk'></i>
                    </button>
                  </div>
                  <div className='col-1 mt-3'>
                    <button
                      type='button'
                      className='btn btn-outline-secondary btn-sm px-3 mx-1  rounded-pill'
                      onMouseDown={event => {
                        event.preventDefault()
                        handleCancel()
                      }}
                    >
                      <i className='fa-solid fa-xmark'></i>
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </>
    )
  }

  // Function to handle confirmation for overall discounts based on user input; returns no value.
  const handleWarningConfirmationOverallDiscount = data => {
    if (data === true) {
      if (checkDiscount === false) {
        setCheckedDiscount(true)
      } else {
        setCheckedDiscount(false)
      }
      clearDiscount(null, null)
    }
  }
  return (
    <>
      {<BallTriangleLoading isshow={loading} />}
      <HierarchicalTreeView
        data={timeLineValue?.treeData}
        show={timeLineValue?.isShow}
        getOpenmodelId={getOpenmodelId}
        currentID={quotationId}
        close={() => {
          setTimeLineValue(prev => ({ ...prev, isShow: false }))
        }}
      />
      <Modal key={index} show={true} fullscreen>
        <Modal.Body>
          <div
            className='itemMaster_Top mx-3 mt-1 d-flex justify-content-center card'
            style={{ width: '98%' }}
          >
            <div className='row align-items-center justify-content-between'>
              <div className='col-4 commanModelTitleColor'>
                <h3 className='mx-2 commanModelTitleColor'>
                  <i
                    className='fa-solid fa-arrow-left fa-sm me-2 commanModelTitleColor'
                    onClick={BeforeCloseCheckData}
                  ></i>
                  Quotation{' '}
                  {initialQuotation?.status ? (
                    <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'>
                      {initialQuotation?.status}
                    </span>
                  ) : (
                    ''
                  )}
                  {initialQuotation?.isAmend ? (
                    <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'>
                      Amend
                    </span>
                  ) : initialQuotation?.isDuplicate ? (
                    <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'>
                      Duplicate
                    </span>
                  ) : (
                    ''
                  )}
                  <span
                    className='commanModelTitleColor cursor-pointer'
                    onClick={() => {
                      fetchTimelineData(initialQuotation.LeadNumber)
                    }}
                  >
                    {' '}
                    Time Line
                  </span>
                </h3>
              </div>
              <div
                className='col-3'
                hidden={
                  initialQuotation?.isAmend
                    ? true
                    : initialQuotation?.isDuplicate
                    ? true
                    : versionList?.length <= 1
                    ? true
                    : false
                }
              >
                <p className='text-start  p-0 m-0 d-flex justify-content-center'>
                  <span className=''>Version</span>
                </p>
                <div className='d-flex justify-content-center' hidden>
                  <AiOutlineCaretLeft
                    size={30}
                    onClick={backWord}
                    disabled={isFirst}
                  />
                  <span className='fs-5 px-1 ms-1  '>
                    {initialQuotation?.QuatationNumber}
                  </span>
                  <AiOutlineCaretRight
                    size={30}
                    onClick={forWord}
                    disabled={isLast}
                  />
                </div>
              </div>
              <div className='col-5 '>
                <div className='row '>
                  <div className='col-9 '>
                    <h6 className='text-end'>
                      {initialQuotation?.createdBy?.id
                        ? `Created By : ${initialQuotation?.createdBy?.name}`
                        : ''}
                    </h6>
                    <h6 className='text-end '>
                      {initialQuotation?.createdAt
                        ? `Last Modified Date : ${initialQuotation?.createdAt} (${initialQuotation?.createdTime})`
                        : ''}
                    </h6>
                  </div>
                  <div className='col-3  '>
                    <i
                      className='fa-solid fa-pen fs-5 text-primary pe-3'
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        seteditView(false)
                      }}
                      hidden={
                        initialQuotation.status === 'Submit'
                          ? true
                          : initialQuotation.status === 'Canceled'
                          ? true
                          : !editView
                      }
                    ></i>
                    <i
                      className='fa-solid fa-trash text-danger fs-5 pe-3 ps-3'
                      style={{ cursor: 'pointer' }}
                      hidden={!(initialQuotation?.status === 'Canceled')}
                      onClick={() => {
                        setIsDeleteWarningShow(true)
                      }}
                    ></i>
                    <PiPrinterLight
                      className='fs-3'
                      onClick={genPDF}
                      // hidden={initialQuotation.status === "Canceled" ? true : !editView}
                      hidden={
                        initialQuotation?.status === 'Submit'
                          ? false
                          : initialQuotation?.status === 'Draft'
                          ? false
                          : true
                      }
                    />
                    <i
                      className='fa-solid fa-copy fs-5 pt-1 mx-3 text-success'
                      hidden={
                        !initialQuotation?.active ||
                        initialQuotation.status === 'Canceled' ||
                        !editView
                      }
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Duplicate'
                      role='button'
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        duplicaTheData(true)
                      }}
                    ></i>

                    <TiCancel
                      hidden={
                        !initialQuotation?.active === true ||
                        initialQuotation.status === 'Canceled' ||
                        !editView
                      }
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Cancel'
                      className='fs-2 pb-1'
                      style={{ color: '#7f7f7f', cursor: 'pointer' }}
                      onClick={() => {
                        setIsCancelWarningShow(true)
                      }}
                    ></TiCancel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Formik
            initialValues={initialQuotation}
            validationSchema={quotationFormSchema}
            enableReinitialize
            key={formKey}
            onSubmit={handleQuotationFromSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              submitForm,
              isValid,
              isValidating,
              isSubmitting
            }) => {
              {
                if (!isValid && !isValidating && isSubmitting) {
                  const errorMessages = Object.values(errors).join(',\n')
                  showErrorToast(true, 'error', `${errorMessages}`)
                }
              }
              return (
                <Form>
                  <div className='row ms-1 mt-3' style={{ width: '100%' }}>
                    <div className='col card shadow mx-2'>
                      <div className='row'>
                        {/* Quotation No */}
                        <div className='col-6 staan-input-group'>
                          <Field
                            type='text'
                            name='QuatationNumber'
                            id='QuatationNumber'
                            className='w-100 staan-form-input'
                            disabled={true}
                          />
                          <label
                            htmlFor='QuatationNumber'
                            className='staan-form-input-label pt-1 px-1 mx-2'
                          >
                            Quotation No
                          </label>
                        </div>
                        {/* Quotation Date */}
                        <div className='col-6 staan-input-group'>
                          <Field
                            type='date'
                            name='QuotationDate'
                            id='QuotationDate'
                            className='w-100 staan-form-input'
                            disabled={editView}
                          />
                          <label
                            htmlFor='QuotationDate'
                            className='staan-form-input-label pt-1 px-1 mx-2'
                          >
                            Quotation Date<span className='text-danger'>*</span>
                          </label>
                        </div>
                        {/* Lead Number */}
                        <div className='col-6 staan-input-group'>
                          <Field
                            type='text'
                            name='LeadNumber'
                            id='LeadNumber'
                            value={leadSelect}
                            className='w-100 staan-form-input'
                            disabled={true}
                          />
                          <label
                            htmlFor='LeadNumber'
                            className='staan-form-input-label pt-1 px-1 mx-2'
                          >
                            Lead No
                          </label>
                        </div>
                        {/* Department */}
                        <div className='col-6 staan-input-group'>
                          <CustomSelect
                            setSelectEditValue={departmentSelect}
                            handleInputChange={e => {
                              getOptions(e, 'DepartmentQuery')
                            }}
                            options={departmentList}
                            name='department_id'
                            id='department_id'
                            handleOnChange={option => {
                              setFieldValue(
                                'department_id',
                                option ? option.value : null
                              )
                              setDepartmentSelect(option ? option : null)
                            }}
                            isDisabled={
                              initialQuotation?.status === 'Submit' ||
                              initialQuotation?.status === 'Draft'
                                ? true
                                : initialQuotation?.isAmend
                                ? true
                                : initialQuotation?.isDuplicate
                                ? true
                                : false
                            }
                          />
                          <label className='staan-form-input-label pt-0 px-1 mx-2'>
                            Department<span className='text-danger'>*</span>
                          </label>
                          {touched.department_id && errors.department_id && (
                            <small className='staan-error-msg'>
                              {errors.department_id}
                            </small>
                          )}
                        </div>
                        {/* Sales Person */}
                        <div className='col-6 staan-input-group'>
                          <CustomSelect
                            setSelectEditValue={salesPersonSelect}
                            handleInputChange={e => {
                              getOptions(e, 'salesPerson')
                            }}
                            options={salesPersonList}
                            name='salesPerson'
                            id='salesPerson'
                            handleOnChange={option => {
                              setFieldValue(
                                'salesPerson',
                                option ? Number(option?.value) : null
                              )
                              setsalesPersonSelect(option ? option : null)
                            }}
                            isDisabled={editView}
                          />
                          <label className='staan-form-input-label  px-1 mx-2'>
                            Sales Person
                          </label>
                          {touched.salesPerson && errors.salesPerson && (
                            <small>{errors.salesPerson}</small>
                          )}
                        </div>
                        {/* Remark */}
                        <div className='col-6 staan-input-group'>
                          <Field
                            type='text'
                            name='remark'
                            id='remark'
                            className='w-100 staan-form-input'
                            onChange={e =>
                              setFieldValue('remark', e.target.value)
                            }
                            disabled={editView}
                          />
                          <label
                            htmlFor='remark'
                            className='staan-form-input-label pt-1 px-1 mx-2'
                          >
                            Remarks
                          </label>
                          {touched.remark && errors.remark && (
                            <small className='staan-error-msg'>
                              {errors.remark}
                            </small>
                          )}
                        </div>
                        {/* currency */}
                        <div className='col-6 staan-input-group mb-3'>
                          <CustomSelect
                            setSelectEditValue={currencySelect}
                            options={prefetchValue?.currencyExchangeList}
                            name='Currency'
                            id='Currency'
                            handleOnChange={async option => {
                              if (
                                (itemDetailsData?.length > 0 ||
                                  otherIncomeChargesData?.length > 0) &&
                                option
                              ) {
                                const currencyResponse =
                                  await quotationCurrencyConvertion(
                                    option,
                                    currencySelect ? currencySelect : null
                                  )
                                if (currencyResponse) {
                                  setcurrencySelect(option)
                                  setFieldValue(
                                    'Currency',
                                    Number(option?.value)
                                  )
                                  setFieldValue(
                                    'currencyExchangeRate',
                                    option?.rate
                                  )
                                  setcurrencyData(option)
                                }
                                // if (currencyResponse) {

                                //   setcurrencyData(option)
                                //   setcurrencySelect(option);
                                //   setFieldValue('Currency', Number(option?.value));
                                //   setFieldValue('currencyExchangeRate', option?.rate)
                                // }
                                // else {
                                //   const rupeeOption = prefetchValue.currencyExchangeList.find(item => item.label === 'Rupee');
                                //   setcurrencyData(rupeeOption)
                                //   setcurrencySelect(rupeeOption);
                                //   setFieldValue('Currency', Number(rupeeOption?.value));
                                // }
                              }
                            }}
                            isDisabled={editView}
                          />
                          {/* rate = rate * convertion rate  */}
                          <label className='staan-form-input-label pt-1  px-1 mx-2'>
                            Currency<span className='text-danger'>*</span>
                          </label>
                          {/* {touched.Currency &&  errors.Currency && <small>{errors.Currency}</small>} */}
                        </div>
                        <div className='col-6 staan-input-group mb-3'>
                          <Field
                            type='text'
                            name='currencyExchangeRate'
                            id='currencyExchangeRate'
                            className='w-100 staan-form-input'
                            disabled={true}
                          />
                          <label
                            htmlFor='currencyExchangeRate'
                            className='staan-form-input-label pt-1 px-1 mx-2'
                          >
                            Currency Exchange Rate
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='col card shadow mx-2 py-2'>
                      <p className='fw-bolder fs-6 commanModelTitleColor'>
                        Customer Details
                      </p>
                      <div className='row'>
                        <div className='col-6 staan-input-group'>
                          <CustomSelect
                            setSelectEditValue={supplierNoSelected}
                            handleInputChange={e => {
                              getOptions(
                                e,
                                'SupplierOptionsOptionsQuery',
                                'supplierNumber'
                              )
                            }}
                            options={supplierNoList}
                            name='supplierNumber'
                            id='supplierNumber'
                            handleOnChange={option => {
                              setFieldValue(
                                'supplierNumber',
                                option?.value ? option?.value : null
                              )
                              setFieldValue(
                                'supplierName',
                                option?.value ? option?.value : null
                              )
                              setFieldValue('buyerAddress', '')
                              setFieldValue('buyerContactPerson', '')
                              setquotationAddressContactData(prevState => ({
                                ...prevState,
                                buyerAddress: '',
                                buyerContactPerson: ''
                              }))
                              setSupplierNoSelected(option ? option : null)
                              setSupplierNameSelected(
                                option
                                  ? {
                                      value: option?.id,
                                      label: option?.companyName
                                    }
                                  : null
                              )
                              setQuotationInputValue(
                                option
                                  ? {
                                      address: option?.address?.[0],
                                      remark: quotationInputValue?.remark
                                    }
                                  : null
                              )
                              setCustomerDetailsLists(prve => ({
                                ...prve,
                                buyerStatesList: getStates(
                                  option?.address?.country
                                ),
                                buyerContactList: option?.contact?.map(
                                  contact_ => ({
                                    value: contact_?.id,
                                    label: contact_?.contactPersonName,
                                    mobile: contact_?.phoneNumber,
                                    Email: contact_?.email
                                  })
                                ),
                                buyerAddressList: option?.address?.map(
                                  address_ => ({
                                    value: address_?.id,
                                    label: address_?.addressType,
                                    fullAddredd: address_
                                  })
                                )
                              }))
                              if (itemDetailsData?.length > 0 && option) {
                                QuotationBulkUpdateForTaxmutations(null)
                              }
                            }}
                            isDisabled
                          />
                          <label className='staan-form-input-label pt-1 px-1 mx-2'>
                            Customer Code<span className='text-danger'>*</span>
                          </label>
                          {touched.supplierNumber && errors.supplierNumber && (
                            <small className='staan-error-msg'>
                              {errors.supplierNumber}
                            </small>
                          )}
                        </div>
                        <div className='col-6 staan-input-group'>
                          <CustomSelect
                            setSelectEditValue={supplierNameSelected}
                            handleInputChange={e => {
                              getOptions(
                                e,
                                'SupplierOptionsOptionsQuery',
                                'supplierName'
                              )
                            }}
                            options={supplierNameList}
                            name='supplierName'
                            id='supplierName'
                            handleOnChange={option => {
                              setFieldValue(
                                'supplierName',
                                option?.value ? option?.value : null
                              )
                              setFieldValue(
                                'supplierNumber',
                                option?.value ? option?.value : null
                              )
                              setquotationAddressContactData(prevState => ({
                                ...prevState,
                                buyerAddress: '',
                                buyerContactPerson: ''
                              }))
                              setSupplierNameSelected(option ? option : null)
                              setSupplierNoSelected(
                                option
                                  ? {
                                      value: option?.value,
                                      label: option?.supplierNo
                                    }
                                  : null
                              )
                              setQuotationInputValue(
                                option
                                  ? {
                                      address: option?.address?.[0],
                                      remark: quotationInputValue?.remark
                                    }
                                  : null
                              )
                              setCustomerDetailsLists(prve => ({
                                ...prve,
                                buyerStatesList: getStates(
                                  option?.address?.country
                                ),
                                buyerAddressList: option?.address?.map(
                                  address_ => ({
                                    value: address_?.id,
                                    label: address_?.addressType,
                                    fullAddredd: address_
                                  })
                                ),
                                buyerContactList: option?.contact?.map(
                                  contact_ => ({
                                    value: contact_?.id,
                                    label: contact_?.contactPersonName,
                                    mobile: contact_?.phoneNumber,
                                    Email: contact_?.email
                                  })
                                )
                              }))
                              if (itemDetailsData?.length > 0 && option) {
                                QuotationBulkUpdateForTaxmutations(null)
                              }
                            }}
                            isDisabled
                          />
                          <label className='staan-form-input-label pt-1 px-1 mx-2'>
                            Customer Name<span className='text-danger'>*</span>
                          </label>
                          {touched.supplierName && errors.supplierName && (
                            <small className='staan-error-msg'>
                              {errors.supplierName}
                            </small>
                          )}
                        </div>
                        <div className='col-6 staan-input-group'>
                          <CustomSelect
                            setSelectEditValue={
                              quotationAddressContactData?.buyerAddress
                            }
                            options={customerDetailsLists?.buyerAddressList}
                            name='accountName'
                            id='accountName'
                            handleOnChange={option => {
                              setFieldValue(
                                'buyerState',
                                option?.fullAddredd?.state
                              )
                              setFieldValue(
                                'buyerAddress',
                                Number(option?.value)
                              )
                              setCustomerDetailsLists(prve => ({
                                ...prve,
                                buyerStatesList: getStates(
                                  option?.fullAddredd?.country
                                )
                              }))
                              // select the buyerPlaceOfSupply
                              setquotationAddressContactData(prve => ({
                                ...prve,
                                buyerAddress: option ? option : null
                              }))
                              if (itemDetailsData?.length > 0 && option) {
                                QuotationBulkUpdateForTaxmutations(
                                  option?.fullAddredd?.state
                                )
                              }
                              setDiscountValue(prev => ({
                                ...prev,
                                isDiscountApplied: false
                              }))
                            }}
                            isDisabled={editView}
                          />
                          <label className='staan-form-input-label  px-1 mx-2'>
                            Address<span className='text-danger'>*</span>
                          </label>
                          {touched.buyerAddress && errors.buyerAddress && (
                            <small>{errors.buyerAddress}</small>
                          )}
                        </div>
                        <div className='col-6 staan-input-group'>
                          <CustomSelect
                            setSelectEditValue={
                              quotationAddressContactData?.buyerContactPerson
                            }
                            options={customerDetailsLists?.buyerContactList}
                            name='buyerContactPerson'
                            id='buyerContactPerson'
                            handleOnChange={option => {
                              setquotationAddressContactData(prve => ({
                                ...prve,
                                buyerContactPerson: option ? option : null
                              }))
                              setFieldValue(
                                'buyerContactPerson',
                                Number(option?.value)
                              )
                            }}
                            isDisabled={editView}
                          />
                          <label className='staan-form-input-label  px-1 mx-2'>
                            Contact Person<span className='text-danger'>*</span>
                          </label>
                          {touched.buyerContactPerson &&
                            errors.buyerContactPerson && (
                              <small>{errors.buyerContactPerson}</small>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className='col card shadow mx-2 py-2'>
                      <div className='row'>
                        <div className='col-6 staan-input-group'>
                          <p className='p-2   fs-6 fw-bolder'>Address</p>
                          {quotationAddressContactData?.buyerAddress
                            ?.fullAddredd ? (
                            <>
                              <p className='ps-2 lh-1 '>
                                {
                                  quotationAddressContactData?.buyerAddress
                                    ?.fullAddredd?.addressLine1
                                }{' '}
                                {
                                  quotationAddressContactData?.buyerAddress
                                    ?.fullAddredd?.addressLine2
                                }
                                ,
                              </p>
                              <p className='ps-2 lh-1 '>
                                {
                                  quotationAddressContactData?.buyerAddress
                                    ?.fullAddredd?.city
                                }{' '}
                                {
                                  quotationAddressContactData?.buyerAddress
                                    ?.fullAddredd?.state
                                }
                                ,
                              </p>
                              <p className='ps-2 lh-1 '>
                                {
                                  quotationAddressContactData?.buyerAddress
                                    ?.fullAddredd?.country
                                }
                              </p>
                              <p className='ps-2 lh-1 '>
                                Pincode :
                                {
                                  quotationAddressContactData?.buyerAddress
                                    ?.fullAddredd?.pincode
                                }
                              </p>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className='col-6'>
                          <p className='p-2 fs-6 fw-bolder'>Contact </p>
                          {quotationAddressContactData?.buyerContactPerson ? (
                            <>
                              <p className='ps-2 lh-1'>
                                Mobile :{' '}
                                {
                                  quotationAddressContactData
                                    ?.buyerContactPerson?.mobile
                                }{' '}
                              </p>
                              <p className='ps-2 lh-1'>
                                {' '}
                                Email :{' '}
                                {
                                  quotationAddressContactData
                                    ?.buyerContactPerson?.Email
                                }{' '}
                              </p>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Item Details */}
                    <div className='row  ms-3 mt-3 card shadow mx-2 pb-2'>
                      <div className='row justify-content-between align-items-center'>
                        <div className='col-2'>
                          <h6 className='commanModelTitleColor'>
                            Item Details
                          </h6>
                        </div>
                        <div className='col-4 text-end'>
                          <span className='me-3'>
                            <LuGift
                              className='me-2'
                              style={{ cursor: 'pointer', color: '#FFAA1D' }}
                            />
                            Item Combo Not Saved
                          </span>
                          <span className='me-3'>
                            <LuGift
                              className='me-2'
                              style={{ cursor: 'pointer', color: '#24a0ed' }}
                            />
                            Item Combo Saved
                          </span>
                        </div>
                        <div className='col-3 text-end'>
                          <OverlayTrigger
                            placement='top'
                            overlay={
                              <Tooltip id='button-tooltip'>
                                Click To Add Item
                              </Tooltip>
                            }
                          >
                            <button
                              type='button'
                              className='btn btn-outline-success mt-4 px-4'
                              onClick={() => {
                                setItemEditShow(false)
                                setQuotationItemDetailsShow(true)
                              }}
                              disabled={
                                !quotationAddressContactData
                                  ? quotationAddressContactData?.buyerAddress
                                      ?.fullAddredd?.state
                                  : '' || editView || !currencyData
                              }
                            >
                              <i className='bx bxs-plus-circle me-2'></i>New
                            </button>
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div
                        className='ag-theme-alpine mt-2'
                        style={{
                          height: '40rem',
                          width: '100%',
                          overflowY: 'auto'
                        }}
                      >
                        <CommanTable
                          headers={columns}
                          rowData={itemDetailsData}
                        />
                        <ItemCombo
                          IsshowItemCombo={showItemCombo}
                          setshowItemCombo={setShowItemCombo}
                          lastUpdatedItemDetails={
                            itemDetailsValues?.lastUpdatedItemDetails
                          }
                          itemComboData={itemDetailsValues?.itemComboData}
                          setItemComboData={value => {
                            setitemDetailsValues(prev => ({
                              ...prev,
                              itemComboData: value
                            }))
                          }}
                          setQuotationItemDetailsShow={
                            setQuotationItemDetailsShow
                          }
                          setItemDetailsData={setItemDetailsData}
                          loading={loading}
                          setLoading={setLoading}
                        />
                      </div>
                      <br />
                    </div>
                    <div className='row justify-content-between ms-3 mt-4'>
                      <div className='col card shadow-lg'>
                        <div className='row'>
                          <div className='col-6 staan-input-group'>
                            <CustomSelect
                              setSelectEditValue={termsOptions}
                              handleInputChange={e => {
                                getOptions(e, 'TermsConditionsFetchQuery')
                              }}
                              options={tCList}
                              name='termsConditions'
                              id='termsConditions'
                              handleOnChange={option => {
                                setFieldValue(
                                  'TermsOptions',
                                  option ? option?.value : ''
                                )
                                setFieldValue(
                                  'termsConditions',
                                  option ? option?.tc : ''
                                )
                                setTermsOptions(option)
                                setTermsConditions(option?.tc)
                              }}
                              isDisabled={editView}
                            />
                            <label className='staan-form-input-label pt-1 px-1 mx-2'>
                              Terms & Conditions
                              <span className='text-danger'>*</span>
                            </label>
                            {touched.termsConditions &&
                              errors.termsConditions && (
                                <small className='staan-error-msg'>
                                  {errors.termsConditions}
                                </small>
                              )}
                          </div>
                          <div className='col-12 staan-input-group mt-2'>
                            <ReactQuill
                              value={termsConditions}
                              onChange={e => {
                                setFieldValue('termsConditions', e ? e : '')
                                setTermsConditions(e)
                              }}
                              modules={textEditormodules}
                              readOnly={editView}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='card shadow-lg p-3'>
                          <div className='row'>
                            <CommanTable
                              title={'Other Charges'}
                              headers={otherIncomeChargesColumns}
                              rowData={otherIncomeChargesData}
                              inputSection={OtherIncomeChargesInputForm}
                              getDeleteRowId={handleDeleteOtherIncome}
                              newRowAdd={true}
                              tooltipMessage={
                                'Click To Add Other Income Charges'
                              }
                              isDisabled={editView}
                            />
                          </div>
                        </div>
                        <div className='card shadow-lg mt-3'>
                          <table className='table'>
                            <tbody>
                              <tr>
                                <td className='text-end p-2'>
                                  Item Before Tax
                                </td>
                                <td className='text-end p-2'>
                                  {displayRupeeFormat(
                                    toSaveData?.beforeTax,
                                    currencyData?.label
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className='text-end p-2'>
                                  Other Charges Before Tax
                                </td>
                                <td className='text-end p-2'>
                                  {displayRupeeFormat(
                                    toSaveData?.otherIncomeChagersBeforeTax,
                                    currencyData?.label
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className='text-end p-2'>Taxable Value</td>
                                <td className='text-end p-2'>
                                  {displayRupeeFormat(
                                    Number(toSaveData?.beforeTax) +
                                      Number(
                                        toSaveData?.otherIncomeChagersBeforeTax
                                      ) || 0,
                                    currencyData?.label
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className='text-end p-2'>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={
                                      <Tooltip id='checkbox-tooltip'>
                                        Click to clear discount
                                      </Tooltip>
                                    }
                                  >
                                    <input
                                      type='checkbox'
                                      checked={checkDiscount}
                                      onChange={e => {
                                        const isChecked = e.target.checked
                                        setwarningShow(true)
                                        setErrorMessage(
                                          isChecked
                                            ? 'Do you want to Clear ItemWise Discount ?'
                                            : 'Do you want to Clear OverAll Discount ?'
                                        )
                                      }}
                                      disabled={editView}
                                      className='me-2'
                                    />
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={
                                      <Tooltip id='overall-discount-tooltip'>
                                        Click to apply overall discount
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      onClick={() => {
                                        if (
                                          initialQuotation?.status !==
                                            'Draft' &&
                                          initialQuotation?.status !== '' &&
                                          !initialQuotation?.isAmend
                                        ) {
                                          return
                                        }
                                        if (!checkDiscount) {
                                          showErrorToast(
                                            true,
                                            'warn',
                                            'Please Select The Check Box!!!'
                                          )
                                        } else {
                                          setShowDiscount(prev => !prev)
                                        }
                                      }}
                                      style={{
                                        cursor: 'pointer',
                                        color: 'red'
                                      }}
                                    >
                                      Overall Discount
                                    </span>
                                  </OverlayTrigger>
                                </td>
                                <td className='text-end p-2'>
                                  {displayRupeeFormat(
                                    calculatedDiscount || 0,
                                    currencyData?.label
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className='text-end p-2 cursor-pointer text-primary'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => setShowTaxModel(true)}
                                >
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={
                                      <Tooltip id='tax-total-tooltip'>
                                        Click to view HSN Breakdown
                                      </Tooltip>
                                    }
                                  >
                                    <span>Tax Total</span>
                                  </OverlayTrigger>
                                </td>
                                <td className='text-end p-2'>
                                  {displayRupeeFormat(
                                    toSaveData?.totaltax,
                                    currencyData?.label
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className='text-end p-2'>Round Off</td>
                                <td className='text-end p-2'>
                                  {displayRupeeFormat(
                                    toSaveData?.roundOff,
                                    currencyData?.label
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className='text-end p-2 fw-bold'>
                                  Nett Amount
                                </td>
                                <td className='text-end p-2'>
                                  {displayRupeeFormat(
                                    toSaveData?.netAmount,
                                    currencyData?.label
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className='row mt-3 ms-3 justify-content-evenly'>
                      <div className='col-1'>
                        <button
                          type='submit'
                          style={{ width: '10rem' }}
                          className='btn btn-outline-secondary px-4 '
                          disabled={
                            initialQuotation?.status === 'Draft'
                              ? false
                              : initialQuotation?.isAmend
                              ? true
                              : initialQuotation?.status === ''
                              ? false
                              : true
                          }
                          onClick={() => {
                            setFieldValue('status', 'Draft')
                          }}
                        >
                          Draft
                        </button>
                      </div>
                      <div className='col-1'>
                        <button
                          type='button'
                          style={{ width: '10rem' }}
                          className='btn btn-outline-success px-3'
                          disabled={
                            initialQuotation?.status === 'Draft'
                              ? false
                              : initialQuotation?.isAmend
                              ? false
                              : true
                          }
                          onClick={() => {
                            setFieldValue('status', 'Submit')
                            submitForm()
                          }}
                        >
                          Submit
                        </button>
                      </div>
                      <div className='col-1'>
                        <button
                          type='submit'
                          style={{ width: '10rem' }}
                          disabled={
                            initialQuotation?.status === 'Submit' &&
                            initialQuotation?.active === true
                              ? false
                              : true
                          }
                          className='btn btn-outline-primary px-3'
                          onClick={() => {
                            // handleQuotationFromSubmit(values)
                            // setStatus("Amend")
                            setInitialQuotation(prev => ({
                              ...prev,
                              isAmend: true
                            }))
                            setVersionList([])
                            duplicaTheData(false)
                          }}
                        >
                          Amend
                        </button>
                      </div>
                      {/* <button
                          type='button'
                          style={{ width: "10rem" }}
                          disabled={initialQuotation?.status === "Submit" && initialQuotation?.active === true ? false : true}
                          className='btn btn-outline-info px-4'
                          onClick={CreateSalesOrder}>
                          Sales Order
                        </button> */}
                      {!isLeadPage && (
                        <div className='col-1'>
                          <button
                            type='button'
                            style={{ width: '10rem' }}
                            disabled={
                              initialQuotation?.status === 'Submit' &&
                              initialQuotation?.active === true
                                ? false
                                : true
                            }
                            className='btn btn-outline-info px-4'
                            onClick={CreateSalesOrder}
                          >
                            Sales Order
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {showDiscount && (
        <DiscountModel
          isShow={showDiscount}
          setIsShow={setShowDiscount}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
          ClearDisCount={clearDiscount}
          applyDisCount={applyDisCount}
          netAmount={toSaveData?.netAmount}
        />
      )}

      {isCancelWarningShow && (
        <WaringModel
          state={isCancelWarningShow}
          setstate={setIsCancelWarningShow}
          title={'Confirm'}
          text={'Confirm to cancel'}
          Confirmtion={value => {
            if (value) {
              CancelTheQuotations(value)
            }
          }}
        />
      )}
      {isDeleteWarningShow && (
        <WaringModel
          state={isDeleteWarningShow}
          setstate={setIsDeleteWarningShow}
          title={'Confirm'}
          text={'Confirm to Delete'}
          Confirmtion={value => {
            if (value) {
              DeleteTheQuotations(value)
            }
          }}
        />
      )}
      {isItemDeleteWarningShow && (
        <WaringModel
          state={isItemDeleteWarningShow}
          setstate={setisItemDeleteWarningShow}
          title={'Confirm'}
          text={'Confirm to Delete'}
          Confirmtion={value => {
            if (value) {
              DeleteTheItem(value)
            }
          }}
        />
      )}

      {showTaxModel && (
        <TaxModel
          showTaxModel={showTaxModel}
          setShowTaxModel={() => {
            setShowTaxModel(false)
          }}
          taxData={toSaveData?.taxData}
          setTaxData={values => {
            setToSaveData(prev => ({ ...prev, taxData: values }))
          }}
          igstTax={toSaveData.igst}
          sgstTax={toSaveData.sgst}
          cgstTax={toSaveData.cgst}
        />
      )}
      {IswarningShow && (
        <WaringModel
          state={IswarningShow}
          setstate={setwarningShow}
          title={'Warning'}
          text={errorMessage}
          Confirmtion={value => {
            if (value) {
              handleWarningConfirmationOverallDiscount(value)
            }
          }}
        />
      )}
      {IswarningAmandCancelShow && (
        <WaringModel
          state={IswarningAmandCancelShow}
          setstate={setwarningAmandCancelShow}
          title={'Confirm'}
          text={errorMessage}
          Confirmtion={value => {
            if (value) {
              handleWarningConfirmationAmandCancel(value)
            }
          }}
        />
      )}
      {IsshowWarningModalWithCopy && (
        <WaringModelWithCopy
          state={IsshowWarningModalWithCopy}
          setState={setShowWarningModalWithCopy}
          title='Value Out Of Range'
          text={errorMessagesWarningModalWithCopy}
          Copy={true}
        />
      )}
      {isShowStockStatementModal && (
        <StockStatementDetails
          isShowSelectedStockStatement={isShowStockStatementModal}
          handleCloseSelectedStockStatement={handleCloseStockStatementModal}
          stockStatement={itemDetailsValues?.selectedStockStatementDetails}
          part_id={itemDetailsValues?.selectedPartNo}
          from={'pos'}
        />
      )}

      {/* {salesOrderModels?.map((item) => (
        <SalesOrderForm
          index={item?.index}
          editData={item?.id}
          quotationData={!isLeadPage ? salesOrderData : null}
        />
      ))} */}

      {!isLeadPage &&
        salesOrderData &&
        salesOrderModels?.map(item => (
          <>
            <SalesOrderForm
              key={item?.id} // Added key to avoid React warning
              index={item?.index}
              editData={item?.id}
              quotationData={!isLeadPage ? salesOrderData : null}
            />
          </>
        ))}
      {otherIncomeChargesvalues?.deleteData && (
        <DeleteConformation
          handleDeletedId={id => handleDeletedIdotherincome(id)}
          deleteData={otherIncomeChargesvalues?.deleteData}
          fullquery={otherIncomeChargesDeleteMutation(
            otherIncomeChargesvalues?.deleteData?.id
          )}
        />
      )}
      {IsDeleteOtherIncomeCharges && (
        <WaringModel
          state={IsDeleteOtherIncomeCharges}
          setstate={setIsDeleteOtherIncomeCharges}
          title={'Confirm'}
          text={deleteOtherIncomeData?.deleteMessage}
          Confirmtion={value => {
            if (value) {
              handleDeleteOtherIncomeFunction(value)
            }
          }}
        />
      )}
      {QuotationItemDetailsShow && (
        <QuatationItemModel
          QuotationItemDetailsShow={QuotationItemDetailsShow}
          setQuotationItemDetailsShow={setQuotationItemDetailsShow}
          stateValue={
            quotationAddressContactData
              ? quotationAddressContactData?.buyerAddress?.fullAddredd?.state
              : ''
          }
          onNewItem={handleNewItem}
          EditData={itemDetailsValues?.ItemEditData}
          setEditData={setitemDetailsValues}
          EditShow={ItemEditShow}
          editView={editView}
          setItemComboData={value => {
            setitemDetailsValues(prev => ({ ...prev, itemComboData: value }))
          }}
          itemDetailsData={itemDetailsData}
          setItemDetailsData={setItemDetailsData}
          setOtherIncomeChargesData={setOtherIncomeChargesData}
          otherIncomeChargesData={otherIncomeChargesData}
          isAfterOverallDiscount={discountValue?.isDiscountApplied}
          setAfterOverallDiscount={() => {
            setDiscountValue(prev => ({ ...prev, isDiscountApplied: false }))
          }}
          ClearDisCount={callClearDiscount}
          currencyData={currencyData}
        />
      )}
    </>
  )
}

export default QuotationFrom
