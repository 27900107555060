import React, { useState, } from 'react' 
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import TableView from '../../Packages/Custom/DataTable';
import AddPO from './comps/AddPO';

const ProductionOrder = () => {
    const [isLoad, setLoad] = useState(false);
    const [showAddPO, setShowAddPO] = useState(false);
    const [currentPo, setCurrentPo] = useState(null);
    

    const handleShowAddPO = () => {
        setShowAddPO(true)
    }

    const handleCloseAddPO = () => {
        setCurrentPo(null)
        setShowAddPO(false)
        setLoad(!isLoad)
    }

    const CustomAddModalComponent = () => {
        handleShowAddPO()
        setLoad(!isLoad)
    }

    const CustomViewModalComponent = (data) => {
      setCurrentPo(data);
      handleShowAddPO();
    }

    const po_column_details = [
        {
          header: 'ID',
          field: 'id',
          flex: 5,
          inputType: 'number',
          align: 'center',
        },
        {
          header: 'Order No',
          field: 'poMaster',
          label: 'orderNo',
          key: "orderNo",
          queryField: "orderNo",
          flex: 8,
          inputType: 'text',
          renderComponent: CustomViewModalComponent,
          query: 'ProductionOrderNumberOptionQuery'
        },
        // {
        //   header: 'PO Items',
        //   field: 'poMaster',
        //   label: 'orderNo',
        //   key: "orderNo",
        //   queryField: "orderNo",
        //   flex: 8,
        //   inputType: 'text',
        //   renderComponent: CustomViewModalComponent,
        //   query: 'ProductionOrderNumberOptionQuery'
        // },
        {
          header: 'Status',
          field: 'poMaster',
          label: "status.name",
          customFilterField: 'status',
          flex: 5,
          inputType: 'text',
          option: true,
          key: "value",
        },
        {
          header: 'Department',
          field: 'poMaster',
          label: "department.name",
          customFilterField: 'department',
          flex: 12,
          inputType: 'text',
          option: true,
          query: 'bomTypeOptionQuery',
          key: 'label'
        },
        {
          header: 'Order Date',
          field: 'poMaster',
          label: 'orderDate',
          customFilterField: 'orderDate',
          flex: 10,
          inputType: 'text',
          isDateField: true,
        },
        {
          header: 'Last Modified Date',
          field: 'poMaster',
          label:"updatedAt", 
          customFilterField: 'updatedAt',
          flex: 5,
          inputType: 'text',
          isDateField: true,
        },
        {
          header: 'Last Modified By',
          field: 'poMaster',
          label: 'modifiedBy.username',
          customFilterField: 'modifiedBy',
          flex: 7,
          inputType: 'text',
          option: true,
          query: "userOptionQuery",
          key: "value",
        },
        
    ]


    return(
        <>
            <SideNavbar />
            <div id="custom-table-itemmaster">
                <TableView 
                    headers = {po_column_details}
                    query = {'ProductionOrderTableFetchQuery'}
                    addComponent = {CustomAddModalComponent}
                    loadComponent = {isLoad}
                    titleName = {'Manufacturing Order'}
                    heightAdjust = {'40rem'} 
                />

                {showAddPO && 
                    <AddPO 
                        show={showAddPO}
                        close={handleCloseAddPO}
                        data = {currentPo?.poMaster?.id || null}
                        po_master = {currentPo?.poMaster}
                    /> 
                }
            </div>
        </>
    )
}

export default ProductionOrder;