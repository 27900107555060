 import React, { useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

const ShowStockNeed = ({showStockNeed, setShowStockNeed, showStockNeedList}) => {
  useEffect(()=>{
    console.log(showStockNeed, setShowStockNeed, showStockNeedList);
    
  },[showStockNeed])
  return (
    <> 
      <Offcanvas show={showStockNeed} onHide={()=>{setShowStockNeed(false)}} scroll = {true} backdrop ={true}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Stock Availability</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div>
          <table>
            <thead>
              <tr>
                <th className='p-1'>Part Code</th>
                <th className='p-1'>Batch</th>
                <th className='p-1'>Serial</th>
                <th className='p-1 ps-2'>Shortage</th>
              </tr>
            </thead>
            <tbody>
              {showStockNeedList?.map((item, index) => (
                <> {item?.paranted_partcode ? 
                  <tr>
                      <td className='p-1'>{item?.paranted_partcode}</td>
                  </tr> : ""}
                  
                  <tr key={index} style={item?.paranted_partcode ? {color:"red"} : {color:"green"}}>
                    {/* Fallback to index if partcode isn't unique */}
                    <td className='p-1'>{item?.partcode}</td>
                    <td className='p-1'>{item?.batch || 'N/A'}</td>
                    <td className='p-1'>{item?.Serial}</td> {/* Corrected from partcode to serial */}
                    <td className='p-1'>{item?.needStock ? String(item?.needStock).replace('-', '') : ''}</td>
                  </tr>

                </>
               
              ))}
            </tbody>
          </table>
        </div>  
        </Offcanvas.Body>
      </Offcanvas>
 
 
    </>
  )
}

export default ShowStockNeed;