export const expenseCategoriesCreateMutation = (data) => {
  return `
    mutation MyMutation { 
  expenseCategoriesCreateMutation(${data}) { 

    errors 

    success 

  } 

} 
    `
}

export const expenseCategoriesDeleteMutation = (data) => {
  return `
mutation MyMutation { 

  expenseCategoriesDeleteMutation(${data}) { 

    errors 

    success 

  } 

} 
    `
}

export const expenseClaimModalCreateMutation = (data) => {
  return `
    mutation MyMutation { 

  expenseClaimDetailsCreateMutation(${data}) { 

    errors 

    success 
ExpenseClaimDetailsInstance {
       approvedAmount
  claimAmount
      createdBy {
        id
      }
      id
      descriptions
      dateOfExp
      expenseCategories{
        id
        expenseCategoryName
      }
      pdfUrl{
        id
        documentFile
        isDelete
      }
      gstIn
    }
  } 

} 
    `
}


export const expenseClaimDetailsDeleteMutation = (data) => {
  return `
mutation MyMutation { 

  expenseClaimDetailsDeleteMutation(${data}) { 

    errors 

    success 

  } 

}  
    `
}


export const expenseClaimCreateMutation = (data) => {
  return `
   mutation MyMutation { 

  expenseClaimCreateMutation(${data}) { 

    errors 

    success 

  } 

} 
    `
}

export const expenseClaimDeleteMutation = (data) => {  
  console.log("expenseClaimDeleteMutation",data);
  
  return `
   mutation MyMutation { 

  expenseClaimDeleteMutation(id:[${data}]) { 

    errors 

    success 

  } 

} 
    `
}

export const expenseClaimDeatilsBulkDeleteMutation=(data)=>{
  return `
  mutation MyMutation {  expenseClaimDetailsDeleteMutation(id:[${data}]) {    errors    success  }}
  `
}

export const advanceTableCreateMutation = (data) => {
  return `
    mutation MyMutation { 

  paymentVoucherAdvanceDetailsCreateMutation(${data}) { 

    errors 

    success 

    PaymentVoucherAdvanceDetailsInstance { 

      advRemark 

      amount 

      id

      createdBy{
        id
      } 

    } 

  } 

} 
    `

}

export const advanceTableDeleteMutation = (data) => {
  return `
    mutation MyMutation { 

  paymentVoucherAdvanceDetailsDeleteMutation(id:${data}) { 

    errors 

    success 

  } 

} 
    `
}
export const voucherCreateMutation = (data) => {
  return `
  mutation MyMutation { 

  paymentVoucherCreateMutation(${data}) { 

    success 

    errors 
     PaymentVoucherInstance {
      advance
      againstInvoice
      advanceDetails{
        id
        advRemark
        amount
        createdAt
        createdBy{
          id 
          username
        }
        modifiedBy{
          id
          username
        }
        
      }
      bank {
        accountType
        accountsName
        id
      }
      chqDate
      chqRefNo
      cusSupAmount
      createdAt
      createdBy {
        id
        username
      }
      cusSupId{
        id
        supplierNo
        companyName
      }
      date
      empAmount
      employeeId{
        id
        employeeName
        employeeId
      }
      expenseRequestId{
        id
        expenseRequestNo
      }
      id
      modifiedBy {
        id
        username
      }
      payFor {
        accountType
        accountsName
        id
      }
      payMode {
        id
        name
      }
      payTo {
        id
        name
      }
      paymentVoucherNo {
        id
        linkedModelId
      }
      transferVia {
        id
        name
      }
      status{
      id
      name
    }
      
    }

  } 

} 
  `
}

export const voucherDeleteMutation=(data)=>{
  return `
  mutation MyMutation { 

  paymentVoucherDeleteMutation(${data}) { 

    errors 

    success 

  } 

} 
  `
}

export const voucherCancelMutation=(data)=>{
  return`
  mutation MyMutation { 

  paymentVoucherCreateMutation(${data}) { 

    success 

    errors 
     PaymentVoucherInstance {
      advance
      againstInvoice
      advanceDetails{
        id
        advRemark
        amount
        createdAt
        createdBy{
          id 
          username
        }
        modifiedBy{
          id
          username
        }
        
      }
      bank {
        accountType
        accountsName
        id
      }
      chqDate
      chqRefNo
      cusSupAmount
      createdAt
      createdBy {
        id
        username
      }
      cusSupId{
        id
        supplierNo
        companyName
      }
      date
      empAmount
      employeeId{
        id
        employeeName
        employeeId
      }
      id
      modifiedBy {
        id
        username
      }
      payFor {
        accountType
        accountsName
        id
      }
      payMode {
        id
        name
      }
      payTo {
        id
        name
      }
      paymentVoucherNo {
        id
        linkedModelId
      }
      transferVia {
        id
        name
      }
      status{
      id
      name
    }
    }

  } 

}`
}

export const reconsiliationTableAutoAdjust=(data,data2)=>{
  console.log("data",data);
  
  console.log("data2",data2);
  
  return`
  mutation MyMutation { 

  expenseReconciliationDetailsCreateMutation(${data}) { 

    errors 

    success 
     expenseReconciliationDetailsInstance{
      id
      paymentVoucherId{
        id
        date
        empAmount
        expenseRequestId{
        expenseRequestNo
      }
        employeeId {
        employeeId
        employeeName
        id
      }
      }
      createdBy{
        id
      }
      modifiedBy{
        id
      }
      adjustedAmount
      expenseclaimSet{
    	  totalApprovedAmount
    	  reimburseAmount
    	  balanceAmount
    	  status{
    	    id
    	    name
    	  }
      }     
    
    }

  } 
    expenseClaimCreateMutation(balanceAmount:${data2?.balanceAmount},id:${data2?.id},reimburseAmount:${data2?.reimburseAmount}) { 

    errors 

    success 
    ExpenseClaimInstance{
      balanceAmount
      reimburseAmount
      totalApprovedAmount
    }

  } 

} 
  `
}

export const reconsiliationTableCreateMutation=(data)=>{  
  return`
  mutation MyMutation { 

  expenseReconciliationDetailsCreateMutation(${data}) { 

    errors 

    success 
     expenseReconciliationDetailsInstance{
      id
      paymentVoucherId{
        id
        date
        empAmount
        expenseRequestId{
        expenseRequestNo
      }
        employeeId {
        employeeId
        employeeName
        id
      }
      }
      createdBy{
        id
      }
      modifiedBy{
        id
      }
      adjustedAmount
      expenseclaimSet{
    	  totalApprovedAmount
    	  reimburseAmount
    	  balanceAmount
    	  status{
    	    id
    	    name
    	  }
      }     
    
    }

  } 

} 
  `
}

export const reconsiliationCreateMutation=(data)=>{
  return`
  mutation MyMutation { 

  expenseReconciliationCreateMutation(${data}) { 

    errors 

    success 

  } 

} 
  `
}