import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'; 
import { getBatchNumberId, serialAutoGentrate, SerialDeletewithString, validatAndSerialNumber } from '../../../GraphQLQueries/PurchaseItemMutations';
import axiosInstance from '../../../api/axoiss';
import { BallTriangle } from 'react-loader-spinner'
import { ToastContainer,  toast, Bounce  } from 'react-toastify';
import { GoXCircle } from "react-icons/go";


const BatchOrSerial = ({isshowBatchSerialModel, setIsShowBatchSerialModel, referenceData,handleSaveGrnItem}) => {
   const [isbatch, setIsbatch] = useState(false)
   const [isSerial, setIsSerial] = useState(false)
   const [serialData, setSerialData] = useState({serialFormate: "", CountStart : "", serialCount : "", serialNumber: "", batchNumber:"" })
   const [isLoading, setIsLoading] = useState(false)
   const [isSaved, setIsSaved] = useState(false)
   const [serialAutoGen, setSerialAutoGen] = useState(false)
   const [stockAdded, setStockAdded] = useState(false)

   // alert message using toastify 
   const alert_custom_error = (error_msg) => { 
    toast.error(`${error_msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
   }

   useEffect(()=>{ 
    if (referenceData !== null){
        setStockAdded(referenceData?.stockAdded)
        let itemMasterData = referenceData?.purchaseOrderId?.itemMasterId
        if (itemMasterData?.batchNumber){
            if (referenceData?.batchNumber?.batchNumberName){
                setSerialData({batchNumber : referenceData?.batchNumber?.batchNumberName})
                
            }
            setIsbatch(true)
        } 
        if (itemMasterData?.serial){ 
            if (referenceData?.serialNumber?.length > 0){
                let serialList  = referenceData?.serialNumber.map(item=>item?.serialNumber) 
                setSerialData({serialNumber:serialList})
                setIsSaved(true)
            }
            if (itemMasterData?.serialAutoGentrate){
                setSerialAutoGen(true)
                setIsSerial(true) 
            } else{
                setIsSerial(true)
            }
            
            
        } 
    }
    
   },[referenceData])

   const handleChangeSerial = (e)=>{
    const {name, value} = e.target
    setSerialData(prve=>({
        ...prve,
        [name]: value
    })) 
   } 

   function generateSerialNumbers(format, start, ) {
        const serialNumbers = [];
        let zero = format.split("#")
        let zero_count = zero[zero.length - 1] 
        let count = referenceData?.qualityinspectionsreportitemdetailsSet?.[0]?.accepted ? Number(referenceData?.qualityinspectionsreportitemdetailsSet?.[0]?.accepted) :
        referenceData?.received 
        
        for (let i = 0; i < count; i++) {
            // Generate the serial number based on the current index and start value 
            const serialNumber = format.replace(`#${zero_count}`, String(start + i).padStart(zero_count, '0'));
            serialNumbers.push(serialNumber);
        }
        let addSerial = serialData.serialNumber ? serialData.serialNumber+"," + String(serialNumbers) :  String(serialNumbers)
        setSerialData({
            serialCount : "",
            serialFormate:"",
            CountStart : '',
            serialNumber: addSerial
        })
   }

   async function autoGenerateSerialNumbers(){
    setIsLoading(true)
    let itemmaster = referenceData?.purchaseOrderId?.itemMasterId?.id
    let count = referenceData?.qualityinspectionsreportitemdetailsSet?.[0]?.accepted ? Number(referenceData?.qualityinspectionsreportitemdetailsSet?.[0]?.accepted) :
        referenceData?.received
    try{
        let peremeter = ` partCode:${itemmaster}, qty: ${count}`
        const response = await axiosInstance.post(`/itemmaster/graphql`, {query:  serialAutoGentrate(peremeter)});
        const responseData = response?.['data']?.['data']?.['serialNumberAutoCreateMutation']
        if (responseData?.success){   
            setSerialData({serialNumber:String(responseData?.serialNumberItem).replace(/'/g, "")})
            setIsLoading(false)
        } else{
            alert_custom_error(responseData?.errors)    
            setIsLoading(false)
        }
    } catch(error){
        alert_custom_error(error)
        setIsLoading(false)
    }

   }
   const clearBatchNumber = async() => {
    setIsLoading(true)
        let saveData = {
        "id" : referenceData?.id, 
        }
        if (isbatch){
            try{ 
                saveData['batchNumber'] =  ''
                let data  = await handleSaveGrnItem("",saveData)
                if (data){ 
                        setSerialData(prevData => ({ ...prevData, batchNumber: '' }));
                    setIsLoading(false)
                } 
            } catch(error){
                alert_custom_error(error)
                setIsLoading(false)
            }
        }
setIsLoading(false)

  };

   async function SaveBatchSerial() {
        setIsLoading(true)
        let saveData = {
        "id" : referenceData?.id, 
        }
        let count = referenceData?.qualityinspectionsreportitemdetailsSet?.[0]?.accepted ? Number(referenceData?.qualityinspectionsreportitemdetailsSet?.[0]?.accepted) :
        referenceData?.received
        if (isbatch){
            let peremeter = `batchNumber : "${serialData?.batchNumber}", partNumberId: ${Number(referenceData?.purchaseOrderId?.itemMasterId?.id)} `
            try{
                const response = await axiosInstance.post(`/itemmaster/graphql`, {query:  getBatchNumberId(peremeter)});
                const responseData = response?.['data']?.['data']?.['getBatchNumberId']
                if (responseData?.success){
                    saveData['batchNumber'] =  Number(responseData?.batchNumberInstance?.id)
                    let data  = await handleSaveGrnItem("",saveData)
                    if (data){
                        setIsShowBatchSerialModel(false)
                        setIsLoading(false)
                    } 
                } else{
                    alert_custom_error(responseData?.errors)    
                    setIsLoading(false)
                }
            } catch(error){
                alert_custom_error(error)
                setIsLoading(false)
            }
        } 
        if (isSerial){ 
            console.log(isSerial,"in");
            let serialList = serialData?.serialNumber?.split(",").map(item => `"${item.trim()}"`); 
            let peremeter = `qty: ${count}, serialNumber: [${serialList.join(",")}], itemmasterId: ${Number(referenceData?.purchaseOrderId?.itemMasterId?.id)}` ;  
            try{ 
                const response = await axiosInstance.post(`/itemmaster/graphql`, {query:  validatAndSerialNumber(peremeter)});
                const responseData = response?.['data']?.['data']?.['validatAndSerialNumberCreateMutation']
                setIsSaved(false) 
                if (responseData.success){
                    let serial_ids = responseData?.serialNumberItem.map(item => item.id)
                    saveData['serialNumber'] =serial_ids
                    let data  = await handleSaveGrnItem("",saveData)
                    if (data){
                        setIsShowBatchSerialModel(false)
                        setIsLoading(false)
                    }
                } else{ 
                    alert_custom_error(responseData?.errors)    
                    toast.error(responseData?.errors, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setIsLoading(false)
                }
            } catch(error){ 
                alert_custom_error(error)
                setIsLoading(false)
            }
        }
   }

   async function deleteSerial(serialDataList) {
    setIsLoading(true); // Start loading before the loop
    let serialItems ;
   if (serialDataList?.length > 0){
    serialItems = serialDataList
   } else{
      serialItems = serialDataList.split(",");
   }
    
    let unclearSerial = ""
    for (const serialItem of serialItems) {
      try {
        const response = await axiosInstance.post(`/itemmaster/graphql`, { query: SerialDeletewithString(serialItem) });
        const responseData = response?.data?.data?.serialNumberStringDeleteMutation;
        
        if (responseData?.success) {
          
        } else {
            unclearSerial +=serialItem
          alert_custom_error(responseData?.errors);
        }
      } catch (error) {
        unclearSerial +=serialItem
        alert_custom_error(error);
      }
    }
    if (unclearSerial === ""){
        setIsSaved(false)
    }
    setSerialData(prevData => ({ ...prevData, serialNumber: unclearSerial }));
    setIsLoading(false); // Stop loading after the loop
  }
  
 
  return (

     <> 
         <ToastContainer/>
        <Modal show={isshowBatchSerialModel} onHide={setIsShowBatchSerialModel}  backdrop="static" centered>
     
            <Modal.Body> 
                {isLoading ? 
                    <div>
                        <div className='d-flex justify-content-center'>
                        <BallTriangle 
                            height={100}
                            width={100}
                            radius={5}
                            color="#4fa94d"
                            ariaLabel="ball-triangle-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            />
                        </div>
                        <div className='d-flex justify-content-center'>
                        <p style={{color:"#4fa94d"}} className='m-3'>Loading . . .</p>
                        </div> 
                    </div> :
                    <>
                    <div className="row">
                        <div className="col-12 staan-input-group" hidden = {!isbatch}> 
                            <input name='batchNumber'  type='text' disabled={stockAdded} value={serialData.batchNumber} onChange={(e)=>{handleChangeSerial(e)}}  className='staan-form-input'/>
                            <label htmlFor="batchNumber" className='staan-form-input-label pt-2 px-1 mx-2'>Batch Number</label>
                            <GoXCircle className='cursor-pointer ms-2' style={{top:"-2rem", right:"-30rem",position:"relative"}} onClick={clearBatchNumber}   />
                        </div>  
                        <div className="col-12" hidden={!isSerial }>
                            <h4>Serial Number</h4>
                            <div className="row">
                                <div className="col-6 staan-input-group"> 
                                    <input name='CountStart' value={serialData.CountStart} disabled={serialAutoGen ? true : isSaved} onChange={(e)=>{handleChangeSerial(e)}}  type='text' className='staan-form-input'/>
                                    <label htmlFor="CountStart" className='staan-form-input-label pt-2 px-1 mx-2'>Start</label>
                                </div>
                                <div className="col-6 staan-input-group"> 
                                    <input name='serialFormate' value={serialData.serialFormate} disabled={serialAutoGen ? true : isSaved} onChange={(e)=>{handleChangeSerial(e)}}  type='text' className='staan-form-input'/>
                                    <label htmlFor="Serial formate" className='staan-form-input-label pt-2 px-1 mx-2'>Serial format</label>
                                </div>
                                <div className="col-12 d-flex justify-content-end me-2 mt-3">
                                    <button type="button"  hidden={ serialAutoGen}class="btn btn-outline-success" onClick={()=>{
                                        generateSerialNumbers((serialData.serialFormate),Number(serialData.CountStart)  )
                                    }} disabled={ isSaved  ? true : !( Number(serialData.CountStart) && serialData.serialFormate )} >Generate</button>
                                    <button hidden={!serialAutoGen} type="button" class="btn btn-outline-success"  disabled={ isSaved  ? true : serialData.serialNumber ? true : false} onClick={autoGenerateSerialNumbers} >Serial Auto Generate</button>
                                </div>
                                <div className="col-12 my-3">
                                    <textarea style={{width:"100%"}} disabled={serialAutoGen ? true : isSaved} name='serialNumber' onChange={(e)=>{handleChangeSerial(e)}} value={serialData.serialNumber} rows={4}/>
                                    {serialAutoGen ? "": <GoXCircle className='cursor-pointer ms-2' style={{top:"-6rem", right:"-30rem",position:"relative"}} onClick={()=>{deleteSerial(serialData.serialNumber)}}    /> }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='text-end'>
                            <Button variant="outline-danger" onClick={()=>{setIsShowBatchSerialModel(false)}} className='mx-2' size='sm'  >
                                Cancel
                            </Button>
                            <Button type='submit' className='mx-2 px-3' size='sm' variant="outline-success" disabled={ isSaved ? true : stockAdded? true: false} onClick={SaveBatchSerial} >
                                Save
                            </Button>
                        </div>
                    </div> 
                    </>
                }
            
            </Modal.Body>
            
        </Modal>
 
        
     </>
  )
}

export default BatchOrSerial