import { Field, Form } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import WaringModel from '../../../components/Warings/WaringModel';
import DataContext from '../../../context/ItemMasterContext';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { OnchangeGetData, calculateFinalPrice, customSelectStyle } from '../../../utils/Utils';
import { SellingSchema } from '../../../validations/itemmaster';


const SellingPrice = ({values, dupData, touched, errors, handleSellingClose ,Selling ,  setFieldValue, tax
,  setAccountGroupType,  type, setSelectedItem_Indicator, setType}) => {


  const {Accountdata,handlAccountShow,AccountGroupdata, handleSupplierShow, ItemAccountSalesSelect,  } = useContext(DataContext)
  const [salesAccountdata, setsalesAccountdata] = useState([]) 
  const [errorsShow, setErrorsShow] = useState()
  const [waringModel, setWaringModel] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState({
    value: null,
    label: null,
  }); 

  const AccountsellingRef_ = useRef(null)
  // on change handleSelectAccount
  const handleSelectAccount = (option) => {
    if (option){
      setSelectedAccount( { value: option.value , label:  option.label });
    } else{
      setSelectedAccount( { value: "" , label:  "" });
    }
    
  };
      
 
  useEffect(()=>{
    const updatedSalesAccountData = [];
    for (let Accountdata_ of Accountdata) {
      const group = Accountdata_.Accounts_Group_Name
      if(group){
        const data = AccountGroupdata.find(item => item.id === group )
        if(data.Accounts_Type ==="Asset" || data.Accounts_Type ==="Income" ){
          updatedSalesAccountData.push(Accountdata_)
        }
      }
    }
    // const defaultSelectedAccountdataSales = Accountdata.find(item => item.id === dupData?.['Item_Sales_Account']);
    // if(defaultSelectedAccountdataSales){
    //  
    // }
      setsalesAccountdata(updatedSalesAccountData)

    
  },[Accountdata, AccountGroupdata])

  useEffect(()=>{ 
    if (dupData){
      if (dupData?.['itemMrp']){
        setFieldValue("Item_Mrp", dupData['itemMrp'])
      }
      if (dupData?.['itemMinPrice']){
       setFieldValue("Item_min_price", dupData['itemMinPrice'])
      }
      if(dupData?.['itemSalesAccount']){ 
      setFieldValue("Item_Sales_Account", dupData['itemSalesAccount']['id']) 
      setSelectedAccount({ value: dupData['itemSalesAccount']['id'], label: dupData['itemSalesAccount']['accountsName'] })
     } 
      setFieldValue("sell_on_mrp", dupData['sellOnMrp'])
    }
      
  },[dupData])


  
  //retun null; if add get Account  after it selete
   useEffect(()=>{
    if (ItemAccountSalesSelect){   
     
      setSelectedAccount({value: ItemAccountSalesSelect.id, label: ItemAccountSalesSelect.lable})
      setFieldValue('Item_Sales_Account', ItemAccountSalesSelect.id)
    }
   },[ItemAccountSalesSelect])

    //retun null; getting data for react select 
  async function getOptions(e, queryName, optionName){

    let search_term = e.trim().replace(/"/g, '\\"');
    if(queryName === "accountsMasterOptionQueryWithFiliter" && search_term){
      let responseData =  await  OnchangeGetData(QueryData[queryName](search_term,"Asset","Income"))
      if(responseData.success){
        setsalesAccountdata(responseData.data[optionName]['items'])
      } 
    } else{
      setsalesAccountdata([])
    }
  }
  function onCloseClearValue(params) {
    values.Item_Mrp = 0
    values.Item_min_price = 0
    values.Item_Sales_Account = ""
    handleSelectAccount()
    setErrorsShow("")
    handleSellingClose() 
    setSelectedItem_Indicator({ value: "", label:  "" })
    setType("")
  }
  
  return (
    <>
      <Form >
        <Modal show={Selling} onHide={()=>{
            setWaringModel(true)
        }}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Selling Price</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <sapn className="text-danger">{errorsShow}</sapn>
          </div>
        <div className="row mx-2">
             <div className="col-6 staan-input-group">
                <Field type='text' name='Item_Mrp'  className='w-100  staan-form-input '/>
                <label htmlFor="Item_Mrp" className='staan-form-input-label pt-2 px-1 mx-2'>Item Max Price</label>
                {touched.Item_Mrp &&  errors.Item_Mrp && <small>{errors.Item_Mrp}</small>}
             </div>
             <div className="col-6 staan-input-group">
                  <Field type='text' name='Item_min_price'  className='w-100  staan-form-input '/>
                  <label htmlFor="Item_min_price" className='staan-form-input-label pt-2 px-1 mx-2'>Item Min Price</label>
                  {touched.Item_min_price &&  errors.Item_min_price && <small>{errors.Item_min_price}</small>}
             </div> 
        </div> 
            <div className='row mx-2'>
              <div className="col-6 staan-input-group">
                  <Select
                      name="Item_Sales_Account"
                      onInputChange={(e)=>{getOptions(e,"accountsMasterOptionQueryWithFiliter","accountsMaster")}}
                      styles={customSelectStyle}
                      options={salesAccountdata.map((item) => ({ value: item.id, label: item.accountsName }))}
                      isSearchable={true}
                      isClearable
                      ref={AccountsellingRef_} 
                      value={selectedAccount}
                      onChange={(option) => {setFieldValue('Item_Sales_Account', option ? option.value : null);
                      handleSelectAccount(option)} }
                      className='my-3'
                    />
                    <label htmlFor="Item_Sales_Account" className='staan-form-input-label pt-2 px-1 mx-2'>Item Sales Account 
                      <i className='bx bx-plus-circle' role="button"  onClick={()=>{
                        handlAccountShow()
                        setAccountGroupType("sell")
                      }} ></i></label>
                      {  touched.Item_Sales_Account &&  errors.Item_Sales_Account && <small>{errors.Item_Sales_Account}</small>}
              </div>
              <div className="col-3 mt-4 text-start">
                <span style={{fontSize: '.8rem'}} name="random_text">{values.sell_on_mrp && 'MRP: ' + calculateFinalPrice(values.Item_Mrp, tax)}</span>
              </div>
              <div className="col-3 mt-4 text-end">
                <label style={{fontSize: '.8rem', top: '5px'}} className='pb-1'>Sell on MRP</label>
                <Field type="checkbox" name="sell_on_mrp" className='ms-3' />
              </div>
            </div>
            <div className='row mx-2'>
              <div className='text-end '>
                <Button variant="outline-success" size="sm" onClick={()=>{
                  SellingSchema.validate({"Item_Mrp": values.Item_Mrp,
                    "Item_Sales_Account" : values.Item_Sales_Account
                   }).then(function(validatedData) {
                    setErrorsShow("")
                    handleSellingClose()
                  })
                  .catch(function(err) { 
                      setErrorsShow(err.errors[0])
                  }) 
                  }}>Save</Button>
              </div>
            </div>
            {waringModel && <WaringModel
             state = {waringModel}
             setstate={setWaringModel}
             title={'Confirm'}
             text={'Confirm to Clear Value'}
             Confirmtion={value => {
              onCloseClearValue(value)
            }}
            />}
        </Modal.Body>
       </Modal>
      </Form>
    </>
  )
}

export default SellingPrice