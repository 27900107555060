import { Modal } from "react-bootstrap";
import { Formik, Form} from "formik";
import { useEffect, useState, useContext, useRef, useCallback} from "react";
import Select from 'react-select';
import { customSelectStyle } from '../../../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import DataContext from '../../../context/ItemMasterContext';
import { toast, ToastContainer } from "react-toastify";
import axiosInstance from "../../../api/axoiss";
import { FetchMrpSourceOptionQuery, MrpItemMasterFetchQuery } from "../../../GraphQLQueries/MrpQuery";
import { FetchBOMOptionOnType } from "../../../GraphQLQueries/BomMutation";
import { TotalStockByPartCode } from '../../../GraphQLQueries/ItemmasterQuery'
import { fetchItemMasterData } from "../../../ReduxState/Action/itemMasterAction";
import { ProductionOrderItemCreateQuery, ItemComboBomFetch } from "../../../GraphQLQueries/ProductionOrderQuery";
import PoItemComboSelect from "./PoItemComboSelect";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { HandleComboChild } from '../../../utils/production_order_util';



const POItem = ({show, close, handleAddedItem, data, isCombo, 
    handleAddedRawMaterials, handleAddedSubProductions, handleAddedSubProductionsForCombo, 
    department, isMultiLevelManufacture, handleAddedRawMaterialsForPoItem, poItemData}) => {
    const {userId} =   useContext(DataContext)
    const gridRefChild = useRef();
    const dispatch = useDispatch(); 
    const itemMasterDataList = useSelector(state => state.itemMasterData.data);
    const [sourceTypeOptions, setSourceTypeOptions] = useState([])
    const [bomOptionList, setBomOptionList] = useState([]);
    let initialMrpItemData = {
        itemPartCode: null,
        itemName: null,
        bom: null,
        qty: 1,
        availableQty: 0,
        shortageQty: 0,
        supplier: '',
        sourceType: '',
        cost: 0,
    }
    const [mrpItemData, setMrpItemData] = useState(initialMrpItemData);
    const [selectedPartCode, setSelectedPartCode] = useState(null)
    const [selectedPartName, setSelectedPartName] = useState(null)
    const [selectedBom, setSelectedBom] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [qty, setQty] = useState(1);
    const [cost, setCost] = useState(0);
    const [availableQty, setAvailableQty] = useState(0);
    const [supplierList, setSupplierList] = useState([]);
    const [sourceType, setSourceType] = useState(null);
    const [showComboModal, setShowComboModal] = useState(false);
    const [childItems, setChildItems] = useState([]);
    const [showChildSelection, setShowChildSelection] = useState(false);
    const [selectedComboChild, setSelectedComboChild] = useState([]);
    const [disablePoSaveButtton, setDisablePoSaveButton] = useState(false);
    const [ExistingPoItems, setExistingPoItems] = useState([])
    const rowStyle = { background: 'white' };
    const defaultColDef ={
        // sortable:true, 
        // filter:true, 
        // floatingFilter:true,  
        resizable: true,
    }

    const comboChildColumns = [
        {
            headerName : "ID",
            valueGetter: "node.rowIndex + 1",
            flex: 1, 
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,   
        },
        {
            headerName : "Part Code",
            field:"partNumber.itemPartCode",  
            flex:2,
        },
        {
            headerName : "Part Name",
            flex:4,
            field:"partNumber.itemName"
        },
        {
            headerName : "Qty",
            field:"itemQty",  
            flex: 2,
            editable: true,
            valueGetter: params => parseFloat(params.data.itemQty) * parseFloat(qty || 1),
            cellStyle: params => {
                const itemQty = parseFloat(params.value);
                const totalStock = parseFloat(params.data.totalStock);
        
                if (totalStock >= itemQty) {
                    return { color: 'green' };
                } else {
                    return { color: 'red' };
                }
            }
        },
        {
            headerName : "Stock",
            field:"totalStock",  
            flex:2
        },
        {
            headerName : "BOM",
            field: "selectedBom",  
            flex: 5,
        } 
    ]


    const handleShowComboModal =  () => {
        if(isCombo){
            setShowComboModal(true)
            setDisablePoSaveButton(true)
        }else{
            setDisablePoSaveButton(true)
            if(isMultiLevelManufacture){
                setShowChildSelection(true)
                handleFetchBomDataForComboItem()
            }else{
                handleSubAssemblyIntoRawMaterial()
            }
        }
    }

    const handleSubAssemblyIntoRawMaterial = () => {
        // main item
        let main_data = {
            partCode: selectedPartCode,
            bom: selectedBom,
            qty: qty,
            sourceType: parseInt(sourceType?.value || 1),
            cost: cost,
            modifiedBy: parseInt(userId),
            createdBy: parseInt(userId),
        }
        try {
            let post_body_content = {
                "created_by": userId,
                "combo": [main_data],
                "department": department?.value,
                "qty": qty,
            }
            // console.log(post_body_content)
            axiosInstance.post('/itemmaster/direct-rm-creation', {data: post_body_content})
            .then(response => {
                handleAddedRawMaterialsForPoItem(response?.data?.data?.raw_material || [])
                close()
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.error('Error fetching suppliers:', error);
        }
    }

    const handleCloseComboModal =  () => {
        setShowComboModal(false)
        if(isMultiLevelManufacture){
            setShowChildSelection(true)
            handleFetchBomDataForComboItem()
        }else{
            close()
        }
    }

    const handleSelectpartCode = (option)=>{
        if(ExistingPoItems.includes(option?.value)){
            toast.error('This Item Already Exists!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }else{
            setSelectedPartCode({ value: option?.value , label:  option?.label })
        }
    }

    const handleSelectpartName = (option)=>{ 
        if(ExistingPoItems.includes(option?.value)){
            toast.error('This Item Already Exists!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }else{
            setSelectedPartName({ value: option?.value , label:  option?.label })
        }
    }

    const handleSelectQty = async (value) => {
        setQty(value);
        try{
            const total_stock_res = await axiosInstance.post(`/itemmaster/graphql`, { query: TotalStockByPartCode(selectedPartCode?.value) });
            const total_stock_res_data = total_stock_res?.data?.data?.itemStockByPartCode?.items?.[0]?.totalCurrentStock || 0;  
            setAvailableQty(total_stock_res_data)
            let calculate_shortage_qty = total_stock_res_data - value
            if(calculate_shortage_qty < 1){
                // setShortageQty(Math.abs(calculate_shortage_qty))
            }
        }catch(e){
            console.log(e)
            // setCost(0)
            // setShortageQty(0)
        }
    }

    const handleSelectBom = (option, setFieldValue) => {
        setSelectedBom(option);
        if (option) {
            if (option.bomType === "MANUFACTURE") {
                setSourceType({ label: "MANUFACTURE", value: 1 });
                setFieldValue("sourceType", 1);
                setSupplierList([]); // Clear supplier list when source type is MANUFACTURE
            } else {
                // Set source type based on the fetched options
                let sourceTypeOption = sourceTypeOptions.find((item) => item.label === option.bomType);
                if (sourceTypeOption) {
                    setSourceType(sourceTypeOption);
                    setFieldValue("sourceType", sourceTypeOption.value);
                }
    
                // Select suppliers for other source types
                let supplierList = option.supplier || [];
                supplierList = supplierList.map((item) => ({ label: item.companyName, value: item.id }));
                setSupplierList(supplierList);
                setFieldValue("supplier", supplierList.map((item) => item.value));
            }
        } else {
            setSourceType(null);
            setSupplierList([]);
        }
    };
    
    // Function to check if default BOM should be set
    const shouldSetDefaultBom = (options) => {
        return options.length === 1;
    };

    // Function to get the default BOM option
    const getDefaultBomOption = (options) => {
        return options.length === 1 ? options[0] : null;
    };


    const handleUpdateDataBasedOnPartCode = async (option, setFieldValue) =>{
        if(option){
            // set source type from item master only for buy type
            let item_master_indicator_type = option?.itemIndicators?.name 
            if(option?.isManufacture){
                setSourceType({label: "MANUFACTURE", value: 1})
                setFieldValue('sourceType', 1)
                
            }
            else if(item_master_indicator_type && item_master_indicator_type === "Buy"){
                setSourceType({label: "BUY", value: 3})
                setFieldValue('sourceType', 3)
            }else{
                setSourceType(null)
            }
            // set item price
            setCost(option?.itemMrp || 0)

            // get available qty 
            const total_stock_res = await axiosInstance.post(`/itemmaster/graphql`, { query: TotalStockByPartCode(option?.id) });
            const total_stock_res_data = total_stock_res?.data?.data?.itemStockByPartCode?.items?.[0]?.totalCurrentStock || 0;  
            setAvailableQty(total_stock_res_data)

            // select supplier
            let supplier_list = option?.supplierData || [];
            if(supplier_list && supplier_list.length > 0){
                supplier_list = supplier_list.filter(item => item.supplier === true).map(item => ({label: item.companyName, value: item.id}))
            }
            setSupplierList(supplier_list)
            setFieldValue('supplier', supplier_list.map(item => item.value))

            // fetch bom based on part code
            axiosInstance.post("/itemmaster/graphql", {query: FetchBOMOptionOnType(option?.id)})
            .then(response => {
                const keys = Object.keys(response.data.data);
                const firstKey = keys.length > 0 ? keys[0] : null;
                const data = response.data.data[firstKey];
                let bom_data_list = data?.items || []
                bom_data_list = bom_data_list.map(item => ({value: item.id, label : `${item.bomNo} --- ${item.bomName}`, ...item}))
                setBomOptionList(bom_data_list)
               // Set default BOM option if necessary
               const defaultBomOption = shouldSetDefaultBom(bom_data_list) ? getDefaultBomOption(bom_data_list) : null;
               setSelectedBom(defaultBomOption);
               setFieldValue('bom', defaultBomOption ? defaultBomOption.value : null);
               if(defaultBomOption?.bomType === "SUBCONTRACT"){
                    setSourceType({label: "SUBCONTRACT", value: 2})
                    setFieldValue('sourceType', 2)
               }
            })
            .catch(error => {
                console.log(error)
            });
        }else{
            handleClearData()
        }
    }

    const handleClearData = () => {
        setMrpItemData(initialMrpItemData)
        setSelectedPartCode(null)
        setSelectedPartName(null)
        setQty(1)
        setAvailableQty(0)
        // setShortageQty(0)
        setCost(0)
        setSupplierList([])
        setSourceType(null);
    }

    const handleOnInputChangeTypeSearch = (value, field) => {
        if(value.trim() !== ''){
            let filter_obj = {
                key: field,
                value: value
            }
            dispatch(fetchItemMasterData(MrpItemMasterFetchQuery(filter_obj)));
        }else{
            dispatch(fetchItemMasterData(MrpItemMasterFetchQuery()))
        }
    }

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors, setFieldError }, toggle, setOpenRowIndex, rowdata) => {
        let submit_data = {
            partCode: parseInt(selectedPartCode?.value),
            bom: parseInt(selectedBom?.value),
            qty: qty,
            sourceType: parseInt(sourceType?.value || 1),
            cost: cost,
            modifiedBy: parseInt(userId),
            createdBy: parseInt(userId),
        }
        if(selectedSupplier){
            submit_data['supplier'] = [parseInt(selectedSupplier?.value)]
        }
        if(data){
            submit_data['id'] = data?.id
        }
        let prod_order_item_create_query = ProductionOrderItemCreateQuery(submit_data);
        axiosInstance.post("/itemmaster/graphql", {query: prod_order_item_create_query})
        .then(response => {
            const keys = Object.keys(response.data.data);
            const firstKey = keys.length > 0 ? keys[0] : null;
            const data = response.data.data[firstKey];
            if(data?.success){
                handleAddedItem(parseInt(data?.productionOrderItem?.id || 0))
                setMrpItemData({...mrpItemData, 'id': data?.productionOrderItem?.id})
                toast.success('Data saved successfully!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })

                handleShowComboModal()
            }else{
                toast.error(data?.errors?.[0], {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
        })
        .catch(error => {
            console.log(error)
            toast.error('Failed to Save', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        });
    }

    const FetchMrpSourceType = () => {
        let mrp_source_option_query = FetchMrpSourceOptionQuery()
        axiosInstance.post("/itemmaster/graphql", {query: mrp_source_option_query})
        .then(res => {
            let mrp_source = res?.data?.data?.mrpSource?.items || []
            if(mrp_source && mrp_source.length > 0){
                mrp_source = mrp_source.map(item => ({label: item.name, value: item.id}))
                setSourceTypeOptions(mrp_source)
            }
        })
        .catch(err =>{ 
            console.log(err, "error")
        })
    }

    const onSelectionChangedChild = useCallback(() => {
        var selectedRows = gridRefChild.current.api.getSelectedRows();
        if(selectedRows.length > 0){
            setSelectedComboChild(selectedRows);
        }else{
            setSelectedComboChild(null);
        }
    }, []);

    const handleFetchBomDataForComboItem = () => {
        let selected_bom = `${selectedBom.bomNo} -- ${selectedBom.bomName} -- ${selectedBom.bomType}`
        let item_combo_fetch_query = ItemComboBomFetch([selected_bom], isMultiLevelManufacture);
        axiosInstance.post("/itemmaster/graphql", {query: item_combo_fetch_query})
        .then(response => {
            let item_combo_bom_data = response?.data?.data?.partCodeHasBom?.items || [] ;
            HandleComboChild(item_combo_bom_data)
            .then(com_childs => {
                setChildItems(com_childs)
            })
            .catch(err => {
                console.log(err)
            })
        })
        .catch(error => {
            console.log(error)
        });
    }

    const handleChildSelection = () => {
        // main item
        let main_data = {
            partCode: selectedPartCode,
            bom: selectedBom,
            qty: qty,
            sourceType: parseInt(sourceType?.value || 1),
            cost: cost,
            modifiedBy: parseInt(userId),
            createdBy: parseInt(userId),
        }
        if(selectedSupplier){
            main_data['supplier'] = [selectedSupplier]
        }
        // child items
        let parent_child_related = {}
        selectedComboChild?.forEach(child_item => {
            let parent = child_item.parent;        
            if (parent_child_related[parent]) {
                parent_child_related[parent].push(child_item);
            } else {
                parent_child_related[parent] = [child_item];
            }
        });
        try {
            let post_body_content = {
                "created_by": userId,
                "combo": [main_data],
                "child": parent_child_related,
                "department": department?.value,
                "is_multi": isMultiLevelManufacture,
                "qty": qty,
            }
            // console.log(post_body_content)
            axiosInstance.post('/itemmaster/sub-po-creation', {data: post_body_content})
            .then(response => {
                handleAddedSubProductions(response?.data?.data?.sub_production || [])
                handleAddedRawMaterialsForPoItem(response?.data?.data?.raw_material || [])
                close()
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.error('Error fetching suppliers:', error);
        }
    }
 
    useEffect(()=>{
        setExistingPoItems(poItemData?.map(item => item?.partCode?.id) || [])
        if(data){
            setDisablePoSaveButton(true)
            // console.log(data)
            setCost(data?.cost || 0)
            setQty(data?.qty)
            setSelectedPartCode({label: data?.partCode?.itemPartCode, value: data?.partCode?.id, ...data?.partCode})
            setSelectedPartName({label: data?.partCode?.itemName, value: data?.partCode?.id, ...data?.partCode})
            setSelectedBom({value: data?.bom?.id, label : `${data?.bom?.bomNo} --- ${data?.bom?.bomName}`, ...data?.bom})
            setSelectedBom({ value: data.bom.id, label: `${data.bom.bomNo} --- ${data.bom.bomName}`, ...data.bom });
            let supplier_data = data?.supplier?.map(item => ({label: item.companyName, value: item.id}))
            setSelectedSupplier(supplier_data?.[0])
            setSupplierList(supplier_data)
            setSourceType({label: data?.sourceType?.name, value:data?.sourceType?.id})
            setMrpItemData({
                itemPartCode: data?.partCode?.id,
                itemName: data?.partCode?.id,
                bom: data?.bom?.id,
                qty: data?.qty,
                availableQty: 0,
                shortageQty: 0,
                supplier: supplier_data?.[0]?.value,
                sourceType: data?.sourceType?.id,
                cost: data?.cost || 0,
            })
        }
        FetchMrpSourceType()
    }, [data])


    return (<>
        <ToastContainer />
        <Modal show={show} onHide={close} fullscreen = {true}
            dialogClassName="modal-transparent" backdrop="static">
            <Modal.Body>
                <div style={{fontSize: '.8rem', width: '90%'}} 
                    className='mx-auto bg-white shadow-lg'>
                    <div className="row mx-auto py-3 border-bottom border-light shadow-sm " style={{width: "100%"}}>
                        <div className="col-5 my-auto">
                            <h4>
                                <span className='me-3' style={{cursor: 'pointer'}} 
                                onClick={()=>{
                                    close()
                                }}>
                                    <i className="fa-solid fa-arrow-left  fs-4"></i>
                                </span>
                                PO Item
                            </h4>
                        </div>
                        <div className='col-5'></div>
                    </div>
                    <Formik 
                        enableReinitialize={true}
                        initialValues = {mrpItemData}
                        // validationSchema={mrp_item_schema}   
                        onSubmit={handleSubmit}
                    >
                    {({errors, touched, isSubmitting, values, setFieldValue , Formik, formikProps })=>{
                        return(<>
                            <Form style={{width: "100%", height: '100%'}}>
                                <div>
                                    <div className="mx-3">
                                        <div className='row pt-3'>
                                            <div className="col-6 staan-input-group">
                                                <Select
                                                    onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemPartCode')}}
                                                    options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemPartCode, name:item.itemName, ...item  }))}
                                                    name='itemPartCode' style={{fontSize: '.8rem'}} 
                                                    isSearchable={true}
                                                    isClearable
                                                    value={selectedPartCode}
                                                    isDisabled = {disablePoSaveButtton}
                                                    onChange={(option) => {
                                                        setFieldValue('itemName', option ? option.value : null);
                                                        setFieldValue('itemPartCode', option ? option.value : null)
                                                        handleClearData()
                                                        handleSelectpartName({ value: option?.value, label: option?.name }) 
                                                        handleSelectpartCode(option)
                                                        handleUpdateDataBasedOnPartCode(option, setFieldValue)
                                                    }}  
                                                    className='mt-3'
                                                    styles={customSelectStyle}
                                                    defaultValue={shouldSetDefaultBom(bomOptionList) ? getDefaultBomOption(bomOptionList) : null}  // Set default value conditionally           
                                                />
                                                <label htmlFor="itemPartCode" className='staan-form-input-label pt-1 px-1 mx-2'>Part Code</label>
                                                <p>{  touched.itemPartCode &&  errors.itemPartCode && <small>{errors.itemPartCode}</small>}</p>
                                            </div>
                                            <div className="col-6 staan-input-group">
                                                <Select
                                                    onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemName')}}
                                                    name='itemName' style={{fontSize: '.8rem'}} 
                                                    options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemName , partCode: item.itemPartCode, ...item}))}
                                                    isSearchable={true}
                                                    value={selectedPartName}
                                                    isClearable
                                                    isDisabled = {disablePoSaveButtton}
                                                    onChange={(option) => {
                                                        setFieldValue('itemName', option ? option?.value : null);
                                                        setFieldValue('itemPartCode', option ? option?.value : null)
                                                        handleClearData()
                                                        handleSelectpartName(option) 
                                                        handleSelectpartCode({ value: option?.value, label: option?.partCode })
                                                        handleUpdateDataBasedOnPartCode(option, setFieldValue)
                                                    }}  
                                                    className='mt-3'
                                                    styles={customSelectStyle}  
                                                />
                                                <label htmlFor="itemName" className='staan-form-input-label pt-1 px-1 mx-2'>Part Name</label>
                                                <p>{  touched.itemName &&  errors.itemName && <small>{errors.itemName}</small>}</p>
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col-6 staan-input-group">
                                                <Select
                                                    name='bom' style={{fontSize: '.8rem'}} 
                                                    options={bomOptionList}
                                                    isSearchable={true}
                                                    value={selectedBom}
                                                    onChange = {(option)=>{
                                                        setSelectedBom(option);
                                                        setFieldValue('bom', option ? option?.value :null);
                                                        handleSelectBom(option, setFieldValue)
                                                    }}
                                                    isClearable 
                                                    isDisabled = {disablePoSaveButtton}
                                                    className='mt-3'
                                                    styles={customSelectStyle}  
                                                    defaultValue={bomOptionList.length === 1 ? bomOptionList[0] : null}
                                                />
                                                <label htmlFor="bom" className='staan-form-input-label pt-1 px-1 mx-2'>Bom</label>
                                                <p>{  touched.bom &&  errors.bom && <small>{errors.bom}</small>}</p>
                                            </div>
                                            <div className="col-6 staan-input-group">
                                                <Select
                                                    name='sourceType' style={{fontSize: '.8rem'}} 
                                                    value={sourceType}
                                                    isDisabled = {true}
                                                    className='mt-3'
                                                    styles={customSelectStyle}  
                                                />
                                                <label htmlFor="sourceType" className='staan-form-input-label pt-1 px-1 mx-2'>Source Type</label>
                                                <p>{ touched.sourceType &&  errors.sourceType && <small>{errors.sourceType}</small>}</p>
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col-2 staan-input-group">
                                                <input type='text' className='w-100 staan-form-input' 
                                                    onChange={(e)=>{
                                                        handleSelectQty(e.target.value); 
                                                        setFieldValue('qty', e.target.value);
                                                    }}
                                                    disabled = {disablePoSaveButtton}
                                                    value={qty}
                                                    name='qty' style={{fontSize: '.8rem'}} 
                                                />
                                                <label htmlFor="qty" className='staan-form-input-label pt-2 px-1 mx-2'>Quantity</label>
                                                <p>{ touched.qty &&  errors.qty && <small>{errors.qty}</small>}</p>
                                            </div>
                                            <div className="col-2 staan-input-group">
                                                <input type='text' className='w-100 staan-form-input' 
                                                    onChange={(e)=>{
                                                        // handleSelectQty(e.target.value); 
                                                        setFieldValue('availableQty', e.target.value);
                                                    }}
                                                    disabled
                                                    value={availableQty}
                                                    name='availableQty' style={{fontSize: '.8rem'}} 
                                                />
                                                <label htmlFor="qty" className='staan-form-input-label pt-2 px-1 mx-2'>Available Quantity</label>
                                            </div>
                                            <div className="col-3 staan-input-group">
                                                <input type='text' className='w-100 staan-form-input' 
                                                    value={cost * qty}
                                                    name='cost' style={{fontSize: '.8rem'}} 
                                                    disabled
                                                />
                                                <label htmlFor="cost" className='staan-form-input-label pt-2 px-1 mx-2'>Cost</label>
                                                <p>{ touched.cost &&  errors.cost && <small>{errors.cost}</small>}</p>
                                            </div>
                                            <div className="col-5 staan-input-group">
                                                {sourceType?.value === 2 && (
                                            <>
                                            <Select
                                                name='supplier' style={{fontSize: '.8rem'}} 
                                                options={supplierList}
                                                value={selectedSupplier}
                                                className='mt-3'
                                                isDisabled = {disablePoSaveButtton}
                                                styles={customSelectStyle}  
                                                onChange={(option)=>{
                                                    setSelectedSupplier(option);
                                                }}
                                                />
                                                <label htmlFor="supplier" className='staan-form-input-label pt-1 px-1 mx-2'>Supplier</label>
                                                <p>{touched.supplier && errors.supplier && <small>{errors.supplier}</small>}</p>
                                            </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row pt-1 text-end">
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <button 
                                                        disabled = {disablePoSaveButtton}
                                                        type="submit" 
                                                        className="btn btn-outline-success px-3">Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {showChildSelection && 
                                    <div className="mt-3 mx-3">
                                        <h6 className="blink fw-bold" style={{color: '#00B2EE'}}>Select Sub Manufacturing Orders</h6>
                                        <div>
                                            <div className='ag-theme-alpine' style={{ height: '25rem', width: '100%' }} > 
                                                <AgGridReact
                                                    ref={gridRefChild}
                                                    rowData={childItems}
                                                    columnDefs={comboChildColumns}
                                                    defaultColDef={defaultColDef} 
                                                    rowSelection="multiple"
                                                    suppressRowClickSelection={true}          
                                                    onSelectionChanged={onSelectionChangedChild} 
                                                    rowStyle ={rowStyle}   
                                                />
                                            </div>
                                            <div className="d-flex justify-content-end align-items-center mt-3">
                                                <div>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-outline-success px-3"
                                                        onClick={()=>{handleChildSelection()}}
                                                    >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> }
                                </div>
                            </Form>
                        </>)
                    }}
                    </Formik>
                    {
                        showComboModal && 
                            <PoItemComboSelect 
                                show = {showComboModal}
                                close = {handleCloseComboModal}
                                data = {selectedPartCode}
                                poItem = {mrpItemData}
                                handleAddedRawMaterials = {handleAddedRawMaterials}
                                handleAddedSubProductionsForCombo = {handleAddedSubProductionsForCombo}
                                department = {department}
                                isMultiLevelManufacture = {isMultiLevelManufacture}
                                qty = {qty}
                            />
                    }
                </div>
            </Modal.Body>
        </Modal>
    </>)
}

export default POItem;