import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import axiosInstance, { commanFetch } from '../../../../api/axoiss';
import showErrorToast from '../../../../components/notifications/react-toastify/toast';
import DataContext from '../../../../context/ItemMasterContext';
import { meetingCreateMutation } from '../../../../GraphQLQueries/ActivitiesQueryAndMutations/ActivitiesMutations';
import { userFiliterbypermission } from '../../../../GraphQLQueries/EnquiryQuery';
import { customSelectStyle, removeEmptyValueInObject, ShowErrorNoties } from '../../../../utils/Utils';
import { meeting_activites_schema } from '../../../../validations/ActivitiesValidations/ActivitiesValidations';


const MettingActivitiesform = ({ setNavList, setShowForm, enquiry_id, setMeetingEdit,
    meetingEdit, setLogList, subjectValue, salesMan }) => {
    const { userId, activitiesMettingstatusList } = useContext(DataContext)
    const [formKey, setFormKey] = useState(0)
    const [initialValue, setInitialValue] = useState({
        id: "",
        subject: "Meeting",
        planedStartDate: "",
        PlanedEndDate: "",
        planedStartTime: "",
        PlanedEndTime: "",
        StartDate: "",
        EndDate: "",
        StartTime: "",
        EndTime: "",
        salesperson: "",
        status: "",
        outcome: "Empty",
        createdBy: "",
    })
    useEffect(() => {
        activitiesMettingstatusList?.map((item) => {
            if (item?.label === "Planned") {
                setStatusValue(item)
                initialValue['status'] = item?.value
            }
        })
    }, [])
    useEffect(() => {
        if (meetingEdit) {
            setInitialValue({
                id: meetingEdit?.id,
                subject: meetingEdit?.subject,
                planedStartDate: meetingEdit?.plannedStartDate,
                PlanedEndDate: meetingEdit?.plannedEndDate,
                planedStartTime: meetingEdit?.plannedStartTime,
                PlanedEndTime: meetingEdit?.plannedEndTime,
                StartDate: meetingEdit?.startDate,
                EndDate: meetingEdit?.endDate,
                StartTime: meetingEdit?.startTime,
                EndTime: meetingEdit?.endTime,
                salesperson: meetingEdit?.salesPerson?.id,
                status: meetingEdit?.status,
                outcome: meetingEdit?.outcome ? meetingEdit?.outcome : "Empty",
                createdBy: meetingEdit?.createdBy?.id
            })

            setStatusValue({ value: meetingEdit?.status?.id, label: meetingEdit?.status?.name })
            setSalesPersonValue({ value: meetingEdit?.salesPerson?.id, label: meetingEdit?.salesPerson?.username })
            setFormKey(prev => prev + 1);

        } else {
            initialValue['salesperson'] = salesMan?.value
            setSalesPersonValue(salesMan)
            setFormKey(prev => prev + 1);
        }
    }, [meetingEdit, subjectValue])
    const [salesPersonList, setSalesPersonlist] = useState([])
    const [salesPersonValue, setSalesPersonValue] = useState({ value: "", label: "" })

    const [statusValue, setStatusValue] = useState({ value: "", label: "" })


    // return null ; user to get options for selecte element
    async function fetchOptions(e) {
        let search_term = e.trim().replace(/"/g, '\\"');
        if (search_term) {
            try {
                const response = await commanFetch(userFiliterbypermission(`isSales:true, userName:"${search_term}"`));
                const reponseData = response?.data?.items
                if (response.success) {
                    setSalesPersonlist(reponseData.map((salesPerson) => (
                        { value: salesPerson?.userId?.id, label: salesPerson?.userId?.username })))
                } else {
                    console.error("Error fetching data:", response.error);
                }
            } catch (error) {

            }
        }


    }





    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        if (statusValue?.label === "Completed") {

            if (!values?.['StartDate'] || values?.['StartDate'].trim() === "") {

                showErrorToast(true, "error", "Start Date is required",)
                return ""
            }
            if (!values?.['EndDate'] || values?.['EndDate'].trim() === "") {
                showErrorToast(true, "error", "End Date is required",)
                return ""
            }
            if (values?.['StartTime'] === "00:00") {
                showErrorToast(true, "error", "Start Time is required",)
                return ""
            }
            if (values?.['EndTime'] === "00:00") {
                showErrorToast(true, "error", "End Time is required",)
                return ""
            } if (!values?.['outcome'] || values?.['outcome'].trim() === "" || values?.['outcome'].trim() === "Empty") {
                showErrorToast(true, "error", "Outcome is required",)
                return ""
            }
        }

        let saveData = {
            id: initialValue['id'] ? Number(initialValue['id']) : "",
            status: values?.['status'] ? Number(values['status']) : "",
            plannedStartDate: values?.['planedStartDate'],
            plannedEndDate: values?.['PlanedEndDate'],
            plannedStartTime: values?.['planedStartTime'],
            plannedEndTime: values?.['PlanedEndTime'],
            startDate: values?.['StartDate'] ? values?.['StartDate'] : "",
            endDate: values?.['EndDate'] ? values?.['EndDate'] : "",
            startTime: values?.['StartTime'] ? values?.['StartTime'] : "",
            endTime: values?.['EndTime'] ? values?.['EndTime'] : "",
            outcome: values?.['outcome']?.trim() !== "" && values?.['outcome'] !== null ? values?.['outcome'] : "Empty",
            subject: values?.['subject'],
            salesPerson: values?.['salesperson'] ? Number(values?.['salesperson']) : "",
            createdBy: initialValue?.id ? Number(initialValue?.['createdBy']) : Number(userId),
            modifiedBy: initialValue?.id ? Number(userId) : ""
        }

        if (enquiry_id) {
            saveData['enquiryId'] = Number(enquiry_id)
        }

        let ClearValue = removeEmptyValueInObject(saveData, ["id", "modifiedBy", "startDate", "endDate",
            "startTime", "endTime"], ['outcome'])
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: meetingCreateMutation(ClearValue)
            });
            const responseData = response?.data?.data?.meetingCreateMutation;
            if (responseData.success) {
                setShowForm(false)
                showErrorToast(true, "success", "", "Saved Successfully")
                resetForm()
                setSubmitting(false)
                setMeetingEdit(null)
                setNavList(prve => prve.map(item => ({ ...item, isShow: false })))
                activitiesMettingstatusList?.map((item) => {
                    if (item?.label === "Planned") {
                        setStatusValue(item)
                    }
                })
                if (enquiry_id) {
                    setLogList((prev) => {
                        let prev_ids = prev.map((item) => item?.status ? item.id : "").filter(item => item !== "");
                        if (prev_ids.includes(responseData?.meetingInstance?.id)) {
                            return prev.map((item) => {
                                return item?.status ? item.id === responseData?.meetingInstance?.id
                                    ? responseData?.meetingInstance
                                    : item : item;
                            });
                        } else {
                            return [responseData?.meetingInstance, ...prev];
                        }
                    });
                }


            } else {
                console.log("--->");
                console.log(responseData.errors, "--->>>");

                let errorData = ShowErrorNoties(responseData.errors);
                showErrorToast(true, "error", errorData,)
            }
        } catch (error) {
            let errorData = ShowErrorNoties(error);
            showErrorToast(true, "error", errorData)
        }
    }
    return (
        <>
            <div className=''>
                <Formik initialValues={initialValue}
                    validationSchema={meeting_activites_schema}
                    onSubmit={handleSubmit}
                    key={formKey}>
                    {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                        return (
                            <Form>
                                <div className="row">
                                    <div className="col-12 staan-input-group">
                                        <Field
                                            id="subject"
                                            name="subject"
                                            className='w-100 staan-form-input '
                                        />
                                        <label htmlFor="subject" className='staan-form-input-label pt-0 px-1 mx-2' onClick={() => {
                                            console.log(values);
                                        }}>Subject <span className='text-danger'>*</span></label>
                                        <ErrorMessage className='text-danger' name="subject" component="div" />
                                    </div>
                                    <div className="col-6 staan-input-group"  >
                                        <Field type="date" id="planedStartDate" name="planedStartDate" className='w-100 staan-form-input ' />
                                        <label htmlFor="planedStartDate" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                            console.log(errors);
                                        }} >Planned Start Date <span className='text-danger'>*</span></label>
                                        <ErrorMessage className='text-danger' name="planedStartDate" component="div" />

                                    </div>
                                    <div className="col-6 staan-input-group"  >
                                        <Field type="date" id="PlanedEndDate" name="PlanedEndDate" className='w-100 staan-form-input ' />
                                        <label htmlFor="PlanedEndDate" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                            console.log("statusValue", statusValue);
                                        }}>Planned End Date<span className='text-danger'>*</span></label>
                                        <ErrorMessage className='text-danger' name="PlanedEndDate" component="div" />

                                    </div>
                                    <div className="col-6 staan-input-group">
                                        <Field type="time" id="planedStartTime" name="planedStartTime" className='w-100 staan-form-input ' />
                                        <label htmlFor="planedStartTime" className='staan-form-input-label pt-1 px-1 mx-2' >Planned Start Time <span className='text-danger'>*</span></label>
                                        <ErrorMessage className='text-danger' name="planedStartTime" component="div" />
                                    </div>
                                    <div className="col-6 staan-input-group">
                                        <Field type="time" id="PlanedEndTime" name="PlanedEndTime" className='w-100 staan-form-input ' />
                                        <label htmlFor="PlanedEndTime" className='staan-form-input-label pt-1 px-1 mx-2' >Planned End Time<span className='text-danger'>*</span></label>
                                        <ErrorMessage className='text-danger' name="PlanedEndTime" component="div" />
                                    </div>
                                    {statusValue?.label === "Completed" &&
                                        <>
                                            <div className="col-6 staan-input-group"  >
                                                <Field type="date" id="StartDate" name="StartDate" className='w-100 staan-form-input ' />
                                                <label htmlFor="StartDate" className='staan-form-input-label pt-1 px-1 mx-2' >Actual Start Date</label>
                                                <ErrorMessage className='text-danger' name="StartDate" component="div" />

                                            </div>
                                            <div className="col-6 staan-input-group"  >
                                                <Field type="date" id="EndDate" name="EndDate" className='w-100 staan-form-input ' />
                                                <label htmlFor="EndDate" className='staan-form-input-label pt-1 px-1 mx-2' >Actual End Date</label>
                                                <ErrorMessage className='text-danger' name="EndDate" component="div" />

                                            </div>
                                            <div className="col-6 staan-input-group">
                                                <Field type="time" id="StartTime" name="StartTime" className='w-100 staan-form-input ' />
                                                <label htmlFor="StartTime" className='staan-form-input-label pt-1 px-1 mx-2' >Actual Start Time</label>
                                                <ErrorMessage className='text-danger' name="StartTime" component="div" />
                                            </div>
                                            <div className="col-6 staan-input-group">
                                                <Field type="time" id="EndTime" name="EndTime" className='w-100 staan-form-input ' />
                                                <label htmlFor="EndTime" className='staan-form-input-label pt-1 px-1 mx-2' >Actual End Time</label>
                                                <ErrorMessage className='text-danger' name="EndTime" component="div" />
                                            </div>
                                        </>
                                    }
                                    <div className="col-6 staan-input-group">
                                        <Select
                                            onInputChange={(e) => { fetchOptions(e) }}
                                            name='salesperson'
                                            isClearable
                                            styles={customSelectStyle}
                                            options={salesPersonList}
                                            className='my-3'
                                            value={salesPersonValue}
                                            onChange={(options) => {
                                                setFieldValue("salesperson", options ? options?.value : null)
                                                setSalesPersonValue(options ? options : null)
                                            }}
                                        />
                                        <ErrorMessage className='text-danger' name="salesperson" component="div" />
                                        <label htmlFor="salesperson" className='staan-form-input-label pt-1 px-1 mx-2' >Sales Person<span className='text-danger'>*</span></label>
                                    </div>
                                    <div className="col-6 staan-input-group">
                                        <Select
                                            options={activitiesMettingstatusList}
                                            name='status'
                                            isClearable
                                            styles={customSelectStyle}
                                            value={statusValue}
                                            className='my-3'
                                            onChange={(options) => {
                                                setFieldValue("status", options ? options?.value : null)
                                                setStatusValue(options ? options : null)
                                            }}
                                        />

                                        <ErrorMessage className='text-danger' name="status" component="div" />
                                        <label htmlFor="status" className='staan-form-input-label pt-1 px-1 mx-2' >Status<span className='text-danger'>*</span></label>
                                    </div>
                                    {statusValue?.label === "Completed" &&
                                        <>
                                            <div className="col-12 staan-input-group">
                                                <Field
                                                    as="textarea"
                                                    id="outcome"
                                                    name="outcome"
                                                    className='w-100 staan-form-input '
                                                    style={{ height: "4rem" }}
                                                    rows="4"
                                                    cols="50"
                                                />
                                                <label htmlFor="outcome" className='staan-form-input-label pt-1 px-1 mx-2' >Outcome</label>
                                                <ErrorMessage className='text-danger' name="outcome" component="div" />
                                            </div>
                                        </>}
                                </div>
                                <div className='row mt-3'>
                                    <div className='text-end'>
                                        <button type="button" className="btn btn-outline-danger btn-sm me-2"
                                            onClick={() => {
                                                setShowForm(false); setMeetingEdit(null);
                                                setNavList(prve => prve.map(item => ({ ...item, isShow: false })))
                                            }} >Cancel</button>
                                        <button type="submit" className="btn btn-sm btn-outline-success shadow-sm" onClick={() => {
                                            console.log(errors);
                                        }} disabled={isSubmitting} >Save</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}

                </Formik>

            </div>

        </>
    )
}

export default MettingActivitiesform