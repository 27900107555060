import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { ToastContainer, toast } from 'react-toastify';
import { ContactFetch, fetchGstTypeQuery, userOptionQuery } from '../../../GraphQLQueries/ItemmasterQuery';
import { SupplierCustomerCreateMutation, SupplierCustomerQuery } from '../../../GraphQLQueries/SupplierAndCustomerMutation/suplpliercustomermutation';
import CommanTable from '../../../Packages/Custom/CommanTable';
import { fetchGstType, fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import axiosInstance from '../../../api/axoiss';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import WaringModel from '../../../components/Warings/WaringModel';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import DataContext from '../../../context/ItemMasterContext';
import { RupessTONormalFormat, ShowErrorNoties, convertToTitleCase, formatToRupees, getCurrentDate, infoMessage, removeEmptyValueInObject } from '../../../utils/Utils';
import { SupplierFromSchema } from '../../../validations/itemmaster';
import AddressFrom from './AddressFrom';
import ContactFrom from './ContactFrom';
import CustomerGroup from './CustomerGroup';
import SupplierGroup from './SupplierGroup';



const SupplierFrom = ({ supplierEdit, setSupplierEdit, isLoad, setLoad, lead, componentName }) => {
  const { SupplierAdd, handleSupplierClose,
    contanctPost, setContanctPost, AddressPost, setAddressPost, costomerGroupdata, supplierGroupdata,
    handlesupplierGroupShow, handlecustomerGroupShow, customerGroupSelect, setCustomerGroupSelec,
    supplierGroupSelect, setSupplierGroupSelect, userId, screenSize, setSupplierSelect } = useContext(DataContext)

  const dispatch = useDispatch();
  const gstTypeList = useSelector(state => state.gstType.data);
  const getData = useSelector(state => state.itemGroup.data)


  const [initialValue, setInitialValue] = useState({
    supplierNo: "",
    companyName: "",
    legalName: "",
    Customer: false,
    Supplier: false,
    Transporter: false,
    TransporterId: "",
    gstInType: "",
    GSTIN: "",

    TCS: "",
    panNo: "",
    active: true,
    customerGroup: "",
    salesPerson: "",
    customerCreditedPeriod: "",
    credited_limit: "",
    supplier_group: "",
    supplier_credited_period: "",
    contact: [

    ],
    address: [

    ]
  });
  const [isWarningShow, setisWarningShow] = useState(false)
  // const [isContactFormShow, setContactFormShow] = useState(false)
  const [formKey, setFormKey] = useState(0);
  const [userOption, setUserOptions] = useState([])
  const [isEditForm, setEditForm] = useState(true);
  const [effectiveDate, setEffectiveDate] = useState(getCurrentDate())
  const [isChangeGst, setIsChangeGst] = useState(false)
  const [contactList, setContactList] = useState([])
  const [newContactNumber, setNewContactNumber] = useState()
  const [contactSelect, setcontactSelect] = useState('')
  const [contactIndex, setcontactIndex] = useState(0)
  const [customerChecked, setcustomerChecked] = useState('')
  const [TiltleName, setTiltleName] = useState('')
  const [supplierChecked, setsupplierChecked] = useState('')
  const [transporterChecked, settransporterChecked] = useState(true)
  const [creditedlimitValue, setcreditedlimitValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [EditContact, setEditContact] = useState()
  const [EditAddress, setEditAddress] = useState()
  const [disableConditionContactFrom, setdisableConditionContactFrom] = useState(false)
  const [ContactFromAdd, setcontactFromAdd] = useState('')
  const [waringToRemove, setWaringToRemove] = useState(false)
  const [addressadd, setaddressadd] = useState(false)
  const [deleteData, setDeleteData] = useState('')
  const [exitWaring, setexitWaring] = useState(false)
  const [gstType, setgsttype] = useState([])
  const [selectTcs, setSelectTcs] = useState({ value: '', label: '' })
  const [selectSalesperson, setSelectSalesperson] = useState({ value: '', label: '' })
  const [waringModal, setWaringModal] = useState(false);

  const handleAddressFormAddClose = () => { setaddressadd(false) }
  const handleAddressFormAddShow = () => { setaddressadd(true); }
  const handleContactFromAddShow = () => setcontactFromAdd(true)
  const handleEnableEditView = () => { setEditForm(!isEditForm) }
  const handleDisableEditView = () => { setEditForm(false) }

  //Fetches to get the Supllier Edit Data and set the initial values and update the state values  
  useEffect(() => {
    dispatch(fetchGstType(fetchGstTypeQuery()))
    let user_query = userOptionQuery()
    axiosInstance.post("/itemmaster/graphql", { query: user_query })
      .then(res => {
        const responseData = res?.data?.data?.User?.items
        let user_options = responseData.map(item => ({ label: item.username, value: item.id }));
        setUserOptions(user_options)
      })
    async function fetchEditData(id) {
      setLoading(true)
      try {
        const response = await axiosInstance.post(`/itemmaster/graphql`, { query: SupplierCustomerQuery(`id:${id}`) });
        const reponseData = response?.data?.data?.supplierFormData?.items?.[0]
        if (reponseData) {
          setEditForm(false)
          setInitialValue({
            id: reponseData?.['id'],
            supplierNo: reponseData?.['supplierNo'],
            companyName: reponseData?.['companyName'],
            legalName: reponseData?.['legalName'],
            Customer: reponseData?.['customer'],
            Supplier: reponseData?.['supplier'],
            Transporter: reponseData?.['transporter'],
            TransporterId: reponseData?.['transporterId'] ? reponseData?.['transporterId'] : '',
            gstInType: reponseData['gstinType']?.['id'],
            GSTIN: reponseData?.['gstin'],
            TCS: reponseData?.['tcs'] ? reponseData?.['tcs'] : "",
            panNo: reponseData?.['panNo'] ? reponseData?.['panNo'] : "",
            active: reponseData?.['active'],
            customerGroup: reponseData['customerGroup']?.['id'],
            salesPerson: reponseData['salesPerson']?.['id'],
            customerCreditedPeriod: reponseData['customerCreditedPeriod'] ? reponseData['customerCreditedPeriod'] : "",
            credited_limit: reponseData['creditedLimit'] ? String(reponseData['creditedLimit']) : "",
            supplier_group: reponseData['supplierGroup'] ? reponseData['supplierGroup']?.['id'] : '',
            Default: reponseData?.['Default'],
            supplier_credited_period: reponseData['supplierCreditedPeriod'] ? reponseData['supplierCreditedPeriod'] : '',
            contact: [
              reponseData?.['contact']
            ],
            address: [
              reponseData?.['address']
            ]
          })
          setFormKey(prev => prev + 1)
          setgsttype({ value: reponseData?.['gstinType']?.id, label: reponseData?.['gstinType']?.['gstType'] })
          setSelectTcs({ value: reponseData['tcs'], label: reponseData['tcs'] })
          setCustomerGroupSelec({
            value: reponseData['customerGroup']?.['id'],
            label: reponseData['customerGroup']?.['name']
          });
          setSupplierGroupSelect({
            value: reponseData['supplierGroup']?.['id'],
            label: reponseData['supplierGroup']?.['name']
          })
          setSelectSalesperson({
            value: reponseData['salesPerson']?.['id'],
            label: reponseData['salesPerson']?.['username']
          })
          if (reponseData['creditedLimit']) {
            setcreditedlimitValue(reponseData['creditedLimit'])
          }



          const getcontactlist = reponseData['contact']
          if (Array.isArray(getcontactlist)) {
            let processed_contacts = getcontactlist.map(item => {
              let updated_contact = {}
              updated_contact['index'] = item['index']
              updated_contact['id'] = item['id']
              updated_contact['Contact_Person_Name'] = item['contactPersonName']
              updated_contact['Email'] = item['email']
              updated_contact['Default'] = item['default'] ? item['default'] : false
              updated_contact['Phone_number'] = item['phoneNumber']
              updated_contact['Salutation'] = item['salutation']
              updated_contact['OtherNo'] = item['otherNo']
              updated_contact['WhatsappNo'] = item['whatsappNo']
              return updated_contact
            })
            // Set the contactIndex to the last contact's index + 1
            const lastIndex = processed_contacts.length > 0 ? processed_contacts[processed_contacts.length - 1].index : 0;
            setcontactIndex(lastIndex + 1); // Set the next index value
            setContanctPost(processed_contacts);
          }
          const getAddressList = reponseData['address'];
          if (Array.isArray(getAddressList)) {
            let processed_addresss = getAddressList.map((item, rowIndex) => {
              let updated_address = {}
              updated_address['index'] = rowIndex
              updated_address['id'] = item['id']
              updated_address['Address_Line_1'] = item['addressLine1']
              updated_address['Address_Line_2'] = item['addressLine2']
              updated_address['Default'] = item['default'] ? item['default'] : false
              updated_address['State'] = item['state']
              updated_address['Country'] = item['country']
              updated_address['address_type'] = item['addressType']
              updated_address['City'] = item['city']
              updated_address['pincode'] = String(item['pincode'])
              return updated_address
            })
            setAddressPost(processed_addresss);
          }

          setFormKey(prve => prve + 1)
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
        let errorData = ShowErrorNoties(error)
        showErrorToast(true, 'error', errorData)
      }

    }
    if (supplierEdit && supplierEdit?.id) {
      fetchEditData(supplierEdit?.id)

    } else {
      setEditForm(true)

      setInitialValue({
        companyName: "",
        legalName: "",
        Customer: false,
        Supplier: false,
        Transporter: false,
        TransporterId: "",
        gstInType: "",
        GSTIN: "",
        TCS: "",
        panNo: "",
        active: true,
        contact: [],
        address: [],
      })
      setFormKey(prev => prev + 1)
    }

  }, [supplierEdit, dispatch, setAddressPost, setContanctPost, setSupplierGroupSelect, setCustomerGroupSelec])
  //Checks the current path of the page. Based on whether the URL contains '/customer' or '/supplier', it updates the form to set Customer/Supplier flags and assigns the correct title. 
  useEffect(() => {
    const path = window.location.pathname;

    if (path.includes('/customer')) {
      setInitialValue(prevState => ({
        ...prevState,
        Customer: true,
        Supplier: false
      }));
      setcustomerChecked(true)
      setFormKey(prev => prev + 1)
      setTiltleName('Customer')

    } else if (path.includes('/supplier')) {
      setInitialValue(prevState => ({
        ...prevState,
        Customer: false,
        Supplier: true
      }));
      setFormKey(prev => prev + 1)
      setsupplierChecked(true)
      setTiltleName('Supplier')
    } else{
      if (componentName === 'Customer'){
        setInitialValue(prevState => ({
          ...prevState,
          Customer: true,
          Supplier: false
        }));
        setcustomerChecked(true)
        setFormKey(prev => prev + 1)
        setTiltleName('Customer')
        setEditForm(true)
      }
    }
  }, []);
  //Fetches and processes contact data from another data source (getData). It transforms the contact data into a format suitable for form use. 
  useEffect(() => {

    if (getData.name === 'SupplierFrom_Conatct_Data') {
      setContactList(getData?.value?.items?.map((item, rowIndex) => ({
        value: item?.id, label: item?.phoneNumber,
        Contact_Person_Name: item?.contactPersonName, Email: item?.email,
        Default: false, Phone_number: item?.phoneNumber, Salutation: convertToTitleCase(item?.['salutation'])
        , OtherNo: item?.otherNo, WhatsappNo: item?.whatsappNo, id: item?.id
      })))
    }
  }, [getData])
  const handlesetexitWaringClose = () => {
    setexitWaring(false)
  }
  // Waringmodel: Displays a modal warning when exiting without saving changes; returns JSX.
  const Waringmodel = () => {
    return (
      <Modal
        show={exitWaring}
        onHide={handlesetexitWaringClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-warning'>warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fs-4 fw-bold text-center'>Do You Want to Save the Changes.</p>
          <div className="row">
            <div className="col-12 text-center">
              <button type="button" className="btn btn-outline-success px-4" onClick={handlesetexitWaringClose}>Yes</button>
              <button type="button" className="btn btn-outline-danger ms-3  px-4" onClick={() => {
                handleSupplierClose();
                RESETDATA()
                // setcontactlength([])
                // setAddresslength([])
                handlesetexitWaringClose()
              }}>No</button>
            </div>
          </div>
        </Modal.Body>

      </Modal>
    )
  }
  // tcs_options: Static list of options for the TCS dropdown; returns an array.
  const tcs_options = [
    { value: "SALES", label: "SALES" },
    { value: "PURCHASE", label: "PURCHASE" },
    { value: "BOTH", label: "BOTH" },
  ]
  // BulkDelete: Deletes selected contacts and addresses in bulk using a GraphQL mutation; returns nothing.
  const BulkDelete = async () => {
    const contactIds = contanctPost.map(item => item?.id)
    const addressIds = AddressPost.map(item => item?.id)
    const query = `mutation createContactData {
  contactDetailsDeleteMutation(id:null,idList:[${contactIds}]) {
    errors
    success
  }
  supplierSubFormDefaultUpdateMutation(idList:[${addressIds}]) {
    errors
    success
  }
}`
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: query })
      let contactDeleteResponseData = response?.data?.data?.contactDetailsDeleteMutation
      let addressDeleteResponseData = response?.data?.data?.contactDetailsDeleteMutation
      if (contactDeleteResponseData?.success && addressDeleteResponseData?.success) {
        handleDisableEditView(); RESETDATA(); handleSupplierClose()
      } else {
        if (contactDeleteResponseData?.errors) {
          showErrorToast(true, "error", contactDeleteResponseData?.errors)
        } else {
          showErrorToast(true, "error", addressDeleteResponseData?.errors)
        }
      }
      console.log(response);

    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, "error", errorFilter)
    }
  }
  // Close: Closes the supplier form and resets data; returns nothing.
  function Close() {
    // if (!supplierEdit?.id) {
    //   if (initialValue.address.length !== AddressPost.length || initialValue?.contact?.length !== contanctPost?.length) {
    //     setisWarningShow(true);
    //   }
    //   else {
    //     handleDisableEditView();
    //     RESETDATA();
    //     handleSupplierClose();
    //   }
    // }
    // else {
    //   handleDisableEditView();
    //   RESETDATA();
    //   handleSupplierClose();
    // }
    handleDisableEditView();
    RESETDATA();
    handleSupplierClose();
  }

  // RESETDATA:Reset the State and initial values
  function RESETDATA() {
    setAddressPost([])
    setContanctPost([])

    setSelectTcs('')
    setgsttype('')
    setCustomerGroupSelec('')
    setSelectSalesperson('')
    setSupplierGroupSelect('')
    if (supplierEdit) {
      setSupplierEdit(null)
    }

  }
  // validateDefaults: Validates if there is at least one default item in the given list (address/contact); returns boolean.
  const validateDefaults = (items, type) => {
    const defaultCount = items?.filter(item => item?.Default === true).length;
    if (defaultCount === 0) {
      showErrorToast(true, 'error', `Any of the ${type} should be Default`)
      return false; // No default found
    }
    return true; // Valid if there's exactly one default
  };
  // handleSubmit: Handles form submission, validates inputs, and sends the data to the server; returns nothing.
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoading(true)
    
    // Validate AddressPost and contanctPost
    if (!validateDefaults(AddressPost, 'address') || !validateDefaults(contanctPost, 'contact')) {
      setSubmitting(false); // Stop form submission if validation fails
      setLoading(false)
      return;
    }
    if (values?.TransporterId) {
      if (values?.GSTIN.length !== 15) {
        showErrorToast(true, 'error', `Gst Number Should Be Equal to 15`)
        setLoading(false)
        return;
      }
    }
    if (gstType?.label !== 'UNREGISTERED/CONSUMER') {
      if (values?.GSTIN.length !== 15) {
        showErrorToast(true, 'error', `Gst Number Should Be Equal to 15`)
        setLoading(false)
        return;
      }
    }
    // Prepare updated address and contact fields
    let updatedAddressesValues = AddressPost?.map(address => ({
      addressLine1: address?.Address_Line_1 || '',
      addressType: address?.address_type || '',
      addressLine2: address?.Address_Line_2 || '',
      city: address?.City || '',
      country: address?.Country || '',
      default: address?.Default || false,
      id: address?.id || '',
      pincode: address?.pincode || '',
      state: address?.State || ''
    })) || []; // Fallback to an empty array if AddressPost is undefined

    // Map the contact fields to match the mutation format for all contacts in contanctPost
    let updatedContactsValues = contanctPost?.map(contact => ({
      contactPersonName: contact?.Contact_Person_Name || '',
      default: contact?.Default || false,
      email: contact?.Email || '',
      id: contact?.id || '',
      otherNo: contact?.OtherNo || '',
      phoneNumber: contact?.Phone_number || '',
      salutation: String(contact?.Salutation).toUpperCase() || '',
      whatsappNo: contact?.WhatsappNo || ''
    })) || []; // Fallback to an empty array if contanctPost is undefined
    console.log("updatedAddressesValues", updatedAddressesValues, "updatedContactsValues", updatedContactsValues);
    // Prepare the request body for the API call
    let reqBody = {
      transporterId: values?.TransporterId,
      transporter: values?.Transporter ? values?.Transporter : false,
      active: values?.active ? values?.active : '',
      address: updatedAddressesValues,
      companyName: values?.companyName ? values?.companyName : '',
      contact: updatedContactsValues,
      createdBy: values?.createdBy ? values?.createdBy : Number(userId),
      creditedLimit: values?.credited_limit ? Number(RupessTONormalFormat(values?.credited_limit)) : '',
      customer: values?.Customer ? values?.Customer : false,
      customerCreditedPeriod: values?.customerCreditedPeriod ? Number(values?.customerCreditedPeriod) : '',
      customerGroup: values?.customerGroup ? Number(values?.customerGroup) : '',
      gstin: values?.GSTIN ? values?.GSTIN : '',
      gstinType: values?.gstInType ? Number(values?.gstInType) : '',
      id: values?.id ? values?.id : '',
      isDelete: false,
      isLead: lead == null ||  lead == undefined ? false: lead ,
      legalName: values?.legalName ? values?.legalName : '',
      modifiedBy: values?.modifiedBy ? values?.modifiedBy : Number(userId),
      panNo: values?.panNo ? values?.panNo : '',
      salesPerson: values?.salesPerson ? Number(values?.salesPerson) : '',
      supplier: values?.Supplier ? values?.Supplier : false,
      supplierCreditedPeriod: values?.supplier_credited_period ? Number(values?.supplier_credited_period) : '',
      supplierGroup: values?.supplier_group ? Number(values?.supplier_group) : '',
      tcs: values?.TCS
    }
    console.log("reqBody", reqBody, "values", values);
    let allow_null = ["id", "transporter", "supplierCreditedPeriod", "supplierGroup", "customerCreditedPeriod", "customerGroup", "transporterId", "salesPerson", "creditedLimit"]
    try {
      let clearedValue = removeEmptyValueInObject(reqBody, allow_null, [], false, ["address", "contact"])
      console.log("clearedValue", clearedValue);
      
      const response = await axiosInstance.post("/itemmaster/graphql", { query: SupplierCustomerCreateMutation(clearedValue, allow_null) })
      const responseData = response?.data?.data?.supplierFormCreateMutation

      if (responseData?.success) {
        let supplierData = responseData?.supplierFormItem
        showErrorToast(true, "success", "", "Submit Successfully")
        resetForm()
        RESETDATA()
        setSubmitting(false)
        handleSupplierClose()
        setSupplierSelect({value:supplierData?.id, label: supplierData?.companyName,
          legalName:supplierData?.legalName})
        // close()
        if (setLoad) {
          setLoad(!isLoad)
        }
      }
      else {
        console.log("error", responseData?.errors);

        showErrorToast(true, "error", responseData?.errors[0])
      }
    } catch (errors) {
      let DetailsError = ShowErrorNoties(errors)
      showErrorToast(true, "error", `${DetailsError}`)
    }
    setLoading(false)
  }
  // RemoveContact: Removes a contact from the contanctPost array based on matching the deleteData id; returns nothing.
  function RemoveContact(params) {
    if (params) {
      const remove_delete_data = contanctPost.filter(data => data.id !== deleteData.id)
      setContanctPost(remove_delete_data)
    }

  }
  // ContantsEdit: Renders the contact name as a clickable element that opens the contact edit form if in edit mode; returns JSX.
  const ContantsEdit = (data) => {
    const handleClickedit = () => {
      setEditContact(data);
      setDeleteData({
        Name: "",
        id: ""
      });
      handleContactFromAddShow();
    };

    const renderTooltip = (props) => {
      // Conditional rendering within the function
      return isEditForm ? (
        <Tooltip id="button-tooltip" {...props}>
          Click here to edit
        </Tooltip>
      ) : (
        <Tooltip id="button-tooltip" {...props}>
          Click Edit Button To enable Editing
        </Tooltip>
      );
    };

    return (
      <>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <p
            className="text-primary"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (isEditForm) {
                handleClickedit()
              }
            }}
          >
            {data?.Contact_Person_Name}
          </p>
        </OverlayTrigger>
      </>
    );
  }
  // AddresEdit: Renders the address type as a clickable element that opens the address edit form if in edit mode; returns JSX.
  const AddresEdit = (data) => {
    const handleClickAddressEdit = () => {
      console.log("Edit Address---", data);

      setEditAddress(data);
      handleAddressFormAddShow();
    };

    const renderTooltip = (props) => {
      // Conditional rendering within the function
      return isEditForm ? (
        <Tooltip id="button-tooltip" {...props}>
          Click here to edit
        </Tooltip>
      ) : (
        <Tooltip id="button-tooltip" {...props}>
          Click Edit Button To enable Editing
        </Tooltip>
      );
    };

    return (
      <>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <p
            className="text-primary"
            style={{ cursor: 'pointer' }}

            onClick={() => {
              if (isEditForm) {
                handleClickAddressEdit()
              }
            }}
          >
            {data?.address_type}
          </p>
        </OverlayTrigger>
      </>
    );
  }

  const colums = [
    {
      headerName: "id",
      hide: true,
      field: "id",
    },
    {
      headerName: "Salutation",
      flex: 0.5,
      field: "Salutation",
    },
    {
      headerName: "Name",
      flex: 0.8,
      field: "Contact_Person_Name",
      cellRenderer: ContantsEdit,
    },
    {
      headerName: "Email",
      flex: 1,
      field: "Email",
    },
    {
      headerName: "Phone_number",
      flex: 1,
      field: "Phone_number",
    },
    {
      headerName: "Default",
      flex: .5,
      field: 'Default',
      input: 'bool',
    },
  ]

  const colums_2 = [
    {
      headerName: "Address Type",
      flex: 0.5,
      cellRenderer: AddresEdit
    },
    {
      headerName: "Address Type1",
      flex: 1,
      field: "Address_Line_1",
    },
    {
      headerName: "Address Line 2",
      flex: 1,
      field: "Address_Line_2",
    },
    {
      headerName: "City",
      flex: 1,
      field: "City",
    },
    {
      headerName: "State",
      flex: 1,
      field: "State",
    },
    {
      headerName: "Pincode",
      flex: 1,
      field: "pincode",
    },
    {
      headerName: "Country",
      flex: 1,
      field: "Country",
    },
    {
      headerName: "Default",
      flex: 1,
      input: 'bool',
      align: 'center',
      field: 'Default',
    },
    // {
    //   headerName: "Actions",
    //   flex: 1,
    //   cellRenderer: AddresEdit
    // },
  ]
  // handlechnagegstType: Sets the GST type state based on the selected option, or clears it if no option is selected; returns nothing.
  const handlechnagegstType = (option) => {
    if (option) {
      setgsttype({ value: option.value, label: option.label })
    } else {
      setgsttype('')
    }

  }
  // handleChangeTcs: Sets the TCS state based on the selected option, or clears it if no option is selected; returns nothing.
  const handleChangeTcs = (option) => {
    if (option) {
      setSelectTcs({ value: option.value, label: option.label })
    } else {
      setSelectTcs({ value: '', label: '' })
    }

  }
  // handleChangeSalesPerson: Sets the selected salesperson state based on the selected option, or clears it if no option is selected; returns nothing.
  const handleChangeSalesPerson = (option) => {
    if (option) {
      setSelectSalesperson({ value: option.value, label: option.label })
    } else {
      setSelectSalesperson({ value: '', label: '' })
    }

  }
  // handleChangeSupplierGroup: Sets the selected supplier group state based on the selected option, or clears it if no option is selected; returns nothing.
  const handleChangeSupplierGroup = (option) => {
    if (option) {
      setSupplierGroupSelect({ value: option.value, label: option.label })
    } else {
      setSupplierGroupSelect({ value: '', label: '' })
    }

  }
  // handleShowDeleteWarningModal: Displays the delete warning modal; returns nothing.
  const handleShowDeleteWarningModal = () => {
    setWaringModal(true)
  }
  // handleCloseDeleteWarningModal: Closes the delete warning modal; returns nothing.
  const handleCloseDeleteWarningModal = () => {
    setWaringModal(false)
  }
  // DeletaTheData: Deletes contact, address, and supplier data by calling GraphQL mutations; returns nothing.
  async function DeletaTheData(supplier_id, contact_list, address_list) {
    contact_list = contact_list.map(item => item.id)
    address_list = address_list.map(item => item.id)
    let contact_delete_query = `mutation deleteContactData{
        contactDetailsDeleteMutation(idList: [${contact_list}]){
          success
          errors
        }
      }`
    let address_delete_query = `mutation deleteAddressData{
        companyAddressDeleteMutation(idList: [${address_list}]){
          success
          errors
        }
      }`
    let supplier_delete_query = `mutation deleteSupplierData{
        supplierFormDeleteMutation(id: ${supplier_id}){
          success
          errors
        }
      }`
    try {
      await axiosInstance.post("/itemmaster/graphql", { query: contact_delete_query })
      // contact_response = contact_response?.data?.data?.contactDetailsDeleteMutation
      await axiosInstance.post("/itemmaster/graphql", { query: address_delete_query })
      // address_response = address_response?.data?.data?.CompanyAddressDeleteMutation
      await axiosInstance.post("/itemmaster/graphql", { query: supplier_delete_query })
      // response = response?.data?.data?.SupplierFormDeleteMutation 
      handleSupplierClose()
      if (setLoad) {
        setLoad(!isLoad)
      }

    } catch (e) {
      console.log(e)
      toast.error('Failed to delete!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    }
  }
  // DeleteConfirmation: Renders the modal that confirms the deletion of supplier data; returns JSX.
  const DeleteConfirmation = () => {
    const handleDeleteData = async () => {
      let supplierid = supplierEdit?.id
      let supplierContectId = supplierEdit?.contact
      let SupplierAddressId = supplierEdit?.address
      DeletaTheData(supplierid, supplierContectId, SupplierAddressId)
    }
    const canceltheDeleteOptions = () => {
      setDeleteData()
      handleCloseDeleteWarningModal()
    }
    return (
      <Modal
        show={waringModal}
        onHide={handleCloseDeleteWarningModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete "{supplierEdit?.['companyName']}" ?</Modal.Body>
        <Modal.Footer>
          <Button size='sm' variant="danger px-4 " onClick={() => { canceltheDeleteOptions() }}>No</Button>
          <Button size='sm' variant="success px-4 " onClick={() => { handleDeleteData() }}>Yes</Button>
        </Modal.Footer>
      </Modal>
    )
  }
  // handleDeleteSupplierData: Opens the delete warning modal; returns nothing.
  const handleDeleteSupplierData = () => {
    handleShowDeleteWarningModal()
  }

  return (
    <>
      {<BallTriangleLoading isshow={loading} />}
      <Waringmodel />
      <DeleteConfirmation />
      <ToastContainer />

      <Modal show={SupplierAdd}
        fullscreen={true}
        onHide={() => { handleDisableEditView(); RESETDATA(); handleSupplierClose() }}
        size='xl'
      >
        <Modal.Body className="d-flex flex-column h-100">
          <div className='mx-3 d-flex justify-content-center card'>
            <div className="row  align-items-center  justify-content-between ">
              {/* justify-content-between */}
              <div className="col-4  d-flex align-items-center  justify-content-between">
                <h3 className='mx-2'>
                  <i className='fa-solid fa-arrow-left fa-sm me-2' onClick={Close}></i>
                  {TiltleName ? TiltleName : ''}
                </h3>
              </div>
              <div className="col-5" hidden={isEditForm}>
                <div className="row ">

                  <div className=" text-end ">
                    <i className="fa-solid fa-pen fs-5 text-primary pe-3"
                      onClick={() => { handleEnableEditView() }}
                    ></i>
                    <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  "
                      onClick={() => { handleDeleteSupplierData() }}
                    ></i>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <Formik initialValues={initialValue} validationSchema={SupplierFromSchema}
            onSubmit={handleSubmit} key={formKey} enableReinitialize>
            {({ errors, touched, values, setFieldValue, }) => {
              return (
                <>
                  <Form className="h-100">
                    {/* UI Change starts */}
                    <div>

                      <div className={screenSize.width > 992 ? 'top-div d-flex justify-content-between' : "top-div"} style={{ width: '100%' }}>
                        <div className='left-pane card mr-2 mt-2' style={{ width: screenSize.width > 992 ? '40%' : "100%" }}>
                          <div>
                            {/* <h6  >Company Details- {initialValue?.supplierNo}</h6> */}
                            <div className='row m-1'>
                              <div className='col-4 staan-input-group'>
                                <Field type='text' name="companyId" id="companyId"
                                  disabled={true}
                                  value={values.supplierNo} className='w-100  staan-form-input' />
                                <label htmlFor="companyId" className='staan-form-input-label pt-2 px-1 mx-2' onClick={() => {
                                  console.log(screenSize);
                                }}>Company Id</label>
                              </div>
                              <div className='col-8 staan-input-group'>
                                <Field type='text' name="companyName" id="companyName"
                                  disabled={isEditForm ? false : true}
                                  value={values.companyName} onChange={(e) => { setFieldValue("companyName", e.target.value); setFieldValue("legalName", e.target.value) }} className='w-100  staan-form-input' />
                                <label htmlFor="companyName" className='staan-form-input-label pt-2 px-1 mx-2' onClick={() => {
                                  console.log("errors", errors);
                                }}>Company Name  </label>
                                {touched.companyName && errors.companyName && <small>{errors.companyName}</small>}
                              </div>
                            </div>
                            <div className='row m-1'>
                              <div className='col-12 staan-input-group'>
                                <Field type='text' id='legalName' name='legalName'
                                  disabled={isEditForm ? false : true}
                                  value={values.legalName} onChange={(e) => { setFieldValue("legalName", e.target.value) }} placeholder='' className='w-100   staan-form-input' />
                                <label htmlFor="legalName" className='staan-form-input-label pt-2 px-1 mx-2'>Legal Name</label>
                                {touched.legalName && errors.legalName && <small>{errors.legalName}</small>}
                              </div>
                            </div>
                            <div className='row m-1'>
                              <div className='col-6 staan-input-group'>
                                <CustomSelect
                                  setSelectEditValue={gstType}
                                  options={gstTypeList?.items?.map(item => ({ label: item.gstType, value: item.id }))}
                                  name='gstInType'
                                  handleOnChange={(selectedOption) => {
                                    if (selectedOption) {
                                      setIsChangeGst(true)
                                      if (selectedOption?.label?.includes('UNREGISTERED')) {
                                        setFieldValue("GSTIN", 'URP')
                                      } else {
                                        setFieldValue('GSTIN', "")
                                      }
                                    }
                                    handlechnagegstType(selectedOption)
                                    setFieldValue("gstInType", selectedOption?.value ?? null)
                                  }}
                                  isClearable
                                  isDisabled={isEditForm ? false : true}
                                />
                                <label className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN Type</label>
                                {touched.gstInType && errors.gstInType && <small>{errors.gstInType}</small>}
                              </div>
                              <div className='col-6 staan-input-group'>
                                <Field type='text' id='GSTIN' name='GSTIN'
                                  disabled={isEditForm ? false : true}
                                  placeholder=''
                                  onChange={e => {
                                    setIsChangeGst(true)
                                    setFieldValue('GSTIN', e.target.value);
                                    if (values?.GSTIN.length + 1 === 15) {
                                      console.log("equal to 15");

                                      settransporterChecked(false)
                                      setFieldValue('Transporter', true)
                                    }
                                    else {
                                      // console.log("less to 15");
                                      // setFieldValue('TransporterId', '')
                                      // settransporterChecked(true)
                                      // setFieldValue('Transporter', false)
                                    }
                                  }}
                                  className='w-100 staan-form-input' />
                                <label htmlFor="GSTIN" className='staan-form-input-label pt-2 px-1 mx-2'>GSTIN{infoMessage('"GSTIN" should be 15 chars!')}</label>
                                {touched.GSTIN && errors.GSTIN && <small>{errors.GSTIN}</small>}
                              </div>
                            </div>
                            <div className='row m-1'>
                              {isChangeGst &&
                                <div className="col-4 staan-input-group" >
                                  <input
                                    type='date'
                                    className=' staan-form-input form-control w-100 fs-6'
                                    style={{ border: "1px solid rgb(206, 201, 201)", fontSize: '.8rem' }}
                                    value={effectiveDate}
                                    min={getCurrentDate()}
                                    onChange={e => {
                                      if (e.target.value) {

                                        setEffectiveDate(e.target.value)
                                      }
                                    }}
                                  />
                                  <label className='staan-form-input-label pt-1 px-1 mx-2'  >Effective Date</label>
                                </div>
                              }

                              <div className={`${isChangeGst ? "col-4" : "col-6"} staan-input-group`}>
                                <CustomSelect
                                  setSelectEditValue={selectTcs}
                                  options={tcs_options}
                                  name='tcs'
                                  handleOnChange={(selectedOption) => {
                                    handleChangeTcs(selectedOption)
                                    setFieldValue("TCS", selectedOption?.value ?? null)
                                  }}
                                  isClearable
                                  isDisabled={isEditForm ? false : true}
                                />
                                <label className='staan-form-input-label pt-1 px-1 mx-2'>TCS</label>
                                {touched.TCS && errors.TCS && <small>{errors.TCS}</small>}
                              </div>
                              <div className={`${isChangeGst ? "col-4" : "col-6"} staan-input-group`}>
                                <Field type='text' id='panNo' name='panNo'
                                  disabled={isEditForm ? false : true}
                                  placeholder='' className='w-100 staan-form-input' />
                                <label htmlFor="panNo" className='staan-form-input-label pt-2 px-1 mx-2'>PAN No</label>
                                {touched.panNo && errors.panNo && <small>{errors.panNo}</small>}
                              </div>
                            </div>
                            <div className='row m-1'>
                              <div className='col-6 staan-input-group' hidden={customerChecked}>
                                <div className='mr-2 text-left mt-4 ml-2'>
                                  <Field type="checkbox" name="Transporter" disabled={isEditForm ? values?.GSTIN?.length >= 15 ? false : true : true} className='me-2' />
                                  <label >Transporter</label>
                                </div>
                              </div>
                              <div className="col-6 staan-input-group" hidden={customerChecked}>
                                <Field type='text' id='TransporterId' name='TransporterId' disabled={isEditForm ? values?.GSTIN?.length === 15 ? values?.Transporter ? false : true : true : true}
                                  className='w-100 staan-form-input' />
                                <label htmlFor="TransporterId" className='staan-form-input-label pt-2 px-1 mx-2' onClick={() => {
                                  console.log("transporterChecked", transporterChecked, "isEditForm", isEditForm);
                                }}>Transporter Id{infoMessage('"Trans ID" should be 15 chars!')}</label>
                                {touched.TransporterId && errors.TransporterId && <small>{errors.TransporterId}</small>}
                              </div>
                            </div>
                            <div className='row mx-2 my-4'>
                              <div className="col-4" hidden={supplierChecked && !supplierEdit}>
                                <Field
                                  type="checkbox"
                                  checked={customerChecked}
                                  onChange={(e) => {
                                    // When customer checkbox is clicked, uncheck the supplier
                                    setcustomerChecked(e.target.checked);
                                    if (e.target.checked) {
                                      // setFieldValue('Transporter', false)
                                      // setFieldValue('TransporterId', '')
                                      setFieldValue('Supplier', false)
                                      // setSupplierGroupSelect({ value: '', label: '' })
                                      setFieldValue('Customer', true)
                                      // setFieldValue('supplier_credited_period', '')
                                      // setFieldValue('supplier_group', '')
                                      setsupplierChecked(false); // Uncheck Supplier
                                    }
                                  }}
                                  name="Customer"
                                  disabled={supplierEdit ? isEditForm ? false : true : true} className="me-2"
                                />
                                {/* <Field type="checkbox" checked={customerChecked} name="Customer" className='me-2' disabled={isEditForm ? values?.Supplier ? true : false : true} /> */}
                                <label style={{ fontSize: '.8rem' }} className='pb-1' >Customer</label>
                              </div>
                              <div className="col-4" hidden={customerChecked && !supplierEdit}>
                                <Field
                                  type="checkbox"
                                  name="Supplier"
                                  checked={supplierChecked}
                                  disabled={supplierEdit ? isEditForm ? false : true : true} className="me-2"
                                  onChange={(e) => {
                                    setsupplierChecked(e.target.checked);
                                    if (e.target.checked) {
                                      // setFieldValue('customerGroup', '')
                                      // setFieldValue('salesPerson', '')
                                      // setFieldValue('customerCreditedPeriod', '')
                                      // setFieldValue('credited_limit', '')
                                      setFieldValue('Customer', false)
                                      // setcreditedlimitValue('')
                                      // setCustomerGroupSelec({ value: '', label: '' })
                                      // setSelectSalesperson({ value: '', label: '' })
                                      setFieldValue('Supplier', true)
                                      setcustomerChecked(false);
                                    }
                                  }}
                                />
                                <label style={{ fontSize: '.8rem' }} className='pb-1'>Supplier</label>
                              </div>
                              <div className="col-4">
                                <Field type="checkbox" name="active" className='me-2' disabled={isEditForm ? false : true} />
                                <label style={{ fontSize: '.8rem' }} className='pb-1'>Active</label>
                              </div>
                              {touched.Transporter && errors.Transporter && <small>{errors.Transporter}</small>}
                            </div>
                          </div>

                        </div>
                        <div className='right-pane card mt-2' style={{ width: screenSize.width > 992 ? '60%' : "100%" }}>
                          <div className=' mx-3 py-1 mt-1  d-flex justify-content-between align-items-center' style={{ height: '3rem' }}>
                            <h6 >Contact Details</h6>
                            {/* <Can allow_list={["Create_Contact","Edit_Contact"]}> */}

                            <div className="col-6 col-md-5 col-lg-5 col-xl-4 staan-input-group" hidden={isEditForm ? false : true}>
                              <CreatableSelect
                                onInputChange={(e) => {
                                  setcontactSelect(e)
                                  dispatch(fetchOptionsData(ContactFetch(`phoneNumber:"${e}"`), "SupplierFrom_Conatct_Data"))
                                }}
                                disabled={isEditForm ? false : true}
                                name="Mobile"
                                isClearable
                                options={contactList}
                                isSearchable={true}
                                onCreateOption={(e) => {
                                  if (e.length >= 10 && e.length <= 15) {
                                    console.log(e);
                                    setNewContactNumber(e);
                                    handleContactFromAddShow();
                                  } else {
                                    showErrorToast(true, "error", "Mobile number out of range.")
                                  }
                                }}
                                inputValue={contactSelect}
                                value={contactSelect}
                                onChange={(option) => {
                                  if (option) {
                                    const phoneNumber = String(option?.Phone_number);
                                    if (phoneNumber.length >= 10 && phoneNumber.length <= 15) {
                                      let updatedResData = { ...option };
                                      setcontactSelect(''); // Clear the selected contact

                                      // Use the current contactIndex as the index for the new contact
                                      updatedResData["index"] = contactIndex;
                                      updatedResData["Default"] = false; // Set index to current value

                                      setContanctPost(prevData => [...prevData, updatedResData]); // Update the contact post data
                                      setcontactIndex(prevIndex => prevIndex + 1); // Increment index for next contact
                                    } else {
                                      showErrorToast(true, "error", "Mobile number out of range.")
                                    }
                                  }
                                }}

                                placeholder="Add Mobile Number.."
                                className='my-3  '
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    height: 30,
                                    minHeight: 30,
                                  }),
                                  menu: (base) => ({
                                    ...base,
                                    maxHeight: 150,
                                    overflowY: 'auto',
                                  }),
                                  option: (base) => ({
                                    ...base,
                                    padding: '5px 10px',
                                  }),
                                }}
                              />
                            </div>
                            {/* </Can> */}
                          </div>
                          <div className='my-2'>
                            <div className='ag-theme-alpine mx-auto' style={{ height: '248px', width: '98%', overflow: "auto" }} >
                              <CommanTable
                                headers={colums}
                                rowData={contanctPost}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='my-3 card' hidden={!customerChecked}>
                        <div className='my-2 mx-2 pt-1 border-bottom'>
                          <h6>Customer Details</h6>
                        </div>
                        <div className='row mb-3 mx-2'>
                          <div className="col-3 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={customerGroupSelect}
                              options={costomerGroupdata.map((item) => ({ value: item.id, label: item.name }))}
                              name='customerGroup'
                              handleOnChange={(selectedOption) => {
                                // handleChangeCustomerGroup(selectedOption)
                                setFieldValue("customerGroup", selectedOption?.value ?? null)
                              }}
                              isClearable
                              isDisabled={isEditForm ? false : true}
                            />
                            <label  className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                              console.log(customerGroupSelect);
                            }}>Customer Group
                              <i className='bx bx-plus-circle' onClick={handlecustomerGroupShow} ></i></label>
                            {(touched.customerGroup || errors.customerGroup) && <small>{errors.customerGroup}</small>}
                          </div>
                          <div className="col-3 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={selectSalesperson}
                              options={userOption}
                              name='salesPerson'
                              handleOnChange={(selectedOption) => {
                                handleChangeSalesPerson(selectedOption)
                                setFieldValue("salesPerson", selectedOption?.value ?? null)
                              }}
                              isDisabled={isEditForm ? false : true}
                            />
                            <label className='staan-form-input-label pt-1 px-1 mx-2'>Sales Person</label>
                            {(touched.salesPerson || errors.salesPerson) && <small>{errors.salesPerson}</small>}
                          </div>
                          <div className="col-3  staan-input-group">
                            <Field type='text' name="customerCreditedPeriod" id="customerCreditedPeriod" value={values.customerCreditedPeriod} onchange={(e) => { setFieldValue("customerCreditedPeriod", e.target.value) }} placeholder='' className='w-100  staan-form-input' disabled={isEditForm ? false : true} />
                            <label htmlFor="customerCreditedPeriod" className='staan-form-input-label pt-2 px-1 mx-2'>Credit period</label>
                            {(touched.customerCreditedPeriod || errors.customerCreditedPeriod) && <small>{errors.customerCreditedPeriod}</small>}
                          </div>
                          <div className="col-3  staan-input-group">
                            <Field type='text' name="credited_limit" id="credited_limit" value={creditedlimitValue} onChange={(e) => {
                              const formattedValue = formatToRupees(e.target.value);
                              setcreditedlimitValue(formattedValue)
                              setFieldValue("credited_limit", e.target.value);
                            }} placeholder='' className='w-100  staan-form-input' disabled={isEditForm ? false : true} />
                            <label htmlFor="credited_limit" className='staan-form-input-label pt-2 px-1 mx-2'>Credit Limit</label>
                            {(touched.credited_limit || errors.credited_limit) && <small>{errors.credited_limit}</small>}
                          </div>
                        </div>
                      </div>
                      <div className='my-3 card' hidden={!supplierChecked}>
                        <div className='my-2 mx-2 pt-1 border-bottom'>
                          <h6>Supplier Details</h6>
                        </div>
                        <div className='row mb-3 mx-2'>
                          <div className="col-3 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={supplierGroupSelect}
                              options={supplierGroupdata?.map((item) => ({ value: item.id, label: item.name }))}
                              name='supplier_group'
                              handleOnChange={(selectedOption) => {
                                handleChangeSupplierGroup(selectedOption)
                                setFieldValue("supplier_group", selectedOption?.value ?? null)
                              }}
                              isClearable
                              isDisabled={isEditForm ? false : true}
                            />
                            <label className='staan-form-input-label pt-1 px-1 mx-2'>Supplier Group <i className='bx bx-plus-circle' onClick={handlesupplierGroupShow} ></i></label>
                            {(touched.supplier_group || errors.supplier_group) && <small>{errors.supplier_group}</small>}
                          </div>
                          <div className="col-3  staan-input-group">
                            <Field type='text' name="supplier_credited_period" id="supplier_credited_period"
                              disabled={isEditForm ? false : true}
                              value={values.supplier_credited_period} className='w-100 staan-form-input' />
                            <label htmlFor="supplier_credited_period" className='staan-form-input-label pt-2 px-1 mx-2'>Credit Period</label>
                            {(touched.supplier_credited_period || errors.supplier_credited_period) && <small>{errors.supplier_credited_period}</small>}
                          </div>
                        </div>
                      </div>
                      <div className='my-3 card'>
                        <div className=' my-2 mx-2 py-1 pb-2 border-bottom d-flex justify-content-between align-items-center'>
                          <h6 onClick={() => {
                            console.log("AddressPost", AddressPost);
                          }}>Address Details</h6>
                          {/* <Can I={"Save"} a={"Address"}> */}
                          <button type="button"
                            className={`fw-bold pt-1 pb-1 pe-3 ps-3 btn btn-sm shadow-sm btn-outline-success me-5 ${isEditForm ? '' : 'disabled'}`}
                            onClick={handleAddressFormAddShow}><i className='bx bxs-plus-circle pt-1 me-2' ></i>New</button>
                          {/* </Can> */}
                        </div>
                        <div className="row mx-auto py-3" style={{ width: '99%' }}>
                          <CommanTable
                            headers={colums_2}
                            rowData={AddressPost}
                          />
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="text-end me-5 mt-2">
                          <button type="submit" disabled={isEditForm ? false : true} onClick={() => {
                            console.log(errors, values);
                          }} className="fw-bold pt-1 mb-2 pb-1 pe-4 ps-4 btn btn-sm shadow-sm btn-success me-5">Save</button>
                        </div>
                      </div> */}
                    </div>
                    {/* UI Change ends */}
                    <CustomerGroup
                      setFieldValue={setFieldValue} />
                    <SupplierGroup
                      setFieldValue={setFieldValue} />
                    <div
                      className={screenSize.height > 900 ? "position-absolute bottom-0 end-0  text-end mb-3 me-4" : 'text-end mb-3 me-4'}
                    // className="position-absolute bottom-0 end-0  text-end mb-3 me-4"
                    >
                      <button
                        type="submit"
                        disabled={isEditForm ? false : true}
                        onClick={() => {
                          console.log(errors, values);
                        }}
                        className="fw-bold pt-1 pb-1 pe-4 ps-4 btn btn-sm shadow-sm btn-success me-5"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                </>
              )
            }
            }
          </Formik>
        </Modal.Body>
      </Modal>
      <>
        {addressadd && <AddressFrom
          addressadd={addressadd}
          handleaddressFromAddClose={handleAddressFormAddClose}
          EditAddress={EditAddress}
          setEditAddress={setEditAddress}
        />}
        {ContactFromAdd && <ContactFrom
          newContactNumber={newContactNumber}
          setNewContactNumber={setNewContactNumber}
          ContactFromAdd={ContactFromAdd}
          setcontactFromAdd={setcontactFromAdd}
          setContanctPost={setContanctPost}
          EditContact={EditContact}
          setEditContact={setEditContact}
          contanctPost={contanctPost}
          condition={disableConditionContactFrom}
          setCondition={setdisableConditionContactFrom}

        />}
        {waringToRemove && (<WaringModel
          state={waringToRemove}
          setstate={setWaringToRemove}
          title={'Confirm'}
          text={'Remove The Contact This Form '}
          Confirmtion={value => {
            RemoveContact(value)
          }}
        />)}
        {isWarningShow &&
          <WaringModel
            state={isWarningShow}
            setstate={setisWarningShow}
            title={'Confirm'}
            text={"Do you want to leave without saving?"}
            Confirmtion={value => {
              if (value) {
                BulkDelete()
              }
            }}
          />}
      </>
    </>
  )
}

export default SupplierFrom
