import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axiosInstance from '../../api/axoiss'
import Can from '../../components/authorizationComponents/Can'
import DeleteConformation from '../../components/deleteConformations.js/DeleteConformation'
import showErrorToast from '../../components/notifications/react-toastify/toast'
import DataContext from '../../context/ItemMasterContext'
 
import {
  ConferenceOptions,
  Filiter_address_with_pincode_Query,
  pincodeQuery,
  ProductFetchQuery,
  QueryFilterWithArray,
  userdataOptionsQuery
} from '../../GraphQLQueries/ItemmasterQuery'
import LeadForm from '../../Page/Lead/modals/LeadForm'
import { fetchOptionsData } from '../../ReduxState/Action/itemMasterAction'
import { convertToTitleCase } from '../../utils/Utils'
import { EnquirySchema } from '../../validations/Enquiryvalidation'
import Activities from '../Activities/models/Activities'
const EnquiryEditForm = ({ enquiryEdit, show, close, isLoad, setLoad }) => {
  const { permisiontochangesalesperson, userId, handltoastDeleteConfomationShow } = useContext(DataContext)
  const [initialValue, setinitialValue] = useState({
    name: '',
    organizationName: '',
    email: '',
    mobileNumber: '',
    location: '',
    message: '',
    otherNumber: '',
    Pincode: '',
    alternateMobileNumber: ''
  })
  const dispatch = useDispatch()
  const OptionsDataList = useSelector(state => state.itemGroup.data)
  const [remark, setRemark] = useState('')
  const [selectStatus, setSelectStatus] = useState('')
  const [followup, setFollpwup] = useState(new Date())
  const [salesMan, setSalesMan] = useState()
  const [Interesteds, setInteresteds] = useState([])
  const [isEditView, setEditView] = useState(false)
  const [productOptions, setProductOptions] = useState([])
  const [salesPersonOptions, setSalesPersonOptions] = useState([])
  const [pincodeList, setpincodeList] = useState([])
  const [districtValue, setDistrictValue] = useState('')
  const [statesValue, setstatesValue] = useState('')
  const [pincodeValue, setpincodeValue] = useState('')
  const [ShowLeadForm, setShowLeadForm] = useState(false)
  const [conferenceList, setConferenceList] = useState([])
  const [conference, setConference] = useState({ value: "", label: "" })
  const [IsLeadSubmited,setIsLeadSubmited]=useState(false)
  function FetchData(e, optionName) {
    let search_term = e.trim().replace(/"/g, '\\"')
    if (optionName === 'pincodeList') {
      dispatch(fetchOptionsData(pincodeQuery(search_term, 500), 'pincodeList'))
    } else if (optionName === "conferenceList") {
      dispatch(fetchOptionsData(ConferenceOptions(`name :"${search_term}"`), "conferenceList"))
    }
  }
  useEffect(() => {
    if (OptionsDataList.name === 'pincodeList') {
      setpincodeList(
        OptionsDataList?.value?.items?.map(type => ({
          value: type.id,
          label: type.pincode
        }))
      )
    }
    if (OptionsDataList.name === "conferenceList") {
      setConferenceList(OptionsDataList?.value?.items?.map((con) => ({ value: con.id, label: con.name })))
    }
    if (OptionsDataList.name === 'distri_state') {
      let filiter_resuelt = OptionsDataList?.value?.items?.[0]
      setDistrictValue(filiter_resuelt?.['district'])
      setstatesValue(filiter_resuelt?.['state'])
    }
    if (OptionsDataList.name === 'pincodeid') {
      let pincodeObject = OptionsDataList?.value?.items?.[0]
      setpincodeValue({
        value: pincodeObject?.id,
        label: pincodeObject?.pincode
      })
    }
    if (OptionsDataList.name === 'districtid') {
      let districtObject = OptionsDataList?.value?.items?.[0]
      setDistrictValue(districtObject)
    }
    if (OptionsDataList.name === 'stateid') {
      let stateObject = OptionsDataList?.value?.items?.[0]
      setstatesValue(stateObject)
    }


  }, [OptionsDataList])
  useEffect(() => {
    setinitialValue(enquiryEdit)

    axiosInstance
      .post('/itemmaster/graphql', { query: ProductFetchQuery() })
      .then(res => {
        let product_data = res?.data?.data?.product?.items
        setProductOptions(product_data)
      })
      .catch(err => {
        console.log(err)
      })
    // if (enquiryEdit?.pincode) {
    //   dispatch(
    //     fetchOptionsData(pincode_id_Query(enquiryEdit?.pincode), 'pincodeid')
    //   )
    // }
    // if (enquiryEdit?.district) {
    //   dispatch(
    //     fetchOptionsData(districtQuery(enquiryEdit?.district), 'districtid')
    //   )
    // }
    // if (enquiryEdit?.state) {
    //   dispatch(fetchOptionsData(StateQuery(enquiryEdit?.state), 'stateid'))
    // }
    console.log(enquiryEdit);
    
    setpincodeValue({
      value: enquiryEdit?.pincode?.id,
      label: enquiryEdit?.pincode?.pincode
    })
    setDistrictValue(enquiryEdit?.district)
    setstatesValue(enquiryEdit?.state)

    if (enquiryEdit?.interests) {
      let updated_interest = enquiryEdit?.interests?.map(item => ({
        value: item?.['id'],
        label: item?.['Name']
      }))
      setInteresteds(updated_interest)
    }
    if (enquiryEdit?.salesPerson) {
      setSalesMan({
        value: enquiryEdit?.salesPerson?.['id'],
        label: enquiryEdit?.salesPerson?.['username']
      })
    }
    if (enquiryEdit?.status) {
      setSelectStatus({
        value: enquiryEdit?.status,
        label: convertToTitleCase(enquiryEdit?.status)
      })
    }
    if (enquiryEdit?.followUp) {
      setFollpwup(enquiryEdit?.followUp)
    }
    if (enquiryEdit?.remarks) {
      setRemark(enquiryEdit?.remarks)
    }
    if (enquiryEdit?.conferenceData) {
      setConference({ value: enquiryEdit?.conferenceData?.id, label: enquiryEdit?.conferenceData?.name })
    }
  }, [])
  function resetData() {
    setRemark('')
    setSelectStatus('')
    setFollpwup('')
    setSalesMan('')
  }

  const handleChangefollowup = value => {
    setFollpwup(value)
  }

  const handleSelectsalesMan = option => {
    setSalesMan({ value: option.value, label: option.label })
  }

  const handleOnchangeValue = e => {
    let value = e.target.value
    let key = e.target.name
    setinitialValue(prevState => {
      return { ...prevState, [key]: value }
    })
  }
  const handleSelectpincode = options => {
    if (options) {
      dispatch(
        fetchOptionsData(
          Filiter_address_with_pincode_Query(`pincode: ${options?.value}`),
          'distri_state'
        )
      )
      setpincodeValue({ value: options.value, label: options.label })
    } else {
      setpincodeValue({ value: '', label: '' })
      setDistrictValue({ id: '', district: '' })
      setstatesValue({ id: '', stateName: '' })
    }
  }

  const handleSubmit = async (status) => {
    let followupStartdate = null
    if (followup) {
      const Datefollowup = new Date(followup)
      const day = Datefollowup.getDate()
      const month = Datefollowup.getMonth() + 1
      const year = Datefollowup.getFullYear()
      followupStartdate = `${year}-${month}-${day}`
    } else {
      followupStartdate = null
    }


    if (conference?.value === null || conference?.value === "") {
      showErrorToast(true, 'error', 'Source Is Required')
      return ""
    }
    let interested = Interesteds?.map(item => `"${item.value}"`)
    const save_enquiry_form = {
      id: enquiryEdit?.['id'],
      name: initialValue.name,
      Hospital: initialValue.organizationName,
      email: initialValue.email,
      Mobile: initialValue.mobileNumber,
      locations: initialValue.location,
      Pincode: pincodeValue?.value ? Number(pincodeValue.value) : '',
      district: districtValue?.id ? Number(districtValue.id) : '',
      state: statesValue?.id ? Number(statesValue.id) : '',
      conferenceData: conference?.value ? Number(conference?.value) : "",
      modifiedBy: userId ? Number(userId) : null
    }
    if (enquiryEdit?.linkContactDetalis?.id) {
      save_enquiry_form['linkContactDetalis'] = Number(
        enquiryEdit?.linkContactDetalis?.id
      )
    }
    if (interested && interested.length > 0) {
      save_enquiry_form['interests'] = interested
    } else {
      save_enquiry_form['interests'] = []
    }
    if (salesMan) {
      save_enquiry_form['salesPerson'] = salesMan ? salesMan.value : null
    }
    if (initialValue['message'] !== '' && initialValue['message']) {
      save_enquiry_form['message'] = initialValue['message']
    }
    if (initialValue['otherNumber'] !== '' && initialValue['otherNumber']) {
      save_enquiry_form['otherNumber'] = initialValue['otherNumber']
    }
    if (
      initialValue['alternateMobileNumber'] !== '' &&
      initialValue['alternateMobileNumber']
    ) {
      save_enquiry_form['alternateMobileNumber'] =
        initialValue['alternateMobileNumber']
    }
    if (followupStartdate) {
      save_enquiry_form['followUp'] = followupStartdate
    }
    if (remark !== '') {
      save_enquiry_form['remarks'] = remark
    }
    if (selectStatus && selectStatus?.value) {
      if (status !== null || status?.trim() !== "") {
        save_enquiry_form['status'] = status
      } else {
        save_enquiry_form['status'] = selectStatus?.value

      }


    }
    try {
      await EnquirySchema.validate(save_enquiry_form, { abortEarly: false })
      save_enquiry_form['organizationName'] = save_enquiry_form['Hospital']
      delete save_enquiry_form['Hospital']
      save_enquiry_form['location'] = save_enquiry_form['locations']
      delete save_enquiry_form['locations']
      save_enquiry_form['mobileNumber'] = save_enquiry_form['Mobile']
      delete save_enquiry_form['Mobile']
      save_enquiry_form['pincode'] = save_enquiry_form['Pincode']
      delete save_enquiry_form['Pincode']

      let enquiry_mutate_query = `mutation MyMutation {
                enquiryDataCreateMutation(`
      enquiry_mutate_query += QueryFilterWithArray(
        save_enquiry_form,
        [],
        [
          'id',
          'salesPerson',
          'pincode',
          'district',
          'state',
          'modifiedBy',
          'linkContactDetalis',
          'conferenceData'
        ],
        ['interests']
      )
      enquiry_mutate_query += `) {
                errors
                success
                enquiryData {
                    id
                    followUp
                    email
                    alternateMobileNumber
                    interestsJoined
                    location
                    message
                    mobileNumber
                    name
                    organizationName
                    otherNumber
                    remarks
                    status
                    interests {
                        Name
                        id
                    }
                    salesPerson {
                        id
                        username
                    }
                }
                }
            }`

      axiosInstance
        .post('/itemmaster/graphql', { query: enquiry_mutate_query })
        .then(res => {
          let enquiry_data = res?.data?.data?.enquiryDataCreateMutation
          if (enquiry_data?.success) {
            resetData()
            close()

            toast.success('Successfully Saved!', {
              position: 'bottom-right',
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light'
            })
            setLoad(!isLoad)
          } else {
            console.log(enquiry_data)
          }
        })
        .catch(err => {
          console.log(err)
        })
    } catch (error) {
      toast.error(`${error.errors}`, {
        position: 'top-center',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: 'light',
        className: 'custom-toast'
      })
    }
  }

  const option = [
    { value: 'NOT_CONTACTED', label: 'Not Contacted' },
    { value: 'CONVERTED_TO_LEAD', label: 'Converted To Lead' },
    { value: 'JUNK', label: 'Junk' },
    { value: 'IN_PROGRESS', label: 'Inprogress' }
  ]

  const handleSelectProduct = option => {
    setInteresteds(option)
  }

  const handleUserTypeSearch = e => {
    axiosInstance
      .post('/itemmaster/graphql', { query: userdataOptionsQuery(e) })
      .then(res => {
        let user_data = res?.data?.data?.User?.items
        user_data = user_data?.map(item => ({
          label: item.username,
          value: item.id
        }))
        setSalesPersonOptions(user_data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleCopyMobileNumber = data => {
    setinitialValue(prevState => {
      return { ...prevState, ['alternateMobileNumber']: data }
    })
  }
  const [deleteData, setDeleteData] = useState({Name:"",id:""})
 
  let enquiry_delete_query = (id)=>{
    return  `mutation deleteEnquiryData{
      enquiryDataDeleteMutation(id: ${id}){
        success
        errors
      }
    }`
  }
  async function handleDeletedId(deleted_id) {
    if (deleted_id){
      close()
      setLoad(!isLoad)
    } 
  }

  const handleDeleteEnquiryData = () => {
    setDeleteData({Name:initialValue?.name ,id:initialValue?.id})
    handltoastDeleteConfomationShow()
  }

  return (
    <>
      <ToastContainer />
      <Modal
        show={show}
        onHide={() => {
          close()
          resetData()
        }}
        fullscreen={true}
      >
        <Modal.Header>
          <Modal.Title style={{ width: '100%' }}>
            <div className='d-flex justify-content-between'>
              <div>
                <h5 style={{ color: '#5CADA9' }}>
                  {' '}
                  <i
                    className='fa-solid fa-arrow-left fa-sm me-2'
                    onClick={() => {
                      close()
                      resetData()
                    }}
                  ></i>
                  Enquiry Form
                </h5>
              </div>

            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowX: "hidden" }}>
          <div className='row'>
            <div className='col-12 col-md-8 border-end'>
              <div className='row '>
                <div
                  style={{ fontSize: '1rem' }}
                  className='col-12 d-flex align-items-center justify-content-end '
                >

                  <Can I='Edit' a='Enquiry'>
                    <span className='me-2'>
                      <i
                        role='button'
                        className={`fa-solid fa-pen me-4`}
                        onClick={() => {
                          setEditView(!isEditView)
                        }}
                      ></i>
                    </span>
                  </Can>
                  <Can I='Delete' a='Enquiry'>
                    <span className='me-4'>
                      <i
                        role='button'
                        className='fa-solid fa-trash text-danger'
                        onClick={() => {
                          handleDeleteEnquiryData()
                        }}
                      ></i>
                    </span>
                  </Can>
                </div>

                <div className='col-6 '>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='form-label'
                  >
                    Name<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    name='name'
                    className='form-control'
                    value={initialValue?.name}
                    id='exampleFormControlInput1'
                    disabled={!isEditView}
                    onChange={e => {
                      handleOnchangeValue(e)
                    }}
                  />
                </div>
                <div className='col-6'>
                  <label
                    htmlFor='exampleFormControlInput2'
                    className='form-label'
                  >
                    Hospital/Company<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='organizationName'
                    value={initialValue?.organizationName}
                    id='exampleFormControlInput1'
                    disabled={!isEditView}
                    onChange={e => {
                      handleOnchangeValue(e)
                    }}
                  />
                </div>
                <div className='col-6'>
                  <label
                    htmlFor='exampleFormControlInput2'
                    className='form-label'
                  >
                    Email
                  </label>
                  <input
                    type='email'
                    name='email'
                    className='form-control'
                    value={initialValue?.email}
                    id='exampleFormControlInput1'
                    disabled={!isEditView}
                    onChange={e => {
                      handleOnchangeValue(e)
                    }}
                  />
                </div>
                <div className='col-6'>
                  <label
                    htmlFor='exampleFormControlInput2'
                    className='form-label'
                  >
                    Mobile Number<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={initialValue?.mobileNumber}
                    id='exampleFormControlInput1'
                    name='mobileNumber'
                    disabled={!isEditView}
                    onChange={e => {
                      handleOnchangeValue(e)
                    }}
                  />
                </div>
                <div className='col-6'>
                  <label
                    htmlFor='exampleFormControlInput2'
                    className='form-label'
                  >
                    Whatsapp Number
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='alternateMobileNumber'
                    value={initialValue?.alternateMobileNumber}
                    id='exampleFormControlInput1'
                    disabled={!isEditView}
                    onChange={e => {
                      handleOnchangeValue(e)
                    }}
                  />
                  <span
                    style={{
                      fontSize: '.8rem',
                      cursor: 'pointer',
                      color: 'orange'
                    }}
                    onClick={() => {
                      handleCopyMobileNumber(initialValue?.mobileNumber)
                    }}
                  >
                    <i className='fa-solid fa-copy me-2'></i>
                    same as mobile number
                  </span>
                </div>
                <div className='col-6'>
                  <label
                    htmlFor='exampleFormControlInput2'
                    className='form-label'
                  >
                    Other Number
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='otherNumber'
                    value={initialValue?.otherNumber}
                    id='exampleFormControlInput1'
                    disabled={!isEditView}
                    onChange={e => {
                      handleOnchangeValue(e)
                    }}
                  />
                </div>
                {initialValue?.location ? (
                  <div className='col-6'>
                    <label
                      htmlFor='exampleFormControlInput2'
                      className='form-label'
                    >
                      City
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='location'
                      value={initialValue?.location}
                      id='exampleFormControlInput1'
                      disabled={!isEditView}
                      onChange={e => {
                        handleOnchangeValue(e)
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className='col-12  col-md-6   '>
                  <label
                    htmlFor='Location'
                    className='staan-form-input-label   px-1 mx-2'
                  >
                    Pincode<span className='text-danger'>*</span>
                  </label>
                  <Select
                    name='Pincode'
                    options={pincodeList}
                    isSearchable={true}
                    isClearable
                    onInputChange={e => {
                      FetchData(e, 'pincodeList')
                    }}
                    value={pincodeValue}
                    isDisabled={!isEditView}
                    className='mt-2'
                    onChange={option => {
                      handleSelectpincode(option)
                    }}
                  />
                  <br />
                </div>
                <div className='col-12  col-md-6 staan-input-group   '>
                  <label htmlFor='District' className='pt-1 px-1 mx-2  '>
                    {' '}
                    District
                  </label>
                  <input
                    type='text'
                    className='form-control mt-1'
                    name='District'
                    value={districtValue?.district}
                    id='exampleFormControlInput1'
                    disabled
                  />
                </div>
                <div className='col-12  col-md-6 staan-input-group   '>
                  <label htmlFor='state' className='pt-1 px-1 mx-2  '>
                    State
                  </label>
                  <input
                    type='text'
                    className='form-control mt-1'
                    name='state'
                    value={statesValue?.stateName}
                    id='exampleFormControlInput1'
                    disabled
                  />
                </div>
                <div className='col-6'>
                  <label
                    htmlFor='exampleFormControlInput2'
                    className='form-label'
                  >
                    Interests
                  </label>
                  <Select
                    isMulti
                    name='interests'
                    isDisabled={!isEditView}
                    value={Interesteds}
                    options={productOptions?.map(product_data => ({
                      value: product_data?.id,
                      label: product_data?.Name
                    }))}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={e => {
                      handleSelectProduct(e)
                    }}
                  />
                </div>
                <div className='col-6'>
                  <label
                    htmlFor='Sales'
                    className='form-label   lable-sub  ps-1 '
                  >
                    Sales Person
                  </label>
                  <Select
                    name='salesMan'
                    onInputChange={e => {
                      handleUserTypeSearch(e)
                    }}
                    value={salesMan}
                    isDisabled={
                      !isEditView ? true : !permisiontochangesalesperson
                    }
                    options={salesPersonOptions}
                    onChange={option => {
                      handleSelectsalesMan(option)
                    }}
                  />
                </div>
                <div className='col-6'>
                  <label
                    htmlFor='Status'
                    className='form-label   lable-sub  ps-1 '
                  >
                    Status
                  </label>
                  <Select
                    name='status'
                    value={selectStatus}
                    isDisabled={!isEditView}
                    options={option}
                    onChange={option => {
                      setSelectStatus({ value: option?.value, label: option?.label })

                      if (option?.value === 'CONVERTED_TO_LEAD') {
                        if (conference?.value === null || conference?.value === "") {
                          showErrorToast(true, 'error', 'Source Is Required')
                        }
                        else{
                          setShowLeadForm(true)
                        }
                      }
                    }}
                  />
                </div>
                <div className='col-12 col-md-6  mt-2'>
                  <label
                    htmlFor='Sales'
                    className='form-label   lable-sub  ps-1 '
                  >
                    Next Follow up
                  </label>
                  <input
                    type='date'
                    name='followUp'
                    disabled={!isEditView}
                    className='form-control w-100'
                    min={new Date().toISOString().split('T')[0]}
                    value={followup}
                    onChange={e => {
                      handleChangefollowup(e.target.value)
                    }}
                  />
                  {/* <DatePicker selected={followup} onChange={(e)=>{handleChangefollowup(e.target.value)}} /> */}
                </div>
                <div className='col-12 col-md-6'>
                  <label htmlFor='exampleFormControlInput2' className='form-label'>
                    Source
                  </label>
                  <Select
                    onInputChange={(e) => { FetchData(e, "conferenceList") }}
                    name='conferenceData'
                    isDisabled={!isEditView}
                    value={conference}
                    options={conferenceList}
                    onChange={(e) => { setConference(e ? e : "") }}
                  />
                </div>
                <div className='col-6 col-md-9  mt-2'>
                  <label
                    htmlFor='exampleFormControlInput2'
                    className='form-label lable-sub   ps-1 '
                  >
                    Requirements
                  </label>
                  <input
                    className='form-control'
                    value={initialValue?.message}
                    disabled={!isEditView}
                    name='message'
                    onChange={e => {
                      handleOnchangeValue(e)
                    }}
                  />
                </div>
                <div className='col-12'>
                  <label
                    htmlFor='Sales'
                    className='form-label   lable-sub  ps-1 '
                  >
                    Remarks
                  </label>
                  <textarea
                    className='form-control'
                    disabled={!isEditView}
                    name='remarks'
                    value={remark}
                    onChange={e => {
                      setRemark(e.target.value)
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className='col-10 col-md-4 mt-3 mt-md-0'>
              <Activities enquiry_id={enquiryEdit?.['id']} show={show} salesMan={salesMan} />
            </div>
          </div>
        </Modal.Body>


        <div className="row text-end my-2">
          <div className="col-12 col-md-8">
            <button
              type='button'
              className='btn btn-outline-secondary mx-1'
              onClick={() => {
                close()
                resetData()
              }}
            >
              Close
            </button>
            <Can I='Create' a='Enquiry'>
              <button
                type='button'
                className='btn btn-outline-success  mx-1 px-3'
                onClick={() => { handleSubmit(selectStatus?.value) }}
              >
                Save
              </button>
            </Can>
          </div>
        </div>

      </Modal>
      {ShowLeadForm && (
        <LeadForm
          EnquireId={enquiryEdit}
          showLeadForm={ShowLeadForm}
          setShowLeadForm={setShowLeadForm}
          conference={conference}
          EnquireInitialValue={initialValue}
          salesMan={salesMan}
          followupDate={followup}
          setIsLeadSubmited={setIsLeadSubmited}
          handleSubmitEnquireForm={handleSubmit}
        />
      )}
      {deleteData?.Name && <DeleteConformation
      handleDeletedId={(id)=>{handleDeletedId(id)}}
      deleteData={deleteData} 
      fullquery={enquiry_delete_query(deleteData?.id)}
      queryName={"deleteEnquiryData"}/>}
    </>
  )
}

export default EnquiryEditForm
