import { Modal } from "react-bootstrap";
import MRDetails from "./MRDetails";
import { useEffect } from "react";
import { convertDateTimeToDateString } from "../../../Packages/Custom/TableUtils/TableUtils";


const AddMR = ({show, close, data}) => {
    
    useEffect(()=>{
        console.log(data)
    }, [data])

    return (
        <>
            <Modal show ={show} onHide={close}
                fullscreen = {true}
                dialogClassName="modal-transparent"
                backdrop="static"
            >
                <Modal.Body className="p-0 bg-white">
                    <div style={{fontSize: '.8rem', width: '100%', height: '100%'}}>
                        <div>
                            <div className="row mx-auto py-3 border-bottom border-light shadow-sm " style={{width: "100%"}}>
                                <div className="col-5 my-auto">
                                    <h4>
                                        <span className='me-3' style={{cursor: 'pointer'}} onClick={()=>{
                                            close()
                                        }}>
                                            <i className="fa-solid fa-arrow-left  fs-4"></i>
                                        </span>
                                        Material Request
                                    </h4>
                                </div>
                                <div className='col-5'></div>
                            </div>
                        </div>
                        <div>
                            <div className="mx-auto mt-5" style={{width: '95%',  height: '100%'}}>
                                <div className="row mx-2 my-2">
                                    <div className="col-4">
                                        <p>Status: {data?.status?.name}</p>
                                    </div>
                                    <div className="col-4">
                                        <p>Created By: {data?.createdBy?.username}</p>
                                    </div>
                                    <div className="col-4">
                                        <p>Last Modified Date: {convertDateTimeToDateString(data?.updatedAt)}</p>
                                    </div>
                                </div>
                                <div className="border border-light py-3 shadow">
                                    <div className="row mx-2 my-2">
                                        <div className="col-3 staan-input-group">
                                            <input type='text' id='requestNo' name='requestNo'  
                                                className='w-100 staan-form-input'
                                                value = {data?.requestNo}
                                                disabled ={true}
                                            /> 
                                            <label htmlFor="requestNo" className='staan-form-input-label pt-1 px-1 mx-2'>MR No</label>
                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <input type='text' id='requestDate' name='requestDate'  
                                                className='w-100 staan-form-input'
                                                value = {data?.requestDate}
                                                disabled ={true}
                                            /> 
                                            <label htmlFor="requestDate" className='staan-form-input-label pt-1 px-1 mx-2'>MR Date</label>
                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <input type='text' id='requestFor' name='requestFor'  
                                                className='w-100 staan-form-input'
                                                value = {data?.requestFor?.name}
                                                disabled ={true}
                                            /> 
                                            <label htmlFor="requestFor" className='staan-form-input-label pt-1 px-1 mx-2'>MR For</label>
                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <input type='text' id='department' name='department'  
                                                className='w-100 staan-form-input'
                                                value = {data?.department?.name}
                                                disabled ={true}
                                            /> 
                                            <label htmlFor="department" className='staan-form-input-label pt-1 px-1 mx-2'>Department</label>
                                        </div>
                                    </div>
                                    <div className="row mx-2 my-2">
                                        <div className="col-3 staan-input-group">
                                            <input type='text' id='orderNo' name='orderNo'  
                                                className='w-100 staan-form-input'
                                                value = {data?.productionOrder?.orderNo}
                                                disabled ={true}
                                            /> 
                                            <label htmlFor="orderNo" className='staan-form-input-label pt-1 px-1 mx-2'>MO No</label>
                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <input type='text' id='orderDate' name='orderDate'  
                                                className='w-100 staan-form-input'
                                                value = {data?.productionOrder?.orderDate}
                                                disabled ={true}
                                            /> 
                                            <label htmlFor="orderDate" className='staan-form-input-label pt-1 px-1 mx-2'>MO Date</label>
                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <input type='text' id='storeName' name='storeName'  
                                                className='w-100 staan-form-input'
                                                value = {data?.issuingStore?.storeName}
                                                disabled ={true}
                                            /> 
                                            <label htmlFor="storeName" className='staan-form-input-label pt-1 px-1 mx-2'>Issuing Store</label>
                                        </div>
                                    </div>
                                    <div className="row mx-2 my-2">
                                        <div className="col-12 staan-input-group">
                                            <textarea className="staan-form-input-text-area" name='remarks' id="textarea" 
                                            ></textarea>
                                            <label htmlFor="remarks" className='staan-form-input-label pt-2 px-1 mx-2 text-muted'>Remarks</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-5">
                                    <div className="border border-light py-3 shadow">
                                        <div className="mx-2 h-100">
                                            <MRDetails 
                                                data = {data}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddMR;