import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import axiosInstance, { commanFetch } from '../../../api/axoiss'
import Can from "../../../components/authorizationComponents/Can"
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import DataContext from '../../../context/ItemMasterContext'
import { DepartmentQuery } from '../../../GraphQLQueries/ItemmasterQuery'
import { allPermissionModelforOptions, rolelist, userManagementCreateMutation } from '../../../GraphQLQueries/userQueryandMutations/userQuerys'
import { removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils'
import { UserManagmentSchema } from '../../../validations/UserValidations'
import CustomSelect from '../../../components/ReactSelect/reactSelect'

const UserManagmentForm = ({ isShow, setIsShow, setLoad, editData, setEditData }) => {
    const { CommanFetchForAllUser, allUsersList, userId } = useContext(DataContext)
    const [initialValue, setInitialValue] = useState({
        id: '',
        user: '',
        firstName: '',
        lastName: '',
        department: '',
        role: '',
        profile: '',
        modifiedBy: '',
        createdBy: ''
    })
    const [edit, setEdit] = useState(true)
    const [optionList, setoptionList] = useState({
        departmentList: [], profileList: [], roleList: []
    })

    const [userManagmentFormValue, setUserManagmentFormValue] = useState()
    const [formKey, setFormKey] = useState(0);

    useEffect(() => {
        if (isShow && editData !== null) {
            setInitialValue(
                {
                    id: Number(editData?.id) ? Number(editData?.id) : '',
                    user: Number(editData?.user?.id) ?? '',
                    firstName: editData?.user?.firstName,
                    lastName: editData?.user?.lastName,
                    department: Number(editData?.department?.id) ?? null,
                    role: Number(editData?.role?.id) ?? null,
                    profile: Number(editData?.profile?.id) ?? null,
                    modifiedBy: editData?.modifiedBy?.id ? Number(editData?.modifiedBy?.id) : '',
                    createdBy: Number(editData?.createdBy?.id) ?? null
                }
            )
            setUserManagmentFormValue({
                user: { value: editData?.user?.id, label: editData?.user?.username },
                email: editData?.user?.email ?? "",
                firstName: editData?.user?.firstName ?? "",
                lastName: editData?.user?.lastName ?? "",
                department: { value: editData?.department?.id, label: editData?.department?.name },
                role: { value: editData?.role?.id, label: editData?.role?.roleName },
                profile: { value: editData?.profile?.id, label: editData?.profile?.profileName }

            })
            setFormKey((prevKey) => prevKey + 1)
            setEdit(true)
        } else {
            setEdit(false)
        }


    }, [editData, isShow])

    async function fetchOptions(query, name) {
        if (query) {
            const response = await commanFetch(query)
            const reponseData = response?.data?.items
            if (name === "departmant") {
                setoptionList(prev => ({
                    ...prev,
                    departmentList: reponseData?.map((item) => ({ "value": item?.id, "label": item?.name }))
                }))
            }
            if (name === "rolelist") {
                setoptionList(prev => ({
                    ...prev,
                    roleList: reponseData?.map((item) => ({ "value": item?.id, "label": item?.roleName }))
                }))
            }
            if (name === "profile") {
                setoptionList(prev => ({
                    ...prev,
                    profileList: reponseData?.map((item) => ({ "value": item?.id, "label": item?.profileName }))
                }))
            }
        }
    }

    // retun null ;  Submit functions
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {

        if (!initialValue['id']) {
            values['createdBy'] = Number(userId)
        } else {
            values['modifiedBy'] = Number(userId)
        }

        let ClearValue = removeEmptyValueInObject(values, ["id", "modifiedBy", "department", "role"])

        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: userManagementCreateMutation(ClearValue)
            });
            const responseData = response?.data?.data?.userManagementCreateMutation;
            if (responseData.success) {
                setIsShow(false)
                showErrorToast(true, "success", "", "Saved Successfully")
                close()
                setSubmitting(false)
                setLoad()
            } else {
                let errorData = ShowErrorNoties(responseData.errors);
                showErrorToast(true, "error", errorData,)
            }
        } catch (error) {
            let errorData = ShowErrorNoties(error);
            showErrorToast(true, "error", errorData,)
        }

    }

    function close(params) {
        setIsShow(false)
        setUserManagmentFormValue()
        setEditData(null)
        setInitialValue({
            id: '',
            user: '',
            firstName: '',
            lastName: '',
            department: '',
            role: '',
            modifiedBy: '',
            createdBy: ''
        })
    }
    return (
        <>
            <Modal show={isShow} backdrop='static' keyboard={false} size='xl'>
                <div className='row'>
                    <div className='col-5 m-3' style={{ color: '#5CADA9' }}>
                        <Modal.Title>
                            <i
                                className='fa-solid fa-arrow-left fa-sm me-2'
                                onClick={close}
                            ></i>
                            Users
                        </Modal.Title>
                    </div>
                    <div className='col-6 mt-4 text-end'>
                        <Can I={"Edit"} a={"User_Management"}>
                            <i
                                className='fa-solid fa-pen fs-5 text-primary pe-3'
                                hidden={!(editData && edit)}
                                onClick={() => { setEdit(!edit) }}
                            ></i>
                        </Can>
                    </div>
                </div>
                <hr className='split_border'/>
                <Modal.Body>
                    <Formik
                        initialValues={initialValue}
                        validationSchema={UserManagmentSchema}
                        key={formKey}
                        onSubmit={handleSubmit}>
                        {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                            return (
                                <Form>
                                    <div className='row'>
                                        <div className='col-6 staan-input-group'>
                                            <CustomSelect
                                                setSelectEditValue={userManagmentFormValue?.user}
                                                handleInputChange={(e) => { CommanFetchForAllUser(e) }}
                                                options={allUsersList}
                                                name='user'
                                                isClearable
                                                handleOnChange={(options) => {

                                                    setFieldValue("user", options ? Number(options?.value) : "")
                                                    setFieldValue("firstName", options?.firstName ? (options?.firstName) : "")
                                                    setFieldValue("lastName", options?.lastName ? (options?.lastName) : "")
                                                    setUserManagmentFormValue({
                                                        user: options ?? null,
                                                        email: options?.email ?? "",
                                                        firstName: options?.firstName ?? "",
                                                        lastName: options?.lastName ?? ""
                                                    });
                                                }}
                                                isDisabled={editData && edit}
                                            />
                                            <label
                                                className='staan-form-input-label   px-1 mx-2'
                                            >
                                                User<span className='text-danger'>*</span>
                                            </label>
                                            {touched.user && errors.user && (
                                                <small className='text-danger'>{errors.user}</small>
                                            )}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='email' name='email' id='email' value={userManagmentFormValue?.email} placeholder='' disabled className='w-100 staan-form-input' />
                                            <label
                                                htmlFor='email'
                                                className='staan-form-input-label pt-1   px-1 mx-2'
                                            >
                                                Email
                                            </label>
                                            {touched.email && errors.email && (
                                                <small className='text-danger'>{errors.email}</small>
                                            )}

                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='firstName' id='firstName' disabled={editData && edit} placeholder=''
                                                onChange={(e) => {
                                                    setFieldValue("firstName", e?.target?.value)
                                                    setUserManagmentFormValue(prve => ({
                                                        ...prve,
                                                        firstName: e?.target?.value ?? ""
                                                    }))

                                                }}
                                                value={userManagmentFormValue?.firstName} className='w-100 staan-form-input' />
                                            <label
                                                htmlFor='firstName'
                                                className='staan-form-input-label pt-1  px-1 mx-2'
                                            >
                                                First Name
                                            </label>
                                            {touched.firstName && errors.firstName && (
                                                <small className='text-danger'>{errors.firstName}</small>
                                            )}

                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='lastName' disabled={editData && edit} placeholder=''
                                                onChange={(e) => {
                                                    setFieldValue("lastName", e?.target?.value)
                                                    setUserManagmentFormValue(prve => ({
                                                        ...prve,
                                                        lastName: e?.target?.value ?? ""
                                                    }))
                                                }}
                                                value={userManagmentFormValue?.lastName} className='w-100 staan-form-input' />
                                            <label
                                                htmlFor='lastName'
                                                className='staan-form-input-label pt-1  px-1 mx-2'
                                            >
                                                Last Name
                                            </label>
                                            {touched.lastName && errors.lastName && (
                                                <small className='text-danger'>{errors.lastName}</small>
                                            )}
                                        </div>
                                        <div className='col-6 staan-input-group'>
                                            <CustomSelect
                                                setSelectEditValue={userManagmentFormValue?.department}
                                                handleInputChange={(e) => { fetchOptions(DepartmentQuery(`name: "${e.trim()}"`), "departmant") }}
                                                isClearable
                                                options={optionList?.departmentList}
                                                name='department'
                                                id='department'
                                                handleOnChange={(options) => {
                                                    setFieldValue("department", options ? Number(options?.value) : "")
                                                    setUserManagmentFormValue(prve => ({
                                                        ...prve,
                                                        department: options ?? null,
                                                    }))
                                                }}
                                                isDisabled={editData && edit}
                                            />
                                            <label
                                                htmlFor='user'
                                                className='staan-form-input-label   px-1 mx-2'
                                            >
                                                Department<span className='text-danger'>*</span>
                                            </label>
                                            {touched.department && errors.department && (
                                                <small className='text-danger'>{errors.department}</small>
                                            )}
                                        </div>
                                        <div className='col-6 staan-input-group'>
                                            <CustomSelect
                                                setSelectEditValue={userManagmentFormValue?.role}
                                                handleInputChange={(e) => { fetchOptions(rolelist(`roleName: "${e.trim()}"`), "rolelist") }}
                                                isClearable
                                                options={optionList?.roleList}
                                                handleOnChange={(options) => {
                                                    setFieldValue("role", options ? Number(options?.value) : "")
                                                    setUserManagmentFormValue(prve => ({
                                                        ...prve,
                                                        role: options ?? null,
                                                    }))
                                                }}
                                                isDisabled={editData && edit}
                                            />
                                            <label
                                                htmlFor='user'
                                                className='staan-form-input-label pt-1  px-1 mx-2'
                                            >
                                                Role
                                            </label>
                                            {touched.role && errors.role && (
                                                <small className='text-danger'>{errors.role}</small>
                                            )}
                                        </div>
                                        <div className='col-6 staan-input-group'>
                                            <CustomSelect
                                                setSelectEditValue={userManagmentFormValue?.profile}
                                                isClearable
                                                handleInputChange={(e) => { fetchOptions(allPermissionModelforOptions(`profileName: "${e.trim()}"`), "profile") }}
                                                options={optionList?.profileList}
                                                name='profile'
                                                id='profile'
                                                handleOnChange={(options) => {
                                                    setFieldValue("profile", options ? Number(options?.value) : "")
                                                    setUserManagmentFormValue(prve => ({
                                                        ...prve,
                                                        profile: options ?? null,
                                                    }))
                                                }}
                                                isDisabled={editData && edit}
                                            />
                                            <label
                                                htmlFor='profile'
                                                className='staan-form-input-label pt-1  px-1 mx-2'
                                            >
                                                Profile
                                            </label>
                                            {touched.profile && errors.profile && (
                                                <small className='text-danger'>{errors.profile}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='text-end'>

                                            <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={close}    >Cancel</button>


                                            <Can allow_list={["Save_User_Management", "Edit_User_Management"]}>
                                                <button type="submit" className="btn btn-sm btn-outline-success shadow-sm" disabled={editData && edit ? true : isSubmitting} >Save</button>
                                            </Can>

                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UserManagmentForm
