
export const companyAddressCreateMutation = (data)=>{
    // addressLine1: "12313" addressLine2: "sddsdf" city: "covai" country: "india" default: false id: null pincode: "423424" state: "tamilnamdu"
    return  `mutation MyMutation {
    companyAddressCreateMutation(${data}) {
        errors
        success
        companyAddressItem {
        addressLine1
        addressLine2
        addressType
        city
        country
        default
        id
        pincode
        state
        }
    }
    }`
    }



