import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlinePercentage } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { GoXCircle } from "react-icons/go";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import DataContext from '../../../context/ItemMasterContext';
import { PurchaseItemCreateMutations } from '../../../GraphQLQueries/PurchaseItemMutations';
import { itemmasterPruchase } from '../../../GraphQLQueries/PurchaseItemQueries';
import { DiscountAsValue, DiscountPercentage, ShowErrorNoties, customSelectStyle, gettax, removeEmptyValueInObject } from '../../../utils/Utils';
import { PurchaseItemSchema } from '../../../validations/PurchaseValidations';
import CustomSelect from '../../../components/ReactSelect/reactSelect';


const PurchaseItemModel = ({ amend, duplicate, purchaseItemShow, setPurchaseItemShow, getOption, itemMasterList, states, setItemMasterList, setItemMasterNameList,
    itemMasterNameList, setPurchaseItemDetails, purchaseItemDetails, purchaseItemDetailsEdit, SNo, setPurchaseItemDetailsEdit }) => {
    const { userId } = useContext(DataContext)
    const [initialvalue, setInitialvalue] = useState({
        id: "",
        sno: "",
        item_master_part_code: "",
        item_master_part_name: "",
        category: "",
        po_qty: 1,
        uom: "",
        rate: "",
        discount: "",
        tax: "",
        hsnId: "",
        amount: "",
        received: "",
        modified_by: "",
        created_by: "",
    })

    const [uomList, setUomList] = useState([])
    const [itemMasterCodeSelecte, setItemMasterCodeSelecte] = useState(null)
    const [itemMasterNameSelecte, setItemMasterNameSelecte] = useState(null)
    const [uomSelecte, setUomSelecte] = useState(null)
    const [isPercentage, setIsPercentage] = useState(true)
    const [discount, setDiscount] = useState("0%")
    const [amount, setAmount] = useState(null)
    const [variation, setVariation] = useState(null)
    const [itemmasterRate, setItemmasterRate] = useState(null)
    const [RateAfterDiscount, setRateAfterDiscount] = useState(null)
    const [itemMasterCost, setItemMasterCost] = useState(null)

    useEffect(() => {
        if (purchaseItemDetailsEdit !== null) {
            initialvalue.id = purchaseItemDetailsEdit?.id
            initialvalue.sno = purchaseItemDetailsEdit?.sNo
            initialvalue.item_master_part_code = purchaseItemDetailsEdit?.itemMasterId?.id
            initialvalue.item_master_part_name = purchaseItemDetailsEdit?.itemMasterId?.id
            getAlUomCost(initialvalue.item_master_part_name)
            initialvalue.category = purchaseItemDetailsEdit?.category
            initialvalue.po_qty = purchaseItemDetailsEdit?.poQty
            initialvalue.uom = purchaseItemDetailsEdit?.uom?.id
            initialvalue.hsnId = purchaseItemDetailsEdit?.id
            initialvalue.rate = purchaseItemDetailsEdit?.rate
            initialvalue.discount = purchaseItemDetailsEdit?.discount ? purchaseItemDetailsEdit?.discount : ""
            initialvalue.received = purchaseItemDetailsEdit?.received
            initialvalue.tax = purchaseItemDetailsEdit?.tax
            initialvalue.amount = purchaseItemDetailsEdit?.amount
            initialvalue.created_by = purchaseItemDetailsEdit?.createdBy?.id
            if (purchaseItemDetailsEdit?.itemMasterId?.variation) {
                setVariation(purchaseItemDetailsEdit?.itemMasterId?.variation)
            }
            if (purchaseItemDetailsEdit?.itemMasterId?.itemCost) {
                setItemmasterRate(purchaseItemDetailsEdit?.itemMasterId?.itemCost)
            }
            if (initialvalue.item_master_part_code) {
                setItemMasterCodeSelecte({ value: purchaseItemDetailsEdit?.itemMasterId?.id, label: purchaseItemDetailsEdit?.itemMasterId?.itemPartCode })
                setItemMasterNameSelecte({ value: purchaseItemDetailsEdit?.itemMasterId?.id, label: purchaseItemDetailsEdit?.itemMasterId?.itemName })
            }
            if (initialvalue.uom) {
                setUomSelecte({ value: purchaseItemDetailsEdit?.uom?.id, label: purchaseItemDetailsEdit?.uom?.name })
            }
            if (initialvalue.discount && initialvalue.discount !== "0%" && initialvalue.discount !== "₹0") {
                setDiscount(initialvalue.discount)
                setRateAfterDiscount(initialvalue.amount / initialvalue.po_qty)
            }
            if (initialvalue.amount) {
                setAmount(initialvalue.amount)
            }
        }
    }, [purchaseItemDetailsEdit])

    async function getAlUomCost(params) {
        try {
            let parameter = `id:${params}`;
            const response = await axiosInstance.post('/itemmaster/graphql', { query: itemmasterPruchase(parameter) });
            const responseData = response?.data?.data?.itemMaster?.items?.[0];
            if (responseData?.alternateUom?.length > 0) {
                for (let index in responseData?.alternateUom) {
                    let alternateuom = responseData?.alternateUom[index];
                    setUomList(prev => {
                        // Find the index of the existing item with the same id
                        const existingIndex = prev?.findIndex(item => item.value === alternateuom?.addtionalUnit?.id);

                        // If the item exists, replace it; otherwise, add it to the list
                        if (existingIndex !== -1) {
                            const newList = [...prev];
                            newList[existingIndex] = {
                                value: alternateuom?.addtionalUnit?.id,
                                label: alternateuom?.addtionalUnit?.name,
                                cf: alternateuom?.conversionFactor,
                                isMain: false
                            };
                            return newList;
                        } else {
                            return [...prev, {
                                value: alternateuom?.addtionalUnit?.id,
                                label: alternateuom?.addtionalUnit?.name,
                                cf: alternateuom?.conversionFactor,
                                isMain: false
                            }];
                        }
                    });
                }

            }
            if (responseData?.itemUom) {
                setUomList(prev => {
                    const existingIndex = prev?.findIndex(item => item.value === responseData?.itemUom?.id);
                    if (existingIndex !== -1) {
                        const newList = [...prev];
                        newList[existingIndex] = {
                            value: responseData?.itemUom?.id,
                            label: responseData?.itemUom?.name,
                            isMain: true
                        };
                        return newList;
                    } else {
                        return [...prev, {
                            value: responseData?.itemUom?.id,
                            label: responseData?.itemUom?.name,
                            isMain: true
                        }];
                    }
                })
            }
            setItemMasterCost(responseData?.itemCost)
        } catch (error) {
            console.error("Error fetching item master data", error);
        }
    }

    function FinalValue(discount, amount) {
        if (discount.includes("%")) {
            return DiscountPercentage(Number(discount?.replace("%", "")), Number(amount))
        } else if (discount.includes("₹")) {
            return DiscountAsValue(discount?.replace("₹", ""), amount)
        }

    }

    // retun null ; save values
    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors, setFieldError }) => {

        let error
        let checkWithId
        if (values?.id) {
            let checkWithId = purchaseItemDetails?.find(item => item?.id === values?.id)
            if (checkWithId?.itemMasterId?.id === values?.item_master_part_name) {
                error = ""
            } else {
                checkWithId = purchaseItemDetails?.filter(item => item?.id !== values?.id).find(item => item?.itemMasterId?.id === values?.item_master_part_name)
                if (checkWithId) {
                    error = "Already exists In Purchase Item Details"
                }
            }
        } else {
            if (amend || duplicate) {
                if (values['sno']) {
                    checkWithId = purchaseItemDetails?.find(item => item?.itemMasterId?.id === values?.['item_master_part_code'] && item?.sNo === values['sno'])
                } else {
                    checkWithId = purchaseItemDetails?.find(item => item?.itemMasterId?.id === values?.['item_master_part_code'])
                }
            } else {
                checkWithId = purchaseItemDetails?.find(item => item?.itemMasterId?.id === values?.['item_master_part_code'])
            }
            if (checkWithId) {
                error = "Already exists In Purchase Item Details"
            } else {
                error = ""
            }
        }
        if (!error) {
            let rate = values?.rate;
            let finalAmount = Number(amount)   // Ensure finalAmount is a number
            let qty = Number(values?.po_qty); // Ensure qty is a number 
            if (variation && itemmasterRate) {
                let variationsValue, variationsAmount, minAmount, maxAmount, perUnitAmount;
                if (variation.includes("%")) {
                    variationsValue = variation.replace("%", "");
                    variationsAmount = rate / 100 * Number(variationsValue);
                    minAmount = Number(itemmasterRate) - Number(variationsAmount);
                    maxAmount = Number(itemmasterRate) + Number(variationsAmount);
                } else if (variation.includes("₹")) {
                    variationsValue = variation.replace('₹', '');
                    variationsAmount = Number(variationsValue); // Convert to number
                    minAmount = Number(itemmasterRate) - Number(variationsAmount);
                    maxAmount = Number(itemmasterRate) + Number(variationsAmount);
                }
                perUnitAmount = finalAmount / qty;
                if (minAmount <= perUnitAmount && perUnitAmount <= maxAmount) {

                } else {
                    toast.error('Item Master Cost is out of Range', {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light'
                    });
                    return "";
                }
            } else {
                console.log("Enter variation");
                return "";
            }
            let save_data = {
                sNo: initialvalue?.sno ? Number(initialvalue?.sno) : Number(SNo),
                id: initialvalue?.id ? Number(initialvalue?.id) : "",
                itemMasterId: values?.item_master_part_code ? Number(values.item_master_part_code) : "",
                category: values?.category,
                poQty: values?.po_qty ? values?.po_qty : "",
                uom: values?.uom ? Number(values?.uom) : "",
                hsnId: values?.hsnId ? Number(values?.hsnId) : "",
                rate: values?.rate ? Number(values?.rate) : "",
                tax: values?.tax ? Number(values?.tax) : "",
                amount: amount ? `${amount}` : `${values.rate * values.po_qty}`,
                discount: values?.discount,
                verifiedAmount: true,
                received: initialvalue?.received ? initialvalue.received : 0,
                createdBy: initialvalue?.created_by ? Number(initialvalue.created_by) : Number(userId),
                modifiedBy: initialvalue?.id ? Number(userId) : ""
            }
            let save_value_with_tax = gettax(save_data, save_data['tax'], states)
            let allow_null = ['id', "modifiedBy", "discount"]
            let clear_data = removeEmptyValueInObject(save_value_with_tax, allow_null)
            try {
                const respones = await axiosInstance.post("/itemmaster/graphql", { query: PurchaseItemCreateMutations(clear_data) })
                const responseData = respones.data.data.purchaseOrderItemDetailsCreateMutation
                if (responseData.success) {
                    if (amend || duplicate) {
                        setPurchaseItemDetails(prev => {
                            // Find the index of the existing item with the same itemPartCode
                            const existingIndex = prev.findIndex(item =>
                                item?.itemMasterId?.itemPartCode === responseData?.purchaseOrderItemDetailsInstance?.itemMasterId?.itemPartCode
                            );
                            if (existingIndex !== -1) {
                                // Replace the existing item
                                const newList = [...prev];
                                newList[existingIndex] = responseData?.purchaseOrderItemDetailsInstance;
                                return newList;
                            } else {
                                // Add the new item
                                return [...prev, responseData?.purchaseOrderItemDetailsInstance];
                            }
                        });

                    } else {
                        if (initialvalue?.id === "") {
                            setPurchaseItemDetails(prve => [...prve, responseData?.purchaseOrderItemDetailsInstance])
                        } else {
                            setPurchaseItemDetails(prev => {
                                return prev.map(item => {
                                    return item.id === responseData?.purchaseOrderItemDetailsInstance?.id ? responseData?.purchaseOrderItemDetailsInstance : item
                                })
                            })
                        }
                    }
                    resetForm()
                    setSubmitting(false)
                    close()
                    toast.success('Saved Successfully', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                } else {
                    let error = responseData.errors
                    let errorData = ShowErrorNoties(error)
                    toast.error(errorData, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                }
            } catch (error) {
                let errorData = ShowErrorNoties(error)
                toast.error(errorData, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        } else {
            toast.error(error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }

    // return null; close model and clear the value
    function close() {
        setPurchaseItemShow(false)
        setItemMasterCodeSelecte(null)
        setItemMasterNameSelecte(null)
        setItemMasterList([])
        setItemMasterNameList([])
        setPurchaseItemDetailsEdit(null)
    }


    // retun null ; on select itemmaster change related datas
    function handleItemMasterOptions(options, setFieldValue, qty) {
        if (options) {
            setVariation(options?.varition)
            setUomSelecte({ value: "", label: '' })
            setFieldValue("uom", null)
            setFieldValue("category", options?.category?.name)
            setFieldValue("rate", options?.rate)
            setItemmasterRate(options?.rate)
            setItemMasterCost(options?.rate)
            setFieldValue("tax", options?.tax)
            setFieldValue("hsnId", options?.Hsn)
            setUomList([{ value: options?.uom?.id, label: options?.uom?.name, isMain: true }])
            setFieldValue("amount", options?.rate * qty)
            setAmount(options?.rate * qty)
            if (options?.alternateUom.length > 0) {
                for (let index in options?.alternateUom) {
                    let alternateuom = options?.alternateUom[index]
                    setUomList(prev => [...prev, {
                        value: alternateuom?.addtionalUnit?.id,
                        label: alternateuom?.addtionalUnit?.name, cf: alternateuom.conversionFactor,
                        isMain: false
                    }])
                }
            }
            if (options) {
                if (options?.purchaseUom?.id) {
                    const filteredUom = options?.alternateUom.filter(singleUom => singleUom?.addtionalUnit?.id == options?.purchaseUom?.id);
                    if (filteredUom.length > 0) {
                        setUomSelecte(options ? { value: options?.purchaseUom?.id, label: options?.purchaseUom?.name, isMain: false } : null)
                    } else {
                        setUomSelecte(options ? { value: options?.purchaseUom?.id, label: options?.purchaseUom?.name, isMain: true } : null)
                    }
                    setFieldValue("uom", options ? options?.purchaseUom?.id : null)

                }
            }
        } else {
            setFieldValue("category", "")
            setFieldValue("rate", "")
            setFieldValue("tax", "")
            setUomList([])
        }
    }

    const handlePoQtyChange = (e, setFieldValue, values) => {
        const poQty = e.target.value;
        const rate = values?.rate;
        setFieldValue('po_qty', poQty);
        if (rate) {
            const amount = Number(rate) * Number(poQty);
            setAmount(amount);
            //   setFieldValue('amount', amount); 
        } else {
            setFieldValue('amount', 0);
            setAmount(0);  // or some default value if rate is not defined
        }
    };

    const handleRateChange = (e, setFieldValue, values) => {
        let rate = e.target.value;
        let poQty = values?.po_qty;
        setFieldValue('rate', rate);
        if (rate) {
            const amount = Number(rate) * Number(poQty);
            setAmount(amount);
        } else {
            setAmount(0);  // or some default value if rate is not defined
        }
    };
    // retun bool; if discount more the zero make disabled qty and rate    
    const isDisabled = (discount) => {
        if (discount.includes('%')) {
            const percentage = parseFloat(discount.replace('%', '').trim());
            return percentage > 0;
        } else if (discount.includes('₹')) {
            const amount = parseFloat(discount.replace('₹', '').trim());
            return amount > 0;
        }
        return false;
    };

    return (
        <>
            <ToastContainer />
            <Modal show={purchaseItemShow} size="lg" backdrop="static" keyboard={false} centered>
                <Modal.Header  >
                    <Modal.Title style={{ color: "#5CADA9" }}>
                        <i className='fa-solid fa-arrow-left fa-sm me-2 cursor-pointer' onClick={close}></i>Item
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik initialValues={initialvalue}
                        validationSchema={PurchaseItemSchema}
                        onSubmit={handleSubmit}>
                        {({ errors, touched, setFieldValue, values, isSubmitting }) => {
                            return (
                                <Form>
                                    <div className="row">
                                        <div className="col-6 staan-input-group">
                                            <CustomSelect
                                                setSelectEditValue={itemMasterCodeSelecte}
                                                handleInputChange={(e) => {
                                                    getOption(e, "ItemMasterPurchaseOrderFetchQuery", "itemPartCode")
                                                }}
                                                options={itemMasterList}
                                                name='item_master_part_code'
                                                id='item_master_part_code'
                                                handleOnChange={(option) => {
                                                    setFieldValue("item_master_part_code", option ? option.value : null)
                                                    setItemMasterCodeSelecte(option ? { value: option.value, label: option.label } : null)
                                                    setFieldValue("item_master_part_name", option ? option.value : null)
                                                    setItemMasterNameSelecte({ value: option.value, label: option.itemName })
                                                    setUomList([])
                                                    handleItemMasterOptions(option, setFieldValue, values?.po_qty)
                                                }}
                                            />
                                            <label htmlFor="item_master_part_code" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => { console.log(errors); }}>Part Code<span className='text-danger'>*</span></label>
                                            {touched.item_master_part_code && errors.item_master_part_code && <small>{errors.item_master_part_code}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <CustomSelect
                                                setSelectEditValue={itemMasterNameSelecte}
                                                handleInputChange={(e) => {
                                                    getOption(e, "ItemMasterPurchaseOrderFetchQuery", "itemPartName")
                                                }}
                                                options={itemMasterNameList}
                                                name='item_master_part_name'
                                                id='item_master_part_name'
                                                handleOnChange={(option) => {
                                                    setFieldValue("item_master_part_name", option ? option.value : null)
                                                    setItemMasterNameSelecte({ value: option.value, label: option.label })
                                                    setFieldValue("item_master_part_code", option ? option.value : null)
                                                    setItemMasterCodeSelecte(option ? { value: option.value, label: option.itemPartCode } : null)
                                                    setUomList([])
                                                    handleItemMasterOptions(option, setFieldValue, values?.po_qty)
                                                }}
                                            />
                                            <label htmlFor="item_master_part_name" className='staan-form-input-label pt-1 px-1 mx-2'>Part Name<span className='text-danger'>*</span></label>
                                            {touched.item_master_part_name && errors.item_master_part_name && <small>{errors.item_master_part_name}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='category' disabled className='w-100 staan-form-input' />
                                            <label htmlFor="category" className='staan-form-input-label pt-1 px-1 mx-2'>Category<span className='text-danger'>*</span></label>
                                            {touched.category && errors.category && <small>{errors.category}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='po_qty' placeholder='Enter Po Qty'
                                                disabled={isDisabled(values.discount)}
                                                onChange={(e) => { handlePoQtyChange(e, setFieldValue, values) }} className='w-100 staan-form-input' />
                                            <label htmlFor="po_qty" className='staan-form-input-label pt-1 px-1 mx-2'>PO Qty<span className='text-danger'>*</span></label>
                                            {touched.po_qty && errors.po_qty && <small>{errors.po_qty}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <CustomSelect
                                                setSelectEditValue={uomSelecte}
                                                options={uomList}
                                                name='accountName'
                                                id='accountName'
                                                handleOnChange={(option) => {
                                                    let rate
                                                    if (option.cf) {
                                                        if (uomSelecte?.isMain && !option?.isMain) {
                                                            rate = (Number(values?.rate) * Number(option?.cf)).toFixed(2)
                                                        } else if (!uomSelecte?.isMain && !option?.isMain) {
                                                            let preSeletce = uomList?.find((item) => item?.value === uomSelecte?.value)

                                                            rate = Number(values?.rate) / Number(preSeletce?.cf)
                                                            rate = (rate * Number(option?.cf)).toFixed(2)
                                                        }
                                                        setFieldValue('rate', rate);
                                                        const amount = Number(rate) * Number(values?.po_qty);
                                                        setAmount(amount);
                                                        setFieldValue("uom", option ? option.value : null)
                                                        setUomSelecte(option ? option : null)
                                                    } else {
                                                        if (!uomSelecte?.isMain && option?.isMain) {
                                                            let preSeletce = uomList?.find((item) => item?.value === uomSelecte?.value)
                                                            rate = (Number(values?.rate) / Number(preSeletce?.cf)).toFixed(1)
                                                        }
                                                        setFieldValue('rate', rate);
                                                        const amount = Number(rate) * Number(values?.po_qty);
                                                        setAmount(amount);
                                                        setFieldValue("uom", option ? option.value : null)
                                                        setUomSelecte(option ? option : null)
                                                    }
                                                }}
                                            />
                                            <label htmlFor="uom" className='staan-form-input-label pt-1 px-1 mx-2'>UOM<span className='text-danger'>*</span></label>
                                            {touched.uom && errors.uom && <small>{errors.uom}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='rate' placeholder='Enter Rate' disabled={true} onChange={(e) => { handleRateChange(e, setFieldValue, values) }} className='w-100 staan-form-input' />
                                            <label htmlFor="rate" className='staan-form-input-label pt-1 px-1 mx-2'>Rate<span className='text-danger'>*</span></label>
                                            {touched.rate && errors.rate && <small>{errors.rate}</small>}
                                        </div>
                                        <div className="col-2 staan-input-group">
                                            <Field type='text' name='discount' placeholder='Enter discount'
                                                value={discount} onChange={(e) => {
                                                    setDiscount(e.target.value)
                                                    setFieldValue('discount', e.target.value)
                                                    let discountAmount = FinalValue(e.target.value, Number(values.rate))
                                                    if (discountAmount === undefined || discountAmount === "") {
                                                        setFieldValue("amount", Number(values.rate * values.po_qty))
                                                        setAmount(Number(values.rate * values.po_qty))
                                                        setRateAfterDiscount(0)
                                                    } else {
                                                        setFieldValue("amount", Number(discountAmount) * Number(values.po_qty))
                                                        setAmount(Number(discountAmount) * Number(values.po_qty))
                                                        setRateAfterDiscount(discountAmount)
                                                    }
                                                }} className='w-100 staan-form-input' />
                                            <label htmlFor="discount" className='staan-form-input-label pt-1 px-1 mx-2'>Discount Per Qty
                                            </label>
                                            <GoXCircle className='cursor-pointer ms-2' style={{ top: "-2rem", left: "5rem", position: "relative" }} onClick={() => {
                                                setFieldValue("amount", Number(values.rate * values.po_qty))
                                                setAmount(Number(values.rate * values.po_qty))
                                                setDiscount("")
                                                setFieldValue('discount', "")
                                                setRateAfterDiscount("")
                                            }} />
                                            {touched.discount && errors.discount && <small>{errors.discount}</small>}
                                        </div>
                                        <div className="col-1 mt-3 text-center text-primary">
                                            {isPercentage ?
                                                <BsCurrencyRupee onClick={() => {
                                                    setIsPercentage(false);
                                                    const newValue = `₹${discount?.replace("%", "")}`;
                                                    setDiscount(newValue !== undefined ? newValue : "")
                                                    setFieldValue('discount', newValue)
                                                    let discountAmount = FinalValue((newValue), Number(values.rate * values.po_qty))
                                                    setFieldValue("amount", discountAmount)
                                                    setAmount(discountAmount)
                                                }} /> :
                                                <AiOutlinePercentage onClick={() => {
                                                    setIsPercentage(false);
                                                    setIsPercentage(true);
                                                    const newValue = `${discount?.replace("₹", "")}%`;
                                                    setDiscount(newValue !== undefined ? newValue : "");
                                                    setFieldValue('discount', newValue);
                                                    let discountAmount = FinalValue((newValue), Number(values.rate * values.po_qty))
                                                    setFieldValue("amount", discountAmount)
                                                    setAmount(discountAmount)
                                                }} />}

                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <Field type='text' name='Rate After Discount' disabled value={RateAfterDiscount} placeholder='Enter discount'
                                                className='w-100 staan-form-input' />
                                            <label htmlFor="discount" className='staan-form-input-label pt-1 px-1 mx-2'>Rate After Discount</label>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='tax' disabled className='w-100 staan-form-input' />
                                            <label htmlFor="tax" className='staan-form-input-label pt-1 px-1 mx-2'>Tax<span className='text-danger'>*</span></label>
                                            {touched.tax && errors.tax && <small>{errors.tax}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='amount' placeholder='Enter Amount' value={amount !== "" ? amount : Number(values.rate * values.po_qty)} disabled className='w-100 staan-form-input' />
                                            <label htmlFor="amount" className='staan-form-input-label pt-1 px-1 mx-2'>Amount<span className='text-danger'>*</span></label>
                                            {touched.amount && errors.amount && <small>{errors.amount}</small>}
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='text-end'>
                                                <button type="button" className="btn btn-outline-danger btn-sm me-2 " onClick={close} >Cancel</button>
                                                <button type="submit" className="btn btn-sm btn-outline-success shadow-sm px-3" disabled={isSubmitting} >Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default PurchaseItemModel