import { Modal } from "react-bootstrap";


const BomDeleteConfirm = ({show, close, handleDeleteData}) => {

    return(
        <div>
            <Modal
                show={show}
                onHide={close}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure want to delete the data?
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <button type="button" className="btn btn-outline-danger btn-sm px-3 fw-bold" onClick={close}>No</button>
                    <button type="button" className="btn btn-outline-success btn-sm px-3 fw-bold" onClick={()=>{handleDeleteData(true); close()}}>Yes</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default BomDeleteConfirm;