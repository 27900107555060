import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlinePercentage } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../../api/axoiss';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import DataContext from '../../../context/ItemMasterContext';
import { ItemMasterPurchaseOrderFetchQuery } from '../../../GraphQLQueries/ItemmasterQuery';
import { getItemComboDetails } from '../../../GraphQLQueries/QuatationqueryAndMutatiom/QuatationItemModalMutation';
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { checkNegativeNumber, DiscountAsValue, DiscountPercentage, rateConversionRupeesToOtherCurrency, ShowErrorNoties } from '../../../utils/Utils';
import { salesOrderItemDetailsschema } from '../../../validations/SalesOrderValidation';
import { parseAndGroupItemComboData } from '../../Quotation/Popup/itemCombo';
const SalesOrderItem = ({ isShow, setIsShow, buyerState, salesOrderItemDetails, setSalesOrderItemDetails, salesOrderItemDetailsEdit, setSalesOrderItemDetailsEdit, salesOrderValues, setSalesOrderValues,
    setIsShowItemComboModel, callClearDiscount, setItemComboDataList
}) => {
    const { userId } = useContext(DataContext)
    const [initialvalue, setInitialvalue] = useState({
        index: salesOrderItemDetails?.length > 0 ? salesOrderItemDetails[salesOrderItemDetails.length - 1].index : 0,
        id: "",
        qty: 1,
        uom: "",
        hsnCode: '',
        hsnId: '',
        rate: "",
        description: "",
        afterDiscountValueForPerItem: "",
        discountPercentage: "",
        discountValue: "",
        amount: "",
        modifiedBy: "",
        createdBy: Number(userId),
        tax: "",
        itemmaster: "",
        item_master_part_code: "",
        item_master_part_name: "",
        discount: ""
    })
    const [isPercentage, setIsPercentage] = useState(true)
    const [formKey, setFormKey] = useState(0)
    const [discount, setDiscount] = useState('0')
    const [salesOrderOptionsList, setSalesOrderOptionsList] = useState({
        "itemDetailsPartNumberList": [], "itemDetailsPartNameList": [], "uomList": [],
    })
    const [salesOrderItemDetailsValue, setSalesOrderItemDetailsValue] = useState({
        "item_master_part_code": "", "item_master_part_name": "", "description": "", "UOM": "", "tax": "", "amount": "",
        "discount": "", "isDisabled": false,
    })

    // Update The Edit Value
    useEffect(() => {
        if (salesOrderItemDetailsEdit !== null && salesOrderItemDetailsEdit !== "" && isShow) {
            setInitialvalue({
                index: salesOrderItemDetailsEdit?.index,
                id: salesOrderItemDetailsEdit?.id,
                qty: salesOrderItemDetailsEdit?.qty,
                uom: { value: salesOrderItemDetailsEdit?.uom?.value, label: salesOrderItemDetailsEdit?.uom?.label },
                rate: salesOrderItemDetailsEdit?.rate,
                afterDiscountValueForPerItem: salesOrderItemDetailsEdit?.afterDiscountValueForPerItem ? salesOrderItemDetailsEdit?.afterDiscountValueForPerItem : "",
                discountPercentage: salesOrderItemDetailsEdit?.discountPercentage ? salesOrderItemDetailsEdit?.discountPercentage : "",
                discountValue: salesOrderItemDetailsEdit?.discountValue ? salesOrderItemDetailsEdit?.discountValue : "",
                amount: `${salesOrderItemDetailsEdit?.amount}`,
                createdBy: salesOrderItemDetailsEdit?.createdBy?.id ? Number(salesOrderItemDetailsEdit?.createdBy?.id) : Number(userId),
                tax: salesOrderItemDetailsEdit?.tax,
                description: salesOrderItemDetailsEdit?.description ? salesOrderItemDetailsEdit?.description :
                    `${salesOrderItemDetailsEdit?.itemmaster?.itemPartCode}-${salesOrderItemDetailsEdit?.itemmaster?.itemName}`,
                itemmaster: salesOrderItemDetailsEdit?.itemmaster?.id,
                item_master_part_code: salesOrderItemDetailsEdit?.item_master_part_code,
                item_master_part_name: salesOrderItemDetailsEdit?.item_master_part_name,
                hsnId:salesOrderItemDetailsEdit?.hsnId,
                itemMrp: salesOrderItemDetailsEdit?.itemMrp,
                hsnCode: salesOrderItemDetailsEdit?.hsnCode,
            })
            setDiscount(salesOrderItemDetailsEdit?.discountPercentage ? `${salesOrderItemDetailsEdit?.discountPercentage}` :
                salesOrderItemDetailsEdit?.discountValue ? `${salesOrderItemDetailsEdit?.discountValue}` : '0'
            )
            if (salesOrderItemDetailsEdit?.discountPercentage) {
                setIsPercentage(true)
            } else {
                setIsPercentage(false)
            }
            setSalesOrderItemDetailsValue({
                "item_master_part_code": salesOrderItemDetailsEdit?.item_master_part_code
                , "item_master_part_name": salesOrderItemDetailsEdit?.item_master_part_name,
                "UOM": { value: salesOrderItemDetailsEdit?.uom?.value, label: salesOrderItemDetailsEdit?.uom?.label },
                "description": salesOrderItemDetailsEdit?.description ? salesOrderItemDetailsEdit?.description :
                    `${salesOrderItemDetailsEdit?.itemmaster?.itemPartCode}-${salesOrderItemDetailsEdit?.itemmaster?.itemName}`
            })
            if (salesOrderItemDetailsEdit?.itemCombo && salesOrderItemDetailsEdit?.itemComboItemDetails?.length) {
                setSalesOrderItemDetailsValue(prve => ({ ...prve, isDisabled: true }))
            }
            setFormKey(prev => prev + 1);
        }
    }, [salesOrderItemDetailsEdit, isShow, userId])

    // Redux
    const dispatch = useDispatch();
    const Options = useSelector(state => state.itemGroup.data);

    // get data by Redux
    useEffect(() => {
        if (Options.name === "salesOrderItemDetailpartcode") {
            setSalesOrderOptionsList(prve => ({
                ...prve, itemDetailsPartNumberList: Options?.value?.items?.map((item) => ({
                    value: item?.id, label: item?.itemPartCode, partName: item?.itemName, uom: { value: item?.itemUom?.id, label: item?.itemUom?.name },
                    tax: item?.itemHsn?.gstRates?.rate, itemMrp: item?.itemMrp, description: item?.description ? item?.description : `${item?.itemPartCode}-${item?.itemName}`,
                    hsnCode: item?.itemHsn?.hsnCode, hsnId: item?.itemHsn?.id, itemComboBool: item?.itemComboBool

                }))
            }))
        } else if (Options?.name === "salesOrderItemDetailpartname") {
            setSalesOrderOptionsList(prve => ({
                ...prve, itemDetailsPartNameList: Options?.value?.items?.map((item) => ({
                    value: item?.id, label: item?.itemName, uom: { value: item?.itemUom?.id, label: item?.itemUom?.name }, partCode: item?.itemPartCode,
                    tax: item?.itemHsn?.gstRates?.rate, itemMrp: item?.itemMrp, description: item?.description ? item?.description : `${item?.itemPartCode}-${item?.itemName}`,
                    hsnCode: item?.itemHsn?.hsnCode, hsnId: item?.itemHsn?.id, itemComboBool: item?.itemComboBool
                }))
            }))
        }
    }, [dispatch, Options])


    // return null; save Item Detaisl
    const handleSubmit = async (values, { resetForm, setErrors, setSubmitting }) => {
        try {

            const validationResponse = checkNegativeNumber(values, ['qty', 'rate', 'amount', 'afterDiscountValueForPerItem', 'discountPercentage', 'discountValue', 'tax'])
            if (validationResponse) {
                showErrorToast(true, 'error', "Value Should Not Be Negative", "")
                setSubmitting(false)
                return
            }
            values['itemmaster'] = Number(values?.item_master_part_code)
            values['states'] = buyerState
            if (isPercentage) {
                values['afterDiscountValueForPerItem'] = values?.afterDiscountValueForPerItem ? `${values?.afterDiscountValueForPerItem}` : ""
                values['discountPercentage'] = discount ? discount?.replace("%", "") : ""
                values['discountValue'] = ""
            } else {
                values['afterDiscountValueForPerItem'] = values?.afterDiscountValueForPerItem ? `${values?.afterDiscountValueForPerItem}` : ""
                values['discountPercentage'] = ""
                values['discountValue'] = discount ? discount?.replace("₹", "") : ""
            }
            if (values['description']?.trim() === "" || values['description'] === null || values['description'] === undefined) {
                values['description'] = `${salesOrderItemDetailsValue?.item_master_part_name?.label}-${salesOrderItemDetailsValue?.item_master_part_name?.label}`
            }
            values['amount'] = `${values['amount']}`
            values['finalValue'] = ''
            // values['item_master_part_code'];
            // values['item_master_part_name'];
            delete values['sno'];
            delete values['discount']
            if (buyerState === "Tamil Nadu" || buyerState === "Tamilnadu") {
                values['sgst'] = values["tax"] / 2
                values['cgst'] = values["tax"] / 2
                values['igst'] = 0
            } else {
                values['sgst'] = 0
                values['cgst'] = 0
                values['igst'] = values["tax"]
            }
            values['index'] = salesOrderItemDetailsEdit?.index ? values['index'] : values['index'] + 1
            values['createdBy'] = values['createdBy'] ? values['createdBy'] : Number(userId)
            values['modifiedBy'] = values['id'] ? values['modifiedBy'] ? values['modifiedBy'] : Number(userId) : ''
            const updatedItemDetails = salesOrderItemDetails?.map((item, index) => {
                if (item.index === values.index) {
                    return values;
                }
                return item;
            })
            if (!updatedItemDetails.some(item => item.index === values.index)) {
                updatedItemDetails.push(values)
            }
            setSalesOrderItemDetails(updatedItemDetails)
            callClearDiscount(updatedItemDetails, null);
            if (values?.itemComboBool) {
                try {
                    let reqBody = {
                        itemmaster: values?.item_master_part_code?.value,
                        amount: Number(values?.amount),
                        qty: Number(values?.qty)
                    }
                    const response = await axiosInstance.post(`/itemmaster/graphql`, {
                        query: getItemComboDetails(reqBody)
                    })
                    let responseData = response?.data?.data?.getItemComboItemDetails
                    if (responseData.itemCombo) {
                        const groupedData = parseAndGroupItemComboData(responseData?.itemComboData, values?.index);
                        values['itemComboItemDetails'] = []
                        setItemComboDataList(groupedData)
                        // setItemComboData(groupedData);
                        setIsShowItemComboModel(true)
                        setSalesOrderValues(prev => ({ ...prev, lastUpdatedItemDetails: values }))
                        // setShowItemCombo(true);

                    }
                    else {
                        let errorData = ShowErrorNoties(responseData.errors)
                        console.log(errorData);
                        showErrorToast(true, 'error', errorData)
                    }
                } catch (error) {
                    let errorData = ShowErrorNoties(error)
                    console.log(error);
                    showErrorToast(true, 'error', errorData)
                }
            }
            Close()
            showErrorToast(true, "success", "", "Saved Successfully")
            setSubmitting(false)
        } catch (error) {
            console.log("error", error);
        }

    }

    // close this model clear the values
    const Close = () => {
        setSalesOrderItemDetailsValue({ "item_master_part_code": "", "item_master_part_name": "", "UOM": "", "tax": "", "amount": "" })
        setInitialvalue({
            id: "",
            qty: 1,
            uom: "",
            rate: "",
            description: "",
            afterDiscountValueForPerItem: "",
            discountPercentage: "",
            discountValue: "",
            amount: "",
            modifiedBy: "",
            createdBy: Number(userId),
            tax: "",
            itemmaster: "",
            item_master_part_code: "",
            item_master_part_name: "",
        })
        setIsShow(false)
        setFormKey(formKey + 1)
        setSalesOrderItemDetailsEdit(null)
    }

    // localy apply the discount
    // function FinalValue(discount, amount) {
    //     if (discount.includes("%")) {
    //         return DiscountPercentage(Number(discount?.replace("%", "")), Number(amount))
    //     } else if (discount.includes("₹")) {
    //         return DiscountAsValue(discount?.replace("₹", ""), amount)
    //     }

    // }
    function FinalValue(discount, amount, isPer) {
        // Function to calculate final value after discount based on the type of discount
        if (isPer) {
            // return DiscountPercentage(Number(discount?.replace("%", "")), Number(amount))
            return DiscountPercentage(Number(discount?.replace("%", "")), Number(amount))

        } else {
            return DiscountAsValue(Number(discount?.replace("%", "")), Number(amount))
            // return DiscountAsValue(discount?.replace(salesOrderValues?.currency?.currencySymbol, ""), Number(amount))
        }

    }
    return (
        <>
            <Modal size="lg" show={isShow} backdrop="static"  >
                <Modal.Header  >
                    <Modal.Title style={{ color: "#5CADA9" }}>
                        <i className='fa-solid fa-arrow-left fa-sm me-2 cursor-pointer' onClick={Close}></i>Item
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik initialValues={initialvalue}
                        validationSchema={salesOrderItemDetailsschema}
                        onSubmit={handleSubmit} enableReinitialize key={formKey}>
                        {({ errors, touched, setFieldValue, values, isSubmitting, setValues }) => {
                            return (
                                <Form>
                                    <div className="row">
                                        <div className="col-6 staan-input-group">
                                            <CustomSelect
                                                setSelectEditValue={salesOrderItemDetailsValue?.item_master_part_code}
                                                handleInputChange={(e) => {
                                                    dispatch(fetchOptionsData(ItemMasterPurchaseOrderFetchQuery(
                                                        `itemPartCode: "${e}", page:1, pageSize:100,  itemIndicatorsList:["Sell","Both"]`), 'salesOrderItemDetailpartcode'))
                                                }}
                                                options={salesOrderOptionsList?.itemDetailsPartNumberList}
                                                name='item_master_part_code'
                                                id='item_master_part_code'
                                                handleOnChange={(option) => {
                                                    setSalesOrderItemDetailsValue({
                                                        item_master_part_code: option ? option : null,
                                                        item_master_part_name: { value: option?.value, label: option?.partName },
                                                        UOM: { value: option?.uom?.value, label: option?.uom?.label },
                                                        tax: option?.tax,
                                                        description: option?.description
                                                    })
                                                    let updateRate = option?.itemMrp
                                                    if (salesOrderValues?.currency?.rate) {
                                                        updateRate = rateConversionRupeesToOtherCurrency(option?.itemMrp, salesOrderValues?.currency?.rate)
                                                    }
                                                    setValues(prve => ({
                                                        ...prve,
                                                        item_master_part_code: option ? option : null,
                                                        item_master_part_name: { value: option?.value, label: option?.partName },
                                                        uom: { value: option?.uom?.value, label: option?.uom?.label },
                                                        hsnCode: option?.hsnCode,
                                                        tax: option?.tax,
                                                        rate: `${updateRate}`,
                                                        amount: Number(values.qty) * Number(updateRate),
                                                        description: option?.description,
                                                        afterDiscountValueForPerItem: 0,
                                                        itemMrp: option?.itemMrp,
                                                        itemComboBool: option?.itemComboBool,
                                                        hsnId:option?.hsnId

                                                    }))
                                                    setDiscount(0)
                                                }}
                                                isDisabled={salesOrderItemDetailsValue?.isDisabled}
                                            />
                                            <label className='staan-form-input-label pt-0 px-1 mx-2'>Part Code<span className='text-danger'>*</span></label>
                                            {touched.item_master_part_code && errors.item_master_part_code && <small>{errors.item_master_part_code}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <CustomSelect
                                                setSelectEditValue={salesOrderItemDetailsValue?.item_master_part_name}
                                                handleInputChange={(e) => {
                                                    dispatch(fetchOptionsData(ItemMasterPurchaseOrderFetchQuery(
                                                        `itemName: "${e}", page:1, pageSize:100,  itemIndicatorsList:["Sell","Both"]`), 'salesOrderItemDetailpartname'))
                                                }}
                                                options={salesOrderOptionsList?.itemDetailsPartNameList}
                                                name='item_master_part_name'
                                                id='item_master_part_name'
                                                handleOnChange={(option) => {
                                                    setSalesOrderItemDetailsValue({
                                                        item_master_part_code: { value: option?.value, label: option?.partCode },
                                                        item_master_part_name: option ? option : null,
                                                        UOM: { value: option?.uom?.value, label: option?.uom?.label },
                                                        tax: option?.tax,
                                                        description: option?.description
                                                    })
                                                    let updateRate = option?.itemMrp
                                                    if (salesOrderValues?.currency?.rate) {
                                                        updateRate = rateConversionRupeesToOtherCurrency(option?.itemMrp, salesOrderValues?.currency?.rate)
                                                    }
                                                    setValues(prve => ({
                                                        ...prve,
                                                        item_master_part_code: { value: option?.value, label: option?.partCode },
                                                        item_master_part_name: option,
                                                        uom: { value: option?.uom?.value, label: option?.uom?.label },
                                                        hsnCode: option?.hsnCode,
                                                        tax: option?.tax,
                                                        rate: updateRate,
                                                        amount: Number(values.qty) * Number(updateRate),
                                                        description: option?.description,
                                                        afterDiscountValueForPerItem: 0,
                                                        itemMrp: option?.itemMrp,
                                                        itemComboBool: option?.itemComboBool,
                                                        hsnId:option?.hsnId
                                                    }))
                                                    setDiscount(0)
                                                }}
                                                isDisabled={salesOrderItemDetailsValue?.isDisabled}
                                            />
                                            <label className='staan-form-input-label pt-0 px-1 mx-2'>Part Name<span className='text-danger'>*</span></label>
                                            {touched.item_master_part_name && errors.item_master_part_name && <small>{errors.item_master_part_name}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='description' id="description"
                                                className='w-100 staan-form-input' />
                                            <label htmlFor="description" className='staan-form-input-label pt-0 px-1 mx-2'>Description<span className='text-danger'>*</span></label>
                                            {touched.description && errors.description && <small>{errors.description}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <CustomSelect
                                                setSelectEditValue={salesOrderItemDetailsValue?.UOM}
                                                options={salesOrderOptionsList?.uomList}
                                                name='uom'
                                                id='uom'
                                                //     handleOnChange={(option) => {
                                                //                                                    //     /* 
                                                // //         In rate convertions we want to handle 3 case
                                                // //         1. main uom to alter uom 
                                                // //         rate= Number(values?.rate) *  Number(alter_uom?.cf)
                                                // //         2. alter uom to main uom  
                                                // //             rate = Number(values?.rate) / Number(alter?.cf)
                                                // //         3. alter uom  to  alter uom 
                                                // //             rate = Number(rate) /  Number(alter_uom?.cf)
                                                // //             rate =  Number(values?.rate) * Number(alter?.cf) 
                                                // //     */
                                                // //     let rate
                                                // //     if (option.cf){ 
                                                // //         if (uomSelecte?.isMain && !option?.isMain){  
                                                // //             rate =( Number(values?.rate) * Number(option?.cf)).toFixed(2)
                                                // //         } else if(!uomSelecte?.isMain && !option?.isMain){ 
                                                // //             let preSeletce  = uomList?.find((item)=> item?.value === uomSelecte?.value)

                                                // //             rate = Number(values?.rate) / Number(preSeletce?.cf)
                                                // //             rate = (rate*Number(option?.cf)).toFixed(2)
                                                // //         }
                                                // //         setFieldValue('rate', rate);
                                                // //         const amount = Number(rate) * Number(values?.po_qty); 
                                                // //         setAmount(amount);
                                                // //         setFieldValue("uom", option? option.value : null)
                                                // //         setUomSelecte(option ? option : null)
                                                // //     } else{ 
                                                // //         if (!uomSelecte?.isMain && option?.isMain){ 
                                                // //             let preSeletce  = uomList?.find((item)=> item?.value === uomSelecte?.value)  
                                                // //             rate = (Number(values?.rate) / Number(preSeletce?.cf)).toFixed(1)
                                                // //         } 
                                                // //         setFieldValue('rate', rate); 
                                                // //         const amount = Number(rate) * Number(values?.po_qty); 
                                                // //         setAmount(amount);
                                                // //         setFieldValue("uom", option? option.value : null)
                                                // //         setUomSelecte(option ? option : null)
                                                // //     }
                                                //     }}
                                                isDisabled={salesOrderItemDetailsValue?.isDisabled}
                                            />
                                            <label className='staan-form-input-label pt-0 px-1 mx-2' >UOM<span className='text-danger'>*</span></label>
                                            {touched.uom && errors.uom && <small>{errors.uom}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='qty' id="qty" placeholder='Enter So Qty'
                                                disabled={salesOrderItemDetailsValue?.isDisabled}
                                                onChange={(e) => {
                                                    setValues(prve => ({ ...prve, qty: e?.target?.value, "amount": Number(e?.target?.value) * Number(values?.afterDiscountValueForPerItem ? values?.afterDiscountValueForPerItem : values?.rate) }))
                                                }}
                                                className='w-100 staan-form-input' />
                                            <label htmlFor="qty" className='staan-form-input-label pt-0 px-1 mx-2'>SO Qty<span className='text-danger'>*</span></label>
                                            {touched.qty && errors.qty && <small>{errors.qty}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='rate' id="rate" placeholder='Enter Rate' onChange={(e) => {

                                                setValues(prve => ({
                                                    ...prve, rate: e?.target?.value, "amount": Number(e?.target?.value) * Number(values?.qty),
                                                    afterDiscountValueForPerItem: 0,
                                                    discount: isPercentage ? `0%` : `${salesOrderValues?.currency?.currencySymbol}0`
                                                }))
                                                setDiscount(isPercentage ? `0%` : `${salesOrderValues?.currency?.currencySymbol}0`)
                                            }} className='w-100 staan-form-input' />
                                            <label htmlFor="rate" className='staan-form-input-label pt-0 px-1 mx-2'>Rate<span className='text-danger'>*</span></label>
                                            {touched.rate && errors.rate && <small>{errors.rate}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='tax' id="tax" disabled className='w-100 staan-form-input' />
                                            <label htmlFor="tax" className='staan-form-input-label pt-0 px-1 mx-2'>Tax<span className='text-danger'>*</span></label>
                                            {touched.tax && errors.tax && <small>{errors.tax}</small>}
                                        </div>
                                        <div className="col-2 staan-input-group">
                                            <Field type='text' id='discount' name='discount' disabled={values?.rate === "" ? true : values?.rate === 0 ? true : salesOrderItemDetailsValue?.isDisabled || salesOrderValues?.allowCommanDisCount} placeholder='Enter discount'
                                                value={discount} onChange={(e) => {
                                                    setDiscount(e.target.value)
                                                    let discountAmount = FinalValue(e.target.value, Number(values.rate), isPercentage ? true : false)
                                                    if (discountAmount === undefined || discountAmount === "") {
                                                        setSalesOrderItemDetailsValue(prve => ({
                                                            ...prve, amount: (values.rate * values.qty),
                                                        }))
                                                        setValues(prve => ({
                                                            ...prve,
                                                            amount: (values.rate * values.qty),
                                                            afterDiscountValueForPerItem: 0,
                                                            discount: `${e.target.value}`
                                                        }))
                                                    } else {
                                                        setValues(prve => ({
                                                            ...prve, amount: Number(discountAmount) * Number(values.qty),
                                                            afterDiscountValueForPerItem: discountAmount,
                                                            discount: e.target.value
                                                        }))
                                                        setSalesOrderItemDetailsValue(prve => ({
                                                            ...prve, amount: Number(discountAmount) * Number(values.qty),

                                                        }))
                                                    }
                                                }}
                                                className='w-100 staan-form-input' />
                                            <label htmlFor="discount" className='staan-form-input-label pt-1 px-1 mx-2'>Discount Per Qty
                                            </label>
                                            <RxCross1 className='cursor-pointer ms-2' style={{ top: "-2rem", left: "5rem", position: "relative" }}
                                                onClick={() => {
                                                    if (salesOrderValues?.allowCommanDisCount) {
                                                        setDiscount(0)
                                                        setSalesOrderItemDetailsValue(prve => ({
                                                            ...prve, amount: Number(values.rate * values.po_qty),
                                                        }))
                                                        setValues(prve => ({
                                                            ...prve, amount: Number(values.rate * values.qty),
                                                            afterDiscountValueForPerItem: 0,
                                                            discount: ""
                                                        }))
                                                    }
                                                }}
                                            />
                                            {touched.discount && errors.discount && <small>{errors.discount}</small>}
                                        </div>
                                        <div className="col-1 mt-3 text-center text-primary   " >
                                            <div hidden={salesOrderItemDetailsValue?.isDisabled || salesOrderValues?.allowCommanDisCount}>
                                                {isPercentage ? (
                                                    <AiOutlinePercentage
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setIsPercentage(false);
                                                            const newValue = `${discount?.includes("%") ? discount?.replace("%", "") : discount}`;
                                                            setDiscount(newValue !== undefined ? newValue : "");
                                                            setFieldValue('discount', newValue);
                                                            let discountAmount = FinalValue(newValue, Number(values.rate), false);
                                                            setValues(prve => ({
                                                                ...prve,
                                                                amount: Number(discountAmount) * Number(values.qty),
                                                                afterDiscountValueForPerItem: discountAmount,
                                                                discount: newValue
                                                            }));
                                                            setSalesOrderItemDetailsValue(prve => ({
                                                                ...prve,
                                                                amount: Number(discountAmount) * Number(values.qty),
                                                            }));
                                                        }}
                                                    />
                                                ) : (
                                                    <p
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setIsPercentage(true)
                                                            const newValue = `${discount?.includes(salesOrderValues?.currency?.currencySymbol) ? discount?.replace(salesOrderValues?.currency?.currencySymbol, "") : discount}`;
                                                            setDiscount(newValue !== undefined ? newValue : "");
                                                            let discountAmount = FinalValue(newValue, Number(values.rate), true);
                                                            setValues(prve => ({
                                                                ...prve,
                                                                amount: Number(discountAmount) * Number(values.qty),
                                                                afterDiscountValueForPerItem: discountAmount,
                                                                discount: newValue
                                                            }));
                                                            setSalesOrderItemDetailsValue(prve => ({
                                                                ...prve,
                                                                amount: Number(discountAmount) * Number(values.qty),
                                                            }));
                                                        }}
                                                    >
                                                        {salesOrderValues?.currency?.currencySymbol}
                                                    </p>
                                                )}
                                            </div>

                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <Field type='text' name='afterDiscountValueForPerItem' id='afterDiscountValueForPerItem' disabled placeholder='Enter discount'
                                                className='w-100 staan-form-input' />
                                            <label htmlFor="afterDiscountValueForPerItem" className='staan-form-input-label pt-1 px-1 mx-2'>Rate After Discount</label>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='amount' id='amount' placeholder='Enter Amount' disabled className='w-100 staan-form-input' />
                                            <label htmlFor="amount" className='staan-form-input-label pt-1 px-1 mx-2'>Amount<span className='text-danger'>*</span></label>
                                            {touched.amount && errors.amount && <small>{errors.amount}</small>}
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='text-end'>
                                                <button type="button" className="btn btn-outline-danger btn-sm me-2 " onClick={Close} >Cancel</button>
                                                <button type="submit" className="btn btn-sm btn-outline-success shadow-sm px-3" disabled={salesOrderItemDetailsValue?.isDisabled || isSubmitting} >Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>


                </Modal.Body>
            </Modal>

        </>
    )
}

export default SalesOrderItem