import React, { useContext, useEffect, useState } from 'react';
import { Modal, } from 'react-bootstrap';
import Tree from 'react-d3-tree';
import DataContext from '../../context/ItemMasterContext';
import "./poHistory.css";

// sample data
// const treeData = [
//     {
//         "children": [
//             {
//                 "attributes": {
//                     "department1": "Received",
//                     "department2": "GIN127",
//                     "id": "127"
//                 },
//                 "children": [
//                     {
//                         "name": "QIR",
//                         "attributes": {
//                             "department1": "Checked",
//                             "department2": "qir-23240078",
//                             "id": "78"
//                         },
//                         "children": [
//                             {
//                                 "name": "GRN",
//                                 "attributes": {
//                                     "department1": "Received",
//                                     "department2": "grn_39",
//                                     "id": "127"
//                                 }
//                             }
//                         ]
//                     }
//                 ]
//             },
//             {
//                 "attributes": {
//                     "department1": "Received",
//                     "department2": "GIN128",
//                     "id": "128"
//                 },
//                 "children": [
//                     {
//                         "name": "QIR",
//                         "attributes": {
//                             "department1": "Checked",
//                             "department2": "qir-23240079",
//                             "id": "79"
//                         },
//                         "children": [
//                             {
//                                 "name": "GRN",
//                                 "attributes": {
//                                     "department1": "Received",
//                                     "department2": "grn_40",
//                                     "id": "128"
//                                 }
//                             }
//                         ]
//                     }
//                 ]
//             }
//         ],
//         "name": "Ds-232463"
//     }
//    ];


// Diagonal, step
// vertical, horizontal
const TreeComponent = ({show,setShow, timeLineData, clickedData, currentID, modelName }) => {
  const separation = { siblings: 1.5, nonSiblings: 2 }; // Adjust these values for more/less separation
  const {screenWidth, screenHeight} = useContext(DataContext)
  // const [orientation, setOrientation] = useState('vertical');
  // const [pathFunc, setPathFunc] = useState('diagonal');

  const handleNodeClick = (nodeDatum) => {
     if (clickedData){
        clickedData(nodeDatum)
     }
  };
  const renderCustomNode = ({ nodeDatum, toggleNode }) => {
    if (!nodeDatum || nodeDatum === "") {
      return null; 
    }
    return (
      <g >
        <circle className="node-circle cursor-pointer"  id={currentID === nodeDatum?.id ? "current-node": currentID === nodeDatum?.attributes?.id ? nodeDatum?.name === modelName ? "current-node":"" : "" }  r="15" onClick={toggleNode}></circle>
        <text x="30" y="-15" className="node-text">
          {nodeDatum?.name}
        </text>
        <text x="30" y="5" className="node-text"> 
          {nodeDatum?.status}
        </text>
        <text x="30" y="25" className="node-text"  onClick={() => handleNodeClick(nodeDatum)}> 
          {nodeDatum?.serial}
        </text>
        {nodeDatum?.attributes &&
          Object.entries(nodeDatum?.attributes)?.map(([key, value], i) => (
            <text key={`${nodeDatum.name}-${key}`} x="20" y={20 + i * 15} className="node-attribute">
              {key !== "id" ? (
                <tspan id={key === "department2" ? "link" : ""}    onClick={() => { 
                    if (key === "department2") {
                      handleNodeClick(nodeDatum);
                    } else { 
                      return null; 
                    }
                  }}>
                    {`${value || ""}`}
                </tspan>
              ) : null}
            </text>
          ))
        }
      </g>
    );
  }; 
  const [dimensions, setDimensions] = useState({ width: screenWidth, height: screenHeight });
  const [translate, setTranslate] = useState({ x: 0, y: 0 });


  useEffect(() => {
    if (screenWidth) {  
     console.log(screenWidth);
      setDimensions({ width: screenWidth, height: screenHeight });
      setTranslate({ x: screenWidth / 5, y: screenHeight / 4 });
    }
  }, [screenWidth]);

  
  return (
    
    <Modal show={show} size="lg"> 
        <div className='itemMaster_Top mx-3 mt-3 d-flex justify-content-center card'   style={{ width: '98%' }}>
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-3 commanModelTitleColor"  >
                <h3 className='mx-2'>
                  <i className='fa-solid fa-arrow-left fa-sm me-2' onClick={()=>{
                      setShow(false)
                  }}></i>
                   Time Line
                </h3>  
              </div>  
            </div> 
          </div>
        <Modal.Body style={{overflowX:"hidden"}}>
        {/* <div className="col-12 d-flex justify-content-around mb-3">
          <Button variant={orientation === "vertical" ? "success" : "primary" } onClick={() => setOrientation('vertical')} className="me-2">Vertical</Button>
          <Button variant={orientation === "horizontal" ? "success" : "primary" } onClick={() => setOrientation('horizontal')} className="me-2">Horizontal</Button>
          <Button variant={pathFunc === "diagonal" ? "success" : "primary" } onClick={() => setPathFunc('diagonal')} className="me-2">Diagonal</Button>
          <Button variant={pathFunc === "step" ? "success" : "primary" } onClick={() => setPathFunc('step')}>Step</Button>
        </div> */}
        <div className='container' style={{ width: '100%', height: '100vh' }}>
            <Tree
                data={timeLineData}
                orientation={"vertical"}
                separation={separation}
                pathFunc={"diagonal"}
                renderCustomNodeElement={renderCustomNode}
                centerNodeOnClick
                enableLegacyTransitions
                initialDepth={3}
                centeringTransitionDuration={1000}
                translate={translate}
            />
        </div>
        </Modal.Body>
             
    </Modal>
    
  );
};

export default TreeComponent;
