import { Bounce, toast } from 'react-toastify';
 

const showErrorToast = (show, type, errorMessage, successMessage, position) => { 
    
    
    if (show && type === "error"){  
        toast.error(errorMessage || 'An unexpected error occurred', {
            position: position ? position : "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    } else if(show && type === "warn"){
        toast.warn(errorMessage || 'An unexpected warning occurred', {
            position: position ? position : "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
    } else if(show && type === "success"){
        toast.success( successMessage || 'Successfully', {
            position: position ? position : "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
    }
}

export default showErrorToast;