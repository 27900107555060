import React, { createContext, useContext, useEffect, useState } from 'react';
import axiosInstance from '../api/axoiss';
import {
    ItemCategoryOptionQuery, ItemGroupOptionQueryWithFiliter, ItemIndicatiorOptionQuery,
    ItemTypeOptionQuery, UOMOptionQuery
} from '../GraphQLQueries/ItemmasterQuery';
import DataContext from './ItemMasterContext';


const PreFetchContext = createContext({});

export const PreFetchDataProvider =({children})=>{
    const {permissionModelList} = useContext(DataContext)
    const [prefetechQuery, setPrefetechQuery]= useState([])
    const [preFetchData,setPreFetchData]= useState({categories:[],itemGroups:[], itemIndicators:[], itemTypes:[],
        uom:[]
    })
    useEffect(() => {
        setPrefetechQuery((prev) => {
            if (permissionModelList?.length > 0) {
                let modelsList = permissionModelList.map((item) => item?.modelName);
    
                // Initialize an updated list with previous queries
                let updatedQueries = [...prev];
    
                if (modelsList.includes("Item_Master")) {
                    updatedQueries.push(
                        ItemTypeOptionQuery("data"),
                        ItemIndicatiorOptionQuery("data"),
                        UOMOptionQuery(null,"data"),
                        ItemCategoryOptionQuery("data"),
                        ItemGroupOptionQueryWithFiliter("data")
                    );
                }
                if (modelsList.includes("Lead")) {
                    updatedQueries.push(ItemTypeOptionQuery("data"));
                }
    
                // Remove duplicates using Set
                const uniqueQueries = [...new Set(updatedQueries)];
                return uniqueQueries;
            }
    
            // If permissionModelList is empty, return the previous state
            return prev;
        });
    }, [permissionModelList]);
    
    useEffect(()=>{
        async function preData(params) {
            try{
                let responce = await axiosInstance.post(`/itemmaster/graphql`, { query: params })
                let responceData = responce?.data?.data
                setPreFetchData({
                    categories:responceData?.categories?.items?.map((categorie)=>({
                    value:categorie?.id, label:categorie?.name
                })),
                itemGroups:responceData?.itemGroupsName?.items?.map((itemGroup)=>({
                    value:itemGroup?.id, label:itemGroup?.name
                })),
                itemIndicators:responceData?.itemIndicators?.items?.map((Indicator)=>({
                    value:Indicator?.id, label:Indicator?.name
                })), itemTypes:responceData?.itemType?.items?.map((itemType)=>({
                    value:itemType?.id, label:itemType?.name
                })),
                    uom:responceData?.uom?.items?.map((UOM)=>({
                        value:UOM?.id, label:UOM?.name
                    }))})

            } catch(error){
                // alert(error)
            }
            
        }
        let query = " query MyQuery {"
        if (prefetechQuery?.length > 0){
            prefetechQuery.forEach(query_ => {
                query += query_
            });
            query += `}`
            
        }
        if (query !== "query MyQuery {" ){
            preData(query)
        }
        
    },[prefetechQuery])



    return (
        <PreFetchContext.Provider value={{
            
            preFetchData, setPreFetchData}}>
            {children}
        </PreFetchContext.Provider>
    )
}
export default PreFetchContext;