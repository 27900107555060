import React from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import ReportTableView from '../../Packages/StaanReportTable/DataTable'
import { ToastContainer } from 'react-toastify'

const SalesOrderReports = () => {
    const headers = [
        {
            header: 'Created Date',
            field: 'CreatedAt',
            flex: 8,
            inputType: 'Date'
        },
        {
            header: 'Sales Order Date',
            field: 'sales_order_date',
            flex: 8,
            isDate: true,
            inputType: 'Date'
        },
        {
            header: 'Due Date',
            field: 'due_date',
            flex: 8,
            isDate: true,
            inputType: 'Date'
        },
        {
            header: 'Credit Period',
            field: 'credit_period',
            filter: "credit_period__icontains",
            flex: 10,
            inputType: "number"
        },
        {
            header: 'Customer PO No',
            field: 'customer_po_no',
            filter: "customer_po_no__icontains",
            flex: 10,
            inputType: "text"
        },
        {
            header: 'Customer PO Date',
            field: 'customer_po_date',
            flex: 8,
            inputType: 'Date'
        },
        {
            header: 'Department',
            field: 'department__name',
            filter: "department__name__icontains",
            flex: 8,
            inputType: 'text',
            distinct: true,
        },
        {
            header: 'Sales Order No',
            field: 'sales_order_no__linked_model_id',
            filter: "sales_order_no__linked_model_id__icontains",
            flex: 10,
            inputType: "text"
        },
        {
            header: 'Sales person',
            field: 'sales_person__username',
            filter: "sales_person__username__icontains",
            flex: 10,
            inputType: "text",
            distinct: true,
        },
        {
            header: 'GST TYPE',
            field: 'buyer_gstin_type',
            filter: "buyer_gstin_type__icontains",
            flex: 10,
            inputType: "text",
            distinct: true,
        },

        {
            header: 'Active',
            field: 'active',
            filter: "active",
            flex: 10,
            option: true,
            inputType: 'Bool'
        },
        {
            header: 'Status',
            inputType: "text",
            field: 'status__name',
            filter: "status__name__icontains",
            flex: 10,
            distinct: true,
        },

    ]

    return (
        <div className='container-fluid index_page_aliment_warp'>
            <div className='index_page_aliment_sidebar'>
                <SideNavbar />
            </div>
             <ToastContainer />
            <div className='index_page_aliment_table'>
                <ReportTableView
                    headers={headers}
                    url={"/itemmaster/common_table_data_fetch"}
                    titleName={'SalesOrderReport'}
                    status={"status__name"}
                    permission={'Save_Department'}
                />
            </div>
        </div>
    )
}

export default SalesOrderReports