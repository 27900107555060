import Modal from 'react-bootstrap/Modal'; 
import {Formik, Form, Field } from 'formik';
import React, { useState, useEffect} from 'react' 
import Select from 'react-select';
import { raw_materials_schema } from '../../../validations/bom';
import { customSelectStyle } from '../../../utils/Utils';
import axiosInstance from '../../../api/axoiss';
import { useSelector, useDispatch } from 'react-redux';
import { fetchItemMasterData, clearItemMasterData} from "../../../ReduxState/Action/itemMasterAction";
import { ItemMasterProductsFetchQuery } from '../../../GraphQLQueries/ItemmasterQuery'
import { fetchStoreData } from '../../../ReduxState/Action/storeActions';
import { storeFetchQuery } from '../../../GraphQLQueries/ItemmasterQuery'
import { createRmItem } from '../../../GraphQLQueries/BomMutation';
import {ToastContainer, toast} from 'react-toastify';


const AddRawMaterials = ({data, isShowAddRawMaterials, handleCloseRawMaterials, handleAddedData, existingData, fgItems}) => {
    const dispatch = useDispatch(); 
    const itemMasterDataList = useSelector(state => state.itemMasterData.data);
    const storeDataList = useSelector(state => state.storeData.data);
    const [uomSelectOptions, setUomSelectOptions] = useState([]);
    const [selectpartCode, setSelectpartCode] = useState(null);
    const [selectpartName, setSelectpartName] = useState(null);
    const [qty, setQty] = useState(0);
    const [selectUom , setSelectUom] = useState(null);
    const [selectStore, setSelectStore] = useState(null);
    const [selectCategory, setSelectCategory] = useState('');
    const [isQtyFixed, setQtyFixed] = useState(true);
    const [itemCost, setItemCost] = useState(0);
    const [itemCostByQty, setItemCostByQty] = useState(0);
    const [rawQty, setRawQty] = useState(0);

    let initialValue = {
        part_code: '',
        part_name: '',
        category: '',
        qty: 0,
        rawQty: 0,
        uom: '',
        store: '',
    }

    const checkIfItemAlreadyExists = (option) => {
        let is_exists = existingData?.find(item => item?.partNo?.id === option?.value)
        if(is_exists){
            toast.error('This Item already Exists!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                // transition: Bounce,
            });
        }
        return is_exists ? true : false;
    }

    const handleSelectpartCode = (option)=>{ 
        let exists_already = checkIfItemAlreadyExists(option)
        if(!exists_already){
            let item_master_data = itemMasterDataList?.items?.find(item => item.id === option?.value);
            setItemCost(item_master_data?.itemCost)
            setSelectpartCode({ value: option?.value, label:  option?.label })
        }
        return exists_already;
    }

    const handleSelectpartName = (option)=>{ 
        let exists_already = checkIfItemAlreadyExists(option)
        if(!exists_already){
            let item_master_data = itemMasterDataList?.items?.find(item => item.id === option?.value);
            setItemCost(item_master_data?.itemCost)
            setSelectpartName({ value: option?.value , label:  option?.label })
        }
        return exists_already;
    }

    const handleSelectQty = (value)=>{
        setItemCostByQty(itemCost*value)
        // setQty(value)
        setRawQty(value)
    }

    const handleSelectUom = (option)=>{ 
        setSelectUom({ value: option?.value , label:  option?.label })
    }

    const handleSelectStore = (option)=>{ 
        setSelectStore({ value: option?.value , label:  option?.label })
    }

    const handleSelectCategory = (value)=>{ 
        setSelectCategory(value)
    }

    const get_uom_options = (option) => {
        let item_master_data = itemMasterDataList?.items?.find(item => item.id === option?.value)
        let uom_options = []
        uom_options.push({'label': item_master_data?.itemUom?.name, 'value': item_master_data?.itemUom?.id})
        if(item_master_data?.alternateUom){
            item_master_data?.alternateUom?.forEach(element => {
                uom_options.push({'label': element?.addtionalUnit?.name, 'value': element?.addtionalUnit?.id})
            });
        }
        setUomSelectOptions(uom_options)
    }

    const handleCategoryValue = (option) => {
        let item_master_data = itemMasterDataList?.items?.find(item => item.id === option?.value)
        setSelectCategory(item_master_data?.category?.name)
    }

    const clearInputDatas = () =>{
        setSelectpartCode(null) 
        setSelectpartName(null)
        setQty('')
        setRawQty('')
        setSelectUom(null)
        setSelectStore(null)
        setSelectCategory('')
    }

    const handleSubmit = (values, { setSubmitting, resetForm, setErrors, setFieldError }) => {
        let updated_qty = parseFloat(fgItems?.qty || 1) * parseFloat(rawQty)
        let form_data = {
            SerialNo: 1,
            part_code: selectpartCode,
            part_name: selectpartName,
            category: selectCategory,
            qty: updated_qty,
            raw_qty: rawQty,
            uom: selectUom,
            store: selectStore,
            fixed: isQtyFixed ? 'Yes' : 'No',
            item_cost: itemCost,
        }
        let submit_data = {
            serialNumber: 1,
            qty: updated_qty,
            rawQty: rawQty,
            partNo: selectpartCode?.value,
            unit: selectUom?.value,
            fixed: isQtyFixed,
            itemCost: itemCost,
        }
        if(data){
            submit_data['id'] = data.id
            form_data['id'] = data.id
        }
        if(selectStore && selectStore.value && selectStore.value !== ''){
            submit_data['store'] = selectStore.value
        }
        if(selectCategory && selectCategory !== ''){
            submit_data['category'] = selectCategory
        }
        let mutation_query = createRmItem(submit_data);
        axiosInstance.post("/itemmaster/graphql", {query: mutation_query})
        .then(response => {
            const keys = Object.keys(response.data.data);
            const firstKey = keys.length > 0 ? keys[0] : null;
            const data = response.data.data[firstKey]?.rawMaterials?.id
            form_data['id'] = data
            handleAddedData(form_data);
            clearInputDatas();
            handleCloseRawMaterials();
            setSubmitting(false);
        })
        .catch(error => {
          console.log(error)
        });
    }

    const handleCloseModal = () => {
        dispatch(clearItemMasterData())
        clearInputDatas();
        handleCloseRawMaterials();
    };
    
    const handleOnInputChangeTypeSearch = (value, field) => {
        if(value.trim() !== ''){
            let filter_obj = {
                key: field,
                value: value
            }
            dispatch(fetchItemMasterData(ItemMasterProductsFetchQuery('rm', filter_obj)));
        }
        else{
            dispatch(fetchItemMasterData(ItemMasterProductsFetchQuery('rm')))
        }
    }

    useEffect(()=>{
        dispatch(fetchStoreData(storeFetchQuery()));
        if(data){
            setQty(data['qty'])
            setRawQty(data['rawQty'])
            setSelectpartCode(data['part_code'])
            setSelectpartName(data['part_name'])
            setSelectCategory(data['category'])
            setSelectStore(data['store'])
            setSelectUom({label: data['uom']['label'], value: data['uom']['value']})
            setQtyFixed(data['fixed'] === 'No' ? false : true)
            setItemCostByQty(data['item_cost'])
            setItemCost(data['per_unit_cost'])
            let uom_options = [];
            uom_options.push({label: data['uom']['label'], value: data['uom']['value']})
            if(data?.partNo?.alternateUom){
                data?.partNo?.alternateUom?.forEach(element => {
                    uom_options.push({'label': element?.addtionalUnit?.name, 'value': element?.addtionalUnit?.id})
                });
            }
            let uom_options_unique = uom_options.filter((obj, index, self) =>
                index === self.findIndex((t) => (
                    t.value === obj.value
                ))
            );
            setUomSelectOptions(uom_options_unique);
        }
    }, [data, dispatch])

    const mapDataToInitialValues = (data) => {
        let mappedData = {...data}
        mappedData['part_code'] = data['part_code']?.value
        mappedData['part_name'] = data['part_name']?.value
        mappedData['uom'] = data['uom']?.value
        mappedData['store'] = data['store']?.value
        return mappedData;
    };

    return(
        <>
             <Modal show={isShowAddRawMaterials} onHide={()=>{clearInputDatas(); handleCloseModal();}}  size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Raw Materials</Modal.Title>
                </Modal.Header>
                <Modal.Body>  
                    <Formik 
                        initialValues = {data? mapDataToInitialValues(data): initialValue}
                        validationSchema={raw_materials_schema}
                        onSubmit={(values, formikProps) => handleSubmit(values, formikProps)}
                        >
                        {({errors, submitForm, touched, setFieldValue })=>(
                            <>
                                <Form style={{width: "100%", fontSize: '.8rem' }}>
                                    <div className='row mx-auto py-3'>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemPartCode')}}
                                                options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemPartCode, name:item.itemName  }))}
                                                isSearchable={true}
                                                value={selectpartCode}
                                                isClearable
                                                onChange={(option) => {
                                                    setItemCost(0)
                                                    setFieldValue('part_code', option ? option.value : null);
                                                    setFieldValue('part_name', option ? option.value : null);
                                                    setSelectUom('')
                                                    setSelectStore('')
                                                    setQty('')
                                                    setRawQty('')
                                                    let is_exists = handleSelectpartCode(option)
                                                    if(!is_exists){
                                                        get_uom_options(option)
                                                        handleCategoryValue(option)
                                                        setSelectpartName({ value: option?.value , label:  option?.name })
                                                    }
                                                }} 
                                                className='mt-3'
                                                styles={customSelectStyle}
                                                name='part_code' style={{fontSize: '.7rem'}} 
                                            />
                                            <label htmlFor="part_code" className='staan-form-input-label pt-1 px-1 mx-2'>Part Code</label>
                                            <p>{  touched.part_code &&  errors.part_code && <small>{errors.part_code}</small>}</p>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemName')}}
                                                isClearable
                                                options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemName , partCode: item.itemPartCode}))}
                                                isSearchable={true}
                                                value={selectpartName}
                                                onChange={(option) => {
                                                    setItemCost(0)
                                                    setFieldValue('part_name', option ? option.value : null);
                                                    setFieldValue('part_code', option ? option.value : null)
                                                    setSelectUom('')
                                                    setSelectStore('')
                                                    setSelectCategory('')
                                                    setQty('')
                                                    setRawQty('')
                                                    let is_exists = handleSelectpartName(option) 
                                                    if(!is_exists){
                                                        get_uom_options(option)
                                                        handleCategoryValue(option)
                                                        setSelectpartCode({ value: option?.value, label:  option?.partCode })
                                                    }
                                                }}
                                                name='part_name' style={{fontSize: '.7rem'}} 
                                                className='mt-3'
                                                styles={customSelectStyle}
                                            />
                                            <label htmlFor="part_name" className='staan-form-input-label pt-1 px-1 mx-2'>Part Name</label>
                                            <p>{  touched.part_name &&  errors.part_name && <small>{errors.part_name}</small>}</p>
                                        </div>
                                    </div>
                                    <div className='row mx-auto my-3'>
                                        <div className="col-6  staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                disabled
                                                value={selectCategory}
                                                onChange={(e)=>{
                                                    setFieldValue('category', e.target.value);
                                                    handleSelectCategory(e.target.value)
                                                }}
                                                name='category' style={{fontSize: '.7rem'}} 
                                            />    
                                            <label htmlFor="category" className='staan-form-input-label pt-2 px-1 mx-2'>Category</label>                                        
                                            <p>{  touched.category &&  errors.category && <small>{errors.category}</small>}</p>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                onChange={(e)=>{
                                                    handleSelectQty(e.target.value); 
                                                    setFieldValue('raw_qty', e.target.value);
                                                }}
                                                value={rawQty}
                                                name='raw_qty' style={{fontSize: '.7rem'}} 
                                                // disabled = {data }
                                            />   
                                            <label htmlFor="raw_qty" className='staan-form-input-label pt-2 px-1 mx-2'>Quantity</label>                                         
                                            <p>{  touched.raw_qty &&  errors.raw_qty && <small>{errors.raw_qty}</small>}</p>
                                        </div>
                                    </div>
                                    <div className='row mx-auto my-4'>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                isClearable
                                                options={uomSelectOptions}
                                                onChange={(option)=>{
                                                    setFieldValue('uom', option ? option.value : null)
                                                    handleSelectUom({ value: option?.value , label:  option?.label})
                                                }}
                                                value={selectUom}
                                                name='uom' style={{fontSize: '.7rem'}} 
                                                className='mt-3'
                                                styles={customSelectStyle}
                                            />        
                                            <label htmlFor="uom" className='staan-form-input-label pt-2 px-1 mx-2'>Unit</label>                                    
                                            <p>{  touched.uom &&  errors.uom && <small>{errors.uom}</small>}</p>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                isClearable
                                                options={storeDataList?.items?.map((item) => ({ value: item.id, label: item.storeName  }))}
                                                onChange={(option)=>{
                                                    setFieldValue('store', option ? option.value : null)
                                                    handleSelectStore({ value: option?.value , label:  option?.label})
                                                }}
                                                value={selectStore}
                                                name='store' style={{fontSize: '.7rem'}}
                                                className='mt-3'
                                                styles={customSelectStyle} 
                                            />
                                            <label htmlFor="store" className='staan-form-input-label pt-1 px-1 mx-2'>Store</label>
                                            <p>{  touched.store &&  errors.store && <small>{errors.store}</small>}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mx-2'>
                                        <div className='row d-flex align-items-center' style={{width: '50%'}}>
                                            <div className='col-6'>
                                                <label>
                                                    <Field type="checkbox" name="fixed" 
                                                        className="me-2" onChange={(e)=>{setQtyFixed(!isQtyFixed)}}
                                                        checked = {isQtyFixed}
                                                        // disabled = {data}
                                                    />
                                                    Fixed
                                                </label>
                                            </div>
                                            <div className='col-6 staan-input-group'>
                                                <input type='text' className='w-100 staan-form-input' 
                                                    onChange={(e)=>{
                                                        setFieldValue('itemCost', e.target.value);
                                                    }}
                                                    value={itemCostByQty}
                                                    name='item_cost' style={{fontSize: '.7rem'}} 
                                                    disabled = {true}
                                                />   
                                                <label htmlFor="item_cost" className='staan-form-input-label pt-2 px-1 mx-2'>Total Cost</label>                                         
                                                <p>{  touched.item_cost &&  errors.item_cost && <small>{errors.item_cost}</small>}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <button className='btn btn-sm btn-outline-success px-3'>Save</button>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </>
    )
}


export default AddRawMaterials;