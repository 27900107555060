import { useEffect } from "react";

const useInternetChecker = (onError) => {
    useEffect(() => {
        const handleOffline = () => {
        console.error("No internet connection");
        if (onError) onError("No internet connection");
        };

        const handleOnline = () => {
        console.log("Internet connection restored");
        };

        window.addEventListener("offline", handleOffline);
        window.addEventListener("online", handleOnline);

        return () => {
        window.removeEventListener("offline", handleOffline);
        window.removeEventListener("online", handleOnline);
        };
    }, [onError]);
};

export default useInternetChecker;
