import React, { useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable'
import ExpenseCategoriesModal from './models/ExpenseCategoriesModal'

const ExpenseCategories = () => {
    const [isCategoriesShow, setCategoriesShow] = useState(false)
    const [categoriesEditData, setCategoriesEditData] = useState('')
    const [isLoad, setLoad] = useState(false)

    const CustomViewModalComponent = data => {
        setCategoriesShow(true)
        setCategoriesEditData(data)
    }
    const headers = [
        {
            header: 'Expense Category Name',
            field: 'expenseCategoryName',
            flex: 10,
            align: 'center',
            renderComponent: CustomViewModalComponent,
        },
        {
            header: 'Account Name',
            field: 'accountName',
            label: 'accountsName',
            flex: 10,
            align: 'center',
        },
        {
            header: 'Active',
            field: 'active',
            flex: 10,
            option: true,
            bool: true,
            inputType: 'text',
            align: 'center',
        },
    ]
    const CustomAddModalComponent = () => {
        setCategoriesShow(true)
    }

    return (
        <>
            <SideNavbar />
                <div id='custom-table-itemmaster'>
                    <TableView
                        headers={headers}
                        query={"ExpenseCategories"}
                        addComponent={CustomAddModalComponent}
                        loadComponent={isLoad}
                        titleName={"Expense Categories"}
                        heightAdjust={"40rem"}
                        permission={"View_Enquiry"}
                        customFilter={true}
                    />
                </div>
            {
                isCategoriesShow &&
                <ExpenseCategoriesModal
                    isCategoriesShow={isCategoriesShow}
                    setCategoriesShow={setCategoriesShow}
                    categoriesEditData={categoriesEditData} 
                    setCategoriesEditData={setCategoriesEditData}
                    setLoad = {()=>{setLoad(!isLoad)}}
                />
            }
        </>
    )
}


export default ExpenseCategories
