import { S3 } from 'aws-sdk';


const S3_BUCKET ='staandoc/';
const REGION ='ap-south-1';
const ACCESS_KEY ='AKIATFIDGZEGFJ6PCEUC';
const SECRET_ACCESS_KEY ='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';

const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}

export const handleFileUploadToS3 = (file, folder) => {
    const s3 = new S3({
        region: config.region,
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey
    });

    const params = {
        Bucket: `${config.bucketName}${folder}`,
        Key: file.name,
        Body: file,
        ACL: 'public-read', // Set the appropriate ACL
    };

    return new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
            if (err) {
                // console.error('Error uploading file:', err);
                reject(err);
            } else {
                console.log('File uploaded successfully:', data);
                resolve(data.Location);
            }
        });
    });
}
