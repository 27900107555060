export const roleMutations = (data) => {
// createdBy: 10 descriptions: "" modifiedBy: 10 reportTo: 10
// roleName: "" shareDataWith: 10
  return `
  mutation MyMutation {
  roleCreateMutation(${data}) {
    errors
    success
  }
}
  `
}
 

export const roleDeleteMutations = (id) => {
  return `
    mutation MyMutation {
  roleDeletemutation(id: ${id}) {
    errors
    success
  }
}
  `
}

export const profileDeleteMutation = (id) => {
  return `
    mutation MyMutation2 {
  profileDeleteMutation(id: ${id}) {
    errors
    success
  }
}
  `
}
