import React, { useContext, useState } from 'react';
 
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import DataContext from '../../context/ItemMasterContext';
import TableView from '../../Packages/Custom/DataTable';
import AddHsn from '../../Page/ItemMaster/model/AddHsn';

const Hsn = () => {
  const { handlHSNShow, screenSize, ItemHSNAdd} = useContext(DataContext)
  const [isLoad, setLoad] = useState(false)
  const [HSNedit, setHsnEdit] = useState('')
  const CustomViewModalComponent =(data)=>{
    setHsnEdit(data)
    handlHSNShow()
  }
  
  const headers = [
    {
      header: 'Hsn Code',
      field: 'hsnCode',
      label : "hsnCode",
      flex: 10,
      // query: 'HsnOptionQueryForTable',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Type',
      field: 'hsnTypes',
      label : "name",
      flex: 10,
      inputType: 'text',
    },
    {
      header: 'GST',
      field: 'gstRates',
      label : "rate",
      flex: 10,
      option: true,
      query: 'gstRateOptionQueryWithFiliter',
      inputType: 'text',
    },
    {
      header: 'CessRate',
      field: 'cessRate',
      flex: 10,
      inputType: 'text',
    },
    {
      header: 'Rcm',
      field: 'rcm',
      flex: 10,
      option: true,
      bool: true,
      inputType: 'text',
    },
    {
      header: 'Itc',
      field: 'itc',
      flex: 10,
      option: true,
      bool: true,
      inputType: 'text',
    },
  ]
  const CustomAddModalComponent = () => {
    handlHSNShow()
  }

  return (
    <>
    <div className='container-fluid index_page_aliment_warp'>
      <div className='index_page_aliment_sidebar'>
        <SideNavbar />
      </div>
      <div className='index_page_aliment_table'>
        <TableView
          headers={headers}
          query = {'HsnTableQuery'}
          addComponent = {CustomAddModalComponent}
          loadComponent = {isLoad}
          titleName = {'HSN'}
          permission={"Save_HSN"}
          heightAdjust = {`${screenSize?.height / 20}rem`}
          customFilter={true}
          />
      </div>
    </div>
   {ItemHSNAdd &&
   <AddHsn 
   HSNedit={HSNedit}
   setHsnEdit= {setHsnEdit}
   setLoad = {()=>{setLoad(!isLoad)}}/>
   }
    
    </>
  )
}

export default Hsn