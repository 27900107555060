import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Modal, Table } from 'react-bootstrap';
import './Taxmodal.css';
const TaxDetailsModal = ({ showTaxModel, setShowTaxModel, taxData, setTaxData }) => {
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const handleClose = () => setShowTaxModel();
    // function to handle drag end 
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedData = Array.from(taxData);
        const [movedRow] = reorderedData.splice(result.source.index, 1);
        reorderedData.splice(result.destination.index, 0, movedRow);
        setTaxData(reorderedData);
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedData = [...taxData].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setTaxData(sortedData);
    };

    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc' ? '▲' : '▼';
        }
        return '';
    };

    return (
        <Modal show={showTaxModel} onHide={handleClose} size="lg" style={{ marginTop: '20px' }}>
            <Modal.Header closeButton>
                <Modal.Title>HSN Break Down</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="taxTable">
                        {(provided) => (
                            <Table bordered hover {...provided.droppableProps} ref={provided.innerRef}>
                                <thead className="table-header">
                                    <tr>
                                        <th>S.No</th>
                                        <th onClick={() => handleSort('hsn')}>
                                            HSN/SAC
                                            <span className="sort-indicator">{getSortIndicator('hsn')}</span>
                                        </th>
                                        <th onClick={() => handleSort('taxableValue')}>
                                            Taxable Value
                                            <span className="sort-indicator">{getSortIndicator('taxableValue')}</span>
                                        </th>
                                        <th onClick={() => handleSort('cgst.percent')}>
                                            CGST (%)
                                            <span className="sort-indicator">{getSortIndicator('cgst.percent')}</span>
                                        </th>
                                        <th onClick={() => handleSort('cgst.amount')}>
                                            CGST Amount
                                            <span className="sort-indicator">{getSortIndicator('cgst.amount')}</span>
                                        </th>
                                        <th onClick={() => handleSort('sgst.percent')}>
                                            SGST (%)
                                            <span className="sort-indicator">{getSortIndicator('sgst.percent')}</span>
                                        </th>
                                        <th onClick={() => handleSort('sgst.amount')}>
                                            SGST Amount
                                            <span className="sort-indicator">{getSortIndicator('sgst.amount')}</span>
                                        </th>
                                        <th onClick={() => handleSort('igst.percent')}>
                                            IGST (%)
                                            <span className="sort-indicator">{getSortIndicator('igst.percent')}</span>
                                        </th>
                                        <th onClick={() => handleSort('igst.amount')}>
                                            IGST Amount
                                            <span className="sort-indicator">{getSortIndicator('igst.amount')}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {taxData?.map((data, index) => (
                                        <Draggable key={index} draggableId={`row-${index}`} index={index}>
                                            {(provided, snapshot) => (
                                                <tr
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    className={`draggable-row ${snapshot.isDragging ? 'dragging' : ''}`}
                                                >
                                                    {/* Drag handle is applied only to the S.No column */}
                                                    <td
                                                        {...provided.dragHandleProps} // Only this column will act as the drag handle
                                                        className="drag-handle"
                                                        style={{ cursor: 'grab' }}
                                                    >
                                                        {index + 1}
                                                    </td>
                                                    <td>{data.hsn}</td>
                                                    <td>{data.taxableValue.toFixed(2) === "0.00" ? "-" : data.taxableValue.toFixed(2)}</td>
                                                    <td>{data.cgst.percent.toFixed(2) === "0.00" ? "-" : data.cgst.percent.toFixed(2)}</td>
                                                    <td>{data.cgst.amount.toFixed(2) === "0.00" ? "-" : data.cgst.amount.toFixed(2)}</td>
                                                    <td>{data.sgst.percent.toFixed(2) === "0.00" ? "-" : data.sgst.percent.toFixed(2)}</td>
                                                    <td>{data.sgst.amount.toFixed(2) === "0.00" ? "-" : data.sgst.amount.toFixed(2)}</td>
                                                    <td>{data.igst.percent.toFixed(2) === "0.00" ? "-" : data.igst.percent.toFixed(2)}</td>
                                                    <td>{data.igst.amount.toFixed(2) === "0.00" ? "-" : data.igst.amount.toFixed(2)}</td>
                                                </tr>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </tbody>

                            </Table>
                        )}
                    </Droppable>
                </DragDropContext>
            </Modal.Body>
        </Modal>
    );
};

export default TaxDetailsModal;
