import { Field, Form } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiOutlinePercentage } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import Select from 'react-select';
import WaringModel from '../../../components/Warings/WaringModel';
import DataContext from '../../../context/ItemMasterContext';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { OnchangeGetData, customSelectStyle } from '../../../utils/Utils';
import { PurchaseSchema } from '../../../validations/itemmaster';
import SupplierFrom from './SupplierFrom';


const PurchaseModal = ({touched, errors, handlePurchaseClose ,setFieldValue, Purchase, dupData, values, setSelectedItem_Indicator, purchaseUomOptions,
  selectedPurchaseuom,setSelectedPurchaseuom, setType
}) => {
  
 const {  setAccountGroupType,handlAccountShow,   handleSupplierShow,  supplierSelect }
  =  useContext(DataContext)
  const [uomOptions, setUomOptions] = useState([])  
  const [purchaseAccountdata, setpurchaseAccountdata] = useState([])
  const AccountRef = useRef(null)
  const SupplierRef = useRef(null)
  const [selectedAccount_, setSelectedAccount] = useState({value: null,label: null});
  const [seletedSupplier_ , setSeletedSupplier] = useState([])
  
  const [supplierOption, setSupplierOption] = useState([])
  const [variation, setvariation] = useState("0%")
  const [isPercentage, setIsPercentage] = useState(true)
  const [errorsShow, setErrorsShow] = useState()
  const [waringModel, setWaringModel] = useState(false)
 
  // retun null ; on change Account
  const handleSelectAccount = (option) => { 
    if (option){
      setSelectedAccount( { value: option.value , label:  option.label });
    } else{
      setSelectedAccount( { value: "" , label:  "" });
    }
    
   };
   // retun null ; on change Supplier
   const handleSelectSupplier = (options) => {
    if (options) {
      const selectedOptions = options.map(option => ({ value: option.value, label: option.label }));
      setSeletedSupplier(selectedOptions);
    } else {
      setSeletedSupplier([]);
    }
  }
 
  useEffect(() => {    
     if (dupData?.['purchaseUom']){
      setFieldValue('purchaseUom', dupData['purchaseUom']?.['id'])
      setSelectedPurchaseuom({ value: dupData['purchaseUom']?.['id'], label: dupData['purchaseUom']?.['name'] })
     }  
     if (dupData?.['itemPurchaseAccount']){
      
      setSelectedAccount({ value: dupData['itemPurchaseAccount']?.['id'], label: dupData['itemPurchaseAccount']?.['accountsName'] })
      setFieldValue('itemPurchaseAccount', dupData['itemPurchaseAccount']?.['id'])
     } 
     if (dupData?.['itemCost']){
      setFieldValue("Item_Cost", dupData['itemCost'])
     } 
     if (dupData?.['variation']){
      setvariation(dupData?.['variation'])
      setFieldValue("variation", dupData?.['variation'])
     }
  }, [dupData]);
  
  // retun null ; getting data for react select Option
  async function getOptions(e, queryName, optionName){ 
    setSupplierOption([])
    let search_term = e.trim().replace(/"/g, '\\"');
    if(queryName === "UOMOptionQuery" && search_term){
      let responseData =  await  OnchangeGetData(QueryData[queryName](search_term))
      if(responseData.success){ 
        setUomOptions(responseData.data[optionName]['items'])
      }
    } else{
      setUomOptions([])
    }
    if(queryName === "accountsMasterOptionQueryWithFiliter" && search_term){
      let responseData =  await  OnchangeGetData(QueryData[queryName](search_term,"Liabilities","Expenses"))
      if(responseData.success){ 
        setpurchaseAccountdata(responseData.data[optionName]['items'])
      } 
    } else{
      setpurchaseAccountdata([])
    }
    if(queryName === "onlyForSupplierOption" && search_term){
      let responseData =  await  OnchangeGetData(QueryData[queryName](search_term))
      if(responseData.success){  
        setSupplierOption(responseData.data[optionName]['items'])
      }  
    }
  
  }
  
  useEffect(()=>{
       if (supplierSelect){
        setSeletedSupplier( [{ value: supplierSelect.id , label:  supplierSelect.label }]);
       }
  },[supplierSelect])

  function onCloseClearValue(params) {
    values.Item_Cost = 0
    values.Purchase_uom = null
    values.variation = ""
    setvariation()
    setFieldValue('supplierData', "");
    handleSelectSupplier("");
    handleSelectAccount()
    setErrorsShow("")
    handlePurchaseClose() 
    setSelectedItem_Indicator({ value: "", label:  "" })
    setType("")
  }
   
  return (
    <>
      <Form>
        <Modal show={Purchase} onHide={()=>{
            setWaringModel(true)
          }}
          size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
            <div className="d-flex justify-content-center">
                <span className='text-danger'>{errorsShow}</span>
            </div>
            <div className='row'>
                  <div className="col-3 staan-input-group">
                    <Field type='text' name='Item_Cost' className='w-100 staan-form-input '/>
                    <label htmlFor="Item_Cost" className='staan-form-input-label pt-2 px-1 mx-2'>Item Cost </label>
                    {  touched.Item_Cost &&  errors.Item_Cost && <small>{errors.Item_Cost}</small>}
                  </div>
                  <div className="col-2 staan-input-group">
                    <Field type='text' name='variation' value={variation} onChange={(e)=>{ setvariation(e.target.value)
                      setFieldValue('variation', e.target.value)}} className='w-100 staan-form-input '/>
                    <label htmlFor="variation" className='staan-form-input-label pt-2 px-1 mx-2' onClick={()=>{console.log(variation);}}>variation </label>
                    {touched.variation &&  errors.variation && <small>{errors.variation}</small>}
                  </div> 
                  <div className="col-1 mt-4 text-center">
                    {isPercentage ?  
                         <BsCurrencyRupee onClick={() => {
                          setIsPercentage(false);
                          const newValue = `₹${variation?.replace("%", "")}`; 
                          setvariation(newValue !== undefined ?  newValue : "");
                          setFieldValue('variation', newValue);
                        }}/>
                        :
                        <AiOutlinePercentage onClick={() => {
                          setIsPercentage(true);
                          const newValue = `${variation?.replace("₹", "")}%`;
                          setvariation(newValue !== undefined ?  newValue : "");
                          setFieldValue('variation', newValue);
                        }}/>
                    }
                  </div>
                  <div className="col-6 staan-input-group">
                      <Select
                        name="Purchase_uom" 
                        options={purchaseUomOptions?.length > 0? purchaseUomOptions : [] }
                        isSearchable={true}
                        isClearable
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            fontSize: '.8rem'
                          }),
                        }}
                        className='my-3'
                        value={selectedPurchaseuom}
                        onChange={(option) => {
                        if (option){
                          setSelectedPurchaseuom({value: option.value , label: option.label})
                          setFieldValue('Purchase_uom', option ? option.value : "")
                        } else{
                          setSelectedPurchaseuom({value: "" , label: ""})
                          setFieldValue('Purchase_uom', option ? option.value : "")
                        }
                      }}
                    />
                      <label htmlFor="Purchase_uom" className='staan-form-input-label pt-1 px-1 mx-2' onClick={()=>{console.log(purchaseUomOptions?.length);
                      }}>Purchase UOM</label>
                      {  touched.Purchase_uom &&  errors.Purchase_uom && <small>{errors.Purchase_uom}</small>}
                  </div>
            </div>
            <div className="row mt-2"> 
              <div className="col-6 staan-input-group"> 
                <Select
                    options={purchaseAccountdata.map((item) => ({ value: item.id, label: item.accountsName
                    }))}
                    isClearable
                    onInputChange={(e)=>{getOptions(e,"accountsMasterOptionQueryWithFiliter","accountsMaster")}}
                    isSearchable={true}
                    ref={AccountRef} 
                    value={selectedAccount_}
                    onChange={(option) => {setFieldValue('Item_Purchase_Account', option ? option.value : '');
                    handleSelectAccount(option)}}
                    styles={customSelectStyle}
                    className='my-3'
                />
                <label htmlFor="Item_Purchase_Account" className='staan-form-input-label pt-1 px-1 mx-2'>Item Purchase Account <i  role="button" className='bx bx-plus-circle'
                  onClick={()=>{
                    handlAccountShow()
                    setAccountGroupType('purchase')
                  }} ></i></label>
                  {  touched.Item_Purchase_Account &&  errors.Item_Purchase_Account && <small>{errors.Item_Purchase_Account}</small>}
              </div>
              <div className="col-6 staan-input-group">
                <Select
                  onInputChange={(e)=>{getOptions(e,"onlyForSupplierOption","supplierFormData")}}
                  styles={customSelectStyle}
                  className='my-3'
                  isClearable
                  ref={SupplierRef}
                  isSearchable={true}
                  options = {supplierOption.map((supplier)=>({ value: supplier.id, label: supplier.companyName }))}
                  value={seletedSupplier_} 
                  onChange={(options) => {
                    const selectedValues = options ? options.map(option => option.value) : [];
                    setFieldValue('supplierData', selectedValues);
                    handleSelectSupplier(options);
                  }}
                  isMulti
                />
                <label htmlFor="supplierData" 
                className='staan-form-input-label pt-2 px-1 mx-2'>Suppliers
                <i role="button" className='bx bx-plus-circle ms-2'
                onClick= {()=>{handleSupplierShow()}} ></i></label>
              </div>
            
            </div>
       

        </Modal.Body>
        <Modal.Footer> 
        <Button  size='sm' variant="outline-success" onClick={()=>{
              PurchaseSchema.validate({"Item_Cost" : values.Item_Cost,
              "Purchase_uom" : values.Purchase_uom, 
              "Item_Purchase_Account": values.Item_Purchase_Account,
              "variation" : values.variation})
              .then(function(validatedData) {
                  setErrorsShow("")
                  handlePurchaseClose()
              })
              .catch(function(err) {
                   
                  setErrorsShow(err.errors[0])
              });
          
            }}>
            Save  
        </Button>
        </Modal.Footer>
        </Modal>
      </Form>
      <SupplierFrom/>
      {waringModel && <WaringModel
       state = {waringModel}
       setstate={setWaringModel}
       title={'Confirm'}
       text={'Confirm to Clear Value'}
       Confirmtion={value => {
        onCloseClearValue(value)
      }}
      />}
    </>
  )
}

export default PurchaseModal