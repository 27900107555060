import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosInstance, { commanFetch } from '../../../api/axoiss'
import HierarchicalTreeView from '../../../components/customTreeViewComponent/HierarchicalTreeView'
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation'
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import CustomSelect from '../../../components/ReactSelect/reactSelect'
import DataContext from '../../../context/ItemMasterContext'
import * as QueryData from '../../../GraphQLQueries/ItemmasterQuery'
import {
  leadDeleteMutations,
  leadsCreateMutation
} from '../../../GraphQLQueries/LeadQueryAndMutations/LeadMutation'
import { leadFormEditQuery, leadTimeLine } from '../../../GraphQLQueries/LeadQueryAndMutations/LeadQuery'
import {
  DateFormate,
  removeEmptyValueInObject,
  ShowErrorNoties
} from '../../../utils/Utils'
import { LeadEditSchema } from '../../../validations/LeadValidation'
import Activities from '../../Activities/models/Activities'
import QuotationFrom from '../../Quotation/Forms/Quatationform'
import SalesOrderForm from '../../SalesOrder2/model/SalesOrderForm'
import Lead from '../Lead'
import './LeadFormEdit.css'

const LeadFormEdit = ({ index, editData, setload }) => {
  const { userId, handltoastDeleteConfomationShow, quotationModels, salesOrderModels, openQuotationModal, openSalesOrderModal,
    closeLeadModal, openLeadModal
  } = useContext(DataContext)
  const location = useLocation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [customerList, setcustomerList] = useState([])
  const [salePersonlist, setsalePersonlist] = useState([])
  const [leadCurrencylist, setleadCurrencylist] = useState([])
  const [deleteData, setdeleteData] = useState({ Name: '', id: '' })
  const [ShowLeadComponent, setShowLeadComponent] = useState(false)
  const [quotationsList, setQuotationsList] = useState(editData?.quotationsSet)
  const [timeLineValue, setTimeLineValue] = useState({ treeData: [], isShow: false })
  const [formKey, setFormKey] = useState(0)
  const [requestData, setRequestData] = useState(null)
  const [leadValue, setLeadValue] = useState({
    selectedCustomer: { label: "", value: "" }, selectedLeadCurrency: { label: "", value: "" },
    selectedSalesPerson: { label: "", value: "" }, currentStep: "", leadStatus: ""
  })
  const [isLeadPage, setIsLeadPage] = useState(false);

  // State hooks for Select field values 
  const navigate = useNavigate()

  const [initialValue, setInitialValue] = useState({
    LeadName: '',
    LeadNumber: '',
    customerValue: '',
    LeadCurrency: '',
    LeadValue: '',
    ExpectedClosureDate: '',
    LeadStatus: "",
    SalesPerson: '',
    Source: '',
    Requirements: "",
    Enquiry: null,
    id: "",
    Name: '',
    Email: '',
    MobileNumber: '',
    WhatsappNumber: '',
    createdBy: ''
  })

  // fetch time line Datas
  async function fetchTimelineData(id) {
    // setLoading(true)
    try {
      const respones = await axiosInstance.post(`/itemmaster/graphql`, {
        query: leadTimeLine(id)
      })
      let timeLime = respones?.data?.data?.leadTimeLine
      setTimeLineValue(prev => ({ ...prev, treeData: [timeLime], isShow: true }))
    } catch (error) {
      console.log(error);
    }
    // setLoading(false)
  }
  useEffect(() => {
    // Check if the current URL path is '/Lead'
    if (window.location.pathname === '/Lead') {
      setIsLeadPage(true);
    } else {
      setIsLeadPage(false);
    }
  }, []);

  function UpdateData(leadData) {
    setInitialValue({
      id: leadData?.id ? Number(leadData?.id) : "",
      LeadName: leadData?.leadName,
      LeadNumber: leadData?.leadNo,
      customerValue: leadData?.customer?.id,
      LeadCurrency: leadData?.leadCurrency?.id,
      LeadValue: leadData?.leadValue,
      ExpectedClosureDate: leadData?.expectedClosingDate,
      LeadStatus: leadData?.status?.name,
      SalesPerson: leadData?.salesPerson?.id,
      Source: leadData?.Enquiry?.conferenceData?.name,
      Requirements: leadData?.requirement,
      Enquiry: leadData?.Enquiry?.id ? leadData?.Enquiry?.id : null,
      Name: leadData?.Enquiry?.linkContactDetalis?.contactPersonName,
      Email: leadData?.Enquiry?.linkContactDetalis?.email,
      MobileNumber: leadData?.Enquiry?.linkContactDetalis?.phoneNumber,
      WhatsappNumber: leadData?.Enquiry?.linkContactDetalis?.whatsappNo,
      createdBy: leadData?.createdBy?.id ? Number(leadData?.createdBy?.id) : Number(userId)
    })

    setLeadValue((prev) => ({
      ...prev, selectedCustomer: {
        label: leadData?.customer?.companyName,
        value: leadData?.customer?.id
      },
      selectedLeadCurrency: { label: leadData?.leadCurrency?.Currency?.name, value: leadData?.leadCurrency?.id },
      selectedSalesPerson: { label: leadData?.salesPerson?.username, value: leadData?.salesPerson?.id },
      leadStatus: { value: leadData?.status?.name, label: leadData?.status?.name }, currentStep: leadData?.status?.name
    }))
    setQuotationsList(leadData?.quotationsSet)

    setFormKey(prev => prev + 1)
  }

  // Fetch data based on the conditions
  useEffect(() => {
    // Function to fetch lead data
    async function fetchDataLeadEditData(id) {
      try {
        const response = await axiosInstance.post(`/itemmaster/graphql`, {
          query: leadFormEditQuery(id),
        });
        const leadData = response?.data?.data?.allLead?.items[0];
        UpdateData(leadData)
        setRequestData(leadData)
        return leadData;
      } catch (error) {
        return null;
      }
    }
    const fetchData = async () => {
      if (location.state?.leadData?.id) {
        fetchDataLeadEditData(location.state?.leadData?.id);
      } else if (editData) {
        fetchDataLeadEditData(editData);
      }
    };
    fetchData();
  }, [location.state?.leadData, editData]);

  const steps = [
    { value: "Qualified", label: 'Qualified' },
    { value: "Quotation", label: 'Quotation' },
    { value: "Demo", label: 'Demo' },
    { value: "Negotiation", label: 'Negotiation' },
    { value: "Won", label: 'Won' }
  ];
  // const handleStepChange = (stepIndex, setFieldValue) => {
  //   setCurrentStep(stepIndex)
  //   const newStatus = steps[stepIndex - 1]
  //   setLeadStatus(newStatus)
  //   setFieldValue('LeadStatus', newStatus)
  // }
  async function getOption(e, queryName) {
    let search_term = e.trim().replace(/"/g, '\\"')
    if (queryName === 'SupplierOption' && search_term) {
      const response = await commanFetch(QueryData[queryName](search_term))
      if (response.success) {
        const reponseData = response?.data?.items
        setcustomerList(
          reponseData?.map(customer => ({
            value: customer.id,
            label: customer.companyName
          }))
        )
      }
    } else if (queryName === 'userdataOptionsQuery' && search_term) {
      const response = await commanFetch(QueryData[queryName](search_term))
      if (response.success) {
        const reponseData = response?.data?.items
        setsalePersonlist(
          reponseData?.map(saleperson => ({
            value: saleperson.id,
            label: saleperson.username
          }))
        )
      }
    } else if (queryName === 'leadCurrencyList' && search_term) {
      const response = await commanFetch(QueryData[queryName](search_term))
      if (response.success) {
        const reponseData = response?.data?.items
        setleadCurrencylist(
          reponseData?.map(leadcurrency => ({
            value: leadcurrency.id,
            label: leadcurrency.name
          }))
        )
      }
    } else {
      setcustomerList([])
      setsalePersonlist([])
    }
  }
  //  return null ;
  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setErrors }
  ) => {
    let saveValues = {
      id: values?.id ? String(values?.id) : '',
      createdBy: Number(values?.createdBy),
      customer: Number(values?.customerValue),
      expectedClosingDate: values.ExpectedClosureDate,
      leadCurrency: Number(values?.LeadCurrency),
      leadName: values?.LeadName,
      leadValue: String(values?.LeadValue),
      salesPerson: Number(values?.SalesPerson),
      status: values?.LeadStatus,
      requirement: values?.Requirements,
      modifiedBy: Number(userId)
    }
    let ClearValue = removeEmptyValueInObject(saveValues, ['id'])
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query: leadsCreateMutation(ClearValue)
      })
      const responseData = response?.data?.data?.leadsCreateMutation
      if (responseData.success) {
        resetForm()
        showErrorToast(true, 'success', '', 'Saved Successfully')
        setSubmitting(false)
        setShowLeadComponent(true)
        if (setload) {
          setload()
        }
        if (index) {
          closeLeadModal(index)
        }

      } else {
        let errorData = ShowErrorNoties(responseData.errors)
        showErrorToast(true, 'error', errorData)
      }
    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
    }
  }
  const handledeleteConfomation = (data) => {
    handltoastDeleteConfomationShow()
    setdeleteData({
      Name: data?.leadName,
      id: data?.id
    })
  }
  const handleDeletedId = deleted_id => {
    if (deleted_id) {
      if (index) {
        closeLeadModal(index)
      }
      setload()
    }
  }
  // return null; convertToSalesOrder
  function CreateSalesOrder(params) {
     
    openSalesOrderModal()
  }
  function getOpenmodelId(id, model) {

    if (id && model === "Sales Order") {
      openSalesOrderModal(id)
    } else if (id && model === "Quotation") {
      openQuotationModal(id)
    } else if (id && model === "Lead") {
      openLeadModal(id)
    }

  }
  function CloseLead() {
    if (editData) {
      closeLeadModal(index)
    }
  }
  return (
    <>
      {ShowLeadComponent ? (
        <Lead />
      ) : (
        <Formik
          initialValues={initialValue}
          validationSchema={LeadEditSchema}
          enableReinitialize
          key={formKey}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            setFieldValue,
            values,
            isSubmitting,
            submitForm
          }) => {
            return (
              <>
                <Form>
                  <Modal show={true} fullscreen  >
                    <Modal.Header>
                      <Modal.Title style={{ width: '100%' }}>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='d-flex align-items-center commanModelTitleColor'>
                            <h3 className='mx-2 commanModelTitleColor'>
                              <i
                                className='fa-solid fa-arrow-left fa-sm me-2'
                                onClick={() => {
                                  setShowLeadComponent(true); CloseLead(); navigate(`/Lead`, {
                                    replace: true
                                  })
                                }}
                                style={{ cursor: 'pointer' }}
                              ></i>
                              Leads
                              <span className='commanModelTitleColor ps-2 cursor-pointer' onClick={() => { fetchTimelineData(editData) }}>Time Line</span>
                            </h3>

                          </div>
                          {!isEditMode && (
                            <div className='d-flex align-items-center'>
                              <span className='me-3' style={{ marginRight: '50px' }}>
                                <i
                                  role='button'
                                  className={`fa-solid fa-pen me-4`}
                                  onClick={() => setIsEditMode(!isEditMode)}
                                ></i>
                              </span>

                              <span style={{ marginRight: '50px' }}>
                                <i
                                  role='button'
                                  className='fa-solid fa-trash text-danger'
                                  onClick={() => {
                                    handledeleteConfomation();
                                  }}
                                ></i>
                              </span>
                            </div>
                          )}
                        </div>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='container-fluid mt-2 height-fluid'>
                        <div className='row'>
                          <div className='col-12'>
                            <ul className='list-unstyled d-flex position-relative'>
                              {steps.map((step, index) => (
                                <li
                                  key={step.value} // Use a unique identifier from the step object
                                  className={`stepper-item ${step.label === leadValue?.currentStep ? 'current' : ''} ${step.label !== leadValue?.currentStep && index < steps.findIndex(s => s.label === leadValue?.currentStep) ? 'complete' : ''}`}
                                >
                                  {step.label} {/* Access the label property here */}
                                </li>
                              ))}
                            </ul>
                          </div>

                        </div>
                        <div className='row mt-2'>
                          {/* First Column */}
                          <div className='col-12 col-md-4'>
                            <div className='row'>
                              <div className='col-12 me-3 mt-4 staan-input-group'>
                                <Field
                                  type='text'
                                  name='LeadNumber'
                                  id='LeadNumber'
                                  className='w-100 staan-form-input'
                                  disabled={true}
                                />
                                <label
                                  htmlFor='leadNuLeadNumbermber'
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                  onClick={() => {
                                    console.log(initialValue?.Enquiry)
                                  }}
                                >

                                  Lead Number
                                </label>
                                {touched.LeadNumber && errors.LeadNumber && (
                                  <small className='staan-error-msg'>
                                    {errors.LeadNumber}
                                  </small>
                                )}
                              </div>
                              <div className='col-12 mt-2 staan-input-group'>
                                <Field
                                  type='text'
                                  name='LeadName'
                                  id='leadName'
                                  className='w-100 staan-form-input'
                                  disabled={!isEditMode || leadValue?.leadStatus?.label === "Won"}
                                />
                                <label
                                  htmlFor='leadName'
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                  onClick={() => {
                                    console.log(leadValue?.leadStatus);
                                  }}
                                >
                                  Lead Name<span className='text-danger'>*</span>
                                </label>
                                {touched.LeadName && errors.LeadName && (
                                  <small className='staan-error-msg'>
                                    {errors.LeadName}
                                  </small>
                                )}
                              </div>
                              <div className='col-12 mt-2 staan-input-group'>
                                <CustomSelect
                                  setSelectEditValue={leadValue?.selectedCustomer}
                                  handleInputChange={(e) => {
                                    getOption(e, 'SupplierOption')
                                  }}
                                  options={customerList}
                                  name='customerValue'
                                  id='customerValue'
                                  handleOnChange={(option) => {
                                    setLeadValue((prev) => ({ ...prev, selectedCustomer: option }))
                                    setFieldValue(
                                      'customerValue',
                                      option ? option.value : ''
                                    )
                                  }}
                                  isDisabled={!isEditMode || leadValue?.leadStatus?.label === "Won"}
                                />
                                <label
                                  htmlFor='customerValue'
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                >
                                  Customer<span className='text-danger'>*</span>
                                </label>
                                {touched.customerValue &&
                                  errors.customerValue && (
                                    <small className='staan-error-msg'>
                                      {errors.customerValue}
                                    </small>
                                  )}
                              </div>
                              <div className='col-12 mt-2 staan-input-group'>
                                <CustomSelect
                                  setSelectEditValue={leadValue?.selectedLeadCurrency}
                                  handleInputChange={(e) => {
                                    getOption(e, 'leadCurrencyList')
                                  }}
                                  options={leadCurrencylist}
                                  name='LeadCurrency'
                                  id='LeadCurrency'
                                  handleOnChange={(option) => {
                                    setLeadValue((prev) => ({ ...prev, selectedLeadCurrency: option }))
                                    setFieldValue(
                                      'LeadCurrency',
                                      option ? option.value : ''
                                    )
                                  }}
                                  isDisabled={!isEditMode || leadValue?.leadStatus?.label === "Won"}
                                />
                                <label
                                  htmlFor='LeadCurrency'
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                >
                                  Lead Currency<span className='text-danger'>*</span>
                                </label>
                                {touched.LeadCurrency &&
                                  errors.LeadCurrency && (
                                    <small className='staan-error-msg'>
                                      {errors.LeadCurrency}
                                    </small>
                                  )}
                              </div>
                              <div className='col-12 mt-2 staan-input-group'>
                                <Field
                                  type='number'
                                  name='LeadValue'
                                  id='LeadValue'
                                  className='w-100 staan-form-input'
                                  disabled={!isEditMode || leadValue?.leadStatus?.label === "Won"}
                                />
                                <label
                                  htmlFor='LeadValue'
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                >
                                  Lead Value<span className='text-danger'>*</span>
                                </label>
                                {touched.LeadValue && errors.LeadValue && (
                                  <small className='staan-error-msg'>
                                    {errors.LeadValue}
                                  </small>
                                )}
                              </div>
                              <div className='col-12 mt-2 staan-input-group'>
                                <Field
                                  type='date'
                                  name='ExpectedClosureDate'
                                  className='staan-form-input  '
                                  style={{
                                    border: '1px solid rgb(206, 201, 201)',
                                    fontSize: '0.8rem'
                                  }}

                                  onChange={e =>
                                    setFieldValue(
                                      'ExpectedClosureDate',
                                      e.target.value
                                    )
                                  }
                                  disabled={!isEditMode || leadValue?.leadStatus?.label === "Won"}
                                />
                                <label className='staan-form-input-label pt-1 px-1 mx-2'>
                                  Expected Closure Date<span className='text-danger'>*</span>
                                </label>
                              </div>

                              <div className='col-12 mt-2 staan-input-group'>
                                <CustomSelect
                                  setSelectEditValue={leadValue?.leadStatus}
                                  options={steps}
                                  name='LeadStatus'
                                  id='LeadStatus'
                                  handleOnChange={(options) => {
                                    setLeadValue((prev) => ({ ...prev, leadStatus: options ? options : null, currentStep: options?.label }))
                                    setFieldValue('LeadStatus', options?.label)
                                  }}
                                  isDisabled={!isEditMode || leadValue?.leadStatus?.label === "Won"}
                                />
                                <label
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                >
                                  Lead Status<span className='text-danger'>*</span>
                                </label>
                                {touched.SalesPerson && errors.SalesPerson && (
                                  <small className='staan-error-msg'>
                                    {errors.SalesPerson}
                                  </small>
                                )}
                              </div>
                              <div className='col-12 mt-2 staan-input-group'>
                                <CustomSelect
                                  setSelectEditValue={leadValue?.selectedSalesPerson}
                                  handleInputChange={(e) => {
                                    getOption(e, 'userdataOptionsQuery')

                                  }}
                                  options={salePersonlist}
                                  name='SalesPerson'
                                  id='SalesPerson'
                                  handleOnChange={(option) => {
                                    setLeadValue((prev) => ({ ...prev, selectedSalesPerson: option ? option : null }))
                                    setFieldValue(
                                      'SalesPerson',
                                      option ? option.value : ''
                                    )
                                  }}
                                  isDisabled={!isEditMode || leadValue?.leadStatus?.label === "Won"}
                                />
                                <label
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                  onClick={() => {
                                    console.log(leadValue, values);
                                  }}
                                >
                                  Sales Person<span className='text-danger'>*</span>
                                </label>
                                {touched.SalesPerson && errors.SalesPerson && (
                                  <small className='staan-error-msg'>
                                    {errors.SalesPerson}
                                  </small>
                                )}
                              </div>
                              <div className='col-12 staan-input-group'>
                                <Field
                                  as='textarea'
                                  name='Requirements'
                                  id='Requirements'
                                  className='w-100 staan-form-input'
                                  rows='4'
                                  disabled={!isEditMode || leadValue?.leadStatus?.label === "Won"}
                                />
                                <label
                                  htmlFor='Requirements'
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                >
                                  Requirements
                                </label>
                              </div>
                              <div className='col-12 mt-2 staan-input-group'>
                                <Field
                                  type='text'
                                  name='Source'
                                  id='Source'
                                  className='w-100 staan-form-input'
                                  disabled={true}
                                />
                                <label
                                  htmlFor='Source'
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                >
                                  Source<span className='text-danger'>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          {/* Second Column */}
                          <div className='col-12 col-md-4'>
                            <label style={{ marginBottom: '0' }}>
                              Contact
                            </label>
                            <div className="row">
                              <div className="col-6 my-1 staan-input-group">
                                <Field
                                  type='text'
                                  name='Name'
                                  id='Name'
                                  className='w-100 staan-form-input'
                                  disabled={true}

                                />
                                <label
                                  htmlFor='Name'
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                >
                                  Name <span className='text-danger'>*</span>
                                </label>
                              </div>
                              <div className="col-6 my-1 staan-input-group">
                                <Field
                                  type='text'
                                  name='Email'
                                  id='Email'
                                  className='w-100 staan-form-input'
                                  disabled={true}

                                />
                                <label
                                  htmlFor='Email'
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                >
                                  Email <span className='text-danger'>*</span>
                                </label>
                              </div>
                              <div className="col-6 my-1 staan-input-group">
                                <Field
                                  type='text'
                                  name='MobileNumber'
                                  id='MobileNumber'
                                  className='w-100 staan-form-input'
                                  disabled={true}
                                />
                                <label
                                  htmlFor='MobileNumber'
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                >
                                  Mobile Number <span className='text-danger'>*</span>
                                </label>
                              </div>
                              <div className="col-6 my-1 staan-input-group">
                                <Field
                                  type='text'
                                  name='WhatsappNumber'
                                  id='WhatsappNumber'
                                  className='w-100 staan-form-input'

                                  disabled={true}
                                />
                                <label
                                  htmlFor='WhatsappNumber'
                                  className='staan-form-input-label pt-1 px-1 mx-2'
                                >
                                  Whatsapp Number <span className='text-danger'>*</span>
                                </label>
                              </div>
                            </div>
                            <label
                              htmlFor='quotationList'
                              className='form-label' onClick={() => {
                                console.log("quotationsList", quotationModels);
                              }}

                            >
                              Quotation
                            </label>
                            <ul
                              id='quotationList'
                              className='list-group '
                              style={{ height: '35rem', overflowY: 'scroll' }}
                            >
                              {quotationsList?.filter(item => item?.active === true)?.map((Quotations, index) => (
                                <li key={Quotations?.id} onClick={() => {
                                  openQuotationModal(Quotations?.id);
                                }} className='p-3 list-group-item list-group-item-action' style={{ cursor: "pointer" }}>
                                  <div className="row">
                                    <div className="col-6 fw-bold">
                                      {Quotations?.quotationNo?.linkedModelId}
                                    </div>
                                    <div className="col-6 text-end">
                                      <span className='fw-semibold'>Status :</span> <span className=''>{Quotations?.status?.name}</span>
                                    </div>
                                    <div className="col-12 mt-1">
                                      <span className='fw-semibold'>Created At :</span> <span className=' '>{DateFormate(Quotations?.CreatedAt)}</span>
                                    </div>
                                    <div className="col-6 mt-1">
                                      <span className='fw-semibold'>CreatedBy :</span> <span className=' '>{Quotations?.createdBy?.username}</span>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* Third Column */}
                          <div className='col-12 col-md-4 mt-2 mt-md-0'>
                            {initialValue?.Enquiry ? <Activities
                              enquiry_id={initialValue?.Enquiry}
                              show={true}
                              salesMan={leadValue?.selectedSalesPerson}
                            /> : ""}

                          </div>
                        </div>
                      </div>
                      <div className='row justify-content-center my-3'>
                        <div className='col-3'>
                          <button
                            type='submit'
                            className='btn btn-outline-success px-4 ms-4'
                            style={{ width: '80%' }}
                            onClick={submitForm}
                            disabled={isSubmitting || leadValue?.leadStatus?.label === "Won"}
                          >
                            Save
                          </button>
                        </div>
                        <div className='col-3'>
                          <button
                            type='button'
                            className='btn btn-outline-secondary px-2 ms-4'
                            style={{ width: '80%' }}
                            onClick={() => { openQuotationModal() }}
                            disabled={leadValue?.leadStatus?.label === "Qualified" ||leadValue?.leadStatus?.label === "Won"}
                          >
                            Quotation
                          </button>
                        </div>
                        <div className='col-3'>
                          <button
                            type='submit'
                            className='btn btn-outline-primary px-2 ms-4'
                            style={{ width: '80%', paddingRight: '20px' }}
                            onClick={CreateSalesOrder}
                            disabled={leadValue?.leadStatus?.label === "Won"}
                          >
                            Sales Order
                          </button>
                        </div>
                        <div className='col-3'>
                          <button
                            type='button'
                            className='btn btn-outline-info px-2 ms-4'
                            style={{ width: '80%' }}
                            disabled={leadValue?.leadStatus?.label === "Won"}

                          >
                            Lost
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </Form>
                {deleteData && (
                  <DeleteConformation
                    handleDeletedId={id => handleDeletedId(id)}
                    deleteData={deleteData}
                    fullquery={leadDeleteMutations(deleteData?.id)}
                  />
                )}
              </>
            )
          }}
        </Formik>
      )}
      <HierarchicalTreeView data={timeLineValue?.treeData} show={timeLineValue?.isShow} getOpenmodelId={getOpenmodelId} currentID={initialValue.id}
        close={() => { setTimeLineValue((prev => ({ ...prev, isShow: false }))) }} />
      {quotationModels?.map((item) => (
        <QuotationFrom
          index={item?.index}
          leadData={requestData}
          setQuotationsList={setQuotationsList}
          quotationId={item?.id}>
        </QuotationFrom>
      ))}
      {isLeadPage && salesOrderModels?.map((item) => (
        <SalesOrderForm
          index={item?.index}
          leadData={requestData}
          editData={item?.id}
        />
      ))}
    </>
  )
}

export default LeadFormEdit
