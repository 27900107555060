import * as  yup from "yup"

export const EnquirySchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    Hospital:  yup.string() ,
    Mobile: yup.string().required('Mobile is required'), 
    alternateMobileNumber : yup.string()
    .min(10, 'Mobile number must be at least 10 characters')
    .max(15, 'Mobile number must be less than or equal to 15 characters'),
    otherNumber : yup.string()
    .min(10, 'Mobile number must be at least 10 characters')
    .max(15, 'Mobile number must be less than or equal to 15 characters'),
    Pincode: yup.string().required("Pincode is required"),
    message:  yup.string(),
    intrested: yup.array(),
})


export const AddConferenceSchema = yup.object().shape({
    name: yup.string().required("Please Enter Name"),
    Startdate: yup.date().required("Please Enter  Start Date"),
    enddate : yup.date().required('Please Enter end Date'),
    incharge : yup.string().required("Please Select incharge"),
    Status: yup.boolean(),
    DefaultStore :yup.string().required("Default Store required"),
    Currency : yup.string().required("Default Store Currency")
   
})

export const enquiry_assign_sales_person_schema = yup.object().shape({
    salesPerson:  yup.string().required("Please Enter Sales Person"),
})