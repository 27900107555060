function convertToGraphQLQuery(data) {
    let query = '';
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        let value = data[key];
        if (typeof value === 'object' && !Array.isArray(value)) {
          // Handle nested object (you can modify this part if needed)
          if (value === null) {
            query += `, ${key}: null`;
          }else{
            query += `, ${key}:{`;
            for (let nestedKey in value) {
              if (value.hasOwnProperty(nestedKey)) {
                query += `${nestedKey}:"${value[nestedKey]}",`;
              }
            }
            query = query.slice(0, -1); // Remove trailing comma
            query += '}';
          }          
        } else if (Array.isArray(value)) {
          // Handle array
          query += `, ${key}:[${value}]`;
        } else {
          // Handle primitive types
          if (typeof value === 'string') {
            query += `, ${key}:"${value}"`;
          } else {
            query += `, ${key}:${value}`;
          }
        }
      }
    }
    return query;
}
  

export const ProductionOrderItemCreateQuery = (data) => {
    let formatted_query_string = convertToGraphQLQuery(data);
    let query = `mutation CreateProductionOrder{
        productionOrderItemCreateMutation(${formatted_query_string}){
            productionOrderItem {
                id
            }
            success
            errors
        }
    }`
    return query;
}


export const FetchProductionOrderItem = (id_list) => {
    let query = `query ProductionOrderFetchQuery {
        productionOrderItem (idList: [${id_list}]){
          items {
            id
            cost
            qty
            updatedAt
            modifiedBy {
              username
              id
            }
            bom {
              bomName
              bomNo
              bomType
              id
            }
            supplier {
              id
              supplier
              companyName
            }
            sourceType {
              id
              name
            }
            partCode{
                id
                itemName
                itemPartCode
                itemMrp
            }
          }
        }
      }`
    return query;
}


export const ProductionOrderMasterItemFetchQuery = (po_master) => {
  let query = `query MyQuery {
    productionOrderLinking(poMaster: ${po_master}) {
      items {
        id
        poMaster {
          id
          orderNo
          isCombo
          isMultiLevelManufacture
          orderDate
          department {
            id
            name
          }
          modifiedBy {
            id
            username
          }
          createdBy {
            id
            username
          }
        }
        poItem {
          id
          qty
          partCode {
            id
            itemName
            itemPartCode
          }
          supplier {
            id
            companyName
          }
          sourceType {
            id
            name
          }
          cost
          bom {
            id
            bomNo
            bomName
            bomType
            fgStore{
              id
              storeName
            }
            scrapStore{
              id
              storeName
            }
          }
        }
        poItemDetail {
          id
          remarks
          modifiedBy {
            id
            username
          }
          dueDate
          routes{
            id
            endTime
            startTime
            serialNumber
            workCenter{
              id
              workCenter
            }
            route{
              id
              routeName
            }
            duration
            actualDuration
          }
          rawMaterial {
            id
            serialNumber
            partCode {
              id
              itemName
              itemPartCode
            }
            actualQty
            fixed
            category {
              id
              name
            }
            bom {
              bomName
              bomNo
              bomType
              id
            }
            usedQty
            updatedAt
            store {
              id
              storeName
            }
            unit {
              id
              name
            }
          }
          finishedGoods {
            acceptedQty
            completedQty
            id
            productionQty
            rejectedQty
            remarks
            reworkQty
            category{
              id
              name
            }
            updatedAt
            unit {
              id
              name
            }
            partCode {
              id
              itemName
              itemPartCode
            }
          }
          status {
            id
            name
          }
          scrap {
            id
            costAllocation
            actualQty
            store{
              id
              storeName
            }
            unit{
              id
              name
            }
            category {
              id
              name
            }
            partCode {
              id
              itemName
              itemPartCode
            }
          }
          otherCharges{
            id
            description
            remarks
            actualAmount
            bomAmount
          }
          subProductionOrders {
            completedQty
            id
            orderNo
            pendingQty
            productionQty
            bomType{
              id
              name
            }
            poMaster{
              id
              orderNo
            }
            status{
              id
              name
            }
            unit {
              id
              name
            }
            partCode {
              id
              itemName
              itemPartCode
            }
          }
        }
      }
    }
  }`
  return query;
}


export const ProductionOrderLinkingMasterItemFetchQuery = (po_master, isSubProductionOrder) => {
  let query = `query ProductionOrderLinkingFetchQuery {
    productionOrderLinking(poMaster: ${po_master}, isSubProductionOrder:${isSubProductionOrder}) {
      items {
        id
        poMaster {
          id
          orderNo
          isCombo
          isMultiLevelManufacture
          orderDate
          department {
            id
            name
          }
          modifiedBy {
            id
            username
          }
          createdBy {
            id
            username
          }
        }
        poItem {
          id
          qty
          isCombo
          partCode {
            id
            itemName
            itemPartCode
          }
          supplier {
            id
            companyName
          }
          sourceType {
            id
            name
          }
          cost
          bom {
            id
            bomNo
            bomName
            bomType
            fgStore{
              id
              storeName
            }
            scrapStore{
              id
              storeName
            }
          }
        }
        poItemDetail {
          id
          remarks
          status {
            id
            name
          }
          modifiedBy {
            id
            username
          }
          dueDate
          routes{
            id
          }
          rawMaterial {
            id
          }
          finishedGoods {
            id
          }
          
          scrap {
            id
          }
          otherCharges{
            id
            description
            remarks
            actualAmount
            bomAmount
          }
          subProductionOrders {
            id
          }
        }
      }
    }
  }`
  return query;
}


export const ProductionOrderMasterCreateQuery = (data) => {
  let formatted_query_string = convertToGraphQLQuery(data);
  let query = `mutation ProductionOrderMasterCreateQuery{
    productionOrderMasterCreateMutation(${formatted_query_string}){
      productionOrderMaster{
        id
        orderNo
      }
      success
      errors
    }
  }`
  return query;
}


export const ProductionOrderLinkCreateQuery = (data) => {
  let formatted_query_string = convertToGraphQLQuery(data);
  let query = `mutation ProductionOrderLinkMutation{
    productionOrderLinkCreateMutation(${formatted_query_string}){
      productionOrderLink{
        id
      }
      success
      errors
    }
  }`
  return query;
}


export const DepartmentOptionFetchQuery = () => {
  let query = `query MyQuery {
    department {
      items {
        id
        name
      }
    }
  }`
  return query;
}


export const ProductionOrderProcessRouteMutateQuery = (data) => {
  let formatted_query_string = convertToGraphQLQuery(data);
  let query = `mutation PoProcessUpdate{
    productionOrderProcessRouteCreateMutation(
      ${formatted_query_string}
    ){
      productionOrderProcessRoute{
        actualDuration
        endTime
        id
        serialNumber
        startTime
        duration
        workCenter {
          id
          workCenter
        }
        route {
          id
          routeName
        }
      }
      success
      errors
    }
  }`;
  return query;
}


export const ProductionOrderListViewFetchQuery = () => {
  let query = `query MyQuery {
      productionOrderLinking{
        items {
          id
          poMaster{
            id
            orderNo
            orderDate
            department{
              id
              name
            }
            modifiedBy{
              id
              username
            }
          }
          poItem{
            id
            partCode{
              id
              itemName
              itemPartCode
            }
            supplier{
              id
              companyName
            }
            sourceType{
              id
              name
            }
            cost
            bom{
              id
              bomNo
              bomName
              bomType
            }
          }
        }
      }
  }`;
  return query;
}


export const ProductionOrderItemComboFetchQuery = (part_code_id) => {
  let query = `query FetchItemMasterCombos{
      itemMaster(id: ${part_code_id}){
        items{
          id
          itemComboBool
          itemComboData{
            sNo
            isMandatory
            itemQty
            partNumber{
              id
              itemPartCode
              itemName
              itemstockSet{
                id
                currentStock
              }
              finishedgoodsSet{
                id
                bomSet{
                  id
                  bomNo
                  bomName
                  bomType
                }
              }
            }
            itemDisplay{
              id
              display
            }
          }
        }
      }
    }`
  return query;
}


export const SubProductionOrderFetchQueryByIdList = (id_list, source) => {
  let query = `query SubProductionOrdersByMaster{
  subProductionOrder(idList: [${id_list}], sourceType: "${source}"){
    items{
      id
      orderNo
      completedQty
      pendingQty
      productionQty
      bomType{
        id
        name
      }
      poMaster {
        id
        orderNo
        productionorderlinkingtableSet{
          poItem{
            partCode{
              itemPartCode
            }
            bom{
              supplier{
                id
                companyName
              }
            }
          }
        }
      }
      status{
        id
        name
      }
      unit {
        id
        name
      }
      partCode {
        id
        itemName
        itemPartCode
      }
  	}
	}
  }`
  return query;
}


export const SubProductionOrderRawMaterialsFetch = (id_list) => {
  let query = `query SubProductionOrderRawMaterial{
  subProductionOrder(idList: [${id_list}]){
    items{
      id
      bomType{
        id
        name
      }
      partCode{
        id
        itemName
        itemPartCode
      }
      pendingQty
      completedQty
      poMaster{
        id
        orderNo
        productionorderlinkingtableSet{
          poItemDetail{
            id
            rawMaterial{
              id
              serialNumber
              partCode {
                id
                itemName
                itemPartCode
              }
              actualQty
              fixed
              category {
                id
                name
              }
              bom {
                bomName
                bomNo
                bomType
                id
              }
              usedQty
              updatedAt
              store {
                id
                storeName
              }
              unit {
                id
                name
              }
              
            }
          }
          
        }
      }
  	}
    
	}
  }`
  return query;
}


export const ItemComboBomFetch = (id_list, is_multi) => {
  let query = `query PartCodeHasBom {
    partCodeHasBom(idList: [${id_list.map(id => `"${id}"`).join(", ")}], isMulti: ${is_multi}) {
      items {
        parent
        bom{
          id
          bomName
          bomNo
          bomType
          isActive
          supplier{
            id
            companyName
          }
        }
        itemCombo{
          id
          category
          qty
          rawQty
          partNo{
            id
            itemName
            itemPartCode
            itemUom{
              id
              name
            }
            supplierData{
              id
              companyName
            }
            itemstockSet{
              id
              currentStock
            }
          }
          unit{
            id
            name
          }
        }
      }
    }
  }`
  return query;
}


export const FetchRecursiveBomQuery = (bom_id) => {
  let query = `query RecursiveBoms {
    fetchRecursiveBom(id: ${bom_id}) {
      items{
        parent
        bom{
          id
          bomName
          bomNo
          bomType
          isActive
          supplier{
            id
            companyName
          }
        }
        itemCombo{
          id
          category
          qty
          rawQty
          partNo{
            id
            itemName
            itemPartCode
            itemUom{
              id
              name
            }
          }
          unit{
            id
            name
          }
        }
      }
    }
  }`
  return query
}


export const FetchProductionOrderFinishedGoods = (id) => {
  let query = `query PoFinishedGoodsFetch{
    productionOrderFinishedGoods(id: ${id}){
      items{
        id
        acceptedQty
        completedQty
        productionQty
        rejectedQty
        remarks
        reworkQty
        category{
          id
          name
        }
        updatedAt
        unit {
          id
          name
        }
        partCode {
          id
          itemName
          itemPartCode
        }
      }
    }
  }`
  return query;
}


export const FetchProductionOrderRawMaterials = (id_list, type) => {
  let query = `query PoRawMaterialsFetch{
      productionOrderRawMaterials(idList: [${id_list}], 
        isBom: false, 
        sourceType: "${type}"
      ){
        items{
          id
          serialNumber
          partCode {
            id
            itemName
            itemPartCode
            supplierData{
              id
              companyName
            }
          }
          actualQty
          fixed
          category {
            id
            name
          }
          bom {
            bomName
            bomNo
            bomType
            id
          }
          usedQty
          updatedAt
          store {
            id
            storeName
          }
          unit {
            id
            name
          }
          parentBom{
            id
            bomNo
            bomName
            bomType
          }
        }
      }
    }`
  return query;
}


export const StoreOptionsFetchQuery = () => {
  let query = `query myFirstQuery{
    Store(matained: true){
      items{
        id
        storeName
        matained
        conference
      }
    }
  }`
  return query;
}

export const ComboItemFinishedGoodsFetch = (id_list) => {
  let query = `query PoSubProductionOrdersFetch {
    subProductionOrder(idList: [${id_list}], isCombo: true) {
      items {
        id
        poMaster {
          id
          orderNo
          productionorderlinkingtableSet{
            poItemDetail{
              id
              finishedGoods{
                id
                partCode{
                  id
                  itemName
                  itemPartCode
                }
                completedQty
                productionQty
                acceptedQty
                rejectedQty
                reworkQty
                category{
                  id
                  name
                }
                unit{
                  id
                  name
                }
                remarks
              }
            }
          }
        }
      }
    }
  }`
  return query
}

export const FetchProductionOrderScrapItems = (id_list) => {
  let query = `query FetchProductionOrderScrap{
    productionOrderScrap(idList: ${id_list}){
      items{
        id
        unit{
          id
          name
        }
        partCode{
          id
          itemName
          itemPartCode
        }
        costAllocation
        category{
          id
          name
        }
        actualQty
        store{
          id
          storeName
        }
      }
    }
  }`
  return query;
}

export const FetchRecursiveSubProductionOrders = (id, type) => {
  let query = `query FetchRecursiveSubProductionOrders{
    recursiveSubProductionOrder(detailId: ${id}, sourceType: "${type}"){
      items{
        id
      orderNo
      completedQty
      pendingQty
      productionQty
      isCombo
      bomType{
        id
        name
      }
      poMaster {
        id
        orderNo
        isSubProductionOrder
        productionorderlinkingtableSet{
          poItem{
            partCode{
              itemPartCode
            }
            bom{
              supplier{
                id
                companyName
              }
            }
          }
        }
      }
      status{
        id
        name
      }
      unit {
        id
        name
      }
      partCode {
        id
        itemName
        itemPartCode
      }
  	}
	}
  }`
  return query;
}

export const FetchRecursiveProductionRawMaterials = (id, type) => {
  let query = ` query FetchRecursiveRawMaterials{
    recursiveRawMaterials(detailId: ${id}, sourceType: "${type}"){
      items{
        id
          serialNumber
          isCombo
          productionorderitemdetailSet{
            id
            productionorderlinkingtableSet{
              id
              poMaster{
                id
                orderNo
              }
            }
          }
          partCode {
            id
            itemName
            itemPartCode
            supplierData{
              id
              companyName
            }
            itemstockSet{
              store{
                id
                storeName
              }
              id
              currentStock
            }
          }
          actualQty
          fixed
          category {
            id
            name
          }
          bom {
            bomName
            bomNo
            bomType
            id
          }
          usedQty
          issuedQty
          updatedAt
          store {
            id
            storeName
          }
          unit {
            id
            name
          }
          parentBom{
            id
            bomNo
            bomName
            bomType
          }
        }
      }
    }`
  return query;
}

export const FetchProductionOrderProcessRoute = (id_list) => {
  let query = `query FetchProcessRoutes{
    productionOrderProcessRoute(idList: [${id_list}]){
      items{
        id
        endTime
        startTime
        serialNumber
        workCenter{
          id
          workCenter
        }
        route{
          id
          routeName
        }
        duration
        actualDuration
      }
    }
  }`
  return query;
}

export const FetchProductionOrderProcessRouteSingle = (id) => {
  let query = `query FetchProcessRouteSingle{
    productionOrderProcessRoute(id: ${id}){
      items{
        endTime
        startTime
      }
    }
  }`
  return query;
}