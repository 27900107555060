import { Field, Form } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiOutlinePercentage } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import Select from 'react-select';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import WaringModel from '../../../components/Warings/WaringModel';
import DataContext from '../../../context/ItemMasterContext';
import { OnchangeGetData, customSelectStyle } from '../../../utils/Utils';
import { PurchaseSellingSchema } from '../../../validations/itemmaster';


const PurchaseSellingModal = ({touched, errors,  handlebothClose ,Both ,  setFieldValue, values, tax, 
  setAccountGroupType, dupData, setSelectedItem_Indicator, setType, purchaseUomOptions}) => { 

  const {handlAccountShow,handleSupplierShow,
  ItemAccountSelect, ItemAccountSalesSelect} = useContext(DataContext)
  const [uomOptions, setUomOptions] = useState([])
  const [purchaseAccountdata, setpurchaseAccountdata] = useState([])
  const [salesAccountdata, setsalesAccountdata] = useState([])
  const AccountRef = useRef(null) 
  const [isPercentage, setIsPercentage] = useState(true)
  const [variation, setvariation] = useState("0%")
  const [waringModel, setWaringModel] = useState(false)
  const [errorsShow, setErrorsShow] = useState()
  const [seletedSupplier_ , setSeletedSupplier] = useState([])
  const [supplierOption, setSupplierOption] = useState([])
  const [selectedPurchaseuom, setSelectedPurchaseuom] = useState();
  const [selectedAccount_, setSelectedAccount] = useState({value: null, label: null});
  const [selectedSalesAccount, setSelectedSalesAccount] = useState({value: null,label: null});
  // retun null on change Supplier
  const handleSelectSupplier = (options) => {
    if (options) {
      const selectedOptions = options.map(option => ({ value: option.value, label: option.label }));
      setSeletedSupplier(selectedOptions);
    } else {
      setSeletedSupplier([]);
    }
  };

  // retun 0 ; on add AccountSalesSelect after it select in  SelectedAccount
  useEffect(()=>{ 
    if(ItemAccountSelect){  
      setSelectedAccount({ value: ItemAccountSelect.id , label:  ItemAccountSelect.lable })
    
      setFieldValue("Item_Purchase_Account", ItemAccountSelect.id) 
    }
    if (ItemAccountSalesSelect){
      setSelectedSalesAccount({ value: ItemAccountSalesSelect.id , label:  ItemAccountSalesSelect.lable })
      setFieldValue("Item_Sales_Account", ItemAccountSalesSelect.id) 
    }
    if (dupData){
      if (dupData['itemMrp']){
        setFieldValue("Item_Mrp", dupData['itemMrp'])
      }
      if (dupData['itemMinPrice']){
        setFieldValue("Item_min_price", dupData['itemMinPrice'])
      }
      if(dupData['itemSalesAccount']){ 
        setFieldValue("Item_Sales_Account", dupData['itemSalesAccount']['id']) 
        setSelectedSalesAccount({ value: dupData['itemSalesAccount']['id'], label: dupData['itemSalesAccount']['accountsName'] })
      } 
      if (dupData['itemPurchaseAccount']){
        setFieldValue('itemPurchaseAccount', dupData['itemPurchaseAccount']?.['id'])
        setSelectedAccount({ value: dupData['itemPurchaseAccount']?.['id'], label: dupData['itemPurchaseAccount']?.['accountsName'] })
      }  
      if (dupData['itemCost']){
        setFieldValue("Item_Cost", dupData['itemCost'])
       }
      if (dupData?.['purchaseUom']){
      setFieldValue('Purchase_uom', dupData['purchaseUom']?.['id'])
      setSelectedPurchaseuom({ value: dupData['purchaseUom']?.['id'], label: dupData['purchaseUom']?.['name'] })
      }   
      if (dupData?.['variation']){
        setFieldValue("variation", dupData?.['variation'])
       }
    }
  
  // const defaultSupplier =  supplierdetails.find(supplier => supplier.id === Number(dupData?.['supplierData']))
  // if(defaultSupplier){
  //   setseletedSupplier({ value: defaultSupplier.id, label: defaultSupplier.Company_Name })
  // }
    
  },[ItemAccountSelect, ItemAccountSalesSelect, dupData])

  // retun 0; getting data for react select 
  async function getOptions(e, queryName, optionName, accountRef){
     
    let search_term = e.trim().replace(/"/g, '\\"');
    setpurchaseAccountdata([])
    if(queryName === "accountsMasterOptionQueryWithFiliter" && search_term && accountRef === "Sales"){
      let responseData =  await  OnchangeGetData(QueryData[queryName](search_term,"Asset","Income"))
      if(responseData.success){  
        setsalesAccountdata(responseData.data[optionName]['items'])
      } 
    } else{
      setsalesAccountdata([])
    }
    if(queryName === "accountsMasterOptionQueryWithFiliter" && search_term && accountRef === "purchase"){
      let responseData =  await  OnchangeGetData(QueryData[queryName](search_term,"Liabilities","Expenses"))
      if(responseData.success){ 
        setpurchaseAccountdata(responseData.data[optionName]['items'])
      } 
    } else{ 
      setpurchaseAccountdata([])
    }
    if(queryName === "UOMOptionQuery" && search_term){
      let responseData =  await  OnchangeGetData(QueryData[queryName](search_term))
      if(responseData.success){ 
        setUomOptions(responseData.data[optionName]['items'])
      }
    } else{
      setUomOptions([])
    }
    if(queryName === "SupplierOption" && search_term){
      let responseData =  await  OnchangeGetData(QueryData[queryName](search_term))
      if(responseData.success){  
        setSupplierOption(responseData.data[optionName]['items'])
      }  
    }
  }

  //return Number; calculate the FinalPrice 
  const calculateFinalPrice = (mrp, tax) => {
    let taxRate = tax / 100
    const mrpAmount = parseFloat(mrp);
    if (!isNaN(mrpAmount) && !isNaN(tax)) {
      const taxAmount = mrpAmount * taxRate;
      const finalPrice = mrpAmount + taxAmount;
      return finalPrice.toFixed(2); 
    } else {
      return 'Invalid MRP';
    }
  };
  // onCloseClearValue
  function onCloseClearValue(params) {
    values.Item_Cost = 0
    values.Purchase_uom = null
    setSelectedPurchaseuom({ value: "", label: ""})
    values.selectedAccount_ = ""
    setSelectedAccount({ value: "", label: "" })
    values.variation = "0"
    setvariation("0%");
    setFieldValue('supplierData', "");
    handleSelectSupplier(""); 
    values.Item_Mrp = 0
    values.Item_min_price = 0
    values.Item_Sales_Account = "" 
    setSelectedSalesAccount({ value: "" , label: ""})
    setErrorsShow("");
    setSelectedItem_Indicator({ value: "", label:  "" });
    handlebothClose()
    setType("")
  }

  return (
     <>
      <Form>
       <Modal show={Both} onHide={
        // 
       ()=>{ setWaringModel(true)}
      }
         size="lg">
        <Modal.Header closeButton> 
        </Modal.Header>
        <Modal.Body>
            <div>
              <div className="d-flex justify-content-center">
                  <sapn className="text-danger">{errorsShow}</sapn>
              </div>
              <div>
                <h6>Purchase</h6>
                <div className='row'>
                  <div className="col-3 staan-input-group">
                      <Field type='text' name='Item_Cost' className='w-100 staan-form-input '/>
                      <label htmlFor="Item_Cost" className='staan-form-input-label pt-2 px-1 mx-2'>Item Cost </label>
                      {  touched.Item_Cost &&  errors.Item_Cost && <small>{errors.Item_Cost}</small>}
                  </div>
                  <div className="col-2 staan-input-group">
                    <Field type='text' name='variation' value={variation} onChange={(e)=>{ setvariation(e.target.value)
                      setFieldValue('variation', e.target.value)}} className='w-100 staan-form-input '/>
                    <label htmlFor="variation" className='staan-form-input-label pt-1 px-1 mx-2' onClick={()=>{console.log(variation);}}>variation </label>
                    {touched.variation &&  errors.variation && <small>{errors.variation}</small>}
                  </div>
                  <div className="col-1 mt-3">
                    {isPercentage ? <AiOutlinePercentage onClick={() => {
                            setIsPercentage(false);
                            const newValue = `₹${variation?.replace("%", "")}`;
                            setvariation(newValue !== undefined ?  newValue : "");
                            setFieldValue('variation', newValue);
                        }}/> :  
                        <BsCurrencyRupee onClick={() => {
                          setIsPercentage(true);
                          const newValue = `${variation?.replace("₹", "")}%`;
                          setvariation(newValue !== undefined ?  newValue : "");
                          setFieldValue('variation', newValue);
                    }}/>}
                  </div>
                  <div className="col-6 staan-input-group">
                      <Select
                        className='my-3'
                        name="Purchase_uom"
                        onInputChange={(e)=>{getOptions(e,"UOMOptionQuery","uom")}}
                        styles={customSelectStyle}
                        options={purchaseUomOptions?.length > 0? purchaseUomOptions : [] }
                        isSearchable={true}
                        isClearable
                        value = {selectedPurchaseuom}
                        onChange={(option) => {setFieldValue('Purchase_uom', option ? option.value : null)
                        setSelectedPurchaseuom({ value: option?.['value'], label: option?.['label'] })}}
                      />
                      <label htmlFor="Purchase_uom" className='staan-form-input-label pt-1 px-1 mx-2'>Purchase UOM</label>
                    {touched.Purchase_uom &&  errors.Purchase_uom && <small>{errors.Purchase_uom}</small>}
                  </div>
                </div>
              
                <div className='row mt-2'>
                  <div className="col-6 staan-input-group">
                    <Select
                      name="Item_Purchase_Account"
                      onInputChange={(e)=>{getOptions(e, "accountsMasterOptionQueryWithFiliter","accountsMaster","purchase")}}
                      styles={customSelectStyle}
                      value={selectedAccount_}
                      ref={AccountRef}
                      isClearable
                      options={purchaseAccountdata.map((item) => ({ value: item.id, label: item.accountsName }))}
                      isSearchable={true}
                      className='my-3'
                      onChange={(option) => {setFieldValue('Item_Purchase_Account', option ? option.value : null)
                      setSelectedAccount({ value: option?.['value'], label: option?.['label'] })}}

                    />
                    <label htmlFor="Item_Purchase_Account" className='staan-form-input-label pt-2 px-1 mx-2'>Item Purchase Account <i role="button" className='bx bx-plus-circle' onClick={()=>{
                      handlAccountShow()
                      setAccountGroupType("purchase")
                    }} ></i></label>
                    {  touched.Item_Purchase_Account &&  errors.Item_Purchase_Account && <small>{errors.Item_Purchase_Account}</small>}
                  </div>  
                  <div className="col-6 staan-input-group">
                      <Select
                      onInputChange={(e)=>{getOptions(e,"SupplierOption","supplierFormData")}}
                      styles={customSelectStyle}
                      className='my-3'
                      isClearable 
                      isSearchable={true}
                      options = {supplierOption.map((supplier)=>({ value: supplier.id, label: supplier.companyName }))}
                      value={seletedSupplier_} 
                      onChange={(options) => {
                        const selectedValues = options ? options.map(option => option.value) : [];
                        setFieldValue('supplierData', selectedValues);
                        handleSelectSupplier(options);
                      }}
                      isMulti
                      />
                      <label htmlFor="Item_Purchase_Account" 
                      className='staan-form-input-label pt-2 px-1 mx-2'>Suppliers
                      <i role="button" className='bx bx-plus-circle ms-2'
                      onClick= {()=>{handleSupplierShow()}} ></i></label>
                  </div>
                  
                  
                </div>
                
              </div>
              <div className='mt-1'>
                <h6>Selling</h6>
                <div className='row'>
                  <div className="col-6 staan-input-group">
                  <label htmlFor="Item_Mrp" className='staan-form-input-label pt-2 px-1 mx-2'>Item Max Price </label>
                            <Field type='text' name='Item_Mrp'  className='w-100 staan-form-input '/>
                    
                            {  touched.Item_Mrp &&  errors.Item_Mrp && <small>{errors.Item_Mrp}</small>}
                  </div>
                  <div className="col-6 staan-input-group">
                  <label htmlFor="Item_min_price" className='staan-form-input-label pt-2 px-1 mx-2'>Item Min Price </label>
                        <Field type='text' name='Item_min_price'  className='w-100 staan-form-input '/>
                
                        {  touched.Item_min_price &&  errors.Item_min_price && <small>{errors.Item_min_price}</small>}
                  </div>
                  
                </div>
                <div className='row'>
                  <div className="col-6 staan-input-group">
                    <Select
                      name="Item_Sales_Account"
                      onInputChange={(e)=>{getOptions(e, "accountsMasterOptionQueryWithFiliter","accountsMaster","Sales")}}
                      styles={customSelectStyle}
                      options={ salesAccountdata.map((item) => ({
                        value: item.id,
                        label: item.accountsName,
                      }))}
                      isClearable
                      isSearchable={true}
                      value = {selectedSalesAccount}
                      onChange={(option) =>{ setFieldValue('Item_Sales_Account', option ? option.value : null)
                      setSelectedSalesAccount({ value: option?.['value'], label: option?.['label'] })}}
                      className="my-3"
                    />
                    <label htmlFor="Item_Sales_Account" 
                    className='staan-form-input-label pt-1 px-1 mx-2'>Item Sales Account <i className='bx bx-plus-circle'   onClick={()=>{
                        handlAccountShow()
                        setAccountGroupType("sell")
                        
                      }} role="button" ></i></label>
                      {  touched.Item_Sales_Account &&  errors.Item_Sales_Account && <small>{errors.Item_Sales_Account}</small>}
                  </div>
                  <div className="col-3 mt-4 text-start">
                    <span style={{fontSize: '.8rem'}} name="random_text">{values.sell_on_mrp && 'MRP: ' + calculateFinalPrice(values.Item_Mrp, tax)}</span>
                  </div>
                  <div className="col-3 mt-4 text-end">
                    <label style={{fontSize: '.8rem', top: '5px'}} className='pb-1'>Sell on MRP</label>
                    <Field type="checkbox" name="sell_on_mrp" className='ms-3' />
                  </div>
                </div>
              </div>  
           </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" size="sm" onClick={
             ()=>{
                PurchaseSellingSchema.validate(
                {"Item_Cost" : values.Item_Cost,
                "Purchase_uom" : values.Purchase_uom, 
                "Item_Purchase_Account": values.Item_Purchase_Account,
                "variation" : values.variation,
                "Item_Mrp": values.Item_Mrp,
                "Item_Sales_Account" : values.Item_Sales_Account})
                .then(function(validatedData) {
                  setErrorsShow("")
                  handlebothClose()
                })
                .catch(function(err) { 
                    setErrorsShow(err.errors[0])
                }) 
             }
            
            }>
              Save  
          </Button>
        </Modal.Footer>
       </Modal>
      </Form>
      {waringModel && 
      <WaringModel
      state = {waringModel}
      setstate={setWaringModel}
      title={'Confirm'}
      text={'Confirm to Clear Value'}
      Confirmtion={value => {
       onCloseClearValue(value)
     }}
      />}
     </>
  )
}

export default PurchaseSellingModal