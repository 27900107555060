import React from 'react'
import Modal from 'react-bootstrap/Modal'

const DiscountModel = ({isShow, setIsShow, discountValue, setDiscountValue, applyDisCount, ClearDisCount, netAmount}) => {
   
    return (
        <div>
            <Modal show={isShow} backdrop="static"
            onHide={()=>{setIsShow(false)}} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                <h5>Discount</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row mx-1 '>
                <div className='col-12'>
                    <div className='row'>
                    <div className='col-12 text-center'>
                        <p className='text-danger'>{discountValue?.discountError}</p>
                    </div>
                    </div>
                    
                    <div className='row'>
                        <div className='col-4 staan-input-group'>
                            <input
                            type='number'
                            id='OverallDiscountPercentage'
                            value={discountValue?.discountPercentage}
                            onChange={e => {
                                if (100 >= e.target.value && e.target.value >= 0) {
                                    setDiscountValue(prev => ({ ...prev, discountPercentage: e.target.value, discountError:"" }));
                                } else {
                                    setDiscountValue(prev =>({...prev, discountError:"Value must be between 1 to 100"}))
                                }
                            }}
                            disabled={
                                discountValue?.discountValue
                                ? true
                                : discountValue?.discountTotalValue
                                ? true
                                : false || discountValue?.isDiscountApplied
                            }
                            className='staan-form-input text-end pe-3 fw-bold'
                            />
                            <label
                            htmlFor='OverallDiscountPercentage'
                            className='staan-form-input-label pt-1 px-1 mx-2 fw-bold'
                            >
                            Overall Discount Percentage %
                            </label>
                        </div>
                        <div className='col-4 staan-input-group'>
                            <input
                            type='number'
                            id='OverallDiscountValue'
                            value={discountValue?.discountValue}
                            disabled={
                                discountValue?.discountPercentage
                                ? true
                                : discountValue?.discountTotalValue
                                ? true
                                : false || discountValue?.isDiscountApplied
                            }
                            onChange={e => {
                                const value = parseFloat(e.target.value);
                                const validValue = isNaN(value) ? '' : value;
                                if ((netAmount > validValue && validValue >= 0) || validValue==="") {
                                    setDiscountValue(prev => ({ ...prev, discountValue: validValue, discountError: "" }));
                                } else {
                                    setDiscountValue(prev => ({ ...prev, discountError: `Value must be between 1 to ${netAmount}` }));
                                }
                            }}
                            
                            className='staan-form-input text-end pe-3 fw-bold'
                            />
                            <label
                            htmlFor='OverallDiscountValue'
                            className='staan-form-input-label pt-1 px-1 mx-2 fw-bold'
                            >
                            Overall Discount Value
                            </label>
                        </div>
                        <div className='col-4 staan-input-group'>
                            <input
                            type='number'
                            id='FinalTotal'
                            value={discountValue?.discountTotalValue}
                            disabled={
                                discountValue?.discountPercentage
                                ? true
                                : discountValue?.discountValue
                                ? true
                                : false  || discountValue?.isDiscountApplied
                            }
                            onChange={e => {
                                const value = parseFloat(e.target.value);
                                const validValue = isNaN(value) ? '' : value;
                                if ((netAmount > validValue && validValue >= 0) || validValue==="") {
                                    setDiscountValue(prev=>({...prev, discountTotalValue:e.target.value, discountError:""}))
                                } else {
                                    setDiscountValue(prev =>({...prev, discountError:`Value must be between 1 to ${netAmount}`}))
                                }
                            }}
                            className='staan-form-input text-end pe-3 fw-bold'
                            />
                            <label
                            htmlFor='FinalTotal'
                            className='staan-form-input-label pt-1 px-1 mx-2 fw-bold'
                            >
                            Final Total
                            </label>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mb-2'>
                    <div>
                        <button
                        type='button'
                        className='btn btn-sm btn-outline-danger mt-3 me-2 px-5'
                        disabled={!discountValue?.isDiscountApplied}
                        onClick={() => {
                            ClearDisCount();
                        }}
                        >
                        Clear
                        </button>
                        <button
                        type='button'
                        className='btn btn-sm btn-outline-success mt-3 ms-3'
                        disabled={discountValue?.isDiscountApplied}
                        onClick={() => {
                            applyDisCount()
                        }}
                        >
                        Apply Discount
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            </Modal.Body>
            </Modal>
        </div>
    )
}

export default DiscountModel