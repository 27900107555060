import React, { useContext, useState } from 'react';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import DataContext from '../../context/ItemMasterContext';
import TableView from '../../Packages/Custom/DataTable';
import AddAccountGroup from '../../Page/ItemMaster/model/AddAccountGroup';



const AccountGroup = () => {
    const {handlAccountGroupShow} = useContext(DataContext)
    
    const [AccountEdit , setAccountEdit] = useState()
    const [isLoad, setLoad] = useState(false)
 
  
    const CustomViewModalComponent = (data)=>{
      handlAccountGroupShow()
      setAccountEdit(data); 
    }
    const headers = [
      {
        header: 'Name',
        field: 'accountsGroupName', 
        flex: 10,
        query: 'accountGroupOption',
        inputType: 'text',
        renderComponent: CustomViewModalComponent,
      },
      {
        header: 'Type',
        field: 'accountsType', 
        label : "name",
        flex: 10,
        option: true,
        query: 'AccountGrouptypeOptionQuery',
        inputType: 'text', 
      },
      {
        header: 'Active',
        field: 'groupActive', 
        flex: 10, 
        bool: true,
        option: true,
        inputType: 'text', 
      }
    ]
 
       
 
  const CustomAddModalComponent = () => {
    handlAccountGroupShow()
  }
  return (
    <>
    <div className=' index_page_aliment_warp'>
        <div className='index_page_aliment_sidebar'>
            <SideNavbar />
        </div>
        <div className='index_page_aliment_table'>
            <TableView
            headers={headers}
            query = {'AccountGroupTableQuery'}
            addComponent = {CustomAddModalComponent}
            loadComponent = {isLoad}
            titleName = {'Account Group'}
            heightAdjust = {'40rem'}
            permission={'Save_Account_Group'}
            customFilter={true}
            />
        </div>
    </div>
      <AddAccountGroup AccountEdit={AccountEdit}
      setAccountEdit ={setAccountEdit}
      setLoad = {()=>{setLoad(!isLoad)}}
        />
    </>
  )
}

export default AccountGroup