import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaRegCircleXmark } from "react-icons/fa6";
import axiosInstance from '../../../api/axoiss';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import BallTriangleComponent from '../../../components/loading/BallTriangle';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import DataContext from '../../../context/ItemMasterContext';
import { allowedPermissionCreateMutation, profileCreateMutation } from '../../../GraphQLQueries/userQueryandMutations/userQuerys';
import { profileDeleteMutation } from '../../../GraphQLQueries/userQueryandMutations/UsersMutations';
import { removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import '../Profile.css';

const ProfileForm = ({ isShow, setIsShow,setLoad,editData, setEditData }) => {
    const {userId, handltoastDeleteConfomationShow, permissionModelList, screenWidth} = useContext(DataContext)
    const [initialValue, setInitialValue] = useState({
        id: "",
        profileName: "",
        descriptions: "",
        modifiedBy: "",
        createdBy: "",
    });
    const [loading, setLoading] = useState(false)
    const [deleteData, setdeleteData] = useState({  Name:"",id:""})
 
    const [model, setModel] = useState(permissionModelList);
    const [edit, setEdit]= useState(true)
    useEffect(()=>{
        setLoading(true)
        if (isShow && editData){ 
        
            setInitialValue({
                id:editData?.['id']? Number(editData?.['id']) : "",
                profileName:editData?.['profileName'],
                descriptions : editData?.['description'],
                createdBy : editData?.createdBy?.id ? Number(editData?.createdBy?.id) : ""
            }) 
            UpdateTheModelinEdit(permissionModelList, editData?.allowedPermission)
            setEdit(true) 
        } else{
            setEdit(false)
            addCheckedProperty(false)
        }
        setLoading(false)
    },[isShow, editData])
    const handledeleteConfomation = (data) => {
        if (data){
            handltoastDeleteConfomationShow();
            setdeleteData({
            Name:data?.profileName,
            id: data?.id, 
            });  
        }
      };
    // 
    function UpdateTheModelinEdit(original, edit) {
        // Create a mapping of details from the edit array
        const detailsMap = edit.reduce((map, item) => {
            let model_name = item.permissionModel?.modelName
            let permissionModel_id = item.permissionModel?.id 
            
            
            if (!map[model_name]) {
                map[model_name] = {
                    allowedIds: new Set(),
                    permissionModel : permissionModel_id,
                    id: item.id,
                    createdBy: item.createdBy
                };
            }
            item.permissionOptions.forEach(permission => {
                map[item.modelName].allowedIds.add(permission.id);
            });
            return map;
        }, {});
    
        // Update the original models based on the details map
        original.forEach(model => {
            const details = detailsMap[model.modelName];
            if (details) {
                model.id = details?.id;
                model.createdBy = details?.createdBy;
                model.permissionModel = details?.permissionModel
                model.permissionOptions.forEach(permission => {
                    permission.checked = details.allowedIds.has(permission.id);
                });
                model['old'] = true
            } else { 
                model.createdBy = ""
                model.permissionOptions.forEach(permission => {
                    permission.checked = false;
                });
            }
        }); 
        
        // Assuming setModel is a function to update the model state or similar
        setModel(original);
    }
    

    const handleModelCheckboxChange = (modelId, isAllowed, item) => {
 
        
        if (isAllowed){ 
            
            setModel(prevModel =>
                prevModel.map(modelItem => {
                    if (modelItem.id === modelId && modelItem?.old ){ 
                        
                          return  {...modelItem, checked: !modelItem.checked}
                             
                    } else{
                        return { ...modelItem, checked: false } // Ensure others are unchecked
                    }
                })
            ); 
           
        } else{
            setModel(prevModel =>
                prevModel.map(modelItem => { 
                    
                    if (modelItem.id === modelId && !modelItem?.old ){  
                          return  {...modelItem, checked: !modelItem.checked}
                             
                    } else{
                        return { ...modelItem, checked: false } // Ensure others are unchecked
                    }
                })
            ); 
        }
       
    };
    

    const handlePermissionCheckboxChange = (modelId, optionId) => {
        setModel(prevModel =>
            prevModel.map(modelItem =>
                modelItem.id === modelId
                    ? {
                        ...modelItem,
                        permissionOptions: modelItem.permissionOptions.map(option =>
                            option.id === optionId
                                ? { ...option, checked: !option.checked } // Toggle the checked state
                                : option
                        )
                    }
                    : modelItem
            )
        );
    };
    const handleCheckboxClearValue = (modelId) => {
        setModel(prevModel =>
            prevModel.map(modelItem =>
                modelItem.id === modelId
                    ? {
                        ...modelItem,
                        permissionOptions: modelItem.permissionOptions.map(option => ( { ...option, checked: false } )// Toggle the checked state
                                
                        )
                    }
                    : modelItem
            )
        );
    };
  

    function Close() {   
            setIsShow(false);
            setInitialValue({
                id: "",
                profileName: "",
                descriptions: "",
                modifiedBy: "",
                createdBy: "",
            })
            setModel(permissionModelList)
            setEditData(null) 
    }

    async function SaveTheAllowedPermission(params) {
        let SavedAllowPermissionIds = []
        for (let x of model) {
            let permissions =  x?.permissionOptions
            ?.filter(item => item?.checked) // Get only items where checked is true
            .map(item => Number(item?.id)) 
            console.log(x);
            
              
            if (permissions?.length > 0){  
                let saveallowedPermission = {
                    id: x?.old ? (x?.id ? Number(x?.id) : "") : "",
                    permissionModel : !x?.old ? Number(x?.id) : Number(x?.permissionModel),
                    modelName: x?.modelName || "",  // Assuming modelName is always present or default to empty string
                    permissionOptions: permissions,  // Ensure permissions is defined elsewhere
                    modifiedBy: x?.old ? (x?.id ? Number(userId) : "") : "",
                    createdBy: Number(userId)
                    // x?.old
                    //     ? (x?.id ? Number(x?.createdBy?.id) : Number(userId)) 
                    //     : Number(userId)
                };
                
                
                let ClearValue = removeEmptyValueInObject(saveallowedPermission, ["id", "modifiedBy", "createdBy"])
                try{
                    const response = await axiosInstance.post(`/itemmaster/graphql`, {
                        query: allowedPermissionCreateMutation(ClearValue)
                    });
                    const responseData = response?.data?.data?.allowedPermissionCreateMutation;
                    if (responseData && responseData?.allowedPermissionInstance?.id){
                        SavedAllowPermissionIds.push(responseData?.allowedPermissionInstance?.id)
                    }
                } catch(error){
                    console.log(error);
                }
            } 
        } 
        return SavedAllowPermissionIds 
    }

    const  handleSubmit = async(e)=>{
        e.preventDefault(); 
        setLoading(true)
        if (initialValue['profileName']?.trim() === "" || initialValue['profileName'] === null){
            showErrorToast(true, "error", "Profile Name is required",  )
            return ""
        }
        let allowPermissionIds = await SaveTheAllowedPermission() 
          
        if (allowPermissionIds?.length <=0 || allowPermissionIds?.length === undefined ){
             showErrorToast(true, "error", "Permission  required",  )
            return ""
        } else{
            let saveallowedPermisson = {
                id : initialValue?.id,
                profileName : initialValue['profileName'],
                description : initialValue['descriptions'],
                allowedPermission :allowPermissionIds, 
                createdBy : initialValue?.id ? initialValue?.createdBy : Number(userId)  ,
                modifiedBy : initialValue?.id ? Number(userId) : ""
            }
            let ClearValue = removeEmptyValueInObject(saveallowedPermisson, ["id", "modifiedBy", "createdBy","description"])
            try{
                const response = await axiosInstance.post(`/itemmaster/graphql`, {
                    query: profileCreateMutation(ClearValue)
                });
                const responseData = response?.data?.data?.profileCreateMutation;
                if (responseData.success){
                    try {
                        setLoad()
                        Close()
                        showErrorToast(true, "success", "", "Saved Successfully" )
                    } catch (error) {
                        let errorData = ShowErrorNoties(error); 
                        showErrorToast(true, "error", errorData , "" ) 
                    }
                } else{
                    let errorData = ShowErrorNoties(responseData?.errors); 
                    showErrorToast(true, "error", errorData , "" )
                }
            } catch(error){
                let errorData = ShowErrorNoties(error);
                showErrorToast(true, "error", errorData , "" )
            }
        }
        setLoading(false)
    }
    const handleDeletedId = (deleted_id) =>{ 
        if (deleted_id){ 
                Close()  
                setEdit(false)
                setLoad()
        } 
        }
    function addCheckedProperty(value) {
        let updatelist =[]
             model?.map(model => {
                // Check if model has permissionOptions property
                if (model?.permissionOptions) {
                    // Add checked = true to each permission option
                    model.permissionOptions = model?.permissionOptions.map(option => ({
                        ...option,
                        checked: value
                    }));
                    updatelist.push(model)
                } 
                
            });
            setModel(updatelist)
        }
    return (
        <>
            {<BallTriangleComponent isshow={loading}/>}
            <Modal className='PermissionsFrom' show={isShow} backdrop='static' keyboard={false}  fullscreen={screenWidth< 1200} size={screenWidth> 1200 ? "xl" : "" } style={{height:"100%"}}>
                <div className='row'>
                    <div className='col-5 m-3' style={{ color: '#5CADA9' }}>
                        <Modal.Title>
                            <i className='fa-solid fa-arrow-left fa-sm me-2' onClick={Close}></i>
                            Profile
                        </Modal.Title>
                    </div>
                    <div className='col-6 mt-4 text-end'>
                        <i className="fa-solid fa-pen fs-5 text-primary pe-3" 
                        hidden={!(editData && edit)} 
                        onClick={()=>{setEdit(!edit)}}  ></i>
                        <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={!(editData && edit)} onClick={()=>{handledeleteConfomation(editData)}} ></i> 
                    </div>
                    <hr />
                </div>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-6 staan-input-group">
                                <input
                                    type='text'
                                    name='profileName'
                                    id='profileName'
                                    className='w-100 staan-form-input'
                                    disabled={editData && edit} 
                                    value={initialValue?.profileName}
                                    onChange={(e)=>{setInitialValue(prve=>({...prve, profileName:e.target.value}))}} 
                                />
                                <label htmlFor="profileName" className='staan-form-input-label pt-0 px-1 mx-2' onClick={()=>{console.log(permissionModelList)
                                }} 
                                
                               >
                                    Profile Name <span className='text-danger'>*</span>
                                </label>
                            </div>
                            <div className="col-6 staan-input-group">
                                <input
                                    type='text'
                                    name='descriptions'
                                    disabled={editData && edit} 
                                    id='descriptions'
                                    className='w-100 staan-form-input'
                                    value={initialValue?.descriptions}
                                    onChange={(e)=>{setInitialValue(prve=>({...prve, descriptions:e.target.value}))}}
                                     
                                />
                                <label htmlFor="descriptions" className='staan-form-input-label pt-0 px-1 mx-2'>
                                    Description
                                </label>
                            </div>
                            <div className='m-2 text-end'>
                                <sapn><button type="button" class="btn btn-outline-success me-1" onClick={()=>{addCheckedProperty(true)}}>Select All</button></sapn>
                                <span><button type="button" class="btn btn-outline-secondary" onClick={()=>{addCheckedProperty(false)}}>Unselect All</button></span>
                            </div>
                            <div className="mt-4" style={{height:"50%", overflow:"scroll"}}> 
                                <div className='Permissions_warp'>
                                    <ul className='PermissionsList ps-0'>
                                        {model?.map(item => (
                                            <li key={item.id} style={item.checked? {position:"relative"}:{}}>
                                                <div className=" ">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <div className=" " onClick={()=>{console.log(item.permissionOptions?.filter(option => option.checked))
                                                            }}>
                                                            {String(item.modelName).replace("_"," ")}
                                                            </div>
                                                        </div>
                                                        <div className="col-1">
                                                            <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                disabled={editData && edit}
                                                                type="checkbox"
                                                                id={`model-${item.id}`}
                                                                onChange={() => handleModelCheckboxChange(item.id, item?.old, item)}
                                                                checked={item.permissionOptions?.filter(option => option.checked).length > 0 || item.checked ||  false}
                                                            />
                                                           
                                                            </div>
                                                           
                                                        </div>
                                                        <div  className={`selecteValue ${item.checked? 'active' : '' } col-8 align-bottom d-flex justify-content-between` } onClick={() => handleModelCheckboxChange(item.id, item?.old, item)}>
                                                            <div >
                                                            {item.permissionOptions?.filter(option => option.checked) // Filter checked permissions
                                                            .map(option => (
                                                                <span key={option.id}>
                                                                    {option.optionsName}, <span>  </span>
                                                                </span>
                                                            ))}
                                                              
                                                            </div>
                                                            <FaRegCircleXmark  onClick={()=>{handleCheckboxClearValue(item.id)}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul key={item.id} className='sub_PermissionsList shadow' hidden={!item.checked} >
                                                    {item.permissionOptions.map(option => (
                                                        <li key={option.id} >
                                                            <div className="d-flex">
                                                                <div className=" text-center pe-3">
                                                                    <input
                                                                        type="checkbox"
                                                                        name={option.optionsName}
                                                                        id={`option-${option.id}`}
                                                                        checked={option.checked || false}
                                                                        onChange={() => handlePermissionCheckboxChange(item.id, option.id)}
                                                                    />
                                                                </div>
                                                                <div className="modelname">
                                                                    {option.optionsName}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                        <div className='text-end'>
                            <button type="button" className="btn btn-outline-danger btn-sm me-2"
                                onClick={Close}
                                >Cancel</button>
                            <button type="submit" className="btn btn-sm btn-outline-success shadow-sm" disabled={editData && edit } >Save</button>
                        </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            {deleteData && 
            <DeleteConformation
            handleDeletedId={(id)=>{handleDeletedId(id)}}
            deleteData={deleteData}
            fullquery={profileDeleteMutation(deleteData?.id)} 
            />}
        </>
    );
};

export default ProfileForm;