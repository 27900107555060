export const salesOrder2ItemDetailsCreateMutations = (data) => {
    // states: "Tamil nadu" , afterDiscountValueForPerItem: null  amount: "100" 
    // createdBy: 3 , discountPercentage: null  , discountValue: null 
    // id: ""  ,  itemmaster: 67862  , modifiedBy: null , qty: "2"  , rate: "50"
    // uom: 13
    return`
        mutation MyMutation {
        salesOrder2ItemDetailsCreateMutations(${data}) {
        errors
        itemCombo
        itemComboData
        success
        salesOrder2ItemDetails {
        afterDiscountValueForPerItem
        hsn{
            hsnCode
          }
        description
        amount
        cgst
        createdAt
        discountPercentage
        discountValue
        finalValue
        id
        itemmaster{
          id
          itemPartCode
          itemName
        }
        qty
        rate
        igst
        sgst
        tax
        updatedAt
        uom{
          id
          name
        }
          itemCombo
      itemComboItemDetails{
         id
      createdBy{
        id
      }
      qty
      itemmaster{
        itemPartCode
        itemName
        id
      }
      uom{
          id
          name
        }
      amount
      afterDiscountValueForPerItem
      isMandatory
      display
      rate
      }
        createdBy{
          id
          username
        }
        modifiedBy{
          id
          username
        }
      }
        }
    }
    `}


export const salesOrder2otherincomeCharges = (data)=>{
  // amount: "2000" createdBy: 3 id: "" states: "" modifiedBy: null otherIncomeChargesId: 1
  return `
  mutation MyMutation {
  salesOrder2OtherIncomeChargesCreateMutations(${data}) {
    errors
    success
    salesOrder2OtherincomeCharges {
      otherIncomeChargesId{
        id
        name
      }
        createdBy{
        id
      }
       currency{
         Currency{
          currencySymbol
        }
      }
      afterDiscountValue
      discountValue
      tax
      amount
      createdAt
      igst
      cgst
      sgst
      id
    }
  }
} `
}

export const salesOrder2CreateMutation = (data)=>{
  return `
   mutation MyMutation {
  salesOrder2CreateMutation(${data}) {
    errors
    success
    outOfRangeErrors
    waring
    SalesOrder2{
        id
            CreatedAt
            salesOrderDate
            status{
            id
            name
            }
            leadNo{
            id
            leadNo
            }
             currency{
            id
            Currency{
              name
              currencySymbol
            }
            }
            quotations{
            id
            quotationNo{
              linkedModelId
            }
            }
            active
            childCount
            salesOrderNo{
            linkedModelId
            }
            salesPerson{
                id
                username
            }
            dueDate
            creditPeriod
            paymentTerms
            customerPoNo
            customerPoDate
            department{
                id
                name
            }
            buyer{
                id
                companyName
                supplierNo
            }
            buyerAddress{
                id
                addressType
                addressLine1
                addressLine2
                city
                state
                country
                pincode
            }
            buyerContactPerson{
                id
                contactPersonName
                phoneNumber
                email
            }
            buyerGstinType
            buyerGstin
            buyerState
            buyerPlaceOfSupply
            consignee{
                id
                companyName
                supplierNo
            }
            consigneeAddress{
                id
                addressType
                addressLine1
                addressLine2
                city
                state
                country
                pincode
            }
            consigneeContactPerson{
                id
                contactPersonName
                phoneNumber
                email
            }
            consigneeGstinType
            consigneeGstin
            consigneeState
            consigneePlaceOfSupply
            overallDiscountPercentage
          	overallDiscountValue
          	discountFinalTotal
            itemDetails{
                afterDiscountValueForPerItem
                description
                amount
                cgst
                createdAt
                discountPercentage
                discountValue
                finalValue
                id
                itemmaster{
                id
                itemHsn{
                  gstRates{
                    rate
                    }
                  hsnCode
                  id
                }
                itemMrp
                itemPartCode
                itemName
                }
                qty
                rate
                igst
                sgst
                tax
                updatedAt
                uom{
                id
                name
                }
                createdBy{
                id
                username
                }
                modifiedBy{
                  id
                }
              	itemCombo
                itemComboItemDetails{
                  id
                createdBy{
                  id
                }
                qty
                uom{
                    id
                    name
                  }
                amount
                afterDiscountValueForPerItem
                isMandatory
                display
                rate
                }
            }
            otherIncomeCharge{
                otherIncomeChargesId{
                id
                name
                hsn{
                    id
                    hsnCode
                }
            }
                createdBy{
                id
            }
            afterDiscountValue
            discountValue
            tax
            amount
            createdAt
            igst
            cgst
            sgst
            id
            }
            termsConditions{
            id
            name
            }
            termsConditionsText
            CreatedAt
            createdBy{
            id
            username
            }
            itemTotalBeforTax
            otherChargesBeforTax
            taxTotal
            roundOff
            netAmount
            igst
          	cgst
          	sgst
    }
  version{versionList}
  }
}   `
}

export const salesOrder2OverallDiscountPercentage = (data)=>{
  return `
    mutation MyMutation {
  salesOrder2OverallDiscountPercentage(${data}) {
    errors
    success
    SalesOrderItemDetails{
    hsn{
            hsnCode
          }
      afterDiscountValueForPerItem
      description
                amount
                cgst
                createdAt
                discountPercentage
                discountValue
                finalValue
                id
                itemmaster{
                id
                itemPartCode
                itemName
                }
                qty
                rate
                igst
                sgst
                tax
                updatedAt
                itemCombo
                itemComboItemDetails{
         id
      createdBy{
        id
      }
      qty
      itemmaster{
        itemPartCode
        itemName
        id
      }
      uom{
          id
          name
        }
      amount
      afterDiscountValueForPerItem
      isMandatory
      display
      rate
      }
                uom{
                id
                name
                }
                createdBy{
                id
                username
                }
    }
    SalesOrderOtherincomeChanges{
      otherIncomeChargesId{
                id
                name
            }
                createdBy{
                id
            }
                 discountValue
      afterDiscountValue
            tax
            amount
            createdAt
            igst
            cgst
            sgst
            id
    }
  }
}
  `
}

export const salesOrder2OverallOverallDiscountValue = (data)=>{
  return `
    mutation MyMutation {
    SalesOrder2_OverallDiscountValue(${data}) {
    errors
    success
    SalesOrderItemDetails{
      hsn{
            hsnCode
          }
      afterDiscountValueForPerItem
      description
                amount
                cgst
                createdAt
                discountPercentage
                discountValue
                finalValue
                id
                itemmaster{
                id
                itemPartCode
                itemName
                }
                qty
                rate
                igst
                sgst
                tax
                updatedAt
                uom{
                id
                name
                }
      itemCombo
                itemComboItemDetails{
         id
      createdBy{
        id
      }
      qty
      itemmaster{
        itemPartCode
        itemName
        id
      }
      uom{
          id
          name
        }
      amount
      afterDiscountValueForPerItem
      isMandatory
      display
      rate
      }
                createdBy{
                id
                username
                }
    }
    SalesOrderOtherincomeChanges{
      otherIncomeChargesId{
                id
                name
            }
                createdBy{
                id
            }
                 discountValue
      afterDiscountValue
            tax
            amount
            createdAt
            igst
            cgst
            sgst
            id
    }
  }
  }

  `
}

export const salesOrder2FinalTotalDiscount = (data)=>{
  return `
   mutation MyMutation {
    salesOrder2FinalTotalDiscount(${data}) {
    errors
    success SalesOrderItemDetails{
      hsn{
            hsnCode
          }
      afterDiscountValueForPerItem
      description
                amount
                cgst
                createdAt
                discountPercentage
                discountValue
                finalValue
                id
                itemmaster{
                id
                itemPartCode
                itemName
                }
                qty
                rate
                igst
                sgst
                tax
                updatedAt
                uom{
                id
                name
                }
        itemCombo
                itemComboItemDetails{
         id
      createdBy{
        id
      }
      qty
      itemmaster{
        itemPartCode
        itemName
        id
      }
      uom{
          id
          name
        }
      amount
      afterDiscountValueForPerItem
      isMandatory
      display
      rate
      }
                createdBy{
                id
                username
                }
    }
    SalesOrderOtherincomeChanges{
      otherIncomeChargesId{
                id
                name
            }
                createdBy{
                id
            }
                 discountValue
      afterDiscountValue
            tax
            amount
            createdAt
            igst
            cgst
            sgst
            id
    }
  }
  }
  `
}


export const salesOrder2ClearDiscount = (data)=>{
  // itemIdList:[46,47], otherIncomeChargeIdList:[36],
  return `  mutation MyMutation {
  salesOrder2ClearDiscount(${data}) {
    errors
    success
       SalesOrderItemDetails{
       hsn{
            hsnCode
          }
       description
      afterDiscountValueForPerItem
      amount
      cgst
      createdAt
      discountPercentage
      discountValue
      finalValue
      id
      itemmaster{
      id
      itemPartCode
      itemName
}
      qty
      rate
      igst
      sgst
      tax
      updatedAt
      uom{
      id
      name
}
      createdBy{
                id
                username
              }
      itemCombo
      itemComboItemDetails{
         id
      createdBy{
        id
      }
      qty
      itemmaster{
        itemPartCode
        itemName
        id
      }
      uom{
          id
          name
        }
      amount
      afterDiscountValueForPerItem
      isMandatory
      display
      rate
      }
    }
    SalesOrderOtherincomeChanges{
      otherIncomeChargesId{
                id
                name
            }
                createdBy{
                id
            }
                 discountValue
      afterDiscountValue
            tax
            amount
            createdAt
            igst
            cgst
            sgst
            id
    }
  }
}`
}

export const salesOrder2ItemcomboItemDetailsCreateMutations = (data)=>{
  // [{afterDiscountValueForPerItem: null, amount: null, createdBy: 10, display: "", id: null, isMandatory: false, itemmaster: 10, modifiedBy: 10, parentItemdetailId: 1, qty: null, rate: null, uom: 10},
  //   {afterDiscountValueForPerItem: null, amount: null, createdBy: 10, display: "", id: null, isMandatory: false, itemmaster: 10, modifiedBy: 10, parentItemdetailId: 1, qty: null, rate: null, uom: 10}]
  return `
  mutation MyMutation {
  salesOrder2ItemcomboItemDetailsCreateMutations(
    items: ${data}) {
    errors
    success
    parent{
        afterDiscountValueForPerItem
        description
        amount
        cgst
        createdAt
        discountPercentage
        discountValue
        finalValue
        id
        itemmaster{
          id
          itemPartCode
          itemName
        }
        qty
        rate
        igst
        sgst
        tax
        updatedAt
        uom{
          id
          name
        }
        createdBy{
          id
          username
        }
        modifiedBy{
          id
          username
        }
          itemCombo
      itemComboItemDetails{
         id
      createdBy{
        id
      }
      qty
      itemmaster{
        itemPartCode
        itemName
        id
      }
      uom{
          id
          name
        }
      amount
      afterDiscountValueForPerItem
      isMandatory
      display
      rate
      }
      }
  }
}`
}


export const salesOrder2Amand = (data)=>{
  // id: "21", userId: 3
  return ` mutation MyMutation {
  salesOrder2Amand(${data}) {
    errors
    success
    itemDetails {
    description
        hsn{
            hsnCode
          }
        afterDiscountValueForPerItem
        amount
        cgst
        createdAt
        discountPercentage
        discountValue
        finalValue
        id
        itemmaster{
          id
          itemPartCode
          itemName
        }
      	itemCombo
      	itemComboItemDetails{
         id
        createdBy{
          id
}
        qty
        itemmaster{
          itemPartCode
          itemName
          id
}
        uom{
            id
            name
}
        amount
        afterDiscountValueForPerItem
        isMandatory
        display
        rate
        }
        qty
        rate
        igst
        sgst
        tax
        updatedAt
        uom{
          id
          name
        }
        createdBy{
          id
          username
        }
    }
    otherIncomeCharge {
       otherIncomeChargesId{
        id
        name
      }
        createdBy{
        id
      }
      tax
      amount
      createdAt
      igst
      cgst
      sgst
      id
    }
  }
}`
}

export const salesOrder2BulkUpdateForTaxmutations = (data)=>{
  // states: "" itemDetialsIds: 10  otherIncomeChargesIds: 10
  return `mutation MyMutation {
  salesOrder2BulkUpdateForTax(${data}) {
    errors
    success
    SalesOrderItemDetails{
      hsn{
            hsnCode
          }
      description
      afterDiscountValueForPerItem
        amount
        cgst
        createdAt
        discountPercentage
        discountValue
        finalValue
        id
        itemmaster{
          id
          itemPartCode
          itemName
        }
        qty
        rate
        igst
        sgst
        tax
        updatedAt
        uom{
          id
          name
        }
        createdBy{
          id
          username
        }
        modifiedBy{
          id
          username
        }
    }
    SalesOrderOtherincomeChanges{
      otherIncomeChargesId{
        id
        name
      }
        createdBy{
        id
      }
      tax
      amount
      createdAt
      igst
      cgst
      sgst
      id
    }
  }
}`
}


export const salesOrder2ItemDetailsDeleteMutation = (data)=>{
  // id: "1"
  return `
  mutation MyMutation {
  salesOrder2ItemDetailsDeleteMutation(${data}) {
    errors
    success
  }
} `
}

export const salesOrder2OtherIncomeChargesDeleteMutation = (data)=>{
  // id: "1"
  return `mutation MyMutation {
salesOrder2OtherIncomeChargesDeleteMutation(${data}){
  errors
  success
}
}`
}

export const salesOrder2AmandCancel = (data)=>{
  // itemDetails: 10, otherIncomeCharge: 10
  return `mutation MyMutation {
  salesOrder2AmandCancel(${data}) {
    errors
    success
  }
} `
}


export const salesOrder2CanceledMutation = (data)=>{
  // id: ""
  return `mutation MyMutation {
  salesOrder2CanceledMutation(${data}) {
    errors
    success
  }
}`
}

export const salesOrder2DeleteMutation = (data)=>{
  // id: ""
  return `mutation MyMutation {
  salesOrder2DeleteMutation(${data}) {
    errors
    success
  }
}`
}

export const salesOrder2CurrencyConvertions = (data)=>{
  // currentCurrency: 10 ,itemIdList: 10 , otherIncomeChargeIdList: 10
  return `mutation MyMutation {
  salesOrder2CurrencyConvertions(${data}) {
    errors
    success
    SalesOrderItemDetails {
      hsn{
            hsnCode
          }
      afterDiscountValueForPerItem
      description
                amount
                cgst
                createdAt
                discountPercentage
                discountValue
                finalValue
                id
                itemmaster{
                id
                itemPartCode
                itemName
                }
                qty
                rate
                igst
                sgst
                tax
                updatedAt
                itemCombo
                itemComboItemDetails{
         id
      createdBy{
        id
      }
      qty
      itemmaster{
        itemPartCode
        itemName
        id
      }
      uom{
          id
          name
        }
      amount
      afterDiscountValueForPerItem
      isMandatory
      display
      rate
      }
                uom{
                id
                name
                }
                createdBy{
                id
                username
                }
    }
    SalesOrderOtherincomeChanges {
        otherIncomeChargesId{
                id
                name
            }
                createdBy{
                id
            }
        discountValue
      	afterDiscountValue
            tax
            amount
            createdAt
            igst
            cgst
            sgst
            id
    }
  }
}`
}

export const GeneratePdfForSalesOrder = (data)=>{
  return`
  mutation MyMutation {
  GeneratePdfForSalesOrder(${data}) {
    errors
    pdfData
    success
  }
}
  `

}