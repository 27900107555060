import CommanTableView from "./CommanTable";

const CommanTable = ({ headers, title,rowData,inputSection,autoAdjust,handleautoAdjust,newRowAdd,getDeleteRowId,isOpenModal,handleopenModal,checkBox,handleChecked,cellRenderer,getEditRow,tooltipMessage,isDisabled}) => {


    return (
        <div style={{fontSize: "14px !important",}}   >
            <CommanTableView
                title={title}
                headers={headers}
                rowData={rowData}
                inputSection = {inputSection}
                handleautoAdjust={handleautoAdjust}
                autoAdjust={autoAdjust}
                newRowAdd={newRowAdd}
                getDeleteRowId={getDeleteRowId}
                isOpenModal={isOpenModal}
                handleopenModal={handleopenModal}
                checkBox={checkBox}
                handleChecked={handleChecked}
                cellRenderer={cellRenderer}
                getEditRow={getEditRow}
                tooltipMessage={tooltipMessage}  
                isDisabled={isDisabled} 
            />
        </div>
    )
}

export default CommanTable;