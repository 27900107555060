import * as  yup from "yup";

export const call_activites_schema= yup.object().shape({
    subject: yup.string().required('Subject is required'),
    outcome: yup.string().required('Outcome is required'),
    salesperson : yup.number().required('Sales Person is required'),
})

export const meeting_activites_schema = yup.object().shape({
    subject: yup.string().required('Subject is required'),
    planedStartDate : yup.date().required('Planed Start Date is required'),
    PlanedEndDate : yup.date().required('Planed End Date is required'),
    planedStartTime: yup
    .string()
    .required('Planned Start Time is required'),
    
    PlanedEndTime: yup
        .string()
        .required('Planned End Time is required'),
        
    // StartDate : yup.date().required('Start Date is required'),
    // EndDate : yup.date().required('End Date is required'),
    // StartTime: yup
    // .string()
    // .required('Start Time is required'),
    
    // EndTime: yup
    //     .string()
    //     .required('End Time is required'),
        
    salesperson : yup.number().required('Sales Person is required'),
    status : yup.number().required('Status is required'),
    // outcome : yup.string().required("Outcome is required")
})