import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { filterValidation } from '../../../../validations/customfiltervalidation';
import { customSelectStyle, removeEmptyValueInObject, ShowErrorNoties } from '../../../../utils/Utils';
import Select from 'react-select';
import './customeFilter.css';
import DataContext from '../../../../context/ItemMasterContext';
import axiosInstance from '../../../../api/axoiss';
import { toast } from 'react-toastify';
import showErrorToast from '../../../../components/notifications/react-toastify/toast';
import { customfiltercreatemutation, customfilterDeletemutation } from '../../../../GraphQLQueries/customfilterQueryandMutation';
import BallTriangleLoading from "../../../../components/loading/BallTriangle";
import WaringModel from '../../../../components/Warings/WaringModel';


const CustomFilterModal = ({ isFilterModalOpen, setFilterModalOpen, filtersData, columnsEnabled, titleName, editData, seteditData, settableListSelect, settableList, clearSelectedData }) => {
    const { allUsersList, CommanFetchForAllUser, userId } = useContext(DataContext)
    const [loading, setLoading] = useState(false)
    const [filterInitialValue, setfilterInitialValue] = useState({
        id: '',
        createdBy: '',
        modifiedBy: '',
        viewName: '',
        visibleTo: '',
        visibleToUser: '',
        tableName: titleName,
        isDefault: true
    })
    const [filterValues, setfilterValues] = useState({
        visibleToSelect: '',
        visibleToUserSelect: [],
        visibleToUserOptionSelect: [],
        isDefault: true
    })
    const [filterOptionList, setfilterOptionList] = useState({
        visibleToList: [
            { value: "Myself", label: "Myself" },
            { value: "All User", label: "All User" },
            { value: "Select Users", label: "Select Users" }
        ],
        visibleToUser: []
    })
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState([]);
    const [editView, seteditView] = useState(false)
    const [isDeleteWarningShow, setIsDeleteWarningShow] = useState(false)


    useEffect(() => {
        if (filtersData) {
            console.log("filtersData",filtersData);
            
            const filterDataArray = Object.entries(filtersData)?.map(([key, item]) => {
                return {
                    [key]: item
                };
            });
            setSelectedFilter(filterDataArray)
        }
        setSelectedColumn(columnsEnabled);
    }, [filtersData, columnsEnabled]);

    useEffect(() => {
        if (isFilterModalOpen && editData !== null && editData?.value) {
            setfilterInitialValue({
                viewName: editData?.label ? editData?.label : '',
                visibleTo: editData?.visibleTo ? editData?.visibleTo : '',
                visibleToUser: editData?.visibleToUser ? editData?.visibleToUser?.map(item => item.id) : '',
                tableName: titleName,
                isDefault: editData?.isDefault ? editData?.isDefault : true,
                createdBy: editData?.createdBy ? editData?.createdBy?.id : '',
                modifiedBy: editData?.createdBy ? editData?.modifiedBy?.id : '',
                id: editData?.value ? editData?.value : '',
            })
            setfilterValues({
                visibleToSelect: { value: editData?.visibleTo, label: editData?.visibleTo },
                visibleToUserSelect: editData?.visibleToUser?.map(item => item?.id),
                visibleToUserOptionSelect: editData?.visibleToUser?.map(item => ({
                    value: item?.id,
                    label: item?.username
                })),
                isDefault: editData?.isDefault
            })
            const parsedDataColumn = JSON.parse(editData?.coloumnToDisplay);
            const updatedColumns = columnsEnabled.filter(column => !parsedDataColumn.includes(column));
            const finalColumns = [...parsedDataColumn, ...updatedColumns];
            setSelectedColumn(finalColumns)
            seteditView(true)
            setSelectedColumn(finalColumns)
            const parsedDataFilter = JSON.parse(editData?.filiterConditions);
            let updatedFilters = [...parsedDataFilter];
            Object.keys(filtersData).forEach(key => {
                const existingFilter = parsedDataFilter.find(filter => filter[key]);
                if (!existingFilter) {
                    updatedFilters.push({ [key]: filtersData[key] });
                }
            });            
            setSelectedFilter(updatedFilters)
            seteditView(true)
        }
    }, [editData])
    async function getOptions(e) {
        CommanFetchForAllUser(e)
        setfilterOptionList(prevState => ({
            ...prevState,
            visibleToUser: allUsersList
        }));
    }
    const handleVisibleToUserSelectChange = (selectedOptions, setFieldValue) => {
        const selectedValues = selectedOptions ? selectedOptions?.map(option => option.value) : [];

        setfilterValues(prevState => ({
            ...prevState,
            visibleToUserSelect: selectedValues,
            visibleToUserOptionSelect: selectedOptions
        }));
        setFieldValue('visibleToUser', selectedValues)
    };
    const handleSubmit = async (values, { resetForm, setErrors }) => {
        setLoading(true)
        const filiterConditions = JSON.stringify(selectedFilter).replace(/"/g, '\\"')
        const coloumnToDisplay = JSON.stringify(selectedColumn).replace(/"/g, '\\"')

        const reqBody = {
            coloumnToDisplay: coloumnToDisplay,
            filiterConditions: filiterConditions,
            id: values?.id ? values?.id : '',
            isDefault: values?.isDefault,
            table: values?.tableName ? values?.tableName : '',
            viewName: values?.viewName ? values?.viewName : '',
            visibleTo: values?.visibleTo ? values?.visibleTo : '',
            visibleToUser: values?.visibleToUser ? values?.visibleToUser : '',
            createdBy: values?.createdBy ? Number(values?.createdBy) : Number(userId),
            modifiedBy: values?.modifiedBy ? Number(values?.modifiedBy) : Number(userId),
        }

        let allow_null = ['id', "modifiedBy", "visibleToUser"]
        let clearedValue = removeEmptyValueInObject(reqBody, allow_null)
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: customfiltercreatemutation(clearedValue, allow_null, selectedFilter, selectedColumn)
            })
            const responseData = response?.data?.data?.editListViewCreateMutations

            if (responseData.success) {
                toast.success(`Filter Created Successfully...`, {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light'
                })
                close()

            }


        }
        catch (error) {
            let errorData = ShowErrorNoties(error)
            showErrorToast(true, 'error', errorData)
        }
        setLoading(false)

    }
    const removeFilterCondition = (filterToRemove) => {
        // setSelectedFilter()
        setSelectedFilter(prevFilters =>
            prevFilters.filter(filter => {
                // Get the key of the current filter object
                const key = Object.keys(filter)[0];

                // Check if the current filter matches the one to remove
                return !(key === Object.keys(filterToRemove)[0] &&
                    filter[key].value === filterToRemove[key].value &&
                    filter[key].label === filterToRemove[key].label);
            })
        );
    }
    const removeColumnCondition = (value) => {
        setSelectedColumn(prevColumns => prevColumns.filter(column => column !== value));
    };
    const close = () => {
        setfilterInitialValue({
            id: '',
            createdBy: '',
            modifiedBy: '',
            viewName: '',
            visibleTo: '',
            visibleToUser: '',
            tableName: titleName,
            isDefault: true
        })
        setfilterValues({
            visibleToSelect: '',
            visibleToUserSelect: [],
            visibleToUserOptionSelect: [],
            isDefault: true
        })
        setfilterOptionList({
            visibleToList: [
                { value: "Myself", label: "Myself" },
                { value: "All User", label: "All User" },
                { value: "Select Users", label: "Select Users" }
            ],
            visibleToUser: []
        })
        setSelectedFilter([])
        setSelectedColumn([])
        setFilterModalOpen(false)
        settableListSelect('')
        settableList([])
        seteditData('')
        clearSelectedData()

    }
    async function customfilterDelete() {
        setLoading(true)
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: customfilterDeletemutation(`id:${filterInitialValue?.id}`) })
            let reponseData = response?.data?.data?.editListViewDeleteMutations
            if (reponseData?.success) {
                close()
            } else {
                showErrorToast(true, "error", reponseData?.errors)
            }
        } catch (error) {
            let errorFilter = ShowErrorNoties(error)
            showErrorToast(true, "error", errorFilter)
        }
        setLoading(false)
    }
    return (
        <>
            {<BallTriangleLoading isshow={loading} />}
            <Modal show={isFilterModalOpen} size="xl">
                <div className="row mx-1">
                    <div className="col-5 m-3" style={{ color: "#5CADA9" }}>
                        <Modal.Title>
                            <i className="fa-solid fa-arrow-left fa-sm me-2" onClick={close}></i>
                            Filter
                        </Modal.Title>
                    </div>
                    <div className="col-6 mt-4 text-end">
                        <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={!editView} onClick={() => { seteditView(false) }}></i>
                        <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3" hidden={!editView} onClick={() => { setIsDeleteWarningShow(true) }}></i>
                    </div>
                </div>
                <hr />
                <Modal.Body>
                    <Formik
                        initialValues={filterInitialValue}
                        validationSchema={filterValidation}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({ errors, submitForm, touched, setFieldValue, values, setValues }) => (
                            <>
                                <Form>
                                    <div className="row">
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='viewName' id='viewName' className='w-100 staan-form-input' disabled={editView} />
                                            <label htmlFor="viewName" className='staan-form-input-label pt-1 px-1 mx-2'
                                                // onClick={() => {
                                                //     console.log("selectedFilter", selectedFilter, "selectedColumn", selectedColumn);
                                                // }}
                                                onClick={() => {
                                                    console.log("values", values, "errors", errors);

                                                }}
                                            >View Name<span className='text-danger'>*</span></label>
                                            {touched.viewName && errors.viewName && <small>{errors.viewName}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                // onInputChange={(e) => {

                                                // }}
                                                onChange={(option) => {
                                                    setFieldValue('visibleTo', option ? option?.value : null)
                                                    setfilterValues(prve => ({
                                                        ...prve,
                                                        visibleToSelect: option ? option : null,
                                                    }))
                                                }}
                                                options={filterOptionList?.visibleToList}
                                                name='visibleTo'
                                                value={filterValues?.visibleToSelect}
                                                className='mt-3'
                                                styles={customSelectStyle}
                                                isDisabled={editView}
                                            />
                                            <label className='staan-form-input-label  px-1 mx-2' onClick={() => {
                                                console.log("filtersData", filtersData);
                                            }}>Visible To<span className='text-danger'>*</span></label>
                                            {touched.visibleTo && errors.visibleTo && <small>{errors.visibleTo}</small>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 staan-input-group" hidden={filterValues?.visibleToSelect?.value === "Select Users" ? false : true}>
                                            <Select
                                                onInputChange={(e) => {
                                                    e ? getOptions(e) :
                                                        setfilterOptionList(prevState => ({
                                                            ...prevState,
                                                            visibleToUser: []
                                                        }));
                                                }}
                                                name='column-customize'
                                                placeholder="Select Filter"
                                                options={filterOptionList?.visibleToUser}
                                                styles={customSelectStyle}
                                                value={filterValues?.visibleToUserOptionSelect}
                                                className='my-3'
                                                isClearable
                                                components={{ DropdownIndicator: () => null }}
                                                onChange={(selectedOptions) => handleVisibleToUserSelectChange(selectedOptions, setFieldValue)}
                                                isMulti
                                                isDisabled={editView}
                                            />
                                            <label className='staan-form-input-label  px-1 mx-2' onClick={() => {
                                                console.log(filterValues?.visibleToUserOptionSelect)
                                            }
                                            }>Visible To User<span className='text-danger'>*</span></label>
                                            {touched.visibleToUser && errors.visibleToUser && <small>{errors.visibleToUser}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' name='tableName' id='tableName' className='w-100 staan-form-input' disabled={true} />
                                            <label htmlFor="tableName" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                console.log("filterValues", filterValues);
                                            }}>Table Name<span className='text-danger'>*</span></label>
                                            {touched.tableName && errors.tableName && <small>{errors.tableName}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <div className="form-check form-switch pt-3 pb-1 ml-3">
                                                <input
                                                    className='form-check-input'
                                                    type="checkbox"
                                                    id="flexSwitchCheckDefault"
                                                    checked={filterValues?.isDefault}
                                                    onChange={(e) => {
                                                        console.log(e.target.checked);

                                                        setFieldValue('isDefault', e.target.checked)
                                                        setfilterValues(prevState => ({
                                                            ...prevState,
                                                            isDefault: e.target.checked
                                                        }));
                                                    }}
                                                    disabled={editView}
                                                />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                                    Is Default
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="quotationList" className="form-label" onClick={() => {
                                                    console.log("selectedFilter", selectedFilter, "filterInitialValue", filterInitialValue);
                                                }}>
                                                    Filter Condition
                                                </label>
                                                <ul
                                                    id="quotationList"
                                                    className="list-group"
                                                    style={{ height: '35rem', overflowY: 'scroll' }}
                                                >
                                                    {selectedFilter && selectedFilter.length > 0 ? (
                                                        selectedFilter?.map((filter, rowIndex) => {
                                                            const filterKey = Object.keys(filter)[0];
                                                            return (
                                                                <li
                                                                    key={rowIndex}
                                                                    className="list-group-item list-group-item-action custom-hover"
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <p style={{ fontWeight: 'bold', fontSize: '15px' }}>{filterKey}</p>
                                                                            <p style={{ fontSize: '10px' }}>{filter[filterKey]?.label}</p>
                                                                        </div>
                                                                        <div className="col-6 text-end">
                                                                            <span
                                                                                onClick={() => {
                                                                                    if (!editView) {
                                                                                        removeFilterCondition(filter);
                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    color: 'red',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                                title="Remove filter"
                                                                            >
                                                                                &times;
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : (
                                                        <li className="p-3 list-group-item">No Filter Applied</li>
                                                    )}

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="quotationList" className="form-label" onClick={() => {
                                                    console.log("selectedColumn", selectedColumn);
                                                }}>
                                                    Columns To Display
                                                </label>
                                                <ul
                                                    id="quotationList"
                                                    className="list-group"
                                                    style={{ height: '35rem', overflowY: 'scroll' }}
                                                >
                                                    {selectedColumn && selectedColumn.length > 0 ? (
                                                        selectedColumn.map((column, index) => (
                                                            <li
                                                                key={index}
                                                                className="list-group-item list-group-item-action custom-hover"
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <div className="row">
                                                                    <div className="col-6 fw-bold">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '15px' }}> {column} </p>
                                                                    </div>
                                                                    <div className="col-6 text-end">
                                                                        <span
                                                                            onClick={() => {
                                                                                if (!editView) {
                                                                                    removeColumnCondition(column);
                                                                                }

                                                                            }}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                color: 'red',
                                                                                fontSize: '20px',
                                                                            }}
                                                                            title="Remove filter"
                                                                        >
                                                                            &times;
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li className="p-3 list-group-item">No Columns Selected</li>
                                                    )}
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-3">
                                        <div className="text-end">
                                            <button type="button" className="btn btn-sm btn-outline-success shadow-sm px-4"
                                                onClick={() => {
                                                    setFieldValue('status', "Draft"); submitForm()
                                                }}
                                                disabled={editView}
                                            >
                                                Submit
                                            </button>

                                        </div>
                                    </div>
                                    {isDeleteWarningShow &&
                                        <WaringModel
                                            state={isDeleteWarningShow}
                                            setstate={setIsDeleteWarningShow}
                                            title={'Confirm'}
                                            text={"Confirm to delete"}
                                            Confirmtion={value => {
                                                if (value) {
                                                    customfilterDelete(value)
                                                }
                                            }}
                                        />}

                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CustomFilterModal;
