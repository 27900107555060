import React, { useContext, useState } from "react";
import { Dropdown, Form, InputGroup, ListGroup, Offcanvas, ToastContainer } from "react-bootstrap";
import { CiViewList } from "react-icons/ci";
import { FaRegCopy, FaRegStar, FaSearch, FaStar } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import { MdDeleteOutline } from "react-icons/md";
import axiosInstance from "../../../api/axoiss";
import showErrorToast from "../../../components/notifications/react-toastify/toast";
import WaringModel from "../../../components/Warings/WaringModel";
import DataContext from "../../../context/ItemMasterContext";
import { customfiltercreatemutation, customfilterDeletemutation, tableListQuery } from "../../../GraphQLQueries/customfilterQueryandMutation";
import { ShowErrorNoties } from "../../../utils/Utils";
import FilterView from "./FilterView";
import "./FilterView.css";


const FilterModal = ({ show, handleClose, headers, url, tableName, filterData, setfilterData, applyFilterFunction, applyFilterState, setFixedFilter }) => {
    const { userId } = useContext(DataContext)
    const [filterValues, setfilterValues] = useState({
        showFilterView: false, filterEditData: [], isDeleteShow: false, deleteData: '', isDuplicate: false
    })
    const handleFilterViewDelete = async (params) => {
        console.log("params", params);
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: customfilterDeletemutation(`id:${filterValues?.deleteData?.id}`) })
            let reponseData = response?.data?.data?.editListViewDeleteMutations
            if (reponseData?.success) {
                setfilterValues(prev => ({
                    ...prev,
                    isDeleteShow: false,
                    deleteData: ''
                }))
            } else {
                showErrorToast(true, "error", reponseData?.errors)
            }
        } catch (error) {
            let errorFilter = ShowErrorNoties(error)
            showErrorToast(true, "error", errorFilter)
        }

    }
    const handleSetAsDefault = async (data) => {
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: customfiltercreatemutation(`id:${data?.id} isDefault:${!data?.isDefault} modifiedBy:${data?.modifiedBy?.id} `)
            })
            const responseData = response?.data?.data?.editListViewCreateMutations
            if (responseData.success) {
                showErrorToast(true, "success", "", "Default Changed...")
                handleClose()
            }
        }
        catch (error) {
            let errorData = ShowErrorNoties(error)
            showErrorToast(true, 'error', errorData)
        }
    }
    const renderDropdown = (params) => {
        return (
            <>
                <Dropdown>
                    <Dropdown.Toggle as="div" className="dots-menu" bsPrefix="custom-toggle" style={{ paddingLeft: '10px' }}>
                        <span>⋮</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {
                            setfilterValues(prev => ({
                                ...prev,
                                showFilterView: true,
                                filterEditData: params
                            }))
                            handleClose()
                        }}>
                            <GrEdit className="dropdown-icon" /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            setfilterValues(prev => ({
                                ...prev,
                                showFilterView: true,
                                filterEditData: params,
                                isDuplicate: true
                            }))
                            handleClose()
                        }}>
                            <FaRegCopy className="dropdown-icon" /> Duplicate
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            setfilterValues(prev => ({
                                ...prev,
                                isDeleteShow: true,
                                deleteData: { id: params?.id, Name: params?.viewName }
                            }))
                            handleClose()
                        }}>
                            <MdDeleteOutline className="dropdown-icon" /> Delete
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            handleSetAsDefault(params)
                        }}>
                            {params?.isDefault
                                ? <><FaStar className="dropdown-icon" style={{ color: 'gold' }} /> Set as Default</>
                                : <><FaRegStar className="dropdown-icon" /> Set as Default</>}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }
    const handleSearch = async (e) => {
        const response = await axiosInstance.post(`/itemmaster/graphql`, { query: tableListQuery(`table:"${tableName}" userID:${Number(userId)} viewName:"${e}",page:1,pageSize:15`) });
        const responseData = response?.data?.data?.EditListViewType?.items
        if (responseData) {
            setfilterData(responseData)
        }
    }
    const handleApplyFilter = (filterData) => {

        // Parse the filterConditions from filterData (assumed to be a JSON string)
        const filterConditions = JSON.parse(filterData.filiterConditions);
        const columnsToDisplay = JSON.parse(filterData.coloumnToDisplay);

        console.log("filterData", filterData);

        // Prepare selectedData by extracting the field from each column
        const selectedData = columnsToDisplay.map(column => column.field);
        // Prepare filterData for applying __icontains format
        const filterObject = {};
        filterConditions.forEach(condition => {
            let filterKey
            let conditionApplied = condition.conditionApplied;
            if (condition?.type === "Date") {
                if (condition?.conditionApplied?.start === condition?.conditionApplied?.end) {
                    filterKey = `${condition.field}__date`;
                    // conditionApplied = formatDate(condition?.conditionApplied?.start); // Format the date
                    conditionApplied = condition?.conditionApplied?.start
                } else {
                    filterKey = `${condition.field}__range`;
                    // conditionApplied = [
                    //     formatDate(condition?.conditionApplied?.start), // Format the start date
                    //     formatDate(condition?.conditionApplied?.end)    // Format the end date
                    // ];
                    conditionApplied = [
                        condition?.conditionApplied?.start,
                        condition?.conditionApplied?.end
                    ];

                }
            }
            else if (condition?.type === "text") {
                if (condition?.conditionOption?.value === "equal") {
                    filterKey = `${condition.field}__icontains`
                } else if (condition?.conditionOption?.value === "notEqual") {
                    filterKey = `${condition.field}__noticontains`
                } else if (condition?.conditionOption?.value === "isEmpty") {
                    filterKey = `${condition.field}__isempty`
                }
                else if (condition?.conditionOption?.value === "isNotEmpty") {
                    filterKey = `${condition.field}__isnotempty`
                }
            } else if (condition?.type === "Bool") {
                if (condition?.conditionApplied?.value === "no") {
                    filterKey = `${condition.field}`;
                    conditionApplied = false
                } else {
                    filterKey = `${condition.field}`;
                    conditionApplied = true
                }
            } else if (condition?.type === "number") {
                if (condition?.conditionOption?.value === "greaterThan") {
                    filterKey = `${condition.field}__gt`
                }
                if (condition?.conditionOption?.value === "lessThan") {
                    filterKey = `${condition.field}__lt`
                }
                if (condition?.conditionOption?.value === "lessThanEqual") {
                    filterKey = `${condition.field}__lte`
                }
                if (condition?.conditionOption?.value === "greaterThanEqual") {
                    filterKey = `${condition.field}__gte`
                }
                if (condition?.conditionOption?.value === "between") {
                    filterKey = `${condition.field}__range`
                }
            }
            filterObject[filterKey] = conditionApplied
        });
        const result = {
            fixedfilterData: filterObject,
            fixedselectedData: selectedData.length > 0 ? selectedData : [], // Fixed selectedData (same as selectedData for now)
            sortOrder: filterData?.defaultSortOrder ? filterData?.defaultSortOrder : null,
            viewName: filterData?.viewName ? filterData?.viewName : "View",
        };
        console.log("Final result object:", result);
        // // Pass this object to applyFilterFunction
        applyFilterFunction({}, result);
        applyFilterState(result?.fixedselectedData)
        setFixedFilter(result)

    };

    return (
        <>
            <Offcanvas show={show} placement="end">
                <div className='row'>
                    <div className='col-12 m-3'>
                        <h3 className='mx-2 commanModelTitleColor'>
                            <i className='fa-solid fa-arrow-left fa-sm me-2 commanModelTitleColor' onClick={handleClose}></i>
                            Select View
                        </h3>
                    </div>
                </div>
                <hr className='split_border' />
                <Offcanvas.Body>
                    <ToastContainer />
                    <Form.Group className="mb-3 mt-4" controlId="searchListsTags">
                        <div className="row">
                            <div className="col-8">
                                <InputGroup>
                                    <InputGroup.Text style={{ background: 'white' }}>
                                        <FaSearch />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search for lists and tags"
                                        onChange={(e) => handleSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                            <div className="col-4">
                                <button
                                    type="button"
                                    className="btn btn-outline-success px-4"
                                    style={{ fontSize: '14px', fontWeight: 'bold' }}
                                    onClick={() => {
                                        setfilterValues(prev => ({
                                            ...prev,
                                            showFilterView: true,
                                            filterEditData: ''
                                        }))
                                        handleClose()
                                    }}
                                >
                                    <i className="bx bxs-plus-circle me-2"></i>New
                                </button>
                            </div>
                        </div>
                    </Form.Group>
                    <div>
                        <h6 onClick={() => {
                            console.log("filterData", filterData);
                        }}>My Lists</h6>
                        <ListGroup>
                            {filterData?.map((filterItem, rowIndex) => {
                                return (
                                    <ListGroup.Item action className="list-item-with-dots" key={filterItem.id} onClick={() => { handleApplyFilter(filterItem) }}>
                                        <CiViewList className="dropdown-icon" style={{ cursor: 'pointer' }} />{filterItem?.viewName}
                                        {renderDropdown(filterItem)}
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            {filterValues?.showFilterView &&
                <FilterView
                    IsFilterShow={filterValues?.showFilterView}
                    setIsFilterShow={() => { setfilterValues(prev => ({ ...prev, showFilterView: false, isDuplicate: false, filterEditData: [] })) }}
                    headers={headers}
                    url={url}
                    tableName={tableName}
                    filterEditData={filterValues?.filterEditData}
                    isDuplicate={filterValues?.isDuplicate}
                />}
            {filterValues?.isDeleteShow &&
                <WaringModel
                    state={filterValues?.isDeleteShow}
                    setstate={() => { setfilterValues(prev => ({ ...prev, isDeleteShow: false })) }}
                    title={'Confirm'}
                    text={`Confirm to Delete ${filterValues?.deleteData?.Name}`}
                    Confirmtion={value => {
                        if (value) {
                            handleFilterViewDelete(value)
                        }
                    }}
                />}
        </>
    );
};

export default FilterModal;