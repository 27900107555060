export const callLogCreateMutation = (data)=>{
    // createdBy: 10 id: "" modifiedBy: 10 outcome: "" salesPerson: 10 subject: 10
    return `mutation MyMutation {
  callLogCreateMutation(${data}) {
    errors
    success
    callLogInstance {
      subject
      types
      createdAt
      id
      outcome
      createdBy{
        id
        username
      }
      salesPerson{
        id
        username
      }
    }
  }
}`
}


export const meetingCreateMutation = (data)=>{
  // createdBy: 10 endDate: "" endTime: "" id: "" modifiedBy: 10 outcome: "" plannedEndDate: "" plannedEndTime: ""
    // plannedStartDate: "" plannedStartTime: "" salesPerson: 10 startDate: "" startTime: ""
    // status: 10 subject: 10
  return `mutation MyMutation {
  meetingCreateMutation(
    ${data}
  ) {
    errors
    success
    meetingInstance {
      createdAt
      endDate
      endTime
      id
      completedDate
      outcome
      plannedEndDate
      plannedEndTime
      plannedStartDate
      plannedStartTime
      startDate
      startTime
      subject
      updatedAt
      status {
        id
        name
      }
      createdBy{
        id
        username
      }
      salesPerson{
        id
        username
      }
    }
  }
}`
}


export const noteCreateMutation = (data)=>{
  // createdBy: 3, enquiryId: 172, id: null, note: "test note "
  return `
  mutation MyMutation {
    noteCreateMutation(${data}) {
      errors
      success
      notesInstance {
        createdAt
        id
        note
        updatedAt
         createdBy{
          id
          username
        }
      }
    } 
  }`
}

