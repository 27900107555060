export const contactDetalisFetchQuery = (params) => {
    return `  query MyQuery {
      contactDetalis(phoneNumber: "${params}"){
        items {
          contactPersonName
          email
          id
          otherNo
          phoneNumber
          whatsappNo
          enquirydatasSet{
            pincode{
              id
              pincode
            }
          }
        }
      }
    }`
  }

export const checkStockPOSQuery = (data) => {
  //posID: "309"
  let mutation = `
  mutation MyMutation {
  checkStock(${data}) {
    errors
    needStock
    success
  }
}
  `
  return mutation
}

export const posItemmasterfetch = (data) => {
  //posID: "309"
  let mutation = `
  query myFirstQuery{
      itemMaster(pageSize :50 ${data} ){
        items{
          id
          itemName
          itemPartCode
          description
          serial
          batchNumber
          itemMrp
           
          
          itemUom{
            id
            name
          }
        
          itemHsn{
          id
          hsnCode
            gstRates{
              rate
              
            }
          }
        }
      }
    }
  `
  return mutation
}