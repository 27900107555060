import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { TbHistory } from 'react-icons/tb'
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import axiosInstance from '../../../api/axoiss'
import Can from '../../../components/authorizationComponents/Can'
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation'
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import DataContext from '../../../context/ItemMasterContext'
import { createNumberingSeries } from '../../../GraphQLQueries/ItemMastermutation'
import * as QueryData from '../../../GraphQLQueries/ItemmasterQuery'
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction'
import {
  checkChangesInputs,
  removeEmptyValueInObject,
  ShowErrorNoties
} from '../../../utils/Utils'
import { NumberingSchema } from '../../../validations/itemmaster'
import HistoryData from './HistoryData'
import CustomSelect from '../../../components/ReactSelect/reactSelect'
import WaringModel from '../../../components/Warings/WaringModel'
import BallTriangleLoading from "../../../components/loading/BallTriangle";

const NumberingModal = ({
  NumberingSeries,
  handleNumberingSeriesClose,
  numberingSeriesEdit,
  setNumberngSeriesEdit,
  setLoad
}) => {
  const { userId, historyIds, setHistoryIds, handltoastDeleteConfomationShow } = useContext(DataContext)
  const dispatch = useDispatch()
  const [waringToCloseModel, setWaringToCloseModel] = useState(false)
  const [edit, setEdit] = useState(false)
  const [deleteData, setdeleteData] = useState({ Name: '', id: '' })
  const [formKey, setFormKey] = useState(0)
  const ResourceOptions = [
    { value: 'Pos', label: 'Pos' },
    { value: 'Purchase Order', label: 'Purchase Order' },
    { value: 'Goods Inward Note', label: 'Goods Inward Note' },
    { value: 'Goods Receipt Note', label: 'Goods Receipt Note' },
    { value: 'Quality Inspection Report', label: 'Quality Inspection Report' },
    { value: 'Rework Delivery Challan', label: 'Rework Delivery Challan' },
    { value: 'Purchase Return Challan', label: 'Purchase Return Challan' },
    { value: 'Quotations', label: 'Quotations' },
    { value: 'SalesOrder', label: 'SalesOrder' },
    { value: 'Payment Voucher', label: 'Payment Voucher' },

    // 

  ]
  const [numberSeriesValues, setnumberSeriesValues] = useState({
    departmentList: [], departmentSelect: [], resourceOptionsType: [], SelectResource: { value: '', label: '' },
    SelectReSourceIsPosType: {
      value: '',
      label: ''
    }
  })
  const [initialValue, setInitialValue] = useState({
    id: '',
    Name: '',
    Resource: '',
    Formate: '',
    Current_Value: 0,
    LastSerialHistory: 0,
    Default: true,
    Department: '',
    Active: true,
    ReSourceIsPosType: '',
    modified_by: '',
    createdby: '',
    HistoryDetails: ''
  })
  const [loading, setLoading] = useState(false)
  async function fetchEditData(id) {
    setLoading(true)
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: QueryData.NumberingSeriesEdit(id) });
      const responseData = response?.data?.data?.numberingSeries?.items?.[0]
      if (responseData) {
        setInitialValue({
          id: responseData?.['id'],
          Name: responseData?.['numberingSeriesName'],
          Resource: responseData?.['resource'],
          Formate: responseData?.['formate'],
          Current_Value: responseData?.['currentValue'],
          LastSerialHistory: responseData?.['lastSerialHistory'],
          Default: responseData?.default,
          Department: responseData?.department?.id,
          Active: responseData?.active,
          ReSourceIsPosType: responseData?.posType?.id || null,
          modified_by: responseData?.['modifiedBy'] ? responseData?.['modifiedBy']?.['id'] : '',
          createdby: responseData['createdBy']?.['id'],
          HistoryDetails: responseData['historyDetails'] ? responseData['historyDetails'] : ''
        })
        if (responseData?.posType) {
          setnumberSeriesValues(prev => ({
            ...prev,
            SelectReSourceIsPosType: { value: responseData['posType']['id'], label: responseData['posType']['ReSourceIsPosType'] }
          }))
        }
        if (responseData?.resource) {
          setnumberSeriesValues(prev => ({
            ...prev,
            SelectResource: { value: responseData['resource'], label: responseData['resource'] }
          }))
        }
        setnumberSeriesValues(prev => ({
          ...prev,
          departmentSelect: {
            value: responseData?.department?.id,
            label: responseData?.department?.name
          }
        }))
        setFormKey(prevKey => prevKey + 1)

        setFormKey(prev => prev + 1)
      }
      else {
        showErrorToast(true, 'error', 'Item Not Found!!!!')
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
    }

  }
  useEffect(() => {
    // This useEffect hook calls `fetchData` to retrieve data when `numberingSeriesEdit` changes. 
    // It sets the edit state based on whether there is an active numbering series being edited. This function does not return a value.
    async function fetchData(id) {
      dispatch(fetchOptionsData(QueryData.PosTypeOptions(), 'PosType'))
      if (id) {
        fetchEditData(id)
      }
    }
    if (numberingSeriesEdit) {
      fetchData(numberingSeriesEdit.id)
      setEdit(true)
    } else {
      setEdit(false)
    }

    fetchData()
  }, [numberingSeriesEdit, dispatch])

  // retun null ; on save and close clear value.
  function resetdata(params) {
    setInitialValue({
      Name: '',
      Resource: '',
      Formate: '',
      Current_Value: 0,
      LastSerialHistory: 0,
      Department: '',
      Default: true,
      Active: true
    })
    setNumberngSeriesEdit()
    setnumberSeriesValues(prev => ({
      ...prev,
      SelectResource: { value: '', label: '' }
    }))
  }
  // retun null ; submit function.
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // The `handleSubmit` function validates the form data for creating or updating a numbering series. It checks if the 'Department' field is required based on the selected 'Resource', 
    // and ensures the 'Formate' follows the correct pattern ending with #<number>. 
    // Upon successful validation, it handles errors, resets the form, and closes the modal.
    let lastSerialHistory
    if ((values['Resource'] === "SalesOrder" || values['Resource'] === 'Quotations') && (values?.['Department'] === "" || values?.['Department'] === null)) {
      showErrorToast(true, 'error', `Department is required`, '')
      return ""
    }
    const formateRegex = /#\d+$/;
    if (!formateRegex.test(values['Formate'])) {
      showErrorToast(true, 'error', `Incorrect Format. It should end with #<number>.`, '');
      return "";
    }
    if (
      Number(initialValue.Current_Value) !== Number(values['Current_Value'])
    ) {
      lastSerialHistory = values['Current_Value']
    } else {
      lastSerialHistory = Number(values['LastSerialHistory'])
    }
    let saveValue = {
      id: numberingSeriesEdit ? numberingSeriesEdit?.id : '',
      numberingSeriesName: values['Name'],
      resource: values['Resource'],
      formate: values['Formate'],
      currentValue: Number(values['Current_Value']),
      lastSerialHistory: Number(lastSerialHistory),
      default: values['Default'],
      active: values['Active'],
      department: values['Department'] ? Number(values['Department']) : '',
      posType: values['ReSourceIsPosType']
        ? Number(values['ReSourceIsPosType'])
        : '',
      modifiedBy: values?.modified_by ? Number(values?.modified_by) : Number(userId),
      createdBy: numberingSeriesEdit
        ? Number(initialValue.createdby)
        : Number(userId)
    }

    let variable = removeEmptyValueInObject(saveValue, [
      'id',
      'modifiedBy',
      'posType',
      'department'
    ])
    try {
      const respones = await axiosInstance.post('/itemmaster/graphql', {
        query: createNumberingSeries(variable)
      })

      if (respones.data.data.numberingSeriesCreateMutation.success) {
        resetForm()
        handleNumberingSeriesClose()
        resetdata()
        setLoad()
        setSubmitting(false)
        showErrorToast(true, 'success', '', 'Successfully Saved')
      } else {
        let errorData = ShowErrorNoties(
          respones.data.data.numberingSeriesCreateMutation.errors
        )
        showErrorToast(true, 'error', `${errorData}`, '')
      }
    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', `${errorData}`, '')
    }
  }

  const handledeleteConfomation = data => {
    // retun null ; call delete waring and pass delete data.
    handltoastDeleteConfomationShow()
    setdeleteData({
      Name: data.numberingSeriesName,
      id: data.id
    })
  }
  let currentValues = {}
  const cleaeTheData = () => {
    // The `cleaeTheData` function closes the numbering series modal, resets the data,
    // and updates the state if in edit mode. It also hides the warning modal. 
    // This function does not return a value.
    handleNumberingSeriesClose()
    resetdata()
    if (numberingSeriesEdit) {
      setEdit(false)
      setNumberngSeriesEdit()
    }
    setWaringToCloseModel(false)
  }
  function Close() {
    // The Close function checks for duplicate changes in input values before closing the numbering series modal. 
    // If no duplicates are found, it resets the data and updates the state; otherwise, 
    // it triggers a warning to the user.retun null;
    let isDuplicate = checkChangesInputs([initialValue, currentValues])
    if (!isDuplicate) {
      handleNumberingSeriesClose()
      resetdata()
      if (numberingSeriesEdit) {
        setEdit(false)
        setNumberngSeriesEdit()
      }
    } else {
      setWaringToCloseModel(true)
    }
  }
  const handleDeletedId = deleted_id => {
    // The `handleDeletedId` function processes the deletion of an item if user enters yes in the delete confirm,
    // closing the numbering series modal, and updating the resource selection values. 
    // This function does not return a value.
    if (deleted_id) {
      setNumberngSeriesEdit()
      resetdata()
      handleNumberingSeriesClose()
      setnumberSeriesValues(prev => ({
        ...prev,
        SelectReSourceIsPosType: { value: '', label: '' },
        SelectResource: { value: '', label: '' }
      }))
      setEdit(false)
      setLoad()
    }
  }

  return (
    <>
      {<BallTriangleLoading isshow={loading} />}
      <HistoryData historyIds={historyIds} setHistoryIds={setHistoryIds} />
      <ToastContainer />
      <Modal show={NumberingSeries} size='lg'>
        <div className='row'>
          <div className="col-5 m-3" style={{ color: "#5CADA9" }}>

            <Modal.Title>
              <i
                className='fa-solid fa-arrow-left fa-sm me-2'
                onClick={() => {
                  Close()
                }}
              ></i>
              Numbering Series
            </Modal.Title>
          </div>
          <div className="col-6 mt-4 text-end" >
            <Can I='Edit' a='Numbering_Series'>
              <i
                className='fa-solid fa-pen fs-5 text-primary pe-3'
                hidden={!(numberingSeriesEdit && edit)}
                onClick={() => {
                  setEdit(!edit)
                }}
              ></i>
            </Can>
            <Can I='Delete' a='Numbering_Series'>
              <i
                className='fa-solid fa-trash text-danger fs-5 pe-3 ps-3  '
                hidden={!(numberingSeriesEdit && edit)}
                onClick={() => {
                  handledeleteConfomation(numberingSeriesEdit)
                }}
              ></i>
            </Can>
            <Can I='Historys' a='Numbering_Series'>
              <TbHistory
                type='button'
                className='fs-4 ms-3'
                data-bs-toggle='offcanvas'
                hidden={!(numberingSeriesEdit && edit)}
                data-bs-target='#History'
                onClick={() => {
                  setHistoryIds(initialValue.HistoryDetails)
                }}
              />
            </Can>
          </div>
        </div>
        <hr className='split_border'/>

        <Modal.Body>
          <Formik
            enableReinitialize={
              typeof numberingSeriesEdit === 'object' ? true : false
            }
            key={formKey}
            initialValues={initialValue}
            validationSchema={NumberingSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting, setFieldValue, values }) => {
              currentValues = values
              return (
                <Form>
                  <div className='row'>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='Name'
                        id='Name'
                        disabled={numberingSeriesEdit && edit}
                        className='w-100 staan-form-input '
                      />
                      <label
                        htmlFor='Name'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                        onClick={() => {
                          console.log(values)

                        }}
                      >
                        Name
                      </label>
                      {touched.Name && errors.Name && (
                        <small>{errors.Name}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <CustomSelect
                        setSelectEditValue={numberSeriesValues?.SelectResource}
                        options={ResourceOptions}
                        name='Resource'
                        id='Resource'
                        handleOnChange={(option) => {
                          setFieldValue(
                            'Resource',
                            option ? option.value : null
                          )
                          setnumberSeriesValues(prev => ({
                            ...prev,
                            SelectResource: { value: option?.value, label: option?.label }
                          }))
                        }}
                        isDisabled={numberingSeriesEdit && edit}
                      />
                      <label
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Resource
                      </label>
                      {touched.Resource && errors.Resource && (
                        <small>{errors.Resource}</small>
                      )}
                    </div>
                    <div
                      className='col-6 staan-input-group'
                      hidden={values.Resource !== 'Pos'}
                    >
                      <CustomSelect
                        setSelectEditValue={numberSeriesValues?.SelectReSourceIsPosType}
                        options={numberSeriesValues?.resourceOptionsType}
                        name='ResourcePosType'
                        id='ResourcePosType'
                        handleOnChange={(option) => {
                          setFieldValue(
                            'ReSourceIsPosType',
                            option ? option.value : null
                          )
                          setnumberSeriesValues(prev => ({
                            ...prev,
                            SelectReSourceIsPosType: { value: option?.value, label: option?.label }
                          }))
                        }}
                        isDisabled={numberingSeriesEdit && edit}
                      />
                      <label
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Pos Type
                      </label>
                      {touched.Resource && errors.Resource && (
                        <small>{errors.Resource}</small>
                      )}
                    </div>
                    <div
                      className='col-6 staan-input-group'
                      hidden={values.Resource === 'SalesOrder' ? false : values.Resource !== "Quotations"}
                    >
                      <CustomSelect
                        setSelectEditValue={numberSeriesValues?.departmentSelect}
                        handleInputChange={(e) => {
                          dispatch(
                            fetchOptionsData(
                              QueryData.DepartmentQuery(
                                `name: "${e.trim()}"`
                              ),
                              'SerialNumberdepartmant'
                            )
                          )
                        }}
                        options={numberSeriesValues?.departmentList}
                        name='Department'
                        id='Department'
                        handleOnChange={(option) => {
                          setFieldValue(
                            'Department',
                            option ? option.value : null
                          )
                          setnumberSeriesValues(prev => ({
                            ...prev,
                            departmentSelect: option
                          }))
                        }}
                        isDisabled={numberingSeriesEdit && edit}
                      />
                      <label
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Department
                      </label>
                      {touched.Department && errors.Department && (
                        <small>{errors.Department}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='Formate'
                        id='Formate'
                        disabled={numberingSeriesEdit && edit}
                        placeholder=''
                        className='w-100 staan-form-input '
                      />
                      <label
                        htmlFor='Formate'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Format
                      </label>
                      {touched.Formate && errors.Formate && (
                        <small>{errors.Formate}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='Current_Value'
                        id='Current_Value'
                        disabled={numberingSeriesEdit && edit}
                        placeholder=''
                        className='w-100 staan-form-input '
                      />
                      <label
                        htmlFor='Current_Value'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Current Value
                      </label>
                      {touched.Current_Value && errors.Current_Value && (
                        <small>{errors.Current_Value}</small>
                      )}
                    </div>
                    <div className='col-6 my-3'>
                      <div className='form-check form-switch'>
                        <label className='form-check-label ' htmlFor='Default'>
                          Default
                        </label>
                        <Field
                          type='checkbox'
                          name='Default'
                          id='Default'
                          disabled={numberingSeriesEdit && edit}
                          className='form-check-input text-end ms-2 '
                        />
                        {touched.Default && errors.Default && (
                          <small>{errors.Default}</small>
                        )}
                      </div>
                    </div>
                    <div className='col-6 my-3'>
                      <div className='form-check form-switch'>
                        <label className='form-check-label ' htmlFor='Active'>
                          Active
                        </label>
                        <Field
                          type='checkbox'
                          name='Active'
                          disabled={numberingSeriesEdit && edit}
                          id='Active'
                          className='form-check-input text-end ms-2 '
                        />
                        {touched.Active && errors.Active && (
                          <small>{errors.Active}</small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row d-flex justify-content-end'>
                    <div className='text-end '>
                      <Button
                        variant='outline-danger'
                        className='mx-2'
                        size='sm'
                        onClick={() => {
                          Close()
                        }}
                      >
                        Cancel
                      </Button>
                      <Can
                        allow_list={[
                          'Save_Numbering_Series',
                          'Edit_Numbering_Series'
                        ]}
                      >
                        <Button
                          type='submit'
                          disabled={isSubmitting}
                          className='mx-2 px-3'
                          size='sm'
                          variant='outline-success'
                        >
                          Save
                        </Button>
                      </Can>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {deleteData && (
        <DeleteConformation
          handleDeletedId={id_ => {
            handleDeletedId(id_)
          }}
          deleteData={deleteData}
          url={'/itemmaster/NumberingSeries'}
        />
      )}
      {waringToCloseModel &&
        <WaringModel
          state={waringToCloseModel}
          setstate={setWaringToCloseModel}
          title={'Confirm'}
          text={"Do you want to leave without saving?"}
          Confirmtion={value => {
            if (value) {
              cleaeTheData()
            }
          }}
        />}
    </>
  )
}

export default NumberingModal
