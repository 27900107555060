import React, {useContext, useEffect, useState} from 'react' 
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import DataContext from '../../../context/ItemMasterContext';
import {   Formik , Field, Form } from 'formik';
import { CustomerGroupSchema } from '../../../validations/itemmaster';
import axiosInstance from '../../../api/axoiss';
import {  ToastContainer,   toast } from 'react-toastify';
import { ShowErrorNoties, customSelectStyle } from '../../../utils/Utils';
import { QueryFilter } from '../../../GraphQLQueries/ItemmasterQuery';



const CustomerGroup = ({setFieldValue}) => {
    const {customerGroup, handlecustomerGroupClose, userId , SetCostomerGroupdata, costomerGroupdata, setCustomerGroupSelec} = useContext(DataContext)
 
    const initialValue  = {
      "name": "",
      "parent_group": '',
      "Active": true,
    }

    const [customerParentGroup, setcustomerParentGroup]  = useState({ value:  '' , label:   '' })
    const handleChangeparentGroup =(option)=>{
        setcustomerParentGroup( { value: option.value , label:  option.label })
    }

    function clear(){
        setcustomerParentGroup({ value:  '' , label:   '' })
    }
    
    const handlesubmit = async (values, { setSubmitting, resetForm, setErrors })=>{
        const proccess_saved_data = { 
          "name":  values['name'],
          "parentGroup": values['parent_group'],
          "Active": values['Active'],
          "SavedBy": userId
        }
        try{
          let update_mutate_query = `mutation createCustomerGroup{
            customerGroupCreateMutation(`
            update_mutate_query += QueryFilter(proccess_saved_data, ['Active'], ['id', 'SavedBy'])
            update_mutate_query +=`){
              customerGroupItem{
                id
                Active
                name
                parentGroup
              }
              errors
              success
            }
            }`
            const response = await axiosInstance.post("/itemmaster/graphql", {query: update_mutate_query})
            const responseData = response?.data?.data?.customerGroupCreateMutation?.customerGroupItem
            if (response?.data?.data?.customerGroupCreateMutation.success){
              let updated_res_data = {...responseData}
              updated_res_data['name'] = updated_res_data['name']
              updated_res_data['parent_group'] = updated_res_data['parentGroup']
              updated_res_data['Active'] = updated_res_data['Active']
              SetCostomerGroupdata((prevData => [ updated_res_data, ...prevData]));
              setCustomerGroupSelec({ value: updated_res_data.id , label: updated_res_data.name })
              setFieldValue("customer_group", updated_res_data.id)
              handlecustomerGroupClose();
              resetForm();
              clear()
              toast.success('Saved', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
              });
            } else{
              let listOfError = ShowErrorNoties(response?.data?.data?.customerGroupCreateMutation)
              toast.error(listOfError,{
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                // transition: Bounce
              })
            }
          
        } catch(errors){ 
          console.log(errors)
          if(errors.response.data){
              //  console.log(typeof(errors.response.data));
              //  console.log(typeof(errors.response.data) === obj);
              if (typeof errors.response.data === 'object') {
                const finderrors = Object.values(errors.response.data);
                try{
                  finderrors.forEach((errorsdata) => {
                    const showerror = String(errorsdata).split('with').pop().trim();
                    console.log(showerror);
                
                    toast.error(showerror, {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  });
                } catch{
                  toast.error(errors.response.data, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              
              }
            
          } else{
              toast.error("Undefined", {
                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });

          }
        }
    }

  return (
    <>
    <ToastContainer/>
      <Modal show={customerGroup} onHide={handlecustomerGroupClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Customer Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
          <Formik initialValues={initialValue} validationSchema={CustomerGroupSchema}
          onSubmit={handlesubmit}>
             {({errors, touched , isSubmitting, values,setFieldValue, onSubmit})=>(
                <>
                <Form>
                  {/* UI Change starts */}
                  <div>
                    <div className='row'>
                        <div className="col-6 staan-input-group">
                            <Field type='text'  name="name" value={values.name} 
                              onchange={(e)=>{setFieldValue("name", e.target.value)}}   
                              placeholder='' className='w-100 staan-form-input'
                            />
                            <label htmlFor="name" className='staan-form-input-label pt-2 px-1 mx-2'>Name</label>
                            {  touched.name &&  errors.name && <small>{errors.name}</small>}
                        </div>
                        <div className="col-6  staan-input-group">
                            <Select
                                name="parent_group" 
                                value={customerParentGroup}
                                options={costomerGroupdata.map((item)=>( { value: item.id , label:  item.name }))}
                                onChange={(option) => {setFieldValue('parent_group', option ? option.value : null)
                                handleChangeparentGroup(option)}}
                                styles={customSelectStyle}
                                className='my-3'
                            />
                            <label htmlFor="parent_group" className='staan-form-input-label pt-2 px-1 mx-2'>Parent Group </label>
                            {  touched.parent_group &&  errors.parent_group && <small>{errors.parent_group}</small>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field type="checkbox" name="Active"   className='me-4 ms-3 mt-4'   />
                            <label style={{fontSize: '.8rem', top: '5px'}} className='pb-1'>Active</label>
                        </div>
                        <div className='col-6 '>
                          <div className="text-end mt-2">
                            <button type="submit" className="px-3 btn btn-sm shadow-sm btn-outline-success">Save</button>
                          </div>
                        </div>
                    </div>
                  </div>
                  {/* UI Change ends */}
                </Form>
                
                </>
             )}

          </Formik>
 
        </Modal.Body>
       
      </Modal>
    </>
  )
}

export default CustomerGroup