export const contactValidation = (data) => {
    // addressLine1: "12313" addressLine2: "sddsdf" city: "covai" country: "india" default: false id: null pincode: "423424" state: "tamilnamdu"
    return `mutation MyMutation {
  contactValidation(${data}) {
    errors
    success
  }
}`
}

export const SupplierCustomerCreateMutation = (data) => {
    return `
mutation MyMutation {
  supplierFormCreateMutation(${data}) {
    errors
    success
    supplierFormItem{
      id
      companyName
      legalName
    }
  }
}
`
}

export const SupplierCustomerQuery=(data)=>{
  return`
   query SupplierDataFetch{
    supplierFormData(${data}){
      items{
        id
        supplierNo
        companyName
        legalName
        customer
        supplier
        transporter
        transporterId
        gstinType{
          id
          gstType
        }
        gstin
        tcs
        panNo
        active
        supplierCreditedPeriod
        customerCreditedPeriod
        creditedLimit
        customerGroup{
          id
          name
        }
        supplierGroup{
          id
          name
        }
        salesPerson{
          id
          username
        }
        contact{
          index
          id
          contactPersonName
          salutation
          whatsappNo
          email
          phoneNumber
          otherNo
          default
          contactType {
        name
      }
        }
        address {
          id
          addressLine1
          addressLine2
          pincode
          addressType
          city
          state
          country
          default
        }
      }
      pageInfo{
        totalPages
        hasNextPage
        hasPreviousPage
        totalItems
      }
    }
  }
  `
}