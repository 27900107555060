import React, { useState, useEffect} from "react";
import TimePickerDropdown from '@ashwinthomas/react-time-picker-dropdown';
import '../../../Style/time_picker.css';
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { calculateMinutesBetweenTime, convertToDateTime, convertToIST} from "../../../utils/PosUtils";
import { FetchProductionOrderProcessRoute, FetchProductionOrderProcessRouteSingle, ProductionOrderProcessRouteMutateQuery } from "../../../GraphQLQueries/ProductionOrderQuery";
import axiosInstance from "../../../api/axoiss";

const PoRoutingProcessTime = ({show, close, data, isLoad, updatedRouteData, master_qty}) => {
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const handleSubmit = () => {
        let start_time = null;
        let end_time = null;
        let actual_duration = '0'
        let start_time_converted = ''
        let end_time_converted = ''
        if(!startTime && endTime){
            toast.error('Please Enter Start Time!', 
                {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }
            )
            return;
        }
        if(startTime){
            start_time = convertToDateTime(startTime);
        }
        if(endTime){
            end_time = convertToDateTime(endTime);
        }
        if(startTime && endTime){
            actual_duration = calculateMinutesBetweenTime(start_time, end_time);
            if(!actual_duration){
                toast.error('End Time should not be less than Start Time!', 
                    {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                )
                return ;
            }else{
                actual_duration = parseFloat(master_qty) * actual_duration
            }
        }

        if(startTime){
            try{
                start_time_converted = start_time.toISOString()
            }catch(e){
                start_time_converted = data?.startTime
            }
        }else{
            start_time_converted = null
        }

        if(endTime){
            try{
                end_time_converted = end_time.toISOString()
            }catch(e){
                end_time_converted = data?.endTime
            }
        }else{
            end_time_converted = null
        }

        let submit_data = {
            id: parseInt(data.id), 
            startTime: start_time_converted, 
            endTime: end_time_converted,
            actualDuration: String(actual_duration)
        }
        let update_query = ProductionOrderProcessRouteMutateQuery(submit_data)
        axiosInstance.post("/itemmaster/graphql", {query: update_query})
        .then(res => {
            let res_data = res?.data?.data?.productionOrderProcessRouteCreateMutation
            updatedRouteData(res_data?.productionOrderProcessRoute || null)
            // console.log(res_data?.productionOrderProcessRoute)
            if(res_data?.success){
                toast.success('Saved Successfully!', 
                    {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                )
                setStartTime(null)
                setEndTime(null)
                close()
            }else{
                toast.error(res_data?.errors?.[0], 
                    {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                )
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(()=>{
        setStartTime(null);
        setEndTime(null);
        if(data){
            // console.log(data.id)
            let formatted_start_time =  null
            let formatted_end_time =  null
            axiosInstance.post("/itemmaster/graphql", {query: FetchProductionOrderProcessRouteSingle(data.id)})
            .then(res => {
                let data_item = res?.data?.data?.productionOrderProcessRoute?.items?.[0]|| null;
                // console.log(data_item)
                if(data_item){
                    // console.log(data_item, "updated")
                    if(data_item?.startTime){
                        formatted_start_time =  convertToIST(data_item?.startTime)
                    }
                    if(data_item?.endTime){
                        formatted_end_time =  convertToIST(data_item?.endTime)
                    }
                    setStartTime(formatted_start_time || null);
                    setEndTime(formatted_end_time || null);
                }
            })
            .catch(err =>{ 
                console.log(err, "error")
            })
        }
    }, [data, isLoad])

    return (
        <>
            <ToastContainer />
            <Modal show={show} onHide={()=>{
                setStartTime(null);
                setEndTime(null);
                close()
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{data?.route?.routeName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex align-items-end">
                        <div className="col-5">
                            <label className="mb-1">Start Time</label>
                            <TimePickerDropdown
                                className="custom-time-picker-input"
                                placeholder = "hh : mm : ss am"
                                defaultValue = {startTime}
                                useTwelveHourFormat={false}
                                showClockIcon = { true } 
                                showCloseIcon = { true } 
                                allowBackdrop = { true }
                                onTimeChange={(e)=>{setStartTime(e)}}        
                            />
                        </div>
                        <div className="col-5">
                            <label className="mb-1">End Time</label>
                            <TimePickerDropdown
                                className="custom-time-picker-input"
                                placeholder = "hh : mm : ss am"
                                defaultValue = {endTime}
                                useTwelveHourFormat={false}
                                showClockIcon = { true } 
                                showCloseIcon = { true } 
                                allowBackdrop = { true }
                                onTimeChange={(e)=>{setEndTime(e)}}        
                            />
                        </div>
                        <div className="col-2 text-end">
                            <div>
                                <button 
                                    onClick={()=>{handleSubmit()}}
                                    className="btn btn-sm btn-outline-success px-3 fw-bold">Save</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}


const PoRouting = ({data, master_qty}) => {
    const [routeData, setRouteData] = useState(null);
    const [showProcessTimeModal, setShowProcessTimeModal] = useState(false);
    const [processName, setProcessName] = useState(null);
    const [isLoad, setLoad] = useState(false);

    const handleCloseProcessTimeModal = () => {
        setShowProcessTimeModal(false);
    }

    const handleShowProcessTimeModal = (route) => {
        setLoad(!isLoad)
        setProcessName(route)
        setShowProcessTimeModal(true);
    }

    const updatedRouteData = (route_data) => {
        if(route_data){
            let updated_route_data = routeData?.filter(item => item.id !== route_data.id);
            updated_route_data.push(route_data);
            updated_route_data = updated_route_data?.sort((a, b) => a.serialNumber - b.serialNumber);
            setRouteData(updated_route_data);
        }
    }

    useEffect(()=>{
        if(data && data.length > 0){
            let route_id_list = data?.map(item => item.id);
            axiosInstance.post("/itemmaster/graphql", {query: FetchProductionOrderProcessRoute(route_id_list)})
            .then(res => {
                let data_list = res?.data?.data?.productionOrderProcessRoute?.items || []
                const sortedData = data_list?.sort((a, b) => a.serialNumber - b.serialNumber);
                setRouteData(sortedData);
            })
            .catch(err =>{ 
                console.log(err, "error")
            })
        }else{
            setRouteData(null)
        }
    }, [data])

    return(<>
        <div>
            <div className="d-flex align-items-center my-4">
                {!routeData && <p> No routes defined!</p>}
                {routeData && <div><i className="fa-solid fa-play text-success"></i></div> }
                <div className="d-flex justify-content-start">
                    {routeData && routeData.map((item, index) => (
                        <div key={index} className="d-flex align-items-center">
                            <div className="mx-3 d-flex flex-column text-center">
                                <span 
                                    className="badge rounded-pill bg-secondary mb-2"
                                    style={{fontSize: '.9rem', letterSpacing: '4px'}}
                                >
                                    {item?.route?.routeName}</span>
                                <span>{item?.workCenter?.workCenter}</span>
                                <span>{parseFloat(item?.duration || 0) * parseFloat(master_qty || 0 )} mins</span>
                                <span>{item?.actualDuration} mins</span>
                                <span className="mt-1"> 
                                    <button 
                                        className="btn btn-sm btn-outline-primary"
                                        onClick={()=>{handleShowProcessTimeModal(item)}}
                                    >update time</button> 
                                </span>
                            </div>
                            {index < routeData.length - 1 && <div><i className="fa-solid fa-arrow-right"></i></div>}
                        </div>
                    ))}
                </div>
                {routeData &&  <div><i className="fa-solid fa-circle text-danger"></i></div> }
                <PoRoutingProcessTime 
                    show = {showProcessTimeModal}
                    close = {handleCloseProcessTimeModal}
                    data = {processName}
                    isLoad = {isLoad}
                    updatedRouteData = {updatedRouteData}
                    master_qty = {master_qty}
                />
            </div>
        </div>
    </>)
}

export default PoRouting;