import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineCaretLeft, AiOutlineCaretRight, AiOutlineShoppingCart } from 'react-icons/ai';
import { LuGift } from "react-icons/lu";
import { PiPrinterLight } from "react-icons/pi";
import { TiCancel } from 'react-icons/ti';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import HierarchicalTreeView from '../../../components/customTreeViewComponent/HierarchicalTreeView';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import DiscountModel from '../../../components/DiscountModel/DiscountModel';
import BallTriangleComponent from '../../../components/loading/BallTriangle';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import TaxModel from '../../../components/Tax/TaxModel';
import WaringModel from '../../../components/Warings/WaringModel';
import WaringModelWithCopy from '../../../components/Warings/WaringModelWithCopy';
import DataContext from '../../../context/ItemMasterContext';
import { DepartmentQuery, stockIdFetchQuery, SupplierOptionPerameter } from '../../../GraphQLQueries/ItemmasterQuery';
import { leadTimeLine } from '../../../GraphQLQueries/LeadQueryAndMutations/LeadQuery';
import { TermsConditionsFetchQuery } from '../../../GraphQLQueries/PurchaseItemMutations';
import { getItemComboDetails } from '../../../GraphQLQueries/QuatationqueryAndMutatiom/QuatationItemModalMutation';
import {
  GeneratePdfForSalesOrder,
  salesOrder2AmandCancel, salesOrder2CanceledMutation, salesOrder2CreateMutation,
  salesOrder2DeleteMutation,
  salesOrder2OtherIncomeChargesDeleteMutation
} from '../../../GraphQLQueries/SalesOrderQueryAndMutation/salesOrderMuatations';
import { SalesOrderEditQuery } from '../../../GraphQLQueries/SalesOrderQueryAndMutation/SalesOrderQuery';
import { userPermissionforOptions } from '../../../GraphQLQueries/userQueryandMutations/userQuerys';
import CommanTable from '../../../Packages/Custom/CommanTable';
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { calculateTax, checkNegativeNumber, CommanFinalDiscountValue, commanOverallDiscountValue, commanPercentageDiscount, DateFormate, displayRupeeFormat, getCurrentDate, getStates, removeEmptyValueInObject, roundToNearest10, ShowErrorNoties, textEditormodules, TimeFormate } from '../../../utils/Utils';
import { SalesOrder2schema, SalesOrderOtherIncomeCharges } from '../../../validations/SalesOrderValidation';
import ItemCombo from '../../Quotation/Popup/itemCombo';
import StockStatementDetails from '../../StockStatement/Details/StockStatementDetails';
import SalesOrderItem from './SalesOrderItem';
import SalesOrderItemCombo, { parseAndGroupItemComboData } from './SalesOrderItemCombo';


function WarningmodelSalesorder(show, error, setSalesOrderValues) {
  const renderErrorMessages = () => {
    if (typeof error === 'string') {
      try {
        // If error is a string, try to parse it into an object
        error = JSON.parse(error);
      } catch (e) {
        console.log("Error is not a valid JSON string", e);
      }
    }

    if (typeof error === 'object' && error !== null) {
      // Separate LEAD entries and others
      const leadEntries = Object.entries(error).filter(([key]) => key.startsWith('LEAD'));
      const otherEntries = Object.entries(error).filter(([key]) => !key.startsWith('LEAD'));
      // Calculate the total for otherEntries
      const totalOtherEntries = otherEntries.reduce((acc, [key, value]) => acc + value, 0);
      return (
        <><div className="row">
          <h6 style={{ color: "red" }}>Sales Order before tax value - does not match with the Lead value!
          </h6>
          <div className="col-6">
            {/* LEAD table */}
            <div>
              <h6>LEAD</h6>
              <table>
                <tbody>
                  {leadEntries.map(([key, value], index) => (
                    <tr key={key}>
                      <td>{index + 1}. {key}</td>
                      <td>: {value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6">
            {/* Other entries table */}
            <div>
              <h6>SO Taxable Value</h6>
              <table>
                <tbody>
                  {otherEntries.map(([key, value], index) => (
                    <tr key={key}>
                      <td>{index + 1}. {key}</td>
                      <td> : {value}</td>
                    </tr>
                  ))}
                  {/* Display total below otherEntries */}
                  <tr>
                    <td><strong>Total: </strong></td>
                    <td>{totalOtherEntries}</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
        </>
      );
    }

    return <div>{error || 'No error message available'}</div>;
  };

  return (
    <Modal show={show} onHide={() => { setSalesOrderValues(prev => ({ ...prev, isshowWaring: false })) }}>
      <Modal.Header closeButton>
        <Modal.Title>Mismatch Value</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderErrorMessages()}</Modal.Body>
    </Modal>
  );

}

const SalesOrderForm = ({ index, setLoad, editData, leadData, quotationData }) => {
  const { otherIncomeChargelist, userId, prefetchValue, openSalesOrderModal, openQuotationModal,
    closeSalesOrderModal, openLeadModal, salesOrderModels } = useContext(DataContext)
  const currentDateType = getCurrentDate();
  const [initialSalesOrder, setInitialSalesOrder] = useState({
    id: "",
    active: true,
    salesOrderNumber: "",
    status: "",
    salesPerson: "",
    dueDate: "",
    currency: "",
    creditPeriod: "",
    paymentTerms: "",
    customerPoNo: " ",
    customerPoDate: " ",
    leadNo: "",
    quotations: "",
    department: "",
    remarks: " ",
    buyer: " ",
    childCount: " ",
    buyerAddress: " ",
    buyerContactPerson: " ",
    buyerGstinType: " ",
    buyerGstin: " ",
    buyerState: " ",
    buyerPlaceOfSupply: " ",
    consignee: " ",
    consigneeAddress: " ",
    consigneeContactPerson: " ",
    consigneeGstinType: " ",
    consigneeGstin: " ",
    consigneeState: " ",
    consigneePlaceOfSupply: " ",
    itemDetails: "",
    otherIncomeCharge: "",
    overallDiscountPercentage: "",
    overallDiscountValue: "",
    discountFinalTotal: "",
    igst: "",
    sgst: "",
    cgst: "",
    itemTotalBeforTax: "",
    otherChargesBeforTax: "",
    taxTotal: "",
    roundOff: "",
    netAmount: "",
    termsConditions: "",
    termsConditionsText: "",
    modifiedBy: "",
    createdBy: "",
    createdAt: currentDateType,
    salesOrderDate: currentDateType,
    updatedAt: ''
  })
  const [timeLineValue, setTimeLineValue] = useState({ treeData: [], isShow: false })
  const [isCancelWarningShow, setIsCancelWarningShow] = useState(false)
  const [isDeleteWarningShow, setIsDeleteWarningShow] = useState(false)
  const [formKey, setFormKey] = useState(0)
  const [isShowItemDetails, setIsShowItemDetails] = useState(false)
  const [salesOrderItemDetails, setSalesOrderItemDetails] = useState([])
  const [salesOrderItemDetailsEdit, setSalesOrderItemDetailsEdit] = useState(null)
  const [itemComboDataList, setItemComboDataList] = useState([])
  const [salesPersonList, setSalesPersonList] = useState([])
  const [otherIncomechargesList, setOtherIncomechargesList] = useState([])
  const [edit, setEdit] = useState(true)
  const [isDiscountWaring, setDiscountWaring] = useState(false)
  const [isBulkDeleteWaring, setIsBulkDeleteWaring] = useState(false)
  const [isShowTax, setIsShowTax] = useState(false)
  const [isShowDiscount, setIsShowDiscount] = useState(false)
  const [isShowItemComboModel, setIsShowItemComboModel] = useState(false)
  const [isShowOutOfRange, setIsShowOutOfRange] = useState(false)
  const [selectedStockStatementDetails, setSelectedStockStatementDetails] = useState([])
  const [isShowStockStatementModal, setShowStockStatementModal] = useState(false)
  const [customerDetailsLists, setCustomerDetailsLists] = useState({
    "buyerNameList": [], "buyerNumberList": [],
    "buyerAddressList": [], "buyerContactList": [], "buyerStatesList": [],
    "consigneeList": [], "consigneeNumberList": [], "consigneeAddressList": [],
    "consigneeContactList": [], "consigneeStatesList": [], "otherIncomeChargelist": [], "termsConditionsList": [], "DepartmentList": []
  })

  const [positionOfVirsion, setPositionOfVirsion] = useState({ IsFirst: "", IsLast: "" })
  const [discountValue, setDiscountValue] = useState({
    discountError: "", discountPercentage: "", discountValue: "",
    discountTotalValue: "", isDiscountApplied: false
  })
  const [salesOrderValues, setSalesOrderValues] = useState({
    salesPerson: "",
    buyer: "", buyerNumber: "", buyerAddress: "", buyerContactPerson: "",
    buyerPlaceOfSupply: "",
    consignee: "", consigneeNumber: "", consigneeAddress: "", consigneeContactPerson: "", consigneePlaceOfSupply: "", otherIncomeCharge: "",
    totaltax: "", item_details_value: "", other_income_value: "", netAmount: "", roundOff: "", sgst: "", cgst: "", igst: "",
    termsCondition: "", termsConditionsText: "", Department: "", createdBy: "", createdAt: "", allowCommanDisCount: false,
    outOfRangeValues: [], actualNetAmount: "", lastUpdatedItemDetails: "", stringItemCombo: "", isLoading: false, isAmend: false,
    deleteItemDetailData: { Name: "", id: "" },
    deleteOtherExpensesData: { Name: "", id: "" }, setSelectedPartNo: "", versionList: [], currentVersionId: "", parentOrder: "", isDuplicate: "",
    currency: "", currencySymbol: "", lead: { id: "", leadNo: "" }, quotations: "", actualigstResult: "", actualcgstResult: "", actualsgstResult: "", waring: "",
    isshowWaring: false
  })
  const [isItemDeleteWarningShow, setisItemDeleteWarningShow] = useState(false)
  const [IsDeleteOtherIncomeCharges, setIsDeleteOtherIncomeCharges] = useState(false)
  const [deleteOtherIncomeData, setDeleteOtherIncomeData] = useState('')
  const [itemComboValues, setitemComboValues] = useState({
    showItemCombo: false, lastUpdatedItemDetails: [], itemComboData: []
  })
  const [calculatedDiscount, setCalculatedDiscount] = useState(0)

  //this funtionc show the Discount Properly
  const ratecellrender = params => {
    let discountPercentage = params?.discountPercentage !== undefined ? params.discountPercentage : 0;
    let discountValue = params?.discountValue !== undefined ? params.discountValue : 0;
    let afterDiscountValue = params?.afterDiscountValueForPerItem !== undefined ? params.afterDiscountValueForPerItem : 0;
    let finalValue = params?.finalValue !== undefined ? params.finalValue : 0;
    let rate = params?.rate !== undefined ? params.rate : 0;
    let currencyLabel = salesOrderValues?.currency?.label


    return (
      <>
        {displayRupeeFormat(rate, currencyLabel)}
        {Number(discountPercentage) !== 0
          ? ` - ${discountPercentage}% = ${displayRupeeFormat(afterDiscountValue, currencyLabel)}`
          : Number(discountValue) !== 0
            ? ` - ${displayRupeeFormat(discountValue, currencyLabel)} = ${displayRupeeFormat(afterDiscountValue, currencyLabel)}`
            : Number(afterDiscountValue) !== 0
              ? `- ${displayRupeeFormat(finalValue, currencyLabel)} =${displayRupeeFormat(afterDiscountValue, currencyLabel)} `
              : ''}
      </>
    );
  };

  const handleCloseStockStatementModal = () => {
    setShowStockStatementModal(false)
  }

  const handleShowStockStatementModal = async (data) => {
    try {
      // Set loading state to true if needed
      setSalesOrderValues(prve => ({ ...prve, isLoading: true }))

      // Perform the API call
      const response = await axiosInstance.post('/itemmaster/graphql', {
        query: stockIdFetchQuery(data?.['itemmaster']?.['id']),
      });

      let stock_ids = [];
      stock_ids = response?.data?.data?.stockIds?.items || [];

      // Construct the stock details dictionary
      let construct_stock_dict = {
        stockId: [],
        itemPartCode: data?.partCode?.itemPartCode,
        itemName: data?.partCode?.itemName,
        description: data?.description,
        batchNumber: '',
        serial: '',
      };

      // If stock ids are available, extract values
      if (stock_ids.length > 0) {
        construct_stock_dict['partCode'] = stock_ids[0]?.partNumber?.['itemPartCode'];
        construct_stock_dict['partName'] = stock_ids[0]?.partNumber?.['itemName'];
        construct_stock_dict['description'] = stock_ids[0]?.partNumber?.['description'];
        construct_stock_dict['isBatch'] = stock_ids[0]?.partNumber?.['batchNumber'];
        construct_stock_dict['isSerial'] = stock_ids[0]?.partNumber?.['serial'];
      }

      // Map stock ids and update the state
      construct_stock_dict['stockId'] = stock_ids.map(item => item.id);

      setSelectedStockStatementDetails(construct_stock_dict)
      setShowStockStatementModal(true)
      setSalesOrderValues(prve => ({ ...prve, setSelectedPartNo: data?.['itemmaster']?.['id'] }))
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    } finally {
      // Ensure loading state is reset regardless of success or failure
      setSalesOrderValues(prve => ({ ...prve, isLoading: false }))
    }
  };

  // Item Details Actions
  const actionsButton = (data) => {
    const handledeleteConfomation = () => {
      setSalesOrderValues(prve => ({ ...prve, deleteItemDetailData: { Name: data?.item_master_part_code?.label, id: data?.index } }))
      setisItemDeleteWarningShow(true);
    }

    const openTheItemCombo = async () => {
      if (data?.itemComboBool && data?.itemComboItemDetails?.length === 0) {
        const reqBody = {
          itemmaster: data?.item_master_part_code?.value,
          amount: String(data?.amount),
          qty: String(data?.qty)
        }
        const response = await axiosInstance.post(`/itemmaster/graphql`, {
          query: getItemComboDetails(reqBody)
        });

        const responseData = response?.data?.data?.getItemComboItemDetails?.itemComboData;
        const groupedData = parseAndGroupItemComboData(responseData, data?.index);
        setSalesOrderValues(prve => ({
          ...prve, lastUpdatedItemDetails: data
        }))
        setItemComboDataList(groupedData)
        setIsShowItemComboModel(true)


      } else {
        setItemComboDataList(data?.itemComboItemDetails)
        setSalesOrderValues(prve => ({ ...prve, lastUpdatedItemDetails: data }))
      }
      setIsShowItemComboModel(true)
    }
    const showStock = () => {
      handleShowStockStatementModal(data)
    }

    return (
      <div style={{ alignItems: "left" }}>
        {/* <button
          type='button'
          className='btn btn-outline-success btn-sm px-3 mx-2'
          onClick={handleClick}
          disabled={edit}

        >
          <i className='fa-solid fa-pen'></i>
        </button> */}
        <button
          type='button'
          className='btn btn-outline-danger btn-sm px-3 mx-2'
          onClick={() => { handledeleteConfomation() }}
          disabled={edit}
        >
          <i className='fa-solid fa-trash '></i>
        </button>
        <AiOutlineShoppingCart
          style={{ cursor: 'pointer' }}
          className='fs-3'
          onClick={() => {
            showStock()
          }}
        />
        {data?.itemComboBool && data?.itemComboItemDetails?.length > 0 ? <button type='button' className='btn btn-outline-info btn-sm px-3 mx-2' onClick={openTheItemCombo} disabled={edit}>
          <LuGift />
        </button> : data?.itemComboBool ? <button type='button' className='btn btn-outline-warning btn-sm px-3 mx-2' onClick={openTheItemCombo} disabled={edit}>
          <LuGift />
        </button> : ""}


      </div>
    )
  }
  const itemDetailsEdit = (params) => {
    const renderTooltip = (props) => {
      // Conditional rendering within the function
      if (params?.itemComboBool === true) {
        return (
          <Tooltip id="button-tooltip" {...props}>
            Item Combo is not editable
          </Tooltip>
        );
      }
      return !edit ? (
        <Tooltip id="button-tooltip" {...props}>
          Click here to edit
        </Tooltip>
      ) : (
        <Tooltip id="button-tooltip" {...props}>
          Click Edit Button To enable Editing
        </Tooltip>
      );
    };
    return (
      <>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <p
            className="text-primary"
            style={{ cursor: 'pointer', marginTop: '1rem' }}
            onClick={() => {
              if (params?.itemComboBool === true || edit) {
                return;
              }
              setSalesOrderItemDetailsEdit(params)
              setIsShowItemDetails(true)
            }}
          >
            {params?.description}
          </p>

        </OverlayTrigger>
      </>
    )
  }
  // define the column for item details
  const COLUMNS = [
    // {
    //   input: 'drag',
    //   flex: .2,
    // },
    {
      headerName: 'S No',
      input: 'S No',
      flex: 5,
      // cellRenderer: 'serialNumberRenderer', // Use custom renderer
      // onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 30,
      textAlign: 'left',
      cellRenderer: itemDetailsEdit
    },
    {
      headerName: 'SO Qty',
      field: 'qty',
      flex: 10,
      // onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'UOM',
      field: 'uom',
      label: 'label',
      flex: 10,
      // onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'Rate',
      field: 'rate',
      textAlign: 'right',
      flex: 30,
      cellRenderer: ratecellrender,
      // onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'HSN',
      field: 'hsnCode',
      flex: 10,
      // onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'Tax',
      field: 'tax',
      flex: 10,
      // onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'Amount',
      textAlign: 'right',
      cellRenderer: (params) => displayRupeeFormat(params?.amount, salesOrderValues?.currency?.label),
      flex: 15,
      // onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'Action',
      textAlign: 'left',
      cellRenderer: actionsButton,
      flex: 15,
    },
  ]
  const ratecellrenderOtherIncome = params => {
    let discountValue = params?.discountValue ? params?.discountValue : 0;
    let afterDiscountValue = params?.afterDiscountValue !== undefined ? params?.afterDiscountValue : 0;
    let amount = params?.amount !== undefined ? params?.amount : 0;
    let currencyLabel = salesOrderValues?.currency?.label

    return (
      <>
        {discountValue
          ? `${displayRupeeFormat(amount, currencyLabel)} - ${displayRupeeFormat(discountValue, currencyLabel)} = ${displayRupeeFormat(afterDiscountValue, currencyLabel)}`
          : displayRupeeFormat(amount, currencyLabel)
        }
      </>
    );
  }
  // define the column for other Charges
  const OTHER_INCOME_CHARGES_COLUMNDEFS = [
    {
      headerName: 'Account',
      field: 'otherIncomeChargesId',
      label: "label",
      flex: '20',

    },
    {
      headerName: 'Tax',
      field: 'tax',
      flex: '11',

    },
    {
      headerName: "Amount",
      textAlign: 'right',
      cellRenderer: ratecellrenderOtherIncome,
      flex: '20'
    },
    {
      headerName: 'Actions',
      flex: '10',
      active: false
    },
  ]

  // Redux
  const dispatch = useDispatch();
  const Options = useSelector(state => state.itemGroup.data);

  // get data by Redux
  useEffect(() => {
    if (Options.name === "SalesOrderSalesperson") {
      setSalesPersonList(Options?.value?.items?.map((user) => ({ value: user?.userId?.id, label: user?.userId?.username })))
    }
    if (Options.name === "SalesOrderCustomer") {
      setCustomerDetailsLists(prve => ({
        ...prve, buyerNameList: Options?.value?.items.map((customer) => ({
          value: customer?.id, label: customer?.companyName, supplierNo: customer?.supplierNo,
          contact: customer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
          address: customer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
          gstin: customer?.gstin, gstinType: customer?.gstinType?.gstType
        }))
      }))

    } if (Options.name === "SalesOrderCustomerNumber") {
      setCustomerDetailsLists(prve => ({
        ...prve, buyerNumberList: Options?.value?.items.map((customer) => ({
          value: customer?.id, label: customer?.supplierNo, supplierName: customer?.companyName,
          contact: customer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
          address: customer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
          gstin: customer?.gstin, gstinType: customer?.gstinType?.gstType
        }))
      }))
    }
    if (Options.name === "SalesOrderConsignee") {
      setCustomerDetailsLists(prve => ({
        ...prve, consigneeList: Options?.value?.items.map((customer) => ({
          value: customer?.id, label: customer?.companyName, supplierNo: customer?.supplierNo,
          contact: customer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
          address: customer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
          gstin: customer?.gstin, gstinType: customer?.gstinType?.gstType
        }))
      }))
    }
    if (Options.name === "SalesOrderConsigneeNumber") {
      setCustomerDetailsLists(prve => ({
        ...prve, consigneeNumberList: Options?.value?.items.map((customer) => ({
          value: customer?.id, label: customer?.supplierNo, supplierName: customer?.companyName,
          contact: customer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
          address: customer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
          gstin: customer?.gstin, gstinType: customer?.gstinType?.gstType
        }))
      }))
    }
    if (Options.name === "SalesOrderTermsConditionsFetchQuery") {
      setCustomerDetailsLists(prve => ({
        ...prve, termsConditionsList: Options?.value?.items.map((tc) => ({
          value: tc?.id, label: tc?.name,
          tc: tc?.tc
        }))
      }))
    }
    if (Options.name === "SalesOrderdepartmant") {
      setCustomerDetailsLists(prve => ({
        ...prve, DepartmentList: Options?.value?.items?.map(item => ({ value: item.id, label: item.name }))
      }))

    }
    if (Options.name === "SalesOrderCustomerForLEad") {
      try {
        setCustomerDetailsLists(prve => ({
          ...prve, buyerNameList: Options?.value?.items.map((customer) => ({
            value: customer?.id, label: customer?.companyName, supplierNo: customer?.supplierNo,
            contact: customer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
            address: customer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
            gstin: customer?.gstin, gstinType: customer?.gstinType?.gstType
          })),
          buyerNumberList: Options?.value?.items.map((customer) => ({
            value: customer?.id, label: customer?.supplierNo, supplierName: customer?.companyName,
            contact: customer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
            address: customer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
            gstin: customer?.gstin, gstinType: customer?.gstinType?.gstType
          })),
          buyerAddressList: Options?.value?.items?.[0]?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
          buyerContactList: Options?.value?.items?.[0]?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
          buyerStatesList: getStates(Options?.value?.items?.[0]?.address.filter((address) => address?.default === true)?.[0]?.country)
        }))

        let selectData = Options?.value?.items?.[0]
        let selectAddress = Options?.value?.items?.[0]?.address.filter((address) => address?.default === true)?.[0]
        let selectContact = Options?.value?.items?.[0]?.contact.filter((Contact) => Contact?.default === true)?.[0]
        // fullAddredd?. 
        setSalesOrderValues(prve => ({
          ...prve,
          buyer: { value: selectData?.id, label: selectData?.companyName, supplierNo: selectData?.supplierNo, },
          buyerNumber: { value: selectData?.id, label: selectData?.supplierNo, supplierNo: selectData?.companyName },
          buyerAddress: { value: selectAddress?.id, label: selectAddress?.addressType, fullAddredd: selectAddress },
          buyerContactPerson: { value: selectContact?.id, label: selectContact?.contactPersonName, mobile: selectContact?.phoneNumber, Email: selectContact?.email },
          buyerPlaceOfSupply: { value: selectAddress?.state, label: selectAddress?.state },
        }))
        setInitialSalesOrder((prevValues) => ({
          ...prevValues,
          buyer: Number(selectData?.id),
          buyerGstinType: Options?.value?.items?.[0]?.gstinType?.gstType,
          buyerGstin: Options?.value?.items?.[0]?.gstin,
          buyerState: selectAddress?.state,
          buyerPlaceOfSupply: selectAddress?.state,
          buyerAddress: Number(selectAddress?.id),
          buyerContactPerson: Number(selectContact?.id)
        }));
      } catch (error) {
        console.log("error", error);

      }
    }
    // if (Options?.name === "copyQuotasionToSalesOrder2") {
    //   const updatedItemDetail = Options?.value?.itemDetails?.map((item, rowIndex) => ({
    //     ...item,
    //     item_master_part_code: {
    //       description: item?.description,
    //       hsnCode: item?.hsnCode,
    //       itemComboBool: item?.itemCombo,
    //       value: item?.itemmaster?.id,
    //       label: item?.itemmaster?.itemPartCode,
    //       uom: item?.uom
    //     },
    //     item_master_part_name: {
    //       value: item?.itemmaster?.id,
    //       label: item?.itemmaster?.itemName,
    //     },
    //     index: rowIndex + 1,
    //     uom: {
    //       value: item?.uom?.id,
    //       label: item?.uom?.name
    //     },
    //     hsnCode: item?.itemmaster?.itemHsn?.hsnCode,
    //     createdBy: item?.createdBy?.id ? item?.createdBy?.id : Number(userId)
    //   }))
    //   const updatedOtherincome = Options?.value?.otherIncomeCharge?.map((item, rowIndex) => ({
    //     ...item,
    //     index: rowIndex + 1,
    //     otherIncomeChargesId: {
    //       hsn: item?.otherIncomeChargesId?.hsn?.id,
    //       hsnCode: item?.otherIncomeChargesId?.hsn?.hsnCode,
    //       value: item?.otherIncomeChargesId?.id,
    //       label: item?.otherIncomeChargesId?.name,
    //       tax: item?.tax
    //     },
    //     igst: item?.igst ? item?.igst : '',
    //     sgst: item?.sgst ? item?.sgst : '',
    //     cgst: item?.cgst ? item?.cgst : '',
    //     createdBy: item?.createdBy
    //   }))
    //   console.log("Options?.value?.otherIncomeCharge", Options?.value?.otherIncomeCharge);
    //   setSalesOrderItemDetails(updatedItemDetail)
    //   setOtherIncomechargesList(updatedOtherincome)

    // }

  }, [dispatch, Options])


  // return null; save
  const handleSubmit = async (values, { resetForm, setErrors }) => {
    const formattedItemDetails = salesOrderItemDetails.map(item => {
      const itemcomboDetails = item?.itemComboBool && item?.itemComboItemDetails?.length > 0 ?
        item?.itemComboItemDetails?.map(detail => ({
          id: detail?.id ? detail?.id : null,
          itemmaster: detail?.itemmasterId ? Number(detail?.itemmasterId) : null,
          uom: detail?.uom?.value ? Number(detail?.uom?.value) : null,
          qty: detail?.qty ? String(detail?.qty) : null,
          rate: detail?.rate ? String(Number(detail?.rate).toFixed(2)) : null,
          display: detail?.display ? detail?.display : "",
          isMandatory: detail?.isMandatory ? detail?.isMandatory : false,
          afterDiscountValueForPerItem: detail?.afterDiscountValueForPerItem ? String(detail?.afterDiscountValueForPerItem) : null,
          amount: detail?.amount ? String(Number(detail?.amount).toFixed(2)) : null,
          modifiedBy: detail?.modifiedBy ? detail?.modifiedBy : Number(userId),
          createdBy: detail?.createdBy ? detail?.createdBy : Number(userId),
        }))
        : []
      return {
        id: item?.id ?? "",
        itemmaster: item?.item_master_part_code?.value ? Number(item?.item_master_part_code?.value) : null,
        qty: item?.qty ? Number(item?.qty) : 1,
        rate: item?.rate ? String(Number(item?.rate).toFixed(2)) : 0,
        description: item?.description ?? "",
        tax: item?.tax ? Number(item?.tax) : 0,
        afterDiscountValueForPerItem: item?.afterDiscountValueForPerItem ? String(item?.afterDiscountValueForPerItem) : null,
        itemComboItemDetails: itemcomboDetails,
        discountPercentage: item?.discountPercentage ? String(item?.discountPercentage) : null,
        discountValue: item?.discountValue ? String(item?.discountValue) : null,
        finalValue: item?.finalValue ? String(item?.finalValue) : null,
        uom: item?.uom?.value ? Number(item?.uom?.value) : null,
        igst: item?.igst ? String(item?.igst) : null,
        sgst: item?.sgst ? String(item?.sgst) : null,
        cgst: item?.cgst ? String(item?.cgst) : null,
        states: item?.states ?? "",
        amount: item?.amount ? String(Number(item?.amount).toFixed(2)) : null,
        hsn: item?.hsnId ? Number(item?.hsnId) : null,
        modifiedBy: item.id ? item?.modifiedBy ? Number(item?.modifiedBy) : Number(userId) : null,
        createdBy: item.createdBy ? Number(item.createdBy) : Number(userId)
      };
    });
    const formattedOtherIncomeCharges = otherIncomechargesList.map(item => {
      return {
        id: item?.id ?? null,
        otherIncomeChargesId: item?.otherIncomeChargesId?.value ? Number(item?.otherIncomeChargesId?.value) : null,
        currency: salesOrderValues?.currency?.value ? Number(salesOrderValues?.currency?.value) : null,
        tax: item?.otherIncomeChargesId?.tax ? Number(item?.otherIncomeChargesId?.tax) : null,
        amount: item?.amount ? String(item?.amount) : null,
        states: salesOrderValues?.buyerAddress?.fullAddredd?.state ?? "",
        sgst: item.sgst ? String(item?.sgst) : null,
        cgst: item.cgst ? String(item?.cgst) : null,
        igst: item.igst ? String(item?.igst) : null,
        modifiedBy: item?.modifiedBy ? Number(item?.modifiedBy) : null,
        afterDiscountValue: item?.afterDiscountValue ? String(item?.afterDiscountValue) : null,
        discountValue: item?.discountValue ? String(item?.discountValue) : null,
        createdBy: item?.createdBy ? Number(item?.createdBy) : Number(userId)
      };
    });

    values['itemDetails'] = formattedItemDetails
    values['otherIncomeCharge'] = formattedOtherIncomeCharges
    values['igst'] = salesOrderValues?.igst
    values['sgst'] = salesOrderValues?.sgst
    values['cgst'] = salesOrderValues?.cgst
    values['itemTotalBeforTax'] = salesOrderValues?.item_details_value
    values['otherChargesBeforTax'] = salesOrderValues?.other_income_value
    values['taxTotal'] = salesOrderValues?.totaltax
    values['roundOff'] = salesOrderValues?.roundOff
    values['netAmount'] = salesOrderValues?.netAmount
    values['overallDiscountPercentage'] = discountValue?.discountPercentage
    values['overallDiscountValue'] = discountValue?.discountValue
    values['discountFinalTotal'] = discountValue?.discountTotalValue
    values['salesOrderDate'] = values['salesOrderDate'] ? values['salesOrderDate'] : currentDateType
    values['childCount'] = ""
    if (values['id'] === "") {
      values['createdBy'] = Number(userId)
    }
    if (values['status'] === "") {
      values['status'] = "Draft"
    }
    delete values?.['salesOrderNumber']
    delete values?.['createdAt']
    delete values?.['createdTime']
    delete values?.['active']
    delete values?.['updatedAt']
    delete values?.['buyerNumber']
    if (salesOrderValues?.isAmend) {
      values['id'] = ""
      values['createdBy'] = Number(userId)
      values['modifiedBy'] = ""
      values['parentOrder'] = salesOrderValues?.parentOrder ? Number(salesOrderValues?.parentOrder) : ""
      values['childCount'] = initialSalesOrder?.childCount >= 0 ? Number(initialSalesOrder?.childCount) + 1 : 1
      values['status'] = 'Submit'
    }

    let allow_null = ['id', "modifiedBy", "leadNo", "quotations", "department", "overallDiscountPercentage",
      "overallDiscountValue", "discountFinalTotal", "childCount", "salesDate"]
    let clearedValue = removeEmptyValueInObject(values, allow_null, [], false, ["itemDetails", "otherIncomeCharge"])
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: salesOrder2CreateMutation(clearedValue) });
      const responseData = response?.['data']?.['data']?.['salesOrder2CreateMutation']
      if (responseData?.success) {
        updateTheStates(responseData?.SalesOrder2)
        resetForm()
        if (values?.['status'] === "Submit") {
          setEdit(true)
          showErrorToast(true, "success", "", "Submit Successfully")
        } else {
          showErrorToast(true, "success", "", "Draft Successfully")
        }
        if (responseData?.version?.versionList) {
          setSalesOrderValues(prve => ({ ...prve, versionList: responseData?.version?.versionList }))
        }
      } else {
        if (responseData?.waring) {
          setSalesOrderValues(prev => ({ ...prev, "waring": responseData?.waring, isshowWaring: true }))
        }
        if (responseData?.outOfRangeErrors?.length > 0) {
          setIsShowOutOfRange(true)
          setSalesOrderValues(prev => ({ ...prev, "outOfRangeValues": responseData?.outOfRangeErrors }))
        } else if (responseData.errors) {
          let errors = ShowErrorNoties(responseData.errors)
          showErrorToast(true, "error", errors,)
        }
      }

    } catch (error) {
      showErrorToast(true, "error", ShowErrorNoties(error))
    }
  }

  // save the item Combo in bulk data
  const saveItemCombo = async (data, itemDetails, closeItemCombo) => {
    let modifiedData = data?.map((item) => {
      if (item?.isSelected) {
        let modifiedValue = {
          ...item,
          createdBy: Number(userId),
          id: null,
          itemmaster: Number(item?.itemmasterId),
          modifiedBy: null,
          parentIndex: Number(itemDetails?.index),
          uom: { value: Number(item?.uom?.value), label: item?.uom?.label },
          afterDiscountValueForPerItem: item?.afterDiscountValueForPerItem,
          amount: Number(item?.amount).toFixed(2),
          qty: Number(item?.qty).toFixed(2),
          rate: Number(item?.rate).toFixed(2),
          edit: true,
        }
        // delete modifiedValue?.['isSelected']
        return modifiedValue
      }
    }).filter(item => item !== undefined)
    // Store the updated salesOrderItemDetails in a variable
    let updatedSalesOrderItemDetails = [...salesOrderItemDetails];
    // Iterate over the salesOrderItemDetails and update the relevant one
    updatedSalesOrderItemDetails = updatedSalesOrderItemDetails.map((item) =>
      item.index === modifiedData[0]?.parentIndex
        ? { ...item, itemComboItemDetails: modifiedData }
        : item
    );
    setSalesOrderItemDetails(updatedSalesOrderItemDetails);
    setIsShowItemComboModel(false)
    closeItemCombo()

    // Convert modifiedData to a proper GraphQL string
    // const itemsString = JSON.stringify(modifiedData)
    //   .replace(/"([^"]+)":/g, '$1:') // Remove quotes from keys
    //   .replace(/null/g, 'null'); // Ensure null values are not quoted
    // try {
    //   const respones = await axiosInstance.post("/itemmaster/graphql", { query: salesOrder2ItemcomboItemDetailsCreateMutations(itemsString) })
    //   const responseData = respones.data.data.salesOrder2ItemcomboItemDetailsCreateMutations
    //   if (responseData?.success) {
    //     //   return the parent component the update it
    //     setSalesOrderItemDetails(prev => {
    //       const newItem = responseData?.parent;
    //       if (!newItem) return prev; // Exit early if there's no new item
    //       const existingIndex = prev.findIndex(item => item?.id === newItem.id);
    //       if (existingIndex !== -1) {
    //         // Replace the existing item
    //         const newList = [...prev];
    //         newList[existingIndex] = newItem;
    //         return newList;
    //       } else {
    //         // Add the new item
    //         return [...prev, newItem];
    //       }
    //     });
    //     setIsShowItemComboModel(false)
    //     closeItemCombo()
    //   } else {
    //     console.log(responseData?.errors);

    //   }

    // } catch (error) {
    //   console.log(error);


    // }
  }
  // const handleSubmitOtherExpenses = async (values, { setSubmitting, resetForm, setErrors, setFieldError }, toggle, setOpenRowIndex) => {
  //   values['states'] = salesOrderValues?.buyerAddress?.fullAddredd?.state
  //   values['currency'] = salesOrderValues?.currency?.value ? Number(salesOrderValues?.currency?.value) : ""
  //   console.log("values", values);

  //   // try {
  //   //   let clearedValue = removeEmptyValueInObject(values, ["id", "modifiedBy", "currency"])
  //   //   const response = await axiosInstance.post(`/itemmaster/graphql`, { query: salesOrder2otherincomeCharges(clearedValue) });
  //   //   const responseData = response?.['data']?.['data']?.['salesOrder2OtherIncomeChargesCreateMutations']
  //   //   responseData['salesOrder2OtherincomeCharges']['id_'] = responseData?.salesOrder2OtherincomeCharges?.id
  //   //   let newId = Number(responseData?.salesOrder2OtherincomeCharges?.id); // Convert to number
  //   //   let oldId = otherIncomechargesList?.map(item => Number(item?.id_)) || []; // Ensure oldId is an array
  //   //   if (!isNaN(newId)) { // Check if newId is a valid number
  //   //     oldId.push(newId); // Add newId to oldId
  //   //   }
  //   //   let modified_data = [...new Set(oldId)]; // Store the modified array
  //   //   setOtherIncomechargesList(prev => {
  //   //     const newItem = responseData?.salesOrder2OtherincomeCharges
  //   //     if (!newItem) return prev; // Exit early if there's no new item
  //   //     const existingIndex = prev.findIndex(item => item?.id === newItem.id);
  //   //     if (existingIndex !== -1) {
  //   //       // Replace the existing item
  //   //       const newList = [...prev];
  //   //       newList[existingIndex] = newItem;

  //   //       return newList;
  //   //     } else {
  //   //       // Add the new item
  //   //       return [...prev, newItem];
  //   //     }

  //   //   });
  //   //   if (discountValue?.isDiscountApplied) {
  //   //     let allItemDetailsIds = salesOrderItemDetails?.map(item => Number(item?.id))
  //   //     let allOtherIncomeChargesIds = modified_data
  //   //     ClearDisCount(allItemDetailsIds, allOtherIncomeChargesIds)
  //   //   }
  //   //   resetForm()
  //   //   toggle()
  //   //   setOpenRowIndex()
  //   // } catch (error) {

  //   // }


  // }
  // Other Income charges input sections
  // const OtherIncomechargesInputFrom = (toggle, setOpenRowIndex, rowdata) => {

  //   const initialValues = {
  //     id: rowdata?.id_ ? Number(rowdata?.id_) : "",
  //     otherIncomeChargesId: rowdata?.otherIncomeChargesId?.id ? Number(rowdata?.otherIncomeChargesId?.id) : "",
  //     amount: rowdata?.amount ? rowdata?.amount : "",
  //     tax: rowdata?.tax ? Number(rowdata?.tax) : "",
  //     createdBy: rowdata?.id ? Number(rowdata?.createdBy?.id ?? userId) : Number(userId),
  //     modifiedBy: rowdata?.id ? Number(userId) : "",
  //   }
  //   const [otherIncomeChargesValues, setOtherIncomeChargesValues] = useState(
  //     rowdata?.otherIncomeChargesId?.id ? { value: rowdata?.otherIncomeChargesId?.id, label: rowdata?.otherIncomeChargesId?.name } :
  //       { value: "", label: "" }
  //   )

  //   return (
  //     <>
  //       <Formik initialValues={initialValues}
  //         validationSchema={SalesOrderOtherIncomeCharges}
  //         onSubmit={(values, formikProps) => handleSubmitOtherExpenses(values, formikProps, toggle, setOpenRowIndex,)}
  //       >
  //         {({ errors, submitForm, touched, setFieldValue, values, resetForm }) => (
  //           <>
  //             <Form>
  //               <div className="row">
  //                 <div className="col-6 staan-input-group">
  //                   <CustomSelect
  //                     setSelectEditValue={otherIncomeChargesValues}
  //                     options={otherIncomeChargelist}
  //                     name='otherIncomeChargesId'
  //                     id='otherIncomeChargesId'
  //                     handleOnChange={(option) => {
  //                       setFieldValue("otherIncomeChargesId", option?.value ? Number(option?.value) : null)
  //                       setOtherIncomeChargesValues(option ? option : null)
  //                       setFieldValue("tax", option?.tax ? option.tax : null)
  //                     }}
  //                   />
  //                   <label className='staan-form-input-label pt-1 px-1 mx-2'  >Account </label>
  //                   {touched.otherIncomeChargesId && errors.otherIncomeChargesId && <small>{errors.otherIncomeChargesId}</small>}
  //                 </div>
  //                 <div className="col-2 staan-input-group">
  //                   <Field type='text' name='tax' disabled className='w-100 staan-form-input' />
  //                   <label htmlFor="tax" className='staan-form-input-label pt-1 px-1 mx-2'>Tax</label>
  //                   {touched.tax && errors.tax && <small>{errors.tax}</small>}
  //                 </div>
  //                 <div className="col-2 staan-input-group">
  //                   <Field type='text' name='amount' className='w-100 staan-form-input' />
  //                   <label htmlFor="amount" className='staan-form-input-label pt-1 px-1 mx-2'>Amount</label>
  //                   {touched.amount && errors.amount && <small>{errors.amount}</small>}
  //                 </div>
  //                 <div className='col-1 mt-3'>
  //                   <button type="button" className="save-button" onMouseDown={() => { submitForm() }}  >
  //                     <i className="fa-regular fa-floppy-disk" ></i>
  //                   </button>
  //                 </div>
  //                 <div className='col-1 mt-3'>
  //                   <button type="button" className="delete-button" onMouseDown={(event) => {
  //                     event.preventDefault();
  //                     toggle()
  //                     setOpenRowIndex()
  //                   }}>

  //                     <i className="fa-solid fa-trash"></i>
  //                   </button >
  //                 </div>
  //               </div>
  //             </Form>
  //           </>
  //         )}
  //       </Formik>


  //     </>
  //   )

  // }
  const handleDeleteOtherIncome = (data) => {
    setDeleteOtherIncomeData({
      deleteIndex: data?.index,
      deleteMessage: `Are You Sure Want To Delete ${data?.otherIncomeChargesId?.label}`
    })
    setIsDeleteOtherIncomeCharges(true)
  }
  const handleDeleteOtherIncomeFunction = (data) => {
    if (data === true) {
      const updatedData = otherIncomechargesList.filter((item, index) => item?.index !== deleteOtherIncomeData?.deleteIndex)
      setOtherIncomechargesList(updatedData);
      setDeleteOtherIncomeData('')
      callClearDiscount(null, updatedData)
    }
  }

  const OtherIncomeChargesInputForm = ({ itemData, setitemData, editData, toggle, setEditingRow }) => {
    const initialValues = {
      index: itemData?.index,
      id: editData?.id ? Number(editData?.id) : "",
      otherIncomeChargesId: editData?.otherIncomeChargesId?.value ? {
        value: editData?.otherIncomeChargesId?.value, label: editData?.otherIncomeChargesId?.label, tax: editData?.otherIncomeChargesId?.tax,
        hsn: editData?.otherIncomeChargesId?.hsn, hsnCode: editData?.otherIncomeChargesId?.hsnCode
      } : "",
      amount: editData?.amount ? editData?.amount : "",
      tax: editData?.tax ? Number(editData?.tax) : "",
      hsnCode: editData?.otherIncomeChargesId?.hsnCode,
      createdBy: editData?.id ? Number(editData?.createdBy?.id ?? userId) : Number(userId),
      modifiedBy: editData?.id ? Number(userId) : "",
    }
    const [otherIncomeChargesValues, setOtherIncomeChargesValues] = useState(
      editData?.otherIncomeChargesId?.value ? { value: editData?.otherIncomeChargesId?.value, label: editData?.otherIncomeChargesId?.label, tax: editData?.otherIncomeChargesId?.tax } :
        { value: "", label: "" }
    )
    const handleSubmitOtherExpenses = (values) => {
      console.log("values--->", values);
      
      const validationResponse = checkNegativeNumber(values, ['amount', 'tax'])
      if (validationResponse) {
        showErrorToast(true, 'error', "Value Should Not Be Negative", "")
        return
      }
      if (salesOrderValues?.buyerAddress?.fullAddredd?.state === "Tamil Nadu" || salesOrderValues?.buyerAddress?.fullAddredd?.state === "Tamilnadu") {
        values['sgst'] = values["otherIncomeChargesId"]['tax'] / 2
        values['cgst'] = values["otherIncomeChargesId"]['tax'] / 2
        values['igst'] = 0
      } else {
        values['sgst'] = 0
        values['cgst'] = 0
        values['igst'] = values["otherIncomeChargesId"]['tax']
      }
      const updatedItems = otherIncomechargesList?.map(item => {
        if (item.index === values.index) {
          // If the item matches the index, update it
          return values;
        }
        return item; // Otherwise, return the item as it is
      });
      // If the item does not exist, add the new item
      if (!updatedItems.some(item => item.index === values.index)) {
        updatedItems.push(values); // Add the new item if it was not found
      }
      setOtherIncomechargesList(updatedItems);
      callClearDiscount(null, updatedItems);
      setEditingRow('')
      toggle()
    }
    const handleCancel = () => {
      setitemData(prevData => prevData.filter(item => item.index !== itemData.index || item.hasOwnProperty('id')));
      setEditingRow('');
      toggle();
    }
    return (
      <>
        <Formik initialValues={initialValues}
          validationSchema={SalesOrderOtherIncomeCharges}
          onSubmit={(values, formikProps) => handleSubmitOtherExpenses(values)}
        >
          {({ errors, submitForm, touched, setFieldValue, values, resetForm }) => (
            <>
              <Form>
                <div className="row">
                  <div className="col-6 staan-input-group">
                    <CustomSelect
                      setSelectEditValue={otherIncomeChargesValues}
                      options={otherIncomeChargelist}
                      name='otherIncomeChargesId'
                      id='otherIncomeChargesId'
                      handleOnChange={(option) => {
                        setFieldValue("otherIncomeChargesId", option?.value ? option : null)
                        setOtherIncomeChargesValues(option ? option : null)
                        setFieldValue("tax", option?.tax ? option.tax : null)
                      }}
                    />
                    <label className='staan-form-input-label pt-1 px-1 mx-2'>Account </label>
                    {touched.otherIncomeChargesId && errors.otherIncomeChargesId && <small>{errors.otherIncomeChargesId}</small>}
                  </div>
                  <div className="col-2 staan-input-group">
                    <Field type='text' name='tax' disabled className='w-100 staan-form-input' />
                    <label htmlFor="tax" className='staan-form-input-label pt-1 px-1 mx-2'>Tax</label>
                    {touched.tax && errors.tax && <small>{errors.tax}</small>}
                  </div>
                  <div className="col-2 staan-input-group">
                    <Field type='text' name='amount' className='w-100 staan-form-input' />
                    <label htmlFor="amount" className='staan-form-input-label pt-1 px-1 mx-2'>Amount</label>
                    {touched.amount && errors.amount && <small>{errors.amount}</small>}
                  </div>
                  <div className='col-1 mt-3'>
                    <button type="button" className="btn btn-outline-success btn-sm px-3 mx-2  rounded-pill"
                      onMouseDown={() => { submitForm() }}  >
                      <i className="fa-regular fa-floppy-disk"></i>
                    </button>
                  </div>
                  <div className='col-1 mt-3'>
                    <button type="button" className="btn btn-outline-secondary btn-sm px-3 mx-1  rounded-pill" onMouseDown={(event) => {
                      event.preventDefault();
                      handleCancel()
                    }}>

                      <i className="fa-solid fa-xmark"></i>
                    </button >
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>


      </>
    )

  }
  // basic calculations
  useEffect(() => {
    if (salesOrderItemDetails.length > 0) {
      let item_value = 0;
      let other_income_value = 0
      let actual_item_value = 0
      let actual_other_income_value = 0
      const igstList = {};
      const cgstList = {};
      const sgstList = {};
      // This actual tax with discount
      // const actualIgstList = {}
      // const actualCgstList = {}
      // const actualSgstList = {}

      let groupedData = {};
      let totalDiscount = 0;
      // Calculate total amount and populate tax lists
      salesOrderItemDetails?.forEach(itemValue => {
        item_value += Number(itemValue.amount);
        actual_item_value += Number((itemValue.rate) * (itemValue?.qty));
        const hsnCode = itemValue?.hsnCode;
        if (!groupedData[hsnCode]) {
          groupedData[hsnCode] = {
            hsn: hsnCode,
            taxableValue: 0,
            cgst: { "percent": 0, "amount": 0 },
            sgst: { "percent": 0, "amount": 0 },
            igst: { "percent": 0, "amount": 0 },
          };
        }
        // const discountAmount = itemValue.discountPercentage ? (itemValue?.discountPercentage / 100) * Number(itemValue?.amount) : Number(itemValue?.discountValue) || 0;
        // const finalTaxableValue = Number(itemValue?.amount) - discountAmount;
        const finalTaxableValue = Number(itemValue?.amount);
        groupedData[hsnCode].taxableValue += finalTaxableValue;
        if (itemValue.igst) {
          igstList[itemValue.igst] = (igstList[itemValue.igst] || []).concat(itemValue.amount);
          // actualIgstList[itemValue.igst] = (actualIgstList[itemValue.igst] || []).concat((itemValue.rate) * (itemValue?.qty));
          groupedData[hsnCode].igst.percent = Number(itemValue.igst);
          groupedData[hsnCode].igst.amount += (finalTaxableValue * Number(itemValue.igst)) / 100;
        }
        if (itemValue.cgst) {
          cgstList[itemValue.cgst] = (cgstList[itemValue.cgst] || []).concat(itemValue.amount);
          // actualCgstList[itemValue.cgst] = (actualCgstList[itemValue.cgst] || []).concat((itemValue.rate) * (itemValue?.qty));
          groupedData[hsnCode].cgst.percent = Number(itemValue.cgst);
          groupedData[hsnCode].cgst.amount += (finalTaxableValue * Number(itemValue.cgst)) / 100;
        }
        if (itemValue.sgst) {
          sgstList[itemValue.sgst] = (sgstList[itemValue.sgst] || []).concat(itemValue.amount);
          // actualSgstList[itemValue.sgst] = (actualSgstList[itemValue.sgst] || []).concat((itemValue.rate) * (itemValue?.qty));
          groupedData[hsnCode].sgst.percent = Number(itemValue.sgst);
          groupedData[hsnCode].sgst.amount += (finalTaxableValue * Number(itemValue.sgst)) / 100;
        }
        // if (discountValue?.isDiscountApplied === false) {
        //   if (itemValue?.discountPercentage > 0) {
        //     totalDiscount += (itemValue?.rate * itemValue?.qty) * (parseFloat(itemValue?.discountPercentage) / 100);
        //   } else if (itemValue?.discountValue > 0) {
        //     totalDiscount += parseFloat(itemValue?.discountValue * itemValue?.qty);
        //   }
        // }
        if (itemValue?.discountPercentage > 0) {
          totalDiscount += (itemValue?.rate * itemValue?.qty) * (parseFloat(itemValue?.discountPercentage) / 100);
        } else if (itemValue?.discountValue > 0) {
          totalDiscount += parseFloat(itemValue?.discountValue * itemValue?.qty);
        } else {
          totalDiscount += Number(itemValue?.finalValue);
        }
      });
      setCalculatedDiscount(totalDiscount)
      otherIncomechargesList?.forEach(itemValue => {
        let other_income_amount = itemValue?.afterDiscountValue ? Number(itemValue?.afterDiscountValue) : Number(itemValue.amount);
        other_income_value += other_income_amount
        actual_other_income_value += itemValue.amount
        const hsnCode = itemValue?.otherIncomeChargesId?.hsnCode;
        if (!groupedData[hsnCode]) {
          groupedData[hsnCode] = {
            hsn: hsnCode,
            taxableValue: 0,
            cgst: { "percent": 0, "amount": 0 },
            sgst: { "percent": 0, "amount": 0 },
            igst: { "percent": 0, "amount": 0 },
          };
        }
        const finalTaxableValue = itemValue?.afterDiscountValue ? Number(itemValue?.afterDiscountValue) : Number(itemValue.amount);
        groupedData[hsnCode].taxableValue += finalTaxableValue;
        if (itemValue.igst) {
          igstList[itemValue.igst] = (igstList[itemValue.igst] || []).concat(other_income_amount);
          // actualIgstList[itemValue.igst] = (actualIgstList[itemValue.igst] || []).concat(other_income_amount);
          groupedData[hsnCode].igst.percent = Number(itemValue?.igst);
          groupedData[hsnCode].igst.amount += (finalTaxableValue * Number(itemValue?.igst)) / 100;
        }
        if (itemValue.cgst) {
          cgstList[itemValue.cgst] = (cgstList[itemValue.cgst] || []).concat(other_income_amount);
          // actualCgstList[itemValue.cgst] = (actualCgstList[itemValue.cgst] || []).concat(other_income_amount);
          groupedData[hsnCode].cgst.percent = Number(itemValue.cgst);
          groupedData[hsnCode].cgst.amount += (finalTaxableValue * Number(itemValue?.cgst)) / 100;
        }
        if (itemValue.sgst) {
          sgstList[itemValue.sgst] = (sgstList[itemValue.sgst] || []).concat(other_income_amount);
          // actualSgstList[itemValue.sgst] = (actualSgstList[itemValue.sgst] || []).concat(other_income_amount);
          groupedData[hsnCode].sgst.percent = Number(itemValue?.sgst);
          groupedData[hsnCode].sgst.amount += (finalTaxableValue * Number(itemValue?.sgst)) / 100;
        }
      });




      // // Calculate taxes
      const { taxResult: igstResult, taxValues: igstaxvalues } = calculateTax(igstList);
      const { taxResult: cgstResult, taxValues: cgstaxvalues } = calculateTax(cgstList);
      const { taxResult: sgstResult, taxValues: sgstaxvalues } = calculateTax(sgstList);

      // // actual taxes
      // const { taxResult: actualigstResult, taxValues: actualigstaxvalues } = calculateTax(actualIgstList);
      // const { taxResult: actualcgstResult, taxValues: actualcgstaxvalues } = calculateTax(actualCgstList);
      // const { taxResult: actualsgstResult, taxValues: actualgstaxvalues } = calculateTax(actualSgstList);


      // let actualNetAmount = Number(actualigstaxvalues) + Number(actualcgstaxvalues) + Number(actualgstaxvalues) + Number(actual_item_value) + Number(actual_other_income_value)
      let actualNetAmount = Number(actual_item_value) + Number(actual_other_income_value)
      const newFinalAmount = roundToNearest10(igstaxvalues + cgstaxvalues + sgstaxvalues + item_value + other_income_value).toFixed(2)
      let totalTax = (igstaxvalues + cgstaxvalues + sgstaxvalues).toFixed(2)
      let roundOff = (newFinalAmount - (igstaxvalues + cgstaxvalues + sgstaxvalues + item_value + other_income_value)).toFixed(2)
      setSalesOrderValues(prve => ({
        ...prve, totaltax: totalTax, item_details_value: item_value.toFixed(2),
        other_income_value: other_income_value.toFixed(2), netAmount: newFinalAmount,
        roundOff: roundOff, sgst: sgstResult, cgst: cgstResult, igst: igstResult,
        actualNetAmount: roundToNearest10(actualNetAmount), taxData: Object.values(groupedData),
        // actualigstResult: actualigstResult,
        // actualcgstResult: actualcgstResult, actualsgstResult: actualsgstResult
      }))

    }
  }, [salesOrderItemDetails, otherIncomechargesList]);

  // Update the Value in inputs
  function updateTheStates(reponseData) {
    setInitialSalesOrder({
      id: Number(reponseData?.id),
      active: reponseData?.active,
      salesOrderNumber: reponseData?.salesOrderNo?.linkedModelId,
      createdAt: reponseData?.CreatedAt,
      updatedAt: reponseData?.UpdatedAt,
      salesOrderDate: reponseData?.salesOrderDate,
      createdTime: TimeFormate(reponseData?.CreatedAt),
      status: reponseData?.status?.name,
      salesPerson: Number(reponseData?.salesPerson?.id),
      dueDate: reponseData?.dueDate,
      creditPeriod: reponseData?.creditPeriod,
      paymentTerms: reponseData?.paymentTerms,
      customerPoNo: reponseData?.customerPoNo,
      customerPoDate: reponseData?.customerPoDate,
      department: Number(reponseData?.department?.id),
      childCount: reponseData?.childCount,
      leadNo: reponseData?.leadNo?.id ? Number(reponseData?.leadNo?.id) : "",
      quotations: reponseData?.quotations?.id ? Number(reponseData?.quotations?.id) : "",
      currency: reponseData?.currency?.id ? Number(reponseData?.currency?.id) : "",

      // remarks : reponseData?.,
      buyer: Number(reponseData?.buyer?.id),
      buyerAddress: Number(reponseData?.buyerAddress?.id),
      buyerContactPerson: Number(reponseData?.buyerContactPerson?.id),
      buyerGstinType: reponseData?.buyerGstinType,
      buyerGstin: reponseData?.buyerGstin,
      buyerState: reponseData?.buyerState,
      buyerPlaceOfSupply: reponseData?.buyerPlaceOfSupply,
      consignee: Number(reponseData?.consignee?.id),
      consigneeAddress: Number(reponseData?.consigneeAddress?.id),
      consigneeContactPerson: Number(reponseData?.consigneeContactPerson?.id),
      consigneeGstinType: reponseData?.consigneeGstinType,
      consigneeGstin: reponseData?.consigneeGstin,
      consigneeState: reponseData?.consigneeState,
      consigneePlaceOfSupply: reponseData?.consigneePlaceOfSupply,
      itemDetails: reponseData?.itemDetails?.map(item => Number(item?.id)),
      otherIncomeCharge: reponseData?.otherIncomeCharge?.map(item => Number(item?.id)),
      overallDiscountPercentage: "",
      overallDiscountValue: "",
      discountFinalTotal: "",
      igst: reponseData?.igst,
      sgst: reponseData?.sgst,
      cgst: reponseData?.cgst,
      itemTotalBeforTax: reponseData?.itemTotalBeforTax,
      otherChargesBeforTax: reponseData?.otherChargesBeforTax,
      taxTotal: reponseData?.taxTotal,
      roundOff: reponseData?.roundOff,
      netAmount: reponseData?.netAmount,
      termsConditions: Number(reponseData?.termsConditions?.id),
      termsConditionsText: reponseData?.termsConditionsText,
      modifiedBy: Number(userId),
      createdBy: Number(reponseData?.createdBy?.id),
    })
    const updatedItem = reponseData?.itemDetails?.map((item, rowIndex) => ({
      index: rowIndex + 1,
      afterDiscountValueForPerItem: item?.afterDiscountValueForPerItem ? item?.afterDiscountValueForPerItem : null,
      description: item?.description ? item?.description : "",
      amount: item?.amount ? item?.amount : null,
      cgst: item?.cgst ? item?.cgst : null,
      sgst: item?.sgst ? item?.sgst : null,
      igst: item?.igst ? item?.igst : null,
      discountValue: item?.discountValue ? item?.discountValue : null,
      discountPercentage: item?.discountPercentage ? item?.discountPercentage : null,
      finalValue: item?.finalValue ? item?.finalValue : null,
      hsnCode: item?.itemmaster?.itemHsn?.hsnCode ? item?.itemmaster?.itemHsn?.hsnCode : "",
      hsnId: item?.itemmaster?.itemHsn?.id ? item?.itemmaster?.itemHsn?.id : "",
      id: item?.id ? Number(item?.id) : null,
      itemMrp: item?.itemmaster?.mrp ? item?.itemmaster?.mrp : "",
      item_master_part_code: {
        value: item?.itemmaster?.id ? Number(item?.itemmaster?.id) : "",
        label: item?.itemmaster?.itemPartCode ? item?.itemmaster?.itemPartCode : "",
      },
      item_master_part_name: {
        value: item?.itemmaster?.id ? Number(item?.itemmaster?.id) : "",
        label: item?.itemmaster?.itemName ? item?.itemmaster?.itemName : "",
      },
      qty: item?.qty ? item?.qty : null,
      rate: item?.rate ? item?.rate : null,
      uom: {
        value: item?.uom?.id ? Number(item?.uom?.id) : "",
        label: item?.uom?.name ? item?.uom?.name : "",
      },
      tax: item?.itemmaster?.itemHsn?.gstRates?.rate ? item?.itemmaster?.itemHsn?.gstRates?.rate : '',
      createdBy: item?.createdBy?.id ? Number(item?.createdBy?.id) : Number(userId),
      modifiedBy: item?.modifiedBy?.id ? Number(item?.modifiedBy?.id) : null,
      itemComboBool: item?.itemCombo ? item?.itemCombo : false,
      itemComboItemDetails: item?.itemComboItemDetails ? item?.itemComboItemDetails : [],
    }))
    const updatedOtherIncome = reponseData?.otherIncomeCharge?.map((item, rowIndex) => ({
      index: rowIndex + 1,
      amount: item?.amount ? item?.amount : null,
      id: item?.id ? Number(item?.id) : null,
      cgst: item?.cgst ? item?.cgst : null,
      sgst: item?.sgst ? item?.sgst : null,
      igst: item?.igst ? item?.igst : null,
      createdBy: item?.createdBy?.id ? Number(item?.createdBy?.id) : Number(userId),
      modifiedBy: item?.modifiedBy?.id ? Number(item?.modifiedBy?.id) : null,
      tax: item?.tax ? item?.tax : '',
      otherIncomeChargesId: {
        value: item?.otherIncomeChargesId?.id ? Number(item?.otherIncomeChargesId?.id) : "",
        label: item?.otherIncomeChargesId?.name ? item?.otherIncomeChargesId?.name : "",
        tax: item?.tax ? item?.tax : "",
        hsn: item?.otherIncomeChargesId?.hsn?.id ? item?.otherIncomeChargesId?.hsn?.id : "",
        hsnCode: item?.otherIncomeChargesId?.hsn?.hsnCode ? item?.otherIncomeChargesId?.hsn?.hsnCode : "",
      },
      afterDiscountValue: item?.afterDiscountValue ? item?.afterDiscountValue : null,
      discountValue: item?.discountValue ? item?.discountValue : null,

    }))
    setSalesOrderItemDetails(updatedItem)
    setOtherIncomechargesList(updatedOtherIncome)

    setSalesOrderValues({
      lead: { id: reponseData?.leadNo?.id, leadNo: reponseData?.leadNo?.leadNo },
      currentVersionId: reponseData?.id,
      createdBy: reponseData?.createdBy,
      currency: {
        value: reponseData?.currency?.id, label: reponseData?.currency?.Currency?.name, currencySymbol: reponseData?.currency?.Currency?.currencySymbol,
        rate: reponseData?.currency?.rate
      },
      currencySymbol: reponseData?.currency?.Currency?.currencySymbol,
      quotations: { value: reponseData?.quotations?.id, label: reponseData?.quotations?.quotationNo?.linkedModelId },
      salesPerson: { value: reponseData?.salesPerson?.id, label: reponseData?.salesPerson?.username },
      Department: { value: reponseData?.department?.id, label: reponseData?.department?.name },
      buyer: { value: reponseData?.buyer?.id, label: reponseData?.buyer?.companyName },
      buyerNumber: { value: reponseData?.buyer?.id, label: reponseData?.buyer?.supplierNo },
      buyerAddress: { value: reponseData?.buyerAddress?.id, label: reponseData?.buyerAddress?.addressType, fullAddredd: reponseData?.buyerAddress },
      buyerContactPerson: {
        value: reponseData?.buyerContactPerson?.id, label: reponseData?.buyerContactPerson?.contactPersonName,
        mobile: reponseData?.buyerContactPerson?.phoneNumber, Email: reponseData?.buyerContactPerson?.email
      },
      buyerPlaceOfSupply: { value: reponseData?.buyerPlaceOfSupply, label: reponseData?.buyerPlaceOfSupply },
      consignee: { value: reponseData?.consignee?.id, label: reponseData?.consignee?.companyName },
      consigneeNumber: { value: reponseData?.consignee?.id, label: reponseData?.consignee?.supplierNo },
      consigneeAddress: { value: reponseData?.consigneeAddress?.id, label: reponseData?.consigneeAddress?.addressType, fullAddredd: reponseData?.consigneeAddress },
      consigneeContactPerson: {
        value: reponseData?.consigneeContactPerson?.id, label: reponseData?.consigneeContactPerson?.contactPersonName,
        mobile: reponseData?.buyerContactPerson?.phoneNumber, Email: reponseData?.buyerContactPerson?.email
      },
      consigneePlaceOfSupply: { value: reponseData?.consigneePlaceOfSupply, label: reponseData?.consigneePlaceOfSupply },
      totaltax: reponseData?.taxTotal, item_details_value: reponseData?.itemTotalBeforTax,
      other_income_value: reponseData?.otherChargesBeforTax, netAmount: reponseData?.netAmount,
      roundOff: reponseData?.roundOff, sgst: reponseData?.sgst, cgst: reponseData?.cgst, igst: reponseData?.igst,
      termsCondition: { value: reponseData?.termsConditions?.id, label: reponseData?.termsConditions?.name },
      termsConditionsText: reponseData?.termsConditionsText,
      allowCommanDisCount: reponseData?.overallDiscountPercentage || reponseData?.overallDiscountValue || reponseData?.discountFinalTotal ? true : false,
    })
    setDiscountValue({
      discountError: "",
      discountPercentage: reponseData?.overallDiscountPercentage ? reponseData?.overallDiscountPercentage : "",
      discountValue: reponseData?.overallDiscountValue ? reponseData?.overallDiscountValue : "",
      discountTotalValue: reponseData?.discountFinalTotal ? reponseData?.discountFinalTotal : "",
      isDiscountApplied: reponseData?.overallDiscountPercentage || reponseData?.overallDiscountValue || reponseData?.discountFinalTotal ? true : false
    })
    setFormKey(prve => prve + 1)
    setCustomerDetailsLists(prve => ({
      ...prve, buyerStatesList: getStates(reponseData?.buyerAddress?.country),
      consigneeStatesList: getStates(reponseData?.consigneeAddress?.country),
      buyerContactList: reponseData?.buyer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
      buyerAddressList: reponseData?.buyer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
      consigneeContactList: reponseData?.consignee?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
      consigneeAddressList: reponseData?.consignee?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ }))
    }))
  }

  // on edit fetch The Value from dataBase
  async function fetchEditData(id) {
    setSalesOrderValues(prve => ({ ...prve, isLoading: true }))
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: SalesOrderEditQuery(`id:${id}`) });
      const reponseData = response?.data?.data?.allSalesorder2?.items?.[0]
      let versionList = response?.data?.data?.allSalesorder2?.version?.versionList
      if (reponseData) {
        updateTheStates(reponseData)
        setSalesOrderValues(prve => ({ ...prve, versionList: versionList }))
      }
    } catch (error) {
      // let errorFilter = ShowErrorNoties(error)
    }
    setSalesOrderValues(prve => ({ ...prve, isLoading: false }))
  }

  // retun null ; set the Quotations data
  function CallFromQuotation(quotationData) {

    try {
      setSalesOrderValues(prve => ({
        ...prve, quotations: { value: quotationData?.Quotation?.id, label: quotationData?.Quotation?.QuatationNumber },
        Department: { value: quotationData?.Department?.value, label: quotationData?.Department?.label },
        salesPerson: { value: quotationData?.SalesPerson?.value, label: quotationData?.SalesPerson?.label },
        lead: quotationData?.lead, currency: quotationData?.currency ? quotationData?.currency : "",
        currencySymbol: quotationData?.currency?.Symbol
      }))
      setInitialSalesOrder(prve => ({
        ...prve, salesPerson: Number(quotationData?.SalesPerson?.value),
        quotations: Number(quotationData?.Quotation?.id),
        department: Number(quotationData?.Department?.value),
        leadNo: quotationData?.lead?.id ? Number(quotationData?.lead?.id) : "",
        currency: quotationData?.currency ? Number(quotationData?.currency?.value) : "",
      }))
      if (quotationData?.overallDiscountPercentage || quotationData?.overallDiscountValue || quotationData?.discountFinalTotal) {
        setDiscountValue({
          discountError: "",
          discountPercentage: quotationData?.overallDiscountPercentage ? quotationData?.overallDiscountPercentage : "",
          discountValue: quotationData?.overallDiscountValue ? quotationData?.overallDiscountValue : "",
          discountTotalValue: quotationData?.discountFinalTotal ? quotationData?.discountFinalTotal : "",
          isDiscountApplied: true
        })
        setSalesOrderValues(prve => ({ ...prve, allowCommanDisCount: true }))
      }
      const updatedItemDetails = quotationData?.itemData ? quotationData?.itemData?.map(item => ({
        ...item,
        id: '',
        createdBy: Number(userId),
        qty: item?.po_qty ? item?.po_qty : '',
        item_master_part_code: {
          value: item?.partCode?.value ? item?.partCode?.value : '',
          label: item?.partCode?.label ? item?.partCode?.label : '',
          description: item?.description ? item?.description : ''
        },
        item_master_part_name: {
          value: item?.partName?.value ? item?.partName?.value : '',
          label: item?.partName?.label ? item?.partName?.label : '',
        },
        tax: item?.gstRates ? item?.gstRates : ''
      })) : []

      const updatedOtherIncome = quotationData?.otherIncomeData ? quotationData?.otherIncomeData?.map(item => ({
        ...item,
        id: '',
        createdBy: Number(userId),
        otherIncomeChargesId: {
          hsnCode: item?.hsnCode,
          label: item?.otherIncomeChargesId?.label,
          tax: item?.otherIncomeChargesId?.tax,
          value: item?.otherIncomeChargesId?.value,
        },
        tax: item?.otherIncomeChargesId?.tax,
      })) : []
      setSalesOrderItemDetails(updatedItemDetails);
      setOtherIncomechargesList(updatedOtherIncome);
      dispatch(fetchOptionsData(SupplierOptionPerameter(`id:${quotationData?.CustomerCode?.value}, customer:true, isLead:null`), "SalesOrderCustomerForLEad"))
    } catch (error) {
      console.log("error", error);

    }
  }

  // retun null ; set the Leead data
  function CallFromLead(params) {
    setSalesOrderValues(prve => ({
      ...prve, lead: { id: params?.id, leadNo: params?.leadNo },
      salesPerson: { value: params?.salesPerson?.id, label: params?.salesPerson?.username },
    }))
    setInitialSalesOrder(prve => ({ ...prve, leadNo: Number(params?.id), salesPerson: Number(params?.salesPerson?.id) }))
    if (params?.customer?.id) {
      dispatch(fetchOptionsData(SupplierOptionPerameter(`id:${params?.customer?.id}, customer:true, isLead:null`), "SalesOrderCustomerForLEad"))
    }
  }

  //call edit data Functions
  useEffect(() => {
    if (editData !== null && editData) {
      fetchEditData(editData)
      setEdit(true)
    } else if (quotationData?.['Quotation']) {
      CallFromQuotation(quotationData)
      setEdit(false)
    } else if (leadData) {
      CallFromLead(leadData)
      setEdit(false)
    } else {
      setEdit(false)
    }
    if (prefetchValue?.currencyExchangeList?.length > 0 && quotationData === undefined) {
      let currencyDefaultValue = prefetchValue?.currencyExchangeList?.filter((currency) => currency?.label === "Rupee")
      setSalesOrderValues(prve => ({ ...prve, currency: currencyDefaultValue?.[0] }))
      setInitialSalesOrder(prve => ({ ...prve, currency: currencyDefaultValue?.[0]?.value ? Number(currencyDefaultValue?.[0]?.value) : "" }))
    }
  }, [editData])

  function BeforeCloseCheckData(params) {
    // if (initialSalesOrder?.id === "" && (salesOrderItemDetails?.length > 0 || otherIncomechargesList?.length > 0)) {
    //   setIsBulkDeleteWaring(true)
    // } else {
    //   close()
    // }
    close()
  }

  // on close clear all data
  function close(params) {
    if (setLoad) {
      setLoad()
    }
    setEdit(true)
    closeSalesOrderModal(index)
    setInitialSalesOrder({
      id: "",
      active: true,
      salesOrderNumber: "",
      status: "",
      salesPerson: "",
      dueDate: "",
      creditPeriod: "",
      currency: "",
      paymentTerms: "",
      customerPoNo: " ",
      customerPoDate: " ",
      childCount: "",
      leadNo: "",
      quotations: "",
      department: "",
      remarks: " ",
      buyer: " ",
      buyerAddress: " ",
      buyerContactPerson: " ",
      buyerGstinType: " ",
      buyerGstin: " ",
      buyerState: " ",
      buyerPlaceOfSupply: " ",
      consignee: " ",
      consigneeAddress: " ",
      consigneeContactPerson: " ",
      consigneeGstinType: " ",
      consigneeGstin: " ",
      consigneeState: " ",
      consigneePlaceOfSupply: " ",
      itemDetails: "",
      otherIncomeCharge: "",
      overallDiscountPercentage: "",
      overallDiscountValue: "",
      discountFinalTotal: "",
      igst: "",
      sgst: "",
      cgst: "",
      itemTotalBeforTax: "",
      otherChargesBeforTax: "",
      taxTotal: "",
      roundOff: "",
      netAmount: "",
      termsConditions: "",
      termsConditionsText: "",
      modifiedBy: "",
      createdBy: Number(userId),
    })
    setSalesOrderItemDetails([])
    setOtherIncomechargesList([])
    setSalesOrderValues({
      salesPerson: "",
      buyer: "", buyerNumber: "", buyerAddress: "", buyerContactPerson: "",
      buyerPlaceOfSupply: "",
      consignee: "", consigneeNumber: "", consigneeAddress: "", consigneeContactPerson: "", consigneePlaceOfSupply: "", otherIncomeCharge: "",
      totaltax: "", item_details_value: "", other_income_value: "", netAmount: "", roundOff: "", sgst: "", cgst: "", igst: "",
      termsCondition: "", termsConditionsText: "", Department: "", createdBy: "", createdAt: "", allowCommanDisCount: false, outOfRangeValues: [],
      lastUpdatedItemDetails: "", stringItemCombo: "", isLoading: false, isAmend: false, deleteItemDetailData: { Name: "", id: "" },
      deleteOtherExpensesData: { Name: "", id: "" }, setSelectedPartNo: "", versionList: [], currentVersionId: "", parentOrder: "", isDuplicate: "",
      currency: "", currencySymbol: "", actualigstResult: "", actualcgstResult: "", actualsgstResult: "",
    })
    setDiscountValue({
      discountError: "",
      discountPercentage: quotationData?.overallDiscountPercentage ? quotationData?.overallDiscountPercentage : "",
      discountValue: quotationData?.overallDiscountValue ? quotationData?.overallDiscountValue : "",
      discountTotalValue: quotationData?.discountFinalTotal ? quotationData?.discountFinalTotal : "",
      isDiscountApplied: false
    })
    setFormKey(prve => prve + 1)
    setCustomerDetailsLists({
      "buyerNameList": [], "buyerNumberList": [],
      "buyerAddressList": [], "buyerContactList": [], "buyerStatesList": [],
      "consigneeList": [], "consigneeNumberList": [], "consigneeAddressList": [],
      "consigneeContactList": [], "consigneeStatesList": [], "otherIncomeChargelist": [], "termsConditionsList": [], "DepartmentList": []
    })
  }

  const overAlldiscountCalculationToDisplay = (commanDiscount, netAmount, itemDetailsData, otherincomedata) => {
    if (commanDiscount?.discountPercentage > 0) {
      // Apply percentage discount
      return (netAmount * commanDiscount?.discountPercentage) / 100
    } else if (commanDiscount?.discountValue > 0) {
      let totalDiscount = 0
      if (itemDetailsData) {
        itemDetailsData?.forEach((item) => {
          let finalValue = Number(item?.discountValue)
          let totalfinalValue = Number(finalValue) * Number(item?.qty)
          totalDiscount += totalfinalValue
        })
      }
      if (otherincomedata) {
        otherincomedata?.forEach((item) => {
          let finalValue = Number(item?.discountValue)
          let totalfinalValue = Number(finalValue)
          totalDiscount += totalfinalValue
        })
      }
      return (totalDiscount).toFixed(2)
    } else if (commanDiscount?.discountTotalValue > 0) {
      let totalDiscount = 0
      itemDetailsData?.forEach((item) => {
        let finalValue = item?.finalValue
        let totalfinalValue = Number(finalValue) * Number(item?.qty)
        totalDiscount += totalfinalValue
      })
      return (totalDiscount).toFixed(2)
    }
    return 0.00;
  };
  const commanPercentageDiscountPOS = (Precentage) => {
    let preparitemData = salesOrderItemDetails?.map((item) => {
      return { index: item?.index, rate: item?.rate }
    })
    let prepareOtherIncome = otherIncomechargesList?.map((item) => {
      return { index: item?.index, rate: item?.amount }
    })
    let result = commanPercentageDiscount(preparitemData, prepareOtherIncome, Precentage)
    let updateDiscountItemDetails = salesOrderItemDetails?.map((item) => {
      // Create a hash map of itemDetails by their index
      const itemDetailsMap = result?.itemDetails?.reduce((acc, item_with_dc) => {
        acc[item_with_dc?.index] = item_with_dc;
        return acc;
      }, {});

      // Find the corresponding item in itemDetails using the index
      const item_with_discount = itemDetailsMap[item?.index];

      if (item_with_discount) {
        // If a matching item is found, calculate the amount and return the updated item
        let amount = item_with_discount?.discountRate * item?.qty;
        return {
          ...item,
          amount: amount,
          afterDiscountValueForPerItem: Number(item_with_discount?.discountRate).toFixed(2),
          discountPercentage: Precentage,
          discountValue: ''
        };
      }

      // If no matching item is found, return the original item
      return item;
    });
    let updateDiscountOtherIncome = otherIncomechargesList?.map((item) => {
      // Create a hash map of itemDetails by their index
      const otherDetailsDetailsMap = result?.otherIncomeCharge?.reduce((acc, item_with_dc) => {
        acc[item_with_dc?.index] = item_with_dc;
        return acc;
      }, {});
      // Find the corresponding item in itemDetails using the index
      const item_with_discount = otherDetailsDetailsMap[item?.index];

      if (item_with_discount) {
        // If a matching item is found, calculate the amount and return the updated item
        return {
          ...item,
          afterDiscountValue: Number(item_with_discount?.discountRate).toFixed(2),
          discountValue: Number(item_with_discount?.discountValue).toFixed(2)
        };
      }

      // If no matching item is found, return the original item
      return item;
    });
    let discoutnCal = overAlldiscountCalculationToDisplay(discountValue, Number(salesOrderValues?.netAmount), null, null)
    setCalculatedDiscount(discoutnCal)
    setSalesOrderItemDetails(updateDiscountItemDetails)
    setOtherIncomechargesList(updateDiscountOtherIncome)
    setDiscountValue((prev) => ({ ...prev, isDiscountApplied: true }))
    setSalesOrderValues((prev) => ({ ...prev, allowCommanDisCount: true }))
    showErrorToast(true, 'success', "", "Discount Applied Successfully")
  }
  const commanOverAllDiscountPOS = (discountValues) => {
    let preparitemData = salesOrderItemDetails?.map((item) => {
      return { index: item?.index, rate: item?.rate }
    })
    let prepareOtherIncome = otherIncomechargesList?.map((item) => {
      return { index: item?.index, rate: item?.amount }
    })
    let result = commanOverallDiscountValue(preparitemData, prepareOtherIncome, discountValues, salesOrderValues?.netAmount)
    let updateDiscount = salesOrderItemDetails?.map((item) => {
      // Create a hash map of itemDetails by their index
      const itemDetailsMap = result?.itemDetails?.reduce((acc, item_with_dc) => {
        acc[item_with_dc?.index] = item_with_dc;
        return acc;
      }, {});

      // Find the corresponding item in itemDetails using the index
      const item_with_discount = itemDetailsMap[item?.index];

      if (item_with_discount) {
        // If a matching item is found, calculate the amount and return the updated item
        let amount = item_with_discount?.discountRate * item?.qty;
        return {
          ...item,
          amount: Number(amount).toFixed(2),
          afterDiscountValueForPerItem: Number(item_with_discount?.discountRate).toFixed(2),
          discountValue: Number(item_with_discount?.discountValue).toFixed(2),
          discountPercentage: ''
        };
      }

      // If no matching item is found, return the original item
      return item;
    });
    let updateDiscountOtherIncome = otherIncomechargesList?.map((item) => {
      // Create a hash map of itemDetails by their index
      const otherDetailsDetailsMap = result?.otherIncomeCharge?.reduce((acc, item_with_dc) => {
        acc[item_with_dc?.index] = item_with_dc;
        return acc;
      }, {});
      // console.log("otherDetailsDetailsMap", otherDetailsDetailsMap);

      // Find the corresponding item in itemDetails using the index
      const item_with_discount = otherDetailsDetailsMap[item?.index];

      if (item_with_discount) {
        // If a matching item is found, calculate the amount and return the updated item
        return {
          ...item,
          afterDiscountValue: Number(item_with_discount?.discountRate).toFixed(2),
          discountValue: Number(item_with_discount?.discountValue).toFixed(2)
        };
      }

      // If no matching item is found, return the original item
      return item;
    });
    setSalesOrderItemDetails(updateDiscount)
    setOtherIncomechargesList(updateDiscountOtherIncome)
    let discoutnCal = overAlldiscountCalculationToDisplay(discountValue, Number(salesOrderValues?.actualNetAmount), updateDiscount, updateDiscountOtherIncome)
    setCalculatedDiscount(discoutnCal)
    setDiscountValue((prev) => ({ ...prev, isDiscountApplied: true }))
    setSalesOrderValues((prev) => ({ ...prev, allowCommanDisCount: true }))
    showErrorToast(true, 'success', "", "Discount Applied Successfully")
  }
  const commanFinalDiscountPOS = async (finalValue) => {
    let preparitemData = salesOrderItemDetails?.map((item) => {
      return { index: item?.index, rate: item?.rate }
    })
    let prepareOtherIncome = otherIncomechargesList?.map((item) => {
      return { index: item?.index, rate: item?.amount }
    })
    let result = CommanFinalDiscountValue(preparitemData, prepareOtherIncome, finalValue, salesOrderValues?.netAmount)
    let updateDiscount = salesOrderItemDetails?.map((item) => {
      // Create a hash map of itemDetails by their index
      const itemDetailsMap = result?.itemDetails?.reduce((acc, item_with_dc) => {
        acc[item_with_dc?.index] = item_with_dc;
        return acc;
      }, {});

      // Find the corresponding item in itemDetails using the index
      const item_with_discount = itemDetailsMap[item?.index];

      if (item_with_discount) {
        // If a matching item is found, calculate the amount and return the updated item
        let amount = item_with_discount?.discountRate * item?.qty;
        return {
          ...item,
          amount: Number(amount).toFixed(2),
          afterDiscountValueForPerItem: Number(item_with_discount?.discountRate).toFixed(2),
          discountPercentage: '',
          discountValue: '',
          finalValue: Number(item_with_discount?.discountValue).toFixed(2),
        };
      }

      // If no matching item is found, return the original item
      return item;
    });
    let updateDiscountOtherIncome = otherIncomechargesList?.map((item) => {
      // Create a hash map of itemDetails by their index
      const otherDetailsDetailsMap = result?.otherIncomeCharge?.reduce((acc, item_with_dc) => {
        acc[item_with_dc?.index] = item_with_dc;
        return acc;
      }, {});
      // console.log("otherDetailsDetailsMap", otherDetailsDetailsMap);

      // Find the corresponding item in itemDetails using the index
      const item_with_discount = otherDetailsDetailsMap[item?.index];

      if (item_with_discount) {
        // If a matching item is found, calculate the amount and return the updated item
        return {
          ...item,
          afterDiscountValue: Number(item_with_discount?.discountRate).toFixed(2),
          discountValue: Number(item_with_discount?.discountValue).toFixed(2)
        };
      }

      // If no matching item is found, return the original item
      return item;
    });
    let discoutnCal = await overAlldiscountCalculationToDisplay(discountValue, Number(salesOrderValues?.actualNetAmount), updateDiscount, updateDiscountOtherIncome)
    setCalculatedDiscount(discoutnCal)
    setSalesOrderItemDetails(updateDiscount)
    setOtherIncomechargesList(updateDiscountOtherIncome)
    setDiscountValue((prev) => ({ ...prev, isDiscountApplied: true }))
    setSalesOrderValues((prev) => ({ ...prev, allowCommanDisCount: true }))
    showErrorToast(true, 'success', "", "Discount Applied Successfully")
  }
  function applyDisCount() {
    if (discountValue?.discountPercentage) {
      commanPercentageDiscountPOS(discountValue?.discountPercentage)
    } else if (discountValue?.discountValue) {
      commanOverAllDiscountPOS(discountValue?.discountValue)
    } else if (discountValue?.discountTotalValue) {
      commanFinalDiscountPOS(discountValue?.discountTotalValue)
    }
  }
  const callClearDiscount = (itemDetails = null, otherincome = null) => {
    if (discountValue?.discountPercentage || discountValue?.discountValue || discountValue?.discountTotalValue) {
      clearDiscount(itemDetails, otherincome)
      setSalesOrderValues((prev) => ({ ...prev, allowCommanDisCount: false }))
    }
  }
  function clearDiscount(itemdetails, otherincome) {
    const item = itemdetails ? itemdetails : salesOrderItemDetails
    const otherIncome = otherincome ? otherincome : otherIncomechargesList
    let Discountclear = item?.map((item) => {
      let amount = Number(item?.rate) * item?.qty
      return {
        ...item,
        afterDiscountValueForPerItem: '',
        discountValue: '',
        amount: Number(amount).toFixed(2),
        discountPercentage: '',
        finalValue: ''
      }
    })
    let DiscountclearOtherIncome = otherIncome?.map((item) => {
      return {
        ...item,
        afterDiscountValue: '',
        discountValue: ''
      }
    })
    setSalesOrderItemDetails(Discountclear)
    setOtherIncomechargesList(DiscountclearOtherIncome)
    setDiscountValue({
      discountError: "", discountPercentage: "", discountValue: "",
      discountTotalValue: "", isDiscountApplied: false
    })
    showErrorToast(true, 'success', "", "Discount Clear Successfully")

  }

  async function CreateDuplicateData() {
    // try {
    //   const response = await axiosInstance.post(`/itemmaster/graphql`, { query: salesOrder2Amand(`id:${Number(initialSalesOrder?.id)}, userId:${userId} `) });
    //   const reponseData = response?.data?.data?.salesOrder2Amand
    //   if (reponseData?.success) {
    //     setSalesOrderItemDetails(reponseData?.itemDetails)
    //     setOtherIncomechargesList(reponseData?.otherIncomeCharge?.map(item => ({ ...item, id_: item?.id })))
    //     showErrorToast(true, 'success', "", "Duplicate ItemDetails And Other income Charges Created Successfully")
    //   } else {
    //     let errorFilter = ShowErrorNoties(reponseData?.errors)
    //     showErrorToast(true, 'error', errorFilter)
    //   }
    // } catch (error) {
    //   let errorFilter = ShowErrorNoties(error)
    //   showErrorToast(true, 'error', errorFilter)


    // }
    setSalesOrderItemDetails(salesOrderItemDetails?.map((item) => ({
      ...item,
      id: '',
      createdBy: '',
      modifiedBy: '',
      amount: item?.rate * item?.qty,
      afterDiscountValueForPerItem: '',
      discount: '',
      discountPercentage: '',
      discountValue: '',
      finalValue: ''
    })))
    setOtherIncomechargesList(otherIncomechargesList?.map((item) => ({
      ...item,
      id: '',
      createdBy: '',
      modifiedBy: '',
      afterDiscountValue: '',
      discountValue: '',
    })))
    setSalesOrderValues(prve => ({ ...prve, isLoading: false }))
    setDiscountValue({
      discountError: "", discountPercentage: "", discountValue: "",
      discountTotalValue: "", isDiscountApplied: false
    })
    setEdit(false)

  }

  // retun null ; dulicate The data
  function duplicaTheData(isDup) {
    setSalesOrderValues(prve => ({ ...prve, isLoading: true, createdBy: null, parentOrder: initialSalesOrder?.id, isDuplicate: isDup }))
    setOtherIncomechargesList([])
    setSalesOrderItemDetails([])
    setInitialSalesOrder(prve => ({ ...prve, status: "", createdBy: null, createdAt: "", id: "" }))
    CreateDuplicateData()
    if (isDup === false) {
      showErrorToast(true, 'success', "", "Amend Successfully...")
    } else {
      showErrorToast(true, 'success', "", "Duplicate Successfully...")
    }
  }

  // retun objects; on change address or buyer bulk update the tax
  async function salesOrder2BulkUpdateForTax(state) {
    const updatedItem = salesOrderItemDetails?.map((item) => {
      if (state === "Tamil Nadu" || state === "Tamilnadu") {
        item['sgst'] = item?.tax / 2
        item['cgst'] = item?.tax / 2
        item['igst'] = 0
      } else {
        item['sgst'] = 0
        item['cgst'] = 0
        item['igst'] = item?.tax
      }
      return item;
    });
    setSalesOrderItemDetails(updatedItem)
    const updatedOtherIncomeCharge = otherIncomechargesList?.map((item) => {
      if (state === "Tamil Nadu" || state === "Tamilnadu") {
        item['sgst'] = item?.otherIncomeChargesId?.tax / 2
        item['cgst'] = item?.otherIncomeChargesId?.tax / 2
        item['igst'] = 0
      } else {
        item['sgst'] = 0
        item['cgst'] = 0
        item['igst'] = item?.otherIncomeChargesId?.tax
      }
      return item;
    })
    setOtherIncomechargesList(updatedOtherIncomeCharge)
    showErrorToast(true, 'success', "", "Tax Values Updated Successfully")
  }
  // retun null; after delete the data clear from list in item details
  async function DeleteTheItem() {
    const item = salesOrderItemDetails?.filter(item => item.index !== salesOrderValues?.deleteItemDetailData?.id)
    setSalesOrderItemDetails(item);
    setSalesOrderValues(prve => ({
      ...prve,
      deleteItemDetailData: { Name: "", id: "" }
    }))
    callClearDiscount(item, null)
  }


  // retun null; after delete the data clear from listin other income charges
  const handleDeleteOtherIncomeCharges = (id) => {
    if (id) {
      setOtherIncomechargesList(prev => {
        return prev.filter(item => item.id !== id);
      });
      setSalesOrderValues(prve => ({
        ...prve,
        deleteOtherExpensesData: { Name: "", id: "" }
      }))
    }
  }

  // retun null ; use to change next virsion
  const forWord = () => {
    for (let x in salesOrderValues?.versionList) {
      if (Number(salesOrderValues.currentVersionId) === Number(salesOrderValues?.versionList[x])) {
        let nextIndex = Number(x) + 1;
        if (nextIndex < salesOrderValues?.versionList?.length) {
          // close()
          setSalesOrderValues(prve => ({ ...prve, "currentVersionId": salesOrderValues?.versionList[nextIndex] }))
          fetchEditData(salesOrderValues?.versionList[nextIndex])
          if (nextIndex === salesOrderValues?.versionList.length - 1) {
            setPositionOfVirsion(prve => ({ ...prve, "IsFirst": false, "IsLast": true }))
          } else {
            setPositionOfVirsion(prve => ({ ...prve, "IsFirst": false, "IsLast": false }))
          }
        }
        break;
      }
    }
  };
  // retun null ; use to change prve virsion
  const backWord = () => {
    for (let x in salesOrderValues?.versionList) {
      if (Number(salesOrderValues.currentVersionId) === Number(salesOrderValues?.versionList[x])) {
        let prevIndex = Number(x) - 1;
        if (prevIndex >= 0) {
          // close()
          setSalesOrderValues(prve => ({ ...prve, "currentVersionId": salesOrderValues?.versionList[prevIndex] }))


          fetchEditData(salesOrderValues?.versionList[prevIndex])
          if (prevIndex === 0) {
            setPositionOfVirsion(prve => ({ ...prve, "IsFirst": true, "IsLast": false }))
          } else {
            setPositionOfVirsion(prve => ({ ...prve, "IsFirst": false, "IsLast": false }))
          }
        }
        break;
      }
    }
  };
  // retun null; some item was created without save close the form delete the that created data
  async function bulkDeleteItemDetailsOtherIncomeCharges(params) {
    setSalesOrderValues(prve => ({ ...prve, isLoading: true }))
    try {
      let itemIdList = salesOrderItemDetails?.map((item) => Number(item?.id))
      let otherIncomeChargeIdList = otherIncomechargesList?.map((item) => Number(item?.id))
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: salesOrder2AmandCancel(`itemDetails: [${itemIdList}], otherIncomeCharge: [${otherIncomeChargeIdList}]`) })
      let reponseData = response?.['data']?.['data']?.['salesOrder2AmandCancel']
      if (reponseData?.success) {
        close()
      } else {
        showErrorToast(true, "error", reponseData?.errors)
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, "error", errorFilter)
    }
    setSalesOrderValues(prve => ({ ...prve, isLoading: false }))
  }

  // return null; Cancel the SalesOrder
  async function CancelTheSalesOrder() {
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: salesOrder2CanceledMutation(`id:${initialSalesOrder?.id}`) })
      let reponseData = response?.['data']?.['data']?.['salesOrder2CanceledMutation']
      if (reponseData?.success) {
        setInitialSalesOrder(prev => ({ ...prev, status: "Canceled" }))
      } else {
        showErrorToast(true, "error", reponseData?.errors)
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, "error", errorFilter)
    }

  }

  // return null; Delete the SalesOrder
  async function DeleteTheSalesOrder() {
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: salesOrder2DeleteMutation(`id:${initialSalesOrder?.id}`) })
      let reponseData = response?.['data']?.['data']?.['salesOrder2DeleteMutation']
      if (reponseData?.success) {
        close()
      } else {
        showErrorToast(true, "error", reponseData?.errors)
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, "error", errorFilter)
    }

  }

  // retun Boolen; on change Currency  update the amount and rate
  async function salesOrder2CurrencyConvertion(CurrencyId, previousCurrencyId) {
    setSalesOrderValues(prve => ({ ...prve, isLoading: true }))
    try {
      const currentRate = Number(CurrencyId?.rate)
      const previousRate = Number(previousCurrencyId?.rate)
      const updatedItemDetailsData = salesOrderItemDetails.map(item => {
        const actualRate = item?.amount ? Number(item?.amount).toFixed(2) : 0;
        let convertedRate
        if (CurrencyId.label === "Rupee") {
          const tempInRupee = actualRate * previousRate;
          convertedRate = tempInRupee
        } else if (CurrencyId.label !== "Rupee" && previousCurrencyId?.label !== "Rupee") {
          const tempInRupee = actualRate * previousRate
          convertedRate = tempInRupee / currentRate;
        }
        else {
          const tempInRupee = actualRate / currentRate;
          convertedRate = tempInRupee
        }
        return {
          ...item,
          discountPercentage: "",
          discountValue: "",
          afterDiscountValueForPerItem: '',
          rate: convertedRate.toFixed(2), // Update the item's rate
          amount: (convertedRate * Number(item?.qty)).toFixed(2)
        };
      });
      setSalesOrderItemDetails(updatedItemDetailsData)
      const updatedOtherIncomeChargesData = otherIncomechargesList.map(item => {
        let valuesInRupees = Number(item?.amount);
        let resultAmount;
        if (CurrencyId.label === "Rupee") {
          const tempInRupee = valuesInRupees * previousRate;
          resultAmount = tempInRupee
        } else if (CurrencyId.label !== "Rupee" && previousCurrencyId?.label !== "Rupee") {
          const tempInRupee = valuesInRupees * previousRate
          resultAmount = tempInRupee / currentRate;
        }
        else {
          const tempInRupee = valuesInRupees / currentRate;
          resultAmount = tempInRupee
        }
        const resultamount = resultAmount
        return {
          ...item,
          amount: parseFloat(resultamount).toFixed(2),
        };
      })
      setOtherIncomechargesList(updatedOtherIncomeChargesData)
      setSalesOrderValues(prve => ({ ...prve, isLoading: false }))
      setDiscountValue({
        discountError: "", discountPercentage: "", discountValue: "",
        discountTotalValue: "", isDiscountApplied: false
      });
      return true
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter, "")
      setSalesOrderValues(prve => ({ ...prve, isLoading: false }))
      return false
    }
  }

  async function GetSalesOrderPDF(params) {
    setSalesOrderValues(prve => ({ ...prve, isLoading: true }))
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query:
          GeneratePdfForSalesOrder(`id:${initialSalesOrder?.id}`)
      });
      const reponseData = response?.data?.data
      if (reponseData?.GeneratePdfForSalesOrder?.success) {
        const base64Data = reponseData.GeneratePdfForSalesOrder.pdfData;

        // Convert base64 string to a Blob
        const byteCharacters = atob(base64Data);


        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], { type: 'application/pdf' });
        // vnd.openxmlformats-officedocument.wordprocessingml.document 
        // Create a link element for downloading
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = '_blank';
        link.click(); // Trigger the download

        // Clean up
        window.URL.revokeObjectURL(link.href);
      }

    } catch (error) {

      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter, "")
      return false
    }
    setSalesOrderValues(prve => ({ ...prve, isLoading: false }))
  }

  // fetch time line Datas
  async function fetchTimelineData(id) {
    // setLoading(true)
    try {
      const respones = await axiosInstance.post(`/itemmaster/graphql`, {
        query: leadTimeLine(id)
      })
      let timeLime = respones?.data?.data?.leadTimeLine
      setTimeLineValue(prev => ({ ...prev, treeData: [timeLime], isShow: true }))
    } catch (error) {
      console.log(error);
    }
    // setLoading(false)
  }

  function getOpenmodelId(id, model) {

    if (id && model === "Sales Order") {
      openSalesOrderModal(id)
    } else if (id && model === "Quotation") {
      openQuotationModal(id)
    } else if (id && model === "Lead") {
      openLeadModal(id)
    }

  }
  return (
    <>
      <ToastContainer />
      {
        salesOrderValues?.isshowWaring && WarningmodelSalesorder(salesOrderValues?.isshowWaring, salesOrderValues?.waring, setSalesOrderValues)
      }
      <HierarchicalTreeView data={timeLineValue?.treeData} show={timeLineValue?.isShow} getOpenmodelId={getOpenmodelId} currentID={initialSalesOrder.id}
        close={() => { setTimeLineValue((prev => ({ ...prev, isShow: false }))) }} />
      {<BallTriangleComponent isshow={salesOrderValues?.isLoading} />}
      <Modal key={index} show={true} fullscreen onHide={BeforeCloseCheckData}>
        <div className='itemMaster_Top mx-3 mt-3 d-flex justify-content-center card' style={{ width: '98%' }}>
          <div className="row  align-items-center  justify-content-between ">
            {/* justify-content-between */}
            <div className="col-4  d-flex align-items-center  justify-content-between">
              <h3 className='mx-2 commanModelTitleColor'>
                <i className='fa-solid fa-arrow-left fa-sm me-2 commanModelTitleColor' onClick={BeforeCloseCheckData}></i>
                Sales Order {initialSalesOrder?.status ? <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'> {initialSalesOrder?.status} </span> : ""}
                {salesOrderValues?.isAmend ? <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'> Amend </span> :
                  salesOrderValues?.isDuplicate ? <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'> Duplicate</span> : ""}
                <span className="commanModelTitleColor cursor-pointer" onClick={() => { fetchTimelineData(initialSalesOrder?.leadNo) }}>Tree View</span>
              </h3>

            </div>
            <div className="col-3 px-4  " hidden={salesOrderValues?.isAmend ? true : salesOrderValues?.isDuplicate ? true :
              salesOrderValues?.versionList?.length <= 1 ? true : false}>
              <p className='text-start  p-0 m-0 d-flex justify-content-center'><span className=''>Version</span></p>
              <div className='d-flex justify-content-center'>
                <AiOutlineCaretLeft size={30} onClick={backWord} style={{ color: positionOfVirsion?.IsFirst ? "#ffff" : "" }} disabled={positionOfVirsion?.IsFirst} />
                <span className='fs-5 px-1 ms-1  '>{initialSalesOrder?.salesOrderNumber}</span>
                <AiOutlineCaretRight size={30} onClick={forWord} style={{ color: positionOfVirsion?.IsLast ? "#ffff" : "" }} disabled={positionOfVirsion?.IsLast} />
              </div>
            </div>
            <div className="col-5">
              <div className="row">
                <div className="col-9">
                  <h6 className='text-end'>
                    {salesOrderValues?.createdBy?.username ? `Created By : ${salesOrderValues?.createdBy?.username}` : ""}
                  </h6>
                  <h6 className='text-end'>
                    {initialSalesOrder?.updatedAt ? `Last Modified Date : ${initialSalesOrder?.updatedAt ? DateFormate(initialSalesOrder?.updatedAt) : initialSalesOrder?.createdAt ? DateFormate(initialSalesOrder?.createdAt) : ""} ${initialSalesOrder?.createdTime ? initialSalesOrder?.createdTime : ''}` : ""}
                  </h6>
                </div>
                <div className="col-3 text-end">
                  <i className="fa-solid fa-pen fs-5 text-primary pe-3"
                    hidden={!initialSalesOrder?.active || (!edit) || initialSalesOrder?.status === "Submit" || initialSalesOrder?.status === "Canceled" ? true : false}
                    onClick={() => { setEdit(false) }}
                  ></i>
                  <PiPrinterLight
                    className='fs-3'
                    onClick={GetSalesOrderPDF}
                    hidden={initialSalesOrder?.status === "Submit" ? false : initialSalesOrder?.status === "Draft" ? false : true}
                  />
                  <TiCancel
                    hidden={!initialSalesOrder?.active || (!edit) || initialSalesOrder?.status === "Canceled" ? true : false}
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Cancle"
                    className='fs-2 pb-1'
                    style={{ color: '#7f7f7f', cursor: "pointer" }}
                    onClick={() => { setIsCancelWarningShow(true) }}

                  />
                  <i className="fa-solid fa-copy  fs-5 pt-1 mx-3 text-success" onClick={() => { duplicaTheData(true) }}
                    hidden={!initialSalesOrder?.active || (!edit) || initialSalesOrder?.status === "Canceled"} data-bs-toggle="tooltip" data-bs-placement="top" title="Duplicate" role="button" style={{ cursor: "pointer" }}></i>
                  <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  "
                    onClick={() => { setIsDeleteWarningShow(true) }}
                    hidden={initialSalesOrder?.status === "Canceled" ? !initialSalesOrder?.active || !(edit) : true}
                  ></i>

                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal.Body>
          <div style={{ width: "98%" }}>
            <Formik initialValues={initialSalesOrder}
              onSubmit={handleSubmit}
              validationSchema={SalesOrder2schema}
              enableReinitialize key={formKey}
            >
              {({ errors, submitForm, touched, setFieldValue, values, setValues, isValid, isValidating, isSubmitting }) => {
                {
                  if (!isValid && !isValidating && isSubmitting) {
                    const errorMessages = Object.values(errors)
                      .join(',\n');
                    showErrorToast(true, 'error', `${errorMessages}`);
                  }
                }

                return (
                  <Form>
                    {/*Basic Details */}
                    <div className="row ms-3 mt-2  ">
                      {/* left Side */}
                      <div className="col card  shadow   py-3">
                        {/* <p>{initialSalesOrder?.salesOrderNumber?.linkedModelId}</p> */}
                        <div className="row">
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='salesOrderNumber' id="salesOrderNumber" disabled values={initialSalesOrder?.salesOrderNumber} className='w-100 staan-form-input' />
                            <label htmlFor="salesOrderNumber" className='staan-form-input-label pt-1 px-1 mx-2'>Sales Order No</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='date' name='salesOrderDate' id='salesOrderDate' disabled={edit} className='w-100 staan-form-input' />
                            <label htmlFor="salesOrderDate" className='staan-form-input-label pt-1 px-1 mx-2'>Sales Order Date<span className='text-danger'>*</span></label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={salesOrderValues?.salesPerson}
                              handleInputChange={(e) => {
                                dispatch(
                                  fetchOptionsData(userPermissionforOptions(`userName:"${e}", isSales: true`), "SalesOrderSalesperson")
                                )
                              }}
                              options={salesPersonList}
                              name='salesPerson'
                              id='salesPerson'
                              handleOnChange={(option) => {
                                setFieldValue('salesPerson', option ? Number(option?.value) : null)
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  salesPerson: option ? option : null
                                }))
                              }}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Sales Person<span className='text-danger'>*</span></label>
                            {touched.salesPerson && errors.salesPerson && <small>{errors.salesPerson}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='date' name='dueDate' id='dueDate' disabled={edit} className='w-100 staan-form-input' />
                            <label htmlFor="dueDate" className='staan-form-input-label pt-1 px-1 mx-2'>Due Date<span className='text-danger'>*</span></label>
                            {touched.dueDate && errors.dueDate && <small>{errors.dueDate}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='number' name='creditPeriod' disabled={edit} id='creditPeriod' placeholder='Enter Credit Period' className='w-100 staan-form-input' />
                            <label htmlFor="creditPeriod" className='staan-form-input-label pt-1 px-1 mx-2'>Credit Period<span className='text-danger'>*</span></label>
                            {touched.creditPeriod && errors.creditPeriod && <small>{errors.creditPeriod}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='paymentTerms' id='paymentTerms' disabled={edit} placeholder='Enter Payment Terms' className='w-100 staan-form-input' />
                            <label htmlFor="paymentTerms" className='staan-form-input-label pt-1 px-1 mx-2'>Payment Terms<span className='text-danger'>*</span></label>
                            {touched.paymentTerms && errors.paymentTerms && <small>{errors.paymentTerms}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='customerPoNo' id="customerPoNo" disabled={edit} placeholder='Enter Customer Po No' className='w-100 staan-form-input' />
                            <label htmlFor="customerPoNo" className='staan-form-input-label pt-1 px-1 mx-2'>Customer PO No<span className='text-danger'>*</span></label>
                            {touched.customerPoNo && errors.customerPoNo && <small>{errors.customerPoNo}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='date' name='customerPoDate' id='customerPoDate' disabled={edit} className='w-100 staan-form-input' />
                            <label htmlFor="customerPoDate" className='staan-form-input-label pt-1 px-1 mx-2'>Customer PO Date<span className='text-danger'>*</span></label>
                            {touched.customerPoDate && errors.customerPoDate && <small>{errors.customerPoDate}</small>}
                          </div>
                          <div
                            className='col-6 staan-input-group'>
                            <CustomSelect
                              setSelectEditValue={salesOrderValues?.Department}
                              handleInputChange={(e) => {
                                dispatch(
                                  fetchOptionsData(
                                    DepartmentQuery(
                                      `name: "${e.trim()}"`
                                    ),
                                    'SalesOrderdepartmant'
                                  )
                                )
                              }}
                              options={customerDetailsLists?.DepartmentList}
                              name='department'
                              id='department'
                              handleOnChange={(option) => {
                                setFieldValue(
                                  'department',
                                  option ? Number(option.value) : null
                                )
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  Department: option ? option : null
                                }))
                              }}
                              isDisabled={initialSalesOrder?.id}
                            />
                            <label

                              className='staan-form-input-label pt-1 px-1 mx-2'
                            >
                              Department<span className='text-danger'>*</span>
                            </label>
                            {touched.department && errors.department && (
                              <small>{errors.department}</small>
                            )}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='ProductionOrderNo' disabled id='ProductionOrderNo' className='w-100 staan-form-input' />
                            <label htmlFor="ProductionOrderNo" className='staan-form-input-label pt-1 px-1 mx-2'>Production Order No</label>
                            {/* {  touched. &&  errors.customerPoNo && <small>{errors.customerPoNo}</small>} */}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='DevelopmentOrderNo' id="DevelopmentOrderNo" disabled className='w-100 staan-form-input' />
                            <label htmlFor="DevelopmentOrderNo" className='staan-form-input-label pt-1 px-1 mx-2'>Development Order No</label>
                            {/* {  touched. &&  errors.customerPoNo && <small>{errors.customerPoNo}</small>} */}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='leadNo' id='leadNo' value={salesOrderValues?.lead?.leadNo} disabled className='w-100 staan-form-input' />
                            <label htmlFor="leadNo" className='staan-form-input-label pt-1 px-1 mx-2'>lead No</label>
                            {touched.leadNo && errors.leadNo && <small>{errors.leadNo}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='quotations' id="quotations" value={salesOrderValues?.quotations?.label} disabled className='w-100 staan-form-input' />
                            <label htmlFor="quotations" className='staan-form-input-label pt-1 px-1 mx-2'>Quotations No</label>
                            {touched.quotations && errors.quotations && <small>{errors.quotations}</small>}
                          </div>
                          <div className="col-3 staan-input-group"  >
                            <CustomSelect
                              isClearable
                              setSelectEditValue={salesOrderValues?.currency}
                              options={prefetchValue?.currencyExchangeList}
                              name='Currency'
                              id='Currency'
                              handleOnChange={async (option) => {
                                if ((salesOrderItemDetails?.length > 0 || otherIncomechargesList?.length > 0) && option) {
                                  const currencyResponse = await salesOrder2CurrencyConvertion(option, salesOrderValues?.currency);
                                  if (currencyResponse) {
                                    setSalesOrderValues(prev => ({ ...prev, currency: option }));
                                    setFieldValue('currency', Number(option?.value));
                                  }
                                }
                                // else {
                                //   setSalesOrderValues(prev => ({ ...prev, currency: option }));
                                //   setFieldValue('currency', Number(option?.value));
                                // }
                              }}
                              isDisabled={edit}
                            />
                            <label className='staan-form-input-label pt-1  px-1 mx-2'>Currency<span className='text-danger'>*</span></label>
                            {touched.Currency && errors.Currency && <small>{errors.Currency}</small>}
                          </div>
                          <div className="col-3 staan-input-group">
                            <Field type='text' name='Exchange rate' id="Exchange rate" value={salesOrderValues?.currency?.rate} disabled className='w-100 staan-form-input' />
                            <label htmlFor="Exchange rate" className='staan-form-input-label pt-1 px-1 mx-2'>Exchange rate</label>

                          </div>
                        </div>

                      </div>
                      {/*Customer Details*/}
                      <div className="col card  shadow  mx-2 py-2">
                        {/* Buyer GST Details */}
                        <p className='fw-bolder fs-6 commanModelTitleColor'>Buyer Details</p>
                        <div className="row">
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={salesOrderValues?.buyer}
                              handleInputChange={(e) => { dispatch(fetchOptionsData(SupplierOptionPerameter(`companyName:"${e}", customer:true`), "SalesOrderCustomer")) }}
                              options={customerDetailsLists?.buyerNameList}
                              name='accountName'
                              id='accountName'
                              handleOnChange={(option) => {
                                setCustomerDetailsLists(prve => ({
                                  ...prve, buyerContactList: option?.contact, buyerAddressList: option?.address, buyerStatesList: []
                                }))
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  buyer: Number(option?.value),
                                  buyerGstinType: option?.gstinType ? option?.gstinType : "",
                                  buyerGstin: option?.gstin ? option?.gstin : "",
                                  buyerState: "",
                                  buyerPlaceOfSupply: null
                                }));

                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  buyerAddress: null, buyerContactPerson: null,
                                  buyer: option ? option : null, buyerNumber: option ? { value: option?.value, label: option?.supplierNo } : null
                                }))
                                if ((salesOrderItemDetails?.length > 0 || otherIncomechargesList?.length > 0) && option) {
                                  salesOrder2BulkUpdateForTax(null)
                                }
                              }}
                              isClearable
                              isDisabled={edit}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Buyer<span className='text-danger'>*</span></label>
                            {touched.buyer && errors.buyer && <small>{errors.buyer}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={salesOrderValues?.buyerNumber}
                              handleInputChange={(e) => { dispatch(fetchOptionsData(SupplierOptionPerameter(`supplierNo:"${e}", customer:true`), "SalesOrderCustomerNumber")) }}
                              options={customerDetailsLists?.buyerNumberList}
                              name='buyerNumber'
                              id='buyerNumber'
                              handleOnChange={(option) => {
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  buyerAddress: null, buyerContactPerson: null, buyerPlaceOfSupply: null,
                                  buyerNumber: option ? option : null, buyer: option ? { value: option?.value, label: option?.supplierName } : null
                                }))
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  buyerNumber: option?.value,
                                  buyerGstinType: option?.gstinType ? option?.gstinType : "",
                                  buyerGstin: option?.gstin ? option?.gstin : "",
                                  buyerState: "",
                                  buyerPlaceOfSupply: null
                                }));
                                setCustomerDetailsLists(prve => ({
                                  ...prve, buyerContactList: option?.contact, buyerAddressList: option?.address, buyerStatesList: []
                                }))
                                if (salesOrderItemDetails?.length > 0 || otherIncomechargesList?.length > 0) {
                                  salesOrder2BulkUpdateForTax(null)
                                }
                              }}
                              isDisabled={edit}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Buyer Number<span className='text-danger'>*</span></label>
                            {touched.buyerNumber && errors.buyerNumber && <small>{errors.buyerNumber}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={salesOrderValues?.buyerAddress}
                              options={customerDetailsLists?.buyerAddressList}
                              name='buyerAddress'
                              id='buyerAddress'
                              handleOnChange={(option) => {
                                setSalesOrderValues(prve => ({ ...prve, buyerAddress: option ? option : null }))
                                setFieldValue('buyerState', option?.fullAddredd?.state)
                                setFieldValue("buyerAddress", Number(option?.value))
                                setCustomerDetailsLists(prve => ({
                                  ...prve, buyerStatesList: getStates(option?.fullAddredd?.country),
                                }))
                                // select the buyerPlaceOfSupply
                                setSalesOrderValues(prve => ({ ...prve, buyerPlaceOfSupply: option ? { values: option?.fullAddredd?.state, label: option?.fullAddredd?.state } : null }))
                                setFieldValue("buyerPlaceOfSupply", option ? option?.fullAddredd?.state : null)
                                if ((salesOrderItemDetails?.length > 0) && option) {
                                  salesOrder2BulkUpdateForTax(option?.fullAddredd?.state)
                                }
                              }}
                              isDisabled={edit}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Buyer Address<span className='text-danger'>*</span></label>
                            {touched.buyerAddress && errors.buyerAddress && <small>{errors.buyerAddress}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={salesOrderValues?.buyerContactPerson}
                              options={customerDetailsLists?.buyerContactList}
                              name='buyerContactPerson'
                              id='buyerContactPerson'
                              handleOnChange={(option) => {
                                setSalesOrderValues(prve => ({ ...prve, buyerContactPerson: option ? option : null }))
                                setFieldValue("buyerContactPerson", Number(option?.value))
                              }}
                              isDisabled={edit}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Contact Person<span className='text-danger'>*</span></label>
                            {touched.buyerContactPerson && errors.buyerContactPerson && <small>{errors.buyerContactPerson}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <p className='p-2   fs-6 fw-bolder'>Address</p>
                            {salesOrderValues?.buyerAddress?.fullAddredd ?
                              <>
                                <p className='ps-2 lh-1 '>{salesOrderValues?.buyerAddress?.fullAddredd?.addressLine1} {salesOrderValues?.buyerAddress?.fullAddredd?.addressLine2},</p>
                                <p className='ps-2 lh-1 '>{salesOrderValues?.buyerAddress?.fullAddredd?.city} {salesOrderValues?.buyerAddress?.fullAddredd?.state},</p>
                                <p className='ps-2 lh-1 '>{salesOrderValues?.buyerAddress?.fullAddredd?.country}</p>
                                <p className='ps-2 lh-1 '>Pincode   :{salesOrderValues?.buyerAddress?.fullAddredd?.pincode}</p>
                              </> :
                              <></>
                            }
                          </div>
                          <div className="col-6">
                            <p className='p-2 fs-6 fw-bolder'>Contact </p>
                            {salesOrderValues?.buyerContactPerson ?
                              <>

                                <p className='ps-2 lh-1'>Mobile : {salesOrderValues?.buyerContactPerson?.mobile} </p>
                                <p className='ps-2 lh-1'> Email  : {salesOrderValues?.buyerContactPerson?.Email} </p>
                              </> :
                              ""
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col card  shadow  mx-2 py-2">
                        {/* Consignee Details */}
                        <p className=' fw-bolder fs-6 commanModelTitleColor'>Consignee Details</p>
                        <div className="row">
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              isClearable
                              setSelectEditValue={salesOrderValues?.consignee}
                              handleInputChange={(e) => { dispatch(fetchOptionsData(SupplierOptionPerameter(`companyName:"${e}", customer:true`), "SalesOrderConsignee")) }}
                              options={customerDetailsLists?.consigneeList}
                              name='consignee'
                              id='consignee'
                              handleOnChange={(option) => {
                                setCustomerDetailsLists(prve => ({
                                  ...prve, consigneeContactList: option?.contact, consigneeAddressList: option?.address
                                }))
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  consignee: Number(option?.value),
                                  consigneeGstinType: option?.gstinType ? option?.gstinType : "",
                                  consigneeGstin: option?.gstin ? option?.gstin : "",
                                  consigneeState: "",
                                }));
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  consigneeAddress: null, consigneeContactPerson: null,
                                  consignee: option ? option : null, consigneeNumber: option ? { value: option?.value, label: option?.supplierNo } : null
                                }))
                              }}
                              isDisabled={edit}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Consignee<span className='text-danger'>*</span></label>
                            {touched.consignee && errors.consignee && <small>{errors.consignee}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              isClearable
                              setSelectEditValue={salesOrderValues?.consigneeNumber}
                              handleInputChange={(e) => {
                                dispatch(fetchOptionsData(SupplierOptionPerameter(`companyName:"${e}", customer:true`), "SalesOrderConsigneeNumber"))
                              }}
                              options={customerDetailsLists?.consigneeNumberList}
                              name='consigneeNumber'
                              id='consigneeNumber'
                              handleOnChange={(option) => {
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  consigneeAddress: null, consigneeContactPerson: null,
                                  consigneeNumber: option ? option : null, consignee: option ? { value: option?.value, label: option?.supplierName } : null
                                }))

                                setCustomerDetailsLists(prve => ({
                                  ...prve, consigneeContactList: option?.contact, consigneeAddressList: option?.address
                                }))
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  consigneeNumber: option?.value,
                                  consigneeGstinType: option?.gstinType ? option?.gstinType : "",
                                  consigneeGstin: option?.gstin ? option?.gstin : "",
                                  consigneeState: "",
                                }));
                              }}
                              isDisabled={edit}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Consignee Number<span className='text-danger'>*</span></label>
                            {touched.consigneeNumber && errors.consigneeNumber && <small>{errors.consigneeNumber}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={salesOrderValues?.consigneeAddress}
                              options={customerDetailsLists?.consigneeAddressList}
                              name='consigneeAddress'
                              id='consigneeAddress'
                              handleOnChange={(option) => {
                                setSalesOrderValues(prve => ({ ...prve, consigneeAddress: option ? option : null }))
                                setFieldValue('consigneeState', option?.fullAddredd?.state)
                                setFieldValue('consigneeAddress', Number(option?.value))
                                setCustomerDetailsLists(prve => ({
                                  ...prve, consigneeStatesList: getStates(option?.fullAddredd?.country),
                                }))
                                // update the consigneePlaceOfSupply
                                setSalesOrderValues(prve => ({ ...prve, consigneePlaceOfSupply: option ? { values: option?.fullAddredd?.state, label: option?.fullAddredd?.state } : null }))
                                setFieldValue("consigneePlaceOfSupply", option ? option?.fullAddredd?.state : null)
                              }}
                              isDisabled={edit}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Consignee Address<span className='text-danger'>*</span></label>
                            {touched.consigneeAddress && errors.consigneeAddress && <small>{errors.consigneeAddress}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <CustomSelect
                              setSelectEditValue={salesOrderValues?.consigneeContactPerson}
                              options={customerDetailsLists?.consigneeContactList}
                              name='consigneeContactPerson'
                              id='consigneeContactPerson'
                              handleOnChange={(option) => {
                                setSalesOrderValues(prve => ({ ...prve, consigneeContactPerson: option ? option : null }))
                                setFieldValue('consigneeContactPerson', Number(option?.value))
                              }}
                              isDisabled={edit}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Contact Person<span className='text-danger'>*</span></label>
                            {touched.consigneeContactPerson && errors.consigneeContactPerson && <small>{errors.consigneeContactPerson}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <p className='p-2 fs-6 fw-bolder'>Address</p>
                            {salesOrderValues?.consigneeAddress?.fullAddredd ?
                              <>
                                <p className='ps-2 lh-1'>{salesOrderValues?.consigneeAddress?.fullAddredd?.addressLine1} {salesOrderValues?.consigneeAddress?.fullAddredd?.addressLine2},</p>
                                <p className='ps-2 lh-1'>{salesOrderValues?.consigneeAddress?.fullAddredd?.city} {salesOrderValues?.consigneeAddress?.fullAddredd?.state},</p>
                                <p className='ps-2 lh-1'> {salesOrderValues?.consigneeAddress?.fullAddredd?.country}</p>
                                <p className='ps-2 lh-1'>Pincode   :{salesOrderValues?.consigneeAddress?.fullAddredd?.pincode}</p>
                              </>
                              : ""
                            }

                          </div>
                          <div className="col-6">
                            <p className='p-2 fs-6 fw-bolder'>Contact </p>
                            {salesOrderValues?.consigneeContactPerson ?
                              <>

                                <p className='ps-2 lh-1'>Mobile : {salesOrderValues?.consigneeContactPerson?.mobile} </p>
                                <p className='ps-2 lh-1'> Email  : {salesOrderValues?.consigneeContactPerson?.Email} </p>
                              </>
                              : ""
                            }

                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="row  ms-3 mt-3">
                      <div className="col card  shadow  mx-2 py-2">
                        <p className='fw-bolder fs-6 commanModelTitleColor'>Buyer GST Details</p>
                        <div className="row">
                          <div className="col staan-input-group">
                            <Field type='text' name='buyerGstinType' id='buyerGstinType' disabled className='w-100 staan-form-input' />
                            <label htmlFor="buyerGstinType" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN Type</label>
                            {touched.buyerGstinType && errors.buyerGstinType && <small>{errors.buyerGstinType}</small>}
                          </div>
                          <div className="col staan-input-group">
                            <Field type='text' name='buyerGstin' id='buyerGstin' disabled className='w-100 staan-form-input' />
                            <label htmlFor="buyerGstin" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN</label>
                            {touched.buyerGstin && errors.buyerGstin && <small>{errors.buyerGstin}</small>}

                          </div>
                          <div className="col staan-input-group">
                            <Field type='text' name='buyerState' id='buyerState' disabled className='w-100 staan-form-input' />
                            <label htmlFor="buyerState" className='staan-form-input-label pt-1 px-1 mx-2'>State</label>
                            {touched.buyerState && errors.buyerState && <small>{errors.buyerState}</small>}

                          </div>
                          <div className="col staan-input-group">
                            <CustomSelect
                              setSelectEditValue={salesOrderValues?.buyerPlaceOfSupply}
                              options={customerDetailsLists?.buyerStatesList}
                              name='buyerPlaceOfSupply'
                              id='buyerPlaceOfSupply'
                              handleOnChange={(option) => {


                                setSalesOrderValues(prve => ({ ...prve, buyerPlaceOfSupply: option ? option : null }))
                                setFieldValue("buyerPlaceOfSupply", option ? option?.label : null)
                              }}
                              isDisabled={edit}
                            />
                            <label className='staan-form-input-label pt-1 px-1 mx-2'>Place Of Supply<span className='text-danger'>*</span></label>
                            {touched.buyerPlaceOfSupply && errors.buyerPlaceOfSupply && <small>{errors.buyerPlaceOfSupply}</small>}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row  ms-3 mt-3">
                      <div className="col card  shadow  mx-2 py-2">
                        <p className='fw-bolder fs-6 commanModelTitleColor'>Consignee GST Details</p>
                        <div className="row">
                          <div className="col staan-input-group">
                            <Field type='text' name='consigneeGstinType' id='consigneeGstinType' disabled className='w-100 staan-form-input' />
                            <label htmlFor="consigneeGstinType" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN Type</label>
                            {touched.consigneeGstinType && errors.consigneeGstinType && <small>{errors.consigneeGstinType}</small>}
                          </div>
                          <div className="col staan-input-group">
                            <Field type='text' name='consigneeGstin' id='consigneeGstin' disabled className='w-100 staan-form-input' />
                            <label htmlFor="consigneeGstin" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN</label>
                            {touched.consigneeGstin && errors.consigneeGstin && <small>{errors.consigneeGstin}</small>}

                          </div>
                          <div className="col staan-input-group">
                            <Field type='text' name='consigneeState' disabled id='consigneeState' className='w-100 staan-form-input' />
                            <label htmlFor="consigneeState" className='staan-form-input-label pt-1 px-1 mx-2'>State</label>
                            {touched.consigneeState && errors.consigneeState && <small>{errors.consigneeState}</small>}
                          </div>
                          <div className="col staan-input-group">
                            <CustomSelect
                              setSelectEditValue={salesOrderValues?.consigneePlaceOfSupply}
                              options={customerDetailsLists?.consigneeStatesList}
                              name='consigneePlaceOfSupply'
                              id='consigneePlaceOfSupply'
                              handleOnChange={(option) => {
                                setSalesOrderValues(prve => ({ ...prve, consigneePlaceOfSupply: option ? option : null }))
                                setFieldValue("consigneePlaceOfSupply", option ? option?.label : null)
                              }}
                              isDisabled={edit}
                            />
                            <label className='staan-form-input-label pt-1 px-1 mx-2'>Place Of Supply<span className='text-danger'>*</span></label>
                            {touched.consigneePlaceOfSupply && errors.consigneePlaceOfSupply && <small>{errors.consigneePlaceOfSupply}</small>}

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Item Details */}
                    <div className="row card  shadow ms-3 mt-3 py-3">
                      <div className="row">
                        <div className="col-4">
                          <p className='fw-bolder fs-6 commanModelTitleColor m-2'>Item Details</p>
                        </div>
                        <div className="col-4 text-center">
                          <span className='me-4'>
                            <LuGift className='me-2' style={{ cursor: 'pointer', color: '#FFAA1D' }} />
                            Item Combo Not Saved
                          </span>
                          <span className='me-3'>
                            <LuGift className='me-2' style={{ cursor: 'pointer', color: '#24a0ed' }} />
                            Item Combo Saved
                          </span>
                        </div>
                        <div className="col-4 text-end">
                          <button type="button" className='btn btn-outline-success px-4' style={{ fontSize: '14px', fontWeight: 'bold' }} disabled={edit} onClick={() => { setIsShowItemDetails(true) }}> <i className='bx bxs-plus-circle me-2'></i>New</button>
                        </div>
                      </div>
                      <div className="ag-theme-alpine mt-2" style={{ height: '40rem', width: '100%' }}>
                        <CommanTable
                          headers={COLUMNS}
                          rowData={salesOrderItemDetails}
                        />
                        <ItemCombo
                          IsshowItemCombo={itemComboValues?.showItemCombo}
                          setshowItemCombo={() => { setitemComboValues((prev) => ({ ...prev, showItemCombo: false })) }}
                          lastUpdatedItemDetails={itemComboValues?.lastUpdatedItemDetails}
                          itemComboData={itemComboValues?.itemComboData}
                          setItemComboData={(value) => { setitemComboValues(prev => ({ ...prev, itemComboData: value })) }}
                          // setQuotationItemDetailsShow={setQuotationItemDetailsShow}
                          setItemDetailsData={setSalesOrderItemDetails}
                        />
                      </div>

                    </div>
                    {/* Terms & Conditions ,Other Charges and Amount   */}
                    <div className="row justify-content-between ms-3 mt-4">
                      {/* Terms & Conditions */}
                      <div className="col card shadow-lg">
                        <div className="row">
                          <div className="col-6 staan-input-group mb-3">
                            <CustomSelect
                              setSelectEditValue={salesOrderValues?.termsCondition}
                              handleInputChange={(e) => {
                                dispatch(fetchOptionsData(TermsConditionsFetchQuery(`pageSize:200, name:"${e}", module:"Sales_order"`), "SalesOrderTermsConditionsFetchQuery"))
                              }}
                              options={customerDetailsLists?.termsConditionsList}
                              name='termsConditions'
                              id='termsConditions'
                              handleOnChange={(option) => {
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  termsConditions: option ? Number(option?.value) : "",
                                  termsConditionsText: option ? option?.tc : ""
                                }));
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  termsCondition: option,
                                  termsConditionsText: option?.tc
                                }))
                              }}
                              isDisabled={edit}
                            />
                            <label className='staan-form-input-label pt-1 px-1 mx-2' >Terms & Conditions<span className='text-danger'>*</span></label>
                            {touched.termsConditions && errors.termsConditions && <small>{errors.termsConditions}</small>}
                          </div>
                          <div className="col-12 staan-input-group">
                            <ReactQuill
                              value={salesOrderValues?.termsConditionsText}
                              readOnly={edit}
                              onChange={(e) => {
                                setFieldValue("termsConditionsText", e ? e : "")
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  termsConditionsText: e
                                }))
                              }}
                              modules={textEditormodules} />
                            {touched.termsConditionsText && errors.termsConditionsText && <small>{errors.termsConditionsText}</small>}
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        {/* Other Charges */}
                        <div className="card shadow-lg  p-3">
                          <div className="row">
                            <CommanTable
                              title={"Other Expenses"}
                              headers={OTHER_INCOME_CHARGES_COLUMNDEFS}
                              rowData={otherIncomechargesList}
                              inputSection={OtherIncomeChargesInputForm}
                              getDeleteRowId={handleDeleteOtherIncome}
                              newRowAdd={true}
                              isDisabled={edit}
                            />
                          </div>
                        </div>
                        {/* Amount */}
                        <div className="card shadow-lg mt-3">
                          <table className="table ">
                            <tbody>
                              <tr  >
                                <td className='text-end p-2'  >Item Before Tax</td>
                                <td className='text-end p-2'>
                                  {/* {salesOrderValues?.currency?.currencySymbol}{salesOrderValues?.item_details_value ? salesOrderValues?.item_details_value : "0.00"} */}
                                  {displayRupeeFormat(salesOrderValues?.item_details_value ? salesOrderValues?.item_details_value : "0.00", salesOrderValues?.currency?.label)}
                                </td>
                              </tr>
                              <tr  >
                                <td className='text-end p-2'>Other Charges Before Tax</td>
                                <td className='text-end p-2'>
                                  {/* {salesOrderValues?.currency?.currencySymbol}{salesOrderValues?.other_income_value ? salesOrderValues?.other_income_value : "0.00"} */}
                                  {displayRupeeFormat(salesOrderValues?.other_income_value ? salesOrderValues?.other_income_value : "0.00", salesOrderValues?.currency?.label)}

                                </td>
                              </tr>
                              <tr  >
                                <td className='text-end p-2'>Taxable Value</td>
                                <td className='text-end p-2'>
                                  {/* {salesOrderValues?.currency?.currencySymbol}{Number(salesOrderValues?.other_income_value) + Number(salesOrderValues?.item_details_value) ?
                                  (Number(salesOrderValues?.other_income_value) + Number(salesOrderValues?.item_details_value)).toFixed(2) : "0.00"} */}
                                  {displayRupeeFormat((Number(salesOrderValues?.other_income_value) + Number(salesOrderValues?.item_details_value)).toFixed(2), salesOrderValues?.currency?.label)}
                                </td>
                              </tr>
                              <tr  >
                                <td className='text-end cursor-pointer text-primary p-2' onClick={() => { setIsShowTax(true) }}  >Tax Total</td>
                                <td className='text-end p-2'>
                                  {/* {salesOrderValues?.currency?.currencySymbol}{salesOrderValues?.totaltax ? salesOrderValues?.totaltax : "0.00"} */}
                                  {displayRupeeFormat(salesOrderValues?.totaltax ? salesOrderValues?.totaltax : "0.00", salesOrderValues?.currency?.label)}

                                </td>
                              </tr>
                              <tr  >
                                <td className='text-end cursor-pointer text-primary p-2'   >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="checkbox-tooltip">Click to clear discount</Tooltip>
                                    }>
                                    <span style={{ marginRight: '1rem' }}>
                                      <input type="checkbox" name="allowCommanDiscount" checked={salesOrderValues?.allowCommanDisCount} disabled={edit}
                                        onChange={() => {
                                          setDiscountWaring(true)
                                        }} />
                                    </span>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="overall-discount-tooltip">Click to apply overall discount</Tooltip>
                                    }
                                  >
                                    <span onClick={() => {
                                      if (edit) {
                                        return
                                      }
                                      salesOrderValues?.allowCommanDisCount ? setIsShowDiscount(true) : showErrorToast(true, "warn", "Click The Check Box To Clear Discount")
                                    }}>
                                      Discount
                                    </span>
                                  </OverlayTrigger>
                                </td>
                                <td className='text-end p-2'>
                                  {/* {salesOrderValues?.currency?.currencySymbol}{(salesOrderValues?.actualNetAmount - salesOrderValues?.netAmount).toFixed(2) ?
                                    (Number(salesOrderValues?.actualNetAmount) - Number(salesOrderValues?.netAmount)).toFixed(2) : "0.00"} */}
                                  {displayRupeeFormat(calculatedDiscount || 0, salesOrderValues?.currency?.label)}

                                </td>
                              </tr>
                              <tr  >
                                <td className='text-end p-2'>Round of</td>
                                <td className='text-end p-2'>
                                  {/* {salesOrderValues?.currency?.currencySymbol}{salesOrderValues?.roundOff ? salesOrderValues?.roundOff : "0.00"} */}

                                  {displayRupeeFormat(salesOrderValues?.roundOff ? salesOrderValues?.roundOff : "0.00" || 0, salesOrderValues?.currency?.label)}
                                </td>
                              </tr>
                              <tr className='p-1'>
                                <td className='text-end p-2  fw-bold'>Net Amount</td>
                                <td className='text-end p-2'>{displayRupeeFormat(salesOrderValues?.netAmount || 0, salesOrderValues?.currency?.label)}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                    {/* button */}
                    <div className="row mt-3 ms-3 justify-content-evenly" hidden={!initialSalesOrder?.active}  >
                      <div className="col-1">
                        <button type="submit" disabled={salesOrderValues?.isAmend ? true : initialSalesOrder?.status === "Draft" ? false : initialSalesOrder?.status === "" ? false : true} style={{ width: "6rem" }} className="btn btn-outline-secondary  px-4"> Draft </button>
                      </div>
                      <div className="col-1">
                        <button type="button" disabled={initialSalesOrder?.status === "Draft" ? false : salesOrderValues?.isAmend ? false : true} style={{ width: "6rem" }} onClick={() => { setFieldValue('status', "Submit"); submitForm() }} className="btn btn-outline-success px-3" >Submit</button>
                      </div>
                      <div className="col-1">
                        <button type="submit" disabled={!(initialSalesOrder?.status === "Submit")} onClick={() => { setSalesOrderValues(prve => ({ ...prve, isAmend: true })); duplicaTheData(false) }} style={{ width: "6rem" }} className="btn btn-outline-primary px-3"  >Amend</button>
                      </div>
                      <div className="col-1">
                        <button type="button" disabled style={{ width: "6rem" }} className="btn btn-outline-info px-4" >DC</button>
                      </div>
                    </div>
                    {isCancelWarningShow &&
                      <WaringModel
                        state={isCancelWarningShow}
                        setstate={setIsCancelWarningShow}
                        title={'Confirm'}
                        text={"Confirm to cancel"}
                        Confirmtion={value => {
                          if (value) {
                            CancelTheSalesOrder(value)
                          }
                        }}
                      />}
                    {isDeleteWarningShow &&
                      <WaringModel
                        state={isDeleteWarningShow}
                        setstate={setIsDeleteWarningShow}
                        title={'Confirm'}
                        text={"Confirm to delete"}
                        Confirmtion={value => {
                          if (value) {
                            DeleteTheSalesOrder(value)
                          }
                        }}
                      />}
                    {isShowItemDetails &&
                      <SalesOrderItem
                        isShow={isShowItemDetails}
                        setIsShow={setIsShowItemDetails}
                        buyerState={values?.buyerState}
                        salesOrderItemDetails={salesOrderItemDetails}
                        setSalesOrderItemDetails={setSalesOrderItemDetails}
                        salesOrderItemDetailsEdit={salesOrderItemDetailsEdit}
                        setSalesOrderItemDetailsEdit={setSalesOrderItemDetailsEdit}
                        salesOrderValues={salesOrderValues}
                        setSalesOrderValues={setSalesOrderValues}
                        setIsShowItemComboModel={setIsShowItemComboModel}
                        discountValue={discountValue}
                        otherIncomechargesList={otherIncomechargesList}
                        callClearDiscount={callClearDiscount}
                        setItemComboDataList={setItemComboDataList}
                      />
                    }
                    {isShowTax &&
                      <TaxModel
                        showTaxModel={isShowTax}
                        setShowTaxModel={setIsShowTax}
                        taxData={salesOrderValues?.taxData}
                        setTaxData={(values) => { setSalesOrderValues(prve => ({ ...prve, taxData: values })) }}
                        igstTax={salesOrderValues?.igst}
                        sgstTax={salesOrderValues?.sgst}
                        cgstTax={salesOrderValues?.cgst}
                      />
                    }
                    {isShowDiscount &&
                      <DiscountModel
                        isShow={isShowDiscount}
                        setIsShow={setIsShowDiscount}
                        discountValue={discountValue}
                        setDiscountValue={setDiscountValue}
                        applyDisCount={applyDisCount}
                        ClearDisCount={clearDiscount}
                        netAmount={salesOrderValues?.netAmount}
                      />
                    }
                    {isDiscountWaring &&
                      <WaringModel
                        state={isDiscountWaring}
                        setstate={setDiscountWaring}
                        title={'Confirm'}
                        text={'Confirm to Clear Discount'}
                        Confirmtion={value => {
                          if (value) {
                            setSalesOrderValues(prev => ({ ...prev, allowCommanDisCount: !salesOrderValues?.allowCommanDisCount }))
                            clearDiscount(null, null)
                          }
                        }}
                      />
                    }
                    {isBulkDeleteWaring &&
                      <WaringModel
                        state={isBulkDeleteWaring}
                        setstate={setIsBulkDeleteWaring}
                        title={'Confirm'}
                        text={'Confirm to bulk delete'}
                        Confirmtion={value => {
                          if (value) {
                            bulkDeleteItemDetailsOtherIncomeCharges()
                          }
                        }}
                      />}
                    {isShowOutOfRange &&
                      <WaringModelWithCopy
                        state={isShowOutOfRange}
                        setState={setIsShowOutOfRange}
                        title={"Value Out Of Range"}
                        text={salesOrderValues?.outOfRangeValues}
                        Copy={true}
                      />}
                    {/* {salesOrderValues?.deleteItemDetailData?.Name &&
                      <DeleteConformation
                        handleDeletedId={(id) => { handleDeleteItemDetails(id) }}
                        deleteData={salesOrderValues?.deleteItemDetailData}
                        fullquery={salesOrder2ItemDetailsDeleteMutation(`id:${salesOrderValues?.deleteItemDetailData?.id}`)}
                      />} */}
                    {isItemDeleteWarningShow &&
                      <WaringModel
                        state={isItemDeleteWarningShow}
                        setstate={setisItemDeleteWarningShow}
                        title={'Confirm'}
                        text={`Confirm to Delete ${salesOrderValues?.deleteItemDetailData?.Name}`}
                        Confirmtion={value => {
                          if (value) {
                            DeleteTheItem(value)
                          }
                        }}
                      />}
                    {IsDeleteOtherIncomeCharges && (
                      <WaringModel
                        state={IsDeleteOtherIncomeCharges}
                        setstate={setIsDeleteOtherIncomeCharges}
                        title={'Confirm'}
                        text={deleteOtherIncomeData?.deleteMessage}
                        Confirmtion={value => {
                          if (value) {
                            handleDeleteOtherIncomeFunction(value)
                          }
                        }}
                      />
                    )}
                    {salesOrderValues?.deleteOtherExpensesData?.Name &&
                      <DeleteConformation
                        handleDeletedId={(id) => { handleDeleteOtherIncomeCharges(id) }}
                        deleteData={salesOrderValues?.deleteOtherExpensesData}
                        fullquery={salesOrder2OtherIncomeChargesDeleteMutation(`id:${salesOrderValues?.deleteOtherExpensesData?.id}`)}
                      />
                    }
                    {isShowItemComboModel &&
                      <SalesOrderItemCombo
                        IsshowItemCombo={isShowItemComboModel}
                        setIsShowItemComboModel={setIsShowItemComboModel}
                        itemComboDataList={itemComboDataList}
                        setItemComboDataList={setItemComboDataList}
                        salesOrderValues={salesOrderValues}
                        setSalesOrderValues={setSalesOrderValues}
                        saveItemCombo={saveItemCombo}
                      />}
                    {isShowStockStatementModal &&
                      <StockStatementDetails
                        isShowSelectedStockStatement={isShowStockStatementModal}
                        handleCloseSelectedStockStatement={handleCloseStockStatementModal}
                        stockStatement={selectedStockStatementDetails}
                        part_id={salesOrderValues?.setSelectedPartNo}
                        from={'pos'}
                      />}

                  </Form>
                )
              }}

            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SalesOrderForm