import React, { useContext, useEffect, useState } from 'react';
import Select from "react-select";
import {
    customMultiSelectStyle,
    customSelectStyle
} from "../../TableUtils/TableUtils";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import CustomFilterModal from './customFilterModal';
import DataContext from '../../../../context/ItemMasterContext';
import { tableListQuery } from '../../../../GraphQLQueries/customfilterQueryandMutation';
import axiosInstance from '../../../../api/axoiss';

const CustomFilter = ({ filtersData, columnsEnabled, titleName, setFiltersData, setColumnsEnabled, selectedFilters, selectedColumns, handleFetchData, setcolumnsEnabledSelectValue,columnsEnabledSelectValue }) => {
    const [isFilterModalOpen, setFilterModalOpen] = useState(false)
    const [tableList, settableList] = useState([])
    const [editData, seteditData] = useState('')
    const [tableListSelect, settableListSelect] = useState('')
    const { userId } = useContext(DataContext)

    async function getOptions(e) {
        if (e) {
            let peremeter = `table: "${titleName}", userID:${userId}`
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: tableListQuery(peremeter) });
            const responseData = response?.data?.data?.EditListViewType
            settableList(responseData?.map(item => ({
                value: item?.id,
                label: item?.viewName,
                coloumnToDisplay: item?.coloumnToDisplay,
                filiterConditions: item?.filiterConditions,
                isDefault: item?.isDefault,
                visibleTo: item?.visibleTo,
                createdBy: item?.createdBy,
                modifiedBy: item?.modifiedBy,
                visibleToUser: item?.visibleToUser,
            })))
        }
    }
    const clearSelectedData = () => {
        setFiltersData(selectedFilters);
        setColumnsEnabled(selectedColumns);
        handleFetchData(selectedFilters)
        setcolumnsEnabledSelectValue([])
    }

    const handleFilterChange = (option) => {
        if (option) {
            settableListSelect({
                value: option?.value,
                label: option?.label,
            })
            seteditData(option)
            const parsedDataFilter = JSON.parse(option?.filiterConditions);
            const parsedDataColumn = JSON.parse(option?.coloumnToDisplay);
            const formattedColumns = parsedDataColumn.map((item) => ({
                value: item,
                label: item
            }));
            const formattedFilterData = parsedDataFilter.reduce((acc, current) => {
                const key = Object.keys(current)[0];
                acc[key] = current[key];
                return acc;
            }, {});
            setcolumnsEnabledSelectValue(formattedColumns)
            setFiltersData(formattedFilterData)
            setColumnsEnabled(parsedDataColumn)
            handleFetchData(formattedFilterData)
        }
        else {
            settableListSelect('')
            setFiltersData(selectedFilters);
            setColumnsEnabled(selectedColumns);
            seteditData('')
            setcolumnsEnabledSelectValue([])
            handleFetchData(selectedFilters)
        }
    }
    return (
        <>
            <div className='row'>
                <div className='col-9'>
                    <Select
                        onInputChange={(e) => {
                            e ? getOptions(e) :
                                settableList([]);
                        }}
                        name='column-customize'
                        placeholder="Select Filter"
                        value={tableListSelect}
                        options={tableList}
                        styles={customSelectStyle}
                        isClearable
                        onChange={(option) => { handleFilterChange(option) }}
                    />
                </div>
                <div className='col-3 mt-1'>
                    <HiOutlineAdjustmentsHorizontal
                        style={{ height: '25px', width: '25px' }}
                        onClick={() => { setFilterModalOpen(true) }}
                    />
                </div>
            </div>
            {isFilterModalOpen && <CustomFilterModal
                isFilterModalOpen={isFilterModalOpen}
                setFilterModalOpen={setFilterModalOpen}
                filtersData={filtersData}
                columnsEnabled={columnsEnabled}
                titleName={titleName}
                editData={editData}
                seteditData={seteditData}
                settableListSelect={settableListSelect}
                settableList={settableList}
                clearSelectedData={clearSelectedData}
            />}
        </>
    );
};

export default CustomFilter;
