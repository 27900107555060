import React,{useState,useContext, useEffect} from 'react'


const CustomTableContainer = ({headers, isEditViewMode, data, handleEditTableRow, handleDeleteTableRow, isRawMaterial, handleShowChildBomComponent}) => {
    const [tableData, setTableData] = useState([])


    useEffect(()=>{
        setTableData(data)
    }, [data, headers])

    return(
        <>
            <table style={{width: '100%'}} className="table table-success table-striped">
                <thead className="row">
                    <th className="col-1" style={{fontWeight: 'normal'}}>Sl. No</th>
                    {headers && headers.map(header => (
                        <th className={`${header['width']} py-2`} style={{fontWeight: 'normal'}}>
                            {header['header']}
                        </th>
                    ))}
                    <th className='col-1 py-2' style={{fontWeight: 'normal'}}>{isEditViewMode && 'Action'}</th>
                </thead>
                    {tableData &&
                        tableData.map((row_item, row_index) => (
                        <>
                            <tr className="row pt-1 align-items-center">
                                <td className="col-1" style={{fontWeight: 'normal'}}>{row_index+1}</td>
                                {headers && headers.map((header, header_index) => 
                                (
                                    <td key={header_index} className={`${header['width']}`} style={{fontWeight: 'normal'}}>
                                        {header.object
                                            ? row_item[header['fieldname']][header['object']]
                                            : row_item[header['fieldname']]}
                                    </td>
                                )
                                )}
                                <td className={isRawMaterial ? 'col-1': 'col-1'}>
                                    {isEditViewMode && <><i role="button" 
                                        style={{backgroundColor: 'transparent', margin:'0px', padding: '0px'}} 
                                        className='fa-solid fa-pen fw-bold text-primary'
                                        onClick={()=>{handleEditTableRow(row_index)}}
                                    ></i>
                                    <i role="button" 
                                        style={{backgroundColor: 'transparent'}} 
                                        className='fa-solid fa-trash fw-bolder text-danger'
                                        onClick={()=>{handleDeleteTableRow(row_index)}}
                                    ></i>                                    
                                    </>}
                                    {isRawMaterial && <i role="button" 
                                        style={{backgroundColor: 'transparent'}} 
                                        className='fa-solid fa-ellipsis fw-bolder text-secondary fs-6'
                                        onClick={()=>{handleShowChildBomComponent(row_index)}}
                                    ></i>}
                                </td>
                            </tr>
                        </>
                    ))}
            </table>
        </>
    )
}

export default CustomTableContainer;