import React, { useContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import DataContext from '../../context/ItemMasterContext';
import TableView from '../../Packages/Custom/DataTable';
import "../../Style/main.css";
import SupplierForm from '../ItemMaster/model/SupplierFrom';
import { useLocation } from 'react-router-dom';

const SupplierList = () => {
    const {SupplierAdd, handleSupplierShow, screenSize } = useContext(DataContext)
    const [supplierEdit, setSupplierEdit] = useState(null)
    const [componentName, setComponentName] = useState("")
    const [tableDataFetchQuery, setTableDataFetchQuery] = useState([])
    const location = useLocation();
    // const handleChangeSCBOption = (value)=>{
    //     if(value === 'Supplier'){
    //         setSupplierFilterType({
    //             supplier: true
    //         })
    //     }else if(value === 'Both' && currentURL === '/supplier'){
    //         setSupplierFilterType({
    //             supplier: true,
    //             transporter:  true
    //         })
    //     }else if(value === 'Both' && currentURL === '/customer'){
    //         setSupplierFilterType({
    //             supplier: true,
    //             transporter:  true
    //         })
    //     }else if(value === 'Transporter'){
    //         setSupplierFilterType({
    //             transporter: true
    //         })
    //     }else{

    //     }
    //     SetSupplierFiliter(value)
    // }

    // const handleShowModal = ()=>{
    //   setWaringModal(true)
    // }

    // const handleCloseModal = ()=>{
    //   setWaringModal(false)
    // }

    // async function DeletaTheData(id, url, setdata) {
    //   const response = await axiosInstance.delete(`${url}/${id}`)
    //   if(response){ 
    //     if(setdata){
    //       const remove_delete_data = setdata.filter(data=> data.id !== deleteData.id)
    //       console.log(remove_delete_data);
    //     }
    //   }


    // }

    // const waringToDelete = ()=>{
    //     const ConformTodete = async ()=>{
    //       let supplierid = deleteData.id
    //       let supplierContectId = deleteData.contact
    //       let SupplierAddressId = deleteData.contact
    //       DeletaTheData(supplierid,"/itemmaster/Contact", setcontactdata)
    //       for (let key in supplierContectId) {
    //         DeletaTheData(key,"/itemmaster/Contact", setContanctPost)
    //       }  
    //       for (let key in SupplierAddressId) {
    //         DeletaTheData(key,"/itemmaster/caddress", setAddressPost)
    //       }     

    //     }
    //     const canceltheDeleteOptions =()=>{
    //       setDeleteData()
    //       handleCloseModal()
    //     }
    //   return(
    //     <Modal
    //         show={waringModal}
    //         onHide={handleCloseModal}
    //         backdrop="static"
    //         keyboard={false}
    //     >
    //         <Modal.Header closeButton>
    //         <Modal.Title>Waring To Delete</Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body>
    //             I will not close if you click outside me. Do not even try to press
    //             escape key.
    //         </Modal.Body>
    //         <Modal.Footer>
    //             <button type="button" onClick={ConformTodete} className="btn btn-outline-success">Yes</button>
    //             <button type="button" onClick={canceltheDeleteOptions} className="btn btn-outline-danger">No</button>
    //         </Modal.Footer>
    //     </Modal>
    //   )
    // }

    const [isLoad, setLoad] = useState(false);
    const CustomViewModalComponent = (data) => {
        handleSupplierShow()
        setSupplierEdit(data)
    }

    const CustomAddModalComponent = () => {
        handleSupplierShow()
    }

    let column_details = [
        {
            header: "Company Id",
            flex: 10,
            field: 'supplierNo',
            inputType: 'text',
            query: 'SupplierFormCompanyOptionFetchQuery',
            renderComponent: CustomViewModalComponent,
        },
        {
            header: "Name",
            flex: 10,
            field: 'companyName',
            inputType: 'text',
            query: 'SupplierFormCompanyOptionFetchQuery',
            renderComponent: CustomViewModalComponent,
        },
        {
            header: "GSTIN",
            field: "gstin",
            flex: 8,
            inputType: 'text',
            query: 'SupplierFormGSTINOptionFetchQuery',
        },
        {
            header: "City",
            field: "address",
            flex: 8,
            inputType: 'text',
            label: 'city',
            customFilterField: 'city',
        },
        {
            header: "State",
            field: "address",
            flex: 8,
            inputType: 'text',
            label: 'state',
            customFilterField: 'state',
        },
        {
            header: "Person Name",
            field: "contact",
            flex: 10,
            inputType: 'text',
            label: 'contactPersonName',
            customFilterField: 'contactPersonName',
        },
        {
            header: "Mobile Number",
            field: "contact",
            flex: 8,
            inputType: 'text',
            label: 'phoneNumber',
            customFilterField: 'phoneNumber',
        },
        {
            header: "Email",
            field: "contact",
            flex: 8,
            inputType: 'text',
            label: 'email',
            customFilterField: 'email',
        },
        {
            header: "Is Active",
            field: "active",
            flex: 8,
            inputType: 'text',
            customFilterField: 'active',
        },

    ]


    useEffect(() => {
        let component_name = location.pathname === "/customer" ? "Customer" : location.pathname === "/supplier" ? "Supplier" : "Supplier & Customer";
        if (component_name === 'Customer') {
            setLoad(prevLoad => !prevLoad); 
            setComponentName('Customer');
            setTableDataFetchQuery(['CustomerDataFetch', 'Save_Customer']);
           
        } else {
            setLoad(prevLoad => !prevLoad); 
            setTableDataFetchQuery(['SupplierDataFetch', 'Save_Supplier']);
            setComponentName('Supplier');
            
        }
    }, [location.pathname]);  // Re-run effect when the URL changes


    return (
        <>
            <ToastContainer />
            <waringToDelete />
            <div className='container-fluid index_page_aliment_warp'>
                <div className='index_page_aliment_sidebar'>
                    <SideNavbar />
                </div>
                <div className='index_page_aliment_table'>
                    {tableDataFetchQuery.length > 0 && <TableView
                        headers={column_details}
                        query={tableDataFetchQuery[0]}
                        loadComponent={isLoad}
                        titleName={componentName}
                        permission={tableDataFetchQuery[1]}
                        heightAdjust={`${(screenSize?.height / 20)}rem`}
                        addComponent={CustomAddModalComponent}
                        customFilter={true}
                    />}
                </div>
            </div>
            {SupplierAdd &&
                <SupplierForm
                    componentName={componentName}
                    supplierEdit={supplierEdit}
                    setSupplierEdit={setSupplierEdit}
                    isLoad={isLoad}
                    setLoad={setLoad}
                />
            }
        </>
    )
}

export default SupplierList;