import { Modal } from "react-bootstrap";
import {Formik ,  Form} from 'formik';
import { useEffect, useState } from "react";
import { mrp_schema } from "../../validations/mrp";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axiosInstance from "../../api/axoiss";
import { TotalStockByPartCode } from "../../GraphQLQueries/ItemmasterQuery";
import MrpItem from "./comps/MrpItem";
// import DataContext from '../../context/ItemMasterContext';
import { MrpItemFetchQuery } from "../../GraphQLQueries/MrpQuery";
import MrpBomTable from "./comps/MrpBomTable";


const AddMrp = ({show, close}) => {
    let mrpInitialValue = {
        isSalesOrder: false,
        isProductionOrder: false,
        isItemGroup: false,
        itemPartCode: null,
        itemName: null,
        bom: null,
        qty: '0',
        availableQty: '0',
        supplier: null,
        shortageQty: '0',
        sourceType: null,
        itemGroup: []
    }
    // const {userId} =   useContext(DataContext)
    const [mrpMasterData, setMrpMasterData] = useState(mrpInitialValue);
    const [mrpItem, setMrpItem] = useState([]);
    const [showTreeView, setShowTreeView] = useState(false);
    const [mrpItemIdList, setMrpItemIdList] = useState([]);
    const [showMrpItemModal, setShowMrpItemModal] = useState(false);
    const [salesOrder, setSalesOrder] = useState(false);
    const [productionOrder, setProductionOrder] = useState(false);
    const [isItemGroup, setItemGroup] = useState(false);
    const [loadBomTree, setLoadBomTree] = useState(false);
    const [currentEditMrpId, setCurrentEditMrpId] = useState(null);

    const handleShowTreeView = () => {
        setLoadBomTree(!loadBomTree)
        setShowTreeView(true)
    }

    const handleShowMrpItemModal = () =>{
        setShowMrpItemModal(true)
    }

    const handleCloseMrpItemModal = () =>{
        setShowMrpItemModal(false)
    }

    const MrpItemBomCell = ({data}) => {
        return (<>
            <span>{data?.bom?.bomNo} -- {data?.bom?.bomName}</span>
        </>)
    }

    const MrpItemCostCell = ({data}) => {
        return (<>
            <span>{data?.qty * data?.cost}</span>
        </>)
    }

    const MrpItemSupplierCell = ({data}) => {
        const [constructedSupplier, setConstructedSupplier] = useState('')
        useEffect(()=>{
            let supplier = data?.supplier?.map(item => item.companyName)
            supplier = supplier.join(', ')
            setConstructedSupplier(supplier)
        }, [])
        return (<>
            <span>{constructedSupplier}</span>
        </>)
    }

    const MrpItemAvailableQtyCell = ({data}) => {
        const [availableQty, setAvailableQty] = useState(0);
        useEffect(()=>{
            axiosInstance.post(`/itemmaster/graphql`, { query: TotalStockByPartCode(data?.partCode?.id) })
            .then(res => {
                const total_stock_res_data = res?.data?.data?.itemStockByPartCode?.items?.[0]?.totalCurrentStock || 0; 
                setAvailableQty(total_stock_res_data)
            })
            .catch(err => {
                setAvailableQty(0)
            })
        }, [data])
        return (<>
            <span>{availableQty}</span>
        </>)
    }

    const MrpItemShortageQtyCell = ({data}) => {
        const [shortageQty, setShortageQty] = useState(0);
        useEffect(()=>{
            axiosInstance.post(`/itemmaster/graphql`, { query: TotalStockByPartCode(data?.partCode?.id) })
            .then(res => {
                const total_stock_res_data = res?.data?.data?.itemStockByPartCode?.items?.[0]?.totalCurrentStock || 0; 
                if((total_stock_res_data-data.qty) < 1){
                    setShortageQty(Math.abs(total_stock_res_data-data.qty))
                }
            })
            .catch(err => {
                setShortageQty(0)
            })
        }, [data])
        return (<>
            <span>{shortageQty}</span>
        </>)
    }

    const MrpItemAction = ({data}) => {
        return (<>
            <div>
                <span onClick={()=>{setCurrentEditMrpId(data);handleShowMrpItemModal()}}>
                    <i className="fa-solid fa-pen text-secondary" style={{cursor: 'pointer'}}></i>
                </span>
                {/* <span className=" ms-4">
                    <i className="fa-solid fa-trash" style={{cursor: 'pointer'}}></i>
                </span> */}
            </div>
        </>)
    }

    const columns = [
        {
            headerName:'S.No' , 
            field:'id',  
            flex: 1,
        },
        {
            headerName:'Part Code' , 
            field:'partCode.itemPartCode',  
            flex: 2,
            valueFormatter: (params) => `${params.data.partCode.itemPartCode}`
        },
        {
            headerName:'Part Name' , 
            field:'partCode.itemName',  
            flex: 3,
            valueFormatter: (params) => `${params.data.partCode.itemName}`
        },
        {
            headerName:'Bom' , 
            field:'bom.bomNo',  
            flex: 2,
            cellRenderer: MrpItemBomCell
        },
        {
            headerName:'Mrp Qty' , 
            field:'qty',  
            flex: 1,
        },
        {
            headerName:'Stock' , 
            flex: 1,
            cellRenderer: MrpItemAvailableQtyCell,
        },
        {
            headerName:'Shortage' , 
            flex: 1,
            cellRenderer: MrpItemShortageQtyCell,
        },
        {
            headerName:'Source Type' , 
            field:'sourceType.name',  
            flex: 1,
            valueFormatter: (params) => `${params.data.sourceType.name}`
        },
        {
            headerName:'Supplier' , 
            field:'supplier',  
            flex: 1,
            cellRenderer: MrpItemSupplierCell
        },
        {
            headerName:'Cost' , 
            field:'cost',  
            flex: 1,
            cellRenderer: MrpItemCostCell
        },
        {
            headerName:'Action' , 
            flex: 1,
            cellRenderer: MrpItemAction
        },
    ]

    const defaultColDef = {
        editable: false, 
        resizable: true,
    }

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors, setFieldError }, toggle, setOpenRowIndex, rowdata) => {

    }

    const FetchMrpItemList = (id_list) => {
        axiosInstance.post("/itemmaster/graphql", {query: MrpItemFetchQuery(id_list)})
        .then(res => {
            let mrp_items = res?.data?.data?.mrpItem?.items || []
            setMrpItem(mrp_items)
            const rawMaterialIds = new Set();
            mrp_items.forEach(item => {
                if (item.bom && Array.isArray(item.bom.rawMaterial)) {
                    item.bom.rawMaterial.forEach(rawMaterial => {
                        rawMaterialIds.add(rawMaterial.id);
                    });
                }
            });
            let raw_material_ids = Array.from(rawMaterialIds);
            console.log(raw_material_ids, "raw_material_ids")
        })
        .catch(err =>{ 
            console.log(err, "error")
        })  
    }

    const handleAddedMrpItem = (data) => {
        let previous_data = [data, ...mrpItemIdList]
        setMrpItemIdList(previous_data)
        FetchMrpItemList(previous_data)
    }

    const handleChangeSalesOrder = (e) => {
        if(e.target.checked){
            if(isItemGroup){
                setMrpItem([]);
                setMrpItemIdList([]);
            }
            setSalesOrder(true);
            setProductionOrder(false);
            setItemGroup(false);
            // setSelectedItemGroup(null);
        }else{
            setSalesOrder(false)
        }
    }

    const handleChangeProductionOrder = (e) => {
        if(e.target.checked){
            if(isItemGroup){
                setMrpItem([]);
                setMrpItemIdList([]);
            }
            setSalesOrder(false);
            setProductionOrder(true);
            setItemGroup(false)
            // setSelectedItemGroup(null)
        }else{
            setProductionOrder(false)
        }
    }
    
    useEffect(()=>{
        // FetchItemGroupOptions()
        if(mrpItemIdList && mrpItemIdList.length > 0){
            FetchMrpItemList(mrpItemIdList)
        }
        
    }, [])

    return(
        <>
            <Modal 
                show={show} 
                onHide={close} 
                fullscreen = {true}
                dialogClassName="modal-transparent"
                backdrop="static"
            >
                <Modal.Body>
                    <div style={{fontSize: '.8rem', width: '100%'}} className='mx-auto bg-white'>
                        <div className=" mt-3" style={{width: "100%", height:"fit-content" }}>
                            <div className="row mx-auto py-3 border-bottom border-light shadow-sm " style={{width: "100%"}}>
                                <div className="col-5 my-auto">
                                    <h4>
                                        <span className='me-3' style={{cursor: 'pointer'}} onClick={()=>{
                                            // if (isClose) {
                                            //     handleClearCurrentData();
                                            //     handleCloseBOMShow(modal.id);
                                            // }
                                            close()
                                        }}>
                                            <i className="fa-solid fa-arrow-left  fs-4"></i>
                                        </span>
                                        Material Resource Planning</h4>
                                    {/* <p>Status: <span>{currentBomId ? currentStatus.name: ''}</span></p> */}
                                </div>
                                <div className='col-5'></div>
                                {/* {currentBomId && !modal.isDuplicate && <div className='col-2 text-end'>
                                    {isEditDelOption && <span className='mx-2 text-secondary' style={{cursor: 'pointer'}} 
                                        onClick={()=>{handleCancelBom()}}
                                    >
                                        <i className="fa-solid fa-ban fs-5"></i>
                                    </span> }
                                    {isEditDelOption && <span className='mx-2 text-success' style={{cursor: 'pointer'}} 
                                        onClick={()=>{handleDuplicateCurrentBom()}}
                                    >
                                        <i className="fa-solid fa-copy fs-5"></i>
                                    </span> }
                                    {isEditView && <span className='mx-2 text-danger' style={{cursor: 'pointer'}} 
                                        onClick={handleDeleteBomItem}
                                    >
                                        <i className='fa-solid fa-trash fs-6'></i>
                                    </span> }
                                    {isEditDelOption && <span className='mx-2 text-primary' style={{cursor: 'pointer'}} 
                                        onClick={()=>{setEditView(!isEditView)}}
                                    >
                                        <i className='fa-solid fa-pen fs-6'></i>
                                    </span> }
                                    
                                </div>} */}
                            </div>
                            <div className='py-4' style={{height: '85vh'}}>
                            <Formik 
                                enableReinitialize={true}
                                initialValues = {mrpMasterData}
                                validationSchema={mrp_schema}   
                                onSubmit={handleSubmit}
                            >
                            {({errors, touched, isSubmitting, values, setFieldValue , Formik, formikProps })=>{
                                return(<>
                                    <Form style={{width: "100%" }}>
                                        <div  style={{height: '74vh',  overflowY: 'scroll', overflowX: 'hidden'}}>
                                            <div className="mx-5">
                                                <div className="row">
                                                    <div className="col-4 d-flex align-items-center">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="isSalesOrder"
                                                                className="me-2"
                                                                checked = {salesOrder}
                                                                onChange={(e)=>{handleChangeSalesOrder(e)}}
                                                            />
                                                            Sales Order 
                                                        </label>
                                                    </div>
                                                    <div className="col-4 d-flex align-items-center">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="isSalesOrder"
                                                                className="me-2"
                                                                checked ={productionOrder}
                                                                onChange={(e)=>{handleChangeProductionOrder(e)}}
                                                            />
                                                            Production Order  
                                                        </label>
                                                    </div>
                                                    {/* <div className="col-4 staan-input-group">
                                                        <Select name="itemGroup"  
                                                            options={itemGroupOption}
                                                            style={{fontSize: '.7rem'}}
                                                            className='mt-3'
                                                            isClearable
                                                            styles={customSelectStyle}
                                                            value = {selectedItemGroup}
                                                            onChange={(option)=>handleChangeItemGroup(option)}
                                                        />
                                                        <label htmlFor="itemGroup" className='staan-form-input-label pt-1 px-1 mx-2'>Item Group</label>
                                                    </div> */}
                                                </div>
                                                <div className="d-flex justify-content-end mt-4">
                                                    <div>
                                                        <button 
                                                            className="btn btn-sm btn-outline-primary"
                                                            onClick={handleShowMrpItemModal}
                                                        >
                                                            <i className="fa-solid fa-plus me-2"></i>
                                                            New
                                                        </button>
                                                    </div>
                                                </div>
                                                <div style={{height: '25rem'}}>
                                                    <div className='ag-theme-alpine mt-2'  style={{ height: '100%', width: '100%' }} >
                                                        <AgGridReact
                                                            rowData={mrpItem}
                                                            columnDefs={columns}
                                                            defaultColDef={defaultColDef} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end mt-2">
                                                    <div>
                                                        <button 
                                                            type = "button"
                                                            className="btn btn-sm btn-outline-secondary"
                                                            onClick={handleShowTreeView}
                                                        >
                                                            <i className="fa-solid fa-check me-2"></i>
                                                            Check
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="my-5">
                                                    {showTreeView && <MrpBomTable 
                                                        data = {mrpItem} 
                                                    /> }
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='my-3 d-flex justify-content-end'>
                                            <div>
                                                <button type="submit" 
                                                    // onClick={()=>{setCurrentStatus({id:2, name: 'Saved'})}}
                                                    className='btn btn-sm btn-outline-success fw-bold px-5 mx-4 mt-3'
                                                >Save</button>
                                            </div> 
                                        </div> */}
                                    </Form>
                                </>)
                            }}
                            </Formik>
                            </div>
                        </div>
                    </div>
                    {showMrpItemModal && <MrpItem 
                        show = {showMrpItemModal}
                        close = {handleCloseMrpItemModal}
                        handleAddedMrpItem = {handleAddedMrpItem}
                        data = {currentEditMrpId}
                    />}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddMrp;