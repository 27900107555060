import { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { customSelectStyle } from "../../Packages/Custom/TableUtils/TableUtils";
const Input = (props) => <components.Input {...props} isHidden={false} isDisabled={false} />;

const CustomSelect = ({ handleInputChange, options, name, handleOnChange, isDisabled, setSelectEditValue, isClearable, id, ref, defaultValue, type, isMulti, onFocus }) => {
    const [selectValue, setSelectValue] = useState({ label: "", value: "" })
    useEffect(() => {        
        if (setSelectEditValue && setSelectEditValue?.value) {

            setSelectValue(setSelectEditValue)
        }
        else {

            setSelectValue({ value: '', label: '' })
        }

    }, [setSelectEditValue])
    return (
        <Select
            onInputChange={(inputValue, { action }) => {
                if (action === "input-change") {
                    setSelectValue({ label: inputValue, value: inputValue })
                    if (handleInputChange) {
                        handleInputChange(inputValue)
                    }

                }
            }}
            type={type}
            options={options || []}
            name={name}
            id={id}
            value={selectValue}
            inputValue={selectValue?.label || ''}
            className='mt-3'
            onChange={(option) => {
                setSelectValue(option)
                if (handleOnChange) {
                    handleOnChange(option)
                }
            }}
            isDisabled={isDisabled}
            styles={customSelectStyle}
            controlShouldRenderValue={true}
            components={{
                Input
            }}
            isClearable={isClearable}
            ref={ref}
            defaultValue={defaultValue}
            isMulti={isMulti}
            onFocus={onFocus}
        />
    )
}

export default CustomSelect;