import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { RiFileExcel2Line } from "react-icons/ri";
import TableView from '../../../Packages/Custom/DataTable';
import { convertDateTimeToDateString } from '../../../Packages/Custom/TableUtils/TableUtils';
import DataContext from '../../../context/ItemMasterContext';
import { handleExportExcel } from '../../../utils/Utils';

const InventoryApprovals = ({show, close, data}) => {
  const {screenSize} = useContext(DataContext)
  const [ExportData, setExportData] = useState({data:"", show:false})
  
  const column_details = [
      {
          header: "Part Number",
          flex: 5,
          field: 'partNumber',
          inputType: 'text',
          query: 'itemMasterCustomOptionFetchQuery',
          label: 'itemPartCode',
          queryField: 'itemPartCode',
        },
        {
          header: "Qty",
          field: "qty",
          flex: 5,
          inputType: 'number',
        },
        {
          header: "Store",
          field: "store",
          flex: 5,
          inputType: 'text',
          label: 'storeName',
          query: 'storeFetchQuery',
          option: true,
        },
        {
          header: "Batch Number",
          field: "batchNumber",
          flex: 5,
          inputType: 'text',
          label: 'batchNumberName',
          query: 'BatchNumberOptionFetchQuery',
        },
        {
          header: "Serial Number",
          field: "serialNumberJoined",
          flex: 5,
          inputType: 'text',
          query: 'SerialNumberOptionFetchQuery',
          label: 'serialNumber'
        },
  ]
    
    const ExportModel=({ExportData, setExportData, screenSize})=>{
 
    
      return(
        <Modal show={ExportData?.show} onHide={()=>{setExportData(prve=>({...prve,show:false }))}} size='xl'>
        <Modal.Header closeButton>
            <Modal.Title>
                <h5>Export Data</h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row ">
            <div className="col-6">
  
            </div>
            <div className="col-6 text-end">
              <button className="btn btn-sm btn-outline-primary p-3 m-2  ">
              <RiFileExcel2Line style={{scale:"2"}}
              
              onClick={()=>{
                let EnquiryReportpdfRef = document.getElementById('stockaddtionsExportView');
                handleExportExcel(EnquiryReportpdfRef, "Enquiry")
              }
              }
                /></button>
             
              
  
              
            </div>
          </div>
          <div  className='table-responsive ' id='stockaddtionsExportView' >
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" >S.No</th>
                  <th scope="col">Part Number</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Store</th>
                  <th scope="col">Batch Number</th>
                  <th scope="col">Serial Number</th>
                </tr>
              </thead>
              <tbody>
                
                {ExportData?.data?.map((stock, index)=>(
                  <>
                  <tr>
                  <th scope="row">{index+1}</th>
                  <td>{stock?.partNumber?.itemPartCode}</td>
                  <td>{stock?.qty}</td>
                  <td>{stock?.store?.storeName}</td>
                  <td>{stock?.batchNumber?.batchNumberName}</td>
                  <td>{stock?.serialNumberJoined}</td> 
                </tr>
                  </>
                )
                  
                )}
              
              </tbody>
            </table>
          </div>
          
        </Modal.Body>
      </Modal>
      )
  }

    function exportData(data) {
    
      setExportData({data:data, show:true})
    }
    return(
        <>
            <Modal onHide={close} show={show} fullscreen>
                <Modal.Body>
                    <div className='text-end'>
                        {/* <span style={{cursor: 'pointer'}} onClick={close}>
                            <i className="fa-solid fa-xmark"></i>
                        </span> */}
                    </div>
                    <div>
                      <div className='d-flex justify-content-between align-items-center mx-auto' style={{width: '95%'}}>
                        <p className='commanModelTitleColor fs-5'><i className='fa-solid fa-arrow-left fa-sm me-2 fs-5 ' onClick={close}></i>Inventory ID: <span className='fw-bold ms-2'>{data?.inventoryHandlerId}</span></p>
                        <p>Conference: <span className='fw-bold ms-2'>{data?.conference?.name}</span></p>
                        <p>Total Qty: {data?.qtyofInventoryApproval}</p>
                        <p>Created At: <span className='fw-bold ms-2'>{data?.createdAt ? convertDateTimeToDateString(data?.createdAt) : ''}</span></p>
                        <p>Created By: <span className='fw-bold ms-2'>{data?.savedBy?.username}</span></p>
                      </div>
                    </div>
                    <TableView
                        headers = {column_details}
                        query = {'InverntoryApprovalMasterQuery'}
                        loadComponent = {false}
                        titleName = {'Stock Addition'}
                        heightAdjust = {'33rem'}
                        currentPageSize = {"50"}
                        exportData = {exportData}
                        addSerialNumber = {true}
                    />
                </Modal.Body>
            </Modal>
            {ExportData?.show &&
          <ExportModel
          ExportData={ExportData}
          setExportData={setExportData}
          screenSize={screenSize}
          />}
        </>
    )
}


export default InventoryApprovals;