import * as yup from "yup";

export const PurchaseItemSchema = yup.object().shape({
    item_master_part_code : yup.string().required('Part Code is required'),
    item_master_part_name : yup.string().required('Part Name is required'),
    category : yup.string().required('Category is required'),
    po_qty : yup.number().required('Po Qty is required'),
    uom : yup.string().required('UOM is required'),
    rate : yup.number().required('Rate is required'),
    // discount : yup.string().nullable(),
    tax : yup.number().required('tax is required'),
    amount : yup.number(),
    discount: yup.string().nullable()
      .test(
          'is-valid-discount',
          'Discount must be a valid percentage or currency amount',
          value => {
              if (value  === undefined || value.trim()  === ''){
                return true; // Allow empty string
              } 
              return /^₹\d+(\.\d+)?$/g.test(value) || /^\d+(\.\d+)?%$/g.test(value);
          }
      )
      .test(
          'is-valid-percentage-range',
          'Percentage must be between 1 and 100',
          value => {
              if (/^\d+(\.\d+)?%$/g.test(value)) { 
                  const percentageValue = parseInt(value.replace('%', ''), 10);
                  return percentageValue >= 0 && percentageValue <= 100;
              }
              return true;
          }
      )
})


export const PurchaseSchema = yup.object().shape({
  supplier_Number : yup.string().required('Supplier Code is required'),
  supplier_name : yup.string().required('Supplier Name is required'),
  credit_period : yup.number().required('Credit Period is required'),
  receiving_store_id : yup.string().required('Receiving Store is required'),
  scrap_reject_store_id : yup.string().required('Scrap Reject is required'),
  contact : yup.string().required('Contact is required'),
  country : yup.string().required('Country is required'),
  due_date : yup.date().required('Due Date is required'),
  gstin_type : yup.string().required('Gstin Type is required'),
  gstin : yup.string().required('Gstin is required'),
  state : yup.string().required('state is required'),
  termsConditions : yup.string().required("Terms Conditions is required")
})