import Modal from 'react-bootstrap/Modal'; 
import {Formik ,  Form } from 'formik';
import React, {useState, useEffect} from 'react' 
import Select from 'react-select';
import { scrap_store_schema } from '../../../validations/bom';
import { customSelectStyle } from '../../../utils/Utils';
import axiosInstance from '../../../api/axoiss';
import { useSelector, useDispatch } from 'react-redux';
import { ItemMasterProductsFetchQuery } from '../../../GraphQLQueries/ItemmasterQuery'
import { fetchItemMasterData } from "../../../ReduxState/Action/itemMasterAction";
import { createScrapItem } from '../../../GraphQLQueries/BomMutation';

const AddScrapStore = ({data, isShowAddScrapStore, handleCloseScrapStore, handleAddedData}) => {
    const dispatch = useDispatch(); 
    const itemMasterDataList = useSelector(state => state.itemMasterData.data);

    let initialValue = {
        part_code: '',
        part_name: '',
        category: '',
        qty: '',
        uom: '',
        cost_allocation: ''
    }

    const [uomSelectOptions, setUomSelectOptions] = useState([])
    const [selectpartCode, setSelectpartCode] = useState(null)
    const handleSelectpartCode = (option)=>{ setSelectpartCode({ value: option?.value , label:  option?.label })}
    const [selectpartName, setSelectpartName] = useState(null)
    const handleSelectpartName = (option)=>{ setSelectpartName({ value: option?.value , label:  option?.label })}
    const [qty, setQty] = useState('')
    const handleSelectQty = (value)=>{setQty(value)}
    const [selectUom , setSelectUom] = useState() 
    const handleSelectUom = (option)=>{ setSelectUom({ value: option?.value , label:  option?.label })}
    const [selectCategory, setSelectCategory] = useState('')
    const handleSelectCategory = (value)=>{ setSelectCategory(value)}
    const [selectCostAllocation, setSelectCostAllocation] = useState('')
    const handleSelectCostAllocation = (value)=>{ setSelectCostAllocation(value)}

    const get_uom_options = (option) => {
        let item_master_data = itemMasterDataList?.items?.find(item => item.id === option?.value)
        let uom_options = []
        uom_options.push({'label': item_master_data?.itemUom?.name, 'value': item_master_data?.itemUom?.id})
        if(item_master_data?.alternateUom){
            item_master_data?.alternateUom?.forEach(element => {
                uom_options.push({'label': element?.addtionalUnit?.name, 'value': element?.addtionalUnit?.id})
            });
        }
        setUomSelectOptions(uom_options)
    }

    const handleCategoryValue = (option) => {
        let item_master_data = itemMasterDataList?.items?.find(item => item.id === option?.value)
        setSelectCategory(item_master_data?.category?.name)
    }

    const clearInputDatas = () =>{
        setSelectpartCode(null) 
        setSelectpartName(null)
        setQty('')
        setSelectUom(null)
        setSelectCostAllocation('')
        setSelectCategory('')
    }

    const handleSubmit = (values, { setSubmitting, resetForm, setErrors, setFieldError }) => {
        let form_data = {
            part_code: selectpartCode,
            part_name: selectpartName,
            category: selectCategory,
            qty: qty,
            uom: selectUom,
            cost_allocation: selectCostAllocation
        }
        let submit_data = {
            serialNumber: 1, 
            qty: qty,
            partNo: selectpartCode?.value,
            unit: selectUom?.value,
        }
        if(data){
            submit_data['id'] = data.id
            form_data['id'] = data.id
        }
        if(selectCostAllocation && selectCostAllocation !==''){
            submit_data['costAllocation'] = selectCostAllocation
        }
        if(selectCategory && selectCategory !==''){
            submit_data['category'] = selectCategory
        }
        let mutation_query = createScrapItem(submit_data)
        axiosInstance.post("/itemmaster/graphql", {query: mutation_query})
        .then(response => {
            const keys = Object.keys(response.data.data);
            const firstKey = keys.length > 0 ? keys[0] : null;
            const data = response.data.data[firstKey]?.scrap?.id
            form_data['id'] = data
            handleAddedData(form_data)
            clearInputDatas()
            handleCloseScrapStore()
            setSubmitting(false)
        })
        .catch(error => {
          console.log(error)
        });
        
    }

    const handleCloseModal = () => {
        clearInputDatas();
        handleCloseScrapStore();
    };

    const handleOnInputChangeTypeSearch = (value, field) => {
        if(value.trim() !== ''){
            let filter_obj = {
                key: field,
                value: value
            }
            dispatch(fetchItemMasterData(ItemMasterProductsFetchQuery('scrap', filter_obj)));
        }else{
            dispatch(fetchItemMasterData(ItemMasterProductsFetchQuery('scrap')))
        }
    }

    useEffect(()=>{
        if(data){
            setQty(data['qty'])
            setSelectpartCode(data['part_code'])
            setSelectpartName(data['part_name'])
            setSelectCostAllocation(data['cost_allocation'])
            setSelectCategory(data['category'])
            setSelectUom({label: data['uom']['label'], value: data['uom']['value']})
            let uom_options = [];
            uom_options.push({label: data['uom']['label'], value: data['uom']['value']})
            if(data?.partNo?.alternateUom){
                data?.partNo?.alternateUom?.forEach(element => {
                    uom_options.push({'label': element?.addtionalUnit?.name, 'value': element?.addtionalUnit?.id})
                });
            }
            let uom_options_unique = uom_options.filter((obj, index, self) =>
                index === self.findIndex((t) => (
                    t.value === obj.value
                ))
            );
            setUomSelectOptions(uom_options_unique);
        }
    }, [data, dispatch])

    const mapDataToInitialValues = (data) => {
        let mappedData = {...data}
        mappedData['part_code'] = data['part_code']?.value
        mappedData['part_name'] = data['part_name']?.value
        mappedData['uom'] = data['uom']?.value
        return mappedData;
    };

    return(
        <>
            <Modal show={isShowAddScrapStore} onHide={()=>{clearInputDatas(); handleCloseModal();}}  size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Scrap Store</Modal.Title>
                </Modal.Header>
                <Modal.Body>  
                    <Formik 
                        initialValues = {data ? mapDataToInitialValues(data) : initialValue}
                        validationSchema={ scrap_store_schema }
                        onSubmit={(values, formikProps) => handleSubmit(values, formikProps)}                        >
                        {({ errors, submitForm, touched, setFieldValue })=>(
                            <>
                                <Form style={{width: "100%", fontSize: '.8rem' }}>
                                    <div className='row mx-auto py-3'>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemPartCode')}}
                                                options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemPartCode, name:item.itemName  }))}
                                                isSearchable={true}
                                                value={selectpartCode}
                                                isClearable
                                                onChange={(option) => {
                                                    setFieldValue('part_code', option ? option.value : null);
                                                    setFieldValue('part_name', option ? option.value : null)
                                                    setSelectUom('')
                                                    setSelectCostAllocation('')
                                                    setSelectCategory('')
                                                    setQty('')
                                                    handleSelectpartCode(option)
                                                    get_uom_options(option)
                                                    handleCategoryValue(option)
                                                    setSelectpartName({ value: option?.value , label:  option?.name })
                                                }} 
                                                name='part_code' style={{fontSize: '.7rem'}} 
                                                className='mt-3'
                                                styles={customSelectStyle} 
                                            />
                                            <label htmlFor="part_code" className='staan-form-input-label pt-1 px-1 mx-2'>Part Code</label>
                                            <p>{  touched.part_code &&  errors.part_code && <small>{errors.part_code}</small>}</p>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                onInputChange={(e)=>{handleOnInputChangeTypeSearch(e, 'itemName')}}
                                                options={itemMasterDataList?.items?.map((item) => ({ value: item.id, label: item.itemName, code:item.itemPartCode  }))}
                                                isSearchable={true}
                                                value={selectpartName}
                                                isClearable
                                                onChange={(option) => {setFieldValue('part_name', option ? option.value : null);
                                                handleSelectpartName(option) 
                                                setFieldValue('part_code', option ? option.value : null)
                                                setSelectUom('')
                                                setSelectCategory('')
                                                setSelectCostAllocation('')
                                                setQty('')
                                                get_uom_options(option)
                                                handleCategoryValue(option)
                                                setSelectpartCode({ value: option?.value , label:  option?.partCode })}}
                                                name='part_name' style={{fontSize: '.7rem'}} 
                                                className='mt-3'
                                                styles={customSelectStyle} 
                                            />  
                                            <label htmlFor="part_name" className='staan-form-input-label pt-1 px-1 mx-2'>Part Name</label>                                          
                                            <p>{  touched.part_name &&  errors.part_name && <small>{errors.part_name}</small>}</p>
                                        </div>
                                    </div>
                                    <div className='row mx-auto my-3'>
                                        <div className="col-6 staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                disabled
                                                value={selectCategory}
                                                onChange={(e)=>{
                                                    setFieldValue('category', e.target.value);
                                                    handleSelectCategory(e.target.value)
                                                }}
                                                name='category' style={{fontSize: '.7rem'}} 
                                                
                                            />
                                            <label htmlFor="category" className='staan-form-input-label pt-2 px-1 mx-2'>Category</label>
                                            <p>{  touched.category &&  errors.category && <small>{errors.category}</small>}</p>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                 onChange={(e)=>{
                                                    handleSelectQty(e.target.value); 
                                                    setFieldValue('qty', e.target.value);
                                                }}
                                                value={qty}
                                                name='qty' style={{fontSize: '.7rem'}} 
                                            />
                                            <label htmlFor="qty" className='staan-form-input-label pt-2 px-1 mx-2'>Quantity</label>
                                            <p>{  touched.qty &&  errors.qty && <small>{errors.qty}</small>}</p>
                                        </div>
                                    </div>
                                    <div className='row mx-auto my-4'>
                                        <div className="col-6 staan-input-group">
                                            <Select
                                                isClearable
                                                options={uomSelectOptions}
                                                onChange={(option)=>{
                                                    setFieldValue('uom', option ? option.value : null)
                                                    handleSelectUom({ value: option?.value , label:  option?.label})
                                                }}
                                                value={selectUom}
                                                name='uom' style={{fontSize: '.7rem'}} 
                                                className='mt-3'
                                                styles={customSelectStyle}
                                            />
                                            <label htmlFor="uom" className='staan-form-input-label pt-1 px-1 mx-2'>Unit</label>
                                            <p>{  touched.uom &&  errors.uom && <small>{errors.uom}</small>}</p>
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <input type='text' className='w-100 staan-form-input' 
                                                value={selectCostAllocation}
                                                onChange={(e)=>{
                                                    handleSelectCostAllocation(e.target.value) ; 
                                                    setFieldValue('cost_allocation', e.target.value) 
                                                }}
                                                name='cost_allocation' style={{fontSize: '.7rem'}} 
                                            />
                                            <label htmlFor="cost_allocation" className='staan-form-input-label pt-1 px-1 mx-2'>Cost Allocation</label>
                                            <p>{  touched.cost_allocation &&  errors.cost_allocation && <small>{errors.cost_allocation}</small>}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end mx-2'>
                                        <button className='btn btn-sm btn-outline-success px-3'>Save</button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddScrapStore;