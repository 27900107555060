import * as  yup from "yup"

export const LeadSchema = yup.object().shape({
    LeadName:yup.string().required("Lead Name is required") ,
    customerValue: yup.string().required("Customer Value is required"),
    LeadCurrency:yup.number().required("Lead Currency is required") ,
    LeadValue: yup.number().required("Lead Value is required"),
    ExpectedClosureDate:yup.string().required("Expected Closure Date is required") ,
    SalesPerson: yup.string().required("Sales Person is required"),
})

export const LeadEditSchema = yup.object().shape({
    LeadName:yup.string().required("Lead Name is required") ,
    customerValue: yup.string().required("Customer Value is required"),
    LeadCurrency:yup.number().required("Lead Currency is required") ,
    LeadValue: yup.number().required("Lead Value is required"),
    ExpectedClosureDate:yup.string().required("Expected Closure Date is required") ,
    SalesPerson: yup.string().required("Sales Person is required"),
    LeadNumber:yup.string().required("Lead Number is required"),
    LeadStatus: yup.string().required("Lead Status is required"),
    // Source:yup.string().required("Source is required"),
})



