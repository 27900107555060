import html2pdf from 'html2pdf.js';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import axiosInstance from "../api/axoiss";
import { countries_list } from "../components/CountryWithStates";
import showErrorToast from '../components/notifications/react-toastify/toast';

function hasDuplicateKey(arr, key) {
  return arr.some(function (item, idx) {
    // Check if there is any other object with the same key value
    return arr.findIndex(function (innerItem, innerIdx) {

      return innerIdx !== idx && innerItem[key] === item[key];
    }) !== -1;
  });
}

export function checkChangesInputs(values) {
  var duplicate_returns = []
  for (var key_name of Object.keys(values[0])) {
    var isDuplicate = hasDuplicateKey(values, key_name)
    duplicate_returns.push(isDuplicate)
  }
  return duplicate_returns.some(item => item === false)
}

// pass query get data for react select
export const OnchangeGetData = async (graphqlQuery, url = "/itemmaster/graphql") => {
  let success = false;
  let data = null;
  let errors = [];
  try {
    const response = await axiosInstance.post(url, { query: graphqlQuery });
    success = true;
    data = response.data.data;
  } catch (error) {
    errors.push("Error occurred while fetching data.");
    // Optionally, set an error state here to give feedback to your users
  }

  return { data, success, errors };
};


// Dynamically construct variables by filtering out empty values 
export function removeEmptyValueInObject(params, allow_null = [], allow_mute_line = [], ispdf = false, multiArray = []) {
  let fields = ""

  Object.entries(params).reduce((acc, [key, value]) => {
    if (value !== "" && value !== undefined) { // Adjust condition based on your needs 

      if (typeof (value) === "number" || typeof (value) === "boolean") {


        fields += `${key}: ${value},`
      } else if (Array.isArray(value)) {
        if (multiArray.includes(key)) {
          let ArrayString = JSON.stringify(value)
          

          fields += `${key}:${ArrayString.replace(/"(\w+)":/g, '$1:')}`
        } else {
          fields += `${key}:[${value}]`
        }
      } else if (allow_mute_line.includes(key)) {

        fields += `${key}: """${value}""",`;
      } else {


        if (typeof (value) === "object") {

          let valueChangeToObj = JSON.stringify(value)
          fields += `${key}: "${valueChangeToObj.replace(/"/g, '\\"')}",`;
        }
        // else if(typeof (value) === "object" && ispdf){
        //   let valueChangeToObj = JSON.stringify(value)
        //   fields += `${key}: "${valueChangeToObj.replace(/"/g, '\"')}",`;
        // }
        else {

          fields += `${key}: "${value}",`;
        }
      }
    }
    else if (((value === undefined) || (value === "")) && (allow_null.length > 0)) {

      if (allow_null.includes(key)) {
        fields += `${key}: ${null} , `;
      } else {
        fields += `${key}: "",`;
      }
    }
    return acc;
  }, {});
  return fields
}


// retun data; get Current Date
export const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

//retun String;  Change TitleCase
export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};



export const ShowErrorNoties = (error) => {
  let checkErrorData = error?.response && error?.response.data

  if (checkErrorData !== undefined) {
    // Handle the error based on the structure of the response

    const errorData = error.response.data;

    if (typeof errorData === "object" && !Array.isArray(errorData)) {
      // Get keys
      let keys = Object.keys(errorData);
      let passTheError = "";

      // Iterate over keys to get values
      keys.forEach(key => {
        let value = errorData[key];
        passTheError += `${key}: ${value}\n`;
      });

      return passTheError.trim();
    } else {
      return errorData.toString();
    }
  } else if (typeof error === "object") {
    return error?.['errors']?.[0] || JSON.stringify(error);
  } else if (!Array.isArray(error)) {
    // Handle other types of errors (e.g., network issues)  
    return "Network Error";
  }


  if (Array.isArray(error)) {
    return error.map(e => e.toString()).join("\n");
  }
  console.log("2n   d condition");
  // Fallback to returning the error as a string if no other conditions match
  return error.toString();
}

//retun Obj; Split account by  account Group Type
export function getAccountGroupOptions(group_list) {
  const OptionDataSell = [];
  const OptionDataPurchase = [];
  const OptionDataAll = []
  group_list.map((group) => {
    if (group['name'] === "Asset" || group['name'] === "Income") {
      OptionDataSell.push({ value: group.id, label: group.name })
    }
    if (group['name'] === "Liabilities" || group['name'] === "Expenses") {
      OptionDataPurchase.push({ value: group.id, label: group.name })
    }
    OptionDataAll.push({ value: group.id, label: group.name })
    return null
  })
  return [OptionDataSell, OptionDataPurchase, OptionDataAll]
}

//retun String; to get path of url
export function currentPath(currentURL) {
  const lastSlashIndex = currentURL.lastIndexOf("/");
  const path = currentURL.substring(lastSlashIndex + 1);
  return path
}

// retun Date; to split date and time 
export function DateFormate(timestamp) {
  
  
  if (timestamp) {
    const dateTime = new Date(timestamp);
    // Extracting date components
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(dateTime.getDate()).padStart(2, '0');    
    return `${day}-${month}-${year}`
  }

}
// retun time; to split date and time
export function TimeFormate(timestamp) {
  if (timestamp) {
    const dateTime = new Date(timestamp);
    const hours = String(dateTime.getHours()).padStart(2, '0');
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`; // Returns the time in IST
  }
  return '';
}

// retun Number; Calculate the final Price
export const calculateFinalPrice = (mrp, tax) => {
  let taxRate = tax / 100
  const mrpAmount = parseFloat(mrp);
  if (!isNaN(mrpAmount) && !isNaN(tax)) {
    const taxAmount = mrpAmount * taxRate;
    const finalPrice = mrpAmount + taxAmount;
    return finalPrice.toFixed(2);
  } else {
    return 'Invalid MRP';
  }
};

export const customSelectStyle = {
  control: (provided, state) => ({
    ...provided,
    fontSize: '.8rem !important',
    backgroundColor: state.isDisabled ? 'transparent' : provided.backgroundColor,
    color: state.isDisabled ? '#000' : provided.color,


  }),
  option: (provided) => ({
    ...provided,
    fontSize: '.8rem !important',
  }),
}

export const customSingleValue = ({ data }) => {
  return (
    <div style={{ userSelect: 'text' }} >
      {data.label}
    </div>
  );
};

export const posCustomSelectStyle = {
  control: (provided) => ({
    ...provided,
    fontSize: '.8rem',
    userSelect: 'text',  // Make control text selectable
  }),
  singleValue: (provided) => ({
    ...provided,
    userSelect: 'text', // Ensure the selected value text is selectable
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '.8rem',
    fontWeight: 'bold',
    userSelect: 'text', // Allow text selection in options
  }),
  menu: (provided) => ({
    ...provided,
    userSelect: 'text', // Allow text selection in the menu dropdown as well
  }),
};

export const handleFocus = (event) => {
  // Select the text inside the control when it's focused
  const control = event.target.querySelector('.react-select__single-value');
  console.log(control);

  if (control) {
    console.log(control);

    const range = document.createRange();
    const selection = window.getSelection();
    console.log("range", range);
    console.log("selection", selection);

    range.selectNodeContents(control);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const HistoryFontSize = "0.9rem"

export function roundToNearest10(number) {
  return Math.round(number / 10) * 10;
}

export const formateGivenStringdate = (date_str) => {
  const date_obj = new Date(date_str);
  const formattedDate = `${date_obj.getDate().toString().padStart(2, '0')}-${(date_obj.getMonth() + 1).toString().padStart(2, '0')}-${date_obj.getFullYear()}`;
  return formattedDate
}


export const convertToTitleCase = (text) => {
  const newStr = text?.replaceAll('_', ' ').split(' ')
    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');
  return newStr;
}

export const DeleteEmptyKeys = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    }
  }
  return obj;
}

export const createDayReportPdf = (data_list, element_id) => {

  let table_container = document.getElementById(`${element_id}`)
  while (table_container?.firstChild) {
    table_container.removeChild(table_container.firstChild);
  }
  table_container.setAttribute('style', 'position: absolute; left: -999px')
  let table_headers = document.getElementById('table-to-export').firstChild
  let copiedHeaders = table_headers.cloneNode(true);
  table_container.appendChild(copiedHeaders)
  data_list.map(item => {
    let table_row = document.createElement('tr')
    // key,
    for (const [value] of Object.entries(item)) {
      let td_item_key = document.createElement('td')
      td_item_key.innerText = value ? value : ''
      td_item_key.setAttribute('style', 'border: 1px solid gray')
      table_row.appendChild(td_item_key)
    }
    table_container.appendChild(table_row)
    return item
  })

}


export const SortHierarchySerialNumbers = (serialNumbers) => {
  const compareSerialNumbers = (a, b) => {
    const aParts = a.serialNumber.split('.').map(Number);
    const bParts = b.serialNumber.split('.').map(Number);

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aPart = aParts[i] !== undefined ? aParts[i] : -1;
      const bPart = bParts[i] !== undefined ? bParts[i] : -1;

      if (aPart < bPart) return -1;
      if (aPart > bPart) return 1;
    }
    return 0;
  };

  return serialNumbers?.sort(compareSerialNumbers) || [];
}

export const convertHtmlToText = (htmlString) => {
  const temporaryElement = document.createElement('div');
  temporaryElement.innerHTML = htmlString;
  return temporaryElement.textContent || temporaryElement.innerText || '';
};

export const textEditormodules = {
  toolbar: [
    //   [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    //   [{size: []}],
    //   ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
    { 'indent': '-1' }, { 'indent': '+1' }],
    //   ['link', 'image', 'video'],
    ['clean']
  ],
};


export const gettax = (data, gstRate, state) => {
  if (state === "Tamil Nadu" || state === "Tamilnadu") {
    data['sgst'] = String(gstRate / 2)
    data['cgst'] = String(gstRate / 2)
    data['igst'] = 0
  } else {
    data['sgst'] = 0
    data['cgst'] = 0
    data['igst'] = String(gstRate)
  }
  return data
}

export const DiscountPercentage = (percentage, price) => {

  const percentagevalue = (Number(price) / 100) * Number(percentage)
  const finalAmount = price - percentagevalue
  // console.log(finalAmount);
  return finalAmount
}

export const DiscountAsValue = (discountValue, price) => {

  // const percentagevalue = (Number(price) / 100) * Number(percentage)
  const finalAmount = Number(price) - Number(discountValue)
  console.log(finalAmount);

  return finalAmount
}

export const calculateTax = taxList => {
  const taxResult = {}
  let taxValues = 0

  Object.keys(taxList).forEach(key => {
    const values = taxList[key]
    const sum = values.reduce((acc, value) => acc + parseFloat(value), 0)
    const calculatedValue = (sum / 100) * parseFloat(key)
    taxValues += Number(calculatedValue.toFixed(2))
    taxResult[key] = calculatedValue.toFixed(2)
  })
  return { taxResult, taxValues }
}

export const addSno = (items) => {
  // Check if items is an array
  if (!Array.isArray(items)) return [];

  // Create a new array with modified items
  const modifiedItems = items.map((element, index) => {
    // Create a copy of the element with the new sNo property
    return { ...element, sNo: index + 1 };
  });

  return modifiedItems; // Return the new array
};

// retun null ; using for copy the value in ag grid
export function onCellDoubleClicked(params) {
  // Extract the value of the cell that was double-clicked
  const value = params.value;

  // Copy the value to the clipboard
  if (navigator.clipboard) {
    navigator.clipboard.writeText(value)
    showErrorToast(true, "success", "", "selected")

  }
}


// retun list; pass states list
export function getStates(Optionvalue) {
  const regionsList = countries_list.find(
    Country => Country.countryName === Optionvalue
  )?.regions


  return regionsList?.map((item) => ({ value: item.name, label: item.name }))
}

// retun list; pass states list
export function rateConversionRupeesToOtherCurrency(rate, conversionRate) {
  return (rate * conversionRate)
}



export function createPDFReport(element, name) {


  if (!element || !(element instanceof HTMLElement)) {
    console.error("Invalid element:", element);
    return;
  }

  const options = {
    margin: 0,
    filename: `${name}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
    jsPDF: { unit: 'cm', format: 'a4', orientation: 'landscape' },
  };

  html2pdf()
    .from(element)
    .set(options)
    .save()
    .then(() => {
      console.log("PDF generated successfully.");
    })
    .catch((err) => {
      console.error("PDF generation failed:", err);
      alert(`PDF generation failed: ${err.message}`);
    });
}

export function handleExportExcel(table) {
  // Get the table element


  // Create a workbook and a worksheet
  const wb = XLSX.utils.table_to_book(table);
  const excelFile = `lead.xlsx`;

  // Write the file
  XLSX.writeFile(wb, excelFile);
};


// const handleExportPDF = () => {
//   handlePdfReportDownload('Lead Report');
// };




// utils.js




// return Objects; depend on unit change the qty,
//  main unit to alter unit and alter unit to main unit
//  must Option maintain As {value: uom?.addtionalUnit?.id, label: uom?.addtionalUnit?.name, cf:uom?.conversionFactor}
export const UnitConversion = (prveSelect, CurrentOption, qty) => {
  // console.log(prveSelect, CurrentOption, qty);


  if (!prveSelect?.cf && CurrentOption?.cf) {
    console.log("main to alter");

  } else if (prveSelect?.cf && !CurrentOption?.cf) {
    console.log("alter to main ");
  } else if (prveSelect?.cf && CurrentOption?.cf) {
    console.log("alter to alter ");
  }
}
// return obj ; get rate then cal the Overall Discount Value
export const commanOverallDiscountValue = (itemList, otherincomeChargesList, totalToSubtract, totalValue) => {
  let total_checked_value = totalValue
  let otherIncomeCharge = [];
  let itemDetails = itemList?.map((item) => {
    let reduction = (item?.rate / total_checked_value) * totalToSubtract
    let discountRate = (item?.rate - reduction).toFixed(2)
    let discountValue = reduction.toFixed(2)
    return { index: item?.index, discountRate: discountRate, discountValue: discountValue }
  })
  if (otherincomeChargesList?.length > 0) {
    otherIncomeCharge = otherincomeChargesList?.map((item) => {
      let reduction_other_income = (item?.rate / total_checked_value) * totalToSubtract
      let discountValue = reduction_other_income?.toFixed(2)
      let discountRate = (item?.rate - reduction_other_income)?.toFixed(2)
      return { index: item?.index, discountRate: discountRate, discountValue: discountValue }
    })
  }
  return { itemDetails: itemDetails, otherIncomeCharge: otherIncomeCharge }
}
// return obj ; get rate then cal the Final Discount Value
export const CommanFinalDiscountValue = (itemList, otherIncomeChargesList, final_total, current_total) => {
  let rounded_final_total = final_total
  let total_checked_value = current_total
  let otherIncomeCharge = []
  let ratios = itemList?.map((item) => {
    return item?.rate / total_checked_value
  })
  let reductions = ratios?.map((item) => {
    return item * (total_checked_value - rounded_final_total)
  })
  let itemDetails = itemList?.map((item, index) => {
    let discountRate = (item?.rate - reductions[index]).toFixed(2)
    let discountValue = reductions[index].toFixed(2)
    return { index: item?.index, discountRate: discountRate, discountValue: discountValue }
  })
  if (otherIncomeChargesList?.length > 0) {
    otherIncomeCharge = otherIncomeChargesList?.map((item) => {
      let reductions = (item?.rate / total_checked_value) * (total_checked_value - rounded_final_total)
      let discountRate = item?.rate - reductions
      return { index: item?.index, discountRate: discountRate, discountValue: reductions }
    })
  }
  return { itemDetails: itemDetails, otherIncomeCharge: otherIncomeCharge }
}

export const commanPercentageDiscount = (itemList, otherIncomeChargesList, percentage) => {
  let otherIncomeCharges = []
  let itemDetails = itemList?.map((item) => {
    let percentageValue = (item.rate / 100) * (percentage)
    let discountRate = (item?.rate - percentageValue)
    let discountValue = percentage
    return { index: item?.index, discountRate: discountRate, discountValue: discountValue }
  })
  if (otherIncomeChargesList?.length > 0) {
    otherIncomeCharges = otherIncomeChargesList?.map((item) => {
      let percentageValue = (item.rate / 100) * (percentage)
      let discountRate = (item?.rate - percentageValue)
      let discountValue = percentage
      return { index: item?.index, discountRate: discountRate, discountValue: discountValue }
    })


  }
  return { itemDetails: itemDetails, otherIncomeCharge: otherIncomeCharges }
}

export const formatToRupees = (value) => {
  if (!value) return '';

  // Allow only numeric characters and at most one decimal point
  const numericValue = value.replace(/[^0-9.]/g, '');

  // Prevent multiple decimal points
  const parts = numericValue.split('.');
  if (parts.length > 2) {
    return parts[0]; // Keep only the part before the first decimal
  }

  // Format the integer part of the number in Indian Rupees format
  let integerPart = parts[0];

  // Handle numbers less than 1000 (no commas needed)
  if (integerPart.length <= 3) {
    return integerPart;
  }

  // For numbers greater than or equal to 1000, insert commas
  const lastThree = integerPart.slice(-3); // Get last three digits
  const otherParts = integerPart.slice(0, integerPart.length - 3); // Get the rest
  const formattedInteger = otherParts.replace(/\B(?=(\d{2})+(?!\d))/g, ','); // Insert commas every 2 digits
  integerPart = `${formattedInteger},${lastThree}`;

  // Return the formatted number with the decimal part (if present)
  return parts.length === 2 ? `${integerPart}.${parts[1]}` : integerPart;
};

export const RupessTONormalFormat = (value) => {
  if (!value) return '';

  // If value contains commas, remove them
  const valueWithoutCommas = value.includes(',') ? value.replace(/,/g, '') : value;

  // Check if the value is a valid number, and if so, return it
  if (!isNaN(valueWithoutCommas)) {
    return valueWithoutCommas;
  }

  return '';
};

export const infoMessage = (value) => {
  return (
    <OverlayTrigger
      placement="top" // Tooltip placement
      overlay={
        <Tooltip style={{ maxWidth: '150px', padding: '5px 10px', borderRadius: '8px', fontSize: '5rem' }}>
          {value}
        </Tooltip>
      }
    >
      <span style={{ cursor: 'pointer' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-info-circle"
          viewBox="0 0 16 16"
          style={{
            marginLeft: '2.1px',
            width: '11px',
            height: '20px',
            marginTop: '-9.8px',
            color: '#031325'
          }}
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
        </svg>
      </span>
    </OverlayTrigger>
  );
}

export const checkNegativeNumber = (object, keys) => {
  // Iterate through the keys and check if any value is negative
  for (const key of keys) {
    const value = parseFloat(object[key]); // Convert value to a number
    if (!isNaN(value) && value < 0) {
      return true; // Return false if any value is negative
    }
  }
  return false; // Return true if all values are non-negative
};


export const displayRupeeFormat = (amount, label) => {

  if (isNaN(amount) || amount === null || amount === undefined) return "0.00";

  // Convert amount to a number if it's in string format
  const numericAmount = Number(amount);

  // Currency mapping with locales
  const currencyMap = {
    Rupee: { code: "INR", locale: "en-IN" },
    USD: { code: "USD", locale: "en-US" },
    Euro: { code: "EUR", locale: "de-DE" }, // Example: Germany for Euros
    Yen: { code: "JPY", locale: "ja-JP" },
  };

  const { code, locale } = currencyMap[label] || currencyMap["Rupee"]; // Default to INR if label is invalid

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: code,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numericAmount);
};

export const overAlldiscountCalculationToDisplay = (commanDiscount, netAmount, itemDetailsData, otherincomedata) => {
  console.log("commanDiscount", commanDiscount);
  console.log("netAmount", netAmount);
  if (commanDiscount?.discountPercentage > 0) {
    // Apply percentage discount
    return (netAmount * commanDiscount?.discountPercentage) / 100
  } else if (commanDiscount?.discountValue > 0) {

    let totalDiscount = 0
    if (itemDetailsData) {
      itemDetailsData?.forEach((item) => {
        console.log("item?.discountValue", item?.finalValue);

        let finalValue = Number(item?.discountValue)
        let totalfinalValue = Number(finalValue) * Number(item?.po_qty)
        totalDiscount += totalfinalValue
      })
    }
    if (otherincomedata) {
      otherincomedata?.forEach((item) => {
        console.log("item?.discountValue", item?.discountValue);

        let finalValue = Number(item?.discountValue)
        let totalfinalValue = Number(finalValue)
        totalDiscount += totalfinalValue
      })
    }

    return (totalDiscount).toFixed(2)
  } else if (commanDiscount?.discountTotalValue > 0) {
    let totalDiscount = 0
    itemDetailsData?.forEach((item) => {
      console.log("item?.discountValue", item?.finalValue);

      let finalValue = item?.finalValue
      let totalfinalValue = Number(finalValue) * Number(item?.po_qty)
      totalDiscount += totalfinalValue
    })
    console.log(totalDiscount);

    return (totalDiscount).toFixed(2)
  }
  return 0.00;
};


export const individualDiscountCalculation = (discountValue, itemDetails) => {
  if (discountValue?.isDiscountApplied === false) {
    let totalDiscount = 0;
    // Handle individual item discounts
    itemDetails?.forEach(item => {
      if (item?.discountPercentage > 0) {
        totalDiscount += (item?.rate * item?.po_qty) * (parseFloat(item?.discountPercentage) / 100);
        console.log("if  totalDiscount", totalDiscount);

      } else if (item?.discountValue > 0) {
        // Apply fixed value discount
        totalDiscount += parseFloat(item?.discountValue * item?.po_qty);
        console.log("else totalDiscount", totalDiscount);
      }
    });
    console.log("totalDiscount", totalDiscount);

    return totalDiscount > 0 ? totalDiscount : 0;
  }
}
