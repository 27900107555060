import AddRawMaterials from "./AddRawMaterials";
import CustomTableContainer from "../CustomTableContainer";
import React, { useState, useEffect} from 'react' 
import { DeleteRawMaterialQuery, RawMaterialFetch } from "../../../GraphQLQueries/BomMutation";
import axiosInstance from "../../../api/axoiss";
import ChildBom from "./ChildBom";
import BomDeleteConfirm from "../BomDeleteConfirm";


const RawMaterials = ({handleRawMaterialsData, isEditView, data, handleDeteteRawMaterialsData, GetTotalRawMaterialCount, isNew, isRmLoad, fgItems}) => {
    const [rawMaterialsData, setRawMaterialsData] = useState([])
    const [isEditViewMode, setEditListViewMode] = useState(false)
    const [rowIndex, setRowIndex] = useState(null);
    const [currentEditingData, setCurrentEditingData] = useState(null);
    const [isShowAddRawMaterials, setShowAddRawMaterials] = useState(false);
    const [isShowChildBomComponent, setShowChildBomComponent] = useState(false);
    const [childBomRMIndex, setChildBomRMIndex] = useState(null);
    const [isShowDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteData, setDeleteData] = useState(null);
    const [currentRmData, setCurrentRmData] = useState(null);

    // table columns
    const raw_materials_column_details = [
        {
            header : "Part Code", 
            fieldname : 'part_code', 
            object : "label",
            width: 'col-1',
         },
        {
            header : "Part Name", 
            fieldname : 'part_name',
            object : "label",
            width: 'col-3',
         },
        {
            header : "Category", 
            fieldname :'category',
            width: 'col-1'
        },
        {
            header : "Qty", 
            fieldname :'qty',
            width: 'col-1'
        },
        {
            header : "Unit", 
            fieldname :'uom',
            object : "label",
            width: 'col-1'
        },
        {
            header : "Fixed", 
            fieldname :'fixed',
            width: 'col-1'
        },
        {
            header : "Store", 
            fieldname :'store',
            width: 'col-1',
            object : "label"
        },
        {
            header : "Item Cost", 
            fieldname :'itemCost',
            width: 'col-1',
        }
    ]
    
    const handleCloseRawMaterials = () => {
        setCurrentEditingData(null)
        setShowAddRawMaterials(false)
    }

    const handleShowRawMaterials = () => {
        setShowAddRawMaterials(true)
    }

    const handleAddedData = (data) => {
        const existingIndex = rawMaterialsData.findIndex(item => item.id === data.id);
    
        if (existingIndex !== -1) {
            const updatedData = rawMaterialsData.map(item =>
                item.id === data.id ? { ...item, ...data } : item
            );
            let total_raw_material = updatedData.reduce((acc, curr) => acc + parseInt(curr.qty), 0);
            setRawMaterialsData(updatedData);
            setCurrentEditingData(null);
            GetTotalRawMaterialCount(total_raw_material);
        } else {
            let raw_materials = [...rawMaterialsData, data]
            let total_raw_material = raw_materials.reduce((acc, curr) => acc + parseInt(curr.qty), 0);
            setRawMaterialsData(prevData => [...prevData, data]);
            handleRawMaterialsData(data.id, data);
            setCurrentEditingData(null);
            GetTotalRawMaterialCount(total_raw_material);
        }
        
    };

    const handleEditTableRow = (data) => {
        setRowIndex(data)
        setCurrentEditingData(rawMaterialsData[data])
        setShowAddRawMaterials(true)
    }

    const handleDeleteTableRow = (data) => {
        setDeleteData(data)
        setShowDeleteConfirm(true);
    }

    const ProceedToDelete = (option) => {
        if(option){
            setRowIndex(deleteData);
            let rm_delete_query = DeleteRawMaterialQuery(rawMaterialsData[deleteData]?.id);
            axiosInstance.post("/itemmaster/graphql", {query: rm_delete_query})
            .then(response => {
                const data = response?.data?.data?.rawMaterialDeleteMutation?.success
                if(data){
                    let rm_list = [...rawMaterialsData]
                    rm_list = rm_list.filter(item=> item.id !== rawMaterialsData[deleteData]?.id)
                    let total_raw_material = rm_list.reduce((acc, curr) => acc + parseInt(curr.qty), 0);
                    GetTotalRawMaterialCount(total_raw_material)
                    setRawMaterialsData(prev_data => prev_data.filter(item=> item.id !== rawMaterialsData[deleteData]?.id))
                    handleDeteteRawMaterialsData(rawMaterialsData[deleteData]?.id)
                }
            })
            .catch(error => {
                console.log(error)
            });
        }
    }

    const handleShowChildBomComponent = (index) => {
        setChildBomRMIndex(rawMaterialsData?.[index]?.id);
        setCurrentRmData(rawMaterialsData?.[index])
        setShowChildBomComponent(true);
    }

    const handleCloseChildBomComponent = () => {
        setShowChildBomComponent(false)
    }

    useEffect(()=>{
        if(data){
            let rm_fetch_query = RawMaterialFetch(data);
            axiosInstance.post("/itemmaster/graphql", {query: rm_fetch_query})
            .then(res => {
                let response = res?.data?.data?.rawMaterials?.items
                let rm_data_list = response?.map(item => {
                    let updated_data = {...item}
                    updated_data['part_code'] = { label: updated_data?.['partNo']?.['itemPartCode'], value: updated_data?.['partNo']?.['id']}
                    updated_data['part_name'] = { label: updated_data?.['partNo']?.['itemName'], value: updated_data?.['partNo']?.['id']}
                    updated_data['uom'] = { label: updated_data?.['unit']?.['name'], value: updated_data?.['unit']?.['id']}
                    updated_data['store'] = { label: updated_data?.['store']?.['storeName'], value: updated_data?.['store']?.['id']};
                    updated_data['item_cost'] = updated_data['itemCost']
                    updated_data['fixed'] = updated_data['fixed'] ? 'Yes': 'No'
                    updated_data['per_unit_cost'] = updated_data?.['partNo']?.['itemCost']
                    return updated_data;
                });
                setRawMaterialsData(rm_data_list)
            }).catch(err => {
                console.log(err);
            })
        }
        if(isNew){
            setRawMaterialsData([])
        }
        if(isEditView === true || isEditView === undefined){
            setEditListViewMode(true)
        }else{
            setEditListViewMode(false)
        }
    }, [isEditView, data, isRmLoad])

    return(
        <>
            <div className='row'>
                <div className='col-3'>
                    <h6>Raw Materials</h6>
                </div>
                <div className='col-7'></div>
                <div className='col-2 text-end'>    
                    {isEditViewMode && 
                    <button
                        type='button'
                        onClick = {()=>{handleShowRawMaterials()}}
                        className='fw-bold btn btn-sm btn-outline-primary'
                    >
                        <i className='bx bxs-plus-circle'></i> New
                    </button>}
                </div>
            </div>

            { rawMaterialsData.length > 0 && <div className="py-3 fw-bold" style={{fontSize: '.7rem'}}>
                <CustomTableContainer 
                    isEditViewMode = {isEditViewMode}
                    headers = {raw_materials_column_details}
                    data = {rawMaterialsData}
                    handleEditTableRow = {(d)=>{handleEditTableRow(d)}}
                    handleDeleteTableRow = {(d)=>{handleDeleteTableRow(d)}}
                    isRawMaterial = {true}
                    handleShowChildBomComponent = {handleShowChildBomComponent}
                />
            </div>}

            {isShowAddRawMaterials && <AddRawMaterials 
                isEditViewMode = {isEditViewMode}
                handleCloseRawMaterials = {handleCloseRawMaterials}
                isShowAddRawMaterials = {isShowAddRawMaterials}
                handleAddedData = {(d) => {handleAddedData(d)}}
                data = {currentEditingData}
                existingData = {rawMaterialsData}
                fgItems = {fgItems}
            />}

            {
                isShowChildBomComponent && 
                    <ChildBom 
                        show = {isShowChildBomComponent}
                        close = {handleCloseChildBomComponent}
                        rawMaterialId = {childBomRMIndex}
                        data = {currentRmData}
                        isEditViewMode = {isEditViewMode}
                    />
            }

            {isShowDeleteConfirm && 
                <BomDeleteConfirm 
                    show = {isShowDeleteConfirm}
                    close = {()=>{setShowDeleteConfirm(!isShowDeleteConfirm)}}
                    handleDeleteData = {ProceedToDelete}
                />
            }
        </>
    )
}

export default RawMaterials