import React,{useState, useEffect, useCallback, useRef, useContext} from 'react'
import { Modal } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { 
    ItemComboBomFetch, 
    ProductionOrderItemComboFetchQuery, 
    StoreOptionsFetchQuery
} from '../../../GraphQLQueries/ProductionOrderQuery';
import axiosInstance from "../../../api/axoiss";
import { ToastContainer, toast } from 'react-toastify';
import '../../../Style/production_combo_select.css'
import {  HandleComboChild} from '../../../utils/production_order_util';
import DataContext from '../../../context/ItemMasterContext';


const WarningBeforeProceed = ({show, close, handleResponse}) => {
    return (
        <>
            <Modal
                show={show}
                onHide={close}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title 
                    >Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Do you want to proceed?</h5>
                    <p className='text-muted'>If you proceed, you can't make changes any more.</p>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <button type="button" className="btn btn-outline-danger btn-sm px-3 fw-bold" 
                        onClick={()=>{handleResponse(false); close()}}>No</button>
                    <button type="button" className="btn btn-outline-success btn-sm px-3 fw-bold" 
                        onClick={()=>{handleResponse(true); close()}}>Yes</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


const PoItemComboSelect = ({show, close, data, poItem, handleAddedRawMaterials, handleAddedSubProductionsForCombo, department, isMultiLevelManufacture, qty}) => {
    const gridRef = useRef();
    const gridRefChild = useRef();
    const {userId} =   useContext(DataContext)
    const [post, setPost] = useState([]);
    const [itemCombo, setItemCombo] = useState(null);
    const [selectedItemCombo, setSelectedItemCombo] = useState(null);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [isComboSelected, setComboSelected] = useState(false);
    const [showSubProduction, setShowSubProduction] = useState(false);
    const [comboChildItems, setComboChildItems] = useState([]);
    const [selectedComboChild, setSelectedComboChild] = useState([]);
    const [masterQty, setMasterQty] = useState(1);
    const rowStyle = { background: 'white' };

    const defaultColDef ={ 
        resizable: true,
    }

    const colums = [
        {
            headerName : "ID",
            valueGetter: "node.rowIndex + 1",
            flex: 1, 
            headerCheckboxSelection: params => !isComboSelected,
            checkboxSelection: params => !isComboSelected,  
            showDisabledCheckboxes: true,      
        },
        {
            headerName : "Part Code",
            field:"partNumber.itemPartCode",  
            flex:2,
        },
        {
            headerName : "Part Name",
            flex: 4,
            field:"partNumber.itemName"
        },
        {
            headerName : "Qty",
            field:"itemQty",  
            flex: 1,
            valueGetter: params => parseFloat(params.data.itemQty) * parseFloat(masterQty || 1),
            cellStyle: params => {
                const itemQty = parseFloat(params.value);
                const totalStock = parseFloat(params.data.totalStock);
        
                if (totalStock >= itemQty) {
                    return { color: 'green' };
                } else {
                    return { color: 'red' };
                }
            }
        },
        {
            headerName : "Stock",
            field:"totalStock",  
            flex: 1,
        },
        {
            headerName : "Is Mandatory",
            field:"isMandatory",  
            flex:2
        },
        {
            headerName : "BOM",
            field: "selectedBom",  
            flex: 3,
            editable: !isComboSelected,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: params => {
                return {
                    values: params.data.bomOptions
                };
            },
        },
        {
            headerName : "Store Select",
            field: "selectedStore",  
            flex: 2,
            editable: !isComboSelected,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: params => {
                return {
                    values: params.data.storeOptions
                };
            },
            cellStyle: params => {
                if (params.value === "-- Select Store --") {
                    return { borderColor: 'red' };
                } else {
                    return { borderColor: '' };
                }
            }
        }
    ]

    const comboChildColumns = [
        {
            headerName : "ID",
            valueGetter: "node.rowIndex + 1",
            flex: 1, 
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,   
        },
        {
            headerName : "Part Code",
            field:"partNumber.itemPartCode",  
            flex: 2,
        },
        {
            headerName : "Part Name",
            flex: 5,
            field:"partNumber.itemName"
        },
        {
            headerName : "Qty",
            field:"itemQty",  
            valueGetter: params => parseFloat(params.data.itemQty) * parseFloat(masterQty || 1),
            flex: 1,
            // editable: true,
            cellStyle: params => {
                const itemQty = parseFloat(params.value);
                const totalStock = parseFloat(params.data.totalStock);
        
                if (totalStock >= itemQty) {
                    return { color: 'green' };
                } else {
                    return { color: 'red' };
                }
            }
        },
        {
            headerName : "Stock",
            field:"totalStock",  
            flex: 1
        },
        {
            headerName : "BOM",
            field: "selectedBom",  
            flex: 5,
            cellStyle: params => {
                if (params.value === "-- Select Bom --") {
                    return { borderColor: 'red' };
                } else {
                    return { borderColor: '' };
                }
            }
        } 
    ]


    const handleShowWarningModal = () => {
        setShowWarningModal(true)
    }

    const handleCloseWarningModal = () => {
        setShowWarningModal(false)
    }
    
    const FetchItemComboData = async (id) => {
        let store_data = await axiosInstance.post("/itemmaster/graphql", {query: StoreOptionsFetchQuery()})
        let store_options = store_data?.data?.data?.Store?.items || []
        let item_combo_fetch_query = ProductionOrderItemComboFetchQuery(id);
        axiosInstance.post("/itemmaster/graphql", {query: item_combo_fetch_query})
        .then(response => {
            let response_data = response?.data?.data?.itemMaster?.items?.[0]
            let updated_res_data = response_data?.itemComboData?.map(item => {
                let total_stock = 0
                if(item?.partNumber?.itemstockSet){
                    for (let stock_data of item?.partNumber?.itemstockSet) {
                        total_stock += parseFloat(stock_data.currentStock);
                    }
                }
                let new_res_data = {
                    ...item,
                    'bomOptions': item?.partNumber?.finishedgoodsSet?.map(fg_item => `${fg_item.bomSet[0].bomNo} -- ${fg_item.bomSet[0].bomName} -- ${fg_item.bomSet[0].bomType}`),
                    'totalStock': total_stock,
                }
                
                if(new_res_data['bomOptions'].length > 0){
                    new_res_data['selectedBom'] = new_res_data['bomOptions'].length > 0 ? `${new_res_data['bomOptions'][0]}` : '-- Select Bom --'

                }else{
                    new_res_data['selectedBom'] = null
                    new_res_data['bomOptions'] = []
                    new_res_data['selectedStore'] = '-- Select Store --'
                    new_res_data['storeOptions'] = store_options?.map(item => `${item?.storeName}`)
                    new_res_data['storeOptionsList'] = store_options
                }
                return new_res_data;
            })
            updated_res_data.sort((a, b) => a.sNo - b.sNo);
            setPost(updated_res_data || [])
            response_data.itemComboData.sort((a, b) => a.sNo - b.sNo);
            setItemCombo(response_data);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const onSelectionChanged = useCallback(() => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        if(selectedRows.length > 0){
            setSelectedItemCombo(selectedRows);
        }else{
            setSelectedItemCombo(null);
        }
    }, []);

    const onSelectionChangedChild = useCallback(() => {
        var selectedRows = gridRefChild.current.api.getSelectedRows();
        if(selectedRows.length > 0){
            setSelectedComboChild(selectedRows);
        }else{
            setSelectedComboChild(null);
        }
    }, []);

    const handleFetchBomDataForComboItem = () => {
        let combo_id_list = selectedItemCombo?.map(item => item.selectedBom);
        combo_id_list = combo_id_list?.filter(item => item !== null)

        let store_selected_list = selectedItemCombo?.map(item => item.selectedStore);
        store_selected_list = store_selected_list?.filter(item => item !== null)

        if(combo_id_list && combo_id_list.length > 0 && (combo_id_list.find(item => item === "-- Select Bom --"))){
            toast.error('Please Select All Boms!', 
                {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                } 
            )
        }else if(store_selected_list && store_selected_list.length > 0 && (store_selected_list.find(item => item === "-- Select Store --"))){
            toast.error('Please Select All Store!', 
                {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                } 
            )
        }else{
            setShowSubProduction(true)
            let item_combo_fetch_query = ItemComboBomFetch(combo_id_list, isMultiLevelManufacture);
            // console.log(item_combo_fetch_query)
            axiosInstance.post("/itemmaster/graphql", {query: item_combo_fetch_query})
            .then(response => {
                let item_combo_bom_data = response?.data?.data?.partCodeHasBom?.items || [] ;
                HandleComboChild(item_combo_bom_data)
                .then(com_childs => {
                    setComboSelected(true)
                    setComboChildItems(com_childs)
                })
                .catch(err => {
                    console.log(err)
                })
                
            })
            .catch(error => {
                console.log(error)
            });
        }
    }

    const handleProceedOption = (option) => {
        if(option ){
            if(isMultiLevelManufacture){
                handleFetchBomDataForComboItem()
            }else{
                setComboSelected(true)
                setShowSubProduction(true)
            }
        }
    }

    const handleSubProductionOrders = () => {
        if(selectedItemCombo){
            handleShowWarningModal()
        }else{
            toast.error('No Combo Items Selected!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    useEffect(()=>{
        if(data){
            setMasterQty(qty || 1)
            let main_item_id = data?.id || data?.value
            // console.log(poItem, "data")
            FetchItemComboData(main_item_id);
        }
    }, [data, poItem, qty])

    const handleSubProductionSubmit = () => {
        gridRef.current.api.stopEditing();
        if(gridRefChild?.current){
            gridRefChild.current.api.stopEditing();
        }
        let parent_child_related = {}
        selectedComboChild?.forEach(child_item => {
            let parent = child_item.parent;        
            if (parent_child_related[parent]) {
                parent_child_related[parent].push(child_item);
            } else {
                parent_child_related[parent] = [child_item];
            }
        });
        try {
            let post_body_content = {
                "created_by": userId,
                "combo": selectedItemCombo,
                "child": parent_child_related,
                "department": department?.value,
                "is_multi": isMultiLevelManufacture,
                "po_item": poItem?.id
            }
            // console.log(post_body_content, "post_body_content")
            axiosInstance.post('/itemmaster/combo-items-creation', {data: post_body_content})
            .then(response => {
                handleAddedRawMaterials(response?.data?.data?.raw_material || [])
                handleAddedSubProductionsForCombo(response?.data?.data?.sub_production || [])
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.error('Error fetching suppliers:', error);
        }
        close()
    }

    return (
        <>
            <ToastContainer />
            <Modal 
                show={show} onHide={close}
                fullscreen = {true}
                dialogClassName="modal-transparent" backdrop="static"
                >
                <Modal.Body>
                    <div style={{fontSize: '.8rem', width: '90%'}} 
                    className='mx-auto bg-white shadow-lg'>
                        <div className="row mx-auto py-3 border-bottom border-light shadow-sm " style={{width: "100%"}}>
                            <div className="col-5 my-auto">
                                <h4>
                                    <span className='me-3' style={{cursor: 'pointer'}} 
                                    onClick={()=>{
                                        close()
                                    }}>
                                        <i className="fa-solid fa-arrow-left  fs-4"></i>
                                    </span>
                                    Combo Select
                                </h4>
                            </div>
                            <div className='col-5'></div>
                        </div>
                        <div className='mx-2'>
                            {itemCombo && itemCombo?.itemComboBool && <>
                                <div className='ag-theme-alpine' style={{ height: '20rem', width: '100%' }} > 
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={post}
                                        columnDefs={colums}
                                        defaultColDef={defaultColDef} 
                                        rowSelection="multiple"
                                        suppressRowClickSelection={true}          
                                        onSelectionChanged={onSelectionChanged}  
                                        rowStyle ={rowStyle}
                                    />
                                </div>
                            <div>
                                <div className='text-end'>
                                    <button 
                                        disabled = {isComboSelected}
                                        className='btn btn-sm btn-outline-secondary px-3 mt-3'
                                        onClick={()=>{handleSubProductionOrders()}}
                                    >
                                        <i className="fa-solid fa-check"></i>
                                    </button>
                                </div>
                            </div>
                            </>}

                            {showSubProduction && isMultiLevelManufacture && comboChildItems?.length < 1 && <>
                                <p>No child Items Found!</p>
                            </>}
                            {showSubProduction &&  comboChildItems?.length > 0 && <>
                                <div className='mt-5'>
                                    <div className='ag-theme-alpine' style={{ height: '20rem', width: '100%' }} > 
                                        <AgGridReact
                                            ref={gridRefChild}
                                            rowData={comboChildItems}
                                            columnDefs={comboChildColumns}
                                            defaultColDef={defaultColDef} 
                                            rowSelection="multiple"
                                            suppressRowClickSelection={true}          
                                            onSelectionChanged={onSelectionChangedChild}    
                                            rowStyle ={rowStyle}
                                        />
                                    </div>
                                </div>
                            </>}
                            {
                                showSubProduction && 
                                <div>
                                    <div className='text-end mt-3'>
                                        <button 
                                            className='btn btn-sm btn-outline-success px-4 py-1 fw-bold my-3'
                                            onClick={()=>{handleSubProductionSubmit()}}
                                            disabled = {!isComboSelected}
                                        >Save</button>
                                    </div>
                                </div>
                            }
                            
                        </div>
                        {showWarningModal &&  
                        <WarningBeforeProceed 
                            show = {showWarningModal}
                            close = {handleCloseWarningModal}
                            handleResponse = {(e) => {handleProceedOption(e)}}
                        />}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PoItemComboSelect;