export const otherIncomeChargesCreateMutation = data => {
  return `
      mutation MyMutation {
  otherIncomeChargesCreateMutation(${data}) {
    errors
    success 
  }
}`
}

export const otherIncomeChargesDeleteMutation = id => {
  return `
  mutation MyMutation { 
  quotationsOtherIncomeChargesDeleteMutation(id:  ${id}) { 
    errors 
    success 
  } 
} 
    `
}


export const quotationsOtherIncomeChargesCreateMutation=data=>{
  let query= `
  mutation MyMutation { 

  quotationsOtherIncomeChargesCreateMutation(${data}) { 
    errors
    success
    quotationsOtherIncomeCharges {
      id
      amount
      igst
      sgst
      cgst
      createdBy{
        id
      }
      tax
      otherIncomeChargesId{
        id
        name
        hsn{
          gstRates{
            rate
          }
        }
      }
    }
  }
}
  `
  return  query
}