import { useEffect, useState} from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axiosInstance from "../../../api/axoiss";


const MrpBomTable = ({data, isLoad}) => {
    const [rowData, setRowData] = useState([]);
    let columnDefs = [
        {
            headerName:'S.No' , 
            field:'id',  
            flex: 1,
        },
        {
            headerName:'Part Code' , 
            field:'partCode.itemPartCode',  
            flex: 2,
            valueFormatter: (params) => `${params.data.partCode.itemPartCode}`
        },
        {
            headerName:'Part Name' , 
            field:'partCode.itemName',  
            flex: 3,
            valueFormatter: (params) => `${params.data.partCode.itemName}`
        },
        {
            headerName:'Bom' , 
            field:'bom.bom_name',  
            flex: 2,
        },
        {
            headerName:'Fixed' , 
            field:'fixed',  
            flex: 1,
        },
        {
            headerName:'Qty' , 
            field:'qty',  
            flex: 1,
        },
        {
            headerName:'Stock' , 
            flex: 1,
            field: 'stock',
        },
        {
            headerName:'Shortage' , 
            flex: 1,
            valueGetter: params => {
                let shortage_qty = parseFloat(params.data.stock) - parseFloat(params.data.qty);
                if(!shortage_qty){
                    shortage_qty = ''
                }
                return shortage_qty;
            }
        },
        {
            headerName:'Order Qty' , 
            field:'orderQty',  
            flex: 1,
        },
        {
            headerName:'Lead Time' , 
            field:'leadTime',  
            flex: 1,
        },
        {
            headerName:'Source Type' , 
            field:'sourceType',  
            flex: 1,
        },
        {
            headerName:'Supplier', 
            flex: 1,
        },
        {
            headerName:'Cost' , 
            field:'cost',  
            flex: 1,
        },
        {
            headerName:'Action' , 
            flex: 1,
        },
    ]

    const defaultColDef = {
        editable: false, 
        resizable: true,
    }

    useEffect(()=>{
        if(data){
            let bom_data = data?.map(item => item.bom);
            axiosInstance.post("/itemmaster/fetch-material-for-mrp",  { "bom": bom_data?.[0]?.id })
            .then(res => {
                let bom_items = res?.data?.data || []
                setRowData(bom_items);                
            })
            .catch(err =>{ 
                console.log(err, "error")
            })
        }
    }, [isLoad])

    return (
        <>
            <h6>BOM</h6>
            <div style={{width: '100%', height: '25rem'}}>
                <div className="ag-theme-alpine"style={{width: '100%', height: '100%'}}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                    />
                </div>
            </div>
        </>     
    )
}

export default MrpBomTable;