import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { TbHistory } from "react-icons/tb";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import { ToastContainer } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import Can from "../../../components/authorizationComponents/Can";
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import DataContext from '../../../context/ItemMasterContext';
import { TermsConditionsCreateMutations, termsConditionsDeleteMutations } from '../../../GraphQLQueries/HrMutation';
import { ShowErrorNoties, removeEmptyValueInObject } from '../../../utils/Utils';
import { termsConditionsSchema } from '../../../validations/Hr';
import HistoryData from '../../ItemMaster/model/HistoryData';

const TermsConditionsModel = ({ setTermsConditionsEditData, termsConditionsIsShowing, setLoad, setTermsConditionsIsShowing, termsConditionsEditData }) => {
  const { userId, historyIds, setHistoryIds, handltoastDeleteConfomationShow } = useContext(DataContext)
  const [initialTermsConditions, setInitialTermsConditions] = useState({
    id: "",
    name: "",
    tc: "",
    module: "",
    HistoryDetails: "",
    modified_by: "",
    createdBy: ""
  })
  const [formKey, setFormKey] = useState(0);
  const [edit, setEdit] = useState(true)
  const [deleteData, setDeleteData] = useState({ Name: "", id: "" })
  const [termsConditions, setTermsConditions] = useState(null)

  let moduleOptions = [
    { value: "Purchase", label: "Purchase" },
    { value: "Sales", label: "Sales" },
    { value: "Account", label: "Account" },
    { value: "Service", label: "Service" },
    { value: "Production", label: "Production" },
    { value: "HR", label: "HR" },
    { value: "Quotations", label: "Quotations" },
    { value: "Sales_order", label: "Sales_order" }]
  const modules = {
    toolbar: [
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['clean']
    ],
  };

  const [moduleValue, setModuleValue] = useState(null)
  // return null ; function used to submit the data and update the state variables
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    let save_data = {
      id: values?.id ? Number(values?.id) : "",
      name: values?.['name'],
      module: values?.['module'],
      tc: values?.['tc'],
      createdBy: values?.id ? Number(values?.createdBy) : Number(userId),
      modifiedBy: values?.id ? Number(userId) : ""
    }
    let allow_null = ['id', "modifiedBy"]
    let clear_data = removeEmptyValueInObject(save_data, allow_null)
    try {
      const response = await axiosInstance.post("/itemmaster/graphql", { query: TermsConditionsCreateMutations(clear_data) })
      if (response.data.data.termsConditionsCreateMutation.success) {
        resetForm()
        setLoad()
        close()
        showErrorToast(true, 'success', "", "Successfully Saved")
      } else {
        let errorData = ShowErrorNoties(response.data.data.termsConditionsCreateMutation.errors)
        showErrorToast(true, 'error', errorData)
      }

    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
    }
  }
  // return null ; function used to close the module and update the state to its initial value 
  function close(params) {
    setTermsConditionsEditData(null)
    setTermsConditions(null)
    setModuleValue(null)
    setTermsConditionsIsShowing(false)
    setInitialTermsConditions({
      id: "",
      name: "",
      module: "",
      tc: "",
      createdBy: "",
      HistoryDetails: "",
      modified_by: ""
    })
    setEdit(true)
  }
  // return null ,useEffect triggered when termsConditionsEditData and update the state variable 
  useEffect(() => {
    if (termsConditionsEditData !== null) {
      setInitialTermsConditions(prev => ({
        ...prev,
        id: termsConditionsEditData?.id,
        name: termsConditionsEditData?.name,
        tc: termsConditionsEditData?.tc,
        module: termsConditionsEditData?.module,
        createdBy: termsConditionsEditData?.createdBy?.id
      }))
      if (termsConditionsEditData.module) {
        setModuleValue({ value: termsConditionsEditData?.module, label: termsConditionsEditData?.module })
      }
      if (termsConditionsEditData.tc) {
        setTermsConditions(termsConditionsEditData.tc)
      }
      setFormKey(prev => prev + 1)
    }

  }, [termsConditionsEditData])

  // return null ,Function is used to handle the delete item 
  const handledeleteConfomation = (data) => {
    handltoastDeleteConfomationShow();
    setDeleteData({ Name: data?.name, id: data?.id });
  }
  // return null ,Function is used to update states after deleting the item 
  const handleDeletedId = (deleted_id) => {
    if (deleted_id) {
      setTermsConditionsIsShowing(false)
      setEdit(false)
      setLoad()
      close()
    }
  }

  return (
    <>
      <ToastContainer />
      <HistoryData
        historyIds={historyIds}
        setHistoryIds={setHistoryIds} />

      <Modal show={termsConditionsIsShowing} size='lg' onHide={close}>
        <div className='row mx-1'>
          <div className='col-5 m-3' style={{ color: "#5CADA9" }}>
            <Modal.Title>
              <i className='fa-solid fa-arrow-left fa-sm me-2' onClick={close}>
              </i>Terms Conditions
            </Modal.Title>
          </div>
          <div className="col-6 mt-4 text-end" >
            <Can I="Edit" a="Terms_Conditions">
              <i className="fa-solid fa-pen fs-5 text-primary pe-3"
                hidden={!(termsConditionsEditData && edit)}
                onClick={() => { setEdit(!edit) }}  ></i>
            </Can>
            <Can I="Delete" a="Terms_Conditions">
              <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  "
                hidden={!(termsConditionsEditData && edit)}
                onClick={() => { handledeleteConfomation(termsConditionsEditData) }}
              ></i>
            </Can>
            <Can I="Historys" a="Terms_Conditions">
              <TbHistory type="button" className='fs-4 ms-3' data-bs-toggle="offcanvas"
                hidden={!(termsConditionsEditData && edit)}
                data-bs-target="#History"
                onClick={() => { setHistoryIds(termsConditionsEditData?.historyDetails) }}
              />
            </Can>


          </div>
        </div>
        <hr className='split_border'/>
        <Modal.Body>
          <Formik initialValues={initialTermsConditions}
            validationSchema={termsConditionsSchema} onSubmit={handleSubmit} key={formKey}>
            {({ errors, touched, isSubmitting, setFieldValue, values }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-6 staan-input-group">
                      <Field type="text" name="name" id='name' placeholder="Name" disabled={(termsConditionsEditData && edit)} className='w-100 staan-form-input' />
                      <label htmlFor="name" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                        console.log(values);
                      }}>Name</label>
                      {touched.name && errors.name && <small className='text-danger' >{errors.name}</small>}
                    </div>
                    <div className="col-6 staan-input-group">
                      <CustomSelect
                        setSelectEditValue={moduleValue}
                        options={moduleOptions}
                        name="module"
                        isClearable={true}
                        handleOnChange={(option) => {
                          setFieldValue('module', option ? option?.value : null);
                          setModuleValue(option)
                        }}
                        isDisabled={(termsConditionsEditData && edit)}
                      />
                      <label className='staan-form-input-label pt-1 px-1 mx-2'>
                        Module </label>
                      {touched.module && errors.module && <small>{errors.module}</small>}
                    </div>
                    <div className="col-12 staan-input-group"    >
                      <label className='  pt-1 px-1 mx-2'  >Terms Conditions</label>
                      <ReactQuill
                        value={termsConditions}
                        readOnly={termsConditionsEditData && edit}
                        onChange={(e) => {
                          setTermsConditions(e)
                          setFieldValue('tc', e);
                        }}
                        modules={modules} />
                      {touched.tc && errors.tc && <small className='text-danger' >{errors.tc}</small>}
                    </div>
                    <div className="row mt-2">
                      <div className="text-end">
                        <Can allow_list={["Save_Terms_Conditions", "Edit_Terms_Conditions"]}>
                          <button type="button" className="btn btn-outline-danger btn-sm me-2"
                            onClick={close}
                          >Cancel</button>
                        </Can>

                        <button type="submit" className="btn btn-sm btn-outline-success shadow-sm px-3" disabled={isSubmitting} >Save</button>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {deleteData.Name && <DeleteConformation
        handleDeletedId={(id) => { handleDeletedId(id) }}
        deleteData={deleteData}
        fullquery={termsConditionsDeleteMutations(deleteData?.id)}
      />
      }
    </>
  )
}

export default TermsConditionsModel