import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./FilterView.css";
import { Field, Formik } from "formik";
import CustomSelect from "../../../components/ReactSelect/reactSelect";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CustomDatePicker from "../../../Packages/DateSelector/CustomDatePicker";
import { customSelectStyle, formateGivenStringdate, removeEmptyValueInObject, ShowErrorNoties } from "../../../utils/Utils";
import showErrorToast from "../../../components/notifications/react-toastify/toast";
import { ToastContainer } from 'react-toastify';
import DataContext from "../../../context/ItemMasterContext";
import axiosInstance from "../../../api/axoiss";
import Select from "react-select";
import { RxDragHandleDots2 } from "react-icons/rx";
import { RiDeleteBin5Line } from "react-icons/ri";
import { customfiltercreatemutation, tableListQuery } from "../../../GraphQLQueries/customfilterQueryandMutation";

const FilterView = ({ IsFilterShow, setIsFilterShow, headers, url, tableName, filterEditData, isDuplicate }) => {
    const { allUsersList, CommanFetchForAllUser, userId } = useContext(DataContext)
    const [initialFilterView, setinitialFilterView] = useState({
        viewName: '', sortColumn: '', sortOrder: '', filterColumn: ''
    })
    const [filterValues, setFilterValues] = useState({
        viewName: '', selectedSortColumn: '', selectedSortOrder: '', selectedColumn: '', selectedVisibleTo: '', selectedVisibleToUser: [],
        isDefault: '', id: '', createdBy: '', modifiedBy: '', selectedDateField: { value: '', label: '' }
    })
    const [optionList, setoptionList] = useState({
        sortColumn: [], sortOrder: [{ value: 'asc', label: 'Ascending' }, { value: 'des', label: 'Descending' }], columnsToDisplay: [], filterOption: [], filterCondition: [],
        visibleTo: [{ value: 'myself', label: "Myself" }, { value: 'allUser', label: "All User" }, { value: 'selectUser', label: 'Select Users' }],
        visibleToUser: [],
        text: [
            // { value: 'beginsWith', label: 'Begins With' },
            // { value: 'doesnotBeginsWith', label: 'Does Not Begins With' },
            // { value: 'endWith', label: 'End With' },
            // { value: 'doesnotEndsWith', label: 'Does Not Ends With' },
            // { value: 'contains', label: 'Contains' },
            // { value: 'doesNotContains', label: 'Does Not Contains' }
            { value: 'equal', label: 'Equals' },
            { value: 'notEqual', label: 'Not Equals' },
            { value: 'isEmpty', label: 'Is Empty' },
            { value: 'isNotEmpty', label: 'Is Not Empty' },
        ],
        number: [
            { value: 'lessThan', label: 'Less than' },
            { value: 'greaterThan', label: 'Greater than' },
            { value: 'lessThanEqual', label: 'Less than & Equal' },
            { value: 'greaterThanEqual', label: 'Greater than & Equal' },
            // { value: 'between', label: 'Between' },
        ],
        bool: [{ value: "true", label: 'Yes' }, { value: "false", label: 'No' }]
    })
    const [sections, setSections] = useState([
        "View Information",
        "Column To Display",
        "Filter Condition",
        "Share List",
    ]);
    const [activeSection, setActiveSection] = useState('View Information');
    const [selectedColumnList, setSelectedColumnsList] = useState([])
    const [selectedFilter, setselectedFilter] = useState([])
    useEffect(() => {
        if (filterEditData && filterEditData?.id) {
            fetchEditData(filterEditData?.id)
        }

    }, [IsFilterShow, filterEditData]);
    useEffect(() => {
        if (IsFilterShow) {
            setoptionList(prev => ({
                ...prev,
                columnsToDisplay: headers?.map((item, rowIndex) => ({
                    value: item?.header,
                    label: item?.header,
                    field: item?.field,
                    id: `column-${rowIndex}`, // Unique ID
                })),
                sortColumn: headers?.map((item, rowIndex) => ({
                    value: item?.header,
                    label: item?.header,
                    field: item?.field,
                })),
                filterOption: headers?.map((item, rowIndex) => ({
                    value: item?.header,
                    label: item?.header,
                    type: item?.inputType,
                    field: item?.field,
                    hidden: false,
                    isDate: item?.isDate ? item?.isDate : false
                })),
            }))
        }
    }, [IsFilterShow]);

    const handleSectionClick = (section) => {
        setActiveSection(section);
        const sectionElement = document.querySelector(`#${section.replace(/\s+/g, '-')}`);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    const updateStates = (responseData) => {
        const columnToDisplay = JSON.parse(responseData?.coloumnToDisplay);
        const filterConditions = JSON.parse(responseData?.filiterConditions);
        const updatedColumns = columnToDisplay?.map((col, index) => ({
            ...col,
            id: `column-${index}`  // Dynamically generate 'id' based on the index
        }));
        setSelectedColumnsList(updatedColumns)
        const updatedFilter = filterConditions?.map((item, rowIndex) => {
            const updatedItem = {
                ...item,
                index: rowIndex + 1
            };
            // Conditionally include isDate based on type
            if (item.type === 'Date') {
                updatedItem.isDate = item?.isDate ? item?.isDate : false;
            } else {
                delete updatedItem.isDate; // Remove isDate if type is not Date
            }

            return updatedItem;
        });
        setselectedFilter(updatedFilter)
        const selectedDateFieldItem = updatedFilter?.find(item => item.type === 'Date' && item.conditionApplied?.start && item.conditionApplied?.end);
        const selectedDateField = selectedDateFieldItem ? {
            value: `${formateGivenStringdate(selectedDateFieldItem.conditionApplied.start)} to ${formateGivenStringdate(selectedDateFieldItem.conditionApplied.end)}`,
            label: `${formateGivenStringdate(selectedDateFieldItem.conditionApplied.start)} to ${formateGivenStringdate(selectedDateFieldItem.conditionApplied.end)}`,
        } : { value: '', label: '' };

        const selectedSortColumn = headers?.find(col => col.field === responseData.defaultSortColumn);
        setFilterValues(prev => ({
            ...prev,
            id: isDuplicate ? '' : responseData?.id ? responseData?.id : '',
            createdBy: isDuplicate ? '' : responseData?.createdBy ? responseData?.createdBy?.id : '',
            modifiedBy: isDuplicate ? '' : responseData?.modifiedBy ? responseData?.modifiedBy?.id : '',
            viewName: isDuplicate ? '' : responseData?.viewName ? responseData?.viewName : '',
            selectedSortColumn: selectedSortColumn ? { field: selectedSortColumn?.field, value: selectedSortColumn?.header, label: selectedSortColumn?.header } : '',
            selectedSortOrder: responseData?.defaultSortOrder?.includes('-') ? { value: 'desc', label: 'Descending' } : { value: 'asc', label: 'Ascending' },
            selectedVisibleTo: { value: responseData?.visibleTo, label: responseData?.visibleTo },
            selectedVisibleToUser: responseData?.visibleToUser.length > 0 ? responseData?.visibleToUser.map((item) => ({ value: item?.id, label: item?.username })) : [],
            isDefault: responseData?.isDefault,
            selectedDateField: selectedDateField
        }))
    }
    async function fetchEditData(id) {
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: tableListQuery(`id:${id}`) });
            const responseData = response?.data?.data?.EditListViewType?.items?.[0]
            if (responseData) {
                updateStates(responseData)
            } else {
                showErrorToast(true, 'error', 'Error Fetching Filter !!!')
            }
        } catch (error) {

        }
    }
    async function getOptions(e) {
        CommanFetchForAllUser(e)
        setoptionList(prev => ({
            ...prev,
            visibleToUser: allUsersList
        }))
    }
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(selectedColumnList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setSelectedColumnsList(items);
    };
    const handleGetSelectedDate = (data, header, index) => {
        // Helper function to format the date in 'YYYY-MM-DD'
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
        // Format start and end date
        let selected_start_date = formatDate(data.startDate);
        let selected_end_date = formatDate(data.endDate);
        setFilterValues(prev => ({
            ...prev,
            selectedDateField: {
                value: `${formateGivenStringdate(data.startDate)}  to  ${formateGivenStringdate(data.endDate)}`,
                label: `${formateGivenStringdate(data.startDate)}  to  ${formateGivenStringdate(data.endDate)}`
            }
        }))

        let date_obj = {
            start: selected_start_date,
            end: selected_end_date,
            key: 'value',
            value: `${selected_start_date} - ${selected_end_date}`
        }
        setselectedFilter((prevFilters) =>
            prevFilters.map((filter) =>
                filter.index === index ? { ...filter, conditionApplied: date_obj } : filter
            )
        );
        closeDatePickerPopup(index)
    }
    const showDatePickerPopup = (field) => {
        let date_range_picker_element = document.getElementById(`date_calender_${field}`)
        date_range_picker_element.style.display = 'block'
    }
    const closeDatePickerPopup = (field) => {
        let date_range_picker_element = document.getElementById(`date_calender_${field}`)
        date_range_picker_element.style.display = 'none'
    }
    const handleAddFilter = () => {
        if (selectedFilter.length >= headers.length) {
            showErrorToast(true, "error", "error")
            return;
        }
        let Index = selectedFilter?.length > 0 ? Number(selectedFilter[selectedFilter?.length - 1]?.index + 1) : 1
        setselectedFilter(prevFilters => [...prevFilters, {
            field: "",
            index: Index,
            label: '',
            type: '',
            value: ''
        }]);
    };
    const handleSelectChange = (currentFilter, previousFilter) => {
        const updatedOption = { ...currentFilter, index: previousFilter?.index, conditionOption: '', conditionApplied: '' };
        setselectedFilter(prevFilters =>
            prevFilters.map(filter =>
                filter.index === previousFilter?.index ? { ...filter, ...updatedOption } : filter
            )
        );
        // const updatedFilterOptions = [...optionList.filterOption];

        // setoptionList(prev => ({
        //     ...prev,
        //     filterOption: prev?.filterOption?.map(item => {
        //         if (item?.field === currentFilter?.field) {
        //             return { ...item, hidden: true };
        //         }
        //         if (item?.field === previousFilter?.field) {
        //             return { ...item, hidden: false };
        //         }
        //         return item;
        //     })
        // }));
    };
    const handleFilterDelete = (filterValue) => {
        // setselectedFilter((currentFilters) => {
        //     const indexToRemove = filterValue.index;
        //     return currentFilters.filter((item, index) => item?.index !== indexToRemove);
        // });
        setselectedFilter((currentFilters) => {
            // Filter out the selected filter based on a property, e.g., 'field'
            const updatedFilters = currentFilters.filter((filter) => filter.index !== filterValue.index);
            // Reset indices starting from the original row index + 1
            return updatedFilters.map((filter, index) => ({
                ...filter,
                index: index + 1 // Assign new indices starting from 1
            }));
        });
        setoptionList(prev => ({
            ...prev,
            filterOption: prev?.filterOption?.map(item => {
                if (item?.field === filterValue?.field) {
                    return { ...item, hidden: false };
                }
                return item;
            })
        }));
    }
    // const handleFetchFilterData = (e, header) => {
    //     let header_field = header?.field
    //     console.log("header_field", header_field);
    //     if (e.trim() !== "") {
    //         let search_term = e.trim().replace(/"/g, '\\"');
    //         let options_data = []
    //         axiosInstance.put(url, {
    //             filterData: { [`${[header_field]}_icontains`]: search_term },
    //             selectedData: [header_field], pageNumber: 1,
    //             pageSize: 200,
    //             distinct: header?.distinct ? [header_field] : []
    //         })
    //             .then((res) => {
    //                 // console.log("res?.data?.results", res?.data?.results);
    //                 res?.data?.results?.map((item) => {
    //                     let option_item_obj = { label: item?.[header_field], value: item?.[header_field] }
    //                     options_data.push(option_item_obj)
    //                 })
    //                 let existing_filter_options = { ...filterFieldOptions }
    //                 existing_filter_options[header_field] = options_data
    //                 console.log(existing_filter_options);
    //                 setFilterFieldOptions(existing_filter_options)
    //                 // res?.data?.results?.map((item) => {
    //                 //     let option_item_obj = { label: item?.[header_field], value: item?.[header_field] }
    //                 //     options_data.push(option_item_obj)
    //                 // })
    //                 // let existing_filter_options = { ...filterFieldOptions }
    //                 // existing_filter_options[header_field] = options_data
    //                 // setFilterFieldOptions(existing_filter_options)
    //             })
    //             .catch((error) => {
    //                 console.log("error", error);
    //             });
    //     }

    // }
    const errorValidation = (filter, column, values) => {
        let errorMessage = "";
        // Validate filterValues fields
        if (!values.viewName) {
            errorMessage += "View Name is required. ";
        }
        if (!values.selectedSortColumn?.value) {
            errorMessage += "Sort Column is required. ";
        }
        if (!values.selectedSortOrder?.value) {
            errorMessage += "Sort Order is required. ";
        }
        // if (values.isDefault === undefined || values.isDefault === "") {
        //     errorMessage += "isDefault is required. ";
        // }
        if (!values.selectedVisibleTo) {
            errorMessage += "Selected Visible To is required. ";
        }

        // Check if selectedVisibleTo is "selectUser" and validate selectedVisibleToUser
        if (values.selectedVisibleTo?.value === "selectUser" && values.selectedVisibleToUser.length === 0) {
            errorMessage += "Selected Visible To User is required. ";
        }
        filter.forEach(f => {
            // Skip validation for conditionApplied if conditionOption is "isNotEmpty" or "isEmpty"
            if (f.conditionOption?.value === "isNotEmpty" || f.conditionOption?.value === "isEmpty") {
                // Only check conditionOption
                if (f.conditionOption === "") {
                    errorMessage += `Filter for ${f.label} requires a valid condition option. `;
                }
            } else if (f.conditionOption?.value === "between") {
                // Check for 'startRange' and 'endRange' if conditionOption is 'between'
                const startRange = f.conditionApplied?.split(',')[0]?.replace('(', '').trim();
                const endRange = f.conditionApplied?.split(',')[1]?.replace(')', '').trim();
                // Ensure both start and end are provided
                if (!startRange || !endRange) {
                    errorMessage += `Filter for ${f.label} requires both start and end range. `;
                }
                // Validate the range format
                if (startRange && endRange) {
                    if (isNaN(startRange) || isNaN(endRange)) {
                        errorMessage += `Filter for ${f.label} requires numeric values for both start and end range. `;
                    } else if (parseFloat(startRange) >= parseFloat(endRange)) {
                        errorMessage += `Filter for ${f.label} requires the start range to be less than the end range. `;
                    }
                }
            } else {
                // Only validate conditionApplied and conditionOption if type is not "Date" or "Bool"
                if ((f.type !== "Date" && f.type !== "Bool") && (f.conditionApplied === "" || f.conditionOption === "")) {
                    errorMessage += `Filter for ${f.label} is incomplete. Please provide both condition and option. `;
                } else if (f.type === "Date" || f.type === "Bool") {
                    if (f.conditionApplied === "") {
                        errorMessage += `Filter for ${f.label} requires a condition. `;
                    }
                }
            }
        });
        // If any errors are found, show error toast
        if (errorMessage) {
            showErrorToast(true, 'error', errorMessage.trim(), null, "bottom-right");
            return false; // Return false to indicate validation failed
        }

        return true; // Return true if no errors
    }
    const handleSubmit = async () => {
        const validationResult = errorValidation(selectedFilter, selectedColumnList, filterValues);

        if (validationResult) {
            const filters_data = selectedFilter.map(({ hidden, index, ...rest }) => rest);
            const column_data = selectedColumnList.map(({ id, ...rest }) => rest);
            const visibleToUser = filterValues?.selectedVisibleToUser.length > 0 ? filterValues?.selectedVisibleToUser.map(item => Number(item.value)) : []
            const filiterConditions = JSON.stringify(filters_data).replace(/"/g, '\\"')
            const coloumnToDisplay = JSON.stringify(column_data).replace(/"/g, '\\"')
            const reqBody = {
                coloumnToDisplay: coloumnToDisplay,
                filiterConditions: filiterConditions,
                defaultSortColumn: filterValues?.selectedSortColumn?.field ? filterValues?.selectedSortColumn?.field : '',
                defaultSortOrder: filterValues?.selectedSortOrder?.value === "des" ? `-${filterValues?.selectedSortColumn?.field}` : `${filterValues?.selectedSortColumn?.field}`,
                id: filterValues?.id ? filterValues?.id : '',
                isDefault: filterValues?.isDefault ? filterValues?.isDefault : false,
                table: tableName ? tableName : '',
                viewName: filterValues?.viewName ? filterValues?.viewName : '',
                visibleTo: filterValues?.selectedVisibleTo?.label ? filterValues?.selectedVisibleTo?.label : '',
                visibleToUser: visibleToUser ? visibleToUser : [],
                createdBy: filterValues?.createdBy ? Number(filterValues?.createdBy) : Number(userId),
                modifiedBy: filterValues?.modifiedBy ? Number(filterValues?.modifiedBy) : Number(userId),
            }

            let allow_null = ['id', "modifiedBy", "visibleToUser"]
            let clearedValue = removeEmptyValueInObject(reqBody, allow_null)
            try {
                const response = await axiosInstance.post(`/itemmaster/graphql`, {
                    query: customfiltercreatemutation(clearedValue)
                })
                const responseData = response?.data?.data?.editListViewCreateMutations
                if (responseData.success) {
                    showErrorToast(true, "success", "", "Filter View Created Successfully...")
                    resetForm()
                }
            }
            catch (error) {
                let errorData = ShowErrorNoties(error)
                showErrorToast(true, 'error', errorData)
            }
        }
    }
    const resetForm = () => {
        setinitialFilterView({
            viewName: '', sortColumn: '', sortOrder: '', filterColumn: ''
        })
        setFilterValues({
            viewName: '', selectedSortColumn: '', selectedSortOrder: '', selectedColumn: '', selectedVisibleTo: '', selectedVisibleToUser: [],
            isDefault: '',
        })
        setoptionList({
            sortColumn: [], sortOrder: [{ value: 'asc', label: 'Ascending' }, { value: 'des', label: 'Descending' }], columnsToDisplay: [], filterOption: [], filterCondition: [],
            visibleTo: [{ value: 'myself', label: "Myself" }, { value: 'allUser', label: "All User" }, { value: 'selectUser', label: 'Select Users' }],
            visibleToUser: [],
            text: [
                // { value: 'beginsWith', label: 'Begins With' },
                // { value: 'doesnotBeginsWith', label: 'Does Not Begins With' },
                // { value: 'endWith', label: 'End With' },
                // { value: 'doesnotEndsWith', label: 'Does Not Ends With' },
                // { value: 'contains', label: 'Contains' },
                // { value: 'doesNotContains', label: 'Does Not Contains' }
                { value: 'equal', label: 'Equals' },
                { value: 'notEqual', label: 'Not Equals' },
                { value: 'isEmpty', label: 'Is Empty' },
                { value: 'isNotEmpty', label: 'Is Not Empty' },
            ],
            number: [
                { value: 'lessThan', label: 'Less than' },
                { value: 'greaterThan', label: 'Greater than' },
                { value: 'lessThanEqual', label: 'Less than & Equal' },
                { value: 'greaterThanEqual', label: 'Greater than & Equal' },
                { value: 'between', label: 'Between' },
            ],
            bool: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }]
        })
        setSelectedColumnsList([])
        setselectedFilter([])
        setIsFilterShow()
    }
    return (
        <>
            {/* <div> */}
            <Modal show={IsFilterShow} size="xl">
                <ToastContainer />
                {/* <Offcanvas show={IsFilterShow} placement={'end'} style={{ width: '800px', marginTop: '70px', marginRight: '10px', height: '90%', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', border: '0.5px solid #c9c2c2', }}> */}
                {/* width: '800px' */}
                <div className='row'>
                    <div className='col-4 m-3'>
                        <h3 className='mx-2 commanModelTitleColor'>
                            <i className='fa-solid fa-arrow-left fa-sm me-2 commanModelTitleColor' onClick={resetForm}></i>
                            Filter View
                        </h3>
                    </div>
                </div>
                <hr className='split_border' />
                <Modal.Body>
                    {/* <Offcanvas.Body> */}
                    <div className="row ">
                        <div className="col-3 mt-3 border-right  filter-section">
                            <h5 onClick={() => { showErrorToast(true, "success", "", "Filter View Created Successfully...") }}>Sections</h5>
                            <ul>
                                {sections?.map((section, index) => (
                                    <li
                                        key={index}
                                        className={activeSection === section ? "active" : ""}
                                        onClick={() => handleSectionClick(section)}
                                    >
                                        {section}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <Formik
                            initialValues={initialFilterView}
                        // validationSchema={{}}
                        // enableReinitialize
                        // key={formKey}
                        // onSubmit={handleSubmit}
                        >{({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            submitForm,
                            isValid,
                            isValidating,
                            isSubmitting
                        }) => {
                            return (
                                <>
                                    <div className="col-9 mt-2 content">
                                        <div className="content-section">
                                            <div id="View-Information" className="list-information">
                                                <h6>View Information</h6>
                                            </div>
                                            <div className="row ml-1">
                                                <div className="col-4 staan-input-group">
                                                    <Field
                                                        type='text'
                                                        name='viewName'
                                                        id='viewName'
                                                        value={filterValues?.viewName}
                                                        className='w-100 staan-form-input'
                                                        onChange={(e) => {
                                                            setFilterValues(prev => ({
                                                                ...prev,
                                                                viewName: e.target.value
                                                            }))
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor='viewName'
                                                        className='staan-form-input-label pt-1 px-1 mx-2'
                                                    >
                                                        View Name
                                                    </label>
                                                </div>
                                                <div className="col-4 staan-input-group">
                                                    <CustomSelect
                                                        setSelectEditValue={filterValues?.selectedSortColumn}
                                                        options={optionList?.sortColumn}
                                                        name='sortColumn'
                                                        id='sortColumn'
                                                        handleOnChange={option => {
                                                            setFilterValues(prev => ({
                                                                ...prev,
                                                                selectedSortColumn: option
                                                            }))
                                                        }}
                                                    />
                                                    <label className='staan-form-input-label pt-1 px-1 mx-2'>
                                                        Default Sort Column
                                                    </label>
                                                    {touched.sortColumn && errors.sortColumn && (<small>{errors.sortColumn}</small>)}
                                                </div>
                                                <div className="col-4 staan-input-group">
                                                    <CustomSelect
                                                        setSelectEditValue={filterValues?.selectedSortOrder}
                                                        options={optionList?.sortOrder}
                                                        name='sortOrder'
                                                        id='sortOrder'
                                                        handleOnChange={option => {
                                                            setFilterValues(prev => ({
                                                                ...prev,
                                                                selectedSortOrder: option
                                                            }))
                                                        }}
                                                    />
                                                    <label className='staan-form-input-label pt-1 px-1 mx-2'>
                                                        Default Sort Order
                                                    </label>
                                                    {touched.sortOrder && errors.sortOrder && (<small>{errors.sortOrder}</small>)}
                                                </div>
                                                <div className="col-6 staan-input-group">
                                                    <div className="form-check form-switch pt-3 pb-1 ml-3">
                                                        <input
                                                            className='form-check-input'
                                                            type="checkbox"
                                                            id="flexSwitchCheckDefault"
                                                            checked={filterValues?.isDefault}
                                                            onChange={(e) => {
                                                                setFilterValues(prevState => ({
                                                                    ...prevState,
                                                                    isDefault: e.target.checked
                                                                }));
                                                            }}

                                                        />
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                                            Is Default
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 content-section">
                                            <div id="Column-To-Display" className="list-information">
                                                <h6>Column To Display</h6>
                                            </div>
                                            <div className="row ml-1">
                                                <div className="col-6 staan-input-group">
                                                    <CustomSelect
                                                        setSelectEditValue={filterValues?.selectedColumn}
                                                        options={optionList?.columnsToDisplay}
                                                        name='columnsToDisplay'
                                                        id='columnsToDisplay'
                                                        handleOnChange={option => {
                                                            setSelectedColumnsList(prevList => {
                                                                // If the column with the same id already exists, update it
                                                                const index = prevList.findIndex(col => col.id === option.id);
                                                                if (index !== -1) {
                                                                    // Update the existing item
                                                                    const updatedList = [...prevList];
                                                                    updatedList[index] = option; // Replace the existing item with the new one
                                                                    return updatedList;
                                                                } else {
                                                                    // If not found, add the new item to the list
                                                                    return [...prevList, option];
                                                                }
                                                            });
                                                            setFilterValues(prev => ({
                                                                ...prev,
                                                                selectedColumn: { value: '', label: '' }
                                                            }))
                                                            setoptionList(prev => ({
                                                                ...prev,
                                                                columnsToDisplay: prev.columnsToDisplay.filter(col => col.id !== option.id)
                                                            }));

                                                        }}
                                                    />
                                                    <label className='staan-form-input-label pt-1 px-1 mx-2'>
                                                        Columns TO Display
                                                    </label>
                                                    {touched.columnsToDisplay && errors.columnsToDisplay && (<small>{errors.columnsToDisplay}</small>)}
                                                </div>
                                            </div>
                                            {
                                                selectedColumnList?.length !== 0 ? (
                                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                                        <Droppable droppableId="columns" direction="horizontal">
                                                            {(provided) => (
                                                                <div
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                    style={{
                                                                        display: "grid",
                                                                        gridTemplateColumns: "repeat(3, 1fr)", // 3 elements per row
                                                                        gap: "5px",
                                                                        padding: "10px",
                                                                    }}
                                                                >
                                                                    {selectedColumnList.map((column, index) => (
                                                                        <Draggable key={column.id} draggableId={column.id} index={index}>
                                                                            {(provided) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    style={{
                                                                                        padding: "5px 2px",
                                                                                        borderRadius: "4px",
                                                                                        backgroundColor: "#e7f3ff",
                                                                                        textAlign: "left",
                                                                                        cursor: "default", // Prevent dragging from the main container
                                                                                        ...provided.draggableProps.style,
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        {...provided.dragHandleProps} // Attach dragging only to the handle
                                                                                        style={{
                                                                                            cursor: "grab",
                                                                                            display: "inline-block",
                                                                                            marginRight: "10px",
                                                                                            verticalAlign: "top", // Align the handle to the top of the container
                                                                                            float: "left",        // Ensure the handle stays to the start (left)
                                                                                        }}
                                                                                    >
                                                                                        <RxDragHandleDots2 />
                                                                                    </span>
                                                                                    {column.label}
                                                                                    <span style={{
                                                                                        cursor: "pointer",
                                                                                        color: "black",
                                                                                        float: 'right',
                                                                                        marginRight: "10px"

                                                                                    }}
                                                                                        onClick={() => {
                                                                                            setSelectedColumnsList(prevList => prevList.filter(c => c.id !== column.id));
                                                                                            setoptionList(prev => ({
                                                                                                ...prev,
                                                                                                columnsToDisplay: [...prev?.columnsToDisplay, column]
                                                                                            }));
                                                                                        }}
                                                                                    >x</span>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                ) : (
                                                    <>
                                                        <p
                                                            style={{
                                                                textAlign: "center",
                                                                height: "5rem",
                                                                fontSize: "14px",
                                                                color: "#666",
                                                                paddingTop: "30px",
                                                            }}
                                                        >
                                                            No Columns Selected
                                                        </p>
                                                    </>
                                                )
                                            }



                                        </div>
                                        <div className="mt-4 content-section" >
                                            <div id="Filter-Condition" className="list-information">
                                                <h6 >Filter Condition</h6>
                                            </div>
                                            <div>
                                                {
                                                    selectedFilter?.length > 0 ? (
                                                        <>
                                                            {selectedFilter.map((filter, rowIndex) => (
                                                                <div className="row" style={{ display: 'flex' }} key={filter?.index}>
                                                                    <div className="col-4 staan-input-group">
                                                                        <CustomSelect
                                                                            setSelectEditValue={{ value: filter?.value ? filter?.value : '', label: filter?.label ? filter?.label : '' }}
                                                                            options={optionList?.filterOption?.filter((option) => {
                                                                                return selectedFilter?.some((data) =>
                                                                                    (data?.index === filter?.index)
                                                                                )
                                                                            })?.filter((option) => {
                                                                                return !selectedFilter?.some((data) =>
                                                                                    (data?.label === option?.label)
                                                                                )
                                                                            })
                                                                            }
                                                                            name='filterColumn'
                                                                            id='filterColumn'
                                                                            handleOnChange={(option) => {
                                                                                handleSelectChange(option, filter);
                                                                            }}
                                                                        />

                                                                        <label className='staan-form-input-label pt-1 px-1 mx-2'>
                                                                            Select Column
                                                                        </label>
                                                                    </div>
                                                                    {filter?.type === 'Date' && (
                                                                        <>
                                                                            <div className="col-6 staan-input-group">
                                                                                <CustomSelect
                                                                                    type="text"
                                                                                    setSelectEditValue={filterValues?.selectedDateField}
                                                                                    id={`date_input_${filter?.index}`}
                                                                                    onFocus={() => { showDatePickerPopup(filter?.index) }}
                                                                                // defaultValue={filter?.type === "Date" && filter['condition'] ? `${filter['condition']?.start} - ${filter['condition']?.end}` : ''}
                                                                                />

                                                                                <label className='staan-form-input-label pt-1 px-1 mx-2' >
                                                                                    Select Date
                                                                                </label>
                                                                                <div
                                                                                    id={`date_calender_${filter?.index}`}
                                                                                    className="border shadow-sm bg-white p-2 mt-2 date-calender"
                                                                                >
                                                                                    <div className="d-flex justify-content-end">
                                                                                        <span className="mx-3" style={{ cursor: 'pointer' }}>
                                                                                            <i className="fa-solid fa-xmark fs-6" onClick={() => { closeDatePickerPopup(filter?.index) }}></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <CustomDatePicker
                                                                                        headerField={filter?.index}
                                                                                        handleGetSelectedDate={(date, field) => { handleGetSelectedDate(date, field, filter?.index) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </>

                                                                    )}
                                                                    {filter?.type === 'text' && (
                                                                        <>
                                                                            <div className="col-3 staan-input-group">
                                                                                <CustomSelect
                                                                                    setSelectEditValue={filter?.conditionOption}
                                                                                    options={optionList?.text}
                                                                                    name='textCondition'
                                                                                    id='textCondition'
                                                                                    handleOnChange={(option) => {
                                                                                        setselectedFilter((prevFilters) =>
                                                                                            prevFilters.map((filter) => {
                                                                                                if (filter.index === rowIndex + 1) {
                                                                                                    const { isDate, ...rest } = filter; // include isDate only in Date 
                                                                                                    return {
                                                                                                        ...rest,
                                                                                                        conditionOption: option,
                                                                                                        conditionApplied: (option.value === "isEmpty" || option.value === "isNotEmpty") ? '' : filter.conditionApplied,
                                                                                                    };
                                                                                                }
                                                                                                return filter;
                                                                                            })
                                                                                        );
                                                                                    }}
                                                                                />

                                                                                <label className='staan-form-input-label pt-1 px-1 mx-2'>
                                                                                    Condition
                                                                                </label>
                                                                            </div>
                                                                            {(filter?.conditionOption?.value !== "isEmpty" && filter?.conditionOption?.value !== "isNotEmpty") && (
                                                                                <>
                                                                                    <div className="col-3 staan-input-group">
                                                                                        <Field
                                                                                            type='text'
                                                                                            name='textConditionApplied'
                                                                                            className='w-100 staan-form-input'
                                                                                            value={filter?.conditionApplied}
                                                                                            onChange={(e) => {
                                                                                                setselectedFilter((prevFilters) =>
                                                                                                    prevFilters.map((filter) =>
                                                                                                        filter.index === rowIndex + 1 ? {
                                                                                                            ...filter,
                                                                                                            conditionApplied: e?.target?.value
                                                                                                        } : filter
                                                                                                    )
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                        <label
                                                                                            htmlFor='textConditionApplied'
                                                                                            className='staan-form-input-label pt-1 px-1 mx-2'
                                                                                        >
                                                                                            Value
                                                                                        </label>
                                                                                        {touched.textConditionApplied && errors.textConditionApplied && <small>{errors.textConditionApplied}</small>}
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                            }
                                                                            {/* <div className="col-3 staan-input-group">
                                                                                    <CustomSelect
                                                                                        handleInputChange={(e) => {
                                                                                            handleFetchFilterData(e, filter)
                                                                                        }}
                                                                                        setSelectEditValue={filter?.conditionApplied}
                                                                                        options={
                                                                                            filterFieldOptions
                                                                                                ? (filterFieldOptions[filter.field] || []).filter((option, index, self) =>
                                                                                                    index === self.findIndex((o) => o.value === option.value)
                                                                                                )
                                                                                                : []
                                                                                        }
                                                                                        name='conditionApplied'
                                                                                        id='conditionApplied'
                                                                                        handleOnChange={(option) => {
                                                                                            setselectedFilter((prevFilters) =>
                                                                                                prevFilters.map((filter) =>
                                                                                                    filter.index === rowIndex + 1 ? { ...filter, conditionApplied: option } : filter
                                                                                                )
                                                                                            );
                                                                                        }}
                                                                                    />

                                                                                    <label className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                                                        console.log(selectedFilter);
                                                                                    }}>
                                                                                        Condition Applied
                                                                                    </label>
                                                                                </div> */}
                                                                        </>
                                                                    )}
                                                                    {filter?.type === 'number' && (
                                                                        <>
                                                                            <div className="col-3 staan-input-group">
                                                                                <CustomSelect
                                                                                    setSelectEditValue={filter?.conditionOption}
                                                                                    options={optionList?.number}
                                                                                    name='numberCondition'
                                                                                    id='numberCondition'
                                                                                    handleOnChange={(option) => {
                                                                                        setselectedFilter((prevFilters) =>
                                                                                            prevFilters.map((filter) => {
                                                                                                if (filter.index === rowIndex + 1) {
                                                                                                    const { isDate, ...rest } = filter; // Include isDate Only in Date Type 
                                                                                                    return {
                                                                                                        ...rest,
                                                                                                        conditionOption: option,
                                                                                                    };
                                                                                                }
                                                                                                return filter;
                                                                                            })
                                                                                        );
                                                                                    }}
                                                                                />

                                                                                <label className='staan-form-input-label pt-1 px-1 mx-2'>
                                                                                    Number Condition
                                                                                </label>
                                                                            </div>
                                                                            {filter?.conditionOption?.value !== "between" && (
                                                                                <div className="col-3 staan-input-group">
                                                                                    <Field
                                                                                        type='number'
                                                                                        name='numberCondition'
                                                                                        value={filter?.conditionApplied}
                                                                                        className='w-100 staan-form-input'
                                                                                        onChange={(e) => {
                                                                                            setselectedFilter((prevFilters) =>
                                                                                                prevFilters.map((filter) =>
                                                                                                    filter.index === rowIndex + 1 ? { ...filter, conditionApplied: e?.target?.value } : filter
                                                                                                )
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor='numberCondition'
                                                                                        className='staan-form-input-label pt-1 px-1 mx-2'
                                                                                    >
                                                                                        Number Condition
                                                                                    </label>
                                                                                    {touched.numberCondition && errors.numberCondition && <small>{errors.numberCondition}</small>}
                                                                                </div>
                                                                            )}
                                                                            {filter?.conditionOption?.value === "between" && (
                                                                                <div className="col-3 staan-input-group">
                                                                                    <div className="row">
                                                                                        <div className="col-6">
                                                                                            <Field
                                                                                                type='number'
                                                                                                name='startRange'
                                                                                                value={filter?.conditionApplied?.split(',')[0]?.replace('(', '') || ''}
                                                                                                className='w-100 staan-form-input'
                                                                                                onChange={(e) => {
                                                                                                    const start = e?.target?.value;
                                                                                                    setselectedFilter((prevFilters) =>
                                                                                                        prevFilters.map((filter) =>
                                                                                                            filter.index === rowIndex + 1
                                                                                                                ? {
                                                                                                                    ...filter,
                                                                                                                    conditionApplied: `(${start},${filter.conditionApplied?.split(',')[1]?.replace(')', '') || ''})`
                                                                                                                }
                                                                                                                : filter
                                                                                                        )
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                            <label
                                                                                                htmlFor='startRange'
                                                                                                className='staan-form-input-label pt-1 px-1 mx-2'
                                                                                            >
                                                                                                Start
                                                                                            </label>
                                                                                        </div>

                                                                                        <div className="col-6">
                                                                                            <Field
                                                                                                type='number'
                                                                                                name='endRange'
                                                                                                value={filter?.conditionApplied?.split(',')[1]?.replace(')', '') || ''}
                                                                                                className='w-100 staan-form-input'
                                                                                                onChange={(e) => {
                                                                                                    const end = e?.target?.value;
                                                                                                    setselectedFilter((prevFilters) =>
                                                                                                        prevFilters.map((filter) =>
                                                                                                            filter.index === rowIndex + 1
                                                                                                                ? {
                                                                                                                    ...filter,
                                                                                                                    conditionApplied: `(${filter.conditionApplied?.split(',')[0]?.replace('(', '') || ''},${end})`
                                                                                                                }
                                                                                                                : filter
                                                                                                        )
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                            <label
                                                                                                htmlFor='endRange'
                                                                                                className='staan-form-input-label pt-1 px-1 mx-2'
                                                                                            >
                                                                                                End
                                                                                            </label>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            )}


                                                                        </>
                                                                    )}
                                                                    {filter?.type === 'Bool' && (
                                                                        <>
                                                                            <div className="col-6 staan-input-group">
                                                                                <CustomSelect
                                                                                    setSelectEditValue={filter?.conditionApplied}
                                                                                    options={optionList?.bool}
                                                                                    name='boolCondition'
                                                                                    id='boolCondition'
                                                                                    handleOnChange={(option) => {
                                                                                        setselectedFilter((prevFilters) =>
                                                                                            prevFilters.map((filter) => {
                                                                                                if (filter.index === rowIndex + 1) {
                                                                                                    const { isDate, ...rest } = filter; // Include isDate Only in Date Type 
                                                                                                    return {
                                                                                                        ...rest,
                                                                                                        conditionApplied: option,
                                                                                                    };
                                                                                                }
                                                                                                return filter;
                                                                                            })
                                                                                        );

                                                                                    }}
                                                                                />

                                                                                <label className='staan-form-input-label pt-1 px-1 mx-2' >
                                                                                    Condition
                                                                                </label>
                                                                            </div>
                                                                            {/* <div className="col-3"></div> */}
                                                                        </>
                                                                    )}
                                                                    <div className="col-1 staan-input-group text-end">
                                                                        <span style={{ cursor: "pointer" }} >
                                                                            <RiDeleteBin5Line className="delete-icon" onClick={() => { handleFilterDelete(filter) }}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>

                                                    ) : (
                                                        <>
                                                            <p className="no-filter">
                                                                No Filter Applied
                                                            </p>
                                                        </>
                                                    )
                                                }

                                            </div>
                                            <div className="mt-4">
                                                <button
                                                    type='button'
                                                    className='btn btn-outline-secondary px-4'
                                                    style={{ fontSize: '14px', fontWeight: 'bold' }}
                                                    onClick={handleAddFilter}
                                                >
                                                    <i className='bx bxs-plus-circle me-2'></i>Add Filter Conditions
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mt-4 content-section">
                                            <div id="Share-List" className="list-information">
                                                <h6 >Share List</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 staan-input-group">
                                                    <CustomSelect
                                                        setSelectEditValue={filterValues?.selectedVisibleTo}
                                                        options={optionList?.visibleTo}
                                                        name='visibleTo'
                                                        id='visibleTo'
                                                        handleOnChange={(option) => {
                                                            if (option?.label !== "Select Users") {
                                                                setFilterValues(prev => ({
                                                                    ...prev,
                                                                    selectedVisibleTo: option,
                                                                    selectedVisibleToUser: []
                                                                }))
                                                            }
                                                            else {
                                                                setFilterValues(prev => ({
                                                                    ...prev,
                                                                    selectedVisibleTo: option,
                                                                }))
                                                            }
                                                        }}
                                                    />

                                                    <label className='staan-form-input-label pt-1 px-1 mx-2'>
                                                        Visible To
                                                    </label>
                                                </div>
                                                {(filterValues?.selectedVisibleTo?.value === 'selectUser' || filterValues?.selectedVisibleTo?.value === 'Select Users') && (
                                                    <div className="col-12 staan-input-group">
                                                        <Select
                                                            onInputChange={(e) => {
                                                                e ? getOptions(e) :
                                                                    setoptionList(prevState => ({
                                                                        ...prevState,
                                                                        visibleToUser: []
                                                                    }))
                                                            }}
                                                            value={filterValues?.selectedVisibleToUser}
                                                            options={optionList?.visibleToUser}
                                                            name='visibleToUser'
                                                            id='visibleToUser'
                                                            onChange={(option) => {
                                                                setFilterValues(prev => ({
                                                                    ...prev,
                                                                    selectedVisibleToUser: option
                                                                }))
                                                            }}
                                                            isMulti={true}
                                                            styles={customSelectStyle}
                                                            className='mt-3'
                                                        />

                                                        <label className='staan-form-input-label pt-1 px-1 mx-2' >
                                                            Visible To Users
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="row">
                                                <div className="col-6">

                                                </div>
                                                <div className="col-6 text-end">
                                                    <button
                                                        type='button'
                                                        style={{ marginRight: '30px' }}
                                                        // style={{ width: '10rem', marginRight: '5px' }}
                                                        onClick={resetForm}
                                                        className='btn btn-outline-danger px-3'
                                                    >
                                                        Clear
                                                    </button>
                                                    <button
                                                        type='button'
                                                        style={{ marginRight: '20px' }}
                                                        // style={{ width: '10rem' }}
                                                        className='btn btn-outline-success px-3'
                                                        onClick={handleSubmit}
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }}
                        </Formik >
                    </div>
                </Modal.Body>
                {/* </Offcanvas.Body> */}
            </Modal>
            {/* </Offcanvas> */}
            {/* </div> */}
        </>
    )
};
export default FilterView;