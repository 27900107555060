import { Route, Routes } from 'react-router-dom';
import CurrencyExchangesList from "../src/Page/CurrencyExchanges/CurrencyExchangesList";
import NumberingSeries from "../src/Page/NumberingSeries/NumberingSeries";
import "./App.css";
import Login from './components/login/Login';
import { DataProvider } from './context/ItemMasterContext';
import { ProductionDataProvider } from "./context/ProductionContext";
import ItemMasterEdit from "./ItemMaster/Page/itemmasterEdit/ItemMasterEdit";
import AccountGroup from "./Page/AccountGroup/AccountGroup";
import AccountMasterlist from "./Page/AccountMaster/AccountMasterlist";
import BOM from "./Page/Bom";
import ConferenceList from "./Page/Conference/index";
import CurrencyMaster from "./Page/CurrencyMaster/CurrencyMaster";
import Department from "./Page/Department";
import EnquiryTable from "./Page/EnquiryData/";
import EnquiryForm from "./Page/EnquiryData/EnquiryForm";
import GrnTable from "./Page/GRN";
import Hsn from "./Page/HSN/Hsn";
import ItemGroup from "./Page/Itemgroup/ItemGroup";
import ItemMaster from "./Page/ItemMaster";
import MRP from "./Page/MRP";
import OtherExpenses from "./Page/OtherExpenses";
import PosPage from "./Page/PosDetails/PosPage";
import PosTable from "./Page/PosDetails/PosTable";
import PosDetailedReport from "./Page/PosDetails/Reports/POsDetailedReport";
import PosReport from "./Page/PosDetails/Reports/PosReport";
import PosStockReport from "./Page/PosDetails/Reports/PosStockReport";
import ProductionOrder from "./Page/ProductionOrder";
import PurchaseTable from "./Page/Purchase";
import InventoryDeletionHandler from "./Page/Stock/InventoryDeletetionHandler";
import InventoryHandler from "./Page/Stock/InventoryHandler";
import StockStatement from "./Page/StockStatement";
import Store from "./Page/Store/Store";
import SupplierList from "./Page/Supplier";
import TermsConditions from "./Page/TermsConditions";
import Unit from "./Page/Uom/Unit";
import "./style.css";
import './Style/main.css';
// import QIR from "./Page/QualityInspectionReport/Model/QIR";
import MaterialRequest from "./Page/MaterialRequest";
import QirTable from "./Page/QualityInspectionReport";
// import POtime from "./components/react-d3-tree/poTimeLine";
// import TreeComponent from "./components/react-d3-tree/poHistory";
// import Dateinput from "./components/Dateinput/Dateinput";
import useInternetChecker from "./components/InterNetChecker/useInternetChecker";
import { Layout } from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import { AuthProvider } from "./context/AuthProviderContext";
import { PreFetchDataProvider } from './context/PreFetchContext';
import ContactList from "./Page/Contact";
import Dashboard from "./Page/Dashboard";
import DeliveryChallan from "./Page/DeliveryChallan/DeliveryChallan";
import Employee from './Page/Employee';
import ExpenseCategories from './Page/ExpenseCategories';
import ExpenseClaim from './Page/ExpenseClaim';
import ExpenseReconciliation from './Page/ExpenseReconciliation';
import ExpenseRequest from './Page/ExpenseRequest';
import ItemMasterlistDup from './Page/ItemMaster/index copy';
import Lead from './Page/Lead/Lead';
import LeadFormEdit from "./Page/Lead/modals/LeadFormEdit";
import OtherIncomeCharges from "./Page/OtherIncomeCharges/index 1";
import PaymentVoucher from './Page/PaymentVoucher';
import Posmodel from './Page/PosDetails/model/posmodel';
import ProfileList from "./Page/Profile";
import PurchaseReturnChallan from "./Page/PurchaseReturnChallan";
import QuatationTable from "./Page/Quotation/index";
import SalesOrderReports from './Page/reportes/salesOrderReports';
import RoleTable from "./Page/Role/Index";
import SalesOrder from './Page/SalesOrder2/Index';
import Unauthorized from "./Page/unauthorized/Unauthorized";
import UserManageMentlist from "./Page/UserManagement";
import ConvertToPdf from './template/quotationPdfTemplate';
import Tree from './template/TreeView';
// import OtherIncomeCharges from "./Page/Quotation/index 1";

function App({ msalInstance }) {
  const handleError = (message) => {
    alert(message); // Show an alert when there's no internet
  };
  useInternetChecker(handleError);
  return (
    <div className="App">

      <DataProvider msalInstance={msalInstance}>
        <PreFetchDataProvider>
          <AuthProvider>
            <ProductionDataProvider msalInstance={msalInstance}>
              <Routes>
                <Route path="/" element={<Layout />}>
                  {/* public router */}
                  <Route path="/" element={<Login />} />
                  <Route path="/unauthorized" element={<Unauthorized />}></Route>
                  <Route path="/dashboard" element={<Dashboard />} />
                  {/* only for staan user */}
                  <Route element={<RequireAuth AllowedPermission={["View_Item_Master"]} />}>
                    <Route path="ItemMaster" element={<ItemMaster />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Item_Master"]} />}>
                    <Route path="ItemMasterdup" element={<ItemMasterlistDup />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["Edit_Item_Master"]} />}>
                    <Route path="/ItemMaster/:id" element={<ItemMasterEdit />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Store"]} />}>
                    <Route path='/store' element={<Store />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_UOM"]} />}>
                    <Route path='/unit' element={<Unit />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Enquiry"]} />}>
                    <Route path='/Enquiry' element={<EnquiryForm />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Lead"]} />}>
                    <Route path='/Lead' element={<Lead />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Lead"]} />}>
                    <Route path='/LeadEdit' element={<LeadFormEdit />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Enquiry"]} />}>
                    <Route path='/EnquiryDetail' element={<EnquiryTable />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Enquiry"]} />}>
                    <Route path='/expense_categories' element={<ExpenseCategories />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Enquiry"]} />}>
                    <Route path='/expense_claim' element={<ExpenseClaim />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Enquiry"]} />}>
                    <Route path='/payment_voucher' element={<PaymentVoucher />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Enquiry"]} />}>
                    <Route path='/expense_reconciliation' element={<ExpenseReconciliation />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Item_Group"]} />}>
                    <Route path='/item_Group' element={<ItemGroup />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Accounts_Master"]} />}>
                    <Route path='/account' element={<AccountMasterlist />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Account_Group"]} />}>
                    <Route path='/account_Group' element={<AccountGroup />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Stock_Statement"]} />}>
                    <Route path='/stock-statement' element={<StockStatement />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_HSN"]} />}>
                    <Route path='/hsn' element={<Hsn />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Currency_Exchange"]} />}>
                    <Route path="/currencyexchangelists" element={<CurrencyExchangesList />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Currency_Master"]} />}>
                    <Route path="/currencymaster" element={<CurrencyMaster />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Numbering_Series"]} />}>
                    <Route path="/numbering-series" element={<NumberingSeries />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Conference"]} />}>
                    <Route path='/conference' element={<ConferenceList />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_POS_Report"]} />}>
                    <Route path="/pos-report" element={<PosReport />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_POS_Collection_Repor"]} />}>
                    <Route path="/pos-collection-wise" element={<PosDetailedReport />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_POS_Detailed_Report"]} />}>
                    <Route path="/pos-detailed-report" element={<PosDetailedReport />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_POS_Stock_Report"]} />}>
                    <Route path="/pos-stock-report" element={<PosStockReport />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Department"]} />}>
                    <Route path="/department" element={<Department />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Terms_Conditions"]} />}>
                    <Route path="/terms-conditions" element={<TermsConditions />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Other_Expenses"]} />}>
                    <Route path="/other-expenses" element={<OtherExpenses />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Roles"]} />}>
                    <Route path="/role" element={<RoleTable />}></Route>
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Contact"]} />}>
                    <Route path="/contact" element={<ContactList />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_User_Management"]} />}>
                    <Route path="/all-user-management" element={<UserManageMentlist />}></Route>
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Profile"]} />}>
                    <Route path="/profile" element={<ProfileList />}></Route>
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Customer"]} />}>
                    <Route path="/customer" element={<SupplierList />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Supplier"]} />}>
                    <Route path="/supplier" element={<SupplierList />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Stock_Addition"]} />}>
                    <Route path="/InventryHandler" element={<InventoryHandler />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Stock_Deletion"]} />}>
                    <Route path="/InventryHandlerDeletion" element={<InventoryDeletionHandler />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_POS"]} />}>
                    <Route path="/posdata" element={<PosTable />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Purchase_Order"]} />}>
                    <Route path="/purchaseOrder" element={<PurchaseTable />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_GIN"]} />}>
                    <Route path="/gin" element={<GrnTable />}></Route>
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_GRN"]} />}>
                    <Route path="/GRN" element={<GrnTable />}></Route>
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Material_Request"]} />}>
                    <Route path="/material-request" element={<MaterialRequest />}></Route>
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_QIR"]} />}>
                    <Route path="/Qir" element={<QirTable />}></Route>
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Delivery_Challan"]} />}>
                    <Route path="/dc" element={<DeliveryChallan />}></Route>
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_PRC"]} />}>
                    <Route path="/purchase-return-challan" element={<PurchaseReturnChallan />}></Route>
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Bom"]} />}>
                    <Route path="/bom" element={<BOM />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_MRP"]} />}>
                    <Route path="/mrp" element={<MRP />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_MRP"]} />}>
                    <Route path="/otherincomecharges" element={<OtherIncomeCharges />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Quotation"]} />}>
                    <Route path="/quotation" element={<QuatationTable />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Quotation"]} />}>
                    <Route path="/convertToPdf" element={<ConvertToPdf />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Quotation"]} />}>
                    <Route path="/Tree" element={<Tree />} />
                  </Route>
                  <Route element={<RequireAuth AllowedPermission={["View_Manufacturing_Order"]} />}>
                    <Route path="/manufacturing-order" element={<ProductionOrder />} />
                  </Route>
                  <Route path="/sales-order" element={<SalesOrder />} />
                  <Route path="/employee" element={<Employee />} />
                  <Route path="expense_request" element={<ExpenseRequest />} />
                  <Route path="sales_order_report" element={<SalesOrderReports />} />

                  <Route path="*" element={<Unauthorized />}></Route>
                </Route>
                <Route path="/pos" element={<PosPage />} />
                <Route path="/pos2" element={<Posmodel />} />
              </Routes>

            </ProductionDataProvider>
          </AuthProvider>
        </PreFetchDataProvider>


      </DataProvider>
    </div>
  );
}

export default App;
