import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import MaxNikLoading from "../../../components/loading/BallTriangle";
import { SideNavbar } from '../../../components/sideNavbar/SideNavbar';
import DataContext from '../../../context/ItemMasterContext';
import { CurrentConferenct, fetchposStockReport } from '../../../GraphQLQueries/ItemmasterQuery';
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { createDayReportPdf, customSelectStyle } from '../../../utils/Utils';
import { handleExcelReportDownload } from './ExcelReportDownload';
import { handlePdfDetailedReportDownload } from './PdfReportDownload';

const PosStockReport = () => {
    const { currentConference, setCurrentUserEvent, currentUserEvent, userId, screenSize } = useContext(DataContext)
    const [marketingEventOptions, setMarketingEventOptions] = useState([])
    const [posReportDataList, setPosReportDataList] = useState([])
    const [Loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const FetchData = useSelector(state => state.itemGroup.data);
    let export_options = [
        { value: 1, label: 'Excel Export' },
        { value: 2, label: 'PDF Export' }
    ]
    let tableHeaders = [
        { header: "S No", field: "" },
        { header: "Part Code", field: "partNumber" },
        { header: "Part Name", field: "partName" },
        { header: "In", field: "stockIn" },
        { header: "Out", field: "stockOut" },
        { header: "Balance", field: "stockBlance" },
    ]
    const dynamicStyle = screenSize?.height < 1000  ? {
        width: `${screenSize.width / 15}rem`,   // Dynamically calculate width
        height: `${screenSize.height / 21}rem`,    // Dynamically calculate height
        overflow: 'auto'                           // Enable scrolling when content overflows
    } : {
        height: '40rem',                           // Default height
        overflow: 'scroll'                         // Default scrolling behavior
    };

    // Initialize total variables
    let totalStockIn = 0;
    let totalStockOut = 0;
    let totalStockBlance = 0;
    const [isShowExportOptionModal, setShowExportOptionModal] = useState(false)
    const handleShowExportOptionModal = () => { setShowExportOptionModal(true) }
    const handleCloseExportOptionModal = () => {
        setShowExportOptionModal(false);
        let parentElement = document.getElementById('pos-table-detailed-report-data');
        while (parentElement?.firstChild) {
            parentElement.removeChild(parentElement.firstChild);
        }
    }
    useEffect(() => {
        if (currentConference) {
            if (currentUserEvent?.value) {
                handluserEvent({ value: currentUserEvent?.value, label: currentUserEvent?.label })
            } else {
                handluserEvent({ value: currentConference?.value, label: currentConference?.lable })
            }
        }
    }, [currentConference])
    const handluserEvent = (option) => {
        if (option) {
            setLoading(true)
            setCurrentUserEvent({ value: option?.value, label: option?.label })
            dispatch(fetchOptionsData(fetchposStockReport(option?.value), "posStockReport"))
        }
        else {
            setCurrentUserEvent({ value: "", label: "" })
            setPosReportDataList([])
        }
    }
    useEffect(() => {
        if (FetchData.name === "posStockReport") {
            setPosReportDataList(FetchData.value)
            setLoading(false)
        } else if (FetchData.name === "CurrentConferenct") {
            let event = FetchData?.value?.items?.map(item => ({ value: item.id, label: item.name }))

            setMarketingEventOptions(event)
        }
    }, [FetchData])
    const getData = () => {
        if (userId) {
            dispatch(fetchOptionsData(CurrentConferenct(userId, false), "CurrentConferenct"))
        }
    }
    const handleClickedExportOption = (option) => {
        let excel_input_data = posReportDataList?.items?.map(report_row_item => {
            let inner_object = {}
            tableHeaders.map(header => {
                inner_object[header.header] = report_row_item[header.field]

            })
            return inner_object
        })
        if (option === 1) {
            handleExcelReportDownload(excel_input_data, 'pos_stock_report')
        } else if (option === 2) {
            createDayReportPdf(excel_input_data, "pos-table-detailed-report-data")
            handlePdfDetailedReportDownload('pos_stock_report')
        }
    }
    useEffect(() => {
        getData()
    }, [userId])

    const DayReportExport = ({ show, close }) => {
        return (
            <Modal
                show={show}
                onHide={close}
                size='sm'
            >
                <Modal.Header closeButton>Export Options</Modal.Header>
                <Modal.Body>
                    <ul style={{ listStyleType: 'none' }}>
                        <li className='my-2' role='button'
                            onClick={() => { handleClickedExportOption(export_options[0].value) }}
                        >
                            <i className="fa-regular fa-file-excel fs-5 me-3"></i> {export_options[0].label}
                        </li>
                        <li className='my-2' role='button'
                            onClick={() => { handleClickedExportOption(export_options[1].value) }}
                        > <i className="fa-regular fa-file-lines fs-5 me-3"></i> {export_options[1].label}
                        </li>
                    </ul>
                </Modal.Body>
            </Modal>
        )
    }
    // Iterate through the items and accumulate values
    posReportDataList &&
        posReportDataList.items &&
        posReportDataList.items.forEach((item, index) => {
            totalStockIn += item.stockIn || 0;
            totalStockOut += item.stockOut || 0;
            totalStockBlance += item.stockBlance || 0;
        });
    const totalRow = {
        partNumber: "Total",
        stockIn: totalStockIn,
        stockOut: totalStockOut,
        stockBlance: totalStockBlance
    };

    // Add total row to posReportDataList
    const updatedPosReportDataList = {
        ...posReportDataList,
        items: posReportDataList.items ? posReportDataList.items.concat(totalRow) : []
    };
    const [columnFilters, setColumnFilters] = useState({
        partNumber: '',
        partName: '',
        stockIn: '',
        stockOut: '',
        stockBlance: ''
    });

    // Filtered data state
    // const [filteredData, setFilteredData] = useState(updatedPosReportDataList.items);
    const handleFilterChange = (field, value) => {
        // Update the column filter state dynamically based on the field
        setColumnFilters(prevFilters => ({
            ...prevFilters,
            [field]: value
        }));
    };

    // Effect hook to filter data when columnFilters change
    const filteredData = useMemo(() => {
        return updatedPosReportDataList.items.filter(item => {
            return Object.keys(columnFilters).every(field => {
                if (!columnFilters[field]) return true;
                return String(item[field] || '').toLowerCase().includes(columnFilters[field].toLowerCase());
            });
        });
    }, [columnFilters, updatedPosReportDataList.items]);
    return (
        <>
            <SideNavbar />
            <div className=' ' style={{ position: "fixed", top: "0", left: "1rem", width: "100%" }}>
                <div className=" my-4 " id="pos-report-data" >
                    <div className='bg-white' style={{ height: '100%' }}>
                        <div className='pos-report-header border-bottom border-solid shadow'>
                            <div className="row">
                                <div className="col-3">
                                    <h3 className='py-3 px-4'>Pos Stock Report</h3>
                                </div>
                                <div className="col-3 staan-input-group" style={{zIndex:100000}}>
                                        <Select
                                            name="marketingEvent"
                                            isClearable
                                            options={marketingEventOptions}
                                            className='mt-3'
                                            styles={customSelectStyle}
                                            value={currentUserEvent}
                                            onChange={(option) => { handluserEvent(option) }}
                                        />
                                        <label htmlFor="marketingEvent" className="staan-form-input-label pt-1 px-1 mx-2">Conference Name</label>
                                </div>
                                <div className="col-4 row align-self-center text-end">
                                    <span>
                                        <i
                                            className="fa-solid fa-download fs-4 text-primary"
                                            role='button'
                                            onClick={() => { handleShowExportOptionModal() }}
                                        ></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='detailed-report-container mx-5 mt-3' style={dynamicStyle}>
                            <table style={{ width: "80%" }} className='mx-auto table-bordered' id='table-to-export'>
                                <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, borderBottom: '2px solid #ccc' }}>
                                    <tr>
                                        <th className='text-center p-0' style={{ border: '1px solid gray', whiteSpace: 'nowrap', width: ".1rem" }} >S.No</th>
                                        <th className='text-center px-3' style={{ border: '1px solid gray', whiteSpace: 'nowrap' }}>Part Code</th>
                                        <th className='text-center px-3' style={{ border: '1px solid gray', whiteSpace: 'wrap' }}>Part Name</th>
                                        <th className='text-center px-3' style={{ border: '1px solid gray', whiteSpace: 'wrap' }}>In</th>
                                        <th className='text-center px-3' style={{ border: '1px solid gray', whiteSpace: 'nowrap' }}>Out</th>
                                        <th className='text-center px-3' style={{ border: '1px solid gray', whiteSpace: 'nowrap' }}>Balance</th>
                                    </tr>
                                </thead>
                                <tbody className='mt-1'>
                                    <tr  style={{ position: 'sticky', top: 20, backgroundColor: 'white', zIndex: 1 }}>
                                        {tableHeaders.map((header, index) => (
                                            index === 0 ? (
                                                <td key={header.field}></td>
                                            ) : (
                                                <td key={header.field}>
                                                    <input
                                                        style={{ border: '1px solid gray', whiteSpace: 'nowrap', width: "100%" }}
                                                        type="text"
                                                        value={columnFilters[header.field] || ''}
                                                        onChange={(e) => handleFilterChange(header.field, e.target.value)}
                                                    />
                                                </td>
                                            )
                                        ))}
                                    </tr>
                                    {/* {updatedPosReportDataList && updatedPosReportDataList.items && updatedPosReportDataList.items.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className='text-center p-0' style={{ width: ".1rem" }} >{item.sno}</td>
                                                <td className='text-center px-3' >{item.partNumber}</td>
                                                <td className='text-center px-3' >{item.partName}</td>
                                                <td className='text-center px-3' >{item.stockIn}</td>
                                                <td className='text-center px-3' >{item.stockOut}</td>
                                                <td className='text-center px-3' >{item.stockBlance}</td>
                                            </tr>
                                        )
                                    })} */}
                                    {filteredData && filteredData.length > 0 && filteredData.map((item, index) => (
                                        <tr key={index}>
                                            <td className='text-center p-0' style={{ width: ".1rem" }}>{item.sno}</td>
                                            <td className='text-center px-3'>{item.partNumber}</td>
                                            <td className='text-center px-3'>{item.partName}</td>
                                            <td className='text-center px-3'>{item.stockIn}</td>
                                            <td className='text-center px-3'>{item.stockOut}</td>
                                            <td className='text-center px-3'>{item.stockBlance}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                        <table className='mt-5' id='pos-table-detailed-report-data' style={{ display: 'none' }}></table>
                    </div>
                    {isShowExportOptionModal &&
                        <DayReportExport
                            show={isShowExportOptionModal}
                            close={handleCloseExportOptionModal}
                        />
                    }

                    {Loading && <MaxNikLoading isshow={true} />}

                </div>
            </div>


        </>
    )
}

export default PosStockReport