import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import CommanTable from '../../../Packages/Custom/CommanTable'
import CustomSelect from '../../../components/ReactSelect/reactSelect'
import WaringModel from '../../../components/Warings/WaringModel'

const AlternateUnit2 = ({ isShow, setIshow, alternateUnit, setAlternateUnit, optionsList, SelectedUOM, user_id }) => {
  const showMeasurementData = params =>{
    return(
      <>
        <p>1 {params?.addtionalUnit?.name} =</p>
      </>
    )

  }
  let ALERNATE_UNIT_COLUMN = [
    {
      headerName: 'Addtional Unit',
      field: 'addtionalUnit',
      label: 'name',
      flex: '15'
    },
    {
      headerName: 'Measurement',
      field: 'show_data',
      flex: '12',
      cellRenderer: showMeasurementData,
    },
    {
      headerName: 'fixed',
      field: 'fixed',
      flex: '12',
      input:'bool'
    
    },
    {
      headerName: 'Conversion Factor',
      field: 'conversionFactor',
      flex: '15'
    }
  ]
  let [selectUomList, setSelectUomList] = useState([SelectedUOM?.value])
  let [alterUnitData, setAlterUnitData] = useState({
    ishowWarning:false, deleteData:{value:"", label:"", uom_id:""}
  })

  const AlterNate_unit_input_form = ({
    itemData,
    setitemData,
    editData,
    toggle,
    setEditingRow
  }) => {
    
    const [alterUom, setAlterUom]=useState(
      {uom: {value:itemData?.addtionalUnit?.id, label:itemData?.addtionalUnit?.name},
      deleteData:{value:"", lable:""}})
    
    const initialValues = {
      id:itemData?.id ? itemData?.id : null,
      uom:itemData?.addtionalUnit?.id,
      index: itemData?.index,
      Measurement: itemData?.addtionalUnit?.name ? `1 ${itemData?.addtionalUnit?.name?? ""} =` : "",
      fixed: itemData?.fixed,
      Conversion: itemData?.conversionFactor,
      createdBy:itemData?.createdBy,
    }
    async function SubmitAlternateUom(params) {
      let savedObjects = {
        "index":params?.index,
        "id": params?.id ? params?.id :null ,
        "addtionalUnit": {
            "name": alterUom?.uom?.label,
            "id": alterUom?.uom?.value
        },
        "conversionFactor": params?.Conversion,
        "fixed": params?.fixed,
        "createdBy": params?.id ? Number(params?.createdBy):Number(user_id),
        "modifiedBy": params?.id? Number(user_id) : null
    }

    let updatedItems = alternateUnit?.map(item=>{
      if (item?.index === savedObjects?.index){
        return savedObjects;
      }
      return item
    })
    if (!updatedItems.some(item => item?.index === savedObjects?.index)) {
      setAlternateUnit([...updatedItems,savedObjects])
    } else{
      setAlternateUnit(updatedItems)
    }
      setEditingRow('')
      toggle()
      setSelectUomList(prev=>([...prev,alterUom?.uom?.value]))
    }
    const handleCancel = () => {
      setAlternateUnit(prevData => prevData.filter(item => item?.index !== itemData?.index || item.hasOwnProperty('id')));
      setEditingRow('');
      toggle();
    }

  
    return (
      <>
        <Formik initialValues={initialValues}
        onSubmit={(values, formikProps) => SubmitAlternateUom(values)}>
          {({
            errors,
            submitForm,
            touched,
            setFieldValue,
            setValues,
            values,
            resetForm
          }) => (
            <>
              <Form>
                <div className='row'>
                  <div className='col-3 staan-input-group'>
                    <CustomSelect 
                    setSelectEditValue={alterUom?.uom}
                    options={optionsList?.filter(uom=> !selectUomList?.includes(uom?.value))}
                    handleOnChange={(option) => { 
                      setFieldValue('uom', option ? option.value : null)
                      setFieldValue('Measurement',`1${option?.label? option?.label : ""} =`)
                      setAlterUom(prev=>({...prev, uom:option?option:null}))
                      
                      }}

                    name='uom' id='uom' />
                    <label className='staan-form-input-label pt-1 px-1 mx-2' onClick={()=>{console.log(values);
                    }}>
                      UOM
                    </label>
                  </div>
                  <div className='col-2 staan-input-group'>
                    <Field
                      type='text'
                      name='Measurement'
                      id='Measurement'
                      disabled
                      className='w-100 staan-form-input'
                    />
                    <label
                      htmlFor='Measurement'
                      className='staan-form-input-label pt-1 px-1 mx-2'
                    >
                      Measurement
                    </label>
                  </div>
                  <div className='col-1 me-3 d-flex alteruom_fixed  '>
                    <label htmlFor='fixed' className=''>
                      Fixed
                    </label>
                    <Field
                      type='checkbox'
                      name='fixed'
                      id='fixed'
                      className='alteruom_fixed_check'
                    />
                  </div>
                  <div className='col-3 staan-input-group'>
                    <Field
                      type='text'
                      name='Conversion'
                      id='Conversion'
                      className='w-100 staan-form-input'
                    />
                    <label
                      htmlFor='Conversion'
                      className='staan-form-input-label pt-1 px-1 mx-2'
                    >
                      Conversion
                    </label>
                  </div>
                  <div className='col-1 mt-3'>
                    <button
                      type='button'
                      className='btn btn-outline-success btn-sm px-3 mx-2  rounded-pill'
                      onMouseDown={() => {
                        submitForm()
                      }}
                    >
                      <i className='fa-regular fa-floppy-disk'></i>
                    </button>
                  </div>
                  <div className='col-1 mt-3'>
                    <button
                      type='button'
                      className='btn btn-outline-secondary btn-sm px-3 mx-1  rounded-pill'
                      onClick={event => {
                        event.preventDefault()
                        
                        handleCancel()
                      }}
                    >
                      <i className='fa-solid fa-xmark'></i>
                    </button>
                  </div>

                  {/*  */}
                </div>
              </Form>
            </>
          )}
        </Formik>
      </>
    )
  }



  const handleDeleteAlternate_unit=(params)=>{
    setAlterUnitData(prev=>({...prev, ishowWarning:true,
      deleteData:{value:params?.index, lable:params?.addtionalUnit?.name,uom_id:params?.addtionalUnit?.id }}))
  }

  const handleDeleteAlternaterUomFunction=(data)=>{
    if (data= true){
      const updatedData = alternateUnit.filter((item, index) => item?.index !== alterUnitData?.deleteData?.value)
      setAlternateUnit(updatedData);
      setSelectUomList(selectUomList?.filter(prev=>prev != alterUnitData?.deleteData?.uom_id))
      setAlterUnitData(prev=>({...prev, ishowWarning:true,
        deleteData:{value:"", lable:"",uom_id:""}}))

    }
  }
  return (
    <>
      <Modal show={isShow}   size='lg'>
        <Modal.Header   className='fs-4' onClick={()=>{console.log("alternateUnit", alternateUnit);
        }}>
          <div className="col-12 fw-bold fs-4"  style={{ color: "#5CADA9" }} >
              <i
                  className='fa-solid fa-arrow-left fa-sm me-2'
                  onClick={setIshow}
              ></i>
              Alternate Unit- {SelectedUOM?.label}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12'></div>
            <div className='col-12'>
              <CommanTable
                title={'Alternate Unit'}
                headers={ALERNATE_UNIT_COLUMN}
                rowData={alternateUnit}
                inputSection={AlterNate_unit_input_form}
                getDeleteRowId={handleDeleteAlternate_unit}
                newRowAdd={true}
              />
            </div>
          </div>
          {console.log(alterUnitData)
          }
          {alterUnitData?.ishowWarning &&(
            <WaringModel
            state={alterUnitData?.ishowWarning}
            setstate={()=>{ setAlterUnitData(prev=>({...prev, ishowWarning:false}))}}
            title={'Confirm'}
            text={`Are You Sure Want to Delete ${alterUnitData?.deleteData?.lable}.`}
            Confirmtion={value => {
              if (value) {
                handleDeleteAlternaterUomFunction(value)
                
              }
            }}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AlternateUnit2
