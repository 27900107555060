export const createFgItem = (data) => {
    let no_string_fields = ['partNo', 'serialNumber', 'unit', "costAllocation"]
    let query = `mutation myFirstMutation {
        finishedGoodsCreateMutation(`
    if (data && Object.keys(data).length > 0) {
        for (const [key, value] of Object.entries(data)) {
            if(no_string_fields.includes(key)){
                query += `${key}:${value}, `;
            }else{
                query += `${key}:"${value}", `;
            }
        }
    }
    query += `) {
            finishedGoods{
                id
            }
            success
            errors
        }
    }`
    return query
}

export const createRmItem = (data) => {
    let no_string_fields = ['partNo', 'serialNumber', 'store', 'unit', "fixed"]
    let query = `mutation myFirstMutation {
        rawMaterialCreateMutation(`
    if (data && Object.keys(data).length > 0) {
        for (const [key, value] of Object.entries(data)) {
            if(no_string_fields.includes(key)){
                query += `${key}:${value}, `;
            }else{
                query += `${key}:"${value}", `;
            }
        }
    }
    query += `) {
            rawMaterials{
                id
            }
            success
            errors
        }
    }`
    return query
}

export const createScrapItem = (data) => {
    let no_string_fields = ['partNo', 'serialNumber', 'costAllocation', 'unit']
    let query = `mutation myFirstMutation {
        scrapCreateMutation(`
    if (data && Object.keys(data).length > 0) {
        for (const [key, value] of Object.entries(data)) {
            if(no_string_fields.includes(key)){
                query += `${key}:${value}, `;
            }else{
                query += `${key}:"${value}", `;
            }
        }
    }
    query += `) {
            success
            errors
            scrap{
                id
            }
        }
    }`
    return query
}

export const createBomItem = (data) => {
    let no_string_fields = ['status', 'modifiedBy', 'createdBy', 'totalRawMaterial', 'finishedGoods',
    "default", "action", "fgStore", "scrapStore", 'isDefault', 'isActive', 'costVariation']
    let object_fields = ['electricityCharges', 'labourCharges', 'machineryCharges', 'otherCharges']
    let array_fields = ['rawMaterial', "scrap", "routes", "supplier"]
    let query = `mutation myFirstMutation {
        bomCreateMutation(`
    if (data && Object.keys(data).length > 0) {
        for (const [key, value] of Object.entries(data)) {
            if(no_string_fields.includes(key)){
                query += `${key}:${value}, `;
            }else if(object_fields.includes(key)){
                query += `${key}: {
                    amount: "${value['amount']}"
                    remarks: "${value['remarks']}"
                }`
            }else if(array_fields.includes(key)){
                query += `${key}: [${value}], `;
            }else{
                query += `${key}:"${value}", `;
            }
        }
    }
    query += `) {
            success
            errors
            bom{
                id
            }
        }
    }`
    return query
}

export const fetchBOMById = (id) => {
    return `query MyQuery {
        bom(id: ${id}) {
          items {
            id
            bomName
            bomNo
            isDefault
            isActive
            bomType
            electricityCharges
            labourCharges
            machineryCharges
            otherCharges
            remarks
            totalRawMaterial
            costVariation{
              id
              isPercentage
              variation
              leadTime
            }
            routes {
              id
            }
            finishedGoods {
              id
              qty
              labourCharges
              costAllocation
              partNo{
                itemPartCode
                itemName
              }
            }
            fgStore {
              id
              storeName
            }
            status {
              status
              id
            }
            scrapStore {
              id
              storeName
            }
            rawMaterial {
              id
              itemCost
              qty
              rawQty
            }
            scrap {
              id
              costAllocation
            }
            supplier{
              id
              companyName
            }
          }
        }
    }`
}

export const CreateBomCostValidation = (data) => {
  let no_string_fields = ['isPercentage']
  let query = `mutation BomCostVariation{
  	bomCostVariationCreateMutation(`
  if (data && Object.keys(data).length > 0) {
    for (const [key, value] of Object.entries(data)) {
        if(no_string_fields.includes(key)){
            query += `${key}:${value}, `;
        }else{
            query += `${key}:"${value}", `;
        }
    }
  }
  query += `){
      bomCostVariation{
        id
        variation
        isPercentage
      }
      success
      errors
    }
  }`
  return query;
}

export const DeleteBomCostValidation = (id) => {
  let query = `mutation DeleteBomCostValidation{
    bomCostVariationDeleteMutation(id: ${id}){
      success
      errors
    }
  }`;
  return query;
}

export const FetchFinishedGoods = (id) => {
    let query = `query FinishedGoods{
        finishedGoods(id: ${id}){
          items{
            partNo{
              id
              itemName
              itemPartCode
              itemUom{
                id
                name
              }
              alternateUom{
                id
                addtionalUnit{
                  id
                  name
                }
              }
            }
            category
            labourCharges
            id
            qty
            remarks
            costAllocation
            unit{
              id
              name
            }
          }
        }
    }`
    return query;
}

export const RawMaterialFetch = (id_list) => {
    return `query RawMaterial{
        rawMaterials(idList: [${id_list}]){
          items{
            partNo{
              id
              itemName
              itemPartCode
              itemLeadTime
              itemCost
              supplierData{
                id
                supplier
                companyName
              }
            }
            itemCost
            qty
            rawQty
            category
            fixed
            id
            store{
              id
              storeName
            }
            unit{
              id
              name
            }
          }
        }
    }`
}

export const RecursiveRawMaterials = (id_list) => {
  return `query RecursiveRawMaterials {
      recursiveRawMaterials(idList: [${id_list}]) {
          items {
              id
              fixed 
              createdAt
              category
              isDelete
              itemCost
              qty
              rawQty
              serialNumber
              updatedAt
              store {
                  id
                  storeName
              }
              unit {
                  id
                  name
              }
              partNo {
                  id
                  itemName
                  itemPartCode
              }
          }
      }
  }`
}


export const ScrapDataFetch = (id_list) => {
    return `query ScrapFetch{
        scrap(idList: [${id_list}]){
          items{
            partNo{
              id
              itemName
              itemPartCode
            }
            category
            id
            qty
            costAllocation
            unit{
              id
              name
            }
          }
        }
    }`
}

export const BomRoutingDataFetch = (id_list) => {
    return `query BomRouteFetch{
        bomRouting(idList: [${id_list}], orderBy: "serialNumber", descending: true){
          items{
            id
            serialNumber
            workCenter{
              id
              workCenter
            }
            duration
            route{
              id
              routeName
            }
          }
        }
    }`
}

export const WorkCenterOptionFetchQuery = (name) =>{
    return `query WorkCenterOptionFetchQuery{
        workCenterMaster(pageSize: 200, workCenter: "${name}"){
          items{
            id
            workCenter
          }
        }
    }`
}

export const FetchBOMOptionOnType = (rawMaterialId) => {
    let query = `query MyQuery {
      bom(isActive: true, status: 2, finishedGoodsPartCode: ${rawMaterialId}){
        items {
          id
          bomName
          bomNo
          bomType
          finishedGoods{
            id
          }
          rawMaterial{
            id
          }     
          supplier{
            id
            companyName
          }         
        }
      }
    }`
    return query;
}

export const RawMaterialBomLinkCreate = (rm_id, bom_id) => {
    let query = `mutation CreateRmBomLink{
        rawMaterialBomLinkCreateMutation(
          bom: ${bom_id}
          rawMaterial: ${rm_id}
        ){
          rawMaterialBomLink{
            id
            rawMaterial{
              id
            }
            bom{
              id
              bomName
              bomType
              finishedGoods{
                id
              }
              rawMaterial{
                id
              }
            }
          }
          success
          errors
        }
    }`
    return query;
}

export const RawMaterialBomLinkFetch = (rm_id) => {
    let query = `query FetchRawMaterialBomLink{
        rawMaterialBomLink(rawMaterial: ${rm_id}){
          items{
            id
            rawMaterial{
              id
            }
            bom{
              id
              bomName
              bomType
              finishedGoods{
                id
              }
              rawMaterial{
                id
              }
            }
          }
        }
    }`
    return query;
}

export const RawMaterialBomLinkDelete = (id) => {
  if(id){
    let query = `mutation DeleteRawMaterialBom{
      rawMaterialBomLinkDeleteMutation(id: ${id}){
        success
        errors
      }
    }`
    return query;
  }else{
    return undefined;
  }
}

export const BomSupplierOptionFetch = (search_term) => {
  let query = `query BomSupplierOptionFetch{
    supplierFormData(supplier: true, companyName: "${search_term}"){
      items{
        id
        companyName
      }
    }
  }`
  return query;
}

export const BomRmLinkFetchQuery = (id) => {
  let query = `query RawMaterialBomLInk{
    rawMaterialBomLink(rawMaterial: ${id}){
      items{
        rawMaterial{
          id
          partNo{
            itemPartCode
            itemLeadTime
            itemName
            id
            supplierData{
              id
              supplier
              companyName
            }
          }
          fixed
          qty
          itemCost
        }
        bom{
          id
          bomName
          bomNo
          bomType
          electricityCharges
          machineryCharges
          labourCharges
          otherCharges
          supplier{
            id
            companyName
          }
          finishedGoods{
            id
            partNo{
              itemPartCode
              itemName
              itemLeadTime
            }
            labourCharges
            qty
          }
          rawMaterial{
            qty
            id
            itemCost
          }
        }
      }
    }
  }`
  return query;
}

export const DeleteRawMaterialQuery = (id = null, id_list = null) => {
  let query = ''
  if(id){
    query = `mutation DeleteBomRmItem{
      rawMaterialDeleteMutation(id: ${id}){
        success
        errors
      }
    }`
  }
  if(id_list){
    query = `mutation DeleteBomRmItem{
      rawMaterialDeleteMutation(idList: [${id_list}]){
        success
        errors
      }
    }`
  }
  return query;
}

export const UpdateBomRawMaterialQuery = (id, rm, trm) => {
  let query = `mutation UpdateBomRmLink{
    bomCreateMutation(id: ${id}, rawMaterial:[${rm}], totalRawMaterial: ${trm}){
      success
      errors
    }
  }`
  return query;
}

export const DeleteScrapItemQuery = (id = null, id_list = null) => {
  let query = ''
  if(id){
    query = `mutation DeleteBomRmItem{
      scrapDeleteMutation(id: ${id}){
        success
        errors
      }
    }`
  }
  if(id_list){
    query = `mutation DeleteBomRmItem{
      scrapDeleteMutation(idList: [${id_list}]){
        success
        errors
      }
    }`
  }
  return query;
}

export const DeleteFgItemQuery = (id) => {
  let query = `mutation DeleteFgItem{
    finishedGoodsDeleteMutation(id: ${id}){
      success
      errors
    }
  }`
  return query;
}

export const UpdateBomScrapItemQuery = (id, rm) => {
  let query = `mutation UpdateBomRmLink{
    bomCreateMutation(id: ${id}, scrap:[${rm}]){
      success
      errors
    }
  }`
  return query;
}

export const DeleteRoutingItems = (id_list) => {
  let query = `mutation DeleteRouting{
    routingDeleteMutation(idList: [${id_list}]){
      success
      errors
    }
  }`
  return query;
}

export const DeleteBomQuery = (id) => {
  let query = `mutation deleteBOM{
    bomDeleteMutation(id: ${id}){
      success
      errors
    }
  }`
  return query;
}

export const UpdateBomStatusById = (status, id) => {
  let query = `mutation updateBomStatus {
      bomCreateMutation(id: ${id}, status: ${status}){
        success
        errors
        bom{
          id
          status{
            id
            status
          }
        }
    }
  }`
  return query;
}

export const DuplicateBomForms = (model, id_list) => {
  let query = `mutation DuplicateRmItem {
    bomDuplicateItemMutation(modelName: "${model}", idList: [${id_list}])
    {
      success
      errors
      items
    }
  }`
  return query
}

export const DuplicateBomFg = (id_list) => {
  let query = `mutation DuplicateFinishedGoodsItem {
    bomDuplicateFgItemMutation(idList: [${id_list}]){
      success
      errors
      finishedGoods{
        id
        qty
        labourCharges
        costAllocation
        partNo{
          itemPartCode
          itemName
        }
      }
    }
  }`
  return query
}

export const DuplicateBomRm = (id_list) => {
  let query = `mutation DuplicateRawMaterialItem {
    bomDuplicateRmItemMutation(idList: [${id_list}]){
      success
      errors
      rawMaterial{
        id
        partNo{
          id
          itemPartCode
          itemName
        }
      }
    }
  }`
  return query
}

export const DuplicateBomScrap = (id_list) => {
  let query = `mutation DuplicateScrapPartItem{
    bomDuplicateScrapItemMutation(idList: [${id_list}]){
      success
      errors
      scrap{
        id
        partNo{
          id
          itemPartCode
          itemName
        }
      }
    }
  }`
  return query
}

export const DuplicateBomRoute = (id_list) =>{
  let query = `mutation DuplicateRoutingItem{
    bomDuplicateRoutingItemMutation(idList: [${id_list}]){
      success
      errors
      routes{
        id
        route{
          id
          routeName
        }
      }
    }
  }`
  return query
}

export const DuplicateChildBom = (id_list, rm) => {
  let query = `mutation DuplicateChildBomItem{
    bomDuplicateChildBomMutation(idList: [${id_list}], rawMaterial: [${rm}]){
      success
      errors
      rawMaterialBomLink{
        id
      }
    }
  }`
  return query;
}

export const DeleteChildBomLink = (id=null, bom_id=null) => {
  if(bom_id){
    let query = `mutation UnlinkCancelledChildBom{
      rawMaterialBomLinkDeleteMutation(bomId: ${bom_id}){
        success
        errors
      }
    }`
    return query;
  }else{
    let query = `mutation UnlinkCancelledChildBom{
      rawMaterialBomLinkDeleteMutation(id: ${id}){
        success
        errors
      }
    }`
    return query;
  }
}

export const FetchLinkedBom = (bom) => {
  let query = `query FetchRawMaterialBomLink {
    rawMaterialBomLink(bom: ${bom}){
      items{
        id
      }
    }
  }`
  return query;
}

export const FetchSupplierBasedOnBom = (bom_name) => {
  let query = `query FetchBomSupplier{
    bom(bomName: "${bom_name}", isActive: true){
      items{
        id
        status{
          id
          status
        }
        supplier{
          id
          companyName
        }
      }
    }
  }`
  return query;
}