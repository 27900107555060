import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../../api/axoiss';
import DataContext from '../../context/ItemMasterContext';
import { itemComboDelete } from '../../GraphQLQueries/ItemMastermutation';
import { ShowErrorNoties } from '../../utils/Utils';
import showErrorToast from '../notifications/react-toastify/toast';


const DeleteConformation = ({deleteData, url, handleDeletedId , query, fullquery, check_data}) => {
    const {toastDeleteConfomation, handletoastDeleteConfomationClose, 
        conferenct, setConference,   
        posItemData, setPosItemData, contanctPost, setContanctPost, contactdata ,setcontactdata,
        AddressPost, Addressdata, setAddressPost, setAddressdata, 
        salesOrderData, setSalesOrderData , handleStoreAddClose} = useContext(DataContext)
     
      const Detele= async ()=>{ 
        
        
        if (query){
          if (query === "itemComboDelete"){ 
              const response = await axiosInstance.post(`/itemmaster/graphql`, {query: itemComboDelete(deleteData.id)})
              let responseData = response.data.data.itemComboDeleteMutation 
              if (responseData.success){
                handletoastDeleteConfomationClose()
                if(handleDeletedId){
                  handleDeletedId(deleteData.id)
                }
              } else{ 
                toast.error(responseData.errors, {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
                });
                handletoastDeleteConfomationClose()
              }
          }  
        } else if (fullquery) {
          
          try{
            const response = await axiosInstance.post(`/itemmaster/graphql`, {query: fullquery})
            const keys = Object.keys(response.data.data);
            const firstKey = keys.length > 0 ? keys[0] : null;
            let responseData = response?.data?.data?.[firstKey] 
          
            
            if (responseData?.success){
              handletoastDeleteConfomationClose()
              if(handleDeletedId){
                handleDeletedId(deleteData.id)
              }
            } else{ 
              // showErrorToast(true, 'error', )
              let filterdError = ShowErrorNoties(responseData?.errors)
              showErrorToast(true, 'error', filterdError)
              // handletoastDeleteConfomationClose()
            }
          } catch(error){
            let filterdError = showErrorToast(error)
            showErrorToast(true, 'error', filterdError)
            handletoastDeleteConfomationClose()
          }
         
        } else{
          try{ 
              
            if(url === "/itemmaster/Store"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){ 
                if(handleDeletedId){
                  handleDeletedId(deleteData.id)
                }
                handleStoreAddClose()
                handletoastDeleteConfomationClose()
              }
            
            } else if(url === "/itemmaster/UOM"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){
                if(handleDeletedId){
                  handleDeletedId(deleteData.id)
                }
                handletoastDeleteConfomationClose()
              } 
            }  else if(url === "/itemmaster/hsn"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){
                if(handleDeletedId){
                  handleDeletedId(deleteData.id)
                }
                handletoastDeleteConfomationClose()
              } 
            } else if(url === "/itemmaster/AccountsMaster"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){
                if(handleDeletedId){
                  handleDeletedId(deleteData.id)
                }
                handletoastDeleteConfomationClose()
              }
            

            } else if(url === "/itemmaster/AccountsGroup"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){
                if(handleDeletedId){
                  handleDeletedId(deleteData.id)
                }
                handletoastDeleteConfomationClose() 
              }
            
            } else if(url === "/api/Conference"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){
                const remove_delete_data = conferenct.filter(data=> data.id !== deleteData.id)
                setConference(remove_delete_data)
                handletoastDeleteConfomationClose()
              }
              
            
            }
            else if(url === "/itemmaster/CurrencyExchange"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){
                if(handleDeletedId){
                  handleDeletedId(deleteData.id)
                }
                handletoastDeleteConfomationClose()
              } 
            }
            else if(url === "/itemmaster/CurrencyMaster"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){
                if(handleDeletedId){
                  handleDeletedId(deleteData.id)
                }
                handletoastDeleteConfomationClose()
              } 
            }
            else if(url === "/itemmaster/SalesOrderItem"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
            
              if(response.data === ""){
                const remove_delete_data = posItemData.filter(data=> data.id !== deleteData.id)
                if(handleDeletedId){
                  handleDeletedId(deleteData.id)
                }
                setPosItemData(remove_delete_data)
                handletoastDeleteConfomationClose()
              }
            
            }
            else if(url === "/itemmaster/Contact"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){
                const remove_delete_data = contanctPost.filter(data=> data.id !== deleteData.id)
                const removed_delete_for_total_data = contactdata.filter(data=> data.id !== deleteData.id)
                setContanctPost(remove_delete_data)
                setcontactdata(removed_delete_for_total_data)
                handletoastDeleteConfomationClose()
              }
          
          
            } else if(url ==="/itemmaster/caddress"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){
                const remove_delete_data = AddressPost.filter(data=> data.id !== deleteData.id)
                setAddressPost(remove_delete_data)
                const removed_delete_for_total_data = Addressdata.filter(data=> data.id !== deleteData.id)
                setAddressdata(removed_delete_for_total_data)
                handletoastDeleteConfomationClose()
              }
      
            } else if(url === "/itemmaster/itemGroup"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){
                if(handleDeletedId){
                  handleDeletedId(deleteData.id)
                }
                handletoastDeleteConfomationClose()
              }
            } else if(url === "/itemmaster/NumberingSeries"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){
                if(handleDeletedId){
                  handleDeletedId(deleteData.id)
                }
                handletoastDeleteConfomationClose()
              }
            } else if(url=== "/itemmaster/SalesOrder"){
              const response = await axiosInstance.delete(`${url}/${deleteData.id}`)
              if(response.data === ""){
                const remove_delete_data = salesOrderData.filter(data=> data.id !== deleteData.id)
                setSalesOrderData(remove_delete_data)
                handletoastDeleteConfomationClose()
              }

            }

            
            
          }catch(error){ 
              toast.error(error.response.data.error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
                handletoastDeleteConfomationClose()
          } 
        }
          
          

      }
    
  
  return (
     <>
      <ToastContainer/>
        <Modal
        show={toastDeleteConfomation} 
        backdrop="static"
        keyboard={false}
      > 
        <Modal.Header className='py-1'>
          <Modal.Title >Confirm </Modal.Title> 
        </Modal.Header >
        <Modal.Body>
          <div className="row fs-6 ms-2 ">
            <div className="col-12">
              <p className='text-center' >Are you sure you want to delete ?</p>
              <p className='text-danger text-center'>"{deleteData?.Name}".</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center py-1'>
          <Button variant="secondary px-4 " onClick={handletoastDeleteConfomationClose}>
            No
          </Button>
          <Button variant="primary px-4 " onClick={()=>{Detele()}}>Yes</Button>
        </Modal.Footer>
      </Modal>
      
    </>
  )
}

export default DeleteConformation