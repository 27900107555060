import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlinePrinter } from 'react-icons/ai';
import { MdInput } from "react-icons/md";
import { TiCancel } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { ToastContainer } from 'react-toastify';
import io from 'socket.io-client';
import axiosInstance from '../../../api/axoiss';
import Can from "../../../components/authorizationComponents/Can";
import ShowCurrencyConversion from '../../../components/currencyConversionModel/ShowCurrencyConversion';
import DiscountModel from '../../../components/DiscountModel/DiscountModel';
import BallTriangleComponent from '../../../components/loading/BallTriangle';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import CustomSelect from '../../../components/ReactSelect/reactSelect';
import TaxModel from '../../../components/Tax/TaxModel';
import WaringModel from '../../../components/Warings/WaringModel';
import DataContext from '../../../context/ItemMasterContext';
import { CancelPOSQuery, createPOSMode, SubmitPOSQuery } from '../../../GraphQLQueries/ItemMastermutation';
import { Filiter_address_with_pincode_Query, getBatchOrSerial, itemMasterDataForPosFetchQuery, pincodeQuery, PosSalesOrderEdit, SupplierEdit, SupplierOption } from '../../../GraphQLQueries/ItemmasterQuery';
import { checkStockPOSQuery, contactDetalisFetchQuery } from '../../../GraphQLQueries/posQueryAndMutations/posQuerys';
import CommanTable from '../../../Packages/Custom/CommanTable';
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { calculateTax, checkChangesInputs, CommanFinalDiscountValue, commanOverallDiscountValue, commanPercentageDiscount, displayRupeeFormat, getCurrentDate, OnchangeGetData, posCustomSelectStyle, removeEmptyValueInObject, roundToNearest10, ShowErrorNoties } from '../../../utils/Utils';
import { PosSalesorderSchema } from '../../../validations/itemmaster';
import SupplierFrom from '../../ItemMaster/model/SupplierFrom';
import PosItemDetails2 from './PosItemDetails2';
import PosOtherIncomecharges from './PosOtherIncomecharges';
import PosPaymentModel from './PosPaymentModel';
import ShowStockNeed from './ShowStockNeed';
const Posmodel = ({isShow, setIsShow, posEditId, setPosEditId, setLoad}) => {
    const [initialValue, setinitialValue] = useState({
        id: '',
        marketingEvent: '',
        OrderDate: '',
        isSales: false,
        CostomerName: '',
        BillingAddress: '',
        DeliverAddress: '',
        contactPerson:"",
        Store: '',
        Mobile: '',
        WhatsappNumber: '',
        CosName: '',
        Email: '',
        State: '',
        City: '',
        createdBy: '',
        SalesPerson: '',
        payment: []
    })
    const [paymentInputs, setpaymentInputs] = useState([{
        index:1,
        id: "",
        payby: {
            label: "",
            value: ""
        },
        payAmount: "",
        balanceAmount: "",
        createdBy: ""
    }]);
    const [otherIncomeInputs, setOtherIncomeInputs] = useState([{
        index:1,
        id: "",
        account:{
            label: "",
            value: ""
        },
        hsn:"",
        hsnCode:"",
        amount:0,
        tax:0,
        sgst:0,
        cgst:0 ,
        igst:0 ,
        created_by: ""
    }])
    const [otherIncomeChargeValue, setOtherIncomeChargeValue]= useState({
        id:"",
        account:{ label: "",
            value: ""
        }, tax:"",
        hsn:"",
        hsnCode:"",
        amount:"",
        currentIndex:1,
    })

    const [initialdata, setInitialdata] = useState(null)
    const [posPamentValue,setPosPamentValue]= useState({payby_Options:[],payby:{label: "", value: ""},
        payAmount:"", blance:"", currentIndex:paymentInputs?.length, paymentCompleted:false, deleteWaring:false, deleteData:null})
    const {userId, screenSize, currentConference, currentConferenceStore, handleSupplierShow,supplierSelect }= useContext(DataContext)
    const scannerRef = React.createRef()
    const [formKey, setFormKey] = useState(0)
    const [posItemEdit, setPosItemEdit] = useState('')
    const [isShowConferenceData,setIsShowConferenceData ] = useState(false)
    const [posValues, setPosValues] =  useState({status:null, posID:"", isSales:false, isNewContact:false, isShowItemDetails:false,
        isSomeStockReduced:false, marketingEvent:{ value: '', label: '' }, store: { value: '', label: '' }, samplelinkedCostomerId:null,
        costomerName: null, whatsAppNumber:null, email:null, selectMobile:{ value: '', label: '' }, orderDate: getCurrentDate(), pincode:null, states:"", city:"",
        SalesPerson:{value: '', label: ''}, selectcostomerName:{value: '', label: ''}, legalName:"", billingAddress:{value: '', label: ''},
        deliverAddress:{value: '', label: ''},contactPerson :{value: '', label: ''}, contactNumber:null, customerGst:null,
        discountPercentageInValue:"", discountValue:"", discountTotalValue:"", currencyRate:0, currencySymbol:"", currencyName:"", posItemDetailsIndex:0,
        scannerValue:"", totalItemQty:0, igstTax:[], sgstTax:[],cgstTax:[], totalTax:"0.00", fixedTotalAmount:"0.00",
        toPay:"0.00", roundOFValue:"0.00", received:"0.00", balance:"0.00", discount:"0.00", beforetax:"0.00", isShowPayment:false,
        isShowDisCount:false, pending:false, isDelivered:false, remark:"", StockNeedList:[], isShowStockNeed:false,isAllStockReduced:false,
        isLoading:false, deleteWaring : false, deleteData:{ Name: "", id: "" }, isShowCurrencyConversion:false,
        isShowTax:false, isShowOtherIncomeCharges:false, totalOtherIncomeCharge:"0.00", taxData:null
    })
    const [cancelWaring, setcancelWaring] = useState(false)
    const [posList, setposList] = useState({contaces:[], pincodes:[], customers:[],
        billingAddresses:[], deliverAddresses:[],contactPersons:[], partCodeList:[], partNameList:[], posItemData:[],
        })
    const {conferenct, prefetchValue, userdata} = useContext(DataContext)
    const [discountValue, setDiscountValue] = useState({
    discountError: "", discountPercentage: "", discountValue: "",
    discountTotalValue: "", isDiscountApplied: false
    })
    
    // Redux
    const dispatch = useDispatch();
    const Options = useSelector(state => state.itemGroup.data);

    function updateTheAllState(params) {
        let selectBillingAddresEdit = params?.BillingAddress
        let AddressLable = `${selectBillingAddresEdit?.addressLine1}, ${selectBillingAddresEdit?.addressLine2},
                ${selectBillingAddresEdit?.city} ,${selectBillingAddresEdit?.state} , ${selectBillingAddresEdit?.country}`
        let selectDeliverAddresEdit = params?.DeliverAddress
        let DeliverAddressLable = `${selectDeliverAddresEdit?.addressLine1}, ${selectDeliverAddresEdit?.addressLine2},
                ${selectDeliverAddresEdit?.city}, ${selectDeliverAddresEdit?.country}`
        if (params?.otherIncomeCharge && params?.otherIncomeCharge?.length > 0){
            setOtherIncomeInputs(  params?.otherIncomeCharge?.map((item , index)=>{
            
                return {
                    index:index+1,
                id: item?.id,
                account:{
                    label: item?.otherIncomeChargesId?.name,
                    value: item?.otherIncomeChargesId?.id
                },
                hsn:item?.hsn?.id ? Number(item?.hsn?.id) : null,
                hsnCode : item?.hsn?.hsnCode,
                amount:item?.amount,
                tax:item?.tax,
                sgst:item?.sgst,
                cgst:item?.cgst,
                igst:item?.igst,
                createdBy: item?.createdBy?.id ? Number(item?.createdBy?.id) : Number(userId),
                afterDiscountValue: item?.afterDiscountValue ? Number(item?.afterDiscountValue).toFixed(2) : null, 
                discountValue:item?.discountValue ? Number(item?.discountValue).toFixed(2) : null
                }
            }))
            setOtherIncomeChargeValue(prev=>({...prev,currentIndex:null }))
        } else{
            setOtherIncomeInputs([{ index:1,
                id: "",
                account:{
                    label: "",
                    value: ""
                },
                hsn:"",
                hsnCode:"",
                amount:0,
                tax:0,
                sgst:0,
                cgst:0 ,
                igst:0 ,
                created_by: ""}])
        }
      
        setPosValues(prev=>({...prev, status:params?.status,
            isSales: params?.posType === "Sales",
            posID:params?.POSId?.linkedModelId,
            orderDate:params?.OrderDate,
            marketingEvent:{ value: params?.marketingEvent?.id, label: params?.marketingEvent?.name },
            store: { value: params?.store?.id, label: params?.store?.storeName},
            costomerName:params?.CosName, samplelinkedCostomerId:params?.sampleContactLink?.id,
            whatsAppNumber:params?.WhatsappNumber, email:params?.Email,selectMobile:
            { value: params?.sampleContactLink?.id, label: params?.Mobile}, pincode:
            {value:params?.pincode?.id, label:params?.pincode?.pincode},
            SalesPerson:{value:params?.SalesPerson?.id, label: params?.SalesPerson?.username},
            remark:params?.Remarks, isDelivered:params?.isDelivered,pending:params?.Pending,
            fixedTotalAmount:params?.TotalAmount, toPay:params?.FinalTotalValue,
            balance:params?.balanceAmount, received:params?.receivedAmount,
            states:params?.State, city:params?.district,billingAddress:
            {value: selectBillingAddresEdit?.id, label: AddressLable,State: selectBillingAddresEdit?.state},
            deliverAddress:{value: selectDeliverAddresEdit?.id, label: DeliverAddressLable},
            selectcostomerName:{value: params?.customerName?.id, label: params?.customerName?.companyName},
            contactPerson: {value: params?.contactPerson?.id, label: params?.contactPerson?.contactPersonName},
            contactNumber: params?.contactPerson?.phoneNumber,
            isAllStockReduced:params?.allStockReduced,
            currencyRate:  params?.marketingEvent?.currency?.rate ,
            currencySymbol:params?.marketingEvent?.currency?.Currency?.currencySymbol,
            posItemDetailsIndex : params?.itemDetails?.length,
            currencyName:params?.marketingEvent?.currency?.Currency?.name,
            
        }))
        setDiscountValue({
            discountError: "", discountPercentage: params?.OverallDiscountPercentage, discountValue: params?.OverallDiscountValue,
            discountTotalValue: params?.DiscountFinalTotal, isDiscountApplied: Number(params?.OverallDiscountPercentage) ? true :
            Number(params?.OverallDiscountValue) ? true :  params?.DiscountFinalTotal ? true : false
        })
        // customerName
        setinitialValue(prev=>({...prev, id:params?.id, marketingEvent:params?.marketingEvent?.id,
            OrderDate:params?.OrderDate, isSales:params?.posType === "Sales", CosName:params?.CosName,
            Store:params?.store?.id, Mobile:params?.Mobile, WhatsappNumber:params?.WhatsappNumber,
            Email:params?.Email, State:params?.State, City:params?.district, createdBy:params?.createdby?.id ? Number(params?.createdby?.id):"",
            SalesPerson:params?.SalesPerson?.id ? Number(params?.SalesPerson?.id):"",
            CostomerName:params?.customerName?.id ? Number(params?.customerName?.id):"",
            BillingAddress:params?.BillingAddress?.id ? Number(params?.BillingAddress?.id):"",
            DeliverAddress:params?.DeliverAddress?.id ? Number(params?.DeliverAddress?.id) : "",
            contactPerson:params?.contactPerson?.id ? Number(params?.contactPerson?.id):""
        }))

        let posPayment = params?.payment?.map((item, index)=>({
            ...item, index:index,
            payby: {
                label: item?.payby?.accountsName,
                value: item?.payby?.id,
            },
        }))
        if (posPayment?.length > 0){
            setpaymentInputs(posPayment)
            if (Number(posPayment[posPayment?.length-1]?.balanceAmount) === 0){
                setPosPamentValue(prev=>({...prev,currentIndex:null, paymentCompleted:true}))
            }else{
                setPosPamentValue(prev=>({...prev,currentIndex:posPayment?.length}))
            }
        } 
        
        let isSomeStockReduced = false;  // Initialize a flag to check if stock needs to be reduced

        // Iterate over the itemDetails and update each item, checking for stockReduce
        let itemDetails = params?.itemDetails?.map((item, index) => {
            // Check if stockReduce is true and set the flag
            if (item?.stockReduce) {
                isSomeStockReduced = true;
            }
             
            
            // Return the item with the added index
            return {
                ...item,
                index: index,
                hsn:item?.hsn?.id ? Number(item?.hsn?.id) : null,
                hsnCode:item?.hsn?.hsnCode ,
                createdBy : item?.createdBy?.id ? Number(item?.createdBy?.id) : userId
            };
        }); 
        
        // After the map, update the state if needed
        if (isSomeStockReduced) {
            setPosValues(prev => ({ ...prev, isSomeStockReduced: isSomeStockReduced }));
        }
        
        setposList(prev => ({
            ...prev,  // Spread the previous state, maintaining other properties
            posItemData: itemDetails
        }));
        if (params?.posType === "Sales"){
            setInitialdata({
                marketingEvent: params?.marketingEvent?.id ? params?.marketingEvent?.id :"",
                Store: params?.store?.id ? params?.store?.id : "",
                CosName: params?.CosName ? params?.CosName : "",
                BillingAddress: selectBillingAddresEdit?.id ?  selectBillingAddresEdit?.id : "",
                DeliverAddress:  selectDeliverAddresEdit?.id ? selectDeliverAddresEdit?.id : "",
                contactPerson:  params?.contactPerson?.id ? params?.contactPerson?.id : "",
                balanceAmount: params?.balanceAmount ? params?.balanceAmount : "",
                SalesPerson: params?.SalesPerson?.id ? params?.SalesPerson?.id : "",
                remarksText:params?.Remarks !==null ? params?.Remarks : "",
                isDelivered: params?.isDelivered,
                pending: params?.Pending
            })
        } else{
            
            setInitialdata({
                marketingEvent:params?.marketingEvent?.id ? params?.marketingEvent?.id :"",
                Store : params?.store?.id ? params?.store?.id : "",
                CosName : params?.CosName ? params?.CosName : "",
                Mobile : params?.Mobile ? params?.Mobile : "",
                WhatsappNumber : params?.WhatsappNumber ? params?.WhatsappNumber : "",
                Email : params?.Email ? params?.Email : "",
                State : params?.State  ? params?.State : "",
                City : params?.district ? params?.district : "",
                balanceAmount : params?.balanceAmount ? params?.balanceAmount : "",
                SalesPerson : params?.SalesPerson?.id ? params?.SalesPerson?.id: "" ,
                remarksText : params?.Remarks !==null ? params?.Remarks : "",
                isDelivered: params?.isDelivered,
                pending: params?.Pending,
            })
        }
        setFormKey(prev => prev + 1);

    }

    // return  null; fetch pos edit data
    useEffect(()=>{
        async function fetchEditData(params) {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: PosSalesOrderEdit(params) });
            const responseData = response?.['data']?.['data']?.['salesOrder']?.items?.[0]
            if (responseData){
                updateTheAllState(responseData)
            }
            else{
                showErrorToast(true, 'error', "POS Not Found", '')
            }
        }
        if(posEditId) {
            fetchEditData(posEditId)
        } else{
            setPosValues(prev=>({...prev, marketingEvent:{value: currentConference?.value , label: currentConference?.lable},
                store:{value: currentConferenceStore?.value , label: currentConferenceStore?.lable},
                currencyRate:currentConference?.currentCurrencyrate, currencySymbol:currentConference?.currencySymbol, currencyName:currentConference?.name}))
            setinitialValue(prev=>({...prev, marketingEvent:Number(currentConference?.value),
                Store:Number(currentConferenceStore?.value)}))
            
        }
    },[posEditId])

    // get data by Redux
    useEffect(()=>{
        if (Options.name === "posContactfetch"){
            setposList(prev=>({...prev, contaces:Options?.value?.items?.map((contace)=>({
                value: contace?.id,
                label: contace?.phoneNumber,
                name: contace?.contactPersonName,
                email: contace?.email,
                whatsappNo: contace?.whatsappNo,
                otherNo: contace?.otherNo,
                pincode: contace?.enquirydatasSet?.[0]?.pincode
                }))
            }))
        } else if(Options.name === "posPincodeList"){
            setposList(prev=>({...prev, pincodes:Options?.value?.items?.map((pincode)=>({
                value: pincode?.id,
                label: pincode?.pincode,
            }))}))
        } else if(Options.name === "distri_state"){
            let filiter_resuelt = Options?.value?.items?.[0]
            setPosValues(prev=>({...prev, states:filiter_resuelt?.['state']?.stateName,
                city: filiter_resuelt?.['district']?.district,
            }))
        } else if(Options.name === "posSupplierfetch"){
            setposList(prev=>({...prev, customers:Options?.value?.items?.map((customer)=>({
                value: customer?.id,
                label: customer?.companyName,
                legalName : customer.legalName
            }))}))
        }
    },[dispatch, Options])

    // on scan the data add the values
    useEffect(()=>{
        
        if (posValues?.scannerValue?.trim() !== ""){
            if (posValues?.isSales) {
                if (posValues?.store?.value && posValues?.marketingEvent?.value) {
                    if (posValues?.selectcostomerName?.value) {
                        fetchScanData()
                    } else{
                        showErrorToast(true, 'warn', 'Customer Is Required!', '')
                    }
                } else{
                    showErrorToast(true, 'warn', 'Store And Marketing Event Is Required!', '')
                }
                } else {
                if (posValues?.store?.value && posValues?.marketingEvent?.value) {
                    fetchScanData()
                } else{
                    showErrorToast(true, 'warn', 'Store And Marketing Event Is Required!', '')
                }
            }
           
        }
        
    },[posValues?.scannerValue])

    // on change item details cal the values
    useEffect(()=>{
        // return after add payment any changes in item or other charges
    function updatethepayment(params) {
        let blance = params
        if(paymentInputs?.length > 0 && paymentInputs[paymentInputs?.length-1]?.payAmount>0 ){
            setpaymentInputs(paymentInputs?.map((item)=>{
                blance =blance -  item?.payAmount
                return {...item, balanceAmount:blance}
            }))
            // showErrorToast(true, 'success', "", "Updated in Payment Successfully.")
        }
    }
        if (posList?.posItemData?.length>0){
            let totalAmount = 0
            let fixedTotalAmount = 0
            let fixedOtherCharges = 0
            let tableTotalQty = 0
            let otherIncomeChargesAmount = 0
            const igstList = {}
            const cgstList = {}
            const sgstList = {}
            // Group the tax to display it in Tax Modal
            let groupedData = {};
            posList?.posItemData?.forEach(item=>{
                fixedTotalAmount += item.rate * item.qty
                tableTotalQty +=Number(item?.qty)
                totalAmount += Number(item?.amount)
                const hsnCode = item?.hsnCode;
                if (!groupedData[hsnCode]) {
                    groupedData[hsnCode] = {
                    hsn: hsnCode,
                    taxableValue: 0,
                    cgst: { "percent": 0, "amount": 0 },
                    sgst: { "percent": 0, "amount": 0 },
                    igst: { "percent": 0, "amount": 0 },
                };
                }
                groupedData[hsnCode].taxableValue += Number(item?.amount);
                if (item?.igst) {
                    if (igstList[item?.igst]) {
                        igstList[item?.igst].push(item?.amount)
                        groupedData[hsnCode].igst.percent = Number(item.igst);
                        groupedData[hsnCode].igst.amount += (item?.amount * Number(item.igst)) / 100;
                    } else {
                        igstList[item?.igst] = [item?.amount]
                        groupedData[hsnCode].igst.percent = Number(item.igst);
                        groupedData[hsnCode].igst.amount += (item?.amount * Number(item.igst)) / 100;
                    }
                }
                if (item?.cgst) {
                    if (cgstList[item?.cgst]) {
                        cgstList[item?.cgst].push(item?.amount)
                        groupedData[hsnCode].cgst.percent = Number(item.cgst);
                        groupedData[hsnCode].cgst.amount += (item?.amount * Number(item.cgst)) / 100;
                    } else {
                        cgstList[item?.cgst] = [item?.amount]
                        groupedData[hsnCode].cgst.percent = Number(item.cgst);
                        groupedData[hsnCode].cgst.amount += (item?.amount * Number(item.cgst)) / 100;
                    }
                }
                if (item?.sgst) {
                    
                    if (sgstList[item?.sgst]) {
                        sgstList[item?.sgst].push(item?.amount)
                        groupedData[hsnCode].sgst.percent = Number(item.sgst);
                        groupedData[hsnCode].sgst.amount += (item?.amount * Number(item.sgst)) / 100;
                    } else {
                        sgstList[item?.sgst] = [item?.amount]
                        groupedData[hsnCode].sgst.percent = Number(item.sgst);
                        groupedData[hsnCode].sgst.amount += (item?.amount * Number(item.sgst)) / 100;
                    }
                }
            })
            if (Number(otherIncomeInputs[0]?.amount) > 0 && initialValue?.isSales){
                otherIncomeInputs?.forEach(charges=>{
                    const hsnCode = charges?.hsnCode;
                    if (!groupedData[hsnCode]) {
                        groupedData[hsnCode] = {
                            hsn: hsnCode,
                            taxableValue: 0,
                            cgst: { "percent": 0, "amount": 0 },
                            sgst: { "percent": 0, "amount": 0 },
                            igst: { "percent": 0, "amount": 0 },
                        };
                    }
                    otherIncomeChargesAmount +=  Number(charges?.afterDiscountValue) > 0 ? Number(charges?.afterDiscountValue) : Number(charges?.amount)
                    fixedOtherCharges +=Number(charges?.amount)
                    groupedData[hsnCode].taxableValue += Number(charges?.afterDiscountValue) > 0 ? Number(charges?.afterDiscountValue) : Number(charges?.amount)
                    if (charges?.igst) {
                        if (igstList[charges?.igst]) {
                            igstList[charges?.igst].push(charges?.afterDiscountValue ? charges?.afterDiscountValue : charges?.amount)
                            groupedData[hsnCode].igst.percent = Number(charges?.igst);
                            groupedData[hsnCode].igst.amount += (Number(charges?.afterDiscountValue ? charges?.afterDiscountValue : charges?.amount) * Number(charges?.igst)) / 100;
                        } else {
                            igstList[charges?.igst] = [charges?.afterDiscountValue ? charges?.afterDiscountValue : charges?.amount]
                            groupedData[hsnCode].igst.percent = Number(charges?.igst);
                            groupedData[hsnCode].igst.amount += (Number(charges?.afterDiscountValue ? charges?.afterDiscountValue : charges?.amount) * Number(charges?.igst)) / 100;
                        }
                    }
                    if (charges?.cgst) {
                        if (cgstList[charges?.cgst]) {
                            cgstList[charges?.cgst].push(charges?.afterDiscountValue ? charges?.afterDiscountValue : charges?.amount)
                            groupedData[hsnCode].cgst.percent = Number(charges.cgst);
                            groupedData[hsnCode].cgst.amount += (Number(charges?.afterDiscountValue ? charges?.afterDiscountValue : charges?.amount) * Number(charges?.cgst)) / 100;
                        } else {
                            cgstList[charges?.cgst] = [charges?.afterDiscountValue ? charges?.afterDiscountValue : charges?.amount]
                            groupedData[hsnCode].cgst.percent = Number(charges.cgst);
                            groupedData[hsnCode].cgst.amount += (Number(charges?.afterDiscountValue ? charges?.afterDiscountValue : charges?.amount) * Number(charges?.cgst)) / 100;
                        }
                    }
                    if (charges?.sgst) {
                        if (sgstList[charges?.sgst]) {
                            sgstList[charges?.sgst].push(charges?.afterDiscountValue ? charges?.afterDiscountValue : charges?.amount)
                            groupedData[hsnCode].sgst.percent = Number(charges?.sgst);
                            groupedData[hsnCode].sgst.amount += (charges?.afterDiscountValue * Number(charges?.sgst)) / 100;
                        } else {
                            sgstList[charges?.sgst] = [charges?.afterDiscountValue ? charges?.afterDiscountValue : charges?.amount]
                            groupedData[hsnCode].sgst.percent = Number(charges?.sgst);
                            groupedData[hsnCode].sgst.amount += (Number(charges?.afterDiscountValue ? charges?.afterDiscountValue : charges?.amount) * Number(charges?.sgst)) / 100;
                        }
                    }
                })
            }
            const { taxResult: igstResult, taxValues: igstaxvalues } =
                        calculateTax(igstList)
            const { taxResult: cgstResult, taxValues: cgstaxvalues } =
                    calculateTax(cgstList)
            const { taxResult: sgstResult, taxValues: sgstaxvalues } =
                    calculateTax(sgstList) 
            
            let toPay = roundToNearest10(totalAmount+otherIncomeChargesAmount+igstaxvalues+cgstaxvalues+sgstaxvalues).toFixed(2)
            if (toPay > 0 && Number(posValues?.toPay) !== toPay){
                updatethepayment(toPay)
            }

            setPosValues(prev=>({...prev,
                totalItemQty:tableTotalQty, igstTax:igstResult, sgstTax:sgstResult,
                cgstTax:cgstResult, totalTax:(igstaxvalues+cgstaxvalues+sgstaxvalues).toFixed(2),
                fixedTotalAmount:Number(fixedTotalAmount).toFixed(2), toPay:toPay,
                roundOFValue:(roundToNearest10(totalAmount+otherIncomeChargesAmount+igstaxvalues+cgstaxvalues+sgstaxvalues).toFixed(2)-(totalAmount+otherIncomeChargesAmount+igstaxvalues+cgstaxvalues+sgstaxvalues).toFixed(2)).toFixed(2),
                beforetax: (Number(totalAmount)+Number(otherIncomeChargesAmount)).toFixed(2),totalOtherIncomeCharge:(fixedOtherCharges).toFixed(2),
                taxData: Object.values(groupedData)
            }))
        }
    },[posList?.posItemData, otherIncomeInputs])

    useEffect(() => {
       
        if (supplierSelect?.value) {
          setPosValues(prev=>({...prev, selectcostomerName:{value: supplierSelect?.value, label: supplierSelect?.label}, LegalName:supplierSelect?.legalName}));
          
          setinitialValue(prev=>({...prev, CostomerName:supplierSelect?.value}))
          setFormKey(prev => prev + 1);
        }
    }, [supplierSelect])

    // item details components

    const descriptionUpdate = params => {
        const openModel = () => {
            if (!params?.['stockReduce'] && !params?.['isCanceled']){
                setPosItemEdit(params)
                setPosValues((prev)=>({...prev, isShowItemDetails:true}))
            }
        }
        return (
            <>
                <div
                style={{
                    color: params?.['stockReduce'] ? 'green' : 'rgb(0 123 255)',
                    fontWeight: params?.['stockReduce'] ? 'bold' : '',
                }}
                className='posTablePartcode cursor-pointer fs-6'
                onClick={()=>{
                    openModel()
                }}
                >
                {params?.description}
                </div>
            </>
        )
    }
    const SerialNumber = (params) => {
        let serialList = [];
        params?.serial?.forEach((item) => {
            serialList.push(item?.serialNumber ? item?.serialNumber : item?.label);
        });
        return (
            <>
                <div>{serialList.join(", ")}</div>
            </>
        );
    };
    
    const qtyrender = (params) =>{
        return<>
        {Number(params?.qty)?.toFixed(0)}
        </>
    }
   
    const Taxcellrender = params => {
        return (
        <>
        {params?.sgst > 0 ? `SGST ${Number(params?.sgst)?.toFixed(0)}% |` : ''}{' '}
        {params?.cgst > 0 ? `CGST ${Number(params?.cgst)?.toFixed(0)}%` : ''}{' '}
        {params?.igst > 0 ? `IGST ${Number(params?.igst)?.toFixed(0)}%` : ''}
        </>
        )
    }
    // pos table columns
    const ratecellrender = params => {
        let discount_percentage =
        params?.discountPercentage !== undefined
            ? params?.discountPercentage
            : 0
        let discount_value =
        params?.discountValue !== undefined ? params?.discountValue : 0
        let final_value =
        params?.finalValue !== undefined ? params?.finalValue : 0
    return (
        <>
            {Number(params?.rate).toFixed(0)}
            {Number(discount_percentage) !== 0
            ? -params?.discountPercentage +
                '  %  = ' +
                Number(params?.discountValueForPerItem).toFixed(2)
            : Number(discount_value) !== 0
            ? '-' +
                Number(params?.discountValue).toFixed(2) +
                ' = ' +
                Number(params?.discountValueForPerItem).toFixed(2)
            : Number(final_value) !== 0
            ? ' - ' +
                Number(params?.finalValue).toFixed(2)+
                ' = ' +
                Number(params?.discountValueForPerItem).toFixed(2)
            : ''}
        </>
    )
    }
    const ActionButtonCellrender =params=>{

        function handledeleteConfomation() {
            setPosValues(prev=>({...prev, deleteData:{Name:params?.description, id:params?.index} ,deleteWaring:true }))
        }
        return(
            <>
            <button
                type='button'
                className='btn btn-outline-danger btn-sm px-2 mx-2 cursor-pointer'
                onMouseDown={handledeleteConfomation}
                disabled={
                    params?.['stockReduce'] ? true : params?.['isCanceled'] ? true : false
                }
                >
                <i className='fa-solid fa-trash ' style={{fontSize:"1rem"}}></i>
                </button>
            </>
        )
    }

    // item Table columns
    const itemDetails_columns=[
        {
            headerName:"Part Code - Name",
            field:"description",
            flex:10,
            cellRenderer:descriptionUpdate,
            textAlign:"left",
        },
        {
            headerName:"Qty",
            field:"qty",
            flex:2,
            cellRenderer:qtyrender
        },
        {
            headerName:"Batch",
            field:"batch",
            label:"batchNumberName",
            flex:.1,
        },
        {
            headerName: 'Serial',
            field: 'serial',
            cellRenderer: SerialNumber,
            flex: 1,
        },
        {
            headerName: 'Rate',
            field: 'rate',
            cellRenderer: ratecellrender,
            flex: 7,
            textAlign:"right",
        },
        {
            headerName: 'Amount',
            field: 'amount',
            flex: 1,
            textAlign:"right",
        },
        {
            headerName: 'Tax',
            active:posValues?.isSales,
            cellRenderer: Taxcellrender,
            field: 'amount',
            flex: 1.8,
        },
        { 
            headerName: 'Action',
            cellRenderer: ActionButtonCellrender,
            flex: .03
        }
    ]

    // return null ; clear already selected value depend on supplier id get address
    async function getAddress (params, setFieldValue) {
        if (params) {
            setPosValues(prev=>({...prev, billingAddress:null, deliverAddress:null,contactNumber:null,
                contactPerson:null,customerGst:null}))
            setposList(prev=>({...prev, billingAddresses:[], deliverAddresses:[],contactPersons:[]  }))
            
            let responseData = await OnchangeGetData(SupplierEdit(params))
            if (responseData.success) {
                if (responseData.data.supplierFormData.items[0]?.['gstin']) {
                    setPosValues(prev=>({...prev, customerGst:responseData.data.supplierFormData.items[0]?.['gstin']}))
                }
                if (responseData.data.supplierFormData.items[0].address.length > 0) {
                    responseData.data.supplierFormData.items[0].address.forEach(Address => {
                        if (Address.addressType === 'Billing Address') {
                            let AddressLable = `${Address?.addressLine1}, ${Address?.addressLine2}, ${Address?.city}, ${Address?.state}, ${Address?.country}`;
                            setPosValues(prev => ({
                                ...prev, 
                                billingAddress: { value: Address?.id, label: AddressLable, State: Address?.state }, 
                                states: Address?.state
                            }));
                            setposList(prev => ({
                                ...prev, 
                                billingAddresses: [{ value: Address?.id, label: AddressLable, State: Address?.state }]
                            }));
                            setFieldValue("BillingAddress", Number(Address?.id));
                            onChangeTypeChangeGSTValue(true, Address?.state);
                        } else if (String(Address.addressType) === 'Shipping Address') {
                            let AddressLable = `${Address?.addressLine1}, ${Address?.addressLine2}, ${Address?.city}, ${Address?.state}, ${Address?.country}`;
                            setPosValues(prev => ({
                                ...prev, 
                                deliverAddress: { value: Address?.id, label: AddressLable, State: Address?.state }
                            }));
                            setposList(prev => ({
                                ...prev, 
                                deliverAddresses: [{ value: Address.id, label: AddressLable }]
                            }));
                            setFieldValue('DeliverAddress', Address?.id);
                        }
                    });
                    
                }
                let salesPerson =
                responseData.data.supplierFormData.items[0]['salesPerson']
                if (salesPerson !== '') {
                    setPosValues((prev)=>({...prev, SalesPerson:{value: salesPerson?.id,label: salesPerson?.username}}))
                }
                let contact_person_options =
                responseData.data.supplierFormData.items[0]['contact']
                // default
                let defaul_context = contact_person_options.filter(
                itemContext => itemContext.default === true
                )
                if (defaul_context[0]) {
                    setPosValues(prev=>({...prev, contactPerson:{ value: defaul_context[0]['id'],label: defaul_context[0]['contactPersonName'],
                        phone: defaul_context[0]['phoneNumber']}, contactNumber:defaul_context[0]['phoneNumber']}))
                        initialValue['contactPerson'] =defaul_context[0]['id']
                        setFieldValue("contactPerson", Number(defaul_context?.[0]?.['id']))
                }
                
                setposList(prev=>({...prev, contactPersons:contact_person_options.map(item => ({
                    label: item?.contactPersonName,
                    value: item?.id,
                    phone: item?.phoneNumber
                    }))}))
            }
        } else {
            setposList(prev=>({...prev, billingAddresses:[], deliverAddresses:[], contactPersons:[]}))
            setPosValues(prev=>({...prev, billingAddress:null, deliverAddress:null, contactNumber:null, contactPerson:null}))
            
        }
    }

    // return Objects; split the gst rate
    function toGetTax(itemData, gst_rate, statedata = null, isSales=null){
    
        
        let checkIssales = isSales !== null  ? isSales : posValues?.isSales ? true : false
        if (checkIssales ){
            if (statedata === 'Tamil Nadu'){
                itemData['sgst'] = Number(gst_rate / 2)
                itemData['cgst'] = Number(gst_rate / 2)
                itemData['igst'] = 0
            } else{
                itemData['igst'] = Number(gst_rate)
                itemData['sgst'] = 0
                itemData['cgst'] = 0
            }
            return itemData
        } else{
            itemData['sgst'] = 0
            itemData['cgst'] = 0
            itemData['igst'] = 0
            return itemData
        }

    }

    // return null ; on item save or delete if discount is applied cleared call the clear discount
    function callClearDiscount(itemDetails=[], otherCharges=[]) {
        if (  Number(discountValue?.discountPercentage) > 0 || Number(discountValue?.discountValue) > 0 || Number(discountValue?.discountTotalValue) > 0) {
            clearDiscount(itemDetails, otherCharges)
        }
    }
    // return null ; on delete we get some error in callClearDiscount so we modifi the callClearDiscount
    function callClearDiscountOnlyOnDelete(itemDetails, otherCharges){
         if (  Number(discountValue?.discountPercentage) > 0 || Number(discountValue?.discountValue) > 0 || Number(discountValue?.discountTotalValue) > 0) {
            let itemList  =  itemDetails !== null? itemDetails : posList?.posItemData
            let chargesList =[]
            if (Number(otherCharges?.[0]?.amount) > 0 && initialValue?.isSales){
                chargesList = otherCharges !== null? otherCharges : otherIncomeInputs
            } else if(Number(otherIncomeInputs[0]?.amount) <= 0 && initialValue?.isSales ){
                chargesList = otherIncomeInputs
            }
            
            
            let Discountclear = itemList?.map((item) => {
                let amount =  item?.rate * item?.qty
                setDiscountValue({
                    discountError: "", discountPercentage: "", discountValue: "",
                    discountTotalValue: "", isDiscountApplied: false
                    })
                return { ...item,discountValueForPerItem:null,
                    discountValue:null, amount:amount, finalValue:null, discountPercentage:null,
                }
            })
            if (Number(chargesList?.[0]?.amount) > 0 && initialValue?.isSales){
                let updateDiscountOtherIncome = chargesList?.map((item) => {
                    return {
                        ...item,
                        afterDiscountValue:null,
                        discountValue:null,
                        };
            });
                setOtherIncomeInputs(updateDiscountOtherIncome)
            } else if(Number(chargesList?.[0]?.amount) <= 0 && initialValue?.isSales){
                setOtherIncomeInputs(chargesList)
            }
            setposList(prev=>({...prev, posItemData:Discountclear}))
            showErrorToast(true, 'success', "", "Discount Clear Successfully")
        }
    }
    

    // Return Objcet; Save  The Batch Number Item local
    function saveLocalyBatchNumber(params, getGstRate) {
        params['discountValueForPerItem'] = '0'
        params['discountPercentage'] = '0'
        params['finalValue'] = '0'
        params['discountValue'] = '0'
        let saveData;
        if (posValues?.isSales){
            saveData = toGetTax(params, getGstRate, posValues?.states, true )
        } else{
            saveData = toGetTax(params, getGstRate)
        }
        
        let alreadyExistIds= posList?.posItemData?.map((item)=>item?.index)
        let isEditData = alreadyExistIds?.includes(saveData?.index)
        if (!isEditData){
            let duplist = posList?.posItemData?.filter(item=>Number(item?.partCode?.id) === Number(saveData?.partCode?.id))?.filter(item=>item?.batch?.id === saveData?.batch?.id)
            let isDep = duplist?.length > 0 ? true : false
            
            if (isDep){
                let dupDataUpdate = duplist[0]
                dupDataUpdate["qty"] = Number(dupDataUpdate["qty"]) + Number(saveData['qty'])
                dupDataUpdate['amount'] = Number(dupDataUpdate["qty"] * dupDataUpdate['rate'])
                let itemDetails = posList?.posItemData?.map(item=>{
                    return item?.index === dupDataUpdate?.index ? dupDataUpdate : item
                })
                setposList(prev => ({...prev, posItemData : itemDetails}));
                callClearDiscount(itemDetails)
                setPosValues(prev =>({...prev, posItemDetailsIndex: saveData?.index-1}))
                return "Update Successfully"
            } else{
                let itemDetails = [saveData, ...posList?.posItemData]
                callClearDiscount(itemDetails)
                setposList(prev => ({...prev, posItemData:itemDetails}));
                return "Add Successfully"
            }
        
        } else{
            let itemDetails = posList?.posItemData?.map(item=>{
                return item?.index === saveData?.index ? saveData : item
            }) 
            
            setposList(prev => ({...prev, posItemData: itemDetails}));
            callClearDiscount(itemDetails)
            return "Update Successfully"
        }
    }
    // Return Objcet; Save  The Serial Number Item local
    function saveSerialNumberinlocal(params,getGstRate) {
        params['discountValueForPerItem'] = '0'
        params['discountPercentage'] = '0'
        params['finalValue'] = '0'
        params['discountValue'] = '0'
        let saveData;
        if (posValues?.isSales){
            saveData = toGetTax(params, getGstRate, posValues?.states, true )
        } else{
            saveData = toGetTax(params, getGstRate)
        }
        
        let alreadyExistIds= posList?.posItemData?.map((item)=>item?.index)
        let isEditData = alreadyExistIds?.includes(saveData?.index)
        if (!isEditData){
            let duplist = posList?.posItemData?.filter(item=>Number(item?.partCode?.id) === Number(saveData?.partCode?.id))
            if (duplist.length > 0){
                // before work this check the batch and noBS
                showErrorToast(true,"success", "", "This Item Already exit in Table Serial Number ")
                return true
            } else{
                let itemDetails = [saveData, ...posList?.posItemData]
                setposList(prev => ({...prev, posItemData:itemDetails}));
                callClearDiscount(itemDetails)
                return true
            }
        } else{
            let itemDetails = posList?.posItemData?.map(item=>{
                return item?.index === saveData?.index ? saveData : item
            })
            callClearDiscount(itemDetails)
            setposList(prev => ({...prev, posItemData:itemDetails}));
            return true
        }
    }
    // Return Objcet; Save  The NoneBS local
    function saveLocalyNoneBS(params, getGstRate) {
        params['discountValueForPerItem'] = '0'
        params['discountPercentage'] = '0'
        params['finalValue'] = '0'
        params['discountValue'] = '0'
        let saveData;
        if (posValues?.isSales){
            saveData = toGetTax(params, getGstRate, posValues?.states, true )
        } else{
            saveData = toGetTax(params, getGstRate)
        }
        
        
        
        let alreadyExistIds= posList?.posItemData?.map((item)=>item?.index)
        let isEditData = alreadyExistIds?.includes(saveData?.index)
       
       
        if(!isEditData){
            let duplist = posList?.posItemData?.filter(item=>Number(item?.partCode?.id) === Number(saveData?.partCode?.id))
            if (duplist?.length > 0 ){
                let dupDataUpdate = duplist[0]
                dupDataUpdate["qty"] = Number(dupDataUpdate["qty"]) + Number(saveData['qty'])
                dupDataUpdate['amount'] = Number(dupDataUpdate["qty"] * dupDataUpdate['rate'])
                let itemDetails  =posList?.posItemData?.map(item=>{
                    return item?.index === dupDataUpdate?.index ? dupDataUpdate : item
                })
                
                setposList(prev => ({...prev, posItemData: itemDetails}));
                callClearDiscount(itemDetails)
                setPosValues(prev =>({...prev, posItemDetailsIndex: saveData?.index-1}))
                return "Update Successfully"
            }else{
                let itemDetails =  [saveData, ...posList?.posItemData]
                setposList(prev => ({...prev, posItemData:itemDetails}));
                callClearDiscount(itemDetails)
                return "Add Successfully"
            }
        }else{
            let itemDetails = posList?.posItemData?.map(item=>{
                return item?.index === saveData?.index ? saveData : item
            }) 
            setposList(prev => ({...prev, posItemData: itemDetails}));
            callClearDiscount(itemDetails)
            return "Update Successfully"
        }
    }

    function onChangeTypeChangeGSTValue(isSales, state=null) {
        if (isSales) {
            // For Sales Transactions: Calculate GST based on the gstRate of each item
            let salesItem = posList.posItemData?.map((item) => {
                return toGetTax(item, item?.gstRate, state,true); // Assuming toGetTax uses gstRate to calculate GST
            });
            let othercharges = otherIncomeInputs?.map((item)=>{
                return toGetTax(item, item?.tax, state,true)
            })
            
            // Update posList with new salesItem data
            setposList((prev) => ({ ...prev, posItemData: salesItem }));
            callClearDiscount(salesItem)
            setOtherIncomeInputs(othercharges)
    
        } else {
            // For Non-Sales Transactions (e.g., Purchases, Returns, etc.):
            let nonSalesItem = posList.posItemData?.map((item) => {
                // Assuming you need to calculate GST differently for non-sales items, adjust as needed
                return toGetTax(item, item?.gstRate,state , false); // Replace with logic for non-sales
            });
            // Update posList with new non-sales item data
            setposList((prev) => ({ ...prev, posItemData: nonSalesItem }));
            callClearDiscount(nonSalesItem)
        }
    }
    
    let timeoutId =""
    let updateValue = ""
    // return null; on scan the value update the data in states
    const handlescannerValueChange = value => {
        updateValue = updateValue + value
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
        setPosValues(prev=>({...prev, scannerValue:updateValue}))
        }, 300)
    }
    // return  null; get scanned data update the item details
    let error= ""
    async function fetchScanData() {
        if (posValues?.scannerValue){
            setPosValues(prev=>({...prev, isLoading:true}))
            // fetch item Master Data
            let partcode = posValues?.scannerValue.split('|')[0]
            partcode = partcode.replace(/"/g, '\\"')
            let serialorBatch = posValues?.scannerValue.split('|')[1]
            let itemIndex = posValues?.posItemDetailsIndex+1
            let savedScanerdata = {
                "index": itemIndex,
                "id": "",
                "partCode": {
                    "id": "",
                    "itemPartCode": "",
                    "itemName": "",
                    "batchNumber": false,
                    "serial": false
                },
                "description": "",
                "qty": 0,
                "rate": 0,
                "uom":"",
                "amount": 0,
                "batch": "",
                "serial": [],
                "gstRate": "",
                "modifiedBy": "",
                "createdBy": "",
                "discountValueForPerItem": "0",
                "discountPercentage": "0",
                "finalValue": "0",
                "discountValue": "0",
                "sgst": 0,
                "cgst": 0,
                "igst": 0
            }
            let batchOrSerial = ''
            try{
                let response = await OnchangeGetData(itemMasterDataForPosFetchQuery({
                    key:"itemPartCodeEquals", value:partcode
                }))
                setPosValues(prev=>({...prev, scannerValue:""}))
                let responceData = response.data.itemMaster.items[0]
                    if (responceData){
                        
                        savedScanerdata = {
                            "index": itemIndex,
                            "id": "",
                            "partCode": {
                                "id": Number(responceData['id']),
                                "itemPartCode": responceData?.itemPartCode,
                                "itemName": responceData?.itemName,
                                "batchNumber": responceData?.batchNumber,
                                "serial": responceData?.serial
                            },
                            "hsn":responceData?.itemHsn?.id ? Number(responceData?.itemHsn?.id) : null,
                            "hsnCode": responceData?.itemHsn?.hsnCode,
                            "description":responceData?.['description'],
                            "qty": 1,
                            "rate": (Number(responceData?.['itemMrp'])/ Number(posValues?.currencyRate)).toFixed(0),
                            "uom":responceData?.['itemUom'],
                            "amount": 1 * (Number(responceData?.['itemMrp'])/ Number(posValues?.currencyRate)).toFixed(0),
                            "batch": null,
                            "serial": [],
                            "gstRate": responceData['itemHsn']?.['gstRates']?.['rate'],
                            "createdBy": Number(userId),
                            "discountValueForPerItem": "0",
                            "discountPercentage": "0",
                            "finalValue": "0",
                            "discountValue": "0",
                            "sgst": 0,
                            "cgst": 0,
                            "igst": 0
                        }
                        // Check the batch or serial after get the data then Add it
                        if (responceData.batchNumber) {
                            batchOrSerial = 'batch'
                        } else if (responceData.serial) {
                            batchOrSerial = 'Serial'
                        } else {
                            batchOrSerial = ''
                        }
                    } else{
                        error = "Item Not Found IN Item Master"
                    }
                setPosValues(prev=>({...prev, scannerValue:""}))
            } catch(errore){
                error = "Not Found"
            }
            // fetch the batch and serial number
            if (batchOrSerial === "batch"){
                if (serialorBatch !== "" && serialorBatch !== undefined && serialorBatch !== null){
                    let response = await OnchangeGetData(getBatchOrSerial(savedScanerdata?.partCode?.id,
                        posValues?.store?.value, serialorBatch
                    ))
                    if(response?.success){
                        let responesData = response?.['data']?.['itemStock']?.['items']?.['0']?.['batchNumber']?.['id']
                        if (responesData){
                            savedScanerdata['batch'] =  response?.['data']?.['itemStock']?.['items']?.['0']?.['batchNumber']
                            setPosValues(prev=>({...prev, posItemDetailsIndex: itemIndex}))
                        } else{
                            error = 'Batch Number Not found '
                        }
                    } else{
                        error = 'Batch Number Not found'
                        let errorData = ShowErrorNoties(response.errors)
                        showErrorToast(true,"error", errorData, "")
                    }
                }else {
                    error = 'Enter With Batch Number'
                }
            }else if (batchOrSerial === 'Serial') {
                if (serialorBatch !== '' && serialorBatch !== undefined) {
                    let response = await OnchangeGetData(
                        getBatchOrSerial(
                        savedScanerdata?.partCode?.id,
                        posValues?.store?.value
                        )
                    )
                    if (response.success) {
                        let responseData =
                        response['data']['itemStock']?.['items']?.['0']?.['serialNumber']
                        if (responseData) {
                            let responceSerial = responseData.filter(
                                serialItem => serialItem.serialNumber === serialorBatch
                            )
                            if (responceSerial?.[0]?.['id']) {
                                savedScanerdata['serial'] = [
                                    responceSerial?.[0]
                                ]
                                setPosValues(prev=>({...prev, posItemDetailsIndex: itemIndex}))
                            } else {
                                error = 'Serial Number Not found'
                            }
                            } else {
                                error = 'Serial Number Not found '
                            }
                    } else {
                        error = 'Serial Number Not found '
                        let errorData = ShowErrorNoties(response.errors)
                        showErrorToast(true,"error", errorData, "")
                    }
                } else {
                    error = 'Enter With Serial Number'
                    
                }
            }

            if (!error){
                if (batchOrSerial === 'batch') {
                    
                    let isSaved =  await saveLocalyBatchNumber(savedScanerdata, savedScanerdata?.['gstRate'])
                    if (isSaved){
                        setPosValues(prev=>({...prev, isLoading:false}))
                    }
                } else if(batchOrSerial === "Serial"){
                    let isSaved =  await saveSerialNumberinlocal(savedScanerdata, savedScanerdata?.['gstRate'])
                    if (isSaved){
                        setPosValues(prev=>({...prev, isLoading:false}))
                    }
                } else{
                    setPosValues(prev=>({...prev, posItemDetailsIndex: itemIndex}))
                    delete savedScanerdata['batch']
                    let isSaved =  await  saveLocalyNoneBS(savedScanerdata, savedScanerdata?.['gstRate'])
                    if (isSaved){
                        setPosValues(prev=>({...prev, isLoading:false}))
                    }
                }
            } else{
                setPosValues(prev=>({...prev, isLoading:false}))
                showErrorToast(true,"error", error, "")
            }
            
        }
        
    }

    // return null ; update the Receive Amount
    const handleCurrentReceivePay=(Receive)=>{
        setPosValues(prev=>({...prev, received:Receive}))
    }
    // return null; pass the data to commanOverallDiscountValue it retun the
    // discount value then update in posList
    const commanOverAllDiscountPOS=(discountValue)=>{
        let preparitemData = posList?.posItemData?.map((item)=>{
            return{index : item?.index, rate:item?.rate}
        })
        let prepareOtherIncome=[]
        if (Number(otherIncomeInputs[0]?.amount) > 0 && initialValue?.isSales){
            prepareOtherIncome = otherIncomeInputs?.map((item) => {
                return { index: item?.index, rate: item?.amount }
            })
        }

        let result;
        if (posValues?.isSales){
            result = commanOverallDiscountValue(preparitemData, prepareOtherIncome, discountValue , Number(posValues?.toPay) )
        }else{
            result = commanOverallDiscountValue(preparitemData, prepareOtherIncome, discountValue ,Number(posValues?.toPay))
        }
        let updateDiscount = posList?.posItemData?.map((item) => {
            // Create a hash map of itemDetails by their index
            const itemDetailsMap = result?.itemDetails?.reduce((acc, item_with_dc) => {
                acc[item_with_dc?.index] = item_with_dc;
                return acc;
            }, {});
        
            // Find the corresponding item in itemDetails using the index
            const item_with_discount = itemDetailsMap[item?.index];
        
            if (item_with_discount) {
                // If a matching item is found, calculate the amount and return the updated item
                
                let amount = (item_with_discount?.discountRate * item?.qty).toFixed(2);
                return {
                    ...item,
                    amount: amount,
                    discountValueForPerItem: Number(item_with_discount?.discountRate)?.toFixed(2),
                    discountValue: Number(item_with_discount?.discountValue)?.toFixed(2),
                };
            }
        
            // If no matching item is found, return the original item
            return item;
        });
        if (Number(otherIncomeInputs[0]?.amount) > 0 && initialValue?.isSales){
            let updateDiscountOtherIncome = otherIncomeInputs?.map((item) => {
                // Create a hash map of itemDetails by their index
                const otherDetailsDetailsMap = result?.otherIncomeCharge?.reduce((acc, item_with_dc) => {
                    acc[item_with_dc?.index] = item_with_dc;
                    return acc;
                }, {});
                // Find the corresponding item in itemDetails using the index
                const item_with_discount = otherDetailsDetailsMap[item?.index];
                if (item_with_discount) { 
                    
                  // If a matching item is found, calculate the amount and return the updated item
                    return {
                        ...item,
                        afterDiscountValue: Number(item_with_discount?.discountRate).toFixed(2),
                        discountValue: Number(item_with_discount?.discountValue).toFixed(2)
                    };
                }
                // If no matching item is found, return the original item
                return item;
            });
            setOtherIncomeInputs(updateDiscountOtherIncome)
        }
        setposList(prev=>({...prev, posItemData:updateDiscount}))
        setDiscountValue((prev)=>({...prev, isDiscountApplied:true}))
        showErrorToast(true, 'success', "", "Discount Applied Successfully")
        setPosValues(prev=>({...prev, isShowDisCount:false}))
    }
    // return null; pass the data to CommanFinalDiscountValue it retun the
    // discount value then update in posList
    const commanFinalDiscountPOS=(finalValue)=>{
        let preparitemData = posList?.posItemData?.map((item)=>{
            return{index : item?.index, rate:item?.rate}
        })
        let prepareOtherIncome = []
        if(Number(otherIncomeInputs[0]?.amount) > 0 && initialValue?.isSales){
            prepareOtherIncome = otherIncomeInputs?.map((item) => {
                return { index: item?.index, rate: item?.amount }
            })
        }
       
        let result ;
        if (posValues?.isSales){
            result = CommanFinalDiscountValue(preparitemData,prepareOtherIncome,finalValue,  Number(posValues?.toPay))
        } else{
            result = CommanFinalDiscountValue(preparitemData,prepareOtherIncome,finalValue,  posValues?.fixedTotalAmount)
        }
        
        let updateDiscount = posList?.posItemData?.map((item) => {
            // Create a hash map of itemDetails by their index
            const itemDetailsMap = result?.itemDetails?.reduce((acc, item_with_dc) => {
                acc[item_with_dc?.index] = item_with_dc;
                return acc;
            }, {});
        
            // Find the corresponding item in itemDetails using the index
            const item_with_discount = itemDetailsMap[item?.index];
        
            if (item_with_discount) {
                // If a matching item is found, calculate the amount and return the updated item
                let amount = (item_with_discount?.discountRate * item?.qty).toFixed(2);
                return {
                    ...item,
                    amount: amount,
                    discountValueForPerItem: Number(item_with_discount?.discountRate).toFixed(2),
                    finalValue: Number(item_with_discount?.discountValue).toFixed(2),
                };
            }
        
            // If no matching item is found, return the original item
            return item;
        });
        if (Number(otherIncomeInputs[0]?.amount) > 0 && initialValue?.isSales){
            let updateDiscountOtherIncome = otherIncomeInputs?.map((item) => {
      // Create a hash map of itemDetails by their index
                const otherDetailsDetailsMap = result?.otherIncomeCharge?.reduce((acc, item_with_dc) => {
                    acc[item_with_dc?.index] = item_with_dc;
                    return acc;
                }, {});

                // Find the corresponding item in itemDetails using the index
                const item_with_discount = otherDetailsDetailsMap[item?.index];

                if (item_with_discount) {
                    // If a matching item is found, calculate the amount and return the updated item
                    return {
                    ...item,
                    afterDiscountValue: Number(item_with_discount?.discountRate).toFixed(2),
                    discountValue: Number(item_with_discount?.discountValue).toFixed(2)
                    };
                }

                // If no matching item is found, return the original item
            return item;
            });
            setOtherIncomeInputs(updateDiscountOtherIncome)
        }
            
        setposList(prev=>({...prev, posItemData:updateDiscount}))
        setDiscountValue((prev)=>({...prev, isDiscountApplied:true}))
        showErrorToast(true, 'success', "", "Discount Applied Successfully")
        setPosValues(prev=>({...prev, isShowDisCount:false}))
    }

    const commanPercentageDiscountPOS=(Precentage)=>{
        setPosValues(prev=>({...prev, isLoading:true}))
        let preparitemData = posList?.posItemData?.map((item)=>{
            return{index : item?.index, rate:item?.rate}
        })
        let prepareOtherIncome = []
        if (Number(otherIncomeInputs[0]?.amount) > 0 && initialValue?.isSales){
            prepareOtherIncome = otherIncomeInputs?.map((item) => {
                return { index: item?.index, rate: item?.amount }
                })
        }
        
        let result = commanPercentageDiscount(preparitemData,prepareOtherIncome,Precentage)
        let updateDiscount = posList?.posItemData?.map((item) => {
            // Create a hash map of itemDetails by their index
            const itemDetailsMap = result?.itemDetails?.reduce((acc, item_with_dc) => {
                acc[item_with_dc?.index] = item_with_dc;
                return acc;
            }, {});
        
            // Find the corresponding item in itemDetails using the index
            const item_with_discount = itemDetailsMap[item?.index];
        
            if (item_with_discount) {
                // If a matching item is found, calculate the amount and return the updated item
                let amount = (item_with_discount?.discountRate * item?.qty).toFixed(2);
                return {
                    ...item,
                    amount: amount,
                    discountValueForPerItem: Number(item_with_discount?.discountRate).toFixed(2),
                    discountPercentage: Precentage,
                };
            }
        
            // If no matching item is found, return the original item
            return item;
        });
        if (Number(otherIncomeInputs[0]?.amount) > 0 && initialValue?.isSales){
                let updateDiscountOtherIncome = otherIncomeInputs?.map((item) => {
              // Create a hash map of itemDetails by their index
              const otherDetailsDetailsMap = result?.otherIncomeCharge?.reduce((acc, item_with_dc) => {
                acc[item_with_dc?.index] = item_with_dc;
                return acc;
              }, {});
        
              // Find the corresponding item in itemDetails using the index
              const item_with_discount = otherDetailsDetailsMap[item?.index];
        
              if (item_with_discount) {
                // If a matching item is found, calculate the amount and return the updated item
                return {
                  ...item,
                  afterDiscountValue: Number(item_with_discount?.discountRate).toFixed(2),
                  discountValue: Number(item_with_discount?.discountValue).toFixed(2)
                };
              }
        
              // If no matching item is found, return the original item
              return item;
            });
        setOtherIncomeInputs(updateDiscountOtherIncome)
        }
        
        setposList(prev=>({...prev, posItemData:updateDiscount}))
        setDiscountValue((prev)=>({...prev, isDiscountApplied:true}))
        showErrorToast(true, 'success', "", "Discount Applied Successfully")
        setPosValues(prev=>({...prev, isShowDisCount:false, isLoading:false}))
    }
    // amount  after_discount_value_for_per_item  discount_value

    // disCountModel
    function applyDisCount() {
        if (discountValue?.discountPercentage){
            commanPercentageDiscountPOS(discountValue?.discountPercentage)
        } else if(discountValue?.discountValue){
            commanOverAllDiscountPOS(discountValue?.discountValue)
        } else if(discountValue?.discountTotalValue){
            commanFinalDiscountPOS(discountValue?.discountTotalValue)
        }
    }

    function clearDiscount(itemDetails, otherCharges) {
        let itemList  =  itemDetails?.length > 0? itemDetails : posList?.posItemData
        let chargesList =[]
        if (Number(otherIncomeInputs[0]?.amount) > 0 && initialValue?.isSales){
            chargesList = otherCharges?.length > 0? otherCharges : otherIncomeInputs
        } 
        
        
        let Discountclear = itemList?.map((item) => {
            let amount =  item?.rate * item?.qty
            return { ...item,discountValueForPerItem:null,
                discountValue:null, amount:amount, finalValue:null, discountPercentage:null,
            }
        })
        setDiscountValue({
            discountError: "", discountPercentage: "", discountValue: "",
            discountTotalValue: "", isDiscountApplied: false
            })
        if (Number(otherIncomeInputs[0]?.amount) > 0 && initialValue?.isSales){
            let updateDiscountOtherIncome = chargesList?.map((item) => {
                return {
                    ...item,
                    afterDiscountValue:null,
                    discountValue:null,
                    };
        });
            setOtherIncomeInputs(updateDiscountOtherIncome)
        }
      
        
        setposList(prev=>({...prev, posItemData:Discountclear}))
        showErrorToast(true, 'success', "", "Discount Clear Successfully")
        
    }
    // return null; pos Save functions
    const handleSubmit = async(values, { resetForm, setErrors, setSubmitting })=>{
        setPosValues(prev=>({...prev, isLoading:true}))
        let errorONSave = ""
        posValues['balance'] = (posValues?.toPay - posValues?.received).toFixed(2) ? (posValues?.toPay - posValues?.received).toFixed(2) : ""
        
        if (Number(posValues?.balance)!== 0.0 && posValues?.pending){
            errorONSave = ""
        } else if(Number(posValues?.balance) ===  0.0){
            errorONSave = ""
        } else if (Number(posValues?.balance) < 0.0) {
            errorONSave = 'Check the Balance'
        } else {
            errorONSave = 'Please Close The Payment Or Enable The Pending'
        }
        if (errorONSave){
            showErrorToast(true, "error", errorONSave)
            setPosValues(prev=>({...prev, isLoading:false}))
            return
        } 
        
        let saveData = {
            posType: posValues?.isSales ? 'Sales' : 'Sample',
            OrderDate: posValues?.orderDate ? posValues?.orderDate : getCurrentDate(),
            Mobile: values['Mobile'] ? values['Mobile'] : '',
            WhatsappNumber: values['WhatsappNumber'] ? values['WhatsappNumber'] : '',
            CosName: posValues?.isSales?  posValues?.selectcostomerName?.label : posValues?.costomerName ? posValues?.costomerName : '',
            Email: values['Email'] ? values['Email'] : '',
            status: "Save",
            district: posValues?.city ? posValues?.city : '',
            State: posValues?.states ? posValues?.states : '',
            pincode: posValues?.pincode?.value ? Number(posValues?.pincode?.value) : '',
            store: posValues?.store?.value ? Number(posValues?.store?.value) : '',
            sampleContactLink: posValues?.samplelinkedCostomerId
                ? Number(posValues?.samplelinkedCostomerId)
                : '',
            customerName: values?.['CostomerName']
                ? Number(values?.['CostomerName'])
                : '',
            BillingAddress: values?.['BillingAddress']
                ? Number(values?.['BillingAddress'])
                : '',
            DeliverAddress: values?.['DeliverAddress']
                ? Number(values?.['DeliverAddress'])
                : '',
            contactPerson : posValues?.contactPerson?.value ? Number(posValues?.contactPerson?.value) : "",
            SalesPerson : values?.['SalesPerson'] ? Number(values?.['SalesPerson']) : '',
            OverallDiscountPercentage: discountValue?.discountPercentage
                ? discountValue?.discountPercentage
                : "",
            OverallDiscountValue: discountValue?.discountValue ? discountValue?.discountValue : "",
            DiscountFinalTotal: discountValue?.discountTotalValue ? discountValue?.discountTotalValue : "",
            TotalAmount:posValues?.fixedTotalAmount ? posValues?.fixedTotalAmount : "",
            balanceAmount: (posValues?.toPay - posValues?.received).toFixed(2) ? (posValues?.toPay - posValues?.received).toFixed(2) : '',
            receivedAmount: posValues?.received ? posValues?.received : 0,
            igst: Object.keys(posValues?.igstTax).length > 0 ? posValues?.igstTax : {},
            sgst: Object.keys(posValues?.sgstTax).length > 0 ? posValues?.sgstTax : {},
            cgst: Object.keys(posValues?.cgstTax).length > 0 ? posValues?.cgstTax : {},
            isDelivered: posValues?.isDelivered,
            Pending: posValues?.pending,
            FinalTotalValue: posValues?.toPay ? posValues?.toPay : '0.00',
            Remarks: posValues?.remark,
            marketingEvent: values['marketingEvent']
                ? Number(values['marketingEvent'])
                : '',
            itemDetails: [],
            payment: [],
            otherIncomeCharge:[],
            createdby: initialValue?.id ? initialValue?.createdBy ? Number(initialValue?.createdBy) :Number(userId) : Number(userId),
            modifiedBy: initialValue?.id ?  Number(userId) : "",
            id: initialValue?.id  ? initialValue?.id : ""
        }
        let itemDetails = posList?.posItemData?.map((item)=>{
            delete item['index']
            
            if (typeof item['createdBy'] === "object"){
                item["createdBy"] = item?.['createdBy']?.['id'] ? Number(item?.['createdBy']?.['id']):""
            }
            item['amount'] =  item?.['amount'] ? `${item?.['amount']}` :item?.['amount']
            item['discountPercentage'] = item?.['discountPercentage']? `${item?.['discountPercentage']}`: item?.['discountPercentage']
            item['discountValue'] = item?.['discountValue'] ? `${item?.['discountValue']}`: item?.['discountValue']
            item['discountValueForPerItem'] = item?.['discountValueForPerItem'] ? `${item?.['discountValueForPerItem']}` : item?.['discountValueForPerItem']
            item['finalValue'] = item?.['finalValue'] ? `${item?.['finalValue']}` : item?.['finalValue']
            delete item['hsnCode']
            return {...item, partCode:Number(item?.partCode?.id),
                batch:item?.batch?.id ? Number(item?.batch?.id):null,
                uom:Number(item?.uom?.id),
                
            }
        })
        let payment = paymentInputs?.map((pay)=>{
            delete pay['index']
            if (pay && pay['payby'] !== null && Number(pay['payby']?.['value']) > 0) {
                pay['payby'] = Number(pay['payby']?.['value']);
                if (typeof pay['createdBy'] === "object") {
                    pay["createdBy"] = pay?.['createdBy']?.['id'] ? Number(pay?.['createdBy']?.['id']) : "";
                }
                return { ...pay };
            }else{
                return ;
            }
        }).filter(item=>item !== "" && item !== null && item !== undefined)
        // replace it after conference 
    //     let payment = paymentInputs
    // ?.map((pay) => {
    //     if (pay && pay['payby'] !== null && Number(pay['payby']?.['value']) > 0) {
    //         const newPay = { ...pay };
    //         delete newPay['index'];
    //         newPay['payby'] = Number(newPay['payby']?.['value']);
    //         if (typeof newPay['createdBy'] === "object") {
    //             newPay['createdBy'] = newPay?.['createdBy']?.['id'] 
    //                 ? Number(newPay['createdBy']?.['id']) 
    //                 : "";
    //         }
    //         return newPay;
    //     }
    //     return null;
    // })
    // .filter(Boolean); // Removes `null`, `undefined`, and `false` values.


        if(Number(otherIncomeInputs[0]?.amount) > 0 && initialValue?.isSales){
            let otherCharges = otherIncomeInputs?.map((charge)=>{
                charge['otherIncomeChargesId'] = Number(charge['account']?.value)
                delete charge['index']
                delete charge['account']
                delete charge['hsnCode']
                return { ...charge };
            })
             
            saveData['otherIncomeCharge'] = otherCharges
        }

        saveData['itemDetails'] = itemDetails
        saveData['payment'] = payment
        
        let allow_null = [
            'Remarks',
            'DeliverAddress',
            'SalesPerson',
            'contactPerson',
            'modifiedBy',
            'Currency',
            'id',
            'customerName',
            'BillingAddress',
            'sampleContactLink',
            'pincode',
            "OverallDiscountPercentage",
            "DiscountFinalTotal",
            "OverallDiscountValue"
        ]
        let allow_mute_line = ['Remarks']
        
        let clearValue = removeEmptyValueInObject(saveData, allow_null, allow_mute_line, false ,['itemDetails',"payment","otherIncomeCharge"] ) 
       
            try{
                const respones = await axiosInstance.post('/itemmaster/graphql', {
                    query: createPOSMode(clearValue)
                })
                const responseData = respones.data.data.salesOrderPosCreateMutation
                if (responseData.success) {
                    updateTheAllState(responseData?.salesOrderPosInstance)
                    showErrorToast(true, "success", "", "Successfully Saved")
                }else {
                    showErrorToast(true, 'error', `${responseData?.errors}`, '')
                }
            } catch(error){
                showErrorToast(true, "error", error)
            }
        setLoad()
        setPosValues(prev=>({...prev, isLoading:false}))
    }
    // return null; Reset the all data
    function clearAllData(){
        setPosValues({status:null, posID:"", isSales:false, isNewContact:false, isShowItemDetails:false,
            isSomeStockReduced:false, marketingEvent:{ value: '', label: '' }, store: { value: '', label: '' }, samplelinkedCostomerId:null,
            costomerName: null, whatsAppNumber:null, email:null, selectMobile:{ value: '', label: '' }, orderDate:null, pincode:null, states:"", city:"",
            SalesPerson:{value: '', label: ''}, selectcostomerName:{value: '', label: ''}, legalName:"", billingAddress:{value: '', label: ''},
            deliverAddress:{value: '', label: ''},contactPerson :{value: '', label: ''}, contactNumber:null, customerGst:null,
            discountPercentageInValue:"", discountValue:"", discountTotalValue:"", currencyRate:0, currencySymbol:"", posItemDetailsIndex:0,
            scannerValue:"", totalItemQty:0, igstTax:[], sgstTax:[],cgstTax:[], totalTax:"0.00", fixedTotalAmount:"0.00",
            toPay:"0.00", roundOFValue:"0.00", received:"0.00", balance:"0.00", discount:"0.00", beforetax:"0.00", isShowPayment:false,
            isShowDisCount:false, pending:false, isDelivered:false, remark:"", StockNeedList:[], isShowStockNeed:false,isAllStockReduced:false,
            isLoading:false})
        setposList({contaces:[], pincodes:[], customers:[],
            billingAddresses:[], deliverAddresses:[],contactPersons:[], partCodeList:[], partNameList:[], posItemData:[]})
        setDiscountValue({
            discountError: "", discountPercentage: "", discountValue: "",
            discountTotalValue: "", isDiscountApplied: false
            })
        setinitialValue({
            id: '',
            marketingEvent: '',
            OrderDate: '',
            isSales: false,
            CostomerName: '',
            BillingAddress: '',
            DeliverAddress: '',
            Store: '',
            Mobile: '',
            WhatsappNumber: '',
            CosName: '',
            Email: '',
            State: '',
            City: '',
            createdBy: '',
            SalesPerson: '',
            payment: []
        })
        setpaymentInputs([{
            index:0,
            id: "",
            payby: {
                label: "",
                value: ""
            },
            payAmount: "",
            balanceAmount: "",
        }])
    }
    // return null; close the form
    function closePosForm (params) {
        let anyChanges = checkDifferentBetweenInitialDataAndCurrentData()
        if (!anyChanges){
            clearAllData()
            setIsShow(false)
            setPosEditId(null)
        } else{
            showErrorToast(true, 'error', "Save The Changes.", '')
        }
        
    }
    // return number; convert percentage to percentagevalue
    function getTotalDiscountPercentage(params) {
        const percentagevalue =
        ((Number(posValues?.fixedTotalAmount) / 100) * Number(params)).toFixed(2)
        return displayRupeeFormat(percentagevalue, posValues?.currencyName)
    }
    // return bool; find different Between Initial Data and Current Data
    function checkDifferentBetweenInitialDataAndCurrentData() {
        let currentValue = {}
        if (initialdata === null){
            return false
        } else{
            if (posValues?.isSales){
                    currentValue['marketingEvent'] = posValues?.marketingEvent?.value ? posValues?.marketingEvent?.value : ""
                    currentValue['Store'] = posValues?.store?.value ? posValues?.store?.value :""
                    currentValue['CosName'] =  posValues?.costomerName ? posValues?.costomerName : ''
                    currentValue['BillingAddress'] = posValues?.billingAddress?.value ? posValues?.billingAddress?.value : ""
                    currentValue['DeliverAddress'] = posValues?.deliverAddress?.value ? posValues?.deliverAddress?.value : ""
                    currentValue['contactPerson'] = posValues?.contactPerson?.value ? posValues?.contactPerson?.value :""
                    currentValue['balanceAmount'] = posValues?.balance ? posValues?.balance : ''
                    currentValue['SalesPerson'] = posValues?.SalesPerson?.value ? posValues?.SalesPerson?.value : ""
                    currentValue['remarksText'] = posValues?.remark ? posValues?.remark : ""
                    currentValue['isDelivered'] = posValues?.isDelivered
                    currentValue['pending'] = posValues?.pending
            } else{
                currentValue['marketingEvent'] = posValues?.marketingEvent?.value ? posValues?.marketingEvent?.value : ""
                currentValue['Store'] = posValues?.store?.value ? posValues?.store?.value :""
                currentValue['CosName'] = posValues?.costomerName ? posValues?.costomerName : ''
                currentValue['Mobile'] = posValues?.selectMobile ? posValues?.selectMobile?.label : ''
                currentValue['WhatsappNumber'] = posValues?.whatsAppNumber ? posValues?.whatsAppNumber : ''
                currentValue['Email'] = posValues?.email ? posValues?.email : ''
                currentValue['State'] = posValues?.states ? posValues?.states : ''
                currentValue['City'] = posValues?.city ? posValues?.city: ''
                currentValue['balanceAmount'] = posValues?.balance ? posValues?.balance : ''
                currentValue['SalesPerson'] = posValues?.SalesPerson?.value ? posValues?.SalesPerson?.value : ""
                currentValue['remarksText'] = posValues?.remark ? posValues?.remark : ""
                currentValue['isDelivered'] = posValues?.isDelivered
                currentValue['pending'] = posValues?.pending
            } 
            
            return checkChangesInputs([initialdata, currentValue])
        }
    }
    // return  bool; Check The stock
    async function CheckTheStock(){
        try{
            let peremeter = `posID: "${initialValue?.id}",`
            const respones = await axiosInstance.post('/itemmaster/graphql', {
            query: checkStockPOSQuery(peremeter)
            })
            const responseData = respones.data.data.checkStock
            return responseData
        } catch(error){
            let filterError = ShowErrorNoties(error)
            showErrorToast(true, 'error', `${filterError}`, '')
            return ""
        }
    }

    async function ReduceStock() {
        if (initialValue?.id){
            setPosValues(prev=>({...prev, isLoading:true}))
        try {
            let peremeter = `posId: "${initialValue?.id}", submitBy: ${userId}`
            const respones = await axiosInstance.post('/itemmaster/graphql', {
                query: SubmitPOSQuery(peremeter)
            })
            const responseData = respones.data.data.salesOrderSubmitMutation
            if (responseData?.success){
                updateTheAllState(responseData?.salesOrderPosInstance)
                setPosValues(prev=>({...prev, status:"Submited"}))
                // need to update the pos
                showErrorToast(true, "success", "", "pos Submit Successfully")
            } else{
                showErrorToast(true, "error", `${responseData?.errors}` , "")
            }
            } catch (error) { 
                
            let filiterError = ShowErrorNoties(error)
            showErrorToast(true, "error", `${filiterError}` , "")
        }
        setPosValues(prev=>({...prev, isLoading:false}))
        setLoad()
        } else{
            showErrorToast(true, 'error', `ID is Null ON submit`, '')
        }
        
    }

    async function AddtionStock(params) {
        if (initialValue?.id){
            setPosValues(prev=>({...prev, isLoading:true}))
            try {
                let peremeter = `posID: "${initialValue?.id}"`
                const respones = await axiosInstance.post('/itemmaster/graphql', {
                    query: CancelPOSQuery(peremeter)
                })
                const responseData = respones.data.data.salesOrderCancleMutation
                if (responseData?.success) {
                    updateTheAllState(responseData?.salesOrderPosInstance)
                  
                    showErrorToast(true, 'success', '', 'pos Cancel Successfully')
                } else {
                    showErrorToast(true, 'error', `${responseData?.errors}`, '')
                }
                } catch (error) {
                let filiterError = ShowErrorNoties(error)
                showErrorToast(true, 'error', `${filiterError}`, '')
                }
            setPosValues(prev=>({...prev, isLoading:false}))
            setLoad()
        } else{
            showErrorToast(true, 'error', `ID is Null ON canceled`, '')
        }
      
    }

    function permissionToStockAddtion (params) {
        if (params) {
            AddtionStock()
        }
    }

    // return null; check the stock data then reduce
    // the stock and make submit the form
    async function SubmitThePos(params) {
        let anyChanges = checkDifferentBetweenInitialDataAndCurrentData()
        if (!anyChanges){
            let stockResult = await CheckTheStock()
            if (!stockResult?.success){
                const rawNeedStock = stockResult?.needStock;
                const needStock = JSON.parse(rawNeedStock);
                if (Array.isArray(needStock)){
                    setPosValues(prev=>({...prev, StockNeedList:needStock, isShowStockNeed:true}))
                } else{
                    setPosValues(prev=>({...prev, StockNeedList:[]}))
                }
            } else if(posValues?.balance > 0){
                showErrorToast(true, 'warn', "Close the payment.", '')
            } else if (posValues?.pending){
                showErrorToast(true, 'warn', 'Mark the pending status as false.', '')
            } else if(!posValues?.isDelivered){
                showErrorToast(true, 'warn', 'Delivery Should be Completed before Submit!', '')
            } else{
                ReduceStock()
            }
        } else{
            showErrorToast(true, 'error', "Save The Changes.", '')
        }
        
    }

    // return  null ; print the data
    function handlePrint(params) {
        const orderDates = new Date(posValues?.orderDate)
        const formattedDate =
            orderDates.getDate().toString().padStart(2, '0') +
            '/' +
            (orderDates.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            orderDates.getFullYear()
        let discount = Number(discountValue?.discountPercentage) > 0? getTotalDiscountPercentage(discountValue?.discountPercentage) :
        Number(discountValue?.discountValue) > 0? dislpayOverallTotalDiscount(): Number(discountValue?.discountTotalValue) > 0?
        dislpayFinalTotalDiscount():(displayRupeeFormat(0.00, posValues?.currencyName))
        // discount
        
        let print_Data = {
            label_name : "pos_label_new",
            data : {
                BillNo: posValues?.posID,
                Date: formattedDate,
                Name: posValues?.costomerName,
                Mobile: posValues?.selectMobile?.label,
                items : posList?.posItemData?.map((item, index)=>({
                    sno: index + 1,
                    Name: item.description,
                    qty: Math.floor(item.qty),
                    rate: Math.floor(item.rate),
                    Amount: Number(item.qty) * Number(item.rate)
                })),
                TotalItem: posValues?.totalItemQty ,
                TotalAmount: displayRupeeFormat(posValues?.fixedTotalAmount, posValues?.currencyName),
                discount:discount,
                BillingAddress: '',
                Taxkey: '',
                TaxAmount: '',
                TotalAfterDiscount: displayRupeeFormat(posValues?.fixedTotalAmount - Number(String(discount).replace(posValues.currencySymbol,"")), posValues?.currencyName),
                Total: displayRupeeFormat(posValues?.toPay, posValues?.currencyName),
                TaxInvoice: '',
                Gst: 'ESTIMATE',
            }
            
        }
        if (posValues?.totalTax > 0) {
            print_Data['data']['Mobile'] = posValues?.selectMobile?.label
            print_Data['data']['BillingAddress'] = posValues?.billingAddress?.label
            print_Data['data']['contactperson'] = posValues?.contactPerson?.label
            // print_Data['data']['CostomerGst'] = posValues?.
            print_Data['data']['TaxInvoice'] = 'Tax Invoice'
            print_Data['data']['Taxkey'] = 'Total Tax'
            print_Data['data']['TaxAmount'] = displayRupeeFormat(posValues?.totalTax, posValues?.currencyName)
            print_Data['data']['Gst'] = '33AAICS8026R1ZQ'
            // print_Data['data']['Name'] = selectLegalName
        }
        const newSocket = io('http://localhost:5000', {
            reconnectionAttempts: 1
        })
        console.log("print_Data", print_Data);
        
        if (newSocket) {
        newSocket.emit('print-data', print_Data)
        newSocket.emit('message', 'Hello from React!')
        }
    }
    // return null; it using for discount, we just reset the values
    function copyPOS(params) {
        setPosValues(prev=>({...prev,status:"",posID:"",received:"0.00", balance:"0.00",pending:true, isDelivered:false, isLoading:true,
            samplelinkedCostomerId:null, costomerName:"",whatsAppNumber:"", email:"", selectMobile:{ value: '', label: '' },
            pincode:{value:"", label:""},states:"", city:"",selectcostomerName:{value: '', label: ''},LegalName:"",billingAddress:null, 
            deliverAddress:null, contactNumber:null, contactPerson:null
        }))
        // setPosEditId(null)
        setDiscountValue({
            discountError: "", discountPercentage: "", discountValue: "",
            discountTotalValue: "", isDiscountApplied: false
            })
        setinitialValue(prev=>({...prev, id:'', CosName:null, WhatsappNumber:"", Email:"",
            Mobile:"",pincode:"",CostomerName:""}))
        let itemDetails = posList?.posItemData?.map((item)=>{
            item["createdBy"] = Number(userId)
            item['id'] = null
            item['discountPercentage'] = "0.00"
            item['discountValue'] = "0.00"
            item['discountValueForPerItem'] = "0.00"
            item['finalValue'] = "0.00"
            item['isCanceled'] = false
            item['stockReduce'] = false
            item['amount'] = item.rate*item.qty
            return {...item}
        })
        setpaymentInputs([{
            index:0,
            id: "",
            payby: {
                label: "",
                value: ""
            },
            payAmount: "",
            balanceAmount: "",
        }])
        setPosPamentValue(prev=>({...prev,currentIndex:0}))
        setposList(prev=>({...prev, posItemData:itemDetails, billingAddresses:[], deliverAddresses:[], contactPersons:[]}))
        setPosValues(prev=>({...prev, isLoading:false, }))
        setFormKey(prev => prev + 1);
    } 
    // return null; after get premission to delete remove the data in list
    function permissionToDelete(params) {
        if (params){
            // Check the structure of deleteData and ensure correct comparison
            let itemDetails = posList?.posItemData?.filter(prev => prev?.index !== posValues?.deleteData?.id);
            
            setposList(prev=>({...prev, posItemData:itemDetails}))
            callClearDiscountOnlyOnDelete(itemDetails, null)
        }
        
    }
    // return  null ; use To displaye Formik error
    function getFormik(params) {
        if (Object.keys(params).length !== 0){
            let filiterError = ShowErrorNoties(params)
            showErrorToast(true, 'warn', filiterError, '')
        }
        
    }
    // return number; cal The total finalValue
    function dislpayFinalTotalDiscount(params) {
        let totalDiscount = 0
        posList?.posItemData?.forEach((item) => {
            const finalValue = item?.finalValue || 0; // Default to 0 if undefined
            const qty = item?.qty || 0; // Default to 0 if undefined
            const totalfinalValue = Number(finalValue) * Number(qty);
            totalDiscount += totalfinalValue;
        });
        
        // Iterate over otherIncomeInputs using forEach for side effects
        otherIncomeInputs?.forEach((item) => {
            const discountValue = Number(item?.discountValue || 0);  // Default to 0 if undefined
            totalDiscount += discountValue;
        });
        return displayRupeeFormat((totalDiscount).toFixed(2), posValues?.currencyName)
    }
    // return number ; cal the total
    function dislpayOverallTotalDiscount(){
        let totalDiscount = 0
        posList?.posItemData?.forEach((item) => {
            const discountValue = item?.discountValue || 0;  // Default to 0 if undefined
            const qty = item?.qty || 0;  // Default to 0 if undefined
            const totalfinalValue = Number(discountValue) * Number(qty);
            totalDiscount += totalfinalValue;
        });
        
        // Calculate discount from otherIncomeInputs using forEach (side-effect)
        otherIncomeInputs?.forEach((item) => {
            const discountValue = Number(item?.discountValue || 0);  // Default to 0 if undefined
            const amount = Number(item?.amount || 0);  // Default to 0 if undefined
            totalDiscount += discountValue > 0 ? discountValue : amount;
        });
        return displayRupeeFormat((totalDiscount).toFixed(2), posValues?.currencyName)
    }
    
    return (
        <>
        
        <ToastContainer/>
        <BallTriangleComponent isshow={posValues?.isLoading}/>
        <Modal
        show={isShow}
        onHide={()=>{setIsShow(false)}}
        fullscreen
        backdrop='static'
        keyboard={false}
        centered>
        <Modal.Body>
            <div
            className='itemMaster_Top mx-3  d-flex justify-content-center card'
            style={{ width: '98%', maxHeight: '5rem', minHeight: '5rem' }}
            >
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='row'>
                        <div className='col-12'>
                            <h3 className='mx-2 commanModelTitleColor d-flex align-items-center'>
                                <i
                                className='fa-solid fa-arrow-left fa-sm me-2' onClick={()=>{closePosForm(true)}}></i>
                                POS{' '}
                                <span className='btn badge rounded-pill text-dark ms-3 fs-6 logoGreen'  >
                                {posValues?.status}
                                </span>
                            </h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12   fs-5" >
                        Event: {posValues?.marketingEvent?.label}
                        </div>
                    </div>
                    <div className='border rounded-pill pt-2 pb-2 ps-3 pe-3 bg-light d-flex align-items-center justify-content-center'>
                        <button
                        type='button'
                        onClick={() => {
                            setPosValues(prev=>({...prev,isSales:true }))
                            onChangeTypeChangeGSTValue(true)
                            setinitialValue(prev=>({...prev, isSales:true}))
                            setFormKey(prev=>prev+1)
                        }}
                        disabled={initialValue?.id? true : posValues?.currencySymbol !== "₹" }
                        className={`btn   badge rounded-pill text-dark    postypeSales px-4  fs-6 ${posValues.isSales? "logoGreen" : "bg-body" }`}
                        >
                        Sales
                        </button>
                        <button
                        type='button'
                        onClick={() => {
                            setPosValues(prev=>({...prev,isSales:false }))
                            onChangeTypeChangeGSTValue(false)
                            setinitialValue(prev=>({...prev, isSales:false}))
                            setFormKey(prev=>prev+1)
                        }}
                        disabled={initialValue?.id}
                        className={`btn   badge rounded-pill text-dark    postypeSales  fs-6 ${!posValues.isSales?  "logoGreen" : "bg-body" }`}
                        >
                        Sample
                        </button>
                    </div>
                <div className="row">
                    <div className="col-12 fs-5"  >
                        Store: {posValues?.store?.label}
                    </div>
                </div>
                <div className=''>
                    <div className=''>
                        <Can I={'Cancel'} a={'POS'}>
                            <TiCancel
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Cancle'
                            hidden = {posValues?.status === "Canceled"? true : initialValue?.id ? false : true}
                            className='fs-2 pb-1  '
                            style={{ color: '#7f7f7f', cursor: 'pointer' }}
                            onClick={() => {
                                if (posValues?.status === 'Submited' || posValues?.status === 'Save') {
                                    setcancelWaring(true)

                                }
                            }}
                            />
                        </Can>
                        <Can I={'Copy'} a={'POS'}>
                            <i
                            className='fa-solid fa-copy  fs-5 pt-1 mx-3 text-success'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            hidden={posValues?.status === "Canceled" ? false : true}
                            title='Duplicate'
                            role='button'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                copyPOS()
                            }}
                            ></i>
                        </Can>
                        <Can I={'Delete'} a={'POS'}>
                            <i
                            className='fa-solid fa-trash mx-3 fs-5 text-danger '
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            hidden={posValues?.status === "Canceled" ? false : true}
                            title='Delete'
                            style={{ cursor: 'pointer' }}
                            // onClick={deletePos}
                            ></i>
                        </Can>
                    </div>
                </div>
                    
                </div>
            </div>
            <div className="mt-3 mx-3" style={{ width: '98%', height: '83vh' }}>
                <Formik initialValues={initialValue}
                validationSchema={PosSalesorderSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                key={formKey}
                >
                {({ errors, submitForm, touched, setFieldValue, values, resetForm, setValues }) => (
                    <>
                        <Form className='row' style={{ width: '100%', height: '100%' }} >
                            {/* customer datas */}
                            <div className="left-side col-3">
                                <div className='customer-details card pt-3' style={{ height: posValues?.isSales ? screenSize?.height > 768 ? "58%" : '53%' : screenSize?.height > 768 ? "67%" :'55%' }}>
                                    <div className="row d-flex justify-content-around">
                                        <div className="col-6 fs-6 fw-bold   commanModelTitleColor d-flex align-items-center"
                                        >
                                        {posValues?.posID}
                                        </div>
                                        <div className="col-6  col-md-5">
                                            <input
                                            type='date'
                                            id='OrderDate'
                                            name='OrderDate'
                                            className=' form-control w-100 fs-6 fw-bold'
                                            style={{ border: 'none', fontSize: '.8rem',  }}
                                            value={posValues?.orderDate}
                                            disabled={
                                                posValues?.status === 'Canceled'
                                                    ? true
                                                    : posValues?.isSomeStockReduced
                                                }
                                            onChange={e => {
                                                setPosValues((prev)=>({...prev, orderDate:e.target.value}))
                                            }}
                                            />
                                        </div>
                                    </div>
                                    {!posValues?.isSales && (
                                    <div className="sample-customer-card  " style={{overflowX:"hidden"}}>
                                        <div className="row mx-2" hidden={!isShowConferenceData} style={{transition:"5", }}>
                                            <div className="col-12 staan-input-group pos_input_group">
                                                <CustomSelect
                                                setSelectEditValue={posValues?.marketingEvent}
                                                options={conferenct}
                                                isClearable
                                                handleOnChange={(option)=>{
                                                    if (option){ 
                                                        
                                                        setPosValues(prev=>({...prev, marketingEvent:  option  , currencyRate:  option?.exchangeRate , currencySymbol:option?.currencySymbol, currencyName:option?.name}))
                                                        setFieldValue('marketingEvent',  Number(option?.value) )
                                                        setinitialValue(prev=>({...prev, marketingEvent:Number(option?.value)}))
                                                    } else{
                                                        setPosValues(prev=>({...prev, marketingEvent:  null  , currencyRate:  0 , currencySymbol:null, currencyName:null}))
                                                        setFieldValue('marketingEvent',  Number(option?.value))
                                                        setinitialValue(prev=>({...prev, marketingEvent:null}))
                                                    }
                                                }}
                                                isDisabled={
                                                    posList?.posItemData?.length > 0 ? true:
                                                    posValues?.status === 'Canceled'
                                                        ? true
                                                        : posValues?.isSomeStockReduced
                                                    }
                                                />
                                                <label
                                                className='staan-form-input-label pt-0 px-1 mx-2'
                                                
                                                >
                                                Marketing Event <span className='text-danger'>*</span>
                                                </label>
                                                {touched.marketingEvent &&
                                                    errors.marketingEvent && (
                                                        <small className='staan-error-msg'>
                                                        {errors.marketingEvent}
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="col-12 staan-input-group pos_input_group">
                                                <CustomSelect
                                                    setSelectEditValue={posValues?.store}
                                                    options={prefetchValue?.storeList}
                                                    handleOnChange={(option)=>{
                                                        setPosValues(prev=>({...prev, store:option? option: null }))
                                                        setFieldValue('Store',option?  Number(option?.value) : null)
                                                    }}
                                                    isDisabled={
                                                        posList?.posItemData?.length > 0 ? true:
                                                        posValues?.status === 'Canceled'
                                                            ? true
                                                            : posValues?.isSomeStockReduced
                                                    }
                                                />
                                                <label className='staan-form-input-label pt-0 px-1 mx-2'>
                                                Store <span className='text-danger'>*</span>
                                                </label>
                                                {touched.Store &&
                                                        errors.Store && (
                                                            <small className='staan-error-msg'>
                                                            {errors.Store}
                                                            </small>
                                                        )}
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-center pt-2  cursor-pointer" onClick={()=>{setIsShowConferenceData(!isShowConferenceData)}}  hidden={isShowConferenceData}>
                                            <p className='col-9 border border-primary'></p>
                                        </div>
                                        <div className="row mx-2">
                                            <div className="col-12 staan-input-group">
                                                <Field
                                                type='text'
                                                name='CosName'
                                                id ="CosName"
                                                disabled={
                                                    posValues.status === 'Canceled'
                                                    ? true
                                                    : !posValues?.isNewContact ? true  : posValues?.isSomeStockReduced
                                                }
                                                value={posValues?.costomerName}
                                                onChange={e => {
                                                    setPosValues(prev=>({...prev , costomerName:e.target.value}))
                                                    setFieldValue('CosName',e.target.value)
                                                }}
                                                placeholder=''
                                                className='w-100 staan-form-input'
                                                />
                                                <label
                                                htmlFor='CosName'
                                                className='staan-form-input-label pt-0 px-1 mx-2'
                                                >
                                                Customer Name <span className='text-danger'>*</span>
                                                </label>
                                                {touched.CosName &&
                                                errors.CosName && (
                                                    <small className='staan-error-msg'>
                                                    {errors.CosName}
                                                    </small>
                                                )}
                                            </div>
                                            <div className="col-12 staan-input-group pos_input_group">
                                                {posValues?.isNewContact ?
                                                <Field
                                                type='number'
                                                name='Mobile'
                                                disabled = {
                                                    posValues?.status === 'Canceled'
                                                    ? true
                                                    : posValues?.isSomeStockReduced
                                                }
                                                className='w-100 staan-form-input'
                                                /> :
                                                <CreatableSelect
                                                onInputChange={(e)=>{ 
                                                    if (e){
                                                        dispatch(fetchOptionsData(contactDetalisFetchQuery(e), "posContactfetch") )
                                                    }
                                                }}
                                                name="Mobile"
                                                isClearable
                                                isDisabled={
                                                    posValues?.status === 'Canceled'
                                                    ? true
                                                    : posValues?.isSomeStockReduced
                                                }
                                                options={posList?.contaces}
                                                isSearchable={true}
                                                value={posValues?.selectMobile}
                                                onCreateOption={(e)=>{
                                                    setFieldValue("Mobile", e )
                                                    setPosValues((prev=>({...prev, isNewContact:true, samplelinkedCostomerId:null,
                                                    })))
                                                }}
                                                onChange={(option) => {
                                                if (option){
                                                    setPosValues((prev=>({...prev, samplelinkedCostomerId:option?.value,
                                                        costomerName:option?.name, whatsAppNumber:option?.whatsappNo, email:option?.whatsappNo,
                                                        selectMobile:option, pincode:{value:option?.pincode?.id, label:option?.pincode?.pincode}
                                                    })))
                                                    if(option?.pincode?.id){
                                                        setFieldValue('pincode',option?.pincode?.id ? option?.pincode?.id : null)
                                                        let peremeter = `pincode:${option?.pincode?.id}, pincodeDistinct:true`
                                                        dispatch(fetchOptionsData(Filiter_address_with_pincode_Query(peremeter), "distri_state"))
                                                    } else{
                                                        setPosValues((prev=>({...prev,pincode:{value:"", label:""}, states:"", city:""
                                                        })))
                                                    }
                                                    setValues(prev=>({...prev, 
                                                        'CosName':option?.name,
                                                        'WhatsappNumber':option?.whatsappNo,
                                                        'Email':option?.email,
                                                        'Mobile': option ? option?.label : null
                                                    }))
                                                } else{
                                                    setPosValues((prev=>({...prev, samplelinkedCostomerId:null,
                                                        costomerName:"", whatsAppNumber:"", email:"",
                                                        selectMobile:{ value: '', label: '' },
                                                        pincode:{value:"", label:""},
                                                        states:"", city:"",
                                                    })))
                                                    setValues(prev=>({...prev, 'CosName':null,
                                                        'WhatsappNumber':null,
                                                        'Email':null,
                                                        'Mobile': null,
                                                        "pincode":null
                                                    }))
                                                }
                                                }}
                                                styles={posCustomSelectStyle}
                                                placeholder="Search Mobile Number. . ."
                                                className='my-3'
                                                />}
                                                <label
                                                    
                                                    className='staan-form-input-label pt-0 px-1 mx-2'
                                                    >
                                                    Mobile Number <span className='text-danger'>*</span>
                                                    <span className='cursor-pointer' style={!posValues?.isNewContact ? {color:" "} 
                                                    : 
                                                    {color:"Highlight"}}
                                                    onClick={
                                                        ()=>{
                                                            setPosValues((prev=>({...prev, samplelinkedCostomerId:null,
                                                                costomerName:"", whatsAppNumber:"", email:"",
                                                                selectMobile:{ value: '', label: ''}, isNewContact:! posValues?.isNewContact,
                                                            })))
                                                            setValues({'CosName':null,'WhatsappNumber':null,'Email':null,'Mobile': null})} }
                                                        >
                                                        {posValues?.isNewContact ? <MdInput className='fs-5'/> : "" }</span>
                                                    </label>
                                                    { touched.Mobile &&
                                                    errors.Mobile && (
                                                        <small className='staan-error-msg'>
                                                        {errors.Mobile}
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="col-12 staan-input-group pos_input_group">
                                                <Field
                                                    type='number'
                                                    name='WhatsappNumber'
                                                    id ="WhatsappNumber"
                                                    value={posValues?.whatsAppNumber}
                                                    disabled={
                                                    posValues?.status === 'Canceled'
                                                        ? true
                                                        : !posValues?.isNewContact ? true  : posValues?.isSomeStockReduced
                                                    }
                                                    onChange={e => {
                                                        setPosValues(prev=>({...prev, whatsAppNumber:e.target.value}))
                                                        setFieldValue('WhatsappNumber', e.target.value)
                                                    }}
                                                    className='w-100 staan-form-input'
                                                />
                                                <label
                                                    htmlFor='WhatsappNumber'
                                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                                    >
                                                    Whatsapp Number
                                                </label>
                                                <span
                                                    style={{
                                                        cursor: 'pointer',
                                                        fontSize: '.7rem'
                                                    }}
                                                    onClick={() => {
                                                        if (values?.Mobile) {
                                                        setFieldValue('WhatsappNumber', values?.Mobile)
                                                        setPosValues(prev=>({...prev, whatsAppNumber:values?.Mobile}))
                                                        }
                                                    }}
                                                    className='text-success p-2'
                                                >
                                                    <i className='fa-solid fa-clone'></i> Same as
                                                    Mobile Number
                                                    </span>
                                                    {touched.WhatsappNumber &&
                                                    errors.WhatsappNumber && (
                                                        <small className='staan-error-msg'>
                                                        {errors.WhatsappNumber}
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="col-12 staan-input-group pos_input_group">
                                                <Field
                                                    type='email'
                                                    name='Email'
                                                    id ="Email"
                                                    disabled={
                                                        posValues?.status === 'Canceled'
                                                            ? true
                                                            : !posValues?.isNewContact ? true  : posValues?.isSomeStockReduced
                                                    }
                                                    value={posValues?.email}
                                                    onChange={e => {
                                                        setPosValues(prev=>({...prev, email:e.target.value}))
                                                        setFieldValue('Email', e.target.value)
                                                    }}
                                                    className='w-100 staan-form-input'
                                                    />
                                                    <label
                                                    htmlFor='Email'
                                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                                    >
                                                Email
                                                </label>
                                                {touched.Email &&
                                                errors.Email && (
                                                    <small className='staan-error-msg'>
                                                    {errors.Email}
                                                    </small>
                                                )}
                                            </div>
                                            <div className="col-6 staan-input-group pos_input_group">
                                                <CustomSelect
                                                setSelectEditValue={posValues?.pincode}
                                                handleInputChange={(e)=>{dispatch(fetchOptionsData(pincodeQuery(e, 1), "posPincodeList")) }}
                                                isDisabled={posValues.status === 'Canceled'
                                                    ? true
                                                    :  posValues?.isSomeStockReduced}
                                                options={posList?.pincodes}
                                                isClearable
                                                handleOnChange={(option) => {
                                                    setPosValues((prev)=>({...prev, pincode:option? option : null}))
                                                    setFieldValue('pincode',option?.value ? option?.value : null)
                                                    let peremeter = `pincode:${option?.value}, pincodeDistinct:true`
                                                    if (option){
                                                        dispatch(fetchOptionsData(Filiter_address_with_pincode_Query(peremeter), "distri_state"))
                                                    }
                                                }}
                                                />

                                                <label
                                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                                    >
                                                    Pincode
                                                </label>
                                                {touched.pincode &&
                                                    errors.pincode && (
                                                        <small className='staan-error-msg'>
                                                        {errors.pincode}
                                                        </small>
                                                )}
                                            </div>
                                            <div className="col-6 col-6 staan-input-group pos_input_group">
                                                <Field
                                                    type='text'
                                                    name='City'
                                                    id="City"
                                                    value={posValues?.city}
                                                    disabled
                                                    placeholder=''
                                                    className='w-100 staan-form-input'
                                                    />
                                                    <label
                                                    htmlFor="City"
                                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                                    >
                                                    City
                                                    </label>
                                                    {touched.City &&
                                                    errors.City && (
                                                        <small className='staan-error-msg'>
                                                        {errors.City}
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="col-6 staan-input-group pos_input_group">
                                                <CustomSelect
                                                setSelectEditValue={posValues?.SalesPerson}
                                                options={userdata.map(option => ({
                                                    value: option.id,
                                                    label: option.username
                                                    }))}
                                                handleOnChange={(option)=>{
                                                    setPosValues(prev=>({...prev, SalesPerson:option? option : null}))
                                                    setFieldValue( 'SalesPerson',option?.value ? option?.value : null)
                                                }}
                                                isDisabled={ posValues.status === 'Canceled'
                                                    ? true
                                                    :  posValues?.isSomeStockReduced}
                                                isClearable
                                                />
                                                <label
                                                className='staan-form-input-label pt-1 px-1 mx-2'
                                                >
                                                Sales Person
                                                </label>
                                                {touched.SalesPerson &&
                                                errors.SalesPerson && (
                                                    <small className='staan-error-msg'>
                                                    {errors.SalesPerson}
                                                    </small>
                                                )}

                                            </div>
                                            <div className='col-6 staan-input-group pos_input_group'> 
                                                <Field
                                                type='text'
                                                name='State'
                                                id="State"
                                                value={posValues?.states}
                                                disabled
                                                placeholder=''
                                                className='w-100 staan-form-input'
                                                />
                                                <label
                                                htmlFor='State'
                                                className='staan-form-input-label pt-1 px-1 mx-2'
                                                >
                                                State
                                                </label>
                                                {touched.State &&
                                                errors.State && (
                                                    <small className='staan-error-msg'>
                                                    {errors.State}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                    {posValues?.isSales &&  (
                                        <div className='sample-customer-card ' style={{overflowX:"hidden"}}>
                                            <div className='row py-1 mx-2' hidden={!isShowConferenceData}>
                                                <div className="col-12 staan-input-group pos_input_group">
                                                    <CustomSelect
                                                    setSelectEditValue={posValues?.marketingEvent}
                                                    options={conferenct?.filter(item => item.currencySymbol === "₹")}
                                                    isClearable
                                                    handleOnChange={(option)=>{
                                                        if (option){
                                                            setPosValues(prev=>({...prev, marketingEvent:  option  , currencyRate:  option?.exchangeRate , currencySymbol:option?.currencySymbol}))
                                                            setFieldValue('marketingEvent',  Number(option?.value) )
                                                            setinitialValue(prev=>({...prev, marketingEvent:Number(option?.value)}))
                                                        } else{
                                                            setPosValues(prev=>({...prev, marketingEvent:  null  , currencyRate:  0 , currencySymbol:null}))
                                                            setFieldValue('marketingEvent',  Number(option?.value))
                                                            setinitialValue(prev=>({...prev, marketingEvent:null}))
                                                        }
                                                    }}
                                                    isDisabled={
                                                        posValues?.status === 'Canceled'
                                                            ? true
                                                            : posValues?.isSomeStockReduced
                                                        }
                                                    />
                                                    <label
                                                    className='staan-form-input-label pt-0 px-1 mx-2'
                                                    >
                                                    Marketing Event <span className='text-danger'>*</span>
                                                    </label>
                                                    {touched.marketingEvent &&
                                                        errors.marketingEvent && (
                                                            <small className='staan-error-msg'>
                                                            {errors.marketingEvent}
                                                            </small>
                                                        )}
                                                </div>
                                                <div className="col-12 staan-input-group pos_input_group">
                                                    <CustomSelect
                                                        setSelectEditValue={posValues?.store}
                                                        options={prefetchValue?.storeList}
                                                        handleOnChange={(option)=>{
                                                            setPosValues(prev=>({...prev, store:option? option: null }))
                                                            setFieldValue('Store',option?  Number(option?.value) : null)
                                                        }}
                                                        isDisabled={
                                                            
                                                            posValues?.status === 'Canceled'
                                                                ? true
                                                                : posValues?.isSomeStockReduced
                                                        }
                                                    />
                                                    <label className='staan-form-input-label pt-0 px-1 mx-2' >
                                                    Store <span className='text-danger'>*</span>
                                                    </label>
                                                    {touched.Store &&
                                                            errors.Store && (
                                                                <small className='staan-error-msg'>
                                                                {errors.Store}
                                                                </small>
                                                            )}
                                                </div>
                                            </div>
                                            <div className="row d-flex justify-content-center pt-2  cursor-pointer" onClick={()=>{setIsShowConferenceData(!isShowConferenceData)}}  hidden={isShowConferenceData}>
                                                <div className="col-9 border border-primary " >
                                                </div>
                                            </div>
                                            <div className='row   mx-2'>
                                                <div className="col-12 staan-input-group pos_input_group">
                                                    <CustomSelect
                                                        setSelectEditValue={posValues?.selectcostomerName}
                                                        handleInputChange={(e)=>{
                                                            if (e){
                                                                dispatch(fetchOptionsData(SupplierOption(e), "posSupplierfetch") )
                                                            }
                                                        }}
                                                        options={posList?.customers}
                                                        handleOnChange={(option)=>{
                                                            if (option){
                                                                setPosValues(prev=>({...prev, selectcostomerName:option,
                                                                        LegalName:option?.legalName, costomerName:option?.lable}))
                                                                setFieldValue('CostomerName',Number(option?.value))
                                                                getAddress(option?.value, setFieldValue)
                                                            } else{
                                                                setPosValues(prev=>({...prev, selectcostomerName:{value: '', label: ''}, LegalName:""}))
                                                                setFieldValue('CostomerName',"")
                                                                getAddress("", setFieldValue)
                                                            }
                                                        }}
                                                        isDisabled={
                                                            posValues?.status === 'Canceled'
                                                                ? true
                                                                :  posValues?.isSomeStockReduced
                                                        }
                                                        isClearable
                                                    />
                                                    <label className='staan-form-input-label pt-0 px-1 mx-2'>
                                                    Customer <span className='text-danger'>*</span>
                                                    <i role="button" className='bx bx-plus-circle ms-2'
                                                    onClick={() => { handleSupplierShow() }} ></i>
                                                    </label>
                                                    {touched.CostomerName &&
                                                            errors.CostomerName && (
                                                                <small className='staan-error-msg'>
                                                                {errors.CostomerName}
                                                                </small>
                                                            )}
                                                </div>
                                                <div className="col-12 staan-input-group pos_input_group">
                                                    <CustomSelect
                                                    setSelectEditValue={posValues?.billingAddress}
                                                    options={posList?.billingAddresses}
                                                    isDisabled={ posValues.status === 'Canceled'
                                                        ? true
                                                        : posValues?.isSomeStockReduced}
                                                    handleOnChange={option=>{
                                                        if(option.value){
                                                            setPosValues(prev=>({...prev, billingAddress:option}))
                                                            setFieldValue('BillingAddress',option?.value)
                                                            // onChangPosTypeChangeTaxForSales(option.State, false)
                                                        } else{
                                                            setPosValues(prev=>({...prev, billingAddress:null}))
                                                            setFieldValue('BillingAddress',null)
                                                        }
                                                    }}
                                                    />
                                                    <label
                                                        className='staan-form-input-label pt-1 px-1 mx-2'
                                                    >
                                                        Billing Address <span className='text-danger'>*</span>
                                                    </label>
                                                </div>
                                                <div className='col-12 staan-input-group'>
                                                    <CustomSelect
                                                        setSelectEditValue={posValues?.deliverAddress}
                                                        options={posList?.deliverAddresses}
                                                        isDisabled={
                                                            posValues.status === 'Canceled'
                                                            ? true
                                                            : posValues?.isSomeStockReduced
                                                        }
                                                        handleOnChange={option => {
                                                            if (option){
                                                                setPosValues(prev=>({...prev, deliverAddress:option}))
                                                                setFieldValue('DeliverAddress',option?.value)
                                                            } else{
                                                                setPosValues(prev=>({...prev, deliverAddress:null}))
                                                                setFieldValue('DeliverAddress',null)
                                                            }
                                                        }}
                                                    />
                                                    <label
                                                        className='staan-form-input-label pt-1 px-1 mx-2'
                                                    >
                                                        Delivery Address
                                                    </label>
                                                    {touched.DeliverAddress &&
                                                        errors.DeliverAddress && (
                                                        <small className='staan-error-msg'>
                                                            {errors.DeliverAddress}
                                                        </small>
                                                        )}
                                                </div>
                                                <div className='col-6 staan-input-group'>
                                                    
                                                    <CustomSelect
                                                        setSelectEditValue={posValues?.contactPerson}
                                                        isDisabled={ posValues.status === 'Canceled'
                                                            ? true
                                                            : posValues?.isSomeStockReduced}
                                                        isClearable
                                                        options={posList?.contactPersons}
                                                        handleOnChange={(option) => {
                                                            setPosValues(prev=>({...prev, contactPerson:option}))
                                                            setFieldValue('contactPerson',option?.value)
                                                        }}
                                                    />
                                                    <label
                                                        className='staan-form-input-label pt-1 px-1 mx-2'
                                                    >
                                                        Contact Person
                                                    </label>
                                                    { touched.contactPerson &&
                                                        errors.contactPerson && (
                                                        <small className='staan-error-msg'>
                                                            {errors.contactPerson}
                                                        </small>
                                                        )}
                                                    </div>
                                                <div className='col-6 staan-input-group'>
                                                    <Field
                                                        type='number'
                                                        name='Contace_Number'
                                                        id="Contace_Number"
                                                        value={posValues?.contactNumber}
                                                        disabled
                                                        className='w-100 staan-form-input'
                                                    />
                                                    <label
                                                        htmlFor='Contace_Number'
                                                        className='staan-form-input-label pt-2 px-1 mx-2'
                                                    >
                                                        Contace Number
                                                    </label>
                                                </div>
                                                <div className='col-12 staan-input-group'>
                                                    <CustomSelect
                                                        setSelectEditValue={posValues?.SalesPerson}
                                                        isDisabled={ posValues.status === 'Canceled'
                                                            ? true
                                                            : posValues?.isSomeStockReduced}
                                                        isClearable
                                                        options={userdata.map(option => ({
                                                            value: option.id,
                                                            label: option.username
                                                            }))}
                                                        handleOnChange={(option)=>{
                                                            if (option){
                                                                setPosValues((prev)=>({...prev, SalesPerson:option}))
                                                                setFieldValue('SalesPerson',option?.value)
                                                            } else{
                                                                setPosValues((prev)=>({...prev, SalesPerson:null}))
                                                                setFieldValue('SalesPerson',null)
                                                            }
                                                        }}
                                                    />
                                                    <label
                                                        className='staan-form-input-label pt-1 px-1 mx-2'
                                                    >
                                                        Sales Person
                                                    </label>
                                                    {touched.SalesPerson &&
                                                        errors.SalesPerson && (
                                                        <small className='staan-error-msg'>
                                                            {errors.SalesPerson}
                                                        </small>
                                                        )}
                                                    </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='bill-details card mt-2 pt-2 pb-2' style={{
                                    fontSize: '.8rem',
                                    height: posValues?.isSales ?  screenSize?.height > 768 ? '33%': "35%" : screenSize?.height > 768 ? '24%':'26%' ,
                                    }}>
                                <div className='row my-1 mx-1'>
                                    <div className='col-5 fw-bold'  >Total Amount</div>
                                    <div className='col-1 fw-bold'>:</div>
                     
                                    <div className='col-5 text-end fw-bold'>
                                    {displayRupeeFormat(posValues?.fixedTotalAmount, posValues?.currencyName)}
                                    </div>
                                </div>
                                <div className='row my-1 mx-1' hidden={!posValues?.isSales}>
                                    <div className='col-5 fw-bold text-primary cursor-pointer'
                                    onClick={()=>{
                                        setPosValues((prev)=>({...prev, isShowOtherIncomeCharges:true}))
                                    }}
                                    >Other Charges</div>
                                    <div className='col-1 fw-bold'>:</div>
                                    <div
                                        className='col-5 text-end fw-bold'
                                    >
                                       {displayRupeeFormat(posValues?.totalOtherIncomeCharge, posValues?.currencyName)}
                                    </div>
                                </div>
                                <div className='row my-1 mx-1'>
                                    <div className='col-5 fw-bold text-danger '
                                        onClick={()=>{
                                        if (posValues?.status ==="Canceled" || posValues?.status === "Submited"){

                                        } else if(!posValues?.isSomeStockReduced){
                                            setPosValues(prev=>({...prev, isShowDisCount:true}))}
                                        }
                                        }
                                        style={{ cursor: 'pointer' }}
                                    > Discount </div>
                                    <div className='col-1 fw-bold d-flex justify-content-between'>:</div>
                                    <div
                                        className='col-5 text-end fw-bold'>
                                        {Number(discountValue?.discountPercentage) > 0? getTotalDiscountPercentage(discountValue?.discountPercentage) :
                                        Number(discountValue?.discountValue) > 0? dislpayOverallTotalDiscount(): Number(discountValue?.discountTotalValue) > 0?
                                        dislpayFinalTotalDiscount():(displayRupeeFormat(0.00, posValues?.currencyName))}
                                    </div>
                                </div>
                                
                                <div className='row my-1 mx-1' hidden={!posValues?.isSales}>
                                <div
                                    className='col-5 fw-bold'>
                                    Before Tax
                                </div>
                                <div className='col-1 fw-bold d-flex justify-content-between'>:</div>
                                <div
                                    className='col-5 text-end fw-bold'
                                    
                                >
                                    {displayRupeeFormat(posValues?.beforetax, posValues?.currencyName)}
                                </div>
                                </div>
                               
                                <div className='row my-1 mx-1' hidden={!posValues?.isSales}>
                                    <div className='col-5 fw-bold text-primary cursor-pointer'
                                    onClick={()=>{setPosValues((prev)=>({...prev, isShowTax:true}))}}
                                    >Total Tax</div>
                                    <div className='col-1 fw-bold d-flex justify-content-between'>:</div>
                                    <div
                                        className='col-5 text-end fw-bold'
                                    >
                                        {displayRupeeFormat(posValues?.totalTax, posValues?.currencyName)}
                                    </div>
                                </div>
                                <div className='row my-1 mx-1'>
                                    <div
                                        className='col-5 fw-bold'>
                                        Round Off
                                    </div>
                                    <div className='col-1 fw-bold d-flex justify-content-between'>:</div>
                                    <div
                                        className='col-5 text-end fw-bold'
                                    > 
                                    {displayRupeeFormat(posValues?.roundOFValue, posValues?.currencyName)}
                                    </div>
                                </div>
                                <div className='row my-1 mx-1'>
                                    <div
                                        className='col-5 text-success fw-bold'
                                        style={{ cursor: 'pointer' }}
                                    >
                                       <span onClick={()=>{
                                            if (posValues?.status ==="Canceled" || posValues?.status === "Submited"){

                                            } else if(!posValues?.isSomeStockReduced){
                                                setPosValues(prev=>({...prev, isShowPayment:true}))}}
                                            }>To Pay</span>    <span onClick={()=>{setPosValues(prev=>({...prev, isShowCurrencyConversion:true}))}}>  {posValues.currencySymbol}</span>
                                    </div>
                                    <div className='col-1 fw-bold d-flex justify-content-between'>:</div>
                                    <div
                                        className='col-5 text-end fw-bold'
                                    >
                                        {displayRupeeFormat(posValues?.toPay, posValues?.currencyName)}
                                    </div>
                                </div> 
                                <div className='row my-1 mx-1'>
                                    <div className='col-5  fw-bold  ' >Received</div>
                                    <div className='col-1 fw-bold d-flex justify-content-between'>:</div>
                                    <div
                                        className='col-5 text-end fw-bold'
                                        
                                    >
                                    { displayRupeeFormat(Number(posValues?.received)?.toFixed(2),posValues?.currencyName)  }
                                    </div>
                                </div>
                                <div className='row my-1 mx-1'>
                                    <div className='col-5  fw-bold  '>Balance</div>
                                    <div className='col-1 fw-bold d-flex justify-content-between'>:</div>
                                    <div
                                        className='col-5 text-end fw-bold'
                                    >
                                    {displayRupeeFormat((posValues?.toPay - posValues?.received).toFixed(2), posValues?.currencyName)}
                                    </div>
                                </div>

                                </div>
                                <div className='action-details card mt-2' style={{ height: '7%' }}>
                                    <div
                                        className='d-flex justify-content-evenly align-items-center'
                                        style={{ height: '100%' }}
                                        >
                                        <Can I={"Draft"} a={"POS"}>
                                            <button
                                            onClick={() => {
                                                submitForm()
                                                getFormik(errors)
                                            }}
                                            type='button'
                                            disabled={
                                                posValues?.status === 'Canceled'
                                                ? true
                                                : posValues?.isSomeStockReduced ? true :  false
                                            }
                                            className='btn   btn btn-outline-secondary    '
                                            style={{width:"8rem"}}
                                            >
                                            Save
                                            </button> 
                                        </Can>
                                        <Can  I={"Submit"} a={"POS"}>
                                            <button
                                            onClick={SubmitThePos}
                                            disabled={
                                                posValues?.status === "Save" ? false :
                                                posValues?.status === "Submited" ? true :
                                                posValues?.isAllStockReduced ? true  : true
                                            }
                                            className='ms-3  btn   btn-outline-success  '
                                            type='button'
                                            style={{width:"8rem"}}
                                            >
                                            Submit
                                            </button>
                                        </Can>
                            
                            
                                    </div>
                                </div>
                            </div>
                            {/* items datas */}
                            <div className="right-side card col-9 pt-1" style={{ height: '100%' }}>
                                <div className='row' style={{ width: '100%' }}>
                                    <div className='col-1 d-flex align-items-center pt-2 pe-0 mt-2  '>
                                        <label
                                        htmlFor='scannedqr'
                                        className='form-label fw-bold p-0'
                                        >
                                        Scan QR:
                                        </label>
                                    </div>
                                    <div className='col-3 mt-2 ms-0 ps-0  '>
                                        <>
                                        <input
                                            disabled={
                                            posValues?.status === 'Canceled'
                                                ? true
                                                : posValues?.isAllStockReduced
                                            }
                                            autoComplete={false}
                                            name='scannedqr'
                                            type='text'
                                            id='scannedqr'
                                            value={posValues?.scannerValue}
                                            ref={scannerRef}
                                            onChange={e => {
                                                
                                                handlescannerValueChange(e.target.value)
                                            }}
                                            // style={{backgroundColor:"green"}}
                                            className='w-100 form-control  bg-light'
                                        />
                                        
                                        </>
                                    
                                        
                                    </div>
                                    <div className='col-3 d-flex justify-content-between align-items-center'>
                                        <p className=' mt-2 pt-2  '>Alt + S</p>
                                        <p className='  mt-2 pt-2 fw-bold text-end fs-5 '>
                                        Total Qty:
                                            <span className='ms-1 fs-5'> {posValues?.totalItemQty}</span>
                                        </p>
                                    </div>
                                    <div className='col-4 pe-4 text-end mt-2 '>
                                        <button
                                        type='button'
                                        className='btn btn-outline-primary px-4'
                                        disabled={
                                            posValues?.status === 'Canceled'
                                            ? true
                                            : posValues?.isSomeStockReduced
                                        }
                                        onClick={() => {
                                            if (posValues?.isSales) {
                                                if (posValues?.store?.value && posValues?.marketingEvent?.value) {
                                                    if (posValues?.selectcostomerName?.value) {
                                                        setPosValues((prev)=>({...prev, isShowItemDetails:true}))
                                                    } else{
                                                        showErrorToast(true, 'warn', 'Customer And Marketing Event Is Required!', '')
                                                    }
                                                } else{
                                                    showErrorToast(true, 'warn', 'Store And Marketing Event Is Required!', '')
                                                }
                                                } else {
                                                if (posValues?.store?.value && posValues?.marketingEvent?.value) {
                                                    setPosValues((prev)=>({...prev, isShowItemDetails:true}))
                                                } else{
                                                    showErrorToast(true, 'warn', 'Store And Marketing Event Is Required!', '')
                                                }
                                            }
                                        }}
                                        >
                                        <i className='bx bxs-plus-circle'></i> Add Item
                                        </button>
                                    </div>
                                    <div className='col-1 text-center mt-2'>
                                        {posList?.posItemData?.length > 0 ? <AiOutlinePrinter
                                            style={{cursor: "pointer"}}
                                            className='fs-3'
                                            onClick={() => {
                                            if (posValues?.status === 'Canceled') {
                                            } else {
                                                handlePrint()
                                            }
                                            }}
                                        /> : ""}
                                    </div>
                                </div>
                                <div className="mt-3" style={{ height: '100%', width: '100%', overflowY:"scroll" }}>
                                    <CommanTable
                                    headers={itemDetails_columns}
                                    rowData={posList?.posItemData}
                                    /> 
                                </div>
                                <div className='row'>
                                        <div className='col-7 staan-input-group'>
                                            <textarea
                                            id='Tax'
                                            className='w-100 staan-form-input-text-area'
                                            disabled={posValues?.status === 'Canceled' ? true : posValues?.isSomeStockReduced}
                                            value={posValues?.remark}
                                            onChange={e => {
                                                setPosValues(prev=>({...prev, remark:e.target.value}))
                                            }}
                                            rows={2}
                                            />
                                            <label
                                            htmlFor='Tax'
                                            className='staan-form-input-label pt-2 px-1 mx-2'
                                            >
                                            Remarks
                                            </label>
                                        </div>
                                        <div className='col-3 my-4'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <label
                                                    className='form-check form-switch'
                                                    style={{ fontSize: '.8rem' }}
                                                    >
                                                    Pending
                                                    </label>
                                                </div>
                                            <div className='col-6 form-check form-switch'>
                                                <Field
                                                disabled={posValues?.status === 'Canceled' ? true : posValues?.isSomeStockReduced}
                                                type='checkbox'
                                                name='Pending'
                                                className='me-3 form-check-input'
                                                checked={posValues?.pending === true ? true : false}
                                                onClick={() => {
                                                    setPosValues(prev=>({...prev, pending:!posValues?.pending}))
                                                }}
                                                />
                                        
                                            </div>
                                            </div>
                                        </div>
                                        <div className='col-2 my-4 '>
                                            <div className='row'>
                                                <div className='col-6'>
                                                <label style={{ fontSize: '.8rem' }}>
                                                    Delivered
                                                </label>
                                                </div>
                                                <div className='col-6 form-check form-switch'>
                                                    <Field
                                                        disabled={posValues?.status === 'Canceled' ? true : posValues?.isSomeStockReduced}
                                                        type='checkbox'
                                                        name='isDelivered'
                                                        className=' form-check-input'
                                                        checked={
                                                        posValues?.isDelivered === true ? true : false
                                                        }
                                                        onClick={() => {
                                                            setPosValues(prev=>({...prev, isDelivered:!posValues?.isDelivered}))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </Form>
                    </>
                )}

                </Formik>

            </div>
        </Modal.Body>
        </Modal>
        <PosItemDetails2
        posList={posList}
        setposList={setposList}
        posItemEdit={posItemEdit}
        setPosItemEdit={setPosItemEdit}
        isShow={posValues?.isShowItemDetails}
        setIsShow={()=>{setPosValues((prev)=>({...prev, isShowItemDetails:false}))}}
        posValues={posValues}
        setPosValues ={setPosValues}
        userId={userId}
        saveLocalyBatchNumber={saveLocalyBatchNumber}
        saveSerialNumberinlocal={saveSerialNumberinlocal}
        saveLocalyNoneBS={saveLocalyNoneBS}
        />
        <PosPaymentModel
        isShow={posValues?.isShowPayment}
        handleCloseModal={()=>{setPosValues(prev=>({...prev, isShowPayment:false}))}}
        posValues={posValues}
        handleCurrentReceivePay={handleCurrentReceivePay}
        paymentInputs = {paymentInputs}
        setpaymentInputs={setpaymentInputs}
        posPamentValue={posPamentValue}
        setPosPamentValue={setPosPamentValue}
        />
        {<ShowCurrencyConversion
            isShow={posValues?.isShowCurrencyConversion}
            setIshow={()=>{setPosValues(prev=>({...prev, isShowCurrencyConversion:false}))}}
            currencySymbols={posValues?.currencySymbol}
            netAmount={posValues?.toPay}
            currencyrate={posValues?.currencyRate}
        />}
        {posValues?.isShowDisCount &&
            <DiscountModel
            isShow={posValues?.isShowDisCount}
            setIsShow={()=>{setPosValues(prev=>({...prev, isShowDisCount:false}))}}
            discountValue = {discountValue}
            setDiscountValue = {setDiscountValue}
            ClearDisCount={clearDiscount}
            applyDisCount={applyDisCount}
            netAmount={posValues?.toPay} />
        }
        {posValues?.isShowStockNeed &&
        <ShowStockNeed
            showStockNeed={posValues?.isShowStockNeed}
            setShowStockNeed={()=>{setPosValues(prev=>({...prev, isShowStockNeed:false}))}}
            showStockNeedList ={posValues?.StockNeedList}
        />}
        
        {<TaxModel
            showTaxModel={posValues?.isShowTax}
            setShowTaxModel={()=>{setPosValues((prev)=>({...prev, isShowTax:false}))}}
            taxData = {posValues?.taxData}
        />}
        {cancelWaring &&
            <WaringModel
            state={cancelWaring}
            setstate={setcancelWaring}
            title={'Confirm'}
            text={'Confirm to cancel'}
            Confirmtion={value => {
                permissionToStockAddtion(value)
            }}
            />
        }
        {posValues?.deleteWaring &&
            <WaringModel
                state={posValues?.deleteWaring}
                setstate={()=>{setPosValues(prev=>({...prev, deleteWaring:false}))}}
                title={'Delete Confirm'}
                text={`Confirm to Delete ${posValues?.deleteData?.Name}.`}
                Confirmtion={value => {
                    permissionToDelete(value)
                }}
            />}
            {posValues?.isShowOtherIncomeCharges&&
            <PosOtherIncomecharges
            isShow={posValues?.isShowOtherIncomeCharges}
            handleCloseModal={()=>{setPosValues(prev=>({...prev, isShowOtherIncomeCharges:false}))}}
            otherIncomeInputs={otherIncomeInputs}
            setOtherIncomeInputs={setOtherIncomeInputs}
            otherIncomeChargeValue={otherIncomeChargeValue}
            setOtherIncomeChargeValue={setOtherIncomeChargeValue}
            state={posValues?.states}
            callClearDiscount={(prev)=>{callClearDiscount(prev)}}
            callClearDiscountOnlyOnDelete={(prev)=>{callClearDiscountOnlyOnDelete(prev)}}
            />}
            <SupplierFrom
                componentName={'Customer'}/>
        </>
    )
}

export default Posmodel