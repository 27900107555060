import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import TableView from '../../Packages/Custom/DataTable';
import "../../Style/conference.css";
import ConferenceFrom from './ConferenceForm';

const ConferenceList = () => {

    const [conferenceEdit, setConferenceEdit] = useState(null)

    const [AddConference, setAddConference] = useState(false);
    const [isLoad, setLoad] = useState(false);

    const handleConferenceFormshow = ()=>{
        setAddConference(true)
        
    }

    const handleConferenceFromClose = ()=>{
        setAddConference(false)
    }

    const CustomViewModalComponent = (data) => {
        setConferenceEdit(data)
        handleConferenceFormshow()
    }

    const column_details = [
        {
            header: "Name", 
            field: "name",
            flex: 10,
            inputType: 'text',
            query: 'ConferenceNameOptionFetchQuery',
            renderComponent: CustomViewModalComponent,
        },
        {
            header: "Active",
            field: "status",
            flex: 10,
            inputType: 'text',
            bool: true,
            option: true,
        },
        {
            header: "Incharge", 
            field: "inCharge",
            flex: 10,
            inputType: 'text',
            label: 'username',
            query: 'userOptionQuery',
            option: true,
        },
        {
            header: "Created By", 
            field: "createdBy",
            flex: 10,
            inputType: 'text',
            label: 'username',
            query: 'userOptionQuery',
            option: true,
        },
        {
            header: "Start Date", 
            field: "startDate",
            flex: 10,
            inputType: 'text',
            isDateField: true,
        },
        {
            header: "End Date", 
            field: "endDate",
            flex: 10,
            inputType: 'text',
            isDateField: true,
        },
        
    ]

    const CustomAddModalComponent = () => {
        handleConferenceFormshow()
    }

    return(
        <>
            <div className=' index_page_aliment_warp'>
                <div className='index_page_aliment_sidebar'>
                    <SideNavbar />
                </div>
                <div className='index_page_aliment_table'>
                    <TableView
                        headers = {column_details}
                        query = {'ConferenceTableMasterQuery'}
                        loadComponent = {isLoad}
                        titleName = {'Conference'}
                        permission={"Save_Conference"}
                        heightAdjust = {'40rem'}
                        addComponent = {CustomAddModalComponent}
                        customFilter={true}
                    />
                </div>
            </div>
       
            
            {AddConference && <ConferenceFrom
                handleConferenceFromClose = {handleConferenceFromClose}
                AddConference = {AddConference}
                conferenceEdit= {conferenceEdit}
                setConferenceEdit ={setConferenceEdit}
                setLoad = {()=>{setLoad(!isLoad)}}
            />}
            <ToastContainer/>
        
        </>
    )
}


export default ConferenceList;