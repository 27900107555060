import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { CiEdit } from "react-icons/ci";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import Reactselect from 'react-select';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import WaringModel from '../../../components/Warings/WaringModel';
import DataContext from '../../../context/ItemMasterContext';
import { customSelectStyle, gettax } from '../../../utils/Utils';

const PosOtherIncomecharges = ({isShow,handleCloseModal, otherIncomeInputs, setOtherIncomeInputs,
    otherIncomeChargeValue, setOtherIncomeChargeValue, state, callClearDiscount, callClearDiscountOnlyOnDelete}) => {
    
    const {otherIncomeChargelist, userId} = useContext(DataContext)
    const [deleteValue, setDeleteValue] =  useState({isshow:false, deleteData:{label: "",value: ""}})
    const saveRow = (index) => {
        if (!otherIncomeChargeValue?.account?.label) {
            showErrorToast(true, 'error', "Account is required.", '')
            return;
        } else if (!otherIncomeChargeValue?.amount) {
            showErrorToast(true, 'error', "Amount is required.", '')
            return;
        } else if (!otherIncomeChargeValue?.tax) {
            showErrorToast(true, 'error', "Tax is required.", '')
            return;
        }
    
        let save = {
            index: index,
            id: otherIncomeChargeValue?.id ? Number(otherIncomeChargeValue?.id) : "",
            account: {
                label: otherIncomeChargeValue?.account?.label,
                value: Number(otherIncomeChargeValue?.account?.value)
            },
            hsnCode: otherIncomeChargeValue?.hsnCode,
            hsn: otherIncomeChargeValue?.hsn ? Number(otherIncomeChargeValue?.hsn) : null,
            afterDiscountValue:Number(otherIncomeChargeValue?.afterDiscountValue)>0 ? otherIncomeChargeValue?.afterDiscountValue : null,
            discountValue:Number(otherIncomeChargeValue?.discountValue)>0 ? otherIncomeChargeValue?.discountValue : null,
            amount: otherIncomeChargeValue?.amount ? Number(otherIncomeChargeValue?.amount) : "",
            tax: otherIncomeChargeValue?.tax,
            createdBy: otherIncomeChargeValue?.id ? Number(otherIncomeChargeValue?.createdBy) : Number(userId),
        };
        
        save = gettax(save, save?.tax, state);
    
        let updateOthercharge = otherIncomeInputs?.map((item) => {
            return item?.index === index ? save : item; // Correctly return the updated item
        });
        setOtherIncomeInputs(updateOthercharge); // Update the state with the new array
    
        setOtherIncomeChargeValue({
            id: "",
            account: { label: "", value: "" },
            hsnCode:"",
            tax: "",
            amount: "",
            currentIndex: null,
        });
    
        callClearDiscount([], updateOthercharge);
    };
    

    function handeleAddRow(params) {
        setOtherIncomeInputs(prev=>([...prev, {
            index:Number(otherIncomeInputs?.length)+1,
            id: "",
            other_income_charges_id:{
                label: "",
                value: ""
            },
            hsn:"",
            hsnCode:"",
            amount:0,
            tax:0,
            sgst:0,
            cgst:0 ,
            igst:0 ,
            createdBy: ""
        }]))
        setOtherIncomeChargeValue({
            id:"",
            account:{ label: "",
                value: ""
            }, tax:"",
            hsnCode:"",
            amount:"",
            currentIndex:Number(otherIncomeInputs?.length)+1,
    
        })
        
        
    }

    function makeEdit(data) {
        
        setOtherIncomeChargeValue({
            index:data?.index,
            id:data?.id,
            account:data?.account,
            hsn:data?.hsn,
            tax:data?.tax,
            hsnCode:"",
            amount:Number(data?.afterDiscountValue)>0 ? Number(data?.afterDiscountValue): Number(data?.amount),
            currentIndex:data?.index,
            createdBy:data?.createdBy,
            afterDiscountValue :data?.afterDiscountValue,
            discountValue : data?.discountValue
        })
    }


     // return null; after get premission to delete remove the data in list
    function permissionToDelete(params) {
        if (params){
            // Check the structure of deleteData and ensure correct comparison
            let otherIncome = otherIncomeInputs?.filter(prev => prev?.index !== deleteValue?.deleteData?.value);
            if (otherIncome?.length <=0){
                otherIncome =[{
                    index:1,
                    id: "",
                    account:{
                        label: "",
                        value: ""
                    },
                    amount:0,
                    tax:0,
                    sgst:0,
                    cgst:0,
                    igst:0,
                    created_by: ""
                }]
            }
            
            setOtherIncomeInputs(otherIncome)
            callClearDiscountOnlyOnDelete(null, otherIncome);
            
            
            if (otherIncome?.length === 1 && Number(otherIncome[0]?.amount) <= 0){
                setOtherIncomeChargeValue({
                    id: "",
                    account: { label: "", value: "" },
                    hsnCode:"",
                    tax: "",
                    amount: "",
                    currentIndex: 1,
                });
            } else{
                setOtherIncomeChargeValue({
                    id: "",
                    account: { label: "", value: "" },
                    hsnCode:"",
                    tax: "",
                    amount: "",
                    currentIndex: null,
                });
            }
           
        }
        
    }

    function closeCharges() {
        if(otherIncomeChargeValue?.currentIndex){
            showErrorToast(true, 'error', "Clear the unsaved value.", '')
        } else{
            handleCloseModal()
        }
        
    }

    function getBeforeChangesValue(params) {
        setOtherIncomeChargeValue({
            id: "",
            account: { label: "", value: "" },
            tax: "",
            amount: "",
            hsnCode:"",
            currentIndex: null,
        });
    }
    return (
        <Modal show={isShow}  size="xl"  onHide={handleCloseModal}  backdrop="static"
        keyboard={false} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5 onClick={()=>{console.log(otherIncomeInputs)
                    }}>Other Charges</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {otherIncomeInputs?.length > 0 &&
                    otherIncomeInputs?.map((item, index)=>(
                        <div key={index} className="row">
                            <div className="col-3 staan-input-group">
                                <Reactselect
                                        name={`rows.${index}.otherincomecharge`}
                                        options={otherIncomeChargelist}
                                        className='mt-3 fw-bold'
                                        value={otherIncomeChargeValue?.currentIndex === item?.index ?
                                            otherIncomeChargeValue?.account: item?.account}
                                        isDisabled={otherIncomeChargeValue?.currentIndex === item?.index ? false: true}
                                        onChange={(options)=>{
                                            if (options){
                                                setOtherIncomeChargeValue(prev=>({...prev, account:options, tax:options?.tax, hsn:options?.hsn,
                                                    hsnCode:options?.hsnCode,
                                                }))
                                            } else{
                                                setOtherIncomeChargeValue(prev=>({...prev, account:{label: "",value: ""}, hsn:"", hsnCode:""}))
                                            }
                                        }}
                                        styles = {customSelectStyle}
                                />
                                <label   className='staan-form-input-label pt-1 px-1 mx-2' onClick={()=>{console.log(item);
                                }}>Account</label>
                            </div>
                            <div className="col-1 staan-input-group">
                                <input
                                    type="text"
                                    id='Tax'
                                    disabled
                                    name={`rows.${index}.Tax`}
                                    value={otherIncomeChargeValue?.currentIndex === item?.index ?
                                        otherIncomeChargeValue?.tax: item?.tax}
                                    className='staan-form-input text-end pe-3 Tax fw-bold'
                                />
                                <label htmlFor="Tax" className='staan-form-input-label pt-2 px-1 mx-2'>Tax</label>
                            </div>
                            <div className="col-3 staan-input-group">
                                <input
                                    type="text"
                                    id='discount'
                                    disabled
                                    name={`rows.${index}.discount`}
                                    value={ item?.afterDiscountValue ? `${item?.amount}-${item?.discountValue} = ${item?.afterDiscountValue}`: ""}
                                    className='staan-form-input text-end pe-3 Tax fw-bold'
                                />
                                <label htmlFor="Tax" className='staan-form-input-label pt-2 px-1 mx-2'>Discount</label>
                            </div>
                            <div className="col-2 staan-input-group">
                                <input
                                    type="number"
                                    id='Amount'
                                    name={`rows.${index}.Amount`}
                                    value={otherIncomeChargeValue?.currentIndex === item?.index ?
                                        otherIncomeChargeValue?.amount: Number(item?.afterDiscountValue)>0 ? Number(item?.afterDiscountValue): Number(item?.amount)}
                                    disabled={otherIncomeChargeValue?.currentIndex === item?.index ? false: true}
                                    className='staan-form-input text-end pe-3 Amount fw-bold'
                                    onChange={(e)=>{
                                        if (e.target.value){
                                            setOtherIncomeChargeValue(prev=>({...prev, amount:e.target.value}))
                                        } else{
                                            setOtherIncomeChargeValue(prev=>({...prev, amount:""}))
                                        }
                                        
                                    }}
                                />
                                <label htmlFor="Amount" className='staan-form-input-label pt-2 px-1 mx-2'>Amount</label>
                            </div>
                            <div className="col-2 d-flex align-items-end justify-content-center mb-1">
                                <button type='button' className='btn btn-sm btn-outline-success px-3 ms-3'
                                hidden={otherIncomeChargeValue?.currentIndex === item?.index ? false: true}
                                onClick={()=>{saveRow(item?.index)}}
                                >
                                    <i class="fa-solid fa-check"></i>
                                </button>
                                <button type='button' 
                                hidden={otherIncomeChargeValue?.currentIndex === item?.index ? true: false}
                                onClick={()=>{makeEdit(item)}}
                                className='btn btn-sm btn-outline-warning px-3 ms-3'>
                                    <CiEdit/>
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-outline-danger ms-3'
                                    
                                    hidden={otherIncomeChargeValue?.currentIndex === item?.index ? true: false}
                                    onClick={()=>{setDeleteValue({isshow:true, deleteData:{value:item?.index, label:item?.account?.label }})}}
                                >
                                <i class="fa-solid fa-trash px-1"  style={{afontSize:"1rem", margin:".2rem"}}></i>
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-outline-danger px-3 ms-3'
                                    hidden={otherIncomeChargeValue?.currentIndex === item?.index ? false: true}
                                    onClick={getBeforeChangesValue}
                                    >
                                    <IoCloseCircleOutline/>
                                </button>
                                 <button
                                    type='button'
                                    className='btn btn-sm btn-outline-primary ms-3 px-3'
                                    disabled={!(otherIncomeInputs[otherIncomeInputs?.length-1]?.index == index+1)}
                                    onClick={()=>{handeleAddRow(item?.index)}}
                                >
                                <IoMdAddCircleOutline className=''/>
                                </button>
                            </div>
                        </div>
                    ))
                }
            </Modal.Body>
            <div  className='d-flex justify-content-center mt-3'>
                <div className="text-center">
                    <button
                    type="submit" 
                    className={`btn btn-sm btn-outline-success px-5 m-3`} 
                    onClick={closeCharges}
                    >
                    Save
                    </button>
                </div>
            </div>
            {deleteValue?.isshow &&
                <WaringModel
                state={deleteValue?.isshow}
                setstate={()=>{setDeleteValue({isshow:false}) }}
                title={'Delete Confirm'}
                text={`Confirm to Delete ${deleteValue?.deleteData?.label}.`}
                Confirmtion={value => {
                    permissionToDelete(value)
                }}
                />
            }
        </Modal>
    )
}

export default PosOtherIncomecharges