// import { useLocation, Navigate, Outlet } from "react-router-dom";
// import useAuth from "../hook/useAuth"; 

// const RequireAuth = ({ AllowedPermission }) => {
//     const { userId } = useAuth(); // Assuming useAuth hook provides userId
//     const location = useLocation();
//     const user = localStorage.getItem('userid');
//     const permissionList = JSON.parse(localStorage.getItem('permissionlist')) || [];

//     // Check if user has any of the required permissions
//     const hasPermission = permissionList?.some(permission => AllowedPermission.includes(permission));
//     if (permissionList?.length <=0){
//         return <Navigate to="/unauthorized" state={{ from: location }} replace />;
//     }

//     // Redirect based on permission and user status
//     if (!user) {
//         return <Navigate to="/" state={{ from: location }} replace />;
//     }

//     if (!hasPermission) {
//         return <Navigate to="/unauthorized" state={{ from: location }} replace />;
//     }

//     return <Outlet />;
// };

// export default RequireAuth;
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireAuth = ({ AllowedPermission }) => {
    // const { userId } = useAuth(); // Assuming useAuth hook provides userId
    const location = useLocation();

    try {
        const user = localStorage.getItem('userid');
        
        // Retrieve and parse permissions from localStorage
        const permissionList = JSON.parse(localStorage.getItem('permissionlist')) || [];
        

        // Check if user has any of the required permissions
        const hasPermission = permissionList.some(permission => AllowedPermission.includes(permission));
        
        // Redirect based on permission and user status
        if (!user) {
            return <Navigate to="/" state={{ from: location }} replace />;
        }

        if (permissionList.length === 0 || !hasPermission) {
            return <Navigate to="/unauthorized" state={{ from: location }} replace />;
        }

        return <Outlet />;
    } catch (error) {
        // Log the error for debugging
        console.error("An error occurred in RequireAuth:", error);

        // Optionally, you might want to redirect to an error page or handle it differently
        return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }
};

export default RequireAuth;
